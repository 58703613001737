<!-- <app-common-dashboard (shareSixStepDataForPrint)="doSomething($event)"></app-common-dashboard>
 -->
<button class="btn btn-primary float-right mb-3" (click)="printData();" style="margin-top:-25px;">SAVE PDF</button>

<div class="d-none" id="__printingFrame">
<!-- 
<html>
<head>
    <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">
    <style type='text/css' media='print'>
        @media print {
            @page {
                size: auto;
                margin: 0;
            }
            table {
                table-layout: fixed;
                width: 100%;
            }
              td,
            th {
                font-size: 12px;
                border: 1px solid rgb(97, 91, 91) !important;
                text-align: center;
                padding: 12px 10px !important;
                vertical-align: middle !important;
            }
    
            .dataAlign {
                border: 1px solid rgb(97, 91, 91);
            }
    
            .spacw30 {
                width: 30%;
            }
    
            .spacw10 {
                width: 10%;
            }
        }
    </style>
    <style>
        table {
            table-layout: fixed;
            width: 100%;
        }

        td,
        th {
            font-size: 12px;
            border: 1px solid rgb(97, 91, 91) !important;
            text-align: center;
            padding: 12px 10px !important;
            vertical-align: middle !important;
        }

        .dataAlign {
            border: 1px solid rgb(97, 91, 91);
        }

        .spacw30 {
            width: 30%;
        }

        .spacw10 {
            width: 10%;
        }
    </style>
    <script>function step1() {
            setTimeout('step2()', 2);
        }
        function step2() { window.print(); window.close() }
    </script>
</head>

<body onload='step1()'> -->

    <div class="mt-2" style="padding:10px;margin: 0 auto;">
        <h3 class="text-center">6 STEP VALIDATION PROFORMA</h3>
        <br><br>
        <div class="mt2">
            <div class="">
                <table class="table">
                    <thead>
                        <tr>
                            <td colspan="5"> <b>Date of Apply : </b>{{myDate | date: 'shortDate'}}</td>
                            <td colspan="5"> <b>-</b></td>
                            <td colspan="6"> <b>Family ID : </b>{{sixstepPrintData[0].householdId}}</td>
                        </tr>

                        <tr>
                            <td colspan="5"> <b>District : </b>{{sixstepPrintData[0]?.districtName}}</td>
                            <td colspan="5"> <b>Mandal :</b> {{sixstepPrintData[0].mandalName}}</td>
                            <td colspan="6"><b> Secretariat Name :</b> {{sixstepPrintData[0].secretariatName}}</td>
                        </tr>

                        <tr>
                            <td colspan="4"> <b>Family Member </b></td>
                            <td colspan="12"> <b>Details of Six Step Valiation</b></td>

                        </tr>
                        <tr>
                            <th style="width:1%;">S.No</th>
                            <th style="width:5%;">Name</th>
                            <th style="width:7% ;">Mobile No</th>
                            <th style="width:4%;">Gender</th>
                            <th style="width:9%;">Details of family member if govt employee/<br />pensioner</th>
                            <th>Agree/Disagre</th>
                            <th>Details of family member paying income tax</th>
                            <th style="width:5.5% ;">Agree/ Disagree</th>
                            <th>Details of 4 wheeler owned by family member</th>
                            <th>Agree/ <br />Disagree</th>

                            <th>Details of land holding of the family</th>
                            <th>Agree/ <br />Disagree</th>
                            <th>Municipal property details more than 1000sq. ft</th>
                            <th><br />Agree/ <br />Disagree</th>

                            <th>Details of 6months Electricity consumption of more than 300units per month</th>
                            <th><br />Agree/ <br />Disagree</th>
                    
                        </tr>
                    </thead>

                    <tbody class="testdata">
                        <tr class="tableRow" *ngFor="let grid of sixstepPrintData,let i=index">
                           <!--  <td style="width:0.5%;"> 1</td>
                            <td style="width:5%"> test testete</td>
                            <td style="width:7%;"> 3443434334</td>
                            <td style="width:4%;"> Male</td>
                            <td style="width:4%;"> test</td>
                            <td style="width:9%;"> N</td>
                            <td> N</td>
                            <td style="width: 5.4%;"> Agree</td>
                            <td> N</td>
                            <td> N</td>
                            <td> N</td>
                            <td> Disagree</td>
                            <td> N</td>
                            <td> N</td>
                            <td> N</td>
                            <td> N</td> -->


                            <td style="width:0.5%;">{{i+1}}</td> 
                            <td style="width:5%"> {{(grid.citizenName== null) ? 'N' :(grid.citizenName)}}</td>
                            <td style="width:7%;">{{grid.mobileNumber}}</td>
                            <td style="width:4%;">{{grid.gender}}</td>
                            <td style="width:9%;">{{(grid.empStatus ==null) ? 'N' :(grid.empStatus)}}</td>
                            <td></td>
                            <td>{{(grid.incomeTax == null) ? 'N' :(grid.incomeTax)}}</td>
                          
                            <td> </td>
                            
                            <td style="width: 5.4%;"> {{(grid.fourWheeler == null) ? 'N':(grid.fourWheeler)}}</td>
                            <td></td>
                            <td>{{(grid.wetLand == null) ? 'N' :(grid.wetLand ) + (grid.dryLand == null) ? 'N' :(grid.dryLand)}}</td>
                            <td></td>
                            <td>{{(grid.cdma == null) ? 'N' :(grid.cdma )}}</td>
                            <td></td>
                            <td>{{(grid.energyLast6MonthsUnits == null) ? 'N' :(grid.energyLast6MonthsUnits)}}</td>
                            <td> </td>
                           
                       
                        </tr>
                </table>
            </div>

            <br> <br> <br>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th class="spacw30">FOUR WHEELER</th>
                            <th class="spacw10">Please Tick (✔)</th>
                            <th class="spacw30">FAMILY LAND HOLDINGS</th>
                            <th class="spacw10">Please Tick (✔)</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                1.The Personal Vehicle not in thename of applicant / familymembers.
                            </td>
                            <td></td>
                            <td>1.The extents of land details are not correct.</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                2.The Taxi vehicle in the name ofapplicant / family members.
                            </td>
                            <td></td>
                            <td>2.The land details not updated and the totalextent of the land is less than 3.00 Ac.Wet
                                (or) 10 Ac. Dry or both together 10Ac.</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                3.The Vehicle was in the name ofthe applicant / family memberbut sold to other person
                                notupdated in the RTA database
                            </td>
                            <td></td>
                            <td>3. Applicant or any Family Member do notown any land.</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                4.The Vehicle never belonged toapplicant/family member andRTA database is not updated.
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <br>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th class="spacw30">ELECTRICTY CONSUMPTION</th>
                            <th class="spacw10">Please Tick (✔)</th>
                            <th class="spacw30">MUNCIPAL PROPERTY DETAILS</th>
                            <th class="spacw10">Please Tick (✔)</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                1.The meter connection is on the name of applicant /family members & average electricity
                                consumptionfor last six months is less than 300 units.
                            </td>
                            <td></td>
                            <td>1. Applicant having property less than 1000Sq.ft. with assessment no._____________.</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                                2.The meter connection is on the name of the propertyowner, but the average consumption
                                of the applicantfor the last six months is less than 300 units.
                            </td>
                            <td></td>
                            <td>2. Ancestral property . The Applicant islegal heir and would get less than 1000Sq.Ft. if
                                mutated / sub divided.</td>
                            <td></td>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
<!-- </body>
</html> -->
</div>