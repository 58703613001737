import { Component, ElementRef, ViewChild } from "@angular/core";
import { CommonService } from "src/shared/common.service";
import { CommonConstants } from '../../constants/common-constants.component';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbNavConfig, NgbModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { fileExtensionValidator, fileSizeValidator } from "src/shared/validators/file-validator";
import { CommonMsgModalForDeleterecordComponent } from "src/shared/common-msg-modal-for-deleterecord/common-msg-modal-for-deleterecord.component";
import { RadioMsgModalComponentComponent } from "src/shared/radio-msg-modal-component/radio-msg-modal-component.component";
import { CommonUploadFileComponentComponent } from "src/shared/common-upload-file-component/common-upload-file-component.component";
import { CommonMsgModalComponent } from "src/shared/common-msg-modal/common-msg-modal.component";

@Component({
  selector: 'app-village-mapping-in-tahsildhar',
  templateUrl: './village-mapping-in-tahsildhar.component.html',
  styleUrls: ['./village-mapping-in-tahsildhar.component.css']
})
export class VillageMappingInTahsildharComponent  {
    isAllSelected:boolean = false;
    items: any[] = [];
  commonConstants: any = CommonConstants;
  checkboxSelectId: any;
  userInfos: any;
  saveresponseData: any;
  mroapprovedData: any;
  deletedData: any;
  rvvid: any;
  villagemappingteh!: FormGroup;
  selectedCheckboxes: number[] = [];
  constructor(private commonService: CommonService, private modalService: NgbModal,
    private router: Router,
    private formBuilder: FormBuilder) { 
      let userData: any = localStorage.getItem('userInfo');
      this.userInfos = JSON.parse(userData);
      this.saveMappedData();
      this.approvedRecords();
      this.villagemappingteh = this.formBuilder.group({
        villageids: new FormControl()
      })    
    }

    secretariatcode: any;

  saveMappedData(){
    this.commonService.getRequest(this.commonConstants.villagemappingTahsildhar + '?Flag=' + 'GET SECRETARIAT').subscribe((responseData: any) => {
    
      if(responseData?.result?.isSuccess) {
        this.villageArray = [];
        this.selectedCheckboxes = [];
        this.saveresponseData = responseData?.result?.rvVillageSecMapTehsildarData;
        this.secretariatcode = responseData?.result?.rvVillageSecMapTehsildarData[0]?.secretariaT_CODE;
      }
      else {
        this.saveresponseData = [];
      }
    })
  }

  mroApproved() {
    if(this.selectedCheckboxes?.length == 0) {
      this.commonService.commonErrorPopup('Alert', 'Please select atleast one record before you approve', 'md');
    }
    else {
      const modalRef = this.modalService.open(RadioMsgModalComponentComponent, {size: 'md'});
      modalRef.componentInstance.title = 'Alert';
      modalRef.result.then((result: any) => {
        if (result == 'Y') {
          const modalRef = this.modalService.open(CommonUploadFileComponentComponent, {size: 'md'});
          modalRef.componentInstance.title = 'Alert';
          modalRef.result.then((result: any) => {
            if(result == 'Y') {
              this.commonService.getRequest(this.commonConstants.villagemappingTahsildhar + '?Flag=' + 'MRO APPROVED' + '&Village=' + this.selectedCheckboxes + '&Secretariat=' + this.secretariatcode + '&TehsildarAction=Approved'  + '&SubRegisterAction=' + result + '&SubRegisterSign=' + this.commonService.filepath).subscribe((responseData: any) => {
                this.saveMappedData();
                this.approvedRecords();
                if(responseData?.result?.isSuccess) {
                  this.mroapprovedData = responseData?.result;
                  this.villageArray = [];
                  this.selectedCheckboxes = [];
                  this.villagemappingteh.reset();
                  this.commonService.commonErrorPopup('Alert', 'The selected village is approved successfully by sub-registrar.', 'md')
                }
                else if(responseData?.result?.isSuccess == false && responseData?.result?.error) {
                  this.villageArray = [];
                  this.selectedCheckboxes = [];
                  this.villagemappingteh.reset();
                  this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
                  this.saveMappedData();
                  this.approvedRecords();
                }
                else {
                  this.commonService.commonErrorPopup('Alert', 'An error occured while approving the village. Please try again.', 'md')
                }
              }
              )}
            
          })}
          else {
            this.commonService.getRequest(this.commonConstants.villagemappingTahsildhar + '?Flag=' + 'MRO APPROVED' + '&Village=' + this.selectedCheckboxes + '&Secretariat=' + this.secretariatcode + '&TehsildarAction=Approved'  + '&SubRegisterAction=N').subscribe((responseData: any) => {
              if(responseData?.result?.isSuccess) {
                this.commonService.commonErrorPopup('Alert', 'The selected village is not approved by sub-registrar', 'md');
                this.villageArray = [];
                this.selectedCheckboxes = [];
                this.villagemappingteh.reset();
              }
              else if(responseData?.result?.isSuccess == false && responseData?.result?.error) {
                this.villageArray = [];
                this.selectedCheckboxes = [];
                this.villagemappingteh.reset();
                this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md')
              }
              else {
                  this.commonService.commonErrorPopup('Alert', 'Unable to approve the village. please try again.', 'md');
              }
            })
          }
          })
    }
   }

  getresponseData: any = [];
  getDropdownDataResult: any = [];
  checkboxid: any;
  checkboxidarray: any = [];
  checkboxidarrayvalues: any = [];

  get f() {
     return this.villagemappingteh.controls
  }

  villageArray: any = [];
  checkboxchecked: any;

  onCheckClick(event: any) {
    this.checkboxchecked = event.target.checked;
    this.checkboxid = event.target.id;
    this.checkboxSelectId = event.target.value;
    this.checkboxidarray = this.villageArray.push(this.checkboxid);
  }

  selectRecord() {
    this.isAllSelected = this.items.every(function(item:any) {
      return item.isSelected == true;
    })
  }

  goToHome() {
    this.router.navigate(['/home']);
  }

  updateSelectedCheckboxes(index: number) {
    if(this.selectedCheckboxes.includes(index)) {
      this.selectedCheckboxes = this.selectedCheckboxes.filter(i => i!== index);
    }
    else {
      this.selectedCheckboxes.push(index);
    }
  }

  deleteRecord() {
    if(this.selectedCheckboxes.length == 0) {
      this.commonService.commonErrorPopup('Alert', 'Please select atleast one record before you delete', 'md');
    }
    else {
    this.checkboxchecked == true;
    const modalRef = this.modalService.open(CommonMsgModalForDeleterecordComponent, {size: 'md'});
      modalRef.componentInstance.title = 'Alert';
      modalRef.componentInstance.message = 'Are you sure you want to delete record ?';
      modalRef.result.then((result: any) => {
        if (result == 'Yes') {
          this.commonService.getRequest(this.commonConstants.villagemappingTahsildhar + '?Flag=' + 'DELETE' + '&Village=' + this.selectedCheckboxes + '&Secretariat=' + this.secretariatcode).subscribe((responseData: any) => {
            this.saveMappedData();
            this.approvedRecords();
            if(responseData?.result?.isSuccess) {
              this.deletedData = responseData?.result;
              this.selectedCheckboxes = [];
              this.villageArray = [];
              this.villagemappingteh.reset();
              this.commonService.commonErrorPopup('Alert','The selected Villages deleted successfully', 'md');
            }
            else if(responseData?.result?.isSuccess == false) {
              this.villageArray = [];
              this.selectedCheckboxes = [];
              this.villagemappingteh.reset();
              this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
            }
            else {
              this.commonService.commonErrorPopup('Alert', 'An error occured while deleting the data. Please try again.', 'md')
            }
          })
        }
      })
    }       
  }
  
  onRowClick(item:any){
    item.isSelected = !item.isSelected
  }

  approvedRecordsData: any = [];

  approvedRecords() {
    this.commonService.getRequest(this.commonConstants.villagemappingTahsildhar + '?Flag=' + 'GET APPROVED SECRETARIAT' + '&UserID=' + this.userInfos.userPrincipalName).subscribe((responseData: any) => {
      if(responseData?.result?.isSuccess) {
        this.approvedRecordsData = responseData?.result?.rvVillageSecMapTehsildarData;
        // this.saveMappedData();
      }
      else {
        this.approvedRecordsData = [];
      }
    })
  }
}
