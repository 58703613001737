<div class="container-fluid mainCls">        
    <div class="breadcrumbContainer  mr-3">        
        <div class="breadcrumbCls">
            GSWS / Application Submission <span class="activePage">/Suraksha Token Registration
                </span>
        </div>
    </div>
    <div *ngIf="isServiceAllowed;else message_content" class="mainPageContainer mr-3">
        <div class="btnHolder" *ngIf="loggedinuserName === 'Village Revenue Officer'
        || loggedinuserName === 'Panchayat Secretary'
        || loggedinuserName === 'Ward Admin Secretary'
        || loggedinuserName === 'Welfare & Educational Assistant'
        || loggedinuserName === 'Ward Revenue Secretary'
        || loggedinuserName === 'Ward Welfare & Development Secretary'
        " >
        <button class="float-right greenBtnCls homebtn mr-3" (click)="goToHome()">Home</button>
            </div>
        <h6 class="mb-3">Suraksha Token Registration</h6>
        <form [formGroup]="TokenRegistrationForm">
                        <div>
                            <div class="row mb-3">
                                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <label class="labelCls">Aadhaar Number: <span class="mandatoryCls">*</span></label>
                                    <input type="text" name="aadharNo" formControlName="aadharNo" class="inputCls width75" (blur)= "aadharencrypt($event)" appBlockCopyPaste required numbersOnly
                                    [ngClass]="{ 'is-invalid':isSubmitted && f.aadharNo.errors }" [readonly] ="isAadharPrefilled" maxlength="12" minlength="12">
                                    <button class="btn btn-primary float-right" (mousedown)="getCitizenInfo()">Pre-Fill</button>
                                    <div *ngIf="isSubmitted && f.aadharNo.errors" class="invalid-feedback">
                                      <div *ngIf="f.aadharNo.errors.required || f.aadharNo.errors.whiteSpaceValidate">Aadhaar Number is required</div>
                                      <div *ngIf="f.aadharNo.errors.aadharValidate || f.aadharNo.errors.minlength || f.aadharNo.errors.maxlength">Please enter valid Aadhaar Number</div>
                                  </div>
                                </div>
                                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-4">
                                    <button  class="btn btn-primary resetButton" (click)="resetForm()">Reset</button>
                                </div>
                            </div>
                            <ng-container *ngIf="isAadharPrefilled">
                                <div class="row mb-3">
                                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <label class="labelCls">Department:</label>
                                        <select class="selectCls" [(ngModel)]="department" [ngModelOptions]="{standalone: true}"
                                            (change)="onDepartmentChange($event.target)" appBlockCopyPaste>
                                            <option  [ngValue]= "null" disabled>Select</option>
                                            <option *ngFor="let item of departmentList" [ngValue]="item">{{item.name}}
                                        </select>      
                                    </div>                            
                                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                        <label class="labelCls">Service:</label>
                                        <select class="selectCls" [(ngModel)]="service" [ngModelOptions]="{standalone: true}"
                                            appBlockCopyPaste>
                                            <option  [ngValue]= "null" disabled>Select</option>
                                            <option *ngFor="let item of serviceList" [ngValue]="item">{{item.name}}
                                        </select>      
                                    </div>
                                    <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                        <div class="btnHolder mt-4">
                                            <button class="greenBtnCls" (click)="addService()">Add Service</button>
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngIf="services.length > 0">
                                    <div class="row mb-2">
                                        <div class="col">
                                            <div class="tab table-responsive">
                                                <hr class="hrCls mt-4">
                                                <h6 class="mb-3">Services: </h6>
                                                <table class="tableCls">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <input type="checkbox" id="chkBBox" [(ngModel)]="isAllSelected" [ngModelOptions]="{standalone: true}"
                                                                (change)="checkUncheckAll()" /> All
                                                            </th>
                                                            <th>Sl.No.</th>
                                                            <th>Department</th>
                                                            <th>Service</th>
                                                            <th>Has citizen furnished all the mandatory documents?</th>
                                                            <!-- <th>Action</th> -->
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of services; let i = index">
                                                            <td>
                                                                <input type="checkbox" (change)="selectService()" [(ngModel)]="item.isSelected" [ngModelOptions]="{standalone: true}"/>
                                                            </td>
                                                            <td> {{ i + 1 }}</td>
                                                            <td> {{ item.departmentName }}</td>
                                                            <td> {{ item.serviceName }}</td>
                                                            <td>
                                                                <input type="radio" name="{{'isAllDocuments'+i}}" value="Yes" [(ngModel)]="item.isAllDocuments" [ngModelOptions]="{standalone: true}"><span class="mx-2 radioCls">Yes</span>
                                                                <input type="radio" name="{{'isAllDocuments'+i}}" value="No" [(ngModel)]="item.isAllDocuments" [ngModelOptions]="{standalone: true}"><span class="mx-2 radioCls">No</span>
                                                                <div *ngIf="submitted && item.isAllDocuments==null" class="selectError">
                                                                    <div>Please select value.</div>
                                                                </div>        
                                                            </td>
                                                            <!-- <td>
                                                                <button class="btn btn-primary mr-3 add-new" (click)="deleteService(i)">Delete</button>
                                                            </td> -->
                                                        </tr>                                                
                                                    </tbody>
                                                </table>
                                            </div>                                        
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col">
                                            <button class="btn btn-primary mr-3 add-new" (click)="deleteServices()">Delete Services</button>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-sm-12">                                
                                            <label class="labelCls" for="familyForm">
                                            Note: Service token will be generated for only those services for which citizen has furnished all the mandatory documents.
                                            </label>
                                        </div>
                                    </div>
                                    <hr class="hrCls mt-4">
                                    <div class="subHeadingCls mb-3">Declaration</div>
                                    <div class="row mb-3">
                                        <div class="ml-4 col-sm-9">
                                            <input class="form-check-input " formControlName="familyForm" required
                                                type="checkbox" value="" id="familyForm"
                                                [ngClass]="{ 'is-invalid':submitted && f.familyForm.errors }">
                                            <label class="labelCls" for="familyForm">The above information is true to the best
                                                of my knowledge. I agree to share my Aadhaar details of self and family with
                                                Government of Andhra Pradesh. I am aware that declaration of wrong information
                                                will entail any disciplinary action against me.:<span
                                                    class="mandatoryCls">*</span> </label>
                                            <div *ngIf="submitted && f.familyForm.errors" class="invalid-feedback">
                                                <div *ngIf="f.familyForm.errors.required">Please Check Note</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btnHolder">
                                        <button class="greenBtnCls float-right" (click)="submit()">Submit</button>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
        </form>
    </div>
    <ng-template #message_content>
        <div class="mainPageContainer mr-3">
            <div class="btnHolder">
                <button class="float-right greenBtnCls homebtn mr-3" *ngIf="loggedinuserName === 'Village Revenue Officer'
                || loggedinuserName === 'Panchayat Secretary'
                || loggedinuserName === 'Ward Admin Secretary'
                || loggedinuserName === 'Welfare & Educational Assistant'
                || loggedinuserName === 'Ward Revenue Secretary'
                || loggedinuserName === 'Ward Welfare & Development Secretary'
                " (click)="goToHome()">Home</button>
            </div>
            <div class="subHeadingCls mb-3 textcenter">
                <h4> {{ serviceNotAllowedMessage }} </h4>
            </div>
        </div>
    </ng-template>
</div>