import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import * as XLSX from 'xlsx';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from '@techiediaries/ngx-qrcode';

import {
  CustomAdapter,
  CustomDateParserFormatter,
} from '../../shared/common.service';

@Component({
  selector: 'app-payment-challan-history',
  templateUrl: './payment-challan-history.component.html',
  styleUrls: ['./payment-challan-history.component.css'],
  providers: [
    NgbNavConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe,
  ],
})
export class PaymentChallanHistoryComponent implements OnInit {
  listOfTrasaction: any[] = [];
  listofTransactionDownload :any[]=[];
  commonConstants: any = CommonConstants;
  userInfo: any = [];
  trasactionData: any[] = [];
  title: string = '';
  closeResult: string = '';

  ViewTransactions: FormGroup;
  TomaxDate: any;
  TominDate: any;
  FrommaxDate: any;
  FromminDate: any;
  
  currentDate = new Date();
  public submitted: Boolean = false;
  public showDetails: Boolean = false;
  public commonFormSubmitted: Boolean = false;
  isCitizen:Boolean = false;
  items: any[] = [];
  itemswithoutPagination:any[]=[];
  challanData: any[] = [];
  page = 1;
  pageSize = 10;
  transactionFilterID: string = '';
  tempItems: any = [];
  currentDateFormat:any
  anmtInWords:any = "";

  newChallanFunction:boolean = false;
  totalPageCount: number = 0;
  secreteriateCode:any;
  barcodeValue: any;
  challanServiceType: any;
  challanTypeActive: any;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.LOW;
  isProd: Boolean = false;
  isLoginPresent: Boolean = false;
  constructor(
    private commonService: CommonService,
    private modalService: NgbModal,
    private config: NgbNavConfig,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private router: Router,
    private currentRoute: ActivatedRoute
  ) {

    const hostname = window && window.location && window.location.hostname;
    //if (/^.*localhost.*/.test(hostname)) {
    /*  if (/^vswsonline.ap.gov.in/.test(hostname)) {
      this.newChallanFunction = true;
    }else{
      this.newChallanFunction = false;
    } */

    if (/^vswsonline.ap.gov.in/.test(hostname)) {
      this.isProd = true;
    }else{
      this.isProd = false;
    }

    this.currentRoute.queryParams.subscribe((params: any) => {
      if(params.type){
        //console.log('type......',params.type);
        this.challanServiceType = params.type;
        let userData = localStorage.getItem('userInfo');
        if (userData && userData !== null) {
           this.userInfo = JSON.parse(userData);
           let userId = this.userInfo.userPrincipalName?.split('-');
           if (userId?.length > 0) {
             let loginIds: any = ['12345678'];
             let secretariatCode = userId[0]?.charAt(0)?.toUpperCase() === 'U' ? userId[0]?.split('U')[1] : userId[0];
             this.isLoginPresent = loginIds.includes(secretariatCode);
           }
           this.isCitizen = (this.userInfo.jobTitle === 'Citizen' || this.userInfo.jobTitle === 'Meeseva') ? true : false;
            if(params.type === 'ots'){
              this.challanTypeActive = true;
            }else{
              this.challanTypeActive = false;
            }
        }
      }
    });


    let userData = localStorage.getItem('userInfo');
    if (userData && userData !== null) {
      this.userInfo = JSON.parse(userData);
      //this.getUserWiseChallanList(this.userInfo.userPrincipalName);
    }

    this.TomaxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
    this.TominDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };

    this.FrommaxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
    this.FromminDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };


    
    this.ViewTransactions = this.formBuilder.group({
      periodFrom: [null, [Validators.required]],
      periodTo: [null, [Validators.required]],
      department_name: [], //[null, [Validators.required]],
      // transactionID: ['', [Validators.required]],
    });

    config.destroyOnHide = false;
    config.roles = false;
  }

  ngOnInit(): void {
    this.secreteriateCode =localStorage.getItem('locationCode');
    if(this.newChallanFunction == false){
        this.currentDateFormat = this.currentDate.getDate()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getFullYear();


        let curntDateFormat = this.currentDate.getFullYear()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getDate();
    
        this.ViewTransactions.patchValue({
          department_name:'all',
          periodFrom: this.currentDateFormat,
          periodTo: this.currentDateFormat,
        });

        this.submit('pagination');
    }

  }

  get f() {
    return this.ViewTransactions.controls;
  }

  getUserWiseChallanList(userId: any) {
    this.commonService
      .getRequest(this.commonConstants.getUserWiseChallanList + userId)
      .subscribe({
        next: (responseData: any) => {
          console.log('service client side', responseData);
          this.listOfTrasaction = responseData.result.reverse();
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  transactionPopup(content: any, challanId: any, challanStatus: any) {
    this.trasactionData = [];
    let inputString = '';

    if (challanStatus === 'Fail') {
      inputString = 'ChallanId=' + challanId;
      let APIcall = ""
      if(this.challanTypeActive === true){
        APIcall = this.commonConstants.getFailedChallanOTSTransactions;
      }else{
       APIcall = this.commonConstants.getFailedChallanTransactions;
      }

      this.commonService
        .getRequest(
          APIcall + inputString
        )
        .subscribe({
          next: (responseData: any) => {
            console.log('service client side', responseData);

            //this.trasactionData = responseData.result;

            responseData.result.forEach((element: any) => {
              let temp: any = {};
              temp.transactionId = element.transactionId;
              temp.serviceName = element.serviceName;
              temp.transactionDate = element.transactionDate;
              temp.transactionAmount = element.transactionAmount;
              temp.dept_App_No = (this.challanTypeActive === true ? '' : (element.dept_App_No ? element.dept_App_No : ''));
              temp.transactionFrom = (element.source == "OLD"? "OLD": "NEW");
              this.trasactionData.push(temp);
            });

            this.modalService
              .open(content, {
                size: 'lg',
                ariaLabelledBy: 'modal-basic-title',
                windowClass: 'app-modal-window',
              })
              .result.then(
                (result) => {
                  this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                  this.closeResult = `Dismissed ${this.getDismissReason(
                    reason
                  )}`;
                }
              );
          },
          error: (error) => {
            console.log('client side', error);
          },
        });
    } else {
      inputString =
        'UserId=' + this.userInfo.userPrincipalName + '&ChallanId=' + challanId;
        let APIcall = "";
        if(this.challanTypeActive === true){
          APIcall = this.commonConstants.getOTSTransactionsByChallanId;
        }else{
          inputString += '&Secretariat_Code=' + this.secreteriateCode;
         APIcall = this.commonConstants.GetCommonTransactionsByChallanId;
        }

        
      this.commonService
        .getRequest(
          APIcall + inputString
        )
        .subscribe({
          next: (responseData: any) => {
            console.log('service client side', responseData);

            // this.trasactionData = responseData.result;
            responseData.result.forEach((element: any) => {
              let temp: any = {};
              temp.transactionId = element.transactionId;
              temp.serviceName = element.serviceName;
              temp.transactionDate = element.transactionDate;
              temp.transactionAmount = element.transactionAmount;
              temp.dept_App_No = element.dept_App_No;
              temp.transactionFrom = (element.source == "OLD"? "OLD": "NEW");
              this.trasactionData.push(temp);
            });

            if(this.challanTypeActive === false && this.trasactionData.length <=0){
              this.getTransactionsByChallanId('UserId=' + this.userInfo.userPrincipalName + '&ChallanId=' + challanId);
            }

            this.modalService
              .open(content, {
                size: 'lg',
                ariaLabelledBy: 'modal-basic-title',
                windowClass: 'app-modal-window',
              })
              .result.then(
                (result) => {
                  this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                  this.closeResult = `Dismissed ${this.getDismissReason(
                    reason
                  )}`;
                }
              );
          },
          error: (error) => {
            console.log('client side', error);
          },
        });
    }
  }

  getTransactionsByChallanId(inputString: any) {
    this.trasactionData = [];
   
    this.commonService
      .getRequest(
        this.commonConstants.getTransactionsByChallanId + inputString
      )
      .subscribe({
        next: (responseData: any) => {
          console.log('service client side', responseData);
          responseData.result.forEach((element: any) => {
            let temp: any = {};
            temp.transactionId = element.transactionId;
            temp.serviceName = element.serviceName;
            temp.transactionDate = element.transactionDate;
            temp.transactionAmount = element.transactionAmount;
            temp.dept_App_No = element.dept_App_No;
            temp.transactionFrom = (element.source == "OLD"? "OLD": "NEW");
            this.trasactionData.push(temp);
          });

          if(this.challanTypeActive === false){
            this.getoldPortalTransactionsByChallanId(inputString);
          }          
        },
        error: (error) => {
          console.log('client side', error);
        },
      });    
  }

  getoldPortalTransactionsByChallanId(inputData: any) {
    this.commonService
      .getRequest(
        this.commonConstants.getoldPortalTransactionsByChallanId + inputData,
        'MAUD'
      )
      .subscribe({
        next: (responseData: any) => {
          console.log('service client side', responseData);
          responseData.result.forEach((element: any) => {
            let temp: any = {};
            temp.transactionId = element.app_No;
            temp.serviceName = element.service_Desc;
            temp.transactionDate = element.request_Date;
            temp.transactionAmount = element.transaction_Amount;
            temp.dept_App_No = element.dept_App_No;
            temp.transactionFrom = 'OLD';
            this.trasactionData.push(temp);
          });
          this.getMeesevaTransactionsByChallanId(inputData)
          console.log(this.trasactionData, 'this.trasactionData');
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  getMeesevaTransactionsByChallanId(inputData: any) {
    this.commonService.getRequest(this.commonConstants.getMesevaTransactionByChallanId + inputData).subscribe({
      next: (responseData: any) => {
        console.log('service client side', responseData);
        responseData.result.forEach((element: any) => {
          let temp: any = {};
          temp.transactionId = element.transactionId;
          temp.serviceName = element.serviceName;
          temp.transactionDate = element.transactionDate;
          temp.transactionAmount = element.totalAmount;
          temp.dept_App_No = '';
          temp.transactionFrom = 'NEW';
          this.trasactionData.push(temp);
        });
        console.log(this.trasactionData, 'this.trasactionData');
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

 

  submit(type:any, value?:any): any {
    
    this.submitted = true;
    this.ViewTransactions.controls['department_name'].clearValidators();
    this.ViewTransactions.controls['department_name'].updateValueAndValidity();
    
    
    //if (this.f['transactionID'].value) {
      this.ViewTransactions.controls['periodFrom'].clearValidators();
      this.ViewTransactions.controls['periodTo'].clearValidators();
      this.ViewTransactions.controls['periodFrom'].updateValueAndValidity();
      this.ViewTransactions.controls['periodTo'].updateValueAndValidity();
   /*  }
    if (this.f['periodFrom'].value && this.f['periodTo'].value) {
      this.ViewTransactions.controls['transactionID'].clearValidators();
      this.ViewTransactions.controls['transactionID'].updateValueAndValidity();
    } */

    if (!this.ViewTransactions.valid) {
      return false;
    } else {
      let postData = '';
     
     /*  if (this.f['transactionID'].value) {
        postData ='?TransactionID=' +this.f['transactionID'].value +'&Userid=';
        postDataForOld = '?TransactionID=' + this.f['transactionID'].value;
      } else { */
        
        let formDateFormat = this.commonService.formatDate(
          this.f['periodFrom'].value
        );
        let toDateFormat = this.commonService.formatDate(
          this.f['periodTo'].value
        );


        this.dateCompare(formDateFormat.split('T')[0],toDateFormat.split('T')[0] , type , value)
        
       /*  let paymentModeSelected = this.f['department_name'].value == 'all' ? '': this.f['department_name'].value;
        postData =this.userInfo.userPrincipalName+'&FromDate=' +formDateFormat.split('T')[0] +'&ToDate=' +toDateFormat.split('T')[0] +'&PaymentMode='+paymentModeSelected; */
      //}

      //this.getUserWiseChallanList_new(postData)
    }
  }


  dateCompare(d1:any, d2:any, type:any, value?:any){
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    let postData = '';
    if(date1 > date2){
       // console.log(`${d1} is greater than ${d2}`)

            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = 'Please select valid date range(ToDate should be greater than or equal to FromDate) !!';
              
    } else{

      let paymentModeSelected = this.f['department_name'].value == 'all' ? '': this.f['department_name'].value;
      postData =(this.isCitizen ===  true ? this.userInfo.userPrincipalName : '') +'&Secretariat_Code=' + (this.isCitizen ===  false ?   this.secreteriateCode : '') +'&FromDate=' +d1 +'&ToDate=' +d2 +'&PaymentMode='+paymentModeSelected+ '&PageIndex=' + this.page + '&PageSize=' + this.pageSize;
if(type === 'withOutPagination'){
  postData =(this.isCitizen ===  true ? this.userInfo.userPrincipalName : '') +'&Secretariat_Code=' + (this.isCitizen ===  false ?   this.secreteriateCode : '') +'&FromDate=' +d1 +'&ToDate=' +d2 +'&PaymentMode='+paymentModeSelected+ '&PageIndex=' + '1' + '&PageSize=' + this.totalPageCount;  
}
      if(this.challanTypeActive === true){
        this.getUserWiseChallanList_new_OTS(postData, type, value);
      }else{
        this.getUserWiseChallanList_new(postData, type , value);
      }
 
    }
    
    
    /* if(date1 < date2){
        //console.log(`${d2} >>>>>>is greater than ${d1}`)
    } else{
        //console.log(`Both dates are equal`)
    } */
}

  getUserWiseChallanList_new(postData:any , type?:any , value?:any) {    
    this.commonService
      .getRequest(this.commonConstants.Pagginated_GetUserWiseChallanList + postData)
      .subscribe({
        next: (responseData: any) => {

          this.showDetails = true;
          console.log('service client side', responseData);
          this.totalPageCount = responseData.result?.totalResultsCount;
          if(type === 'pagination'){
            this.items = [];
            this.listOfTrasaction = [];
            this.listOfTrasaction = responseData.result?.pagedList?.reverse();
            this.page = responseData.result?.currentPage;
            this.listOfTrasaction.forEach((element: any,id:any) => {         
              let temp: any = {};         
            
             
                temp.id = id;
                temp.challanId = element.challanId;
                temp.challanStatus = element.challanStatus;
                temp.noOfTransactions = element.noOfTransactions;
                temp.challanDate = element.challanDate;
                temp.paymentGateway = element.paymentGateway;
                temp.paymentGateway = element.paymentGateway;
                temp.challanAmount = element.challanAmount;
                temp.beneficiaryAccountNo = element.beneficiaryAccountNo;
                this.items.push(temp);
              
            });

          }
           if(type === 'withOutPagination') {
            
             this.listofTransactionDownload=[];    
             this.itemswithoutPagination=[];
             this.listofTransactionDownload  = responseData.result?.pagedList?.reverse();  
             this.listofTransactionDownload.forEach((element: any,id:any) => {
              let tempDownload:any={};            
                tempDownload.id = id;
                tempDownload.challanId = element.challanId;
                tempDownload.challanStatus = element.challanStatus;
                tempDownload.noOfTransactions = element.noOfTransactions;
                tempDownload.challanDate = element.challanDate;
                tempDownload.paymentGateway = element.paymentGateway;
                tempDownload.paymentGateway = element.paymentGateway;
                tempDownload.challanAmount = element.challanAmount;
                tempDownload.beneficiaryAccountNo = element.beneficiaryAccountNo;
                this.itemswithoutPagination.push(tempDownload);            
  
              
            });
if(this.itemswithoutPagination.length >0){
  if(value === 'downloadExcel'){
    this.exportexcel();
  }else{
    setTimeout(()=> {
      this.printReceipt(value);
    },3000)
  }
}
            

           }
         
         // this.page = responseData.result?.currentPage;
          

         
         
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  getUserWiseChallanList_new_OTS(postData:any , type?:any , value?:any) {

   
    
    this.commonService
      .getRequest(this.commonConstants.Pagginated_GetUserWiseOTSChallanList + postData)
      .subscribe({
        next: (responseData: any) => {

          this.showDetails = true;
          console.log('service client side', responseData);
          //this.listOfTrasaction = responseData.result.reverse();
         
          
         
         
          //this.page = responseData.result?.currentPage;
          this.totalPageCount = responseData.result?.totalResultsCount;

          if(type === 'pagination'){
            this.listOfTrasaction = [];
            this.items = [];
            this.listOfTrasaction = responseData.result?.pagedList?.reverse();
            this.page = responseData.result?.currentPage;
            this.listOfTrasaction.forEach((element: any,id:any) => {
         
              let temp: any = {};
             
            
                temp.id = id;
                temp.challanId = element.challanId;
                temp.challanStatus = element.challanStatus;
                temp.noOfTransactions = element.noOfTransactions;
                temp.challanDate = element.challanDate;
                temp.paymentGateway = "ICICI_CHALLAN";
                temp.challanAmount = element.challanAmount;
                temp.beneficiaryAccountNo = element.beneficiaryAccountNo;
                
                this.items.push(temp);
               
             
             
            });
          }
          if(type === 'withOutPagination') {
            this.itemswithoutPagination=[];
            this.listofTransactionDownload=[];
            this.listofTransactionDownload  = responseData.result?.pagedList?.reverse();   
            this.listofTransactionDownload.forEach((element: any,id:any) => {
         
              let tempDownload:any={};
            
                tempDownload.id = id;
                tempDownload.challanId = element.challanId;
                tempDownload.challanStatus = element.challanStatus;
                tempDownload.noOfTransactions = element.noOfTransactions;
                tempDownload.challanDate = element.challanDate;
                tempDownload.paymentGateway = "ICICI_CHALLAN";
                tempDownload.challanAmount = element.challanAmount;
                tempDownload.beneficiaryAccountNo = element.beneficiaryAccountNo;
              
              this.itemswithoutPagination.push(tempDownload);
  
          
             
             
            });
if(this.itemswithoutPagination.length >0){
  if(value === 'downloadExcel'){
    this.exportexcel();
  }else{
    setTimeout(()=> {
      this.printReceipt(value);
    },3000)
    
  }
}
       
          }

          console.log(this.items)
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }

  searchTransaction() {
    let value = this.transactionFilterID.trim()?.toLowerCase();

    if (value !== null && value !== '') {
      let filteredItems = this.tempItems.filter(
        (item: any) =>
          item.transactionID?.toLowerCase().match(value) ||
          item.serviceName?.toLowerCase().match(value) ||
          item.applicantName?.toLowerCase().match(value)
      );

      this.items = filteredItems;
      this.page = 1;
    } else {
      this.items = this.tempItems;
    }
  }

  showReceipt(content:any, challan_ID:any){
    
    this.challanData = [];
    this.anmtInWords = "";
    this.listOfTrasaction.forEach((element: any,id:any) => {
      if(element.challanId === challan_ID){
        this.challanData.push(element);
      }
    });
    this.anmtInWords = this.intToEnglishWordConverter(this.challanData[0].challanAmount);
    this.barcodeValue = JSON.stringify(this.challanData);
    console.log(this.challanData)

    this.modalService
    .open(content, {
      size: 'md',
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'app-modal-window',
    })
    .result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(
          reason
        )}`;
      }
    );

  }


  printReceipt(printId: any) {
    let Pagelink = 'about:blank';
    var pwa = window.open(Pagelink, '_new');
    pwa?.document.open();

    if( printId === '__printingFrame'){
      pwa?.document.write(this.preparePrintFormatForReceipt(printId));
    }else{
      pwa?.document.write(this.preparePrintFormatFotTable(printId));
    }
    pwa?.document.close();
  }

  preparePrintFormatFotTable(printId: any) {
    const printContent: HTMLElement | null = document.getElementById(printId);
    let htmlData: any = '';
    let printdata = printContent?.innerHTML;
    htmlData +=
      '<html><head><title>Receipt</title> <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">';
    htmlData +=
      "<style type='text/css' media='print'> @media print{@page{size: A4 !important;margin: 11mm 17mm 17mm 17mm !important; }  table td, table th{ padding:10px !important; border:0.5px solid #ccc !important; } .ptCls{ margin:50px !important;} table {width:90% !important; margin:0 auto !important;} .btnHide{display:none !important;} .headCls{ text-align:center !important; padding :10px 0px !important;}</style> <script>function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'>";

    if (printId === 'transPoup') {
      htmlData += "<div class='headCls'><h3>Transaction Details<h3><br><div>";
    }

    htmlData += printdata;
    htmlData += '</body></html>';

    return htmlData;
  }

  
  preparePrintFormatForReceipt(printID: any) {
    const printContent: HTMLElement | null = document.getElementById(printID);
    let htmlData: any = '';
    let printdata = printContent?.innerHTML;
    htmlData +=
      '<html><head><title>ChallanPrint</title> <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">';
      htmlData +=
        "<style type='text/css' media='print'> @media print{@page{size: auto; margin: 0;} table{ width: 80% !important; margin : 0 auto !important;} table th ,table td{border:none!important;}</style> <script>function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'>";
    htmlData += printdata;
    htmlData += '<hr></body></html>';

    return htmlData;
  }


  fileName = 'TransactionHistoryExcelSheet.xlsx';

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
  public intToEnglishWordConverter(number: number): any {
    var NS = [
      { value: 10000000, str: 'Crore' },
      { value: 100000, str: 'Lakh' },
      { value: 1000, str: 'Thousand' },
      { value: 100, str: 'Hundred' },
      { value: 90, str: 'Ninety' },
      { value: 80, str: 'Eighty' },
      { value: 70, str: 'Seventy' },
      { value: 60, str: 'Sixty' },
      { value: 50, str: 'Fifty' },
      { value: 40, str: 'Fourty' },
      { value: 30, str: 'Thirty' },
      { value: 20, str: 'Twenty' },
      { value: 19, str: 'Nineteen' },
      { value: 18, str: 'Eighteen' },
      { value: 17, str: 'Seventeen' },
      { value: 16, str: 'Sixteen' },
      { value: 15, str: 'Fifteen' },
      { value: 14, str: 'Fourteen' },
      { value: 13, str: 'Thirteen' },
      { value: 12, str: 'Twelve' },
      { value: 11, str: 'Eleven' },
      { value: 10, str: 'Ten' },
      { value: 9, str: 'Nine' },
      { value: 8, str: 'Eight' },
      { value: 7, str: 'Seven' },
      { value: 6, str: 'Six' },
      { value: 5, str: 'Five' },
      { value: 4, str: 'Four' },
      { value: 3, str: 'Three' },
      { value: 2, str: 'Two' },
      { value: 1, str: 'One' },
    ];

    var result = '';
    for (var n of NS) {
      if (number >= n.value) {
        if (number <= 99) {
          result += n.str;
          number -= n.value;
          if (number > 0) result += ' ';
        } else {
          var t = Math.floor(number / n.value);
          // console.log(t);
          var d = number % n.value;
          if (d > 0) {
            return (
              this.intToEnglishWordConverter(t) +
              ' ' +
              n.str +
              ' ' +
              this.intToEnglishWordConverter(d)
            );
          } else {
            return this.intToEnglishWordConverter(t) + ' ' + n.str;
          }
        }
      }
    }
    return result;
  }

  backTochallan(typeC:any){
    const queryParams: Params = { type: typeC};
    this.router.navigate(['/view-challan'], {
      relativeTo: this.currentRoute,
      queryParams: queryParams,
      queryParamsHandling: '',
    });
  }
}
