import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-birth-death-certificate-view',
  templateUrl: './birth-death-certificate-view.component.html',
  styleUrls: ['./birth-death-certificate-view.component.css']
})
export class BirthDeathCertificateViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  commonConstants : any = CommonConstants;

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

}
