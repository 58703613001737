
<div class="reportContainer">
    <div  [formGroup]="form">

        <div class="row mb-3">
            
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
            <select class="selectCls"  formControlName="district" (change)="onDistrictChange()"
            >
                <option value="All">All</option>
                <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
            </select>
            
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="mandal" (change)="onMandalChange()">
            <option value="All">All</option>
            <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
            </select>
            
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">GP/Location: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="gplocation">
                <option value="All">All</option>
                <option *ngFor="let location of locations" [ngValue]="location">{{location.gpName}}</option>
            </select>
            
            </div>
        </div>
    </div>

    
    <div class="tabClass">
        <ul>
            <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li> 
        </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getReportData()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" (click)="goToHome()">Home</button>
        </div>
      </div>

      <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="table-responsive tableFixHead" *ngIf="showReports">
        <table class="tableCls" >
            <thead> 
                <tr>
                    <th [attr.colspan]="noOfCol">VBSY Day Nodal Officer IEC Distribution Report</th>
                </tr>
                <tr>
                    <th width="100px">S.No</th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districtname'" >District <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandalname'" *ngIf="format === 'Mandal Wise'  || format === 'GP/Location Wise' || format === 'GP Wise'">Mandal/ULB <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'gP_NAME'" *ngIf="format === 'GP/Location Wise'|| format === 'GP Wise'">GP/Location <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'camP_DATE'" *ngIf="format === 'GP/Location Wise'|| format === 'GP Wise'">Date of camp <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_CALENDARS'">Calenders <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_BROCHURES'">Brochures <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_BOOKLETS'">Booklet <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_TSHIRTS'">TShirts <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_CAPS'">Caps <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_BADGES'">Badges <mdb-icon fas icon="sort"></mdb-icon></th>             
                </tr>           
               
                
            </thead>
    
            <tbody *ngIf="items && items.length > 0">
                <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;"> 
                <td width="100px">{{ ((page -1) * pageSize) + (i + 1)}}</td>
                <td>{{item.districtname}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'GP/Location Wise' || format === 'GP Wise'">{{item.mandalname}}</td>
                <td *ngIf="format === 'GP/Location Wise' || format === 'GP Wise'">{{item.gP_NAME}}</td>
                <td *ngIf="format === 'GP/Location Wise' || format === 'GP Wise'">{{item.camP_DATE}}</td>
                <td>{{item.nO_OF_CALENDARS}}</td>
                <td>{{item.nO_OF_BROCHURES}}</td>  
                <td>{{item.nO_OF_BOOKLETS}}</td> 
                <td>{{item.nO_OF_TSHIRTS}}</td>
                <td>{{item.nO_OF_CAPS}}</td>  
                <td>{{item.nO_OF_BADGES}}</td>               
            </tr>
            </tbody>
            <tbody *ngIf="items && items.length === 0">
                <tr>
                    <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="mt-4 btnHide" *ngIf="showReports">
            <div class="float-left" *ngIf="items.length > 0">
                <label>Requests per page: </label>
                <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                    <option  value="10">10</option>
                    <option  value="20">20</option>
                    <option  value="30">30</option>
                    <option  value="40">40</option>
                    <option  value="50">50</option>
                </select> 
            </div>
            <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
            </ngb-pagination>
        </div>
    
    
    </div>
    </div>

    
    <div class="table-responsive tableFixHead" #TABLE class="hideTable">
        <table class="tableCls" >
            <thead>
             <tr>
                 <th [attr.colspan]="noOfCol">Day nodal officer IEC distribution report</th>
             </tr>
            
                <tr>
                    <th width="100px">S.No</th>
                    <th width="100px" >District</th>
                    <th width="100px" *ngIf="format === 'Mandal Wise'  || format === 'GP/Location Wise'|| format === 'GP Wise'">Mandal/ULB</th>
                    <th width="100px" *ngIf="format === 'GP/Location Wise'|| format === 'GP Wise'">GP/Location</th>
                    <th width="100px" *ngIf="format === 'GP/Location Wise'|| format === 'GP Wise'">Date of Camp</th>
                    <th width="100px">Calendars</th>
                    <th width="100px">Brochures</th>
                    <th width="100px">Booklet</th>
                    <th width="100px">TShirts</th>
                    <th width="100px">Caps</th>
                    <th width="100px">Badges</th>                  
                    
                </tr>
            </thead>
     
            <tbody *ngIf="items && items.length > 0">
                <tr *ngFor="let item of items;let i=index">
                    <td width="100px">{{i + 1}}</td>
                    <td width="100px">{{item.districtname}}</td>
                    <td width="100px" *ngIf="format === 'Mandal Wise'  || format === 'GP/Location Wise'  || format === 'GP Wise'">{{item.mandalname}}</td>
                    <td width="100px" *ngIf="format === 'GP/Location Wise' || format === 'GP Wise'">{{item.gP_NAME}}</td>
                    <td *ngIf="format === 'GP/Location Wise'|| format === 'GP Wise'">{{item.camP_DATE}}</td>
                    <td >{{item.nO_OF_CALENDARS}}</td>
                    <td >{{item.nO_OF_BROCHURES}}</td>
                    <td >{{item.nO_OF_BOOKLETS}}</td>
                    <td >{{item.nO_OF_TSHIRTS}}</td>
                    <td >{{item.nO_OF_CAPS}}</td>
                    <td >{{item.nO_OF_BADGES}}</td>
                    
                </tr>
                <!-- <tr *ngIf="totalItems && totalItems.length > 0">
                 <td></td>
                 <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{totalItems[0].districT_NAME}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
                <td >{{totalItems[0].toT_TRANS}}</td>
                <td >{{totalItems[0].toT_AMOUNT}}</td>
                <td >{{totalItems[0].paiD_TRANS}}</td>
              
             </tr> -->
            </tbody>
            <tbody *ngIf="items && items.length === 0">
                <tr>
                    <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </table>
     </div>
</div>