<div class="container-fluid mainCls">
    <div class="mainPageContainer mr-3">
        <div class="btnHolder mb-3">
            <button class="btn btn-primary float-right my-3"  (click)="goToHome()">Home</button>
        </div>
        <h6 >VBSY daily IEC material data entry</h6>
        <form [formGroup]="distribution">
<div class="row mb-3">
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
        <label class="labelCls">Date: <span class="mandatoryCls">*</span></label>
        <div class="input-group">
            <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                name="d"  ngbDatepicker #d="ngbDatepicker" (dateSelect)="getDropDownDetails($event)"                    
                required formControlName="sheduleDate"  [markDisabled]="isDisabled"
                [ngClass]="{ 'is-invalid':submitted && f.sheduleDate.errors }"
                >
            <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
            </div>
        </div>
        <div *ngIf="submitted && f.sheduleDate.errors" class="selectError">
            <div *ngIf="f.sheduleDate.errors?.required || f.sheduleDate.invalid">Please enter valid Schedule Date</div>
        </div>
    </div>

    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">GP Name: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" required formControlName="mgName" 
        [ngClass]="{ 'is-invalid':submitted && (f.mgName.errors || f.mgName.value === 'Select') }">
          <option disabled value=null>Select</option>
          <option *ngFor="let mgName of gpsNames" [ngValue]="mgName">{{mgName}}</option>
        </select> 
        <div *ngIf="submitted && (f.mgName.errors || f.mgName.value === 'Select')" class="selectError">
            <div *ngIf="f.mgName.errors?.required || f.mgName.value === 'Select'">Please Select the GPS Name</div>
        </div>
      </div>

    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">No. of Calendars distributed: </label>
        <input type="text" name="" formControlName="noOfCalendars" class="inputCls" appBlockCopyPaste numbersOnly
        [ngClass]="{ 'is-invalid':submitted && f.noOfCalendars.errors }" maxlength="6" minlength="1">
        <div *ngIf="submitted && f.noOfCalendars.errors" class="invalid-feedback">
        <div *ngIf="f.noOfCalendars.errors?.required || f.noOfCalendars.errors?.whiteSpaceValidate">Please Enter Number of Calendars Distributed</div>
        <div *ngIf="f.noOfCalendars.errors?.pattern">Please enter valid Number</div>
    </div>
</div>

<div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <label class="labelCls">No. of Booklets distributed: </label>
    <input type="text" name="noOfBooklets" formControlName="noOfBooklets" class="inputCls" appBlockCopyPaste numbersOnly
    [ngClass]="{ 'is-invalid':submitted && f.noOfBooklets.errors }" maxlength="6" minlength="1">
    <div *ngIf="submitted && f.noOfBooklets.errors" class="invalid-feedback">
    <div *ngIf="f.noOfBooklets.errors?.required || f.noOfBooklets.errors?.whiteSpaceValidate">Please Enter Number of Booklets Distributed</div>
    <div *ngIf="f.noOfBooklets.errors?.pattern">Please enter valid Number</div>
</div>
</div>

<div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <label class="labelCls">No. of Brochures distributed: </label>
    <input type="text" name="noOfBrochers" formControlName="noOfBrochers" class="inputCls" appBlockCopyPaste numbersOnly
    [ngClass]="{ 'is-invalid':submitted && f.noOfBrochers.errors }" maxlength="6" minlength="1">
    <div *ngIf="submitted && f.noOfBrochers.errors" class="invalid-feedback">
    <div *ngIf="f.noOfBrochers.errors?.required || f.noOfBrochers.errors?.whiteSpaceValidate">Please Enter Number of Brochures Distributed</div>
    <div *ngIf="f.noOfBrochers.errors?.pattern">Please enter valid Number</div>
</div>
</div>

<div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
  <label class="labelCls">No. of Tshirts distributed: </label>
  <input type="text" name="noOfTShirts" formControlName="noOfTShirts" class="inputCls" appBlockCopyPaste numbersOnly
  [ngClass]="{ 'is-invalid':submitted && f.noOfTShirts.errors }" maxlength="6" minlength="1">
  <div *ngIf="submitted && f.noOfTShirts.errors" class="invalid-feedback">
  <div *ngIf="f.noOfTShirts.errors?.required || f.noOfTShirts.errors?.whiteSpaceValidate">Please Enter Number of TShirts Distributed</div>
  <div *ngIf="f.noOfTShirts.errors?.pattern">Please enter valid Number</div>
</div>
</div>

<div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
  <label class="labelCls">No. of Caps distributed: </label>
  <input type="text" name="noOfCaps" formControlName="noOfCaps" class="inputCls" appBlockCopyPaste numbersOnly
  [ngClass]="{ 'is-invalid':submitted && f.noOfCaps.errors }" maxlength="6" minlength="1">
  <div *ngIf="submitted && f.noOfCaps.errors" class="invalid-feedback">
  <div *ngIf="f.noOfCaps.errors?.required || f.noOfCaps.errors?.whiteSpaceValidate">Please Enter Number of Caps Distributed</div>
  <div *ngIf="f.noOfCaps.errors?.pattern">Please enter valid Number</div>
</div>
</div>

<div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
  <label class="labelCls">No. of Badges distributed: </label>
  <input type="text" name="noOfBadges" formControlName="noOfBadges" class="inputCls" appBlockCopyPaste numbersOnly
  [ngClass]="{ 'is-invalid':submitted && f.noOfBadges.errors }" maxlength="6" minlength="1">
  <div *ngIf="submitted && f.noOfBadges.errors" class="invalid-feedback">
  <div *ngIf="f.noOfBadges.errors?.required || f.noOfBadges.errors?.whiteSpaceValidate">Please Enter Number of Badges Distributed</div>
  <div *ngIf="f.noOfBadges.errors?.pattern">Please enter valid Number</div>
</div>
</div>

</div>
<div class="row mb-3">
<div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
    <button name="saveDetails" class="greenBtnCls float-right" (click)="submit()">Submit</button>
  </div>
</div>
  <div class="row mb-3">
    <div class="col">
      <div class="tab">
      <table class="tableCls">
        <thead>
          <tr>
        <th>S.No</th>
        <th>Date</th>
        <th>GP Name</th>
        <th>No. of Calendars distributed</th>
        <th>No. of Booklets distributed</th>
        <th>No. of Brochures distributed</th>
        <th>No. of TShirts distributed</th>
        <th>No. of Caps distributed</th>
        <th>No. of Badges distributed</th>
        </tr>
    </thead>
    <tbody *ngIf="distributionDetails && distributionDetails.length > 0">
        <tr *ngFor="let item of distributionDetails;let i = index;">
            <td class="serial">{{i + 1}}</td>
                <td>{{item.camP_DATE}}</td>
                <td>{{item.gP_Name}}</td>  
                <td>{{item.nO_OF_CALENDARS_DISB}}</td> 
                <td>{{item.nO_OF_BOOKLETS_DISB}}</td> 
                <td>{{item.nO_OF_BROCHURES_DISB}}</td>
                <td>{{item.nO_OF_TSHIRTS_DISB}}</td>
                <td>{{item.nO_OF_CAPS_DISB}}</td>
                <td>{{item.nO_OF_BADGES_DISB}}</td>
                 </tr>
                </tbody>
                <tbody *ngIf="distributionDetails && distributionDetails.length === 0">
                  <td colspan="9" class="text-center">No records found.</td>
                </tbody>
              </table>
            </div>  
          </div>
        </div>
</form>
</div>
</div>