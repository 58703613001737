import { Component, Input, OnInit, ElementRef, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService, AadharValidator } from '../../../shared/common.service';
import { WhitespaceValidator } from 'src/shared/common.service';
import { Router ,ActivatedRoute ,Params} from '@angular/router';
import { NgbNavConfig, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants } from '../../../constants/common-constants.component';
import { NgbModal,NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { fileExtensionValidator, fileSizeValidator } from 'src/shared/validators/file-validator';
import { CustomAdapter, CustomDateParserFormatter } from '../../../shared/common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { EnvironmentService } from 'src/shared/environment.service';
import { CommonFormComponent } from 'src/shared/common-form/common-form.component';



@Component({
  selector: 'app-land-assignment-to-soldiers',
  templateUrl: './land-assignment-to-soldiers.component.html',
  styleUrls: ['./land-assignment-to-soldiers.component.css'],
  providers: [NgbNavConfig, DatePipe,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]
})
export class LandAssignmentToSoldiersComponent implements OnInit {
  @ViewChild(CommonFormComponent) childComp: any;
  public commonFormSubmitted: Boolean = false;
  isPattadarOtpValidated : boolean = false;
  submits: Boolean = false;
  submitted: Boolean = false;
  photo: any;
  currentDate = new Date();
  maxDate: any;
  minDate: any;
  aadharTransactionID: any;
  minDateOfEnrollment:any;
  loggedInSecretariat:any;
  minDateOfDateOfDeath: any;
  secretariatList: any[] = [];
  commonConstants: any = CommonConstants;
  biometricFlag:Boolean=false;
  isOtpValidated: Boolean = false;
  showCertificateInfo: Boolean = false;
  isAadharFieldDisable: Boolean = false;
  soldierForm: FormGroup;
  form : FormGroup;
  encrtyptedAadhar: any;
  aadharData: any;  
  userInfo:any;
  loggedInUserId: any;
  showBiometricPopup: Boolean = false;
  isEKYCCompleted:Boolean=false;
  isDisable: Boolean = false;
  isPrefillDisable: Boolean =false;
  isResurveyVillage:Boolean=false;
  showResurvey:Boolean=false;
  selectedReasonForService:string = "";
  autoBiometricId: any;
  windowObjectReference: any;
  districts: any = [];
  mandals: any = [];
  villages: any = [];
  revenueDistrict: any;
  districtCode: string = '';
  mandalCode:string = '';
  validExt = '';
  @ViewChild('nav')
  nav: any;
  @ViewChild('dischargeDocumentLabel')
  dischargeDocumentLabel!:ElementRef;
  @ViewChild('PensionLabel')
  PensionLabel!: ElementRef;
  @ViewChild('idCardLabel')
  idCardLabel!: ElementRef;
  @ViewChild('signatureLabel')
  signatureLabel!: ElementRef;
  @ViewChild('disabilityLabel')
  disabilityLabel!: ElementRef;
  @ViewChild('supportingDocumentsLabel')
  supportingDocumentsLabel!: ElementRef;
  @ViewChild('supportingDocumentsLabelSS')
  supportingDocumentsLabelSS!: ElementRef;
  @ViewChild('serviceDocumentLabel')
  serviceDocumentLabel!: ElementRef;
  @ViewChild('PostingLabel')
  PostingLabel!: ElementRef;
  @ViewChild('signatureLabelSS')
  signatureLabelSS!: ElementRef;
  
  @ViewChild('deceasedSoldierLabel')
  deceasedSoldierLabel!:ElementRef;
  @ViewChild('PensionLabelDS')
  PensionLabelDS!: ElementRef;
  @ViewChild('WidowLabel')
  WidowLabel!: ElementRef;
  @ViewChild('signatureLabelDS')
  signatureLabelDS!: ElementRef;
  @ViewChild('deathDSLabel')
  deathDSLabel!: ElementRef;
  @ViewChild('supportingDocumentsLabelDS')
  supportingDocumentsLabelDS!: ElementRef;
  @ViewChild('VerificationDocumentsDSLabelDS')
  VerificationDocumentsDSLabelDS!: ElementRef;
  @ViewChild('VerificationDocumentsEXLabelEX')
  VerificationDocumentsEXLabelEX!: ElementRef;
  @ViewChild('VerificationDocumentsSSLabelSS')
  VerificationDocumentsSSLabelSS!: ElementRef;
 
  fileList: { FileName: string; DocumentType: string; BlobRef: string }[] = [];
  constructor(private config: NgbNavConfig, private formBuilder: FormBuilder, public activeModal: NgbActiveModal,private commonService: CommonService, private router: Router, private modalService: NgbModal,
    private datePipe: DatePipe, private activatedRoute: ActivatedRoute, private sanitizer: DomSanitizer, private environmentService: EnvironmentService) { 
      let userData = localStorage.getItem('userInfo');
      if (userData && userData !== null) {
        this.userInfo = JSON.parse(userData);
        this.loggedInUserId = this.userInfo.userPrincipalName
      }
    
      this.maxDate = {
        year: this.currentDate.getFullYear(),
        month: this.currentDate.getMonth() + 1,
        day: this.currentDate.getDate()- 1,
      };
      this.minDate = {
        year: this.currentDate.getFullYear() - 150,
        month: this.currentDate.getMonth() + 1,
        day: this.currentDate.getDate(),
      };
      this.minDateOfEnrollment={
        year: this.currentDate.getFullYear()-17,
        month: this.currentDate.getMonth() + 1,
        day: this.currentDate.getDate(),
      }
      this.minDateOfDateOfDeath={
        year: this.currentDate.getFullYear()-5,
        month: this.currentDate.getMonth() + 1,
        day: this.currentDate.getDate(),
      }
      this.soldierForm = this.formBuilder.group({
        applicantName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
        applicantName1: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
        category: [null],
        relation:[null, [Validators.required]],
        awardWinner: [null, [Validators.required]],
        warDisabled: [null],
        reason: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
        aadhaarNumber: ['',[Validators.required,WhitespaceValidator.whiteSpaceValidate]],
        authenticationType: ['rbOTP',[Validators.required, WhitespaceValidator.whiteSpaceValidate]],
        otp: ['', Validators.required],
        adhaarNumberBio: ['', [AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],

        dateOfApplication: [null, [Validators.required]],
        serviceNumber: [null],
        rank: [null],
        corps: [null],
        dateOfEnrolment: [null],
        dateOfDischarge: [null],
        causeOfDischarge: [''],
        serviceNumberSS: [null],
        rankSS: [null],
        corpsSS: [null],
        dateOfEnrolmentSS: [null],
        serviceNumberDS: [null],
        rankDS: [null],
        corpsDS: [null],
        dateOfEnrolmentDS: [null],
        dateOfDeathDS: [null],
        causeOfDischargeDS: [''],
      });
      this.form = this.formBuilder.group({
        commonForm: [],
        district:[null,[Validators.required]],
        mandal:[null,[Validators.required]],
        village:[null,[Validators.required]],
        psecretariat: [null, [Validators.required]],
        dischargeDocument:[''],
        Pension:[''],
        idCard:['',],
        signature:[''],
        signatureSS:[''],
        disability:[''],
        supportingDocuments:[''],
        supportingDocumentsSS:[''],
        serviceDocument:[''],
        Posting:[''],
        deceasedSoldier:[''],
        PensionDS:[''],
        Widow:['',],
        signatureDS:[''],
        deathDS:[''],
        supportingDocumentsDS:[''],
        VerificationDocumentsDS:[''],
        VerificationDocumentsEX:[''],
        VerificationDocumentsSS:[''],
        discharged:[null],
        syNo:[[]],
        lpNo:[[]],
      });
      this.getDistrictsInTe();
      config.destroyOnHide = false;
      config.roles = false;
    }

  ngOnInit(): void {
    let userInfo: any = localStorage.getItem('userInfo');
    let userDetails: any = JSON.parse(userInfo);
    if (userDetails && userDetails !== null) {
        this.loggedInSecretariat = userDetails.userPrincipalName?.split("@")[0].replace('U', '');
        this.loggedInUserId = userDetails.userPrincipalName;
    }
  }

  get f() {
    return this.soldierForm.controls;
  }

  get f1() {
    return this.form.controls;
  }

  aadharChange(){
    if(this.f.aadhaarNumber.valid){
      this.isDisable = false;
    }else{
      this.isDisable = true;
    }
  }

  sendOTP():any{
   // this.submitted = true;
   this.submits = true;
    if(!this.f.aadhaarNumber.valid)
        return false;
    var sendPostData={
      uid: this.f.aadhaarNumber.value,
      authentication_type: "OTPGENERATE"
    }
    this.commonService.postRequestForAadhar(this.commonConstants.generateAadharOTP, sendPostData).subscribe({
      next: (responseData:any) => { 
        if(responseData?.reason == 'OTP Sent Successfully'){
          this.aadharTransactionID = responseData?.result
        } else {
          this.commonService.commonErrorPopup('Alert', responseData?.result, 'md');
        }
      },
      error: (error: any) => {
        console.log('client side', error);
      }
    });
  }
  validateOTP():any {
    if(!this.soldierForm.valid) {
      this.invalidControls();
      this.submits=true;
      return false;    
    } 
    if(this.f.category.value === 'Ex-Serviceperson'){
      let dateOfEnrolment = this.commonService.formatDate(this.f.dateOfEnrolment.value);
      let dateOfDischarge = this.commonService.formatDate(this.f.dateOfDischarge.value);
      if(new Date(dateOfEnrolment) > new Date(dateOfDischarge)){  
        this.commonService.commonErrorPopup("Alert", 'Date of Enrolment should be less than Date of Discharge', "md");
        return false;
      }
    }
    else if(this.f.category.value === 'DeceasedSoldier'){
      let dateOfEnrolmentDS = this.commonService.formatDate(this.f.dateOfEnrolmentDS.value);
      let dateOfDeathDS = this.commonService.formatDate(this.f.dateOfDeathDS.value);
      if(new Date(dateOfEnrolmentDS) > new Date(dateOfDeathDS)){  
        this.commonService.commonErrorPopup("Alert", 'Date of Enrolment should be less than Date of Death', "md");
        return false;
      }
    } 
        this.aadharData = [];
    var sendPostData={
      PIDXML: this.aadharTransactionID,
      otp : this.f.otp.value,
      uid: this.f.aadhaarNumber.value, //'514492421388',//
      authentication_type: "OTPVALIDATE"
    }
    this.commonService.postRequestForAadhar(this.commonConstants.generateAadharOTP, sendPostData).subscribe({
      next: (responseData:any) => { 
        if(responseData?.reason == 'OTP Verified Successfully'){
          this.commonService.commonErrorPopup('Alert', 'Authentication Success.', 'md');
          this.aadharData = responseData?.result;
              let data: any;
              data = {}
            this.isPattadarOtpValidated = true;
            this.activeModal.close(data);
            this.f1.commonForm.patchValue({
              aadharNo: this.f.aadhaarNumber.value,
              firstName: '',
              middleName: '',
              lastName: '',
              fatherHusName: '',
              gender: null,
              dob: '',
              caste: null,
              religion: null,
              qualification: null,
              maritalStatus: null,
              mobileNo: '',
              email: '',
              deliveryType: '',
              preDoorNo: '',
              preStreet: '',
              preVillage: null,
              preMandal: null,
              preDistrict: null,
              prePinCode: '',
              prePostalOffice : null,
              prePostalVillage : null,
              permDoorNo: '',
              permStreet: '',
              permVillage: null,
              permMandal: null,
              permDistrict: null,
              permPinCode: '',
              permPostalOffice: null,
              permPostalVillage: null,
              sameAddress: '',
              encryptedAadharNo: '',
              isWhatsAppAvailable: null,
              householdid: '',
              clusterid: '',
              isDataFromAadhar:'',
            });
         //   this.f.applicantName1.patchValue(responseData?.data?.Citizen_Name);
         this.isEKYCCompleted = true;
         this.prefillData();   
        } else if(responseData?.reason == 'OTP validation failed'){
         this.commonService.commonErrorPopup('Alert', 'Invalid OTP Entered. Please Enter Valid OTP.', 'md')
        } else{
          this.commonService.commonErrorPopup('Alert', 'Invalid OTP Entered. Please Enter Valid OTP.', 'md')
        }
      },
      error: (error: any) => {
        console.log('client side', error);
      }
    });
  }
  prefillData() {
    setTimeout(() => {
      this.childComp.getCitizenInfo();
      this.childComp.aadharencrypt('');
    }, 3000);
  }
  onChangeState() {
    if(this.f.warDisabled.value === true && this.f.category.value==='Ex-Serviceperson') {
        this.f.reason.setValidators([Validators.required, WhitespaceValidator.whiteSpaceValidate]);
        this.f.reason.updateValueAndValidity();
        this.form.controls['disability']?.setValidators([Validators.required,fileExtensionValidator('pdf')]);
        this.form.controls['disability']?.updateValueAndValidity(); 
    } else if(this.f.warDisabled.value === true && this.f.category.value==='ServingSoldier'){
      this.f.reason.setValidators([Validators.required, WhitespaceValidator.whiteSpaceValidate]);
      this.f.reason.updateValueAndValidity();
    }
    else {
        this.f.reason.clearValidators();
        this.f.reason.updateValueAndValidity();
        this.f.reason.patchValue('');
        this.form.controls['disability']?.clearValidators(); 
        this.form.controls['disability']?.updateValueAndValidity(); 
    }
  }
  goToHome() {
    this.router.navigate(['/view-transactionzswo']);
  }
  gotoProfile() {
    const queryParams: Params = { flag: 'SELF'};
    this.router.navigate(['/signature-upload'], {queryParams: queryParams});
  }
   
  selectedCategory(data:any) {
    this.childComp?.resetForm();
    this.isEKYCCompleted= false;
    this.isPattadarOtpValidated = false;
    this.photo='';
    this.fileList =[];
    this.form.reset();
    this.f.aadhaarNumber.patchValue('');
    this.f.applicantName1.patchValue('');
    this.f.otp.patchValue('');
    this.f.relation.patchValue(null);
    this.clearFileUpload('dischargeDocument', 'dischargeDocumentLabel');
    this.clearFileUpload('Pension', 'PensionLabel');
    this.clearFileUpload('idCard', 'idCardLabel');
    this.clearFileUpload('signature', 'signatureLabel');
    // this.clearFileUpload('disability', 'disabilityLabel');
    this.clearFileUpload('VerificationDocumentsEX', 'VerificationDocumentsEXLabelEX');
    this.clearFileUpload('supportingDocuments', 'supportingDocumentsLabel');

    this.clearFileUpload('serviceDocument', 'serviceDocumentLabel');
    this.clearFileUpload('Posting', 'PostingLabel');
    this.clearFileUpload('signatureSS', 'signatureLabelSS');
    this.clearFileUpload('VerificationDocumentsSS', 'VerificationDocumentsSSLabelSS');
    this.clearFileUpload('supportingDocumentsSS', 'supportingDocumentsLabelSS');

    this.clearFileUpload('deceasedSoldier', 'deceasedSoldierLabel');
    this.clearFileUpload('PensionDS', 'PensionLabelDS');
    this.clearFileUpload('Widow', 'WidowLabel');
    this.clearFileUpload('signatureDS', 'signatureLabelDS');
    this.clearFileUpload('deathDS', 'deathDSLabel');
    this.clearFileUpload('VerificationDocumentsDS', 'VerificationDocumentsDSLabelDS');
    this.clearFileUpload('supportingDocumentsDS', 'supportingDocumentsLabelDS');

    if(this.f.category.value =="Ex-Serviceperson") {
      this.f1["serviceDocument"]?.clearValidators();
      this.f1["Posting"]?.clearValidators();
      this.f1["signatureSS"]?.clearValidators();
      this.f1["VerificationDocumentsSS"]?.clearValidators();
      this.f1["supportingDocumentsSS"]?.clearValidators();
      this.f1["deceasedSoldier"]?.clearValidators();
      this.f1["PensionDS"]?.clearValidators();
      this.f1["Widow"]?.clearValidators();
      this.f1["signatureDS"]?.clearValidators();
      this.f1["deathDS"]?.clearValidators();
      this.f1["VerificationDocumentsDS"]?.clearValidators();
      this.f1["supportingDocumentsDS"]?.clearValidators();
      this.f1["serviceDocument"]?.updateValueAndValidity();
      this.f1["Posting"]?.updateValueAndValidity();
      this.f1["signatureSS"]?.updateValueAndValidity();
      this.f1["VerificationDocumentsSS"]?.updateValueAndValidity();
      this.f1["supportingDocumentsSS"]?.updateValueAndValidity();
      this.f1["deceasedSoldier"]?.updateValueAndValidity();
      this.f1["PensionDS"]?.updateValueAndValidity();
      this.f1["Widow"]?.updateValueAndValidity();
      this.f1["signatureDS"]?.updateValueAndValidity();
      this.f1["deathDS"]?.updateValueAndValidity();
      this.f1["VerificationDocumentsDS"]?.updateValueAndValidity();
      this.f1["supportingDocumentsDS"]?.updateValueAndValidity();

      this.f["warDisabled"].setValidators([Validators.required]);
      this.f["warDisabled"].updateValueAndValidity();
      this.f["reason"].setValidators([Validators.required, WhitespaceValidator.whiteSpaceValidate]);
      this.f["reason"].updateValueAndValidity();
      this.f["serviceNumber"].setValidators([Validators.required,WhitespaceValidator.whiteSpaceValidate,Validators.pattern(this.commonConstants.alphanumericPattern)]);
      this.f["serviceNumber"].updateValueAndValidity();
      this.f["rank"].setValidators([Validators.required,WhitespaceValidator.whiteSpaceValidate,Validators.pattern(this.commonConstants.alphanumericPattern)]);
      this.f["rank"].updateValueAndValidity();
      this.f["corps"].setValidators([Validators.required,WhitespaceValidator.whiteSpaceValidate,Validators.pattern(this.commonConstants.alphanumericPattern)]);
      this.f["corps"].updateValueAndValidity();
      this.f["dateOfEnrolment"].setValidators([Validators.required]);
      this.f["dateOfEnrolment"].updateValueAndValidity();
      this.f["dateOfDischarge"].setValidators([Validators.required]);
      this.f["dateOfDischarge"].updateValueAndValidity();
      this.f["causeOfDischarge"].setValidators([Validators.required]);
      this.f["causeOfDischarge"].updateValueAndValidity(); 
      this.f["serviceNumberDS"].clearValidators();
      this.f.serviceNumberDS.patchValue('');
      this.f["serviceNumberDS"].updateValueAndValidity();
      this.f["rankDS"].clearValidators();
      this.f.rankDS.patchValue('');
      this.f["rankDS"].updateValueAndValidity();
      this.f["corpsDS"].clearValidators();
      this.f.corpsDS.patchValue('');
      this.f["corpsDS"].updateValueAndValidity();
      this.f["dateOfEnrolmentDS"].clearValidators();
      this.f.dateOfEnrolmentDS.patchValue('');
      this.f["dateOfEnrolmentDS"].updateValueAndValidity();
      this.f["dateOfDeathDS"].clearValidators();
      this.f.dateOfDeathDS.patchValue('');
      this.f["dateOfDeathDS"].updateValueAndValidity();
      this.f["causeOfDischargeDS"].clearValidators();
      this.f.causeOfDischargeDS.patchValue('');
      this.f["causeOfDischargeDS"].updateValueAndValidity(); 
      this.f.awardWinner.patchValue(null);
      this.f.warDisabled.patchValue(null);
      this.f.reason.patchValue('');
      this.f.relation.patchValue('self');
      this.f.dateOfApplication.patchValue('');
      this.f["serviceNumberSS"].clearValidators();
      this.f.serviceNumberSS.patchValue('');
      this.f["rankSS"].clearValidators();
      this.f.rankSS.patchValue('');
      this.f.dateOfDischarge.patchValue('');
      this.f["corpsSS"].clearValidators();
      this.f.corpsSS.patchValue('');
      this.f["dateOfEnrolmentSS"].clearValidators();
      this.f.dateOfEnrolmentSS.patchValue('');
      this.f1["discharged"].setValidators([Validators.required]);
      this.f1["discharged"].updateValueAndValidity(); 
    } 
    else if(this.f.category.value =="ServingSoldier") {  
      this.f1["dischargeDocument"]?.clearValidators();
      this.f1["Pension"]?.clearValidators();
      this.f1["idCard"]?.clearValidators();
      this.f1["signature"]?.clearValidators();
      this.f1["VerificationDocumentsEX"]?.clearValidators();
      this.f1["supportingDocuments"]?.clearValidators();
      this.f1["deceasedSoldier"]?.clearValidators();
      this.f1["PensionDS"]?.clearValidators();
      this.f1["Widow"]?.clearValidators();
      this.f1["signatureDS"]?.clearValidators();
      this.f1["deathDS"]?.clearValidators();
      this.f1["VerificationDocumentsDS"]?.clearValidators();
      this.f1["supportingDocumentsDS"]?.clearValidators();
      this.f1["dischargeDocument"]?.updateValueAndValidity();
      this.f1["Pension"]?.updateValueAndValidity();
      this.f1["idCard"]?.updateValueAndValidity();
      this.f1["signature"]?.updateValueAndValidity();
      this.f1["VerificationDocumentsEX"]?.updateValueAndValidity();
      this.f1["supportingDocuments"]?.updateValueAndValidity();
      this.f1["deceasedSoldier"]?.updateValueAndValidity();
      this.f1["PensionDS"]?.updateValueAndValidity();
      this.f1["Widow"]?.updateValueAndValidity();
      this.f1["signatureDS"]?.updateValueAndValidity();
      this.f1["deathDS"]?.updateValueAndValidity();
      this.f1["VerificationDocumentsDS"]?.updateValueAndValidity();
      this.f1["supportingDocumentsDS"]?.updateValueAndValidity();

      this.f["warDisabled"].setValidators([Validators.required]);
      this.f["warDisabled"].updateValueAndValidity();
      this.f["reason"].setValidators([Validators.required, WhitespaceValidator.whiteSpaceValidate]);
      this.f["reason"].updateValueAndValidity();
      this.f["serviceNumberSS"].setValidators([Validators.required,WhitespaceValidator.whiteSpaceValidate,Validators.pattern(this.commonConstants.alphanumericPattern)]);
      this.f["serviceNumberSS"].updateValueAndValidity();
      this.f["rankSS"].setValidators([Validators.required,WhitespaceValidator.whiteSpaceValidate,Validators.pattern(this.commonConstants.alphanumericPattern)]);
      this.f["rankSS"].updateValueAndValidity();
      this.f["corpsSS"].setValidators([Validators.required,WhitespaceValidator.whiteSpaceValidate,Validators.pattern(this.commonConstants.alphanumericPattern)]);
      this.f["corpsSS"].updateValueAndValidity();
      this.f["dateOfEnrolmentSS"].setValidators([Validators.required]);
      this.f["dateOfEnrolmentSS"].updateValueAndValidity();
      this.f["serviceNumberDS"].clearValidators();
      this.f.serviceNumberDS.patchValue('');
      this.f["serviceNumberDS"].updateValueAndValidity();
      this.f["rankDS"].clearValidators();
      this.f.rankDS.patchValue('');
      this.f["rankDS"].updateValueAndValidity();
      this.f["corpsDS"].clearValidators();
      this.f.corpsDS.patchValue('');
      this.f["corpsDS"].updateValueAndValidity();
      this.f["dateOfEnrolmentDS"].clearValidators();
      this.f.dateOfEnrolmentDS.patchValue('');
      this.f["dateOfEnrolmentDS"].updateValueAndValidity();
      this.f["dateOfDeathDS"].clearValidators();
      this.f.dateOfDeathDS.patchValue('');
      this.f["dateOfDeathDS"].updateValueAndValidity();
      this.f["causeOfDischargeDS"].clearValidators();
      this.f.causeOfDischargeDS.patchValue('');
      this.f["causeOfDischargeDS"].updateValueAndValidity(); 
      this.f.awardWinner.patchValue(null);
      this.f.warDisabled.patchValue(null);
      this.f.reason.patchValue('');
      this.f.relation.patchValue('self');
      this.f.dateOfApplication.patchValue('');
      this.f["dateOfDischarge"].clearValidators();
      this.f.dateOfDischarge.patchValue('');
      this.f["dateOfDischarge"].updateValueAndValidity();
      this.f["serviceNumber"].clearValidators();
      this.f.serviceNumber.patchValue('');
      this.f["rank"].clearValidators();
      this.f.rank.patchValue('');
      this.f["corps"].clearValidators();
      this.f.corps.patchValue('');
      this.f["dateOfEnrolment"].clearValidators();
      this.f.dateOfEnrolment.patchValue('');
      this.f["causeOfDischarge"].clearValidators();
      this.f.causeOfDischarge.patchValue('');
      this.f1["discharged"].clearValidators();
      this.f1.discharged.patchValue(null);
      this.f1["discharged"].updateValueAndValidity();    
    }
    else if(this.f.category.value =="DeceasedSoldier"){
      this.f1["dischargeDocument"]?.clearValidators();
      this.f1["Pension"]?.clearValidators();
      this.f1["idCard"]?.clearValidators();
      this.f1["signature"]?.clearValidators();
      this.f1["VerificationDocumentsEX"]?.clearValidators();
      this.f1["supportingDocuments"]?.clearValidators();
      this.f1["serviceDocument"]?.clearValidators();
      this.f1["Posting"]?.clearValidators();
      this.f1["signatureSS"]?.clearValidators();
      this.f1["VerificationDocumentsSS"]?.clearValidators();
      this.f1["supportingDocumentsSS"]?.clearValidators();
      this.f1["dischargeDocument"]?.updateValueAndValidity();
      this.f1["Pension"]?.updateValueAndValidity();
      this.f1["idCard"]?.updateValueAndValidity();
      this.f1["signature"]?.updateValueAndValidity();
      this.f1["VerificationDocumentsEX"]?.updateValueAndValidity();
      this.f1["supportingDocuments"]?.updateValueAndValidity();
      this.f1["serviceDocument"]?.updateValueAndValidity();
      this.f1["Posting"]?.updateValueAndValidity();
      this.f1["signatureSS"]?.updateValueAndValidity();
      this.f1["VerificationDocumentsSS"]?.updateValueAndValidity();
      this.f1["supportingDocumentsSS"]?.updateValueAndValidity();
      this.f.relation.patchValue(null);
      this.f["warDisabled"].clearValidators();
      this.f.warDisabled.patchValue('');
      this.f["warDisabled"].updateValueAndValidity();
      this.f["serviceNumber"].clearValidators();
      this.f.serviceNumber.patchValue('');
      this.f["serviceNumber"].updateValueAndValidity();
      this.f["reason"].clearValidators();
      this.f.reason.patchValue('');
      this.f["reason"].updateValueAndValidity();
      this.f["rank"].clearValidators();
      this.f.rank.patchValue('');
      this.f["rank"].updateValueAndValidity();
      this.f["corps"].clearValidators();
      this.f.corps.patchValue('');
      this.f["corps"].updateValueAndValidity();
      this.f["dateOfEnrolment"].clearValidators();
      this.f.dateOfEnrolment.patchValue('');
      this.f["dateOfEnrolment"].updateValueAndValidity();
      this.f["dateOfDischarge"].clearValidators();
      this.f.dateOfDischarge.patchValue('');
      this.f["dateOfDischarge"].updateValueAndValidity();
      this.f["causeOfDischarge"].clearValidators();
      this.f.causeOfDischarge.patchValue('');
      this.f["causeOfDischarge"].updateValueAndValidity();
      this.f["serviceNumberSS"].clearValidators();
      this.f.serviceNumberSS.patchValue('');
      this.f["serviceNumberSS"].updateValueAndValidity();
      this.f["rankSS"].clearValidators();
      this.f.rankSS.patchValue('');
      this.f["rankSS"].updateValueAndValidity();
      this.f["corpsSS"].clearValidators();
      this.f.corpsSS.patchValue('');
      this.f["corpsSS"].updateValueAndValidity();
      this.f["dateOfEnrolmentSS"].clearValidators();
      this.f.dateOfEnrolmentSS.patchValue('');
      this.f["dateOfEnrolmentSS"].updateValueAndValidity();
      this.f["serviceNumberDS"].setValidators([Validators.required,WhitespaceValidator.whiteSpaceValidate,Validators.pattern(this.commonConstants.alphanumericPattern)]);
      this.f["serviceNumberDS"].updateValueAndValidity();
      this.f["rankDS"].setValidators([Validators.required,WhitespaceValidator.whiteSpaceValidate,Validators.pattern(this.commonConstants.alphanumericPattern)]);
      this.f["rankDS"].updateValueAndValidity();
      this.f["corpsDS"].setValidators([Validators.required,WhitespaceValidator.whiteSpaceValidate,Validators.pattern(this.commonConstants.alphanumericPattern)]);
      this.f["corpsDS"].updateValueAndValidity();
      this.f["dateOfEnrolmentDS"].setValidators([Validators.required]);
      this.f["dateOfEnrolmentDS"].updateValueAndValidity();
      this.f["dateOfDeathDS"].setValidators([Validators.required]);
      this.f["dateOfDeathDS"].updateValueAndValidity();
      this.f["causeOfDischargeDS"].setValidators([Validators.required]);
      this.f["causeOfDischargeDS"].updateValueAndValidity(); 
      this.f.awardWinner.patchValue(null);
      this.f.warDisabled.patchValue(null);
      this.f.reason.patchValue(null);
      this.f.dateOfApplication.patchValue('');
      this.f1["discharged"].clearValidators();
      this.f1.discharged.patchValue(null);
      this.f1["discharged"].updateValueAndValidity();   
    }
  }
    
  selectedRelation(data:any) {
 
  }

  getDistrictsInTe() {
    this.commonService.getRequest(this.commonConstants.getDistrictsInTe).subscribe({
      next: (responseData: any) => { 
      this.districts = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  onDistrictChange(event: any) {
    this.mandals = [];
    this.villages = [];
    this.secretariatList = [];
    this.f1.mandal.patchValue(null);
    this.f1.village.patchValue(null);
    this.f1.psecretariat.patchValue(null);
    this.getRevenueDistricts();
  }

    
  getRevenueDistricts() {
    this.commonService.getRequest(this.commonConstants.getRevenueDistrictTe + this.f1.district?.value.id).subscribe({
      next: (responseData: any) => { 
      console.log( responseData.result, ' responseData.result')
      this.revenueDistrict = responseData.result[0];
      this.getMandalsInTe();
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
 
  getMandalsInTe() {
    this.commonService.getRequest(this.commonConstants.getRevenueMandalsInTe + this.revenueDistrict?.id).subscribe({
      next: (responseData: any) => { 
      this.mandals = this.commonService.sortData(responseData.result);
      console.log(responseData.result,"mandals")
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  onMandalChange(event: any) {
    this.villages = [];
    this.secretariatList = [];
    this.f1.village.patchValue(null);
    this.f1.psecretariat.patchValue(null);
    this.getVillagesInTe();
    this.getRVToRevenueMandal();
  }
  getRVToRevenueMandal() {
    this.secretariatList = [];
    this.commonService.getRequest(this.commonConstants.getRVToRevenueMandal + this.f1.mandal.value?.id).subscribe({
      next: (responseData: any) => { 
        let revenueMandals = responseData.result; //[0]?.entityCode;
        revenueMandals.forEach((item:any) => {
          this.getSecretariatList(item.entityCode)
        });
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getSecretariatList(code: any) {
    this.commonService.getRequest(this.commonConstants.getDetailsByRevenueMandal + code).subscribe({
      next: (responseData: any) => { 
        let data: any = responseData.result;
        this.districtCode = data?.neW_LGD_DIST_CODE;
        this.mandalCode = data?.lgD_MANDAL_CODE;
        this.secretariatList = [...this.secretariatList, ...responseData.result.secretariatList];
        this.secretariatList = this.commonService.sortItems(this.secretariatList, 'secretariaT_NAME');
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getVillagesInTe() {
    this.commonService.getRequest(this.commonConstants.getRevenueVillagesInTe + this.f1.mandal?.value.id).subscribe({
      next: (responseData: any) => { 
      this.villages = this.commonService.sortData(responseData.result);      
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  
  onVillageChange(){
    this.showResurvey = true;
    this.f1.syNo.patchValue('');
    this.f1.lpNo.patchValue('');
    this.checkResurveyVillage();
    this.generateLPtoken();
  }

  invalidControls(){
    let invalidControls = [];
    const controls = this.soldierForm.controls;
    for(const name in controls)
    {
      if(controls[name].invalid)
      {
        invalidControls.push(name);
      }
    }
    console.log(invalidControls);
  }

  invalidControls2(){
    let invalidControls = [];
    const controls = this.form.controls;
    for(const name in controls)
    {
      if(controls[name].invalid)
      {
        invalidControls.push(name);
      }
    }
    console.log(invalidControls);
  }

  otpChange(event: any) {
    this.f.aadhaarNumber.setValidators([Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]);
    this.f.aadhaarNumber.updateValueAndValidity();
    if (event.target.value === 'rbOTP') {
      this.f.otp.setValidators([Validators.required]);
      this.f.otp.updateValueAndValidity();
    } else {
      this.f.otp.clearValidators();
      this.f.otp.updateValueAndValidity();
      this.f.otp.patchValue('');
    }
  }

  idCardFileSelected(fileEvent: any,controlName: any,labelName:any) {
    const fileExtensionType = fileEvent.target.files[0]?.name.split('.').pop();
    if(labelName === 'idCardLabel'){
    if (fileExtensionType.toLowerCase() === 'jpeg') {
      this.validExt = 'jpeg';
    } 
      this.form.controls['idCard'].setValidators([fileExtensionValidator(this.validExt), fileSizeValidator(fileEvent) ])
      this.form.controls['idCard'].updateValueAndValidity();
    const idCard: File | null = fileEvent.target.files[0];
    this.idCardLabel.nativeElement.innerText = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
    if (this.f1.idCard.valid)
      this.fileUpload(idCard,this.f1['idCard'].value,fileEvent.target.files[0].name, controlName, controlName, labelName);
  }
  else  if(labelName === 'signatureLabel'){
    if (fileExtensionType.toLowerCase() === 'jpeg') {
      this.validExt = 'jpeg';
    } 
      this.form.controls['signature'].setValidators([fileExtensionValidator(this.validExt), fileSizeValidator(fileEvent) ])
      this.form.controls['signature'].updateValueAndValidity();
    const signature: File | null = fileEvent.target.files[0];
    this.signatureLabel.nativeElement.innerText = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
    if (this.f1.signature.valid)
      this.fileUpload(signature,this.f1['signature'].value,fileEvent.target.files[0].name, controlName, controlName, labelName);
  }

  else  if(labelName === 'WidowLabel'){
    if (fileExtensionType.toLowerCase() === 'jpeg') {
      this.validExt = 'jpeg';
    } 
      this.form.controls['Widow'].setValidators([fileExtensionValidator(this.validExt), fileSizeValidator(fileEvent) ])
      this.form.controls['Widow'].updateValueAndValidity();
    const Widow: File | null = fileEvent.target.files[0];
    this.WidowLabel.nativeElement.innerText = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
    if (this.f1.Widow.valid)
      this.fileUpload(Widow,this.f1['Widow'].value,fileEvent.target.files[0].name, controlName, controlName, labelName);
  }
  else  if(labelName === 'signatureLabelSS'){
    if (fileExtensionType.toLowerCase() === 'jpeg') {
      this.validExt = 'jpeg';
    } 
      this.form.controls['signatureSS'].setValidators([fileExtensionValidator(this.validExt), fileSizeValidator(fileEvent) ])
      this.form.controls['signatureSS'].updateValueAndValidity();
    const signatureSS: File | null = fileEvent.target.files[0];
    this.signatureLabelSS.nativeElement.innerText = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
    if (this.f1.signatureSS.valid)
      this.fileUpload(signatureSS,this.f1['signatureSS'].value,fileEvent.target.files[0].name, controlName, controlName, labelName);
  }
  else  if(labelName === 'signatureLabelDS'){
    if (fileExtensionType.toLowerCase() === 'jpeg') {
      this.validExt = 'jpeg';
    } 
      this.form.controls['signatureDS'].setValidators([fileExtensionValidator(this.validExt), fileSizeValidator(fileEvent) ])
      this.form.controls['signatureDS'].updateValueAndValidity();
    const signatureDS: File | null = fileEvent.target.files[0];
    this.signatureLabelDS.nativeElement.innerText = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
    if (this.f1.signatureDS.valid)
      this.fileUpload(signatureDS,this.f1['signatureDS'].value,fileEvent.target.files[0].name, controlName, controlName, labelName);
  }
}

  onFileChange(fileEvent: any, controlName: any, labelName:any) {
    const file: File | null = fileEvent.target.files[0];
    if(labelName === 'dischargeDocumentLabel'){
      this.dischargeDocumentLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
      this.form.controls['dischargeDocument']?.setValidators([Validators.required, fileExtensionValidator('pdf'),fileSizeValidator(fileEvent)]);
      this.form.controls['dischargeDocument']?.updateValueAndValidity(); 
      if (this.f1.dischargeDocument.valid){
        this.fileUpload(file, this.f1.dischargeDocument?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
      }
    }
      else if(labelName === 'PensionLabel') {
      this.PensionLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
      this.form.controls['Pension']?.setValidators([Validators.required,fileExtensionValidator('pdf'), fileSizeValidator(fileEvent)]);
      this.form.controls['Pension']?.updateValueAndValidity();   
      
      if (this.f1.Pension.valid){
      this.fileUpload(file, this.f1.Pension?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
    }

    }    
    else if(labelName === 'disabilityLabel') {
      this.disabilityLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';  
      
    if (this.f1.disability.valid){
      this.fileUpload(file, this.f1.disability?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
    } 
}
else if(labelName === 'serviceDocumentLabel') {
  this.serviceDocumentLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
  this.form.controls['serviceDocument']?.setValidators([Validators.required,fileExtensionValidator('pdf'), fileSizeValidator(fileEvent)]);
  this.form.controls['serviceDocument']?.updateValueAndValidity();   
  
if (this.f1.serviceDocument.valid){
  this.fileUpload(file, this.f1.serviceDocument?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
} 
}
else if(labelName === 'deceasedSoldierLabel') {
  this.deceasedSoldierLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
  this.form.controls['deceasedSoldier']?.setValidators([Validators.required,fileExtensionValidator('pdf'), fileSizeValidator(fileEvent)]);
  this.form.controls['deceasedSoldier']?.updateValueAndValidity();   
  
if (this.f1.deceasedSoldier.valid){
  this.fileUpload(file, this.f1.deceasedSoldier?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
} 
}
else if(labelName === 'PensionLabelDS') {
  this.PensionLabelDS.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
  this.form.controls['PensionDS']?.setValidators([Validators.required,fileExtensionValidator('pdf'), fileSizeValidator(fileEvent)]);
  this.form.controls['PensionDS']?.updateValueAndValidity();   
  
if (this.f1.PensionDS.valid){
  this.fileUpload(file, this.f1.PensionDS?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
} 
}
else if(labelName === 'deathDSLabel') {
  this.deathDSLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
  this.form.controls['deathDS']?.setValidators([Validators.required,fileExtensionValidator('pdf'), fileSizeValidator(fileEvent)]);
  this.form.controls['deathDS']?.updateValueAndValidity();   
  
if (this.f1.deathDS.valid){
  this.fileUpload(file, this.f1.deathDS?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
} 
}
else if(labelName === 'VerificationDocumentsDSLabelDS') {
  this.VerificationDocumentsDSLabelDS.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
  this.form.controls['VerificationDocumentsDS']?.setValidators([Validators.required,fileExtensionValidator('pdf'), fileSizeValidator(fileEvent)]);
  this.form.controls['VerificationDocumentsDS']?.updateValueAndValidity();   
  
if (this.f1.VerificationDocumentsDS.valid){
  this.fileUpload(file, this.f1.VerificationDocumentsDS?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
} 
}

else if(labelName === 'VerificationDocumentsSSLabelSS') {
  this.VerificationDocumentsSSLabelSS.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
  this.form.controls['VerificationDocumentsSS']?.setValidators([Validators.required,fileExtensionValidator('pdf'), fileSizeValidator(fileEvent)]);
  this.form.controls['VerificationDocumentsSS']?.updateValueAndValidity();   
  
if (this.f1.VerificationDocumentsSS.valid){
  this.fileUpload(file, this.f1.VerificationDocumentsSS?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
} 
}

else if(labelName === 'VerificationDocumentsEXLabelEX') {
  this.VerificationDocumentsEXLabelEX.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
  this.form.controls['VerificationDocumentsEX']?.setValidators([Validators.required,fileExtensionValidator('pdf'), fileSizeValidator(fileEvent)]);
  this.form.controls['VerificationDocumentsEX']?.updateValueAndValidity();   
  
if (this.f1.VerificationDocumentsEX.valid){
  this.fileUpload(file, this.f1.VerificationDocumentsEX?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
} 
}

else if(labelName === 'PostingLabel') {
  this.PostingLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
  this.form.controls['Posting']?.setValidators([Validators.required,fileExtensionValidator('pdf'), fileSizeValidator(fileEvent)]);
  this.form.controls['Posting']?.updateValueAndValidity();   
  
if (this.f1.Posting.valid){
  this.fileUpload(file, this.f1.Posting?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
} 
}
else if(labelName === 'supportingDocumentsLabel') {
  this.supportingDocumentsLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
  
if (this.f1.supportingDocuments.valid){
  this.fileUpload(file, this.f1.supportingDocuments?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
} 
}
else if(labelName === 'supportingDocumentsLabelSS') {
  this.supportingDocumentsLabelSS.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
  
if (this.f1.supportingDocumentsSS.valid){
  this.fileUpload(file, this.f1.supportingDocumentsSS?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
} 
}
else if(labelName === 'supportingDocumentsLabelDS') {
  this.supportingDocumentsLabelDS.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
  
if (this.f1.supportingDocumentsDS.valid){
  this.fileUpload(file, this.f1.supportingDocumentsDS?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
} 
}
  }
  dataURLtoFile(dataurl: any, filePath:any, filename: any, documentType: any) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
  
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    this.fileUpload(
      new File([u8arr], filename, { type: mime }),
      filePath,
      filename,
      documentType
    );
  }
fileUpload(file: any, filePath: string, fileName: string, documentType: string, name: string = '', label: string = '') {
  const formData = new FormData();
  formData.append('File', file, fileName);
  formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
  console.log(formData, 'formData');
  this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload, formData).subscribe({
      next: (responseData: any) => {
        if(typeof(responseData.result) !== 'string'){
          let index = this.fileList.map((item) => item.DocumentType).indexOf(documentType);
          if (index > -1) {
            this.fileList.splice(index, 1);
          }
          this.fileList.push({
            FileName: fileName,
            DocumentType: documentType,
            BlobRef: responseData['result'].blobRef,
          });
          console.log(this.fileList)
        } else{
          if(name && label) {
            this.clearFileUpload(name, label);
          }
          this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
        }
        console.log(this.fileList, 'FileList');
      },
      error: (error) => {
        if(name && label) {
          this.clearFileUpload(name, label);
        }
        console.log('client side', error);
      },
    });
}

clearFileUpload(name: string, label: string) {    
  this.f1[name]?.reset();
  if(label ==='dischargeDocumentLabel' && this.dischargeDocumentLabel){
    this.dischargeDocumentLabel.nativeElement.innerText = 'No File Choosen';
    this.f1.dischargeDocument?.setValidators([Validators.required]);
    this.f1.dischargeDocument.updateValueAndValidity();
  }
   else if(label === 'PensionLabel' && this.PensionLabel) {
    this.PensionLabel.nativeElement.innerText = 'No File Choosen';
    this.f1.Pension.setValidators([Validators.required]);
    this.f1.Pension.updateValueAndValidity();
  } 
  else if(label === 'disabilityLabel' && this.disabilityLabel) {
    this.disabilityLabel.nativeElement.innerText = 'No File Choosen';
  } 
  else if(label === 'signatureLabel' && this.signatureLabel) {
    this.signatureLabel.nativeElement.innerText = 'No File Choosen';
    this.f1.signature.setValidators([Validators.required]);
    this.f1.signature.updateValueAndValidity();
  } 
  else if(label === 'signatureLabelSS' && this.signatureLabelSS) {
    this.signatureLabelSS.nativeElement.innerText = 'No File Choosen';
    this.f1.signatureSS.setValidators([Validators.required]);
    this.f1.signatureSS.updateValueAndValidity();
  } 
  else if(label === 'idCardLabel' && this.idCardLabel) {
    this.idCardLabel.nativeElement.innerText = 'No File Choosen';
    this.f1.idCard.setValidators([Validators.required]);
    this.f1.idCard.updateValueAndValidity();
  } 
  else if(label === 'serviceDocumentLabel' && this.serviceDocumentLabel) {
    this.serviceDocumentLabel.nativeElement.innerText = 'No File Choosen';
    this.f1.serviceDocument.setValidators([Validators.required]);
    this.f1.serviceDocument.updateValueAndValidity();
  } 
  else if(label === 'PostingLabel' &&  this.PostingLabel) {
    this.PostingLabel.nativeElement.innerText = 'No File Choosen';
    this.f1.Posting.setValidators([Validators.required]);
    this.f1.Posting.updateValueAndValidity();
  } 

  else if(label === 'deceasedSoldierLabel' &&  this.deceasedSoldierLabel) {
    this.deceasedSoldierLabel.nativeElement.innerText = 'No File Choosen';
    this.f1.deceasedSoldier.setValidators([Validators.required]);
    this.f1.deceasedSoldier.updateValueAndValidity();
  } 
  else if(label === 'PensionLabelDS' &&  this.PensionLabelDS) {
    this.PensionLabelDS.nativeElement.innerText = 'No File Choosen';
    this.f1.PensionDS.setValidators([Validators.required]);
    this.f1.PensionDS.updateValueAndValidity();
  } 
  else if(label === 'WidowLabel' &&  this.WidowLabel) {
    this.WidowLabel.nativeElement.innerText = 'No File Choosen';
    this.f1.Widow.setValidators([Validators.required]);
    this.f1.Widow.updateValueAndValidity();
  } 
  else if(label === 'signatureLabelDS' &&  this.signatureLabelDS) {
    this.signatureLabelDS.nativeElement.innerText = 'No File Choosen';
    this.f1.signatureDS.setValidators([Validators.required]);
    this.f1.signatureDS.updateValueAndValidity();
  } 
  else if(label === 'deathDSLabel' &&  this.deathDSLabel) {
    this.deathDSLabel.nativeElement.innerText = 'No File Choosen';
    this.f1.deathDS.setValidators([Validators.required]);
    this.f1.deathDS.updateValueAndValidity();
  } 
  else if(label === 'VerificationDocumentsDSLabelDS' &&  this.VerificationDocumentsDSLabelDS) {
    this.VerificationDocumentsDSLabelDS.nativeElement.innerText = 'No File Choosen';
    this.f1.VerificationDocumentsDS.setValidators([Validators.required]);
    this.f1.VerificationDocumentsDS.updateValueAndValidity();
  } 
  else if(label === 'VerificationDocumentsEXLabelEX' &&  this.VerificationDocumentsEXLabelEX) {
    this.VerificationDocumentsEXLabelEX.nativeElement.innerText = 'No File Choosen';
    this.f1.VerificationDocumentsEX.setValidators([Validators.required]);
    this.f1.VerificationDocumentsEX.updateValueAndValidity();
  } 
  else if(label === 'VerificationDocumentsSSLabelDS' &&  this.VerificationDocumentsSSLabelSS) {
    this.VerificationDocumentsSSLabelSS.nativeElement.innerText = 'No File Choosen';
    this.f1.VerificationDocumentsSS.setValidators([Validators.required]);
    this.f1.VerificationDocumentsSS.updateValueAndValidity();
  } 
  else if(label === 'supportingDocumentsLabel' &&  this.supportingDocumentsLabel) {
    this.supportingDocumentsLabel.nativeElement.innerText = 'No File Choosen';
  } 
  else if(label === 'supportingDocumentsLabelSS' &&  this.supportingDocumentsLabelSS) {
    this.supportingDocumentsLabelSS.nativeElement.innerText = 'No File Choosen';
  } 
  else if(label === 'supportingDocumentsLabelDS' &&  this.supportingDocumentsLabelDS) {
    this.supportingDocumentsLabelDS.nativeElement.innerText = 'No File Choosen';
  } 
 

}


updateFileUpload(id: any) {
  let fileData = {
    FileList: this.fileList,
    RequisitionId: id,
    RequisitionType:'AssignmentofLandstoEx-ServicepersonServingSoldiers',
  }  
  this.commonService.postRequest(this.commonConstants.updateFileUpload,fileData).subscribe({
    next: (resData:any) => { 
      console.log(resData,'File Upload  Respose data');
      if(resData.result?.updateResult == 'Success') {
        this.showPayment(id);
      }  
    },
    error: (error) => {
      console.log('client side', error);
    }
  });
}

showPayment(requisionId: any) {
  const queryParams: Params = { id: requisionId,department: 'RV',type: 'AssignmentofLandstoEx-ServicepersonServingSoldiers'};
  this.router.navigate(['/payment-gateway'], {
    relativeTo: this.activatedRoute,
    queryParams: queryParams,
    queryParamsHandling: 'merge',
  });
}

lpNoarray: any = [];
syNoarray: any = [];

saveRequisition() {
  this.invalidControls();
  this.invalidControls2();
  if((this.f1.discharged.value===false|| this.f1.discharged.value===null)&& this.f.category.value === "Ex-Serviceperson"){
    this.submitted = true;
    this.commonService.commonErrorPopup('Alert', 'Was the Soldier discharged honourably must be selected as yes', 'md');
    return;
  }  
  if(!this.soldierForm.valid){
    this.submits = true;
    return;
  }
  if(!this.form.valid){
    this.submitted = true;
    return;
  }
  let dateFormat = this.commonService.formatDate(this.f1['commonForm'].value['dob']);
  if(this.isResurveyVillage == true && this.f1.lpNo.value) {
    this.lpNoarray = this.f1.lpNo.value?.map((item: any) => item.toString()) 
  }
  else if(this.f1.syNo.value) {
    this.syNoarray = this.f1.syNo.value?.map((item: any) => item.toString())
  }

  let postData: any = {
    requisitionHeader: {
      requisitionType: 'AssignmentofLandstoEx-ServicepersonServingSoldiers',
      department: 'RV',
      applicantInfo: {
        id: '',
        aadhaarNumber: this.f1['commonForm'].value['encryptedAadharNo'].trim(), // CryptoJS.AES.encrypt(this.f['commonForm'].value['aadharNo'].trim(), this.commonConstants.encryptDecryptPassword.trim()).toString(), //this.f['commonForm'].value['aadharNo'],
        // aadhaarNumber: this.f1['commonForm'].value['aadharNo'],
        personNames: [
          {
            languageCode: 'en-in',
            firstName: this.f1['commonForm'].value['firstName'],
            middleName: this.f1['commonForm'].value['middleName'],
            lastName: this.f1['commonForm'].value['lastName'],
            fatherHusbandName: this.f1['commonForm'].value['fatherHusName'],
          },
        ],
        gender: this.f1['commonForm'].value['gender'],
        birthDate: dateFormat,
        caste: this.f1['commonForm'].value['caste'].name,
        religion: this.f1['commonForm'].value['religion'].name,
        qualification: this.f1['commonForm'].value['qualification'].name,
        maritalStatus: this.f1['commonForm'].value['maritalStatus'],
        addresses: [
          {
            addressType: 'present',
            doorNo: this.f1['commonForm'].value['preDoorNo'],
            streetName: this.f1['commonForm'].value['preStreet'],
            villageWard: this.f1['commonForm'].value['preVillage'].entityCode,
            mandalMunicipality:  this.f1['commonForm'].value['preMandal'].entityCode,
            district: this.f1['commonForm'].value['preDistrict'].entityCode,
            pinCode: this.f1['commonForm'].value['prePinCode'],
            postalOfficeCode: this.f1['commonForm'].value['prePostalOffice'] !== null ? this.f1['commonForm'].value['prePostalOffice'].entityCode : "",
            postalOfficeName: this.f1['commonForm'].value['prePostalOffice'] !== null ? this.f1['commonForm'].value['prePostalOffice'].name : "",
            postalVillageCode: this.f1['commonForm'].value['prePostalVillage'] !== null ? this.f1['commonForm'].value['prePostalVillage'].entityCode : "",
            postalVillageName: this.f1['commonForm'].value['prePostalVillage'] !== null ? this.f1['commonForm'].value['prePostalVillage'].name : ""
          },
          {
            addressType: 'permanent',
            doorNo: this.f1['commonForm'].value['permDoorNo'],
            streetName: this.f1['commonForm'].value['permStreet'],
            villageWard: this.f1['commonForm'].value['permVillage'].entityCode,
            mandalMunicipality: this.f1['commonForm'].value['permMandal'].entityCode,
            district: this.f1['commonForm'].value['permDistrict'].entityCode,
            pinCode: this.f1['commonForm'].value['permPinCode'],
            postalOfficeCode: this.f1['commonForm'].value['permPostalOffice'] !==  null ? this.f1['commonForm'].value['permPostalOffice'].entityCode : "",
            postalOfficeName: this.f1['commonForm'].value['permPostalOffice'] !== null ? this.f1['commonForm'].value['permPostalOffice'].name : "",
            postalVillageCode: this.f1['commonForm'].value['permPostalVillage'] !== null ? this.f1['commonForm'].value['permPostalVillage'].entityCode : "",
            postalVillageName: this.f1['commonForm'].value['permPostalVillage'] !== null ? this.f1['commonForm'].value['permPostalVillage'].name : ""
          },
          {
            addressType:"workflow",
            doorNo: "1",
            streetName: null,
            villageWard:this.f1['psecretariat'].value?.secretariaT_CODE?.toString(),
            mandalMunicipality: this.mandalCode?.toString(),
            district: this.districtCode?.toString(),
            pinCode: "523445"
          },
        ],
        rationCardNo: '',
        deliveryType: this.f1['commonForm'].value['deliveryType'],
        emailAddress: this.f1['commonForm'].value['email'],
        mobileNumber: this.f1['commonForm'].value['mobileNo'],
        isWhatsAppAvailable: this.f1['commonForm'].value['isWhatsAppAvailable'],
        isDataFromAadhar: this.f1['commonForm'].value['isDataFromAadhar']
      },
      remarks: '',
    },
    requisitionData: {
      secretariatCode : this.f1.psecretariat.value?.secretariaT_CODE.toString(),
      secretariatName : this.f1.psecretariat.value?.secretariaT_NAME,
      applicantAadharNo: this.commonService.RSAEncrypt(this.f.aadhaarNumber.value),
      nameofServicePersonOrSoldier: this.f.applicantName.value,
      categoryofServicepersonOrSoldie:this.f.category.value,
      isGallantryAwardWinner:this.f.awardWinner.value,
      isWarDisabled: this.f.warDisabled.value,
      disabilityDetails: this.f.reason.value,
      dateofApplicationreceivedtoZSWO: this.f.dateOfApplication.value,
      district:this.f1.district.value?.entityCode,
      mandal: this.f1.mandal.value?.entityCode,
      village: this.f1.village.value?.entityCode,
      districtName:this.f1.district.value?.name,
      mandalName: this.f1.mandal.value?.name,
      villageName: this.f1.village.value?.name,
      lpNoSYNo: this.isResurveyVillage === true ? this.lpNoarray : this.syNoarray,
      // lpNoSYNo: this.isResurveyVillage === true ? this.f1.lpNo.value?.toString() : this.f1.syNo.value?.toString(),
      isDischargedDishonourably: this.f1.discharged.value,
      relation:this.f.relation.value,
      exSoldierDetails: {
        serviceNumber: this.f.serviceNumber.value,
        rank: this.f.rank.value,
        nameofCorpsOrRecords: this.f.corps.value,
        entryDateofEnrolment:this.f.dateOfEnrolment.value,
        dateofDischarge: this.f.dateOfDischarge.value,
        causeofDischarge: this.f.causeOfDischarge.value
      },
      servingSoldierDetails: {
        serviceNumber: this.f.serviceNumberSS.value,
        rank: this.f.rankSS.value,
        nameofCorpsOrRecords: this.f.corpsSS.value,
        entryDateofEnrolment: this.f.dateOfEnrolmentSS.value
      },
      deceasedSoldierDetails: {
        serviceNumber: this.f.serviceNumberDS.value,
        rank: this.f.rankDS.value,
        nameofCorpsOrRecords:this.f.corpsDS.value,
        entryDateofEnrolment:this.f.dateOfEnrolmentDS.value,
        dateofDeath: this.f.dateOfDeathDS.value,
        causeofDeath: this.f.causeOfDischargeDS.value
    }
  }
};

  this.commonService.postRequest(this.commonConstants.landAssignmentForSoldiers, postData).subscribe({
      next: (responseData: any) => {
       this.updateFileUpload(responseData['result'].id);
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
}

goToNextTab() {
  this.commonFormSubmitted = true;
  // this.f.aadhaarNumber.patchValue(this.f1['commonForm'].value['aadharNo']);
  // this.f.aadhaarNumber.patchValue(this.f1['commonForm'].value['aadharNo']);
  if(this.f.aadhaarNumber.value !== this.f1['commonForm'].value['aadharNo']) {
    this.commonService.commonErrorPopup('Alert', 'Aadhar number provided at the basic details is not matching with the Aadhar Number which is authenticated:' + this.f.aadhaarNumber.value, 'md')
    return;
  } 
  if (this.f1.commonForm.valid) {
    this.nav.select(2)
  }
}
onNavChange(changeEvent: NgbNavChangeEvent) {
  this.commonFormSubmitted = true;
  // this.f.aadhaarNumber.patchValue(this.f1['commonForm'].value['aadharNo']);
  // this.f.aadhaarNumber.patchValue(this.f1['commonForm'].value['aadharNo']);
  if (changeEvent.nextId === 2 && !this.f1.commonForm.valid) {
    changeEvent.preventDefault();
  } else if (changeEvent.nextId === 2 && this.f1.commonForm.valid) {
    changeEvent.preventDefault();
    this.nav.select(2);
  }
}

// biometricBtn(from?:any): any {
//   this.biometricFlag=true; 
//   if(!this.soldierForm.valid) {
//     this.invalidControls();
//     this.submits=true;
//     return false;    
//   } 
//   if(this.f.category.value === 'Ex-Serviceperson'){
//     let dateOfEnrolment = this.commonService.formatDate(this.f.dateOfEnrolment.value);
//     let dateOfDischarge = this.commonService.formatDate(this.f.dateOfDischarge.value);
//     if(new Date(dateOfEnrolment) > new Date(dateOfDischarge)){  
//       this.commonService.commonErrorPopup("Alert", 'Date of Enrolment should be less than Date of Discharge', "md");
//       return false;
//     }
//   }
//   else if(this.f.category.value === 'DeceasedSoldier'){
//     let dateOfEnrolmentDS = this.commonService.formatDate(this.f.dateOfEnrolmentDS.value);
//     let dateOfDeathDS = this.commonService.formatDate(this.f.dateOfDeathDS.value);
//     if(new Date(dateOfEnrolmentDS) > new Date(dateOfDeathDS)){  
//       this.commonService.commonErrorPopup("Alert", 'Date of Enrolment should be less than Date of Death', "md");
//       return false;
//     }
//   } else {
//     let postData: any = {};
//         this.encrtyptedAadhar =  this.commonService.RSAEncrypt(this.f['aadhaarNumber'].value);
//         postData.autoBiometricId = 0;
//         postData.aadharNumber = this.encrtyptedAadhar;
//         postData.responseStatus = '';
//         postData.userId = this.loggedInUserId;
//         this.commonService.postRequestForWebLand(this.commonConstants.getSNoForBiometric, postData).subscribe({
//           next: (responseData:any) => { 
//           this.autoBiometricId = responseData?.result?.autoBiometricId;
//           if (this.autoBiometricId !== undefined && this.autoBiometricId !== null) {
//             this.showBiometricPopup = true;
//             let url: any = this.environmentService.externalUrls.biometricRedirectionPage + encodeURIComponent(this.encrtyptedAadhar) +'&SNo='+ this.autoBiometricId +'&DoEkyc=true&ReqPhoto=false';
//             this.windowObjectReference = window.open(url, "BiometricAuthentication", 'popup=1;left=500,top=100,width=780,height=700');
//           } else {
//             this.showBiometricPopup = false;
//           }
//         },
//           error: (error) => {
//             console.log('client side', error);
//           }
//         });
//   }
//   }

  biometricBtn() : any{
    let postData: any = {};
     if(!this.soldierForm.valid) {
      this.invalidControls();
      this.submits=true;
      return false;    
    } 
    if(this.f.category.value === 'Ex-Serviceperson'){
      if(this.f.dateOfEnrolment.value > this.f.dateOfDischarge.value){  
        this.commonService.commonErrorPopup("Alert", 'Date of Enrolment should be less than Date of Discharge', "md");
        return false;
      }
    }
    else if(this.f.category.value === 'DeceasedSoldier'){
      if(this.f.dateOfEnrolmentDS.value > this.f.dateOfDeathDS.value){  
        this.commonService.commonErrorPopup("Alert", 'Date of Enrolment should be less than Date of Death', "md");
        return false;
      }
    } 
      //this.isDisable = true; //added for testing purpose
      this.encrtyptedAadhar =  this.commonService.RSAEncrypt(this.f.aadhaarNumber.value);
      console.log(this.encrtyptedAadhar, 'encryptedAadhaar')
      postData.autoBiometricId = 0;
      postData.aadharNumber = this.encrtyptedAadhar;
      postData.responseStatus = '';
      postData.userId = this.loggedInUserId;
      this.commonService.postRequestForWebLand(this.commonConstants.getSNoForBiometric, postData).subscribe({
        next: (responseData:any) => { 
       console.log('response from biometric', responseData)
        this.autoBiometricId = responseData?.result?.autoBiometricId;
        console.log(this.autoBiometricId, 'autobiometricId')
        if (this.autoBiometricId !== undefined && this.autoBiometricId !== null) {
          this.showBiometricPopup = true;
          let url: any = this.environmentService.externalUrls.biometricRedirectionPage + encodeURIComponent(this.encrtyptedAadhar) +'&SNo='+ this.autoBiometricId +'&DoEkyc=true&ReqPhoto=true';
          this.windowObjectReference = window.open(url, "BiometricAuthentication", 'popup=1;left=500,top=100,width=780,height=700');
          console.log(url, 'url checking');
        } else {
          this.showBiometricPopup = false;
        }
      },
        error: (error) => {
          console.log('client side', error);
        }
      });

}


checkResurveyVillage() {
  this.isResurveyVillage = false;
  let postData: any = {};
  postData.Ptype = 'RVSN';
  postData.DistrictCode = this.f1.district.value?.entityCode;
  postData.MandalCode = this.f1.mandal.value?.entityCode;
  postData.VillageCode = this.f1.village.value?.entityCode;
  postData.LPM = '';
this.commonService.postRequestForWebLand(this.commonConstants.mutationResurveyToken, postData).subscribe({
    next: (responseData: any) => {
      if(responseData.Code == 100) {
        let token = 'Bearer ' +responseData.Data;
        console.log('token', token);
        this.commonService.postRequestForWebLand(this.commonConstants.mutationResurveyMaster, postData, 'resurvey', token).subscribe({
          next: (responseData: any) => {
            if(responseData.Code == 100) {
              if(responseData.Data[0]?.FrCode == 2) {
                this.isResurveyVillage = true;
              } else if(responseData.Data[0]?.FrCode == 1) {
                this.isResurveyVillage = false;
              }
            } else {
              this.isResurveyVillage = false;
              this.commonService.commonErrorPopup('Alert', responseData?.Message, 'md');
            }
          },
          error: (error: any) => {             
      console.log('client side', error);
          }
      });
      }
    },
    error: (error) => {
  console.log('client side', error);
    }
});
}
// checkBiometricStatus(from?: any) {
//   this.submits = true;
//   this.windowObjectReference.close();
//   let postData: any = {};
//   postData.autoBiometricId = this.autoBiometricId;
//   postData.aadharNumber = this.encrtyptedAadhar;
//   postData.responseStatus = '';
//   postData.userId = this.loggedInUserId;
//   this.commonService.postRequestForWebLand(this.commonConstants.getSNoForBiometric, postData).subscribe({
//     next: (responseData:any) => { 
//    let status = responseData?.result?.responseStatus;
//    if (status === 'Success') {
//     this.showBiometricPopup = true;
//     this.commonService.commonErrorPopup('Alert', 'Biometric Authentication Success.', 'md');
//     this.commonService.getRequest("comms-vm/AadhaarBiometric/Authentication/GetBiometricData?SNo="+ this.autoBiometricId).subscribe({
//       next: (responseData:any) => {
//         if(responseData?.data?.Reason == 'Success'){
//           this.aadharData = responseData?.data;
//             let data: any;
//             data = {}
//             this.submits=false;
//             this.activeModal.close(data);
//             this.dataURLtoFile(
//               'data:image/jpeg;base64,' + responseData?.data?.Ekyc_IMG_Path,
//               'aadharphoto',
//               'aadharPhoto.jpeg',
//               'aadharPhoto'
//             );
//             this.f1.commonForm.patchValue({
//               aadharNo: this.f.aadhaarNumber.value,
//               firstName: '',
//               middleName: '',
//               lastName: '',
//               fatherHusName: '',
//               gender: null,
//               dob: '',
//               caste: null,
//               religion: null,
//               qualification: null,
//               maritalStatus: null,
//               mobileNo: '',
//               email: '',
//               deliveryType: '',
//               preDoorNo: '',
//               preStreet: '',
//               preVillage: null,
//               preMandal: null,
//               preDistrict: null,
//               prePinCode: '',
//               prePostalOffice : null,
//               prePostalVillage : null,
//               permDoorNo: '',
//               permStreet: '',
//               permVillage: null,
//               permMandal: null,
//               permDistrict: null,
//               permPinCode: '',
//               permPostalOffice: null,
//               permPostalVillage: null,
//               sameAddress: '',
//               encryptedAadharNo: '',
//               isWhatsAppAvailable: null,
//               householdid: '',
//               clusterid: '',
//               isDataFromAadhar:'',
//             });
//             this.photo = 'data:image/png;base64,'+ responseData?.data?.Ekyc_IMG_Path;
//             this.f.applicantName1.patchValue(responseData?.data?.Citizen_Name);
//             this.isEKYCCompleted = true;     
//             this.isDisable= true;
//           }
//       },
//       error: (error) => {
//         console.log('GetBiometricData Error client side', error);
//       }
//     })
//    } else {
//     this.showBiometricPopup = false;
//     this.commonService.commonErrorPopup('Alert', 'Biometric Authentication failed. Please try again!!', 'md');
//    }     
//   },
//     error: (error) => {
//       console.log('client side', error);
//     }
//   });
// }
checkBiometricStatus() {
  this.windowObjectReference.close();
  let postData: any = {};
  postData.autoBiometricId = this.autoBiometricId;
  postData.aadharNumber = this.encrtyptedAadhar;
  console.log(this.encrtyptedAadhar, 'encryptedaadharNumberCheckstatus')
  postData.responseStatus = '';
  postData.userId = this.loggedInUserId;
  this.commonService.postRequestForWebLand(this.commonConstants.getSNoForBiometric, postData).subscribe({
    next: (resData:any) => {
    console.log('response from biometric', resData)
   let status = resData?.result?.responseStatus;
   if (status === 'Success') {
    this.showBiometricPopup = true;
    this.commonService.commonErrorPopup('Alert', 'Biometric Authentication Success.', 'md');
    this.commonService.getRequest("comms-vm/AadhaarBiometric/Authentication/GetBiometricData?SNo="+ this.autoBiometricId).subscribe({
      next: (responseData:any) => {
        console.log("GetBiometricData Success Result", JSON.stringify(responseData));
        if(responseData?.data?.Reason == 'Success'){
          console.log(responseData?.data + 'Biometrci data');
          console.log(responseData?.data?.Citizen_Name + 'Biometric data name');
          this.dataURLtoFile(
            'data:image/jpeg;base64,' + responseData?.data?.Ekyc_IMG_Path,
            'aadharphoto',
            'aadharPhoto.jpeg',
            'aadharPhoto'
          );
          this.f.commonForm.patchValue({
            aadharNo: this.f.aadhaarNumber.value,
            firstName: '',
            middleName: '',
            lastName: '',
            fatherHusName: '',
            gender: null,
            dob: '',
            caste: null,
            religion: null,
            qualification: null,
            maritalStatus: null,
            mobileNo: '',
            email: '',
            deliveryType: '',
            preDoorNo: '',
            preStreet: '',
            preVillage: null,
            preMandal: null,
            preDistrict: null,
            prePinCode: '',
            prePostalOffice : null,
            prePostalVillage : null,
            permDoorNo: '',
            permStreet: '',
            permVillage: null,
            permMandal: null,
            permDistrict: null,
            permPinCode: '',
            permPostalOffice: null,
            permPostalVillage: null,
            sameAddress: '',
            encryptedAadharNo: '',
            isWhatsAppAvailable: null,
            householdid: '',
            clusterid: '',
            isDataFromAadhar:'',
          });
          this.photo = 'data:image/png;base64,'+ responseData?.data?.Ekyc_IMG_Path;
          this.f.applicantName1.patchValue(responseData?.data?.Citizen_Name);
          this.isEKYCCompleted = true;     
          this.isDisable= true;    
      }else{
        this.isEKYCCompleted = false;
        this.isDisable= false;
      }
      },
      error: (error) => {
        console.log('GetBiometricData Error client side', error);
      }
    });
   } else {
    this.showBiometricPopup = false;
    this.commonService.commonErrorPopup('Alert', 'Biometric Authentication failed. Please try again!!', 'md');
   }
   
  },
    error: (error) => {
      console.log('client side', error);
    }
  });
}

resetDetails() {
  this.submits = false;
  this.soldierForm.reset();
  this.soldierForm.enable();
}
getCitizenInfo() {
  if (this.f.aadhaarNumber.valid) { 
    let postData: any = {};
    postData.aadhaarNumber = this.commonService.RSAEncrypt(this.f.aadhaarNumber.value, true); 
  this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe({
    next: (responseData: any) => { 
      if(responseData?.result &&responseData?.result.length !==0)
       { 
        this.f.applicantName1.patchValue(responseData.result[0].firstName);
        this.isPrefillDisable = true;
        }else{
        this.isPrefillDisable = false;
        this.f.applicantName1.patchValue('');
       }
    },
    error: (error) => {
      console.log('client side', error);
    }
});
  }    
}

generateLPtoken() {
  this.commonService.postRequestForWebLand(this.commonConstants.zswotoken,"").subscribe({
    next: (responseData: any) => {
      if(responseData.Code == 100) {
        let token = 'Bearer ' +responseData.Data;
        this.lpNumberWise('RC',token)
      }
    },
    error: (error) => {
    console.log('client side', error);
    }
});
}

lpNumbertable: any = [];

lpNumberWise(data: any, token: any) {
  this.submitted = true;
    let postData: any = {};
    postData.ProgramType = data;
    postData.VillageCode = this.f1.village.value?.entityCode;
    this.commonService.postRequestForWebLand(this.commonConstants.zswolpmnumberfetch, postData, 'resurvey',token).subscribe({
      next: (responseData: any) => {
        if(responseData.Code==100){
          let data = responseData?.Data;
          let temp: any = [];
          data?.forEach((item: any) => {
            if (item !== '' && item !== null && item.LPMNO !== undefined) {
              temp.push(item.LPMNO );
            }
            else {
              temp.push(item.Survey_No);
            }
          });
          this.lpNumbertable = temp;
        } 
      },
      error: (error: any) => {
      console.log('client side', error);
      }
  }); 
 }


}

