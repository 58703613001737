<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
    <!-- <div class="subHeadingCls mb-3">Informant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Informant Name:</label>
                <div class="valueCls">{{certificateInfo?.informantName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Relationship with the Applicant:</label>
                <div class="valueCls">{{certificateInfo?.informantRelation}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Email:</label>
                <div class="valueCls">{{certificateInfo?.informantEmail}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mobile:</label>
                <div class="valueCls">{{certificateInfo?.informantMobileNo}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Delivery Type:</label>
                <div class="valueCls"></div>
            </div>

        </div>
    </div> -->

    <!-- </div> -->
    <div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">

    <div class="subHeadingCls mb-3">Land Details Description</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo?.landDetails?.districtName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo?.landDetails?.mandalMunicipalityName}}</div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{certificateInfo?.landDetails?.villageWardName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Servey Number:</label>
                <div class="valueCls">{{certificateInfo?.surveyNo}}</div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Extent Of Land:</label>
                <div class="valueCls">{{certificateInfo?.extentOfLand}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Type Of land:</label>
                <div class="valueCls">{{certificateInfo?.landType}}</div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Category Of Farme:</label>
                <div class="valueCls">{{certificateInfo?.farmerCategory}}</div>
            </div>
        </div>
    </div>


</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">

    <div class="subHeadingCls mb-3">Other Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Type well to be dug:</label>
                <div class="valueCls">{{certificateInfo?.wellType}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Mode of drawing water:</label>
                <div class="valueCls">{{certificateInfo?.drawingWaterMode}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Specification of pump:</label>
                <div class="valueCls">{{certificateInfo?.pumpSpecification}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Distance from existing functional well:</label>
                <div class="valueCls">{{certificateInfo?.functionalwellDistance}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Well Depth(Meters):</label>
                <div class="valueCls">{{certificateInfo?.wellDepth}}</div>
            </div>
        </div>
    </div>



    </div>
    <div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
        <div class="row">
            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                <ng-container *ngIf="fileList && fileList.length !== 0">
                    <div class="subHeadingCls mb-3">List of Documents</div>
                    <div class="fieldHolder" *ngFor="let file of fileList ; let i = index">
                        <div *ngIf="file.documentType === 'Application Form'">
                            <span class="numberCls">{{i+1}}</span><label class="labelCls">Application in prescribed
                                format:</label>
                            <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                    (click)="downloadFile(file)" class="download ml-2"></span></div>
                        </div>
                        <div *ngIf="file.documentType === 'Register Documents'">
                            <span class="numberCls">{{i+1}}</span><label class="labelCls">Photocopy of Pattadar
                                Passbook/Title Deed/1B Extract/ Registered sale deeds of the Lands:</label>
                            <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                    (click)="downloadFile(file)" class="download ml-2"></span></div>
                        </div>
                        <div *ngIf="file.documentType === 'Passport size photo'">
                            <span class="numberCls">{{i+1}}</span><label class="labelCls">Small and Marginal Farmer
                                Certificate for Fee concession :</label>
                            <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                    (click)="downloadFile(file)" class="download ml-2"></span></div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <!-- ------------ -->

