<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo):'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks ):'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Survey No:</label>
                <div class="valueCls">{{certificateInfo.surveyNumber}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Extent:</label>
                <div class="valueCls">{{certificateInfo.extent}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Sub Division Number:</label>
                <div class="valueCls">{{certificateInfo.subDivisionNumber}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">File No/Reference No:</label>
                <div class="valueCls">{{certificateInfo.fileNoOrRefNo}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Date of File/Reference:</label>
                <div class="valueCls">{{certificateInfo.dateOfFileOrReference}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Purpose For Extract Of NOC:</label>
                <div class="valueCls">{{certificateInfo.purposeForExtractNOC}}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList ;let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Copy Of Noc'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Copy Of NOC:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>
