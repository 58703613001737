import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { Router } from '@angular/router';
import { CommonService, CustomAdapter, CustomDateParserFormatter } from 'src/shared/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbDatepickerConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as moment from 'moment';
import {
  fileExtensionValidator,
  fileSizeValidator,fileSizeValidatorForAarogyaSri
} from 'src/shared/validators/file-validator';
@Component({
  selector: 'app-team-schedule',
  templateUrl: './team-schedule.component.html',
  styleUrls: ['./team-schedule.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]
})
export class TeamScheduleComponent implements OnInit {
  buildTeamDetails: any = [];
  submitted: Boolean = false;
  finalSubmitted: Boolean = false;
  isSubmitted: Boolean = false;
  isStartSubmitted: Boolean = false;
  dropdownSettings:IDropdownSettings={};
  commonConstants : any = CommonConstants;
  teamList: any = [];
  teamListPrint: any = [];
  locationDetails: any = [];
  teams: any = [];
  isUrban: Boolean = false;
  secretariatList: any = [];
  teamForm: FormGroup;
  currentDate = new Date();
  maxDate: any;
  minDate: any;
  maxDateReschedule: any;
  minDateReschedule: any;
  isDisabled:any;
  userData: any;
  //@ViewChild('campPhotoLabel') campPhotoLabel!: ElementRef ;
  campPhotoLabelValue = "";
  campPhotoFile: any;
  scheduleConductedForm: FormGroup;
  selectedScheduleIndex: any;
  conductModel: any;
  lastRescheduledDate = new Date("2023-06-28 17:00:00");
  startModel: any;
  campStartedPhotoLabelValue = "";
  campStartedPhotoFile: any;
  scheduleStartedForm: FormGroup;


  constructor(private modalService: NgbModal,
    private formBuilder: FormBuilder, private router: Router, private commonService: CommonService,  private calendar: NgbCalendar, private config: NgbDatepickerConfig) {
    this.teamForm = this.formBuilder.group({
      team: [null, [Validators.required ]], 
      secretariat: [null, [Validators.required]], 
      sheduleDate: [null, [Validators.required]]
    });
    this.scheduleConductedForm = this.formBuilder.group({
      noOfCitizensParticipated: [null, [Validators.required ]], 
      noOfTokenGenerated: [null, [Validators.required ]], 
      noOfServicesRegistered: [null, [Validators.required]], 
      noOfGrievancesRaised: [null, [Validators.required]],
      noOfCertificatesIssued: [null, [Validators.required]],
      noOfCCRCCardIssued: [null, [Validators.required]],
      noOfMobileUpdated: [null, [Validators.required]],
      campPhoto: ["", [Validators.required]]
    });
    this.scheduleStartedForm = this.formBuilder.group({
      campStartedPhoto: ["", [Validators.required]]
    });
    let userInfo = localStorage.getItem('userInfo');
    if (userInfo && userInfo !== null) {
      this.userData = JSON.parse(userInfo);;
    }
    this.getDetails();
    
    // 24th June 2023  to 22nd July 2023
    this.maxDate = {
      // year: this.currentDate.getFullYear(),
      // month: this.currentDate.getMonth() + 2,
      // day: this.currentDate.getDate()
      year: this.currentDate.getFullYear(),
      month: 7,
      day: 29
    };
    this.maxDateReschedule = {
      // year: this.currentDate.getFullYear(),
      // month: this.currentDate.getMonth() + 2,
      // day: this.currentDate.getDate()
      year: this.currentDate.getFullYear(),
      month: 7,
      day: 29
    };
    this.minDate = {
      // year: this.currentDate.getFullYear(),
      // month: this.currentDate.getMonth() + 1,
      // day: this.currentDate.getDate()
      year: this.currentDate.getFullYear(),
      month: 7,
      day: 7
    };

  
    // let minDayReschedule = 1;
    // if(this.currentDate < new Date("2023-07-07 00:00:00")) {
    //   minDayReschedule = 7;
    // }if(this.currentDate.getMonth() == 6 && this.currentDate.getDate() >= 7) {
    //   minDayReschedule = this.currentDate.getDate();
    // } else if(this.currentDate.getMonth() == 7) {
    //   minDayReschedule = this.currentDate.getDate();
    // }
    let limitRescheduleDate = new Date("2023-07-07 00:00:00");
    let limitRescheduleDate8 = new Date(new Date().setDate(this.currentDate.getDate() + 8));
    let minRescheduleDate = (limitRescheduleDate > limitRescheduleDate8) ? limitRescheduleDate : limitRescheduleDate8;
    this.minDateReschedule = {
      year: minRescheduleDate.getFullYear(),
      month: minRescheduleDate.getMonth() + 1,
      day: minRescheduleDate.getDate()
    };


    // this.minDateReschedule = {
    //   // year: this.currentDate.getFullYear(),
    //   // month: this.currentDate.getMonth() + 1,
    //   // day: this.currentDate.getDate()
    //   year: this.currentDate.getFullYear(),
    //   month: (this.currentDate.getMonth() == 6 || this.currentDate.getMonth() == 7) ? (this.currentDate.getMonth() + 1) : 7,
    //   // day: (this.currentDate.getDate() > 1 && (this.currentDate.getMonth() == 6 || this.currentDate.getMonth() == 7)) ? this.currentDate.getDate() : 1
    //   day: minDayReschedule
    // };

    this.isDisabled = (date: NgbDateStruct) => {
      return ([7].includes(calendar.getWeekday(new NgbDate(date.year,date.month,date.day)))) ? true : false;
    };
   }

  ngOnInit(): void {
    this.dropdownSettings = {
      idField: 'secretariaT_CODE',
      textField: 'secretariaT_NAME',
      enableCheckAll: true,
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All"
  };
  }
  get f() {
    return this.teamForm.controls;
  }

  get f2() {
    return this.scheduleConductedForm.controls;
  }

  get f3() {
    return this.scheduleStartedForm.controls;
  }
  goToHome() {
    this.router.navigate(['/home']);
  }
  goToTeamComposition() {
    this.router.navigate(['/team-composition']);
  }
  getDetails() {
    let locationCode: any = localStorage.getItem('locationCode');
    this.commonService.getRequest(this.commonConstants.getDistrictSecretariatDetailsbyMandal + locationCode).subscribe({
      next: (responseData: any) => {
        this.locationDetails = responseData.result;
        this.isUrban = responseData.result?.isRuralUrbanFlag === 'R' ? false : true ;
        this.secretariatList = this.commonService.sortItems(responseData.result?.secretariatList, 'secretariaT_NAME');
        this.teams = this.secretariatList.length > 24 ? ['Team 1', 'Team 2', 'Team 3', 'Team 4', 'Team 5', 'Team 6','Team 7', 'Team 8', 'Team 9','Team 10'] : ['Team 1', 'Team 2'];
        this.getScheduleDetails();
        this.getTeamDetails();
       },
      error: (error) => {
        this.locationDetails = [];
        console.log('client side', error);
      },
    });
  }
  addToSchedule():any {
    if(new Date() >= this.lastRescheduledDate) {
      this.commonService.commonErrorPopup('Alert', 'All the schedules are freezed. New Schedule is not allowed.', 'md');
      return;
    }
    this.submitted = true;
    if(!this.teamForm.valid) 
      return false;
    let isTeamExist: any = this.teamList.findIndex((item:any) => item.teamName === this.f.team.value && item.scheduledDate === moment(this.f.sheduleDate.value,"DD-MM-YYYY").format("DD-MM-YYYY"));
    if(isTeamExist > -1) {
      this.commonService.commonErrorPopup('Alert', 'The selected team is scheduled for other secretariat/cluster for the selected date already.', 'md');
      return false;
    } else if(this.isUrban && this.f.secretariat.value?.length > 5) {
      this.commonService.commonErrorPopup('Alert', 'Maximum 5 secretariats are allowed for a schedule in case of urban.', 'md');
      return false;
    } else if(!this.isUrban) {
     
      this.teamList.push({
        //isSelected: false,
        teamName: this.f.team.value,
        secretariatDisplay:  this.f.secretariat.value?.secretariaT_NAME,
        secretariat: this.f.secretariat.value?.secretariaT_NAME,
        secretariatCode: this.f.secretariat.value?.secretariaT_CODE,
        scheduledDate: moment(this.f.sheduleDate.value,"DD-MM-YYYY").format("DD-MM-YYYY"),
        venue: null,
        meetingHappenedOrReschedule: null,
        rescheduleRemarks : null,
        newrescheduleRemarks: null,
        rescheduleDate: null,
        isNewAdded: true,
        noOfCitizensParticipated: null,
        noOfTokenGenerated: null,
        noOfServicesRegistered: null,
        noOfGrievancesRaised: null,
        noOfCertificatesIssued: null,
        noOfCCRCCardIssued: null,
        noOfMobileUpdated: null,
        campPhotos: null,
        isVenueFreezed: false,
        campStartedPhotos: null
      });
      this.secretariatList = this.secretariatList.filter((item:any) => {
        return !this.teamList.find((el:any) => el.secretariatCode === item.secretariaT_CODE);
      });
     
    } else {
      console.log('selected secretariats' ,this.f.secretariat.value);
      let selectedSecretariats: any = this.f.secretariat.value;
      let selectedSecNames: any = [];
      let selectedSecCodes: any = [];
      selectedSecretariats.forEach((item:any) => {
        selectedSecNames.push(item.secretariaT_NAME);
        selectedSecCodes.push(item.secretariaT_CODE);
      });
      this.teamList.push({
        //isSelected: false,
        teamName: this.f.team.value,
        secretariatDisplay: selectedSecNames,
        secretariat: selectedSecNames,
        secretariatCode: selectedSecCodes,
        scheduledDate: moment(this.f.sheduleDate.value,"DD-MM-YYYY").format("DD-MM-YYYY"),
        selectedSecList: selectedSecretariats,
        venue: null,
        meetingHappenedOrReschedule: null,
        rescheduleRemarks : null,
        newrescheduleRemarks: null,
        rescheduleDate: null,
        isNewAdded: true,
        noOfCitizensParticipated: null,
        noOfTokenGenerated: null,
        noOfServicesRegistered: null,
        noOfGrievancesRaised: null,
        noOfCertificatesIssued: null,
        noOfCCRCCardIssued: null,
        noOfMobileUpdated: null,
        campPhotos: null,
        isVenueFreezed: false,        
        campStartedPhotos: null
      });
      let addedSecList: any = [];
      this.teamList.forEach((item:any) => {
        addedSecList = [...addedSecList , ...item.selectedSecList];
      })
      this.secretariatList = this.secretariatList.filter((item:any) => {
        return !addedSecList.find((el:any) => el.secretariaT_CODE === item.secretariaT_CODE);
      });
    }
      this.f.team.patchValue(null);
      this.f.secretariat.patchValue(null);
      this.f.sheduleDate.patchValue(null);
      this.submitted = false;
    console.log('teamList', this.teamList);
  }
  // deleteSchedule() {
  //   let index:any = this.teamList.findIndex((item:any) => item.isSelected);
  //   if(index > -1) {
  //     let item: any = this.teamList[index];
  //     if(!this.isUrban) {
  //       let temp: any = {};
  //       temp.secretariaT_CODE = item.secretariatCode;
  //       temp.secretariaT_NAME = item.secretariat;
  //       this.secretariatList.push(temp);
  //       this.commonService.sortItems(this.secretariatList, 'secretariaT_NAME');
  //       this.teamList.splice(index, 1);
  //     } else {
  //       this.secretariatList = [...this.secretariatList, ...item.selectedSecList];
  //       this.commonService.sortItems(this.secretariatList, 'secretariaT_NAME');
  //       this.teamList.splice(index, 1);
  //     }
  //   } else {
  //     this.commonService.commonErrorPopup('Alert', 'Please select a schedule to delete it.', 'md');
  //   }
  // }
  meetingHappenedOrRescheduleChanged(item:any, content: any, index: any, startContent: any,){    
    if(item.meetingHappenedOrReschedule == "Conducted") {
      const year=  new Date().getFullYear();
      const month= new Date().getMonth() + 1;
      const day=  new Date().getDate();
      const dateFormat = day +"-"+  month +"-"+ year;
      const todayDate = moment(dateFormat,"DD-MM-YYYY").format("YYYY-MM-DDThh:mm:ss");
      const scheduledDate = moment(item.scheduledDate,"DD-MM-YYYY").format("YYYY-MM-DDThh:mm:ss");
      if(todayDate < scheduledDate) {
        this.commonService.commonErrorPopup('Alert', 'Schedule can be conducted on or after the scheduled date.', 'md');
        setTimeout(() => {
          item.meetingHappenedOrReschedule = this.teamListPrint[index].meetingHappenedOrReschedule;;
        }, 100);
        return;
      }
      if(this.teamListPrint[index].meetingHappenedOrReschedule != "Started") {
        this.commonService.commonErrorPopup('Alert', 'Schedule can be conducted only if it is started.', 'md');
        setTimeout(() => {
          item.meetingHappenedOrReschedule = this.teamListPrint[index].meetingHappenedOrReschedule;;
        }, 100);
        return;
      }
      this.scheduleConductedForm.reset();
      item.rescheduleDate = null;
      item.newrescheduleRemarks = null;
      this.campPhotoLabelValue = "";
      this.campStartedPhotoLabelValue = "";
      this.selectedScheduleIndex = index;
      this.conductModel = this.modalService
      .open(content, {
        ariaLabelledBy: 'Schedule Conduct',
      });
    } else if(item.meetingHappenedOrReschedule == "Reschedule") {
      if(new Date() >= this.lastRescheduledDate) {
        this.commonService.commonErrorPopup('Alert', 'Rescheduling has been freezed.', 'md');
        setTimeout(() => {
          item.meetingHappenedOrReschedule = null;
        }, 100);
        return;
      }

      const scheduledDate = moment(item.scheduledDate,"DD-MM-YYYY").format("YYYY-MM-DDThh:mm:ss");
      if(scheduledDate < "2023-07-06 23:59:59") {
        this.commonService.commonErrorPopup('Alert', 'Schedules between 01-July-2023 and 06-July-2023 are freezed. Reschedule is not allowed.', 'md');
        setTimeout(() => {
          item.meetingHappenedOrReschedule = null;
        }, 100);
        return;
      }
      this.scheduleConductedForm.reset();
      item.noOfCitizensParticipated = null;
      item.noOfTokenGenerated = null;
      item.noOfServicesRegistered = null;
      item.noOfGrievancesRaised = null;
      item.noOfCertificatesIssued = null;
      item.noOfCCRCCardIssued = null;
      item.noOfMobileUpdated = null;
      item.campPhotos = null;
      this.campPhotoLabelValue = "";
      item.campStartedPhotos = null;
      this.campStartedPhotoLabelValue = "";
    } else if(item.meetingHappenedOrReschedule == "Started") {
      const year=  new Date().getFullYear();
      const month= new Date().getMonth() + 1;
      const day=  new Date().getDate();
      const dateFormat = day +"-"+  month +"-"+ year;
      const todayDate = moment(dateFormat,"DD-MM-YYYY").format("YYYY-MM-DDThh:mm:ss");
      const scheduledDate = moment(item.scheduledDate,"DD-MM-YYYY").format("YYYY-MM-DDThh:mm:ss");
      if(todayDate < scheduledDate) {
        this.commonService.commonErrorPopup('Alert', 'Schedule can be started on or after the scheduled date.', 'md');
        setTimeout(() => {
          item.meetingHappenedOrReschedule = this.teamListPrint[index].meetingHappenedOrReschedule;;
        }, 100);
        return;
      }
      this.scheduleConductedForm.reset();
      item.rescheduleDate = null;
      item.newrescheduleRemarks = null;
      item.noOfCitizensParticipated = null;
      item.noOfTokenGenerated = null;
      item.noOfServicesRegistered = null;
      item.noOfGrievancesRaised = null;
      item.noOfCertificatesIssued = null;
      item.noOfCCRCCardIssued = null;
      item.noOfMobileUpdated = null;
      item.campPhotos = null;
      this.campPhotoLabelValue = "";
      this.selectedScheduleIndex = index;
      this.startModel = this.modalService
      .open(startContent, {
        ariaLabelledBy: 'Schedule Start',
      });
    }
  }

  // selectSchedule(item: any) {
  //   this.teamList.forEach((item:any) => {
  //     item.isSelected = false;
  //   });
  //   item.isSelected = true;
  // }

  oncampPhotoFileSelected(fileEvent: any) {
    const appFormFile: File | null = fileEvent.target.files[0];
    this.campPhotoLabelValue = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
    this.scheduleConductedForm.controls['campPhoto'].setValidators([
      Validators.required, fileExtensionValidator('jpg,jpeg,png'), fileSizeValidator(fileEvent)
    ])
    this.scheduleConductedForm.controls['campPhoto'].updateValueAndValidity();
    if (this.f2.campPhoto.valid){
      this.fileUpload(appFormFile,this.f2['campPhoto'].value,fileEvent.target.files[0].name,'campPhoto', 'campPhoto', 'campPhotoLabel');
    }
  }

  clearFileUpload(name:string, label:string) {
    this.f2[name].reset();
    if (label === 'campPhotoLabel') {
      this.campPhotoLabelValue = 'No File Choosen';
    } 
  }

  fileUpload(file: any, filePath: string, fileName: string, documentType: string, name:string, label:string)  {
    const formData = new FormData();
      formData.append('File', file, fileName);
      formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
      console.log( formData,'formData');
    this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload,formData).subscribe({
      next: (responseData:any) => {
        if(typeof(responseData.result) !== 'string'){ 
          this.campPhotoFile  = {
            FileName: fileName,
            DocumentType: documentType,
            BlobRef : responseData['result'].blobRef
          };
        }else{
          if(name && label) {
            this.clearFileUpload(name, label);
          }
            this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
        }
      },
      error: (error) => {
        if(name && label) {
          this.clearFileUpload(name, label);
        }
        console.log('client side', error);
      }
    });
  }

  markAsConducted() {
    this.isSubmitted = true;
    if(this.scheduleConductedForm.valid) {
      if(this.selectedScheduleIndex >= 0) {
        this.teamList[this.selectedScheduleIndex].noOfCitizensParticipated = this.f2.noOfCitizensParticipated.value;
        this.teamList[this.selectedScheduleIndex].noOfTokenGenerated = this.f2.noOfTokenGenerated.value;
        this.teamList[this.selectedScheduleIndex].noOfServicesRegistered = this.f2.noOfServicesRegistered.value;
        this.teamList[this.selectedScheduleIndex].noOfGrievancesRaised = this.f2.noOfGrievancesRaised.value;
        this.teamList[this.selectedScheduleIndex].noOfCertificatesIssued = this.f2.noOfCertificatesIssued.value;
        this.teamList[this.selectedScheduleIndex].noOfCCRCCardIssued = this.f2.noOfCCRCCardIssued.value;
        this.teamList[this.selectedScheduleIndex].noOfMobileUpdated = this.f2.noOfMobileUpdated.value;
        this.teamList[this.selectedScheduleIndex].campPhotos = this.campPhotoFile?.BlobRef;
        this.teamList[this.selectedScheduleIndex].isLocalAction = true;
        this.conductModel.close();
        this.scheduleConductedForm.reset();
        this.isSubmitted = false;
        this.selectedScheduleIndex = -1;
        this.campPhotoLabelValue = "";
      }
    }
  }

  oncampStartedPhotoFileSelected(fileEvent: any) {
    const appFormFile: File | null = fileEvent.target.files[0];
    this.campStartedPhotoLabelValue = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
    this.scheduleStartedForm.controls['campStartedPhoto'].setValidators([
      Validators.required, fileExtensionValidator('jpg,jpeg,png'), fileSizeValidator(fileEvent)
    ])
    this.scheduleStartedForm.controls['campStartedPhoto'].updateValueAndValidity();
    if (this.f3.campStartedPhoto.valid){
      this.startFileUpload(appFormFile,this.f3['campStartedPhoto'].value,fileEvent.target.files[0].name,'campStartedPhoto', 'campStartedPhoto', 'campStartedPhotoLabel');
    }
  }

  clearStartFileUpload(name:string, label:string) {
    this.f3[name].reset();
    if (label === 'campStartedPhotoLabel') {
      this.campStartedPhotoLabelValue = 'No File Choosen';
    } 
  }

  startFileUpload(file: any, filePath: string, fileName: string, documentType: string, name:string, label:string)  {
    const formData = new FormData();
      formData.append('File', file, fileName);
      formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
      console.log( formData,'formData');
    this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload,formData).subscribe({
      next: (responseData:any) => {
        if(typeof(responseData.result) !== 'string'){ 
          this.campStartedPhotoFile  = {
            FileName: fileName,
            DocumentType: documentType,
            BlobRef : responseData['result'].blobRef
          };
        }else{
          if(name && label) {
            this.clearStartFileUpload(name, label);
          }
            this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
        }
      },
      error: (error) => {
        if(name && label) {
          this.clearStartFileUpload(name, label);
        }
        console.log('client side', error);
      }
    });
  }

  markAsStarted() {
    this.isStartSubmitted = true;
    if(this.scheduleStartedForm.valid) {
      if(this.selectedScheduleIndex >= 0) {
        this.teamList[this.selectedScheduleIndex].campStartedPhotos = this.campStartedPhotoFile?.BlobRef;
        this.teamList[this.selectedScheduleIndex].isLocalAction = true;
        this.startModel.close();
        this.scheduleStartedForm.reset();
        this.isStartSubmitted = false;
        this.selectedScheduleIndex = -1;
        this.campStartedPhotoLabelValue = "";
      }
    }
  }

  closeStartPopup(){
    this.scheduleStartedForm.reset();
    this.startModel.close();
    this.teamList[this.selectedScheduleIndex].meetingHappenedOrReschedule = this.teamListPrint[this.selectedScheduleIndex].meetingHappenedOrReschedule;
    this.isStartSubmitted = false;
    this.selectedScheduleIndex = -1;
    this.campStartedPhotoLabelValue = "";
  }

  closePopup(){
    this.scheduleConductedForm.reset();
    this.conductModel.close();
    this.teamList[this.selectedScheduleIndex].meetingHappenedOrReschedule = this.teamListPrint[this.selectedScheduleIndex].meetingHappenedOrReschedule;
    this.isSubmitted = false;
    this.selectedScheduleIndex = -1;
    this.campPhotoLabelValue = "";
  }
  submit() {
    console.log(this.teamList);
    this.finalSubmitted = true;

    const invalidRecords = this.teamList.filter((x:any) => x.meetingHappenedOrReschedule == 'Reschedule' 
                            && (!x.rescheduleDate || !x.newrescheduleRemarks?.trim() || this.commonService.formatDate(x.rescheduleDate) == "Invalid date"))
    if(invalidRecords.length > 0) {
      // this.invalidDateIndex = this.teamList.findIndex((x:any) => x.meetingHappenedOrReschedule == 'Reschedule' 
      // && (this.commonService.formatDate(x.rescheduleDate) == "Invalid date"))
      return;
    }

    const invalidvenues = this.teamList.filter((x:any) => (x.meetingHappenedOrReschedule == 'Started' || x.meetingHappenedOrReschedule == 'Reschedule' || x.meetingHappenedOrReschedule == 'Conducted' || x.rescheduleRemarks?.trim() )
                            && !x.venue?.trim())
                          console.log("invalidvenues", invalidvenues);
    if(invalidvenues.length > 0) {
      return;
    }

    if(this.teamList?.length === 0) {
      this.commonService.commonErrorPopup('Alert', 'Please schedule the teams to submit.', 'md');
    } else {

      let isValidPayload = true;
      this.teamList.forEach( (element:any) => {
        if(element.meetingHappenedOrReschedule == "Reschedule") {
          let isTeamExist: any = this.teamList.findIndex((item:any) => item.teamName === element.teamName && item.scheduledDate ===  moment(element.rescheduleDate,"DD-MM-YYYY").format("DD-MM-YYYY"));
          if(isTeamExist > -1) {
            isValidPayload = false;
          }
        }
      });
      if(!isValidPayload) {
        this.commonService.commonErrorPopup('Alert', 'The selected team is scheduled for other secretariat/cluster for the selected reschedule date already.', 'md');
        return;
      }

      let postData: any = {};
      let scheduleList:any = [];
      this.teamList.forEach((item: any) => {
        let temp: any = {};
        temp.districtCode = this.locationDetails?.neW_LGD_DIST_CODE?.toString();
        temp.mandalCode = this.locationDetails?.lgD_MANDAL_CODE?.toString();
        temp.secretariatName = this.isUrban ? item.secretariat?.join(',') : item.secretariat;
        temp.secretariatCode = this.isUrban ? item.secretariatCode?.join(',') : item.secretariatCode?.toString();
        temp.teamName = item.teamName;
        temp.scheduleDate = moment(item.scheduledDate,"DD-MM-YYYY").format("YYYY-MM-DDThh:mm:ss"),
        temp.venueDetails = item.venue?.trim(),
        temp.meetingHappenedOrReschedule = item.meetingHappenedOrReschedule,
        temp.rescheduleRemarks =  item.rescheduleRemarks?.trim(),
        temp.newrescheduleRemarks = item.newrescheduleRemarks?.trim(),
        temp.rescheduleDate = item.rescheduleDate ? moment(item.rescheduleDate,"DD-MM-YYYY").format("YYYY-MM-DDThh:mm:ss"): null,
        temp.noOfCitizensParticipated = item.noOfCitizensParticipated ? Number(item.noOfCitizensParticipated) : null;
        temp.noOfTokenGenerated = item.noOfTokenGenerated ? Number(item.noOfTokenGenerated) : null;
        temp.noOfServicesRegistered = item.noOfServicesRegistered ? Number(item.noOfServicesRegistered) : null;
        temp.noOfGrievancesRaised = item.noOfGrievancesRaised ? Number(item.noOfGrievancesRaised) : null;
        temp.noOfCertificatesIssued = item.noOfCertificatesIssued ? Number(item.noOfCertificatesIssued) : null;
        temp.noOfCCRCCardIssued = item.noOfCCRCCardIssued ? Number(item.noOfCCRCCardIssued) : null;
        temp.noOfMobileUpdated = item.noOfMobileUpdated ? Number(item.noOfMobileUpdated) : null;
        temp.campPhotos = item.campPhotos;
        temp.campStartedPhotos = item.campStartedPhotos;

       // temp.remarks = '';
       // temp.createdBy = this.userData.userPrincipalName;
        scheduleList.push(temp);
      });
      postData.createdBy = this.userData.userPrincipalName;
      postData.remarks = '';
      postData.teamScheduleList = scheduleList;
      this.commonService.postRequest(this.commonConstants.saveTeamScheduleDetails, postData).subscribe({
        next: (responseData: any) => {
          if(responseData.result?.isSuccess) {
            this.commonService.commonErrorPopup('Alert', 'The team schedule details are saved successfully.', 'md');
            this.getScheduleDetails();
          } else if(responseData.result?.isSuccess == false && responseData.result?.errorDescription) {
            this.commonService.commonErrorPopup('Alert', responseData.result?.errorDescription, 'md')
          } else {
            this.commonService.commonErrorPopup('Alert', 'An error occured while saving the data. Please try again.', 'md')
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
    }
  }
  getScheduleDetails() {
    this.finalSubmitted = false;
    this.teamList = [];
    this.commonService.getRequest(this.commonConstants.getTeamScheduleDetails + this.userData.userPrincipalName + "&isUrban=" + this.isUrban).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          let teamList: any = responseData.result.teamScheduleData;
          if(!this.isUrban && teamList.length > 0) {
            teamList.forEach((item:any) => {
              this.teamList.push({
                //isSelected: false,
                teamName: item.teamName,
                secretariatDisplay: item.secretariatName,
                secretariat: item.secretariatName,
                secretariatCode: Number(item.secretariatCode),
                scheduledDate: moment(item.scheduleDate,"YYYY-MM-DDThh:mm:ss[Z]").format("DD-MM-YYYY"),
                venue: item.venueDetails,
                meetingHappenedOrReschedule: (item.meetingHappenedOrReschedule == "Conducted" || item.meetingHappenedOrReschedule == "Started" ) ? item.meetingHappenedOrReschedule: null,
                rescheduleRemarks : item.rescheduleRemarks?.trim(),
                newrescheduleRemarks: null,
                rescheduleDate: item.rescheduleDate,
                isConducted: item.meetingHappenedOrReschedule == "Conducted" ? true : false,
                noOfCitizensParticipated: item.noOfCitizensParticipated,
                noOfServicesRegistered: item.noOfServicesRegistered,
                noOfGrievancesRaised: item.noOfGrievancesRaised,
                noOfCertificatesIssued: item.noOfCertificatesIssued,
                noOfCCRCCardIssued: item.noOfCCRCCardIssued,
                noOfMobileUpdated: item.noOfMobileUpdated,
                campPhotos: item.campPhotos,
                campStartedPhotos: item.campStartedPhotos,
                isVenueFreezed: (item.venueDetails && item.venueDetails.trim() != "" && new Date() >= new Date(new Date(item.scheduleDate).setDate(new Date(item.scheduleDate).getDate()-2))) ? true : false
              });
            });
            console.log('teamList', this.teamList);
            this.secretariatList = this.secretariatList.filter((item:any) => {
              return !this.teamList.find((el:any) => el.secretariatCode === item.secretariaT_CODE);
            });
            console.log('secretariatList', this.secretariatList);
          } else if(this.isUrban && teamList.length > 0) {
            teamList.forEach((item:any) => {
              let secCodeArray: any = item.secretariatCode.split(',');
              let secNameArray: any = item.secretariatName.split(',');
              let secList: any = [];
              secCodeArray.forEach((item:any, index: any)=> {
                let temp: any = {};
                temp.secretariaT_CODE = Number(item);
                temp.secretariaT_NAME = secNameArray[index];
                secList.push(temp);
              })
              this.teamList.push({
                //isSelected: false,
                teamName: item.teamName,
                secretariatDisplay: item.secretariatName,
                secretariat: item.secretariatName.split(','),
                secretariatCode: item.secretariatCode.split(','),
                scheduledDate: moment(item.scheduleDate,"YYYY-MM-DDThh:mm:ss[Z]").format("DD-MM-YYYY"),
                selectedSecList: secList,
                venue: item.venueDetails,
                meetingHappenedOrReschedule: (item.meetingHappenedOrReschedule == "Conducted" || item.meetingHappenedOrReschedule == "Started" ) ? item.meetingHappenedOrReschedule: null,
                rescheduleRemarks : item.rescheduleRemarks?.trim(),
                newrescheduleRemarks: null,
                rescheduleDate: item.rescheduleDate,
                isConducted: item.meetingHappenedOrReschedule == "Conducted" ? true : false,
                noOfCitizensParticipated: item.noOfCitizensParticipated,
                noOfServicesRegistered: item.noOfServicesRegistered,
                noOfGrievancesRaised: item.noOfGrievancesRaised,
                noOfCertificatesIssued: item.noOfCertificatesIssued,
                noOfCCRCCardIssued: item.noOfCCRCCardIssued,
                noOfMobileUpdated: item.noOfMobileUpdated,
                campPhotos: item.campPhotos,
                campStartedPhotos: item.campStartedPhotos,
                isVenueFreezed: (item.venueDetails && item.venueDetails.trim() != "" && new Date() >= new Date(new Date(item.scheduleDate).setDate(new Date(item.scheduleDate).getDate()-2))) ? true : false
              });
              let addedSecList: any = [];
              this.teamList.forEach((item:any) => {
                addedSecList = [...addedSecList , ...item.selectedSecList];
              })
              this.secretariatList = this.secretariatList.filter((item:any) => {
                return !addedSecList.find((el:any) => el.secretariaT_CODE === item.secretariaT_CODE);
              });
            })
          }
        } else {
          this.teamList = [];
        }
        this.teamListPrint = JSON.parse(JSON.stringify(this.teamList));
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  onChangeSecretariat() {

  }
  onScheduleDateSelect(event:any) {

  }
  printSchedule() {
    
    let Pagelink = 'about:blank';
    var pwa = window.open(Pagelink, '_new');
    pwa?.document.open();
    pwa?.document.write(this.preparePrintFormat());
    pwa?.document.close();
  }
  getAadharNo(encryptedAadhar:any) {
    return this.commonService.RSADecrypt(encryptedAadhar);
  }
  preparePrintFormat() {
    const printContent: HTMLElement | null =
      document.getElementById('teamSchedule');
    const teamMemberDetails: HTMLElement | null =
    document.getElementById('teamMemberDetails');
    let htmlData: any = '';
    let printdata = printContent?.innerHTML;
    let printTeamData = teamMemberDetails?.innerHTML;
    htmlData +=
      '<html><head><title>Team Schedule Details</title> <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">';
    htmlData +=
      `<style type='text/css' media='print'> @media print{@page{size: auto; margin: 0;} 
      .tableCls td, .tableCls th {padding: 5px;border: 1px solid #ccc;} table {width: 100%;} body{margin: 15px;}
        </style> <script>function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'>`;

    htmlData += printdata;
    htmlData += printTeamData;
    htmlData += '</body></html>';

    return htmlData;
  }
  getTeamDetails() {
    this.commonService.getRequest(this.commonConstants.getJSTeamDetails + this.userData.userPrincipalName).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          let teamData: any = responseData.result?.jsTeamDetailsData;
          let teamOneData: any = teamData.filter((item: any) => item.teamName?.trim() === 'Team 1')
          let teamTwoData: any = teamData.filter((item: any) => item.teamName?.trim() === 'Team 2')
          let teamThreeData: any = teamData.filter((item: any) => item.teamName?.trim() === 'Team 3')
          let teamFourData: any = teamData.filter((item: any) => item.teamName?.trim() === 'Team 4')
          let teamFiveData: any = teamData.filter((item: any) => item.teamName?.trim() === 'Team 5')
          let teamSixData: any = teamData.filter((item: any) => item.teamName?.trim() === 'Team 6')
          let teamSevenData: any = teamData.filter((item: any) => item.teamName?.trim() === 'Team 7')
          let teamEightData: any = teamData.filter((item: any) => item.teamName?.trim() === 'Team 8')
          let teamNineData: any = teamData.filter((item: any) => item.teamName?.trim() === 'Team 9')
          let teamTenData: any = teamData.filter((item: any) => item.teamName?.trim() === 'Team 10')
          this.buildTeamDetails = [];
          if(teamOneData.length > 0) {
            let temp: any = {};
            temp.teamName = 'Team 1';
            temp.teamMemberData = teamOneData;
            this.buildTeamDetails.push(temp);
          }
          if(teamTwoData.length > 0) {
            let temp: any = {};
            temp.teamName = 'Team 2';
            temp.teamMemberData = teamTwoData;
            this.buildTeamDetails.push(temp);
          }
          if(teamThreeData.length > 0) {
            let temp: any = {};
            temp.teamName = 'Team 3';
            temp.teamMemberData = teamThreeData;
            this.buildTeamDetails.push(temp);
          }
          if(teamFourData.length > 0) {
            let temp: any = {};
            temp.teamName = 'Team 4';
            temp.teamMemberData = teamFourData;
            this.buildTeamDetails.push(temp);
          }
          if(teamFiveData.length > 0) {
            let temp: any = {};
            temp.teamName = 'Team 5';
            temp.teamMemberData = teamFiveData;
            this.buildTeamDetails.push(temp);
          }
          if(teamSixData.length > 0) {
            let temp: any = {};
            temp.teamName = 'Team 6';
            temp.teamMemberData = teamSixData;
            this.buildTeamDetails.push(temp);
          }
          if(teamSevenData.length > 0) {
            let temp: any = {};
            temp.teamName = 'Team 7';
            temp.teamMemberData = teamSevenData;
            this.buildTeamDetails.push(temp);
          }
          if(teamEightData.length > 0) {
            let temp: any = {};
            temp.teamName = 'Team 8';
            temp.teamMemberData = teamEightData;
            this.buildTeamDetails.push(temp);
          }
          if(teamNineData.length > 0) {
            let temp: any = {};
            temp.teamName = 'Team 9';
            temp.teamMemberData = teamNineData;
            this.buildTeamDetails.push(temp);
          }
          if(teamTenData.length > 0) {
            let temp: any = {};
            temp.teamName = 'Team 10';
            temp.teamMemberData = teamTenData;
            this.buildTeamDetails.push(temp);
          }
          console.log('buildTeamDetails', this.buildTeamDetails);
          
        } else {
          this.buildTeamDetails =[]
        }
      },
      error: (error) => {
        this.buildTeamDetails =[]
        console.log('client side', error);
      },
    });
   } 
}
