import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-feedback-status',
  templateUrl: './feedback-status.component.html',
  styleUrls: ['./feedback-status.component.css']
})
export class FeedbackStatusComponent implements OnInit {
  status: any;
  paramsReceived: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.paramsReceived = true;
      if (params && params.status == 'success') {
        this.status = 'మీ ఫీడ్ బ్యాక్ తెలియ చేసినందుకు ధన్యవాదములు!';
      } else if (params && params.status == 'feedback-submitted') {
        this.status = 'మీ ఫీడ్ బ్యాక్ ఇప్పటికే అందించబడింది!';
      } else if (params && params.status == 'fail') {
        this.status = 'page-not-found';
      }  else {
        this.status = 'page-not-found';
      }
    });
  }

  ngOnInit(): void {
    
  }

}
