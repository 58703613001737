<!-- <ng-container *ngIf="!loginDisplay">
<app-header></app-header> -->
<div class="docPage">
    <form [formGroup]="form">
        <div class="row">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 offset-lg-4 offset-md-4">
                <label class="labelCls">Application Number:<span class="mandatoryCls">*</span></label>
                <input type="text" name="applicationNo"  formControlName="applicationNo" class="inputCls" required
                [ngClass]="{ 'is-invalid':submitted && f.applicationNo.errors }" maxlength="20" (keypress)="onAppNoChange()" (paste)="onAppNoChange()">
                <div *ngIf="submitted && f.applicationNo.errors" class="invalid-feedback ">
                    <div *ngIf="f.applicationNo.errors.required || f.applicationNo.errors.whiteSpaceValidate">Please enter Application Number</div>
                    <div *ngIf="f.applicationNo.errors.pattern">Please enter valid Application Number</div>
                </div>
            </div>
            <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-4">
            <div class="btnHolder">
                <button  class="greenBtnCls" (click)="getDocuments()">Get Documents</button>
            </div>
            </div>
        </div>
    </form>
        
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3" *ngIf="showDocuments">
        <div class="table-responsive tableFixHead">
            <h6 class="text-center">Application No: {{reqId}}</h6>
            <table class="tableCls" >
                <thead> 
                    <tr>
                        <th>S.No</th>
                        <th>File Name</th>
                    </tr>
                </thead>
                <tbody *ngIf="fileList && fileList.length > 0">
                    <tr *ngFor="let file of fileList;let i = index;">
                        <td>{{i + 1}}</td>
                        <td><div class="valueCls"><a (click)="viewFile(file.blobRef, file.fileName)">{{file.fileName}}</a></div></td>
                    </tr>
                </tbody>
                <tbody *ngIf="fileList && fileList.length === 0">
                    <tr>
                        <td colspan="2" class="text-center">No documents found.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<!-- </ng-container> -->