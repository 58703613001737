import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
@Component({
  selector: 'app-secreteriate-pan-updated-report',
  templateUrl: './secreteriate-pan-updated-report.component.html',
  styleUrls: ['./secreteriate-pan-updated-report.component.css']
})
export class SecreteriatePanUpdatedReportComponent implements OnInit {
  form: FormGroup;
  districts: any[] = [];
  mandals: any[] = [];
  secreteriates: any[]= [];
  format: any;
  formatTypes: any[]= [];
  totalItems:any=[];
  items: any[] = [];
  showReports:Boolean=false;
  submitted:Boolean = false;
  page = 1;
  pageSize = 10;
  noOfCol: any = 8;
  commonConstants : any = CommonConstants;
  @ViewChild('TABLE') table!: ElementRef;

  constructor(private formBuilder: FormBuilder, private commonService: CommonService,private router: Router) { 
  this.form = this.formBuilder.group({
    district: [null],
    RUFlag:[null],
    mandal: [null],
    secreteriate :[null]
  });
  this.format = 'District Wise';
  this.formatTypes = [{name: 'District Wise', isActive: true},
                      {name:'Mandal Wise', isActive: false},
                      {name:'Secretariat Wise', isActive: false}
]
 }

ngOnInit(): void {    
  this.format = 'District Wise';
  this.form.patchValue({
    district:'All',
    RUFlag:'All',
    mandal:'All',
    secreteriate: 'All',      
  });
  this.getDistricts();
  this.getReportData();
}
get f() { return this.form.controls; }
getDistricts() {
  this.commonService.getRequest(this.commonConstants.getCSPDistricts).subscribe({
    next: (responseData: any) => {
      if(responseData.result?.isSuccess) {
        this.districts = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'districtName');
      } else {
        this.districts = [];
        this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
      }
    },
    error: (error) => {
      console.log('client side', error);
    }
});
}

onRuralUrbanChange() {
  this.getMandals();
  
}

onDistrictChange() {
  this.showReports = false;
  this.mandals = [];
  this.secreteriates = [];
  this.f.mandal.patchValue('All');
  this.f.secreteriate.patchValue('All');  
  this.getMandals();

}
getMandals(){
  this.showReports = false;
  this.mandals = [];
  this.secreteriates = [];
  this.f.mandal.patchValue('All');
  this.f.secreteriate.patchValue('All'); 
  let RUFlag: any = this.f['RUFlag'].value === 'All' ? '' : this.f['RUFlag'].value;
  this.commonService.getRequest(this.commonConstants.getCSPMandalsRuralUrban + '?DistrictCode=' + this.f['district'].value.districtCode + '&RuralOrUrbanFlag=' + RUFlag).subscribe({
    next: (responseData: any) => { 
      if(responseData.result?.isSuccess) {
        this.mandals = this.commonService.sortItems(responseData.result?.getMandalDetails, 'mandalName');
      } else {
        this.mandals = [];
        this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
      }
    },
    error: (error) => {
      console.log('client side', error);
    }
});
}

onMandalChange() {
  this.showReports = false;
  this.secreteriates = [];
  this.f.secreteriate.patchValue('All');
  this.commonService.getRequest(this.commonConstants.getCSPSecretariat + '?MandalCode=' + this.f.mandal.value.mandalCode).subscribe({
    next: (responseData: any) => { 
      if(responseData.result?.isSuccess) {
        this.secreteriates = this.commonService.sortItems(responseData.result?.getSecretariatDetails, 'secretariatName');
      } else {
        this.secreteriates = [];
        this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
      }
    },
    error: (error) => {
      console.log('client side', error);
    }
});
}

onChangeFormat(item: any) {
  this.showReports = false;
  this.formatTypes.map((item:any) => {
    item.isActive = false;
    return item;
  });
  item.isActive = true;
  this.format = item.name;
  this.getFormat();
}

getFormat(): any {
    if (this.format === 'District Wise' ) {
    this.noOfCol = 6;
    return this.noOfCol;
  } else if (this.format === 'Mandal Wise') {
    this.noOfCol = 7;
    return this.noOfCol;
  } else {
    this.noOfCol = 7;
    return this.noOfCol;
  }
}
getReportData(from?: any): any {    
      this.page = 1;       
      let district = this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode;
      let RUFlag = this.f.RUFlag.value === 'All' ? 'All' : this.f.RUFlag.value;
      let mandal = this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode;
      let Secreteriate = this.f.secreteriate.value === 'All' ? 'All' : this.f.secreteriate.value?.secretariatCode;
   
      let postData: any = '?FlagName='+ this.format +'&DistrictId=' + district + '&RURBAN=' + RUFlag + '&MandalId=' + mandal +'&SecCode=' + Secreteriate  ;
      this.commonService.getRequest(this.commonConstants.getSecPanUpdatedReport + postData).subscribe({
        next: (responseData: any) => { 
          console.log(typeof(responseData?.result),'result man')
          if(typeof(responseData?.result) !== 'string') {
              this.showReports = true;
              this.items = responseData?.result;
              this.items = this.items.filter(item => item.totalflag !== 'Y')
              this.totalItems = responseData?.result.filter((item: { totalflag: string; }) => item.totalflag === 'Y')
            } else {
            this.items = [];
            this.showReports= true;
            this.totalItems = [];
            //this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    }
    downloadExcel() {
      let fileName: any = 'SECRETARIAT PAN UPDATED REPORT' //+ this.f.fromDate.value +' to '+ this.f.toDate.value;
      const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
      let wscols = [
        {wpx:50},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100}
    ];
    
    ws['!cols'] = wscols;
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'SECRETARIAT PAN UPDATED REPORT');
      
      /* save to file */
      XLSX.writeFile(wb, fileName + '.xlsx');
    }
    saveAsExcelFile(buffer: any, fileName: string) {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    }
    goToHome() {
      this.router.navigate(['/home']);
    }
  
  
}

