import { Component, ElementRef,OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CommonService, WhitespaceValidator } from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import { NgbNavChangeEvent, NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { fileExtensionValidator, fileSizeValidator} from 'src/shared/validators/file-validator';
@Component({
  selector: 'app-address-certificate-for-aadhar-enrollment',
  templateUrl: './address-certificate-for-aadhar-enrollment.component.html',
  styleUrls: ['./address-certificate-for-aadhar-enrollment.component.css'],
  providers: [
    NgbNavConfig,
  ]
})
export class AddressCertificateForAadharEnrollmentComponent implements OnInit {
  
  @ViewChild('nav')
  nav: any;
  @ViewChild('aadharLabel')
  aadharLabel!: ElementRef;  
  @ViewChild('otherDocumentLabel')
  otherDocumentLabel!: ElementRef;
  addressForm: FormGroup;
  submitted: Boolean = false;
  commonConstants : any = CommonConstants;
  commonFormSubmitted: Boolean = false;
  fileList: { FileName: string; DocumentType: string; BlobRef: string }[] = [];
  workflowDistricts: any = [];
  workflowMandals: any = [];
  workflowSecretariats: any = [];
  isUrban: Boolean = false;
  designations: any = [];
  isCheckUrbanFlag: Boolean = false;
  requisitionType: any;
  constructor(private formBuilder: FormBuilder, private commonService: CommonService,private config: NgbNavConfig, private activatedRoute: ActivatedRoute, private router: Router) { 
    this.addressForm = this.formBuilder.group({
       commonForm: [],
       landmark: ['',Validators.required],
       area:['',Validators.required],
       street: ['',Validators.required],
       village:['',Validators.required],
       panchayatName:['',Validators.required],
       purposeOfCertificate:['',Validators.required],
       aadhar:[null,Validators.required],
       otherDocument:[null],       
       consent: [null, [Validators.required]],
       consent2: [null, [Validators.required]],
       workflowDistrict: [null, [Validators.required]],
       workflowMandal: [null, [Validators.required]],
       workflowSecretariat: [null, [Validators.required]],
       workflowDesignation: [null, [Validators.required]]
  });
  config.destroyOnHide = false;
  config.roles = false;
  }
  get f() {
    return this.addressForm.controls;
  }
  ngOnInit(): void {
    this.getDistricts();
  }

  goToNextTab() {
    this.commonFormSubmitted = true;
    if (this.f.commonForm.valid) {
      this.nav.select(2);
    }
  }
  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.commonFormSubmitted = true;
    if (changeEvent.nextId === 2 && !this.f.commonForm.valid) {
      changeEvent.preventDefault();
    }
  }
  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getDistricts).subscribe({
      next: (responseData: any) => { 
        this.workflowDistricts = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onDistrictChange() {
    this.commonService.getRequest(this.commonConstants.getMandals + this.f.workflowDistrict.value.id).subscribe({
      next: (responseData: any) => { 
        this.workflowMandals = this.commonService.sortData(responseData.result);
        this.workflowSecretariats = [];
        this.designations = [];
        this.f.workflowMandal.patchValue(null);
        this.f.workflowSecretariat.patchValue(null);
        this.f.workflowDesignation.patchValue(null);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onMandalChange() {
    this.commonService.getRequest(this.commonConstants.getVillages + this.f.workflowMandal.value.id).subscribe({
      next: (responseData: any) => { 
          this.workflowSecretariats = this.commonService.sortData(responseData.result);
          this.f.workflowSecretariat.patchValue(null);
          this.designations = [];
          this.f.workflowDesignation.patchValue(null);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onChangeSecretariat() {
    this.designations = [];
    this.f.workflowDesignation.patchValue(null);
    this.commonService
    .getRequest(this.commonConstants.checkRuralUrban + this.f.workflowSecretariat.value.entityCode)
    .subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.isCheckUrbanFlag = true;
          this.isUrban = responseData.result?.ruralUrbanFlag === 'U' ? true : false;
          if(this.isUrban) {
            this.designations = ['Ward Revenue Secretary', 'Ward Admin Secretary'];
          } else {
            this.designations = ['Village Revenue Officer', 'Panchayat Secretary'];
          }
        } else {
          this.isCheckUrbanFlag = false;
          this.commonService.commonErrorPopup('Alert', 'An error occured while getting rural/urban details.', 'md');
        }
        
      },
      error: (error) => {
        this.isCheckUrbanFlag = false;
        console.log('client side', error);
      },
    });
  }
  onChangeDesignation() {
    if(this.f.workflowDesignation.value === 'Village Revenue Officer') {
      this.requisitionType = 'AddressCertificateForAadharEnrollment';
    } else if(this.f.workflowDesignation.value === 'Panchayat Secretary') {
      this.requisitionType = 'AddressCertificateForAadharEnrollment@1';
    } else if(this.f.workflowDesignation.value === 'Ward Revenue Secretary') {
      this.requisitionType = 'AddressCertificateForAadharEnrollment@2';
    } else {
      this.requisitionType = 'AddressCertificateForAadharEnrollment@3';
    }
  }
  onFileSelected(fileEvent: any, controlName: any, labelName:any) {   
    const file: File | null = fileEvent.target.files[0]; 
    if(labelName === 'aadharLabel')   {
      this.aadharLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0]?.name : 'No File Choosen';
      if(this.aadharLabel.nativeElement.innerText !=="No File Choosen"){{}
      this.addressForm.controls['aadhar']?.setValidators([
        fileExtensionValidator('pdf'),
        fileSizeValidator(fileEvent),
      ]);
      this.addressForm.controls['aadhar']?.updateValueAndValidity();
    }
  }
    else if(labelName === 'otherDocumentLabel'){
      this.otherDocumentLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0]?.name : 'No File Choosen';
     if(this.otherDocumentLabel.nativeElement.innerText !=="No File Choosen"){{}
      this.addressForm.controls['otherDocument']?.setValidators([
        fileExtensionValidator('pdf'),
        fileSizeValidator(fileEvent),
      ]);
      this.addressForm.controls['otherDocument']?.updateValueAndValidity();
    }
    } 
    
      let fieldcontrol: any = this.addressForm.get(controlName);  
   
    if (fieldcontrol?.valid){
      this.fileUpload(file, this.f['aadhar']?.value, fileEvent.target.files[0]?.name,controlName,labelName);
    }
    


  }
 
  fileUpload(file: any, filePath: string, fileName: string, documentType: string, label:string) {
    const formData = new FormData();
    formData.append('File', file, fileName);
    formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
    this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload, formData).subscribe({
        next: (responseData: any) => {
          if(typeof(responseData.result) !== 'string'){
            let index = this.fileList.map((item) => item.DocumentType).indexOf(documentType);
            if (index > -1) {
              this.fileList.splice(index, 1);
            }
            this.fileList.push({
              FileName: fileName,
              DocumentType: documentType,
              BlobRef: responseData['result'].blobRef,
            });
          } else{
            if(documentType && label) {
              this.clearFileUpload(documentType, label);
            }
            this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
                    }
          console.log(this.fileList,'FileList');
        },
        error: (error) => {
          if(documentType && label) {
            this.clearFileUpload(documentType, label);
          }
          console.log('client side', error);
        },
      });
  }

 
  updateFileUpload(id: any) {
    let fileData = {
      FileList: this.fileList,
      RequisitionId: id
    }
    if(this.fileList.length > 0){
    
    this.commonService.postRequest(this.commonConstants.updateFileUpload,fileData).subscribe({
      next: (resData:any) => { 
        console.log(resData,'File Upload  Respose data');       
       if(resData.result?.updateResult == 'Success') {
        this.showPayment(id);
       }else{
        this.commonService.commonErrorPopup('Alert', 'An error occured while updating file list in the requisition.', 'md');
      }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }else{
    this.commonService.commonErrorPopup('Alert', 'Aadhar is missed, Please re-upload ', 'md');
  }
  }
 
  clearFileUpload(name: string, label: string) {    
    this.f[name]?.reset(); 
    if(label === 'aadharLabel') {
      this.aadharLabel.nativeElement.innerText = 'No File Choosen';
      this.addressForm.controls[name].setValidators([
        Validators.required 
      ]);
     
    } else if(label === 'otherDocumentLabel') {
      this.f[name]?.clearValidators();      
      this.otherDocumentLabel.nativeElement.innerText = 'No File Choosen';
    } 
    this.f[name]?.updateValueAndValidity();
  }
  submit(){
    if(this.addressForm.valid){
      this.submitted = false;
       this.saveRequisition();
    }  else{
      this.submitted = true;
    }

  }
  saveRequisition() {
    let dateFormat = this.commonService.formatDate(this.f['commonForm'].value['dob']);
    let postData: any = {
      requisitionHeader: {
        requisitionType: this.requisitionType,
        department: 'RV',
        applicantInfo: {
          id: '',
          aadhaarNumber: this.f['commonForm'].value['encryptedAadharNo'].trim(), // CryptoJS.AES.encrypt(this.f['commonForm'].value['aadharNo'].trim(), this.commonConstants.encryptDecryptPassword.trim()).toString(), //this.f['commonForm'].value['aadharNo'],
          personNames: [
            {
              languageCode: 'en-in',
              firstName: this.f['commonForm'].value['firstName'],
              middleName: this.f['commonForm'].value['middleName'],
              lastName: this.f['commonForm'].value['lastName'],
              fatherHusbandName: this.f['commonForm'].value['fatherHusName'],
            },
          ],
          gender: this.f['commonForm'].value['gender'],
          birthDate: dateFormat,
          caste: this.f['commonForm'].value['caste'].name,
          religion: this.f['commonForm'].value['religion'].name,
          qualification: this.f['commonForm'].value['qualification'].name,
          maritalStatus: this.f['commonForm'].value['maritalStatus'],
          addresses: [
            {
              addressType: 'present',
              doorNo: this.f['commonForm'].value['preDoorNo'],
              streetName: this.f['commonForm'].value['preStreet'],
              villageWard: this.f['commonForm'].value['preVillage'].entityCode,
              mandalMunicipality:  this.f['commonForm'].value['preMandal'].entityCode,
              district: this.f['commonForm'].value['preDistrict'].entityCode,
              pinCode: this.f['commonForm'].value['prePinCode'],
              postalOfficeCode: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].entityCode : "",
              postalOfficeName: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].name : "",
              postalVillageCode: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].entityCode : "",
              postalVillageName: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].name : ""
            },
            {
              addressType: 'permanent',
              doorNo: this.f['commonForm'].value['permDoorNo'],
              streetName: this.f['commonForm'].value['permStreet'],
              villageWard: this.f['commonForm'].value['permVillage'].entityCode,
              mandalMunicipality: this.f['commonForm'].value['permMandal'].entityCode,
              district: this.f['commonForm'].value['permDistrict'].entityCode,
              pinCode: this.f['commonForm'].value['permPinCode'],
              postalOfficeCode: this.f['commonForm'].value['permPostalOffice'] !==  null ? this.f['commonForm'].value['permPostalOffice'].entityCode : "",
              postalOfficeName: this.f['commonForm'].value['permPostalOffice'] !== null ? this.f['commonForm'].value['permPostalOffice'].name : "",
              postalVillageCode: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].entityCode : "",
              postalVillageName: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].name : ""
            },
            {
              addressType:"workflow",
              doorNo: "1",
              streetName: null,
              villageWard: this.f.workflowSecretariat.value.entityCode,
              mandalMunicipality: this.f.workflowMandal.value.entityCode,
              district: this.f.workflowDistrict.value.entityCode,
              pinCode: "523445"
            }
          ],
          rationCardNo: '',
          deliveryType: this.f['commonForm'].value['deliveryType'],
          emailAddress: this.f['commonForm'].value['email'],
          mobileNumber: this.f['commonForm'].value['mobileNo'],
          isWhatsAppAvailable: this.f['commonForm'].value['isWhatsAppAvailable'],
          isDataFromAadhar: this.f['commonForm'].value['isDataFromAadhar']
        },
        remarks: '',
      },
      requisitionData: {
        landMark: this.f.landmark.value,
        area:this.f.area.value,
        purposeOfCertificate:this.f.purposeOfCertificate.value,
        panchayatWardName:this.f.panchayatName.value,
        street:this.f.street.value,
        village:this.f.village.value,
        workflowDistrict: this.f.workflowDistrict.value.entityCode,
        workflowMandal: this.f.workflowMandal.value.entityCode,
        workflowSecretariat: this.f.workflowSecretariat.value.entityCode,
        workflowDesignation: this.f.workflowDesignation.value,
        workflowDistrictName: this.f.workflowDistrict.value.name,
        workflowMandalName: this.f.workflowMandal.value.name,
        workflowSecretariatName: this.f.workflowSecretariat.value.name
      }
    };

    this.commonService.postRequest(this.commonConstants.addressCertificateForAadharEnrollment, postData).subscribe({
        next: (responseData: any) => {
         this.updateFileUpload(responseData['result'].id)
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  showPayment(requisionId: any) {
    const queryParams: Params = { id: requisionId,department: 'RV',type: this.requisitionType};
    this.router.navigate(['/payment-gateway'], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }
}
