import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-rofr-mutation-for-transactions-succession-view',
  templateUrl: './rofr-mutation-for-transactions-succession-view.component.html',
  styleUrls: ['./rofr-mutation-for-transactions-succession-view.component.css']
})
export class RofrMutationForTransactionsSuccessionViewComponent implements OnInit {

  constructor(private commonService: CommonService) { }

  commonConstants : any = CommonConstants;
  applicantAadharNumber: any;
  deceasedAadharNumber: any;
  plotIds: any;
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  @Output() viewImg = new EventEmitter();

  ngOnInit(): void {
    this.decryptAadharNum(this.certificateInfo?.rofrApplicantDetails.applicantAadharNo, "applicant");
    this.decryptAadharNum(this.certificateInfo?.deceasedPattadarAadhar, "deceasedPattadar");
    this.fetchPlotId();
  }

  decryptAadharNum(aadhar: string, whose: string) {
    this.commonService.getRequest(this.commonConstants.cryptoDecryptValue + encodeURIComponent(aadhar)).subscribe({
      next: (responseData: any) => {
        if (whose == 'applicant') {
          this.applicantAadharNumber  = responseData.result.rawValue;
        } else if (whose == 'deceasedPattadar') {
          this.deceasedAadharNumber = responseData.result.rawValue;
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  fetchPlotId() {
    let plotIds: any[] = [];
    plotIds = this.certificateInfo?.rofrLandDetails.map((item: any) => {
      return item.plotId;
    });
    this.plotIds = plotIds.toString();
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
  viewImgFile(file: any) {
    this.viewImg.emit(file);
  }

}
