import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IntegratedCertificateViewComponent } from './integrated-certificate-view/integrated-certificate-view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ObcCertificateViewComponent } from './obc-certificate-view/obc-certificate-view.component';
import { IncomeCertificateViewComponent } from './income-certificate-view/income-certificate-view.component';
import { FamilyMemberCertificateViewComponent } from './family-member-certificate-view/family-member-certificate-view.component';
import { PossessionCertificateViewComponent } from './possession-certificate-view/possession-certificate-view.component';
import { NoEarningCertificateViewComponent } from './no-earning-certificate-view/no-earning-certificate-view.component';
import { LateRegCertificateViewComponent } from './late-reg-certificate-view/late-reg-certificate-view.component';
import { IncomeEwsCertificateViewComponent } from './income-ews-certificate-view/income-ews-certificate-view.component';
import { IssueSmallFarmerCertificateViewComponent } from './issue-small-farmer-certificate-view/issue-small-farmer-certificate-view.component';
import { FLineCertificateViewComponent } from './f-line-certificate-view/f-line-certificate-view.component';
import { AgricultureIncomeCertificateViewComponent } from './agriculture-income-certificate-view/agriculture-income-certificate-view.component';
import { EncumbranceCertificateViewComponent } from './encumbrance-certificate-view/encumbrance-certificate-view.component';
import { FmbCopyViewComponent } from './fmb-copy-view/fmb-copy-view.component';
import { CrackerLicenceApplicationComponent } from './cracker-licence-application/cracker-licence-application.component';
import { IssueOfOccupancyRightsCertificatesForInamLandsViewComponent } from './issue-of-occupancy-rights-certificates-for-inam-lands-view/issue-of-occupancy-rights-certificates-for-inam-lands-view.component';
import { PermissionOfDiggingOfBoreWellForAgriculturalPurposeViewComponent } from './permission-of-digging-of-bore-well-for-agricultural-purpose-view/permission-of-digging-of-bore-well-for-agricultural-purpose-view.component';
import { ChangeOfNameCertificateViewComponent } from './change-of-name-certificate-view/change-of-name-certificate-view.component';
import { NoPropertyCertificateViewComponent } from './no-property-certificate-view/no-property-certificate-view.component';
import { RenewalCinemaLicenceViewComponent } from './renewal-cinema-licence-view/renewal-cinema-licence-view.component';
import { IssueofnocForstoringofpetroleumproductsCertificateViewComponent } from './issueofnoc-forstoringofpetroleumproducts-certificate-view/issueofnoc-forstoringofpetroleumproducts-certificate-view.component';
import { ExtractofnocExplosiveorpetroleumCerificateComponent } from './extractofnoc-explosiveorpetroleum-cerificate/extractofnoc-explosiveorpetroleum-cerificate.component';
import { ExtractOfHousePattaViewComponent } from './extract-of-house-patta-view/extract-of-house-patta-view.component';
import { AdditionOfSurveyNoViewComponent } from './addition-of-survey-no-view/addition-of-survey-no-view.component';
import { OldAdangalViewComponent } from './old-adangal-view/old-adangal-view.component';
import { LandConversionViewComponent } from './land-conversion-view/land-conversion-view.component';
import { ModificationsIn22AListViewComponent } from './modifications-in22-alist-view/modifications-in22-alist-view.component';
import { ApDottedLandsViewComponent } from './ap-dotted-lands-view/ap-dotted-lands-view.component';
import { HousingSiteComponent } from './housing-site/housing-site.component';
import { ROR1BViewComponent } from './ror1-bview/ror1-bview.component';
import { ComputerisedAdangalViewComponent } from './computerised-adangal-view/computerised-adangal-view.component';
import { PattadarMobilenoSeedingViewComponent } from './pattadar-mobileno-seeding-view/pattadar-mobileno-seeding-view.component';
import { ReissuanceViewComponent } from './reissuance-view/reissuance-view.component';
import { TitledeedCumPassbookViewComponent } from './titledeed-cum-passbook-view/titledeed-cum-passbook-view.component';
import { PattaSubdivisionAutoMutationViewComponent } from './patta-subdivision-auto-mutation-view/patta-subdivision-auto-mutation-view.component';
import { PrintingofTitledeedViewComponent } from './printingof-titledeed-view/printingof-titledeed-view.component';
import { RectificationofEntriesViewComponent } from './rectificationof-entries-view/rectificationof-entries-view.component';
import { MutationtitledeedViewComponent } from './mutationtitledeed-view/mutationtitledeed-view.component';
import { EPassbookViewComponent } from './epassbook-view/epassbook-view.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxBarcodeModule } from 'ngx-barcode';
import { MutationCorrectionsResurveyViewComponent } from './mutation-corrections-resurvey-view/mutation-corrections-resurvey-view.component';
import { HouseholdSplitViewComponent } from './household-split-view/household-split-view.component';
import { PattadarAadharSeedLpmRecordViewComponent } from './pattadar-aadhar-seed-lpm-record-view/pattadar-aadhar-seed-lpm-record-view.component';
import { LandConversionAgriViewComponent } from './land-conversion-agri-view/land-conversion-agri-view.component';
import { MutationTransactionResurveyViewComponent } from './mutation-transaction-resurvey-view/mutation-transaction-resurvey-view.component';
import { IssuanceOfPassbooksLpmViewComponent } from './issuance-of-passbooks-lpm-view/issuance-of-passbooks-lpm-view.component';
import { RegularizationOfSadabainamasViewComponent } from './regularization-of-sadabainamas-view/regularization-of-sadabainamas-view.component';
import { RectificationResurveyViewComponent } from './rectification-resurvey-view/rectification-resurvey-view.component';
import { RofrMutationForTransactionsSuccessionViewComponent } from './rofr-mutation-for-transactions-succession-view/rofr-mutation-for-transactions-succession-view.component';
import { LandAssignmentToSoldiersViewComponent } from './land-assignment-to-soldiers-view/land-assignment-to-soldiers-view.component';
import { LandAssignmentViewNewComponent } from './land-assignment-view-new/land-assignment-view-new.component';

@NgModule({
  declarations: [
    IntegratedCertificateViewComponent,
    ObcCertificateViewComponent,
    IncomeCertificateViewComponent,
    FamilyMemberCertificateViewComponent,
    PossessionCertificateViewComponent,
    NoEarningCertificateViewComponent,
    LateRegCertificateViewComponent,
    IncomeEwsCertificateViewComponent,
    IssueSmallFarmerCertificateViewComponent,
    FLineCertificateViewComponent,
    AgricultureIncomeCertificateViewComponent,
    EncumbranceCertificateViewComponent,
    FmbCopyViewComponent,
    CrackerLicenceApplicationComponent,
    IssueOfOccupancyRightsCertificatesForInamLandsViewComponent,
    PermissionOfDiggingOfBoreWellForAgriculturalPurposeViewComponent,
    ChangeOfNameCertificateViewComponent,
    NoPropertyCertificateViewComponent,
    RenewalCinemaLicenceViewComponent,
    IssueofnocForstoringofpetroleumproductsCertificateViewComponent,
    ExtractOfHousePattaViewComponent,
    ExtractofnocExplosiveorpetroleumCerificateComponent,
    AdditionOfSurveyNoViewComponent,
    OldAdangalViewComponent,
    LandConversionViewComponent,
    ModificationsIn22AListViewComponent,
    ApDottedLandsViewComponent,
    HousingSiteComponent,
    ROR1BViewComponent,
    ComputerisedAdangalViewComponent,
    PattadarMobilenoSeedingViewComponent,
    ReissuanceViewComponent,
    TitledeedCumPassbookViewComponent,
    PattaSubdivisionAutoMutationViewComponent,
    PrintingofTitledeedViewComponent,
    RectificationofEntriesViewComponent,
    MutationtitledeedViewComponent,
    EPassbookViewComponent,
    MutationCorrectionsResurveyViewComponent,
    HouseholdSplitViewComponent,
    PattadarAadharSeedLpmRecordViewComponent,
    LandConversionAgriViewComponent,
    MutationTransactionResurveyViewComponent,
    IssuanceOfPassbooksLpmViewComponent,
    RegularizationOfSadabainamasViewComponent,
    RectificationResurveyViewComponent,
    RofrMutationForTransactionsSuccessionViewComponent,
    LandAssignmentToSoldiersViewComponent,
    LandAssignmentViewNewComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgxBarcodeModule
  ],
  exports: [
    CommonModule,
    IntegratedCertificateViewComponent,
    ObcCertificateViewComponent,
    IncomeCertificateViewComponent,
    FamilyMemberCertificateViewComponent,
    PossessionCertificateViewComponent,
    NoEarningCertificateViewComponent,
    LateRegCertificateViewComponent,
    IncomeEwsCertificateViewComponent,
    IssueSmallFarmerCertificateViewComponent,
    FLineCertificateViewComponent,
    AgricultureIncomeCertificateViewComponent,
    EncumbranceCertificateViewComponent,
    FmbCopyViewComponent,
    CrackerLicenceApplicationComponent,
    IssueOfOccupancyRightsCertificatesForInamLandsViewComponent,
    PermissionOfDiggingOfBoreWellForAgriculturalPurposeViewComponent,
    ChangeOfNameCertificateViewComponent,
    NoPropertyCertificateViewComponent,
    RenewalCinemaLicenceViewComponent,
    IssueofnocForstoringofpetroleumproductsCertificateViewComponent,
    ExtractOfHousePattaViewComponent,
    ExtractofnocExplosiveorpetroleumCerificateComponent,
    AdditionOfSurveyNoViewComponent,
    OldAdangalViewComponent,
    LandConversionViewComponent,
    ApDottedLandsViewComponent,
    ModificationsIn22AListViewComponent,
    HousingSiteComponent,
    ROR1BViewComponent,
    ComputerisedAdangalViewComponent,
    PattadarMobilenoSeedingViewComponent,
    ReissuanceViewComponent,
    TitledeedCumPassbookViewComponent,
    PattaSubdivisionAutoMutationViewComponent,
    PrintingofTitledeedViewComponent,
    RectificationofEntriesViewComponent,
    MutationtitledeedViewComponent,
    EPassbookViewComponent,
    MutationCorrectionsResurveyViewComponent,
    HouseholdSplitViewComponent,
    PattadarAadharSeedLpmRecordViewComponent,
    LandConversionAgriViewComponent,
    MutationTransactionResurveyViewComponent,
    IssuanceOfPassbooksLpmViewComponent,
    RegularizationOfSadabainamasViewComponent,
    RectificationResurveyViewComponent,
    RofrMutationForTransactionsSuccessionViewComponent,
    LandAssignmentToSoldiersViewComponent,
    LandAssignmentViewNewComponent
 ]
})
export class RevenueViewModule { }
