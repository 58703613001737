<div class="menuWorklist mx-3">
    <ul>
        <li *ngIf="role === 'Zilla Sainik Welfare Officer'"><a routerLink="/revenue/land-assignment-to-soldiers"  routerLinkActive='active'>Assignment of Lands to Ex-Serviceperson/Serving Soldiers</a></li>
        <li *ngIf="role === 'Zilla Sainik Welfare Officer'"><a routerLink="/view-transactionzswo"  routerLinkActive='active'>View Transactions</a></li>  
        <li><a routerLink="/home" routerLinkActive='active'><span class="rightArrowCls"></span>Home</a></li>
        <li><a href="#" (click)="redirectServices('Dept')">Redirect to Meeseva</a></li>
        <li><a href="#" (click)="redirectServices('RTS')">Request Tracking System</a></li>
        <li><a href="#" (click)="redirectToeKYCStatusServices()">Hosue Sites eKYC Status Updation</a></li>
        <li><a href="#">Reports</a>
            <ul class="child-menu" >
              <li><a routerLink="/csp-service-report" routerLinkActive='active'><span class="rightArrowCls"></span>AP SEVA Services Dashboard</a></li>
              <li><a routerLink="/csp-payment-report" routerLinkActive='active'><span class="rightArrowCls"></span>AP SEVA Payment Dashboard</a></li>
              <li><a target="_blank" href='https://app.powerbi.com/view?r=eyJrIjoiMGNjMDkyZjAtMWMzOS00MTNmLTllODctNWVjYTY5Zjk5NzYwIiwidCI6IjI3ZjAxMGQwLTk2YWQtNGNhNC1iNjJkLWFlNzhhNGEyYTQzMCJ9&pageName=ReportSection'><span class="rightArrowCls"></span>Analytics Dashboard</a></li>
              <li><a target="_blank" href='https://app.powerbi.com/view?r=eyJrIjoiZGZjNzkwMDctZjhmMy00MTVlLTlkNTUtZWQwOTAyM2Q0YzA0IiwidCI6IjI3ZjAxMGQwLTk2YWQtNGNhNC1iNjJkLWFlNzhhNGEyYTQzMCJ9'><span class="rightArrowCls"></span>Sub SLA Dashboard</a></li>
              <!-- <li><a routerLink="/excel-report" routerLinkActive='active'><span class="rightArrowCls"></span>Download Report</a></li> -->
              <!-- <li><a routerLink="/payment-report" routerLinkActive='active'><span class="rightArrowCls"></span>Payment Report</a></li> -->
              <li><a routerLink="/ots-payment-report" routerLinkActive='active'><span class="rightArrowCls"></span>OTS Payment Report</a></li>
             <li><a routerLink="/aarogya-suraksha-report" routerLinkActive='active'><span class="rightArrowCls"></span>Aarogya Suraksha 2.0 Scheduling Status Reports</a></li>
             <li><a routerLink="/aarogya-suraksha-dashboard" routerLinkActive='active'><span class="rightArrowCls"></span>Aarogya Suraksha 2.0 Camp Status Report</a></li>
             <li><a routerLink="/jagananna-aarogya-suraksha-token-generation-report" routerLinkActive='active'><span class="rightArrowCls"></span>Aarogya Suraksha 2.0 Token Generation Report</a></li>
             <li><a routerLink="/welfare-scheme-program" routerLinkActive='active'><span class="rightArrowCls"></span>Unveiling Of Welfare Schemes Display Boards-Program Details Report</a></li>
             <li><a routerLink="/good-government-report" routerLinkActive='active'><span class="rightArrowCls"></span>ఇది మంచి ప్రభుత్వం క్యాంపెయిన్ రిపోర్ట్ </a></li>
             <li><a routerLink="/palleku-podam" routerLinkActive='active'><span class="rightArrowCls"></span>Unveiling of Welfare Schemes  Display Boards - Schedule</a></li>
             <li><a routerLink="/passbook-service-report" routerLinkActive='active'><span class="rightArrowCls"></span>Passbook Service Report</a></li>
             <li><a routerLink="/pension-report" routerLinkActive='active'><span class="rightArrowCls"></span>Pension 6 Step Parameter Update Report</a></li>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                       
             <!-- <li><a routerLink="/monitoring-dashboard-for-pan-collection" routerLinkActive='active'><span class="rightArrowCls"></span>Monitoring Dashboard for PAN Collection Report</a></li>     -->
             <li><a routerLink="/utilization-certificate-report" routerLinkActive='active'><span class="rightArrowCls"></span>Utilization Certificate Report</a></li>
             <li><a routerLink="/aadhar-challan-generation-report" routerLinkActive='active'><span class="rightArrowCls"></span>Aadhar Challan Generation Report</a></li>  
             <li><a routerLink="/volunteer-appreciation-report" routerLinkActive='active'><span class="rightArrowCls"></span>Volunteer Appreciation Program Details Report</a></li> 
             <li><a routerLink="/pension-occupational-report" routerLinkActive='active'><span class="rightArrowCls"></span>Pension Occupational Report</a></li> 
             <li><a routerLink="/secreteriate-pan-updated-report" routerLinkActive='active'><span class="rightArrowCls"></span>Secretariat pan updated report</a></li> 
             <li><a routerLink="/secreteriate-revVillage-mapping-report" routerLinkActive='active'><span class="rightArrowCls"></span>Secretariat-Revenue Village Mapping Report</a></li>
             <li *ngIf="role === 'Tahsildar' || role === 'Deputy Tahsildar' || role === 'Revenue Inspector' || role === 'Joint Collector' || role === 'Collector' || role === 'Revenue Divisonal Officer'"><a routerLink="/suomoto-pending-applications-report" routerLinkActive='active'><span class="rightArrowCls"></span>SuoMoto Designation wise Pending Report</a></li>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
             <ng-container *ngIf="isViksitBharathDayNodalReport" >
                <li><a routerLink="/vbd-report" routerLinkActive='active'><span class="rightArrowCls"></span>VBSY Day Nodal Officer IEC Distribution Report</a></li>
                <li><a routerLink="/viksit-bharat-report" routerLinkActive='active'><span class="rightArrowCls"></span>VBSY district level stationary balance report</a></li>                 
            </ng-container>
            </ul>
        </li>
        <li *ngIf="role === 'Tahsildar'"><a routerLink="/document-list" routerLinkActive='active'>View Documents</a></li>
        <li><a href="#">Profile</a>
            <ul class="child-menu">
                <li *ngIf="role === 'Tahsildar' ||
                        role === 'Deputy Tahsildar' ||
                        role === 'Revenue Divisonal Officer' ||
                        role === 'Joint Collector' ||
                        role === 'Collector' ||
                        role === 'Village Revenue Officer' ||
                        role === 'Panchayat Secretary' ||
                        role === 'Assistant Secretary' ||
                        role === 'Revenue Inspector' ||
                        role === 'Municipal Commissioner' ||
                        role === 'Mandal Parishad Development Officers' ||
                        role === 'Joint Collector Development' ||
                        role === 'Ward Revenue Secretary' ||
                        role === 'Welfare & Educational Assistant' ||
                        role === 'Ward Welfare & Development Secretary' ||
                        role === 'ExciseDC' ||
                        role === 'HandloomDC' ||
                        role === 'SCWELDC' ||
                        role === 'DMO' ||
                        role === 'FisheryDC' ||
                        role === 'Panchayat Secretary DDO' ||
                        role === 'CEO, WAQF board' ||
                        role === 'Ward Admin Secretary' ||
                        role === 'Superintendent' ||
                        role === 'Village surveyor' ||
                        role === 'Mandal surveyor' ||
                        role === 'Assistant Civil Supplies Officer' ||
                        role === 'Assistant Social Welfare Officer' ||
                        role === 'Fisheries Development Officer' ||
                        role === 'HandloomDO' ||
                        role === 'District Civil Supplies Officer'">
                        <a routerLink="/signature-upload" [queryParams]="{flag: 'SELF'}" routerLinkActive='active'>
                            <span class="rightArrowCls"></span>Profile Update
                        </a>
                </li>
                <li *ngIf="role === 'Tahsildar' ||
                        role === 'Mandal surveyor' ||
                        role === 'Joint Collector' ||
                        role === 'Municipal Commissioner' ||
                        role === 'Mandal Parishad Development Officers' ||
                        role ==='CEO, WAQF board'">
                        <a routerLink="/signature-upload" [queryParams]="{flag: 'WORKFLOW'}" routerLinkActive='active'>
                            <span class="rightArrowCls"></span>Profile Approval
                        </a>
                </li>
            </ul>
        </li>
        <li *ngIf="role === 'Tahsildar' || role === 'Revenue Divisonal Officer'  || role === 'Collector' || role === 'Joint Collector'"><a routerLink="/suomoto-caste-verification-report" routerLinkActive='active'><span class="rightArrowCls"></span>Suomoto Caste Verification Report</a></li>
       

           <!-- <li><a href="#">Jagananna Suraksha</a>
                <ul class="child-menu" >
                    <li *ngIf="role === 'Municipal Commissioner'|| role === 'Mandal Parishad Development Officers'"><a routerLink="/team-composition" routerLinkActive='active'>Team Composition</a></li>
                    <li *ngIf="role === 'Municipal Commissioner'|| role === 'Mandal Parishad Development Officers'"><a routerLink="/team-schedule" routerLinkActive='active'>Team Schedule</a></li>
                    <li><a routerLink="/schedule-report" routerLinkActive='active'><span class="rightArrowCls"></span>Team Schedule Report</a></li>
                    <li> <a routerLink="/jagananna-suraksha-dashboard" routerLinkActive='active'>Jagananna Suraksha Dashboard</a></li>
                    <li> <a routerLink="/jagananna-suraksha-camp-status-report" routerLinkActive='active'>Jagananna Suraksha Camp Status Report</a></li>
                </ul>
            </li> -->
            <ng-container *ngIf="role === 'Municipal Commissioner'|| role === 'Mandal Parishad Development Officers'">
                <li><a href="#">Aarogya Suraksha 2.0</a>
                    <ul class="child-menu" >
                            <li><a routerLink="/aarogya-suraksha-team-composition" routerLinkActive='active'>Team Composition</a></li>
                            <li><a routerLink="/aarogya-team-schedule" routerLinkActive='active'>Team Schedule</a></li>
                        <!--<li *ngIf="role === 'Digital Assistant'|| role === 'Ward Education Secretary' || role === 'Welfare & Educational Assistant'|| role === 'Ward Welfare & Development Secretary' || role === 'Panchayat Secretary'|| role === 'Ward Admin Secretary' ||
                        role === 'Village Revenue Officer'|| role === 'Ward Revenue Secretary'"><a routerLink="/aarogya-suraksha-tokens-registaration" routerLinkActive='active'>Token Registration</a></li> -->
                    </ul>
                </li>
            </ng-container>
            <li *ngIf="role == 'Panchayat Secretary' || role == 'Ward Admin Secretary' "><a routerLink="/pan-details" routerLinkActive='pan-details'>PAN Details</a></li>
             <!-- <li *ngIf="role === 'Village Revenue Officer'|| role === 'Panchayat Secretary'|| role === 'Ward Admin Secretary'|| role === 'Welfare & Educational Assistant'|| role === 'Ward Revenue Secretary'|| role === 'Ward Welfare & Development Secretary'"><a routerLink="/registration-tokens" routerLinkActive='active'>Token Registration</a></li> -->
            <li><a routerLink="/revenue-sadassulu-grievance-report" routerLinkActive='active'><span class="rightArrowCls"></span>Revenue Sadassulu Grievance Report</a></li>  
            <!-- <li *ngIf="role === 'Tahsildar' || role === 'Deputy Tahsildar'"><a routerLink="/bulk-approval" routerLinkActive='active'>Bulk Approval</a></li> -->
            <li *ngIf= "role === 'Joint Collector'"><a routerLink="/revoke-worklist" routerLinkActive='active'>Revoke Worklist</a></li>
            <li *ngIf="role === 'Tahsildar' || role === 'Deputy Tahsildar' || role === 'Revenue Divisonal Officer' || role === 'Municipal Commissioner' || role === 'Panchayat Secretary DDO'"><a routerLink="/revoke-provision" routerLinkActive='active'>Revoke Application</a></li>
            <ng-container *ngIf="role === 'Mandal Parishad Development Officers' || role === 'Municipal Commissioner' ">
                <li >
                    <a href="#">Unveiling Of Welfare Schemes Display Boards</a>
                    <ul class="child-menu" >
                        <li><a routerLink="/palleku-podam" routerLinkActive='active'>Unveiling Of Welfare Schemes Display Boards-Schedule</a></li>
                        <li><a routerLink="/welfare-scheme" routerLinkActive='active'>Unveiling Of Welfare Schemes Display Boards-Program Details</a></li>
                    </ul>
                </li>
                <li><a routerLink="/viksit-bharat-distribution" routerLinkActive='active'><span class="rightArrowCls"></span>VBSY daily IEC material data entry</a></li>
            </ng-container>
            <!-- <li *ngIf="role === 'Welfare & Educational Assistant' || role === 'Ward Welfare & Development Secretary' || role === 'Municipal Commissioner'" class="width321Cls"><a routerLink="/pension-urban-electricity-tagging-screen" routerLinkActive='active'>Urban Property and Electricity Tagging for Pension Application</a></li> -->
            <li *ngIf="role === 'Welfare & Educational Assistant' || role === 'Ward Welfare & Development Secretary'">
                <a href="#">School Education</a>
                <ul class="child-menu">
                    <li><a target="_blank" href='https://cse.ap.gov.in/RTE'><span class="rightArrowCls"></span>RTE School Education</a></li>
                </ul>
            </li>
            <li *ngIf="role === 'Ward Admin Secretary' || role === 'Municipal Commissioner' || role === 'Panchayat Secretary'" class="width321Cls"><a routerLink="/utilization-certificate" routerLinkActive='active'>Utilization Certificate Upload</a></li>
            <li *ngIf="role === 'Tahsildar'" class="width321Cls"><a routerLink="/village-mapping" routerLinkActive='active'>Revenue Village To Secretariat Mapping</a></li>
          <!-- <li *ngIf="role === 'Tahsildar' || role === 'Municipal Commissioner'|| role === 'Mandal Parishad Development Officers'
          "><a (click)="getOfficerLocations()">Account Details</a></li> -->
          <li *ngIf="role === 'Municipal Commissioner'|| role === 'Mandal Parishad Development Officers'"><a routerLink="/volunteer-appreciation-program" routerLinkActive='active'><span class="rightArrowCls"></span>Volunteer Appreciation Program Details</a></li>
          <li *ngIf="role === 'Village Revenue Officer'|| role === 'Ward Revenue Secretary' || role === 'Revenue Inspector' || role === 'Tahsildar' || role === 'Revenue Divisonal Officer' || role === 'Collector'"><a routerLink="/suomoto" routerLinkActive='active'><span class="rightArrowCls"></span>Suo Moto Integrated Certificate Verification</a></li>
    </ul>
</div>