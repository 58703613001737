<div class="certificateInfo">
    <div class="subHeadingCls mb-3">Lamination Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Rice Card:</label>
                <div class="valueCls">{{certificateInfo?.riceCard ? (certificateInfo?.riceCard) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Aarogya sri Card:</label>
                <div class="valueCls">{{certificateInfo?.aarogyasriCard ? (certificateInfo?.aarogyasriCard) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Pension Card:</label>
                <div class="valueCls">{{certificateInfo?.pensionCard ? (certificateInfo?.pensionCard) : '-' }}</div>
            </div>
         </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Any A4 Certificate:</label>
                <div class="valueCls">{{certificateInfo?.a4Certificate ? (certificateInfo?.a4Certificate) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">ID Card:</label>
                <div class="valueCls">{{certificateInfo?.idCard ? (certificateInfo?.idCard) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Aadhaar Card:</label>
                <div class="valueCls">{{certificateInfo?.aadharCard ? (certificateInfo?.aadharCard) : '-'}}</div>
            </div>
        </div>
     
    </div>
</div>
