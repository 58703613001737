    <div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
            <div class="subHeadingCls mb-3">Selected Criteria Details</div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Registration Unit Id:</label>
                        <div class="valueCls">{{certificateInfo?.registrationUnitId ? (certificateInfo?.registrationUnitId) : '-'}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Purpose Of Certificate:</label>
                        <div class="valueCls">{{certificateInfo?.purposeOfCertificate ? (certificateInfo?.purposeOfCertificate) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">No Of Copies:</label>
                        <div class="valueCls">{{certificateInfo?.noOfCopies ? (certificateInfo?.noOfCopies) : '-' }}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Registration Number:</label>
                        <div class="valueCls">{{certificateInfo?.registrationNumber ? (certificateInfo?.registrationNumber) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Registration Year:</label>
                        <div class="valueCls">{{certificateInfo?.registrationYear ? (certificateInfo?.registrationYear) : '-' }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
            <div class="subHeadingCls mb-3">Applicant Details</div>
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Whether you want to update child name:</label>
                        <div class="valueCls">{{certificateInfo?.isChildNameUpdate ? 'Yes' : 'No'}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Whether you want to update date of birth:</label>
                        <div class="valueCls">{{certificateInfo?.isDateOfBirthUpdate ? 'Yes' : 'No'}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Whether you want to update gender:</label>
                        <div class="valueCls">{{certificateInfo?.isGenderUpdate ? 'Yes' : 'No'}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Whether you want to update father name:</label>
                        <div class="valueCls">{{certificateInfo?.isFatherNameUpdate ? 'Yes' : 'No'}}</div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Whether you want to update mother name:</label>
                        <div class="valueCls">{{certificateInfo?.isMotherNameUpdate ? 'Yes' : 'No' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Whether you want to update birth place:</label>
                        <div class="valueCls">{{certificateInfo?.isBirthPlaceUpdate ? 'Yes' : 'No' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Whether you want to update address at the time of birth:</label>
                        <div class="valueCls">{{certificateInfo?.isBirthAddressUpdate ? 'Yes' : 'No' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Whether you want to update permanent address:</label>
                        <div class="valueCls">{{certificateInfo?.isPermanentAddressUpdate ? 'Yes' : 'No' }}</div>
                    </div>
               
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Actual Child Name:</label>
                        <div class="valueCls">{{certificateInfo?.actualChildName ? (certificateInfo?.actualChildName) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Correct Child Name:</label>
                        <div class="valueCls">{{certificateInfo?.correctChildName ? (certificateInfo?.correctChildName) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Actual Child Surname:</label>
                        <div class="valueCls">{{certificateInfo?.actualChildSurName ? (certificateInfo?.actualChildSurName) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Correct  Child Surname:</label>
                        <div class="valueCls">{{certificateInfo?.correctChildSurName ? (certificateInfo?.correctChildSurName) : '-' }}</div>
                    </div>
               
                </div>
            </div>
                <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Actual Date of Birth:</label>
                        <div class="valueCls">{{certificateInfo?.actualDOB ? (certificateInfo?.actualDOB | date: 'dd/MM/yyyy') : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Correct Date of Birth:</label>
                        <div class="valueCls">{{certificateInfo?.correctDOB ? (certificateInfo?.correctDOB) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Actual Gender:</label>
                        <div class="valueCls">{{certificateInfo?.actualGender ? (certificateInfo?.actualGender) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Correct Gender:</label>
                        <div class="valueCls">{{certificateInfo?.correctGender ? (certificateInfo?.correctGender) : '-' }}</div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Actual Father Name:</label>
                        <div class="valueCls">{{certificateInfo?.actualFatherName ? (certificateInfo?.actualFatherName) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Correct Father Name:</label>
                        <div class="valueCls">{{certificateInfo?.correctFatherName ? (certificateInfo?.correctFatherName) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Actual Father Surname:</label>
                        <div class="valueCls">{{certificateInfo?.actualFatherSurName ? (certificateInfo?.actualFatherSurName) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Correct Father Surname:</label>
                        <div class="valueCls">{{certificateInfo?.correctFatherSurName ? (certificateInfo?.correctFatherSurName) : '-' }}</div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Actual Mother Name:</label>
                        <div class="valueCls">{{certificateInfo?.actualMotherName ? (certificateInfo?.actualMotherName) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Correct Mother Name:</label>
                        <div class="valueCls">{{certificateInfo?.correctMotherName ? (certificateInfo?.correctMotherName) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Actual Mother Surname:</label>
                        <div class="valueCls">{{certificateInfo?.actualMotherSurName ? (certificateInfo?.actualMotherSurName) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Correct Mother Surname:</label>
                        <div class="valueCls">{{certificateInfo?.correctMotherSurName ? (certificateInfo?.correctMotherSurName) : '-' }}</div>
                    </div>
                </div>
                </div>
                <div class="row"> 
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="fieldHolder">
                            <label class="labelCls">Actual Birth Place:</label>
                            <div class="valueCls">{{certificateInfo?.actualBirthPlace ? (certificateInfo?.actualBirthPlace) : '-' }}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Correct Birth Place:</label>
                            <div class="valueCls">{{certificateInfo?.correctBirthPlace ? (certificateInfo?.correctBirthPlace) : '-' }}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Location Name:</label>
                            <div class="valueCls">{{certificateInfo?.locationName ? (certificateInfo?.locationName) : '-' }}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Actual Address 1:</label>
                            <div class="valueCls">{{certificateInfo?.actualAddress1 ? (certificateInfo?.actualAddress1) : '-' }}</div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="fieldHolder">
                            <label class="labelCls">Actual Address 2:</label>
                            <div class="valueCls">{{certificateInfo?.actualAddress2 ? (certificateInfo?.actualAddress2) : '-' }}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Actual Address 3:</label>
                            <div class="valueCls">{{certificateInfo?.actualAddress3 ? (certificateInfo?.actualAddress3) : '-' }}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Changed Address 1:</label>
                            <div class="valueCls">{{certificateInfo?.changedAddress1 ? (certificateInfo?.changedAddress1) : '-' }}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Changed Address 2:</label>
                            <div class="valueCls">{{certificateInfo?.changedAddress2 ? (certificateInfo?.changedAddress2) : '-' }}</div>
                        </div>
                    </div>  
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Changed Address 3:</label>
                        <div class="valueCls">{{certificateInfo?.changedAddress3 ? (certificateInfo?.changedAddress3) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Pincode:</label>
                        <div class="valueCls">{{certificateInfo?.pincode ? (certificateInfo?.pincode) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Actual Birth Address 1:</label>
                        <div class="valueCls">{{certificateInfo?.actualBirthAddress1 ? (certificateInfo?.actualBirthAddress1) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Actual Birth Address 2:</label>
                        <div class="valueCls">{{certificateInfo?.actualBirthAddress2 ? (certificateInfo?.actualBirthAddress2) : '-' }}</div>
                    </div>
                </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div class="fieldHolder">
                                <label class="labelCls">Actual Birth Address 3:</label>
                                <div class="valueCls">{{certificateInfo?.actualBirthAddress3 ? (certificateInfo?.actualBirthAddress3) : '-' }}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Correct Birth Address 1:</label>
                                <div class="valueCls">{{certificateInfo?.correctBirthAddress1 ? (certificateInfo?.correctBirthAddress1) : '-' }}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Correct Birth Address 2:</label>
                                <div class="valueCls">{{certificateInfo?.correctBirthAddress2 ? (certificateInfo?.correctBirthAddress2) : '-' }}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Correct Birth Address 3:</label>
                                <div class="valueCls">{{certificateInfo?.correctBirthAddress3 ? (certificateInfo?.correctBirthAddress3) : '-' }}</div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div class="fieldHolder">
                                <label class="labelCls">Actual Permanent Address 1:</label>
                                <div class="valueCls">{{certificateInfo?.actualBirthAddress3 ? (certificateInfo?.actualBirthAddress3) : '-' }}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Actual Permanent Address 2:</label>
                                <div class="valueCls">{{certificateInfo?.correctBirthAddress1 ? (certificateInfo?.correctBirthAddress1) : '-' }}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Actual Permanent Address 3:</label>
                                <div class="valueCls">{{certificateInfo?.correctBirthAddress2 ? (certificateInfo?.correctBirthAddress2) : '-' }}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Correct Permanent Address 1:</label>
                                <div class="valueCls">{{certificateInfo?.correctBirthAddress3 ? (certificateInfo?.correctBirthAddress3) : '-' }}</div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div class="fieldHolder">
                                <label class="labelCls">Correct Permanent Address 2:</label>
                                <div class="valueCls">{{certificateInfo?.correctBirthAddress1 ? (certificateInfo?.correctBirthAddress1) : '-' }}</div>
                            </div>
                            <div class="fieldHolder">
                                <label class="labelCls">Correct Permanent Address 3:</label>
                                <div class="valueCls">{{certificateInfo?.correctBirthAddress2 ? (certificateInfo?.correctBirthAddress2) : '-' }}</div>
                            </div>
                        </div>
                </div>
        </div>
        
        <div class="certificateInfo">
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
                    <ng-container *ngIf="fileList && fileList.length !== 0">
                    <div class="subHeadingCls mb-3">List of Documents</div>
                    <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                        <div *ngIf="file.documentType === 'Application Form'">
                            <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                            <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                        </div>
              
                    </div>
                    </ng-container>
                </div>
            </div>
        </div>
        
        
    
