import { Component, Input, OnInit } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { CommonService } from '../../shared/common.service';
@Component({
  selector: 'app-print-service-view',
  templateUrl: './print-service-view.component.html',
  styleUrls: ['./print-service-view.component.css']
})
export class PrintServiceViewComponent implements OnInit {
  commonConstants: any = CommonConstants;
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  serviceList: any = [];
  serviceName:any;
  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getServiceName(this.certificateInfo?.serviceName)
  }
  getDepartment(dept: any): any{
    if(dept === 'RV') {
      return 'Revenue';
    }else if(dept === 'HF'){
      return 'Health and Family Welfare Department'
    }
  }
  getServiceName(name: any):any{
    this.serviceList = [];
    this.commonService
    .getRequest(this.commonConstants.getServiceList )
    .subscribe({
      next: (responseData: any) => {
         this.serviceList = responseData.result;
         let serviceNames:any =[];
         serviceNames= this.serviceList.filter((c:any) => c.serviceCode === name);
         this.serviceName = serviceNames[0]?.serviceName;
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  // getServiceName(name: any): any {
  //   if(name === 'IntegratedCertificate') {
  //     return 'Integrated Certificate';
  //   } else if(name === 'IncomeCertificate') {
  //     return 'Income Certificate';
  //   } else if(name === 'FamilyMemberCertificate') {
  //     return 'Family Member Certificate';
  //   } else if(name === 'RePrintServiceForAarogyaSri'){
  //      return 'Re-Print Service for Aarogyasri Card';
  //   }else if(name ==='AgricultureIncomeCertificate'){
  //      return 'Agriculture Income Certificate'
  //   }    else if(name ==='OBCMemberCertificate'){
  //     return 'OBC Certificate'
  //   }  else if(name ==='NoEarningMemberCertificate'){
  //      return 'No-Earning Member Certificate'
  //   }else if(name === 'PossessionCertificate'){
  //    return 'Possession Certificate'
  //   }else if(name === 'IncomeAssetCertificateForEWS'){
  //     return 'Issuance Of Income & Asset Certificate For Economically Weaker Sections (EWS)'
  //    }else if(name === 'SnMFarmerCertificate'){
  //     return 'Issuance Of Small And Marginal Farmer Certificate'
  //    }else if(name === 'BirthDeathCertificate'){
  //     return 'Possession Certificate'
  //    }

  // }
}
