import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router) { 
    }
    ngOnInit(): void {
    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
         let userData = localStorage.getItem('userInfo');
         let isSuccess =  localStorage.getItem('isSuccess');
        if(userData && userData !== null) {
            let userInfo = JSON.parse(userData);
            // check if route is restricted by role
        //   console.log('route.children0', route);

        //   console.log('route.children1', route.children);
        //   console.log('route.children2', route.children[0]?.data);

        //   console.log('route.children3', route.children[0]?.data?.isCitizen);

            if (route.data.roles && route.data.roles.indexOf(userInfo.jobTitle) === -1) {
                // role not authorized so redirect to home page
                alert('You are not authorized to view this page....')
                this.router.navigate(['home']);
                return false;
            } else if((userInfo.jobTitle === 'Citizen' && route.data?.isCitizen !== true) || 
                      (userInfo.jobTitle?.trim() === 'Meeseva' && route.data?.isMeeseva !== true)) {
                console.log('!route.children[0]?.data?.isCitizen', !route.children[0]?.data?.isCitizen)
                alert('You are not authorized to view this page.')
                this.router.navigate(['home']);
                return false;    
            }else if(userInfo.jobTitle === 'Reports Admin' && route.data?.isCommissioner === true && userInfo.userPrincipalName?.toLowerCase()?.trim() !== 'com_gsws@apgsws.onmicrosoft.com') {
                alert('You are not authorized to view this page.')
                this.router.navigate(['home']);
                return false;    
            }else if(userInfo.jobTitle === 'Citizen' && route.data?.isCitizen == true && isSuccess=='false' && route.data?.isViewTransaction !== true){
                alert('You have already completed ten transactions for this month');
                return false;    
            }
             else {
                console.log('route.children', route.children[0]?.data?.isCitizen);
                return true;
            }
            // else if(userInfo.jobTitle === 'Citizen' && !citizenServices.includes(location.href.split('#')[1])) {
            //     alert('You are not authorized to view this page.')
            //     this.router.navigate(['home']);
            //     return false;
            // }
            // authorized so return true
        }

        // not logged in so redirect to login page
        this.router.navigate(['home']);
        return false;
    }
    
      
}