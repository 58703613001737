<div class="container-fluid mainCls">
    <div class="breadcrumbContainer mr-3">
        <div class="breadcrumbCls">
          GSWS / <span class="activePage">Details of Household Visited</span>
        </div>
        <button  class="greenBtnCls float-right mt-5" *ngIf="role === 'Panchayat Secretary' || role === 'Ward Admin Secretary'" routerLink="/home">Home</button>     
    </div>
    <div class="mainPageContainer mr-3">
        <h6 class="mb-3 mt-3">Details of Household Visited</h6>
        <form [formGroup]="goodGovernmentForm">
            <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Select Date: <span class="mandatoryCls">*</span></label>
                    <div class="input-group">
                        <input class="inputCls form-control" appBlockCopyPaste
                            name="d" ngbDatepicker #d="ngbDatepicker" [minDate]="minDate"
                            [maxDate]="maxDate" required formControlName = "date"
                            [ngClass]="{ 'is-invalid': isSubmitted && f.date.errors }">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                [ngClass]="{ 'is-invalid': isSubmitted && f.date.errors }" type="button"></button>
                        </div>
                    </div>
                    <div *ngIf="isSubmitted && f.date.errors" class="selectError">
                        <div *ngIf="f.date.errors?.required || f.date.invalid">Please enter valid Date</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">No. Of Households visited: <span class="mandatoryCls">*</span></label>
                    <input type="number" name="householdsVisited" class="inputCls width75" appBlockCopyPaste required numbersOnly min="0"
                        formControlName="householdsVisited" [ngClass]="{ 'is-invalid': isSubmitted && f.householdsVisited.errors }">
                    <div *ngIf="isSubmitted && f.householdsVisited.errors" class="invalid-feedback">
                        <div *ngIf="f.householdsVisited.errors.required">Please enter No. Of Households visited</div>
                    </div>
                </div>
            </div>
            <div  formArrayName="guestDetails" *ngFor="let item of guestDetails.controls; let i = index; let isLast = last; let isFirst = first">
                <ng-container [formGroupName]="i" >
                    <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Name of Chief Guest: </label>
                    <input type="text" appBlockCopyPaste  name="{{'chiefGuestName' + i}}" class="inputCls" formControlName="chiefGuestName"
                        [ngClass]="{ 'is-invalid': isSubmitted && guestDetails.controls[i].get('chiefGuestName')?.errors}"  maxlength="50">
                    <div *ngIf="isSubmitted && guestDetails.controls[i].get('chiefGuestName')?.errors" class="invalid-feedback">
                        <div *ngIf="guestDetails.controls[i].get('chiefGuestName')?.errors?.whiteSpaceValidate">Please enter Chief Guest Name</div>
                        <div *ngIf="guestDetails.controls[i].get('chiefGuestName')?.errors?.pattern">Please enter valid Chief Guest Name</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Designation of Chief Guest: </label>
                    <select class="selectCls"  
                    (change)="onDesignationChange(i, $event.target.value)"  name="{{'chiefGuestDesignationName' + i}}" placeholder="Select"
                    formControlName="chiefGuestDesignationName"
                    >
                    <!-- [ngClass]="{ 'is-invalid': isSubmitted && guestDetails.controls[i].get('chiefGuestDesignationName')?.errors }" -->
                    <option [ngValue]="null" disabled>Select</option>
                    <option *ngFor="let designation of designation" [ngValue]="designation.name">{{designation.name}}
                    </option>
                    
                </select>
                        <!-- <div *ngIf="isSubmitted && (guestDetails.controls[i].get('chiefGuestDesignationName')?.errors || guestDetails.controls[i].get('chiefGuestDesignationName')?.value === 'Select')" class="selectError">
                            <div *ngIf="guestDetails.controls[i].get('chiefGuestDesignationName')?.errors?.required || guestDetails.controls[i].get('chiefGuestDesignationName')?.value === 'Select'">Please enter Chief Guest Designation</div>
                        </div>    -->
                  
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf="guestDetails.controls[i].get('chiefGuestDesignationName').value === 'others' ">
                    <label class="labelCls">Designation of Chief Guest: </label>  
                    <input type="text" appBlockCopyPaste name="chiefGuestDesignation" class="inputCls" name="{{'chiefGuestDesignation' + i}}" formControlName="chiefGuestDesignation"
                        [ngClass]="{ 'is-invalid': isSubmitted && guestDetails.controls[i].get('chiefGuestDesignation')?.errors }"  maxlength="50"> 
                         <div *ngIf="isSubmitted && guestDetails.controls[i].get('chiefGuestDesignation')?.errors" class="invalid-feedback">
                        <div *ngIf="guestDetails.controls[i].get('chiefGuestDesignation')?.errors?.whiteSpaceValidate">Please enter Chief Guest Designation</div>
                        <div *ngIf="guestDetails.controls[i].get('chiefGuestDesignation')?.errors?.pattern">Please enter valid Chief Guest Designation</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-4">
                    <button *ngIf="guestDetails.length > 1" class="btn btn-primary mr-3" (click)="delete(i)">Delete</button>
                    <button *ngIf="isLast && guestDetails.length < 9" class="btn btn-primary mr-3 add-new" (click)="addGuestDetails(i)">Add</button>
                </div>
            </div>
            </ng-container>
            </div>
        </form>
        <div class="btnHolder text-center">
            <button class="greenBtnCls" (click)="submit()">Submit</button>
        </div>
        <div class="row mt-3" *ngIf="houseHoldSurveyData?.length > 0">
            <div class="col">
                <div class="subHeadingCls mb-3">Households Visited Count</div>
                <div class="tab table-responsive">
                    <table class="tableCls">
                        <thead>
                            <tr>
                                <th>Serial No</th>
                                <!-- <th>Secretariat</th> -->
                                <th>Date</th>
                                <th>No. Of Households Visited</th>
                                <th>Chief Guest </th>
                                <!-- <th>Chief Guest Designation</th> -->
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let data of houseHoldSurveyData; let i=index">
                            <tr>
                                <td class="text-center">{{ i + 1 }}</td>
                                <!-- <td>{{ data?.secretariatName }}</td> -->
                                <td class="text-center">{{ data?.surveyDate | date: 'dd/MM/yyyy' }}</td>
                                <td class="value">
                                     <span *ngIf="!editState[i]" >{{ data?.noOfHouseHoldsVisited }}</span>
                                     <input  type="text" numbersOnly *ngIf="editState[i]" [(ngModel)]="currentHouseHoldNumber[i]" >
                                </td>
                                <td>{{ data?.chiefGuestName ? data?.chiefGuestName : '-' }}</td>
                                <!-- <td>{{ data?.chiefGuestDesignation ? data?.chiefGuestDesignation : '-'}}</td> -->
                                <td>
                                 
                                        <button class="greenBtnCls btn-sm" (click)="editHouseHold(i,data?.noOfHouseHoldsVisited)" *ngIf="!editState[i]">Edit</button>  &nbsp;
                                        <button class="greenBtnCls btn-sm" (click)="openDeleteHouseHold(confirmModal,data?.id)"  *ngIf="!editState[i]" >Delete</button>  &nbsp;
                                        <button class="greenBtnCls btn-sm" (click)="updateHouseHold(i,currentHouseHoldNumber[i],data?.id)" *ngIf="editState[i]">Update</button> &nbsp;
                                        <button class="greenBtnCls btn-sm" (click)="cancelHouseHold(i)" *ngIf="editState[i]">Cancel</button>  &nbsp;
                                   
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
        <ng-template #confirmModal let-modal>
            <div class="modal-header">
             <h5 class="modal-title" id="editProfileLabel">Confirm Households</h5>
             <button type="button" class="close" #closebutton (click)="modal.dismiss();" aria-label="Close">
              <span aria-hidden="true">&times;</span>
             </button>
            </div>
           
            <div class="modal-body">
              <p>Are you sure you want to delete this Households?</p>
            </div>
            <div class="modal-footer">
              <button  name="close" class="greenBtnCls" (click)="modal.dismiss();">No</button>
              <button  type="submit" class="greenBtnCls" name="submit" (click)="deleteHouseHold()">Yes</button>
            </div>
        </ng-template>
         
         