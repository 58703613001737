import { ElementRef, Injectable, ViewChild } from '@angular/core';
import * as ExifReader from 'exifreader';



@Injectable({
  providedIn: 'root'
})
export class FileService {

canvas :ElementRef<HTMLCanvasElement>;
constructor(){
  const canvas = document.createElement('canvas');
  canvas.style.display='none';
  document.body.appendChild(canvas);
  this.canvas = {nativeElement:canvas} as ElementRef<HTMLCanvasElement>;
}

 
  public async getOrientaion(file:File):Promise<any>{
    try {
      const arrayBuffer = await this.readFileAsArrayBuufer(file);
      const tags =  await ExifReader.load(arrayBuffer);
      return tags.Orientation ? tags.Orientation.value : 1;
    } catch (error) {
      return null;
    }
       
  }

  private readFileAsArrayBuufer(file:File):Promise<ArrayBuffer> | any {
    return new Promise((resolve,reject)=>{
      const reader = new FileReader();
      reader.onload = ()=>{
        resolve(reader.result as ArrayBuffer)
      };
      reader.onerror = (error)=>{
        reject(error)
      };
      reader.readAsDataURL(file);
    })
  }

  

  public async readFile(event:any ) {
   return new Promise ( async (resolve,reject)=>{
    try {
      const img = new Image();
       const arrayBuffer = await this.readFileAsArrayBuufer(event);
       img.src = arrayBuffer;
        img.onload = async ()=>{
        const orientation:any = await this.getOrientaion(event);
        let rotationdegree = 0;
        switch(orientation){
          case 6 :
          rotationdegree=90;
          break;
          case 8 :
            rotationdegree=270; 
            break; 
          default :
            rotationdegree = 0;
            break;
        }
        const imageDimension =  this.roateImage(img ,rotationdegree);
         resolve(imageDimension)
      }
      img.onerror=(error)=>{
      
        reject(error);
      }
    } catch (error) {
      reject(error)
    }
       
    })
        
  }

 

  



  public roateImage(img:HTMLImageElement,degree:number){
        const canvas = this.canvas.nativeElement;
        var ctx = canvas.getContext('2d');
        const radians = degree*Math.PI/180;
        const sin = Math.sign(radians);
        const cos = Math.cos(radians);
        canvas.width = Math.abs(img.width*cos)+Math.abs(img.height*sin);
        canvas.height = Math.abs(img.width*sin)+Math.abs(img.height*cos);
        if(ctx){
          ctx.translate(canvas.width/2,canvas.height/2);
          ctx.rotate(radians);
          ctx.drawImage(img,-img.width/2,-img.height/2);
        }
       
        return {width:canvas.width,height:canvas.height};
    
  }
}
