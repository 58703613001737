<form [formGroup]="aarogyaSriView">
    <div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
        <div class="subHeadingCls mb-3">Applicant Details</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Ration Card No:</label>
                    <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Remarks:</label>
                    <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Volunteer Name:</label>
                    <div class="valueCls">{{certificateInfo?.volunteerName ? (certificateInfo?.volunteerName) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Application Date:</label>
                    <div class="valueCls">{{certificateInfo?.applicationDate ? (certificateInfo?.applicationDate | date: 'dd/MM/yyyy') : '-' }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
            <!-- <div class="subHeadingCls mb-3">Address Details</div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">District:</label>
                        <div class="valueCls">{{certificateInfo?.familyAddress?.district ? (certificateInfo?.familyAddress?.district) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Mandal/Circle:</label>
                        <div class="valueCls">{{certificateInfo.familyAddress?.mandalMunicipality }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Village/Ward:</label>
                        <div class="valueCls">{{certificateInfo.familyAddress?.villageWard }}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Door No:</label>
                        <div class="valueCls">{{certificateInfo.familyAddress?.doorNo}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Locality/Land Mark:</label>
                        <div class="valueCls">{{certificateInfo.familyAddress?.streetName }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Pin Code:</label>
                        <div class="valueCls">{{certificateInfo.familyAddress?.pinCode }}</div>
                    </div>
                </div>
            </div>
        <hr> -->
        <div class="subHeadingCls mb-3">Family Details</div>
        <div class="row">
           
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form [formGroup]="aarogyaSri">
                <table class="tableCls mt-2">
                    <thead>
                    <tr> 
                        <th>Member Name</th>
                        <th>Telugu Name</th>
                        <th>Gender</th>
                        <th>Relation with the family head</th>
                        <th>Age</th>
                        <th>Date of Birth</th>
                        <th>Mobile Number</th>
                        <th>Aadhaar Number</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr  *ngFor="let item of certificateInfo?.familyDetails; let i = index">
                            <td>{{item.memberName ? (item.memberName) : '-' }}</td>
                            <td *ngIf="isEdit == false">{{item.teluguName ? (item.teluguName) : '-' }}</td>
                            <td *ngIf="isEdit == true" formArrayName="familyDetailsTableData">
                                <div [formGroupName]="i">
                                    <input type="text" class="inputCls value" name="tbTeluguName" maxlength="50" minlength="2"
                                    [attr.disabled] = "(item.iS_LIVING_WITHFAMILY?.toUpperCase()?.trim() === 'DEATH' ) ? '' : null" 
                                    [required] = "(item.iS_LIVING_WITHFAMILY?.toUpperCase()?.trim() !== 'DEATH' ) ? '' : null"  
                                    id="tbTeluguName"  [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableData.controls[i].get('tbTeluguName')?.errors }" 
                                    formControlName = "tbTeluguName"  (keypress)="matcher($event)">
                                    <div *ngIf="submitted && this.familyDetailsTableData.controls[i].get('tbTeluguName')?.errors" class="invalid-feedback">
                                        <div *ngIf="this.familyDetailsTableData.controls[i].get('tbTeluguName')?.errors?.required">Please enter Telugu Name</div>
                                        <div *ngIf="this.familyDetailsTableData.controls[i].get('tbTeluguName')?.errors?.minlength ">Please enter Valid Telugu Name</div>
                                    </div>
                                </div>
                            </td>
                            <td class="value">{{item.gender ? (item.gender) : '-' }}</td>
                            <td class="value">{{item.relationwithHead ? (item.relationwithHead) : '-' }}</td>
                            <td class="value">{{item.age ? (item.age) : '-' }}</td>
                            <td class="value">{{item.dateOfBirth ? (item.dateOfBirth | date: 'dd/MM/yyyy') : '-' }}</td>
                            <td class="value">{{(item.mobileNumber)? (item.mobileNumber) : '-' }}</td>
                            <td class="value">{{this.numberMasking(item.familyAadharNo) ? this.numberMasking(item.familyAadharNo) : '-' }}</td>
                        </tr>
                    </tbody>
                </table>
            </form>
            </div>
        
        </div>
        <hr>
        <div class="subHeadingCls mb-3">Employment Details</div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <table class="tableCls mt-2">
                    <thead>
                    <tr> 
                        <th>Member Name</th>
                        <th>Is GovernmentEmployee/Pensioner</th>
                        <th>Government Employee Category</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of certificateInfo?.familyDetails">
                            <td>{{item.memberName ? (item.memberName) : '-' }}</td>
                            <td class="value">{{item.isGovernment ? (item.isGovernment) : 'NO' }}</td>
                            <td>{{item.empCategory ?  item.empCategory : '-'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <hr>
        <div class="subHeadingCls mb-3">Family Income Details</div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <table class="tableCls mt-2">
                    <thead>
                    <tr> 
                        <th>Member Name</th>
                        <th>Is Paying Income Tax</th>
                        <th>Annual Income</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of certificateInfo?.familyDetails">
                            <td>{{item.memberName ? (item.memberName) : '-' }}</td>
                            <td class="value">{{item.isPayingIncomeTax ? (item.isPayingIncomeTax) : 'NO' }}</td>
                            <td class="value">{{item.annualIncome ? (item.annualIncome) : '0' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Total Family Income:</label>
                    <div class="valueCls">{{certificateInfo?.totalFamilyIncome ? (certificateInfo?.totalFamilyIncome) : '0' }}</div>
                </div>
            </div>
        </div>
        <hr>
        <div class="subHeadingCls mb-3">Details of Land Holding by the Family</div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <table class="tableCls mt-2">
                    <thead>
                    <tr> 
                        <th>Member Name</th>
                        <th>Land Status</th>
                        <th>Wetland</th>
                        <th>Dryland</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of certificateInfo?.familyDetails">
                            <td>{{item.memberName ? (item.memberName) : '-' }}</td>
                            <td class="value">{{item.landStatus ? (item.landStatus) : 'NO' }}</td>
                            <td class="value">{{item.wetLand ? (item.wetLand) : '0' }}</td>
                            <td class="value">{{item.dryLand ? (item.dryLand) : '0' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Total Family Wetland:</label>
                    <div class="valueCls">{{certificateInfo?.totalFamilyWetland ? (certificateInfo?.totalFamilyWetland) : '0' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Total Family Dryland:</label>
                    <div class="valueCls">{{certificateInfo?.totalFamilyDryland ? (certificateInfo?.totalFamilyDryland) : '0' }}</div>
                </div>
            </div>
        </div>
        <hr>
        <div class="subHeadingCls mb-3">Urban Property Details</div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <table class="tableCls mt-2">
                    <thead>
                    <tr> 
                        <th>Member Name</th>
                        <th>Is Paying Property Tax</th>
                        <th>Total no. of Sq.ft for which tax is paid</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of certificateInfo?.familyDetails">
                            <td>{{item.memberName ? (item.memberName) : '-' }}</td>
                            <td class="value">{{item.isPayingPropertyTax ? (item.isPayingPropertyTax) : 'NO' }}</td>
                            <td class="value">{{item.totalNoOfSqft ? (item.totalNoOfSqft) : '0' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Total Property Tax:</label>
                    <div class="valueCls">{{certificateInfo?.totalPropertyTax ? (certificateInfo?.totalPropertyTax) : '0' }}</div>
                </div>
            </div>
        </div>
        <hr>
        <div class="subHeadingCls mb-3">No. of Cars Owned By The Family</div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <table class="tableCls mt-2">
                    <thead>
                    <tr> 
                        <th>Member Name</th>
                        <th>Has Four Wheeler</th>
                        <th>Four Wheeler</th>
                        <th>Four Wheeler Numbers</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of certificateInfo?.familyDetails">
                            <td>{{item.memberName ? (item.memberName) : '-' }}</td>
                            <td class="value">{{item.isFourWheeler ? (item.isFourWheeler) : '-' }}</td>
                            <td class="value">{{item.noOfFourWheelers ? (item.noOfFourWheelers) : '0' }}</td>
                            <td class="value">{{item.fourWheelerNumbers ? (item.fourWheelerNumbers) : 'NO' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="certificateInfo" *ngIf = "isNewEnrollment">
        <div class="row">
            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
                <ng-container *ngIf="originalFileList && originalFileList.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of originalFileList; let i = index">
                    <div *ngIf="file.documentType === 'Aadhaar Card' || file.DocumentType ==='Aadhaar Card' ">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Aadhaar Card:</label>
                        <div class="valueCls"><a (click)="viewFileforAadhar(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
                <div class="fieldHolder" *ngFor="let file of originalFileList; let i = index">
                    <div *ngIf="file.documentType === 'Family Photo' || file.DocumentType === 'Family Photo'">
                        <span class="numberCls">{{i+1}}</span>
                        <label class="labelCls">Family Photo<span *ngIf="showBtn" style="color:blue">  ('Please upload image with resolution (640 x 480) or lower')</span>:</label>
                                             <div class="valueCls">
    
                            <img [src]="imgSrc" class="img-fluid" width="300" height="250" style="display: block;">
                            <!-- <a (click)="viewFile(file.blobRef, file.fileName)">{{file.fileName}}</a> -->
                           <!--  <a (click)="viewFile(file.blobRef, file.fileName)">Update/Reupload Photo</a>
                            <span (click)="downloadFile(file)" class="download ml-2"> Download</span> -->
                            
                            <!-- <button *ngIf="showBtn" type="button" class="mt-2 btn btn-primary" (click)="viewFile(file.blobRef, file.fileName)">Preview </button> -->
                            <button type="button" class="mx-3 mt-2 btn btn-primary" (click)="downloadFile(file)">Download </button>
                            <button *ngIf="showBtn" type="button" class="mt-2 btn btn-primary" (click)="showReuploadImage()">Update/Reupload Photo </button>
                        </div>
                    </div>
                </div>
                </ng-container>
            </div>
           <!--  <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3" >
                <img [src]="imgSrc" class="img-fluid" width="200" height="140">
            </div> -->
        </div>
    </div>
    <!-- <div class="fileUpload" *ngIf = "!isNewEnrollment && basicInfo && basicInfo.length !==0"> -->
    <div class="fileUpload" style="display: none;">
        <!-- <div class="row">
            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
                <ng-container *ngIf="originalFileList && originalFileList.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of originalFileList; let i = index">
                    <div *ngIf="file.documentType === 'Aadhaar Card'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Aadhaar Card:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef, file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
                </ng-container>
            </div>
        </div> -->
       
        <!--  <div class="subHeadingCls">Document Upload:</div> -->
         <div class="subHeadingCls">Update Family Photo:</div>
         
    
        <div class="row mb-3">
            <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <!--   <label class="labelCls" for="familyPhoto">Update Family Photo :<span class="mandatoryCls">*</span></label> -->
              <label class="labelCls" for="familyPhoto"></label>
                <div class="input-group mb-3">
                    <div class="custom-file">
                    <input type="file" class="custom-file-input" formControlName="familyPhoto" #familyPhoto id="familyPhoto" accept=".jpg, .jpeg , .png" 
                    [ngClass]="{ 'is-invalid':submitted && aarogyaSriView.controls.familyPhoto.errors }" (change)="onFamilyPhotoSelected($event)">
                    <label class="custom-file-label" #familyPhotoLabel for="familyPhoto"><i class="fas fa-search"></i>No File choosen</label>
                    </div>
                    <div class="clearCls" (click)="clearFileUpload('familyPhoto', 'familyPhotoLabel')" *ngIf="aarogyaSriView.controls.familyPhoto.value"></div>
                </div>
                <div *ngIf="submitted && aarogyaSriView.controls.familyPhoto.errors" class="selectError">
                    <div *ngIf="aarogyaSriView.controls.familyPhoto.errors.required">Please upload physical document</div>
                    <div *ngIf="aarogyaSriView.controls.familyPhoto?.errors?.inValidExt && !aarogyaSriView.controls.familyPhoto.errors.required ">Please upload the file in PNG/JPG/JPEG Format</div>
                    <div *ngIf="aarogyaSriView.controls.familyPhoto.errors?.inValidSize && !aarogyaSriView.controls.familyPhoto.errors.required && !aarogyaSriView.controls.familyPhoto.errors.inValidExt">File size exceeding 1MB</div> 
                </div>
            </div>
           <!--  <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <a download href="assets/GUIDELINES.pdf">Guidelines for scanning the Family Photo</a>
            </div> -->
        </div>
        <!-- <div class="btnHolder">
            <button class="greenBtnCls float-right" (click)="submit()">Forward</button>
        </div> -->
    </div>
    </form>