import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-income-ews-certificate-view',
  templateUrl: './income-ews-certificate-view.component.html',
  styleUrls: ['./income-ews-certificate-view.component.css']
})
export class IncomeEwsCertificateViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  @Output() viewImgFile = new EventEmitter();

  
  ngOnInit(): void {
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  viewImageFile(blobRef: any) {
    this.viewImgFile.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
}
