<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
      <div class="breadcrumbCls">
        GSWS / <span class="activePage">Utilization Certificate Upload</span>
      </div>
      <button  class="greenBtnCls float-right mt-5" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" routerLink="/home">Home</button>     
    </div>
    
    <div class="mainPageContainer mr-3">       
      <form [formGroup]="utilizationForm">        
        <h6 class="mb-3 mt-3">Utilization Certificate Upload</h6>     
         
        <div class="row">
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                    <label class="labelCls">Program Conducted : <span class="mandatoryCls">*</span></label>
                </div>
                 <div class="col col-lg-5 col-md-5 col-sm-12 col-xs-12">                    
                    <select class="selectCls"  formControlName="program" (change)="onChangeProgram()"
                      [ngClass]="{ 'is-invalid':submitted && (f.program.errors || f.program.value === 'Select') }">
                      <option [ngValue]= "null" disabled>Select</option>
                      <option *ngFor="let item of programs" [ngValue]="item.programName">{{item.programName}}</option>
                    
                    </select>
                    <div *ngIf="submitted && (f.program.errors || f.program.value === 'Select')" class="selectError">
                      <div *ngIf="f.program.errors?.required || f.program.value === 'Select'">Please select Program</div>
                  </div>
                </div>
       </div>
            
        
          
       <ng-container class="mt-3" *ngIf="showForm">
        <div class="row mt-3">
            <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
            <label class="labelCls">Account Number: <span class="mandatoryCls">*</span></label>
            </div>
            <div class="col col-lg-5 col-md-5 col-sm-12 col-xs-12">                
            <input type="text" class="inputCls" appBlockCopyPaste name="Account" formControlName="accountNumber"
            [ngClass]="{ 'is-invalid':submitted  && f.accountNumber.errors }" required disabled>
                <div *ngIf="submitted && f.accountNumber.errors" class="invalid-feedback">
                   <div *ngIf="f.accountNumber.errors?.required">Account Number is required</div>
             </div>
            </div>
        </div>
          <div class="row mt-3">
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <label class="labelCls">Amount Released : <span class="mandatoryCls">*</span></label>
                </div>
                <div class="col col-lg-5 col-md-5 col-sm-12 col-xs-12">                
                <input type="text" class="inputCls" appBlockCopyPaste name="Amount" formControlName="amount"
                [ngClass]="{ 'is-invalid':submitted  && f.amount.errors }" required disabled>
                    <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                       <div *ngIf="f.amount.errors?.required">Amount Released is required</div>
                 </div>
                </div>
            </div>
            <div class="row mt-3">
              <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <label class="labelCls">Total Amount Utilized: <span class="mandatoryCls">*</span></label>
              </div>
              <div class="col col-lg-5 col-md-5 col-sm-12 col-xs-12">  
                <input type="text" class="inputCls" appBlockCopyPaste name="totalAmount"  formControlName="utilizedAmount" numbersOnly
                [ngClass]="{ 'is-invalid':submitted  && f.utilizedAmount.errors }" required (keyup)="calculateUnSpent()" maxlength="15">
                 <div *ngIf="submitted && f.utilizedAmount.errors" class="invalid-feedback">
                 <div *ngIf="f.utilizedAmount.errors?.required">Total Amount Utilized is required</div>
                 </div>
             </div>
             
             </div>
             <div class="row mt-3">       
            <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <label class="labelCls">Unspent Balance: <span class="mandatoryCls">*</span></label>
            </div>
            <div class="col col-lg-5 col-md-5 col-sm-12 col-xs-12">  
                <input type="text" class="inputCls" appBlockCopyPaste name="unspentBal" formControlName="unSpentBalance" numbersOnly
                [ngClass]="{ 'is-invalid':submitted  && f.unSpentBalance.errors }" disabled  required>
                  <div *ngIf="submitted && f.unSpentBalance.errors" class="invalid-feedback">
                    <div *ngIf="f.unSpentBalance.errors?.required">Unspent Balance is required</div>
                  </div>
             </div>
            </div>


            <div  class="mr-3 ml-4" *ngIf="showFields">
                <div class="row mt-3">
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label class="labelCls">Date of remittance back to the GSWS Head Office A/C:<span class="mandatoryCls">*</span></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div class="input-group">
                          <input class="calendarCls form-control" placeholder="dd/mm/yyyy"
                              name="a" ngbDatepicker #a="ngbDatepicker"
                              [minDate]="minDate" [maxDate]="maxDate" 
                              formControlName="dateOfRemittance"  [ngClass]="{'is-invalid':submitted &&
                              f.dateOfRemittance?.errors }">
                          <div class="input-group-append">
                              <button class="btn btn-outline-secondary calendar buton"
                                  (click)="a.toggle()" type="button"></button>
                          </div>
                          <div *ngIf="submitted && f.dateOfRemittance.errors"
                              class="invalid-feedback">
                              <div
                                  *ngIf="f.dateOfRemittance.errors?.required || f.dateOfRemittance?.invalid">
                                  Please enter valid Date of remittance back to the GSWS Head Office A/C
                              </div>
                          </div>
                      </div>
                </div>
                </div>
                <div class="row mt-3">
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <label class="labelCls">UTR/Cheque No : <span class="mandatoryCls">*</span></label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <input type="text" class="inputCls" appBlockCopyPaste name="UTRcheckNo" formControlName="checkNo"
                        [ngClass]="{ 'is-invalid':submitted  && f.checkNo.errors }" maxlength="25" minlength="10">
                          <div *ngIf="submitted && f.checkNo.errors" class="invalid-feedback">
                            <div *ngIf="f.checkNo.errors?.required">UTR/Cheque No is required</div>
                            <div *ngIf="f.checkNo.errors?.minlength">Please enter valid UTR/Cheque No</div>

                          </div>
        
                    </div>
            </div>      

            <div class="row mt-3">
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <label class="labelCls">Upload UTR/Cheque receipt : <span class="mandatoryCls">*</span></label>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div class="input-group mb-3">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" formControlName="checkReceiptDoc"
                            id="checkReceiptDoc" accept=".pdf" (change)="onFileChange($event,'checkReceiptDoc','checkReceiptDocLabel')"
                            required
                            [ngClass]="{ 'is-invalid':submitted && f.checkReceiptDoc.errors }">
                        <label class="custom-file-label" #checkReceiptDocLabel for="checkReceiptDoc"><i
                                class="fas fa-search"></i>No File choosen</label>
                    </div>
                    <div class="clearCls"
                        (click)="clearFileUpload('checkReceiptDoc', 'checkReceiptDocLabel')"
                        *ngIf="f.checkReceiptDoc.value"></div>
                </div>
                    <div *ngIf="submitted && f.checkReceiptDoc.errors" class="selectError">
                        <div *ngIf="f.checkReceiptDoc.errors.required">Please
                            upload UTR/Cheque receipt </div>
                        <div
                            *ngIf="f.checkReceiptDoc.errors?.inValidExt && !f.checkReceiptDoc.errors.required ">
                            Please upload UTR/Cheque receipt in PDF Format</div>
                        <div
                            *ngIf="f.checkReceiptDoc.errors?.inValidSize && !f.checkReceiptDoc.errors.required && !f.checkReceiptDoc.errors.inValidExt">
                            File size exceeding 1MB</div>
                    </div>
            </div>
            </div>

        </div>             

    

                      
            
            <div class="subHeadingCls mt-3">Upload Original Bills (You can upload upto 10 bills)<span class="mandatoryCls">*</span></div>
        
        <div class="row mb-3">


            <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div class="checkBoxCont">
                  <label class="labelCls" for="challan">Bill 1<span
                          class="mandatoryCls">*</span></label>
              </div>
              <div class="input-group mb-3">
                  <div class="custom-file">
                      <input type="file" class="custom-file-input" formControlName="bill1"
                          id="Bill1" accept=".pdf" (change)="onFileChange($event,'bill1','billLabel1')"
                          required
                          [ngClass]="{ 'is-invalid':submitted && f.bill1.errors }">
                      <label class="custom-file-label" #billLabel1 for="Bill1"><i
                              class="fas fa-search"></i>No File choosen</label>
                  </div>
                  <div class="clearCls"
                      (click)="clearFileUpload('bill1', 'billLabel1')"
                      *ngIf="f.bill1.value"></div>
              </div>
                  <div *ngIf="submitted && f.bill1.errors" class="selectError">
                      <div *ngIf="f.bill1.errors.required">Please upload Bill 1 </div>
                      <div
                          *ngIf="f.bill1.errors?.inValidExt && !f.bill1.errors.required ">
                          Please upload Bill 1 in PDF Format</div>
                      <div
                          *ngIf="f.bill1.errors?.inValidSize && !f.bill1.errors.required && !f.bill1.errors.inValidExt">
                          File size exceeding 1MB</div>
                  </div>
          </div>
              <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="checkBoxCont">
                      <label class="labelCls" for="Bill2">Bill 2</label>
                  </div>
                  <div class="input-group mb-3">
                      <div class="custom-file">
                          <input type="file" class="custom-file-input" formControlName="bill2"
                              id="Bill2" accept=".pdf" (change)="onFileChange($event,'bill2','billLabel2')"
                              
                              [ngClass]="{ 'is-invalid':submitted && f.bill2.errors }">
                          <label class="custom-file-label" #billLabel2 for="Bill2"><i
                                  class="fas fa-search"></i>No File choosen</label>
                      </div>
                      <div class="clearCls"
                          (click)="clearFileUpload('bill2', 'billLabel2')"
                          *ngIf="f.bill2.value"></div>
                  </div>
                      <div *ngIf="submitted && f.bill2.errors" class="selectError">
                          <!-- <div *ngIf="f.bill2.errors.required">Please upload Bill 2</div> -->
                          <div
                              *ngIf="f.bill2.errors?.inValidExt && !f.bill2.errors.required ">
                              Please upload Bill 2 in PDF Format</div>
                          <div
                              *ngIf="f.bill2.errors?.inValidSize && !f.bill2.errors.required && !f.bill2.errors.inValidExt">
                              File size exceeding 1MB</div>
                      </div>
              </div>


              <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="checkBoxCont">
                    <label class="labelCls" for="Bill3">Bill 3</label>
                </div>
                <div class="input-group mb-3">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" formControlName="bill3"
                            id="Bill3" accept=".pdf" (change)="onFileChange($event,'bill3','billLabel3')"
                            
                            [ngClass]="{ 'is-invalid':submitted && f.bill3.errors }">
                        <label class="custom-file-label" #billLabel3 for="Bill3"><i
                                class="fas fa-search"></i>No File choosen</label>
                    </div>
                    <div class="clearCls"
                        (click)="clearFileUpload('bill3', 'billLabel3')"
                        *ngIf="f.bill3.value"></div>
                </div>
                    <div *ngIf="submitted && f.bill3.errors" class="selectError">
                        <!-- <div *ngIf="f.bill3.errors.required">Please upload Bill 3</div> -->
                        <div
                            *ngIf="f.bill3.errors?.inValidExt && !f.bill3.errors.required">
                            Please upload Bill 3 in PDF Format</div>
                        <div
                            *ngIf="f.bill3.errors?.inValidSize && !f.bill3.errors.required && !f.bill3.errors.inValidExt">
                            File size exceeding 1MB</div>
                    </div>
            </div>
            <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="checkBoxCont">
                    <label class="labelCls" for="Bill4">Bill 4</label>
                </div>
                <div class="input-group mb-3">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" formControlName="bill4"
                            id="Bill4" accept=".pdf" (change)="onFileChange($event,'bill4','billLabel4')"
                            
                            [ngClass]="{ 'is-invalid':submitted && f.bill4.errors }">
                        <label class="custom-file-label" #billLabel4 for="Bill4"><i
                                class="fas fa-search"></i>No File choosen</label>
                    </div>
                    <div class="clearCls"
                        (click)="clearFileUpload('bill4', 'billLabel4')"
                        *ngIf="f.bill4.value"></div>
                </div>
                    <div *ngIf="submitted && f.bill4.errors" class="selectError">
                        <!-- <div *ngIf="f.bill4.errors.required">Please upload Bill 4</div> -->
                        <div
                            *ngIf="f.bill4.errors?.inValidExt && !f.bill4.errors.required ">
                            Please upload Bill 4 in PDF Format</div>
                        <div
                            *ngIf="f.bill4.errors?.inValidSize && !f.bill4.errors.required && !f.bill4.errors.inValidExt">
                            File size exceeding 1MB</div>
                    </div>
            </div>
            <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="checkBoxCont">
                    <label class="labelCls" for="Bill5">Bill 5</label>
                </div>
                <div class="input-group mb-3">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" formControlName="bill5"
                            id="Bill5" accept=".pdf" (change)="onFileChange($event,'bill5','billLabel5')"
                            
                            [ngClass]="{ 'is-invalid':submitted && f.bill5.errors }">
                        <label class="custom-file-label" #billLabel5 for="Bill5"><i
                                class="fas fa-search"></i>No File choosen</label>
                    </div>
                    <div class="clearCls"
                        (click)="clearFileUpload('bill5', 'billLabel5')"
                        *ngIf="f.bill5.value"></div>
                </div>
                    <div *ngIf="submitted && f.bill5.errors" class="selectError">
                        <!-- <div *ngIf="f.bill2.errors.required">Please upload Bill 2</div> -->
                        <div
                            *ngIf="f.bill5.errors?.inValidExt && !f.bill5.errors.required ">
                            Please upload Bill 5 in PDF Format</div>
                        <div
                            *ngIf="f.bill5.errors?.inValidSize && !f.bill5.errors.required && !f.bill5.errors.inValidExt">
                            File size exceeding 1MB</div>
                    </div>
            </div>
            <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="checkBoxCont">
                    <label class="labelCls" for="Bill6">Bill 6</label>
                </div>
                <div class="input-group mb-3">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" formControlName="bill6"
                            id="Bill6" accept=".pdf" (change)="onFileChange($event,'bill6','billLabel6')"
                            
                            [ngClass]="{ 'is-invalid':submitted && f.bill6.errors }">
                        <label class="custom-file-label" #billLabel6 for="Bill6"><i
                                class="fas fa-search"></i>No File choosen</label>
                    </div>
                    <div class="clearCls"
                        (click)="clearFileUpload('bill6', 'billLabel6')"
                        *ngIf="f.bill6.value"></div>
                </div>
                    <div *ngIf="submitted && f.bill6.errors" class="selectError">
                        <!-- <div *ngIf="f.bill2.errors.required">Please upload Bill 2</div> -->
                        <div
                            *ngIf="f.bill6.errors?.inValidExt && !f.bill6.errors.required ">
                            Please upload Bill 6 in PDF Format</div>
                        <div
                            *ngIf="f.bill6.errors?.inValidSize && !f.bill6.errors.required && !f.bill6.errors.inValidExt">
                            File size exceeding 1MB</div>
                    </div>
            </div>
            
            <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="checkBoxCont">
                    <label class="labelCls" for="Bill7">Bill 7</label>
                </div>
                <div class="input-group mb-3">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" formControlName="bill7"
                            id="Bill7" accept=".pdf" (change)="onFileChange($event,'bill7','billLabel7')"
                            
                            [ngClass]="{ 'is-invalid':submitted && f.bill7.errors }">
                        <label class="custom-file-label" #billLabel7 for="Bill7"><i
                                class="fas fa-search"></i>No File choosen</label>
                    </div>
                    <div class="clearCls"
                        (click)="clearFileUpload('bill7', 'billLabel7')"
                        *ngIf="f.bill7.value"></div>
                </div>
                    <div *ngIf="submitted && f.bill7.errors" class="selectError">
                        <!-- <div *ngIf="f.bill2.errors.required">Please upload Bill 2</div> -->
                        <div
                            *ngIf="f.bill7.errors?.inValidExt && !f.bill7.errors.required ">
                            Please upload Bill 7 in PDF Format</div>
                        <div
                            *ngIf="f.bill7.errors?.inValidSize && !f.bill7.errors.required && !f.bill7.errors.inValidExt">
                            File size exceeding 1MB</div>
                    </div>
            </div>
            <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="checkBoxCont">
                    <label class="labelCls" for="Bill8">Bill 8</label>
                </div>
                <div class="input-group mb-3">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" formControlName="bill8"
                            id="Bill8" accept=".pdf" (change)="onFileChange($event,'bill8','billLabel8')"
                            
                            [ngClass]="{ 'is-invalid':submitted && f.bill8.errors }">
                        <label class="custom-file-label" #billLabel8 for="Bill8"><i
                                class="fas fa-search"></i>No File choosen</label>
                    </div>
                    <div class="clearCls"
                        (click)="clearFileUpload('bill8', 'billLabel8')"
                        *ngIf="f.bill8.value"></div>
                </div>
                    <div *ngIf="submitted && f.bill8.errors" class="selectError">
                        <!-- <div *ngIf="f.bill2.errors.required">Please upload Bill 2</div> -->
                        <div
                            *ngIf="f.bill8.errors?.inValidExt && !f.bill8.errors.required ">
                            Please upload Bill 8 in PDF Format</div>
                        <div
                            *ngIf="f.bill8.errors?.inValidSize && !f.bill8.errors.required && !f.bill8.errors.inValidExt">
                            File size exceeding 1MB</div>
                    </div>
            </div>
            <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="checkBoxCont">
                    <label class="labelCls" for="Bill9">Bill 9</label>
                </div>
                <div class="input-group mb-3">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" formControlName="bill9"
                            id="Bill9" accept=".pdf" (change)="onFileChange($event,'bill9','billLabel9')"
                            
                            [ngClass]="{ 'is-invalid':submitted && f.bill9.errors }">
                        <label class="custom-file-label" #billLabel9 for="Bill9"><i
                                class="fas fa-search"></i>No File choosen</label>
                    </div>
                    <div class="clearCls"
                        (click)="clearFileUpload('bill9', 'billLabel9')"
                        *ngIf="f.bill9.value"></div>
                </div>
                    <div *ngIf="submitted && f.bill9.errors" class="selectError">
                        <!-- <div *ngIf="f.bill9.errors.required">Please upload Bill 9</div> -->
                        <div
                            *ngIf="f.bill9.errors?.inValidExt && !f.bill9.errors.required ">
                            Please upload Bill 9 in PDF Format</div>
                        <div
                            *ngIf="f.bill9.errors?.inValidSize && !f.bill9.errors.required && !f.bill9.errors.inValidExt">
                            File size exceeding 1MB</div>
                    </div>
            </div>
            <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="checkBoxCont">
                    <label class="labelCls" for="Bill10">Bill 10</label>
                </div>
                <div class="input-group mb-3">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" formControlName="bill10"
                            id="Bill10" accept=".pdf" (change)="onFileChange($event,'bill10','billLabel10')"
                            
                            [ngClass]="{ 'is-invalid':submitted && f.bill10.errors }">
                        <label class="custom-file-label" #billLabel10 for="Bill10"><i
                                class="fas fa-search"></i>No File choosen</label>
                    </div>
                    <div class="clearCls"
                        (click)="clearFileUpload('bill10', 'billLabel10')"
                        *ngIf="f.bill10.value"></div>
                </div>
                    <div *ngIf="submitted && f.bill10.errors" class="selectError">
                        <!-- <div *ngIf="f.bill10.errors.required">Please upload Bill 10</div> -->
                        <div
                            *ngIf="f.bill10.errors?.inValidExt && !f.bill10.errors.required ">
                            Please upload Bill 10 in PDF Format</div>
                        <div
                            *ngIf="f.bill10.errors?.inValidSize && !f.bill10.errors.required && !f.bill10.errors.inValidExt">
                            File size exceeding 1MB</div>
                    </div>
            </div>
             </div>          
                
           
            <div class="row">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">            
                <label class="labelCls" for="UtilizationDoc">Upload Utilization Certificate<span class="mandatoryCls">*</span></label>
               </div>
            <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12"><a class="ml-3" download href="assets/UCformat.doc">Click here to download Utilization certificate format</a></div>
                
            </div>

            <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
            <div class="input-group mb-3">
                <div class="custom-file">
                    <input type="file" class="custom-file-input"
                        formControlName="utilizationDoc" id="UtilizationDoc"
                        accept=".pdf,.doc,.docx" (change)="onFileChange($event,'utilizationDoc','utilizationDocLabel')"
                        required
                        [ngClass]="{ 'is-invalid' :submitted && f.utilizationDoc.errors}">
                    <label class="custom-file-label" #utilizationDocLabel
                        for="UtilizationDoc"><i class="fas fa-search"></i>No File
                        choosen</label>
                </div>
                <div class="clearCls"
                    (click)="clearFileUpload('utilizationDoc', 'utilizationDocLabel')"
                    *ngIf="f.utilizationDoc.value"></div>
            </div>

                <div *ngIf="submitted && f.utilizationDoc.errors"
                    class="selectError">
                    <div *ngIf="f.utilizationDoc.errors.required">Please upload Utilization Certificate</div>
                    <div *ngIf="f.utilizationDoc.errors?.inValidExt && !f.utilizationDoc.errors.required">                                              Please
                        upload Utilization Certificate in PDF Format</div>
                    <div *ngIf="f.utilizationDoc.errors?.inValidSize && !f.utilizationDoc.errors?.inValidExt && !f.utilizationDoc.errors.required">
                        File size exceeding 1MB</div>
                </div>
            </div>

           
                <hr class="hrCls mt-4">
                <div class="row mb-3">
                    <div class="ml-4 col-sm-9">
                        <input class="form-check-input " type="checkbox" formControlName="consent" required
                            value="" id="consent"
                            [ngClass]="{ 'is-invalid':submitted && f.consent.errors }">
                        <label class="labelCls" for="consent"><b>I (Applicant) agree that the
                          information/documents submitted is with my consent and can be verified. <span
                                    class="mandatoryCls">*</span></b> </label>
                        <div *ngIf="submitted && f.consent.errors" class="invalid-feedback">
                            <div *ngIf="f.consent.errors.required">Please Check the Consent</div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                  <div class="ml-4 col-sm-9">
                      <input class="form-check-input " type="checkbox" formControlName="consent2" required
                          value="" id="consent2"
                          [ngClass]="{ 'is-invalid':submitted && f.consent2.errors }">
                      <label class="labelCls" for="consent2"><b>I (Verify Authority) , hereby, declare that the particulars
                        given above are correct and complete. I will be responsible for any misrepresentation
                        of facts and will be liable for punitive action. <span
                                  class="mandatoryCls">*</span></b> </label>
                      <div *ngIf="submitted && f.consent2.errors" class="invalid-feedback">
                          <div *ngIf="f.consent2.errors.required">Please Check the Consent</div>
                      </div>
                  </div>
              </div>
              <div class="btnHolder">
                <button class="greenBtnCls float-right" (click) = "submit()">Save</button>
              </div>
            
            
            </ng-container>
        
      </form>

      <ng-container *ngIf="isHistory">

        <!-- <h6 class="mb-3 mt-3">Utilization Certificate Upload Preview</h6> -->
                        <div class="row mb-3 mt-3">
                            <div class="col">
                                <div class="tab tableScroll">
                                    <table class="tableCls">
                                        <thead>
                                            <tr>
                                                <th>S NO</th>
                                                <th>Program</th>
                                                <th>Amount Released</th>
                                                <th>Total Amount Utilized</th>
                                                <th>Unspent Balance</th>
                                                <th style="width:15%">Date of remittance back to the GSWS Head Office A/C </th>
                                                <th>UTR/Cheque No</th>
                                                <th>Account Number</th>
                                                <th>UTR/Cheque receipt</th>
                                                <th>Original Bills</th>
                                                <th>Utilization Certificate</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let data of history;let i=index">
                                             <td>{{i+1}}</td>
                                             <td>{{data.programName}}</td>
                                             <td>{{data.amountReleased}}</td>
                                             <td>{{data.totalAmountUtilized}}</td>
                                             <td>{{data.unSpendBalance}}</td>
                                             <td>{{data.dateRemittanceBack | date: 'dd/MM/yyyy' }}</td>
                                             <td>{{data.chequeNo}}</td>
                                             <td>{{numberMasking(this.commonService.RSADecrypt(data.accountNumber))}}</td>                                             
                                             <td><div *ngIf="data.chequeReceiptFile" class="valueCls"><a style="color:blue" (click)="viewFile(data.chequeReceiptFile.id)">{{data.chequeReceiptFile?.filename}}</a><span (click)="downloadFile(data.chequeReceiptFile)" class="download ml-2"></span></div></td>
                                             <td><table><tbody>
                                                <tr *ngFor="let files of data.uploadedBills">
                                                    <td style="border:none"><div class="valueCls"><a style="color:blue" (click)="viewFile(files.id)">{{files.filename}}</a><span (click)="downloadFile(files)" class="download ml-2"></span></div></td>
                                                </tr>
                                             </tbody></table>                                               
                                            </td>
                                             <td><div class="valueCls">{{data.utilizationCertificateFile?.filename}}<span (click)="downloadFile(data.utilizationCertificateFile)" class="download ml-2"></span></div></td>
                                             </tr>
                                        </tbody>
                                    </table><br>
                                </div>
                            </div>
                        </div>
      </ng-container>  
      
      
    </div>
  </div>
  