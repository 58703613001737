<div class="reportContainer"> 
<div class="tabClass">
<div class="btnHolder">
    <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
    <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
</div>
</div>
<div class="row">
    <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
<div class="table-responsive tableFixHead" *ngIf="showReports">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th [attr.colspan]="noOfCol">District level balance stationary balance report as on date</th>
            </tr>
            <tr>
                <th [attr.rowspan]="2">S.No</th>
                <th [attr.rowspan]="2" aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'districT_NAME'">District <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="4" aria-controls="tableSortExample" scope="col">Calendars</th>
                <th colspan="4" aria-controls="tableSortExample" scope="col">Booklets</th>
                <th colspan="4" aria-controls="tableSortExample" scope="col">Brochures</th>
                <th colspan="4" aria-controls="tableSortExample" scope="col">TShirts</th>
                <th colspan="4" aria-controls="tableSortExample" scope="col">Caps</th>
                <th colspan="4" aria-controls="tableSortExample" scope="col">Badges</th>
            </tr>
            <tr>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opnG_STOCK_CALENDARS'">Opening stock <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rcvD_STOCK_CALENDARS'">Received today <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'disT_STOCK_CALENDARS'">Distributed today <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsnG_STOCK_CALENDARS'">Closing stock <mdb-icon fas icon="sort"></mdb-icon></th>
                
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opnG_STOCK_BOOKLETS'">Opening stock <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rcvD_STOCK_BOOKLETS'">Received today <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'disT_STOCK_BOOKLETS'">Distributed today <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsnG_STOCK_BOOKLETS'">Closing stock<mdb-icon fas icon="sort"></mdb-icon></th>

               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opnG_STOCK_BROCHURES'">Opening stock <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rcvD_STOCK_BROCHURES'">Received today <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'disT_STOCK_BROCHURES'">Distributed today <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsnG_STOCK_BROCHURES'">Closing stock <mdb-icon fas icon="sort"></mdb-icon></th>   
               
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opnG_STOCK_TSHIRTS'">Opening stock <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rcvD_STOCK_TSHIRTS'">Received today <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'disT_STOCK_TSHIRTS'">Distributed today <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsnG_STOCK_TSHIRTS'">Closing stock <mdb-icon fas icon="sort"></mdb-icon></th>            

               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opnG_STOCK_CAPS'">Opening stock <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rcvD_STOCK_CAPS'">Received today <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'disT_STOCK_CAPS'">Distributed today <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsnG_STOCK_CAPS'">Closing stock <mdb-icon fas icon="sort"></mdb-icon></th>            

               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opnG_STOCK_BADGES'">Opening stock <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rcvD_STOCK_BADGES'">Received today <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'disT_STOCK_BADGES'">Distributed today <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsnG_STOCK_TBADGES'">Closing stock <mdb-icon fas icon="sort"></mdb-icon></th>            
            </tr> 
        </thead>

        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items;let i = index;"> 
            <td width="100px">{{i + 1}}</td>
            <td>{{item.districT_NAME}}</td>
            <td>{{item.opnG_STOCK_CALENDARS}}</td>
            <td>{{item.rcvD_STOCK_CALENDARS}}</td>
            <td>{{item.disT_STOCK_CALENDARS}}</td>
            <td>{{item.clsnG_STOCK_CALENDARS}}</td>
            <td>{{item.opnG_STOCK_BOOKLETS}}</td>
            <td>{{item.rcvD_STOCK_BOOKLETS}}</td>
            <td>{{item.disT_STOCK_BOOKLETS}}</td>
            <td>{{item.clsnG_STOCK_BOOKLETS}}</td>
            <td>{{item.opnG_STOCK_BROCHURES}}</td>
            <td>{{item.rcvD_STOCK_BROCHURES}}</td>
            <td>{{item.disT_STOCK_BROCHURES}}</td>
            <td>{{item.clsnG_STOCK_BROCHURES}}</td>
            <td>{{item.opnG_STOCK_TSHIRTS}}</td>
            <td>{{item.rcvD_STOCK_TSHIRTS}}</td>
            <td>{{item.disT_STOCK_TSHIRTS}}</td>
            <td>{{item.clsnG_STOCK_TSHIRTS}}</td>
            <td>{{item.opnG_STOCK_CAPS}}</td>
            <td>{{item.rcvD_STOCK_CAPS}}</td>
            <td>{{item.disT_STOCK_CAPS}}</td>
            <td>{{item.clsnG_STOCK_CAPS}}</td>
            <td>{{item.opnG_STOCK_BADGES}}</td>
            <td>{{item.rcvD_STOCK_BADGES}}</td>
            <td>{{item.disT_STOCK_BADGES}}</td>
            <td>{{item.clsnG_STOCK_TBADGES}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    </div>
    <div class="mt-4 btnHide" *ngIf="showReports">
        <div class="float-left" *ngIf="items.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
</div>
</div>
</div>
<div class="table-responsive tableFixHead" #TABLE class="hideTable">
    <table class="tableCls" >
        <thead>
         <tr>
             <th [attr.colspan]="noOfCol">District level balance stationary balance report as on date</th>
         </tr>
         <tr>
            <th [attr.rowspan]="2">S.No</th>
            <th [attr.rowspan]="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District <mdb-icon fas icon="sort"></mdb-icon></th>
            <th colspan="4" aria-controls="tableSortExample" scope="col">Calendars</th>
            <th colspan="4" aria-controls="tableSortExample" scope="col">Booklets</th>
            <th colspan="4" aria-controls="tableSortExample" scope="col">Brochures</th>
            <th colspan="4" aria-controls="tableSortExample" scope="col">TShirts</th>
            <th colspan="4" aria-controls="tableSortExample" scope="col">Caps</th>
            <th colspan="4" aria-controls="tableSortExample" scope="col">Badges</th>
         </tr>
         <tr>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opnG_STOCK_CALENDARS'">Opening stock <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rcvD_STOCK_CALENDARS'">Received today <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'disT_STOCK_CALENDARS'">Distributed today <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsnG_STOCK_CALENDARS'">Closing stock <mdb-icon fas icon="sort"></mdb-icon></th>
             
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opnG_STOCK_BOOKLETS'">Opening stock <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rcvD_STOCK_BOOKLETS'">Received today <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'disT_STOCK_BOOKLETS'">Distributed today <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsnG_STOCK_BOOKLETS'">Closing stock<mdb-icon fas icon="sort"></mdb-icon></th>

            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opnG_STOCK_BROCHURES'">Opening stock <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rcvD_STOCK_BROCHURES'">Received today <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'disT_STOCK_BROCHURES'">Distributed today <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsnG_STOCK_BROCHURES'">Closing stock <mdb-icon fas icon="sort"></mdb-icon></th>
            
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opnG_STOCK_TSHIRTS'">Opening stock <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rcvD_STOCK_TSHIRTS'">Received today <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'disT_STOCK_TSHIRTS'">Distributed today <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsnG_STOCK_TSHIRTS'">Closing stock <mdb-icon fas icon="sort"></mdb-icon></th>            

            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opnG_STOCK_CAPS'">Opening stock <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rcvD_STOCK_CAPS'">Received today <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'disT_STOCK_CAPS'">Distributed today <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsnG_STOCK_CAPS'">Closing stock <mdb-icon fas icon="sort"></mdb-icon></th>            

            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opnG_STOCK_BADGES'">Opening stock <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rcvD_STOCK_BADGES'">Received today <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'disT_STOCK_BADGES'">Distributed today <mdb-icon fas icon="sort"></mdb-icon></th>
            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsnG_STOCK_TBADGES'">Closing stock <mdb-icon fas icon="sort"></mdb-icon></th>            

         </tr>
        </thead>
 
        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items;let i = index;"> 
            <td width="100px">{{i + 1}}</td>
            <td>{{item.districT_NAME}}</td>
            <td>{{item.opnG_STOCK_CALENDARS}}</td>
            <td>{{item.rcvD_STOCK_CALENDARS}}</td>
            <td>{{item.disT_STOCK_CALENDARS}}</td>
            <td>{{item.clsnG_STOCK_CALENDARS}}</td>
            <td>{{item.opnG_STOCK_BOOKLETS}}</td>
            <td>{{item.rcvD_STOCK_BOOKLETS}}</td>
            <td>{{item.disT_STOCK_BOOKLETS}}</td>
            <td>{{item.clsnG_STOCK_BOOKLETS}}</td>
            <td>{{item.opnG_STOCK_BROCHURES}}</td>
            <td>{{item.rcvD_STOCK_BROCHURES}}</td>
            <td>{{item.disT_STOCK_BROCHURES}}</td>
            <td>{{item.clsnG_STOCK_BROCHURES}}</td>
            <td>{{item.opnG_STOCK_TSHIRTS}}</td>
            <td>{{item.rcvD_STOCK_TSHIRTS}}</td>
            <td>{{item.disT_STOCK_TSHIRTS}}</td>
            <td>{{item.clsnG_STOCK_TSHIRTS}}</td>
            <td>{{item.opnG_STOCK_CAPS}}</td>
            <td>{{item.rcvD_STOCK_CAPS}}</td>
            <td>{{item.disT_STOCK_CAPS}}</td>
            <td>{{item.clsnG_STOCK_CAPS}}</td>
            <td>{{item.opnG_STOCK_BADGES}}</td>
            <td>{{item.rcvD_STOCK_BADGES}}</td>
            <td>{{item.disT_STOCK_BADGES}}</td>
            <td>{{item.clsnG_STOCK_TBADGES}}</td>
        </tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
             <td></td>
             <td>{{totalItems[0].districT_NAME}}</td>
             <td>{{totalItems[0].opnG_STOCK_CALENDARS}}</td>
             <td>{{totalItems[0].rcvD_STOCK_CALENDARS}}</td>
             <td>{{totalItems[0].disT_STOCK_CALENDARS}}</td>
             <td>{{totalItems[0].clsnG_STOCK_CALENDARS}}</td>
             <td>{{totalItems[0].opnG_STOCK_BOOKLETS}}</td>
             <td>{{totalItems[0].rcvD_STOCK_BOOKLETS}}</td>
             <td>{{totalItems[0].disT_STOCK_BOOKLETS}}</td>
             <td>{{totalItems[0].clsnG_STOCK_BOOKLETS}}</td>
             <td>{{totalItems[0].opnG_STOCK_BROCHURES}}</td>
             <td>{{totalItems[0].rcvD_STOCK_BROCHURES}}</td>
             <td>{{totalItems[0].disT_STOCK_BROCHURES}}</td>
             <td>{{totalItems[0].clsnG_STOCK_BROCHURES}}</td>
             <td>{{totalItems[0].opnG_STOCK_TSHIRTS}}</td>
             <td>{{totalItems[0].rcvD_STOCK_TSHIRTS}}</td>
             <td>{{totalItems[0].disT_STOCK_TSHIRTS}}</td>
             <td>{{totalItems[0].clsnG_STOCK_TSHIRTS}}</td>
             <td>{{totalItems[0].opnG_STOCK_CAPS}}</td>
             <td>{{totalItems[0].rcvD_STOCK_CAPS}}</td>
             <td>{{totalItems[0].disT_STOCK_CAPS}}</td>
             <td>{{totalItems[0].clsnG_STOCK_CAPS}}</td>
             <td>{{totalItems[0].opnG_STOCK_BADGES}}</td>
             <td>{{totalItems[0].rcvD_STOCK_BADGES}}</td>
             <td>{{totalItems[0].disT_STOCK_BADGES}}</td>
             <td>{{totalItems[0].clsnG_STOCK_TBADGES}}</td>
         </tr>
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
 </div>

