<div class="certificateInfo ">
    <div class="subHeadingCls mb-3">Applicant Details
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder" *ngIf="certificateInfo?.typeOfPension ==='1'">
                <label class="labelCls">Type of Pension:</label>
                <div class="valueCls">O.A.P</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.typeOfPension ==='2'">
                <label class="labelCls">Type of Pension:</label>
                <div class="valueCls">Weavers</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.typeOfPension ==='3'">
                <label class="labelCls">Type of Pension:</label>
                <div class="valueCls">Disabled</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.typeOfPension ==='4'">
                <label class="labelCls">Type of Pension:</label>
                <div class="valueCls">Widow</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.typeOfPension ==='5'">
                <label class="labelCls">Type of Pension:</label>
                <div class="valueCls">Toddy Tappers</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.typeOfPension ==='6'">
                <label class="labelCls">Type of Pension:</label>
                <div class="valueCls">Transgender</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.typeOfPension ==='7'">
                <label class="labelCls">Type of Pension:</label>
                <div class="valueCls">Fishermen</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.typeOfPension ==='8'">
                <label class="labelCls">Type of Pension:</label>
                <div class="valueCls">Single Women</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.typeOfPension ==='9'">
                <label class="labelCls">Type of Pension:</label>
                <div class="valueCls">Traditional Cobblers</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.typeOfPension ==='10'">
                <label class="labelCls">Type of Pension:</label>
                <div class="valueCls">Dappu Artists</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.typeOfPension ==='3'">
                <label class="labelCls">Disabled:</label>
                <div class="valueCls">{{certificateInfo?.disabled ?certificateInfo?.disabled : '-' }}</div>
            </div>
        </div>
    </div>
</div>

<div class="certificateInfo ">

    <div class="subHeadingCls mb-3">Family Details along with eligibility criteria</div>

    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                    <tr>
                        <th>S NO</th>
                        <th>Name of Person</th>
                        <th>Gender</th>
                        <th>DOB</th>
                        <th>Wet Land (Acres)</th>
                        <th>Dry Land (Acres)</th>
                        <th>Property(Sqft)</th>
                        <th>Vehicle(Yes/No)</th>
                        <th>Govt Employee (Yes/No)</th>
                        <th>Income Tax(Yes/No)</th>
                        <th>Power(Units)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of certificateInfo?.familyMembers;let i=index">
                        <td class="serial">{{i+1}}</td>
                        <td>{{item?.memberName}}</td>
                        <td>{{item?.gender}}</td>
                        <td class="value">{{item?.dob | date: 'dd/MM/yyyy'}}</td>
                        <td class="value">{{item?.wetLandAcres ? (item?.wetLandAcres):'-'}}</td>
                        <td class="value">{{item?.dryLandAcres ? (item?.dryLandAcres):'-'}}</td>
                        <td class="value">{{item?.property ? (item?.property):'-'}}</td>
                        <td>{{item?.vehicle ? (item?.vehicle):'-'}}</td>
                        <td>{{item?.govtEmployee ? (item?.govtEmployee):'-'}}</td>
                        <td>{{item?.incomeTax ? (item?.incomeTax):'-'}}</td>
                        <td class="value">{{item?.powerUnits ? (item?.powerUnits):'-'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>

<div class="certificateInfo" *ngIf="certificateInfo?.electricityConnectionDetails?.houseType && certificateInfo?.electricityConnectionDetails?.connectionNumber">

    <div class="subHeadingCls mb-3">Electricity Connection Details</div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                    <tr>
                        <th>House Type</th>
                        <th>Meter Type</th>
                        <th>Electricity Board</th>
                        <th>Connection Number</th>
                      
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{certificateInfo?.electricityConnectionDetails?.houseType}}</td>
                        <td>{{certificateInfo?.electricityConnectionDetails?.meterType}}</td>
                        <td>{{certificateInfo?.electricityConnectionDetails?.electricityBoard | uppercase}}</td>
                        <td>{{certificateInfo?.electricityConnectionDetails?.connectionNumber}}</td>
                       
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="certificateInfo" *ngIf="certificateInfo?.urbanPropertyDetails?.houseTypeUrban && certificateInfo?.urbanPropertyDetails?.assessmentNumber">

    <div class="subHeadingCls mb-3">Urban Property Details</div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                    <tr>
                        <th>House Type</th>
                        <th>Assessment</th>
                        <th>Assessment Number</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{certificateInfo?.urbanPropertyDetails?.houseTypeUrban}}</td>
                        <td>{{certificateInfo?.urbanPropertyDetails?.assessment}}</td>
                        <td>{{certificateInfo?.urbanPropertyDetails?.assessmentNumber}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="certificateInfo ">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Cluster ID:</label>
                <div class="valueCls">{{certificateInfo?.clusterId ?certificateInfo?.clusterId:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Volunteer Name:</label>
                <div class="valueCls">{{certificateInfo?.volunteerName?certificateInfo?.volunteerName:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Volunteer Mobile:</label>
                <div class="valueCls">{{certificateInfo?.volunteerMobile?certificateInfo?.volunteerMobile:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Sub caste:</label>
                <div class="valueCls">{{certificateInfo?.subCaste}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Relation Type:</label>
                <div class="valueCls">{{certificateInfo?.relationType}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Relation Name:</label>
                <div class="valueCls">{{certificateInfo?.relationName}} </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Panchayat Name:</label>
                <div class="valueCls">{{certificateInfo?.panchayatName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Habitation Name:</label>
                <div class="valueCls">{{certificateInfo?.habitationName}}</div>
            </div>
        </div>
    </div>
    <hr class="hrCls mt-4">
    <div class="row">
        <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Income Certificate Number:</label>
                <div class="valueCls">{{certificateInfo?.incomeCertificateNumber}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Name:</label>
                <div class="valueCls">{{certificateInfo?.incomeCertificateinfo?.name}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Annual Income:</label>
                <div class="valueCls">{{certificateInfo?.incomeCertificateinfo?.annualIncomevalue}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Eligible as per Income Certificate:</label>
                <div class="valueCls">{{certificateInfo?.incomeCertificateinfo?.incomeConfirmation}}</div>
            </div>
        </div> -->
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Caste Certificate Number:</label>
                <div class="valueCls">{{certificateInfo?.casteCertificateNumber}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Name:</label>
                <div class="valueCls">{{certificateInfo?.casteCertificateinfo?.name}} </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Caste:</label>
                <div class="valueCls">{{certificateInfo?.casteCertificateinfo?.caste}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Sub Caste:</label>
                <div class="valueCls">{{certificateInfo?.casteCertificateinfo?.subCaste}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Eligible as per Caste Certificate:</label>
                <div class="valueCls">{{certificateInfo?.confirmationOnCaste}}</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.castUnderVerification === 'Caste Under Verification'">
                <label class="labelCls">Caste Under Verification:</label>
                <div class="valueCls">{{certificateInfo?.castUnderVerification === 'Caste Under Verification' ? 'Yes' : 'No'}}</div>
            </div>
        </div>
    </div>
    <hr class="hrCls mt-4">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Any other Person in Family taking other Pensions:</label>
                <div class="valueCls">{{certificateInfo?.isFamilyPersonTakingOtherPensions}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">How many times Aadhar updated:</label>
                <div class="valueCls">{{certificateInfo?.uidUpdationNumber}}</div>
            </div>
        </div>
    </div>
</div>
<div  *ngIf="certificateInfo?.uidUpdateinfo.length != 0">
    <div class="certificateInfo" *ngIf="certificateInfo?.uidUpdationNumber ===0">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <table class="tableCls mt-2">
                    <thead>
                        <tr>
                            <th>S NO</th>
                            <th>Enrollment date</th>
                            <th>Present Date of Birth</th>
                          
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let info of certificateInfo?.uidUpdateinfo;let i=index">
                            <td class="serial">{{i+1}}</td>
                            <td>{{info?.enrollmentDate | date: 'dd/MM/yyyy'}}</td>
                            <td class="value">{{info?.presentDOB | date: 'dd/MM/yyyy'}}</td>
                           
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    
    </div>
    <div class="certificateInfo " *ngIf="certificateInfo?.uidUpdationNumber !==0">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <table class="tableCls mt-2">
                    <thead>
                        <tr>
                            <th>S NO</th>
                            <th>Type of Update</th>
                            <th>Previous Date of Birth</th>
                            <th>Changed Date of Birth</th>
                            <th>Date of Update</th>
                            <!-- <th>Date of Birth</th>
                            <th>Date of Change</th> -->
                            <!-- <th>Proof Document</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let info of certificateInfo?.uidUpdateinfo;let i=index">
                            <td class="serial">{{i+1}}</td>
                            <td>{{info?.typeOfUpdate}}</td>
                            <td class="value">{{info?.previousDate | date: 'dd/MM/yyyy'}}</td>
                            <td class="value">{{info?.dob | date: 'dd/MM/yyyy' }}</td>
                            <td class="value">{{info?.dateofUpdate | date: 'dd/MM/yyyy' }}</td>
    
    
                            <!-- <td style="width:70%">
                                <ng-container *ngIf="fileList">
                                    <div class="fieldHolder" *ngFor="let file of fileList ; let j = index">
                                        <div *ngIf="file.documentType === 'uploaddoc'+i">
                                            <div class="valueCls"><a
                                                    (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                                    (click)="downloadFile(file)" class="download ml-2"></span></div>
                                        </div>
                                    </div>
                                </ng-container>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    
    </div>
  

</div>

   


<div class="certificateInfo">




    <hr class="hrCls mt-4">
<div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
         
            <label class="labelCls">Residence proof of citizen:</label>
            <div class="valueCls">{{certificateInfo?.residenceProofOfCitizen ? certificateInfo?.residenceProofOfCitizen : '-'}}</div>
        </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          
            <label class="labelCls">Document Id:</label>
            <div class="valueCls">{{certificateInfo?.documentId ? certificateInfo?.documentId : '-'}}</div>
        </div>
    </div>
</div>

<hr class="hrCls mt-4">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of fileList ;let i = index">
                    <div *ngIf="file.documentType === 'AgeProofForm'">
                        <span class="numberCls">{{i}}</span><label class="labelCls">Age Proof Document:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'PensionCertificateForm'">
                        <span class="numberCls">{{i}}</span><label class="labelCls">Pension Category Certificate
                            Proof:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'uploaddoc'">
                        <span class="numberCls">{{i}}</span><label class="labelCls">Aadhar Update History Document
                            Proof:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'residenceUploadDocument'">
                        <span class="numberCls">{{i}}</span><label class="labelCls">Residence Proof Document:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>