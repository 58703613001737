<div class="container-fluid mainCls">
  <div class="breadcrumbContainer  mr-3">
    <div class="breadcrumbCls">
      GSWS / Application Submission / Minorities Welfare Department <span class="activePage">/ Muslim Marriage Certificate</span>
    </div>
  </div>
  <div class="mainPageContainer mr-3">
    <h6>Muslim Marriage Certificate Application Form</h6>
    <form [formGroup]="marriageCertificate">
      <nav ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)">
        <ng-container [ngbNavItem] = "1">
          <a ngbNavLink>Basic Details</a>
          <ng-template ngbNavContent>
            <app-common-form formControlName="commonForm" [isSubmitted]="commonFormSubmitted" (casteChange)="getSubCaste($event)" (reset)="commonFormSubmitted = false"></app-common-form>
            <div class="btnHolder">
              <button  class="greenBtnCls float-right" (click)="goToNextTab()">Continue</button>
            </div>
          </ng-template>
        </ng-container>
        <ng-container [ngbNavItem] = "2">
          <a ngbNavLink>Application Form</a>
          <ng-template ngbNavContent>
           
            <!--<div class="row mb-3">
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Request Type: <span class="mandatoryCls">*</span> </label>
                <select class="selectCls" formControlName="requestType" required [ngClass]="{ 'is-invalid':submitted && (f.requestType.errors|| f.requestType.value === 'Select')}"> 
                    <option [ngValue]="null" disabled>Select</option>
                    <option value="Tatkal">Tatkal</option>
                    <option value="Ordinary">Ordinary</option>
                    
                  </select>
                <div *ngIf="submitted && f.requestType.errors" class="selectError">
                  <div *ngIf="f.requestType.errors?.required">Please select request type</div>
              </div>
              </div>
            </div> -->
            <div class="subHeadingCls mb-3">Marriage Details</div>
            <div class="row mb-3">
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Date of Marriage: <span class="mandatoryCls">*</span></label>
                <div class="input-group">
                    <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                           name="d"  ngbDatepicker #d="ngbDatepicker" [minDate]="minDate"
                           [maxDate]="maxDate" required formControlName="dateOfMarriage"
                           [ngClass]="{ 'is-invalid':submitted && f.dateOfMarriage.errors }">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                  </div>
                  <div *ngIf="submitted && f.dateOfMarriage.errors" class="selectError">
                    <div *ngIf="f.dateOfMarriage.errors?.required || f.dateOfMarriage.invalid">Please enter valid Date of Marriage</div>
                </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Address of Marriage: <span class="mandatoryCls">*</span> </label>
                <input type="text" name="venueOfMarriage" formControlName="venueOfMarriage" class="inputCls" appBlockCopyPaste required maxlength="100"
                  [ngClass]="{ 'is-invalid':submitted && f.venueOfMarriage.errors }">
                  <div *ngIf="submitted && f.venueOfMarriage.errors" class="invalid-feedback">
                    <div *ngIf="f.venueOfMarriage.errors.required"> Please enter address of marriage</div>
                  </div>  
              </div>
            
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Marriage Booklet Number: <span class="mandatoryCls">*</span> </label>
                <input type="text" name="marriageBookletNumber" numbersOnly formControlName="marriageBookletNumber" maxlength="10" 
                 class="inputCls" appBlockCopyPaste required
                  [ngClass]="{ 'is-invalid':submitted && f.marriageBookletNumber.errors }">
                  <div *ngIf="submitted && f.marriageBookletNumber.errors" class="invalid-feedback">
                    <div *ngIf="f.marriageBookletNumber.errors.required"> Please enter Marriage Booklet Number</div>
                  </div>  
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Maher Amount: <span class="mandatoryCls">*</span> </label>
                <input type="text" name="maherAmount" formControlName="maherAmount" class="inputCls" appBlockCopyPaste required maxlength="30" 
                  [ngClass]="{ 'is-invalid':submitted && f.maherAmount.errors }">
                  <div *ngIf="submitted && f.maherAmount.errors" class="invalid-feedback">
                    <div *ngIf="f.maherAmount.errors.required"> Please enter Maher Amount</div>
                  </div>  
              </div>
            </div>
            
           <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Qazi District : <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName="district" (change)="onDistrictChange()"
                    [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
                    <option  [ngValue]= "null" disabled>Select</option>
                      <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
                    </select>
                    <div *ngIf="submitted && (f.district.errors || f.district.value === 'Select')" class="selectError">
                      <div *ngIf="f.district.errors?.required || f.district.value === 'Select'">Please select District</div>
                  </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Qazi Mandal: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName="mandal" (change)="onMandalChange()"
                    [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
                    <option [ngValue]= "null" disabled>Select</option>
                    <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
                  </select>
                  <div *ngIf="submitted && (f.mandal.errors || f.mandal.value === 'Select')" class="selectError">
                    <div *ngIf="f.mandal.errors?.required || f.mandal.value === 'Select'">Please select Mandal/Municipality</div>
                  </div>
                  </div>
                  <!-- <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Qazi Village: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName="village" 
                      [ngClass]="{ 'is-invalid':submitted && (f.village.errors || f.village.value === 'Select') }">
                      <option [ngValue]= "null" disabled>Select</option>
                      <option *ngFor="let village of villages" [ngValue]="village">{{village.name}}</option>
                    </select>
                    <div *ngIf="submitted && (f.village.errors || f.village.value === 'Select')" class="selectError">
                      <div *ngIf="f.village.errors?.required || f.village.value === 'Select'">Please select Village/Ward/Secretariat</div>
                  </div>
                  </div> -->
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Qazzat: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName="quazzat"
                    [ngClass]="{ 'is-invalid':submitted && (f.quazzat.errors || f.quazzat.value === 'Select') }">
                    <option [ngValue]= "null" disabled>Select</option>
                    <option *ngFor="let quazzat of quazzats" [ngValue]="quazzat">{{quazzat.qaziName}}</option>
                  </select>
                  <div *ngIf="submitted && (f.quazzat.errors || f.quazzat.value === 'Select')" class="selectError">
                    <div *ngIf="f.quazzat.errors?.required || f.quazzat.value === 'Select'">Please select Qazzat</div>
                  </div>
                  </div>
            </div>
            <div class="row mb-3">
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Number of Certificates: <span class="mandatoryCls">*</span></label>
                <select class="selectCls" formControlName="noOfCertificates"  required [ngClass]="{ 'is-invalid':submitted && (f.noOfCertificates.errors || f.noOfCertificates.value === 'Select')}"> 
                    <option [ngValue]="null" disabled>Select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>
                <div *ngIf="submitted && (f.noOfCertificates.errors || f.noOfCertificates.value === 'Select')" class="selectError">
                  <div *ngIf="f.noOfCertificates.errors?.required || f.noOfCertificates.value === 'Select'">Please select No Of Certificates</div>
               </div>
              </div>
            </div>
            <!--<ng-container *ngIf="isCheckUrbanFlag"> -->
            <div class="subHeadingCls mb-3">Bride Details</div>
             <!--<div class="row mb-3">
              <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <label class="labelCls">Authentication Type:<span class="mandatoryCls">*</span></label>
                  <div class="col-sm-9" required 
                      [ngClass]="{ 'is-invalid':submitted && f.brideAuthType.errors }">
                      <input type="radio" name="brideAuthType" value="bOTP"  (change)="otpChange($event, 'bride')"
                          formControlName="brideAuthType"><span class="mx-2 radioCls">OTP(One Time
                          Password)</span>
                      <input type="radio" name="brideAuthType" value="bBiometric"  (change)="otpChange($event, 'bride')" [attr.disabled]="true"
                          formControlName="brideAuthType"><span class="mx-2 radioCls">Biometric</span>
                  </div>
                  <div *ngIf="submitted && f.brideAuthType.errors"
                      class="invalid-feedback col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div *ngIf="f.brideAuthType.errors.required">Please select either OTP / Biometric
                      </div>
                  </div>
              </div>
          </div> -->
          <!-- <div *ngIf="f.brideAuthType.value == 'bOTP'"> -->
            <div class="row mb-3" >
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls"> Bride Aadhaar Number: <span class="mandatoryCls">*</span></label>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <input type="text" appBlockCopyPaste name="brideAadharNo" formControlName="brideAadharNo" (keyup)="onBrideAadharChange()"  class="inputCls width64" required numbersOnly
                  [ngClass]="{ 'is-invalid':brideAuthSubmitted && f.brideAadharNo.errors }" maxlength="12" minlength="12"
                  >
                  <!--<button class="btn btn-primary float-right" [disabled]="isBride" (click)="generateOTP('bride')">Send OTP</button> -->
                  <div *ngIf="brideAuthSubmitted && f.brideAadharNo.errors" class="invalid-feedback">
                      <div *ngIf="f.brideAadharNo.errors?.required || f.brideAadharNo.errors?.whiteSpaceValidate">Bride Aadhaar Number is required</div>
                      <div *ngIf="f.brideAadharNo.errors?.aadharValidate || f.brideAadharNo.errors?.minlength || f.brideAadharNo.errors?.maxlength">Please enter 12 digit Valid Aadhaar Number</div>
                  </div>
              </div>
              
          
           <div class="btnHolder">
              <button class="greenBtnCls float-right" (click)="getBrideDetails()" >Get Details</button>
           </div>
          </div>
          <!--</div> -->
          
          <!--<ng-container *ngIf="isBrideAuthenticated"> -->
            <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Name: <span class="mandatoryCls">*</span></label>
                  <input type="text" name="brideName" formControlName="brideName" class="inputCls" appBlockCopyPaste required maxlength="50"
                  [ngClass]="{ 'is-invalid':submitted && f.brideName.errors }">
                  <div *ngIf="submitted && f.brideName.errors" class="invalid-feedback">
                    <div *ngIf="f.brideName.errors.required || f.brideName.errors.whiteSpaceValidate"> Name is required</div>
                    <div *ngIf="f.brideName.errors?.pattern">Please enter valid  Name</div>
                </div>
                </div>
                
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Date of Birth:</label>
                  <div class="input-group">
                    <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                           name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate"
                           (change)="onBrideDobChange()" (dateSelect)="onBrideDobSelect()" [maxDate]="maxDate" formControlName="brideDob" 
                           >
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                    </div>
                  </div>
                  <!--<div *ngIf="submitted && f.brideDob.errors" class="selectError">
                    <div *ngIf="f.brideDob.errors?.required || f.brideDob.invalid">Please enter valid Date of Birth</div>
                </div> -->
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Nationality: <span class="mandatoryCls">*</span></label>
                  <select class="selectCls" required formControlName="brideNationality" 
                    [ngClass]="{ 'is-invalid':submitted && (f.brideNationality.errors || f.brideNationality.value === 'Select') }">
                    <option [ngValue]= "null" disabled>Select</option>
                    <option *ngFor="let nationality of nationalities" [ngValue]="nationality">{{nationality.name}}</option>
                  </select>
                  <div *ngIf="submitted && (f.brideNationality.errors || f.brideNationality.value === 'Select')" class="selectError">
                    <div *ngIf="f.brideNationality.errors?.required || f.brideNationality.value === 'Select'">Please select Nationality</div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Age: <span class="mandatoryCls">*</span></label>
                  <input type="text" name="brideAge" formControlName="brideAge" class="inputCls" appBlockCopyPaste required numbersOnly
                  [ngClass]="{ 'is-invalid':submitted && f.brideAge.errors }">
                  <div *ngIf="submitted && f.brideAge.errors" class="invalid-feedback">
                    <div *ngIf="f.brideAge.errors.required ">Age is required</div>
                </div>
                </div>
                </div>

                <div class="row mb-3"> 
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Father's Name: <span class="mandatoryCls">*</span></label>
                  <input type="text" name="brideFatherName" formControlName="brideFatherName" class="inputCls" appBlockCopyPaste required maxlength="75"
                  [ngClass]="{ 'is-invalid':submitted && f.brideFatherName.errors }">
                  <div *ngIf="submitted && f.brideFatherName.errors" class="invalid-feedback">
                    <div *ngIf="f.brideFatherName.errors.required || f.brideFatherName.errors.whiteSpaceValidate">Please enter Father's Name</div>
                    <div *ngIf="f.brideFatherName.errors?.pattern">Please enter valid Father's Name</div>
                </div>
                </div>
                
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Mother's Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="brideMotherName" formControlName="brideMotherName" class="inputCls" appBlockCopyPaste required maxlength="75"
                    [ngClass]="{ 'is-invalid':submitted && f.brideMotherName.errors }">
                    <div *ngIf="submitted && f.brideMotherName.errors" class="invalid-feedback">
                      <div *ngIf="f.brideMotherName.errors.required || f.brideMotherName.errors.whiteSpaceValidate">Please enter Mother's Name</div>
                      <div *ngIf="f.brideMotherName.errors?.pattern">Please enter valid Mother's Name</div>
                  </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Address: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="brideAddress" formControlName="brideAddress" class="inputCls" appBlockCopyPaste required maxlength="300"
                    [ngClass]="{ 'is-invalid':submitted && f.brideAddress.errors }">
                    <div *ngIf="submitted && f.brideAddress.errors" class="invalid-feedback">
                      <div *ngIf="f.brideAddress.errors.required || f.brideAddress.errors.whiteSpaceValidate">Please enter Address</div>
                  </div>
                  </div>
                
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Occupation : <span class="mandatoryCls">*</span></label>
                  <select class="selectCls" placeholder = "Select" formControlName="brideOccupation" required [ngClass]="{ 'is-invalid':submitted && (f.brideOccupation.errors || f.brideOccupation.value === 'Select') }"> 
                    <option [ngValue]="null" disabled>Select</option>
                    <option *ngFor="let occupation of occupations" [ngValue]="occupation">{{occupation.name}}</option>
                  </select>
                <div *ngIf="submitted && (f.brideOccupation.errors || f.brideOccupation.value === 'Select')" class="selectError">
                  <div *ngIf="f.brideOccupation.errors?.required || f.brideOccupation.value === 'Select'">Please select Occupation</div>
                </div>
                </div>
                </div>
                <div class="row mb-3"> 
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Gender: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" placeholder = "Select" formControlName="brideGender" (change)="onGenderChange('bride')" required [ngClass]="{ 'is-invalid':submitted && (f.brideGender.errors || f.brideGender.value === 'Select') }"> 
                        <option [ngValue]="null" disabled>Select</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                    </select>
                  <div *ngIf="submitted && (f.brideGender.errors || f.brideGender.value === 'Select')" class="selectError">
                    <div *ngIf="f.brideGender.errors?.required || f.brideGender.value === 'Select'">Please select Gender</div>
                  </div>
                  </div>
                </div>
          <!-- </ng-container> -->
          <div class="subHeadingCls mb-3">Bridegroom Details</div>
          <!-- <div class="row mb-3">
            <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <label class="labelCls">Authentication Type:<span class="mandatoryCls">*</span></label>
                <div class="col-sm-9" required 
                    [ngClass]="{ 'is-invalid':groomAuthSubmitted && f.groomAuthType.errors }">
                    <input type="radio" name="groomAuthType" value="gOTP"  (change)="otpChange($event,'groom')"
                        formControlName="groomAuthType"><span class="mx-2 radioCls">OTP(One Time
                        Password)</span>
                    <input type="radio" name="groomAuthType" value="gBiometric"  (change)="otpChange($event,'groom')" [attr.disabled]="true"
                        formControlName="groomAuthType"><span class="mx-2 radioCls">Biometric</span>
                </div>
                <div *ngIf="groomAuthSubmitted && f.groomAuthType.errors"
                    class="invalid-feedback col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div *ngIf="f.groomAuthType.errors.required">Please select either OTP / Biometric
                    </div>
                </div>
            </div>
        </div> -->
        <!-- <div *ngIf="f.groomAuthType.value == 'gOTP'"> -->
          <div class="row mb-3" >
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Bridegroom Aadhaar Number: <span class="mandatoryCls">*</span></label>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <input type="text" appBlockCopyPaste name="groomAadharNo" formControlName="groomAadharNo" (keyup)="onGroomAadharChange()"  class="inputCls width64" required numbersOnly
                [ngClass]="{ 'is-invalid':groomAuthSubmitted && f.groomAadharNo.errors }" maxlength="12" minlength="12"
                >
                <!--<button class="btn btn-primary float-right" [disabled]="isGroom" (click)="generateOTP('groom')">Send OTP</button> -->
                <div *ngIf="groomAuthSubmitted && f.groomAadharNo.errors" class="invalid-feedback">
                    <div *ngIf="f.groomAadharNo.errors?.required || f.groomAadharNo.errors?.whiteSpaceValidate">Bridegroom Aadhaar Number is required</div>
                    <div *ngIf="f.groomAadharNo.errors?.aadharValidate || f.groomAadharNo.errors?.minlength || f.groomAadharNo.errors?.maxlength">Please enter 12 digit Valid Aadhaar Number</div>
                </div>
            </div>
            
        
        <div class="btnHolder">
            <button class="greenBtnCls float-right" (click)="getGroomDetails()" >Get Details</button>
        </div>
      </div>
        <!-- </div> -->
        
        <!-- <ng-container *ngIf="isGroomAuthenticated"> -->
          <div class="row mb-3">
            
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Name: <span class="mandatoryCls">*</span></label>
                <input type="text" name="groomName" formControlName="groomName" class="inputCls" appBlockCopyPaste required maxlength="50"
                [ngClass]="{ 'is-invalid':submitted && f.groomName.errors }">
                <div *ngIf="submitted && f.groomName.errors" class="invalid-feedback">
                  <div *ngIf="f.groomName.errors.required || f.groomName.errors.whiteSpaceValidate"> Name is required</div>
                  <div *ngIf="f.groomName.errors?.pattern">Please enter valid  Name</div>
              </div>
              </div>
              
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Date of Birth:</label>
                <div class="input-group">
                  <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                         name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="minDate"
                         (change)="onGroomDobChange()" (dateSelect)="onGroomDobSelect()" [maxDate]="maxDate" formControlName="groomDob" 
                         >
                  <div class="input-group-append" >
                    <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
                  </div>
                </div>
                <!--<div *ngIf="submitted && f.groomDob.errors" class="selectError">
                  <div *ngIf="f.groomDob.errors?.required || f.groomDob.invalid">Please enter valid Date of Birth</div>
              </div> -->
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Nationality: <span class="mandatoryCls">*</span></label>
                <select class="selectCls" required formControlName="groomNationality" 
                  [ngClass]="{ 'is-invalid':submitted && (f.groomNationality.errors || f.groomNationality.value === 'Select') }">
                  <option [ngValue]= "null" disabled>Select</option>
                  <option *ngFor="let nationality of nationalities" [ngValue]="nationality">{{nationality.name}}</option>
                </select>
                <div *ngIf="submitted && (f.groomNationality.errors || f.groomNationality.value === 'Select')" class="selectError">
                  <div *ngIf="f.groomNationality.errors?.required || f.groomNationality.value === 'Select'">Please select Nationality</div>
              </div>
              </div> 
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Age: <span class="mandatoryCls">*</span></label>
                <input type="text" name="groomAge" formControlName="groomAge" class="inputCls" appBlockCopyPaste required numbersOnly
                [ngClass]="{ 'is-invalid':submitted && f.groomAge.errors }" >
                <div *ngIf="submitted && f.groomAge.errors" class="invalid-feedback">
                  <div *ngIf="f.groomAge.errors.required ">Age is required</div>
              </div>
              </div>
              </div>
 
              <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Father's Name: <span class="mandatoryCls">*</span></label>
                  <input type="text" name="groomFatherName" formControlName="groomFatherName" class="inputCls" appBlockCopyPaste required maxlength="75"
                  [ngClass]="{ 'is-invalid':submitted && f.groomFatherName.errors }">
                  <div *ngIf="submitted && f.groomFatherName.errors" class="invalid-feedback">
                    <div *ngIf="f.groomFatherName.errors.required || f.groomFatherName.errors.whiteSpaceValidate">Please enter Father's Name</div>
                    <div *ngIf="f.groomFatherName.errors?.pattern">Please enter valid Father's Name</div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Mother's Name: <span class="mandatoryCls">*</span></label>
                  <input type="text" name="groomMotherName" formControlName="groomMotherName" class="inputCls" appBlockCopyPaste required maxlength="75"
                  [ngClass]="{ 'is-invalid':submitted && f.groomMotherName.errors }">
                  <div *ngIf="submitted && f.groomMotherName.errors" class="invalid-feedback">
                    <div *ngIf="f.groomMotherName.errors.required || f.groomMotherName.errors.whiteSpaceValidate">Please enter Mother's Name</div>
                    <div *ngIf="f.groomMotherName.errors?.pattern">Please enter valid Mother's Name</div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Address: <span class="mandatoryCls">*</span></label>
                  <input type="text" name="groomAddress" formControlName="groomAddress" class="inputCls" appBlockCopyPaste required maxlength="300"
                  [ngClass]="{ 'is-invalid':submitted && f.groomAddress.errors }">
                  <div *ngIf="submitted && f.groomAddress.errors" class="invalid-feedback">
                    <div *ngIf="f.groomAddress.errors.required || f.groomAddress.errors.whiteSpaceValidate">Please enter Address</div>
                </div>
                </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Occupation : <span class="mandatoryCls">*</span></label>
                <select class="selectCls" placeholder = "Select" formControlName="groomOccupation" required [ngClass]="{ 'is-invalid':submitted && (f.groomOccupation.errors || f.groomOccupation.value === 'Select') }"> 
                  <option [ngValue]="null" disabled>Select</option>
                  <option *ngFor="let occupation of occupations" [ngValue]="occupation">{{occupation.name}}</option>
                </select>
              <div *ngIf="submitted && (f.groomOccupation.errors || f.groomOccupation.value === 'Select')" class="selectError">
                <div *ngIf="f.groomOccupation.errors?.required || f.groomOccupation.value === 'Select'">Please select Occupation</div>
              </div>
              </div>
              </div>
              <div class="row mb-3"> 
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Gender: <span class="mandatoryCls">*</span></label>
                  <select class="selectCls" placeholder = "Select" formControlName="groomGender" (change)="onGenderChange('groom')" required [ngClass]="{ 'is-invalid':submitted && (f.groomGender.errors || f.groomGender.value === 'Select') }"> 
                      <option [ngValue]="null" disabled>Select</option>
                      <option value="Female">Female</option>
                      <option value="Male">Male</option>
                  </select>
                <div *ngIf="submitted && (f.groomGender.errors || f.groomGender.value === 'Select')" class="selectError">
                  <div *ngIf="f.groomGender.errors?.required || f.groomGender.value === 'Select'">Please select Gender</div>
                </div>
                </div>
              </div>
              <!--<div class="row mb-3">
              
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Handicapped: <span class="mandatoryCls">*</span></label>
                <input type="radio"  value="Yes" name="groomHandicap" formControlName="groomHandicap" required>
                        <span class="mx-2 radioCls">Yes</span>
                        <input type="radio"  value="No" name="groomHandicap" formControlName="groomHandicap" required>
                       <span class="mx-2 radioCls">No</span>
              <div *ngIf="submitted && (f.groomHandicap.errors)" class="selectError">
                <div *ngIf="f.groomHandicap.errors?.required">Please select the option</div>
              </div>
              </div>
              </div> -->
            <!-- </ng-container> -->
              <!-- <div class="subHeadingCls mb-3">Witness 1 Details for Bride</div>
              <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Aadhaar Number: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="bWOneAadharNo" formControlName="bWOneAadharNo" class="inputCls width75" appBlockCopyPaste required numbersOnly
                    [ngClass]="{ 'is-invalid':submitted && f.bWOneAadharNo.errors }" [readonly] ="isBrideWitnessOne" maxlength="12" minlength="12">
                    <button class="btn btn-primary float-right" (mousedown)="getCitizenInfo('bWOneAadharNo', 'BW1')">Pre-Fill</button>
                    <div *ngIf="submitted && f.bWOneAadharNo.errors" class="invalid-feedback">
                      <div *ngIf="f.bWOneAadharNo.errors.required || f.bWOneAadharNo.errors.whiteSpaceValidate">Aadhaar Number is required</div>
                      <div *ngIf="f.bWOneAadharNo.errors.aadharValidate || f.bWOneAadharNo.errors.minlength || f.bWOneAadharNo.errors.maxlength">Please enter valid Aadhaar Number</div>
                  </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="bWOneName" formControlName="bWOneName" class="inputCls" appBlockCopyPaste required maxlength="50"
                    [ngClass]="{ 'is-invalid':submitted && f.bWOneName.errors }">
                    <div *ngIf="submitted && f.bWOneName.errors" class="invalid-feedback">
                      <div *ngIf="f.bWOneName.errors.required || f.bWOneName.errors.whiteSpaceValidate"> Name is required</div>
                      <div *ngIf="f.bWOneName.errors?.pattern">Please enter valid Name</div>
                  </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Father/Husband Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="bWOneFatherHusName" formControlName="bWOneFatherHusName" class="inputCls" appBlockCopyPaste required maxlength="75"
                    [ngClass]="{ 'is-invalid':submitted && f.bWOneFatherHusName.errors }">
                    <div *ngIf="submitted && f.bWOneFatherHusName.errors" class="invalid-feedback">
                      <div *ngIf="f.bWOneFatherHusName.errors.required || f.bWOneFatherHusName.errors.whiteSpaceValidate">Please enter Father/Husband Name</div>
                      <div *ngIf="f.bWOneFatherHusName.errors?.pattern">Please enter valid Father/Husband Name</div>
                  </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Address: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="bWOneAddress" formControlName="bWOneAddress" class="inputCls" appBlockCopyPaste required maxlength="300"
                    [ngClass]="{ 'is-invalid':submitted && f.bWOneAddress.errors }">
                    <div *ngIf="submitted && f.bWOneAddress.errors" class="invalid-feedback">
                      <div *ngIf="f.bWOneAddress.errors.required || f.bWOneAddress.errors.whiteSpaceValidate">Please enter Address</div>
                  </div>
                  </div>
                
            </div>
            <div class="row mb-3">
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Age: <span class="mandatoryCls">*</span></label>
                <input type="text" name="bWOneAge" formControlName="bWOneAge" class="inputCls" appBlockCopyPaste required numbersOnly
                [ngClass]="{ 'is-invalid':submitted && f.bWOneAge.errors }">
                <div *ngIf="submitted && f.bWOneAge.errors" class="invalid-feedback">
                  <div *ngIf="f.bWOneAge.errors.required ">Age is required</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Occupation : <span class="mandatoryCls">*</span></label>
                <select class="selectCls" placeholder = "Select" formControlName="bWOneOccupation" required 
                [ngClass]="{ 'is-invalid':submitted && (f.bWOneOccupation.errors || f.bWOneOccupation.value === 'Select') }"> 
                  <option [ngValue]="null" disabled>Select</option>
                  <option *ngFor="let occupation of occupations" [ngValue]="occupation">{{occupation.name}}</option>
                </select>
              <div *ngIf="submitted && (f.bWOneOccupation.errors || f.bWOneOccupation.value === 'Select')" class="selectError">
                <div *ngIf="f.bWOneOccupation.errors?.required || f.bWOneOccupation.value === 'Select'">Please select Occupation</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Relation : <span class="mandatoryCls">*</span></label>
                <select class="selectCls" placeholder = "Select" formControlName="bWOneRelation" required 
                [ngClass]="{ 'is-invalid':submitted && (f.bWOneRelation.errors || f.bWOneRelation.value === 'Select') }"> 
                  <option [ngValue]="null" disabled>Select</option>
                  <option *ngFor="let relation of relations" [ngValue]="relation">{{relation.name}}</option>
                </select>
              <div *ngIf="submitted && (f.bWOneRelation.errors || f.bWOneRelation.value === 'Select')" class="selectError">
                <div *ngIf="f.bWOneRelation.errors?.required || f.bWOneRelation.value === 'Select'">Please select Relation</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div class="checkBoxCont">
                     
                    <label class="labelCls" for="bWOnePhoto">Passport Size photo
                        (Upload Only jpg/jpeg format)<span class="mandatoryCls">*</span></label>
                </div>
                <div class="input-group mb-3">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" required
                            formControlName="bWOnePhoto" id="bWOnePhoto" accept=".jpg,.jpeg"
                            [ngClass]="{ 'is-invalid':submitted && f.bWOnePhoto.errors }"
                            (change)="onPhotoSelected($event, 'bWOnePhoto', 'bWOnePhotoLabel')">
                        <label class="custom-file-label" #bWOnePhotoLabel
                            for="bWOnePhoto"><i class="fas fa-search"></i>No File
                            choosen</label>
                    </div>
                    <div class="clearCls" (click)="clearFileUpload('bWOnePhoto', 'bWOnePhotoLabel')"
                        *ngIf="f.bWOnePhoto.value"></div>
                    </div>
                <div *ngIf="submitted && f.bWOnePhoto.errors" class="selectError">
                    <div *ngIf="f.bWOnePhoto.errors.required">Please upload Passport
                        Size photo (Upload Only jpg/jpeg format)</div>
                    <div *ngIf="f.bWOnePhoto.errors?.inValidExt && !f.bWOnePhoto.errors.required ">
                        Please upload the file in JPG/JPEG Format</div>
                    <div *ngIf="f.bWOnePhoto.errors?.inValidSize && !f.bWOnePhoto.errors.required && !f.bWOnePhoto.errors.inValidExt">
                        File size exceeding 1MB</div>
                    </div>
                </div>
            </div>
            <div class="subHeadingCls mb-3">Witness 2 Details for Bride</div>
              <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Aadhaar Number: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="bWTwoAadharNo" formControlName="bWTwoAadharNo" class="inputCls width75" appBlockCopyPaste required numbersOnly
                    [ngClass]="{ 'is-invalid':submitted && f.bWTwoAadharNo.errors }" [readonly] ="isBrideWitnessTwo" maxlength="12" minlength="12">
                    <button class="btn btn-primary float-right" (mousedown)="getCitizenInfo('bWTwoAadharNo', 'BW2')">Pre-Fill</button>
                    <div *ngIf="submitted && f.bWTwoAadharNo.errors" class="invalid-feedback">
                      <div *ngIf="f.bWTwoAadharNo.errors.required || f.bWTwoAadharNo.errors.whiteSpaceValidate">Aadhaar Number is required</div>
                      <div *ngIf="f.bWTwoAadharNo.errors.aadharValidate || f.bWTwoAadharNo.errors.minlength || f.bWTwoAadharNo.errors.maxlength">Please enter valid Aadhaar Number</div>
                  </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="bWTwoName" formControlName="bWTwoName" class="inputCls" appBlockCopyPaste required maxlength="50"
                    [ngClass]="{ 'is-invalid':submitted && f.bWTwoName.errors }">
                    <div *ngIf="submitted && f.bWTwoName.errors" class="invalid-feedback">
                      <div *ngIf="f.bWTwoName.errors.required || f.bWTwoName.errors.whiteSpaceValidate"> Name is required</div>
                      <div *ngIf="f.bWTwoName.errors?.pattern">Please enter valid Name</div>
                  </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Father/Husband Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="bWTwoFatherHusName" formControlName="bWTwoFatherHusName" class="inputCls" appBlockCopyPaste required maxlength="75"
                    [ngClass]="{ 'is-invalid':submitted && f.bWTwoFatherHusName.errors }">
                    <div *ngIf="submitted && f.bWTwoFatherHusName.errors" class="invalid-feedback">
                      <div *ngIf="f.bWTwoFatherHusName.errors.required || f.bWTwoFatherHusName.errors.whiteSpaceValidate">Please enter Father/Husband Name</div>
                      <div *ngIf="f.bWTwoFatherHusName.errors?.pattern">Please enter valid Father/Husband Name</div>
                  </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Address: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="bWTwoAddress" formControlName="bWTwoAddress" class="inputCls" appBlockCopyPaste required maxlength="300"
                    [ngClass]="{ 'is-invalid':submitted && f.bWTwoAddress.errors }">
                    <div *ngIf="submitted && f.bWTwoAddress.errors" class="invalid-feedback">
                      <div *ngIf="f.bWTwoAddress.errors.required || f.bWTwoAddress.errors.whiteSpaceValidate">Please enter Address</div>
                  </div>
                  </div>
                
            </div>
            <div class="row mb-3">
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Age: <span class="mandatoryCls">*</span></label>
                <input type="text" name="bWTwoAge" formControlName="bWTwoAge" class="inputCls" appBlockCopyPaste required numbersOnly
                [ngClass]="{ 'is-invalid':submitted && f.bWTwoAge.errors }">
                <div *ngIf="submitted && f.bWTwoAge.errors" class="invalid-feedback">
                  <div *ngIf="f.bWTwoAge.errors.required ">Age is required</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Occupation : <span class="mandatoryCls">*</span></label>
                <select class="selectCls" placeholder = "Select" formControlName="bWTwoOccupation" required 
                [ngClass]="{ 'is-invalid':submitted && (f.bWTwoOccupation.errors || f.bWTwoOccupation.value === 'Select') }"> 
                  <option [ngValue]="null" disabled>Select</option>
                  <option *ngFor="let occupation of occupations" [ngValue]="occupation">{{occupation.name}}</option>
                </select>
              <div *ngIf="submitted && (f.bWTwoOccupation.errors || f.bWTwoOccupation.value === 'Select')" class="selectError">
                <div *ngIf="f.bWTwoOccupation.errors?.required || f.bWTwoOccupation.value === 'Select'">Please select Occupation</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Relation : <span class="mandatoryCls">*</span></label>
                <select class="selectCls" placeholder = "Select" formControlName="bWTwoRelation" required 
                [ngClass]="{ 'is-invalid':submitted && (f.bWTwoRelation.errors || f.bWTwoRelation.value === 'Select') }"> 
                  <option [ngValue]="null" disabled>Select</option>
                  <option *ngFor="let relation of relations" [ngValue]="relation">{{relation.name}}</option>
                </select>
              <div *ngIf="submitted && (f.bWTwoRelation.errors || f.bWTwoRelation.value === 'Select')" class="selectError">
                <div *ngIf="f.bWTwoRelation.errors?.required || f.bWTwoRelation.value === 'Select'">Please select Relation</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div class="checkBoxCont">
                   
                    <label class="labelCls" for="bWTwoPhoto">Passport Size photo
                        (Upload Only jpg/jpeg format)<span class="mandatoryCls">*</span></label>
                </div>
                <div class="input-group mb-3">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" required
                            formControlName="bWTwoPhoto" id="bWTwoPhoto" accept=".jpg,.jpeg"
                            [ngClass]="{ 'is-invalid':submitted && f.bWTwoPhoto.errors }"
                            (change)="onPhotoSelected($event, 'bWTwoPhoto', 'bWTwoPhotoLabel')">
                        <label class="custom-file-label" #bWTwoPhotoLabel
                            for="bWTwoPhoto"><i class="fas fa-search"></i>No File
                            choosen</label>
                    </div>
                    <div class="clearCls" (click)="clearFileUpload('bWTwoPhoto', 'bWTwoPhotoLabel')"
                        *ngIf="f.bWTwoPhoto.value"></div>
                    </div>
                <div *ngIf="submitted && f.bWTwoPhoto.errors" class="selectError">
                    <div *ngIf="f.bWTwoPhoto.errors.required">Please upload Passport
                        Size photo (Upload Only jpg/jpeg format)</div>
                    <div *ngIf="f.bWTwoPhoto.errors?.inValidExt && !f.bWTwoPhoto.errors.required ">
                        Please upload the file in JPG/JPEG Format</div>
                    <div *ngIf="f.bWTwoPhoto.errors?.inValidSize && !f.bWTwoPhoto.errors.required && !f.bWTwoPhoto.errors.inValidExt">
                        File size exceeding 1MB</div>
                    </div>
                </div>
            </div>
            <div class="subHeadingCls mb-3">Witness 1 Details for Bridegroom</div>
              <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Aadhaar Number: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="gWOneAadharNo" formControlName="gWOneAadharNo" class="inputCls width75" appBlockCopyPaste required numbersOnly
                    [ngClass]="{ 'is-invalid':submitted && f.gWOneAadharNo.errors }" [readonly] ="isGroomWitnessOne" maxlength="12" minlength="12">
                    <button class="btn btn-primary float-right" (mousedown)="getCitizenInfo('gWOneAadharNo', 'GW1')">Pre-Fill</button>
                    <div *ngIf="submitted && f.gWOneAadharNo.errors" class="invalid-feedback">
                      <div *ngIf="f.gWOneAadharNo.errors.required || f.gWOneAadharNo.errors.whiteSpaceValidate">Aadhaar Number is required</div>
                      <div *ngIf="f.gWOneAadharNo.errors.aadharValidate || f.gWOneAadharNo.errors.minlength || f.gWOneAadharNo.errors.maxlength">Please enter valid Aadhaar Number</div>
                  </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="gWOneName" formControlName="gWOneName" class="inputCls" appBlockCopyPaste required maxlength="50"
                    [ngClass]="{ 'is-invalid':submitted && f.gWOneName.errors }">
                    <div *ngIf="submitted && f.gWOneName.errors" class="invalid-feedback">
                      <div *ngIf="f.gWOneName.errors.required || f.gWOneName.errors.whiteSpaceValidate"> Name is required</div>
                      <div *ngIf="f.gWOneName.errors?.pattern">Please enter valid Name</div>
                  </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Father/Husband Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="gWOneFatherHusName" formControlName="gWOneFatherHusName" class="inputCls" appBlockCopyPaste required maxlength="75"
                    [ngClass]="{ 'is-invalid':submitted && f.gWOneFatherHusName.errors }">
                    <div *ngIf="submitted && f.gWOneFatherHusName.errors" class="invalid-feedback">
                      <div *ngIf="f.gWOneFatherHusName.errors.required || f.gWOneFatherHusName.errors.whiteSpaceValidate">Please enter Father/Husband Name</div>
                      <div *ngIf="f.gWOneFatherHusName.errors?.pattern">Please enter valid Father/Husband Name</div>
                  </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Address: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="gWOneAddress" formControlName="gWOneAddress" class="inputCls" appBlockCopyPaste required maxlength="300"
                    [ngClass]="{ 'is-invalid':submitted && f.gWOneAddress.errors }">
                    <div *ngIf="submitted && f.gWOneAddress.errors" class="invalid-feedback">
                      <div *ngIf="f.gWOneAddress.errors.required || f.gWOneAddress.errors.whiteSpaceValidate">Please enter Address</div>
                  </div>
                  </div>
                
            </div>
            <div class="row mb-3">
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Age: <span class="mandatoryCls">*</span></label>
                <input type="text" name="gWOneAge" formControlName="gWOneAge" class="inputCls" appBlockCopyPaste required numbersOnly
                [ngClass]="{ 'is-invalid':submitted && f.gWOneAge.errors }">
                <div *ngIf="submitted && f.gWOneAge.errors" class="invalid-feedback">
                  <div *ngIf="f.gWOneAge.errors.required ">Age is required</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Occupation : <span class="mandatoryCls">*</span></label>
                <select class="selectCls" placeholder = "Select" formControlName="gWOneOccupation" required 
                [ngClass]="{ 'is-invalid':submitted && (f.gWOneOccupation.errors || f.gWOneOccupation.value === 'Select') }"> 
                  <option [ngValue]="null" disabled>Select</option>
                  <option *ngFor="let occupation of occupations" [ngValue]="occupation">{{occupation.name}}</option>
                </select>
              <div *ngIf="submitted && (f.gWOneOccupation.errors || f.gWOneOccupation.value === 'Select')" class="selectError">
                <div *ngIf="f.gWOneOccupation.errors?.required || f.gWOneOccupation.value === 'Select'">Please select Occupation</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Relation : <span class="mandatoryCls">*</span></label>
                <select class="selectCls" placeholder = "Select" formControlName="gWOneRelation" required 
                [ngClass]="{ 'is-invalid':submitted && (f.gWOneRelation.errors || f.gWOneRelation.value === 'Select') }"> 
                  <option [ngValue]="null" disabled>Select</option>
                  <option *ngFor="let relation of relations" [ngValue]="relation">{{relation.name}}</option>
                </select>
              <div *ngIf="submitted && (f.gWOneRelation.errors || f.gWOneRelation.value === 'Select')" class="selectError">
                <div *ngIf="f.gWOneRelation.errors?.required || f.gWOneRelation.value === 'Select'">Please select Relation</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div class="checkBoxCont">
                   
                    <label class="labelCls" for="gWOnePhoto">Passport Size photo
                        (Upload Only jpg/jpeg format)<span class="mandatoryCls">*</span></label>
                </div>
                <div class="input-group mb-3">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" required
                            formControlName="gWOnePhoto" id="gWOnePhoto" accept=".jpg,.jpeg"
                            [ngClass]="{ 'is-invalid':submitted && f.gWOnePhoto.errors }"
                            (change)="onPhotoSelected($event, 'gWOnePhoto', 'gWOnePhotoLabel')">
                        <label class="custom-file-label" #gWOnePhotoLabel
                            for="gWOnePhoto"><i class="fas fa-search"></i>No File
                            choosen</label>
                    </div>
                    <div class="clearCls" (click)="clearFileUpload('gWOnePhoto', 'gWOnePhotoLabel')"
                        *ngIf="f.gWOnePhoto.value"></div>
                    </div>
                <div *ngIf="submitted && f.gWOnePhoto.errors" class="selectError">
                    <div *ngIf="f.gWOnePhoto.errors.required">Please upload Passport
                        Size photo (Upload Only jpg/jpeg format)</div>
                    <div *ngIf="f.gWOnePhoto.errors?.inValidExt && !f.gWOnePhoto.errors.required ">
                        Please upload the file in JPG/JPEG Format</div>
                    <div *ngIf="f.gWOnePhoto.errors?.inValidSize && !f.gWOnePhoto.errors.required && !f.gWOnePhoto.errors.inValidExt">
                        File size exceeding 1MB</div>
                    </div>
                </div>
            </div>
            <div class="subHeadingCls mb-3">Witness 2 Details for Bridegroom</div>
              <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Aadhaar Number: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="gWTwoAadharNo" formControlName="gWTwoAadharNo" class="inputCls width75" appBlockCopyPaste required numbersOnly
                    [ngClass]="{ 'is-invalid':submitted && f.gWTwoAadharNo.errors }" [readonly] ="isGroomWitnessTwo" maxlength="12" minlength="12">
                    <button class="btn btn-primary float-right" (mousedown)="getCitizenInfo('gWTwoAadharNo', 'GW2')">Pre-Fill</button>
                    <div *ngIf="submitted && f.gWTwoAadharNo.errors" class="invalid-feedback">
                      <div *ngIf="f.gWTwoAadharNo.errors.required || f.gWTwoAadharNo.errors.whiteSpaceValidate">Aadhaar Number is required</div>
                      <div *ngIf="f.gWTwoAadharNo.errors.aadharValidate || f.gWTwoAadharNo.errors.minlength || f.gWTwoAadharNo.errors.maxlength">Please enter valid Aadhaar Number</div>
                  </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="gWTwoName" formControlName="gWTwoName" class="inputCls" appBlockCopyPaste required maxlength="50"
                    [ngClass]="{ 'is-invalid':submitted && f.gWTwoName.errors }">
                    <div *ngIf="submitted && f.gWTwoName.errors" class="invalid-feedback">
                      <div *ngIf="f.gWTwoName.errors.required || f.gWTwoName.errors.whiteSpaceValidate"> Name is required</div>
                      <div *ngIf="f.gWTwoName.errors?.pattern">Please enter valid Name</div>
                  </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Father/Husband Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="gWTwoFatherHusName" formControlName="gWTwoFatherHusName" class="inputCls" appBlockCopyPaste required maxlength="75"
                    [ngClass]="{ 'is-invalid':submitted && f.gWTwoFatherHusName.errors }">
                    <div *ngIf="submitted && f.gWTwoFatherHusName.errors" class="invalid-feedback">
                      <div *ngIf="f.gWTwoFatherHusName.errors.required || f.gWTwoFatherHusName.errors.whiteSpaceValidate">Please enter Father/Husband Name</div>
                      <div *ngIf="f.gWTwoFatherHusName.errors?.pattern">Please enter valid Father/Husband Name</div>
                  </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Address: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="gWTwoAddress" formControlName="gWTwoAddress" class="inputCls" appBlockCopyPaste required maxlength="300"
                    [ngClass]="{ 'is-invalid':submitted && f.gWTwoAddress.errors }">
                    <div *ngIf="submitted && f.gWTwoAddress.errors" class="invalid-feedback">
                      <div *ngIf="f.gWTwoAddress.errors.required || f.gWTwoAddress.errors.whiteSpaceValidate">Please enter Address</div>
                  </div>
                  </div>
                
            </div>
            <div class="row mb-3">
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Age: <span class="mandatoryCls">*</span></label>
                <input type="text" name="gWTwoAge" formControlName="gWTwoAge" class="inputCls" appBlockCopyPaste required numbersOnly
                [ngClass]="{ 'is-invalid':submitted && f.gWTwoAge.errors }">
                <div *ngIf="submitted && f.gWTwoAge.errors" class="invalid-feedback">
                  <div *ngIf="f.gWTwoAge.errors.required ">Age is required</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Occupation : <span class="mandatoryCls">*</span></label>
                <select class="selectCls" placeholder = "Select" formControlName="gWTwoOccupation" required 
                [ngClass]="{ 'is-invalid':submitted && (f.gWTwoOccupation.errors || f.gWTwoOccupation.value === 'Select') }"> 
                  <option [ngValue]="null" disabled>Select</option>
                  <option *ngFor="let occupation of occupations" [ngValue]="occupation">{{occupation.name}}</option>
                </select>
              <div *ngIf="submitted && (f.gWTwoOccupation.errors || f.gWTwoOccupation.value === 'Select')" class="selectError">
                <div *ngIf="f.gWTwoOccupation.errors?.required || f.gWTwoOccupation.value === 'Select'">Please select Occupation</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Relation : <span class="mandatoryCls">*</span></label>
                <select class="selectCls" placeholder = "Select" formControlName="gWTwoRelation" required 
                [ngClass]="{ 'is-invalid':submitted && (f.gWTwoRelation.errors || f.gWTwoRelation.value === 'Select') }"> 
                  <option [ngValue]="null" disabled>Select</option>
                  <option *ngFor="let relation of relations" [ngValue]="relation">{{relation.name}}</option>
                </select>
              <div *ngIf="submitted && (f.gWTwoRelation.errors || f.gWTwoRelation.value === 'Select')" class="selectError">
                <div *ngIf="f.gWTwoRelation.errors?.required || f.gWTwoRelation.value === 'Select'">Please select Relation</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div class="checkBoxCont">
                    <label class="labelCls" for="gWTwoPhoto">Passport Size photo
                        (Upload Only jpg/jpeg format)<span class="mandatoryCls">*</span></label>
                </div>
                <div class="input-group mb-3">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" required
                            formControlName="gWTwoPhoto" id="gWTwoPhoto" accept=".jpg,.jpeg"
                            [ngClass]="{ 'is-invalid':submitted && f.gWTwoPhoto.errors }"
                            (change)="onPhotoSelected($event, 'gWTwoPhoto', 'gWTwoPhotoLabel')">
                        <label class="custom-file-label" #gWTwoPhotoLabel
                            for="gWTwoPhoto"><i class="fas fa-search"></i>No File
                            choosen</label>
                    </div>
                    <div class="clearCls" (click)="clearFileUpload('gWTwoPhoto', 'gWTwoPhotoLabel')"
                        *ngIf="f.gWTwoPhoto.value"></div>
                    </div>
                <div *ngIf="submitted && f.gWTwoPhoto.errors" class="selectError">
                    <div *ngIf="f.gWTwoPhoto.errors.required">Please upload Passport
                        Size photo (Upload Only jpg/jpeg format)</div>
                    <div *ngIf="f.gWTwoPhoto.errors?.inValidExt && !f.gWTwoPhoto.errors.required ">
                        Please upload the file in JPG/JPEG Format</div>
                    <div *ngIf="f.gWTwoPhoto.errors?.inValidSize && !f.gWTwoPhoto.errors.required && !f.gWTwoPhoto.errors.inValidExt">
                        File size exceeding 1MB</div>
                    </div>
                </div>
            </div> -->
            <hr class="hrCls mt-4">
            <div class="subHeadingCls">Document List</div>
            <div class="mb-3"><b>Note: </b>All Upload Documents are in Pdf Format Only. Maximum Upload Size Per File is 3MB.</div>
            <div class="row mb-3">
              
              <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="checkBoxCont">
                  <label class="labelCls">Marriage Booklet (Bride/Bridegroom) <span class="mandatoryCls">*</span></label>
                </div>
                <div class="input-group mb-3">
                  <div class="custom-file">
                      <input type="file" class="custom-file-input" name="marriageBookBride" required formControlName = "marriageBookBride" [ngClass]="{ 'is-invalid':submitted && f.marriageBookBride.errors }" accept=".pdf" (change) = "onmarriageBookBrideSelected($event)">
                      <label class="custom-file-label" #marriageBookBrideLabel for="marriageBookBride"><i class="fas fa-search"></i>No File choosen</label>
                  </div>
                  <div class="clearCls" (click)="clearFileUpload('marriageBookBride', 'marriageBookBrideLabel')" *ngIf="f.marriageBookBride.value"></div>
                </div>
                <div *ngIf="submitted && f.marriageBookBride.errors" class="selectError">
                  <div *ngIf="f.marriageBookBride.errors?.required">Please upload the file</div>
                  <div *ngIf="f.marriageBookBride.errors?.inValidExt">Please upload the file in PDF Format</div>
                  <div *ngIf="f.marriageBookBride.errors?.inValidSize && !f.marriageBookBride.errors.inValidExt">File size exceeding 3MB</div>  
                </div>  
              </div>
              <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="checkBoxCont">
                  <label class="labelCls">Other documents</label>
                </div>
                <div class="input-group mb-3">
                  <div class="custom-file">
                      <input type="file" class="custom-file-input" name="otherDocuments" formControlName = "otherDocuments" [ngClass]="{ 'is-invalid':submitted && f.otherDocuments.errors }" accept=".pdf" (change) = "otherDocumentsUpload($event)">
                      <label class="custom-file-label" #otherDocumentsLabel for="otherDocuments"><i class="fas fa-search"></i>No File choosen</label>
                  </div>
                  <div class="clearCls" (click)="clearFileUpload('otherDocuments', 'otherDocumentsLabel')" *ngIf="f.otherDocuments.value"></div>
                </div>
                <div *ngIf="submitted && f.otherDocuments.errors" class="selectError">
                  <!-- <div *ngIf="f.otherDocuments.errors?.required">Please upload the file</div> -->
                  <div *ngIf="f.otherDocuments.errors?.inValidExt">Please upload the file in PDF Format</div>
                  <div *ngIf="f.otherDocuments.errors?.inValidSize && !f.otherDocuments.errors.inValidExt">File size exceeding 1MB</div>  
                </div>  
              </div>
            </div>
            <hr class="hrCls mt-4">
            <div class="row mb-3">
                <div class="ml-4 col-sm-9">
                  <input class="form-check-input " type="checkbox" formControlName = "consent" required value="" id="consent" [ngClass]="{ 'is-invalid':submitted && f.consent.errors }">
                  <label class="labelCls" for="consent"><b>I (Applicant), agree that the
                      information/documents submitted is with my consent and can be verified.<span
                    class="mandatoryCls">*</span></b> </label>
                  <div *ngIf="submitted && f.consent.errors" class="invalid-feedback">
                    <div *ngIf="f.consent.errors?.required">Please Check the Consent</div>
                  </div>
                </div>
            </div>
            <div class="row mb-3">
              <div class="ml-4 col-sm-9">
                <input class="form-check-input " type="checkbox" formControlName = "consent2" required value="" id="consent2" [ngClass]="{ 'is-invalid':submitted && f.consent2.errors }">
                <label class="labelCls" for="consent2"><b>I (Verify Authority), hereby, declare that the particulars
                  given above are correct and complete. I will be responsible for any misrepresentation
                  of facts and will be liable for punitive action.<span
                  class="mandatoryCls">*</span></b> </label>
                <div *ngIf="submitted && f.consent2.errors" class="invalid-feedback">
                  <div *ngIf="f.consent2.errors?.required">Please Check the Consent</div>
                </div>
              </div>
          </div>
            <div class="btnHolder">
              <button  class="greenBtnCls float-right" (click)="submit()">Show Payment</button>
            </div>
            <!-- </ng-container> -->
          </ng-template>
        </ng-container>
      </nav>
      <div [ngbNavOutlet]="nav"></div>
    </form>
  </div>
</div>

