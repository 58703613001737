
<div class="certificateInfo ">
    <div class="subHeadingCls mb-3">Applicant Details
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">FamilyIncome:</label>
                <div class="valueCls">{{certificateInfo?.familyIncome}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Occupation:</label>
                <div class="valueCls">{{certificateInfo?.occupation}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

            <div class="fieldHolder">
                <label class="labelCls">Habitation:</label>
                <div class="valueCls">{{certificateInfo?.habitation}}</div>
            </div>

        </div>

    </div>
</div>

<div class="certificateInfo ">

    <div class="subHeadingCls mb-3">Rice Card Family Member Details</div>

    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                    <tr>
                        <th>Serial Number</th>
                        <th>Rice Card Number</th>
                        <th>Name</th>
                        <th>Relation</th>
                        <th>EKYC Status</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of gridDetail;let i=index">
                        <td class="serial">{{i+1 }}</td>
                        <td class="value">{{item.rice_Card_id ? (item.rice_Card_id) : '-' }}</td>
                        <td>{{item?.name }}</td>
                        <td>{{item?.relation}}</td>
                        <td>{{item.ekyc_status_done ? (item.ekyc_status_done) : '-' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
     <!--    <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngFor="let item of members">
                <div class="fieldHolder">
                    <label class="labelCls">Aadhar Number:</label>
                    <div class="valueCls">{{item.aadharNumber}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Deletion Reason:</label>
                    <div class="valueCls">{{item.deletionReason}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Death Certificate No:</label>
                    <div class="valueCls">{{item.deathCertificateNo}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Death Certificate	
                        :</label>
                    <div class="valueCls">{{item.deathCertificate}}</div>
                </div>
              
               
               
            </div>
        </div> -->
        </div>
    <!-- </div> -->

    </div>

<!-- </div> -->

<div class="certificateInfo ">
    <div class="subHeadingCls mb-3">Delete Member Details
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                    <tr>
                        <th>Serial Number</th>
                        <th>Aadhar Number</th>
                        <th>Deletion Reason</th>
                        <th>Death Certificate No</th>
                        <th>Death Certificate</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of members;let i=index">
                        <td class="serial">{{i +1}}</td>
                        <td class="value">{{item.aadhaarNumber ? this.commonService.RSADecrypt(item.aadhaarNumber) : '-' }}</td>
                        <td>{{item?.deletionReason }}</td>
                        <td>{{item?.deathCertificateNo}}</td>
                        <td>

                            <ng-container *ngIf="fileList">
                                <div class="fieldHolder" *ngFor="let file of fileList ; let i = index">
                                    <div *ngIf="file.documentType === item.aadhaarNumber+'_deathCertificate'">
                                        <div class="valueCls"><a
                                                (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                                    </div>
                                </div>
                            </ng-container>

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>
