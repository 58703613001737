import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-regularization-of-sadabainamas-view',
  templateUrl: './regularization-of-sadabainamas-view.component.html',
  styleUrls: ['./regularization-of-sadabainamas-view.component.css']
})
export class RegularizationOfSadabainamasViewComponent implements OnInit {

  constructor(private commonService: CommonService) { }
  
  commonConstants : any = CommonConstants;
  applicantAadharNumber: any;
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  @Output() viewImg = new EventEmitter();
  
  ngOnInit(): void {
    this.decryptAadharNum(this.certificateInfo?.applicantAadharNumber);
  }
  
  decryptAadharNum(aadhar: string) {
    this.commonService
      .getRequest(
        this.commonConstants.cryptoDecryptValue + encodeURIComponent(aadhar)
      )
      .subscribe({
        next: (responseData: any) => {
          this.applicantAadharNumber  = responseData.result.rawValue;
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  
  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
  viewImgFile(file: any) {
    this.viewImg.emit(file);
  }
}
