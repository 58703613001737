
import { AbstractControl, ValidationErrors } from '@angular/forms';
import {map } from 'rxjs/operators';

export function fileExtensionValidator(validExt: string){
  return (control: AbstractControl): { [key: string]: any } | null => {
    let forbidden = true;
    if (control.value) {
      const fileExt = control.value.split('.').pop();
      validExt.split(',').forEach(ext => {
        // console.log(ext.trim(),'ext.trim()')
        // console.log(fileExt.toLowerCase(),'fileExt.toLowerCase()')
        if (ext.trim() == fileExt.toLowerCase()) {
          forbidden = false;
        }
      });
    }
    return forbidden ? { 'inValidExt': true } : null;
  };
} 

export function fileSizeValidator(fileEvent: any) {
  return function(control: AbstractControl) {
    const file: File = fileEvent.target.files[0];
    let forbidden = true
    if (file) {
      const fileSizeInMB = Math.round(file.size / 1024);
      if (fileSizeInMB < 1024)
      {
        //console.log('less than 1024', fileSizeInMB);
          forbidden = false;
      }
      else{
     // console.log('more than 1024', fileSizeInMB);
      }

    }
    return forbidden ? { 'inValidSize': true } : null;
  };
}
export function fileSizeValidatorforPAN(fileEvent: any) {
  return function(control: AbstractControl) {
    const file: File = fileEvent.target.files[0];
    let forbidden = true
    if (file) {
      const fileSizeInMB = Math.round(file.size / 1024);
      if (fileSizeInMB <= 1024)
      {
          forbidden = false;
      }
      else{
      }

    }
    return forbidden ? { 'inValidSize': true } : null;
  };
}

export function fileSizeValidatorComupterized(fileEvent: any) {
  return function(control: AbstractControl) {
    const file: File = fileEvent.target.files[0];
    let forbidden = true
    if (file) {
      const fileSizeInMB = Math.round(file.size / 5120);
      if (fileSizeInMB < 5120)
      {
        //console.log('less than 1024', fileSizeInMB);
          forbidden = false;
      }
      else{
     // console.log('more than 1024', fileSizeInMB);
      }

    }
    return forbidden ? { 'inValidSize': true } : null;
  };
}
export function fileSizeValidatorForAarogyaSri(fileEvent: any) {
  return function(control: AbstractControl) {
    const file: File = fileEvent.target.files[0];
    let forbidden = true
    if (file) {
      const fileSizeInMB = Math.round(file.size / 1024);
      if (fileSizeInMB < 200)
      {
        //console.log('less than 1024', fileSizeInMB);
          forbidden = false;
      }
      else{
     // console.log('more than 1024', fileSizeInMB);
      }

    }
    return forbidden ? { 'inValidSize': true } : null;
  };
}


export function fileResolutionValidator(fileEvent: any,fileService:any,) {
  return function(control: AbstractControl){
     return fileService.readFile(fileEvent).pipe(map((res:any)=>{
     if(res?.width>640){
       return { 'inValidResolution': true }
      }else{
        return null
      }

    }))
  };
}


export function fileSizeValidatorForLabourService(fileEvent: any) {
  return function(control: AbstractControl) {
    const file: File = fileEvent.target.files[0];
    let forbidden = true
    if (file) {
      const fileSizeInMB = Math.round(file.size / 1024);
      if (fileSizeInMB < 100)
      {
        //console.log('less than 1024', fileSizeInMB);
          forbidden = false;
      }
      else{
     // console.log('more than 1024', fileSizeInMB);
      }

    }
    return forbidden ? { 'inValidSize': true } : null;
  };
}

export function fileSizeValidatorForMMC(fileEvent: any) {
  return function(control: AbstractControl) {
    const file: File = fileEvent.target.files[0];
    let forbidden = true
    if (file) {
      const fileSizeInMB = Math.round(file.size / 1024);
      if (fileSizeInMB < 3072)
      {
        //console.log('less than 1024', fileSizeInMB);
          forbidden = false;
      }
      else{
     // console.log('more than 1024', fileSizeInMB);
      }

    }
    return forbidden ? { 'inValidSize': true } : null;
  };
}

export class riceOrRationCardValidator{
  static riceOrRationCardValidate(control : AbstractControl) : ValidationErrors | null{
    //console.log("inside directive" , control.value , " &" , control.value.length )
    let forbidden = true;
    if( control.value.length == 0
    ||(control.value.length == 10 && control.value.match('^[0-9]{1,10}$'))
    || (control.value.length == 15 && control.value.match('^[WYPRTJA]AP[0-9]{12}$', 'i')))
    {
      //console.log("rice card or ration card")
      forbidden = false;
    }
    else{
      //console.log('neither ration card nor rice card')
    }
    return forbidden ? {riceOrRationCardValidate : true} : null;
  }


  


}