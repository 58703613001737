<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
      <div class="breadcrumbCls">
        GSWS / Application Submission / Human Resources <span class="activePage">/ Job Seeker Registration</span>
      </div>
    </div>
    <div class="mainPageContainer mr-3">
      <h6>Job Seeker Application Form</h6>
      <form [formGroup]="jobSeekerForm">
        <nav ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)">
          <ng-container [ngbNavItem] = "1">
            <a ngbNavLink>Basic Details</a>
            <ng-template ngbNavContent>
              <app-common-form formControlName="commonForm" [isSubmitted]="commonFormSubmitted" (casteChange)="getSubCaste($event)" (reset)="commonFormSubmitted = false"></app-common-form>
              <div class="btnHolder">
                <button  class="greenBtnCls float-right" (click)="goToNextTab()">Continue</button>
              </div>
            </ng-template>
          </ng-container>
          <ng-container [ngbNavItem] = "2">
            <a ngbNavLink>Application Form</a>
            <ng-template ngbNavContent>
              <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                  <label class="labelCls">Category : <span class="mandatoryCls">*</span></label>
                  <select class="selectCls" formControlName="category"
                    [ngClass]="{ 'is-invalid':submitted && (f.category.errors || f.category.value === 'Select')}">
                    <option [ngValue]="null" disabled>Select</option>
                    <option *ngFor="let item of casteCategories" [ngValue]="item.entityCode"> {{item.name}}</option>
                  </select>
                  <div *ngIf="submitted && (f.category.errors|| f.category.value === 'Select')"
                  class="selectError">
                  <div *ngIf="f.category.errors?.required|| f.category.value === 'Select'">Please select Category</div>
                </div>
                </div>
              </div>
              <div class="subHeadingCls mb-3">Physical Attributes</div>
              <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Are you Differently abled (PwD)? </label>
                  <input type="radio"  value="Yes" name="physicallyDisabled" formControlName="physicallyDisabled" (change)="onChangeDisablity()">
                  <span class="mx-2 radioCls">Yes</span>
                  <input type="radio"  value="No" name="physicallyDisabled" formControlName="physicallyDisabled">
                  <span class="mx-2 radioCls">No</span>
                </div>
                <ng-container *ngIf="f.physicallyDisabled.value === 'Yes'">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                  <label class="labelCls">Disability Type : <span class="mandatoryCls">*</span></label>
                  <select class="selectCls mb-3 " formControlName="disabilityType"
                    [ngClass]="{ 'is-invalid':submitted && (f.disabilityType.errors || f.disabilityType.value === 'Select')}">
                    <option [ngValue]="null" disabled>Select</option>
                    <option value="OH">OH</option>
                    <option value="VH">VH</option>
                    <option value="HH">HH</option>
                    <option value="MD">MD</option>
                    <option value="MH">MH</option>
                  </select>
                  <div *ngIf="submitted && (f.disabilityType.errors|| f.disabilityType.value === 'Select')"
                  class="selectError">
                  <div *ngIf="f.disabilityType.errors?.required|| f.disabilityType.value === 'Select'">Please select Disability Type</div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                  <label class="labelCls">Working Capacity : <span class="mandatoryCls">*</span></label>
                  <select class="selectCls mb-3 " formControlName="workingCapacity"
                    [ngClass]="{ 'is-invalid':submitted && (f.workingCapacity.errors || f.workingCapacity.value === 'Select')}">
                    <option [ngValue]="null" disabled>Select</option>
                    <option value="4-5 hrs">4-5 hrs</option>
                    <option value="8-10 hrs">8-10 hrs</option>
                    <option value="10-12 hrs">10-12 hrs</option>
                    <option value="12-15 hrs">12-15 hrs</option>
                  </select>
                  <div *ngIf="submitted && (f.workingCapacity.errors|| f.workingCapacity.value === 'Select')"
                  class="selectError">
                  <div *ngIf="f.workingCapacity.errors?.required|| f.workingCapacity.value === 'Select'">Please select Working Capacity</div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                  <label class="labelCls">Partial Disability / Full Disability  : <span class="mandatoryCls">*</span></label>
                  <select class="selectCls mb-3 " formControlName="fullPartDisable"
                    [ngClass]="{ 'is-invalid':submitted && (f.fullPartDisable.errors || f.fullPartDisable.value === 'Select')}">
                    <option [ngValue]="null" disabled>Select</option>
                    <option value="Full">Full</option>
                    <option value="Partial">Partial</option>
                  </select>
                  <div *ngIf="submitted && (f.fullPartDisable.errors|| f.fullPartDisable.value === 'Select')"
                  class="selectError">
                  <div *ngIf="f.fullPartDisable.errors?.required|| f.fullPartDisable.value === 'Select'">Please select Partial Disability / Full Disability </div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                  <label class="labelCls">Disability Percentage   : <span class="mandatoryCls">*</span></label>
                  <select class="selectCls mb-3 " formControlName="disablePercent"
                    [ngClass]="{ 'is-invalid':submitted && (f.disablePercent.errors || f.disablePercent.value === 'Select')}">
                    <option [ngValue]="null" disabled>Select</option>
                    <option value="Less than 40%">Less than 40%</option>
                    <option value="40% or More">40% or More</option>
                  </select>
                  <div *ngIf="submitted && (f.disablePercent.errors|| f.disablePercent.value === 'Select')"
                  class="selectError">
                  <div *ngIf="f.disablePercent.errors?.required|| f.disablePercent.value === 'Select'">Please select Partial Disability / Full Disability </div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                  <label class="labelCls">Additional Disability Type   : </label>
                  <select class="selectCls mb-3 " formControlName="additionDisability"
                    [ngClass]="{ 'is-invalid':submitted && (f.additionDisability.errors || f.additionDisability.value === 'Select')}">
                    <option [ngValue]="null" disabled>Select</option>
                    <option *ngFor="let item of additionalDisabilities" [ngValue]="item.entityCode"> {{item.name}}</option>
                  </select>
                  <div *ngIf="submitted && (f.additionDisability.errors|| f.additionDisability.value === 'Select')"
                  class="selectError">
                  <div *ngIf="f.additionDisability.errors?.required|| f.additionDisability.value === 'Select'">Please select Additional Disability Type </div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Do you have a certificate?</label>
                  <input type="radio"  value="Yes" name="haveCertificate" formControlName="haveCertificate">
                  <span class="mx-2 radioCls">Yes</span>
                  <input type="radio"  value="No" name="haveCertificate" formControlName="haveCertificate">
                  <span class="mx-2 radioCls">No</span>
                </div>
                </ng-container>
              </div>
              <div class="subHeadingCls mb-3">Miscellaneous</div>
              <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                  <label class="labelCls">Employment Status: <span class="mandatoryCls">*</span></label>
                  <select class="selectCls" formControlName="empStatus"
                    [ngClass]="{ 'is-invalid':submitted && (f.empStatus.errors || f.empStatus.value === 'Select')}">
                    <option [ngValue]="null" disabled>Select</option>
                    <option *ngFor="let item of employmentStatus" [ngValue]="item"> {{item.name}}</option>
                  </select>
                  <div *ngIf="submitted && (f.empStatus.errors|| f.empStatus.value === 'Select')"
                  class="selectError">
                  <div *ngIf="f.empStatus.errors?.required|| f.empStatus.value === 'Select'">Please select Employment Status</div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                  <label class="labelCls">Primary Language: <span class="mandatoryCls">*</span></label>
                  <select class="selectCls" formControlName="primaryLang"
                    [ngClass]="{ 'is-invalid':submitted && (f.primaryLang.errors || f.primaryLang.value === 'Select')}">
                    <option [ngValue]="null" disabled>Select</option>
                    <option *ngFor="let item of Languages" [ngValue]="item"> {{item.name}}</option>
                  </select>
                  <div *ngIf="submitted && (f.primaryLang.errors|| f.primaryLang.value === 'Select')"
                  class="selectError">
                  <div *ngIf="f.primaryLang.errors?.required|| f.primaryLang.value === 'Select'">Please select Primary Language</div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                  <label class="labelCls">Are you a Government Services Retiree?: </label>
                  <input type="checkbox" name="govEmployee" formControlName = "govEmployee">
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                  <label class="labelCls">Are you an Ex-Serviceman?: </label>
                  <input type="checkbox" name="exServiceMan" formControlName = "exServiceMan">
                </div>
              </div>
              <div class="subHeadingCls mb-3">Education & Training Details</div>
              <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Highest Educational Level: <span class="mandatoryCls">*</span></label>
                  <select class="selectCls" formControlName="highestEducation" required
                    [ngClass]="{ 'is-invalid':submitted && (f.highestEducation.errors || f.highestEducation.value === 'Select')}">
                    <option [ngValue]="null" disabled>Select</option>
                    <option *ngFor="let item of highestEdu" [ngValue]="item"> {{item.name}}</option>
                  </select>
                  <div *ngIf="submitted && (f.highestEducation.errors|| f.highestEducation.value === 'Select')"
                    class="selectError">
                    <div *ngIf="f.highestEducation.errors?.required|| f.highestEducation.value === 'Select'">Please select Highest Educational Level</div>
                  </div>
                </div>
              </div>
              <div class="subHeadingCls mb-3">Education History</div>
              <div formArrayName="educationHistory">
                <div class="row mb-3"  [formGroupName]="i"  *ngFor="let item of educationHistory.controls; let i=index;">
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Education: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" formControlName="education" required
                      [ngClass]="{ 'is-invalid':submitted && (this.educationHistory.controls[i].get('education')?.errors || this.educationHistory.controls[i].get('education')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Select</option>
                      <option *ngFor="let item of educationList" [ngValue]="item"> {{item.name}}</option>
                    </select>
                    <div *ngIf="submitted && (this.educationHistory.controls[i].get('education')?.errors|| this.educationHistory.controls[i].get('education')?.value === 'Select')"
                      class="selectError">
                      <div *ngIf="this.educationHistory.controls[i].get('education')?.errors?.required|| this.educationHistory.controls[i].get('education')?.value === 'Select'">Please select Education</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Specialization/Major: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" formControlName="specialization" required
                      [ngClass]="{ 'is-invalid':submitted && (this.educationHistory.controls[i].get('specialization')?.errors || this.educationHistory.controls[i].get('specialization')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Select</option>
                      <option value="Agriculture">Agriculture</option>
                      <option value="Botany">Botany</option>
                      <option value="Electronics">Electronics</option>
                    </select>
                    <div *ngIf="submitted && (this.educationHistory.controls[i].get('specialization')?.errors|| this.educationHistory.controls[i].get('specialization')?.value === 'Select')"
                      class="selectError">
                      <div *ngIf="this.educationHistory.controls[i].get('specialization')?.errors?.required|| this.educationHistory.controls[i].get('specialization')?.value === 'Select'">Please select Specialization/Major</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Board/University: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" formControlName="boardUniversity" required
                      [ngClass]="{ 'is-invalid':submitted && (this.educationHistory.controls[i].get('boardUniversity')?.errors || this.educationHistory.controls[i].get('boardUniversity')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Select</option>
                      <option *ngFor="let item of boardUniversities" [ngValue]="item"> {{item.name}}</option>
                    </select>
                    <div *ngIf="submitted && (this.educationHistory.controls[i].get('boardUniversity')?.errors|| this.educationHistory.controls[i].get('boardUniversity')?.value === 'Select')"
                      class="selectError">
                      <div *ngIf="this.educationHistory.controls[i].get('boardUniversity')?.errors?.required|| this.educationHistory.controls[i].get('boardUniversity')?.value === 'Select'">Please select Board/University</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Institute: </label>
                    <input type="text" name="institute" (keypress)="keyPressAlphaNumeric($event)"
                     appBlockCopyPaste class="inputCls" formControlName = "institute">
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Entry Date: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls yearSelect" formControlName="entryYear" required
                    (change)="getEntryYear(i)"
                      [ngClass]="{ 'is-invalid':submitted && (this.educationHistory.controls[i].get('entryYear')?.errors || this.educationHistory.controls[i].get('entryYear')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Year</option>
                      <option *ngFor="let item of years" [ngValue]="item.year"> {{item.year}}</option>
                    </select>
                    <div *ngIf="submitted && (this.educationHistory.controls[i].get('entryYear')?.errors|| this.educationHistory.controls[i].get('entryYear')?.value === 'Select')"
                    class="selectError">
                    <div *ngIf="this.educationHistory.controls[i].get('entryYear')?.errors?.required|| this.educationHistory.controls[i].get('entryYear')?.value === 'Select'">Please select Year of Entry</div>
                   </div>
                    <select class="selectCls monthSelect" formControlName="entryMonth" required
                      [ngClass]="{ 'is-invalid':submitted && (this.educationHistory.controls[i].get('entryMonth')?.errors || this.educationHistory.controls[i].get('entryMonth')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Month</option>
                      <option *ngFor="let item of month" [ngValue]="item.month"> {{item.month}}</option>
                                            
                    </select>
                    <div *ngIf="submitted && (this.educationHistory.controls[i].get('entryMonth')?.errors|| this.educationHistory.controls[i].get('entryMonth')?.value === 'Select')"
                    class="selectError">
                    <div *ngIf="this.educationHistory.controls[i].get('entryMonth')?.errors?.required|| this.educationHistory.controls[i].get('entryMonth')?.value === 'Select'">Please select Month of Entry</div>
                   </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Year of Passing: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls yearSelect" formControlName="passedYear" required
                    (change) = "getPassedMonth(i)"
                      [ngClass]="{ 'is-invalid':submitted && (this.educationHistory.controls[i].get('passedYear')?.errors || this.educationHistory.controls[i].get('passedYear')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Year</option>
                      <option *ngFor="let item of entryYears" [ngValue]="item.year"> {{item.year}}</option>
                    </select>
                    <div *ngIf="submitted && (this.educationHistory.controls[i].get('passedYear')?.errors|| this.educationHistory.controls[i].get('passedYear')?.value === 'Select')"
                    class="selectError">
                    <div *ngIf="this.educationHistory.controls[i].get('passedYear')?.errors?.required|| this.educationHistory.controls[i].get('passedYear')?.value === 'Select'">Please select Year of Passing</div>
                  </div>
                    <select class="selectCls monthSelect" formControlName="passedMonth" required
                      [ngClass]="{ 'is-invalid':submitted && (this.educationHistory.controls[i].get('passedMonth')?.errors || this.educationHistory.controls[i].get('passedMonth')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Month</option>
                      <option *ngFor="let item of passMonth" [ngValue]="item.month"> {{item.month}}</option>
                    </select>
                    <div *ngIf="submitted && (this.educationHistory.controls[i].get('passedMonth')?.errors|| this.educationHistory.controls[i].get('passedMonth')?.value === 'Select')"
                    class="selectError">
                    <div *ngIf="this.educationHistory.controls[i].get('passedMonth')?.errors?.required|| this.educationHistory.controls[i].get('passedMonth')?.value === 'Select'">Please select Month of Passing</div>
                  </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Medium of Education: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" formControlName="mediumEducation" required
                      [ngClass]="{ 'is-invalid':submitted && (this.educationHistory.controls[i].get('mediumEducation')?.errors || this.educationHistory.controls[i].get('mediumEducation')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Select</option>
                      <option *ngFor="let item of Languages" [ngValue]="item"> {{item.name}}</option>                      
                    </select>
                    <div *ngIf="submitted && (this.educationHistory.controls[i].get('mediumEducation')?.errors|| this.educationHistory.controls[i].get('mediumEducation')?.value === 'Select')"
                      class="selectError">
                      <div *ngIf="this.educationHistory.controls[i].get('mediumEducation')?.errors?.required|| this.educationHistory.controls[i].get('mediumEducation')?.value === 'Select'">Please select Medium of Education</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Nature of Course: <span class="mandatoryCls">*</span></label>
                    <!-- <select class="selectCls" formControlName="course" required
                      [ngClass]="{ 'is-invalid':submitted && (this.educationHistory.controls[i].get('course')?.errors || this.educationHistory.controls[i].get('course')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Select</option>
                      <option *ngFor="let item of courseNatures" [ngValue]="item.name"> {{item.name}}</option>
                    </select> -->
                    <input type="text" name="nature" (keypress)="keyPressAlphabet($event)"
                    appBlockCopyPaste class="inputCls" formControlName = "course"
                    [ngClass]="{ 'is-invalid':submitted && this.educationHistory.controls[i].get('course')?.errors }">
                    <div *ngIf="submitted && (this.educationHistory.controls[i].get('course')?.errors|| this.educationHistory.controls[i].get('course')?.value === 'Select')"
                      class="selectError">
                      <div *ngIf="this.educationHistory.controls[i].get('course')?.errors?.required|| this.educationHistory.controls[i].get('course')?.value === 'Select'">Please select Nature of Course</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Grade/Percentage/ Percentile: </label>
                    <select class="selectCls mb-3" formControlName="gradeType"
                      [ngClass]="{ 'is-invalid':submitted && (this.educationHistory.controls[i].get('gradeType')?.errors || this.educationHistory.controls[i].get('gradeType')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Select</option>
                      <option value="Grade">Grade</option>
                      <option value="Percentage">Percentage</option>
                    </select>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">&nbsp;</label>
                    <input type="text" name="gradeValue" appBlockCopyPaste class="inputCls" formControlName = "gradeValue">
                  </div>
                  <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="checkBoxCont">
                        <label class="labelCls" for="marksheet">Upload Final Marksheet
                            (Upload Only PDF format) <span class="mandatoryCls">*</span></label>
                    </div>
                    <div class="input-group">
                        <div class="custom-file">
                            <input type="file" class="custom-file-input" required
                                formControlName="marksheet" id="marksheet" accept=".pdf"
                                [ngClass]="{ 'is-invalid':submitted && this.educationHistory.controls[i].get('marksheet')?.errors }"
                                (change)="onPDFFileSelected($event,this.educationHistory.controls[i].get('education')?.value.name, 'marksheet', 'marksheetLabel', i )">
                            <label class="custom-file-label" #marksheetLabel
                                for="marksheet"><i class="fas fa-search"></i>No File
                                choosen</label>
                        </div>
                        <div class="clearCls" (click)="clearFileUpload('marksheet', 'marksheetLabel', i)"
                            *ngIf="this.educationHistory.controls[i].get('marksheet')?.value"></div>
                        </div>
                    <div *ngIf="submitted && this.educationHistory.controls[i].get('marksheet')?.errors" class="selectError">
                        <div *ngIf="this.educationHistory.controls[i].get('marksheet')?.errors?.required">Please upload Final Marksheet</div> 
                        <div *ngIf="this.educationHistory.controls[i].get('marksheet')?.errors?.inValidExt && !this.educationHistory.controls[i].get('marksheet')?.errors?.required ">
                            Please upload the file in PDF Format</div>
                        <div *ngIf="this.educationHistory.controls[i].get('marksheet')?.errors?.inValidSize && !this.educationHistory.controls[i].get('marksheet')?.errors?.required && !this.educationHistory.controls[i].get('marksheet')?.errors?.inValidExt">
                            File size exceeding 1MB</div>
                        </div>
                    </div>
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div class="checkBoxCont">
                          <label class="labelCls" for="passCertificate">Upload Passing Certificate/Degree
                              (Upload Only PDF format) <span class="mandatoryCls">*</span></label>
                      </div>
                      <div class="input-group">
                          <div class="custom-file">
                              <input type="file" class="custom-file-input" required
                                  formControlName="passCertificate" id="passCertificate" accept=".pdf"
                                  [ngClass]="{ 'is-invalid':submitted && this.educationHistory.controls[i].get('passCertificate')?.errors }"
                                  (change)="onPDFFileSelected($event,this.educationHistory.controls[i].get('education')?.value.name, 'passCertificate','passCertificateLabel', i)">
                              <label class="custom-file-label" #passCertificateLabel
                                  for="passCertificate"><i class="fas fa-search"></i>No File
                                  choosen</label>
                          </div>
                          <div class="clearCls" (click)="clearFileUpload('passCertificate', 'passCertificateLabel', i)"
                              *ngIf="this.educationHistory.controls[i].get('passCertificate')?.value"></div>
                          </div>
                      <div *ngIf="submitted && this.educationHistory.controls[i].get('passCertificate')?.errors" class="selectError">
                          <div *ngIf="this.educationHistory.controls[i].get('passCertificate')?.errors?.required">Please upload Passing Certificate/Degree</div>
                          <div *ngIf="this.educationHistory.controls[i].get('passCertificate')?.errors?.inValidExt && !this.educationHistory.controls[i].get('passCertificate')?.errors?.required ">
                              Please upload the file in PDF Format</div>
                          <div *ngIf="this.educationHistory.controls[i].get('passCertificate')?.errors?.inValidSize && !this.educationHistory.controls[i].get('passCertificate')?.errors?.required && !this.educationHistory.controls[i].get('passCertificate')?.errors?.inValidExt">
                              File size exceeding 1MB</div>
                          </div>
                      </div>
                  <div class="btnHolder removeCls">
                    <button  class="greenBtnCls float-right " (click)="removeEducation(i)">Remove</button>
                  </div>
                </div> 
              </div>
              <hr class="hrCls my-3">
              <div class="btnHolder">
                <button  class="greenBtnCls float-right" (click)="addEducation()">Add More</button>
              </div>
              <div class="subHeadingCls my-3">Other Qualifications / Trainings / Courses</div>
              <div formArrayName="otherQuals">
                <div class="row mb-3"  [formGroupName]="i"  *ngFor="let item of otherQuals.controls; let i=index;">
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Course / Certificate Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="courseName" (keypress)="keyPressAlphaNumeric($event)"
                     appBlockCopyPaste class="inputCls" formControlName = "courseName"
                    [ngClass]="{ 'is-invalid':submitted && this.otherQuals.controls[i].get('courseName')?.errors }">

                    <div *ngIf="submitted && (this.otherQuals.controls[i].get('courseName')?.errors)" class="selectError">
                      <div *ngIf="this.otherQuals.controls[i].get('courseName')?.errors?.required">Please enter Course / Certificate Name</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Year: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" formControlName="year" required
                      [ngClass]="{ 'is-invalid':submitted && (this.otherQuals.controls[i].get('year')?.errors || this.otherQuals.controls[i].get('year')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Select</option>
                      <option *ngFor="let item of years" [ngValue]="item.year"> {{item.year}}</option>
                    </select>
                    <div *ngIf="submitted && (this.otherQuals.controls[i].get('year')?.errors|| this.otherQuals.controls[i].get('year')?.value === 'Select')"
                      class="selectError">
                      <div *ngIf="this.otherQuals.controls[i].get('year')?.errors?.required|| this.otherQuals.controls[i].get('year')?.value === 'Select'">Please select Year</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Issued By: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="courseName" (keypress)="keyPressAlphabet($event)"
                     appBlockCopyPaste class="inputCls" formControlName = "issuedBy"
                    [ngClass]="{ 'is-invalid':submitted && this.otherQuals.controls[i].get('issuedBy')?.errors }">

                    <div *ngIf="submitted && (this.otherQuals.controls[i].get('issuedBy')?.errors)" class="selectError">
                      <div *ngIf="this.otherQuals.controls[i].get('issuedBy')?.errors?.required">Please enter Issued By</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Duration(In Months): </label>
                    <input type="text" name="duration" numbersOnly appBlockCopyPaste class="inputCls yearSelect" formControlName = "duration">
                    <select class="selectCls monthSelect" formControlName="durationIn">
                    <option [ngValue]="null" disabled>Select</option>
                    <option *ngFor="let item of durationTypes" [ngValue]="item.name"> {{item.name}}</option>
                  </select>
                  </div>
                  <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Further Details:</label>
                    <textarea type="text" name="remarks" class="textareaCls" formControlName="details"
                    maxlength="1000" rows="5"></textarea>
                    <div class="noteText">Maximum 1000 Characters are Allowed.</div>
                    <div *ngIf="submitted && this.otherQuals.controls[i].get('issuedBy')?.errors" class="selectError">
                        <div *ngIf="this.otherQuals.controls[i].get('issuedBy')?.errors?.pattern">Please enter valid remarks</div>
                    </div>
                
                  </div>
                  <div class="btnHolder removeCls">
                    <button  class="greenBtnCls float-right" (click)="removeQual(i)">Remove</button>
                  </div>
                </div> 
              </div>
              <hr class="hrCls my-3">
              <div class="btnHolder">
                <button  class="greenBtnCls float-right" (click)="addQual()">Add More</button>
              </div>
              <div class="subHeadingCls mb-3">Work Experience</div>
              <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Key Skills: <span class="mandatoryCls">*</span></label>
                  <input type="text" name="keySkills" (keypress)="keyPressAlphaNumeric($event)"
                   appBlockCopyPaste class="inputCls" formControlName = "keySkills"
                    [ngClass]="{ 'is-invalid':submitted && f.keySkills.errors }">
                    <div *ngIf="submitted && f.keySkills.errors" class="selectError">
                      <div *ngIf="f.keySkills.errors?.required">Please enter Course / Certificate Name</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                  <label class="labelCls">Total Experience: <span class="mandatoryCls">*</span></label>
                  <select class="selectCls yearSelect" formControlName="totalExpYear" required
                    [ngClass]="{ 'is-invalid':submitted && (f.totalExpYear.errors || f.totalExpYear.value === 'Select')}">
                    <option [ngValue]="null" disabled>Year</option>
                    <option *ngFor="let item of totExperience" [ngValue]="item.year"> {{item.year}}</option>
                  </select>
                  <div *ngIf="submitted && (f.totalExpYear.errors|| f.totalExpYear.value === 'Select')"
                  class="selectError">
                  <div *ngIf="f.totalExpYear.errors?.required|| f.totalExpYear.value === 'Select'">Please select Total Experience in years</div>
                </div>
                  <select class="selectCls monthSelect" formControlName="totalExpMonth" required
                    [ngClass]="{ 'is-invalid':submitted && (f.totalExpMonth.errors || f.totalExpMonth.value === 'Select')}">
                    <option [ngValue]="null" disabled>Month</option>
                    <option *ngFor="let item of month" [ngValue]="item.month"> {{item.month}}</option>
                  </select>
                  <div *ngIf="submitted && (f.totalExpMonth.errors|| f.totalExpMonth.value === 'Select')"
                  class="selectError">
                  <div *ngIf="f.totalExpMonth.errors?.required|| f.totalExpMonth.value === 'Select'">Please select Total Experience in months</div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Available to Join in (in Days) : <span class="mandatoryCls">*</span></label>
                  <input type="text" name="joinInDays" numbersOnly
                   appBlockCopyPaste class="inputCls" formControlName = "joinInDays"
                    [ngClass]="{ 'is-invalid':submitted && f.joinInDays.errors }">
                    <div *ngIf="submitted && f.joinInDays.errors" class="selectError">
                      <div *ngIf="f.joinInDays.errors?.required">Please enter Available to Join in (in Days) </div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Current/Last Job Title : </label>
                  <input type="text" name="jobTitle" (keypress)="keyPressAlphabet($event)"
                   appBlockCopyPaste class="inputCls" formControlName = "jobTitle"
                    [ngClass]="{ 'is-invalid':submitted && f.jobTitle.errors }">
                    <div *ngIf="submitted && f.jobTitle.errors" class="selectError">
                      <div *ngIf="f.jobTitle.errors?.required">Please enter Current/Last Job Title </div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  <label class="labelCls">Current/Last Salary : </label>
                  <input type="text" name="salary" numbersOnly appBlockCopyPaste class="inputCls" formControlName = "salary"
                    [ngClass]="{ 'is-invalid':submitted && f.salary.errors }">
                    <div *ngIf="submitted && f.salary.errors" class="selectError">
                      <div *ngIf="f.salary.errors?.required">Please enter Current/Last Salary </div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                  <label class="labelCls">Current Salary Duration Type: </label>
                  <select class="selectCls mb-3 " formControlName="salaryType"
                    [ngClass]="{ 'is-invalid':submitted && (f.salaryType.errors || f.salaryType.value === 'Select')}">
                    <option [ngValue]="null" disabled>Select</option>
                    <option value="Daily">Daily</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                  <div *ngIf="submitted && (f.salaryType.errors|| f.salaryType.value === 'Select')"
                  class="selectError">
                  <div *ngIf="f.salaryType.errors?.required|| f.salaryType.value === 'Select'">Please select Current Salary Duration Type</div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                  <label class="labelCls">Current Employer Organisation Type :  <span class="mandatoryCls">*</span></label>
                  <select class="selectCls" formControlName="empOrgType"
                    [ngClass]="{ 'is-invalid':submitted && (f.empOrgType.errors || f.empOrgType.value === 'Select')}">
                    <option [ngValue]="null" disabled>Select</option>
                    <option  value="Others">Others</option>
                    <option  value="Self Employed">Self Employed</option> 
                    <option  value="Private">Private</option>
                    <option  value="Central Government">Central Government</option>
                    <option  value="State Government">State Government</option>
                    <option  value="Central PSU">Central PSU</option>
                    <option  value="State PSU">State PSU</option>
                    <option  value="Local Bodies">Local Bodies</option>
                    <option  value="Autonomous">Autonomous</option>
                    <option  value="Company">Company</option>
                    <option  value="NGO">NGO</option>
                    <option  value="Partnership">Partnership</option>
                    <option  value="Proprietorship">Proprietorship</option>
                    <option  value="Autonomous/Others">Autonomous/Others</option>
                    <option  value="Government">Government</option>
                  </select>
                  <div *ngIf="submitted && (f.empOrgType.errors|| f.empOrgType.value === 'Select')"
                  class="selectError">
                  <div *ngIf="f.empOrgType.errors?.required|| f.empOrgType.value === 'Select'">Please select Current Salary Duration Type</div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                  <label class="labelCls">Currently Seeking a Job: </label>
                  <input type="checkbox" name="jobSeeking" formControlName = "jobSeeking">
                </div>
              </div>
              <div class="subHeadingCls my-3">Employment History</div>
              <div formArrayName="empHistory">
                <div class="row mb-3"  [formGroupName]="i"  *ngFor="let item of empHistory.controls; let i=index;">
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Employer Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" (keypress)="keyPressAlphabet($event)"
                     name="employerName" appBlockCopyPaste class="inputCls" formControlName = "employerName"
                    [ngClass]="{ 'is-invalid':submitted && this.empHistory.controls[i].get('employerName')?.errors }">
                    <div *ngIf="submitted && (this.empHistory.controls[i].get('employerName')?.errors)" class="selectError">
                      <div *ngIf="this.empHistory.controls[i].get('employerName')?.errors?.required">Please enter Employer Name</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Employer Address: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="employerAddress" (keypress)="keyPressAlphaNumeric($event)"
                     appBlockCopyPaste class="inputCls" formControlName = "employerAddress"
                    [ngClass]="{ 'is-invalid':submitted && this.empHistory.controls[i].get('employerAddress')?.errors }">
                    <div *ngIf="submitted && (this.empHistory.controls[i].get('employerAddress')?.errors)" class="selectError">
                      <div *ngIf="this.empHistory.controls[i].get('employerAddress')?.errors?.required">Please enter Employer Address</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">State: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" formControlName="state" required (change)="onChangeState(i)"
                      [ngClass]="{ 'is-invalid':submitted && (this.empHistory.controls[i].get('state')?.errors || this.empHistory.controls[i].get('state')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Select</option>
                      <option *ngFor="let item of states" [ngValue]="item.name"> {{item.name}}</option>
                    </select>
                    <div *ngIf="submitted && (this.empHistory.controls[i].get('state')?.errors|| this.empHistory.controls[i].get('state')?.value === 'Select')"
                      class="selectError">
                      <div *ngIf="this.empHistory.controls[i].get('state')?.errors?.required|| this.empHistory.controls[i].get('state')?.value === 'Select'">Please select State</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">District: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" formControlName="district" required
                      [ngClass]="{ 'is-invalid':submitted && (this.empHistory.controls[i].get('district')?.errors || this.empHistory.controls[i].get('district')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Select</option>
                      <option *ngFor="let item of districts" [ngValue]="item.name"> {{item.name}}</option>
                    </select>
                    <div *ngIf="submitted && (this.empHistory.controls[i].get('district')?.errors|| this.empHistory.controls[i].get('district')?.value === 'Select')"
                      class="selectError">
                      <div *ngIf="this.empHistory.controls[i].get('district')?.errors?.required|| this.empHistory.controls[i].get('district')?.value === 'Select'">Please select District</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Employment Organisation Type: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" formControlName="empOrgType" required
                      [ngClass]="{ 'is-invalid':submitted && (this.empHistory.controls[i].get('empOrgType')?.errors || this.empHistory.controls[i].get('empOrgType')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Select</option>
                      <option  value="Others">Others</option>
                      <option  value="Self Employed">Self Employed</option> 
                    <option  value="Private">Private</option>
                    <option  value="Central Government">Central Government</option>
                    <option  value="State Government">State Government</option>
                    <option  value="Central PSU">Central PSU</option>
                    <option  value="State PSU">State PSU</option>
                    <option  value="Local Bodies">Local Bodies</option>
                    <option  value="Autonomous">Autonomous</option>
                    <option  value="Company">Company</option>
                    <option  value="NGO">NGO</option>
                    <option  value="Partnership">Partnership</option>
                    <option  value="Proprietorship">Proprietorship</option>
                    <option  value="Autonomous/Others">Autonomous/Others</option>
                    <option  value="Government">Government</option>

                    </select>
                    <div *ngIf="submitted && (this.empHistory.controls[i].get('empOrgType')?.errors|| this.empHistory.controls[i].get('empOrgType')?.value === 'Select')"
                      class="selectError">
                      <div *ngIf="this.empHistory.controls[i].get('empOrgType')?.errors?.required|| this.empHistory.controls[i].get('empOrgType')?.value === 'Select'">Please select Employment Organisation Type</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Designation: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="designation" (keypress)="keyPressAlphabet($event)"
                     appBlockCopyPaste class="inputCls" formControlName = "designation"
                    [ngClass]="{ 'is-invalid':submitted && this.empHistory.controls[i].get('designation')?.errors }">
                    <div *ngIf="submitted && (this.empHistory.controls[i].get('designation')?.errors)" class="selectError">
                      <div *ngIf="this.empHistory.controls[i].get('designation')?.errors?.required">Please enter Designation</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Nature of Job: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" formControlName="natureOfJob" required
                      [ngClass]="{ 'is-invalid':submitted && (this.empHistory.controls[i].get('natureOfJob')?.errors || this.empHistory.controls[i].get('natureOfJob')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Select</option>
                      <option *ngFor="let item of jobNature" [ngValue]="item.name"> {{item.name}}</option>
                    </select>
                    <div *ngIf="submitted && (this.empHistory.controls[i].get('natureOfJob')?.errors|| this.empHistory.controls[i].get('natureOfJob')?.value === 'Select')"
                      class="selectError">
                      <div *ngIf="this.empHistory.controls[i].get('natureOfJob')?.errors?.required|| this.empHistory.controls[i].get('natureOfJob')?.value === 'Select'">Please select Nature of Job</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Sector: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" formControlName="sector" required
                      [ngClass]="{ 'is-invalid':submitted && (this.empHistory.controls[i].get('sector')?.errors || this.empHistory.controls[i].get('sector')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Select</option>
                      <option *ngFor="let item of sectors" [ngValue]="item.name"> {{item.name}}</option>
                    </select>
                    <div *ngIf="submitted && (this.empHistory.controls[i].get('sector')?.errors|| this.empHistory.controls[i].get('sector')?.value === 'Select')"
                      class="selectError">
                      <div *ngIf="this.empHistory.controls[i].get('sector')?.errors?.required|| this.empHistory.controls[i].get('sector')?.value === 'Select'">Please select Sector</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Functional Area: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="funcArea" (keypress)="keyPressAlphaNumeric($event)"
                     appBlockCopyPaste class="inputCls" formControlName = "funcArea"
                     [ngClass]="{ 'is-invalid':submitted && this.empHistory.controls[i].get('funcArea')?.errors }">
                    <!-- <select class="selectCls" formControlName="funcArea" required
                      [ngClass]="{ 'is-invalid':submitted && (this.empHistory.controls[i].get('funcArea')?.errors || this.empHistory.controls[i].get('funcArea')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Select</option>
                    </select> -->
                    <div *ngIf="submitted && (this.empHistory.controls[i].get('funcArea')?.errors|| this.empHistory.controls[i].get('funcArea')?.value === 'Select')"
                      class="selectError">
                      <div *ngIf="this.empHistory.controls[i].get('funcArea')?.errors?.required|| this.empHistory.controls[i].get('funcArea')?.value === 'Select'">Please select Functional Area</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Functional Role: <span class="mandatoryCls">*</span></label>
                    <!-- <select class="selectCls" formControlName="funcRole" required
                      [ngClass]="{ 'is-invalid':submitted && (this.empHistory.controls[i].get('funcRole')?.errors || this.empHistory.controls[i].get('funcRole')?.value === 'Select')}">
                      <option [ngValue]="null" disabled>Select</option>
                    </select> -->
                    <input type="text" name="funcRole" (keypress)="keyPressAlphaNumeric($event)"
                     appBlockCopyPaste class="inputCls" formControlName = "funcRole"
                     [ngClass]="{ 'is-invalid':submitted && this.empHistory.controls[i].get('funcRole')?.errors }">
                    <div *ngIf="submitted && (this.empHistory.controls[i].get('funcRole')?.errors|| this.empHistory.controls[i].get('funcRole')?.value === 'Select')"
                      class="selectError">
                      <div *ngIf="this.empHistory.controls[i].get('funcRole')?.errors?.required|| this.empHistory.controls[i].get('funcRole')?.value === 'Select'">Please select Functional Role</div>
                    </div>
                  </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Start Date: <span class="mandatoryCls">*</span></label>
                    <div class="input-group">
                        <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                                name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" (dateSelect)="onStartDateSelect($event)"
                                [maxDate]="maxDate" required formControlName="startDate"  
                                [ngClass]="{ 'is-invalid':submitted && this.empHistory.controls[i].get('startDate')?.errors }" >
                        <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                        </div>
                    </div>
                    <div *ngIf="submitted && this.empHistory.controls[i].get('startDate')?.errors" class="selectError">
                        <div *ngIf="this.empHistory.controls[i].get('startDate')?.errors?.required || this.empHistory.controls[i].get('startDate')?.invalid">Please select From Date</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">End Date: <span class="mandatoryCls">*</span></label>
                    <div class="input-group">
                        <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                                name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="toMinDate" 
                                [maxDate]="maxDate" required formControlName="endDate"
                                [ngClass]="{ 'is-invalid':submitted && this.empHistory.controls[i].get('endDate')?.errors }" >
                        <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
                        </div>
                    </div>
                    <div *ngIf="submitted && this.empHistory.controls[i].get('endDate')?.errors" class="selectError">
                        <div *ngIf="this.empHistory.controls[i].get('endDate')?.errors?.required || this.empHistory.controls[i].get('endDate')?.invalid">Please select End Date</div>
                    </div>
                </div>
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Is Current Job: </label>
                    <input type="checkbox" name="isCurrentJob" formControlName = "isCurrentJob">
                  </div>
                  <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Key Responsibilites:</label>
                    <textarea type="text" name="remarks" class="textareaCls" formControlName="keyResp"
                    maxlength="1000" rows="5"></textarea>
                    <div class="noteText">Maximum 1000 Characters are Allowed.</div>
                  </div>
                  <div class="btnHolder removeCls">
                    <button  class="greenBtnCls float-right" (click)="removeEmployment(i)">Remove</button>
                  </div>
                </div> 
              </div>
              <hr class="hrCls my-3">
              <div class="btnHolder">
                <button  class="greenBtnCls float-right" (click)="addEmployment()">Add More</button>
              </div>
              <div class="subHeadingCls mb-3">Preferences</div>
              <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                  <label class="labelCls">Preferred Location: </label>
                  <input type="text" name="preferredLocation" (keypress)="keyPressAlphabet($event)"
                   appBlockCopyPaste class="inputCls" formControlName = "preferredLocation">
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                  <label class="labelCls">Job Nature: </label>
                  <select class="selectCls mb-3 " formControlName="jobNature"
                    [ngClass]="{ 'is-invalid':submitted && (f.jobNature.errors || f.jobNature.value === 'Select')}">
                    <option [ngValue]="null" disabled>Select</option>
                    <option *ngFor="let item of jobNature" [ngValue]="item.name"> {{item.name}}</option>
                  </select>
                  <div *ngIf="submitted && (f.jobNature.errors|| f.jobNature.value === 'Select')"
                  class="selectError">
                  <div *ngIf="f.jobNature.errors?.required|| f.jobNature.value === 'Select'">Please select Job Nature</div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                  <label class="labelCls">Shift Timings: </label>
                  <select class="selectCls mb-3 " formControlName="shiftTimings"
                    [ngClass]="{ 'is-invalid':submitted && (f.shiftTimings.errors || f.shiftTimings.value === 'Select')}">
                    <option [ngValue]="null" disabled>Select</option>
                    <option value="Day">Day</option>
                    <option value="Night">Night</option>
                  </select>
                  <div *ngIf="submitted && (f.shiftTimings.errors|| f.shiftTimings.value === 'Select')"
                  class="selectError">
                  <div *ngIf="f.shiftTimings.errors?.required|| f.shiftTimings.value === 'Select'">Please select Shift Timings</div>
                </div>
                </div>
               
              </div>
              <hr class="hrCls mt-4">
              <div class="subHeadingCls">Document List</div>
              <div class="mb-3"><b>Note: </b>All Upload Documents are in Pdf Format Only. Maximum Upload Size Per File is 1MB.</div>
              <div class="row mb-3">
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="checkBoxCont">
                    <label class="labelCls">Aadhar Card <span class="mandatoryCls">*</span></label>
                  </div>
                  <div class="input-group">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" name="aadharFile" required formControlName = "aadharFile" [ngClass]="{ 'is-invalid':submitted && f.aadharFile.errors }" accept=".pdf" (change) = "onFileSelected($event, 'aadharFile', 'aadharFileLabel')">
                        <label class="custom-file-label" #aadharFileLabel for="aadharFile"><i class="fas fa-search"></i>No File choosen</label>
                    </div>
                    <div class="clearCls" (click)="clearFileUpload('aadharFile', 'aadharFileLabel')" *ngIf="f.aadharFile.value"></div>
                  </div>
                  <div *ngIf="submitted && f.aadharFile.errors" class="selectError">
                    <div *ngIf="f.aadharFile.errors?.inValidExt || f.aadharFile.errors?.required">Please upload the file in PDF Format</div>
                    <div *ngIf="f.aadharFile.errors?.inValidSize && !f.aadharFile.errors.inValidExt">File size exceeding 1MB</div>  
                  </div>  
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="checkBoxCont">
                    <label class="labelCls">Caste Certificate <span class="mandatoryCls">*</span></label>
                  </div>
                  <div class="input-group">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" name="casteCert" required formControlName = "casteCert" [ngClass]="{ 'is-invalid':submitted && f.casteCert.errors }" accept=".pdf" (change) = "onFileSelected($event, 'casteCert', 'casteCertLabel')">
                        <label class="custom-file-label" #casteCertLabel for="casteCert"><i class="fas fa-search"></i>No File choosen</label>
                    </div>
                    <div class="clearCls" (click)="clearFileUpload('casteCert', 'casteCertLabel')" *ngIf="f.casteCert.value"></div>
                  </div>
                  <div *ngIf="submitted && f.casteCert.errors" class="selectError">
                    <div *ngIf="f.casteCert.errors?.inValidExt || f.casteCert.errors?.required">Please upload the file in PDF Format</div>
                    <div *ngIf="f.casteCert.errors?.inValidSize && !f.casteCert.errors.inValidExt">File size exceeding 1MB</div>  
                  </div>  
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <div class="checkBoxCont">
                    <label class="labelCls">CV <span class="mandatoryCls">*</span></label>
                  </div>
                  <div class="input-group">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" name="docCV" required formControlName = "docCV" [ngClass]="{ 'is-invalid':submitted && f.docCV.errors }" 
                        accept=".pdf" (change) = "onFileSelected($event, 'docCV', 'docCVLabel')">
                        <label class="custom-file-label" #docCVLabel for="docCV"><i class="fas fa-search"></i>No File choosen</label>
                    </div>
                    <div class="clearCls" (click)="clearFileUpload('docCV', 'docCVLabel')" *ngIf="f.docCV.value"></div>
                  </div>
                  <div *ngIf="submitted && f.docCV.errors" class="selectError">
                    <div *ngIf="f.docCV.errors?.inValidExt || f.docCV.errors?.required">Please upload the file in PDF Format</div>
                    <div *ngIf="f.docCV.errors?.inValidSize && !f.docCV.errors.inValidExt">File size exceeding 1MB</div>  
                  </div>  
                </div>
              </div>
              <hr class="hrCls mt-4">
              <div class="row mb-3">
                  <div class="ml-4 col-sm-9">
                    <input class="form-check-input " type="checkbox" formControlName = "consent" required value="" id="consent" [ngClass]="{ 'is-invalid':submitted && f.consent.errors }">
                    <label class="labelCls" for="consent"><b>I (Applicant), agree that the
                        information/documents submitted is with my consent and can be verified.<span
                      class="mandatoryCls">*</span></b> </label>
                    <div *ngIf="submitted && f.consent.errors" class="invalid-feedback">
                      <div *ngIf="f.consent.errors?.required">Please Check the Consent</div>
                    </div>
                  </div>
              </div>
              <div class="row mb-3">
                <div class="ml-4 col-sm-9">
                  <input class="form-check-input " type="checkbox" formControlName = "consent2" required value="" id="consent2" [ngClass]="{ 'is-invalid':submitted && f.consent2.errors }">
                  <label class="labelCls" for="consent2"><b>I (Verify Authority), hereby, declare that the particulars
                    given above are correct and complete. I will be responsible for any misrepresentation
                    of facts and will be liable for punitive action.<span
                    class="mandatoryCls">*</span></b> </label>
                  <div *ngIf="submitted && f.consent2.errors" class="invalid-feedback">
                    <div *ngIf="f.consent2.errors?.required">Please Check the Consent</div>
                  </div>
                </div>
            </div>
            <div class="btnHolder">
                <button  class="greenBtnCls float-right" (click)="submit()">Show Payment</button>
              </div> 
            </ng-template>
          </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav"></div>
      </form>
    </div>
  </div>
  
  