import { Component, OnInit ,ViewChild, ElementRef } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { CommonService, CustomAdapter, CustomDateParserFormatter, WhitespaceValidator } from '../../shared/common.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter,NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';;
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-reshedule-welfare-schemes',
  templateUrl: './reshedule-welfare-schemes.component.html',
  styleUrls: ['./reshedule-welfare-schemes.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
  ]
})
export class ResheduleWelfareSchemesComponent implements OnInit {
  currentDate: any= new Date();
  maxDate: any;
  minDate: any; 
  form: FormGroup;
  scheduleForm: FormGroup;
  commonConstants : any = CommonConstants;
  headerCol = [{colId:'districT_NAME', colName: 'District'},
  {colId:'mandaL_NAME', colName: 'Mandal'},
  {colId:'secretariaT_NAME', colName: 'Secretariat'},
  ]
  items: any[] = [];
  totalItems: any[] = [];
  districts: any[] = [];
  mandals: any[] = [];
  secretariats: any[] = [];
  pendingDistricts: any[] = [];
  pendingMandals: any[] = [];
  pendingSecretariats: any[] = [];
  page = 1;
  popupPage = 1;
  pageSize = 10;
  showReports:Boolean= false;
  submitted:Boolean=false;
  noOfCol: any = 7;
  popupPageSize = 10;
  scheduleDate: any;
  scheduleSubmitted: Boolean = false;
  selectedItem: any;
  modal: any;
  constructor(private commonService: CommonService,private router: Router,private modalService: NgbModal, private formBuilder: FormBuilder, private datePipe: DatePipe) {
    this.form = this.formBuilder.group({
      district: [null],
      mandal: [null],
      secretariat: [null],
      pendingDistrict: [null],
      pendingMandal: [null],
      pendingSecretariat: [null],
      scheduleType: [null, Validators.required]
    });
    this.scheduleForm = this.formBuilder.group({
      resheduleDate: [null, [Validators.required]],
      reason: [null, [Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]]
    });
    this.getDistricts('schedule');
    this.getDistricts();
    this.maxDate = {
      year: this.currentDate.getFullYear() + 1,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: 2023,
      month: 10, //this.currentDate.getMonth() + 1,
      day: 1 //this.currentDate.getDate() + 1
    };
  }
  get f() {
    return this.form.controls;
  }
  get f1() {
    return this.scheduleForm.controls;
  }
  ngOnInit(): void {
    // this.form.patchValue({
    //   district:'All',
    //   mandal:'All',
    //   secretariat: 'All',
    //   // pendingDistrict: 'All',
    //   // pendingMandal: 'All',
    //   // pendingSecretariat: 'All'
    // })
  }

  getDistricts(from?:any) {
   
    let postData: any = from === 'schedule' ? 'Flag=Schedule Districts' : 'Flag=Pending Districts';
    this.commonService.getRequest(this.commonConstants.getWelfareSchmeRescheduleDetails + postData).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          if(from === 'schedule')
            this.districts = this.commonService.sortItems(responseData.result?.data, 'districT_NAME');
          else
            this.pendingDistricts = this.commonService.sortItems(responseData.result?.data, 'districT_NAME');
        } else {
          this.districts = [];
          this.pendingDistricts = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onDistrictChange(from?:any) {
    if(from === 'schedule') {
      this.showReports = false;
      this.mandals = [];
      this.secretariats = [];
      this.f.mandal.patchValue(null);
      this.f.secretariat.patchValue(null);
    } else {
      this.pendingMandals = [];
      this.pendingSecretariats = [];
      this.f.pendingMandal.patchValue(null);
      this.f.pendingSecretariat.patchValue(null);
    }
    this.getMandals(from);
  }
  getMandals(from?:any){
    let postData: any = from === 'schedule' ? 'Flag=Schedule Mandals&DistrictId='+ this.f.district.value?.did : 'Flag=Pending Mandals&DistrictId='+ this.f.pendingDistrict.value?.did;
    this.commonService.getRequest(this.commonConstants.getWelfareSchmeRescheduleDetails + postData).subscribe({
      next: (responseData: any) => { 
        if(responseData.result?.isSuccess) {
          if(from === 'schedule')
            this.mandals = this.commonService.sortItems(responseData.result?.data, 'mandaL_NAME');
          else
            this.pendingMandals = this.commonService.sortItems(responseData.result?.data, 'mandaL_NAME');
        } else {
          this.mandals = [];
          this.pendingMandals = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onMandalChange(from?:any) {
    if(from === 'schedule') {
      this.showReports = false;
      this.secretariats = [];
      this.f.secretariat.patchValue(null);
    } else {
      this.pendingSecretariats = [];
      this.f.pendingSecretariat.patchValue(null);
    }
   
    let postData: any = from === 'schedule' ? 'Flag=Schedule Secretariates&MandalId='+ this.f.mandal.value?.mid + '&DistrictId='+ this.f.district.value?.did : 'Flag=Pending Secretariates&MandalId='+ this.f.pendingMandal.value?.mid + '&DistrictId='+ this.f.pendingDistrict.value?.did ;
    this.commonService.getRequest(this.commonConstants.getWelfareSchmeRescheduleDetails + postData).subscribe({
      next: (responseData: any) => { 
        if(responseData.result?.isSuccess) {
          if(from === 'schedule')
            this.secretariats = this.commonService.sortItems(responseData.result?.data, 'secretariaT_NAME');
          else
            this.pendingSecretariats = this.commonService.sortItems(responseData.result?.data, 'secretariaT_NAME');
        } else {
          this.secretariats = [];
          this.pendingSecretariats = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  getSchedules():any { 
    this.submitted = true;
    if(!this.form.valid) {
      return false;
    }
    this.items = [];
    let district = this.f.district.value === 'All' ? 'All' : this.f.district.value?.did;
    let mandal = this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mid;
    let village = this.f.secretariat.value === 'All' ? 'All' : this.f.secretariat.value?.secretariaT_CODE;
    let postData: any = 'Flag=Schedules&DistrictId='+ district +'&MandalId='+ mandal + '&SecretariatCode='+ village;
    this.commonService.getRequest(this.commonConstants.getWelfareSchmeRescheduleDetails + postData).subscribe({
      next: (responseData: any) => { 
        if(responseData.result?.isSuccess) {
          this.showReports = true;
          this.items = responseData.result?.data;
        } else {
          this.items = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        this.items = [];
        console.log('client side', error);
      }
  });
  }
  openSchedule(item:any, from:any, modal:any): any {
    this.submitted = true;
    this.scheduleForm.reset();
    if(!this.form.valid) {
      return false;
    }
    this.scheduleSubmitted = false;
    this.selectedItem = item;
    this.modal = this.modalService.open(modal, { size: 'md', centered: true, backdrop: 'static'});
  }
saveReschedule(): any {
  this.scheduleSubmitted = true;
  if(!this.scheduleForm.valid)
    return false;
  let district = this.selectedItem?.did;
  let mandal = this.selectedItem?.mid;
  let village = this.selectedItem?.secretariaT_CODE;
  let scheduleDate = this.datePipe.transform(this.commonService.formatDate(this.f1.resheduleDate.value), 'dd/MM/yyyy')
  let postData: any = 'Flag=Reschedule&DistrictId='+ district +'&MandalId='+ mandal + '&SecretariatCode='+ village + '&ScheduleDate='+ scheduleDate + '&RescheduleReason='+ this.f1.reason.value;
  this.commonService.getRequest(this.commonConstants.getWelfareSchmeRescheduleDetails + postData).subscribe({
    next: (responseData: any) => { 
      if(responseData.result?.isSuccess) {
        this.modal.close();
        this.commonService.commonErrorPopup('Alert', 'Saved successfully.', 'md');
        this.f.district.patchValue(null);
        this.f.mandal.patchValue(null);
        this.f.secretariat.patchValue(null);
        this.showReports = false;
        this.submitted =false;
      } else {
        this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
      }
    },
    error: (error) => {
      console.log('client side', error);
    }
});
}
addNewSchedule(): any {
  this.scheduleSubmitted = true;
  if(!this.scheduleForm.valid)
    return false;
  let district =  this.f.pendingDistrict.value?.did;
  let mandal =  this.f.pendingMandal.value?.mid;
  let village =  this.f.pendingSecretariat.value?.secretariaT_CODE;
  let scheduleDate = this.datePipe.transform(this.commonService.formatDate(this.f1.resheduleDate.value), 'dd/MM/yyyy')
  let postData: any = 'Flag=NEW SCHEDULE&DistrictId='+ district +'&MandalId='+ mandal + '&SecretariatCode='+ village + '&ScheduleDate=' + scheduleDate;
  this.commonService.getRequest(this.commonConstants.getWelfareSchmeRescheduleDetails + postData).subscribe({
    next: (responseData: any) => { 
      if(responseData.result?.isSuccess) {
        this.modal.close();
        this.commonService.commonErrorPopup('Alert', 'Saved successfully.', 'md');
        this.f.pendingDistrict.patchValue(null);
        this.f.pendingMandal.patchValue(null);
        this.f.pendingSecretariat.patchValue(null);
        this.submitted =false;
      } else {
        this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
      }
    },
    error: (error) => {
      console.log('client side', error);
    }
});
} 
    goToHome() {
      this.router.navigate(['/home']);
    }
    onScheduleChange() {
      this.submitted = false;
      this.f.district.patchValue(null);
      this.f.mandal.patchValue(null);
      this.f.secretariat.patchValue(null);
      this.f.pendingDistrict.patchValue(null);
      this.f.pendingMandal.patchValue(null);
      this.f.pendingSecretariat.patchValue(null);
      this.mandals = [];
      this.secretariats = [];
      this.pendingMandals = [];
      this.pendingSecretariats = [];
      if(this.f.scheduleType.value === 'addNew') {
        this.f.pendingDistrict.setValidators([Validators.required]);
        this.f.pendingMandal.setValidators([Validators.required]);
        this.f.pendingSecretariat.setValidators([Validators.required]);
        this.f.district.clearValidators();
        this.f.mandal.clearValidators();
        this.f.secretariat.clearValidators();
        this.f1.reason.clearValidators();
      } else {
        this.f.district.setValidators([Validators.required]);
        this.f.mandal.setValidators([Validators.required]);
        this.f.secretariat.setValidators([Validators.required]);
        this.f.pendingDistrict.clearValidators();
        this.f.pendingMandal.clearValidators();
        this.f.pendingSecretariat.clearValidators();
        this.f1.reason.setValidators([Validators.required,Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]);
      }
      this.f.pendingDistrict.updateValueAndValidity();
        this.f.pendingMandal.updateValueAndValidity();
        this.f.pendingSecretariat.updateValueAndValidity();
        this.f.district.updateValueAndValidity();
        this.f.mandal.updateValueAndValidity();
        this.f.secretariat.updateValueAndValidity();
        this.f1.reason.updateValueAndValidity();
    }
    //getWelfareSchmeRescheduleDetails
}
