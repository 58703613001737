<div class="modal-header" >
    <h6 class="modal-title">Please Upload Image</h6>
    <button type="button" class="close" aria-label="Close"  (click)="activeModal.close('Cross Click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="uploadFile">
        <div class="input-group mb-3">
          <!-- <div class="custom-file"> 
            <input type="file" class="form-control" id="otherDocument" formControlName="uploadFileControl" #otherDocumentsLabel [ngClass]="{ 'is-invalid':submitted && f.uploadFileControl.errors }" 
            (change)="onFileSelected($event)" accept=".jpg, .jpeg, .png"> 
            <input class="w-100" formControlName="uploadFileControl" (change)="onFileSelected($event)" #otherDocumentsLabel type="file" id="formFileMultiple" accept=".jpg, .jpeg, .png" [ngClass]="{ 'is-invalid':submitted && f.uploadFileControl.errors }"> 
         </div> -->
         <div class="custom-file">
          <input type="file" class="custom-file-input" formControlName="uploadFileControl" id="otherDocument" #otherDocumentsLabel accept=".jpg, .jpeg, .png" [ngClass]="{ 'is-invalid':submitted && f.uploadFileControl.errors }" 
          (change)="onFileSelected($event)">
          <label class="custom-file-label" for="campStartPhoto"><i class="fas fa-search"></i>{{campPhotoLabelValue}}</label>
        </div>
        <div class="clearCls" (click)="clearFileUpload('uploadFileControl', 'otherDocumentsLabel')" *ngIf="f.uploadFileControl.value"></div>
        </div>
        <div *ngIf="submitted && f.uploadFileControl.errors" class="selectError">
          <div *ngIf="f.uploadFileControl.errors.required">Please upload signature photo</div>
          <div *ngIf="f.uploadFileControl.errors?.inValidExt && !f.uploadFileControl.errors.required ">Please upload the file in jpg/jpeg/png Format</div>
          <div *ngIf="f.uploadFileControl.errors?.inValidSize && !f.uploadFileControl.errors.required && !f.uploadFileControl.errors.inValidExt">File size exceeding 1MB</div> 
      </div>
      </form>
  </div>
  <div>
    <div class="modal-footer">
      <button type="button" class="greenBtnCls" (click)="onOkClick()">Ok</button>
    </div>
  </div>
