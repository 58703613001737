<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Selected Criteria Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Document Type:</label>
                <div class="valueCls">{{certificateInfo?.documentType ? (certificateInfo?.documentType) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo?.districtName ? (certificateInfo?.districtName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo?.mandalMunicipalityName ? (certificateInfo?.mandalMunicipalityName) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Village:</label>
            <div class="valueCls">{{certificateInfo?.villageWardName ? (certificateInfo?.villageWardName) : '-' }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Reference Number:</label>
            <div class="valueCls">{{certificateInfo?.referenceNumber ? (certificateInfo?.referenceNumber) : '-' }}</div>
        </div>
        </div>
    </div>
    <hr>
    <div class="subHeadingCls mb-3">Applicant Details</div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <table class="tableCls mt-2">
                    <thead>
                    <tr> 
                        <th>Survey Number</th>
                        <th>Extent Registered During Registration</th>
                        <th>Name of the Claimant(Buyer)</th>
                        <th>Payment Fee(As per each record)</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of members">
                            <td class="value">{{item.surveyNo ? (item.surveyNo) : '-' }}</td>
                            <td class="value">{{item.extent ? (item.extent) : '-' }}</td>
                            <td>{{item.buyerName ? (item.buyerName) : '-' }}</td>
                            <td class="value">{{item.paymentFee ? (item.paymentFee) : '-' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

</div>

