<div class="reportContainer">
    <div  [formGroup]="form">
        <div class="row mb-3">
            
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Service Name : <span class="mandatoryCls">*</span></label>
            <select class="selectCls"  formControlName="services"
            [ngClass]="{ 'is-invalid':submitted && (f.services.errors || f.services.value === 'Select') }">
                <option value="All" selected>All</option>
                <option value="Mutation for Transaction">Mutation for Transaction</option>
                <option value="Mutation for Correction">Mutation for Correction</option>
                <option value="Mutation(MU2)">Mutation(MU2)</option>
                <option value="Replacement of Pattadar Passbook">Replacement of Pattadar Passbook</option>
                <option value="Duplicate Pattadar Passbook">Duplicate Pattadar Passbook</option>

            </select>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">From Date: <span class="mandatoryCls">*</span></label>
                <div class="input-group">
                  <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                         name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" (dateSelect)="onFromDateSelect($event)"
                         [maxDate]="maxDate" required formControlName="fromDate"
                         [ngClass]="{ 'is-invalid':submitted && f.fromDate.errors }">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                  </div>
                </div>
                <div *ngIf="submitted && f.fromDate.errors" class="selectError">
                  <div *ngIf="f.fromDate.errors?.required || f.fromDate.invalid">Please enter valid From Date</div>
              </div>
              </div>

            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">To Date: <span class="mandatoryCls">*</span></label>
        <div class="input-group">
          <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                 name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="toMinDate" (dateSelect)="onToDateSelect($event)"
                 [maxDate]="maxDate" required formControlName="toDate"
                 [ngClass]="{ 'is-invalid':submitted && f.toDate.errors }">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
          </div>
        </div>
        <div *ngIf="submitted && f.toDate.errors" class="selectError">
          <div *ngIf="f.toDate.errors?.required || f.toDate.invalid">Please enter valid To Date</div>
      </div>
      </div>
        </div>
    </div>

    
      <div class="tabClass">
       
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getReports()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" (click)="downloadExcel()">Download Excel</button>
        </div>
      </div>
 <!-- </div>  -->
<!-- <div class="row"> -->
    <!-- <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12"> -->
<div class="table-responsive tableFixHead">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th [attr.colspan]="noOfCol">Application Wise Passbooks Related Service Status {{f.fromDate.value}} to  {{f.toDate.value}}</th>
            </tr>
            <tr>
                <th>S.No</th>
                <!-- <th rowspan="3" aria-controls="tableSortExample" scope="col" [mdbTableSort]="getData" [sortBy]="'districT_NAME'">District <mdb-icon fas icon="sort"></mdb-icon></th> -->
                <th>District</th>
                <th>Mandal</th>
                <th>Village</th>
                <th>Application Number</th>
                <th>Application Approved Date</th>
                <th>Passbook sent to Printers Date</th>
                <th>Passbook  Printed by Printers Date</th>
                <th>Passbook Dispatched by Printers Date</th>
                <th>Consignment Number/ Courier Ref Number</th>
            </tr>
        </thead>

        <tbody *ngIf="getData && getData.length > 0">
            <tr *ngFor="let data of getData | slice: (page-1) * pageSize : page * pageSize;let i = index;"> 
            <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
            <td>{{data.districT_NAME}}</td>
            <td>{{data.mandaL_NAME}}</td>
            <td>{{data.villagE_NAME}}</td>
            <td>{{data.applicatioN_NO}}</td>
            <td>{{data.approveD_DATE | date: 'dd/MM/yyyy'}}</td>
            <td>{{data.passbooK_SENT_DATE | date: 'dd/MM/yyyy'}}</td>
            <td>{{data.printeD_DATE | date: 'dd/MM/yyyy'}}</td>
            <td>{{data.dispatcheD_DATE | date: 'dd/MM/yyyy'}}</td>
            <td>{{data.consignmenT_NO}}</td>
            
        </tr>
        </tbody>
        <tbody *ngIf="getData && getData.length ==0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    </div>
    <div class="mt-4 btnHide">
        <div class="float-left" *ngIf="getData.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="getData.length > 0" [collectionSize]="getData.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
<!-- </div> -->
<!-- </div> -->
</div>

<!-- download Excel -->
<div class="table-responsive tableFixHead" #TABLE class="hideTable">
    <table class="tableCls" >
        <thead>
            <tr>
             <th [attr.colspan]="noOfCol">Application Wise Passbooks Related Service Status {{f.fromDate.value}} to  {{f.toDate.value}}</th>
             </tr>
            <tr>
                <th width="100px">S.No</th>
                <th width="100px">District</th>
                <th width="100px">Mandal</th>
                <th width="100px">Village</th>
                <th width="100px">Application Number</th>
                <th width="100px">Application Approved Date</th>
                <th width="100px">Passbook sent to Printers Date</th>
                <th width="100px">Passbook  Printed by Printers Date</th>
                <th width="100px">Passbook Dispatched by Printers Date</th>
                <th width="100px">Consignment Number/ Courier Ref Number</th>
            </tr>
        </thead>

        <tbody *ngIf="getData && getData.length > 0">
            <tr *ngFor="let data of getData;let i = index;">
                <td width="100px">{{i + 1}}</td>
                <td width="100px">{{data.districT_NAME}}</td>
                <td width="100px">{{data.mandaL_NAME}}</td>
                <td width="100px">{{data.villagE_NAME}}</td>
                <td width="100px">{{data.applicatioN_NO}}</td>
                <td width="100px">{{data.approveD_DATE | date: 'dd/MM/yyyy'}}</td>
                <td width="100px">{{data.passbooK_SENT_DATE | date: 'dd/MM/yyyy'}}</td>
                <td width="100px">{{data.printeD_DATE | date: 'dd/MM/yyyy'}}</td>
                <td width="100px">{{data.dispatcheD_DATE | date: 'dd/MM/yyyy'}}</td>
                <td width="100px">{{data.consignmenT_NO}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="getData && getData.length === 0">
            <tr>
             <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
         </tr>
        </tbody>
    </table>
 </div>