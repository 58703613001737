<ng-container *ngIf="requisitiontype == 'IntegratedCertificate-SuoMoto'"> 
    <div class="certificateInfo">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="subHeadingCls mb-3">Current Address from Caste Survey</div>   
                <div class="fieldHolder">
                    <label class="labelCls">District:</label>
                    <div class="valueCls">{{certificateInfo?.suoMotoCasteSurveyAddresses ? certificateInfo?.suoMotoCasteSurveyAddresses[0]?.district : "-"}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Mandal:</label>
                    <div class="valueCls">{{certificateInfo?.suoMotoCasteSurveyAddresses ? certificateInfo?.suoMotoCasteSurveyAddresses[0]?.mandal : "-" }}</div>
                </div>
                <div class="fieldHolder">
                <label class="labelCls">Secretariat Code:</label>
                <div class="valueCls">{{certificateInfo?.suoMotoCasteSurveyAddresses ? certificateInfo?.suoMotoCasteSurveyAddresses[0]?.secretariatCode: "-"}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Secretariat Name:</label>
                <div class="valueCls">{{certificateInfo?.suoMotoCasteSurveyAddresses ? certificateInfo?.suoMotoCasteSurveyAddresses[0]?.secretariatName: "-"}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Door No:</label>
                <div class="valueCls">{{certificateInfo?.suoMotoCasteSurveyAddresses ? certificateInfo?.suoMotoCasteSurveyAddresses[0]?.doorNo : "-"}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Pin Code:</label>
                <div class="valueCls">{{certificateInfo?.suoMotoCasteSurveyAddresses ? certificateInfo?.suoMotoCasteSurveyAddresses[0]?.pinCode : "-"}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mobile No:</label>
                <div class="valueCls">{{certificateInfo?.suoMotoCasteSurveyAddresses ? certificateInfo?.suoMotoCasteSurveyAddresses[0]?.mobileNo : "-"}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Current Address :</label>
                <div class="valueCls">{{certificateInfo?.suoMotoCasteSurveyAddresses ? certificateInfo?.suoMotoCasteSurveyAddresses[0]?.currentAddress : "-"}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Panchayat:</label>
                <div class="valueCls">{{certificateInfo?.suoMotoCasteSurveyAddresses ? certificateInfo?.suoMotoCasteSurveyAddresses[0]?.panchayat : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Ward:</label>
                <div class="valueCls">{{certificateInfo?.suoMotoCasteSurveyAddresses ? certificateInfo?.suoMotoCasteSurveyAddresses[0]?.ward: "-"}}</div>
            </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="subHeadingCls mb-3">Permanent Address from Caste Survey</div>   
                <div class="fieldHolder">
                    <label class="labelCls">District:</label>
                    <div class="valueCls">{{certificateInfo?.suoMotoCasteSurveyAddresses ? certificateInfo?.suoMotoCasteSurveyAddresses[1]?.district : "-"}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Mandal:</label>
                    <div class="valueCls">{{certificateInfo?.suoMotoCasteSurveyAddresses ? certificateInfo?.suoMotoCasteSurveyAddresses[1]?.mandal : "-"}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Panchayat:</label>
                    <div class="valueCls">{{certificateInfo?.suoMotoCasteSurveyAddresses ? certificateInfo?.suoMotoCasteSurveyAddresses[1]?.panchayat : "-"}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Door No:</label>
                    <div class="valueCls">{{certificateInfo?.suoMotoCasteSurveyAddresses ? certificateInfo?.suoMotoCasteSurveyAddresses[1]?.doorNo : "-"}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Street:</label>
                    <div class="valueCls">{{certificateInfo?.suoMotoCasteSurveyAddresses ? certificateInfo?.suoMotoCasteSurveyAddresses[1]?.street : "-"}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Pin Code:</label>
                    <div class="valueCls">{{certificateInfo?.suoMotoCasteSurveyAddresses ? certificateInfo?.suoMotoCasteSurveyAddresses[1]?.pinCode : "-"}}</div>
                </div>  
            </div>
        </div>
    </div>
</ng-container>
<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
            </div>           
                <div class="fieldHolder">
                    <label class="labelCls">Revenue Ward/Village:</label>
                    <div class="valueCls">{{certificateInfo.villageName ? (certificateInfo.villageName) : '-' }}</div>
                </div>
                          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Are you availing this service for the purpose of Grievance?:</label>
                <div class="valueCls">{{certificateInfo?.grievanceQestion ? (certificateInfo?.grievanceQestion) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Grievance Number:</label>
                <div class="valueCls">{{certificateInfo?.grievanceNumber ? (certificateInfo?.grievanceNumber) : '-' }}</div>
            </div>           
              
                          
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Caste Certificate</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Workflow Address Type:</label>
                <div class="valueCls">{{certificateInfo.workflowAddressType ? (certificateInfo.workflowAddressType == 'permanent' ? 'Permanent' : 'Present') : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Issued Caste Certificate in Past:</label>
                <div class="valueCls">{{certificateInfo.issuedCastCertificateInPast ? (certificateInfo.issuedCastCertificateInPast) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Caste Claimed:</label>
                <div class="valueCls">{{certificateInfo.castClaimed ? (certificateInfo.castClaimed) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Education Certificate Contains Caste:</label>
            <div class="valueCls">{{certificateInfo.doesEducationCerficateContainCast? 'Yes' : 'No'}}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Purpose of Caste Certificate:</label>
            <div class="valueCls">{{certificateInfo.purposeOfCertificate ? (certificateInfo.purposeOfCertificate) : '-' }}</div>
        </div>
        </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3" *ngIf="(requisitiontype !== 'IntegratedCertificate-SuoMoto')">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Caste Certificate'|| file.documentType?.trim() === 'CC_FM_PDF'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Caste Certificate issued to the family members:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'SSC Marks'|| file.documentType?.trim() === 'CC_SSC_PDF'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">SSC marks memo or DOB extract or Transfer Certificate:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'DOB Certificates'|| file.documentType?.trim() === 'CC_SC_PDF'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">1 to 10th Study Certificate or DOB certificate issued by Municipality/Gram Panchayath:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="((file.documentType === 'Ration Card Certificate' || file.documentType?.trim() === 'CC_AC_PDF') && (jobtitle === 'Tahsildar' || jobtitle === 'Village Revenue Officer' || jobtitle === 'Ward Revenue Secretary' || jobtitle === 'Revenue Inspector' || jobtitle === 'Revenue Divisonal Officer' || jobtitle == 'Digital Assistant' || jobtitle === 'Collector'))">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Ration Card/EPIC Card/AADHAR Card:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                
                <div *ngIf="file.documentType === 'Schedule' || file.documentType?.trim() === 'CC_S14_PDF'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Schedule I to IV:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
            <ng-container *ngIf="fileList && fileList.length != 0">
                <div class="fieldHolder" *ngFor="let file of getOrderFiles(); let i=index">
                    <div *ngIf="((file.documentType === 'sumotoverificationDoc') && (jobtitle === 'Tahsildar' || jobtitle === 'Revenue Inspector' || jobtitle === 'Revenue Divisonal Officer' || jobtitle === 'Collector'))">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Ration Card/EPIC Card/AADHAR Card by VRO/WRS:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="(file.documentType === 'sumotoverificationDocforRI' && (jobtitle === 'Tahsildar' || jobtitle === 'Revenue Divisonal Officer' || jobtitle === 'Collector'))">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Ration Card/EPIC Card/AADHAR Card by Revenue Inspector:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="(file.documentType === 'sumotoverificationDocforTahsildar' && (jobtitle === 'Revenue Divisonal Officer' || jobtitle === 'Collector'))">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Ration Card/EPIC Card/AADHAR Card by Tahsildar:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class="certificateInfo" *ngIf="(jobtitle === 'Tahsildar' || jobtitle === 'Revenue Inspector' || jobtitle === 'Revenue Divisonal Officer' || jobtitle === 'Collector') && requisitiontype == 'IntegratedCertificate-SuoMoto'">
    <div class="subHeadingCls mb-3">Suo Moto Integrated Certificate Verification Details By VRO/WRS</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Caste: </label>
                <div class="valueCls">{{ (certificateInfo?.suoMotoApproverData?.vroData?.caste) ? (certificateInfo?.suoMotoApproverData?.vroData?.caste) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Sub Caste: </label>
                <div class="valueCls">{{ (certificateInfo?.suoMotoApproverData?.vroData?.subcaste) ? (certificateInfo?.suoMotoApproverData?.vroData?.subcaste) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Revenue Village: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.vroData?.revenuevillage) ? (certificateInfo?.suoMotoApproverData?.vroData?.revenuevillage) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Marital Status: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.vroData?.maritalStatus) ? (certificateInfo?.suoMotoApproverData?.vroData?.maritalStatus) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Email: </label>
                <div class="valueCls">{{certificateInfo?.suoMotoApproverData?.vroData?.email ? certificateInfo?.suoMotoApproverData?.vroData?.email : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Status of the Head of House Hold: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.vroData?.headOfHouseHold === 'Deceasedvro') ? 'Deceased' :  ((certificateInfo?.suoMotoApproverData?.vroData?.headOfHouseHold === 'Alivevro') ? 'Alive' : '-') }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Mobile: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.vroData?.mobilenumber) ? (certificateInfo?.suoMotoApproverData?.vroData?.mobilenumber) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Father/Husband Name: </label>
                <div class="valueCls">{{ (certificateInfo?.suoMotoApproverData?.vroData?.relationName) ? (certificateInfo?.suoMotoApproverData?.vroData?.relationName) : "-"}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Religion: </label>
                <div class="valueCls">{{ (certificateInfo?.suoMotoApproverData?.vroData?.religion) ? (certificateInfo?.suoMotoApproverData?.vroData?.religion) : "-"}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Qualification: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.vroData?.qualification) ? (certificateInfo?.suoMotoApproverData?.vroData?.qualification) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Date Of Birth: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.vroData?.birthDate) ? (certificateInfo?.suoMotoApproverData?.vroData?.birthDate | date: 'dd/MM/yyyy') : "-" }}</div>
            </div>
        </div>
    </div>
</div>
<!--Ri Data and VRO/WRS data want to be shown in this vro/wrs data already been shown -->
<div class="certificateInfo" *ngIf="(jobtitle === 'Tahsildar' || jobtitle === 'Revenue Divisonal Officer' || jobtitle === 'Collector') && requisitiontype == 'IntegratedCertificate-SuoMoto'">
    <div class="subHeadingCls mb-3">Suo Moto Integrated Certificate Verification Details By Revenue Inspector</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Caste: </label>
                <div class="valueCls">{{ (certificateInfo?.suoMotoApproverData?.riData?.caste) ? (certificateInfo?.suoMotoApproverData?.riData?.caste) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Sub Caste: </label>
                <div class="valueCls">{{ (certificateInfo?.suoMotoApproverData?.riData?.subcaste) ? (certificateInfo?.suoMotoApproverData?.riData?.subcaste) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Revenue Village: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.riData?.revenuevillage) ? (certificateInfo?.suoMotoApproverData?.riData?.revenuevillage) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Marital Status: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.riData?.maritalStatus) ? (certificateInfo?.suoMotoApproverData?.riData?.maritalStatus) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Email: </label>
                <div class="valueCls">{{certificateInfo?.suoMotoApproverData?.riData?.email ? certificateInfo?.suoMotoApproverData?.riData?.email : "-"}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Status of the Head of House Hold: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.riData?.headOfHouseHold === 'Deceasedri') ? 'Deceased' : ((certificateInfo?.suoMotoApproverData?.riData?.headOfHouseHold === 'Aliveri') ? 'Alive' : '-') }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Mobile: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.riData?.mobilenumber) ? (certificateInfo?.suoMotoApproverData?.riData?.mobilenumber) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Father/Husband Name: </label>
                <div class="valueCls">{{ (certificateInfo?.suoMotoApproverData?.riData?.relationName) ? (certificateInfo?.suoMotoApproverData?.riData?.relationName) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Religion: </label>
                <div class="valueCls">{{ (certificateInfo?.suoMotoApproverData?.riData?.religion) ? (certificateInfo?.suoMotoApproverData?.riData?.religion) : "-"}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Qualification: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.riData?.qualification) ? (certificateInfo?.suoMotoApproverData?.riData?.qualification) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Date Of Birth: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.riData?.birthDate) ? (certificateInfo?.suoMotoApproverData?.riData?.birthDate | date: 'dd/MM/yyyy') : "-" }}</div>
            </div>

        </div>
    </div>
</div>

<div class="certificateInfo" *ngIf="(jobtitle === 'Revenue Divisonal Officer' || jobtitle === 'Collector') && requisitiontype == 'IntegratedCertificate-SuoMoto'">
    <div class="subHeadingCls mb-3">Suo Moto Integrated Certificate Verification Details By Tahsildar</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Caste: </label>
                <div class="valueCls">{{ (certificateInfo?.suoMotoApproverData?.tehData?.caste) ? (certificateInfo?.suoMotoApproverData?.tehData?.caste) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Sub Caste: </label>
                <div class="valueCls">{{ (certificateInfo?.suoMotoApproverData?.tehData?.subcaste) ? (certificateInfo?.suoMotoApproverData?.tehData?.subcaste) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Revenue Village: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.tehData?.revenuevillage) ? (certificateInfo?.suoMotoApproverData?.tehData?.revenuevillage) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Marital Status: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.tehData?.maritalStatus) ? (certificateInfo?.suoMotoApproverData?.tehData?.maritalStatus) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Email: </label>
                <div class="valueCls">{{certificateInfo?.suoMotoApproverData?.tehData?.email ? certificateInfo?.suoMotoApproverData?.tehData?.email : "-"}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Status of the Head of House Hold: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.tehData?.headOfHouseHold === 'Deceasedteh') ? 'Deceased' : (certificateInfo?.suoMotoApproverData?.tehData?.headOfHouseHold === 'Aliveteh' ? 'Alive' : '-') }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Mobile: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.tehData?.mobilenumber) ? (certificateInfo?.suoMotoApproverData?.tehData?.mobilenumber) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Father/Husband Name: </label>
                <div class="valueCls">{{ (certificateInfo?.suoMotoApproverData?.tehData?.relationName) ? (certificateInfo?.suoMotoApproverData?.tehData?.relationName) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Religion: </label>
                <div class="valueCls">{{ (certificateInfo?.suoMotoApproverData?.tehData?.religion) ? (certificateInfo?.suoMotoApproverData?.tehData?.religion) : "-"}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Qualification: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.tehData?.qualification) ? (certificateInfo?.suoMotoApproverData?.tehData?.qualification) : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Date Of Birth: </label>
                <div class="valueCls">{{(certificateInfo?.suoMotoApproverData?.tehData?.birthDate) ? (certificateInfo?.suoMotoApproverData?.tehData?.birthDate | date: 'dd/MM/yyyy') : "-" }}</div>
            </div>
        </div>
    </div>
</div>
