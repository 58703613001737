<button class="float-right greenBtnCls homebtn mt-3" routerLink="/home">Home</button>
<div class="container-fluid mainCls">
    <div class="mainPageContainer mr-2">
        <h5 style="margin-bottom: 20px;">Pending Villages To Approve</h5>

    <div class="row mt-2">
        <div class="col">
            <div class="tab">
                <form [formGroup]="villagemappingteh">
                    <table class="tableCls" > 
                        <thead>
                            <tr>
                                <th class="ml-3"></th>
                                <th>S.No</th>
                                <th>District Name</th>
                                <th>Division Name</th>
                                <th>Mandal Name</th>
                                <th>Village Name</th>
                                <th>Village Id</th>
                                <th>Secretariat Name</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="saveresponseData?.length > 0">
                            <tr *ngFor="let item of saveresponseData; let i=index">
                               <td>
                                <input type="checkbox" formControlName="villageids" [checked]="item.checked" name="villageids" id= {{item.rV_VID}} (click)="onCheckClick($event)" (change)="updateSelectedCheckboxes(item.rV_VID)" [(ngModel)]="item.isSelected" [ngModelOptions]="{standalone: true}"/>
                               </td>
                               <td>{{i+1}}</td>
                               <td>
                                <span>{{item.rV_DISTRICT_NAME}}</span>
                               </td>
                               <td>
                                <span>{{item.rV_DVNAME}}</span>
                               </td>
                               <td>
                                <span>{{item.rV_MNAME}}</span>
                               </td>
                               <td>
                                <span>{{item.rV_VNAME}}</span>
                               </td>
                               <td>
                                <span>{{item.rV_VID}}</span>
                               </td>
                               <td>
                                <span>{{item.secretariaT_NAME}}</span>
                               </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="saveresponseData?.length == 0">
                            <tr >
                               <td colspan="8" style="text-align: center;">
                                    No Villages are there to approve.
                               </td>
                            </tr>
                        </tbody>
                    </table>  
                </form>
    </div>
    </div>
 </div>
 <div class = "row mb-5 m-auto" *ngIf="saveresponseData?.length > 0">
        <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12 btnHolder">
        <button class="greenBtnCls float-left my-3" (click)="mroApproved()">Approve</button>
    </div>
    <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12 btnHolder">
        <button class="greenBtnCls float-left my-3" (click)="deleteRecord()">Delete</button>
    </div>
</div>
<h5 style="margin-top: 20px;">Approved Villages</h5>
    <div class="row mt-3">
        <div class="col">
            <div class="tab">
        <table class="tableCls"> 
            <thead>
                <tr>
                    <th>S.No</th>
                    <th>District Name</th>
                    <th>Division Name</th>
                    <th>Mandal Name</th>
                    <th>Village Name</th>
                    <th>Village Id</th>
                    <th>Secretariat Name</th>
                </tr>
            </thead>
            <tbody *ngIf="approvedRecordsData?.length > 0">
                <tr *ngFor="let item of approvedRecordsData; let i=index">
                   <td>{{i+1}}</td>
                   <td>
                    <span>{{item.rV_DISTRICT_NAME}}</span>
                   </td>
                   <td>
                    <span>{{item.rV_DVNAME}}</span>
                   </td>
                   <td>
                    <span>{{item.rV_MNAME}}</span>
                   </td>
                   <td>
                    <span>{{item.rV_VNAME}}</span>
                   </td>
                   <td>
                    <span>{{item.rV_VID}}</span>
                   </td>
                   <td>
                    <span>{{item.secretariaT_NAME}}</span>
                   </td>
                </tr>
            </tbody>
            <tbody *ngIf="approvedRecordsData?.length == 0">
                <tr>
                    <td colspan="8" style="text-align: center;">
                        No Village is approved.
                   </td>
                </tr>
            </tbody>
        </table> 
        </div>
        </div> 
</div>
</div>
</div>
