import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-nonavailability-birth-view',
  templateUrl: './nonavailability-birth-view.component.html',
  styleUrls: ['./nonavailability-birth-view.component.css']
})
export class NonavailabilityBirthViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  screenMandal: any;

  constructor(private commonService: CommonService) {
  }
  commonConstants : any = CommonConstants;

  ngOnInit(): void {
  }
  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

  getById(id: any) {
    this.commonService.getRequest(this.commonConstants.getById + id).subscribe({
      next: (responseData: any) => { 
        let name =  responseData.result[0]?.name;
        this.screenMandal = name;
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
}
