<div class="row fixed-header">
    <div class="col col-lg-12">
        <div class="headerCls">
            <div class="headerLogo">
            <img src="../../assets/home-images/APSevaPortal-bkp1.png" alt="logo" class="img-fluid">
            <!-- <div class="logo-text"><span class="english-text">AP </span><span class="telugu-text">  సేవ పోర్టల్‌</span></div> -->
            </div>
            <div class="profileCls">
                <!-- <div class="profileLogo"></div> -->
                <!-- <div class="profileName">
                    <span>Sri YS Jagan Mohan Reddy</span>
                    <span class="profileTitle">Hon'ble Chief Minister of Andhra Pradesh</span>
                </div> -->
            </div>
        </div>
        
    </div>
</div>



