import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from '../../shared/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import {
  fileExtensionValidator,
  fileSizeValidatorforPAN
} from 'src/shared/validators/file-validator';
@Component({
  selector: 'app-pan-details-collection',
  templateUrl: './pan-details-collection.component.html',
  styleUrls: ['./pan-details-collection.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
 
  ]
})
export class PanDetailsCollectionComponent implements OnInit {
  panCard: FormGroup;
  public submitted:Boolean=false;
  panDetails:any={};
  commonConstants : any = CommonConstants;
  maxDate: any;
  minDate: any;
  secreteriateCode:any;
  currentDate = new Date();
  @ViewChild('pancardLabel')
  pancardLabel!: ElementRef;
  fileList: { FileName: string; DocumentType: string; BlobRef: string }[] = [];
  isAvailable:Boolean=false;
  role:any;
  isSaved :Boolean=false;
  constructor(private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private datePipe: DatePipe) {
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.panCard = this.formBuilder.group({
      panNumber: ['',[Validators.required,Validators.minLength(10),Validators.pattern(this.commonConstants.panNumber)]],
      enterPanNumber: ['',[Validators.required,Validators.minLength(10),Validators.pattern(this.commonConstants.panNumber)]],
      name:['',[Validators.required]],
      date:['',[Validators.required]],
      linkedPan:[null,[Validators.required]],
      pancard:['',[Validators.required]]
   });
    
  

   }
  
 
  ngOnInit(): void {
    this.secreteriateCode =localStorage.getItem('locationCode');
    this.getPanDetails();
    let userInfo = localStorage.getItem('userInfo');
  if (userInfo && userInfo !== null) {
    let userData = JSON.parse(userInfo);
    this.role = userData.jobTitle;
  }    

  }
  get f() {
    return this.panCard.controls;
  }
  getPanDetails(){
      this.panDetails={};
      this.f.panNumber.patchValue('');     
      this.commonService.getRequest(this.commonConstants.getPanDetails + '?SecretariatCode='+ this.secreteriateCode ).subscribe({
        next: (responseData: any) => {
          if(typeof(responseData.result) !== 'string') {            
            this.panDetails = responseData.result;
            if(this.panDetails){
              this.isAvailable = true;
              this.f.panNumber.patchValue(this.commonService.RSADecrypt(this.panDetails.panCardNo));
              this.f.enterPanNumber.patchValue(this.commonService.RSADecrypt(this.panDetails.panCardNo));
              this.f.name.patchValue(this.panDetails.panName);
              let panDate= this.panDetails?.panCardDate.includes('T') ? this.datePipe.transform((this.panDetails?.panCardDate)?.split('T')[0],'dd-MM-yyyy') : this.panDetails?.panCardDate
              this.f.date.patchValue(panDate);
              this.f.linkedPan.patchValue(this.panDetails.eServicePensionAccFlag); 
              //this.f.pancard.patchValue(this.panDetails.panBlobReference);
              this.pancardLabel.nativeElement.innerText = this.panDetails.panBlobReference? 'PanCard.pdf' : 'No File Choosen';
              if(this.panDetails.panBlobReference) {
                this.panCard.controls['pancard'].clearValidators()
                this.panCard.controls['pancard'].updateValueAndValidity();
              }            
             
            
            }
           } else {
           
            this.isAvailable = false;
            if(responseData.result !== 'No data available.'){
              this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
            }
            
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    
  }
  downloadFile(){
   
      this.commonService.getRequest(this.commonConstants.fileDownload + this.panDetails?.panBlobReference).subscribe({
        next: (responseData: any) => { 
            this.commonService.downloadPdfFile(responseData.result.fileContents, 'PanCard.pdf')
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    
  }
  savePanDetails(){
    if(this.panCard.valid){
      this.submitted= false;
      let postData :any = {};
      if(this.f.enterPanNumber.value != this.f.panNumber.value){
        this.commonService.commonErrorPopup('Alert', 'Secretariat PAN number and Re-entering of Secretariat PAN number should be same', 'md');
        return 
      }
      postData={
        "secretariatCode": this.secreteriateCode,
        "panCardNo":this.commonService.RSAEncrypt(this.f.enterPanNumber.value),
        "panName": this.f.name.value,
        "panCardDate": this.commonService.formatDate(this.f.date.value),
        "eServicePensionAccFlag": this.f.linkedPan.value,
        "panBlobReference": this.fileList[0]?.BlobRef 
      }
      this.commonService.postRequest(this.commonConstants.savePanDetails, postData).subscribe({
        next: (responseData: any) => {        
          this.commonService.commonErrorPopup('Alert',responseData.result ,'md');  
          if(responseData.result === 'Details Saved successfully'){            
            this.getPanDetails();
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });

    }else{
      this.submitted= true;
    }

  }

  updatePanDetails(){
    if(this.panCard.valid){
      this.submitted= false;
      let postData :any = {};
      if(this.f.enterPanNumber.value != this.f.panNumber.value){
        this.commonService.commonErrorPopup('Alert', 'Secretariat PAN number and Re-entering of Secretariat PAN number should be same', 'md');
        return 
      }
      postData={
        "secretariatCode": this.secreteriateCode,
        "panCardNo":this.commonService.RSAEncrypt(this.f.enterPanNumber.value),
        "panName": this.f.name.value,
        "panCardDate": this.commonService.formatDate(this.f.date.value),
        "eServicePensionAccFlag": this.f.linkedPan.value,
        "panBlobReference": this.fileList.length !=0 ? this.fileList[0]?.BlobRef : this.panDetails.panBlobReference
      }
      this.commonService.postRequest(this.commonConstants.updatePanDetails, postData).subscribe({
        next: (responseData: any) => {     
          this.commonService.commonErrorPopup('Alert',responseData.result ,'md'); 
          if(responseData.result === 'Details Updated successfully'){            
            this.getPanDetails();
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });

    }else{
      this.submitted= true;
    }

  }

 
  alphaNumberOnly (e:any) {  // Accept only alpha numerics, not special characters 
    var regex = new RegExp("^([A-Z]){5}([0-9]){4}([A-Z]){1}$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }else{
      e.preventDefault();
      return false;
    }
    
  }
  onKeyUp(e:any){
  this.f.panNumber.patchValue(e.target.value.toUpperCase())
 }
 onKeyUpPAN(e:any){
  this.f.enterPanNumber.patchValue(e.target.value.toUpperCase())
 }
  onPanSelected(fileEvent: any) {
    const appFormFile: File | null = fileEvent.target.files[0];
    this.pancardLabel.nativeElement.innerText =
      fileEvent.target.files.length !== 0
        ? fileEvent.target.files[0]?.name
        : 'No File Choosen';
    this.panCard.controls['pancard'].setValidators([
      Validators.required,
      fileExtensionValidator('pdf'),
      fileSizeValidatorforPAN(fileEvent),
    ]);
    this.panCard.controls['pancard'].updateValueAndValidity();
    if (this.f.pancard.valid)
      this.fileUpload(
        appFormFile,
        this.f['pancard'].value,
        fileEvent.target.files[0].name,
        'Application Form', 'pancard', 'pancardLabel'
      );
  }

  fileUpload(
    file: any,
    filePath: string,
    fileName: string,
    documentType: string,
    name:string,
    label:string
  ) {
    const formData = new FormData();
    formData.append('File', file, fileName);
    formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
    console.log(formData, 'formData');
    this.commonService
      .postUploadedFileRequest(this.commonConstants.fileUpload, formData)
      .subscribe({
        next: (responseData: any) => {
          if(typeof(responseData.result) !== 'string'){
          let index = this.fileList
            .map((item) => item.DocumentType)
            .indexOf(documentType);
          if (index > -1) {
            this.fileList.splice(index, 1);
          }
          this.fileList.push({
            FileName: fileName,
            DocumentType: documentType,
            BlobRef: responseData['result'].blobRef,
          });
        } else {
          if(name && label) {
            this.clearFileUpload(name, label);
          }
          this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
        }
        },
        error: (error) => {
          if(name && label) {
            this.clearFileUpload(name, label);
          }
          console.log('client side', error);
        },
      });
  }
  clearFileUpload(name: string, label: string) {
    this.f[name].reset();
    if (label === 'pancardLabel') {
      this.pancardLabel.nativeElement.innerText = 'No File Choosen';
    } 
  }
  goToHome() {
    this.router.navigate(['/home']);
  }
}
