<div class="certificateInfo" *ngIf="certificateInfo">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Aadhar No: </label>
                <div class="valueCls">{{certificateInfo.applicantAadharNo}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Applicant Name: </label>
                <div class="valueCls">{{certificateInfo.applicantName}}</div>
            </div>
        <div class="fieldHolder">
            <label class="labelCls">Relation With Pattadar:</label>
            <div class="valueCls">{{certificateInfo.relationWithOwner}}</div>
        </div>
        </div>
    </div>
    <div class="subHeadingCls mb-3">Land Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo.landDetails?.districtName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo.landDetails?.mandalMunicipalityName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Village:</label>
            <div class="valueCls">{{certificateInfo.landDetails?.villageWardName}}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Land Parcel Numbers:</label>
            <div class="valueCls">{{certificateInfo.landParcelNo}}</div>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Secretariat:</label>
                <div class="valueCls">{{certificateInfo.secretariatName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Pattadar Name:</label>
                <div class="valueCls">{{certificateInfo.pattadarName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Khata Number:</label>
                <div class="valueCls">{{certificateInfo.khataNo}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Seeding Type:</label>
                <div class="valueCls">{{certificateInfo.seedingType === 'new' ? 'New Aadhar Seeding' : 'De-Seed Existing Aadhar and Seed New Aadhar'}}</div>
            </div>
        </div>
    </div>
    <div class="subHeadingCls mb-3">Pattadar Land Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Pattadar Name:</label>
                <div class="valueCls">{{certificateInfo.existPattadarDetails?.pattadarName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Pattadar Father Name:</label>
                <div class="valueCls">{{certificateInfo.existPattadarDetails?.pattadarFatherName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Khata Number:</label>
                <div class="valueCls">{{certificateInfo.khataNo}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">LP Number:</label>
                <div class="valueCls">{{certificateInfo.landParcelNo}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Aadhar Number seeded as per Webland:</label>
                <div class="valueCls">{{certificateInfo.existPattadarDetails?.aadharNumber}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mobile Number seeded as per Webland:</label>
                <div class="valueCls">{{certificateInfo.existPattadarDetails?.mobileNumber}}</div>
            </div>
        </div>
    </div>
    <div class="subHeadingCls mb-3">Aadhar Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Name:</label>
                <div class="valueCls">{{certificateInfo.requestedDetails?.pattadarName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Father/Husband Name:</label>
                <div class="valueCls">{{certificateInfo.requestedDetails?.pattadarFatherName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Name (In English):</label>
                <div class="valueCls">{{certificateInfo.requestedDetails?.pattadarNameInEng}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Father/Husband Name (In English):</label>
                <div class="valueCls">{{certificateInfo.requestedDetails?.pattadarFatherNameInEng}}</div>
            </div>
        </div>
    </div>
    <div class="subHeadingCls mb-3">Data to be Seeded</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Aadhar Number:</label>
                <div class="valueCls">{{certificateInfo.requestedDetails?.aadharNumber}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mobile Number Linked to Aadhar Number:</label>
                <div class="valueCls">{{certificateInfo.requestedDetails?.mobileNumber}}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo mt-2 mb-3">
    <div class="row">
      <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <ng-container *ngIf="fileList && fileList?.length !== 0">
          <div class="subHeadingCls mb-3">List of Documents</div>
          <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
            <div *ngIf="file.documentType === 'Aadhar Document'">
              <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Aadhar Document:</label>
              <div class="valueCls">
                <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                  class="download ml-2"></span>
              </div>
            </div>
            <div *ngIf="file.documentType === 'photo'">
                <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Photo:</label>
                <div class="valueCls">
                  <a (click)="viewImgFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                    class="download ml-2"></span>
                </div>
              </div>
            <div *ngIf="file.documentType === 'Other Docs'">
              <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Other Documents if any:</label>
              <div class="valueCls">
                <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                  class="download ml-2"></span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>