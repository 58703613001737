<div class="modal-header" >
    <h6 class="modal-title">Account Details</h6>
    
  </div>
  <div class="modal-body">
    <form [formGroup]="accountForm">
        <div class="form-group row">
            <label class="col-sm-5 labelCls text-right">Name of the District : <span class="mandatoryCls">*</span></label>
            <div class="col-sm-7">
                <label class="labelCls" *ngIf="isEditable">{{officerData.district}}</label>
                <label class="labelCls" *ngIf="!isEditable">{{savedData?.districtName}}</label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-5 labelCls text-right">Name of the Mandal / Revenue Divisions : <span class="mandatoryCls">*</span></label>
            <div class="col-sm-7">
                <label class="labelCls" *ngIf="isEditable">{{officerData.mandal}}</label>
                <label class="labelCls" *ngIf="!isEditable">{{savedData?.mandalOrRevDivisionName}}</label>
            </div>
          </div>  
        <div class="form-group row">
            <label class="col-sm-5 labelCls text-right">Designation : <span class="mandatoryCls">*</span></label>
            <div class="col-sm-7">
                <label class="labelCls" *ngIf="isEditable">{{officerData.designation}}</label>
                <label class="labelCls" *ngIf="!isEditable">{{savedData?.designation}}</label>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-5 labelCls text-right">Name of the Designated person : <span class="mandatoryCls">*</span></label>
            <div class="col-sm-7">
                <label class="labelCls" *ngIf="isEditable">{{officerData.name}}</label>
                <label class="labelCls" *ngIf="!isEditable">{{savedData?.designatedPersonName}}</label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-5 labelCls text-right">Aadhaar Number :</label>
            <div class="col-sm-7">
                <label class="labelCls" *ngIf="isEditable">{{officerData.aadharNo}}</label>
                <label class="labelCls" *ngIf="!isEditable">{{(savedData?.aadharNumber)? (savedData?.aadharNumber) : '-'}}</label>
            </div>
          </div>  
        <div class="form-group row">
            <label class="col-sm-5 labelCls text-right">Contact Number  : </label>
            <div class="col-sm-7">
                <label class="labelCls" *ngIf="isEditable">{{officerData.mobileNo? officerData.mobileNo: '-' }}</label>
                <label class="labelCls" *ngIf="!isEditable">{{(savedData?.contactNumber)? savedData?.contactNumber: '-' }}</label>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-5 labelCls text-right">Official Account Name  : <span class="mandatoryCls">*</span></label>
            <div class="col-sm-7" *ngIf="isEditable">
                <input type="text" name="acName" formControlName="acName" class="inputCls" appBlockCopyPaste required maxlength="50"
                [ngClass]="{ 'is-invalid':submitted && f.acName.errors }">
                <div *ngIf="submitted && f.acName.errors" class="invalid-feedback">
                    <div *ngIf="f.acName.errors.required || f.acName.errors.whiteSpaceValidate">Account Name is required</div>
                    <div *ngIf="f.acName.errors?.pattern">Please enter valid Official Account Name</div>
                </div>
            </div>
            <div class="col-sm-7" *ngIf="!isEditable">
                <label class="labelCls">{{savedData?.officialAccountName}}</label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-5 labelCls text-right">A/c. No. : <span class="mandatoryCls">*</span></label>
            <div class="col-sm-7" *ngIf="isEditable">
                <input type="text" name="acNo" formControlName="acNo" class="inputCls" appBlockCopyPaste required maxlength="50"
                [ngClass]="{ 'is-invalid':submitted && f.acNo.errors }">
                <div *ngIf="submitted && f.acNo.errors" class="invalid-feedback">
                    <div *ngIf="f.acNo.errors.required || f.acNo.errors.whiteSpaceValidate">A/c. No. is required</div>
                    <div *ngIf="f.acNo.errors?.pattern">Please enter valid A/c. No.</div>
                </div>
            </div>
            <div class="col-sm-7" *ngIf="!isEditable">
                <label class="labelCls">{{savedData?.accountNumber}}</label>
            </div>
          </div>  
        <div class="form-group row">
            <label class="col-sm-5 labelCls text-right">Name of the Bank & Branch : <span class="mandatoryCls">*</span></label>
            <div class="col-sm-7" *ngIf="isEditable">
                <input type="text" name="bankName" formControlName="bankName" class="inputCls" appBlockCopyPaste required maxlength="200"
                [ngClass]="{ 'is-invalid':submitted && f.bankName.errors }">
                <div *ngIf="submitted && f.bankName.errors" class="invalid-feedback">
                    <div *ngIf="f.bankName.errors.required || f.bankName.errors.whiteSpaceValidate">Name of the Bank & Branch is required</div>
                    <div *ngIf="f.bankName.errors?.pattern">Please enter valid Name of the Bank & Branch</div>
                </div>
            </div>
            <div class="col-sm-7" *ngIf="!isEditable">
                <label class="labelCls">{{savedData?.bankName}}</label>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-5 labelCls text-right">IFSC code : <span class="mandatoryCls">*</span></label>
            <div class="col-sm-7" *ngIf="isEditable">
                <input type="text" name="ifscCode" formControlName="ifscCode" class="inputCls" appBlockCopyPaste required maxlength="200"
                [ngClass]="{ 'is-invalid':submitted && f.ifscCode.errors }">
                <div *ngIf="submitted && f.ifscCode.errors" class="invalid-feedback">
                    <div *ngIf="f.ifscCode.errors.required || f.ifscCode.errors.whiteSpaceValidate">Name of the Bank & Branch is required</div>
                </div>
            </div>
            <div class="col-sm-7" *ngIf="!isEditable">
                <label class="labelCls">{{savedData?.ifscCode}}</label>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-5 labelCls text-right">Passbook Front page picture : <span class="mandatoryCls">*</span></label>
            <div class="col-sm-7" *ngIf="isEditable">
                <div class="input-group mb-3">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" formControlName="passbook" id="passbook" accept=".jpg,.jpeg"
                            required [ngClass]="{ 'is-invalid':submitted && f.passbook.errors }" (change)="onPassbookSelected($event)">
                        <label class="custom-file-label" #passbookLabel for="passbook"><i class="fas fa-search"></i>No File choosen</label>
                    </div>
                    <div class="clearCls" (click)="clearFileUpload('passbook', 'passbookLabel')" *ngIf="f.passbook.value"></div>
                </div>
                <div *ngIf="submitted && f.passbook.errors" class="selectError">
                    <div *ngIf="f.passbook.errors.required">Please upload Passbook Front page picture (Upload Only jpg/jpeg format)</div>
                    <div *ngIf="f.passbook.errors?.inValidExt && !f.passbook.errors.required ">Please upload the file in JPG/JPEG Format</div>
                    <div *ngIf="f.passbook.errors?.inValidSize && !f.passbook.errors.required && !f.passbook.errors.inValidExt">File size exceeding 1MB</div>
                </div>
            </div>
            <div class="col-sm-7" *ngIf="!isEditable">
                <label class="labelCls"> <img [src]="imgSrc" class="passbookCls"></label>
            </div>
          </div>  
      </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="greenBtnCls" (click)="activeModal.close('Close click')">Close</button>
    <button type="button" class="greenBtnCls" (click)="saveDetails()" *ngIf="isEditable">Save</button>
  </div>