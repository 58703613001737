<div *ngIf="certificateInfo">

    <div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
        <div class="subHeadingCls mb-3">Applicant Details</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Caste</label>
                    <div class="valueCls">{{certificateInfo?.casteCategory ? (certificateInfo?.casteCategory) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Employment Status:</label>
                    <div class="valueCls">{{certificateInfo?.employmentStatusName ? (certificateInfo?.employmentStatusName) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Highest Education:</label>
                    <div class="valueCls">{{certificateInfo?.highestEducationName ? (certificateInfo?.highestEducationName) : '-' }}</div>
                </div>
            </div>
        <!--</div>
        <div class="row"> -->
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Primary Lang:</label>
                    <div class="valueCls">{{certificateInfo?.primaryLangName ? (certificateInfo?.primaryLangName) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Physically Dsiabled:</label>
                    <div class="valueCls">{{certificateInfo?.physicallyDsiabled ? (certificateInfo?.physicallyDsiabled) : '-' }}</div>
                </div>
            </div>
            
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Is Ex Serviceman?</label>
                    <div class="valueCls">{{certificateInfo?.isExServiceman ? 'YES' : 'NO' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Is Govt Employee?</label>
                    <div class="valueCls">{{certificateInfo?.isGovtEmployee ? 'YES' : 'NO' }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
        <div class="subHeadingCls mb-3">Applicant Education History</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Board/University</label>
                    <div class="valueCls">{{certificateInfo?.educationHistoryList[0].boardUniversityName ? (certificateInfo?.educationHistoryList[0].boardUniversityName) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Course:</label>
                    <div class="valueCls">{{certificateInfo?.educationHistoryList[0].courseNature ? (certificateInfo?.educationHistoryList[0].courseNature) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Education:</label>
                    <div class="valueCls">{{certificateInfo?.educationHistoryList[0].educationName ? (certificateInfo?.educationHistoryList[0].educationName) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Institute:</label>
                    <div class="valueCls">{{certificateInfo?.educationHistoryList[0].institute ? (certificateInfo?.educationHistoryList[0].institute) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Education Medium:</label>
                    <div class="valueCls">{{certificateInfo?.educationHistoryList[0].mediumEducationName ? (certificateInfo?.educationHistoryList[0].mediumEducationName) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Grade/Percentage:</label>
                    <div class="valueCls">{{certificateInfo?.educationHistoryList[0].grade ? (certificateInfo?.educationHistoryList[0].grade) : '-' }}</div>
                </div>
            </div>
        <!--</div>
        <div class="row"> -->
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Entry Year:</label>
                    <div class="valueCls">{{certificateInfo?.educationHistoryList[0].entryYear ? (certificateInfo?.educationHistoryList[0].entryYear) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Entry Month:</label>
                    <div class="valueCls">{{this.entryMonth ? (this.entryMonth) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Passed Year:</label>
                    <div class="valueCls">{{certificateInfo?.educationHistoryList[0].passedYear ? (certificateInfo?.educationHistoryList[0].passedYear) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Passed Month:</label>
                    <div class="valueCls">{{this.passedMonth ? (this.passedMonth) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Specialization:</label>
                    <div class="valueCls">{{certificateInfo?.educationHistoryList[0].specialization ? (certificateInfo?.educationHistoryList[0].specialization) : '-' }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
        <div class="subHeadingCls mb-3">Employment History</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Designation:</label>
                    <div class="valueCls">{{certificateInfo?.employmentHistoryList[0].designation ? (certificateInfo?.employmentHistoryList[0].designation) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Organization Type:</label>
                    <div class="valueCls">{{certificateInfo?.employmentHistoryList[0].empOrgType ? (certificateInfo?.employmentHistoryList[0].empOrgType) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Employer Name:</label>
                    <div class="valueCls">{{certificateInfo?.employmentHistoryList[0].employerName ? (certificateInfo?.employmentHistoryList[0].employerName) : '-' }}</div>
                </div>
            </div>
        <!--</div>
        <div class="row"> -->
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Employer Address:</label>
                    <div class="valueCls">{{certificateInfo?.employmentHistoryList[0].employerAddress ? (certificateInfo?.employmentHistoryList[0].employerAddress) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">State:</label>
                    <div class="valueCls">{{certificateInfo?.employmentHistoryList[0].state ? (certificateInfo?.employmentHistoryList[0].state) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">District:</label>
                    <div class="valueCls">{{certificateInfo?.employmentHistoryList[0].district ? (certificateInfo?.employmentHistoryList[0].district) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Sector:</label>
                    <div class="valueCls">{{certificateInfo?.employmentHistoryList[0].sector ? (certificateInfo?.employmentHistoryList[0].sector) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Start Date:</label>
                    <div class="valueCls">{{certificateInfo?.employmentHistoryList[0].startDate ? (certificateInfo?.employmentHistoryList[0].startDate | date:'dd MMM yyyy') : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">End Date:</label>
                    <div class="valueCls">{{certificateInfo?.employmentHistoryList[0].endDate ? (certificateInfo?.employmentHistoryList[0].endDate | date:'dd MMM yyyy') : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Nature of Job:</label>
                    <div class="valueCls">{{certificateInfo?.employmentHistoryList[0].natureOfJob ? (certificateInfo?.employmentHistoryList[0].natureOfJob) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Is Current Job:</label>
                    <div class="valueCls">{{certificateInfo?.employmentHistoryList[0].isCurrentJob ? 'Yes' : 'No' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Functional Area:</label>
                    <div class="valueCls">{{certificateInfo?.employmentHistoryList[0].functionalArea ? (certificateInfo?.employmentHistoryList[0].functionalArea) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Functional Role:</label>
                    <div class="valueCls">{{certificateInfo?.employmentHistoryList[0].functionalRole ? (certificateInfo?.employmentHistoryList[0].functionalRole) : '-' }}</div>
                </div>
            </div>
        </div>
    </div><div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
        <div class="subHeadingCls mb-3">Other Qualifications / Trainings / Courses</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Course / Certificate Name:</label>
                    <div class="valueCls">{{certificateInfo?.qualTrainingsCoursesList[0].courseName ? (certificateInfo?.qualTrainingsCoursesList[0].courseName) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Year:</label>
                    <div class="valueCls">{{certificateInfo?.qualTrainingsCoursesList[0].year ? (certificateInfo?.qualTrainingsCoursesList[0].year) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Issued By:</label>
                    <div class="valueCls">{{certificateInfo?.qualTrainingsCoursesList[0].issuedBy ? (certificateInfo?.qualTrainingsCoursesList[0].issuedBy) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Duration:</label>
                    <div class="valueCls">{{certificateInfo?.qualTrainingsCoursesList[0].duration ? (certificateInfo?.qualTrainingsCoursesList[0].duration) : '-' }} {{certificateInfo?.qualTrainingsCoursesList[0].durationIn ? (certificateInfo?.qualTrainingsCoursesList[0].durationIn) : '-' }}</div>
                </div>
            </div>
            
            
         </div>
    </div>
    <div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
        <div class="subHeadingCls mb-3">Work Experience</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Current Job Title:</label>
                    <div class="valueCls">{{certificateInfo?.workExperienceList[0].currentJobTitle ? (certificateInfo?.workExperienceList[0].currentJobTitle) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Current Salary:</label>
                    <div class="valueCls">{{certificateInfo?.workExperienceList[0].currentSalary ? (certificateInfo?.workExperienceList[0].currentSalary) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Salary Duration:</label>
                    <div class="valueCls">{{certificateInfo?.workExperienceList[0].salaryDuration ? (certificateInfo?.workExperienceList[0].salaryDuration) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Organization Type:</label>
                    <div class="valueCls">{{certificateInfo?.workExperienceList[0].empOrgType ? (certificateInfo?.workExperienceList[0].empOrgType) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Is seeking job?</label>
                    <div class="valueCls">{{certificateInfo?.workExperienceList[0].seekingJob === '' ? 'No' : 'Yes' }}</div>
                </div>
            </div>
        <!--</div>
        <div class="row"> -->
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Key Skills:</label>
                    <div class="valueCls">{{certificateInfo?.workExperienceList[0].keySkills ? (certificateInfo?.workExperienceList[0].keySkills) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Total Experience(in years):</label>
                    <div class="valueCls">{{certificateInfo?.workExperienceList[0].totalExp ? (certificateInfo?.workExperienceList[0].totalExp) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Available to Join(in days):</label>
                    <div class="valueCls">{{certificateInfo?.workExperienceList[0].availableToJoin ? (certificateInfo?.workExperienceList[0].availableToJoin) : '-' }}</div>
                </div>
            </div>
            
         </div>
    </div>
    <div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
        <div class="subHeadingCls mb-3">Preferences</div>
        <div class="row">
                        
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Prefered Location:</label>
                    <div class="valueCls">{{certificateInfo?.preferedLocation ? (certificateInfo?.preferedLocation) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Job Nature:</label>
                    <div class="valueCls">{{certificateInfo?.jobNature ? (certificateInfo?.jobNature) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Shift Timings:</label>
                    <div class="valueCls">{{certificateInfo?.shiftTimings ? (certificateInfo?.shiftTimings) : '-' }}</div>
                </div>
            </div>
            
        </div>
    </div>
    <div class="certificateInfo">
        <div class="row">
            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
                <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                    <div *ngIf="file.documentType !== 'aadharFile' && file.documentType !== 'casteCert' && file.documentType !== 'docCV'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">{{file.documentType}}:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <!--<div *ngIf="file.documentType === '80_passCertificate'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Pass Certificate:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div> -->
                    <div *ngIf="file.documentType === 'aadharFile'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Aadhar Card:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'casteCert'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Caste Certificate:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'docCV'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">CV:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>