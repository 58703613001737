import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService, CustomAdapter, CustomDateParserFormatter, WhitespaceValidator } from '../../shared/common.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbDateAdapter, NgbDateParserFormatter, NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants } from '../../constants/common-constants.component';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerPopupComponent } from 'src/shared/pdf-viewer-popup/pdf-viewer-popup.component';
import { CommonMsgModalComponent } from '../../shared/common-msg-modal/common-msg-modal.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-revoke-provision',
  templateUrl: './revoke-provision.component.html',
  styleUrls: ['./revoke-provision.component.css'],
  providers: [
    NgbNavConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe,
  ],
})
export class RevokeProvisionComponent implements OnInit {
  ViewTransactions: FormGroup;
  showHideTable :Boolean =false;
  showTable:Boolean=false;
  commonConstants : any = CommonConstants;
  transactionID:any
  reqData:any ;
  public submitted:Boolean=false;
  serviceList: any = [];
  userInfo: any;
  requistionType: any;
  data: any = {};
  fileList: { FileName: string, DocumentType: string, BlobRef: string }[] = [];
  basicInfo: any = [];
  noOfCols = 7;
  constructor(private modalService: NgbModal,private router: Router,private activatedRoute: ActivatedRoute,private commonService: CommonService,private formBuilder: FormBuilder) {
    this.ViewTransactions = this.formBuilder.group({
      transactionID: ['',[Validators.required,WhitespaceValidator.whiteSpaceValidate]],
      reason:['',[Validators.required,WhitespaceValidator.whiteSpaceValidate]]
    });
    let userData = localStorage.getItem('userInfo');
    if(userData && userData !== null) {
      this.userInfo = JSON.parse(userData);
    }
   }
  transactionDataPopup: any = [];
  ngOnInit(): void {
  }
  get f() {
    return this.ViewTransactions.controls;
  }
    goToHome() {
    this.router.navigate(['/home']);
  }
  clearGrid(){
    this.showHideTable = false;
  }
  getCertificateDetails() {
    let reqId = this.f.transactionID.value.trim();
    if( this.f.transactionID.valid ){
      this.commonService.getRequest(this.commonConstants.getCertificateDetails + 'RV&IncludeFileUploadInfo=true&IncludePaymentDetails=false&RequisitionId='+ reqId).subscribe({
        next: (responseData: any) => { 
          if(responseData && responseData.length !==0) {
            this.basicInfo = responseData.result?.requisitionHeader;
          } else {
            this.basicInfo = [];
          }
            console.log(responseData);
        },
        error: (error) => {
          this.basicInfo = [];
          console.log('client side', error);
        }
    });
  }
  }
  submit(){
    this.submitted=true;
      let postData = '?ApplicationNo=' + this.f['transactionID'].value ;
      if( this.ViewTransactions.get('transactionID')?.value !== ""  ){
      this.commonService.getRequest(this.commonConstants.GetTransactionDetailsForRevoke + postData).subscribe({
      next: (responseData: any) => { 
        this.reqData = responseData?.result; 
       if(typeof(this.reqData)!== 'string'){
       // if(this.reqData){
        this.showTable=true;
        if(this.reqData?.serviceName == 'Integrated Certificate-SuoMoto') {
          this.noOfCols = 6;
        }
        this.showHideTable=true;
        this.getCertificateDetails();
        }
        else{
        this.commonService.commonErrorPopup("Alert", this.reqData,"md")
        this.showTable=false;
        this.showHideTable=false;
        }
      },
    error: (error: any) => {
    this.showTable=false;
    this.showHideTable=false;
    console.log('client side', error);
  } 
})
}
  }
  revoke(){
    this.submitted=true;
    this.data =
    {
      requisitionHeader: {
        requisitionType: 'RevokeCertificate',
        department: this.basicInfo?.department,
        applicantInfo: this.basicInfo?.applicantInfo,
        remarks: "",
      },
      requisitionData: {
        transactionIdOrApplicationId: this.reqData?.applicationNo,
        serviceName:  this.reqData?.serviceName,
        citizenName: this.reqData?.citizenName,
        revokeReason: this.f['reason'].value
    }
    }
    console.log(this.data, 'post data');
   if(this.ViewTransactions.get('reason')?.value !== ""  ){
     this.commonService.postRequest(this.commonConstants.SaveRevokeCertificateData,this.data).subscribe({
      next: (responseData: any) => {
        if(typeof(responseData.result) !== 'string'){
        this.commonService.commonErrorPopup("Alert", "Application is successfully submitted", "md");
        this.f.transactionID.patchValue(null);
        this.showTable=false;
        this.submitted=false;
        this.f.reason.patchValue("");
        }
        else{
          this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    
    });
  }
  }
  
  getDashBoardForCertificatePreview(){
    console.log("herer call APi",this.f['transactionID'].value);
    let deptCode:any;
    if(this.f['transactionID'].value?.substr(0, 2)?.toUpperCase() === 'MC') {
      deptCode = 'RS';
    } else {
      deptCode = 'RV';
    }
    this.transactionDataPopup = [];
    this.commonService.getRequest(this.commonConstants.getSignedCertificateDataForPreview +  this.f['transactionID'].value + '&DepartmentCode='+ deptCode ).subscribe({
      next: (responseData: any) => {
        if(responseData.result.error == null){
          let fileInfo =  responseData.result.fileContents;
          const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
          modalRef.componentInstance.sourceFile = fileInfo;
          modalRef.componentInstance.showPrintDownload = false;   
        }else{
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = 'No Detail found...Please enter valid application number.';
          modalRef.result.then((result: any) => {});
        }    
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
}
}

