<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
      <div class="breadcrumbCls">
        GSWS / Application Submission / GSWS Department <span class="activePage">/ Aarogya Suraksha 2.0 Token Registration </span>
      </div>
    </div>
    <div class="mainPageContainer mr-3">
        <div class="btnHolder mb-3">
            <button class="btn btn-primary float-right my-3"  (click)="goToHome()">Home</button>
        </div>
      <h6>Token Registration Form</h6>
      <form [formGroup]="tokenRegForm">
        <div class="row mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Aadhaar Number: <span class="mandatoryCls">*</span></label>
                <input type="text" name="aadharNo" formControlName="aadharNo" class="inputCls width70" appBlockCopyPaste required numbersOnly
                [ngClass]="{ 'is-invalid':submitted && f.aadharNo.errors }" maxlength="12" minlength="12">
                <button class="btn btn-primary float-right" (click)="getCitizenInfo()">Pre-Fill</button>
                <div *ngIf="submitted && f.aadharNo.errors" class="invalid-feedback">
                  <div *ngIf="f.aadharNo.errors.required || f.aadharNo.errors.whiteSpaceValidate">Aadhaar Number is required</div>
                  <div *ngIf="f.aadharNo.errors.aadharValidate || f.aadharNo.errors.minlength || f.aadharNo.errors.maxlength">Please enter valid Aadhaar Number</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Citizen Name: <span class="mandatoryCls">*</span></label>
                <input type="text" name="citizenName" formControlName="citizenName" class="inputCls" appBlockCopyPaste required maxlength="200"
                [ngClass]="{ 'is-invalid':submitted && f.citizenName.errors }">
                <div *ngIf="submitted && f.citizenName.errors" class="invalid-feedback">
                  <div *ngIf="f.citizenName.errors.required || f.citizenName.errors.whiteSpaceValidate">Citizen Name is required</div>
                  <div *ngIf="f.citizenName.errors?.pattern">Please enter valid Citizen Name</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Gender: <span class="mandatoryCls">*</span></label>
                <select class="selectCls" formControlName="gender" required [ngClass]="{ 'is-invalid':submitted && (f.gender.errors || f.gender.value === 'Select')}" > 
                    <option [ngValue]="null" disabled>Select</option>
                    <option value="Female">Female</option>
                    <option value="Male">Male</option>
                    <option value="Transgender">Transgender</option>
                  </select>
                <div *ngIf="submitted && (f.gender.errors || f.gender.value === 'Select')" class="selectError">
                  <div *ngIf="f.gender.errors?.required || f.gender.value === 'Select'">Please select Gender</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Date of Birth: <span class="mandatoryCls">*</span></label>
                <div class="input-group">
                  <input class="calendarCls form-control" appBlockCopyPaste
                         name="d"  ngbDatepicker #d="ngbDatepicker" [minDate]="minDate"
                         [maxDate]="maxDate" required formControlName="dob"
                         [ngClass]="{ 'is-invalid':submitted && f.dob.errors }">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                  </div>
                </div>
                <div *ngIf="submitted && f.dob.errors" class="selectError">
                  <div *ngIf="f.dob.errors?.required || f.dob.invalid">Please enter valid Date of Birth</div>
              </div>
              </div>
        </div>
      <div class="btnHolder">
        <button  class="greenBtnCls float-right" *ngIf="!showPrint" (click)="generateToken()">Generate Token</button>
        <button  class="greenBtnCls float-right mr-3" *ngIf="showPrint" (click)="openPrintPopup(printModal)">Print Token Details</button>
        <button  class="greenBtnCls float-right mr-3"  (click)="resetDetails()">Reset</button>
      </div>
      </form>
    </div>
  </div>
  <ng-template #printModal let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">Print Token Details</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss();" aria-label="Close">
      <span aria-hidden="true">&times;</span>
     </button>
    </div>
    
    <div class="modal-body" id="printContainer">
       <div class="row">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 text-center">
            <!-- <img src="../../assets/images/aarogya-suraksha-logo.png" height="160px"/> -->
            </div>
            <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0">
                <h2 class="mt-5 text-center">ఆరోగ్య సురక్ష 2.0</h2>
                <div class="row">
                    <div class="col col-lg-4 col-md-4 col-xs-4 col-sm-4 p-0">Date: {{tokenDetails?.createD_DATE | date: 'dd/MM/yyyy'}}</div>
                    <div class="col col-lg-8 col-md-8 col-xs-8 col-sm-8  text-right p-0">Token No: <span class="boldText"><b>{{tokenDetails?.tokeN_NO}}</b></span></div>
                </div>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 py-3 text-center">
                <img src="../../assets/images/logo_official.png" height="150px"/>
            </div>
       </div>
      <div class="row mt-3 mx-5">
        <label class="labelCls col col-lg-3 col-md-3 col-sm-12 col-xs-12"><b>Citizen Name:</b></label>   
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 labelCls">{{tokenDetails?.citizeN_NAME}}</div>  
        <label class="labelCls col col-lg-3 col-md-6 col-sm-12 col-xs-12"><b>Date of Birth:</b></label>   
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 labelCls">{{tokenDetails?.dob | date: 'dd/MM/yyyy'}}</div> 
        <label class="labelCls col col-lg-3 col-md-3 col-sm-12 col-xs-12"><b>Gender:</b></label>   
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 labelCls">{{tokenDetails.gender === 'F' ? 'Female' : (tokenDetails.gender === 'M' ? 'Male' : 'Transgender') }}</div>  
      </div>
      
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss();">Cancel</button>
      <button  type="submit" class="greenBtnCls" name="submit" (click)="printTokenDetails()">Print</button>
    </div>
</ng-template>
  