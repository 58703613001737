<div class="modal-header" >
    <h6 class="modal-title">{{title}}</h6>
    <!-- <button type="button" class="close" aria-label="Close"  (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>
  <div class="modal-body">
    <span>Did sub-registrar also approve ?</span>&nbsp;&nbsp;&nbsp;&nbsp;
     <input type="radio" name="subregisterapprove" value="Y" id="yes" (click)="activeModal.close('Y')">&nbsp;Yes &nbsp;&nbsp;&nbsp;&nbsp;
     <input type="radio" name="subregisterapprove" value="N" id="no" (click)="activeModal.close('N')">&nbsp;No
  </div>
 