<div class="container-fluid mainCls"> 
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
            GSWS / Application Submission<span class="activePage">/ Lamination Service</span>
        </div>
    </div>
    <div class="mainPageContainer mr-3">
        <h6>Lamination Service</h6>
        <form [formGroup]="lamination">
            <nav ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)">
                <ng-container [ngbNavItem] = "1">
                    <a ngbNavLink>Basic Details</a>
                    <ng-template ngbNavContent>
                        <app-common-form formControlName="commonForm" [isSubmitted]="commonFormSubmitted" (reset)="commonFormSubmitted = false"></app-common-form>
                        <div class="btnHolder">
                        <button  class="greenBtnCls float-right" (click)="goToNextTab()">Continue</button>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-container [ngbNavItem] = "2">
                    <a ngbNavLink>Application Form</a>
                    <ng-template ngbNavContent>
                        <div class="subHeadingCls mb-3">Lamination Details</div>
                        <div class="row mb-3">
                            <div class="col">
                                <div class="tab">
                                  <table class="tableCls">
                                        <thead>
                                            <tr> 
                                                <th>Service Name</th>
                                                <th>Number of Copies</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let Data of getData; let i = index">
                                                <td>
                                                    <label>{{Data.name}}</label>
                                                </td>
                                                <td class="text-center"><input type="number"  placeholder="0" [formControlName]="Data.entityCode" max="100" min="0" numbersOnly></td>
                                            </tr>
                                        </tbody>
                                  </table>
                                </div>
                            </div>
                        </div>
                        <div class="btnHolder">
                            <button class="greenBtnCls float-right" (click)="submit()">Show Payment</button>
                        </div>
                    </ng-template>
                </ng-container>
            </nav>
            <div [ngbNavOutlet]="nav"></div>
        </form>
    </div>
</div>