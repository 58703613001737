<div class="certificateInfo ">
    <div class="subHeadingCls mb-3">Applicant Details
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo?.districtName}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo?.mandalName}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Secretariat:</label>
                <div class="valueCls">{{certificateInfo?.secretariatName}}</div>
            </div>          
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Cluster:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.cluster}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">FP Shop No:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.fpShopNo}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">FP Shop Type:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.fpShopType}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">FP Shop Run By:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.fpShopRunBy}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Caste:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.caste ? certificateInfo?.fpShopDetailsDataList[0]?.caste : '-' }}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">FP Shop Dealer Name:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.fpShopDealerName}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">FP Shop Dealer Aadhaar:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0].fpShopDealerAadhar}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">FP Shop Dealer Rice Card:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.fpShopDealerRiceCard ? certificateInfo?.fpShopDetailsDataList[0]?.fpShopDealerRiceCard : '-'}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Dob:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.dob | date: 'dd/MM/yyyy'}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">FP Shop Dealer Father Name:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.fpShopDealerFatherName}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Mobile Number:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.mobileNumber}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Gender:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.gender}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Authorization No:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.authorizationNo}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">FP Shop Issue Date:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.fpShopIssueDate ? certificateInfo?.fpShopDetailsDataList[0]?.fpShopIssueDate : '-'}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">FP Shop Validity upto:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.fpShopValidityupto}}</div>
            </div>          
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">FP Shop Address:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.fpShopAddress}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Landmark:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.landmark}}</div>
            </div>          
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">PinCode:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.pincode}}</div>
            </div>          
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Challan Number:</label>
                <div class="valueCls">{{certificateInfo?.fpShopDetailsDataList[0]?.challanNumber}}</div>
            </div>          
        </div>
    </div>
</div>


<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of fileList ;let i = index">
                    <div *ngIf="file.documentType === 'ChallanDocument'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Challan:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                                       <div *ngIf="file.documentType === 'Form1'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Form-1:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Authorization'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Authorization:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Aadhar'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Aadhar:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'RiceCard'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">RiceCard:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>