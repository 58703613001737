import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';
@Component({
  selector: 'app-issue-small-farmer-certificate-view',
  templateUrl: './issue-small-farmer-certificate-view.component.html',
  styleUrls: ['./issue-small-farmer-certificate-view.component.css']
})
export class IssueSmallFarmerCertificateViewComponent implements OnInit {
  commonConstants : any = CommonConstants;
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  // screenMandal: any;
  // screenDistrict: any;
  // screenVillage: any;

  constructor(private commonService: CommonService) {
  }

  ngOnInit(): void {
    // this.getByEntitycode(this.certificateInfo?.details?.district, 'District');
    // this.getByEntitycode(this.certificateInfo?.details?.mandalMunicipality, 'Mandal');

    // this.getdynamiceVillageByEntitycode(this.certificateInfo?.landInfo , 'Secretariat');
  }

  // getByEntitycode(entityCode: any, entityType:any, addrType?: any): any {
  //   this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({  
  //   next: (responseData: any) => { 
  //       let name =  responseData.result[0]?.name;
  //       if (entityType === 'District' ) {
  //         this.screenDistrict = name;
  //       }else if (entityType === 'Mandal') {
  //         this.screenMandal = name; 
  //       } else if (entityType === 'Secretariat') {
  //         this.screenVillage = name;
  //       }
  //     },
  //     error: (error) => {
  //       console.log('client side', error);
  //     }
  // });
  // }

  serviceDetailsGridData = [
    {
      village : "",
      khataNo: "",
      surveyNo : "",
      landType : "",
      extentofLand : "",
      landUnit : ""
    }
  ];
  // getdynamiceVillageByEntitycode(gridData: any, entityType:any): any {
  //   this.serviceDetailsGridData = []
  //   gridData.forEach((element: any) => {
  //     this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + element.village + '&EntityType=' + entityType).subscribe({  
  //       next: (responseData: any) => { 
  //           let name =  responseData.result[0]?.name;
  //           if (entityType === 'Secretariat') {
  //             this.screenVillage = name;
  //             this.serviceDetailsGridData.push({
  //              village : this.screenVillage,
  //              khataNo : element.khataNo,
  //              surveyNo : element.surveyNo,
  //              landType : element.landType,
  //              extentofLand : element.extentofLand,
  //              landUnit : element.landUnit


  //             })
  //           }
  //         },
  //         error: (error) => {
  //           console.log('client side', error);
  //         }
  //     });

  //   });

    
  // }
  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

}
