import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/shared/common.service';
import { CommonConstants } from 'src/constants/common-constants.component';
@Component({
  selector: 'app-view-surrenderricecard',
  templateUrl: './view-surrenderricecard.component.html',
  styleUrls: ['./view-surrenderricecard.component.css']
})
export class ViewSurrenderricecardComponent implements OnInit {
  commonConstants : any = CommonConstants;
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
 
  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
}
