

<!-- <div class="popup-modal" *ngIf="digiSignPopUp">
    <div class="popup-content">
      <div class="popup-header">
        <h4 class="title">Grama Ward Sachivalayam</h4>
        <a
          type="button"
          class="close"
          (click)="digiSignPopUpChange();"
          style="cursor: pointer"
        >
          <span>×</span>
        </a>
      </div>
  
      <div class="popup-body">
        <table class="table table-striped rs-table">
          <tbody>
            <tr>
              <td>Tokens<sup class="text-danger h6">*</sup></td>
              <td>
                <select
              class="form-control"
              [(ngModel)]="tokenDetails"
              (change)="tokenChange()"
            >
              <option value="">-- SELECT TOKEN --</option>
              <option
                *ngFor="let obj of tokenList"
                value="{{ JSON.stringify(obj) }}"
              >
                {{ obj.keyStoreDisplayName }}
              </option>
            </select>
              </td>
            </tr>
            <tr>
              <td>Certificates<sup class="text-danger h6">*</sup></td>
              <td>
                <select
                class="form-control"
                [(ngModel)]="certificateTokenId"
              >
                <option value="">-- SELECT CERTIFICATE --</option>
                <option
                  *ngFor="let obj of certificateList"
                  value="{{ obj.KeyId }}"
                >
                  {{ obj.CommonName }}
                </option>
              </select>
              </td>
            </tr>
            <tr>
              <td>Password<sup class="text-danger h6">*</sup></td>
              <td>
                <input type="password" id="eMudhraPassword" class="form-control" [(ngModel)]="keyStorePassphrase" placeholder="Enter Password" maxlength="20" autocomplete="off"/>              
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <div class="popup-footer justify-content-end">
        <button
          type="button"
          class="btn btn-outline-success btn-sm btn-rounded"
          (click)="btnSignDoc();"
        >
          Sign Document
        </button>
        <button
          type="button"
          class="btn btn-outline-danger btn-sm btn-rounded"
          (click)="digiSignPopUpChange();"
        >
          Close
        </button>
      </div>
    </div>
  </div> -->


<!-- <div class="container-fluid" *ngIf="digiSignPopUp">
  <form [formGroup]="emudhraformGroup">
  <div class="subHeadingCls mb-3">e-Mudhra Digital Signature</div>
  <div class="row mb-3">
    <div class="col col-lg-1 col-md-1 col-sm-12 col-xs-12">
      <label class="labelCls">Tokens : <span class="mandatoryCls">*</span></label>
    </div>
    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12" >
      <select class="selectCls" required formControlName = "ddTokens"  (change)="tokenChange()" [ngClass]="{ 'is-invalid':submitted && (e.ddTokens.errors || e.ddTokens.value === 'Select') }">
        <option [selected]= true value="Select">Select</option>
        <option *ngFor="let obj of tokenList" [ngValue]="JSON.stringify(obj)">{{ obj.keyStoreDisplayName }}</option>
      </select>
      <div *ngIf="submitted && (e.ddTokens.errors || e.ddTokens.value === 'Select')" class="selectError">
        <div *ngIf="e.ddTokens.errors?.required || e.ddTokens.value === 'Select'">Please select Token</div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col col-lg-1 col-md-1 col-sm-12 col-xs-12">
      <label class="labelCls">Certificates : <span class="mandatoryCls">*</span></label>
    </div>
    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12" >
      <select class="selectCls" required formControlName = "ddCertificates"  (change)="tokenChange()" [ngClass]="{ 'is-invalid':submitted && (e.ddCertificates.errors || e.ddCertificates.value === 'Select') }">
        <option [selected]= true value="Select">Select</option>
        <option *ngFor="let obj of certificateList" [ngValue]="obj.KeyId">{{ obj.CommonName }}</option>
      </select>
      <div *ngIf="submitted && (e.ddCertificates.errors || e.ddCertificates.value === 'Select')" class="selectError">
        <div *ngIf="e.ddCertificates.errors?.required || e.ddCertificates.value === 'Select'">Please select Certificate</div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col col-lg-1 col-md-1 col-sm-12 col-xs-12">
      <label class="labelCls">Password : <span class="mandatoryCls">*</span></label>
    </div>
    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12" >
      <input type="password" name="ddPassword" formControlName="ddPassword" class="inputCls" required 
      [ngClass]="{ 'is-invalid':submitted && e.ddPassword.errors }"  maxlength="20">
      <div *ngIf="submitted && e.ddPassword.errors" class="invalid-feedback">
        <div *ngIf="e.ddPassword.errors.required">Please enter Password</div>
      </div>
    </div>
  </div>
  <div class="text-center ">
    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
    <ng-container >
        
        <button  name="signdocument" class=" greenBtnCls mr-3" (click)="btnSignDoc()">Sign Document</button>
        <button name="close" class="blueBtnCls mr-3" (click)="digiSignPopUpChange()">Close</button>
      </ng-container>
  </div>
</form>
</div> -->
<button type="button" class="btn btn-primary" (click)="openModal(editProfileModal)">Sign Document</button>

<ng-template #editProfileModal let-modal>
  <div class="modal-header">
   <h5 class="modal-title" id="editProfileLabel">e-Mudhra Digital Signature</h5>
   <button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
   </button>
  </div>
  
  <div class="modal-body">
   <form [formGroup]="emudhraformGroup" (ngSubmit)="btnSignDoc()">
    <div class="form-group row">
      <label class="col-sm-4 col-form-label">Tokens : <span class="mandatoryCls">*</span></label>
      <div class="col-sm-8">
        <select class="selectCls" required formControlName = "ddTokens"  (change)="tokenChange()" [ngClass]="{ 'is-invalid':submitted && (e.ddTokens.errors || e.ddTokens.value === 'Select') }">
          <option [ngValue]="null" disabled>Select</option>
          <option *ngFor="let obj of tokenList" [ngValue]="JSON.stringify(obj)">{{ obj.keyStoreDisplayName }}</option>
        </select>
        <div *ngIf="submitted && (e.ddTokens.errors || e.ddTokens.value === 'Select')" class="selectError">
          <div *ngIf="e.ddTokens.errors?.required || e.ddTokens.value === 'Select'">Please select Token</div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label">Certificates : <span class="mandatoryCls">*</span></label>
      <div class="col-sm-8">
        <select class="selectCls" required formControlName = "ddCertificates"   [ngClass]="{ 'is-invalid':submitted && (e.ddCertificates.errors || e.ddCertificates.value === 'Select') }">
          <option [ngValue]="null" disabled>Select</option>
          <option *ngFor="let obj of certificateList" [ngValue]="obj.KeyId">{{ obj.CommonName }}</option>
        </select>
        <div *ngIf="submitted && (e.ddCertificates.errors || e.ddCertificates.value === 'Select')" class="selectError">
          <div *ngIf="e.ddCertificates.errors?.required || e.ddCertificates.value === 'Select'">Please select Certificate</div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4 col-form-label">Password : <span class="mandatoryCls">*</span></label>
      <div class="col-sm-8">
        <input type="password" name="ddPassword" formControlName="ddPassword" class="inputCls" required 
        [ngClass]="{ 'is-invalid':submitted && e.ddPassword.errors }"  maxlength="20">
        <div *ngIf="submitted && e.ddPassword.errors" class="invalid-feedback">
          <div *ngIf="e.ddPassword.errors.required">Please enter Password</div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button  type="submit" name="signdocument" class="btn btn-primary " (click)="btnSignDoc()">Sign Document</button>
      <button  name="close" class="btn btn-secondary" (click)="modal.dismiss()">Close</button>
    </div>
   </form>
  </div>
 </ng-template>

  <!-- <div class="row mb-3">
    <div class="btnHolder">
      <button  class="whiteBtnCls float-right mr-3" (click)="digiSignPopUpChange();">  Close</button>
      <button  class="greenBtnCls float-right" (click)="btnSignDoc()">Sign Document</button>
    </div>
  </div> -->

