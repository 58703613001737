<div class="reportContainer">
    <div [formGroup]="form">
    <div class="row mb-3">
</div>
 <div class="header">
     <h3>Team Schedule Details Report</h3>
 </div>
  <!-- <div class="tabClass"> -->
        <ul class="nav nav-pills flex-column flex-sm-row"  class="navbar navbar-expand-lg navbar-light "> 
              <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li>
              </ul>
        <div class="btnHolder">
            <!-- <button  class="greenBtnCls float-right my-3" (click)="GetTeamScheduleReportData()">Get Reports</button> -->
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
        </div>
      </div>
<!-- </div> -->
<div class="table-responsive tableFixHead" *ngIf="showReports">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th [attr.colspan]="noOfCol">Team Schedule Details Report</th>
            </tr>
            <tr>
               <th rowspan="3">S.No</th>
               <th rowspan="3" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'ruraL_URBAN_FLAG'">RURAL/URBAN Flag <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'">Secretariat Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'teamName'">Team Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'scheduleDate'">Schedule Date <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'venueDetails'">Venue Details <mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>
        </thead>

        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                <td>{{item.districT_NAME}}</td>
                <td>{{item.mandaL_NAME}}</td>
                <td>{{item.ruraL_URBAN_FLAG}}</td>
               <td>{{item.secretariaT_NAME}}</td>
               <td class="text-right">{{item.teamName}}</td>
               <td class="text-right">{{item.scheduleDate}}</td>
               <td class="text-right">{{item.venueDetails}}</td>
              <tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td>{{totalItems[0].districT_NAME}}</td>
                <td>{{totalItems[0].mandaL_NAME}}</td>
                <td>{{totalItems[0].ruraL_URBAN_FLAG}}</td>
                <td>{{totalItems[0].secretariaT_NAME}}</td>
                <td class="text-right">{{totalItems[0].teamName}}</td>
                <td class="text-right">{{totalItems[0].scheduleDate}}</td>
                <td class="text-right">{{totalItems[0].venueDetails}}</td>
                </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    <div class="mt-4 btnHide">
        <div class="float-left" *ngIf="items.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
</div>
</div>
<div class="table-responsive tableFixHead" #TABLE class="hideTable">
   <table class="tableCls" >
       <thead>
           <tr>
           
                <th [attr.colspan]="noOfCol">Team Schedule Details Report</th>
            </tr>
           <tr>
               <th width="100px">S.No</th>
               <th width="100px" >District Name</th>
               <th width="100px">Mandal Name</th>
               <th width="100px">RURAL/URBAN Flag</th>
               <th width="100px" >Secretariat Name</th>
               <th width="100px" >Team Name</th>
               <th width="100px" >Schedule Date</th>
               <th width="100px" >Venue Details</th>
            </tr>
        </thead>

       <tbody *ngIf="items && items.length > 0">
           <tr *ngFor="let item of items;let i = index;">
               <td width="100px">{{i + 1}}</td>
               <td width="100px">{{item.districT_NAME}}</td>
               <td width="100px">{{item.mandaL_NAME}}</td>
               <td width="100px">{{item.ruraL_URBAN_FLAG}}</td>
               <td width="100px">{{item.secretariaT_NAME}}</td>
               <td width="100px">{{item.teamName}}</td>
               <td width="100px">{{item.scheduleDate}}</td>
               <td width="100px">{{item.venueDetails}}</td>
                </tr>
           <tr *ngIf="totalItems && totalItems.length > 0">
            <td></td>
            <td>{{totalItems[0].districT_NAME}}</td>
            <td>{{totalItems[0].mandaL_NAME}}</td>
            <td>{{totalItems[0].ruraL_URBAN_FLAG}}</td>
            <td>{{totalItems[0].secretariaT_NAME}}</td>
            <td >{{totalItems[0].teamName}}</td>
            <td >{{totalItems[0].scheduleDate}}</td>
            <td >{{totalItems[0].venueDetails}}</td>
          </tr>
       </tbody>
       <tbody *ngIf="items && items.length === 0">
           <tr>
              <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
       </tbody>
   </table>
  </div>
  
