<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Selection Criteria Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo.formDistrictName?certificateInfo.formDistrictName:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo.formMandalName
                    ?certificateInfo.formMandalName:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Village / Ward:</label>
                <div class="valueCls">{{certificateInfo.formVillageName?certificateInfo.formVillageName:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Survey Number:</label>
                <div class="valueCls">{{certificateInfo.formSurveyNo}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Correction Type:</label>

            </div>
            <div class="fieldHolder">
                <label class="labelCls">CorrectionInAdangal:</label>
                <div class="valueCls">
                    {{certificateInfo.isCorrectionInAdangalChecked?certificateInfo.isCorrectionInAdangalChecked:'-'}}
                </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">CorrectionInKhataNumber:</label>
                <div class="valueCls">
                    {{certificateInfo.isCorrectionInKhataNumberChecked?certificateInfo.isCorrectionInKhataNumberChecked:'-'}}
                </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">CorrectionInSurveyNumber:</label>
                <div class="valueCls">
                    {{certificateInfo.isCorrectionInSurveyNumberChecked?certificateInfo.isCorrectionInSurveyNumberChecked:'-'}}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Adangal Certificate Details:</div>
    <div class="row mb-3">
        <div class="col">
            <div class="tab">
                <table class="tableCls">
                    <thead>
                        <tr>
                            <th>Serial No</th>
                            <th>Survey No</th>
                            <th>Uncultivable Land</th>
                            <th>Cultivable Land</th>
                            <th>Land Nature</th>
                            <th>Tax</th>
                            <th>Land Classification</th>
                            <th>Water Source</th>
                            <th>Water Source Extent</th>
                            <th>Khata Number</th>
                            <th>Enjoyment Nature</th>
                            <th>Pattadar Name</th>
                            <th>Pattadar Father Name</th>
                            <th>Occupant Name</th>
                            <th>Occupant Father Name</th>
                            <th>Occupant Extent</th>
                            <th>Total Extent</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of certificateInfo?.adangalCertificateDetailsList">
                            <td>{{item?.serialNo ? (item?.serialNo) : '-'}}</td>
                            <td>{{item?.surveyNo ? (item?.surveyNo) : '-'}}</td>
                            <td>{{item?.uncultivatedLand ? (item?.uncultivatedLand) :'-'}}
                            </td>
                            <td>{{item?.cultivatedLand ? (item?.cultivatedLand) :'-'}}</td>
                            <td>{{item?.landNature ? (item?.landNature) :'-'}}</td>
                            <td>{{item?.tax ? (item?.tax) :'-'}}</td>
                            <td>{{item?.landClassification ? (item?.landClassification) :'-'}}</td>
                            <td>{{item?.waterSource ? (item?.waterSource) :'-'}}</td>
                            <td>{{item?.waterSourceExtent ? (item?.waterSourceExtent) :'-'}}</td>
                            <td>{{item?.khataNumber ? (item?.khataNumber) :'-'}}</td>
                            <td>{{item?.enjoymentNature ? (item?.enjoymentNature) :'-'}}</td>
                            <td>{{item?.pattadarName ? (item?.pattadarName) :'-'}}</td>
                            <td>{{item?.pattadarFatherName ? (item?.pattadarFatherName) :'-'}}</td>
                            <td>{{item?.occupantName ? (item?.occupantName) :'-'}}</td>
                            <td>{{item?.occupantFatherName ? (item?.occupantFatherName) :'-'}}</td>
                            <td>{{item?.occupantExtent ? (item?.occupantExtent) :'-'}}</td>
                            <td>{{item?.totalExtent ? (item?.totalExtent) :'-'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of fileList ;let i = index">
                    <div *ngIf="file.documentType === 'Application Form'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>