import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-permission-of-digging-of-bore-well-for-agricultural-purpose-view',
  templateUrl: './permission-of-digging-of-bore-well-for-agricultural-purpose-view.component.html',
  styleUrls: ['./permission-of-digging-of-bore-well-for-agricultural-purpose-view.component.css']
})
export class PermissionOfDiggingOfBoreWellForAgriculturalPurposeViewComponent implements OnInit {


  commonConstants : any = CommonConstants;
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  screenMandal: any;
  screenDistrict: any;
  screenVillage: any;

  constructor(private commonService: CommonService) {
  }

  ngOnInit(): void {
  //   this.getByEntitycode(this.certificateInfo?.landDetails?.district, 'District');
  // this.getByEntitycode(this.certificateInfo?.landDetails?.mandalMunicipality, 'Mandal');
  // this.getByEntitycode(this.certificateInfo?.landDetails?.villageWard , 'Secretariat');
  }

  // getByEntitycode(entityCode: any, entityType:any, addrType?: any): any {
  //   this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({  
  //   next: (responseData: any) => { 
  //       let name =  responseData.result[0]?.name;
  //       if (entityType === 'District' ) {
  //         this.screenDistrict = name;
  //       }else if (entityType === 'Mandal') {
  //         this.screenMandal = name; 
  //       } else if (entityType === 'Secretariat') {
  //         this.screenVillage = name;
  //       }
  //     },
  //     error: (error) => {
  //       console.log('client side', error);
  //     }
  // });
  // }

 
  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

}
