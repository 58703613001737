import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-view-spillitingricecard',
  templateUrl: './view-spillitingricecard.component.html',
  styleUrls: ['./view-spillitingricecard.component.css']
})
export class ViewSpillitingricecardComponent implements OnInit {
  members: any[] = [];
  gridDetail: any[] = [];
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  items: any[] = [];
  commonConstants: any = CommonConstants;
  constructor(public commonService: CommonService) {
    this.commonService.commonAction.subscribe((data: any)=> {
      if(data) {
        this.onDataChange(data);
      }
    });
  }

  ngOnInit(): void {
    console.log(this.certificateInfo?.splitSingleMemberType, "RICECARD NUMBER");
    console.log(this.fileList, "fileList");
    this.getDetails(this.certificateInfo?.riceCardNumber);
  }
  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
  getDetails(riceCardNumber: any) {

    let obj = {
      rice_card_no: riceCardNumber,
    };
    this.commonService
      .postRequest(this.commonConstants.getRiceCardMemberDetails, obj)
      .subscribe({
        next: (responseData: any) => {
          console.log(responseData.result, 'riceCardDetails');
          this.gridDetail = responseData.result.rice_card_mem_details;

          console.log(this.gridDetail.length)
          if (this.gridDetail.length > 0) {
            // this.showDetails = true;
          } else {

          }

        },
        error: (error) => {
          console.log(error);
        },
      });
  }
  onDataChange(data: any) {
    this.getDetails(data?.riceCardNumber);
  }
}
