import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, retry } from 'rxjs/operators';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from './common.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class EmudhraDigitalSignService {
  baseURL = '';
  //eMudhraURL = 'https://20.197.60.196:26769/DSC/';
  eMudhraURL = 'https://localhost.emudhra.com:26769/DSC/';
  req: any = {
    Data: '',
    // Type : '',
    TempFilePath: '',
  };
  commonConstants : any = CommonConstants;
  apiUrl: any;
  constructor(
    private httpClient: HttpClient,
    private httpClientModule: HttpClientModule,
    private commonService: CommonService,
    private environmentService: EnvironmentService
  ) {
    this.baseURL = commonService.baseUrl() + 'api/digi/';
    this.apiUrl = this.environmentService.apiUrl;
  }


  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    })
  }  
  APIMoptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      // 'Ocp-Apim-Subscription-Key': '7819e1cf869d4bcfb4ee8d71ab0b0f7b',
      'Ocp-Apim-Subscription-Key': this.environmentService.SubscriptionKeys.internalKey,
      'Ocp-Apim-Trace': 'true'
    })
  }
  //encrypt 
  
  public tokenListEncrypt(data: any) {
    this.req.Data = data;
    // this.req.Type = 'ListToken';
   // console.log(data,'data')
   const aesKey = this.commonService.getRandomString(32);
   const aesVector = this.commonService.getRandomString(16);
      let encodedData: any = {
        key: this.commonService.RSAEncrypt(aesKey),
        vector: this.commonService.RSAEncrypt(aesVector),
        value: this.commonService.AESEncrypt(JSON.stringify(data), aesKey, aesVector)
      }
      let body
      body = JSON.stringify(encodedData);
    const result: any = this.httpClient
      .post(
        this.apiUrl + this.commonConstants.encryptEmudraDoc,
        body,
        this.APIMoptions
      )
      .pipe(retry(3))
      .toPromise();
    return result;
  }

  public certificateListEncrypt(data: any) {
    this.req.Data = data;
    // this.req.Type = 'ListCertificate';
    const aesKey = this.commonService.getRandomString(32);
   const aesVector = this.commonService.getRandomString(16);
      let encodedData: any = {
        key: this.commonService.RSAEncrypt(aesKey),
        vector: this.commonService.RSAEncrypt(aesVector),
        value: this.commonService.AESEncrypt(JSON.stringify(data), aesKey, aesVector)
      }
      let body
      body = JSON.stringify(encodedData);
    const result: any = this.httpClient
      .post(
        this.apiUrl + this.commonConstants.encryptEmudraDoc,
        body,
        this.APIMoptions
      )
      .pipe(retry(3))
      .toPromise();
    return result;
  }

  public individualSignEncrypt(data: any) {
    this.req.Data = JSON.stringify(data.Data);
    // this.req.Type = 'PKCSBulkSign';
    this.req.file = data.file;
    const aesKey = this.commonService.getRandomString(32);
    const aesVector = this.commonService.getRandomString(16);
       let encodedData: any = {
         key: this.commonService.RSAEncrypt(aesKey),
         vector: this.commonService.RSAEncrypt(aesVector),
         value: this.commonService.AESEncrypt(JSON.stringify(data), aesKey, aesVector)
       }
       let body
       body = JSON.stringify(encodedData);
    const result: any = this.httpClient
      .post(
        this.apiUrl + this.commonConstants.encryptEmudraDoc,
        body,
        this.APIMoptions
      )
      .pipe(retry(3))
      .toPromise();
    return result;
  }

  //decrypt

  public tokenListDecrypt(data: any) {
    this.req.Data = data;
    this.req.Type = 'ListToken';
    const result: any = this.httpClient
      .post(
        this.apiUrl + this.commonConstants.decryptEmudraDoc,
        data,
        this.APIMoptions
      )
      .pipe(retry(3))
      .toPromise();
    return result;
  }

  public certificateListDecrypt(data: any) {
    this.req.Data = data;
    // this.req.Type = 'ListCertificate';
    const result: any = this.httpClient
      .post(
        this.apiUrl + this.commonConstants.decryptEmudraDoc,
        data,
        this.APIMoptions
      )
      .pipe(retry(3))
      .toPromise();
    return result;
  }

  public individualSignDecrypt(data: any) {
    this.req.Data = data;
    // this.req.Type = 'PKCSBulkSign';
   // this.req.TempFilePath = TempFilePath;
    const result: any = this.httpClient
      .post(
        this.apiUrl + this.commonConstants.decryptEmudraDoc,
        data,
        this.APIMoptions
      )
      .pipe(retry(3))
      .toPromise();
    return result;
  }

  //list
  public ListToken(data: any) {
    const result: any = this.httpClient
      .post(
        this.eMudhraURL + 'ListToken',
        data,
        this.options
      )
      .pipe(retry(3))
      .toPromise();
    return result;
  }

  public ListCertificate(data: any) {
    const result: any = this.httpClient
      .post(
        this.eMudhraURL + 'ListCertificate',
        data,
        this.options
      )
      .pipe(retry(3))
      .toPromise();
    return result;
  }

  public PKCSBulkSign(data: any) {
    const result: any = this.httpClient
      .post(
        this.eMudhraURL + 'PKCSBulkSign',
        data,
        this.options
      )
      .pipe(retry(3))
      .toPromise();
    return result;
  }

  
}
