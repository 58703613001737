<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Selection Criteria Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo.districtName?certificateInfo.districtName:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo.mandalMunicipalityName
                    ?certificateInfo.mandalMunicipalityName:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Village / Ward:</label>
                <div class="valueCls">{{certificateInfo.villageWardName?certificateInfo.villageWardName:'-'}}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details :</div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                    <tr>
                        <th>SerialNo</th>
                        <th>Pattadar Name</th>
                        <th>Pattadar Father Name</th>
                        <th>Khata No</th>
                        <th>Survey No</th>
                        <th>Land Classification</th>
                        <th>Total Extent</th>
                        <th>Tax</th>
                        <th>Acquired Reason</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of certificateInfo?.applicationDetails">
                        <td>{{item?.serialNo ? (item?.serialNo) : '-'}}</td>
                        <td>{{item?.pattadarDetails.firstName ? (item?.pattadarDetails.firstName) : '-'}}</td>
                        <td>{{item?.pattadarDetails.fatherHusbandName ? (item?.pattadarDetails.fatherHusbandName) :'-'}}
                        </td>
                        <td>{{item?.khataNo ? (item?.khataNo) :'-'}}</td>
                        <td>{{item?.surveyNo ? (item?.surveyNo) :'-'}}</td>
                        <td>{{item?.landClassification ? (item?.landClassification) :'-'}}</td>
                        <td>{{item?.totalExtent ? (item?.totalExtent) :'-'}}</td>
                        <td>{{item?.tax ? (item?.tax) :'-'}}</td>
                        <td>{{item?.acquiredReason ? (item?.acquiredReason) :'-'}}</td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>