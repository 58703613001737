import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonFormComponent } from 'src/shared/common-form/common-form.component';
import { EnvironmentService } from 'src/shared/environment.service';
import { AadharValidator, CommonService, WhitespaceValidator } from 'src/shared/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-basic-popup-changes',
  templateUrl: './basic-popup-changes.component.html',
  styleUrls: ['./basic-popup-changes.component.css'],
})
export class BasicPopupChangesComponent implements OnInit {
  submits: boolean = false;
  ekycForm: FormGroup;
  stateForm: FormGroup;
  showBiometricPopup = false;
  aadharNumber: any;
  isAadharDisable: Boolean = false;
  isDisable = false;
  biometricFlag:Boolean = false;
  isAadharOtpValidated = false;
  commonConstants: any = CommonConstants;
  aadharTransactionID: any;
  aadharData: any;
  encrtyptedAadhar: any;
  loggedInUserId: any;
  userInfo: any;
  autoBiometricId: any;
  windowObjectReference: any;
  @Input() aadharFromCF: string = '';
  constructor(private formBuilder: FormBuilder, public router: Router, private environmentService: EnvironmentService, private commonService: CommonService, private modalService: NgbModal, public activeModal: NgbActiveModal,) {
    let userData = localStorage.getItem('userInfo');
    if (userData && userData !== null) {
       this.userInfo = JSON.parse(userData);
       this.loggedInUserId = this.userInfo?.userPrincipalName
    }
    this.ekycForm = this.formBuilder.group({
      commonForm: [],
      authenticationType: [null, [Validators.required, WhitespaceValidator.whiteSpaceValidate]],
      aadharOtp: [null, [Validators.required]],
      aadhaarNumber: ['',[Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
      aadhaarNumberBio: ['', [AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
    });
    this.stateForm = this.formBuilder.group({
      authenticationType: [null, [Validators.required]],
      rbAuthenticationType: [null, [Validators.required]],
    });
  }
  ngOnInit(): void {
    this.commonService.aadharotpdatamessage = '';
  }

 get f() { return this.ekycForm.controls;}
 get f1() { return this.ekycForm.controls }
 get f2() { return this.stateForm.controls;}
  
  otpChange(event: any) {
    if (event.target.value === 'rbOTP') {
      this.f.aadhaarNumber.setValidators([Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]);
      this.f.aadharOtp.setValidators([Validators.required]);
      this.f.aadhaarNumber.updateValueAndValidity();
      this.f.aadharOtp.updateValueAndValidity();
      this.f.aadhaarNumberBio.clearValidators();
      this.f.aadhaarNumberBio.updateValueAndValidity();
      this.f.aadhaarNumberBio.reset();
      this.f.aadhaarNumber.patchValue(this.aadharFromCF);
    } else {
      this.f.aadhaarNumberBio.setValidators([Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]);
      this.f.aadhaarNumberBio.updateValueAndValidity();
      this.f.aadhaarNumber.clearValidators();
      this.f.aadharOtp.clearValidators();
      this.f.aadhaarNumber.updateValueAndValidity();
      this.f.aadharOtp.updateValueAndValidity();
      this.f.aadhaarNumber.reset();
      this.f.aadhaarNumberBio.patchValue(this.aadharFromCF);
    }
  }
 
  sendAadharOtp() : any{
    this.submits = true;
    var sendPostData={
      P_USER_ID: "10690658-DA",
      PID_DATA: "",
      P_OTP: "",
      P_UID: this.f.aadhaarNumber.value,
      P_LATITUDE: "",
      P_LONGITUDE: "",
      AUTHENTICATION_TYPE: "EKYCOTPGENERATE"
  }
  let postData: any = btoa(JSON.stringify(sendPostData));
  this.commonService.postRequestForAadhar(this.commonConstants.pensionAadharOTP, postData, 'pension').subscribe({
    next: (responseData:any) => {
      if(responseData?.message == 'OTP Sent Successfully'){
        this.aadharTransactionID = responseData?.result
      } else {
        this.commonService.commonErrorPopup('Alert', responseData?.result, 'md');
      }
    },
    error: (error: any) => {
      console.log('client side', error);
    }
  });
  }

  authType(authtype: any) {
    this.commonService.authTypeFromBasicPopup = authtype
  }

  validateAadharOTP(){
    this.submits = true;
    if(this.f.aadhaarNumber.valid  &&  this.f.aadharOtp.value.length == 6 ){
      this.aadharData = [];
      var sendPostData={
        P_USER_ID: "10690658-DA",
        PID_DATA: this.aadharTransactionID,
        P_OTP: this.f.aadharOtp.value,
        P_UID:  this.f.aadhaarNumber.value,
        P_LATITUDE: "",
        P_LONGITUDE: "",
        AUTHENTICATION_TYPE: "EKYCOTPVALIDATE"
      }
      let postData: any = btoa(JSON.stringify(sendPostData));
      this.commonService.postRequestForAadhar(this.commonConstants.pensionAadharOTP, postData, 'pension').subscribe({
        next: (responseData:any) => { 
          if(responseData?.message == 'Data Loaded successfully !!!'){
            this.commonService.commonErrorPopup('Alert', 'Authentication Success.', 'md')
              this.aadharData = responseData?.result;
              this.commonService.aadharotpdatamessage = responseData?.message;
              let data: any;
              data = {}
              data.firstName = this.aadharData?.name;
              data.fatherHusName = this.aadharData?.co;
              data.gender = this.loadGender(this.aadharData?.gender?.toUpperCase()?.trim());
              data.dob = this.aadharData?.dob;
                this.submits=false;
                this.isAadharOtpValidated = true;
                this.activeModal.close(data);
          }else if(responseData?.message == 'OTP Flooding error.'){
            this.submits=true;
            this.commonService.commonErrorPopup('Alert', 'OTP Flooding error. Please try again after sometime.' , 'md')
          }else{
            this.commonService.commonErrorPopup('Alert', responseData?.message , 'md')
          }
        },
        error: (error: any) => {
          console.log('client side', error);
        }
      });
  }
  }

  biometricBtn(from?:any): any {
    this.biometricFlag=true; 
    if(!this.f['aadhaarNumberBio'].valid) {
      return false;
    } else {
      let postData: any = {};
          this.encrtyptedAadhar =  this.commonService.RSAEncrypt(this.f['aadhaarNumberBio'].value);
          postData.autoBiometricId = 0;
          postData.aadharNumber = this.encrtyptedAadhar;
          postData.responseStatus = '';
          postData.userId = this.loggedInUserId;
          this.commonService.postRequestForWebLand(this.commonConstants.getSNoForBiometric, postData).subscribe({
            next: (responseData:any) => { 
            this.autoBiometricId = responseData?.result?.autoBiometricId;
            if (this.autoBiometricId !== undefined && this.autoBiometricId !== null) {
              this.showBiometricPopup = true;
              let url: any = this.environmentService.externalUrls.biometricRedirectionPage + encodeURIComponent(this.encrtyptedAadhar) +'&SNo='+ this.autoBiometricId +'&DoEkyc=true&ReqPhoto=false';
              this.windowObjectReference = window.open(url, "BiometricAuthentication", 'popup=1;left=500,top=100,width=780,height=700');
            } else {
              this.showBiometricPopup = false;
            }
          },
            error: (error) => {
              console.log('client side', error);
            }
          });
    }
    }

  checkBiometricStatus(from?: any) {
    this.submits = true;
    this.windowObjectReference.close();
    let postData: any = {};
    postData.autoBiometricId = this.autoBiometricId;
    postData.aadharNumber = this.encrtyptedAadhar;
    postData.responseStatus = '';
    postData.userId = this.loggedInUserId;
    this.commonService.postRequestForWebLand(this.commonConstants.getSNoForBiometric, postData).subscribe({
      next: (responseData:any) => { 
     let status = responseData?.result?.responseStatus;
     if (status === 'Success') {
      this.showBiometricPopup = true;
      this.commonService.commonErrorPopup('Alert', 'Biometric Authentication Success.', 'md');
      this.commonService.getRequest("comms-vm/AadhaarBiometric/Authentication/GetBiometricData?SNo="+ this.autoBiometricId).subscribe({
        next: (responseData:any) => {
          if(responseData?.data?.Reason == 'Success'){
            this.aadharData = responseData?.data;
              let data: any;
              data = {}
              data.firstName = this.aadharData?.Citizen_Name;
              data.fatherHusName = this.aadharData?.Care_Of_Country;
              data.gender = this.loadGender(this.aadharData?.Gender?.toUpperCase()?.trim());
              data.dob = this.aadharData?.DateofBirth;
              this.submits=false;
              this.activeModal.close(data);
        }
        },
        error: (error) => {
          console.log('GetBiometricData Error client side', error);
        }
      })
     } else {
      this.showBiometricPopup = false;
      this.commonService.commonErrorPopup('Alert', 'Biometric Authentication failed. Please try again!!', 'md');
     }     
    },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  loadGender(value: string) {
    if (value === 'M')
      return 'Male';
    else if (value === 'F')
      return 'Female';
    else if (value !== null && value !== '')
      return 'Transgender';
    else
      return null;
  }

  aadharChange(from?:any){
    if(from === 'aadhar') {
      this.f.aadharOtp.patchValue(null);
      if(this.f.aadhaarNumber.valid){
        this.isAadharDisable = false;
      }else{
        this.isAadharDisable = true;
      }
    } else {
    }
  }
  reset() {
    // this.fbSearchBirthDetailsForm.reset();
    // this.showGrid = false;
  }


}
