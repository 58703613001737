<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
            GSWS / Application Submission / Revenue Department <span class="activePage">/ Assignment of Lands to Ex-Serviceperson/Serving Soldiers</span>
        </div>
    </div>
    <div class="btnHolder mb-3">
    <button  class="greenBtnCls float-right mr-3 my-3" (click)="gotoProfile()">Go to Profile</button>
    <button  class="greenBtnCls float-right mr-3 my-3" (click)="goToHome()">Go to Transactions</button></div>
    <div class="mainPageContainer mr-3">
        <h6 class="mb-3">Assignment of Lands to Ex-Serviceperson/Serving Soldiers</h6>
        <h6 class="mb-3">Details of Ex-Service Person/Soldier applying for grant of Assignment: </h6>
        <form [formGroup]="soldierForm">
                <div class="row mb-3">
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Name of Service Person/Soldier: <span class="mandatoryCls">*</span></label>
                        <input type="text" name="applicantName" required class="inputCls" formControlName="applicantName" [ngClass]="{ 'is-invalid':submits && f.applicantName.errors }"  maxlength="50">
                        <div *ngIf="submits && f.applicantName.errors" class="invalid-feedback">
                            <div *ngIf="f.applicantName.errors.required || f.applicantName.errors.whiteSpaceValidate">Applicant Name is required</div>
                            <div *ngIf="f.applicantName.errors?.pattern">Please enter valid Applicant Name</div>
                        </div>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Category of Service person/Soldier:<span class="mandatoryCls">*</span></label>
                        <select class="selectCls" required formControlName = "category" [ngClass]="{ 'is-invalid':submits && (f.category.errors || f.category.value === 'Select')}"  (change)="selectedCategory($event)">
                            <option  [ngValue]= "null" disabled>Select</option>
                            <option value="Ex-Serviceperson">Ex-Serviceperson</option>
                            <!-- <option value="ServingSoldier">Serving Soldier</option> -->
                            <option value="DeceasedSoldier">Deceased Soldier</option>
                        </select>
                        <div *ngIf="submits && (f.category.errors || f.category.value === 'Select')" class="selectError">
                            <div *ngIf="f.category.errors?.required || f.category.value === 'Select'">Please select Category of Service person/Soldier</div>
                        </div>
                    </div>  
                </div>  
                <div class="row mb-3">   
                        <div class="col col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label class="labelCls">Is the Soldier/Ex-Service person a Gallantry Award Winner ?<span class="mandatoryCls">*</span></label>
                                <input type="radio"  id="yesUpdate" [value]="true"  formControlName="awardWinner"/>
                                <label class="mx-2" for="yesUpdate">Yes</label>
                                <input type="radio"  id="noUpdate" [value]="false" [checked]="true" formControlName="awardWinner"/>
                                <label class="mx-2" for="noUpdate" >No</label>
                              <div *ngIf="submits && (f.awardWinner.errors || f.awardWinner.value === 'Select')" class="selectError">
                                <div *ngIf="f.awardWinner.errors?.required || f.awardWinner.value === 'Select'">Please select the Value.</div>
                            </div>
                        </div>
                        </div>
                        
                         <div class="row mb-3">
                        <div *ngIf="(f.category.value === 'Ex-Serviceperson' || f.category.value === 'ServingSoldier')" class="col col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <label class="labelCls">Is the soldier/ex-service person War Disabled ?<span class="mandatoryCls">*</span></label>
                                <input type="radio"  id="yes" [value]="true" formControlName="warDisabled" (change)="onChangeState()" />
                                <label class="mx-2" for="yes">Yes</label>
                                <input type="radio"  id="no" [checked]="true" [value]="false" formControlName="warDisabled" (change)="onChangeState()" />
                                <label class="mx-2" for="no" >No</label>
                              <div *ngIf="submits && (f.warDisabled.errors || f.warDisabled.value === 'Select')" class="selectError">
                                <div *ngIf="f.warDisabled.errors?.required || f.warDisabled.value === 'Select'">Please select the Value.</div>
                            </div>
                        </div>
                    </div> 
                  <div class="row mb-3" *ngIf="f.warDisabled.value === true && f.category.value !== 'DeceasedSoldier'">
                    <div class="col col-lg-9 col-md-6 col-sm-9 col-xs-12">
                            <label class="labelCls">Please specify:<span class="mandatoryCls">*</span></label>
                            <div class="valueCls">
                                <textarea type="text" name="reason" class="text-areaCls" formControlName="reason"
                                rows="2"></textarea>
                            
                            <div *ngIf="submits && f.reason.errors" class="selectError">
                                <div *ngIf="f.reason.errors.required || f.reason.errors.whiteSpaceValidate">Please enter reason.</div>
                            </div>   
                        </div>
                    </div>
                  </div>
               
                        <div class="row mb-3">
                            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <label class="labelCls"> Date of Application received to ZSWO:  <span class="mandatoryCls">*</span></label>
                             
                                <div class="input-group">
                                  <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                                          name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" 
                                          [maxDate]="maxDate"  formControlName="dateOfApplication"  required
                                          [ngClass]="{ 'is-invalid':submits && f.dateOfApplication.errors }">
                                  <div class="input-group-append">
                                  <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                                  </div>
                              </div>
                              <div *ngIf="submits &&  f.dateOfApplication?.errors" class="selectError">
                                <div *ngIf="f.dateOfApplication?.errors?.required || f.dateOfApplication?.invalid">Please select Date of Application received to ZSWO</div>
                            </div> </div>
                    </div>
                    <ng-container *ngIf="f.category.value === 'Ex-Serviceperson'">
                    <div class="row mb-3">
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                      <label class="labelCls">Service Number: <span class="mandatoryCls">*</span></label>
                      <input type="text" name="serviceNumber" formControlName="serviceNumber"  class="inputCls " 
                      [ngClass]="{ 'is-invalid':submits && f.serviceNumber.errors }" maxlength="50" minlength="1">
                      <div *ngIf="submits && f.serviceNumber.errors" class="invalid-feedback">
                        <div *ngIf="f.serviceNumber.errors.required || f.serviceNumber.errors.whiteSpaceValidate">Service Number is required</div>
                        <div *ngIf="f.serviceNumber.errors.aadharValidate || f.serviceNumber.errors?.pattern  || f.serviceNumber.errors.minlength || f.serviceNumber.errors.maxlength">Please enter valid Service Number</div>
                    </div>
                    </div>
                
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Rank: <span class="mandatoryCls">*</span></label>
                        <input type="text" name="rank" formControlName="rank" class="inputCls " 
                        [ngClass]="{ 'is-invalid':submits && f.rank.errors }" maxlength="50" minlength="1" >
                        <div *ngIf="submits && f.rank.errors" class="invalid-feedback">
                          <div *ngIf="f.rank.errors.required || f.rank.errors.whiteSpaceValidate">Rank is required</div>
                          <div *ngIf="f.rank.errors.aadharValidate || f.rank.errors?.pattern || f.rank.errors.minlength || f.rank.errors.maxlength">Please enter valid Rank</div>
                      </div>
                      </div>

                      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Name if Corps/Records: <span class="mandatoryCls">*</span></label>
                        <input type="text" name="corps" required class="inputCls" formControlName="corps" [ngClass]="{ 'is-invalid':submits && f.corps.errors }"  maxlength="50">
                        <div *ngIf="submits && f.corps.errors" class="invalid-feedback">
                            <div *ngIf="f.corps.errors.required || f.corps.errors.whiteSpaceValidate">Name if Corps/Records is required</div>
                            <div *ngIf="f.corps.errors?.pattern ||  f.corps.errors?.pattern ">Please enter valid Name if Corps/Records</div>
                        </div>
                    </div>
                </div>
                    <div class="row mb-3">
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">  Entry Date of Enrolment:  <span class="mandatoryCls">*</span></label>
                     
                        <div class="input-group">
                          <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                                  name="d5"  ngbDatepicker #d5="ngbDatepicker" [minDate]="minDate"
                                  [maxDate]="maxDate"  formControlName="dateOfEnrolment"  required
                                  [ngClass]="{ 'is-invalid':submits && f.dateOfEnrolment.errors }">
                          <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar"(click)="d5.toggle()" type="button"></button>
                          </div>
                      </div>
                      <div *ngIf="submits &&  f.dateOfEnrolment?.errors" class="selectError">
                        <div *ngIf="f.dateOfEnrolment?.errors?.required || f.dateOfEnrolment?.invalid">Please select Entry Date of Enrolment</div>
                    </div> </div>
                
               
                   <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls"> Date of Discharge:  <span class="mandatoryCls">*</span></label>
                   
                        <div class="input-group">
                          <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                                  name="d3"  ngbDatepicker #d3="ngbDatepicker" [minDate]="minDateOfDateOfDeath" 
                                  [maxDate]="maxDate"  formControlName="dateOfDischarge" 
                                  [ngClass]="{ 'is-invalid':submits && f.dateOfDischarge.errors }">
                          <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar"  (click)="d3.toggle()" type="button"></button>
                          </div>
                      </div>
                      <div *ngIf="submits &&  f.dateOfDischarge?.errors" class="selectError">
                        <div *ngIf="f.dateOfDischarge?.errors?.required || f.dateOfDischarge?.invalid">Please select Date of Discharge</div>
                    </div>   
                 </div>
               
           
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Cause of Discharge: <span class="mandatoryCls">*</span></label>
                        <input type="text" name="causeOfDischarge" required class="inputCls" formControlName="causeOfDischarge" 
                     [ngClass]="{ 'is-invalid':submits && f.causeOfDischarge.errors }"  maxlength="50">
                        <div *ngIf="submits && f.causeOfDischarge.errors" class="invalid-feedback">
                            <div *ngIf="f.causeOfDischarge.errors.required || f.causeOfDischarge.errors.whiteSpaceValidate">Cause of Discharge is required</div>
                            <div *ngIf="f.causeOfDischarge.errors?.pattern">Please enter valid Cause of Discharge</div>
                        </div>
                    </div>
                
            </div>
           </ng-container>

           <ng-container *ngIf="f.category.value === 'ServingSoldier'">
            <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
              <label class="labelCls">Service Number: <span class="mandatoryCls">*</span></label>
              <input type="text" name="serviceNumberSS" formControlName="serviceNumberSS" class="inputCls " 
           [ngClass]="{ 'is-invalid':submits && f.serviceNumberSS.errors }" maxlength="50" minlength="1">
              <div *ngIf="submits && f.serviceNumberSS.errors" class="invalid-feedback">
                <div *ngIf="f.serviceNumberSS.errors.required || f.serviceNumberSS.errors.whiteSpaceValidate">Service Number is required</div>
                <div *ngIf="f.serviceNumberSS.errors.aadharValidate || f.serviceNumberSS.errors?.pattern || f.serviceNumberSS.errors.minlength || f.serviceNumberSS.errors.maxlength">Please enter valid Service Number</div>
            </div>
            </div>
        
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Rank: <span class="mandatoryCls">*</span></label>
                <input type="text" name="rankSS" formControlName="rankSS" class="inputCls " 
               [ngClass]="{ 'is-invalid':submits && f.rankSS.errors }" maxlength="50" minlength="1">
                <div *ngIf="submits && f.rankSS.errors" class="invalid-feedback">
                  <div *ngIf="f.rankSS.errors.required || f.rankSS.errors.whiteSpaceValidate">Rank is required</div>
                  <div *ngIf="f.rankSS.errors.aadharValidate || f.rankSS.errors?.pattern || f.rankSS.errors.minlength || f.rankSS.errors.maxlength">Please enter valid Rank</div>
              </div>
              </div>

              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Name if Corps/Records: <span class="mandatoryCls">*</span></label>
                <input type="text" name="corpsSS"  class="inputCls" 
              formControlName="corpsSS" [ngClass]="{ 'is-invalid':submits && f.corpsSS.errors }"  maxlength="50">
                <div *ngIf="submits && f.corpsSS.errors" class="invalid-feedback">
                    <div *ngIf="f.corpsSS.errors.required  || f.corpsSS.errors.whiteSpaceValidate">Name if Corps/Records is required</div>
                    <div *ngIf="f.corpsSS.errors?.pattern ||  f.corpsSS.errors?.pattern">Please enter valid Name if Corps/Records</div>
                </div>
            </div>
        </div>
            <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">  Entry Date of Enrolment:  <span class="mandatoryCls">*</span></label>
                 
                    <div class="input-group">
                      <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                              name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="minDateOfEnrollment"
                              [maxDate]="maxDate"  formControlName="dateOfEnrolmentSS"  required
                              [ngClass]="{ 'is-invalid':submits && f.dateOfEnrolmentSS.errors }">
                      <div class="input-group-append">
                      <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
                      </div>
                  </div>
                  <div *ngIf="submits &&  f.dateOfEnrolmentSS?.errors" class="selectError">
                    <div *ngIf="f.dateOfEnrolmentSS?.errors?.required || f.dateOfEnrolmentSS?.invalid">Please select Entry Date of Enrolment</div>
                </div> </div>
        </div>
   </ng-container>

   <ng-container *ngIf="f.category.value === 'DeceasedSoldier'">
    <div class="row mb-3">
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">Service Number: <span class="mandatoryCls">*</span></label>
      <input type="text" name="serviceNumberDS" formControlName="serviceNumberDS" class="inputCls " 
     [ngClass]="{ 'is-invalid':submits && f.serviceNumberDS.errors }" maxlength="50" minlength="1">
      <div *ngIf="submits && f.serviceNumberDS.errors" class="invalid-feedback">
        <div *ngIf="f.serviceNumberDS.errors.required || f.serviceNumberDS.errors.whiteSpaceValidate">Service Number is required</div>
        <div *ngIf="f.serviceNumberDS.errors.aadharValidate || f.serviceNumberDS.errors?.pattern || f.serviceNumberDS.errors.minlength || f.serviceNumberDS.errors.maxlength">Please enter valid Service Number</div>
    </div>
    </div>

    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Rank: <span class="mandatoryCls">*</span></label>
        <input type="text" name="rankDS" formControlName="rankDS" class="inputCls " 
       [ngClass]="{ 'is-invalid':submits && f.rankDS.errors }" maxlength="50" minlength="1">
        <div *ngIf="submits && f.rankDS.errors" class="invalid-feedback">
          <div *ngIf="f.rankDS.errors.required || f.rankDS.errors.whiteSpaceValidate">Rank is required</div>
          <div *ngIf="f.rankDS.errors.aadharValidate || f.rankDS.errors?.pattern || f.rankDS.errors.minlength || f.rankDS.errors.maxlength">Please enter valid Rank</div>
      </div>
      </div>

      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Name if Corps/Records: <span class="mandatoryCls">*</span></label>
        <input type="text" name="corpsDS"  class="inputCls" formControlName="corpsDS" 
       [ngClass]="{ 'is-invalid':submits && f.corpsDS.errors }"  maxlength="50">
        <div *ngIf="submits && f.corpsDS.errors" class="invalid-feedback">
            <div *ngIf="f.corpsDS.errors.required  || f.corpsDS.errors.whiteSpaceValidate">Name if Corps/Records is required</div>
            <div *ngIf="f.corpsDS.errors?.pattern || f.corpsDS.errors?.pattern">Please enter valid Name if Corps/Records</div>
        </div>
    </div>
</div>
    <div class="row mb-3">
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">  Date of Enrolment:  <span class="mandatoryCls">*</span></label>
      
        <div class="input-group">
          <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                  name="d4"  ngbDatepicker #d4="ngbDatepicker" [minDate]="minDate" 
                  [maxDate]="maxDate"  formControlName="dateOfEnrolmentDS" 
                  [ngClass]="{ 'is-invalid':submits && f.dateOfEnrolmentDS.errors }">
          <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="d4.toggle()" type="button"></button>
          </div>
      </div>
      <div *ngIf="submits &&  f.dateOfEnrolmentDS?.errors" class="selectError">
        <div *ngIf="f.dateOfEnrolmentDS?.errors?.required || f.dateOfEnrolmentDS?.invalid">Please select Entry Date of Enrolment</div>
    </div> </div>

   <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls"> Date of Death:  <span class="mandatoryCls">*</span></label>
      
        <div class="input-group">
          <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                  name="d6"  ngbDatepicker #d6="ngbDatepicker" [minDate]="minDateOfDateOfDeath" 
                  [maxDate]="maxDate"  formControlName="dateOfDeathDS"
                  [ngClass]="{ 'is-invalid':submits && f.dateOfDeathDS.errors }">
          <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="d6.toggle()" type="button"></button>
          </div>
      </div>
      <div *ngIf="submits &&  f.dateOfDeathDS?.errors" class="selectError">
        <div *ngIf="f.dateOfDeathDS?.errors?.required || f.dateOfDeathDS?.invalid">Please select Date of Death</div>
    </div>    </div>

    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Cause of Death: <span class="mandatoryCls">*</span></label>
        <input type="text" name="causeOfDischargeDS"  class="inputCls"
        formControlName="causeOfDischargeDS" [ngClass]="{ 'is-invalid':submits && f.causeOfDischargeDS.errors }"  maxlength="50">
        <div *ngIf="submits && f.causeOfDischargeDS.errors" class="invalid-feedback">
            <div *ngIf="f.causeOfDischargeDS.errors.required || f.causeOfDischargeDS.errors.whiteSpaceValidate">Cause of Death is required</div>
            <div *ngIf="f.causeOfDischargeDS.errors?.pattern">Please enter valid Cause of Death</div>
        </div>
    </div>
</div>
</ng-container>

 
<hr class="hrCls mt-4">
<h6 class="mb-3">Details of Applicant:</h6>
    <div class="row mb-3">
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <label class="labelCls">Aadhaar Number: <span class="mandatoryCls">*</span></label>
    <input type="text" appBlockCopyPaste name="aadhaarNumber" formControlName="aadhaarNumber" 
    class="inputCls width75" numbersOnly [attr.disabled] = "this.isDisable ? '' : null"
    [ngClass]="{ 'is-invalid':submits && f.aadhaarNumber.errors }" maxlength="12" minlength="12">
   
    <button class="btn btn-primary float-right" (mousedown)="getCitizenInfo()">Pre-Fill</button>
    <div *ngIf="submits && f.aadhaarNumber.errors" class="invalid-feedback">
        <div *ngIf="f.aadhaarNumber.errors.required || f.aadhaarNumber.errors.whiteSpaceValidate">Aadhaar Number is required</div>
        <div *ngIf="f.aadhaarNumber.errors.aadharValidate || f.aadhaarNumber.errors.minlength || f.aadhaarNumber.errors.maxlength">Please enter valid Aadhaar Number</div>
    </div>
    </div>

    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <label class="labelCls">Name of Applicant: <span class="mandatoryCls">*</span></label>
    <input type="text" appBlockCopyPaste name="applicantName1" [attr.disabled] = "(this.isDisable || this.isPrefillDisable) ? '' : null" required class="inputCls" formControlName="applicantName1" [ngClass]="{ 'is-invalid':submits && f.applicantName1.errors }"  maxlength="100">
    <div *ngIf="submits && f.applicantName1.errors" class="invalid-feedback">
     <div *ngIf="f.applicantName1.errors.required || f.applicantName1.errors.whiteSpaceValidate">Applicant Name is required</div>
    <div *ngIf="f.applicantName1.errors?.pattern">Please enter valid Applicant Name</div>
    </div>
    </div>
    <div  class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Relation of the applicant with Soldier:<span class="mandatoryCls">*</span></label>
        <select class="selectCls" required formControlName = "relation" (change)="selectedRelation($event)" 
        [ngClass]="{ 'is-invalid':submits && (f.relation.errors || f.relation.value === 'Select')}">
            <option *ngIf="f.category.value === 'DeceasedSoldier'" value="null" disabled>Select</option>
            <option *ngIf="(f.category.value === 'Ex-Serviceperson' || f.category.value === 'ServingSoldier')" value="self" selected>Self</option>
            <option *ngIf="f.category.value === 'DeceasedSoldier'" value="War Widows">War Widows/ Next of Kin (NoK) of soldier killed in action (Battle Casualties)</option>
            <option *ngIf="f.category.value === 'DeceasedSoldier'" value="Widows">Widows/ Next of Kin (NoK) of soldier died while in service (Physical Casualties)</option>
        </select>
        <div *ngIf="submits && (f.relation.errors || f.relation.value === 'Select')" class="selectError">
            <div *ngIf="f.relation.errors?.required || f.relation.value === 'Select'">Please select Relation of the applicant with Soldier</div>
        </div>
    </div> 
    </div>
                <hr class="hrCls mt-4">
                <div *ngIf="(f.category.value === 'Ex-Serviceperson' || f.category.value === 'ServingSoldier')" class="row ml-1">
                    <div class="subHeadingCls">Aadhaar Authentication of Ex Serviceperson/Serving Soldier:</div>
                </div>
                <div *ngIf="f.category.value === 'DeceasedSoldier'" class="row ml-1">
                    <div class="subHeadingCls">Aadhaar Authentication of the Widow/War Widow/ Next of Kin (NoK) of Deceased soldier:</div>
                </div>
                <br>
                <div class="row mb-3">
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div class="col-sm-9" required 
                            [ngClass]="{ 'is-invalid':submits && f.authenticationType.errors }">
                            <input type="radio" name="authenticationType" value="rbOTP"  (change)="otpChange($event)"
                                formControlName="authenticationType"><span class="mx-2 radioCls">OTP(One Time
                                Password)</span>
                            <input type="radio" name="authenticationType" value="rbBiometric"  (change)="otpChange($event)"
                                formControlName="authenticationType"><span class="mx-2 radioCls">Biometric</span>
                        </div>
                        <div *ngIf="submits && f.authenticationType.errors"
                            class="invalid-feedback col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div *ngIf="f.authenticationType.errors.required">Please select either OTP / Biometric
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="f.authenticationType.value == 'rbOTP'">
                <div class="row mb-3" >  
                    <div class="btnHolder">
                    <button class="greenBtn float-right" [disabled]="isDisable" (click)="sendOTP()">Send OTP</button>
                </div>
                    <div *ngIf="!isDisable" class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">OTP (One Time Password): <span class="mandatoryCls">*</span></label>
                        <input type="password" name="otp"  appBlockCopyPaste class="inputCls" formControlName="otp" required [ngClass]="{ 'is-invalid':submits && f.otp.errors }" numbersOnly minlength="6" maxlength="6"
                        [readonly] ="isPattadarOtpValidated">
                        <div *ngIf="submits && f.otp.errors"
                        class="invalid-feedback">
                        <div *ngIf="f.otp.errors.required">Please enter 6 digit Valid OTP
                        </div>
                        </div>
                    </div>
                </div>
               
                <div class="btnHolder"  *ngIf="f.authenticationType.value == 'rbOTP'">
                    <button class="greenBtnCls float-right"  [disabled]="isPattadarOtpValidated" (click)="validateOTP()" >OTP Authenticate</button>
                </div>
            </div>
           
           
                 <div *ngIf="f.authenticationType.value == 'rbBiometric'"> 
                    <div class="row mb-3">
                      <div class="btnHolder mt-4">
                        <button *ngIf="!showBiometricPopup" class="greenBtnCls float-right" (click)="biometricBtn()">Biometric Authenticate</button>
                        <button *ngIf="!showBiometricPopup" class="greenBtnCls float-right mr-3"  (click)="resetDetails()">Reset</button>
                      </div>
                    </div>
                    <div class="btnHolder" *ngIf="f.authenticationType.value == 'rbBiometric'">
                        <button *ngIf="showBiometricPopup" class="greenBtnCls float-right" (click)="checkBiometricStatus()">Check Biometric Status</button>
                    </div>

                </div>
              
        </form>
    </div>

    <div *ngIf="isEKYCCompleted" class="mainPageContainer mr-3">
        <!-- <div class="mainPageContainer mr-3"> -->
        <form [formGroup]="form">
          <h6 class="mb-3">Assignment of Lands to Ex-Serviceperson/Serving Soldiers</h6>
          <nav ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)">
            <ng-container [ngbNavItem] = "1">
              <a ngbNavLink>Basic Details of Applicant</a>
              <ng-template ngbNavContent>
                <app-common-form formControlName="commonForm" [department]="'RV'" [postalRequired] = "true" [isSubmitted]="commonFormSubmitted" (reset)="commonFormSubmitted = false"></app-common-form>
                <div class="btnHolder">
                  <button  class="greenBtnCls float-right" (click)="goToNextTab()">Continue</button>
                </div>
              </ng-template>
            </ng-container>
            <ng-container [ngbNavItem] = "2">
              <a ngbNavLink>Application Form</a>
              <ng-template ngbNavContent>
                <ng-container>
                
                <h6 class="mb-3">Proposed Request for Grant of Assignment for the Land Located:</h6>
                  <div class="row mb-3">
                                    
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">District: <span class="mandatoryCls">*</span></label>
                        <select class="selectCls" required formControlName="district" (change)="onDistrictChange($event.target)"
                            [ngClass]="{ 'is-invalid':submitted && (f1.district.errors || f1.district.value === 'Select') }">
                            <option [ngValue]="null" disabled>Select</option>
                            <option *ngFor="let district of districts" [ngValue]="district">{{district.name}}</option>
                        </select>
                        <div *ngIf="submitted && (f1.district.errors || f1.district.value === 'Select')" class="selectError">
                            <div *ngIf="f1.district.errors?.required || f1.district.value === 'Select'">Please select
                                District</div>
                        </div>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Mandal: <span class="mandatoryCls">*</span></label>
                        <select class="selectCls" required formControlName="mandal" (change)="onMandalChange($event.target)"
                            [ngClass]="{ 'is-invalid':submitted && (f1.mandal.errors || f1.mandal.value === 'Select') }">
                            <option [ngValue]="null" disabled>Select</option>
                            <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.name}}</option>
                        </select>
                        <div *ngIf="submitted && (f1.mandal.errors || f1.mandal.value === 'Select')" class="selectError">
                            <div *ngIf="f1.mandal.errors?.required || f1.mandal.value === 'Select'">Please select Mandal
                            </div>
                        </div>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Village: <span class="mandatoryCls">*</span></label>
                        <select class="selectCls" required formControlName="village" (change)="onVillageChange()"
                            [ngClass]="{ 'is-invalid':submitted && (f1.village.errors || f1.village.value === 'Select') }">
                            <option [ngValue]="null" disabled>Select</option>
                            <option *ngFor="let village of villages" [ngValue]="village">{{village.name}}</option>
                        </select>
                        <div *ngIf="submitted && (f1.village.errors || f1.village.value === 'Select')" class="selectError">
                            <div *ngIf="f1.village.errors?.required || f1.village.value === 'Select'">Please select
                                Village / Ward</div>
                        </div>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                        <label class="labelCls"> Secretariat List:<span class="mandatoryCls">*</span></label>
                        <select class="selectCls" required formControlName = "psecretariat"  [ngClass]="{ 'is-invalid':submitted && (f1.psecretariat.errors || f1.psecretariat.value === 'Select') }">
                            <option [ngValue]= "null" disabled>Select</option>
                            <option *ngFor="let secretariat of secretariatList" [ngValue]="secretariat">{{secretariat.secretariaT_NAME}}</option>
                        </select>
                        <div *ngIf="submitted && (f1.psecretariat.errors || f1.psecretariat.value === 'Select')" class="selectError">
                            <div *ngIf="f1.psecretariat.errors?.required || f1.psecretariat.value === 'Select'">Please select Secretariat</div>
                        </div>
                    </div>
                </div>  
                <!-- <div *ngIf="showResurvey" class="row mb-3">
                    <div *ngIf="isResurveyVillage" class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Any preferred LP Number ? <span class="mandatoryCls">*</span></label>
                        <input type="text" name="lpNo" required class="inputCls" formControlName="lpNo" [ngClass]="{ 'is-invalid':submitted && f1.lpNo.errors }"  maxlength="50" numbersOnly>
                        <div *ngIf="submitted && f1.lpNo.errors" class="invalid-feedback">
                            <div *ngIf="f1.lpNo.errors.required || f1.lpNo.errors.whiteSpaceValidate">LP number is required</div>
                            <div *ngIf="f1.lpNo.errors?.pattern">Please enter valid LP Number</div>
                        </div>
                    </div>  
                    <div *ngIf="!isResurveyVillage" class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Any preferred Survey Number ? </label>
                        <input type="text" name="syNo" class="inputCls" formControlName="syNo" [ngClass]="{ 'is-invalid':submitted && f1.syNo.errors }"  maxlength="50">
                        <div *ngIf="submitted && f1.syNo.errors" class="invalid-feedback">
                             <div *ngIf="f1.syNo.errors.required || f1.syNo.errors.whiteSpaceValidate">Survey number is required</div> 
                            <div *ngIf="f1.syNo.errors?.pattern">Please enter valid Survey Number</div>
                        </div>
                    </div>   
                </div> -->

                <div *ngIf="showResurvey" class="row mb-3">
                    <div *ngIf="isResurveyVillage == true" class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Any preferred LP Number ? </label>
                        <ng-multiselect-dropdown
                        [placeholder]="'Select'"
                        [settings]="dropdownSettings"
                        [data]="lpNumbertable"
                        name="lpNo"
                        formControlName="lpNo"
                        >
                    </ng-multiselect-dropdown>
                   
                </div>    
            
             <div *ngIf="isResurveyVillage == false" class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Any preferred Survey Number ? </label>
                <ng-multiselect-dropdown
                [placeholder]="'Select'"
                [data]="lpNumbertable"
                [settings]="dropdownSettings"
                name="syNo"
                formControlName="syNo"
                >
            </ng-multiselect-dropdown>
            
                    </div>   
    </div>  
    
            
                <hr class="hrCls mt-4">
                <div class="subHeadingCls">Check list of Zilla Sainik Welfare Officer:</div>
                <div *ngIf="f.category.value === 'Ex-Serviceperson'" class="row mb-3">   
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label class="labelCls">Was the Soldier discharged honourably ?<span class="mandatoryCls">*</span></label>
                    </div>
                    <div class="row mb-3">   
                        <div class="col-sm-12">
                            <input type="radio"  id="YES" [value]="true" formControlName="discharged"/>
                            <label class="mx-2" for="YES">Yes</label>
                            <input type="radio"  id="NO" [checked]="true" [value]="false" formControlName="discharged"/>
                            <label class="mx-2" for="NO" >No</label>
                          </div>
                          <div *ngIf="submitted && (f1.discharged.errors || f1.discharged.value === 'Select')" class="selectError">
                            <div *ngIf="f1.discharged.errors?.required || f1.discharged.value === 'Select'">Please select the Value.</div>
                        </div>
                    </div>
                </div>
                  <hr class="hrCls mt-4">
                  <ng-container *ngIf="f.category.value === 'Ex-Serviceperson'">
                  <div class="subHeadingCls">Documents Upload:</div>
                              <div class="row mb-3">
                                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                  <div class="checkBoxCont">
                                      <label class="labelCls" for="dischargeDocument">Discharge/Service Certificate<span
                                              class="mandatoryCls">*</span></label>
                                  </div>
                                  <div class="input-group mb-3">
                                      <div class="custom-file">
                                          <input type="file" class="custom-file-input" formControlName="dischargeDocument"
                                              id="dischargeDocument" accept=".pdf" (change)="onFileChange($event,'dischargeDocument','dischargeDocumentLabel')"
                                              required
                                              [ngClass]="{ 'is-invalid':submitted && f1.dischargeDocument.errors }">
                                          <label class="custom-file-label" #dischargeDocumentLabel for="dischargeDocument"><i
                                                  class="fas fa-search"></i>No File choosen</label>
                                      </div>
                                      <div class="clearCls"
                                          (click)="clearFileUpload('dischargeDocument', 'dischargeDocumentLabel')"
                                          *ngIf="f1.dischargeDocument.value"></div>
                                  </div>
                                      <div *ngIf="submitted && f1.dischargeDocument.errors" class="selectError">
                                          <div *ngIf="f1.dischargeDocument.errors.required">Please upload Discharge Document </div>
                                          <div
                                              *ngIf="f1.dischargeDocument.errors?.inValidExt && !f1.dischargeDocument.errors.required ">
                                              Please upload Challan Document in PDF Format</div>
                                          <div
                                              *ngIf="f1.dischargeDocument.errors?.inValidSize && !f1.dischargeDocument.errors.required && !f1.dischargeDocument.errors.inValidExt">
                                              File size exceeding 1MB</div>
                                      </div>
                              </div>
                                  <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                      <div class="checkBoxCont">
                                          <label class="labelCls" for="Pension">Pension Payment Order<span
                                                  class="mandatoryCls">*</span></label>
                                      </div>
                                      <div class="input-group mb-3">
                                          <div class="custom-file">
                                              <input type="file" class="custom-file-input" formControlName="Pension"
                                                  id="pension" accept=".pdf" (change)="onFileChange($event,'Pension','PensionLabel')"
                                                  required
                                                  [ngClass]="{ 'is-invalid':submitted && f1.Pension.errors }">
                                              <label class="custom-file-label" #PensionLabel for="Pension"><i
                                                      class="fas fa-search"></i>No File choosen</label>
                                          </div>
                                          <div class="clearCls"
                                              (click)="clearFileUpload('Pension', 'PensionLabel')"
                                              *ngIf="f1.Pension.value"></div>
                                      </div>
                                          <div *ngIf="submitted && f1.Pension.errors" class="selectError">
                                              <div *ngIf="f1.Pension.errors.required">Please
                                                  upload Pension Payment Order </div>
                                              <div
                                                  *ngIf="f1.Pension.errors?.inValidExt && !f1.Pension.errors.required ">
                                                  Please upload Pension Payment Order in PDF Format</div>
                                              <div
                                                  *ngIf="f1.Pension.errors?.inValidSize && !f1.Pension.errors.required && !f1.Pension.errors.inValidExt">
                                                  File size exceeding 1MB</div>
                                          </div>
                                  </div>

                                 
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                      <div class="checkBoxCont">
                                      <label class="labelCls" for="idCard">Ex-Service Men Id Card Issued by Zilla Sainik Welfare Officer (ZSWO):<span class="mandatoryCls">*</span></label>
                                      </div>
                                      <div class="input-group mb-3">
                                        <div class="custom-file">
                                          <input type="file" class="custom-file-input" formControlName="idCard" id="idCard" accept=".jpeg" required
                                          [ngClass]="{ 'is-invalid':submitted && f1.idCard.errors }" (change)="idCardFileSelected($event,'idCard','idCardLabel')">
                                          <label class="custom-file-label" #idCardLabel for="idCard"><i class="fas fa-search"></i>No File choosen</label>
                                        </div>
                                        <div class="clearCls" (click)="clearFileUpload('idCard', 'idCardLabel')" *ngIf="f1.idCard.value"></div>
                                      </div>
                                      <div *ngIf="submitted && f1.idCard.errors" class="selectError">
                                        <div *ngIf="f1.idCard.errors.required">Please upload Ex-Service Men Id Card Issued by Zilla Sainik Welfare Officer (ZSWO)</div>
                                        <div *ngIf="f1.idCard.errors?.inValidExt">Please upload the file in JPEG format</div>
                                        <div *ngIf="f1.idCard.errors?.inValidSize && !f1.idCard.errors.inValidExt">File size exceeding 1MB</div> 
                                      </div>
                                    </div>
   
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="checkBoxCont">
                                        <label class="labelCls" for="signature">Signature of Ex-Serviceperson:<span class="mandatoryCls">*</span></label>
                                        </div>
                                        <div class="input-group mb-3">
                                          <div class="custom-file">
                                            <input type="file" class="custom-file-input" formControlName="signature" id="signature" accept=".jpeg" required
                                            [ngClass]="{ 'is-invalid':submitted && f1.signature.errors }" (change)="idCardFileSelected($event ,'signature','signatureLabel')">
                                            <label class="custom-file-label" #signatureLabel for="signature"><i class="fas fa-search"></i>No File choosen</label>
                                          </div>
                                          <div class="clearCls" (click)="clearFileUpload('signature', 'signatureLabel')" *ngIf="f1.signature.value"></div>
                                        </div>
                                        <div *ngIf="submitted && f1.signature.errors" class="selectError">
                                            <div *ngIf="f1.signature.errors.required">Please upload Signature of Ex-Serviceperson</div>
                                          <div *ngIf="f1.signature.errors?.inValidExt">Please upload the file in JPEG format</div>
                                          <div *ngIf="f1.signature.errors?.inValidSize && !f1.signature.errors.inValidExt">File size exceeding 1MB</div> 
                                        </div>
                                      </div>

                                 </div> 
                                 
                                 
                                 <div class="row mb-3">
                                   
                                      <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="checkBoxCont">
                                            <label *ngIf="f.warDisabled.value === true && f.category.value==='Ex-Serviceperson'" class="labelCls" for="disability">Disability Certificate<span class="mandatoryCls">*</span></label>
                                            <label *ngIf="f.warDisabled.value === false && f.category.value==='Ex-Serviceperson'" class="labelCls" for="disability">Disability Certificate</label>
                                        </div>
                                        <div class="input-group mb-3">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" formControlName="disability"
                                                    id="disability" accept=".pdf" (change)="onFileChange($event,'disability','disabilityLabel')"
                                                    [ngClass]="{ 'is-invalid':submitted && f1.disability.errors }">
                                                <label class="custom-file-label" #disabilityLabel for="disability"><i
                                                        class="fas fa-search"></i>No File choosen</label>
                                            </div>
                                            <div class="clearCls"
                                                (click)="clearFileUpload('disability', 'disabilityLabel')"
                                                *ngIf="f1.disability.value"></div>
                                        </div>
                                            <div *ngIf="submitted && f1.disability.errors" class="selectError">
                                                <div *ngIf="f1.disability.errors.required">Please
                                                    upload Disability Certificate</div>
                                                <div
                                                    *ngIf="f1.disability.errors?.inValidExt && !f1.disability.errors.required ">
                                                    Please upload Disability Certificate in PDF Format</div>
                                                <div
                                                    *ngIf="f1.disability.errors?.inValidSize && !f1.disability.errors.required && !f1.disability.errors.inValidExt">
                                                    File size exceeding 1MB</div>
                                            </div>
                                    </div>

                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="checkBoxCont">
                                            <label class="labelCls" for="VerificationDocumentsEX">Verification report by ZSWO:<span class="mandatoryCls">*</span></label>
                                        </div>
                                        <div class="input-group mb-3">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" formControlName="VerificationDocumentsEX"
                                                    id="VerificationDocumentsEX" accept=".pdf" (change)="onFileChange($event,'VerificationDocumentsEX','VerificationDocumentsEXLabelEX')"
                                                    required
                                                    [ngClass]="{ 'is-invalid':submitted && f1.VerificationDocumentsEX.errors }">
                                                <label class="custom-file-label" #VerificationDocumentsEXLabelEX for="VerificationDocumentsEX"><i
                                                        class="fas fa-search"></i>No File choosen</label>
                                            </div>
                                            <div class="clearCls"
                                                (click)="clearFileUpload('VerificationDocumentsEX', 'VerificationDocumentsEXLabelEX')"
                                                *ngIf="f1.VerificationDocumentsEX.value"></div>
                                        </div>
                                            <div *ngIf="submitted && f1.VerificationDocumentsEX.errors" class="selectError">
                                                <div *ngIf="f1.VerificationDocumentsEX.errors.required">Please
                                                    upload  Verification report by ZSWO</div>
                                                <div
                                                    *ngIf="f1.VerificationDocumentsEX.errors?.inValidExt && !f1.VerificationDocumentsEX.errors.required ">
                                                    Please upload Verification report by ZSWO in PDF Format</div>
                                                <div
                                                    *ngIf="f1.VerificationDocumentsEX.errors?.inValidSize && !f1.VerificationDocumentsEX.errors.required && !f1.VerificationDocumentsEX.errors.inValidExt">
                                                    File size exceeding 1MB</div>
                                            </div>
                                    </div>

                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="checkBoxCont">
                                            <label class="labelCls" for="supportingDocuments">Any Supporting Documents</label>
                                        </div>
                                        <div class="input-group mb-3">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" formControlName="supportingDocuments"
                                                    id="supportingDocuments" accept=".pdf" (change)="onFileChange($event,'supportingDocuments','supportingDocumentsLabel')"
                                                    [ngClass]="{ 'is-invalid':submitted && f1.supportingDocuments.errors }">
                                                <label class="custom-file-label" #supportingDocumentsLabel for="supportingDocuments"><i
                                                        class="fas fa-search"></i>No File choosen</label>
                                            </div>
                                            <div class="clearCls"
                                                (click)="clearFileUpload('supportingDocuments', 'supportingDocumentsLabel')"
                                                *ngIf="f1.supportingDocuments.value"></div>
                                        </div>
                                            <div *ngIf="submitted && f1.supportingDocuments.errors" class="selectError">
                                                <div
                                                    *ngIf="f1.supportingDocuments.errors?.inValidExt && !f1.supportingDocuments.errors.required ">
                                                    Please upload  Supporting Documents in PDF Format</div>
                                                <div
                                                    *ngIf="f1.supportingDocuments.errors?.inValidSize && !f1.supportingDocuments.errors.required && !f1.supportingDocuments.errors.inValidExt">
                                                    File size exceeding 1MB</div>
                                            </div>
                                    </div>
  
                                    </div> 
                  </ng-container>

                  <ng-container *ngIf="f.category.value === 'ServingSoldier'">
                    <div class="subHeadingCls">Documents Upload:</div>
                                <div class="row mb-3">
                                  <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div class="checkBoxCont">
                                        <label class="labelCls" for="serviceDocument">Service Certificate from Commanding Office<span
                                                class="mandatoryCls">*</span></label>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" formControlName="serviceDocument"
                                                id="serviceDocument" accept=".pdf" (change)="onFileChange($event,'serviceDocument','serviceDocumentLabel')"
                                                required
                                                [ngClass]="{ 'is-invalid':submitted && f1.serviceDocument.errors }">
                                            <label class="custom-file-label" #serviceDocumentLabel for="serviceDocument"><i
                                                    class="fas fa-search"></i>No File choosen</label>
                                        </div>
                                        <div class="clearCls"
                                            (click)="clearFileUpload('serviceDocument', 'serviceDocumentLabel')"
                                            *ngIf="f1.serviceDocument.value"></div>
                                    </div>
                                        <div *ngIf="submitted && f1.serviceDocument.errors" class="selectError">
                                            <div *ngIf="f1.serviceDocument.errors.required">Please upload Service Certificate from Commanding Office </div>
                                            <div
                                                *ngIf="f1.serviceDocument.errors?.inValidExt && !f1.serviceDocument.errors.required ">
                                                Please upload Service Certificate from Commanding Office in PDF Format</div>
                                            <div
                                                *ngIf="f1.serviceDocument.errors?.inValidSize && !f1.serviceDocument.errors.required && !f1.serviceDocument.errors.inValidExt">
                                                File size exceeding 1MB</div>
                                        </div>
                                </div>
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="checkBoxCont">
                                            <label class="labelCls" for="Posting">Current Regiment/Posting Details<span
                                                    class="mandatoryCls">*</span></label>
                                        </div>
                                        <div class="input-group mb-3">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" formControlName="Posting"
                                                    id="Posting" accept=".pdf" (change)="onFileChange($event,'Posting','PostingLabel')"
                                                    required
                                                    [ngClass]="{ 'is-invalid':submitted && f1.Posting.errors }">
                                                <label class="custom-file-label" #PostingLabel for="Posting"><i
                                                        class="fas fa-search"></i>No File choosen</label>
                                            </div>
                                            <div class="clearCls"
                                                (click)="clearFileUpload('Posting', 'PostingLabel')"
                                                *ngIf="f1.Posting.value"></div>
                                        </div>
                                            <div *ngIf="submitted && f1.Posting.errors" class="selectError">
                                                <div *ngIf="f1.Posting.errors.required">Please
                                                    upload Current Regiment/Posting Details </div>
                                                <div
                                                    *ngIf="f1.Posting.errors?.inValidExt && !f1.Posting.errors.required ">
                                                    Please upload Current Regiment/Posting Details in PDF Format</div>
                                                <div
                                                    *ngIf="f1.Posting.errors?.inValidSize && !f1.Posting.errors.required && !f1.Posting.errors.inValidExt">
                                                    File size exceeding 1MB</div>
                                            </div>
                                    </div>
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="checkBoxCont">
                                        <label class="labelCls" for="signatureSS">Signature of Serving Soldier:<span class="mandatoryCls">*</span></label>
                                        </div>
                                        <div class="input-group mb-3">
                                          <div class="custom-file">
                                            <input type="file" class="custom-file-input" formControlName="signatureSS" id="signatureSS" accept=".jpeg" required
                                            [ngClass]="{ 'is-invalid':submitted && f1.signatureSS.errors }" (change)="idCardFileSelected($event ,'signatureSS','signatureLabelSS')">
                                            <label class="custom-file-label" #signatureLabelSS for="signatureSS"><i class="fas fa-search"></i>No File choosen</label>
                                          </div>
                                          <div class="clearCls" (click)="clearFileUpload('signatureSS', 'signatureLabelSS')" *ngIf="f1.signatureSS.value"></div>
                                        </div>
                                        <div *ngIf="submitted && f1.signatureSS.errors" class="selectError">
                                          <div *ngIf="f1.signatureSS.errors.required">Please upload Signature of Serving Soldier</div>
                                          <div *ngIf="f1.signatureSS.errors?.inValidExt">Please upload the file in JPEG format</div>
                                          <div *ngIf="f1.signatureSS.errors?.inValidSize && !f1.signatureSS.errors.inValidExt">File size exceeding 1MB</div> 
                                        </div>
                                      </div>
                                      <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="checkBoxCont">
                                            <label class="labelCls" for="VerificationDocumentsSS">Verification report by ZSWO:<span class="mandatoryCls">*</span></label>
                                        </div>
                                        <div class="input-group mb-3">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" formControlName="VerificationDocumentsSS"
                                                    id="VerificationDocumentsSS" accept=".pdf" (change)="onFileChange($event,'VerificationDocumentsSS','VerificationDocumentsSSLabelSS')"
                                                    required
                                                    [ngClass]="{ 'is-invalid':submitted && f1.VerificationDocumentsSS.errors }">
                                                <label class="custom-file-label" #VerificationDocumentsSSLabelSS for="VerificationDocumentsSS"><i
                                                        class="fas fa-search"></i>No File choosen</label>
                                            </div>
                                            <div class="clearCls"
                                                (click)="clearFileUpload('VerificationDocumentsSS', 'VerificationDocumentsSSLabelSS')"
                                                *ngIf="f1.VerificationDocumentsSS.value"></div>
                                        </div>
                                            <div *ngIf="submitted && f1.VerificationDocumentsSS.errors" class="selectError">
                                                <div *ngIf="f1.VerificationDocumentsSS.errors.required">Please
                                                    upload  Verification report by ZSWO</div>
                                                <div
                                                    *ngIf="f1.VerificationDocumentsSS.errors?.inValidExt && !f1.VerificationDocumentsSS.errors.required ">
                                                    Please upload Verification report by ZSWO in PDF Format</div>
                                                <div
                                                    *ngIf="f1.VerificationDocumentsSS.errors?.inValidSize && !f1.VerificationDocumentsSS.errors.required && !f1.VerificationDocumentsSS.errors.inValidExt">
                                                    File size exceeding 1MB</div>
                                            </div>
                                    </div>

                                   </div> 
                                   
                                   <div class="row mb-3">
                                       
                                      <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                          <div class="checkBoxCont">
                                              <label class="labelCls" for="supportingDocumentsSS">Any Supporting Documents</label>
                                          </div>
                                          <div class="input-group mb-3">
                                              <div class="custom-file">
                                                  <input type="file" class="custom-file-input" formControlName="supportingDocumentsSS"
                                                      id="supportingDocumentsSS" accept=".pdf" (change)="onFileChange($event,'supportingDocumentsSS','supportingDocumentsLabelSS')"
                                                      [ngClass]="{ 'is-invalid':submitted && f1.supportingDocumentsSS.errors }">
                                                  <label class="custom-file-label" #supportingDocumentsLabelSS for="supportingDocumentsSS"><i
                                                          class="fas fa-search"></i>No File choosen</label>
                                              </div>
                                              <div class="clearCls"
                                                  (click)="clearFileUpload('supportingDocumentsSS', 'supportingDocumentsLabelSS')"
                                                  *ngIf="f1.supportingDocumentsSS.value"></div>
                                          </div>
                                              <div *ngIf="submitted && f1.supportingDocumentsSS.errors" class="selectError">
                                                  <div
                                                      *ngIf="f1.supportingDocumentsSS.errors?.inValidExt && !f1.supportingDocumentsSS.errors.required ">
                                                      Please upload  Supporting Documents in PDF Format</div>
                                                  <div
                                                      *ngIf="f1.supportingDocumentsSS.errors?.inValidSize && !f1.supportingDocumentsSS.errors.required && !f1.supportingDocumentsSS.errors.inValidExt">
                                                      File size exceeding 1MB</div>
                                              </div>
                                      </div>
    
                                      </div> 
                    </ng-container>

                    <ng-container *ngIf="f.category.value === 'DeceasedSoldier'">
                        <div class="subHeadingCls">Documents Upload:</div>
                                    <div class="row mb-3">
                                      <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <div class="checkBoxCont">
                                            <label class="labelCls" for="deceasedSoldier">Service Particulars of Deceased Soldier<span
                                                    class="mandatoryCls">*</span></label>
                                        </div>
                                        <div class="input-group mb-3">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" formControlName="deceasedSoldier"
                                                    id="deceasedSoldier" accept=".pdf" (change)="onFileChange($event,'deceasedSoldier','deceasedSoldierLabel')"
                                                    required
                                                    [ngClass]="{ 'is-invalid':submitted && f1.deceasedSoldier.errors }">
                                                <label class="custom-file-label" #deceasedSoldierLabel for="deceasedSoldier"><i
                                                        class="fas fa-search"></i>No File choosen</label>
                                            </div>
                                            <div class="clearCls"
                                                (click)="clearFileUpload('deceasedSoldier', 'deceasedSoldierLabel')"
                                                *ngIf="f1.deceasedSoldier.value"></div>
                                        </div>
                                            <div *ngIf="submitted && f1.deceasedSoldier.errors" class="selectError">
                                                <div *ngIf="f1.deceasedSoldier.errors.required">Please upload Service Particulars of Deceased Soldier </div>
                                                <div
                                                    *ngIf="f1.deceasedSoldier.errors?.inValidExt && !f1.deceasedSoldier.errors.required ">
                                                    Please upload Challan Document in PDF Format</div>
                                                <div
                                                    *ngIf="f1.deceasedSoldier.errors?.inValidSize && !f1.deceasedSoldier.errors.required && !f1.deceasedSoldier.errors.inValidExt">
                                                    File size exceeding 1MB</div>
                                            </div>
                                    </div>
                                        <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div class="checkBoxCont">
                                                <label class="labelCls" for="PensionDS">Pension Payment Order<span
                                                        class="mandatoryCls">*</span></label>
                                            </div>
                                            <div class="input-group mb-3">
                                                <div class="custom-file">
                                                    <input type="file" class="custom-file-input" formControlName="PensionDS"
                                                        id="pension" accept=".pdf" (change)="onFileChange($event,'PensionDS','PensionLabelDS')"
                                                        required
                                                        [ngClass]="{ 'is-invalid':submitted && f1.PensionDS.errors }">
                                                    <label class="custom-file-label" #PensionLabelDS for="PensionDS"><i
                                                            class="fas fa-search"></i>No File choosen</label>
                                                </div>
                                                <div class="clearCls"
                                                    (click)="clearFileUpload('PensionDS', 'PensionLabelDS')"
                                                    *ngIf="f1.PensionDS.value"></div>
                                            </div>
                                                <div *ngIf="submitted && f1.PensionDS.errors" class="selectError">
                                                    <div *ngIf="f1.PensionDS.errors.required">Please
                                                        upload Pension Payment Order </div>
                                                    <div
                                                        *ngIf="f1.PensionDS.errors?.inValidExt && !f1.PensionDS.errors.required ">
                                                        Please upload Pension Payment Order in PDF Format</div>
                                                    <div
                                                        *ngIf="f1.PensionDS.errors?.inValidSize && !f1.PensionDS.errors.required && !f1.PensionDS.errors.inValidExt">
                                                        File size exceeding 1MB</div>
                                                </div>
                                        </div>
      
                                       
                                          <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div class="checkBoxCont">
                                            <label class="labelCls" for="Widow">Widow/Next Kin Id Card Issued by Zilla Sainik Welfare Officer (ZSWO):<span class="mandatoryCls">*</span></label>
                                            </div>
                                            <div class="input-group mb-3">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" formControlName="Widow" id="Widow" accept=".jpeg" required
                                                [ngClass]="{ 'is-invalid':submitted && f1.Widow.errors }" (change)="idCardFileSelected($event,'Widow','WidowLabel')">
                                                <label class="custom-file-label" #WidowLabel for="Widow"><i class="fas fa-search"></i>No File choosen</label>
                                              </div>
                                              <div class="clearCls" (click)="clearFileUpload('Widow', 'WidowLabel')" *ngIf="f1.Widow.value"></div>
                                            </div>
                                            <div *ngIf="submitted && f1.Widow.errors" class="selectError">
                                                <div *ngIf="f1.Widow.errors.required">Please upload Widow/Next Kin Id Card Issued by Zilla Sainik Welfare Officer (ZSWO)</div>
                                              <div *ngIf="f1.Widow.errors?.inValidExt">Please upload the file in JPEG format</div>
                                              <div *ngIf="f1.Widow.errors?.inValidSize && !f1.Widow.errors.inValidExt">File size exceeding 1MB</div> 
                                            </div>
                                          </div>
         
                                          <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div class="checkBoxCont">
                                            <label class="labelCls" for="signatureDS">Signature of Relation with Deceased Soldier:<span class="mandatoryCls">*</span></label>
                                            </div>
                                            <div class="input-group mb-3">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" formControlName="signatureDS" id="signatureDS" accept=".jpeg" required
                                                [ngClass]="{ 'is-invalid':submitted && f1.signatureDS.errors }" (change)="idCardFileSelected($event ,'signatureDS','signatureLabelDS')">
                                                <label class="custom-file-label" #signatureLabelDS for="signatureDS"><i class="fas fa-search"></i>No File choosen</label>
                                              </div>
                                              <div class="clearCls" (click)="clearFileUpload('signatureDS', 'signatureLabelDS')" *ngIf="f1.signatureDS.value"></div>
                                            </div>
                                            <div *ngIf="submitted && f1.signatureDS.errors" class="selectError">
                                              <div *ngIf="f1.signatureDS.errors.required">Please upload Signature of Relation with Deceased Soldier</div>
                                              <div *ngIf="f1.signatureDS.errors?.inValidExt">Please upload the file in JPEG format</div>
                                              <div *ngIf="f1.signatureDS.errors?.inValidSize && !f1.signatureDS.errors.inValidExt">File size exceeding 1MB</div> 
                                            </div>
                                          </div>
    
                                       </div> 
                                       
                                       <div class="row mb-3">
                                          
                                            <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                              <div class="checkBoxCont">
                                                  <label class="labelCls" for="deathDS">Death Certificate<span
                                                          class="mandatoryCls">*</span></label>
                                              </div>
                                              <div class="input-group mb-3">
                                                  <div class="custom-file">
                                                      <input type="file" class="custom-file-input" formControlName="deathDS"
                                                          id="deathDS" accept=".pdf" (change)="onFileChange($event,'deathDS','deathDSLabel')"
                                                          required
                                                          [ngClass]="{ 'is-invalid':submitted && f1.deathDS.errors }">
                                                      <label class="custom-file-label" #deathDSLabel for="deathDS"><i
                                                              class="fas fa-search"></i>No File choosen</label>
                                                  </div>
                                                  <div class="clearCls"
                                                      (click)="clearFileUpload('deathDS', 'deathDSLabel')"
                                                      *ngIf="f1.deathDS.value"></div>
                                              </div>
                                                  <div *ngIf="submitted && f1.deathDS.errors" class="selectError">
                                                      <div *ngIf="f1.deathDS.errors.required">Please
                                                          upload Death Certificate</div>
                                                      <div
                                                          *ngIf="f1.deathDS.errors?.inValidExt && !f1.deathDS.errors.required ">
                                                          Please upload Death Certificate in PDF Format</div>
                                                      <div
                                                          *ngIf="f1.deathDS.errors?.inValidSize && !f1.deathDS.errors.required && !f1.deathDS.errors.inValidExt">
                                                          File size exceeding 1MB</div>
                                                  </div>
                                          </div>
                                          <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div class="checkBoxCont">
                                                <label class="labelCls" for="VerificationDocumentsDS">Verification report by ZSWO:<span class="mandatoryCls">*</span></label>
                                            </div>
                                            <div class="input-group mb-3">
                                                <div class="custom-file">
                                                    <input type="file" class="custom-file-input" formControlName="VerificationDocumentsDS"
                                                        id="VerificationDocumentsDS" accept=".pdf" (change)="onFileChange($event,'VerificationDocumentsDS','VerificationDocumentsDSLabelDS')"
                                                        required
                                                        [ngClass]="{ 'is-invalid':submitted && f1.VerificationDocumentsDS.errors }">
                                                    <label class="custom-file-label" #VerificationDocumentsDSLabelDS for="VerificationDocumentsDS"><i
                                                            class="fas fa-search"></i>No File choosen</label>
                                                </div>
                                                <div class="clearCls"
                                                    (click)="clearFileUpload('VerificationDocumentsDS', 'VerificationDocumentsDSLabelDS')"
                                                    *ngIf="f1.VerificationDocumentsDS.value"></div>
                                            </div>
                                                <div *ngIf="submitted && f1.VerificationDocumentsDS.errors" class="selectError">
                                                    <div *ngIf="f1.VerificationDocumentsDS.errors.required">Please
                                                        upload  Verification report by ZSWO</div>
                                                    <div
                                                        *ngIf="f1.VerificationDocumentsDS.errors?.inValidExt && !f1.VerificationDocumentsDS.errors.required ">
                                                        Please upload Verification report by ZSWO in PDF Format</div>
                                                    <div
                                                        *ngIf="f1.VerificationDocumentsDS.errors?.inValidSize && !f1.VerificationDocumentsDS.errors.required && !f1.VerificationDocumentsDS.errors.inValidExt">
                                                        File size exceeding 1MB</div>
                                                </div>
                                        </div>
      
                                          <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                              <div class="checkBoxCont">
                                                  <label class="labelCls" for="supportingDocumentsDS">Any Supporting Documents</label>
                                              </div>
                                              <div class="input-group mb-3">
                                                  <div class="custom-file">
                                                      <input type="file" class="custom-file-input" formControlName="supportingDocumentsDS"
                                                          id="supportingDocumentsDS" accept=".pdf" (change)="onFileChange($event,'supportingDocumentsDS','supportingDocumentsLabelDS')"
                                                          [ngClass]="{ 'is-invalid':submitted && f1.supportingDocumentsDS.errors }">
                                                      <label class="custom-file-label" #supportingDocumentsLabelDS for="supportingDocumentsDS"><i
                                                              class="fas fa-search"></i>No File choosen</label>
                                                  </div>
                                                  <div class="clearCls"
                                                      (click)="clearFileUpload('supportingDocumentsDS', 'supportingDocumentsLabelDS')"
                                                      *ngIf="f1.supportingDocumentsDS.value"></div>
                                              </div>
                                                  <div *ngIf="submitted && f1.supportingDocumentsDS.errors" class="selectError">
                                                      <div
                                                          *ngIf="f1.supportingDocumentsDS.errors?.inValidExt && !f1.supportingDocumentsDS.errors.required ">
                                                          Please upload  Supporting Documents in PDF Format</div>
                                                      <div
                                                          *ngIf="f1.supportingDocumentsDS.errors?.inValidSize && !f1.supportingDocumentsDS.errors.required && !f1.supportingDocumentsDS.errors.inValidExt">
                                                          File size exceeding 1MB</div>
                                                  </div>
                                          </div>
                                    </div> 

                        </ng-container>
                       <div class="row mb-3">
                        <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="checkBoxCont">
                                <label class="labelCls">Applicant Photo</label>
                            </div>
                            <div class="input-group mb-3">
                                    <img *ngIf="photo" [src]="photo" style="width:70px; height:70px;">
                            </div>
                        </div>
                    </div>
                <div class="btnHolder">
                  <button class="greenBtn float-right"  (click) = "saveRequisition()">Submit</button>
                </div>
              
              </ng-container>
             
              </ng-template>
            </ng-container>
          </nav>
          <div [ngbNavOutlet]="nav"></div>
        </form>
        
      </div>
</div>


