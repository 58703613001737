import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-suomoto-caste-verification-report',
  templateUrl: './suomoto-caste-verification-report.component.html',
  styleUrls: ['./suomoto-caste-verification-report.component.css']
})
export class SuomotoCasteVerificationReportComponent implements OnInit {

    form: FormGroup;
    items: any[] = [];
    totalItems:any=[];
    showReports:Boolean=true;
    submitted:Boolean = false;
    page = 1;
    pageSize = 10;
    noOfCol: any = 15;
    commonConstants : any = CommonConstants;
    @ViewChild('TABLE') table!: ElementRef;
    userData: any;
  
    constructor(private formBuilder: FormBuilder, private commonService: CommonService,private router: Router) { 
    this.form = this.formBuilder.group({
      status: [null],
    });
    let userInfo = localStorage.getItem('userInfo');
    if (userInfo && userInfo !== null) {
      this.userData = JSON.parse(userInfo);
      
    }
   }
  
  ngOnInit(): void {    
    this.form.patchValue({
      status:'All',
    });
    this.getReportData()
    
  }
  get f() { return this.form.controls; }
  
  onStatusChange(){
    this.getReportData()
  }
  getReportData(): any {    
        this.page = 1;       
        let status = this.f.status.value 
     
        let postData: any = '?UserId='+ this.userData.userPrincipalName +'&Status=' + status  ;
        this.commonService.getRequest(this.commonConstants.suomotoCasteReport + postData).subscribe({
          next: (responseData: any) => { 
            console.log(typeof(responseData?.result),'result man')
            if(typeof(responseData?.result) !== 'string') {
                this.showReports = true;
                this.items = responseData?.result;
                this.items = this.items.filter(item => item.totalflag !== 'Y')
                this.totalItems = responseData?.result.filter((item: { totalflag: string; }) => item.totalflag === 'Y')
              } else {
              this.items = [];
              this.showReports= true;
              this.totalItems = [];
              //this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
      });
      }
      downloadExcel() {
        let fileName: any = 'SUOMOTO CASTE VERIFICATION REPORT' //+ this.f.fromDate.value +' to '+ this.f.toDate.value;
        const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
        let wscols = [
          {wpx:50},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100},
          {wpx:100}
      ];
      
      ws['!cols'] = wscols;
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'SECRETARIAT PAN UPDATED REPORT');
        
        /* save to file */
        XLSX.writeFile(wb, fileName + '.xlsx');
      }
      saveAsExcelFile(buffer: any, fileName: string) {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
      }
      goToHome() {
        this.router.navigate(['/home']);
      }
    
    
  }
  
