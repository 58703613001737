import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/shared/common.service';
import { CommonConstants } from 'src/constants/common-constants.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerPopupComponent } from '../../../shared/pdf-viewer-popup/pdf-viewer-popup.component';
@Component({
  selector: 'app-patient-bankdetails-view',
  templateUrl: './patient-bankdetails-view.component.html',
  styleUrls: ['./patient-bankdetails-view.component.css']
})
export class PatientBankDetailsViewComponent implements OnInit {
  commonConstants : any = CommonConstants;
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
 
  constructor(private commonService: CommonService, private modalService: NgbModal,config: NgbModalConfig) { }

  ngOnInit(): void {
    if(this.certificateInfo) {
      this.certificateInfo.accountNo = this.commonService.RSADecrypt(this.certificateInfo.accountNo);
      this.certificateInfo.acHolderType = this.certificateInfo.acHolderType == "S" ? "Self" : "Family Member";
    }
  }

  viewFile(blobRef:any, fileName:any) {
    console.log('file Extension', this.checkFileExtension(fileName));
    this.commonService.getRequest(this.commonConstants.viewFile + blobRef).subscribe({
      next: (responseData: any) => { 
        let fileInfo =  responseData.result.fileContents;
        const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
        modalRef.componentInstance.sourceFile = fileInfo;
        modalRef.componentInstance.showPrintDownload = false;
        if (this.checkFileExtension(fileName) !== 'pdf') {
          modalRef.componentInstance.isImage = true;
          modalRef.componentInstance.sourceFile = 'data:image/png;base64,' +fileInfo;
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  
  checkFileExtension(name: any) {
    let fileNameArr = name.split('.');
    return fileNameArr[fileNameArr?.length - 1]?.toLowerCase();
  }
   
  downloadFile(file: any) {
    this.download.emit(file);
  }
}
