import { Component, OnInit ,ViewChild, ElementRef } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { CommonService } from '../../shared/common.service';
import { Router } from '@angular/router';
import {NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-viksit-bharat-report',
  templateUrl: './viksit-bharat-report.component.html',
  styleUrls: ['./viksit-bharat-report.component.css']
})
export class ViksitBharatReportComponent implements OnInit {
  commonConstants : any = CommonConstants;
  items: any[] = [];
  totalItems: any[] = [];
  page = 1;
  pageSize = 10;
  role: any;
  showReports:Boolean=false;
  submitted:Boolean=false;
  noOfCol: any = 31;
  @ViewChild('TABLE') table!: ElementRef;
  constructor(private commonService: CommonService,private router: Router) { }

  ngOnInit(): void {
    this.getViksitBharatReport();
  }
 
  goToHome() {
    this.router.navigate(['/home']);
  }

  getViksitBharatReport(): any {
    this.submitted = true;
        this.page = 1;
        this.commonService.getRequest(this.commonConstants.getViksitBharatReport ).subscribe({
          next: (responseData: any) => { 
            console.log(typeof(responseData?.result),'result man')
            if(typeof(responseData?.result) !== 'string') {
                this.showReports = true;
                this.items = responseData?.result;
                  this.items = this.items.filter(item => item.totalFlag !== 'Y')
                  this.totalItems = responseData?.result.filter((item: { totalFlag: string; }) => item.totalFlag === 'Y')
                  console.log(this.items,'items')
                  console.log( this.totalItems ,'total items')
            } else {
              this.items = [];
              this.totalItems = [];
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
      });
    }
    downloadExcel() {
      let fileName: any = 'District level balance stationary balance report as on date' 
      const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
      let wscols = [
        {wpx:50},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100}
    ];
    
    ws['!cols'] = wscols;
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Stationary Balance Report');
      
      /* save to file */
      XLSX.writeFile(wb, fileName + '.xlsx');
    }
}
