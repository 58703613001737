<div class="reportContainer">
    <div [formGroup]="form">
        <div class="row mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
            <select class="selectCls"  formControlName="district" (change)="onDistrictChange()"
            [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
                <option value="All">All</option>
                <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
            </select>  
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Rural/Urban : <span class="mandatoryCls">*</span></label> 
                <select class="selectCls" required formControlName="RUFlag" (change)="onRuralUrbanChange()"
                [ngClass]="{ 'is-invalid':submitted && (f.RUFlag.errors || f.RUFlag.value === 'Select') }">
                <option value = "All">All</option>
                <option value = "R">Rural</option>
                <option value = "U">Urban</option>
                </select>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Mandal <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
              [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
            <option value="All">All</option>
            <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
            </select>
         
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Secretariat: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="village"  (change)="onVillageChange()"  >
                <option value="All">All</option>
                <option *ngFor="let village of villages" [ngValue]="village">{{village.secretariatName}}</option>
            </select>
            
            </div>
        </div>
    </div>
        <div class="tabClass">
            <ul>
                <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li> 
            </ul>
            <div class="btnHolder">
                <button  class="greenBtnCls float-right my-3" (click)="getUCReportData()">Get Reports</button>
                <button  class="greenBtnCls float-right mr-3 my-3"  *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
                <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
            </div>
          </div>
        <div class="table-responsive tableFixHead" *ngIf="showReports">
            <table class="tableCls">
                <thead>
                    <tr>
                        <th [attr.colspan]="noOfCol">Utilization Certificate Report</th>
                    </tr>
                    <tr>
                        <th rowspan="3">S No</th>
                        <th rowspan="3" *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th rowspan="3" *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_MANDALS'">No of Mandals <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th rowspan="3" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th rowspan="3" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'ruR_URBAN'"> Rural/Urban <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th rowspan="3" *ngIf="format === 'District Wise'  || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_SEC'">No of Secretariats<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th rowspan="3" *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'">Secretariat Code<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th rowspan="3" *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'">Secretariat <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise'" colspan="10">Suraksha</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="7">Suraksha</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="5">Suraksha</th>
                        <th *ngIf="format === 'District Wise'" colspan="10">Adudham Andhra</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="7">Adudham Andhra</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="5">Adudham Andhra</th>
                        <th *ngIf="format === 'District Wise'" colspan="10">Aarogya Suraksha</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="8">Aarogya Suraksha</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="5">Aarogya Suraksha</th>
                    </tr>
                    <tr>
                        <th *ngIf="format === 'District Wise'" colspan="3">Fund Received</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="2">Fund Received</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="1">Fund Received</th>
                        <th *ngIf="format === 'District Wise'" colspan="4">UC Uploaded</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="3">UC Uploaded</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="3">UC Uploaded</th>
                        <th *ngIf="format === 'District Wise'" colspan="3">UC Pending</th>
                        <th *ngIf="format === 'Mandal Wise'"  colspan="2">UC Pending</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="1">UC Pending</th>
                        <th *ngIf="format === 'District Wise'" colspan="3">Fund Received</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="2">Fund Received</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="1">Fund Received</th>
                        <th *ngIf="format === 'District Wise'" colspan="4">UC Uploaded</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="3">UC Uploaded</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="3">UC Uploaded</th>
                        <th *ngIf="format === 'District Wise'" colspan="3">UC Pending</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="2">UC Pending</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="1">UC Pending</th>
                        <!-- jas -->
                        <th *ngIf="format === 'District Wise'" colspan="3">Fund Received</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="2">Fund Received</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="1">Fund Received</th>
                        <th *ngIf="format === 'District Wise'" colspan="4">UC Uploaded</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="4">UC Uploaded</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="3">UC Uploaded</th>
                        <th *ngIf="format === 'District Wise'" colspan="3">UC Pending</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="2">UC Pending</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="1">UC Pending</th>
                    </tr>
                    <tr>
                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_MID_JS_RCVD'">No of Mandals <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise'  || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_SEC_JS_RCVD'">No of Secretariates <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jS_AMT_RELEASED'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                       
                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_JS_MID_AMT_UTILIZED'">No of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise'  || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_JS_SEC_AMT_UTILIZED'">No of Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jS_AMT_UTILIZED'">Spent Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jS_AMT_BALANCE'">Balance Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                        
                        <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jS_UC_FILE'">Utilization Certificate<mdb-icon fas icon="sort"></mdb-icon></th>

                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jS_PENDING_MID'">No of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jS_PENDING_SEC'">No of Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jS_PENDING_AMT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                      
                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_MID_AA_RCVD'">No of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_SEC_AA_RCVD'">No of Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'aA_AMT_RELEASED'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                        
                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_AA_MID_AMT_UTILIZED'">No of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise'  || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_AA_SEC_AMT_UTILIZED'">No of Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'aA_AMT_UTILIZED'">Spent Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'aA_AMT_BALANCE'">Balance Amount <mdb-icon fas icon="sort"></mdb-icon></th>

                        <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'aA_UC_FILE'">Utilization Certificate<mdb-icon fas icon="sort"></mdb-icon></th>

                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'aA_PENDING_MID'">No of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'aA_PENDING_SEC'">No of Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'aA_PENDING_AMT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                        <!-- jas -->

                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_MID_JAS_RCVD'">No of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_SEC_JAS_RCVD'">No of Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jaS_AMT_RELEASED'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>

                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_JAS_MID_AMT_UTILIZED'">No of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise'  || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_JAS_SEC_AMT_UTILIZED'">No of Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jaS_AMT_UTILIZED'">Spent Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jaS_AMT_BALANCE'">Balance Amount <mdb-icon fas icon="sort"></mdb-icon></th>

                        <th *ngIf="format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jaS_MANDAL_UC_FILE'">Utilization Certificate<mdb-icon fas icon="sort"></mdb-icon></th>

                        <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jaS_UC_FILE'">Utilization Certificate<mdb-icon fas icon="sort"></mdb-icon></th>

                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jaS_PENDING_MID'">No of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jaS_PENDING_SEC'">No of Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jaS_PENDING_AMT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                    </tr>
                </thead>
                <tbody *ngIf="items && items.length > 0">
                    <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                        <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.districT_NAME}}</td>
                        <td *ngIf="format === 'District Wise'">{{item.nO_OF_MANDALS}}</td>
                        <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
                        <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.ruR_URBAN}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{item.nO_OF_SEC}}</td>
                        <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{item.secretariaT_CODE}}</td>
                        <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{item.secretariaT_NAME}}</td>
                        <!-- JS -->
                        <td *ngIf="format === 'District Wise'">{{item.nO_OF_MID_JS_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' ">{{item.nO_OF_SEC_JS_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.jS_AMT_RELEASED}}</td>

                        <td *ngIf="format === 'District Wise'">{{item.nO_OF_JS_MID_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{item.nO_OF_JS_SEC_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.jS_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.jS_AMT_BALANCE}}</td>
                        <td *ngIf="format === 'Secretariat Wise' && item.jS_UC_FILE"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.jS_UC_FILE)">View PDF</a></td>
                        <td *ngIf="format === 'Secretariat Wise' && !item.jS_UC_FILE"  class="text-right">-</td>

                        <td *ngIf="format === 'District Wise'">{{item.jS_PENDING_MID}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{item.jS_PENDING_SEC}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.jS_PENDING_AMT}}</td>
                        
                        <!-- AA -->

                        <td *ngIf="format === 'District Wise'">{{item.nO_OF_MID_AA_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' ">{{item.nO_OF_SEC_AA_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.aA_AMT_RELEASED}}</td>

                        <td *ngIf="format === 'District Wise'">{{item.nO_OF_AA_MID_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{item.nO_OF_AA_SEC_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.aA_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.aA_AMT_BALANCE}}</td>
                        <td *ngIf="format === 'Secretariat Wise' && item.aA_UC_FILE"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.aA_UC_FILE)">View PDF</a></td>
                        <td *ngIf="format === 'Secretariat Wise' && !item.aA_UC_FILE"  class="text-right">-</td>

                        <td *ngIf="format === 'District Wise'">{{item.aA_PENDING_MID}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{item.aA_PENDING_SEC}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.aA_PENDING_AMT}}</td>

                        <!-- JAS -->
                        
                        <td *ngIf="format === 'District Wise'">{{item.nO_OF_MID_JAS_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{item.nO_OF_SEC_JAS_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.jaS_AMT_RELEASED}}</td>
                        
                        <td *ngIf="format === 'District Wise'">{{item.nO_OF_JAS_MID_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{item.nO_OF_JAS_SEC_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.jaS_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.jaS_AMT_BALANCE}}</td>
                        <td *ngIf="format === 'Mandal Wise' && item.jaS_MANDAL_UC_FILE"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.jaS_MANDAL_UC_FILE)">View PDF</a></td>
                        <td *ngIf="format === 'Mandal Wise' && !item.jaS_MANDAL_UC_FILE"  class="text-right">-</td>
                        <td *ngIf="format === 'Secretariat Wise' && item.jaS_UC_FILE"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.jaS_UC_FILE)">View PDF</a></td>
                        <td *ngIf="format === 'Secretariat Wise' && !item.jaS_UC_FILE"  class="text-right">-</td>

                        <td *ngIf="format === 'District Wise'">{{item.jaS_PENDING_MID}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{item.jaS_PENDING_SEC}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.jaS_PENDING_AMT}}</td>
                    </tr>
                    <tr *ngIf="totalItems && totalItems.length > 0">
                        <td></td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].districT_NAME}}</td>
                        <td *ngIf="format === 'District Wise'">{{totalItems[0].nO_OF_MANDALS}}</td>
                        <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
                        <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].ruR_URBAN}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{totalItems[0].nO_OF_SEC}}</td>
                        <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{totalItems[0].secretariaT_CODE}}</td>
                        <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{totalItems[0].secretariaT_NAME}}</td>
                        <!-- JS -->
                        <td *ngIf="format === 'District Wise'">{{totalItems[0].nO_OF_MID_JS_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' ">{{totalItems[0].nO_OF_SEC_JS_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].jS_AMT_RELEASED}}</td>

                        <td *ngIf="format === 'District Wise'">{{totalItems[0].nO_OF_JS_MID_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{totalItems[0].nO_OF_JS_SEC_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].jS_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].jS_AMT_BALANCE}}</td>           
                        <td *ngIf="format === 'Secretariat Wise'"></td>


                        <td *ngIf="format === 'District Wise'">{{totalItems[0].jS_PENDING_MID}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{totalItems[0].jS_PENDING_SEC}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].jS_PENDING_AMT}}</td>
                        
                        <!-- AA -->

                        <td *ngIf="format === 'District Wise'">{{totalItems[0].nO_OF_MID_AA_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' ">{{totalItems[0].nO_OF_SEC_AA_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].aA_AMT_RELEASED}}</td>

                        <td *ngIf="format === 'District Wise'">{{totalItems[0].nO_OF_AA_MID_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{totalItems[0].nO_OF_AA_SEC_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].aA_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].aA_AMT_BALANCE}}</td>
                        <td *ngIf="format === 'Secretariat Wise'"></td>

                        <td *ngIf="format === 'District Wise'">{{totalItems[0].aA_PENDING_MID}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{totalItems[0].aA_PENDING_SEC}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].aA_PENDING_AMT}}</td>

                        <!-- JAS -->
                        
                        <td *ngIf="format === 'District Wise'">{{totalItems[0].nO_OF_MID_JAS_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{totalItems[0].nO_OF_SEC_JAS_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].jaS_AMT_RELEASED}}</td>
                        
                        <td *ngIf="format === 'District Wise'">{{totalItems[0].nO_OF_JAS_MID_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{totalItems[0].nO_OF_JAS_SEC_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].jaS_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].jaS_AMT_BALANCE}}</td>
                        <td *ngIf="format === 'Mandal Wise'"></td>
                        <td *ngIf="format === 'Secretariat Wise'"></td>


                        <td *ngIf="format === 'District Wise'">{{totalItems[0].jaS_PENDING_MID}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{totalItems[0].jaS_PENDING_SEC}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].jaS_PENDING_AMT}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="items && items.length === 0">
                    <tr>
                        <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                    </tr>
                </tbody>
            </table>
         
            <div class="mt-4 btnHide">
                <div class="float-left" *ngIf="items.length > 0">
                    <label>Requests per page: </label>
                    <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                        <option  value="10">10</option>
                        <option  value="20">20</option>
                        <option  value="30">30</option>
                        <option  value="40">40</option>
                        <option  value="50">50</option>
                    </select> 
                </div>
                <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
                    [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                </ngb-pagination>
            </div>
        </div>
       
        </div>
    
    <div class="table-responsive tableFixHead" #TABLE class="hideTable">
        <table class="tableCls" >
            <table class="tableCls">
                <thead>
                    <tr>
                        <th [attr.colspan]="noOfCol">Utilization Certificate Report</th>
                    </tr>
                    <tr>
                        <th rowspan="3">S No</th>
                        <th rowspan="3" *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th rowspan="3" *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_MANDALS'">No of Mandals <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th rowspan="3" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th rowspan="3" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'ruR_URBAN'"> Rural/Urban <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th rowspan="3" *ngIf="format === 'District Wise'  || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_SEC'">No of Secretariats<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th rowspan="3" *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'">Secretariat Code <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th rowspan="3" *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'">Secretariat <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise'" colspan="10">Suraksha</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="7">Suraksha</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="4">Suraksha</th>
                        <th *ngIf="format === 'District Wise'" colspan="10">Adudham Andhra</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="7">Adudham Andhra</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="4">Adudham Andhra</th>
                        <th *ngIf="format === 'District Wise'" colspan="10">Aarogya Suraksha</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="7">Aarogya Suraksha</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="4">Aarogya Suraksha</th>
                    </tr>
                    <tr>
                        <th *ngIf="format === 'District Wise'" colspan="3">Fund Received</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="2">Fund Received</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="1">Fund Received</th>
                        <th *ngIf="format === 'District Wise'" colspan="4">UC Uploaded</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="3">UC Uploaded</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="2">UC Uploaded</th>
                        <th *ngIf="format === 'District Wise'" colspan="3">UC Pending</th>
                        <th *ngIf="format === 'Mandal Wise'"  colspan="2">UC Pending</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="1">UC Pending</th>
                        <th *ngIf="format === 'District Wise'" colspan="3">Fund Received</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="2">Fund Received</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="1">Fund Received</th>
                        <th *ngIf="format === 'District Wise'" colspan="4">UC Uploaded</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="3">UC Uploaded</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="2">UC Uploaded</th>
                        <th *ngIf="format === 'District Wise'" colspan="3">UC Pending</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="2">UC Pending</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="1">UC Pending</th>
                        <!-- jas -->
                        <th *ngIf="format === 'District Wise'" colspan="3">Fund Received</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="2">Fund Received</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="1">Fund Received</th>
                        <th *ngIf="format === 'District Wise'" colspan="4">UC Uploaded</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="4">UC Uploaded</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="2">UC Uploaded</th>
                        <th *ngIf="format === 'District Wise'" colspan="3">UC Pending</th>
                        <th *ngIf="format === 'Mandal Wise'" colspan="2">UC Pending</th>
                        <th *ngIf="format === 'Secretariat Wise'" colspan="1">UC Pending</th>
                    </tr>
                    <tr>
                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_MID_JS_RCVD'">No of Mandals <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise'  || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_SEC_JS_RCVD'">No of Secretariates <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jS_AMT_RELEASED'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                       
                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_JS_MID_AMT_UTILIZED'">No of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise'  || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_JS_SEC_AMT_UTILIZED'">No of Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jS_AMT_UTILIZED'">Spent Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jS_AMT_BALANCE'">Balance Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                        
                        <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jS_UC_FILE'">Utilization Certificate<mdb-icon fas icon="sort"></mdb-icon></th>

                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jS_PENDING_MID'">No of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jS_PENDING_SEC'">No of Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jS_PENDING_AMT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                      
                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_MID_AA_RCVD'">No of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_SEC_AA_RCVD'">No of Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'aA_AMT_RELEASED'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                        
                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_AA_MID_AMT_UTILIZED'">No of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise'  || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_AA_SEC_AMT_UTILIZED'">No of Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'aA_AMT_UTILIZED'">Spent Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'aA_AMT_BALANCE'">Balance Amount <mdb-icon fas icon="sort"></mdb-icon></th>

                        <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'aA_UC_FILE'">Utilization Certificate<mdb-icon fas icon="sort"></mdb-icon></th>

                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'aA_PENDING_MID'">No of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'aA_PENDING_SEC'">No of Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'aA_PENDING_AMT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                        <!-- jas -->

                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_MID_JAS_RCVD'">No of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_SEC_JAS_RCVD'">No of Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jaS_AMT_RELEASED'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>

                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_JAS_MID_AMT_UTILIZED'">No of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise'  || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_JAS_SEC_AMT_UTILIZED'">No of Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jaS_AMT_UTILIZED'">Spent Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jaS_AMT_BALANCE'">Balance Amount <mdb-icon fas icon="sort"></mdb-icon></th>

                        <th *ngIf="format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jaS_MANDAL_UC_FILE'">Utilization Certificate<mdb-icon fas icon="sort"></mdb-icon></th>

                        <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jaS_UC_FILE'">Utilization Certificate<mdb-icon fas icon="sort"></mdb-icon></th>

                        <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jaS_PENDING_MID'">No of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jaS_PENDING_SEC'">No of Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'jaS_PENDING_AMT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                    </tr>
                </thead>
                <tbody *ngIf="items && items.length > 0">
                    <tr *ngFor="let item of items;let i = index;">
                        <td width="100px">{{i + 1}}</td>    
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.districT_NAME}}</td>
                        <td *ngIf="format === 'District Wise'">{{item.nO_OF_MANDALS}}</td>
                        <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
                        <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.ruR_URBAN}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{item.nO_OF_SEC}}</td>
                        <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{item.secretariaT_CODE}}</td>
                        <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{item.secretariaT_NAME}}</td>
                        <!-- JS -->
                        <td *ngIf="format === 'District Wise'">{{item.nO_OF_MID_JS_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' ">{{item.nO_OF_SEC_JS_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.jS_AMT_RELEASED}}</td>

                        <td *ngIf="format === 'District Wise'">{{item.nO_OF_JS_MID_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{item.nO_OF_JS_SEC_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.jS_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.jS_AMT_BALANCE}}</td>
                        <td *ngIf="format === 'Secretariat Wise' && item.jS_UC_FILE"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.jS_UC_FILE)">View PDF</a></td>
                        <td *ngIf="format === 'Secretariat Wise' && !item.jS_UC_FILE"  class="text-right">-</td>

                        <td *ngIf="format === 'District Wise'">{{item.jS_PENDING_MID}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{item.jS_PENDING_SEC}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.jS_PENDING_AMT}}</td>
                        
                        <!-- AA -->

                        <td *ngIf="format === 'District Wise'">{{item.nO_OF_MID_AA_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' ">{{item.nO_OF_SEC_AA_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.aA_AMT_RELEASED}}</td>

                        <td *ngIf="format === 'District Wise'">{{item.nO_OF_AA_MID_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{item.nO_OF_AA_SEC_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.aA_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.aA_AMT_BALANCE}}</td>
                        <td *ngIf="format === 'Secretariat Wise' && item.aA_UC_FILE"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.aA_UC_FILE)">View PDF</a></td>
                        <td *ngIf="format === 'Secretariat Wise' && !item.aA_UC_FILE"  class="text-right">-</td>

                        <td *ngIf="format === 'District Wise'">{{item.aA_PENDING_MID}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{item.aA_PENDING_SEC}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.aA_PENDING_AMT}}</td>

                        <!-- JAS -->
                        
                        <td *ngIf="format === 'District Wise'">{{item.nO_OF_MID_JAS_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{item.nO_OF_SEC_JAS_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.jaS_AMT_RELEASED}}</td>
                        
                        <td *ngIf="format === 'District Wise'">{{item.nO_OF_JAS_MID_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{item.nO_OF_JAS_SEC_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.jaS_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.jaS_AMT_BALANCE}}</td>
                        <td *ngIf="format === 'Mandal Wise' && item.jaS_MANDAL_UC_FILE"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.jaS_MANDAL_UC_FILE)">View PDF</a></td>
                        <td *ngIf="format === 'Mandal Wise' && !item.jaS_MANDAL_UC_FILE"  class="text-right">-</td>
                        <td *ngIf="format === 'Secretariat Wise' && item.jaS_UC_FILE"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.jaS_UC_FILE)">View PDF</a></td>
                        <td *ngIf="format === 'Secretariat Wise' && !item.jaS_UC_FILE"  class="text-right">-</td>

                        <td *ngIf="format === 'District Wise'">{{item.jaS_PENDING_MID}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{item.jaS_PENDING_SEC}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.jaS_PENDING_AMT}}</td>
                    </tr>
                    <tr *ngIf="totalItems && totalItems.length > 0">
                        <td></td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].districT_NAME}}</td>
                        <td *ngIf="format === 'District Wise'">{{totalItems[0].nO_OF_MANDALS}}</td>
                        <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
                        <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].ruR_URBAN}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{totalItems[0].nO_OF_SEC}}</td>
                        <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{totalItems[0].secretariaT_CODE}}</td>
                        <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{totalItems[0].secretariaT_NAME}}</td>
                        <!-- JS -->
                        <td *ngIf="format === 'District Wise'">{{totalItems[0].nO_OF_MID_JS_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' ">{{totalItems[0].nO_OF_SEC_JS_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].jS_AMT_RELEASED}}</td>

                        <td *ngIf="format === 'District Wise'">{{totalItems[0].nO_OF_JS_MID_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{totalItems[0].nO_OF_JS_SEC_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].jS_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].jS_AMT_BALANCE}}</td>

                        <td *ngIf="format === 'District Wise'">{{totalItems[0].jS_PENDING_MID}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{totalItems[0].jS_PENDING_SEC}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].jS_PENDING_AMT}}</td>
                        
                        <!-- AA -->

                        <td *ngIf="format === 'District Wise'">{{totalItems[0].nO_OF_MID_AA_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' ">{{totalItems[0].nO_OF_SEC_AA_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].aA_AMT_RELEASED}}</td>

                        <td *ngIf="format === 'District Wise'">{{totalItems[0].nO_OF_AA_MID_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{totalItems[0].nO_OF_AA_SEC_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].aA_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].aA_AMT_BALANCE}}</td>

                        <td *ngIf="format === 'District Wise'">{{totalItems[0].aA_PENDING_MID}}</td>
                        <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'">{{totalItems[0].aA_PENDING_SEC}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].aA_PENDING_AMT}}</td>

                        <!-- JAS -->
                        
                        <td *ngIf="format === 'District Wise'">{{totalItems[0].nO_OF_MID_JAS_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{totalItems[0].nO_OF_SEC_JAS_RCVD}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].jaS_AMT_RELEASED}}</td>
                        
                        <td *ngIf="format === 'District Wise'">{{totalItems[0].nO_OF_JAS_MID_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{totalItems[0].nO_OF_JAS_SEC_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].jaS_AMT_UTILIZED}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].jaS_AMT_BALANCE}}</td>

                        <td *ngIf="format === 'District Wise'">{{totalItems[0].jaS_PENDING_MID}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{totalItems[0].jaS_PENDING_SEC}}</td>
                        <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].jaS_PENDING_AMT}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="items && items.length === 0">
                    <tr>
                        <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                    </tr>
                </tbody>
            </table>
        </table>
    </div>

    
    
    