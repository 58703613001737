<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
            GSWS /Application Submission / Health And Family Welfare Department<span class="activePage">/ Health Cards Dispatch Status Updation</span>
        </div>
    </div>
   

    <div class="mainPageContainer mr-3">
   
    
        
        <table class="tableCls">
            <thead>
                <tr>
                <th>S.No</th>
                <th>Member Name</th>
                <th>UHID</th>
                <th>Reference ID</th>
                <th>Batch Date</th>   
                <th>Consignment ID</th>  
                <th>Received Date</th>  
                <th>Confirmation</th>   
                
                </tr>
            </thead>
            <tbody>              
                    <tr  *ngFor="let item of applicationData | slice: (page-1) * pageSize : page * pageSize; let i=index">
                        
                        <td>{{i+1}}</td> 
                        <td>{{item.memberName}}</td>
                        <td>{{item.uhid}}</td>
                        <td>{{item.referenceId}}</td>                       
                        <td>{{item.batchDate}} </td> 
                        <td>{{item.consignmentId}}</td>
                        <td>
                            <div>
                            <div class="input-group" style="position:initial !important">
                                <input class="calendarCls form-control"
                                    placeholder="dd/mm/yyyy" name="d" 
                                    ngbDatepicker [(ngModel)]="item.receivedDate"
                                    #d="ngbDatepicker" [minDate]="minDate" 
                                    [maxDate]="maxDate"
                                    appBlockCopyPaste  
                                    maxlength="10"
                                    >
                                   
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar"  (click)="d.toggle()"  
                                     type="button"></button>
                                </div>
                            </div>
                      
                    </div>
                        </td>
                        <td><div>
                            <button class="btn btn-primary mr-3" (click)="updateData(item)">  Confirm  </button>
                        </div></td>
                        
                    </tr>
               
            </tbody>
        </table>
        <div class="mt-4 btnHide">
            <div class="float-left" *ngIf="applicationData.length > 0">
                <label>Requests per page: </label>
                <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                    <option  value="10">10</option>
                    <option  value="20">20</option>
                    <option  value="30">30</option>
                    <option  value="40">40</option>
                    <option  value="50">50</option>
                </select> 
            </div>
            <ngb-pagination class="d-flex justify-content-end" *ngIf="applicationData.length > 0" [collectionSize]="applicationData.length" [(page)]="page" [pageSize]="pageSize"
                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
            </ngb-pagination>
        </div>
      
   
</div>
</div>