<div class="row my-3 justify-content-md-center paymentSuccess" *ngIf="!paymentError">
    <div class="col col-lg-10 col-md-8 col-sm-12 col-xs-12">
        <div class="paymentContainer">
            <br>
            <h5 class="text-center mb-3">PAYMENT ERROR</h5>

            <img src="../../assets/payment-error.gif" alt="" class="d-block mx-auto" style="max-width: 200px;">
            <p class="resMsg">{{responseMsg}}</p>

            <!-- <div class="row">
                <div class="col-3"></div>
                <div class="col-9"> -->

            <!-- <div class="amountFont"><label class="labelCls">Order ID:</label> {{orderId}}</div>  -->
            <div class="amountFont"><label class="labelCls">Transaction Status:</label> {{transactionStatus}} </div>
            <div class="amountFont"><label class="labelCls">Transaction ID:</label> {{orderId}}</div>
            <div class="amountFont"><label class="labelCls">Transaction Date and Time:</label> {{dateTime}}
            </div>
            <!--  <div class="amountFont"><label class="labelCls">Buildesk Response Message:</label> {{responseMsg}}</div>  -->

            <!--    </div>
            </div> -->

            </div>
    </div>
</div>
<div class="row my-3 justify-content-md-center paymentSuccess" *ngIf="isZeroPayment">
    <div class="col col-lg-10 col-md-8 col-sm-12 col-xs-12">
        <div class="paymentContainer">
            <br>
            <h5 class="text-center mb-3">TRANSACTION ERROR</h5>

            <img src="../../assets/payment-error.gif" alt="" class="d-block mx-auto" style="max-width: 200px;">
            <!-- <p class="resMsg">{{responseMsg}}</p> -->
            <div class="amountFont"><label class="labelCls">Transaction Status:</label> {{transactionStatus}} </div>
            <div class="amountFont"><label class="labelCls">Application ID:</label> {{orderId}}</div>
            </div>
    </div>
</div>