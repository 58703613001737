<div class="modal-header" >
  <h5 class="modal-title">Captcha Verification</h5>
    <button type="button" class="close" aria-label="Close" (click)="reset()" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="mr-3 modal-body"> 
  <form>
    <div class="captchaClass"  appBlockCopyPaste>{{generatedCode}}</div>
    <input type="text" class="inputCls" name="captchaInput" [(ngModel)]= "captchaInput" maxlength="8" minlength="8"  appBlockCopyPaste>
    <div [ngClass]="{'errorCls' : captchaError, 'successCls' : !captchaError}">{{errorMsg}}</div>
    <div class="btnHolder mt-3">
      <button class="login-btn ml-3 pull-right" (click)="validateCaptcha()">Submit</button>
      <button class="login-btn ml-3 pull-right"  (click) ="generateCaptcha()">Refresh</button>
    </div>
    </form>
      
</div>
