import { Component,Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-computerised-adangal-view',
  templateUrl: './computerised-adangal-view.component.html',
  styleUrls: ['./computerised-adangal-view.component.css']
})
export class ComputerisedAdangalViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  isJoint: boolean = false;
  constructor() { }

  ngOnInit(): void {
    if (this.certificateInfo?.applicantLPDetailsList.length > 0) {
      if (this.certificateInfo?.applicantLPDetailsList[0]?.isJoint == 'yes') {
        this.isJoint = true;
      } else {
        this.isJoint = false;
      }
    } else if (this.certificateInfo?.applicantDetailsList.length > 0) {
      if (this.certificateInfo?.applicantDetailsList[0]?.isJoint == 'yes') {
        this.isJoint = true;
      } else {
        this.isJoint = false;
      }
    }
  }
  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

}
