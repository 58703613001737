<div class="container-fluid mainCls">
<div class="mainPageContainer mt-15 mr-3">
    <h6 class="mb-3 mt-15"><b>Search Screen for Caste Certificate</b>
        <button type="button" class="close" aria-label="Close" aria-label="Close" (click)="activeModal.close('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
    </h6>
    <hr class="hrCls mt-4">
    <form [formGroup]="form">

        <div class="row  mb-3"> 
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
              
                    <label class="labelCls ml-1">Application Number:</label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <input type="text" class="inputCls value" maxlength="15" minlength="15"
                    name="applicationNo" id="applicationNo" formControlName="applicationNo"   [ngClass]="{ 'is-invalid':submitted && f.applicationNo.errors }">
                    <div *ngIf="submitted && f.applicationNo.errors" class="invalid-feedback">
                        <div *ngIf="f.applicationNo.errors?.pattern || f.applicationNo.errors?.minlength">Please enter valid Application Number</div>            
                    </div>
                </div>
               
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 m-auto font-weight-bold"> OR</div>

            <div class="row  mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls ml-1">District : <span class="mandatoryCls">*</span></label>
                    <select class="selectCls"  formControlName="district" (change)="onDistrictChange()"
                    [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
                         <option value="null" disabled>Select</option>
                         <option *ngFor="let district of districts" [ngValue]="district">{{district.rV_DISTRICT_ENGLISHNAME}}</option>
                    </select> 
                    <div *ngIf="submitted && (f.district.errors || f.district.value === 'Select')" class="selectError">
                        <div *ngIf="f.district.errors?.required || f.district.value === 'Select'">Please select District</div>
                      </div> 
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls ml-1">Mandal: <span class="mandatoryCls">*</span></label>
                        <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
                          [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
                         <option value="null" disabled>Select</option>
                         <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.revenuE_MANDAL_ENGLISHNAME}}</option>
                        </select>
                        <div *ngIf="submitted && (f.mandal.errors || f.mandal.value === 'Select')" class="selectError">
                            <div *ngIf="f.mandal.errors?.required || f.mandal.value === 'Select'">Please select Mandal</div>
                          </div>
                        </div>

                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="labelCls ml-1">Revenue Village: <span class="mandatoryCls">*</span> </label>
                            <select class="selectCls" formControlName="village"  [ngClass]="{ 'is-invalid':submitted && (f.village.errors || f.village.value === 'Select') }">
                                 <option value="null" disabled>Select</option>
                                 <option *ngFor="let village of villages" [ngValue]="village">{{village.revenuE_VILLAGE_ENGLISHNAME}}</option>
                            </select>   
                            <div *ngIf="submitted && (f.village.errors || f.village.value === 'Select')" class="selectError">
                                <div *ngIf="f.village.errors?.required || f.village.value === 'Select'">Please select Village</div>
                              </div>
                            </div>

                            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <label class="labelCls ml-1">Applicant Name:</label>
                                <input type="text" name="applicantname" formControlName="applicantname" class="inputCls" appBlockCopyPaste maxlength="75"
                                [ngClass]="{ 'is-invalid':submitted && f.applicantname.errors }">
                              </div>
                      </div>
                      <div class="row mb-3">
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="labelCls ml-1">Father/Husband Name:</label>
                            <input type="text" name="fatherHusName" formControlName="fatherHusName" class="inputCls" appBlockCopyPaste maxlength="75"
                            [ngClass]="{ 'is-invalid':submitted && f.fatherHusName.errors }">
                          </div>
                        
                          <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="labelCls ml-1">Caste</label>
                            <input type="text" name="caste" formControlName="caste" class="inputCls" appBlockCopyPaste maxlength="75"
                            [ngClass]="{ 'is-invalid':submitted && f.applicantname.errors }">
                          </div>
                      </div>        

                </form>
                <div class="row mb-3 mt-4">
                    <div class="btnHolder text-center col-12">
                        <button class="btn btn-primary mx-auto d-block" (click)="submit()">Search</button>
                    </div>
                </div>

                <div class="mr-3">
                    <h6 class="mb-3 mt-15 ml-1"><b>Caste Certificate Details</b></h6>
                    
                    <table class="tableCls">
                        <thead>
                            <tr>
                            <th></th>
                            <th>S.No</th>
                            <th>District</th>
                            <th>Mandal</th>
                            <th>Revenue Village</th>
                            <th>Application No</th>
                            <th>Applicant Name</th>   
                            <th>Father/Husband Name</th>  
                            <th>Date of Birth</th>  
                            <th>Caste</th>    
                            <th>Sub Caste</th>
                            <th>Date of Issue</th>  
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="responseDataFromgetVSWSCasteCertificate && responseDataFromgetVSWSCasteCertificate?.length > 0">
                                <tr *ngFor="let certificate of responseDataFromgetVSWSCasteCertificate | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                                    <td>
                                        <input type="radio" name="VSWScertificateRadio" value="{{certificate.Name}}" name="select" (change)="selectSchedule(certificate)">
                                    </td>
                                    <td>{{ ((page -1) * pageSize) + (i + 1) }}</td>
                                    <td>{{certificate.DistrictName}}</td> 
                                    <td>{{certificate.MandalName}}</td>
                                    <td>{{certificate.VillageName}}</td>
                                    <td>{{certificate.TransactionID}}</td>
                                    <td>{{certificate.Name}}</td> 
                                    <td>{{certificate.FatherName}}</td>
                                    <td>{{(certificate.DOB | date: 'dd/MM/yyyy') ? (certificate.DOB | date: 'dd/MM/yyyy'): "-"}}</td>
                                    <td>{{certificate.Caste}}</td>
                                    <td>{{certificate.Subcaste}}</td> 
                                    <td>{{certificate.IssuedDate | date: 'dd/MM/yyyy'}}</td>
                            </ng-container> 
                        </tbody>
                        <tbody>
                            <ng-container *ngIf="searchcastedetailsData && searchcastedetailsData?.length > 0">
                                <tr *ngFor="let certificatedata of searchcastedetailsData | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                                    <td>
                                        <input type="radio" name="searchcastecertificateRadio" value="{{certificatedata.Name}}" name="selectcertificatedata" (change)="selectSchedule(certificatedata)">
                                    </td>
                                    <td>{{ ((page -1) * pageSize) + (i + 1) }}</td>
                                    <td>{{certificatedata.District}}</td> 
                                    <td>{{certificatedata.Mandal}}</td>
                                    <td> {{certificatedata.Village}} </td>
                                    <td>{{certificatedata.Application_Number}}</td>
                                    <td>{{certificatedata.Applicant_Name}}</td> 
                                    <td>{{certificatedata.Father_Name}}</td>
                                    <td>{{certificatedata.DateOfBirth | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{certificatedata.CasteCategory}}</td>
                                    <td>{{certificatedata.CasteClaimed}}</td> 
                                    <td>{{certificatedata.ApprovedDate | date: 'dd/MM/yyyy'}}</td>
                            </ng-container> 
                        </tbody>
                        <tbody *ngIf="(searchcastedetailsData?.length == 0 && responseDataFromgetVSWSCasteCertificate?.length == 0)">
                            <td colspan="12" class="text-center">No Records Found</td>
                        </tbody>
                        <tbody *ngIf="responseDataFromgetVSWSCasteCertificate == null && searchcastedetailsData != null">
                            <td colspan="12" class="text-center">No Records Found</td>
                        </tbody>
                        <tbody *ngIf="responseDataFromgetVSWSCasteCertificate !== null && searchcastedetailsData == null">
                            <td colspan="12" class="text-center">No Records Found</td>
                        </tbody>
                    </table>
                    </div>
                    <div class="mt-4 btnHide">
                        <div class="float-left" *ngIf="responseDataFromgetVSWSCasteCertificate?.length > 0 || searchcastedetailsData?.length > 0">
                            <label class="ml-1">Requests per page: </label>
                            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                                <option  value="10">10</option>
                                <option  value="20">20</option>
                                <option  value="30">30</option>
                                <option  value="40">40</option>
                                <option  value="50">50</option>
                            </select> 
                        </div>
                        <ngb-pagination class="d-flex justify-content-end" *ngIf="responseDataFromgetVSWSCasteCertificate?.length > 0" [collectionSize]="responseDataFromgetVSWSCasteCertificate?.length" [(page)]="page" [pageSize]="pageSize"
                            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                        </ngb-pagination>
                        <ngb-pagination class="d-flex justify-content-end" *ngIf="searchcastedetailsData?.length > 0" [collectionSize]="searchcastedetailsData?.length" [(page)]="page" [pageSize]="pageSize"
                            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                        </ngb-pagination>
                    </div>
                    <form [formGroup]="aadharForm">
                <div class="container mt-4 mr-3">
                    <div class="row" *ngIf="showButton">
                            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 alignmentaadharform">
                                <label class="labelCls ml-1">Aadhaar Number: <span class="mandatoryCls">*</span></label>
                                <input type="text" appBlockCopyPaste name="aadharNo" formControlName="aadharNo" class="inputCls width75" numbersOnly (keyup)="aadharChange('aadhar')" [value]="aadhIntegratedCertificate"
                                [ngClass]="{ 'is-invalid':submits && g.aadharNo.errors }" maxlength="12" minlength="12"
                                [readonly] ="isPensionerOtpValidated">
                                
                                <div *ngIf="submits && g.aadharNo.errors" class="invalid-feedback">
                                    <div *ngIf="g.aadharNo.errors.required || g.aadharNo.errors.whiteSpaceValidate">Aadhaar Number is required</div>           
                                    <div *ngIf="g.aadharNo.errors.aadharValidate || g.aadharNo.errors.minlength || g.aadharNo.errors.maxlength">Please enter 12 digit Valid Aadhaar Number</div>
                                </div>
                            </div>
                            <span>
                                <button class="btn btn-primary mt-4" (click)="sendAadharOtp()">Send OTP</button>
                            </span>
                           
                            <div *ngIf="aadharsentmessage == 'OTP Sent Successfully'" class="col col-lg-3 col-md-3 col-sm-12 col-xs-12"> 
                                <div *ngIf="!isAadharDisable">
                                    <label class="labelCls ml-1">OTP (One Time Password): <span class="mandatoryCls">*</span></label>
                                    <input type="password" appBlockCopyPaste name="aadharOtp" appBlockCopyPaste formControlName="aadharOtp" class="inputCls width75" numbersOnly
                                    [ngClass]="{ 'is-invalid':submits && g.aadharOtp.errors }" maxlength="6" minlength="6"
                                    [readonly]="isAadharOtpValidated">
                                    <div *ngIf="submits && g.aadharOtp.errors" class="invalid-feedback">
                                        <div *ngIf="g.aadharOtp.errors?.required">
                                            Please enter 6 digit Valid OTP
                                        </div>
                                        <div *ngIf="g.aadharOtp.errors?.minlength || g.aadharNo.errors?.maxlength">
                                            The Otp Should be of 6 digits.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="btnHolder">
                                <button class="float-right mt-4 btn btn-success" *ngIf="g.aadharOtp.value?.length == 6"
                                    (click)="validateAadharOTP()">OTP Authenticate
                                </button>
                            </div>
                        </div>
                      
                    </div> 
                        </form>
                   
                    <div class="container" *ngIf="redirectbuttonFlag">
                        <button type="submit" class="btn btn-primary ml-5 mt-4" (click)="navigatetoreissuance()">Re-Issuance Redirect</button>
                    </div>
                </div>
                </div>
