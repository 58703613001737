import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {WhitespaceValidator, CustomAdapter, CustomDateParserFormatter, AadharValidator} from 'src/shared/common.service';
import {fileExtensionValidator, fileSizeValidator, fileSizeValidatorForMMC} from 'src/shared/validators/file-validator';
import { CommonService } from '../../../shared/common.service';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { CommonConstants } from 'src/constants/common-constants.component';
import {
  NgbNavConfig,
  NgbNavChangeEvent,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-muslim-marriage-certificate',
  templateUrl: './muslim-marriage-certificate.component.html',
  styleUrls: ['./muslim-marriage-certificate.component.css'],
  providers: [
    NgbNavConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]
})
export class MuslimMarriageCertificateComponent implements OnInit {
  marriageCertificate: FormGroup;
  brideAuthSubmitted: Boolean = false;
  groomAuthSubmitted: Boolean = false;
  isBrideAuthenticated: Boolean = false;
  isGroomAuthenticated: Boolean = false;
  isCheckUrbanFlag: Boolean = false;
  public submitted: Boolean = false;
  public commonFormSubmitted: Boolean = false;
  commonConstants: any = CommonConstants;
  fileList: { FileName: string; DocumentType: string; BlobRef: string }[] = [];
  subCaste: any;
  currentDate = new Date();
  maxDate: any;
  minDate: any;
  districts: any[] = [];
  mandals: any[] = [];
  quazzats: any[] = [];
  villages: any[] = [];
  panchayats: any = [];
  nationalities: any = [];
  religions: any[] = [];
  casteCategory: any[] = [];
  occupations: any[] = [];
  qualifications: any[] = [];
  relations: any[] = [];
  @ViewChild('nav')
  nav: any;
  
  @ViewChild('marriageBookBrideLabel')
  marriageBookBrideLabel!: ElementRef;
  @ViewChild('otherDocumentsLabel')
  otherDocumentsLabel!: ElementRef;
  
  isBrideWitnessOne: Boolean = false;
  isBrideWitnessTwo: Boolean = false;
  isGroomWitnessOne: Boolean = false;
  isGroomWitnessTwo: Boolean = false;
  aadharTransactionID: any;
  //isBrideOtpValidated: boolean = false;
  isBride: boolean = true;
  //isGroomOtpValidated: boolean = false;
  isGroom: boolean = true;
  isUrban: Boolean = false; 
  loggedInSecretariat: any;  
  bWOneAge:any
  bWTwoAge:any
  bGOneAge:any
  bGTwoAge:any
  // showBiometricPopup: Boolean = false;
  // loggedInUserId: any;
  // autoBiometricId: any;
  // windowObjectReference: any;
  constructor( private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private config: NgbNavConfig,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute) {
      this.marriageCertificate = this.formBuilder.group({
        commonForm: [],
        dateOfMarriage: ['', [Validators.required]],
        venueOfMarriage: [null, [Validators.required]],
        //requestType: [null, [Validators.required]],
        marriageBookletNumber: [null, [Validators.required]],
        maherAmount: [null, [Validators.required]],
        district: [null, [Validators.required]],
        mandal: [null, [Validators.required]],
        //village: [null, [Validators.required]],
        quazzat: [null, [Validators.required]],
        noOfCertificates: [null, [Validators.required]],
        //panchayat: [null],
        brideAadharNo :['', [Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
        brideName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
        //brideGender: [null, [Validators.required]],
        brideDob: [null],
        brideNationality: [null, [Validators.required]],
        //brideReligion :[null, [Validators.required]],
        brideAge : ['', [Validators.required]],
        brideFatherName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
        brideMotherName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
        brideAddress: ['', [Validators.required]],
        //brideCaste: [null, [Validators.required]],
        //brideMarStatus: [null, [Validators.required]],
        //brideMobileNo: ['', [Validators.required]],
        brideOccupation: [null, [Validators.required]],
        brideGender:  [null, [Validators.required]],
        //brideQual: [null, [Validators.required]],
        //brideHandicap:  [null, [Validators.required]],
        groomAadharNo :['', [Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
        groomName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
        //groomGender: [null, [Validators.required]],
        groomDob: [null],
        groomNationality: [null, [Validators.required]],
        //groomReligion :[null, [Validators.required]],
        groomAge : ['', [Validators.required]],
        groomFatherName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
        groomMotherName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
        groomAddress: ['', [Validators.required]],
        //groomCaste: [null, [Validators.required]],
        //groomMarStatus: [null, [Validators.required]],
        //groomMobileNo: ['', [Validators.required]],
        groomOccupation: [null, [Validators.required]],
        groomGender:  [null, [Validators.required]],
        //groomQual: [null, [Validators.required]],
        /*groomHandicap:  [null, [Validators.required]],
        bWOneAadharNo:['', [Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
        bWOneName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
        bWOneFatherHusName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
        bWOneAddress: ['', [Validators.required]],
        bWOneAge: ['', [Validators.required]],
        bWOneOccupation:  [null, [Validators.required]],
        bWOneRelation:  [null, [Validators.required]],
        bWOnePhoto: ['', [Validators.required]],
        bWTwoAadharNo:['', [Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
        bWTwoName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
        bWTwoFatherHusName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
        bWTwoAddress: ['', [Validators.required]],
        bWTwoAge: ['', [Validators.required]],
        bWTwoOccupation:  [null, [Validators.required]],
        bWTwoRelation:  [null, [Validators.required]],
        bWTwoPhoto: ['', [Validators.required]],
        gWOneAadharNo:['', [Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
        gWOneName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
        gWOneFatherHusName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
        gWOneAddress: ['', [Validators.required]],
        gWOneAge: ['', [Validators.required]],
        gWOneOccupation:  [null, [Validators.required]],
        gWOneRelation:  [null, [Validators.required]],
        gWOnePhoto: ['', [Validators.required]],
        gWTwoAadharNo:['', [Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
        gWTwoName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
        gWTwoFatherHusName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
        gWTwoAddress: ['', [Validators.required]],
        gWTwoAge: ['', [Validators.required]],
        gWTwoOccupation:  [null, [Validators.required]],
        gWTwoRelation:  [null, [Validators.required]],
        gWTwoPhoto: ['', [Validators.required]],*/
        //marriagePhoto: ['', [Validators.required]],
        marriageBookBride: ['', [Validators.required]],
        otherDocuments: [''],
        //groomAadhar: ['', [Validators.required]],
        // casteBride: ['', [Validators.required]],
        // casteGroom: ['', [Validators.required]],
        //divorceCert: [''],
        //deathCert: [''],
        //notary: [''],
        //residenceProof: ['', [Validators.required]],
        consent: ['', [Validators.required]],
        consent2: ['', [Validators.required]],
        //brideAuthType: ['bOTP', [Validators.required]],
        //brideOtp: [''],
        //groomAuthType: ['gOTP', [Validators.required]],
        //groomOtp: [''],
      });
      config.destroyOnHide = false;
      config.roles = false;
      this.maxDate = {
        year: this.currentDate.getFullYear(),
        month: this.currentDate.getMonth() + 1,
        day: this.currentDate.getDate()
      };
      this.minDate = {
        year: this.currentDate.getFullYear() - 150,
        month: this.currentDate.getMonth() + 1,
        day: this.currentDate.getDate()
      };
  }

  ngOnInit(): void {
    let userInfo: any = localStorage.getItem('userInfo');
    // console.log(JSON.parse(this.userInfo));
    let userDetails: any = JSON.parse(userInfo);
    //this.isCheckUrbanFlag = true;
    // if (userDetails && userDetails !== null) {
    //     this.loggedInSecretariat = userDetails.userPrincipalName?.split("@")[0].replace('U', '');
    //     this.loggedInUserId = userDetails.userPrincipalName;
    // }
    this.getNationality();
    this.getOccupation();
    this.getRelations();
    this.getDistricts();
    /*if (!this.commonService.getMasterData('districts'))
      this.getDistricts();
    else 
      this.districts = this.commonService.getMasterData('districts')
    if (!this.commonService.getMasterData('caste'))
      this.getCaste();
    else
      this.casteCategory = this.commonService.getMasterData('caste');
    if (!this.commonService.getMasterData('qualifications'))
      this.getQualifications();
    else
      this.qualifications = this.commonService.getMasterData('qualifications')
    if (!this.commonService.getMasterData('religions'))
      this.getReligions();
    else
      this.religions = this.commonService.getMasterData('religions')
    */
  }
  get f() {
    return this.marriageCertificate.controls;
  }
  getSubCaste(data: any) {
    this.subCaste = data;
  }
  goToNextTab() {
    this.commonFormSubmitted = true;
    if (this.f.commonForm.valid) {
      this.nav.select(2);
    }
  }
  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.commonFormSubmitted = true;
    if (changeEvent.nextId === 2 && !this.f.commonForm.valid) {
      changeEvent.preventDefault();
    }
  }
  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getQaziDistrictList).subscribe({
      next: (responseData: any) => { 
        console.log(responseData.result,'qazi district list')
        this.districts = this.commonService.sortQaziDistrict(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onDistrictChange() {
    this.commonService.getRequest(this.commonConstants.getQaziMandalListByDistrict + this.f.district.value.districtCode).subscribe({
      next: (responseData: any) => { 
        console.log(responseData.result,'Mandal List')
        this.mandals = this.commonService.sortQaziMandal(responseData.result);
        //this.villages = [];
        this.f.mandal.patchValue(null);
        this.f.quazzat.patchValue(null);
        //this.f.panchayat.patchValue(null);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onMandalChange(){
    let input = '?DistrictCode='+this.f.district.value.districtCode+'&MandalCode='+this.f.mandal.value.mandalCode;
  this.commonService.getRequest(this.commonConstants.getAllQaziMasterDetails + input).subscribe({
    next: (responseData: any) => { 
      console.log(responseData.result,'qazi details')
      this.quazzats = this.commonService.sortQaziName(responseData.result);
      this.f.quazzat.patchValue(null);
    },
    error: (error) => {
      console.log('client side', error);
    }
 }); 
}
  
  getNationality() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'MW_Nationality').subscribe({
      next: (responseData: any) => { 
        console.log(responseData.result,'Nationility')
          this.nationalities = this.commonService.sortData(responseData.result);
          
         this.f.brideNationality.patchValue(null);
         this.f.groomNationality.patchValue(null);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onChangeSecretariat() {
    this.commonService.getRequest(this.commonConstants.getPanchayatDDO + this.f.village.value.id  + '&ParentType=Secretariat&LanguageCode=EN-IN').subscribe({
      next: (responseData: any) => { 
          this.panchayats = this.commonService.sortData(responseData.result);
          this.f.panchayat.patchValue(null);
          this.checkRuralUrban();
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  fileUpload(
    file: any,
    filePath: string,
    fileName: string,
    documentType: string,
    name:string,
    label:string,
    certType: string = 'MMC'
  ) {
    const formData = new FormData();
    formData.append('File', file, fileName);
    formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
    formData.append('CertType', certType)
    console.log(formData, 'formData');
    this.commonService
      .postUploadedFileRequest(this.commonConstants.fileUpload, formData)
      .subscribe({
        next: (responseData: any) => {
          if(typeof(responseData.result) !== 'string'){
          let index = this.fileList
            .map((item) => item.DocumentType)
            .indexOf(documentType);
          if (index > -1) {
            this.fileList.splice(index, 1);
          }
          this.fileList.push({
            FileName: fileName,
            DocumentType: documentType,
            BlobRef: responseData['result'].blobRef,
          });}
          else{
            if(name && label) {
              this.clearFileUpload(name, label);
            }
            this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
          }
          console.log(this.fileList, 'FileList');
        },
        error: (error) => {
          if(name && label) {
            this.clearFileUpload(name, label);
          }
          console.log('client side', error);
        },
      });
  }

  updateFileUpload(id: any) {
    let fileData = {
      FileList: this.fileList,
      RequisitionId: id,
    };
    console.log(this.fileList.length,'File list length')
    if(this.fileList.length === 2){
    this.commonService
      .postRequest(this.commonConstants.updateFileUpload, fileData)
      .subscribe({
        next: (resData: any) => {
          console.log(resData, 'File Upload  Respose data');
          if(resData.result?.updateResult === 'Success') {
          this.showPayment(id);
          }
          else{
            this.commonService.commonErrorPopup('Alert', 'An error occured while updating file list in the requisition.', 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
    }
    else {
      this.commonService.commonErrorPopup('Alert', 'An error occured while updating file list in the requisition.', 'md');
    }  
  }

  showPayment(requisionId: any) {
    let type: any = "MuslimMarriageCertificate"
    const queryParams: Params = { id: requisionId,department: 'MW' ,type: type};
    this.router.navigate(['/payment-gateway'], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }
  getQualifications() {
    this.commonService.getRequest(this.commonConstants.getQualifications).subscribe({
      next: (responseData: any) => { 
        this.qualifications =  this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  getCaste() {
    this.commonService.getRequest(this.commonConstants.getCaste).subscribe({
      next: (responseData: any) => { 
        this.casteCategory = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  getReligions() {
    this.commonService.getRequest(this.commonConstants.getReligions).subscribe({
      next: (responseData: any) => { 
        this.religions =  this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  getOccupation() {
    this.commonService.getRequest(this.commonConstants.getOccupation).subscribe({
      next: (responseData: any) => { 
        this.occupations =  this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  getRelations() {
    this.commonService.getRequest(this.commonConstants.getRelations).subscribe({
      next: (responseData: any) => { 
        this.relations = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  getCitizenInfo(name: any, witness:any): any {
    if(!this.f[name].valid) {
      this.commonService.commonErrorPopup('Alert', 'Please enter valid Aadhar Number.', 'md');
      return false;
    }
    else if(this.f.groomAadharNo.value === this.f[name].value || this.f.brideAadharNo.value === this.f[name].value) {
      this.commonService.commonErrorPopup('Alert', 'Bride or Bridegroom\'s aadhar numbers are not allowed as witness details.', 'md');
      return false;
    } else if((witness==='BW1' && this.f.bWOneAadharNo.value === this.f.bWTwoAadharNo.value) || (witness==='BW1' && this.f.bWOneAadharNo.value === this.f.gWOneAadharNo.value) ||
      (witness==='BW1' && this.f.bWOneAadharNo.value === this.f.gWTwoAadharNo.value) || (witness==='BW2' && this.f.bWTwoAadharNo.value === this.f.bWOneAadharNo.value) || 
      (witness==='BW2' && this.f.bWTwoAadharNo.value === this.f.gWOneAadharNo.value) || (witness==='BW2' && this.f.bWTwoAadharNo.value === this.f.gWTwoAadharNo.value) || 
      (witness==='GW1' && this.f.gWOneAadharNo.value === this.f.bWTwoAadharNo.value) || (witness==='GW1' && this.f.gWOneAadharNo.value === this.f.bWOneAadharNo.value) ||
      (witness==='GW1' && this.f.gWOneAadharNo.value === this.f.gWTwoAadharNo.value) || (witness==='GW2' && this.f.gWTwoAadharNo.value === this.f.gWOneAadharNo.value) || 
      (witness==='GW2' && this.f.gWTwoAadharNo.value === this.f.bWOneAadharNo.value) || (witness==='GW2' && this.f.gWTwoAadharNo.value === this.f.bWTwoAadharNo.value)) {
      this.commonService.commonErrorPopup('Alert', 'Witness details shouldn\'t be repeated for bride and bridegroom.', 'md');
      return false;
    }
    let postData: any = {};
    postData.aadhaarNumber = this.commonService.RSAEncrypt(this.f[name].value, true); 
    this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe({
      next: (responseData: any) => { 
        if(responseData?.result &&responseData?.result.length !==0) { 
          responseData.result.forEach((item: any) => {
            item.aadhaarNumber = item.aadhaarNumber;
          });
          this.prefillData(responseData.result[0], witness);
         } else {
          alert('Citizen details are not available to populate');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  prefillData(data:any, witness: any) {
    //data.birthDate
    let address: any = data?.doorNo + ','+ data?.villageWard  + ','+ data?.mandalMunicipality + ','+ data?.district;
    let dob = data?.birthDate;
    let age: any;
    if(dob !== null || dob !== undefined || dob !== '') {
      const convertAge = new Date(this.commonService.formatDate(dob));
      const timeDiff = Math.abs(Date.now() - convertAge.getTime());
      age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365); 
    } else {
      age = null;
    }
    if(witness === 'BW1'){
     this.bWOneAge = age;
    } else if(witness === 'BW2'){
    this.bWTwoAge = age;
    }else if(witness === 'GW1'){
    this.bGOneAge = age;
    }else if(witness === 'GW2'){
    this.bGTwoAge = age;
    }

   if(age >=18){
    if(witness === 'BW1') {
      this.isBrideWitnessOne = true;
      this.f.bWOneName.patchValue(data?.firstName);
      this.f.bWOneFatherHusName.patchValue(data?.fatherHusbandName);
      this.f.bWOneAddress.patchValue(address);
      this.f.bWOneAge.patchValue(age);
  } else if(witness === 'BW2') {
    this.isBrideWitnessTwo = true;
    this.f.bWTwoName.patchValue(data?.firstName);
    this.f.bWTwoFatherHusName.patchValue(data?.fatherHusbandName);
    this.f.bWTwoAddress.patchValue(address);
    this.f.bWTwoAge.patchValue(age);
  } else if(witness === 'GW1') {
    this.isGroomWitnessOne = true;
    this.f.gWOneName.patchValue(data?.firstName);
      this.f.gWOneFatherHusName.patchValue(data?.fatherHusbandName);
      this.f.gWOneAddress.patchValue(address);
      this.f.gWOneAge.patchValue(age);
  } else if(witness === 'GW2') {
    this.isGroomWitnessTwo = true;
    this.f.gWTwoName.patchValue(data?.firstName);
      this.f.gWTwoFatherHusName.patchValue(data?.fatherHusbandName);
      this.f.gWTwoAddress.patchValue(address);
      this.f.gWTwoAge.patchValue(age);
  }
   }else{
    this.commonService.commonErrorPopup('Alert', 'Age should not be less than 18 years.', 'md')
   }
    
  }
  
  onmarriageBookBrideSelected(fileEvent: any) {
    const file: File | null = fileEvent.target.files[0];
    this.marriageBookBrideLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
    this.f.marriageBookBride.setValidators([
      Validators.required, fileExtensionValidator('pdf'), fileSizeValidatorForMMC(fileEvent)
    ])
    this.f.marriageBookBride.updateValueAndValidity();
    if (this.f.marriageBookBride.valid)
      this.fileUpload(file, this.f.marriageBookBride.value, fileEvent.target.files[0].name, 'Marriage Booklet (Bride/Bridegroom)','marriageBookBride', 'marriageBookBrideLabel');
  }
  otherDocumentsUpload(fileEvent: any) {
    const file: File | null = fileEvent.target.files[0];
    this.otherDocumentsLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
    this.f.otherDocuments.setValidators([
       fileExtensionValidator('pdf'), fileSizeValidator(fileEvent)
    ])
    this.f.otherDocuments.updateValueAndValidity();
    if (this.f.otherDocuments.valid)
      this.fileUpload(file, this.f.otherDocuments.value, fileEvent.target.files[0].name, 'Other Documents','otherDocuments', 'otherDocumentsLabel');
  }

  clearFileUpload(name: string, label: string) {
    this.f[name].reset();
    if (label === 'marriageBookBrideLabel') {
      this.marriageBookBrideLabel.nativeElement.innerText = 'No File Choosen';
    } else if (label === 'otherDocumentsLabel') {
      this.otherDocumentsLabel.nativeElement.innerText = 'No File Choosen';
    } 
  }
  submit(): any {
    this.submitted = true;
    let mandalId: string = this.f.mandal.value.mandalCode.toString();
    let districtId: string = this.f.district.value.districtCode.toString();
  
    if(!this.marriageCertificate.valid){
       return false;
    }
    
    /*else if(this.isUrban && this.diffInDates(this.commonService.formatDate(this.f.dateOfMarriage.value)) > 90 ) {
      this.commonService.commonErrorPopup('Alert', 'Application cannot be accepted as Marriage Date is greater than 90 days of application date.', 'md')
    } else if(!this.isUrban && this.diffInDates(this.commonService.formatDate(this.f.dateOfMarriage.value)) > 60 ) {
      this.commonService.commonErrorPopup('Alert', 'Application cannot be accepted as Marriage Date is greater than 60 days of application date.', 'md')
    }*/
     else if(Number(this.f.brideAge.value) < 18) {
      this.commonService.commonErrorPopup('Alert', 'Bride Age should be greater than or equal to 18 years.', 'md')
    } else if(Number(this.f.groomAge.value) < 21) {
      this.commonService.commonErrorPopup('Alert', 'Bridegroom Age should be greater than or equal to 21 years.', 'md')
    }
    else if((this.f.brideDob.value !== null) && (Number(this.getAge(this.f.brideDob.value)) !== Number(this.f.brideAge.value))){
      this.commonService.commonErrorPopup('Alert', 'Bride Age is not matching with Date of Birth', 'md')
  }
    else if((this.f.groomDob.value !== null) && (Number(this.getAge(this.f.groomDob.value)) !== Number(this.f.groomAge.value))){
      this.commonService.commonErrorPopup('Alert', 'Bridegroom Age is not matching with Date of Birth', 'md')
  }
     /*else if(this.f.commonForm.value?.aadharNo !== this.f.brideAadharNo.value && this.f.commonForm.value?.aadharNo !== this.f.groomAadharNo.value) {
      this.commonService.commonErrorPopup('Alert', 'The Applicant Aadhar Number is not matching with the bride and bridegroom\'s Aadhar Number.', 'md')
    } else if(coupleAadhars.some((item:any) => witnessAadhars.includes(item))) {
      this.commonService.commonErrorPopup('Alert', 'Bride or Bridegroom\'s aadhar numbers are not allowed as witness details.', 'md');
    } else if(this.isDuplicate(witnessAadhars)) {
      this.commonService.commonErrorPopup('Alert', 'Witness details shouldn\'t be repeated for bride and bridegroom.', 'md');
    } else if(this.bWOneAge < 18 || this.bWTwoAge < 18 || this.bGOneAge < 18 || this.bGTwoAge  < 18){
      this.commonService.commonErrorPopup('Alert', 'Age should not be less than 18 years.', 'md')
    }*/
    else {  
      let dateFormat = this.commonService.formatDate(this.f['commonForm'].value['dob']);
      let postData: any = {
          requisitionHeader: {
            requisitionType: "MuslimMarriageCertificate", 
            department: "MW",
            applicantInfo: {
              id: "",
              aadhaarNumber: this.f['commonForm'].value['encryptedAadharNo'].trim(), 
              personNames: [
                {
                  languageCode: "en-in",
                  firstName: this.f['commonForm'].value['firstName'],
                  middleName: this.f['commonForm'].value['middleName'],
                  lastName: this.f['commonForm'].value['lastName'],
                  fatherHusbandName: this.f['commonForm'].value['fatherHusName'],
                }
              ],
              gender: this.f['commonForm'].value['gender'],
              birthDate: dateFormat,
              caste: this.f['commonForm'].value['caste'].name,
              religion: this.f['commonForm'].value['religion'].name,
              qualification: this.f['commonForm'].value['qualification'].name,
              maritalStatus: this.f['commonForm'].value['maritalStatus'],
              addresses: [
                {
                  addressType: "present",
                  doorNo: this.f['commonForm'].value['preDoorNo'],
                  streetName: this.f['commonForm'].value['preStreet'],
                  villageWard: this.f['commonForm'].value['preVillage'].entityCode,
                  mandalMunicipality: this.f['commonForm'].value['preMandal'].entityCode,
                  district: this.f['commonForm'].value['preDistrict'].entityCode,
                  pinCode: this.f['commonForm'].value['prePinCode'],
                  postalOfficeCode: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].entityCode : "",
                  postalOfficeName: this.f['commonForm'].value['prePostalOffice'] !== null ? this.f['commonForm'].value['prePostalOffice'].name : "",
                  postalVillageCode: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].entityCode : "",
                  postalVillageName: this.f['commonForm'].value['prePostalVillage'] !== null ? this.f['commonForm'].value['prePostalVillage'].name : ""
                },
                {
                  addressType:"permanent",
                  doorNo: this.f['commonForm'].value['permDoorNo'],
                  streetName:this.f['commonForm'].value['permStreet'],
                  villageWard: this.f['commonForm'].value['permVillage'].entityCode,
                  mandalMunicipality: this.f['commonForm'].value['permMandal'].entityCode,
                  district: this.f['commonForm'].value['permDistrict'].entityCode,
                  pinCode: this.f['commonForm'].value['permPinCode'],
                  postalOfficeCode: this.f['commonForm'].value['permPostalOffice'] !==  null ? this.f['commonForm'].value['permPostalOffice'].entityCode : "",
                  postalOfficeName: this.f['commonForm'].value['permPostalOffice'] !== null ? this.f['commonForm'].value['permPostalOffice'].name : "",
                  postalVillageCode: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].entityCode : "",
                  postalVillageName: this.f['commonForm'].value['permPostalVillage'] !== null ? this.f['commonForm'].value['permPostalVillage'].name : ""
                },
                /*{
                  addressType:"workflow",
                  doorNo: "1",
                  streetName: null,
                  villageWard: this.f.village.value?.entityCode,
                  mandalMunicipality: this.f.mandal.value?.entityCode,
                  district: this.f.district.value?.entityCode,
                  pinCode: "523445"
                }*/
              ],
              rationCardNo: '',
              deliveryType:  this.f['commonForm'].value['deliveryType'],
              emailAddress: this.f['commonForm'].value['email'],
              mobileNumber: this.f['commonForm'].value['mobileNo'],
              isWhatsAppAvailable: this.f['commonForm'].value['isWhatsAppAvailable'],
              isDataFromAadhar: this.f['commonForm'].value['isDataFromAadhar']
            },
            remarks: '',
          },
            requisitionData: {
              marriageDate: this.commonService.formatDate(this.f.dateOfMarriage.value),
              //requestType: this.f.requestType.value,
              marriageAddress: this.f.venueOfMarriage.value,
              marriageBookletNumber: this.f.marriageBookletNumber.value,
              maherAmount: this.f.maherAmount.value,
              //villageWard: this.f.village.value?.entityCode,
              mandalMunicipality: mandalId,
              district: districtId,
              //villageWardName:  this.f.village.value?.name,
              mandalMunicipalityName:  this.f.mandal.value?.mandalName,
              districtName: this.f.district.value?.districtName,
              quazzat: this.f.quazzat.value.qaziName,
              quazzatAddress: this.f.quazzat.value.qaziAddress,
              noOfCertificates: this.f.noOfCertificates.value,
              /*{
                  addressType: '',
                  doorNo: '',
                  streetName: '',
                  villageWard: this.f.village.value?.entityCode,
                  mandalMunicipality: this.f.mandal.value?.entityCode,
                  district: this.f.district.value?.entityCode,
                  villageWardName:  this.f.village.value?.name,
                  mandalMunicipalityName:  this.f.mandal.value?.name,
                  districtName: this.f.district.value?.name,
                  pinCode: ''
              },*/
              //panchayatOrMunicipalityCode: this.f.panchayat.value?.entityCode,
              //panchayatOrMunicipalityName: this.f.panchayat.value?.name,
              brideDetails: {
                  aadhaarNumber: this.commonService.RSAEncrypt(this.f.brideAadharNo.value),
                  name: this.f.brideName.value,
                  //gender: this.f.brideGender.value,
                  dateOfBirth: this.f.brideDob.value !== null ? this.commonService.formatDate(this.f.brideDob.value) : null,
                  Nationality: this.f.brideNationality.value.name,
                  //religion: this.f.brideReligion.value?.name,
                  age: this.f.brideAge.value?.toString(),
                  fathersName: this.f.brideFatherName.value,
                  mothersName: this.f.brideMotherName.value,
                  address: this.f.brideAddress.value,
                  //caste: this.f.brideCaste.value?.name,
                  //status: this.f.brideMarStatus.value,
                  //mobileNumber: this.f.brideMobileNo.value,
                  occupation: this.f.brideOccupation.value?.name,
                  //qualification: this.f.brideQual.value?.name
                  //isHandicapped: this.f.brideHandicap.value === 'Yes' ? true : false
              },
              bridegroomDetails: {
                aadhaarNumber: this.commonService.RSAEncrypt(this.f.groomAadharNo.value),
                name: this.f.groomName.value,
                //gender: this.f.groomGender.value,
                dateOfBirth: this.f.groomDob.value !== null ? this.commonService.formatDate(this.f.groomDob.value) : null,
                Nationality: this.f.groomNationality.value.name,
                //religion: this.f.groomReligion.value?.name,
                age: this.f.groomAge.value?.toString(),
                fathersName: this.f.groomFatherName.value,
                mothersName: this.f.groomMotherName.value,
                address: this.f.groomAddress.value,
                //caste: this.f.groomCaste.value?.name,
                //status: this.f.groomMarStatus.value,
                //mobileNumber: this.f.groomMobileNo.value,
                occupation: this.f.groomOccupation.value?.name,
                //qualification: this.f.groomQual.value?.name
                //isHandicapped: this.f.groomHandicap.value === 'Yes' ? true : false
              },
              /*brideWitnessOneDetails: {
                  aadhaarNumber: this.f.bWOneAadharNo.value,
                  name: this.f.bWOneName.value,
                  fatherOrHusbandName: this.f.bWOneFatherHusName.value,
                  address: this.f.bWOneAddress.value,
                  age: this.f.bWOneAge.value?.toString(),
                  occupation: this.f.bWOneOccupation.value?.name,
                  relation: this.f.bWOneRelation.value?.name
              },
              brideWitnessTwoDetails: {
                aadhaarNumber: this.f.bWTwoAadharNo.value,
                name: this.f.bWTwoName.value,
                fatherOrHusbandName: this.f.bWTwoFatherHusName.value,
                address: this.f.bWTwoAddress.value,
                age: this.f.bWTwoAge.value?.toString(),
                occupation: this.f.bWTwoOccupation.value?.name,
                relation: this.f.bWTwoRelation.value?.name
              },
              bridegroomWitnessOneDetails: {
                aadhaarNumber: this.f.gWOneAadharNo.value,
                name: this.f.gWOneName.value,
                fatherOrHusbandName: this.f.gWOneFatherHusName.value,
                address: this.f.gWOneAddress.value,
                age: this.f.gWOneAge.value?.toString(),
                occupation: this.f.gWOneOccupation.value?.name,
                relation: this.f.gWOneRelation.value?.name
              },
              bridegroomWitnessTwoDetails: {
                aadhaarNumber: this.f.gWTwoAadharNo.value,
                name: this.f.gWTwoName.value,
                fatherOrHusbandName: this.f.gWTwoFatherHusName.value,
                address: this.f.gWTwoAddress.value,
                age: this.f.gWTwoAge.value?.toString(),
                occupation: this.f.gWTwoOccupation.value?.name,
                relation: this.f.gWTwoRelation.value?.name
              }*/
          }
      }
      console.log(postData,'this is post data')
      this.commonService.postRequest(this.commonConstants.muslimMarriageCertificate, postData).subscribe({
        next: (responseData: any) => {
          this.updateFileUpload(responseData['result'].id);
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
    }
  }
  checkRuralUrban() {
    this.commonService
      .getRequest(this.commonConstants.checkRuralUrban + this.f.village.value.entityCode)
      .subscribe({
        next: (responseData: any) => {
          if(responseData.result?.isSuccess) {
            this.isCheckUrbanFlag = true;
            this.isUrban = responseData.result?.ruralUrbanFlag === 'U' ? true : false;
            if(this.isUrban) {
              this.f.panchayat.clearValidators();
              this.f.panchayat.updateValueAndValidity();
              if(this.diffInDates(this.commonService.formatDate(this.f.dateOfMarriage.value)) > 90 ) {
                this.commonService.commonErrorPopup('Alert', 'Application cannot be accepted as Marriage Date is greater than 90 days of application date.', 'md')
              }
            } else {
              this.f.panchayat.setValidators([Validators.required]);
              this.f.panchayat.updateValueAndValidity();
              if(this.diffInDates(this.commonService.formatDate(this.f.dateOfMarriage.value)) > 60 ) {
                this.commonService.commonErrorPopup('Alert', 'Application cannot be accepted as Marriage Date is greater than 60 days of application date.', 'md')
              }
            }
          } else {
            //this.isCheckUrbanFlag = false;
            this.commonService.commonErrorPopup('Alert', 'An error occured while getting rural/urban details.', 'md');
          }
          
        },
        error: (error) => {
          //this.isCheckUrbanFlag = false;
          console.log('client side', error);
        },
      });
  }
  onMarriageDateSelect(event: any) {
    console.log(event);
    // if (this.diffInDates(this.commonService.formatDate(this.f.dateOfMarriage.value)) > 90 ) {
    //   this.commonService.commonErrorPopup('Alert', 'Application cannot be accepted as Marriage Date is greater than 90 days of application date.', 'md')
    // }
    console.log(this.f.mandal.value?.name)
    if(this.f.village.value !== null && this.f.village.value?.name !== undefined){
      
if(this.isUrban) {
  this.f.panchayat.clearValidators();
  this.f.panchayat.updateValueAndValidity();
  if(this.diffInDates(this.commonService.formatDate(this.f.dateOfMarriage.value)) > 90 ) {
    this.commonService.commonErrorPopup('Alert', 'Application cannot be accepted as Marriage Date is greater than 90 days of application date.', 'md')
  }
} else {
  this.f.panchayat.setValidators([Validators.required]);
  this.f.panchayat.updateValueAndValidity();
  if(this.diffInDates(this.commonService.formatDate(this.f.dateOfMarriage.value)) > 60 ) {
    this.commonService.commonErrorPopup('Alert', 'Application cannot be accepted as Marriage Date is greater than 60 days of application date.', 'md')
  }
}
    }
  }
  diffInDates(from:any): any {
    let date1: Date = new Date(from);
    let date2: Date = new Date();
   console.log('date1', date1)
   console.log('date2', date2)

    let timeInMilisec: number = date2.getTime() - date1.getTime();
    let daysBetweenDates: number = timeInMilisec / (1000 * 60 * 60 * 24);
   console.log('daysBetweenDates', daysBetweenDates)
     return daysBetweenDates;
  }
  getAge(dob: any): any {
    const convertAge = new Date(this.commonService.formatDate(dob));
    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    return Math.floor(timeDiff / (1000 * 3600 * 24) / 365); 
  }
  onBrideAadharChange(){
    if(!this.f.brideAadharNo.valid){
      this.f.brideName.reset();
      this.f.brideDob.reset();
      this.f.brideAge.reset();
      this.f.brideAddress.reset();
      this.f.brideNationality.reset();
      this.f.brideFatherName.reset();
      this.f.brideMotherName.reset();
      this.f.brideOccupation.reset();
    }
  }
  onGroomAadharChange(){
    if(!this.f.groomAadharNo.valid){
    this.f.groomName.reset();
    this.f.groomDob.reset();
    this.f.groomAge.reset();
    this.f.groomAddress.reset();
    this.f.groomNationality.reset();
    this.f.groomFatherName.reset();
    this.f.groomMotherName.reset();
    this.f.groomOccupation.reset();
    }
  }
  onAadharChange(name:any) {
    if(name === 'bride') {
      if(!this.f.brideAadharNo.valid){
        this.isBride = true;
      } else {
        this.isBride = false;
      }
    } else {
      if(!this.f.groomAadharNo.valid){
        this.isGroom = true;
      } else {
        this.isGroom = false;
      }
    }
    
  }
  otpChange(event:any, name: any){
    if(name === 'bride') {
      this.isBride = true;
      if(event.target.value === "bOTP"){
  
      } else {
    
      }
    } else {
      this.isGroom = true;
    }
   

 }
  generateOTP(name: any): any {
    if(this.f.brideAadharNo.value === this.f.groomAadharNo.value) {
      this.commonService.commonErrorPopup('Alert', 'Bride and Bridegroom\'s Aadhar numbers should not be same.', 'md');
      return false;
    }
   // this.getPesionerDetails();
    let postData: any = {
      P_USER_ID: this.loggedInSecretariat,
      PID_DATA: "",
      P_OTP: "",
      P_UID: name === 'bride' ? this.f.brideAadharNo.value: this.f.groomAadharNo.value,
      P_LATITUDE: "",
      P_LONGITUDE: "",
      AUTHENTICATION_TYPE: "EKYCOTPGENERATE"
      // uid: this.f.aadhaarNumber.value,
      // authentication_type: "OTPGENERATE"
    }
    let sendPostData: any = btoa(JSON.stringify(postData));
    this.commonService.postRequestForAadhar(this.commonConstants.pensionAadharOTP, sendPostData, 'pension').subscribe({
      next: (responseData:any) => { 
        if(responseData?.message == 'OTP Sent Successfully'){
          this.aadharTransactionID = responseData?.result
        } else {
          this.commonService.commonErrorPopup('Alert', responseData?.result, 'md');
        }
      },
      error: (error: any) => {
        console.log('client side', error);
      }
    });
  }
  onBrideDobChange(){
    if(this.f.brideDob.value !== null){
    this.f.brideAge.patchValue(this.getAge(this.f.brideDob.value));
    }
  }
  onBrideDobSelect(){
    if(this.f.brideDob.value !== null){
      this.f.brideAge.patchValue(this.getAge(this.f.brideDob.value));
      }
  }
  onGroomDobChange(){
    if(this.f.groomDob.value !== null){
    this.f.groomAge.patchValue(this.getAge(this.f.groomDob.value));
    }
  }
  onGroomDobSelect(){
    if(this.f.groomDob.value !== null){
      this.f.groomAge.patchValue(this.getAge(this.f.groomDob.value));
      }
  }
  getBrideDetails() {

    /*if(person === 'bride') {
      this.brideAuthSubmitted = true;
      if(!this.f.brideAadharNo.valid)
         return false;
    } else {
      this.groomAuthSubmitted = true;
      if(!this.f.groomAadharNo.valid)
        return false;
    }*/
  if(this.f.brideAadharNo.valid){
    let postData: any = {};
    postData.aadhaarNumber = this.commonService.RSAEncrypt(this.f.brideAadharNo.value, true); 
    this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe({
      next: (responseData: any) => {
        if(responseData?.result && responseData?.result.length !==0) {
          responseData.result.forEach((item: any) => {
            item.aadhaarNumber = item.aadhaarNumber;
          });
        }
        let data = responseData.result[0];
        console.log(data.gender.toUpperCase(),'gender response data')
        
          if(data?.gender?.toUpperCase() === 'F' || data?.gender?.toUpperCase() === 'FEMALE'){ 
          this.f.brideName.patchValue(data?.firstName);
          //this.f.brideGender.patchValue(this.getGender(data?.gender?.toUpperCase()));
          this.f.brideDob.patchValue(data?.birthDate);
          this.f.brideAge.patchValue(this.getAge(data?.birthDate));
          this.f.brideAddress.patchValue(data?.streetName + ','+ data?.villageWard + ',' + data?.mandalMunicipality + ',' + data?.district);
          //this.f.brideMobileNo.patchValue(data?.mobileNumber);
          this.f.brideGender.patchValue(this.loadGender(data.gender?.toUpperCase()));
          }
          else {
            this.commonService.commonErrorPopup('Alert', 'Please provide bride aadhar number.', 'md');
            this.f.brideAadharNo.reset();
          }
        
        
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
   }
   else{
    this.f.brideName.reset();
    this.f.brideDob.reset();
    this.f.brideAge.reset();
    this.f.brideAddress.reset();
    this.f.brideNationality.reset();
    this.f.brideFatherName.reset();
    this.f.brideMotherName.reset();
    this.f.brideOccupation.reset();
    this.commonService.commonErrorPopup('Alert', 'Please enter valid aadhar number and try.', 'md');
   }
  }

  getGroomDetails() {

    /*if(person === 'bride') {
      this.brideAuthSubmitted = true;
      if(!this.f.brideAadharNo.valid)
         return false;
    } else {
      this.groomAuthSubmitted = true;
      if(!this.f.groomAadharNo.valid)
        return false;
    }*/
  if(this.f.groomAadharNo.valid){
    let postData: any = {};
    postData.aadhaarNumber = this.commonService.RSAEncrypt(this.f.groomAadharNo.value, true); 
    this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe({
      next: (responseData: any) => {
        if(responseData?.result && responseData?.result.length !==0) {
          responseData.result.forEach((item: any) => {
            item.aadhaarNumber = item.aadhaarNumber;
          });
        }
        let data = responseData.result[0];
        console.log(data.gender.toUpperCase(),'gender response data')
        
        
          if(data?.gender?.toUpperCase() === 'M' || data?.gender?.toUpperCase() === 'MALE'){
            this.f.groomName.patchValue(data?.firstName);
            //this.f.groomGender.patchValue(this.getGender(data?.gender?.toUpperCase()));
            this.f.groomDob.patchValue(data?.birthDate);
            this.f.groomAge.patchValue(this.getAge(data?.birthDate));
            this.f.groomAddress.patchValue(data?.streetName + ','+ data?.villageWard + ',' + data?.mandalMunicipality + ',' + data?.district);
            //this.f.groomMobileNo.patchValue(data?.mobileNumber);
            this.f.groomGender.patchValue(this.loadGender(data.gender?.toUpperCase()));
          }
          else{
            this.commonService.commonErrorPopup('Alert', 'Please provide bridegroom aadhar number.', 'md');
            this.f.groomAadharNo.reset();
          }
        
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
   }
   else{
    this.f.groomName.reset();
    this.f.groomDob.reset();
    this.f.groomAge.reset();
    this.f.groomAddress.reset();
    this.f.groomNationality.reset();
    this.f.groomFatherName.reset();
    this.f.groomMotherName.reset();
    this.f.groomOccupation.reset();
    this.commonService.commonErrorPopup('Alert', 'Please enter valid aadhar number and try.', 'md');
   }
  }

  validateOTP(person: any): any {
    if(person === 'bride') {
      this.brideAuthSubmitted = true;
      if(!this.f.brideAadharNo.valid)
         return false;
    } else {
      this.groomAuthSubmitted = true;
      if(!this.f.groomAadharNo.valid)
        return false;
    }
    
    var sendPostData={
      P_USER_ID: this.loggedInSecretariat,
      PID_DATA: this.aadharTransactionID,
      P_OTP: person === 'bride' ? this.f.brideOtp.value: this.f.groomOtp.value,
      P_UID: person === 'bride' ? this.f.brideAadharNo.value: this.f.groomAadharNo.value,
      P_LATITUDE: "",
      P_LONGITUDE: "",
      AUTHENTICATION_TYPE: "EKYCOTPVALIDATE"
    }
    let postData: any = btoa(JSON.stringify(sendPostData));
    this.commonService.postRequestForAadhar(this.commonConstants.pensionAadharOTP, postData, 'pension').subscribe({
      next: (responseData:any) => { 
        if(responseData?.message == 'Data Loaded successfully !!!'){
          //street, loc, vtc, dist
          let data: any = responseData.result;
          if(person === 'bride') {
            if(data?.gender?.toUpperCase() === 'F') {
              //this.isBrideOtpValidated = true;
              this.isBrideAuthenticated = true;
              this.f.brideName.patchValue(data?.name);
              this.f.brideGender.patchValue(this.getGender(data?.gender?.toUpperCase()));
              this.f.brideDob.patchValue(data?.dob);
              this.f.brideAge.patchValue(this.getAge(data?.dob));
              this.f.brideFatherName.patchValue(data?.co);
              this.f.brideAddress.patchValue(data?.street + ','+ data?.loc + ',' + data?.vtc + ',' + data?.dist);
            } else {
              //this.isBrideOtpValidated = false;
              this.isBrideAuthenticated = false;
              this.commonService.commonErrorPopup('Alert', 'Please provide bride aadhar number.', 'md')
            }
           
          } else {
            if(data?.gender?.toUpperCase() === 'M') {
                //this.isGroomOtpValidated = true;
                this.isGroomAuthenticated = true;
                this.f.groomName.patchValue(data?.name);
                this.f.groomGender.patchValue(this.getGender(data?.gender?.toUpperCase()));
                this.f.groomDob.patchValue(data?.dob);
                this.f.groomAge.patchValue(this.getAge(data?.dob));
                this.f.groomFatherName.patchValue(data?.co);
                this.f.groomAddress.patchValue(data?.street + ','+ data?.loc + ',' + data?.vtc + ',' + data?.dist);
              }
              else {
                //this.isGroomOtpValidated = false;
                this.isGroomAuthenticated = false;
                this.commonService.commonErrorPopup('Alert', 'Please provide bridegroom aadhar number.', 'md')
             }
          }
        
        } else if(responseData?.message == 'OTP Flooding error.'){
          this.commonService.commonErrorPopup('Alert', 'OTP Flooding error. Please try again after sometime.', 'md')
        } else{
          this.commonService.commonErrorPopup('Alert', responseData?.message, 'md')
        }
      },
      error: (error: any) => {
        console.log('client side', error);
      }
    });
  }
  getGender(value: any): any {
    if(value === 'F' || value === 'FEMALE') {
      return 'Female'
    } else if(value === 'M' || value === 'MALE') {
      return 'Male'
    } else if(value === 'T' || value === 'TRANSGENDER') {
      return 'Transgender'
   } else {
     return null;
   }
  }
  isDuplicate(witness:any): any {
    let value = witness.some((item: any, index: any) => {
      return witness.indexOf(item) !== index;
    });
    return value;
  }
  onGenderChange(type: any): any {
    if(type === 'bride' && this.f.brideGender.value !== 'Female') {
      this.commonService.commonErrorPopup('Alert', 'Please provide bride aadhar number.', 'md');
      this.f.brideGender.patchValue(null);
    } else if(type === 'groom' && this.f.groomGender.value !== 'Male') {
      this.commonService.commonErrorPopup('Alert', 'Please provide bridegroom aadhar number.', 'md');
      this.f.groomGender.patchValue(null);
    }
  }
  loadGender(value: string) {
    if (value === 'MALE')
      return 'Male';
    else if (value === 'FEMALE')
      return 'Female';
    else 
      return null;
  }
  // biometricBtn(name: any): any {
  //   if(this.f.brideAadharNo.value === this.f.groomAadharNo.value) {
  //     this.commonService.commonErrorPopup('Alert', 'Bride and Bridegroom\'s Aadhar numbers should not be same.', 'md');
  //     return false;
  //   }
  //   let postData: any = {};
  //   postData.autoBiometricId = 0;
  //   postData.aadharNumber = name === 'bride' ? this.f.brideAadharNo.value: this.f.groomAadharNo.value,
  //   postData.responseStatus = '';
  //   postData.userId = this.loggedInUserId;
  //   this.commonService.postRequestForWebLand(this.commonConstants.getSNoForBiometric, postData).subscribe({
  //     next: (responseData:any) => { 
  //     // console.log('response from biometric', responseData)
  //     this.autoBiometricId = responseData?.result?.autoBiometricId;
  //     if (this.autoBiometricId !== undefined && this.autoBiometricId !== null) {
  //       this.showBiometricPopup = true;
  //       let url: any = 'https://gramawardsachivalayam.ap.gov.in/GSWS/#!/AadhaarAuthentication?uid=' +this.f['commonForm'].value['encryptedAadharNo'].trim()+ '&SNo=' + this.autoBiometricId;
  //       this.windowObjectReference = window.open(url, "BiometricAuthentication", 'popup=1;left=500,top=100,width=700,height=700');
  //     } else {
  //       this.showBiometricPopup = false;
  //     }
  //   },
  //     error: (error) => {
  //       console.log('client side', error);
  //     }
  //   });
  // }
  // checkBiometricStatus(person: any): any {
  //   if(person === 'bride') {
  //     this.brideAuthSubmitted = true;
  //     if(!this.f.brideAadharNo.valid)
  //        return false;
  //   } else {
  //     this.groomAuthSubmitted = true;
  //     if(!this.f.groomAadharNo.valid)
  //       return false;
  //   }
  //   this.windowObjectReference.close();
  //   let postData: any = {};
  //   postData.autoBiometricId = this.autoBiometricId;
  //   postData.aadharNumber = person === 'bride' ? this.f.brideAadharNo.value: this.f.groomAadharNo.value;
  //   postData.responseStatus = '';
  //   postData.userId = this.loggedInUserId;
  //   this.commonService.postRequestForWebLand(this.commonConstants.getSNoForBiometric, postData).subscribe({
  //     next: (responseData:any) => { 
  //    // console.log('response from biometric', responseData)
  //    // this.autoBiometricId = responseData?.result?.autoBiometricId;
  //    let status = responseData?.result?.responseStatus;
  //    if (status === 'Success') {
  //     this.showBiometricPopup = true;
  //     this.commonService.commonErrorPopup('Alert', 'Biometric Authentication Success.', 'md');
  //    } else {
  //     this.showBiometricPopup = false;
  //     this.commonService.commonErrorPopup('Alert', 'Biometric Authentication failed. Please try again!!', 'md');
  //    }
     
  //   },
  //     error: (error) => {
  //       console.log('client side', error);
  //     }
  //   });
  // }
}
