import { Component, OnInit ,ViewChild, ElementRef } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { CommonService, CustomAdapter, CustomDateParserFormatter } from '../../shared/common.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter,NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';;
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-palleku-podam',
  templateUrl: './palleku-podam.component.html',
  styleUrls: ['./palleku-podam.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
    ]
})
export class PallekuPodamComponent implements OnInit {
  form: FormGroup;
  commonConstants : any = CommonConstants;
  headerCol = [{colId:'districT_NAME', colName: 'District'},
  {colId:'mandaL_NAME', colName: 'Mandal'},
  {colId:'secretariaT_NAME', colName: 'Secretariat'},
  ]
  items: any[] = [];
  totalItems: any[] = [];
  districts: any[] = [];
  mandals: any[] = [];
  page = 1;
  popupPage = 1;
  pageSize = 10;
  showReports:Boolean= true;
  submitted:Boolean=false;
  noOfCol: any = 7;
  popupPageSize = 10;
  scheduleDate: any;
  @ViewChild('TABLE') table!: ElementRef;
  constructor(private commonService: CommonService,private router: Router,private datePipe: DatePipe,private modalService: NgbModal, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      district: [null, Validators.required],
      mandal: [null, Validators.required]
    });
    this.getDistricts();
  }
  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {
    this.form.patchValue({
      district:'All',
      mandal:'All'
    })
    this.GetJASWelfareScheduleDetails();
  }

  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getCSPDistricts).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.districts = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'districtName');
        } else {
          this.districts = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onDistrictChange() {
    this.showReports = false;
    this.mandals = [];
    this.f.mandal.patchValue('All');
    this.getMandals();
  }
  getMandals(){
    this.showReports = false;
    this.mandals = [];
    this.f.mandal.patchValue('All');
    this.commonService.getRequest(this.commonConstants.getCSPMandalsRuralUrban + '?DistrictCode=' + this.f['district'].value.districtCode ).subscribe({
      next: (responseData: any) => { 
        if(responseData.result?.isSuccess) {
          this.mandals = this.commonService.sortItems(responseData.result?.getMandalDetails, 'mandalName');
        } else {
          this.mandals = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onMandalChange() {
    this.showReports = false;
    this.commonService.getRequest(this.commonConstants.getCSPSecretariat + '?MandalCode=' + this.f.mandal.value.mandalCode).subscribe({
      next: (responseData: any) => { 
        if(responseData.result?.isSuccess) {
        } else {
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  downloadExcel() {
    let fileName: any = 'Scheduletable for welfarescheme';
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      
  ];
  
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Scheduletable for welfarescheme');
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
 
  GetJASWelfareScheduleDetails(): any {
    this.submitted = true;
        this.page = 1;
        let district = this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode;
        let mandal = this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode;
        this.commonService.getRequest(this.commonConstants.GetJASWelfareScheduleDetails  + '?DistrictId=' +
        district + '&MandalId=' +   mandal).subscribe({
          next: (responseData: any) => { 
            if(responseData?.result.isSuccess) {
                this.showReports = true;
                this.items = responseData?.result?.welfareList;   
                // this.scheduleDate = this.datePipe.transform(responseData?.result?.welfareList[0]?.scheduleDate, 'dd/MM/yyyy')   
                  this.items = this.items.filter(item => item.totalFlag !== 'Y')
                  this.totalItems = responseData?.result?.welfareList.filter((item: { totalFlag: string; }) => item.totalFlag === 'Y')
            } else {
              this.items = [];
              this.totalItems = [];
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
      });
      
    }
  
    goToHome() {
      this.router.navigate(['/home']);
    }
    printScheduleDetails() {
      let Pagelink = 'about:blank';
      var pwa = window.open(Pagelink, '_new');
      pwa?.document.open();
      pwa?.document.write(this.preparePrintFormat());
      pwa?.document.close();
    }
    preparePrintFormat() {
      const printContent: HTMLElement | null =
        document.getElementById('printContainer');
      let htmlData: any = '';
      let printdata = printContent?.innerHTML;
      htmlData +=
        '<html><head><title>Schedule table for welfare schemes</title> <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">';
      htmlData +=
        `<style type='text/css' media='print'> @media print{@page{size: auto; margin: 0;} 
        .tableCls td, .tableCls th {padding: 5px;border: 1px solid #ccc;} table {width: 100%;} body{margin: 25px;}
          </style> <script>function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'>`;
  
      htmlData += printdata;
      htmlData += '</body></html>';
  
      return htmlData;
    }
  }
  