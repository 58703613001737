<div class="certificateInfo">
    <div class="subHeadingCls mb-3">Land Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Revenue District:</label>
                <div class="valueCls">{{certificateInfo?.khathaDetails?.districtName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Revenue Mandal: </label>
                <div class="valueCls">{{certificateInfo?.khathaDetails?.mandalMunicipalityName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Revenue Village: </label>
                <div class="valueCls">{{certificateInfo?.khathaDetails?.villagewardName}}</div>
            </div>
         </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Khatha Number:</label>
                <div class="valueCls">{{certificateInfo?.khathaDetails?.khathaNo}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Secretariat:</label>
                <div class="valueCls">{{certificateInfo?.khathaDetails?.secretariatName}}</div>
            </div>
        </div>
     
    </div>
</div>

<hr class="hrCls mt-4">
<div class="certificateInfo">
    <div class="row mb-3">
        <div class="col">
            <div class="tab">
                <table class="tableCls">
                    <thead>
                        <tr>
                            <th>LP No</th>
                            <th>LP Extent</th>
                            <th>Khata Number</th>
                            <th>Pattadar Name</th>
                            <th>Pattadar Father/Husband Name</th>                                                            
                            <th>Land Nature</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of certificateInfo.rectificationResurveyRecords">
                            <td>{{data?.lpmNo}}</td>
                            <td>{{data?.totalExtent}}</td>
                            <td>{{data?.khataNo}}</td>
                            <td>{{data?.pattadarName}}</td>
                            <td>{{data?.pattadarRelationName}}</td>   
                            <td>{{data?.landNature}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>


<div class="certificateInfo">
    

<div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Grievance Type:</label>
            <div class="valueCls">{{certificateInfo?.grievance}}</div>
        </div>
    </div>
</div>
<hr class="hrCls mt-4">

<div class="subHeadingCls mb-3">Documents Uploaded by the Applicant</div>
<div class="fieldHolder" *ngFor="let file of fileList ;let i = index">
    <div *ngIf="file.documentType === 'Application Form'">
        <span class="numberCls">{{i + 1}}</span><label class="labelCls">Grievance Representation:</label>
        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                (click)="downloadFile(file)" class="download ml-2"></span></div>
    </div>
    <div *ngIf="file.documentType === 'additionalDocument Certificate'">
        <span class="numberCls">{{i + 1}}</span><label class="labelCls">Supporting Documents if any:</label>
        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                (click)="downloadFile(file)" class="download ml-2"></span></div>
    </div>
</div>
</div>
