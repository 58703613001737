
<div class="reportContainer">
    <div [formGroup]="form">
        <div class="row mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">From Date: <span class="mandatoryCls">*</span></label>
                <div class="input-group">
                    <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                            name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" (dateSelect)="onFromDateSelect($event)"
                            [maxDate]="maxDate" required formControlName="fromDate"  
                            [ngClass]="{ 'is-invalid':submitted && f.fromDate.errors }" >
                    <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                    </div>
                </div>
                <div *ngIf="submitted && f.fromDate.errors" class="selectError">
                    <div *ngIf="f.fromDate.errors?.required || f.fromDate.invalid">Please enter valid From Date</div>
                </div>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">To Date: <span class="mandatoryCls">*</span></label>
                <div class="input-group">
                    <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                            name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="toMinDate" (dateSelect)="onToDateSelect($event)"
                            [maxDate]="maxDate" required formControlName="toDate"
                            [ngClass]="{ 'is-invalid':submitted && f.toDate.errors }" >
                    <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
                    </div>
                </div>
                <div *ngIf="submitted && f.toDate.errors" class="selectError">
                    <div *ngIf="f.toDate.errors?.required || f.toDate.invalid">Please enter valid To Date</div>
                </div>
            </div>
            <div class="btnHolder">
                <button  class="greenBtnCls float-right my-4" (click)="getReports()">Get Reports</button>
            </div>
        </div>
        <div class="table-responsive tableFixHead">
            <table class="tableCls">
                <thead>
                    <tr>
                        <th colspan="30">Abstract Report on Failed Transactions From  {{f.fromDate.value}} To  {{f.toDate.value}}</th>
                    </tr>
                    <tr>
                        <th>Sr No</th>
                        <th>Application No</th>
                        <th>Service Name</th>
                        <th>Transaction Id</th>
                        <th>Transaction Mode</th>
                        <th>Transaction Date</th>
                        <th>Transaction Amount</th>
                    </tr>
                </thead>
                <tbody *ngIf="getData && getData.length > 0">
                    <tr *ngFor="let data of getData  | slice: (popupPage-1) * popupPageSize : popupPage * popupPageSize;let i = index;">
                            <td class="serial">{{((popupPage -1) * popupPageSize) + (i + 1)}}</td>
                            <td class="value">{{data.requisitionId}}</td>
                            <td>{{data.requisitionType}}</td>
                            <td>{{data.transactionId}}</td>
                            <td>{{data.transactionMode}}</td>
                            <td>{{data.transactionDate}}</td>
                            <td>{{data.transactionAmount}}</td>
                        </tr>
                    </tbody>
                <tbody *ngIf= "getData && getData.length ==0">
                    <tr>
                        <td colspan= "28" class="text-center">No records found.</td>
                    </tr>
                </tbody>
            </table>
            
        </div>
    </div>
    <div class="mt-4 btnHide">
        <div class="float-left" *ngIf="getData.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="popupPageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="getData.length > 0" [collectionSize]="getData.length" [(page)]="popupPage" [pageSize]="popupPageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
 
</div>
