import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
@Component({
  selector: 'app-change-of-name-certificate-view',
  templateUrl: './change-of-name-certificate-view.component.html',
  styleUrls: ['./change-of-name-certificate-view.component.css']
})
export class ChangeOfNameCertificateViewComponent implements OnInit {

  constructor() { }

  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();

  ngOnInit(): void {
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

}
