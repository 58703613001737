<div class="fullscreen-container secHeaderCls">
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-md-12">
            <img src="../../assets/home-images/APSevaPortal.png" alt="logo" class="img-fluid"  style="height:37px;">
        </div>
    </div>
</div>
<div class="designationContainer">
    <h6 class="mt-3">Secretariat Employees - Working Status</h6>
    <form [formGroup]="form">
        <div class="row mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Please enter Secretariat Code : <span class="mandatoryCls">*</span></label>
                <input type="text" name="secretariatCode" formControlName="secretariatCode" class="inputCls"  appBlockCopyPaste required numbersOnly
                [ngClass]="{ 'is-invalid':validSecretariat && f.secretariatCode.errors }" minlength="8" maxlength="8" [attr.disabled] ="dataFetched ? '' : null">
                <div *ngIf="validSecretariat && f.secretariatCode.errors" class="invalid-feedback">
                    <div *ngIf="f.secretariatCode.errors.required || f.secretariatCode.errors.whiteSpaceValidate">Secretariat Code is required</div>
                    <div *ngIf="f.secretariatCode.errors.minlength || f.secretariatCode.errors.maxlength">Please enter valid Secretariat Code</div>
                </div>
            </div>
            <div class="btnHolder mt-4">
                <button class="greenBtnCls float-right" *ngIf="!dataFetched" (click)="getDetails()">Get Details</button>
                <button class="greenBtnCls float-right" *ngIf="dataFetched" (click)="reset()">Reset</button>
            </div>
        </div>
        <ng-container *ngIf="designations && designations?.length > 0">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">District:</label>
                        <div class="valueCls">{{secretariatData.district_name }}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Mandal:</label>
                        <div class="valueCls">{{secretariatData.mandal_name }}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Secretariat:</label>
                        <div class="valueCls">{{secretariatData.secretariat_name }}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Secretariat Type:</label>
                        <div class="valueCls">{{secretariatData.secretariat_type === 'R' ? 'Rural' : 'Urban' }}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <!-- <h6 class="mt-3">Secretariat Employees - Working Status</h6> -->
                    <table class="tableCls my-3">
                        <thead>
                        <tr> 
                            <th>S.No</th>
                            <th>Designation</th>
                            <th>Position Type</th>
                        </tr>
                        </thead>
                        <tbody *ngIf="designations && designations?.length > 0">
                            <tr *ngFor="let item of designations; let i= index;">
                                <td>{{i + 1}}</td>
                                <td>{{item.designation}}</td>
                                <td>
                                    <!-- <select class="selectCls"  [(ngModel)]="item.roleType" [ngModelOptions]="{standalone: true}" 
                                        [ngClass]="{ 'is-invalid':submitted && (item.roleType === null) }">
                                        <option [ngValue]= "null" disabled>Select</option>
                                        <option value = "Incharge">Incharge</option>
                                        <option value = "Regular">Regular</option>
                                        <option value = "Vacant">Vacant</option>
                                    </select> -->
                                    <input type="radio" name="{{'roleType' + i}}" value="Incharge" [(ngModel)]="item.roleType" [ngModelOptions]="{standalone: true}" id="{{'Incharge' + i}}">
                                    <label class="mx-2 radioCls" for="{{'Incharge' + i}}">Incharge</label>
                                    <input type="radio" name="{{'roleType' + i}}" value="Regular" [(ngModel)]="item.roleType" [ngModelOptions]="{standalone: true}" id="{{'Regular' + i}}">
                                    <label class="mx-2 radioCls" for="{{'Regular' + i}}">Regular</label>
                                    <input type="radio" name="{{'roleType' + i}}" value="Vacant" [(ngModel)]="item.roleType" [ngModelOptions]="{standalone: true}" id="{{'Vacant' + i}}">
                                    <label class="mx-2 radioCls" for="{{'Vacant' + i}}">Vacant</label>
                                    <div *ngIf="submitted && (item.roleType === null)" class="selectError">
                                        Please select Position Type
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Mobile No: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="mobileNo" formControlName="mobileNo" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':submitted && f.mobileNo.errors }" minlength="10" maxlength="10" numbersOnly>
                    <div *ngIf="submitted && f.mobileNo.errors" class="invalid-feedback">
                        <div *ngIf="f.mobileNo.errors.required">Please enter Mobile No</div>
                        <div *ngIf="f.mobileNo.errors?.minlength || f.mobileNo.errors?.pattern">Please enter valid Mobile No</div>
                    </div>
                </div>
            </div>
            <div class="btnHolder mt-4">
                <button class="greenBtnCls float-right" *ngIf="!dataSaved" (click)="saveDetails()">Save</button>
            </div>
        </ng-container>
    </form>
</div>