<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
      <div class="breadcrumbCls">
        GSWS / Application Submission / GSWS Department <span class="activePage">/ Address Certificate for Aadhar Enrollment/Update</span>
      </div>
    </div>
    <div class="mainPageContainer mr-3">
      <h6>Address Certificate for Aadhar Enrollment/Update</h6>
      <form [formGroup]="addressForm">
        <nav ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)">
          <ng-container [ngbNavItem] = "1">
            <a ngbNavLink>Basic Details</a>
            <ng-template ngbNavContent>
              <app-common-form formControlName="commonForm" [isSubmitted]="commonFormSubmitted" [postalRequired] = 'true' (reset)="commonFormSubmitted = false"></app-common-form>
              <div class="btnHolder">
                <button  class="greenBtnCls float-right" (click)="goToNextTab()">Continue</button>
              </div>
            </ng-template>
          </ng-container>
          <ng-container [ngbNavItem] = "2">
            <a ngbNavLink>Application Form</a>
            <ng-template ngbNavContent>
                
              <div class="row"> 
                <div class="col-lg-2">Purpose of certificate : <span class="mandatoryCls"> *</span></div>
               
                  <div class="col-lg-4" [ngClass]="{ 'is-invalid':submitted && f.purposeOfCertificate?.errors }">
                    <input type="radio"  value="NewEnrolment" name="purposeOfCertificate" formControlName="purposeOfCertificate">
                    <span class="mx-2 radioCls">New Enrollment</span>
                    <input type="radio"  value="UpdateRequest" name="purposeOfCertificate" formControlName="purposeOfCertificate">
                    <span class="mx-2 radioCls">Update Request</span>

                    <div *ngIf="submitted && f.purposeOfCertificate.errors" class="selectError">
                      <div *ngIf="f.purposeOfCertificate.errors?.required">Please select Purpose of certificate</div>
                    </div>
                  
                </div>
               
              </div>
                <div class="row my-3">
                    <div class="col-6">
                        <label class="labelCls">Nearby Landmark to your address: <span class="mandatoryCls"> *</span></label>
                        <input type="text" name="landmark" appBlockCopyPaste class="inputCls" formControlName = "landmark"   maxlength ="100" required [ngClass]="{ 'is-invalid':submitted && f.landmark.errors}">
                       
                     
                        <div *ngIf="submitted && f.landmark.errors" class="invalid-feedback">
                           <div *ngIf="f.landmark.errors?.required">Please enter Nearby Landmark to your address</div>
                         </div>
                       
                  </div> 
                  <div class="col-6">
                    <label class="labelCls">Area/Locality/Sector: <span class="mandatoryCls"> *</span></label>
                    <input type="text" name="area" appBlockCopyPaste class="inputCls" formControlName = "area"   maxlength ="100" required [ngClass]="{ 'is-invalid':submitted && f.area.errors}">
                   
                 
                    <div *ngIf="submitted && f.area.errors" class="invalid-feedback">
                       <div *ngIf="f.area.errors?.required">Please enter Area/Locality/Sector</div>
                     </div>
                   
              </div> 
            
                  </div>

                  <div class="row mb-3">
                    <div class="col-6">
                        <label class="labelCls">Street/Road/Lane: <span class="mandatoryCls"> *</span></label>
                        <input type="text" name="street" appBlockCopyPaste class="inputCls" formControlName = "street"  required [ngClass]="{ 'is-invalid':submitted && f.street.errors}">
                       
                     
                        <div *ngIf="submitted && f.street.errors" class="invalid-feedback">
                           <div *ngIf="f.street.errors?.required">Please enter Street/Road/Lane</div>
                         </div>                       
                      </div> 
                    <div class="col-6">
                      <label class="labelCls">Village/Town/City: <span class="mandatoryCls"> *</span></label>
                      <input type="text" name="village" appBlockCopyPaste class="inputCls" formControlName = "village"    required [ngClass]="{ 'is-invalid':submitted && f.village.errors}">
                      <div *ngIf="submitted && f.village.errors" class="invalid-feedback">
                        <div *ngIf="f.village.errors?.required">Please enter Village/Town/City</div>
                      </div>                   
                    </div>            
                  </div>

                  <div class="row mb-3">
                    <div class="col-6">
                      <label class="labelCls">Panchayat Name/Ward Number: <span class="mandatoryCls"> *</span></label>
                      <input type="text" name="panchayatName" appBlockCopyPaste class="inputCls" formControlName = "panchayatName"   maxlength ="50" required [ngClass]="{ 'is-invalid':submitted && f.panchayatName.errors}">
                     
                   
                      <div *ngIf="submitted && f.panchayatName.errors" class="invalid-feedback">
                         <div *ngIf="f.panchayatName.errors?.required">Please enter Panchayat Name/Ward Number</div>
                       </div>
                     
                </div>
                  </div>
                  <div class="subHeadingCls mb-3">Workflow Details</div>
                  <div class="row mb-3">
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
                        <select class="selectCls" required formControlName="workflowDistrict" (change)="onDistrictChange()"
                        [ngClass]="{ 'is-invalid':submitted && (f.workflowDistrict.errors || f.workflowDistrict.value === 'Select') }">
                        <option  [ngValue]= "null" disabled>Select</option>
                          <option *ngFor="let district of workflowDistricts" [ngValue]="district">{{district.name}}</option>
                        </select>
                        <div *ngIf="submitted && (f.workflowDistrict.errors || f.workflowDistrict.value === 'Select')" class="selectError">
                          <div *ngIf="f.workflowDistrict.errors?.required || f.workflowDistrict.value === 'Select'">Please select District</div>
                      </div>
                      </div>
                      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
                        <select class="selectCls" required formControlName="workflowMandal" (change)="onMandalChange()"
                        [ngClass]="{ 'is-invalid':submitted && (f.workflowMandal.errors || f.workflowMandal.value === 'Select') }">
                        <option [ngValue]= "null" disabled>Select</option>
                        <option *ngFor="let mandal of workflowMandals" [ngValue]="mandal">{{mandal.name}}</option>
                      </select>
                      <div *ngIf="submitted && (f.workflowMandal.errors || f.workflowMandal.value === 'Select')" class="selectError">
                        <div *ngIf="f.workflowMandal.errors?.required || f.workflowMandal.value === 'Select'">Please select Mandal/Municipality</div>
                      </div>
                      </div>
                      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Village/Ward/Secretariat: <span class="mandatoryCls">*</span></label>
                        <select class="selectCls" required formControlName="workflowSecretariat" (change)="onChangeSecretariat()"
                          [ngClass]="{ 'is-invalid':submitted && (f.workflowSecretariat.errors || f.workflowSecretariat.value === 'Select') }">
                          <option [ngValue]= "null" disabled>Select</option>
                          <option *ngFor="let secretariat of workflowSecretariats" [ngValue]="secretariat">{{secretariat.name}}</option>
                        </select>
                        <div *ngIf="submitted && (f.workflowSecretariat.errors || f.workflowSecretariat.value === 'Select')" class="selectError">
                          <div *ngIf="f.workflowSecretariat.errors?.required || f.workflowSecretariat.value === 'Select'">Please select Village/Ward/Secretariat</div>
                      </div>
                      </div>
                      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Designation: <span class="mandatoryCls">*</span></label>
                        <select class="selectCls"  formControlName="workflowDesignation" (change)="onChangeDesignation()"
                          [ngClass]="{ 'is-invalid':submitted && (f.workflowDesignation.errors || f.workflowDesignation.value === 'Select') }">
                          <option [ngValue]= "null" disabled>Select</option>
                          <option *ngFor="let designation of designations" [ngValue]="designation">{{designation}}</option>
                        </select>
                        <div *ngIf="submitted && (f.designation.errors || f.workflowDesignation.value === 'Select')" class="selectError">
                          <div *ngIf="f.workflowDesignation.errors?.required || f.workflowDesignation.value === 'Select'">Please select Designation</div>
                      </div>
                      </div>
                </div>
                  <hr class="hrCls mt-4">
                  <div class="subHeadingCls mb-3">Document List :</div>
                  <div class="mb-3">(Note: Upload Document should be in PDF Format Only and size should be less than 1 mb)</div>
                
                <div class="row">
                    <div class="fieldHolder col-12">
                      <label class="labelCls">Aadhar Card:<span class="mandatoryCls"> *</span></label>
                    </div>
                    <div class="input-group mb-3 col-6">
                      <div class="custom-file">
                          <input type="file" class="custom-file-input"  id="aadhar" formControlName = "aadhar" [ngClass]="{ 'is-invalid':submitted && f.aadhar?.errors }" 
                          (change) = "onFileSelected($event, 'aadhar','aadharLabel')" accept=".pdf" required>
                          <label class="custom-file-label" #aadharLabel for="aadhar"><i class="fas fa-search"></i>No File choosen</label>
                      </div>
                      <div class="clearCls" (click)="clearFileUpload('aadhar','aadharLabel')"  *ngIf="f.aadhar?.value"></div>
                      
                    </div>
                  
                    <div *ngIf="submitted && f.aadhar?.errors" class="selectError ml-3">
                      <div *ngIf="f.aadhar.errors?.required">Please upload Aadhar </div>
                      <div *ngIf="f.aadhar.errors?.inValidExt">Please upload the file in PDF Format</div>
                      <div *ngIf="f.aadhar.errors?.inValidSize && !f.aadhar.errors?.inValidExt">File size exceeding 1 mb</div>  
                    </div>
                  
                  </div>

                  <div class="row">
                    <div class="fieldHolder col-12">
                      <label class="labelCls">Other Documents (Rice Card/Driving License/Passport/Electricity Bill/Gas Bill):</label>
                    </div>
                    <div class="input-group mb-3 col-6">
                      <div class="custom-file">
                          <input type="file" class="custom-file-input"  id="otherDocument" formControlName = "otherDocument" [ngClass]="{ 'is-invalid':submitted && f.otherDocument?.errors }" 
                          (change) = "onFileSelected($event,'otherDocument','otherDocumentLabel')" accept=".pdf">
                          <label class="custom-file-label" #otherDocumentLabel for="otherDocument"><i class="fas fa-search"></i>No File choosen</label>
                      </div>
                      <div class="clearCls" (click)="clearFileUpload('otherDocument','otherDocumentLabel')"  *ngIf="f.otherDocument?.value"></div>
                      
                  
                    </div>
                  
                    <div *ngIf="submitted && f.otherDocument?.errors" class="selectError ml-3">
                      <div *ngIf="f.otherDocument.errors?.inValidExt">Please upload the file in PDF Format</div>
                      <div *ngIf="f.otherDocument.errors?.inValidSize && !f.otherDocument.errors?.inValidExt">File size exceeding 1 mb</div>  
                    </div> 
                  </div>
                  <hr class="hrCls mt-4">
                  <div class="subHeadingCls mb-3">Declaration</div>
                  <div class="row mb-3">
                    <div class="row mb-3">
                        <div class="ml-4 col-sm-9">
                          <input class="form-check-input " type="checkbox" formControlName = "consent" required value="" id="consent" [ngClass]="{ 'is-invalid':submitted && f.consent.errors }">
                          <label class="labelCls" for="consent"><b>I (Applicant), agree that the
                              information/documents submitted is with my consent and can be verified.<span
                            class="mandatoryCls">*</span></b> </label>
                          <div *ngIf="submitted && f.consent.errors" class="invalid-feedback">
                            <div *ngIf="f.consent.errors?.required">Please Check the Consent</div>
                          </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                      <div class="ml-4 col-sm-9">
                        <input class="form-check-input " type="checkbox" formControlName = "consent2" required value="" id="consent2" [ngClass]="{ 'is-invalid':submitted && f.consent2.errors }">
                        <label class="labelCls" for="consent2"><b>I (Verify Authority), hereby, declare that the particulars
                          given above are correct and complete. I will be responsible for any misrepresentation
                          of facts and will be liable for punitive action.<span
                          class="mandatoryCls">*</span></b> </label>
                        <div *ngIf="submitted && f.consent2.errors" class="invalid-feedback">
                          <div *ngIf="f.consent2.errors?.required">Please Check the Consent</div>
                        </div>
                      </div>
                  </div>
                  </div>
                  <div class="btnHolder">
                      <button class="greenBtnCls float-right" (click)="submit()" *ngIf="isCheckUrbanFlag">Show Payment</button>
                  </div>
            </ng-template>
          </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav"></div>
      </form>
    </div>
  </div>
  