import { Component, OnInit, Output , EventEmitter, Input} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonConstants } from '../../constants/common-constants.component';
import { AadharValidator, CommonService, WhitespaceValidator } from '../common.service';
import { EnvironmentService } from 'src/shared/environment.service';

@Component({
  selector: 'app-aadhar-authentication',
  templateUrl: './aadhar-authentication.component.html',
  styleUrls: ['./aadhar-authentication.component.css']
})
export class AadharAuthenticationComponent implements OnInit {
  @Output() isAuthenticated = new EventEmitter();
  @Input() aadharNumber: string = '';
  authenticationForm: FormGroup;
  submitted: Boolean = false;
  biometricFlag: Boolean = false;
  isPattadarOtpValidated: Boolean = false;
  isDisable: Boolean = true;
  commonConstants: any = CommonConstants;
  aadharTransactionID: any;
  loggedInUserId: any;
  showBiometricPopup: Boolean = false;
  autoBiometricId: any;
  encrtyptedAadhar: any;
  windowObjectReference: any;
  isAadharFieldDisable: Boolean = false;
  constructor(private formBuilder: FormBuilder, private commonService: CommonService, private environmentService: EnvironmentService) {
    this.authenticationForm = this.formBuilder.group({
      authenticationType: ['rbOtp',[Validators.required]],
      aadhaarOtp: [''],
      otp: [],
      adhaarNumberBio: ['']
    });
    let userInfo = localStorage.getItem('userInfo');
    if (userInfo && userInfo !== null) {
      let userData = JSON.parse(userInfo);
      this.loggedInUserId = userData.userPrincipalName;
    }
  }
  get f() {
    return this.authenticationForm.controls;
  }
  ngOnInit(): void {
    console.log('aadharNumber', this.aadharNumber);
    if(this.aadharNumber !== '' && this.aadharNumber !== undefined && this.aadharNumber !== null) {
      this.f.adhaarNumberBio.patchValue(this.aadharNumber);
      this.f.aadhaarOtp.patchValue(this.aadharNumber);
      this.isAadharFieldDisable = true;
      this.isDisable = false;
    } else {
      this.isAadharFieldDisable = false;
      this.isDisable = true;
    }
  }
  changeAuth() {
    if(this.f.authenticationType.value  === 'OTP') {
      this.f.aadhaarOtp.setValidators([Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]);
      this.f.otp.setValidators([Validators.required]);
      this.f.aadhaarOtp.updateValueAndValidity();
      this.f.otp.updateValueAndValidity();
      this.f.adhaarNumberBio.clearValidators();
      this.f.adhaarNumberBio.updateValueAndValidity();
    } else {
      this.f.adhaarNumberBio.setValidators([Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]);
      this.f.adhaarNumberBio.updateValueAndValidity();
      this.f.aadhaarOtp.clearValidators();
      this.f.otp.clearValidators();
      this.f.aadhaarOtp.updateValueAndValidity();
      this.f.otp.updateValueAndValidity();
    }
  }
  aadharChange(){
    if(this.f.aadhaarOtp.valid){
      this.isDisable = false;
    }else{
      this.isDisable = true;
    }
  }
  sendOTP():any{
    this.submitted = true;
    if(!this.f.aadhaarOtp.valid)
        return false;
    var sendPostData={
      uid: this.f.aadhaarOtp.value,
      authentication_type: "OTPGENERATE"
    }
    this.commonService.postRequestForAadhar(this.commonConstants.generateAadharOTP, sendPostData).subscribe({
      next: (responseData:any) => { 
        if(responseData?.reason == 'OTP Sent Successfully'){
          this.aadharTransactionID = responseData?.result
        } else {
          this.commonService.commonErrorPopup('Alert', responseData?.result, 'md');
        }
      },
      error: (error: any) => {
        console.log('client side', error);
      }
    });
  }
  validateOTP():any {
    this.submitted = true;
    if(!this.f.aadhaarOtp.valid ||  !this.f.otp.valid)
        return false;
    var sendPostData={
      PIDXML: this.aadharTransactionID,
      otp : this.f.otp.value,
      uid: this.f.aadhaarOtp.value, //'514492421388',//
      authentication_type: "OTPVALIDATE"
    }
    this.commonService.postRequestForAadhar(this.commonConstants.generateAadharOTP, sendPostData).subscribe({
      next: (responseData:any) => { 
        if(responseData?.reason == 'OTP Verified Successfully'){
            this.isPattadarOtpValidated = true;
            this.isAuthenticated.emit('true');
        } else if(responseData?.reason == 'OTP validation failed'){
         this.commonService.commonErrorPopup('Alert', 'Invalid OTP Entered. Please Enter Valid OTP.', 'md')
        } else{
          this.commonService.commonErrorPopup('Alert', 'Invalid OTP Entered. Please Enter Valid OTP.', 'md')
        }
      },
      error: (error: any) => {
        console.log('client side', error);
      }
    });
  }
  biometricBtn(from?:any): any {
    this.biometricFlag=true;
   
    if(!this.f.adhaarNumberBio.valid) {
      return false;
    }  else {
      let postData: any = {};
          this.encrtyptedAadhar =  this.commonService.RSAEncrypt(this.f.adhaarNumberBio.value);
          postData.autoBiometricId = 0;
          postData.aadharNumber = this.encrtyptedAadhar;
          postData.responseStatus = '';
          postData.userId = this.loggedInUserId;
          this.commonService.postRequestForWebLand(this.commonConstants.getSNoForBiometric, postData).subscribe({
            next: (responseData:any) => { 
            console.log('response from biometric', responseData)
            this.autoBiometricId = responseData?.result?.autoBiometricId;
            if (this.autoBiometricId !== undefined && this.autoBiometricId !== null) {
              this.showBiometricPopup = true;
                let url: any = this.environmentService.externalUrls.biometricRedirectionPage + encodeURIComponent(this.encrtyptedAadhar) +'&SNo='+ this.autoBiometricId +'&DoEkyc=false&ReqPhoto=false';
                this.windowObjectReference = window.open(url, "BiometricAuthentication", 'popup=1;left=500,top=100,width=780,height=700');
            } else {
              this.showBiometricPopup = false;
            }
          },
            error: (error) => {
              console.log('client side', error);
            }
          });
    }
    }
    

checkBiometricStatus(from?: any) {
  this.windowObjectReference.close();
  let postData: any = {};
  postData.autoBiometricId = this.autoBiometricId;
  postData.aadharNumber = this.encrtyptedAadhar;
  postData.responseStatus = '';
  postData.userId = this.loggedInUserId;
  this.commonService.postRequestForWebLand(this.commonConstants.getSNoForBiometric, postData).subscribe({
    next: (responseData:any) => { 
    console.log('response from biometric', responseData)
   // this.autoBiometricId = responseData?.result?.autoBiometricId;
   let status = responseData?.result?.responseStatus;
   if (status === 'Success') {
    this.isAuthenticated.emit('true');
    this.showBiometricPopup = true;
    this.commonService.commonErrorPopup('Alert', 'Biometric Authentication Success.', 'md');
   } else {
    this.showBiometricPopup = false;
    this.commonService.commonErrorPopup('Alert', 'Biometric Authentication failed. Please try again!!', 'md');
   }
   
  },
    error: (error) => {
      console.log('client side', error);
    }
  });
}
}
