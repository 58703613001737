<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-'}}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <ng-container>
        <div class="subHeadingCls mb-3">Land Details</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">District:</label>
                    <div class="valueCls">{{certificateInfo?.districtName}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Mandal:</label>
                    <div class="valueCls">{{certificateInfo?.mandalMunicipalityName}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Village:</label>
                    <div class="valueCls">{{certificateInfo?.villageWardName}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Brief Content Of Application:</label>
                    <div class="valueCls">{{certificateInfo?.briefContentofApplication}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Survey Number:</label>
                    <div class="valueCls">{{certificateInfo?.surveyNo}}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Khata Number:</label>
                <div class="valueCls">{{certificateInfo?.khataNo}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Extent:</label>
                <div class="valueCls">{{certificateInfo?.extent}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Possession Type:</label>
                <div class="valueCls">{{certificateInfo?.possessionType}}</div>
            </div>
            <div class="fieldHolder" *ngIf = "certificateInfo?.subPossessionType">
                <label class="labelCls">Possession Type:</label>
                <div class="valueCls">{{certificateInfo?.subPossessionType}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Category:</label>
                <div class="valueCls">{{certificateInfo?.category}}</div>
            </div>
            <div class="fieldHolder" *ngIf = "certificateInfo?.subCategoryType">
                <label class="labelCls">Sub Category Type:</label>
                <div class="valueCls">{{certificateInfo.subCategoryType }}</div>
            </div>
            </div>
        </div>
    </ng-container>
</div>
