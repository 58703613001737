<div class="container-fluid">
    <div class="paymentGateway" *ngIf="isAuth">
        <div class="">
            <br>
            <div class="row my-3">
               

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12"></div>

                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3 clsbox">
                    
                   
                    <div class="totalamount mt-3 text-center">
                        <label class="labelCls" id="sizeFont"><b>GSWS EXTERNAL PAYMENT GATEWAY</b></label>
                    </div>

                    <div class="paymentContainer" *ngIf="isAuth">
                        <div class="orderSummary">
                            <h6 class="float-left">Payment Details</h6>
                        </div>
                        <br>
                        <hr>
                       
                        <div>
                            <label class="custLbl"> UserId </label>
                            <span><strong>{{ userData }}</strong></span>
                        </div>
                        <div>
                            <label class="custLbl">RequestType </label>
                            <span><strong> {{ amountData[15] }}</strong></span>
                        </div>
                        <div>
                            <label class="labelCls">RequestId </label>
                            <span><strong> {{ amountData[0] }}</strong></span>
                        </div>
                       
                      <!--   <div>
                            <label class="labelCls">Request Sub Type </label>
                            <span><strong>{{ amountData[12] }}</strong></span>
                        </div> -->
                        <div>
                            <label class="labelCls">Department </label>
                            <span><strong>{{ amountData[16] }}</strong></span>
                        </div>
                        <div>
                            <label class="labelCls">Customer Name </label>
                            <span><strong>{{ amountData[9] }}</strong></span>
                        </div>

                        <!--<br>
                         <div>
                            <label class="labelCls">Agent ID </label>
                            <span><strong>{{ amountData[11] }}</strong></span>
                        </div>
                        <div>
                            <label class="labelCls">Agent Name </label>
                            <span><strong>{{ amountData[10] }}</strong></span>
                        </div>
                        <div>
                            <label class="labelCls">Customer Name </label>
                            <span><strong>{{ amountData[9] }}</strong></span>
                        </div>
                        <div>
                            <label class="labelCls">Secretrait ID </label>
                            <span><strong>{{ amountData[13] }}</strong></span>
                        </div> -->
                        

                        <br>
                        <div>
                            <label class="labelCls">User Charges </label>
                            <span><strong>{{ amountData[3] }}</strong></span>
                        </div>
                        <div>
                            <label class="labelCls">Service Charges </label>
                            <span><strong>{{ amountData[4] }}</strong></span>
                        </div>
                        <div>
                            <label class="labelCls">Statutary Charges </label>
                            <span><strong>{{ amountData[5] }}</strong></span>
                        </div>
                        <div>
                            <label class="labelCls">Delivery Charges </label>
                            <span><strong>{{ amountData[6] }}</strong></span>
                        </div>
                        <div>
                            <label class="labelCls">Transaction Amount </label>
                            <span><strong>{{ amountData[7] }}</strong></span>
                        </div>
                       
                        <br><hr>
                        <div class="text-center ">
                            <button name="payment" class="btn btn-success col-lg-6" (click)="proceedPayment()" [disabled]="!isProceed">Proceed To Payment</button>
                        </div>
                        <br>
                        <div class="text-center ">
                            <a class="cancel" href="">Cancel Payment</a>
                        </div>
                      
                        <br>
                    </div>


                    <div class="paymentContainer" *ngIf="!isAuth">
                        <div class="orderSummary">
                            <h6 class="float-left">Payment Details Not able to decrypt !!!</h6>
                        </div>
                    </div>   
                </div>
               
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12"></div>
            
            </div>
        </div>

    </div>
