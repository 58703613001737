<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Type Of Application</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Type Of Application:</label>
                <div class="valueCls">{{certificateInfo?.typeofApplication ? (certificateInfo?.typeofApplication) : '-'}}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Applicant Present Sur Name:</label>
                <div class="valueCls">{{certificateInfo?.applicantPresentSurName ? (certificateInfo?.applicantPresentSurName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Applicant Present Name:</label>
                <div class="valueCls">{{certificateInfo?.applicantPresentName ? (certificateInfo?.applicantPresentName) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Applicant Proposed Sur Name:</label>
            <div class="valueCls">{{certificateInfo?.applicantProposedSurName ? (certificateInfo?.applicantProposedSurName) : '-' }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Applicant Proposed Name:</label>
            <div class="valueCls">{{certificateInfo?.applicantProposedName ? (certificateInfo?.applicantProposedName) : '-' }}</div>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Father Name:</label>
                <div class="valueCls">{{certificateInfo?.fatherName ? (certificateInfo?.fatherName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Police Station Name having jurisdiction:</label>
                <div class="valueCls">{{certificateInfo?.policsStationJurisdictionName ? (certificateInfo?.policsStationJurisdictionName) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Mother Name:</label>
                <div class="valueCls">{{certificateInfo?.motherName ? (certificateInfo?.motherName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Place Of Birth:</label>
                <div class="valueCls">{{certificateInfo?.placeOfBirth ? (certificateInfo?.placeOfBirth) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo?.typeofApplication == 'minor'">
    <div class="subHeadingCls mb-3">Occupation Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Work/Education:</label>
                <div class="valueCls">{{certificateInfo?.workOrEducation ? (certificateInfo?.workOrEducation) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo?.typeofApplication == 'major'">
    <div class="subHeadingCls mb-3">Occupation Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Profession:</label>
                <div class="valueCls">{{certificateInfo?.profession ? (certificateInfo?.profession) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo?.profession == 'Government Employee'">
    <div class="subHeadingCls mb-3">{{certificateInfo?.profession ? (certificateInfo?.profession) : '-' }}</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Name of the Employee:</label>
                <div class="valueCls">{{certificateInfo.employeeDetailsInfo?.employeeName ? (certificateInfo.employeeDetailsInfo?.employeeName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Designation:</label>
                <div class="valueCls">{{certificateInfo.employeeDetailsInfo?.designation ? (certificateInfo.employeeDetailsInfo?.designation) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Name of the Department:</label>
                <div class="valueCls">{{certificateInfo.employeeDetailsInfo?.departmentName ? (certificateInfo.employeeDetailsInfo?.departmentName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Date of Appointment:</label>
                <div class="valueCls">{{certificateInfo.employeeDetailsInfo?.appointmentDate | date: 'dd/MM/yyyy'   }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo?.profession == 'Non Government Employee'">
    <div class="subHeadingCls mb-3">{{certificateInfo?.profession}}</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Name of the Employee:</label>
                <div class="valueCls">{{certificateInfo.employeeDetailsInfo?.employeeName ? (certificateInfo.employeeDetailsInfo?.employeeName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Designation:</label>
                <div class="valueCls">{{certificateInfo.employeeDetailsInfo?.designation ? (certificateInfo.employeeDetailsInfo?.designation) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Name of the Company:</label>
                <div class="valueCls">{{certificateInfo.employeeDetailsInfo?.companyName ? (certificateInfo.employeeDetailsInfo?.companyName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Date of Appointment:</label>
                <div class="valueCls">{{certificateInfo.employeeDetailsInfo?.appointmentDate | date: 'dd/MM/yyyy' }}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Company Address:</label>
                <div class="valueCls">{{certificateInfo.employeeDetailsInfo?.companyAddress ? (certificateInfo.employeeDetailsInfo?.companyAddress) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo?.profession == 'Others'">
    <div class="subHeadingCls mb-3">{{certificateInfo?.profession ? (certificateInfo?.profession) : '-' }}</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Employment:</label>
                <div class="valueCls">{{certificateInfo?.employment ? (certificateInfo?.employment) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList ; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Recent Passport size Photograph'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Recent Passport size Photograph:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Citizenship Certificate issued by Gazetted Officer'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Citizenship Certificate issued by Gazetted Officer:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Affidavit stating exact reason for change of his/her name'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Affidavit stating exact reason for change of his/her name:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Certificates from Class 1 to 10th attested by Gazetted Officer'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Certificates from Class 1 to 10th attested by Gazetted Officer:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Police Certificate stating that he or she has not come to any adverse records during last 5 years'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Police Certificate stating that he or she has not come to any adverse records during last 5 years:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Others(Aadhar/Ration Card/VoterID etc for Illiterates)'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Others(Aadhar/Ration Card/VoterID etc for Illiterates):</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>
