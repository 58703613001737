<div class="certificateInfo" *ngIf="certificateInfo">   
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Aadhar No: </label>
                <div class="valueCls">{{certificateInfo.applicantAadharNo}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Applicant Name: </label>
                <div class="valueCls">{{certificateInfo.applicantName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Relation With Pattadar:</label>
            <div class="valueCls">{{certificateInfo.relationWithOwner}}</div>
        </div>
        </div>
    </div>
    <div class="subHeadingCls mb-3">Land Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo.landDetails?.districtName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo.landDetails?.mandalMunicipalityName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{certificateInfo.landDetails?.villageWardName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Khata Number:</label>
                <div class="valueCls">{{certificateInfo.khataNo}}</div>
            </div>
        </div>
    </div>
    <hr class="hrCls mt-4">
    <div class="subHeadingCls mb-3">Pattadar Details</div>
    <div class="row mt-3">
        <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label class="labelCls">Pattadar Name:</label>
            <span> {{ pattadarDetails[0]?.pattadarTeluguName }} </span>
        </div>
        <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label class="labelCls">LP Numbers:</label>
            <span> {{ lpmNumbers }} </span>
        </div>
    </div>
    <ng-container *ngIf="certificateInfo.isOtherState">
    <div class="subHeadingCls mb-3">Postal Address</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Door No & Street Name: :</label>
                <div class="valueCls">{{certificateInfo.postalLocation }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">State:</label>
                <div class="valueCls">{{certificateInfo.postalState}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">District:</label>
            <div class="valueCls">{{certificateInfo.postalDistrict }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Mandal:</label>
            <div class="valueCls">{{certificateInfo.postalMandal}}</div>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{certificateInfo.postalVillage }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Pin Code:</label>
                <div class="valueCls">{{certificateInfo.postalPincode }}</div>
            </div>
        </div>
    </div>
    </ng-container>
    <div class="subHeadingCls mb-3">Adangal Details</div>
    <div class="row mb-3">
        <div class="col">
            <div class="tab table-responsive">
                <table class="tableCls">
                    <tr>
                        <th>S.No</th>
                        <th>Pattadar Name</th>
                        <th>Pattadar Father/ Husband Name</th>
                        <th>Khata Number</th>
                        <th>LP Number</th>
                        <th>LP Extent</th>
                        <th>Old Survey Number</th>
                        <th>Land classification</th>
                        <th>Acquisition Rights</th>
                    </tr>
                    <tr *ngFor="let item of landDetails; let i=index">
                        <td>{{ i + 1 }}</td>
                        <td>{{pattadarDetails[0]?.pattadarTeluguName}}</td>
                        <td>{{pattadarDetails[0]?.pattadarFatherNameTelugu}}</td>
                        <td>{{pattadarDetails[0]?.khathaNumber}}</td>
                        <td>{{item?.lpM_No}}</td>
                        <td>{{item?.lpM_Extent}}</td>
                        <td>{{item?.surveyNo}}</td>
                        <td>{{item?.landClassification}}</td>
                        <td>{{item?.modeofAcquistionTelugu}}</td>
                    </tr>
            </table>
            </div>
        </div>
    </div>
    <ng-container *ngIf="jointDetails && jointDetails !== undefined && jointDetails?.length > 0">
        <div class="subHeadingCls mb-3">Joint Cultivator Details</div>
        <div class="row mb-3">
            <div class="col">
                <div class="tab table-responsive">
                    <table class="tableCls">
                        <tr>
                            <th>S.No</th>
                            <th>LP Number</th>
                            <th>Khata Number</th>
                            <th>Pattadar Name</th>
                            <th>Pattadar Father/ Husband Name</th>
                        
                        </tr>
                        <tr *ngFor="let item of jointDetails; let i=index">
                            <td>{{ i + 1 }}</td>
                            <td>{{item?.lpM_No}}</td>
                            <td>{{item?.khataNo}}</td>
                            <td>{{item?.pattadarName}}</td>
                            <td>{{item?.pattadarRelationName}}</td>
                        </tr>
                </table>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Reason for Issuance of BHP:</label>
                <div class="valueCls">{{certificateInfo.passbookType }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo mt-2 mb-3">
    <div class="row">
      <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <ng-container *ngIf="fileList && fileList?.length !== 0">
          <div class="subHeadingCls mb-3">List of Documents</div>
          <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
            <div *ngIf="file.documentType === 'aadharPhoto'">
              <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Pattadar Aadhar Photo:</label>
              <div class="valueCls">
                <a (click)="viewImgFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                  class="download ml-2"></span>
              </div>
            </div>
            <div *ngIf="file.documentType === 'Signature'">
              <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Signature:</label>
              <div class="valueCls">
                <a (click)="viewImgFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                  class="download ml-2"></span>
              </div>
            </div>
            <div *ngIf="file.documentType === 'weblandPhoto'">
              <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Pattadar Photo from adangal details:</label>
              <div class="valueCls">
                <a (click)="viewImgFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                  class="download ml-2"></span>
              </div>
            </div>
          
          </div>
        </ng-container>
      </div>
    </div>
  </div>