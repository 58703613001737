<div class="modal-header" >
    <button type="button" class="close" aria-label="Close" (click)="reset()" aria-label="Close" (click)="activeModal.close('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="mr-3 modal-body">
<div class="container">
    <form [formGroup]="stateForm">
        <div>
            <label class="labelCls">Are You Residing In:<span class="mandatoryCls">*</span></label>
            <div>
                <input type="radio" name="authenticationType" value="wState" (click)="authType('wState')"
                    formControlName="authenticationType" />
                <span class="mx-2 radioCls">WithIn State</span>
                <input type="radio" name="authenticationType" value="oState" (click)="authType('oState')"
                    formControlName="authenticationType" />
                <span class="mx-2 radioCls">OutSide State</span>
            </div>
            <div *ngIf="submits && f.authenticationType.errors"
                class="invalid-feedback col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div *ngIf="f.authenticationType.errors.required">
                    Please select either with In the State / outside State
                </div>
            </div>
        </div>
    </form>
</div>


<br>
<div class="container">
<div *ngIf="f2.authenticationType.value == 'wState' || f2.authenticationType.value == 'oState'">
        <form [formGroup]="ekycForm">
            <div class="subHeadingCls mb-3">Authentication</div>
            <div class="row mb-3">
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label class="labelCls">Authentication Type:<span class="mandatoryCls">*</span></label>
                    <div class="col-sm-9" required [ngClass]="{ 'is-invalid': submits && f.authenticationType.errors }">
                        <input type="radio" name="authenticationType" value="rbOTP"
                            formControlName="authenticationType" (change)="otpChange($event)" />
                        <span class="mx-2 radioCls">OTP(One Time Password)</span>
                        <input type="radio" name="authenticationType" value="rbBiometric" (change)="otpChange($event)"
                            formControlName="authenticationType"/>
                        <span class="mx-2 radioCls">Biometric</span>
                    </div>
                    <div *ngIf="submits && f.authenticationType.errors"
                        class="invalid-feedback col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div *ngIf="f.authenticationType.errors.required">
                            Please select either OTP / Biometric
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="f.authenticationType.value == 'rbOTP'">
                <div class="row mb-3">
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Aadhaar Number: <span class="mandatoryCls">*</span></label>
                        <input type="text" appBlockCopyPaste name="aadhaarNumber" formControlName="aadhaarNumber"
                            (keyup)="aadharChange('aadhar')" class="inputCls width75" numbersOnly
                            [ngClass]="{ 'is-invalid': submits && f.aadhaarNumber.errors }" maxlength="12" minlength="12" readonly />
                        <button class="btn btn-primary float-right" [disabled]="isAadharDisable"
                            (click)="sendAadharOtp()">Send OTP
                        </button>
                        <div *ngIf="submits && f.aadhaarNumber.errors" class="invalid-feedback">
                            <div *ngIf="f.aadhaarNumber.errors.required || f.aadhaarNumber.errors.whiteSpaceValidate">
                                Aadhaar Number is required
                            </div>
                            <div
                                *ngIf="f.aadhaarNumber.errors.aadharValidate || f.aadhaarNumber.errors.minlength || f.aadhaarNumber.errors.maxlength">
                                Please enter 12 digit Valid Aadhaar Number
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isAadharDisable" class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">OTP (One Time Password): <span class="mandatoryCls">*</span></label>
                        <input type="password" name="aadharOtp" appBlockCopyPaste class="inputCls"
                            formControlName="aadharOtp" required
                            [ngClass]="{ 'is-invalid': submits && f.aadharOtp.errors }" numbersOnly minlength="6"
                            maxlength="6" [readonly]="isAadharOtpValidated" />
                        <div *ngIf="submits && f.aadharOtp.errors" class="invalid-feedback">
                            <div *ngIf="f.aadharOtp.errors?.required">
                                Please enter 6 digit Valid OTP
                            </div>
                        </div>
                    </div>
                </div>

                <div class="btnHolder" *ngIf="f.authenticationType.value == 'rbOTP'">
                    <button class="greenBtnCls float-right" [disabled]="isAadharOtpValidated"
                        (click)="validateAadharOTP()">OTP Authenticate
                    </button>
                </div>
            </div>

            <!-- Biometric code here -->
            <div *ngIf="f.authenticationType.value == 'rbBiometric'">
                <div class="row mb-3">
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Aadhaar Number: <span class="mandatoryCls">*</span></label>
                        <input type="text" appBlockCopyPaste name="aadhaarNumberBio" formControlName="aadhaarNumberBio"
                            class="inputCls" [required]="f.authenticationType.value == 'rbBiometric'" numbersOnly readonly
                            [ngClass]="{'is-invalid': biometricFlag && f.aadhaarNumberBio.errors}" maxlength="12"
                            minlength="12" />
                        <div *ngIf="biometricFlag && f.aadhaarNumberBio.errors" class="invalid-feedback">
                            <div
                                *ngIf="f.aadhaarNumberBio.errors.required || f.aadhaarNumberBio.errors.whiteSpaceValidate">
                                Aadhaar Number is required
                            </div>

                            <div
                                *ngIf="f.aadhaarNumberBio.errors.aadharValidate || f.aadhaarNumberBio.errors.minlength || f.aadhaarNumberBio.errors.maxlength">
                                Please enter valid Aadhaar Number
                            </div>
                        </div>
                    </div>

                    <div class="btnHolder mt-4">
                        <button *ngIf="!showBiometricPopup" class="greenBtnCls float-right" (click)="biometricBtn()">
                            Biometric Authenticate
                        </button>
                    </div>
                </div>

                <div class="btnHolder" *ngIf="f.authenticationType.value == 'rbBiometric'">
                    <button *ngIf="showBiometricPopup" class="greenBtnCls float-right" (click)="checkBiometricStatus()">
                        Check Biometric Status
                    </button>
                </div>
            </div>
        </form>
</div>
</div>
</div>