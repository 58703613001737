<div class="certificateInfo">
    <div class="subHeadingCls mb-3">Assignment of Lands to Ex-Serviceperson/Serving Soldiers</div>
    <div class="subHeadingCls mb-3">Details of Ex-Service Person/Soldier applying for grant of Assignment:</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Name of Service Person/Soldier:</label>
                <div class="valueCls">{{certificateInfo?.nameofServicePersonOrSoldier ? (certificateInfo?.nameofServicePersonOrSoldier) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Category of Service person/Soldier:</label>
                <div class="valueCls">{{certificateInfo?.categoryofServicepersonOrSoldie ? (certificateInfo?.categoryofServicepersonOrSoldie) : '-'}}</div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Is the Soldier/Ex-Service person a Gallantry Award Winner ?</label>
                <div class="valueCls">{{certificateInfo?.isGallantryAwardWinner === true ? 'Yes' : 'No' }}</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie === 'Ex-Serviceperson' || certificateInfo?.categoryofServicepersonOrSoldie === 'Serving Soldier'">
                <label class="labelCls">Is the soldier/ex-service person War Disabled ?</label>
                <div class="valueCls">{{ certificateInfo?.isWarDisabled === true ? 'Yes' : 'No' }}</div>
            </div>
          </div> 
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder" *ngIf="certificateInfo?.isWarDisabled == true">
                <label class="labelCls">Please Specify: </label>
                <div class="valueCls">{{certificateInfo?.disabilityDetails ? (certificateInfo?.disabilityDetails) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Date of Application received to ZSWO:</label>
                <div class="valueCls">{{certificateInfo?.dateofApplicationreceivedtoZSWO ? (certificateInfo?.dateofApplicationreceivedtoZSWO) : '-' }}</div>
            </div>
           </div> 
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Service Number:</label>
                <div class="valueCls" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Ex-Serviceperson'">{{certificateInfo?.exSoldierDetails.serviceNumber ? (certificateInfo?.exSoldierDetails.serviceNumber) : '-' }}</div>
                <div class="valueCls" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Serving Soldier'">{{certificateInfo?.servingSoldierDetails.serviceNumber ? (certificateInfo?.servingSoldierDetails.serviceNumber) : '-' }}</div>
                <div class="valueCls" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Deceased Soldier' || certificateInfo?.categoryofServicepersonOrSoldie == 'DeceasedSoldier'">{{certificateInfo?.deceasedSoldierDetails.serviceNumber ? (certificateInfo?.deceasedSoldierDetails.serviceNumber) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Rank:</label>
                <div class="valueCls" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Ex-Serviceperson'">{{certificateInfo?.exSoldierDetails.rank ? (certificateInfo?.exSoldierDetails.rank) : '-' }}</div>
                <div class="valueCls" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Serving Soldier'">{{certificateInfo?.servingSoldierDetails.rank ? (certificateInfo?.servingSoldierDetails.rank) : '-' }}</div>
                <div class="valueCls" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Deceased Soldier' || certificateInfo?.categoryofServicepersonOrSoldie == 'DeceasedSoldier'">{{certificateInfo?.deceasedSoldierDetails.rank ? (certificateInfo?.deceasedSoldierDetails.rank) : '-' }}</div>
            </div>  
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls" >Name if Corps/Records:</label>
                <div class="valueCls" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Ex-Serviceperson'">{{certificateInfo?.exSoldierDetails.nameofCorpsOrRecords ? (certificateInfo?.exSoldierDetails.nameofCorpsOrRecords) : '-' }}</div>
                <div class="valueCls" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Serving Soldier'">{{certificateInfo?.servingSoldierDetails.nameofCorpsOrRecords ? (certificateInfo?.servingSoldierDetails.nameofCorpsOrRecords) : '-' }}</div>
                <div class="valueCls" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Deceased Soldier' || certificateInfo?.categoryofServicepersonOrSoldie == 'DeceasedSoldier'">{{certificateInfo?.deceasedSoldierDetails.nameofCorpsOrRecords ? (certificateInfo?.deceasedSoldierDetails.nameofCorpsOrRecords) : '-' }}</div>
            </div>               
            <div class="fieldHolder">
                <label class="labelCls" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Ex-Serviceperson' || certificateInfo?.categoryofServicepersonOrSoldie == 'Serving Soldier'">Entry Date of Enrolment:</label>
                <label class="labelCls" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Deceased Soldier' || certificateInfo?.categoryofServicepersonOrSoldie == 'DeceasedSoldier'">Date of Enrolment</label>
                <div class="valueCls" *ngIf="certificateInfo.categoryofServicepersonOrSoldie == 'Ex-Serviceperson'">{{certificateInfo?.exSoldierDetails.entryDateofEnrolment ? (certificateInfo?.exSoldierDetails.entryDateofEnrolment) : '-' }}</div>
                <div class="valueCls" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Serving Soldier'">{{certificateInfo?.servingSoldierDetails.entryDateofEnrolment ? (certificateInfo?.servingSoldierDetails.entryDateofEnrolment) : '-' }}</div>
                <div class="valueCls" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Deceased Soldier' || certificateInfo?.categoryofServicepersonOrSoldie == 'DeceasedSoldier'">{{certificateInfo?.deceasedSoldierDetails.entryDateofEnrolment ? (certificateInfo?.deceasedSoldierDetails.entryDateofEnrolment) : '-' }}</div>
            </div> 
        </div>
        <div  *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Ex-Serviceperson'" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Ex-Serviceperson'">
                <label class="labelCls">Date of Discharge:</label>
                <div class="valueCls">{{certificateInfo?.exSoldierDetails.dateofDischarge ? (certificateInfo?.exSoldierDetails.dateofDischarge) : '-' }}</div>
            </div> 
            <div class="fieldHolder" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Ex-Serviceperson'">
                <label class="labelCls">Cause of Discharge:</label>
                <div class="valueCls">{{certificateInfo?.exSoldierDetails.causeofDischarge ? (certificateInfo?.exSoldierDetails.causeofDischarge) : '-' }}</div>
            </div> 
        </div> 
        <div *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Deceased Soldier' || certificateInfo?.categoryofServicepersonOrSoldie == 'DeceasedSoldier'" class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Deceased Soldier' || certificateInfo?.categoryofServicepersonOrSoldie == 'DeceasedSoldier'">
                <label class="labelCls">Date of Death:</label>
                <div class="valueCls">{{certificateInfo?.deceasedSoldierDetails.dateofDeath ? (certificateInfo?.deceasedSoldierDetails.dateofDeath) : '-' }}</div>
            </div> 
            <div class="fieldHolder" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Deceased Soldier' || certificateInfo?.categoryofServicepersonOrSoldie == 'DeceasedSoldier'">
                <label class="labelCls">Cause of Death:</label>
                <div class="valueCls">{{certificateInfo?.deceasedSoldierDetails.causeofDeath ? (certificateInfo?.deceasedSoldierDetails.causeofDeath) : '-' }}</div>
            </div> 
        </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="subHeadingCls mb-3">Details Of Applicant</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Aadhaar Number:</label>
                <div class="valueCls">{{certificateInfo?.applicantAadharNo ? certificateInfo?.applicantAadharNo : "-" }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Name of Applicant:</label>
                <div class="valueCls">{{certificateInfo?.nameofServicePersonOrSoldier ? (certificateInfo?.nameofServicePersonOrSoldier) : '-' }}</div>
            </div>  
        </div> 
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Relation of the applicant with Soldier:</label>
                <div class="valueCls">{{certificateInfo?.relation ? (certificateInfo?.relation) : '-' }}</div>
            </div>    
        </div>
    </div>
</div>
<div class="certificateInfo"></div>