<div class="modal-header" >
    <!-- <h6 class="modal-title">Successor </h6> -->
    <!-- <button type="button" class="close" aria-label="Close"  (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>
  <div class="modal-body basic-details-popup">
    <form [formGroup]="popupForm">
    <div class="row mb-3">
        <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label class="labelCls">Please enter {{name}} Aadhar No: <span class="mandatoryCls">*</span></label>
            <input type="text" name="successorAadharNo" class="inputCls width75" maxlength="12" minlength="12" appBlockCopyPaste required numbersOnly
                formControlName="successorAadharNo" [ngClass]="{ 'is-invalid':popupSubmitted && f1.successorAadharNo.errors }">
            <div *ngIf="popupSubmitted && f1.successorAadharNo.errors" class="invalid-feedback">
                <div *ngIf="f1.successorAadharNo.errors.required || f1.successorAadharNo.errors.whiteSpaceValidate">Aadhaar Number is required</div>
                <div *ngIf="f1.successorAadharNo.errors.aadharValidate || f1.successorAadharNo.errors.minlength || f1.successorAadharNo.errors.maxlength">Please enter valid Aadhaar Number</div>
            </div>
    </div>
    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <label class="labelCls">Father/Husband Name: <span class="mandatoryCls">*</span></label>
      <input type="text" name="fatherHusbandName" class="inputCls" appBlockCopyPaste  maxlength="150"
          formControlName="fatherHusbandName" [ngClass]="{ 'is-invalid':popupSubmitted && f1.fatherHusbandName.errors }">
          <div *ngIf="popupSubmitted && f1.fatherHusbandName.errors" class="invalid-feedback">
            <div *ngIf="f1.fatherHusbandName.errors.required || f1.fatherHusbandName.errors.whiteSpaceValidate"> Father/Husband Name is required</div>
            <div *ngIf="f1.fatherHusbandName.errors?.pattern">Please enter valid Father/ Husband Name</div>
        </div>
    </div>
    </div>
    </form>
    <button  type="submit" class="greenBtnCls"  (click)="getSuccessorLand()">Get Details</button>
  </div>
  <!-- <div class="modal-footer">
  </div> -->
