import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-view-change-of-rice-card',
  templateUrl: './view-change-of-rice-card.component.html',
  styleUrls: ['./view-change-of-rice-card.component.css']
})
export class ViewChangeOfRiceCardComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() presentAddr: any;
  @Input() commonFormAddedFields : any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  commonConstants: any = CommonConstants;
  clusterName : any;

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.clusterName = "C"+this.certificateInfo?.clusterId.substring(9);
    console.log(this.clusterName)
  }

}
