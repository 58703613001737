import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';
import { DigitalSignService } from 'src/shared/digital-sign.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-e-mudra',
  templateUrl: './e-mudra.component.html',
  styleUrls: ['./e-mudra.component.css']
})



export class EMudraComponent implements OnInit, OnChanges {

  emudhraformGroup: FormGroup ;
  public submitted: Boolean = false;
  requsitionID : string = "";
  commonConstants : any = CommonConstants;
  JSON: any;
  tokenDetails = '';
  tokenList :any;
  certificateList :any;
  certificateTokenId = '';
  keyStorePassphrase = '';
  @Input('digitalSign') digiSignPopUp = true;
  @Output() digitalSignChange = new EventEmitter<boolean>();
  @Output() docGenChange = new EventEmitter<any>();
  @Output() docSubChange = new EventEmitter<any>();
  
  constructor(private commonService: CommonService, private formBuilder: FormBuilder, private modalService: NgbModal, private digiSign: DigitalSignService,private utils: CommonService) 
  {
    this.emudhraformGroup = this.formBuilder.group({
      ddTokens : [null, [Validators.required]],
      ddCertificates : [null, [Validators.required]],
      ddPassword : ['', [Validators.required]],
    });
  }

  openModal(targetModal: any) {
    this.modalService.open(targetModal, 
      { size: 'lg' ,
      centered: true,
      backdrop: 'static'
      }
      );
}
    
  get e() { return this.emudhraformGroup.controls; }

  ngOnInit(): void {
    this.JSON = JSON;
    if (this.digiSignPopUp) {
      this.loadTokens();
    }
  }

  ngOnChanges(): void {
    if (this.digiSignPopUp) {
      this.loadTokens();
    }
  }
 
  digiSignPopUpChange(): void {
    this.digitalSignChange.emit(false);
  }

  loadTokens(): void {
    this.digiSign
      .tokenList()
      .then((resp: any) => {
        if(resp.success){
          this.tokenList = resp.result;
        }
      })
      .catch((error: any) => {
        this.utils.catchResponse(error);
      });
  }

  tokenChange(): void {
    this.certificateList = [];
    console.log('tokevalue',this.emudhraformGroup.controls.ddTokens.value)
    this.tokenDetails = this.emudhraformGroup.controls.ddTokens.value
    let certReq = {
      keyStoreDisplayName: JSON.parse(this.tokenDetails).keyStoreDisplayName,
      certFilter: {
        commonName: '',
        issuerName: '',
        serialNumber: '',
        isNotExpired: '',
      },
    };

    this.digiSign
      .certificateList(certReq)
      .then((resp: any) => {
        if(resp.success){
          this.certificateList = resp.result;
        }
      })
      .catch((error: any) => {
        this.utils.catchResponse(error);
      });
  }

  btnSignDoc() : any {
    this.submitted = true;
    if (!this.emudhraformGroup.valid )
      return false;
    else { 
      this.docGenChange.emit();
      this.signDoc();
    }
  }

 
  emBridgeSignerInput : any;

  signDoc(): void {
    this.keyStorePassphrase = this.emudhraformGroup.controls.ddPassword.value
    this.certificateTokenId = this.emudhraformGroup.controls.ddCertificates.value
    var certReq = {
      keyStoreDisplayName : JSON.parse(this.tokenDetails).keyStoreDisplayName,
      keyStorePassphrase: this.keyStorePassphrase,
      keyId : this.certificateTokenId,
      TempFolder : "",
      bulkInput : []
    };
    this.emBridgeSignerInput = [];
    this.emBridgeSignerInput=
    {
      Location : "Vijayawada", 
      Reason : "Testing",
      SignedBy : "eMudhra Limited", 
      PageNo : "1",
      Coordinates : "30,170,140,205",
      AppearanceText :"" ,
       IsAcro6Layer : true  ,
       DigitalTokenId : null,   //JSON.parse(this.tokenDetails).keyStoreDisplayName,
       DigitalCertificateId :  null, //this.certificateTokenId,
       TokenIdFromDevice :   null, //JSON.parse(this.tokenDetails).keyStoreID
              
       
    };
    console.log('certificate and password',certReq)
    console.log('tokenIdFromDevice',JSON.parse(this.tokenDetails).keyStoreID)
    //certReq.file.tokenIdFromDevice = JSON.parse(this.tokenDetails).keyStoreID;
    this.requsitionID = "RV-IntegratedCertificate-154"
    this.digiSign
      .signDoc(certReq,this.emBridgeSignerInput,this.requsitionID,"RV")
      .then((resp : any) => {  
        console.log('sign resp',resp)          
        if(resp.success){
          if(resp.result[0].status){
            const res = {
              certificateId : resp.result[0].id,
              baseFile : resp.result[0].signedData
            }
            console.log(res,'final')
            this.commonService.downloadPdfFile(res.baseFile, res.certificateId)
            this.docSubChange.emit(res);
          }else{
            alert('Failed to Sign Document, Please try again !!!')
          }
        }  
      })
      .catch((error: any) => {
        this.utils.catchResponse(error);
      });
  }


  
}
