import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NonavailabilityBirthViewComponent } from './nonavailability-birth-view/nonavailability-birth-view.component';
import { NonavailabilityDeathViewComponent } from './nonavailability-death-view/nonavailability-death-view.component';
import { ChildnameInclusionViewComponent } from './childname-inclusion-view/childname-inclusion-view.component';
import { CorrectionsInBirthViewComponent } from './corrections-in-birth-view/corrections-in-birth-view.component';
import { CorrectionsInDeathViewComponent } from './corrections-in-death-view/corrections-in-death-view.component';
import { BirthDeathCertificateViewComponent } from './birth-death-certificate-view/birth-death-certificate-view.component';



@NgModule({
  declarations: [
    NonavailabilityBirthViewComponent,
    NonavailabilityDeathViewComponent,
    CorrectionsInBirthViewComponent,
    CorrectionsInDeathViewComponent,
    ChildnameInclusionViewComponent,
    ChildnameInclusionViewComponent,
    CorrectionsInBirthViewComponent,
    BirthDeathCertificateViewComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule

  ],
  exports:[
    CommonModule,
    NonavailabilityBirthViewComponent,
    NonavailabilityDeathViewComponent,
    ChildnameInclusionViewComponent,
    CorrectionsInBirthViewComponent,
    CorrectionsInDeathViewComponent,
    BirthDeathCertificateViewComponent
  ]
})
export class MaudViewModule { }
