import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewricecardviewComponent } from './newricecard/newricecard.component';
import { ViewMemberaddtionComponent } from './view-memberaddtion/view-memberaddtion.component';
import { ViewMemberdeletionComponent } from './view-memberdeletion/view-memberdeletion.component';
import { ViewSpillitingricecardComponent } from './view-spillitingricecard/view-spillitingricecard.component';
import { ViewSurrenderricecardComponent } from './view-surrenderricecard/view-surrenderricecard.component';
import { ViewCorrectionOfWrongAadharSeedingComponent } from './view-correction-of-wrong-aadhar-seeding/view-correction-of-wrong-aadhar-seeding.component';
import { ViewChangeOfRiceCardComponent } from './view-change-of-rice-card/view-change-of-rice-card.component';
import { FpShopViewComponent } from './fp-shop-view/fp-shop-view.component';


const routes: Routes = [
  { path: '', redirectTo: 'newricecard', pathMatch: 'full' },
  { path: 'view-newricecard', component : NewricecardviewComponent },
  { path: 'view-memberaddition', component : ViewMemberaddtionComponent },
  { path: 'view-memberdeletion', component : ViewMemberdeletionComponent },
  { path: 'view-spilttingricecard', component : ViewSpillitingricecardComponent },
  { path: 'view-surrenderricecard', component : ViewSurrenderricecardComponent },
  { path: 'view-correctionofwrongaadhar', component : ViewCorrectionOfWrongAadharSeedingComponent },
  { path: 'view-changeofaddressinricecard', component : ViewChangeOfRiceCardComponent },
  { path: 'fp-Shop-view', component :FpShopViewComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CivilsuppliesViewRoutingModule { }
