import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-household-split-view',
  templateUrl: './household-split-view.component.html',
  styleUrls: ['./household-split-view.component.css']
})
export class HouseholdSplitViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  viewFile(file: any) {
    this.view.emit(file);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
}
