import { Component, OnInit, Output , EventEmitter, Input} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonConstants } from '../../constants/common-constants.component';
import { AadharValidator, CommonService, WhitespaceValidator } from 'src/shared/common.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EnvironmentService } from 'src/shared/environment.service';

@Component({
  selector: 'app-patient-bank-details-popup',
  templateUrl: './patient-bank-details-popup.component.html',
  styleUrls: ['./patient-bank-details-popup.component.css']
})
export class PatientBankDetailsPopupComponent implements OnInit {
  @Output() isAuthenticated = new EventEmitter();
  @Input() aadharNumber: string = '';
  submits: boolean = false;
  authenticationForm: FormGroup;
  submitted: Boolean = false;
  biometricFlag: Boolean = false;
  isPattadarOtpValidated: Boolean = false;
  isDisable: Boolean = true;
  commonConstants: any = CommonConstants;
  aadharTransactionID: any;
  loggedInUserId: any;
  showBiometricPopup: Boolean = false;
  autoBiometricId: any;
  encrtyptedAadhar: any;
  windowObjectReference: any;
  isAadharFieldDisable: Boolean = false;
  aadharData: any;  

  constructor(private formBuilder: FormBuilder, private commonService: CommonService,private modalService: NgbModal, public activeModal: NgbActiveModal, private environmentService: EnvironmentService) {
    
    this.authenticationForm = this.formBuilder.group({
      authenticationType: ['OTP',[Validators.required, WhitespaceValidator.whiteSpaceValidate]],
      aadhaarOtp: [null, [Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
      otp: ['', Validators.required],
      aadhaarNumber: ['',[Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
      adhaarNumberBio: ['', [AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]]
    });
    let userInfo = localStorage.getItem('userInfo');
    if (userInfo && userInfo !== null) {
      let userData = JSON.parse(userInfo);
      this.loggedInUserId = userData.userPrincipalName; 
    }  
  
  }

  ngOnInit(): void {
  }

  otpChange(event: any) {
    if (event.target.value === 'OTP') {
      this.f.aadhaarOtp.setValidators([Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]);
      this.f.otp.setValidators([Validators.required]);
      this.f.aadhaarOtp.updateValueAndValidity();
      this.f.otp.updateValueAndValidity();
      this.f.adhaarNumberBio.clearValidators();
      this.f.adhaarNumberBio.updateValueAndValidity();
      this.f.adhaarNumberBio.reset();
    } else {
      this.f.adhaarNumberBio.setValidators([Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]);
      this.f.adhaarNumberBio.updateValueAndValidity();
      this.f.aadhaarOtp.clearValidators();
      this.f.otp.clearValidators();
      this.f.aadhaarOtp.updateValueAndValidity();
      this.f.otp.updateValueAndValidity();
      this.f.aadhaarOtp.reset();
    }
  }

  get f() {
    return this.authenticationForm.controls;
  }

  aadharChange(){
    if(this.f.aadhaarOtp.valid){
      this.isDisable = false;
    }else{
      this.isDisable = true;
    }
  }
  sendOTP():any{
    this.submitted = true;
    if(!this.f.aadhaarOtp.valid)
        return false;
    var sendPostData={
      uid: this.f.aadhaarOtp.value,
      authentication_type: "OTPGENERATE"
    }
    this.commonService.postRequestForAadhar(this.commonConstants.generateAadharOTP, sendPostData).subscribe({
      next: (responseData:any) => { 
        if(responseData?.reason == 'OTP Sent Successfully'){
          this.aadharTransactionID = responseData?.result
        } else {
          this.commonService.commonErrorPopup('Alert', responseData?.result, 'md');
        }
      },
      error: (error: any) => {
        console.log('client side', error);
      }
    });
  }
  validateOTP():any {
    this.submitted = true;
    if(!this.f.aadhaarOtp.valid ||  !this.f.otp.valid)
        return false;
        this.aadharData = [];
    var sendPostData={
      PIDXML: this.aadharTransactionID,
      otp : this.f.otp.value,
      uid: this.f.aadhaarOtp.value, //'514492421388',//
      authentication_type: "OTPVALIDATE"
    }
    this.commonService.postRequestForAadhar(this.commonConstants.generateAadharOTP, sendPostData).subscribe({
      next: (responseData:any) => { 
        if(responseData?.reason == 'OTP Verified Successfully'){
          this.commonService.commonErrorPopup('Alert', 'Authentication Success.', 'md');
          this.aadharData = responseData?.result;
              let data: any;
              data = {}
            this.isPattadarOtpValidated = true;
            this.activeModal.close(data);
        } else if(responseData?.reason == 'OTP validation failed'){
         this.commonService.commonErrorPopup('Alert', 'Invalid OTP Entered. Please Enter Valid OTP.', 'md')
        } else{
          this.commonService.commonErrorPopup('Alert', 'Invalid OTP Entered. Please Enter Valid OTP.', 'md')
        }
      },
      error: (error: any) => {
        console.log('client side', error);
      }
    });
  }

  biometricBtn(from?:any): any {
    this.biometricFlag=true; 
    if(!this.f['adhaarNumberBio'].valid) {
      return false;
    } else {
      let postData: any = {};
          this.encrtyptedAadhar =  this.commonService.RSAEncrypt(this.f['adhaarNumberBio'].value);
          postData.autoBiometricId = 0;
          postData.aadharNumber = this.encrtyptedAadhar;
          postData.responseStatus = '';
          postData.userId = this.loggedInUserId;
          this.commonService.postRequestForWebLand(this.commonConstants.getSNoForBiometric, postData).subscribe({
            next: (responseData:any) => { 
            this.autoBiometricId = responseData?.result?.autoBiometricId;
            if (this.autoBiometricId !== undefined && this.autoBiometricId !== null) {
              this.showBiometricPopup = true;
              let url: any = this.environmentService.externalUrls.biometricRedirectionPage + encodeURIComponent(this.encrtyptedAadhar) +'&SNo='+ this.autoBiometricId +'&DoEkyc=true&ReqPhoto=false';
              this.windowObjectReference = window.open(url, "BiometricAuthentication", 'popup=1;left=500,top=100,width=780,height=700');
            } else {
              this.showBiometricPopup = false;
            }
          },
            error: (error) => {
              console.log('client side', error);
            }
          });
    }
    }


    checkBiometricStatus(from?: any) {
      this.submits = true;
      this.windowObjectReference.close();
      let postData: any = {};
      postData.autoBiometricId = this.autoBiometricId;
      postData.aadharNumber = this.encrtyptedAadhar;
      postData.responseStatus = '';
      postData.userId = this.loggedInUserId;
      this.commonService.postRequestForWebLand(this.commonConstants.getSNoForBiometric, postData).subscribe({
        next: (responseData:any) => { 
       let status = responseData?.result?.responseStatus;
       if (status === 'Success') {
        this.showBiometricPopup = true;
        this.commonService.commonErrorPopup('Alert', 'Biometric Authentication Success.', 'md');
        this.commonService.getRequest("comms-vm/AadhaarBiometric/Authentication/GetBiometricData?SNo="+ this.autoBiometricId).subscribe({
          next: (responseData:any) => {
            if(responseData?.data?.Reason == 'Success'){
              this.aadharData = responseData?.data;
                let data: any;
                data = {}
                this.submits=false;
                this.activeModal.close(data);
          }
          },
          error: (error) => {
            console.log('GetBiometricData Error client side', error);
          }
        })
       } else {
        this.showBiometricPopup = false;
        this.commonService.commonErrorPopup('Alert', 'Biometric Authentication failed. Please try again!!', 'md');
       }     
      },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }

reset() {
  // this.fbSearchBirthDetailsForm.reset();
  // this.showGrid = false;
}

}
