import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-common-msg-modal',
  templateUrl: './common-msg-modal.component.html',
  styleUrls: ['./common-msg-modal.component.css']
})
export class CommonMsgModalComponent implements OnInit {
  @Input() reqId: string = '';
  @Input() title: string = '';
  @Input() message: string = '';
  @Input() btnText: string = 'Ok'
  constructor(public activeModal: NgbActiveModal, config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
  }
}
