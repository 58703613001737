import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CaptchaComponent } from 'src/shared/captcha-viewer-popup/captcha/captcha.component';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-gsws-feedback',
  templateUrl: './gsws-feedback.component.html',
  styleUrls: ['./gsws-feedback.component.css']
})
export class GswsFeedbackComponent implements OnInit {
  feedbackForm: FormGroup;
  questions: any;
  submitted: boolean = false;
  commonConstants: any = CommonConstants;
  id: any;
  userData: any;
  feedbackImage: any;

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router) {
    this.feedbackForm = this.formBuilder.group({
      experienceRating: ['', [Validators.required]],
      completeInformation: ['', [Validators.required]],
      staffBehaviour: ['', [Validators.required]],
      anyExtraAmountPaid: ['', [Validators.required]],
      anySuggestions: ['', [Validators.pattern(this.commonConstants.validTeluguPattern)]]
    });
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.id = params.Id;
      if (this.id) {
        this.userDetails(this.id);
      }
    });
  }

  ngOnInit(): void {
  }

  get f() {
    return this.feedbackForm.controls;
  }

  userDetails(id: any) {
    let postData = {
      id: id
    }
    this.commonService.postRequest(this.commonConstants.CitizenSurveyData, postData, true).subscribe({
      next: (responseData: any) => {
        if (responseData) {
          if (responseData && responseData.result.constructor === Object) {
            this.userData = responseData.result;
          } else if (responseData && responseData.result == 'Feedback Already Submitted') {
            let params = {
              status: 'feedback-submitted'
            }
            this.router.navigate(['/feedback-status'], { queryParams: params })
          } else if (responseData && responseData.result == 'Invalid Input') {
            let params = {
              status: 'fail'
            }
            this.router.navigate(['/feedback-status'], { queryParams: params })
          }
        }
      },
      error: (error) => {
        if (error) {
          let params = {
            status: 'fail'
          }
          this.router.navigate(['/feedback-status'], { queryParams: params });
        }
        console.log('client side', error);
      }
    })
  }

  ratingChanged() {
    this.setFeedbackImage(this.f.experienceRating?.value);
  }

  hoverOnrating(event: any) {
    this.setFeedbackImage(event);
  }

  leaveHover() {
    this.setFeedbackImage(this.f.experienceRating?.value);
  }

  setFeedbackImage(value: any) {
    if (value == 1) {
      this.feedbackImage = 'fas fa-frown rating-1 ml-4';
    } else if (value == 2) {
      this.feedbackImage = 'fas fa-frown-open rating-2 ml-4';
    } else if (value == 3) {
      this.feedbackImage = 'fas fa-meh rating-3 ml-4';
    } else if (value == 4) {
      this.feedbackImage = 'fas fa-smile rating-4 ml-4';
    } else if (value == 5) {
      this.feedbackImage = 'fas fa-smile-beam rating-5 ml-4';
    } else {
      this.feedbackImage = '';
    }
  }

  submit(): any {
    this.submitted = true;
    if (this.userData === undefined) {
      return false;
    } else if (this.userData?.citizeN_NAME == '' || this.userData?.citizeN_MOBILE == '' || this.userData?.availeD_SERVICES?.join(', ') == '' || !this.feedbackForm.valid) {
      return false;
    } else {
      //this.openCaptchaPopup();
      let payload: any = {
        json: {
          flag: "set",
          uid: this.commonService.RSAEncrypt(this.userData?.citizen_Uid),
          questionsData: [{
            QUESTION_NAME: "పైన తెలిపిన సేవ పొందడంలో మీ అనుభవాన్ని మీరు ఎలా రేటింగ్ చేస్తారు. (1,2,3,4,5) స్టార్ రేటింగ్ 5 అత్యధికంగా ఉంటుంది.",
            QUESTION_CODE: "experienceRating",
            QUESTION_VALUE: this.f.experienceRating.value.toString()
          }, {
            QUESTION_NAME: "సచివాలయం సిబ్బంది మీకు సేవకి సంబందించిన పూర్తి సమాచారాన్ని అందించారా?",
            QUESTION_CODE: "completeInformation",
            QUESTION_VALUE: this.f.completeInformation.value
          }, {
            QUESTION_NAME: "సచివాలయం సిబ్బంది మీతో మర్యాదపూర్వకంగా మెలిగారా?",
            QUESTION_CODE: "staffBehaviour",
            QUESTION_VALUE: this.f.staffBehaviour.value
          }, {
            QUESTION_NAME: "రసీదులో పేర్కొన్న సేవ రుసుముకి మించి అదనంగా డబ్బులు ఏదైనా వసూలు చేయబడిందా?",
            QUESTION_CODE: "anyExtraAmountPaid",
            QUESTION_VALUE: this.f.anyExtraAmountPaid.value
          }, {
            QUESTION_NAME: "సేవలు మరింత మెరుగు పరుచుకోవడానికి ఏదైనా సలహాలు / సూచనలు ఉంటే ఇవ్వగలరు.",
            QUESTION_CODE: "anySuggestions",
            QUESTION_VALUE: this.f.anySuggestions.value
          }]
        }
      }
      this.commonService.postRequest(this.commonConstants.InsertCitizenSurveyData, payload, true).subscribe({
        next: (responseData: any) => {
          if (responseData && responseData?.result[0].remarks == 'Success') {
            let params = {
              status: 'success'
            }
            this.router.navigate(['/feedback-status'], {
              queryParams: params
            });
          } else {
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = responseData?.result[0].remarks;
            modalRef.result.then((result: any) => {
              if (result) {
                this.modalService.dismissAll();
                this.submitted = false;
                this.feedbackForm.reset();
              }
            }).catch((res) => {});
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }
  }

  // openCaptchaPopup() {
  //   const modalRef = this.modalService.open(CaptchaComponent, { size: 'md' });
  //   modalRef.componentInstance.message = 'captcha code';
  //   modalRef.result.then((data: any) => {
  //     if (data) {
  //       let payload: any = {
  //         json: {
  //           flag: "set",
  //           uid: this.commonService.RSAEncrypt(this.userData?.citizen_Uid),
  //           questionsData: [{
  //             QUESTION_NAME: "పైన తెలిపిన సేవ పొందడంలో మీ అనుభవాన్ని మీరు ఎలా రేటింగ్ చేస్తారు. (1,2,3,4,5) స్టార్ రేటింగ్ 5 అత్యధికంగా ఉంటుంది.",
  //             QUESTION_CODE: "experienceRating",
  //             QUESTION_VALUE: this.f.experienceRating.value.toString()
  //           }, {
  //             QUESTION_NAME: "సచివాలయం సిబ్బంది మీకు సేవకి సంబందించిన పూర్తి సమాచారాన్ని అందించారా?",
  //             QUESTION_CODE: "completeInformation",
  //             QUESTION_VALUE: this.f.completeInformation.value
  //           }, {
  //             QUESTION_NAME: "సచివాలయం సిబ్బంది మీతో మర్యాదపూర్వకంగా మెలిగారా?",
  //             QUESTION_CODE: "staffBehaviour",
  //             QUESTION_VALUE: this.f.staffBehaviour.value
  //           }, {
  //             QUESTION_NAME: "రసీదులో పేర్కొన్న సేవ రుసుముకి మించి అదనంగా డబ్బులు ఏదైనా వసూలు చేయబడిందా?",
  //             QUESTION_CODE: "anyExtraAmountPaid",
  //             QUESTION_VALUE: this.f.anyExtraAmountPaid.value
  //           }, {
  //             QUESTION_NAME: "సేవలు మరింత మెరుగు పరుచుకోవడానికి ఏదైనా సలహాలు / సూచనలు ఉంటే ఇవ్వగలరు.",
  //             QUESTION_CODE: "anySuggestions",
  //             QUESTION_VALUE: this.f.anySuggestions.value
  //           }]
  //         }
  //       }
  //       this.commonService.postRequest(this.commonConstants.InsertCitizenSurveyData, payload, true).subscribe({
  //         next: (responseData: any) => {
  //           if (responseData && responseData?.result[0].remarks == 'Success') {
  //             let params = {
  //               status: 'success'
  //             }
  //             this.router.navigate(['/feedback-status'], {
  //               queryParams: params
  //             });
  //           } else {
  //             const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
  //             modalRef.componentInstance.title = 'Alert';
  //             modalRef.componentInstance.message = responseData?.result[0].remarks;
  //             modalRef.result.then((result: any) => {
  //               if (result) {
  //                 this.modalService.dismissAll();
  //                 this.submitted = false;
  //                 this.feedbackForm.reset();
  //               }
  //             }).catch((res) => {});
  //           }
  //         },
  //         error: (error) => {
  //           console.log('client side', error);
  //         }
  //       });
  //     }
  //   }).catch((res) => {});
  // }

}
