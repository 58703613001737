<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">F.M.B Copy Application</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{screenDistrict}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{screenVillage }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Mandal:</label>
            <div class="valueCls">{{screenMandal }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Survey Number:</label>
            <div class="valueCls">{{certificateInfo.surveyNo ? (certificateInfo.surveyNo) : '-' }}</div>
        </div>
        </div>
    </div>
</div>