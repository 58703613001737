<div>
    <div class="certificateInfo">
        <h6 class="mb-3">Proposed Request for Grant of Assignment for the Land Located:</h6>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">District:</label>
                    <div class="valueCls">{{(certificateInfo?.districtName) ? (certificateInfo?.districtName) : "-"}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Mandal:</label>
                    <div class="valueCls">{{(certificateInfo?.mandalName) ? (certificateInfo?.mandalName) : "-"}}</div>
                </div>
            </div>    
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">       
                <div class="fieldHolder">
                    <label class="labelCls">Village:</label>
                    <div class="valueCls">{{ (certificateInfo?.villageName) ? (certificateInfo?.villageName) : "-" }}</div>
                </div>   
            </div>   
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">       
                <div class="fieldHolder">
                    <label class="labelCls"> Secretariat List:</label>
                    <div class="valueCls">{{ (certificateInfo?.secretariatName) ? (certificateInfo?.secretariatName) : "-" }}</div>
                </div>   
            </div>    
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">       
                
                <div class="fieldHolder">
                    <label class="labelCls">Preferred LP Number / Sy Number selected by Applicant:</label>
                    <div class="valueCls">{{(certificateInfo?.lpNoSYNo).length > 0 ? (certificateInfo?.lpNoSYNo) : 'Not Available' }}</div>
                </div> 
              </div>
                <div *ngIf="certificateInfo?.categoryofServicepersonOrSoldie !== 'DeceasedSoldier'" class="col-lg-6 col-md-6 col-sm-12 col-xs-12"> 
                    <div class="fieldHolder">
                        <label class="labelCls">Was the Soldier discharged honourably ? </label>
                        <div class="valueCls">{{ certificateInfo?.isDischargedDishonourably === true ? 'Yes' : 'No'}}</div>
                    </div> 
                </div> 
            </div>                         
         </div>  
        </div>         
    <div class="certificateInfo">
        <div class="row" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Ex-Serviceperson'">
            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
                <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">Documents Upload</div>
                <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                    <div *ngIf="file.documentType === 'dischargeDocument'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Discharge/Service Certificate:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Pension'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Pension Payment Order:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                
                    <div *ngIf="file.documentType === 'idCard'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Ex-Service Men Id Card Issued by Zilla Sainik Welfare Officer (ZSWO):</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    
                    <div *ngIf="file.documentType === 'signature'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Signature of Ex-Serviceperson:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'disability'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Disability Certificate:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'supportingDocuments'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Any Supporting Documents:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'VerificationDocumentsEX'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Verification report by ZSWO:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
                </ng-container>
            </div>
        </div>
        <div class="row" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Serving Soldier'">
            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
                <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">Documents Upload</div>
                <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                    <div *ngIf="file.documentType === 'serviceDocument'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Service Certificate from Commanding Office:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Posting'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Current Regiment/Posting Details:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'signatureSS'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Signature of Serving Soldier:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'supportingDocumentsSS'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Any Supporting Documents:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'VerificationDocumentsSS'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Verification report by ZSWO:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                  </div>
                </ng-container>
            </div>
        </div>
        <div class="row" *ngIf="certificateInfo?.categoryofServicepersonOrSoldie == 'Deceased Soldier' || certificateInfo?.categoryofServicepersonOrSoldie == 'DeceasedSoldier'">
            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
                <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">Documents Upload</div>
                <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                    <div *ngIf="file.documentType === 'deceasedSoldier'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Service Particulars of Deceased Soldier:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'PensionDS'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Pension Payment Order:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
               
                    <div *ngIf="file.documentType === 'Widow'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Widow/Next Kin Id Card Issued by Zilla Sainik Welfare Officer (ZSWO)*:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
               
                    <div *ngIf="file.documentType === 'signatureDS'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Signature of Relation with Deceased Soldier:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
              
                    <div *ngIf="file.documentType === 'deathDS'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Death Certificate:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                
                    <div *ngIf="file.documentType === 'supportingDocumentsDS'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Any Supporting Documents:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'VerificationDocumentsDS'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Verification report by ZSWO:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
                </ng-container>
            </div>
        </div>
    </div> 

 