import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HealthRoutingModule } from './health-routing.module';
import { AarogyaSriComponent } from './aarogya-sri/aarogya-sri.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/shared/shared.module';
import { UpdateAarogyaSriComponent } from './update-aarogya-sri/update-aarogya-sri.component';
import { HealthViewModule } from '../health-view/health-view.module';
import { HealthCardStatusComponent } from './health-card-status/health-card-status.component';
import { HealthCardComponent } from './health-card/health-card.component';
import { PatientBankDetailsComponent } from './patient-bankdetails/patient-bankdetails.component';
import { AarogyaSriCardDispatchStatusComponent } from './aarogya-sri-card-dispatch-status/aarogya-sri-card-dispatch-status.component';

@NgModule({
  declarations: [
    AarogyaSriComponent,
    UpdateAarogyaSriComponent,
    HealthCardStatusComponent,
    HealthCardComponent,
    PatientBankDetailsComponent,
    AarogyaSriCardDispatchStatusComponent
  ],
  imports: [
    CommonModule,
    HealthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule,
    HealthViewModule,
  ]
})
export class HealthModule { }
