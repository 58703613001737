<div class="mainPageContainer mr-3 mt-5"> 
    <div class="btnHolder mb-3">
        <button  class="greenBtnCls float-right mr-3 my-3"  (click)="goToReport()">Aarogya Suraksha 2.0 Report</button>
        <button  class="greenBtnCls float-right mr-3 my-3"  (click)="goToReportDashboard()">Aarogya Suraksha 2.0 Camp Status Report</button>

    </div>
    <ng-container *ngIf ="!isScheduleConfirmed">
    <h6 class="mb-3">Schedule for the Doctors</h6>
    <form [formGroup]="teamForm">
       <div class="row mb-3">
            <!-- <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Schedule Date: <span class="mandatoryCls">*</span></label>
                <div class="input-group">
                    <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                        name="d"  ngbDatepicker #d="ngbDatepicker" [minDate]="minDate" (dateSelect)="onScheduleDateSelect($event)"
                        [maxDate]="maxDate" required formControlName="sheduleDate" [markDisabled]="isDisabled"
                        [ngClass]="{ 'is-invalid':submitted && f.sheduleDate.errors }">
                    <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                    </div>
                </div>
                <div *ngIf="submitted && f.sheduleDate.errors" class="selectError">
                    <div *ngIf="f.sheduleDate.errors?.required || f.sheduleDate.invalid">Please enter valid Schedule Date</div>
                </div>
            </div> -->
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Mandals: <span class="mandatoryCls">*</span></label>
                <select class="selectCls" required formControlName = "scheduleMandal" (change) ="onScheduleMandalChange()" 
                    [ngClass]="{ 'is-invalid':submitted && (f.scheduleMandal.errors || f.scheduleMandal.value === 'Select')}">
                    <option  [ngValue]= "null" disabled>Select</option>
                    <option *ngFor="let scheduleMandal of scheduleMandalList" [ngValue]="scheduleMandal">{{scheduleMandal.mandaL_NAME}}</option>
                </select>
            
                <div *ngIf="submitted && (f.scheduleMandal.errors || f.scheduleMandal.value === 'Select')" class="selectError">
                    <div *ngIf="f.scheduleMandal.errors?.required || f.scheduleMandal.value === 'Select'">Please select Mandal</div>
                </div>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">VHC: <span class="mandatoryCls">*</span></label>
                <select class="selectCls" required formControlName = "scheduleVHC" (change) ="onScheduleVHCChange()" 
                    [ngClass]="{ 'is-invalid':submitted && (f.scheduleVHC.errors || f.scheduleVHC.value === 'Select')}">
                    <option  [ngValue]= "null" disabled>Select</option>
                    <option *ngFor="let scheduleVHC of scheduleVHCList" [ngValue]="scheduleVHC">{{scheduleVHC.vhC_NAME}}</option>
                </select>
            
                <div *ngIf="submitted && (f.scheduleVHC.errors || f.scheduleVHC.value === 'Select')" class="selectError">
                    <div *ngIf="f.scheduleVHC.errors?.required || f.scheduleVHC.value === 'Select'">Please select VHC</div>
                </div>
            </div>
            <!-- <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">VHC: <span class="mandatoryCls">*</span></label>
                <select class="selectCls" required formControlName = "VHC" (change) ="onVHCChange()" 
                    [ngClass]="{ 'is-invalid':submitted && (f.VHC.errors || f.VHC.value === 'Select')}">
                    <option  [ngValue]= "null" disabled>Select</option>
                    <option *ngFor="let VHC of VHCList" [ngValue]="VHC">{{VHC.vhC_NAME}}</option>
                </select>
            
                <div *ngIf="submitted && (f.VHC.errors || f.VHC.value === 'Select')" class="selectError">
                    <div *ngIf="f.VHC.errors?.required || f.VHC.value === 'Select'">Please select VHC</div>
                </div>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">PHC : <span class="mandatoryCls">*</span></label>
                <input type="text" name="PHC" formControlName="PHC" class="inputCls" appBlockCopyPaste required  
                [ngClass]="{ 'is-invalid':submitted && f.PHC.errors }" readonly>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Doctor 1 Name : <span class="mandatoryCls">*</span></label>
                <input type="text" name="doc1Name" formControlName="doc1Name" class="inputCls" appBlockCopyPaste required  
                [ngClass]="{ 'is-invalid':submitted && f.doc1Name.errors }" readonly>
            </div> -->
        </div>
        <ng-container *ngIf="this.f.scheduleMandal.value !== null && this.f.scheduleVHC.value !== null">
            <h6 class="mb-3">Please click on the Secretariat to schedule the doctor details.</h6>
            <div class="row mb-3" *ngIf="VHCList && VHCList?.length > 0">
                <div *ngFor="let VHC of VHCList" class="col col-lg-2 col-md-2 col-sm-12 col-xs-12 mb-3">
                    <a (click)="showVHCDetails(VHC)" class="VHCBox" [ngClass]="{'active': VHC.isSelected}">{{VHC.secretariaT_NAME}}</a>
                </div>
            </div>
            <div class="row mb-3" *ngIf="VHCList && VHCList?.length === 0">
                <div  class="mx-3 text-center">All Secretariats scheduled in the selected mandal and VHC.</div>
            </div>
        </ng-container>

        <ng-container *ngIf="showVHCInfo">
            <h6 class="mb-3">Schedule Date: <span class="spanCls">{{ scheduleDate }}</span></h6>           
            <!-- <div class="row mb-3"> -->
                <!-- <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                 <span><label class="labelCls">{{ scheduleDate }}</label></span>                   
                </div> -->
            <!-- </div> -->
            <div class="subHeadingCls mb-3"><b>Note: It is advisable to include one female doctor in each schedule.</b></div>
            <h6 class="mb-3">Doctor 1 Details</h6>
            <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Doctor Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="doc1NameText" formControlName="doc1NameText" class="inputCls" appBlockCopyPaste maxlength="200" 
                    [ngClass]="{ 'is-invalid':submitted && f.doc1NameText.errors }">
                    <div *ngIf="submitted && f.doc1NameText.errors" class="invalid-feedback">
                    <div *ngIf="f.doc1NameText.errors?.required || f.doc1NameText.errors?.whiteSpaceValidate">Doctor Name is required</div>
                    <div *ngIf="f.doc1NameText.errors?.pattern">Please enter valid Doctor Name</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Aadhaar Number: </label>
                    <input type="text" name="doc1AadharNo" formControlName="doc1AadharNo" class="inputCls" appBlockCopyPaste numbersOnly
                    [ngClass]="{ 'is-invalid':submitted && f.doc1AadharNo.errors }" maxlength="12" minlength="12">
                    <div *ngIf="submitted && f.doc1AadharNo.errors" class="invalid-feedback">
                    <div *ngIf="f.doc1AadharNo.errors?.required || f.doc1AadharNo.errors?.whiteSpaceValidate">Aadhaar Number is required</div>
                    <div *ngIf="f.doc1AadharNo.errors?.aadharValidate || f.doc1AadharNo.errors?.minlength || f.doc1AadharNo.errors?.maxlength">Please enter valid Aadhaar Number</div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Mobile No: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="doc1MobileNo" formControlName="doc1MobileNo" class="inputCls" appBlockCopyPaste 
                    [ngClass]="{ 'is-invalid':submitted && f.doc1MobileNo.errors }" minlength="10" maxlength="10" numbersOnly>
                    <div *ngIf="submitted && f.doc1MobileNo.errors" class="invalid-feedback">
                    <div *ngIf="f.doc1MobileNo.errors?.required">Please enter Mobile No</div>
                    <div *ngIf="f.doc1MobileNo.errors?.minlength || f.doc1MobileNo.errors?.pattern">Please enter valid Mobile No</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Gender: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName = "doc1Gender"
                        [ngClass]="{ 'is-invalid':submitted && (f.doc1Gender.errors || f.doc1Gender.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="Transgender">Transgender</option>
                    </select>
                    <div *ngIf="submitted && (f.doc1Gender.errors || f.doc1Gender.value === 'Select')" class="selectError">
                        <div *ngIf="f.doc1Gender.errors?.required || f.doc1Gender.value === 'Select'">Please select Doctor 1 Gender</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">HPR Id:</label>
                    <input type="text" name="doc1HprId" formControlName="doc1HprId" class="inputCls" appBlockCopyPaste 
                    [ngClass]="{ 'is-invalid':submitted && f.doc1HprId.errors }" minlength="14" maxlength="14" numbersOnly>
                    <div *ngIf="submitted && f.doc1HprId.errors" class="invalid-feedback">
                    <div *ngIf="f.doc1HprId.errors?.required">Please enter HPR Id</div>
                    <div *ngIf="f.doc1HprId.errors?.minlength">Please enter valid HPR Id</div>
                    </div>
                </div>
            </div>
            <h6 class="mb-3">Doctor 2 Details</h6>
            <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Doctor Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="doc2NameText" formControlName="doc2NameText" class="inputCls" appBlockCopyPaste maxlength="200" 
                    [ngClass]="{ 'is-invalid':submitted && f.doc2NameText.errors }">
                    <div *ngIf="submitted && f.doc2NameText.errors" class="invalid-feedback">
                    <div *ngIf="f.doc2NameText.errors?.required || f.doc2NameText.errors?.whiteSpaceValidate">Doctor Name is required</div>
                    <div *ngIf="f.doc2NameText.errors?.pattern">Please enter valid Doctor Name</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Aadhaar Number: </label>
                    <input type="text" name="doc2AadharNo" formControlName="doc2AadharNo" class="inputCls" appBlockCopyPaste numbersOnly
                    [ngClass]="{ 'is-invalid':submitted && f.doc2AadharNo.errors }" maxlength="12" minlength="12">
                    <div *ngIf="submitted && f.doc2AadharNo.errors" class="invalid-feedback">
                    <div *ngIf="f.doc2AadharNo.errors?.required || f.doc2AadharNo.errors?.whiteSpaceValidate">Aadhaar Number is required</div>
                    <div *ngIf="f.doc2AadharNo.errors?.aadharValidate || f.doc2AadharNo.errors?.minlength || f.doc2AadharNo.errors?.maxlength">Please enter valid Aadhaar Number</div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Mobile No: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="doc2MobileNo" formControlName="doc2MobileNo" class="inputCls" appBlockCopyPaste 
                    [ngClass]="{ 'is-invalid':submitted && f.doc2MobileNo.errors }" minlength="10" maxlength="10" numbersOnly>
                    <div *ngIf="submitted && f.doc2MobileNo.errors" class="invalid-feedback">
                    <div *ngIf="f.doc2MobileNo.errors?.required">Please enter Mobile No</div>
                    <div *ngIf="f.doc2MobileNo.errors?.minlength || f.doc2MobileNo.errors?.pattern">Please enter valid Mobile No</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Gender: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName = "doc2Gender"
                        [ngClass]="{ 'is-invalid':submitted && (f.doc2Gender.errors || f.doc2Gender.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="Transgender">Transgender</option>
                    </select>
                    <div *ngIf="submitted && (f.doc2Gender.errors || f.doc2Gender.value === 'Select')" class="selectError">
                        <div *ngIf="f.doc2Gender.errors?.required || f.doc2Gender.value === 'Select'">Please select Doctor 2 Gender</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Type of Doctor: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName = "doc2Type"
                        [ngClass]="{ 'is-invalid':submitted && (f.doc2Type.errors || f.doc2Type.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option value="Private">Private</option>
                        <option value="Government">Government</option>        
                    </select>
                    <div *ngIf="submitted && (f.doc2Type.errors || f.doc2Type.value === 'Select')" class="selectError">
                        <div *ngIf="f.doc2Type.errors?.required || f.doc2Type.value === 'Select'">Please select Type of Doctor 2</div>
                    </div>
                </div>

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Specialization: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName = "doc2Specialist"
                        [ngClass]="{ 'is-invalid':submitted && (f.doc2Specialist.errors || f.doc2Specialist.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option value="Gynaecology">Gynaecology</option>
                        <option value="Paediatrics">Paediatrics</option> 
                        <option value="GeneralMedicine">General Medicine</option> 
                        <option value="Ophthalmology">Ophthalmology</option> 
                        <option value="Orthopaedics">Orthopaedics</option> 
                        <option value="Dermatology">Dermatology</option> 
                        <option value="ENT">ENT </option> 
                        <option value="GeneralSurgery">General Surgery</option> 
                        <option value="Dental">Dental</option> 
                        <option value="Pathology">Pathology</option> 
                        <option value="Pulmonology">Pulmonology</option>        
                        <option value="Radiology">Radiology</option> 
                        <option value="SPM">SPM</option> 
                        <option value="Others">Others</option> 
                    </select>
                    <div *ngIf="submitted && (f.doc2Specialist.errors || f.doc2Specialist.value === 'Select')" class="selectError">
                        <div *ngIf="f.doc2Specialist.errors?.required || f.doc2Specialist.value === 'Select'">Please select Doctor 2 Specialization</div>
                    </div>
                </div>
            </div>
            <!-- <h6 class="mb-3">Doctor 1 Details</h6>
            <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">VHC: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="VHC" formControlName="VHC" class="inputCls" appBlockCopyPaste required  
                    [ngClass]="{ 'is-invalid':submitted && f.VHC.errors }" readonly>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">PHC : <span class="mandatoryCls">*</span></label>
                    <input type="text" name="PHC" formControlName="PHC" class="inputCls" appBlockCopyPaste required  
                    [ngClass]="{ 'is-invalid':submitted && f.PHC.errors }" readonly>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Doctor 1 Name : <span class="mandatoryCls">*</span></label>
                    <input type="text" name="doc1Name" formControlName="doc1Name" class="inputCls" appBlockCopyPaste required  
                    [ngClass]="{ 'is-invalid':submitted && f.doc1Name.errors }" readonly>
                </div>
            </div>
            <div class="row mb-3"> 
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <label class="labelCls">Do you want to edit Doctor 1 details ?</label>
                    <div class="col-sm-12">
                        <input type="radio" value="Yes" id="yes" formControlName="editDoctor" (change)="onChangeDoctorEdit()" />
                        <label class="mx-2" for="yes">Yes</label>
                        <input type="radio" value="No" id="no" [checked]="true" formControlName="editDoctor" (change)="onChangeDoctorEdit()" />
                        <label class="mx-2" for="no" >No</label>
                    </div>
                </div>
            </div>
            <div class="row mb-3" *ngIf="f.editDoctor.value === 'Yes'">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Doctor Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="doc1NameText" formControlName="doc1NameText" class="inputCls" appBlockCopyPaste maxlength="200" 
                    [ngClass]="{ 'is-invalid':submitted && f.doc1NameText.errors }">
                    <div *ngIf="submitted && f.doc1NameText.errors" class="invalid-feedback">
                    <div *ngIf="f.doc1NameText.errors?.required || f.doc1NameText.errors?.whiteSpaceValidate">Doctor Name is required</div>
                    <div *ngIf="f.doc1NameText.errors?.pattern">Please enter valid Doctor Name</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Aadhaar Number: </label>
                    <input type="text" name="doc1AadharNo" formControlName="doc1AadharNo" class="inputCls" appBlockCopyPaste numbersOnly
                    [ngClass]="{ 'is-invalid':submitted && f.doc1AadharNo.errors }" maxlength="12" minlength="12">
                    <div *ngIf="submitted && f.doc1AadharNo.errors" class="invalid-feedback">
                    <div *ngIf="f.doc1AadharNo.errors?.required || f.doc1AadharNo.errors?.whiteSpaceValidate">Aadhaar Number is required</div>
                    <div *ngIf="f.doc1AadharNo.errors?.aadharValidate || f.doc1AadharNo.errors?.minlength || f.doc1AadharNo.errors?.maxlength">Please enter valid Aadhaar Number</div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Mobile No: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="doc1MobileNo" formControlName="doc1MobileNo" class="inputCls" appBlockCopyPaste 
                    [ngClass]="{ 'is-invalid':submitted && f.doc1MobileNo.errors }" minlength="10" maxlength="10" numbersOnly>
                    <div *ngIf="submitted && f.doc1MobileNo.errors" class="invalid-feedback">
                    <div *ngIf="f.doc1MobileNo.errors?.required">Please enter Mobile No</div>
                    <div *ngIf="f.doc1MobileNo.errors?.minlength || f.doc1MobileNo.errors?.pattern">Please enter valid Mobile No</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Gender: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName = "doc1Gender"
                        [ngClass]="{ 'is-invalid':submitted && (f.doc1Gender.errors || f.doc1Gender.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="Transgender">Transgender</option>
                    </select>
                    <div *ngIf="submitted && (f.doc1Gender.errors || f.doc1Gender.value === 'Select')" class="selectError">
                        <div *ngIf="f.doc1Gender.errors?.required || f.doc1Gender.value === 'Select'">Please select Doctor Gender</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">HPR Id: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="doc1HprId" formControlName="doc1HprId" class="inputCls" appBlockCopyPaste 
                    [ngClass]="{ 'is-invalid':submitted && f.doc1HprId.errors }" minlength="14" maxlength="14" numbersOnly>
                    <div *ngIf="submitted && f.doc1HprId.errors" class="invalid-feedback">
                    <div *ngIf="f.doc1HprId.errors?.required">Please enter HPR Id</div>
                    <div *ngIf="f.doc1HprId.errors?.minlength">Please enter valid HPR Id</div>
                    </div>
                </div>
            </div>
            <h6 class="mb-3">Doctor 2 Details</h6>
            <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Doctor 2 Type: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName = "doctor2Type" (change) ="onDoc2TypeChange()"
                        [ngClass]="{ 'is-invalid':submitted && (f.doctor2Type.errors || f.doctor2Type.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option value="PHC Doctor">PHC Doctor</option>
                        <option value="Others">Others</option>
                    </select>
                    <div *ngIf="submitted && (f.doctor2Type.errors || f.doctor2Type.value === 'Select')" class="selectError">
                        <div *ngIf="f.doctor2Type.errors?.required || f.doctor2Type.value === 'Select'">Please select Doctor 2 Type</div>
                    </div>
                </div>
                <ng-container *ngIf="f.doctor2Type.value === 'PHC Doctor'">
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Mandal: <span class="mandatoryCls">*</span></label>
                        <select class="selectCls" required formControlName = "mandal" (change) ="onMandalChange()"
                            [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select')}">
                            <option  [ngValue]= "null" disabled>Select</option>
                            <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandaL_NAME}}</option>
                        </select>
                        <div *ngIf="submitted && (f.mandal.errors || f.mandal.value === 'Select')" class="selectError">
                            <div *ngIf="f.mandal.errors?.required || f.mandal.value === 'Select'">Please select mandal</div>
                        </div>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">PHC: <span class="mandatoryCls">*</span></label>
                        <select class="selectCls" required formControlName = "PHCDoc2" (change)="onPHCChange()" [ngClass]="{ 'is-invalid':submitted && (f.PHCDoc2.errors || f.PHCDoc2.value === 'Select')}">
                            <option  [ngValue]= "null" disabled>Select</option>
                            <option *ngFor="let phc of PHCDoc2List" [ngValue]="phc">{{phc.phC_NAME}}</option>
                        </select>
                        <div *ngIf="submitted && (f.PHCDoc2.errors || f.PHCDoc2.value === 'Select')" class="selectError">
                            <div *ngIf="f.PHCDoc2.errors?.required || f.PHCDoc2.value === 'Select'">Please select PHC</div>
                        </div>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Doctor Name: <span class="mandatoryCls">*</span></label>
                        <select class="selectCls" required formControlName = "doc2Name" [ngClass]="{ 'is-invalid':submitted && (f.doc2Name.errors || f.doc2Name.value === 'Select')}">
                            <option  [ngValue]= "null" disabled>Select</option>
                            <option *ngFor="let doc of doc2NameList" [ngValue]="doc">{{doc.mO_NAME}}</option>
                        </select>
                    
                        <div *ngIf="submitted && (f.doc2Name.errors || f.doc2Name.value === 'Select')" class="selectError">
                            <div *ngIf="f.doc2Name.errors?.required || f.doc2Name.value === 'Select'">Please select Doctor Name</div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="f.doctor2Type.value === 'Others'">
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Doctor Name: <span class="mandatoryCls">*</span></label>
                        <input type="text" name="doc2NameText" formControlName="doc2NameText" class="inputCls" appBlockCopyPaste maxlength="200" 
                        [ngClass]="{ 'is-invalid':submitted && f.doc2NameText.errors }">
                        <div *ngIf="submitted && f.doc2NameText.errors" class="invalid-feedback">
                        <div *ngIf="f.doc2NameText.errors?.required || f.doc2NameText.errors?.whiteSpaceValidate">Doctor Name is required</div>
                        <div *ngIf="f.doc2NameText.errors?.pattern">Please enter valid Doctor Name</div>
                        </div>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Aadhaar Number: </label>
                        <input type="text" name="doc2AadharNo" formControlName="doc2AadharNo" class="inputCls" appBlockCopyPaste numbersOnly
                        [ngClass]="{ 'is-invalid':submitted && f.doc2AadharNo.errors }" maxlength="12" minlength="12">
                        <div *ngIf="submitted && f.doc2AadharNo.errors" class="invalid-feedback">
                        <div *ngIf="f.doc2AadharNo.errors?.required || f.doc2AadharNo.errors?.whiteSpaceValidate">Aadhaar Number is required</div>
                        <div *ngIf="f.doc2AadharNo.errors?.aadharValidate || f.doc2AadharNo.errors?.minlength || f.doc2AadharNo.errors?.maxlength">Please enter valid Aadhaar Number</div>
                    </div>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Mobile No: <span class="mandatoryCls">*</span></label>
                        <input type="text" name="doc2MobileNo" formControlName="doc2MobileNo" class="inputCls" appBlockCopyPaste 
                        [ngClass]="{ 'is-invalid':submitted && f.doc2MobileNo.errors }" minlength="10" maxlength="10" numbersOnly>
                        <div *ngIf="submitted && f.doc2MobileNo.errors" class="invalid-feedback">
                        <div *ngIf="f.doc2MobileNo.errors?.required">Please enter Mobile No</div>
                        <div *ngIf="f.doc2MobileNo.errors?.minlength || f.doc2MobileNo.errors?.pattern">Please enter valid Mobile No</div>
                        </div>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Gender: <span class="mandatoryCls">*</span></label>
                        <select class="selectCls" required formControlName = "doc2Gender"
                            [ngClass]="{ 'is-invalid':submitted && (f.doc2Gender.errors || f.doc2Gender.value === 'Select')}">
                            <option  [ngValue]= "null" disabled>Select</option>
                            <option value="Female">Female</option>
                            <option value="Male">Male</option>
                            <option value="Transgender">Transgender</option>
                        </select>
                        <div *ngIf="submitted && (f.doc2Gender.errors || f.doc2Gender.value === 'Select')" class="selectError">
                            <div *ngIf="f.doc2Gender.errors?.required || f.doc2Gender.value === 'Select'">Please select Doctor 2 Gender</div>
                        </div>
                    </div>
                </ng-container>
            </div> -->
            <h6 class="mb-3">Doctor 3 Details</h6>
            <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                    <label class="labelCls">Doctor Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="doc3Name" formControlName="doc3Name" class="inputCls" appBlockCopyPaste required maxlength="200" 
                    [ngClass]="{ 'is-invalid':submitted && f.doc3Name.errors }">
                    <div *ngIf="submitted && f.doc3Name.errors" class="invalid-feedback">
                    <div *ngIf="f.doc3Name.errors?.required || f.doc3Name.errors?.whiteSpaceValidate">Doctor Name is required</div>
                    <div *ngIf="f.doc3Name.errors?.pattern">Please enter valid Doctor Name</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Aadhaar Number: </label>
                    <input type="text" name="doc3AadharNo" formControlName="doc3AadharNo" class="inputCls" appBlockCopyPaste  numbersOnly
                    [ngClass]="{ 'is-invalid':submitted && f.doc3AadharNo.errors }" maxlength="12" minlength="12">
                    <div *ngIf="submitted && f.doc3AadharNo.errors" class="invalid-feedback">
                    <div *ngIf="f.doc3AadharNo.errors?.required || f.doc3AadharNo.errors?.whiteSpaceValidate">Aadhaar Number is required</div>
                    <div *ngIf="f.doc3AadharNo.errors?.aadharValidate || f.doc3AadharNo.errors?.minlength || f.doc3AadharNo.errors?.maxlength">Please enter valid Aadhaar Number</div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Mobile No: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="doc3MobileNo" formControlName="doc3MobileNo" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':submitted && f.doc3MobileNo.errors }" minlength="10" maxlength="10" numbersOnly>
                    <div *ngIf="submitted && f.doc3MobileNo.errors" class="invalid-feedback">
                    <div *ngIf="f.doc3MobileNo.errors?.required">Please enter Mobile No</div>
                    <div *ngIf="f.doc3MobileNo.errors?.minlength || f.doc3MobileNo.errors?.pattern">Please enter valid Mobile No</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Gender: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName = "doc3Gender"
                        [ngClass]="{ 'is-invalid':submitted && (f.doc3Gender.errors || f.doc3Gender.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="Transgender">Transgender</option>
                    </select>
                    <div *ngIf="submitted && (f.doc3Gender.errors || f.doc3Gender.value === 'Select')" class="selectError">
                        <div *ngIf="f.doc3Gender.errors?.required || f.doc3Gender.value === 'Select'">Please select Doctor 3 Gender</div>
                    </div>
                </div>

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Type of Doctor: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName = "doc3Type"
                        [ngClass]="{ 'is-invalid':submitted && (f.doc3Type.errors || f.doc3Type.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option value="Private">Private</option>
                        <option value="Government">Government</option>        
                    </select>
                    <div *ngIf="submitted && (f.doc3Type.errors || f.doc3Type.value === 'Select')" class="selectError">
                        <div *ngIf="f.doc3Type.errors?.required || f.doc3Type.value === 'Select'">Please select Type of Doctor 3</div>
                    </div>
                </div>

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Specialization: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName = "doc3Specialist"
                        [ngClass]="{ 'is-invalid':submitted && (f.doc3Specialist.errors || f.doc3Specialist.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option value="Gynaecology">Gynaecology</option>
                        <option value="Paediatrics">Paediatrics</option> 
                        <option value="GeneralMedicine">General Medicine</option> 
                        <option value="Ophthalmology">Ophthalmology</option> 
                        <option value="Orthopaedics">Orthopaedics</option> 
                        <option value="Dermatology">Dermatology</option> 
                        <option value="ENT">ENT </option> 
                        <option value="GeneralSurgery">General Surgery</option> 
                        <option value="Dental">Dental</option> 
                        <option value="Pathology">Pathology</option> 
                        <option value="Pulmonology">Pulmonology</option>        
                        <option value="Radiology">Radiology</option> 
                        <option value="SPM">SPM</option> 
                        <option value="Others">Others</option> 
                    </select>
                    <div *ngIf="submitted && (f.doc3Specialist.errors || f.doc3Specialist.value === 'Select')" class="selectError">
                        <div *ngIf="f.doc3Specialist.errors?.required || f.doc3Specialist.value === 'Select'">Please select Doctor 3 Specialization</div>
                    </div>
                </div>
            </div>
            <h6 class="mb-3">Ophthalmic Assistant Details</h6>
            <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="docOpthName" formControlName="docOpthName" class="inputCls" appBlockCopyPaste required maxlength="200" 
                    [ngClass]="{ 'is-invalid':submitted && f.docOpthName.errors }">
                    <div *ngIf="submitted && f1.docOpthName.errors" class="invalid-feedback">
                        <div *ngIf="f.docOpthName.errors?.required || f.docOpthName.errors?.whiteSpaceValidate">Name is required</div>
                        <div *ngIf="f.docOpthName.errors?.pattern">Please enter valid Name</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Mobile No: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="docOpthMobileNo" formControlName="docOpthMobileNo" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':submitted && f.docOpthMobileNo.errors }" minlength="10" maxlength="10" numbersOnly>
                    <div *ngIf="submitted && f.docOpthMobileNo.errors" class="invalid-feedback">
                        <div *ngIf="f.docOpthMobileNo.errors?.required">Please enter Mobile No</div>
                        <div *ngIf="f.docOpthMobileNo.errors?.minlength || f.docOpthMobileNo.errors?.pattern">Please enter valid Mobile No</div>
                    </div>
                </div>
            </div>
            <!-- <h6 class="mb-3">Doctor 4 Details</h6>
            <div class="row mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Doctor Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="doc4Name" formControlName="doc4Name" class="inputCls" appBlockCopyPaste required maxlength="200" 
                    [ngClass]="{ 'is-invalid':submitted && f.doc4Name.errors }">
                    <div *ngIf="submitted && f.doc4Name.errors" class="invalid-feedback">
                    <div *ngIf="f.doc4Name.errors?.required || f.doc4Name.errors?.whiteSpaceValidate">Doctor Name is required</div>
                    <div *ngIf="f.doc4Name.errors?.pattern">Please enter valid Doctor Name</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Aadhaar Number:</label>
                    <input type="text" name="doc4AadharNo" formControlName="doc4AadharNo" class="inputCls" appBlockCopyPaste  numbersOnly
                    [ngClass]="{ 'is-invalid':submitted && f.doc4AadharNo.errors }" maxlength="12" minlength="12">
                    <div *ngIf="submitted && f.doc4AadharNo.errors" class="invalid-feedback">
                    <div *ngIf="f.doc4AadharNo.errors?.required || f.doc4AadharNo.errors?.whiteSpaceValidate">Aadhaar Number is required</div>
                    <div *ngIf="f.doc4AadharNo.errors?.aadharValidate || f.doc4AadharNo.errors?.minlength || f.doc4AadharNo.errors?.maxlength">Please enter valid Aadhaar Number</div>
                </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Mobile No: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="doc4MobileNo" formControlName="doc4MobileNo" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':submitted && f.doc4MobileNo.errors }" minlength="10" maxlength="10" numbersOnly>
                    <div *ngIf="submitted && f.doc4MobileNo.errors" class="invalid-feedback">
                    <div *ngIf="f.doc4MobileNo.errors?.required">Please enter Mobile No</div>
                    <div *ngIf="f.doc4MobileNo.errors?.minlength || f.doc4MobileNo.errors?.pattern">Please enter valid Mobile No</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Gender: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName = "doc4Gender"
                        [ngClass]="{ 'is-invalid':submitted && (f.doc4Gender.errors || f.doc4Gender.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="Transgender">Transgender</option>
                    </select>
                    <div *ngIf="submitted && (f.doc4Gender.errors || f.doc4Gender.value === 'Select')" class="selectError">
                        <div *ngIf="f.doc4Gender.errors?.required || f.doc4Gender.value === 'Select'">Please select Doctor 4 Gender</div>
                    </div>
                </div>
            </div> -->
            <div class="btnHolder mt-4">
                <button class="greenBtnCls"  (click)="addToSchedule()">Add To Schedule</button>
            </div>
        </ng-container>
        </form>
    </ng-container>   
        <div>
            <div class="subHeadingCls my-3"><b>Doctors Team Schedule List</b></div>
            <div class="row mb-3 " >
                <div class="col">
                    <div class="tab table-responsive">
                        <table class="tableCls">
                            <thead>
                                <tr>
                                    <th rowspan="2">Select</th>
                                    <th rowspan="2">S.No</th>
                                    <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'districT_NAME'">District <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'mandaL_NAME'">Mandal <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'teaM_NAME'">Team Name <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'scheduleD_DATE'">Scheduled Date <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'phC_NAME'">PHC <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'vhC_NAME'">VHC <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'secretariaT_NAME'">Secretariat <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th colspan="4">Doctor 1 Details</th>
                                    <th colspan="8">Doctor 2 Details</th>
                                    <th colspan="6">Doctor 3 Details</th>
                                    <!-- <th colspan="4">Doctor 4 Details</th> -->
                                    <th colspan="2">Ophthalmologist Details</th>
                                    <th rowspan="2">Action</th>
                                </tr>
                                <tr>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_1_NAME'">Name <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'neW_DC1_AADHAAR'">Aadhar Number <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'neW_DC1_MOBILE'">Mobile Number <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'neW_DC1_GENDER'">Gender <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_2_TYPE'">Doctor 2 Type <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_2_NAME'">Name <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR2_PHC_NAME'">PHC <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_2_AADHAAR'">Aadhar Number <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_2_MOBILE'">Mobile Number <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_2_GENDER'">Gender <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_3_TYPE'">Doctor Type <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_2_SPECIALIST'">Specialization <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_3_NAME'">Name <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_3_AADHAAR'">Aadhar Number <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_3_MOBILE'">Mobile Number <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_3_GENDER'">Gender <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_3_TYPE'">Doctor Type <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_3_SPECIALIST'">Specialization <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <!-- <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_4_NAME'">Name <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_4_AADHAAR'">Aadhar Number <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_4_MOBILE'">Mobile Number <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'doctoR_4_GENDER'">Gender <mdb-icon fas icon="sort"></mdb-icon></th> -->
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'ophT_ASSIST_NAME'">Name <mdb-icon fas icon="sort"></mdb-icon></th>
                                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="teamList" [sortBy]="'ophT_ASSIST_MOBILE'">Mobile Number <mdb-icon fas icon="sort"></mdb-icon></th>
                                </tr>

                            </thead>
                            <tbody *ngIf="teamList && teamList.length > 0">
                                <tr *ngFor="let item of teamList | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                                    <td><input type="radio"  value = {{item.isSelected}} name="select" (change)="selectSchedule(item)"></td>
                                    <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                                    <!-- <td>{{i + 1}}</td> -->
                                    <td>{{item.districT_NAME}}</td>
                                    <td>{{item.mandaL_NAME}}</td>
                                    <td>{{item.teaM_NAME}}</td>
                                    <td>{{item.scheduleD_DATE | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{item.phC_NAME}}</td>
                                    <td>{{item.vhC_NAME}}</td>
                                    <td>{{item.secretariaT_NAME}}</td>
                                    <td>{{item.doctoR_1_NAME}}</td>
                                    <td>{{item.neW_DC1_AADHAAR !== null ? item.neW_DC1_AADHAAR : '-'}}</td>
                                    <td>{{item.neW_DC1_MOBILE !== null ? item.neW_DC1_MOBILE : '-'}}</td>
                                    <td>{{item.neW_DC1_GENDER !== null ? item.neW_DC1_GENDER : '-'}}</td>
                                    <td>{{item.doctoR_2_TYPE}}</td>
                                    <td>{{item.doctoR_2_NAME}}</td>
                                    <td>{{item.doctoR2_PHC_NAME !== null ? item.doctoR2_PHC_NAME : '-'}}</td>
                                    <td>{{item.doctoR_2_AADHAAR !== null ? item.doctoR_2_AADHAAR : '-'}}</td>
                                    <td>{{item.doctoR_2_MOBILE !== null ? item.doctoR_2_MOBILE : '-'}}</td>
                                    <td>{{item.doctoR_2_GENDER !== null ? item.doctoR_2_GENDER : '-'}}</td>
                                    <td>{{item.doctoR_2_TYPE !== null ? item.doctoR_2_TYPE : '-'}}</td>
                                    <td>{{item.doctoR_2_SPECIALIST !== null ? item.doctoR_2_SPECIALIST : '-'}}</td>
                                    <td>{{item.doctoR_3_NAME}}</td>
                                    <td>{{item.doctoR_3_AADHAAR !== null ? item.doctoR_3_AADHAAR : '-'}}</td>
                                    <td>{{item.doctoR_3_MOBILE}}</td>
                                    <td>{{item.doctoR_3_GENDER}}</td>
                                    <td>{{item.doctoR_3_TYPE !== null ? item.doctoR_3_TYPE : '-'}}</td>
                                    <td>{{item.doctoR_3_SPECIALIST !== null ? item.doctoR_3_SPECIALIST : '-'}}</td>
                                    <!-- <td>{{item.doctoR_4_NAME}}</td>
                                    <td>{{item.doctoR_4_AADHAAR !== null ? item.doctoR_4_AADHAAR : '-'}}</td>
                                    <td>{{item.doctoR_4_MOBILE}}</td>
                                    <td>{{item.doctoR_4_GENDER}}</td> -->
                                    <td>{{item.ophT_ASSIST_NAME}}</td>
                                    <td>{{item.ophT_ASSIST_MOBILE}}</td>
                                    <!-- <td class="text-center">
                                        <button *ngIf="isScheduleConfirmed && !item.ophT_ASSIST_NAME" class="greenBtnCls opthbtn" (click)="editOpth(item, editModal)">Add Ophthalmic Assistant</button>
                                    </td> -->
                                    <td class="text-center">                                        
                                        <button *ngIf="item.specialisT_FLAG == 'P'" class="greenBtnCls opthbtn" (click)="editSpecialization(item, specialistModal)">Add Doctor Specialization</button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="teamList && teamList.length === 0">
                                <tr><td colspan="30" class="text-center">No records found.</td></tr>
                            </tbody>
                        </table>
                      
                    </div>
                    <div class="mt-4 btnHide">
                        <div class="float-left" *ngIf="teamList.length > 0">
                            <label>Requests per page: </label>
                            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                                <option  value="10">10</option>
                                <option  value="20">20</option>
                                <option  value="30">30</option>
                                <option  value="40">40</option>
                                <option  value="50">50</option>
                            </select> 
                        </div>
                        <ngb-pagination class="d-flex justify-content-end" *ngIf="teamList.length > 0" [collectionSize]="teamList.length" [(page)]="page" [pageSize]="pageSize"
                            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
        <div class="btnHolder">
            <button class="greenBtnCls float-right" *ngIf="teamList && teamList.length > 0 && !isScheduleConfirmed && isAllSchedulesCompleted" (click) = "openConfirmScheduleModal1(confirmScheduleModal1)">Confirm Schedule</button>
            <!-- <button class="greenBtnCls float-right mr-3"  *ngIf="teamList && teamList?.length > 0" (click) = "printSchedule()">Print Schedule</button> -->
            <button class="greenBtnCls float-right mr-3" *ngIf="teamList && teamList.length > 0 && !isScheduleConfirmed"  (click) = "deleteSchedule()">Delete Schedule</button>
        </div>
   
</div>
<div class="hideTable" id="teamSchedule">
    <div class="subHeadingCls mb-3"><b>Doctors Team Schedule List for {{teamList[0]?.districT_NAME}}</b></div>
    <div class="row mb-3 " >
        <div class="col">
            <div class="tab table-responsive">
                <table class="tableCls">
                    <thead>
                        <tr>
                            <th rowspan="2">S.No</th>
                            <!-- <th rowspan="2">District</th> -->
                            <th rowspan="2">Mandal</th>
                            <th rowspan="2">Team Name</th>
                            <th rowspan="2">Scheduled Date</th>
                            <th rowspan="2">PHC</th>
                            <th rowspan="2">VHC</th>
                            <th rowspan="2">Secretariat</th>
                            <th rowspan="2">Doctor 1 Name</th>
                            <th colspan="2">Doctor 2 Details</th>
                            <th colspan="3">Doctor 3 Details</th>
                            <!-- <th colspan="3">Doctor 4 Details</th> -->
                        </tr>
                        <tr>
                            <th>Name</th>
                            <th>PHC</th>
                            <th>Name</th>
                            <!-- <th>Aadhar Number</th> -->
                            <th>Mobile Number</th>
                            <th>Name</th>
                            <!-- <th>Aadhar Number</th> -->
                            <th>Mobile Number</th>
                        </tr>

                    </thead>
                    <tbody *ngIf="teamList && teamList.length > 0">
                        <tr *ngFor="let item of teamList;let i = index;">
                            <td>{{i + 1}}</td>
                            <!-- <td>{{item.districT_NAME}}</td> -->
                            <td>{{item.mandaL_NAME}}</td>
                            <td>{{item.teaM_NAME}}</td>
                            <td>{{item.scheduleD_DATE | date: 'dd/MM/yyyy'}}</td>
                            <td>{{item.phC_NAME}}</td>
                            <td>{{item.vhC_NAME}}</td>
                            <td>{{item.secretariaT_NAME}}</td>
                            <td>{{item.doctoR_1_NAME}}</td>
                            <td>{{item.doctoR_2_NAME}}</td>
                            <td>{{item.doctoR2_PHC_NAME}}</td>
                            <td>{{item.doctoR_3_NAME}}</td>
                            <!-- <td>{{item.doctoR_3_AADHAAR}}</td> -->
                            <td>{{item.doctoR_3_MOBILE}}</td>
                            <!-- <td>{{item.doctoR_4_NAME}}</td> -->
                            <!-- <td>{{item.doctoR_4_AADHAAR}}</td> -->
                            <!-- <td>{{item.doctoR_4_MOBILE}}</td> -->
                        </tr>
                    </tbody>
                    <tbody *ngIf="teamList && teamList.length === 0">
                        <tr><td colspan="16" class="text-center">No records found.</td></tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<ng-template #confirmScheduleModal1 let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">Confirm Schedule</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss();" aria-label="Close">
      <span aria-hidden="true">&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
      <p>Are you sure you want to confirm schedules?</p>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss();">No</button>
      <button  type="submit" class="greenBtnCls" name="submit" (click)="openConfirmScheduleModal2(confirmScheduleModal2)">Yes</button>
    </div>
</ng-template>

<ng-template #confirmScheduleModal2 let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">Confirm Schedule</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss();" aria-label="Close">
      <span aria-hidden="true">&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
      <p>After confirming, all the schedules will be freezed and any updates will not be allowed.</p>
      <p>Are you sure you want to confirm schedules?</p>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss();">No</button>
      <button  type="submit" class="greenBtnCls" name="submit" (click)="confirmSchedule()">Confirm</button>
    </div>
</ng-template>

<ng-template #editModal let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">Ophthalmic Assistant</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss();" aria-label="Close">
      <span aria-hidden="true">&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
        <form [formGroup]="teamOpthForm">
            <div class="row">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12  mb-3">
                    <label class="labelCls">Name: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="docOpthName" formControlName="docOpthName" class="inputCls" appBlockCopyPaste required maxlength="200" 
                    [ngClass]="{ 'is-invalid':isEditSubmitted && f1.docOpthName.errors }">
                    <div *ngIf="isEditSubmitted && f1.docOpthName.errors" class="invalid-feedback">
                        <div *ngIf="f1.docOpthName.errors?.required || f1.docOpthName.errors?.whiteSpaceValidate">Name is required</div>
                        <div *ngIf="f1.docOpthName.errors?.pattern">Please enter valid Name</div>
                    </div>
                </div>        
            </div>
            <div class="row">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12  mb-3">
                    <label class="labelCls">Mobile No: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="docOpthMobileNo" formControlName="docOpthMobileNo" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isEditSubmitted && f1.docOpthMobileNo.errors }" minlength="10" maxlength="10" numbersOnly>
                    <div *ngIf="isEditSubmitted && f1.docOpthMobileNo.errors" class="invalid-feedback">
                        <div *ngIf="f1.docOpthMobileNo.errors?.required">Please enter Mobile No</div>
                        <div *ngIf="f1.docOpthMobileNo.errors?.minlength || f1.docOpthMobileNo.errors?.pattern">Please enter valid Mobile No</div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss();">Cancel</button>
      <button  type="submit" class="greenBtnCls" name="submit" (click)="updateOpth()">Update</button>
    </div>
</ng-template>


<ng-template #specialistModal let-modal>
    <div class="modal-header">
     <h5 class="modal-title" id="editProfileLabel">Doctor Specialization</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss();" aria-label="Close">
      <span aria-hidden="true">&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
        <form [formGroup]="specialistForm">
            <div class="row">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12  mb-3">
                    <label class="labelCls">Type of Doctor 2: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName = "doc2Type"
                        [ngClass]="{ 'is-invalid':isEditSubmitted && (f2.doc2Type.errors || f2.doc2Type.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option value="Private">Private</option>
                        <option value="Government">Government</option>        
                    </select>
                    <div *ngIf="isEditSubmitted && (f2.doc2Type.errors || f2.doc2Type.value === 'Select')" class="selectError">
                        <div *ngIf="f2.doc2Type.errors?.required || f2.doc2Type.value === 'Select'">Please select Type of Doctor 2</div>
                    </div>
                </div>       
            </div>
            <div class="row">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12  mb-3">
                    <label class="labelCls">Doctor 2 Specialization: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName = "doc2Specialist"
                        [ngClass]="{ 'is-invalid':isEditSubmitted && (f2.doc2Specialist.errors || f2.doc2Specialist.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option value="Gynaecology">Gynaecology</option>
                        <option value="Paediatrics">Paediatrics</option> 
                        <option value="GeneralMedicine">General Medicine</option> 
                        <option value="Ophthalmology">Ophthalmology</option> 
                        <option value="Orthopaedics">Orthopaedics</option> 
                        <option value="Dermatology">Dermatology</option> 
                        <option value="ENT">ENT </option> 
                        <option value="GeneralSurgery">General Surgery</option> 
                        <option value="Dental">Dental</option> 
                        <option value="Pathology">Pathology</option> 
                        <option value="Pulmonology">Pulmonology</option>        
                        <option value="Radiology">Radiology</option> 
                        <option value="SPM">SPM</option> 
                        <option value="Others">Others</option> 
                    </select>
                    <div *ngIf="isEditSubmitted && (f2.doc2Specialist.errors || f2.doc2Specialist.value === 'Select')" class="selectError">
                        <div *ngIf="f2.doc2Specialist.errors?.required || f2.doc2Specialist.value === 'Select'">Please select Doctor 2 Specialization</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12  mb-3">
                    <label class="labelCls">Type of Doctor 3: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName = "doc3Type"
                        [ngClass]="{ 'is-invalid':isEditSubmitted && (f2.doc3Type.errors || f2.doc3Type.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option value="Private">Private</option>
                        <option value="Government">Government</option>        
                    </select>
                    <div *ngIf="isEditSubmitted && (f2.doc3Type.errors || f2.doc3Type.value === 'Select')" class="selectError">
                        <div *ngIf="f2.doc3Type.errors?.required || f2.doc3Type.value === 'Select'">Please select Type of Doctor 3</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12  mb-3">
                    <label class="labelCls">Doctor 3 Specialization: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" required formControlName = "doc3Specialist"
                        [ngClass]="{ 'is-invalid':isEditSubmitted && (f2.doc3Specialist.errors || f2.doc3Specialist.value === 'Select')}">
                        <option  [ngValue]= "null" disabled>Select</option>
                        <option value="Gynaecology">Gynaecology</option>
                        <option value="Paediatrics">Paediatrics</option> 
                        <option value="GeneralMedicine">General Medicine</option> 
                        <option value="Ophthalmology">Ophthalmology</option> 
                        <option value="Orthopaedics">Orthopaedics</option> 
                        <option value="Dermatology">Dermatology</option> 
                        <option value="ENT">ENT </option> 
                        <option value="GeneralSurgery">General Surgery</option> 
                        <option value="Dental">Dental</option> 
                        <option value="Pathology">Pathology</option> 
                        <option value="Pulmonology">Pulmonology</option>        
                        <option value="Radiology">Radiology</option> 
                        <option value="SPM">SPM</option> 
                        <option value="Others">Others</option> 
                    </select>
                    <div *ngIf="isEditSubmitted && (f2.doc3Specialist.errors || f2.doc3Specialist.value === 'Select')" class="selectError">
                        <div *ngIf="f2.doc3Specialist.errors?.required || f2.doc3Specialist.value === 'Select'">Please select Doctor 3 Specialization</div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss();">Cancel</button>
      <button  type="submit" class="greenBtnCls" name="submit" (click)="updateSpecialization()">Update</button>
    </div>
</ng-template>