<div class="container-fluid mainCls">
  <div class="breadcrumbContainer  mr-3">
    <div class="breadcrumbCls">
      GSWS<span class="activePage">/ Unveiling Of Welfare Schemes Display Boards-Program Details</span>
    </div>
  </div>
  <div class="mainPageContainer mr-3">
    <div class="btnHolder mb-3 pull-right">
      <button class="greenBtnCls float-right my-3" (click)="goToHome()">Home</button>
  </div>
    <form [formGroup]="Form">
      <div class="row mb-3">        
        <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="labelCls">Village/Ward/Secretariat: <span class="mandatoryCls">*</span></label>
        </div>
        <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <select class="selectCls" formControlName="village" (change)="onChangeSecretariat()"
              [ngClass]="{ 'is-invalid':submitted && (f.village.errors || f.village.value === 'Select') }">
              <option [ngValue]="null" disabled>Select</option>
              <option *ngFor="let schedule of scheduleDetails" [ngValue]="schedule">{{schedule.secretariatName}}</option>
            </select>
            <div *ngIf="submitted && f.village.errors" class="selectError">
                <div *ngIf="f.village.errors?.required">Please select Village</div>
              </div>
        </div>
      
      </div>
     <ng-container *ngIf="showProgramDetails"> 
      <div class="row mb-3">
          <div class="col-lg-4">
          <label class="subHeadingCls">Program Started:</label>
        </div>
          <div class="col-lg-6" [ngClass]="{ 'is-invalid':submitted && f.programStarted?.errors }">
          <input type="radio"  value="Yes" name="programStarted" formControlName="programStarted"  (change)="onChange()">
          <span class="mx-2 radioCls">Yes</span>
          <input type="radio"  value="No" name="programStarted" formControlName="programStarted" (change)="onChange()">
          <span class="mx-2 radioCls">No</span>
          <div *ngIf="submitted && f.programStarted.errors" class="selectError">
              <div *ngIf="f.programStarted.errors.required">Please select Program Started Status </div>
          </div>
      </div>
      </div>
      <div *ngIf="f.programStarted.value=='Yes'">
          <h6 class="mb-3"><strong>Note: All Upload Documents are in jpg/jpeg/png Format Only. Maximum Upload Size Per File is 1MB. </strong></h6>
      <div class="row mb-3">
          <div class="col-lg-4">
            <label class="labelCls">No Of Members Attended: <span class="mandatoryCls"> *</span></label>
          </div>
          <div class="col-lg-4">
            <input type="text" name="membersAttended" numbersOnly maxlength ="10"
            appBlockCopyPaste class="inputCls" formControlName = "membersAttended"  required [ngClass]="{ 'is-invalid':submitted && f.membersAttended?.errors }">
            <div *ngIf="submitted && f.membersAttended.errors" class="invalid-feedback">
                <div *ngIf="f.membersAttended.errors?.required">Please enter number of members attended</div>
              </div>
          </div>
      </div>
      <div class="row mb-3"></div>
      <div class="row mb-3">
          <div class="col-lg-4">
              <label class="labelCls">Chief Guest: <span class="mandatoryCls">*</span></label>
          </div>
              <div class="col-lg-4 fieldHolder">
              <input type="text" name="nameOfGuest"   maxlength ="50" (keypress)="keyPressAlpha($event)"
                appBlockCopyPaste class="inputCls" formControlName = "nameOfGuest" required [ngClass]="{ 'is-invalid':submitted && f.nameOfGuest.errors}">
                <div *ngIf="submitted && f.nameOfGuest.errors" class="invalid-feedback">
                  <div *ngIf="f.nameOfGuest.errors?.required || f.nameOfGuest.errors?.whiteSpaceValidate">Please enter Chief Guest Name</div>
                </div>
            </div> 
            </div>
      <div class="row mb-3"></div>
            <div class="row mb-3">
              <div class="col-lg-4">
              <div class="checkBoxCont">
                  <label class="labelCls">Program Photo:<span class="mandatoryCls">*</span></label>
              </div>
          </div>
          <div class="col-lg-4 fieldHolder"> 
              <div class="input-group">
                  <div class="custom-file">
                  <input type="file" class="custom-file-input" formControlName="programPhoto" id="programPhoto" accept=".jpg, .jpeg, .png" required
                  [ngClass]="{ 'is-invalid':submitted && (f.programPhoto.errors )}"(change)="onCampStartPhotoSelected($event)">
                  <label class="custom-file-label" for="programPhoto"><i class="fas fa-search"></i> {{campStartPhotoLabelValue}}</label>
                  </div>
                  <div class="clearCls" (click)="clearCampStartPhotoUpload('programPhoto', 'campStartPhotoLabel')" *ngIf="f.programPhoto.value"></div>
              </div>
              <div *ngIf="f.programPhoto.errors && submitted" class="selectError">
                  <div *ngIf="f.programPhoto.errors?.required">Please upload Program photo</div>
                  <div *ngIf="f.programPhoto.errors?.inValidExt && !f.programPhoto.errors.required ">Please upload the file in jpg/jpeg/png Format</div>
                  <div *ngIf="f.programPhoto.errors?.inValidSize && !f.programPhoto.errors.required && !f.programPhoto.errors.inValidExt">File size exceeding 1MB</div> 
              </div>
          </div>

          </div> 
      </div>

        <div *ngIf="f.programStarted.value=='No'">
          <div class="row mb-3">
              <div class="col-lg-4">
                  <label class="labelCls">Reason: <span class="mandatoryCls">*</span></label>
              </div>
                  <div class="col-lg-4 fieldHolder">
                  <input type="text" name="reason"   maxlength ="80" 
                    appBlockCopyPaste class="inputCls" formControlName = "reason" required [ngClass]="{ 'is-invalid':submitted && f.reason.errors}">
                    <div *ngIf="submitted && f.reason.errors" class="invalid-feedback">
                      <div *ngIf="f.reason.errors?.required">Please enter reason</div>
                    </div>
                </div> 
                </div>
                <div class="row mb-3"></div>
                <div class="row mb-3">
                  <div class="col-lg-4">
                    <label class="labelCls">  Tentative Schedule Date:  <span class="mandatoryCls">*</span></label>
                  </div>
                <div class="col-lg-4">
                  <div class="input-group">
                    <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                            name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="minDate" 
                            [maxDate]="maxDate" required formControlName="dateOfCommencement"  required
                            [ngClass]="{ 'is-invalid':submitted && f.dateOfCommencement?.errors }" >
                    <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
                    </div>
                </div>
                <div *ngIf="submitted && f.dateOfCommencement?.errors" class="selectError">
                  <div *ngIf="f.dateOfCommencement?.errors?.required || f.dateOfCommencement?.invalid">Please select Tentative Schedule Date</div>
              </div>
                </div>
          </div>
      </div>
      <div class="btnHolder">
          <button class="greenBtnCls float-right mr-3 my-3" (click) = "submit()">Submit</button>
      </div>
      </ng-container>
    </form>
  </div>
</div>

