<div class="reportContainer">
    <div  [formGroup]="form">
        <div class="row mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
            <select class="selectCls"  formControlName="district" (change)="onDistrictChange()"
            [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
                <option value="All">All</option>
                <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
            </select>  
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Rural/Urban : <span class="mandatoryCls">*</span></label> 
                <select class="selectCls" required formControlName="RUFlag" (change)="onRuralUrbanChange()"
                [ngClass]="{ 'is-invalid':submitted && (f.RUFlag.errors || f.RUFlag.value === 'Select') }">
                <option value = "All">All</option>
                <option value = "R">Rural</option>
                <option value = "U">Urban</option>
                </select>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Mandal <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
              [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
            <option value="All">All</option>
            <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
            </select>
         
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Secretariat: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="village"  (change)="onVillageChange()"  >
                <option value="All">All</option>
                <option *ngFor="let village of villages" [ngValue]="village">{{village.secretariatName}}</option>
            </select>
            
            </div>
        </div>
    </div>

 
    
      <div class="tabClass">
        <ul>
            <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li> 
        </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getPanCardReportData()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
        </div>
      </div>
 
      <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="table-responsive tableFixHead" *ngIf="showReports">
        <table class="tableCls" >
            <thead> 
                <tr>
                    <th [attr.colspan]="noOfCol">Monitoring Dashboard for PAN Collection Report</th>
                </tr>
                <tr>
                    <th>S.No</th>
                    <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'dname'">District <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mname'">Mandal <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'">Secretariat Code <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'sname'">Secretariat <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'comP_FLAG'">Pancard Updated (Yes/No) <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'toT_SEC'">No of Secretariats <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'comP_SEC'">No of Secretariats updated PAN number <mdb-icon fas icon="sort"></mdb-icon></th>
                </tr>      
            </thead>
    
            <tbody *ngIf="items && items.length > 0">
                <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                    <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                    <td>{{item.dname}}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mname}}</td>
                    <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_CODE}}</td>
                    <td *ngIf="format === 'Secretariat Wise'">{{item.sname}}</td>
                    <td *ngIf="format === 'Secretariat Wise'">{{item.comP_FLAG}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'" class="text-right">{{item.toT_SEC}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'"class="text-right">{{item.comP_SEC}}</td>
                </tr>
                <tr *ngIf="totalItems && totalItems.length > 0">
                    <td></td>
                    <td>{{totalItems[0].dname}}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mname}}</td>
                    <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_CODE}}</td>
                    <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].sname}}</td>
                    <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].comP_FLAG}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'" class="text-right">{{totalItems[0].toT_SEC}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'" class="text-right">{{totalItems[0].comP_SEC}}</td>
                </tr>
            </tbody>
            <tbody *ngIf="items && items.length === 0">
                <tr>
                    <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="mt-4 btnHide" *ngIf="showReports">
            <div class="float-left" *ngIf="items.length > 0">
                <label>Requests per page: </label>
                <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                    <option  value="10">10</option>
                    <option  value="20">20</option>
                    <option  value="30">30</option>
                    <option  value="40">40</option>
                    <option  value="50">50</option>
                </select> 
            </div>
            <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
            </ngb-pagination>
        </div>
    </div>
    </div>

<div class="table-responsive tableFixHead" #TABLE class="hideTable">
    <table class="tableCls" >
        <thead>
            <tr>
                <th [attr.colspan]="noOfCol">Monitoring Dashboard for PAN Collection Report</th>
            </tr>
            <tr>
                <th>S.No</th>
                   <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'dname'">District <mdb-icon fas icon="sort"></mdb-icon></th>
                   <th *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mname'">Mandal <mdb-icon fas icon="sort"></mdb-icon></th>
                   <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'">Secretariat Code <mdb-icon fas icon="sort"></mdb-icon></th>
                   <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'sname'">Secretariat <mdb-icon fas icon="sort"></mdb-icon></th>
                   <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'comP_FLAG'">Pancard Updated (Yes/No) <mdb-icon fas icon="sort"></mdb-icon></th>
                   <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'toT_SEC'">No of Secretariats <mdb-icon fas icon="sort"></mdb-icon></th>
                   <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'comP_SEC'">No of Secretariats updated PAN number <mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>     
        </thead>
 
        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items;let i = index;">
                <td width="100px">{{i + 1}}</td>
                <td>{{item.dname}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mname}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_CODE}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{item.sname}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{item.comP_FLAG}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'" class="text-right">{{item.toT_SEC}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'"class="text-right">{{item.comP_SEC}}</td>
            </tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
             <td></td>
             <td>{{totalItems[0].dname}}</td>
             <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mname}}</td>
             <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_CODE}}</td>
             <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].sname}}</td>
             <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].comP_FLAG}}</td>
             <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'" class="text-right">{{totalItems[0].toT_SEC}}</td>
             <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'" class="text-right">{{totalItems[0].comP_SEC}}</td>
         </tr>
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
</div>
</div>


