    <div class="certificateInfo">
        <div class="subHeadingCls mb-3">Applicant Details</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Family Income:</label>
                    <div class="valueCls">{{certificateInfo?.familyIncome ? (certificateInfo?.familyIncome) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Occupation:</label>
                    <div class="valueCls">{{certificateInfo?.occupation ? (certificateInfo?.occupation) : '-' }}</div>
                </div>
             </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Habitation:</label>
                    <div class="valueCls">{{certificateInfo?.habitation ? (certificateInfo?.habitation) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Rice Card Number:</label>
                    <div class="valueCls">{{certificateInfo?.riceCardNumber ? (certificateInfo?.riceCardNumber) : '-' }}</div>
                </div>
             
            </div>
         
        </div>
        <hr>
        <div class="subHeadingCls mb-3">Rice Card Family Member Details</div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <table class="tableCls mt-2">
                    <thead>
                    <tr> 
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Rice Card Number</th>
                        <th>Relation</th>
                        <th>EKYCStatus</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of certificateInfo?.riceCardMembersDataList; let i=index">
                            <td class="serial">{{i+1}}</td>
                            <td>{{item.englishName ? (item.englishName) : '-' }}</td>
                            <td class="value">{{item.existingRiceCardNo ? (item.existingRiceCardNo) : '-' }}</td>
                            <td>{{item.relationShip ? (item.relationShip) : '-' }}</td>
                            <td>{{item.exycStatus ? (item.exycStatus) : '-' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    
    
    