import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../common.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { fileExtensionValidator, fileSizeValidator } from 'src/shared/validators/file-validator';
import { CommonConstants } from 'src/constants/common-constants.component';

@Component({
  selector: 'app-common-upload-file-component',
  templateUrl: './common-upload-file-component.component.html',
  styleUrls: ['./common-upload-file-component.component.css']
})
export class CommonUploadFileComponentComponent implements OnInit {
  
    @Input() reqId: string = '';
    @Input() title: string = '';
    @Input() message: string = '';
    submitted: boolean = false;
    uploadFile!: FormGroup;
    @ViewChild('otherDocumentsLabel')
  otherDocumentsLabel!: ElementRef;
  commonConstants: any = CommonConstants

  constructor(public activeModal: NgbActiveModal, config: NgbModalConfig, public formBuilder: FormBuilder, public commonService: CommonService) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.uploadFile = this.formBuilder.group({
      uploadFileControl: [null, Validators.required]
    })
  }

  get f() {
    return this.uploadFile.controls
  }

  ngOnInit(): void {
   
  }

  fileList: any = [];
  campPhotoFile: any;
  campPhotoLabelValue = "";


  onFileSelected(fileEvent: any) {
    this.submitted = true;
    if(this.uploadFile.valid) {
      this.submitted = false;
    }
   const appFormFile: File | null = fileEvent.target.files[0];
   const filename = fileEvent.target.files[0]?.name;
    this.campPhotoLabelValue = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
    this.uploadFile.controls['uploadFileControl'].setValidators([
      Validators.required, fileExtensionValidator('jpg,jpeg,png'), fileSizeValidator(fileEvent)
    ])
    this.uploadFile.controls['uploadFileControl'].updateValueAndValidity();
    if (this.uploadFile.valid){
      this.fileUpload(appFormFile,this.f['uploadFileControl'].value,filename,'campPhoto', 'campPhoto', 'campPhotoLabel');
    }
  }

  clearFileUpload(name:string, label:string) {
    this.f['uploadFileControl'].reset();
    if (label === 'otherDocumentsLabel') {
      this.campPhotoLabelValue = 'No File Choosen';
    } 
  }

  fileUpload(file: any, filePath: string, fileName: string, documentType: string, name:string, label:string)  {
    const formData = new FormData();
      formData.append('File', file, fileName);
      formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
      console.log( formData,'formData');
    this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload,formData).subscribe({
      next: (responseData:any) => {
        this.commonService.filepath = responseData.result.blobRef;
        if(typeof(responseData.result) !== 'string'){ 
          this.campPhotoFile  = {
            FileName: fileName,
            DocumentType: documentType,
            BlobRef : responseData['result'].blobRef
          };
        }else{
          if(name && label) {
            this.clearFileUpload(name, label);
          }
            this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
        }
      },
      error: (error) => {
        if(name && label) {
          this.clearFileUpload(name, label);
        }
        console.log('client side', error);
      }
    });
  }

  onOkClick() {
    if(this.uploadFile.valid) {
      this.activeModal.close('Y')
      this.submitted = false; 
    }
    else {
      this.submitted = true;
    }
  }
  }

