<div class="mainPageContainer mr-3 mt-5"> 
    <div class="btnHolder mb-3">
        <button class="btn btn-primary float-right my-3"  (click)="goToHome()">Home</button>
        <button class="btn btn-primary float-right my-3 mr-3"  (click)="goToTeamSchedule()">Go To Team Schedule</button>
    </div>
    <h6 class="mb-3">Team Composition for Aarogya Suraksha 2.0</h6>
    <h6 class="mb-3 subHeadingCls">Note: Please click on submit for saving the changes (edit/delete/add).</h6>
    <form [formGroup]="teamForm">
        <div formArrayName="teamData">
            <div [formGroupName]="i" *ngFor="let team of teamData.controls; let i=index;" class="teamContainer">
                <h6 class="mb-3">Team {{i + 1}}</h6>
                <div class="row mb-3">
                    <div class="col">
                    <div class="tab">
                        <table class="tableCls">
                        <thead>
                            <tr>
                            <th>Select</th>
                            <th>SNo</th>
                            <th>Aadhaar No<span class="mandatoryCls">*</span></th>
                            <th>Name<span class="mandatoryCls">*</span></th>
                            <th>Designation<span class="mandatoryCls">*</span></th>
                            <th>Mobile No<span class="mandatoryCls">*</span></th>
                            
                            </tr>
                        </thead>
                        <tbody formArrayName="teamMemberData" *ngIf="getTeamMembers(team).length > 0">
                            <tr [formGroupName]="j"  *ngFor="let member of getTeamMembers(team); let j=index;">
                            <td><input type="radio" value="{{member.get('isSelected')?.value}}" [checked]="member.get('isSelected')?.value" formControlName = "isSelected" name="{{'Team' + i}}" (change)="selectTeamMember(team, j)"></td>
                            <td class="serial">{{j+1}}</td>
                            <td>
                                <input type="text" class="inputCls value" name="aadharNo" id="aadharNo" appBlockCopyPaste required [ngClass]="{ 'is-invalid':submitted && member.get('aadharNo')?.errors }" formControlName = "aadharNo" maxlength="12" minlength="12" numbersOnly>
                                <div *ngIf="submitted && member.get('aadharNo')?.errors" class="invalid-feedback">
                                    <div *ngIf="member.get('aadharNo')?.errors?.required  || member.get('aadharNo')?.errors?.whiteSpaceValidate">Please enter Aadhaar Number</div>
                                    <div *ngIf="member.get('aadharNo')?.errors?.aadharValidate  || member.get('aadharNo')?.errors?.minlength || member.get('aadharNo')?.errors?.maxlength">Please enter valid Aadhaar Number</div>
                                </div>
                            </td>
                            <td>
                                <input type="text" class="inputCls" name="name" id="name" appBlockCopyPaste required [ngClass]="{ 'is-invalid':submitted && member.get('name')?.errors }" formControlName = "name" >
                                <div *ngIf="submitted && member.get('name')?.errors" class="invalid-feedback">
                                <div *ngIf="member.get('name')?.errors?.required  || member.get('name')?.errors?.whiteSpaceValidate">Please enter name</div>
                                <div *ngIf="member.get('name')?.errors?.pattern">Please enter valid Name</div>
                                </div>
                            </td>
                            <td>
                                <input type="text" class="inputCls" name="designation" id="designation" appBlockCopyPaste required [ngClass]="{ 'is-invalid':submitted && member.get('designation')?.errors }"  formControlName = "designation" >
                                <div *ngIf="submitted && member.get('designation')?.errors" class="invalid-feedback">
                                <div *ngIf="member.get('designation')?.errors?.required  || member.get('designation')?.errors?.whiteSpaceValidate">Please enter designation</div>
                                <div *ngIf="member.get('designation')?.errors?.pattern">Please enter valid Designation</div>
                                </div>
                            </td>
                            <td>
                                <input type="text" class="inputCls value" name="mobileNo" id="mobileNo" appBlockCopyPaste required [ngClass]="{ 'is-invalid':submitted && member.get('mobileNo')?.errors }" numbersOnly formControlName = "mobileNo"  maxlength="10" minlength="10">
                                <div *ngIf="submitted && member.get('mobileNo')?.errors" class="invalid-feedback">
                                <div *ngIf="member.get('mobileNo')?.errors?.required  || member.get('mobileNo')?.errors?.whiteSpaceValidate">Please enter mobile number</div>
                                </div>
                            </td>
                            <!-- <td>
                                <button  class="btn btn-primary mr-3 add-new" (click)="deleteTeamMember(team, j)">Delete Team Member</button>
                            </td> -->
                            </tr>
                        </tbody>
                        <tbody *ngIf="getTeamMembers(team).length === 0">
                            <tr>
                                <td colspan="6">No Team members added.</td>
                            </tr>
                        </tbody>
                        </table>
                    
                    </div>
                    </div>
                </div>
                <div class="text-center mb-3">
                    <button  class="btn btn-primary mr-3 add-new" (click)="addTeamMember(i)"><i class="fa fa-plus"></i>Add Team Member</button>
                    <button  class="btn btn-primary mr-3 add-new" (click)="deleteTeamMember(i)">Delete Team Member</button>
                    <!-- <button  class="btn btn-primary mr-3 add-new" (click)="deleteTeam(i)">Delete Team</button> -->
                </div>
            </div>
        </div>
        <div class="text-center mb-3">
            <button  class="btn btn-primary mr-3 add-new" (click)="addTeam()"><i class="fa fa-plus"></i>Add Team</button>
        </div>
        <div class="btnHolder">
            <button class="greenBtnCls float-right" (click) = "submit()">Submit</button>
          </div>
    </form>
</div>