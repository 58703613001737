<div class="pensions-report-container">
    <div [formGroup]="form">
        <div class="row mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
            <select class="selectCls"  formControlName="district" (change)="onDistrictChange()"
            [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
                <option value="All">All</option>
                <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
            </select>  
            </div>
           
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Mandal <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
              [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
            <option value="All">All</option>
            <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
            </select>
         
            </div>

            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Rural/Urban : <span class="mandatoryCls">*</span></label> 
                <select class="selectCls" required formControlName="RUFlag"
                [ngClass]="{ 'is-invalid':submitted && (f.RUFlag.errors || f.RUFlag.value === 'Select') }">
                <option value = "All">All</option>
                <option value = "R">Rural</option>
                <option value = "U">Urban</option>
                </select>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Secretariat: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="village" (change)="onVillageChange()" >
                <option value="All">All</option>
                <option *ngFor="let village of villages" [ngValue]="village">{{village.secretariatName}}</option>
            </select>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">From Date: <span class="mandatoryCls">*</span></label>
                <div class="input-group">
                  <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                         name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" (dateSelect)="onFromDateSelect()"
                         [maxDate]="maxDate" required formControlName="fromDate"
                         [ngClass]="{ 'is-invalid':submitted && f.fromDate.errors }">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                  </div>
                </div>
                <div *ngIf="submitted && f.fromDate.errors" class="selectError">
                  <div *ngIf="f.fromDate.errors?.required || f.fromDate.invalid">Please enter valid From Date</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">To Date: <span class="mandatoryCls">*</span></label>
                <div class="input-group">
                  <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                         name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="toMinDate" (dateSelect)="onToDateSelect()"
                         [maxDate]="maxDate" required formControlName="toDate"
                         [ngClass]="{ 'is-invalid':submitted && f.toDate.errors }">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
                  </div>
                </div>
                <div *ngIf="submitted && f.toDate.errors" class="selectError">
                  <div *ngIf="f.toDate.errors?.required || f.toDate.invalid">Please enter valid To Date</div>
              </div>
              </div>

              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Transaction Source <span class="mandatoryCls">*</span></label>
                <select class="selectCls" formControlName="source" (change)="onSourceChange()" 
                [ngClass]="{ 'is-invalid':submitted && (f.source.errors || f.source.value === 'Select') }">
                    <option value="All">All</option>
                    <option value="Apseva">Apseva</option>
                    <option value="Old">Old</option>
                </select>
                </div>
        </div>
    <div class="tabClass">
        <ul>
            <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li> 
        </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getAadharChallanGenerationReport()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" (click)="downloadExcel()" *ngIf="items && items?.length > 0 && showReports">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
        </div>
    </div>
</div>

<div class="table-responsive tableFixHead" *ngIf="showReports">
    <table class="tableCls">
        <thead>
            <tr>
                <th [attr.colspan]="noOfCol">Aadhar Challan Generation Report from {{f.fromDate.value}} to  {{f.toDate.value}}</th>
            </tr>
            <tr>
                <th rowspan="3">S.no</th>
                <th rowspan="3" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District Name <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="3" [mdbTableSort]="items" [sortBy]="'nO_OF_KITS'" *ngIf="format === 'District Wise'">Number of Kits <mdb-icon fas icon="sort"></mdb-icon></th>	
                <th rowspan="3" [mdbTableSort]="items" [sortBy]="'revenuE_DIV_NAME'" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Division Name <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="3" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal Name <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="3" [mdbTableSort]="items" [sortBy]="'ruraL_URBAN_FLAG'" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Rural/Urban <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="3" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'" *ngIf="format === 'Secretariat Wise'">Secretariat Name <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="3" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'" *ngIf="format === 'Secretariat Wise'">Secretariat Code <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="1" colspan="14">Service Types</th>		
                <th rowspan="1" colspan="2">Total Number Of Transactions</th>
                <th rowspan="1" colspan="2">Total Number Of Challans Generated Transactions</th>
                <th rowspan="1" colspan="2">Total Number Of Challans Not Generated Transactions</th>
                <th rowspan="1" colspan="2">Total Number Of Challans Deposited Transactions</th>
            </tr>
            <tr>
                <th colspan="2">New Aadhar Enrollments</th>
                <th colspan="2">Mandatory Biometric Update Along With Demographic Updates (Age 5-7 && 15-17)</th>
                <th colspan="2">Mandatory Biometric Update For Ages 7-15 Years & Greater Than 17 Years</th>
                <th colspan="2">Biometric Update With Or Without Demographic Update</th>
                <th colspan="2">Demographic Update</th>
                <th colspan="2">E-Aadhar Download And Color Print On A4 Sheet</th>
                <th colspan="2">Document Update</th>
                <th colspan="1" rowspan="3" [mdbTableSort]="items" [sortBy]="'toT_REQ_CNT'">Count <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" rowspan="3" [mdbTableSort]="items" [sortBy]="'toT_REQ_AMT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" rowspan="3" [mdbTableSort]="items" [sortBy]="'chaL_GEN_REQ'">Count <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" rowspan="3" [mdbTableSort]="items" [sortBy]="'chaL_GEN_AMT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" rowspan="3" [mdbTableSort]="items" [sortBy]="'chaL_NOTGEN_REQ'">Count <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" rowspan="3" [mdbTableSort]="items" [sortBy]="'chaL_NOTGEN_AMT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" rowspan="3" [mdbTableSort]="items" [sortBy]="'chaL_DEP_REQ'">Count <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" rowspan="3" [mdbTableSort]="items" [sortBy]="'chaL_DEP_AMT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>
            <tr>
                <th colspan="1" [mdbTableSort]="items" [sortBy]="'aadhaR_ENROL_REQ_CNT'">Count <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" [mdbTableSort]="items" [sortBy]="'aadhaR_ENROL_REQ_AMT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" [mdbTableSort]="items" [sortBy]="'mbU_5TO7_15_17_REQ_CNT'">Count <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" [mdbTableSort]="items" [sortBy]="'mbU_5TO7_15_17_REQ_AMT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" [mdbTableSort]="items" [sortBy]="'mbU_GRT_7_17_REQ_CNT'">Count <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" [mdbTableSort]="items" [sortBy]="'mbU_GRT_7_17_REQ_AMT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" [mdbTableSort]="items" [sortBy]="'biO_DEMO_REQ_CNT'">Count <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" [mdbTableSort]="items" [sortBy]="'biO_DEMO_REQ_AMT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" [mdbTableSort]="items" [sortBy]="'demO_UPDATE_REQ_CNT'">Count <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" [mdbTableSort]="items" [sortBy]="'demO_UPDATE_REQ_AMT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" [mdbTableSort]="items" [sortBy]="'eaadhaaR_A4_REQ_CNT'">Count <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" [mdbTableSort]="items" [sortBy]="'eaadhaaR_A4_REQ_AMT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" [mdbTableSort]="items" [sortBy]="'doC_UPDATE_REQ_CNT'">Count <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="1" [mdbTableSort]="items" [sortBy]="'doC_UPDATE_REQ_AMT'">Amount <mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>
        </thead>
        <tbody *ngIf="items && items?.length > 0">
            <ng-container>
                <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                    <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                    <td>{{ item.districT_NAME}}</td>
                    <td *ngIf="format === 'District Wise'">{{ item.nO_OF_KITS }}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.revenuE_DIV_NAME}}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.ruraL_URBAN_FLAG}}</td>
                    <td *ngIf=" format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
                    <td *ngIf=" format === 'Secretariat Wise'">{{item.secretariaT_CODE}}</td>
                    <td colspan="1">{{ item.aadhaR_ENROL_REQ_CNT}}</td>  
                    <td colspan="1">{{ item.aadhaR_ENROL_REQ_AMT}}</td>
                    <td colspan="1">{{ item.mbU_5TO7_15_17_REQ_CNT}}</td>
                    <td colspan="1">{{ item.mbU_5TO7_15_17_REQ_AMT}}</td>
                    <td colspan="1">{{item.mbU_GRT_7_17_REQ_CNT}}</td>
                    <td colspan="1">{{item.mbU_GRT_7_17_REQ_AMT}}</td>
                    <td colspan="1">{{ item.biO_DEMO_REQ_CNT}}</td>
                    <td colspan="1">{{ item.biO_DEMO_REQ_AMT}}</td>
                    <td colspan="1">{{ item.demO_UPDATE_REQ_CNT}}</td>
                    <td colspan="1">{{ item.demO_UPDATE_REQ_AMT}}</td>
                    <td colspan="1">{{ item.eaadhaaR_A4_REQ_CNT}}</td>
                    <td colspan="1">{{ item.eaadhaaR_A4_REQ_AMT}}</td>
                    <td colspan="1">{{ item.doC_UPDATE_REQ_CNT}}</td>
                    <td colspan="1">{{ item.doC_UPDATE_REQ_AMT}}</td>
                    <td colspan="1">{{ item.toT_REQ_CNT}}</td>
                    <td colspan="1">{{ item.toT_REQ_AMT}}</td>
                    <td colspan="1">{{ item.chaL_GEN_REQ}}</td>
                    <td colspan="1">{{ item.chaL_GEN_AMT}}</td>
                    <td colspan="1">{{ item.chaL_NOTGEN_REQ}}</td>
                    <td colspan="1">{{ item.chaL_NOTGEN_AMT}}</td>
                    <td colspan="1">{{ item.chaL_DEP_REQ}}</td>
                    <td colspan="1">{{ item.chaL_DEP_AMT}}</td>
                </tr>
                <tr *ngIf="totalItems && totalItems?.length > 0">
                    <td></td>
                    <td>{{ totalItems[0].districT_NAME}}</td>
                    <td *ngIf="format === 'District Wise'">{{ totalItems[0].nO_OF_KITS }}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].revenuE_DIV_NAME}}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].ruraL_URBAN_FLAG}}</td>
                    <td *ngIf=" format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
                    <td *ngIf=" format === 'Secretariat Wise'">{{totalItems[0].secretariaT_CODE}}</td>
                    <td colspan="1">{{ totalItems[0].aadhaR_ENROL_REQ_CNT}}</td>
                    <td colspan="1">{{ totalItems[0].aadhaR_ENROL_REQ_AMT}}</td>
                    <td colspan="1">{{ totalItems[0].mbU_5TO7_15_17_REQ_CNT}}</td>
                    <td colspan="1">{{ totalItems[0].mbU_5TO7_15_17_REQ_AMT}}</td>
                    <td colspan="1">{{totalItems[0].mbU_GRT_7_17_REQ_CNT}}</td>
                    <td colspan="1">{{totalItems[0].mbU_GRT_7_17_REQ_AMT}}</td>
                    <td colspan="1">{{ totalItems[0].biO_DEMO_REQ_CNT}}</td>
                    <td colspan="1">{{ totalItems[0].biO_DEMO_REQ_AMT}}</td>
                    <td colspan="1">{{ totalItems[0].demO_UPDATE_REQ_CNT}}</td>
                    <td colspan="1">{{ totalItems[0].demO_UPDATE_REQ_AMT}}</td>
                    <td colspan="1">{{ totalItems[0].eaadhaaR_A4_REQ_CNT}}</td>
                    <td colspan="1">{{ totalItems[0].eaadhaaR_A4_REQ_AMT}}</td>
                    <td colspan="1">{{ totalItems[0].doC_UPDATE_REQ_CNT}}</td>
                    <td colspan="1">{{ totalItems[0].doC_UPDATE_REQ_AMT}}</td>
                    <td colspan="1">{{ totalItems[0].toT_REQ_CNT}}</td>
                    <td colspan="1">{{ totalItems[0].toT_REQ_AMT}}</td>
                    <td colspan="1">{{ totalItems[0].chaL_GEN_REQ}}</td>
                    <td colspan="1">{{ totalItems[0].chaL_GEN_AMT}}</td>
                    <td colspan="1">{{ totalItems[0].chaL_NOTGEN_REQ}}</td>
                    <td colspan="1">{{ totalItems[0].chaL_NOTGEN_AMT}}</td>
                    <td colspan="1">{{ totalItems[0].chaL_DEP_REQ}}</td>
                    <td colspan="1">{{ totalItems[0].chaL_GEN_AMT}}</td>
                </tr>
            </ng-container>
        </tbody> 
        <ng-container>
            <tbody>
                <tr *ngIf="items?.length == 0">
                    <td [attr.colspan]="noOfCol" class="text-center" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </ng-container>
    </table>
</div>

<div class="table-responsive tableFixHead" #TABLE *ngIf="showReports" class="hideTable">
    <table class="tableCls">
        <thead>
            <tr>
                <th [attr.colspan]="noOfCol">Aadhar Challan Generation Report from {{f.fromDate.value}} to  {{f.toDate.value}} </th>
            </tr>
            <tr>
                <th rowspan="3">S No</th>
                <th rowspan="3">District Name</th>
                <th rowspan="3" [mdbTableSort]="items" [sortBy]="'nO_OF_KITS'" *ngIf="format === 'District Wise'">Number of Kits <mdb-icon fas icon="sort"></mdb-icon></th>	
                <th rowspan="3" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Division Name</th>
                <th rowspan="3" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal Name</th>
                <th rowspan="3" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Rural/Urban</th>
                <th rowspan="3" *ngIf="format === 'Secretariat Wise'">Secretariat Name</th>
                <th rowspan="3" *ngIf="format === 'Secretariat Wise'">Secretariat Code</th>
                <th rowspan="1" colspan="14">Services Type</th>		
                <th rowspan="1" colspan="2">Total Number Of Transactions</th>
                <th rowspan="1" colspan="2">Total Number Of Challans Generated Transactions</th>
                <th rowspan="1" colspan="2">Total Number Of Challans Not Generated Transactions</th>
                <th rowspan="1" colspan="2">Total Number Of Challans Deposited Transactions</th>
            </tr>
            <tr>
                <th colspan="2">New Aadhar Enrollments</th>
                <th colspan="2">Mandatory Biometric Update Along With Demographic Updates (Age 5-7 && 15-17)</th>
                <th colspan="2">Mandatory Biometric Update For Ages 7-15 Years & Greater Than 17 Years</th>
                <th colspan="2">Biometric Update With Or Without Demographic Update</th>
                <th colspan="2">Demographic Update</th>
                <th colspan="2">E-Aadhar Download And Color Print On A4 Sheet</th>
                <th colspan="2">Document Update</th>
                <th colspan="1" rowspan="2">Count</th>
                <th colspan="1" rowspan="2">Amount</th>
                <th colspan="1" rowspan="2">Count</th>
                <th colspan="1" rowspan="2">Amount</th>
                <th colspan="1" rowspan="2">Count</th>
                <th colspan="1" rowspan="2">Amount</th>
                <th colspan="1" rowspan="2">Count</th>
                <th colspan="1" rowspan="2">Amount</th>
            </tr>
            <tr>
                <th colspan="1">Count</th>
                <th colspan="1">Amount</th>
                <th colspan="1">Count</th>
                <th colspan="1">Amount</th>
                <th colspan="1">Count</th>
                <th colspan="1">Amount</th>
                <th colspan="1">Count</th>
                <th colspan="1">Amount</th>
                <th colspan="1">Count</th>
                <th colspan="1">Amount</th>
                <th colspan="1">Count</th>
                <th colspan="1">Amount</th>
                <th colspan="1">Count</th>
                <th colspan="1">Amount</th>
            </tr>
    </thead>
        <tbody *ngIf="items && items?.length > 0">
            <ng-container>
                <tr *ngFor="let item of items; let i = index;">
                    <td>{{(i + 1)}}</td>
                    <td>{{ item.districT_NAME}}</td>
                    <td *ngIf="format === 'District Wise'">{{ item.nO_OF_KITS }}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.revenuE_DIV_NAME}}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.ruraL_URBAN_FLAG}}</td>
                    <td *ngIf=" format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
                    <td *ngIf=" format === 'Secretariat Wise'">{{item.secretariaT_CODE}}</td>
                    <td  colspan="1">{{ item.aadhaR_ENROL_REQ_CNT}}</td>
                    <td  colspan="1">{{ item.aadhaR_ENROL_REQ_AMT}}</td>
                    <td  colspan="1">{{ item.mbU_5TO7_15_17_REQ_CNT}}</td>
                    <td  colspan="1">{{ item.mbU_5TO7_15_17_REQ_AMT}}</td>
                    <td  colspan="1">{{ item.mbU_GRT_7_17_REQ_CNT}}</td>
                    <td  colspan="1">{{ item.mbU_GRT_7_17_REQ_AMT}}</td>
                    <td  colspan="1">{{ item.biO_DEMO_REQ_CNT}}</td>
                    <td  colspan="1">{{ item.biO_DEMO_REQ_AMT}}</td>
                    <td  colspan="1">{{ item.demO_UPDATE_REQ_CNT}}</td>
                    <td  colspan="1">{{ item.demO_UPDATE_REQ_AMT}}</td>
                    <td  colspan="1">{{ item.eaadhaaR_A4_REQ_CNT}}</td>
                    <td  colspan="1">{{ item.eaadhaaR_A4_REQ_AMT}}</td>
                    <td  colspan="1">{{ item.doC_UPDATE_REQ_CNT}}</td>
                    <td  colspan="1">{{ item.doC_UPDATE_REQ_AMT}}</td>
                    <td  colspan="1">{{ item.toT_REQ_CNT}}</td>
                    <td  colspan="1">{{ item.toT_REQ_AMT}}</td>
                    <td  colspan="1">{{ item.chaL_GEN_REQ}}</td>
                    <td  colspan="1">{{ item.chaL_GEN_AMT}}</td>
                    <td  colspan="1">{{ item.chaL_NOTGEN_REQ}}</td>
                    <td  colspan="1">{{ item.chaL_NOTGEN_AMT}}</td>
                    <td  colspan="1">{{ item.chaL_DEP_REQ}}</td>
                    <td  colspan="1">{{ item.chaL_GEN_AMT}}</td>
                </tr>
                <tr *ngIf="totalItems && totalItems?.length > 0">
                    <td></td>
                    <td>{{ totalItems[0].districT_NAME}}</td>
                    <td *ngIf="format === 'District Wise'">{{ totalItems[0].nO_OF_KITS }}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].revenuE_DIV_NAME}}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].ruraL_URBAN_FLAG}}</td>
                    <td *ngIf=" format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
                    <td *ngIf=" format === 'Secretariat Wise'">{{totalItems[0].secretariaT_CODE}}</td>
                    <td  colspan="1">{{ totalItems[0].aadhaR_ENROL_REQ_CNT}}</td>
                    <td  colspan="1">{{ totalItems[0].aadhaR_ENROL_REQ_AMT}}</td>
                    <td  colspan="1">{{ totalItems[0].mbU_5TO7_15_17_REQ_CNT}}</td>
                    <td  colspan="1">{{ totalItems[0].mbU_5TO7_15_17_REQ_AMT}}</td>
                    <td  colspan="1">{{totalItems[0].mbU_GRT_7_17_REQ_CNT}}</td>
                    <td  colspan="1">{{totalItems[0].mbU_GRT_7_17_REQ_AMT}}</td>
                    <td  colspan="1">{{ totalItems[0].biO_DEMO_REQ_CNT}}</td>
                    <td  colspan="1">{{ totalItems[0].biO_DEMO_REQ_AMT}}</td>
                    <td  colspan="1">{{ totalItems[0].demO_UPDATE_REQ_CNT}}</td>
                    <td  colspan="1">{{ totalItems[0].demO_UPDATE_REQ_AMT}}</td>
                    <td  colspan="1">{{ totalItems[0].eaadhaaR_A4_REQ_CNT}}</td>
                    <td  colspan="1">{{ totalItems[0].eaadhaaR_A4_REQ_AMT}}</td>
                    <td  colspan="1">{{ totalItems[0].doC_UPDATE_REQ_CNT}}</td>
                    <td  colspan="1">{{ totalItems[0].doC_UPDATE_REQ_AMT}}</td>
                    <td  colspan="1">{{ totalItems[0].toT_REQ_CNT}}</td>
                    <td  colspan="1">{{ totalItems[0].toT_REQ_AMT}}</td>
                    <td  colspan="1">{{ totalItems[0].chaL_GEN_REQ}}</td>
                    <td  colspan="1">{{ totalItems[0].chaL_GEN_AMT}}</td>
                    <td  colspan="1">{{ totalItems[0].chaL_NOTGEN_REQ}}</td>
                    <td  colspan="1">{{ totalItems[0].chaL_NOTGEN_AMT}}</td>
                    <td  colspan="1">{{ totalItems[0].chaL_DEP_REQ}}</td>
                    <td  colspan="1">{{ totalItems[0].chaL_GEN_AMT}}</td>
                </tr>
            </ng-container>
        </tbody> 
        <ng-container>
            <tbody>
                <tr *ngIf="items?.length == 0">
                    <td [attr.colspan]="noOfCol" class="text-center" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </ng-container>
    </table>
</div>

<div class="mt-4 btnHide" *ngIf="showReports">
    <div class="float-left" *ngIf="items?.length > 0">
        <label>Requests per page: </label>
        <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
            <option  value="10">10</option>
            <option  value="20">20</option>
            <option  value="30">30</option>
            <option  value="40">40</option>
            <option  value="50">50</option>
        </select> 
    </div>
    <ngb-pagination class="d-flex justify-content-end" *ngIf="items?.length > 0" [collectionSize]="items?.length" [(page)]="page" [pageSize]="pageSize"
        [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
    </ngb-pagination>
</div>