<div class="container">
    <h3 class="text-center">ఫీడ్ బ్యాక్ ఫారం</h3>
    <div class="certificateDetails mt-3">
        <div class="certificateInfo">
            <div class="subHeadingCls mb-3">మీరు అందించిన సమాచారం గోప్యంగా ఉంచబడుతుంది మరియు ఇతర వ్యక్తులతో పంచుకోబడదు.</div>
            <div class="row mb-3">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="pl-0 px-0 col-lg-2 col-md-4 col-sm-4 col-xs-4 labelCls">పేరు : </label>
                        <div class="pl-0 px-0 col-lg-4 col-md-8 col-sm-8 col-xs-8 valueCls">{{ userData?.citizeN_NAME }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="pl-0 px-0 col-lg-2 col-md-4 col-sm-4 col-xs-4 labelCls">మొబైల్ నంబర్ : </label>
                        <div class="pl-0 px-0 col-lg-4 col-md-8 col-sm-8 col-xs-8 valueCls">{{ userData?.citizeN_MOBILE }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="pl-0 px-0 col-lg-2 col-md-4 col-sm-4 col-xs-4 labelCls">పొందిన సేవ : </label>
                        <div class="pl-0 px-0 col-lg-4 col-md-8 col-sm-8 col-xs-8 valueCls">{{ userData?.availeD_SERVICES?.join(', ')}}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <form [formGroup]="feedbackForm">
                        <div class="fieldHolder mb-3">
                            <label class="feedback-question">1. పైన తెలిపిన సేవ పొందడంలో మీ అనుభవాన్ని మీరు ఎలా రేటింగ్ చేస్తారు. (1,2,3,4,5) స్టార్ రేటింగ్ 5 అత్యధికంగా ఉంటుంది. <span class="mandatoryCls">*</span></label>
                            <div class="feedback-value">
                                <ngb-rating [max]="5" required formControlName="experienceRating" (rateChange)="ratingChanged()" (hover)="hoverOnrating($event)" (leave)="leaveHover()"></ngb-rating><i class="{{feedbackImage}}"></i>
                            </div>
                            <div *ngIf="submitted && f.experienceRating.errors" class="selectError feedback-value">
                                <div *ngIf="f.experienceRating.errors?.required">దయచేసి రేటింగ్ ఇవ్వగలరు</div>
                            </div>
                        </div>
                        <div class="fieldHolder mb-3">
                            <label class="feedback-question">2. సచివాలయం సిబ్బంది మీకు సేవకి సంబందించిన పూర్తి సమాచారాన్ని అందించారా? <span class="mandatoryCls">*</span></label>
                            <div class="feedback-value" required [ngClass]="{ 'is-invalid':submitted && f.completeInformation.errors }">
                                <input type="radio" name="completeInformation" value="yes"
                                    formControlName="completeInformation"><span class="mx-2 radioCls">అవును</span>
                                <input type="radio" name="completeInformation" value="no"
                                    formControlName="completeInformation"><span class="mx-2 radioCls">కాదు</span>
                            </div>
                            <div *ngIf="submitted && f.completeInformation.errors" class="invalid-feedback feedback-value">
                                <div *ngIf="f.completeInformation.errors.required">
                                    దయచేసి అవును/కాదు ఎంచుకోండి
                                </div>
                            </div>
                        </div>
                        <div class="fieldHolder mb-3">
                            <label class="feedback-question">3. సచివాలయం సిబ్బంది మీతో మర్యాదపూర్వకంగా మెలిగారా? <span class="mandatoryCls">*</span></label>
                            <div class="feedback-value" required [ngClass]="{ 'is-invalid':submitted && f.staffBehaviour.errors }">
                                <input type="radio" name="staffBehaviour" value="yes"
                                    formControlName="staffBehaviour"><span class="mx-2 radioCls">అవును</span>
                                <input type="radio" name="staffBehaviour" value="no"
                                    formControlName="staffBehaviour"><span class="mx-2 radioCls">కాదు</span>
                            </div>
                            <div *ngIf="submitted && f.staffBehaviour.errors" class="invalid-feedback feedback-value">
                                <div *ngIf="f.staffBehaviour.errors.required">
                                    దయచేసి అవును/కాదు ఎంచుకోండి
                                </div>
                            </div>
                        </div>
                        <div class="fieldHolder mb-3">
                            <label class="feedback-question">4. రసీదులో పేర్కొన్న సేవ రుసుముకి మించి అదనంగా డబ్బులు ఏదైనా వసూలు చేయబడిందా? <span class="mandatoryCls">*</span></label>
                            <div class="feedback-value" required [ngClass]="{ 'is-invalid':submitted && f.anyExtraAmountPaid.errors }">
                                <input type="radio" name="anyExtraAmountPaid" value="yes"
                                    formControlName="anyExtraAmountPaid"><span class="mx-2 radioCls">అవును</span>
                                <input type="radio" name="anyExtraAmountPaid" value="no"
                                    formControlName="anyExtraAmountPaid"><span class="mx-2 radioCls">కాదు</span>
                            </div>
                            <div *ngIf="submitted && f.anyExtraAmountPaid.errors" class="invalid-feedback feedback-value">
                                <div *ngIf="f.anyExtraAmountPaid.errors.required">
                                    దయచేసి అవును/కాదు ఎంచుకోండి
                                </div>
                            </div>
                        </div>
                        <div class="fieldHolder mb-3">
                            <label class="feedback-question">5. సేవలు మరింత మెరుగు పరుచుకోవడానికి ఏదైనా సలహాలు / సూచనలు ఉంటే ఇవ్వగలరు.</label>
                            <div class="feedback-value" [ngClass]="{ 'is-invalid':submitted && f.anySuggestions.errors }">
                                <textarea type="text" name="anySuggestions" class="text-areaCls" formControlName="anySuggestions"
                                    [ngClass]="{ 'is-invalid':submitted && f.anySuggestions.errors}" rows="3"></textarea>
                            </div>
                            <div *ngIf="submitted && f.anySuggestions.errors" class="invalid-feedback feedback-value">
                                <div *ngIf="f.anySuggestions.errors.pattern">
                                    ప్రత్యేక అక్షరాలు అనుమతించబడవు
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="btnHolder text-center">
                        <button class="greenBtnCls" (click)="submit()">Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>