import { Component, OnInit} from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService, CustomAdapter, CustomDateParserFormatter, WhitespaceValidator } from '../../shared/common.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgbDateAdapter, NgbDateParserFormatter,NgbDateStruct,NgbModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-viksit-bharat-distribution',
  templateUrl: './viksit-bharat-distribution.component.html',
  styleUrls: ['./viksit-bharat-distribution.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
  ]
})
export class ViksitBharatDistributionComponent implements OnInit {
  commonConstants : any = CommonConstants;
  public submitted: Boolean = false;
  showDetails: Boolean = false;
  isDisabled: any;
  distributionDetails: any = [];
  allowedDates: any = [];
  gpsNames: any =[];
 
  distribution: FormGroup;
  constructor(private formBuilder: FormBuilder, private commonService: CommonService, private datePipe: DatePipe, private router: Router,private modalService: NgbModal) {
    this.distribution = this.formBuilder.group({
      sheduleDate: [null, [Validators.required]],
      mgName:[null, [Validators.required]],
      noOfCalendars: [null, [Validators.required,Validators.pattern(this.commonConstants.zeroAllowedPattern), WhitespaceValidator.whiteSpaceValidate]],
      noOfBooklets: [null, [Validators.required, Validators.pattern(this.commonConstants.zeroAllowedPattern), WhitespaceValidator.whiteSpaceValidate]],
      noOfBrochers: [null, [Validators.required, Validators.pattern(this.commonConstants.zeroAllowedPattern), WhitespaceValidator.whiteSpaceValidate]],
      noOfTShirts: [null, [Validators.required, Validators.pattern(this.commonConstants.zeroAllowedPattern), WhitespaceValidator.whiteSpaceValidate]],
      noOfCaps: [null, [Validators.required, Validators.pattern(this.commonConstants.zeroAllowedPattern), WhitespaceValidator.whiteSpaceValidate]],
      noOfBadges: [null, [Validators.required, Validators.pattern(this.commonConstants.zeroAllowedPattern), WhitespaceValidator.whiteSpaceValidate]]
    });

  }

  ngOnInit(): void {
    this.enableDisableDates();
    this.getDetails();
  }
  get f() {
    return this.distribution.controls;
  }
  goToHome() {
    this.router.navigate(['/home']);
  }
  enableDisableDates() {
    this.allowedDates = [];
    let postData = "Flag=GET DATES";
    this.commonService.getRequest(this.commonConstants.getVBSYScreenDetails + postData).subscribe({
      next: (responseData: any) => {
          responseData?.result?.vbsyScreenDetailsData?.forEach((item:any) => {
            this.allowedDates.push(item.dt);
          });          
          this.isDisabled = (date: NgbDateStruct) => {
            let dt = moment(new Date(date.year,date.month-1, date.day),"DD-MM-YYYY").format("DD/MM/YYYY");
            return this.allowedDates.indexOf(dt) >= 0? false : true;
          };
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  //drop down called on change of date
  getDropDownDetails(event: any) {
    this.gpsNames = [];
    this.f.mgName.patchValue(null);
    let data = "Flag=GET GPS&";
     data += "Date=" + moment(this.f.sheduleDate.value,"DD-MM-YYYY").format("DD/MM/YYYY");
    this.commonService.getRequest(this.commonConstants.getVBSYScreenDetails + data).subscribe({      
      next: (responseData: any) => {
        if(responseData?.result?.isSuccess){
          responseData?.result?.vbsyScreenDetailsData.forEach((item:any) => {
            this.gpsNames.push(item.gP_Name);
          });          
        }
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  getDetails() {
    this.distributionDetails = [];
    let data = "Flag=GET_MANDAL_STAT";
    this.commonService.getRequest(this.commonConstants.getVBSYScreenDetails + data).subscribe({      
      next: (responseData: any) => {
        if(responseData?.result?.isSuccess){
          this.distributionDetails = responseData.result?.vbsyScreenDetailsData;
        }
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  submit():any {    
    this.submitted = true;
    if(!this.distribution.valid) {
      return false;  
    }
    let postData = "Flag=SET_MANDAL_STAT&";
    postData += "BookletDistributedCount=" + this.f.noOfBooklets.value + "&";
    postData += "GPName=" + this.f.mgName.value + "&";
    postData += "CalendarDistributedCount=" + this.f.noOfCalendars.value + "&";    
    postData += "BrochureDistributedCount=" + this.f.noOfBrochers.value + "&";
    postData += "TShirtDistributedCount=" + this.f.noOfTShirts.value + "&";
    postData += "CapsDistributedCount=" + this.f.noOfCaps.value + "&";
    postData += "BadgeDistributedCount=" + this.f.noOfBadges.value + "&";
    postData += "Date=" + moment(this.f.sheduleDate.value,"DD-MM-YYYY").format("DD/MM/YYYY");
 
    this.commonService.getRequest(this.commonConstants.getVBSYScreenDetails + postData).subscribe({
      next: (responseData: any) => {
        if(responseData?.result?.isSuccess) {
          this.commonService.commonErrorPopup('Alert', 'The details are saved successfully.', 'md');
          this.getDetails();
          this.enableDisableDates();  
          this.gpsNames = [];
          this.f.mgName.patchValue(null);
          this.distribution.reset();      
          this.submitted = false; 
        } else if(responseData?.result?.isSuccess == false && responseData?.result?.error) {
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md')
        } else {
          this.commonService.commonErrorPopup('Alert', 'An error occured while saving the details. Please try again.', 'md')
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
}
