<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Revenue Village/Ward:</label>
                <div class="valueCls">{{certificateInfo?.villageName}}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <form [formGroup]="form">
    <div class="subHeadingCls mb-3">Deceased Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder" [ngClass]="{'mb-3': isEditable}">
                <label class="labelCls">Deceased First Name: <span *ngIf="isEditable" class="mandatoryCls">*</span></label>
                 <div class="valueCls" *ngIf="!isEditable">{{certificateInfo.deceasedInfo?.firstName ? (certificateInfo.deceasedInfo?.firstName) : '-' }}</div>
                 <div class="valueCls" *ngIf="isEditable"> 
                    <input type="text" appBlockCopyPaste name="deceasedfirstName" formControlName="deceasedfirstName" class="inputCls" required maxlength="50"
                    [ngClass]="{ 'is-invalid':formSubmitted && f.deceasedfirstName?.errors }">
                    <div *ngIf="formSubmitted && f.deceasedfirstName?.errors" class="invalid-feedback">
                      <div *ngIf="f.deceasedfirstName.errors?.required || f.deceasedfirstName.errors?.whiteSpaceValidate">First Name is required</div>
                      <div *ngIf="f.deceasedfirstName.errors?.pattern">Please enter valid First Name</div>
                    </div>
                 </div>
            </div>
            <div class="fieldHolder" [ngClass]="{'mb-3': isEditable}">
                <label class="labelCls">Deceased Middle Name:</label>
                <div class="valueCls" *ngIf="!isEditable">{{certificateInfo.deceasedInfo?.middleName ? (certificateInfo.deceasedInfo?.middleName) : '-' }}</div>
                <div class="valueCls" *ngIf="isEditable">
                    <input type="text" appBlockCopyPaste name="deceasedmiddleName" formControlName="deceasedmiddleName" class="inputCls"  maxlength="50"
                  [ngClass]="{ 'is-invalid':formSubmitted && f.deceasedmiddleName.errors }">
                  <div *ngIf="formSubmitted && f.deceasedmiddleName?.errors" class="invalid-feedback">
                    <div *ngIf="f.deceasedmiddleName.errors?.required || f.deceasedmiddleName.errors?.whiteSpaceValidate">Midle Name is required</div>
                    <div *ngIf="f.deceasedmiddleName.errors?.pattern ">Please enter valid Middle Name</div>
                  </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder" [ngClass]="{'mb-3': isEditable}">
            <label class="labelCls">Deceased Last Name: <span *ngIf="isEditable" class="mandatoryCls">*</span></label>
            <div class="valueCls" *ngIf="!isEditable">{{certificateInfo.deceasedInfo?.lastName ? (certificateInfo.deceasedInfo?.lastName) : '-' }}</div>
            <div class="valueCls" *ngIf="isEditable">
                <input type="text" nappBlockCopyPaste name="deceasedlastName" formControlName="deceasedlastName" class="inputCls" required maxlength="50"
                  [ngClass]="{ 'is-invalid':formSubmitted && f.deceasedlastName?.errors }">
                  <div *ngIf="formSubmitted && f.deceasedlastName?.errors" class="invalid-feedback">
                    <div *ngIf="f.deceasedlastName.errors?.required || f.deceasedlastName.errors?.whiteSpaceValidate">Last Name is required</div>
                    <div *ngIf="f.deceasedlastName.errors?.pattern">Please enter valid Last Name</div>
                  </div>    
            </div>
        </div>
        <div class="fieldHolder" [ngClass]="{'mb-3': isEditable}">
            <label class="labelCls">Father/Husband Name: <span *ngIf="isEditable" class="mandatoryCls">*</span></label>
            <div class="valueCls" *ngIf="!isEditable">{{certificateInfo.deceasedInfo?.fatherHusbandName ? (certificateInfo.deceasedInfo?.fatherHusbandName) : '-' }}</div>
            <div class="valueCls" *ngIf="isEditable">
                <input type="text"  appBlockCopyPaste class="inputCls" name="fatherrHusbandName" maxlength="50" formControlName = "fatherrHusbandName" [ngClass]="{ 'is-invalid' : formSubmitted && f.fatherrHusbandName.errors }" required>
                  <div *ngIf="formSubmitted && f.fatherrHusbandName?.errors" class="invalid-feedback">
                    <div *ngIf="f.fatherrHusbandName.errors?.required || f.fatherrHusbandName.errors?.whiteSpaceValidate">Father / Husband Name is required</div>
                    <div *ngIf="f.fatherrHusbandName.errors?.pattern">Please enter valid Father / Husband Name</div>
                  </div>    
            </div>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder" [ngClass]="{'mb-3': isEditable}">
                <label class="labelCls">Date of Death: <span *ngIf="isEditable" class="mandatoryCls">*</span></label>
                <div class="valueCls" *ngIf="!isEditable">{{certificateInfo.deceasedDateofDeath | date: 'dd/MM/yyyy'}}</div>
                <div class="valueCls" *ngIf="isEditable">
                    <div class="input-group">
                        <input class="calendarCls form-control" placeholder="dd/mm/yyyy"
                               name="d"  ngbDatepicker #d="ngbDatepicker" [minDate]="minDate" appBlockCopyPaste 
                               [maxDate]="maxDate" required formControlName="dateOfDeath"
                               [ngClass]="{ 'is-invalid':formSubmitted && f.dateOfDeath?.errors }">
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                        </div>
                      </div>
                      <div *ngIf="formSubmitted && f.dateOfDeath?.errors" class="selectError">
                        <div *ngIf="f.dateOfDeath.errors?.required || f.dateOfDeath.invalid">Please enter valid Date of Death</div>
                      </div>
                </div>
            </div>
            <div class="fieldHolder" [ngClass]="{'mb-3': isEditable}">
                <label class="labelCls">Reason for Death: <span *ngIf="isEditable" class="mandatoryCls">*</span></label>
                <div class="valueCls" *ngIf="!isEditable">{{certificateInfo.reasonofDeath ? (certificateInfo.reasonofDeath) : '-' }}</div>
                <div class="valueCls" *ngIf="isEditable">
                    <select class="selectCls" required name="reasonForDeath" formControlName = "reasonForDeath" [ngClass]="{ 'is-invalid':formSubmitted && (f.reasonForDeath?.errors || f.reasonForDeath?.value === 'Select') }">
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let reason of reasonDeath" [ngValue]="reason.name">{{reason.name}}</option>
                      </select>
                      <div *ngIf="formSubmitted && (f.reasonForDeath?.errors || f.reasonForDeath?.value === 'Select')" class="selectError">
                        <div *ngIf="f.reasonForDeath.errors?.required || f.reasonForDeath.value === 'Select'">Please select Reason For Death</div>
                      </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder" [ngClass]="{'mb-3': isEditable}">
            <label class="labelCls">Occupation: <span *ngIf="isEditable" class="mandatoryCls">*</span></label>
            <div class="valueCls" *ngIf="!isEditable">{{certificateInfo.deceasedOccupation ? (certificateInfo.deceasedOccupation) : '-' }}</div>
            <div class="valueCls" *ngIf="isEditable">
                <select class="selectCls" required name="occupation" formControlName = "occupation" [ngClass]="{ 'is-invalid':formSubmitted && (f.occupation?.errors || f.occupation.value === 'Select') }">
                    <option [ngValue]="null" disabled>Select</option>
                    <option *ngFor="let occupation of occupations" [ngValue]="occupation.name">{{occupation.name}}</option>
                  </select>
                  <div *ngIf="formSubmitted && (f.occupation?.errors || f.occupation.value === 'Select')" class="selectError">
                    <div *ngIf="f.occupation.errors?.required || f.occupation.value === 'Select'">Please select Occupation</div>
                  </div>   
            </div>
        </div>
        <div class="fieldHolder" [ngClass]="{'mb-3': isEditable}">
            <label class="labelCls">Deceased Aadhar/Aadhar Enrollment No:</label>
            <div class="valueCls" *ngIf="!isEditable">{{certificateInfo.deceasedAadharNo ? this.commonService.RSADecrypt(certificateInfo.deceasedAadharNo) : '-' }}</div>
            <div class="valueCls" *ngIf="isEditable">
                <input type="text" appBlockCopyPaste name="aadharEnrollmentNo" [ngClass]="{ 'is-invalid':formSubmitted && f.aadharEnrollmentNo.errors }"  class="inputCls" numbersOnly (keyup)="aadharChange()" minlength="12" maxlength="14"  formControlName="aadharEnrollmentNo">
                <div *ngIf="formSubmitted && f.aadharEnrollmentNo.errors" class="invalid-feedback">
                    <div *ngIf="f.aadharEnrollmentNo.errors?.minlength || f.aadharEnrollmentNo.errors?.maxlength">Please enter 14 digit valid Aadhaar Enrolment Number (1234/12345/12345) or Aadhar Number</div>
                    <div *ngIf="f.aadharEnrollmentNo.errors?.aadharValidate">Please enter 12 digit Valid Aadhaar Number</div>
                  </div> 
            </div>
        </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder" [ngClass]="{'mb-3': isEditable}">
                <label class="labelCls">Death Happened Place (Hospital Name / Home): <span *ngIf="isEditable" class="mandatoryCls">*</span></label>
                <div class="valueCls" *ngIf="!isEditable">{{certificateInfo.placeofDeath ? (certificateInfo.placeofDeath) : '-' }}</div>
                <div class="valueCls" *ngIf="isEditable">
                    <input type="text" appBlockCopyPaste class="inputCls" formControlName = "deathPlace" id="deathPlace" maxlength="200"  rows="3" [ngClass]="{ 'is-invalid': formSubmitted && f.deathPlace.errors }" required>
                  <div *ngIf="formSubmitted && f.deathPlace.errors" class="invalid-feedback">
                    <div *ngIf="f.deathPlace.errors.required || f.deathPlace.errors.whiteSpaceValidate">Death Happened Place is required</div>
                    <div *ngIf="f.deathPlace.errors?.pattern">Please enter valid Death place</div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    </form>
    <form [formGroup] ="gridForm">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                <tr> 
                    <th>Name of the Family Member</th>
                    <th>Gender</th>
                    <th>Age</th>
                    <th>Relationship with Deceased</th>
                    <th>Marital Status</th>
                    <th>Identity Proof</th>
                    <th>Identity Number</th>
                    <th *ngIf="isEditable">Action</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of gridData;let i = index;">
                        <td>
                            <ng-container *ngIf="!item.isEditRow">
                                {{item.familyMemberInfo?.personNames[0]?.firstName ? (item.familyMemberInfo?.personNames[0]?.firstName) : '-' }}
                            </ng-container>
                            <ng-container *ngIf="item.isEditRow">
                                <input type="text" appBlockCopyPaste class="form-control" name="tbFamilymembername" id="tbFamilymembername" [ngClass]="{ 'is-invalid':submitted && f1.tbFamilymembername?.errors }" required maxlength="50" formControlName = "tbFamilymembername">
                        <div *ngIf="submitted && f1.tbFamilymembername?.errors" class="invalid-feedback">
                          <div *ngIf="f1.tbFamilymembername?.errors?.required  || f1.tbFamilymembername?.errors?.whiteSpaceValidate">Please enter Family Member Name</div>
                          <div *ngIf="f1.tbFamilymembername?.errors?.pattern">Please enter valid Family Member Name</div>
                      </div>
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="!item.isEditRow">
                                {{item.familyMemberInfo?.gender ? (item.familyMemberInfo?.gender) : '-' }}
                            </ng-container>
                            <ng-container *ngIf="item.isEditRow">
                                <select class="selectCls" name="tbGender" id="tbGender" 
                                [ngClass]="{ 'is-invalid':submitted && ( f1.tbGender?.errors || f1.tbGender?.value === 'Select') }" required formControlName = "tbGender" >
                                    <option [ngValue]="null" disabled>Select</option>
                                    <option value="Female">Female</option>
                                    <option value="Male">Male</option>
                                    <option value="Transgender">Transgender</option>
                                  </select>
                                  <div *ngIf="submitted && (f1.tbGender?.errors || f1.tbGender?.value === 'Select')" class="selectError">
                                    <div *ngIf="f1.tbGender?.errors?.required || f1.tbGender?.value === 'Select'">Please select Gender</div>
                                  </div>
                            </ng-container>

                        </td>
                        <td class="value">
                            <ng-container *ngIf="!item.isEditRow">
                            {{item.age ? (item.age) : '-' }}
                            </ng-container>
                            <ng-container *ngIf="item.isEditRow">
                                <input type="text" appBlockCopyPaste class="form-control value" name="tbAge" id="tbAge" [ngClass]="{ 'is-invalid':submitted && f1.tbAge?.errors }" required formControlName = "tbAge" numbersOnly maxlength="3">
                        <div *ngIf="submitted && f1.tbAge?.errors" class="invalid-feedback">
                          <div *ngIf="f1.tbAge?.errors?.required  || f1.tbAge?.errors?.whiteSpaceValidate">Please enter Age</div>
                          <div *ngIf="f1.tbAge?.errors?.age">Age should not be more than 150 years</div>
                          <div *ngIf="f1.tbAge?.errors?.pattern">Numbers not starting with zero allowed</div>

                      </div>
                            </ng-container>

                        </td>
                        <td>
                            <ng-container *ngIf="!item.isEditRow">
                            {{item.relationwithDeceased ? (item.relationwithDeceased) : '-' }}
                        </ng-container>
                        <ng-container *ngIf="item.isEditRow">
                            <select class="selectCls" name="tbDeceased" id="tbDeceased" 
                            [ngClass]="{ 'is-invalid':submitted && (f1.tbDeceased?.errors || f1.tbDeceased?.value === 'Select') }" required formControlName = "tbDeceased">
                                <option [ngValue]="null" disabled>Select</option>
                                <option *ngFor="let relation of relations" [ngValue]="relation.name">{{relation.name}}</option>
                              </select>
                              <div *ngIf="submitted && (f1.tbDeceased?.errors || f1.tbDeceased?.value === 'Select')" class="selectError">
                                <div *ngIf="f1.tbDeceased?.errors?.required || f1.tbDeceased?.value === 'Select'">Please select Relationship with Deceased</div>
                              </div>
                        </ng-container>

                        </td>
                        <td>
                            <ng-container *ngIf="!item.isEditRow">
                            {{item.familyMemberInfo?.maritalStatus ? (item.familyMemberInfo?.maritalStatus) : '-' }}
                        </ng-container>
                        <ng-container *ngIf="item.isEditRow">
                            <select class="selectCls" name="tbMaritalStatus" id="tbMaritalStatus" 
                            [ngClass]="{ 'is-invalid':submitted && (f1.tbMaritalStatus?.errors || f1.tbMaritalStatus?.value === 'Select') }" required formControlName = "tbMaritalStatus" >
                                <option [ngValue]="null" disabled>Select</option>
                                <option value="Married">Married</option>
                                <option value="UnMarried">UnMarried</option>
                                <option value="Widow">Widow</option>
                              </select>
                              <div *ngIf="submitted && (f1.tbMaritalStatus?.errors || f1.tbMaritalStatus?.value === 'Select')" class="selectError">
                                <div *ngIf="f1.tbMaritalStatus?.errors?.required || f1.tbMaritalStatus?.value === 'Select'">Please select Marital Status</div>
                              </div>
                        </ng-container>

                        </td>
                        <td>
                            <ng-container *ngIf="!item.isEditRow">
                            {{item.identityProof ? (item.identityProof) : '-' }}
                        </ng-container>
                        <ng-container *ngIf="item.isEditRow">
                            <select class="selectCls" name="tbIdProof" id="tbIdProof" (change)="idproofselection()" [ngClass]="{ 'is-invalid':submitted && (f1.tbIdProof?.errors || f.tbIdProof?.value === 'Select') }" required formControlName = "tbIdProof"  >
                                <option [ngValue]="null" disabled>Select</option>
                                <option value="Aadhaar No">Aadhaar No</option>
                                <option value="Aadhar Enrollment No">Aadhar Enrollment No</option>
                                <option value="EPIC ID/ Voter ID">EPIC ID/ Voter ID</option>
                                <option value="Passport ID">Passport ID</option>
                              </select>
                              <div *ngIf="submitted && (f1.tbIdProof?.errors || f1.tbIdProof?.value === 'Select')" class="selectError">
                                <div *ngIf="f1.tbIdProof?.errors?.required || f1.tbIdProof?.value === 'Select'">Please select Valid ID Proof</div>
                              </div>
                        </ng-container>

                        </td>
                        <td class="value">
                            <ng-container *ngIf="!item.isEditRow">
                            {{item.identityNumber ? this.commonService.RSADecrypt(item.identityNumber) : '-' }}
                        </ng-container>
                        <ng-container *ngIf="item.isEditRow">
                            <input type="text" appBlockCopyPaste class="form-control value" name="tbIdNumber" id="tbIdNumber" 
                            [ngClass]="{ 'is-invalid':submitted && f1.tbIdNumber?.errors }" required formControlName = "tbIdNumber"  minlength="{{idMinLength}}" maxlength="{{idMaxLength}}" >
                            <div *ngIf="submitted && f1.tbIdNumber?.errors" class="invalid-feedback">
                              <div *ngIf="f1.tbIdNumber?.errors?.required  || f1.tbIdNumber?.errors?.whiteSpaceValidate">Identity Number is required</div>
                              <div *ngIf="f1.tbIdNumber?.errors?.pattern">please enter valid {{f1.tbIdProof?.value}}</div>
                              <div *ngIf="f1.tbIdProof?.value === 'Aadhaar No' && f1.tbIdNumber?.errors?.aadharValidate"> Please enter valid Aadhar Number</div>
                            </div>
                        </ng-container>
                        </td>
                        <td *ngIf="isEditable" class="linkCls">
                            <span *ngIf="!item.isEditRow"><span><a (click) ="editRow(item)">Edit</a></span><span><a (click) ="deleteRow(item, i)">Delete</a></span></span>
                            <span *ngIf="item.isEditRow"><span><a (click) ="updateRow(item)">Update</a></span> <span><a (click) ="cancelEdit(item, i)">Cancel</a></span></span>
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
    </div>
    
    <div class="text-center mb-3 mt-3" *ngIf="isEditable">
      <button name="addnewrow" class="btn btn-primary mr-3 add-new" (click)="addRow()"><i class="fa fa-plus"></i>Add New Row</button>
      <!-- <button name="deleterow" class="btn btn-primary mr-3"  (click)="deleteTableDataRow()"><i class="fa fa-trash"></i>Delete Row</button> -->
  </div>
</form>
</div>
<div class="certificateInfo" *ngIf="role == 'Digital Assistant' && certificateInfo.certificateDetails != null">
    <div class="row">
        <ng-container *ngIf="certificateInfo && certificateInfo.length !==0">

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" >
            <div class="subHeadingCls mb-3">Updated Deceased Details</div>
                <div class="fieldHolder">
                    <label class="labelCls">Deceased First Name:</label>
                    <div class="valueCls">{{certificateInfo?.certificateDetails?.deceasedFirstName != certificateInfo?.deceasedInfo?.firstName ? (certificateInfo?.certificateDetails?.deceasedFirstName) : '-' }}</div>
                </div>
            
            
                <div class="fieldHolder">
                    <label class="labelCls">Deceased MiddleName:</label>
                    <div class="valueCls">{{certificateInfo.certificateDetails.deceasedMiddleName != certificateInfo?.deceasedInfo?.middleName ? (certificateInfo.certificateDetails.deceasedMiddleName) : '-' }}</div>
                </div>
       
           
                <div class="fieldHolder">
                    <label class="labelCls">Deceased Last Name:</label>
                    <div class="valueCls">{{certificateInfo.certificateDetails.deceasedLastName != certificateInfo?.deceasedInfo?.lastName ? (certificateInfo.certificateDetails.deceasedLastName) : '-' }}</div>
                </div>
         
            
                <div class="fieldHolder">
                    <label class="labelCls">Date of Death:</label>
                    <div class="valueCls">{{certificateInfo.certificateDetails.deceasedDateofDeath != certificateInfo?.deceasedDateofDeath ? (certificateInfo.certificateDetails.deceasedDateofDeath | date: 'dd/MM/yyyy' ) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Father/Husband name:</label>
                    <div class="valueCls">{{certificateInfo.certificateDetails.deceasedFatherHusbandName != certificateInfo?.deceasedInfo?.fatherHusbandName ? (certificateInfo.certificateDetails.deceasedFatherHusbandName) : '-' }}</div>
                </div>
           
         
        </div>
       
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-5" >

       
            <div class="fieldHolder">
                <label class="labelCls">Reason of Death:</label>
                <div class="valueCls">{{certificateInfo.certificateDetails.reasonofDeath != certificateInfo.reasonofDeath ? (certificateInfo.certificateDetails.reasonofDeath) : '-' }}</div>
            </div>
     
      
            <div class="fieldHolder">
                <label class="labelCls">Occupation:</label>
                <div class="valueCls">{{certificateInfo.certificateDetails.deceasedOccupation != certificateInfo.deceasedOccupation ? (certificateInfo.certificateDetails.deceasedOccupation) : '-' }}</div>
            </div>
        
      
            <div class="fieldHolder">
                <label class="labelCls">Deceased AadharNo:</label>
                <div class="valueCls">{{this.commonService.RSADecrypt(certificateInfo.certificateDetails.deceasedAadharNo) != this.commonService.RSADecrypt(certificateInfo.deceasedAadharNo) ? this.commonService.RSADecrypt(certificateInfo.certificateDetails.deceasedAadharNo) : '-' }}</div>
            </div>
      
            <div class="fieldHolder">
                <label class="labelCls">Death Happened Place (Hospital Name / Home):</label>
                <div class="valueCls">{{certificateInfo.certificateDetails.placeofDeath != certificateInfo.placeofDeath ? (certificateInfo.certificateDetails.placeofDeath) : '-' }}</div>
            </div>
      
        </div>
    </ng-container>
    </div>
</div>
<div class="certificateInfo" *ngIf="role == 'Digital Assistant' && certificateInfo.certificateDetails != null && updatedData > 0">
<div class="subHeadingCls mb-3">Updated Family Details</div>
<div class="row">
    <ng-container>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <table class="tableCls mt-2">
            <thead>
            <tr> 
                <th>Name of family member</th>
                <th>Age</th>
                <th>Gender</th>
                <th>Marital Status</th>
                <th>Relationship with deceased</th>
                <th>Identity Proof</th>
                <th>Identity Number</th>
            </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of certificateInfo?.updatedFamilyDetails">
                    <td>{{item.familyMemberInfo?.personNames[0].firstName ? (item.familyMemberInfo?.personNames[0].firstName) : '-' }}</td>
                    <td class="value">{{item.age ? (item.age) : '-' }}</td>
                    <td>{{item.familyMemberInfo?.gender ? (item.familyMemberInfo?.gender) : '-' }}</td>
                    <td>{{item.familyMemberInfo?.maritalStatus ? (item.familyMemberInfo?.maritalStatus) : '-' }}</td>
                    <td>{{item.relationwithDeceased ? (item.relationwithDeceased) : '-' }}</td>
                    <td>{{item.identityProof ? (item.identityProof) : '-' }}</td>
                    <td>{{item.identityNumber ? this.commonService.RSADecrypt(item.identityNumber) : '-' }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</ng-container>
</div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Affidavit Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">A notarized affidavit containing Name,Age and Relationship with deceased:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Govt ID proof Doc Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Document(Ration Card/Voter Id/Passport/Passbook,Aadhaar etc.) indication the relationship of the applicant with the deceased:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Death Certificate Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Death Certificate/FIR:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>

