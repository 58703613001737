<div class="certificateInfo ">
    <div class="subHeadingCls mb-3">Sadarem Details
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Sadarem Id:</label>
                <div class="valueCls">{{certificateInfo.sadaremId}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Sadarem Code:</label>
                <div class="valueCls">{{certificateInfo.sadaremcode}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">AadharNumber:</label>
                <div class="valueCls">{{certificateInfo.aadharNumber}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Address:</label>
                <div class="valueCls">{{certificateInfo.address}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Date Of Birth:</label>
                <div class="valueCls">{{certificateInfo.dateOfBirth}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Date Of Issue:</label>
                <div class="valueCls">{{certificateInfo.dateOfIssue}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Person Name</label>
                <div class="valueCls">{{certificateInfo.personname}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Status</label>
                <div class="valueCls">{{certificateInfo.status}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Type Of Disability</label>
                <div class="valueCls">{{certificateInfo.typeofdisability}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Gender</label>
                <div class="valueCls">{{certificateInfo.gender}}</div>
            </div>          
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Percentage</label>
                <div class="valueCls">{{certificateInfo.percentage}}</div>
            </div>          
        </div>
    </div>
</div>