<div class="mainPageContainer mr-3 mt-5">
    <div class="btnHolder">
        <button
        class="greenBtnCls homebtn mr-3 my-3"
        *ngIf="
            loggedinuserName === 'Tahsildar' ||
            loggedinuserName === 'Deputy Tahsildar' ||
            loggedinuserName === 'Revenue Divisonal Officer' ||
            loggedinuserName === 'Joint Collector' ||
            loggedinuserName === 'Collector' ||
            loggedinuserName === 'Village Revenue Officer' ||
            loggedinuserName === 'Revenue Inspector' ||
            loggedinuserName === 'Mandal Parishad Development Officers' ||
            loggedinuserName === 'Municipal Commissioner' ||
            loggedinuserName === 'Joint Collector Development' ||
            loggedinuserName === 'Ward Revenue Secretary' ||
            loggedinuserName === 'Welfare & Educational Assistant' ||
            loggedinuserName === 'Ward Welfare & Development Secretary' ||
            loggedinuserName === 'ExciseDC' ||
            loggedinuserName === 'HandloomDC' ||
            loggedinuserName === 'HandloomDO' ||
            loggedinuserName === 'SCWELDC' ||
            loggedinuserName === 'DMO' ||
            loggedinuserName === 'FisheryDC' ||
            loggedinuserName === 'Ward Education Secretary' ||
            loggedinuserName === 'Panchayat Secretary DDO' ||
            loggedinuserName === 'Panchayat Secretary' ||
            loggedinuserName === 'CEO, WAQF board' ||
            loggedinuserName === 'Ward Admin Secretary' ||
            loggedinuserName === 'Superintendent' ||
            loggedinuserName === 'Assistant Secretary' ||
            loggedinuserName === 'Village surveyor' ||
            loggedinuserName === 'Mandal surveyor' ||
            loggedinuserName === 'Assistant Civil Supplies Officer' ||
            loggedinuserName === 'District Civil Supplies Officer' ||
            loggedinuserName === 'Assistant Social Welfare Officer' ||
            loggedinuserName === 'Fisheries Development Officer'||
            loggedinuserName === 'Zilla Sainik Welfare Officer'
            "
            (click)="goToHome()">Home
        </button>
    </div>

    <h6 class="mb-3"
        *ngIf="
        (loggedinuserName === 'Joint Collector' ||
            loggedinuserName === 'Collector' ||
            loggedinuserName === 'Tahsildar' ||
            loggedinuserName === 'Mandal surveyor' ||
            loggedinuserName === 'Mandal Parishad Development Officers' ||
            loggedinuserName === 'Municipal Commissioner' ||
            loggedinuserName === 'CEO, WAQF board') &&
        flag === 'SELF'">Add/Edit
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Joint Collector' && flag === 'WORKFLOW'">
        MPDO/Tahsildar/DT/RDO/MC/Excise DC/Handloom DC/SCWELDC/DMO/Fishery DC/Handloom DO
    </h6>
    <!-- <h6 class="mb-3" *ngIf="loggedinuserName==='Collector'">Edit JCREV /Municipal Commissioner/JCDEV
        </h6> -->
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Revenue Divisonal Officer'">
        Add/Edit RDO
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'District Civil Supplies Officer'">
        Add/Edit District Civil Supplies Officer
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Deputy Tahsildar'">
        Add/Edit Deputy Tahsildar
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Tahsildar' && flag === 'WORKFLOW'">
        VRO/RI
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Village Revenue Officer'">
        Add/Edit Village Revenue Officer
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Village surveyor'">
        Add/Edit Village surveyor
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Panchayat Secretary'">
        Add/Edit Panchayat Secretary
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Assistant Secretary'">
        Add/Edit Assistant Secretary
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Digital Assistant'">
        Add/Edit Digital Assistant
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Zilla Sainik Welfare Officer'">
        Add/Edit Zilla Sainik Welfare Officer
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Ward Education Secretary'">
        Add/Edit Ward Education Secretary
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Revenue Inspector'">
        Add/Edit Revenue Inspector
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Assistant Civil Supplies Officer'">
        Add/Edit Assistant Civil Supplies Officer
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Mandal surveyor' && flag === 'WORKFLOW'">
        Add/Edit Village Surveyor
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Mandal Parishad Development Officers' && flag === 'WORKFLOW'">
        Panchayat Secretary/WEA/DA
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Municipal Commissioner' && flag === 'WORKFLOW'">
        WWDS/Ward Admin Secretary
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Joint Collector Development'">
        Add JCDEV/Edit MPDO /JCDEV
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Ward Revenue Secretary'">
        Add/Edit WRS
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Welfare & Educational Assistant'">
        Add/Edit WEA
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Ward Welfare & Development Secretary'">
        Add/Edit WWDS
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'ExciseDC'">
        Add/Edit Excise DC
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'HandloomDC'">
        Add/Edit Handloom DC
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'HandloomDO'">
        Add/Edit Handloom DO
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'SCWELDC'">
        Add/Edit SCWELDC
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'DMO'">
        Add/Edit DMO
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'FisheryDC'">
        Add/Edit Fishery DC
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Panchayat Secretary DDO'">
        Add/Edit Panchayat Secretary
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'CEO, WAQF board' && flag === 'WORKFLOW'">
        Superintendent/Assistant Secretary/ CEO, WAQF board
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Ward Admin Secretary'">
        Add/Edit Ward Admin Secretary
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Superintendent'">
        Add/Edit Superintendent
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Assistant Social Welfare Officer'">
        Add/Edit Assistant Social Welfare Officer
    </h6>
    <h6 class="mb-3" *ngIf="loggedinuserName === 'Fisheries Development Officer'">
        Add/Edit Fisheries Development Officer
    </h6>

    <form [formGroup]="form">
        <div class="row mb-3">
            <!-- <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf="loggedinuserName !=='Revenue Divisonal Officer' && loggedinuserName !=='Deputy Tahsildar' && loggedinuserName !=='Mandal Parishad Development Officers'&& loggedinuserName !=='Municipal Commissioner' && loggedinuserName !=='Welfare & Educational Assistant'
                    && loggedinuserName !=='Village Revenue Officer' && loggedinuserName !=='Digital Assistant'   && loggedinuserName !=='Revenue Inspector'  && loggedinuserName !=='Collector' && loggedinuserName !=='Joint Collector' && loggedinuserName !=='Tahsildar'">
                    <label class="labelCls">Designation:</label>
                    <select class="selectCls" formControlName="designation" required (change)="designationChange($event)"
                        [ngClass]="{ 'is-invalid':submitted && (f.designation.errors || f.designation.value === 'Select')}">
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngIf="loggedinuserName === 'Deputy Tahsildar'" value="Deputy Tahsildar">Deputy Tahsildar
                        </option>
                        <option *ngIf="loggedinuserName === 'Joint Collector'" value="Joint Collector">Joint Collector
                        </option>
                        <option *ngIf="loggedinuserName === 'Tahsildar'" value="Tahsildar">Tahsildar
                        </option>
                        
                    </select>
                    <div *ngIf="submitted && (f.designation.errors || f.designation.value === 'Select')"
                        class="selectError">
                        <div *ngIf="f.designation.errors?.required || f.designation.value === 'Select'">
                            Please
                            select designation</div>
                    </div>
                <option *ngIf="loggedinuserName === 'Tahsildar'" value="Village Revenue Officer">Village Revenue
                            Officer
                        </option>
                        <option *ngIf="loggedinuserName === 'Tahsildar'" value="Revenue Inspector">Revenue Inspector
                        </option> -->

            <!-- <option *ngIf="loggedinuserName === 'Joint Collector'" value="Deputy Tahsildar">Deputy Tahsildar
                        </option>
                        <option
                            *ngIf="loggedinuserName === 'Joint Collector' || loggedinuserName === 'Revenue Divisonal Officer'"
                            value="Sub Collector">RDO/Sub Collector
                        </option>
                </div> -->
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12"
                *ngIf="
                    (loggedinuserName === 'Joint Collector' ||
                    loggedinuserName === 'Collector' ||
                    loggedinuserName === 'Joint Collector Development' ||
                    loggedinuserName === 'Tahsildar' ||
                    loggedinuserName === 'Mandal surveyor' ||
                    loggedinuserName === 'Mandal Parishad Development Officers' ||
                    loggedinuserName === 'Municipal Commissioner' ||
                    loggedinuserName === 'CEO, WAQF board') &&
                    flag === 'WORKFLOW'">
                <div *ngIf="flag === 'WORKFLOW'" class="subHeadingCls mb-3">
                    Profile Approval Requests
                </div>
                <label class="labelCls">Profile Update Status:</label>
                <select class="selectCls" (change)="getStatus($event)" formControlName="signstatus">
                    <option [ngValue]="null" disabled>Select</option>
                    <option value="All">All</option>
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                </select>
            </div>
            <!-- <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf="loggedinuserName !=='Revenue Divisonal Officer'  && f.designation.value !=='Tahsildar'  && loggedinuserName !=='Deputy Tahsildar'  && loggedinuserName !=='Mandal Parishad Development Officers'&& loggedinuserName !=='Municipal Commissioner' && loggedinuserName !=='Welfare & Educational Assistant'
                    && loggedinuserName !=='Village Revenue Officer' && loggedinuserName !=='Digital Assistant'   && loggedinuserName !=='Revenue Inspector'">
                    <label class="labelCls">Division:</label>
                    <select class="selectCls" formControlName="division" required
                        [ngClass]="{ 'is-invalid':submitted && (f.division.errors || f.division.value === 'Select')}"
                        (change)="onDivisionChange()">
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let division of revenueDivision" [ngValue]="division">
                            {{division.name}}</option>
                    </select>
                    <div *ngIf="submitted && (f.division.errors || f.division.value === 'Select')" class="selectError">
                        <div *ngIf="f.division.errors?.required || f.division.value === 'Select'">
                            Please
                            select Division</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf="loggedinuserName !=='Revenue Divisonal Officer' && f.designation.value !=='Tahsildar' && loggedinuserName !=='Deputy Tahsildar'  && loggedinuserName !=='Mandal Parishad Development Officers'&& loggedinuserName !=='Municipal Commissioner'&& loggedinuserName !=='Welfare & Educational Assistant'
                    && loggedinuserName !=='Village Revenue Officer' && loggedinuserName !=='Digital Assistant'   && loggedinuserName !=='Revenue Inspector'">
                    <label class="labelCls">Mandal:</label>
                    <select class="selectCls" formControlName="mandal" required
                        [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select')}"
                        (change)="onMandalChange($event)">
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let mandal of revenueMandals" [ngValue]="mandal">
                            {{mandal.name}}</option>
                    </select>
                    <div *ngIf="submitted && (f.mandal.errors || f.mandal.value === 'Select')" class="selectError">
                        <div *ngIf="f.mandal.errors?.required || f.mandal.value === 'Select'">
                            Please
                            select Mandal</div>
                    </div>
                </div> -->
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12 mt-4">
                <label class="labelCls"><span class="mandatoryCls"></span></label>
                <button class="btn btn-primary btn" 
                    *ngIf="
                        (loggedinuserName === 'Tahsildar' ||
                        loggedinuserName === 'Mandal surveyor' ||
                        loggedinuserName === 'Deputy Tahsildar' ||
                        loggedinuserName === 'Revenue Divisonal Officer' ||
                        loggedinuserName === 'District Civil Supplies Officer' ||
                        loggedinuserName === 'Joint Collector' || loggedinuserName === 'Zilla Sainik Welfare Officer' ||
                        loggedinuserName === 'Village Revenue Officer' ||
                        loggedinuserName === 'Village surveyor' ||
                        loggedinuserName === 'Digital Assistant' ||
                        loggedinuserName === 'Ward Education Secretary' ||
                        loggedinuserName === 'Revenue Inspector' ||
                        loggedinuserName === 'Assistant Civil Supplies Officer' ||
                        loggedinuserName === 'Municipal Commissioner' ||
                        loggedinuserName === 'Joint Collector Development' ||
                        loggedinuserName === 'Welfare & Educational Assistant' ||
                        loggedinuserName === 'Ward Welfare & Development Secretary' ||
                        loggedinuserName === 'Ward Revenue Secretary' ||
                        loggedinuserName === 'ExciseDC' ||
                        loggedinuserName === 'HandloomDC' ||
                        loggedinuserName === 'HandloomDO' ||
                        loggedinuserName === 'SCWELDC' ||
                        loggedinuserName === 'DMO' ||
                        loggedinuserName === 'FisheryDC' ||
                        loggedinuserName === 'Panchayat Secretary DDO' ||
                        loggedinuserName === 'Ward Admin Secretary' ||
                        loggedinuserName === 'Superintendent' ||
                        loggedinuserName === 'Panchayat Secretary' ||
                        loggedinuserName === 'Assistant Social Welfare Officer' ||
                        loggedinuserName === 'Fisheries Development Officer'||
                        loggedinuserName === 'Assistant Secretary' ||
                        loggedinuserName === 'Collector') &&
                        array.length === 0"
                    (click)="openModal(addModal)">Add Profile
                </button>
                <button class="btn btn-primary btn"
                    *ngIf="
                        (loggedinuserName === 'Tahsildar' ||
                        loggedinuserName === 'Mandal surveyor' ||
                        loggedinuserName === 'Joint Collector' ||
                        loggedinuserName === 'Municipal Commissioner' ||
                        loggedinuserName === 'CEO, WAQF board' || loggedinuserName === 'Zilla Sainik Welfare Officer') &&
                        showaddsignature && array.length !== 0 && flag !== 'WORKFLOW'"
                    (click)="openModal(addModal)">Add Profile
                </button>
                <button class="btn btn-primary btn"
                    *ngIf="
                        loggedinuserName === 'Mandal Parishad Development Officers' && array.length === 0 && flag !== 'WORKFLOW'"
                        (click)="openModal(addModal)">Add Profile
                </button>
                <button class="btn btn-primary btn"
                    *ngIf="
                        (loggedinuserName === 'Joint Collector Development' ||
                        loggedinuserName === 'Mandal Parishad Development Officers')
                        && showaddsignature && array.length !== 0"
                    (click)="openModal(addModal)">Add Profile
                </button>
                <button class="btn btn-primary btn"
                    *ngIf="loggedinuserName === 'Collector' && designation !== 'Collector' && array.length === 0"
                    (click)="openModal(addModal)">Add Profile
                </button>

                <!-- <button class="btn btn-primary btn"
                        *ngIf="f.designation.value !=='Joint Collector' && f.designation.value !=='Tahsildar' && loggedinuserName !=='Revenue Divisonal Officer' && loggedinuserName !=='Deputy Tahsildar'
                        && loggedinuserName !=='Village Revenue Officer' && loggedinuserName !=='Digital Assistant'   && loggedinuserName !=='Revenue Inspector' && loggedinuserName !=='Mandal Parishad Development Officers'&& loggedinuserName !=='Municipal Commissioner'&& loggedinuserName !=='Welfare & Educational Assistant'"
                        (click)="filter()">Get Details
                    </button> -->
            </div>
        </div>

        <div *ngIf="showtable">
            <div class="subHeadingCls mb-3" *ngIf="flag === 'SELF'">My Profile</div>
            <!-- <hr class="hrCls mt-4"> -->
            <!-- <h6 class="mb-3" *ngIf="f.designation.value ==='Tahsildar'">All Tahsildar'S Details in ANANTHAPUR District
                    </h6>
                    <h6 class="mb-3" *ngIf="f.designation.value ==='Deputy Tahsildar'">All Deputy Tahsildar's Details in
                        ANANTHAPUR District</h6>
                    <h6 class="mb-3" *ngIf="f.designation.value ==='MPDO'">All MPDO Details in
                        ANANTHAPUR District</h6>
                    <h6 class="mb-3" *ngIf="f.designation.value ==='Municipal Commissioner'">All Municipal Commissioner Details
                        in
                        ANANTHAPUR District</h6>
                    <h6 class="mb-3" *ngIf="f.designation.value ==='Sub Collector'">All Sub Collector Details
                        in
                        ANANTHAPUR District</h6>
                    <h6 class="mb-3" *ngIf="f.designation.value ==='Joint Collector'">All Joint Collector Details
                        in
                        ANANTHAPUR District</h6> -->
            <!-- <div class="row">
                    <div class="col-d-8">

                    </div>
                    <div class="col-md-4">
                        <input type="text" placeholder="Search" class="searchInput float-right">
                    </div>
                </div> -->
            <div class="row mb-3">
                <div class="col">
                    <div class="tab overflowscroll">
                        <table class="tableCls">
                            <thead>
                                <tr>
                                    <!-- <th *ngIf="loggedinuserName === 'Joint Collector'"></th> -->
                                    <th>S NO</th>
                                    <!-- <th *ngIf="f.designation.value ==='Tahsildar' || f.designation.value ==='Deputy Tahsildar' || f.designation.value ==='MPDO'
                                                        || f.designation.value ==='Municipal Commissioner'">Mandal Name</th>
                                                        <th *ngIf="loggedinuserName ==='Revenue Divisonal Officer'">
                                                            Division</th> -->
                                    <th>Name</th>
                                    <th
                                        *ngIf="
                                        loggedinuserName === 'ExciseDC' ||
                                        loggedinuserName === 'HandloomDC' ||
                                        loggedinuserName === 'HandloomDO' ||
                                        loggedinuserName === 'SCWELDC' ||
                                        loggedinuserName === 'DMO' ||
                                        loggedinuserName === 'FisheryDC' ||
                                        loggedinuserName === 'Digital Assistant' ||
                                        loggedinuserName === 'Ward Education Secretary' ||
                                        loggedinuserName === 'Collector' ||
                                        loggedinuserName === 'Deputy Tahsildar' ||
                                        loggedinuserName === 'Tahsildar' ||
                                        loggedinuserName === 'Joint Collector' ||
                                        loggedinuserName === 'Revenue Inspector' ||
                                        loggedinuserName === 'Mandal surveyor' ||
                                        loggedinuserName === 'Assistant Civil Supplies Officer' ||
                                        loggedinuserName === 'Village Revenue Officer' ||
                                        loggedinuserName === 'Village surveyor' ||
                                        loggedinuserName === 'Ward Revenue Secretary' ||
                                        loggedinuserName === 'Welfare & Educational Assistant' ||
                                        loggedinuserName === 'Ward Welfare & Development Secretary' ||
                                        loggedinuserName === 'Mandal Parishad Development Officers' ||
                                        loggedinuserName === 'Municipal Commissioner' ||
                                        loggedinuserName === 'Revenue Divisonal Officer' ||
                                        loggedinuserName === 'District Civil Supplies Officer' ||
                                        loggedinuserName === 'Sub Collector' ||
                                        loggedinuserName === 'Zilla Sainik Welfare Officer' ||
                                        loggedinuserName === 'Assistant Social Welfare Officer' ||
                                        loggedinuserName === 'Fisheries Development Officer'||
                                        loggedinuserName === 'Panchayat Secretary DDO'">District Name
                                    </th>
                                    <th
                                        *ngIf="
                                        loggedinuserName === 'Deputy Tahsildar' ||
                                        loggedinuserName === 'Tahsildar' ||
                                        loggedinuserName === 'Joint Collector' ||
                                        loggedinuserName === 'Digital Assistant' ||
                                        loggedinuserName === 'Ward Education Secretary' ||
                                        loggedinuserName === 'Revenue Inspector' ||
                                        loggedinuserName === 'Mandal surveyor' ||
                                        loggedinuserName === 'Assistant Civil Supplies Officer' ||
                                        loggedinuserName === 'Village Revenue Officer' ||
                                        loggedinuserName === 'Village surveyor' ||
                                        loggedinuserName === 'Ward Revenue Secretary' ||
                                        loggedinuserName === 'Welfare & Educational Assistant' ||
                                        loggedinuserName === 'Ward Welfare & Development Secretary' ||
                                        loggedinuserName === 'Mandal Parishad Development Officers' ||
                                        loggedinuserName === 'Municipal Commissioner' ||
                                        loggedinuserName === 'Assistant Social Welfare Officer' ||
                                        loggedinuserName === 'Fisheries Development Officer'||
                                        loggedinuserName === 'Panchayat Secretary DDO'">Mandal Name
                                    </th>
                                    <th
                                        *ngIf="
                                        loggedinuserName === 'Revenue Divisonal Officer' ||
                                        loggedinuserName === 'Sub Collector' ||
                                        loggedinuserName === 'Joint Collector'">Division Name
                                    </th>
                                    <th
                                        *ngIf="
                                        loggedinuserName === 'Village Revenue Officer' ||
                                        loggedinuserName === 'Village surveyor' ||
                                        loggedinuserName === 'Digital Assistant' ||
                                        loggedinuserName === 'Ward Education Secretary' ||
                                        loggedinuserName === 'Ward Revenue Secretary' ||
                                        loggedinuserName === 'Welfare & Educational Assistant' ||
                                        loggedinuserName === 'Ward Welfare & Development Secretary' ||
                                        loggedinuserName === 'Tahsildar' ||
                                        loggedinuserName === 'Mandal Parishad Development Officers' ||
                                        loggedinuserName === 'Municipal Commissioner' ||
                                        loggedinuserName === 'Assistant Social Welfare Officer' ||
                                        loggedinuserName === 'Fisheries Development Officer'||
                                        loggedinuserName === 'Panchayat Secretary DDO'">Secretariat Name
                                    </th>
                                    <th
                                        *ngIf="
                                        loggedinuserName === 'Mandal Parishad Development Officers' ||
                                        loggedinuserName === 'Panchayat Secretary DDO'">Panchayat Name
                                    </th>
                                    <th>Designation</th>
                                    <th>UID</th>
                                    <th>Mobile Number</th>
                                    <th
                                        *ngIf="
                                        loggedinuserName === 'Ward Education Secretary' ||
                                        loggedinuserName == 'Digital Assistant' ||
                                        loggedinuserName === 'Mandal Parishad Development Officers' ||   
                                        loggedinuserName === 'Municipal Commissioner'">Email Id
                                    </th>
                                    <th
                                        *ngIf="
                                        loggedinuserName !== 'Village Revenue Officer' &&
                                        loggedinuserName !== 'Assistant Social Welfare Officer' &&
                                        loggedinuserName !== 'Fisheries Development Officer' &&
                                        loggedinuserName !== 'Village surveyor' &&
                                        loggedinuserName !== 'Panchayat Secretary' &&
                                        loggedinuserName !== 'Assistant Secretary' &&
                                        loggedinuserName !== 'Digital Assistant' &&
                                        loggedinuserName !== 'Ward Education Secretary' &&
                                        loggedinuserName !== 'Revenue Inspector' &&
                                        loggedinuserName !== 'Mandal surveyor' &&
                                        loggedinuserName !== 'Assistant Civil Supplies Officer' &&
                                        loggedinuserName !== 'ExciseDC' &&
                                        loggedinuserName !== 'HandloomDC' &&
                                        loggedinuserName !== 'HandloomDO' &&
                                        loggedinuserName !== 'SCWELDC' &&
                                        loggedinuserName !== 'DMO' &&
                                        loggedinuserName !== 'FisheryDC' &&
                                        loggedinuserName !== 'Ward Admin Secretary' &&
                                        loggedinuserName !== 'Superintendent' && loggedinuserName !== 'Zilla Sainik Welfare Officer'">Digital Signature Serial Number
                                    </th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    <!-- <th>Last Updated Date</th> -->
                                    <th
                                        *ngIf="
                                        loggedinuserName !== 'Village Revenue Officer' &&
                                        loggedinuserName !== 'Assistant Social Welfare Officer' &&
                                        loggedinuserName !== 'Fisheries Development Officer' &&
                                        loggedinuserName !== 'Village surveyor' &&
                                        loggedinuserName !== 'Panchayat Secretary' &&
                                        loggedinuserName !== 'Assistant Secretary' &&
                                        loggedinuserName !== 'Digital Assistant' &&
                                        loggedinuserName !== 'Ward Education Secretary' &&
                                        loggedinuserName !== 'Revenue Inspector' &&
                                        loggedinuserName !== 'Mandal surveyor' &&
                                        loggedinuserName !== 'Assistant Civil Supplies Officer' &&
                                        loggedinuserName !== 'ExciseDC' &&
                                        loggedinuserName !== 'HandloomDC' &&
                                        loggedinuserName !== 'HandloomDO' &&
                                        loggedinuserName !== 'SCWELDC' &&
                                        loggedinuserName !== 'DMO' &&
                                        loggedinuserName !== 'FisheryDC' &&
                                        loggedinuserName !== 'Ward Admin Secretary' &&
                                        loggedinuserName !== 'Superintendent'">Fascimile Signature
                                    </th>
                                    <th
                                        *ngIf="
                                        loggedinuserName == 'Mandal Parishad Development Officers' ||
                                        loggedinuserName == 'Municipal Commissioner' ||
                                        loggedinuserName == 'Tahsildar' ||
                                        loggedinuserName == 'Joint Collector'">Official Account Name
                                    </th>
                                    <th
                                        *ngIf="
                                        loggedinuserName == 'Mandal Parishad Development Officers' ||
                                        loggedinuserName == 'Municipal Commissioner' ||
                                        loggedinuserName == 'Tahsildar' ||
                                        loggedinuserName == 'Joint Collector'">A/c. No.
                                    </th>
                                    <th
                                        *ngIf="
                                        loggedinuserName == 'Mandal Parishad Development Officers' ||
                                        loggedinuserName == 'Municipal Commissioner' ||
                                        loggedinuserName == 'Tahsildar' ||
                                        loggedinuserName == 'Joint Collector'">Name of the Bank & Branch
                                    </th>
                                    <th
                                        *ngIf="
                                        loggedinuserName == 'Mandal Parishad Development Officers' ||
                                        loggedinuserName == 'Municipal Commissioner' ||
                                        loggedinuserName == 'Tahsildar' ||
                                        loggedinuserName == 'Joint Collector'">IFSC code
                                    </th>
                                    <th
                                        *ngIf="
                                        loggedinuserName == 'Mandal Parishad Development Officers' ||
                                        loggedinuserName == 'Municipal Commissioner' ||
                                        loggedinuserName == 'Tahsildar' ||
                                        loggedinuserName == 'Joint Collector'">Passbook Front Page Picture
                                    </th>
                                    <th>Profile Update Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of tempArr; let i = index">
                                    <!-- <td *ngIf="loggedinuserName === 'Joint Collector'"> <input
                                                                type="checkbox" /><span></span>
                                                        </td> -->
                                    <td>{{ i + 1 }}</td>
                                    <!-- <td>
                                                            {{data.mandalName}}</td> -->

                                    <td>{{ data.userName }}</td>
                                    <td
                                        *ngIf="
                                        loggedinuserName === 'ExciseDC' ||
                                        loggedinuserName === 'HandloomDC' ||
                                        loggedinuserName === 'HandloomDO' ||
                                        loggedinuserName === 'SCWELDC' ||
                                        loggedinuserName === 'DMO' ||
                                        loggedinuserName === 'FisheryDC' ||
                                        loggedinuserName === 'Digital Assistant' ||
                                        loggedinuserName === 'Ward Education Secretary' ||
                                        loggedinuserName === 'Collector' ||
                                        loggedinuserName === 'Deputy Tahsildar' ||
                                        loggedinuserName === 'Tahsildar' ||
                                        loggedinuserName === 'Joint Collector' ||
                                        loggedinuserName === 'Revenue Inspector' ||
                                        loggedinuserName === 'Mandal surveyor' ||
                                        loggedinuserName === 'Assistant Civil Supplies Officer' ||
                                        loggedinuserName === 'Village Revenue Officer' ||
                                        loggedinuserName === 'Village surveyor' ||
                                        loggedinuserName === 'Ward Revenue Secretary' ||
                                        loggedinuserName === 'Welfare & Educational Assistant' ||
                                        loggedinuserName === 'Ward Welfare & Development Secretary' ||
                                        loggedinuserName === 'Mandal Parishad Development Officers' ||
                                        loggedinuserName === 'Municipal Commissioner' ||
                                        loggedinuserName === 'Revenue Divisonal Officer' ||
                                        loggedinuserName === 'District Civil Supplies Officer' ||
                                        loggedinuserName === 'Sub Collector' ||
                                        loggedinuserName === 'Assistant Social Welfare Officer' ||
                                        loggedinuserName === 'Fisheries Development Officer'||
                                        loggedinuserName === 'Panchayat Secretary DDO' || loggedinuserName == 'Zilla Sainik Welfare Officer'">
                                        {{ data.districtName ? data.districtName : "-" }}
                                    </td>
                                    <td
                                        *ngIf="
                                        loggedinuserName === 'Deputy Tahsildar' ||
                                        loggedinuserName === 'Tahsildar' ||
                                        loggedinuserName === 'Joint Collector' ||
                                        loggedinuserName === 'Digital Assistant' ||
                                        loggedinuserName === 'Ward Education Secretary' ||
                                        loggedinuserName === 'Revenue Inspector' ||
                                        loggedinuserName === 'Mandal surveyor' ||
                                        loggedinuserName === 'Assistant Civil Supplies Officer' ||
                                        loggedinuserName === 'Village Revenue Officer' ||
                                        loggedinuserName === 'Village surveyor' ||
                                        loggedinuserName === 'Ward Revenue Secretary' ||
                                        loggedinuserName === 'Welfare & Educational Assistant' ||
                                        loggedinuserName === 'Ward Welfare & Development Secretary' ||
                                        loggedinuserName === 'Mandal Parishad Development Officers' ||
                                        loggedinuserName === 'Municipal Commissioner' ||
                                        loggedinuserName === 'Assistant Social Welfare Officer' ||
                                        loggedinuserName === 'Fisheries Development Officer'||
                                        loggedinuserName === 'Panchayat Secretary DDO'">
                                        <!--  {{(data.mandalName !==(null || "")) && (data?.designation !=="Mandal Parishad Development Officers")  ? data.mandalName :'-'}} -->

                                        {{ data.mandalName !== (null || "") ? data.mandalName : "-" }}
                                    </td>
                                    <td
                                        *ngIf="
                                        loggedinuserName === 'Revenue Divisonal Officer' ||
                                        loggedinuserName === 'Sub Collector' ||
                                        loggedinuserName === 'Joint Collector'">
                                        <span
                                            *ngIf="
                                            data.revenueMandal !== null || data.revenueMandal !== ''">
                                            {{ data.revdiv }}
                                        </span>
                                        <span
                                            *ngIf="
                                            !data.revdiv &&
                                            (data?.designation !== 'Revenue Divisonal Officer' ||
                                            loggedinuserName !== 'Revenue Divisonal Officer')">-
                                        </span>
                                        <span
                                            *ngIf="
                                            !data.revdiv &&
                                            (data?.designation === 'Revenue Divisonal Officer' ||
                                            loggedinuserName === 'Revenue Divisonal Officer')">-
                                        </span>
                                    </td>
                                    <td
                                        *ngIf="
                                        loggedinuserName === 'Village Revenue Officer' ||
                                        loggedinuserName === 'Village surveyor' ||
                                        loggedinuserName === 'Digital Assistant' ||
                                        loggedinuserName === 'Ward Education Secretary' ||
                                        loggedinuserName === 'Ward Revenue Secretary' ||
                                        loggedinuserName === 'Welfare & Educational Assistant' ||
                                        loggedinuserName === 'Ward Welfare & Development Secretary' ||
                                        loggedinuserName === 'Tahsildar' ||
                                        loggedinuserName === 'Mandal Parishad Development Officers' ||
                                        loggedinuserName === 'Municipal Commissioner' ||
                                        loggedinuserName === 'Assistant Social Welfare Officer' ||
                                        loggedinuserName === 'Fisheries Development Officer'||
                                        loggedinuserName === 'Panchayat Secretary DDO'">
                                        {{ data.secName ? data.secName : "-" }}
                                    </td>
                                    <td
                                        *ngIf="
                                        loggedinuserName === 'Mandal Parishad Development Officers' ||
                                        loggedinuserName === 'Panchayat Secretary DDO'">
                                        {{ data.panchayatName ? data.panchayatName : "-" }}
                                    </td>
                                    <td
                                        *ngIf="
                                        loggedinuserName === 'Ward Education Secretary' &&
                                        data?.designation === 'DA'">
                                        <span>WEDS</span>
                                    </td>
                                    <td *ngIf="loggedinuserName !== 'Ward Education Secretary'">
                                        <span
                                            *ngIf="
                                            (loggedinuserName !== 'Ward Welfare & Development Secretary' && data?.designation !== 'WEA') ||
                                            (loggedinuserName !== 'Municipal Commissioner' && data?.designation !== 'WEA')">
                                            {{ data?.designation }}
                                        </span>
                                        <span
                                            *ngIf="
                                            (loggedinuserName === 'Welfare & Educational Assistant' && data?.designation === 'WEA') ||
                                            (loggedinuserName === 'Mandal Parishad Development Officers' && data?.designation === 'WEA')">
                                            {{ data?.designation }}
                                        </span>
                                        <span
                                            *ngIf="
                                            (loggedinuserName === 'Ward Welfare & Development Secretary' && data?.designation === 'WEA') ||
                                            (loggedinuserName === 'Municipal Commissioner' && data?.designation === 'WEA')">
                                            WWDS
                                        </span>
                                    </td>

                                    <td>
                                        {{ data.aadharNo !== null ? data.maskAadharrrr : "-" }}
                                    </td>
                                    <td>{{ data.mobileNo ? data.mobileNo : "-" }}</td>
                                    <td
                                        *ngIf="
                                        loggedinuserName === 'Ward Education Secretary' ||
                                        loggedinuserName == 'Digital Assistant' ||
                                        loggedinuserName === 'Mandal Parishad Development Officers' ||
                                        loggedinuserName === 'Municipal Commissioner'">
                                        {{ data.emailID ? data.emailID : "-" }}
                                    </td>
                                    <!--<td>{{this.loggedinDetails.userPrincipalName ?this.loggedinDetails.userPrincipalName:'-'}}</td> -->
                                    <td
                                        *ngIf="
                                        loggedinuserName !== 'Village Revenue Officer' &&
                                        loggedinuserName !== 'Assistant Social Welfare Officer' &&
                                        loggedinuserName !== 'Fisheries Development Officer'&&
                                        loggedinuserName !== 'Village surveyor' &&
                                        loggedinuserName !== 'Panchayat Secretary' &&
                                        loggedinuserName !== 'Assistant Secretary' &&
                                        loggedinuserName !== 'Digital Assistant' &&
                                        loggedinuserName !== 'Ward Education Secretary' &&
                                        loggedinuserName !== 'Revenue Inspector' &&
                                        loggedinuserName !== 'Mandal surveyor' &&
                                        loggedinuserName !== 'Assistant Civil Supplies Officer' &&
                                        loggedinuserName !== 'ExciseDC' &&
                                        loggedinuserName !== 'HandloomDC' &&
                                        loggedinuserName !== 'HandloomDO' &&
                                        loggedinuserName !== 'SCWELDC' &&
                                        loggedinuserName !== 'DMO' &&
                                        loggedinuserName !== 'FisheryDC' &&
                                        loggedinuserName !== 'Ward Admin Secretary' &&
                                        loggedinuserName !== 'Superintendent' &&
                                        loggedinuserName !== 'Zilla Sainik Welfare Officer'
                                        ">
                                        {{ data.digitalSignature ? data.digitalSignature : "No Digital Signature Serial Number" }}
                                    </td>
                                    <td>{{ data.role ? data.role : "-" }}</td>
                                    <td>{{ data.status ? data.status : "-" }}</td>
                                    <!-- <td>{{data.date ?data.date :'-'}}</td> -->
                                    <td
                                        *ngIf="
                                        loggedinuserName !== 'Village Revenue Officer' &&
                                        loggedinuserName !== 'Assistant Social Welfare Officer' &&
                                        loggedinuserName !== 'Fisheries Development Officer'&&
                                        loggedinuserName !== 'Village surveyor' &&
                                        loggedinuserName !== 'Panchayat Secretary' &&
                                        loggedinuserName !== 'Assistant Secretary' &&
                                        loggedinuserName !== 'Digital Assistant' &&
                                        loggedinuserName !== 'Ward Education Secretary' &&
                                        loggedinuserName !== 'Revenue Inspector' &&
                                        loggedinuserName !== 'Mandal surveyor' &&
                                        loggedinuserName !== 'Assistant Civil Supplies Officer' &&
                                        loggedinuserName !== 'ExciseDC' &&
                                        loggedinuserName !== 'HandloomDC' &&
                                        loggedinuserName !== 'HandloomDO' &&
                                        loggedinuserName !== 'SCWELDC' &&
                                        loggedinuserName !== 'DMO' &&
                                        loggedinuserName !== 'FisheryDC' &&
                                        loggedinuserName !== 'Ward Admin Secretary' &&
                                        loggedinuserName !== 'Superintendent'">
                                        <span *ngIf="data.signatureImage !== null">
                                            <img
                                                [src]="'data:image/JPG;base64,' + data.signatureImage"
                                                width="100px"
                                                height="100px"
                                            />
                                        </span>
                                        <span *ngIf="data.signatureImage === null">
                                            No Fascimile Signature
                                        </span>
                                    </td>
                                    <td
                                        *ngIf="
                                        loggedinuserName == 'Mandal Parishad Development Officers' ||
                                        loggedinuserName == 'Municipal Commissioner' ||
                                        loggedinuserName == 'Tahsildar' ||
                                        loggedinuserName == 'Joint Collector'">
                                        {{ data.accountHolderName ? data.accountHolderName : "-" }}
                                    </td>
                                    <td
                                        *ngIf="
                                        loggedinuserName == 'Mandal Parishad Development Officers' ||
                                        loggedinuserName == 'Municipal Commissioner' ||
                                        loggedinuserName == 'Tahsildar' ||
                                        loggedinuserName == 'Joint Collector'">
                                        {{ data.accountNumber ? data.accountNumber : "-" }}
                                    </td>
                                    <td
                                        *ngIf="
                                        loggedinuserName == 'Mandal Parishad Development Officers' ||
                                        loggedinuserName == 'Municipal Commissioner' ||
                                        loggedinuserName == 'Tahsildar' ||
                                        loggedinuserName == 'Joint Collector'">
                                        {{ data.bankBranch ? data.bankBranch : "-" }}
                                    </td>
                                    <td
                                        *ngIf="
                                        loggedinuserName == 'Mandal Parishad Development Officers' ||
                                        loggedinuserName == 'Municipal Commissioner' ||
                                        loggedinuserName == 'Tahsildar' ||
                                        loggedinuserName == 'Joint Collector'">
                                        {{ data.ifscCode ? data.ifscCode : "-" }}
                                    </td>
                                    <td
                                        *ngIf="
                                        loggedinuserName == 'Mandal Parishad Development Officers' ||
                                        loggedinuserName == 'Municipal Commissioner' ||
                                        loggedinuserName == 'Tahsildar' ||
                                        loggedinuserName == 'Joint Collector'">
                                        <img
                                            *ngIf="data.passbookStatement"
                                            [src]="'data:image/JPG;base64,' + data.passbookStatement"
                                            width="100px"
                                            height="100px"
                                        />
                                        <span *ngIf="!data.passbookStatement">No Passbook Picture</span>
                                    </td>
                                    <td>
                                        <span *ngIf="data.isApproved === false">Pending</span>
                                        <span *ngIf="data.isApproved === true">Approved</span>
                                        <span *ngIf="data.isApproved === null">-</span>
                                    </td>
                                    <td>
                                        <button class="btn btn-primary btn"
                                            *ngIf="
                                            loggedinuserName === 'Deputy Tahsildar' ||
                                            loggedinuserName === 'Revenue Divisonal Officer' ||
                                            loggedinuserName === 'District Civil Supplies Officer' ||
                                            loggedinuserName === 'Village Revenue Officer' ||
                                            loggedinuserName === 'Village surveyor' ||
                                            loggedinuserName === 'Panchayat Secretary' ||
                                            loggedinuserName === 'Assistant Secretary' ||
                                            loggedinuserName === 'Digital Assistant' ||
                                            loggedinuserName === 'Ward Education Secretary' ||
                                            loggedinuserName === 'Ward Revenue Secretary' ||
                                            loggedinuserName === 'Revenue Inspector' ||
                                            loggedinuserName === 'Assistant Civil Supplies Officer' ||
                                            loggedinuserName === 'Welfare & Educational Assistant' ||
                                            loggedinuserName === 'Ward Welfare & Development Secretary' ||
                                            loggedinuserName === 'Panchayat Secretary DDO' ||
                                            loggedinuserName === 'Ward Admin Secretary' ||
                                            loggedinuserName === 'Assistant Social Welfare Officer' ||
                                            loggedinuserName === 'Fisheries Development Officer'||
                                            loggedinuserName === 'Superintendent'"
                                            (click)="openModall(addModal)"
                                            (click)="edit(data)">Edit
                                        </button>

                                        <button class="btn btn-primary btn"
                                            *ngIf="
                                            (data?.designation === 'Joint Collector' && loggedinuserName === 'Joint Collector') || (data?.designation === 'Zilla Sainik Welfare Officer' && loggedinuserName === 'Zilla Sainik Welfare Officer') ||
                                            (data?.designation === 'Tahsildar' && loggedinuserName === 'Tahsildar') ||
                                            (data?.designation === 'MS' && loggedinuserName === 'Mandal surveyor') ||
                                            (data?.designation === 'CEO, WAQF board' && loggedinuserName === 'CEO, WAQF board') ||
                                            (data?.designation === 'Mandal Parishad Development Officers' && loggedinuserName === 'Mandal Parishad Development Officers') ||
                                            (data?.designation === 'Municipal Commissioner' && loggedinuserName === 'Municipal Commissioner') ||
                                            (data?.designation === 'Joint Collector Development' && loggedinuserName === 'Joint Collector Development') ||
                                            (data?.designation === 'ExciseDC' && loggedinuserName === 'ExciseDC') ||
                                            (data?.designation === 'HandloomDC' && loggedinuserName === 'HandloomDC') ||
                                            (data?.designation === 'HandloomDO' && loggedinuserName === 'HandloomDO') ||
                                            (data?.designation === 'SCWELDC' && loggedinuserName === 'SCWELDC') ||
                                            (data?.designation === 'DMO' && loggedinuserName === 'DMO') ||
                                            (data?.designation === 'FisheryDC' && loggedinuserName === 'FisheryDC') ||
                                            (data?.designation !== 'Joint Collector' && loggedinuserName === 'Collector')"
                                            (click)="openModall(addModal)"
                                            (click)="edit(data)">Edit
                                        </button>
                                        <button class="btn btn-primary btn"
                                            *ngIf="
                                                loggedinuserName === 'Joint Collector' &&
                                                data?.designation !== 'Joint Collector' &&
                                                data.isApproved === false"
                                            (click)="openModall(addModal)"
                                            (click)="edit(data)">Edit & Approve
                                        </button>
                                        <button class="btn btn-primary btn"
                                            *ngIf="
                                                (loggedinuserName === 'CEO, WAQF board' &&
                                                data?.designation === 'SPT' && data.isApproved === false) ||
                                                (loggedinuserName === 'CEO, WAQF board' &&
                                                data?.designation === 'AS' && data.isApproved === false)"
                                                (click)="openModall(addModal)"
                                                (click)="edit(data)">Edit & Approve
                                        </button>
                                        <button class="btn btn-primary btn"
                                            *ngIf="
                                            loggedinuserName === 'Collector' &&
                                            data?.designation === 'Joint Collector' &&
                                            data.isApproved === false"
                                            (click)="openModall(addModal)"
                                            (click)="edit(data)">Edit & Approve
                                        </button>
                                        <button class="btn btn-primary btn"
                                            *ngIf="
                                            (loggedinuserName === 'Tahsildar' &&
                                            data?.designation !== 'Tahsildar' &&
                                            data.isApproved === false) ||
                                            (loggedinuserName === 'Mandal surveyor' &&
                                            data?.designation !== 'MS' &&
                                            data.isApproved === false)"
                                            (click)="openModall(addModal)"
                                            (click)="edit(data)">Edit & Approve
                                        </button>
                                        <button class="btn btn-primary btn"
                                            *ngIf="
                                            loggedinuserName === 'Joint Collector Development' &&
                                            data?.designation !== 'Joint Collector Development' &&
                                            data.isApproved === false"
                                            (click)="openModall(addModal)"
                                            (click)="edit(data)">Edit & Approve
                                        </button>
                                        <button class="btn btn-primary btn"
                                            *ngIf="
                                            loggedinuserName ===
                                            'Mandal Parishad Development Officers' &&
                                            (data?.designation !==
                                            'Mandal Parishad Development Officers' ||
                                            data?.designation == 'Panchayat Secretary DDO' ||
                                            data?.designation == 'PSDDO') &&
                                            data.isApproved === false"
                                            (click)="openModall(addModal)"
                                            (click)="edit(data)">Edit & Approve
                                        </button>
                                        <button class="btn btn-primary btn"
                                            *ngIf="
                                            loggedinuserName === 'Municipal Commissioner' &&
                                            data?.designation !== 'Municipal Commissioner' &&
                                            data.isApproved === false"
                                            (click)="openModall(addModal)"
                                            (click)="edit(data)">Edit & Approve
                                        </button>

                                        <button class="btn btn-primary btn"
                                            *ngIf="
                                            (loggedinuserName === 'ExciseDC' &&
                                            data?.designation !== 'ExciseDC' &&
                                            data.isApproved === false) ||
                                            (loggedinuserName === 'HandloomDC' &&
                                            data?.designation !== 'HandloomDC' &&
                                            data.isApproved === false) ||
                                            (loggedinuserName === 'HandloomDO' &&
                                            data?.designation !== 'HandloomDO' &&
                                            data.isApproved === false) ||
                                            (loggedinuserName === 'SCWELDC' &&
                                            data?.designation !== 'SCWELDC' &&
                                            data.isApproved === false) ||
                                            (loggedinuserName === 'DMO' &&
                                            data?.designation !== 'DMO' &&
                                            data.isApproved === false) ||
                                            (loggedinuserName === 'FisheryDC' &&
                                            data?.designation !== 'FisheryDC' &&
                                            data.isApproved === false)"
                                            (click)="openModall(addModal)"
                                            (click)="edit(data)">Edit & Approve
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <!-- <div class="text-right">
                                        <button type="button" class="btn btn-primary text-center"
                                            *ngIf="loggedinuserName === 'Joint Collector'">Approve</button>
                                        <button type="button" class="btn btn-primary text-center"
                                            *ngIf="loggedinuserName === 'Revenue Divisonal Officer'">Submit</button>
                                    </div> -->
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>


<ng-template #addModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="previewLabel"
            *ngIf="
                (loggedinuserName === 'Tahsildar' ||
                loggedinuserName === 'Revenue Divisonal Officer' ||
                loggedinuserName === 'District Civil Supplies Officer' ||
                loggedinuserName === 'Deputy Tahsildar' ||
                loggedinuserName === 'Joint Collector' ||
                loggedinuserName === 'Zilla Sainik Welfare Officer' ||
                loggedinuserName === 'Village Revenue Officer' ||
                loggedinuserName === 'Village surveyor' ||
                loggedinuserName === 'Digital Assistant' ||
                loggedinuserName === 'Ward Education Secretary' ||
                loggedinuserName === 'Panchayat Secretary DDO' ||
                loggedinuserName === 'Panchayat Secretary' ||
                loggedinuserName === 'Assistant Secretary' ||
                loggedinuserName === 'Ward Admin Secretary' ||
                loggedinuserName === 'Superintendent' ||
                loggedinuserName === 'Revenue Inspector' ||
                loggedinuserName === 'Mandal surveyor' ||
                loggedinuserName === 'Assistant Civil Supplies Officer' ||
                loggedinuserName === 'ExciseDC' ||
                loggedinuserName === 'HandloomDC' ||
                loggedinuserName === 'HandloomDO' ||
                loggedinuserName === 'SCWELDC' ||
                loggedinuserName === 'DMO' ||
                loggedinuserName === 'FisheryDC' ||
                loggedinuserName === 'Mandal Parishad Development Officers' ||
                loggedinuserName === 'Municipal Commissioner' ||
                loggedinuserName === 'Welfare & Educational Assistant' ||
                loggedinuserName === 'Joint Collector Development' ||
                loggedinuserName === 'Ward Welfare & Development Secretary' ||
                loggedinuserName === 'Assistant Social Welfare Officer' ||
                loggedinuserName === 'Fisheries Development Officer'||
                loggedinuserName === 'Ward Revenue Secretary' ||
                loggedinuserName === 'Collector') &&
                array.length === 0">Add Profile
        </h5>
        <h5 class="modal-title" id="previewLabel"
            *ngIf="
                (loggedinuserName === 'Joint Collector' || loggedinuserName === 'Zilla Sainik Welfare Officer' ||
                loggedinuserName === 'Tahsildar' ||
                loggedinuserName === 'Mandal surveyor' ||
                loggedinuserName ==='Mandal Parishad Development Officers' ||
                loggedinuserName ==='CEO, WAQF board' ||
                loggedinuserName === 'Joint Collector Development' ||
                loggedinuserName === 'Municipal Commissioner') &&
                showaddsignature && !isEdit && array.length !== 0">Add Profile:
        </h5>
        <h5 class="modal-title" id="previewLabel"
            *ngIf="
                (loggedinuserName === 'Tahsildar' && designation === 'Tahsildar') ||
                (loggedinuserName === 'Deputy Tahsildar' && designation === 'Deputy Tahsildar') ||
                (loggedinuserName === 'Revenue Divisonal Officer' && designation === 'Revenue Divisonal Officer') ||
                (loggedinuserName === 'Revenue Divisonal Officer' && designation === 'Sub Collector') ||
                (loggedinuserName === 'District Civil Supplies Officer' && designation === 'DSO') ||
                (loggedinuserName === 'Municipal Commissioner' && designation === 'Municipal Commissioner') ||
                (loggedinuserName === 'Municipal Commissioner' && designation === 'WEA') ||
                (loggedinuserName === 'Municipal Commissioner' && designation === 'Ward Education Secretary') ||
                (loggedinuserName === 'Mandal Parishad Development Officers' && designation === 'Mandal Parishad Development Officers') ||
                (loggedinuserName === 'Welfare & Educational Assistant' && designation === 'WEA') ||
                (loggedinuserName === 'Ward Welfare & Development Secretary' && designation === 'WEA') ||
                (loggedinuserName === 'Joint Collector Development' && designation === 'Joint Collector Development') ||
                (loggedinuserName === 'CEO, WAQF board' && designation === 'CEO, WAQF board') ||
                (loggedinuserName === 'ExciseDC' && designation === 'ExciseDC') ||
                (loggedinuserName === 'HandloomDC' && designation === 'HandloomDC') ||
                (loggedinuserName === 'HandloomDO' && designation === 'HandloomDO') ||
                (loggedinuserName === 'SCWELDC' && designation === 'SCWELDC') ||
                (loggedinuserName === 'DMO' && designation === 'DMO') ||
                (loggedinuserName === 'FisheryDC' && designation === 'FisheryDC') ||
                (loggedinuserName === 'Ward Revenue Secretary' && designation === 'VRO') ||
                (loggedinuserName === 'Revenue Inspector' && designation === 'RI') ||
                (loggedinuserName === 'Mandal surveyor' && designation === 'MS') || 
                (loggedinuserName === 'Assistant Civil Supplies Officer' && designation === 'ASO') ||
                (loggedinuserName === 'Zilla Sainik Welfare Officer' && designation === 'Zilla Sainik Welfare Officer') ||
                (loggedinuserName === 'Joint Collector' && designation === 'Sub Collector') ||
                (loggedinuserName === 'Joint Collector' && designation === 'ExciseDC') ||
                (loggedinuserName === 'Joint Collector' && designation === 'SCWELDC') ||
                (loggedinuserName === 'Joint Collector' && designation === 'DMO') ||
                (loggedinuserName === 'Joint Collector' && designation === 'FisheryDC') ||
                (loggedinuserName === 'Joint Collector' && designation === 'Joint Collector')">Edit Profile
        </h5>
        
        <h5 class="modal-title" id="previewLabel"
            *ngIf="
                loggedinuserName ==='Municipal Commissioner' && isEdit &&
                (designation === 'PS' ||
                designation === 'WWDS')">Edit Profile
        </h5>
        <h5 class="modal-title" id="previewLabel"
            *ngIf="
                loggedinuserName ==='Mandal Parishad Development Officers' && isEdit &&
                (designation === 'WEA' ||
                designation === 'PS' ||
                designation === 'DA')">Edit Profile
        </h5>
        <!-- <h5 class="modal-title" id="previewLabel" *ngIf="loggedinuserName ==='Municipal Commissioner'">Add WWDS:
        </h5> -->
        <h5 class="modal-title" id="previewLabel"
            *ngIf="
                loggedinuserName === 'Joint Collector' && isEdit &&
                (designation === 'Revenue Divisonal Officer' ||
                designation === 'District Civil Supplies Officer' ||
                designation === 'Deputy Tahsildar' ||
                designation === 'Tahsildar' ||
                designation === 'HandloomDC' ||
                designation === 'HandloomDO' ||
                designation === 'Mandal Parishad Development Officers')">Edit Profile
        </h5>

        <h5 class="modal-title" id="previewLabel"
            *ngIf="
                loggedinuserName === 'Tahsildar' && isEdit &&
                (designation === 'VRO' ||
                designation === 'MS' ||
                designation === 'RI')">Edit Profile
        </h5>
        <h5 class="modal-title" id="previewLabel"
            *ngIf="loggedinuserName === 'Mandal surveyor' && isEdit && designation === 'VS'">Edit Profile
        </h5>
        <h5 class="modal-title" id="previewLabel"
            *ngIf="loggedinuserName === 'Joint Collector Development' && isEdit && designation === 'Mandal Parishad Development Officers'">Edit Profile
        </h5>
        <h5 class="modal-title" id="previewLabel"
            *ngIf="
                loggedinuserName === 'Collector' && array.length > 0 &&
                (designation === 'Joint Collector' ||
                designation === 'Municipal Commissioner' ||
                designation === 'Joint Collector Development' ||
                designation === 'Collector')">Edit Profile
        </h5>
        <h5 class="modal-title" id="previewLabel"
            *ngIf="loggedinuserName === 'Joint Collector' && designation === 'Municipal Commissioner' && array.length > 0">Edit Profile
        </h5>
        <h5 class="modal-title" id="previewLabel"
            *ngIf="(loggedinuserName ==='Village Revenue Officer' ||
                loggedinuserName === 'Assistant Social Welfare Officer' ||
                loggedinuserName === 'Fisheries Development Officer'||
                loggedinuserName === 'Village surveyor' ||
                loggedinuserName ==='Digital Assistant' ||
                loggedinuserName=== 'Ward Education Secretary') &&
                (designation === 'VRO' || designation === 'DA' || designation === 'VS' || designation === 'ASWO' || designation === 'FDO' ) ">Edit Profile
        </h5>
        <h5 class="modal-title" id="previewLabel"
            *ngIf="
                (loggedinuserName === 'Panchayat Secretary' ||
                loggedinuserName === 'Assistant Secretary' ||
                loggedinuserName === 'Ward Admin Secretary' ||
                loggedinuserName === 'Superintendent' ||
                loggedinuserName === 'Panchayat Secretary DDO') && isEdit">Edit Profile
        </h5>
        <h5 class="modal-title" id="previewLabel"
            *ngIf="
                loggedinuserName === 'CEO, WAQF board' && isEdit &&
                (designation === 'AS' ||
                designation === 'SPT')">Edit Profile
        </h5>
        <button type="button" class="close" (click)="modal.dismiss(); resetAddEditForm();" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="addForm">
            <div class="row mb-3">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <label class="labelCls lableHead"><b>Name :</b><span class="mandatoryCls">*</span></label>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <input type="text" name="name" class="inputCls" formControlName="Name" maxlength="60" required
                        appBlockCopyPaste placeholder="Enter Name"
                        [ngClass]="{ 'is-invalid':formsubmitted && g.Name.errors }">
                    <div *ngIf="formsubmitted && g.Name.errors" class="invalid-feedback">
                        <div *ngIf="g.Name.errors.required || g.Name.errors.whiteSpaceValidate">
                            Name is required</div>
                        <div *ngIf="g.Name.errors?.pattern">Please enter valid Name
                        </div>
                    </div>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <label class="labelCls lableHead"><b>Aadhar Number :</b><span class="mandatoryCls">*</span></label>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <input type="text" name="aadharNumber" class="inputCls" formControlName="aadharNumber" required
                        maxlength="12" minlength="12" required numbersOnly placeholder="Enter Aadhar Number"
                        [ngClass]="{ 'is-invalid':formsubmitted && g.aadharNumber.errors }">
                    <div *ngIf="formsubmitted && g.aadharNumber.errors" class="invalid-feedback">
                        <div *ngIf="g.aadharNumber.errors.required || g.aadharNumber.errors.whiteSpaceValidate">
                            Aadhar Number is required</div>
                        <div
                            *ngIf="g.aadharNumber.errors.aadharValidate || g.aadharNumber.errors.minlength || g.aadharNumber.errors.maxlength">
                            Please enter valid Aadhaar Number</div>
                    </div>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <label class="labelCls lableHead"><b>Mobile Number :</b><span class="mandatoryCls">*</span></label>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <input type="text" name="mobileNumber" class="inputCls" formControlName="mobileNumber" required
                        placeholder="Enter Mobile Number" minlength="10" maxlength="10" numbersOnly appBlockCopyPaste
                        [ngClass]="{ 'is-invalid':formsubmitted && g.mobileNumber.errors }">
                    <div *ngIf="formsubmitted && g.mobileNumber.errors" class="invalid-feedback">
                        <div *ngIf="g.mobileNumber.errors.required || g.mobileNumber.errors.whiteSpaceValidate">
                            Mobile Number is required</div>
                        <div *ngIf="g.mobileNumber.errors?.minlength || g.mobileNumber.errors?.pattern">Please enter valid Mobile No
                        </div>
                    </div>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                </div>
            </div>
            <div *ngIf="(loggedinuserName === 'Ward Education Secretary' ||
                    loggedinuserName =='Digital Assistant' ||
                    (loggedinuserName==='Mandal Parishad Development Officers' && designations ==='DA') ||
                    (loggedinuserName==='Municipal Commissioner' && designations ==='DA'))"
                class="row mb-3">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <label class="labelCls lableHead"><b>Email Id :</b><span class="mandatoryCls">*</span></label>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                </div>
            </div>
            <div *ngIf="(loggedinuserName === 'Ward Education Secretary' ||
                loggedinuserName =='Digital Assistant' ||
                (loggedinuserName==='Mandal Parishad Development Officers' && designations ==='DA') ||
                (loggedinuserName==='Municipal Commissioner' && designations ==='DA'))"
                class="row mb-3">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <input type="text" name="emailId" class="inputCls" formControlName="emailId" required
                        placeholder="Enter Email Id" email appBlockCopyPaste
                        [ngClass]="{ 'is-invalid':formsubmitted && g.emailId.errors }">
                    <div *ngIf="formsubmitted && g.emailId.errors" class="invalid-feedback">
                        <div *ngIf="g.emailId.errors.required || g.emailId.errors.whiteSpaceValidate">
                            Email Id is required</div>
                        <div *ngIf="g.emailId.errors?.minlength">Please enter valid Email Id
                        </div>
                    </div>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                </div>
            </div>
            <div class="row mb-3"
                *ngIf="designations !=='DA' &&
                    designations !=='VRO' &&
                    designations !=='RI' &&
                    designations !=='MS' &&
                    designation !== 'ASWO' &&
                    designation !== 'FDO' &&
                    designations !=='ASO' &&
                    loggedinuserName !== 'Village Revenue Officer' &&
                    loggedinuserName !== 'Assistant Social Welfare Officer' &&
                    loggedinuserName !== 'Fisheries Development Officer'&&
                    loggedinuserName !== 'Village surveyor' &&
                    loggedinuserName !== 'Panchayat Secretary' &&
                    loggedinuserName !== 'Assistant Secretary' &&
                    loggedinuserName !== 'Digital Assistant' &&
                    loggedinuserName !== 'Ward Education Secretary' &&
                    loggedinuserName !== 'Revenue Inspector' &&
                    loggedinuserName !== 'Mandal surveyor' &&
                    loggedinuserName !== 'Assistant Civil Supplies Officer' &&
                    loggedinuserName !== 'Ward Revenue Secretary' &&
                    loggedinuserName !== 'Welfare & Educational Assistant' &&
                    loggedinuserName !== 'Ward Welfare & Development Secretary' &&
                    designations !== 'WEA' && loggedinuserName !== 'ExciseDC' &&
                    loggedinuserName !== 'HandloomDC' &&
                    loggedinuserName !== 'HandloomDO' &&
                    loggedinuserName !== 'SCWELDC' &&
                    loggedinuserName !== 'DMO' &&
                    loggedinuserName !== 'FisheryDC' &&
                    designations !== 'ExciseDC' &&
                    designations !== 'HandloomDC' &&
                    designations !== 'HandloomDO' &&
                    designations !== 'SCWELDC' &&
                    designations !== 'DMO' &&
                    designations !== 'FisheryDC' &&
                    designations !== 'PS' &&
                    loggedinuserName !== 'Ward Admin Secretary' &&
                    loggedinuserName !== 'Superintendent' &&
                    designations !== 'SPT' && loggedinuserName !== 'Zilla Sainik Welfare Officer' &&
                    designations !== 'AS'">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12"></div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <label class="labelCls lableHead"><b>Digital Signature Serial No :</b>
                        <span class="mandatoryCls">*</span>
                    </label>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                </div>
            </div>
            <div class="row mb-3"
                *ngIf="designations !=='DA' &&
                    designations !=='VRO' &&
                    designations !== 'FDO' &&
                    designations !== 'ASWO' &&
                    designations !=='RI' &&
                    designations !=='MS' &&
                    designations !=='ASO' &&
                    loggedinuserName !=='Village Revenue Officer' &&
                    loggedinuserName !== 'Assistant Social Welfare Officer' &&
                    loggedinuserName !== 'Fisheries Development Officer'&&
                    loggedinuserName !=='Village surveyor' &&
                    loggedinuserName !=='Panchayat Secretary' &&
                    loggedinuserName !=='Assistant Secretary' &&
                    loggedinuserName !=='Digital Assistant' &&
                    loggedinuserName !=='Ward Education Secretary' &&
                    loggedinuserName !=='Revenue Inspector' &&
                    loggedinuserName !=='Mandal surveyor' &&
                    loggedinuserName !=='Assistant Civil Supplies Officer' &&
                    loggedinuserName !=='Ward Revenue Secretary' &&
                    loggedinuserName !=='Welfare & Educational Assistant' &&
                    loggedinuserName !=='Ward Welfare & Development Secretary' &&
                    designations !=='WEA' &&
                    loggedinuserName !=='ExciseDC' &&
                    loggedinuserName !=='HandloomDC' &&
                    loggedinuserName !=='HandloomDO' &&
                    loggedinuserName !=='SCWELDC' &&
                    loggedinuserName !=='DMO' &&
                    loggedinuserName !=='FisheryDC' &&
                    designations !=='ExciseDC' &&
                    designations !=='HandloomDC' &&
                    designations !=='HandloomDO' &&
                    designations !=='SCWELDC' &&
                    designations !=='DMO' &&
                    designations !=='FisheryDC' &&
                    designations !== 'PS' &&
                    loggedinuserName !== 'Ward Admin Secretary' &&
                    designations !== 'PS' &&
                    loggedinuserName !== 'Superintendent' && loggedinuserName !== 'Zilla Sainik Welfare Officer' &&
                    designations !== 'SPT' &&
                     designations !== 'AS'">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12"></div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <input type="text" name="digitalSignature" class="inputCls" formControlName="digitalSignature"
                        maxlength="50" required appBlockCopyPaste placeholder="Enter Digital Signature Serial No"
                        [ngClass]="{ 'is-invalid':formsubmitted && g.digitalSignature.errors }">
                    <div *ngIf="formsubmitted && g.digitalSignature.errors" class="invalid-feedback">
                        <div *ngIf="g.digitalSignature.errors.required || g.digitalSignature.errors.whiteSpaceValidate">
                            Digital Signature Serial No is required</div>
                        <div *ngIf="g.digitalSignature.errors?.pattern">Please enter valid Digital Signature Serial No
                        </div>
                    </div>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12"></div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <label class="labelCls lableHead"><b>Role :</b><span class="mandatoryCls">*</span></label>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <select class="selectCls" formControlName="role" required
                        [ngClass]="{ 'is-invalid':formsubmitted && (g.role.errors || g.role.value === 'Select')}">
                        <option [ngValue]="null" disabled>Select</option>
                        <option value="Regular">Regular</option>
                        <option value="Incharge">Incharge</option>
                    </select>
                    <div *ngIf="formsubmitted && (g.role.errors || g.role.value === 'Select')" class="selectError">
                        <div *ngIf="g.role.errors?.required || g.role.value === 'Select'">
                            Please
                            select Role</div>
                    </div>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <label class="labelCls lableHead"><b>Status :</b><span class="mandatoryCls">*</span></label>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <select class="selectCls" formControlName="status" required
                        [ngClass]="{ 'is-invalid':formsubmitted && (g.status.errors || g.status.value === 'Select')}">
                        <option [ngValue]="null" disabled>Select</option>
                        <option value="Active">Active</option>
                        <option value="InActive">InActive</option>
                    </select>
                    <div *ngIf="formsubmitted && (g.status.errors || g.status.value === 'Select')" class="selectError">
                        <div *ngIf="g.status.errors?.required || g.status.value === 'Select'">
                            Please
                            select Status</div>
                    </div>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                </div>
            </div>


            <!-- For RDO/ Sub Collector only start -->
            <!-- <div *ngIf="(loggedinuserName ==='Revenue Divisonal Officer' && designation ==='Revenue Divisonal Officer')||(loggedinuserName ==='Sub Collector' && designation ==='Sub Collector')">      -->
            <div *ngIf="(loggedinuserName === 'Revenue Divisonal Officer' && (designation === 'Revenue Divisonal Officer' || designation ==='Sub Collector')) ||
                    (loggedinuserName ==='Sub Collector' && (designation === 'Revenue Divisonal Officer' || designation === 'Sub Collector'))">  
            <div class="row mb-3">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <label class="labelCls lableHead"><b>Designation :</b><span class="mandatoryCls">*</span></label>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <select class="selectCls" formControlName="designation" required
                        [ngClass]="{ 'is-invalid':formsubmitted && (g.designation.errors || g.designation.value === 'Select')}">
                        <option [ngValue]="null" disabled>Select</option>
                        <option value="Sub Collector">Sub Collector</option>
                        <option value="Revenue Divisonal Officer">Revenue Divisonal Officer</option>
                    </select>
                    <div *ngIf="formsubmitted && (g.designation.errors || g.designation.value === 'Select')" class="selectError">
                        <div *ngIf="g.designation.errors?.required || g.designation.value === 'Select'">
                            Please
                            select Designation</div>
                    </div>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                </div>
            </div>
            </div>
    
            <!-- For RDO/ Sub Collector only End -->







            <div class="row mb-3"
                *ngIf="designations !== 'DA' &&
                    designations !== 'VRO' &&
                    designations !== 'VS' &&
                    designations !== 'FDO' &&
                    designations !== 'ASWO' &&
                    designations !== 'RI' &&
                    designations !== 'MS' &&
                    designations !== 'ASO' &&
                    loggedinuserName !== 'Village Revenue Officer' &&
                    loggedinuserName !== 'Assistant Social Welfare Officer' &&
                    loggedinuserName !== 'Fisheries Development Officer' &&
                    loggedinuserName !== 'Village surveyor' &&
                    loggedinuserName !== 'Panchayat Secretary' &&
                    loggedinuserName !== 'Assistant Secretary' &&
                    loggedinuserName !== 'Digital Assistant' &&
                    loggedinuserName !== 'Ward Education Secretary' &&
                    loggedinuserName !== 'Revenue Inspector' &&
                    loggedinuserName !== 'Mandal surveyor' &&
                    loggedinuserName !== 'Assistant Civil Supplies Officer' &&
                    loggedinuserName !== 'Ward Revenue Secretary' &&
                    loggedinuserName !== 'Welfare & Educational Assistant' &&
                    loggedinuserName !== 'Ward Welfare & Development Secretary' &&
                    designations !== 'WEA' &&
                    loggedinuserName !== 'ExciseDC' &&
                    loggedinuserName !== 'HandloomDC' &&
                    loggedinuserName !== 'HandloomDO' &&
                    loggedinuserName !== 'SCWELDC' &&
                    loggedinuserName !== 'DMO' &&
                    loggedinuserName !== 'FisheryDC' &&
                    designations !== 'ExciseDC' &&
                    designations !== 'HandloomDC' &&
                    designations !== 'HandloomDO' &&
                    designations !== 'SCWELDC' &&
                    designations !== 'DMO' &&
                    designations !== 'FisheryDC' &&
                    designations !== 'PS' &&
                    loggedinuserName !== 'Ward Admin Secretary' &&
                    loggedinuserName !== 'Superintendent' &&
                    designations !== 'SPT' &&
                    designations !== 'AS'">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <div class="checkBoxCont">
                        <label class="labelCls" for="applicationForm">Fascimile Signature <span
                                class="mandatoryCls">*</span></label>
                    </div>
                    <input type="file" class="" formControlName="fascimileForm" id="fascimileForm" accept=".jpg,"
                        (change)="fascimileFormDocument($event)" required 
                        [ngClass]="{ 'is-invalid':formsubmitted && g.fascimileForm.errors }">
                    <div class="clearCls" (click)="clearDocs('fascimileForm')" *ngIf="g.fascimileForm.value"></div>

                    <!--  <div class="input-group mb-3">
                    <div class="custom-file">
                        <input type="file" class="custom-file-input" formControlName="fascimileForm"
                            id="fascimileForm" accept=".jpg,.jpeg,.gif" (change)="fascimileFormDocument($event)"
                            required [ngClass]="{ 'is-invalid':formsubmitted && g.fascimileForm.errors }">
                        <label class="custom-file-label" #fascimileFormLabel for="fascimileForm"><i
                                class="fas fa-search"></i>No File choosen</label>
                    </div>
                    <div class="clearCls" (click)="clearDocs('fascimileForm', 'fascimileFormLabel')" *ngIf="g.fascimileForm.value"></div>
                </div> -->
                    <div *ngIf="formsubmitted && g.fascimileForm.errors" class="selectError">
                        <div *ngIf="g.fascimileForm.errors.required">Please
                            upload Digital/Scanned Fascimile Signature </div>
                        <div *ngIf="g.fascimileForm.errors?.inValidExt && !g.fascimileForm.errors.required ">
                            Please
                            upload Digital/Scanned Fascimile Signature in JPG Format</div>
                        <div
                            *ngIf="g.fascimileForm.errors?.inValidSize && !g.fascimileForm.errors.required && !g.fascimileForm.errors.inValidExt">
                            File size exceeding 200 KB</div>
                    </div>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12"
                    *ngIf="sourceFile && showchangedimage && loggedinuserName !=='Village Revenue Officer' && loggedinuserName !=='Village surveyor' && loggedinuserName !=='Panchayat Secretary'&& loggedinuserName !=='Assistant Secretary' && loggedinuserName !=='Digital Assistant'&& loggedinuserName !=='Ward Education Secretary'  && loggedinuserName !=='Revenue Inspector' && loggedinuserName !=='Mandal surveyor' && loggedinuserName !=='Assistant Civil Supplies Officer' && loggedinuserName !=='Ward Admin Secretary'&& loggedinuserName !=='Superintendent' && loggedinuserName !== 'Assistant Social Welfare Officer' && loggedinuserName !== 'Fisheries Development Officer'">
                    <img [src]="sourceFile" width="100px" height="100px" />
                </div>
            </div>

            <ng-container *ngIf="(loggedinuserName == 'Mandal Parishad Development Officers' && designations == 'Mandal Parishad Development Officers') ||
                (loggedinuserName == 'Municipal Commissioner' && designations == 'Municipal Commissioner') ||
                (loggedinuserName == 'Tahsildar' && designations == 'Tahsildar') || 
                (loggedinuserName == 'Joint Collector' &&
                (designations == 'Mandal Parishad Development Officers' ||
                designations == 'Municipal Commissioner' ||
                designations == 'Tahsildar'))">
                <div class="row mb-3">
                    <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                    </div>
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label class="labelCls lableHead"><b>Official Account Name :</b><span
                                class="mandatoryCls">*</span></label>
                    </div>
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                    </div>
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <input type="text" name="acName" formControlName="acName" class="inputCls" appBlockCopyPaste required maxlength="50"
                        [ngClass]="{ 'is-invalid':formsubmitted && g.acName.errors }">
                        <div *ngIf="formsubmitted && g.acName.errors" class="invalid-feedback">
                            <div *ngIf="g.acName.errors.required || g.acName.errors.whiteSpaceValidate">Account Name is required</div>
                            <div *ngIf="g.acName.errors?.pattern">Please enter valid Official Account Name</div>
                        </div>
                    </div>
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    </div>
                </div>
                
                <div class="row mb-3">
                    <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                    </div>
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label class="labelCls lableHead"><b>A/c. No. :</b><span
                                class="mandatoryCls">*</span></label>
                    </div>
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                    </div>
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <input type="text" name="acNo" formControlName="acNo" class="inputCls" numbersOnly appBlockCopyPaste required maxlength="50"
                        [ngClass]="{ 'is-invalid':formsubmitted && g.acNo.errors }">
                        <div *ngIf="formsubmitted && g.acNo.errors" class="invalid-feedback">
                            <div *ngIf="g.acNo.errors.required || g.acNo.errors.whiteSpaceValidate">A/c. No. is required</div>
                            <div *ngIf="g.acNo.errors?.pattern">Please enter valid A/c. No.</div>
                        </div>
                    </div>
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                    </div>
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label class="labelCls lableHead"><b>Name of the Bank & Branch :</b><span
                                class="mandatoryCls">*</span></label>
                    </div>
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                    </div>
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <input type="text" name="bankName" formControlName="bankName" class="inputCls" appBlockCopyPaste required maxlength="200"
                        [ngClass]="{ 'is-invalid':formsubmitted && g.bankName.errors }">
                        <div *ngIf="formsubmitted && g.bankName.errors" class="invalid-feedback">
                            <div *ngIf="g.bankName.errors.required || g.bankName.errors.whiteSpaceValidate">Name of the Bank & Branch is required</div>
                            <div *ngIf="g.bankName.errors?.pattern">Please enter valid Name of the Bank & Branch</div>
                        </div>
                    </div>
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                    </div>
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label class="labelCls lableHead"><b>IFSC code :</b><span
                                class="mandatoryCls">*</span></label>
                    </div>
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                    </div>
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <input type="text" name="ifscCode" formControlName="ifscCode" class="inputCls" appBlockCopyPaste required maxlength="11" minlength="11"
                        [ngClass]="{ 'is-invalid':formsubmitted && g.ifscCode.errors }">
                        <div *ngIf="formsubmitted && g.ifscCode.errors" class="invalid-feedback">
                            <div *ngIf="g.ifscCode.errors.required || g.ifscCode.errors.whiteSpaceValidate">IFSC code is required</div>
                            <div *ngIf="g.ifscCode.errors?.pattern">Please enter valid IFCS Code</div>
                        </div>
                    </div>
                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                    </div>
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="checkBoxCont">
                            <label class="labelCls" for="applicationForm">Passbook Front Page Picture : <span
                                    class="mandatoryCls">*</span></label>
                        </div>
                        <input type="file" class="" formControlName="passbook" id="passbook" name="passbook" accept=".jpg,"
                            (change)="passbookDocument($event)"  
                            [ngClass]="{ 'is-invalid':formsubmitted && g.passbook.errors }">
                        <div class="clearCls" (click)="clearPassbookDocs('passbook')" *ngIf="g.passbook.value"></div>

                        <div *ngIf="formsubmitted && g.passbook.errors" class="selectError">
                            <div *ngIf="g.passbook.errors.required">Please upload Passbook Front Page Picture (Upload Only jpg/jpeg format) </div>
                            <div *ngIf="g.passbook.errors?.inValidExt && !g.passbook.errors.required ">
                                Please upload the file in JPG/JPEG Format</div>
                            <div
                                *ngIf="g.passbook.errors?.inValidSize && !g.passbook.errors.required && !g.passbook.errors.inValidExt">
                                File size exceeding 1 MB</div>
                        </div>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12"
                        *ngIf="passbooksourceFile && passbookshowchangedimage">
                        <img [src]="passbooksourceFile" width="100px" height="100px" />
                    </div>
                </div>

            </ng-container>
            <!-- <div class="row mb-3"
                *ngIf="loggedinuserName !=='Revenue Divisonal Officer' && f.designation.value !=='Sub Collector'">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <label class="labelCls lableHead"><b>Revenue Mandal :</b><span class="mandatoryCls">*</span></label>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                </div>
            </div>
            <div class="row mb-3"
                *ngIf="loggedinuserName !=='Revenue Divisonal Officer' && f.designation.value !=='Sub Collector'">
                <div class="col col-lg-2 col-md-4 col-sm-12 col-xs-12">
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <select class="selectCls" formControlName="revenueMandal" required
                        [ngClass]="{ 'is-invalid':formsubmitted && (g.revenueMandal.errors || g.revenueMandal.value === 'Select')}">
                        <option [ngValue]="null" disabled>Select</option>
                        <option>Chittor</option>
                    </select>
                    <div *ngIf="formsubmitted && (g.revenueMandal.errors || g.revenueMandal.value === 'Select')"
                        class="selectError">
                        <div *ngIf="g.revenueMandal.errors?.required || g.revenueMandal.value === 'Select'">
                            Please
                            select Revenue Mandal</div>
                    </div>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                </div>
            </div> -->
        </form>
    </div>

    <div class="modal-footer">
        <button name="close" class="greenBtnCls" data-dismiss="modal" *ngIf="!isEdit" (click)="submit()">Submit</button>
        <button name="close" class="greenBtnCls" data-dismiss="modal"
            *ngIf="isEdit &&
                loggedinuserName !== 'Joint Collector' &&
                loggedinuserName !== 'Collector' &&
                loggedinuserName !== 'Tahsildar' &&
                loggedinuserName !== 'Mandal surveyor' &&
                loggedinuserName !== 'Joint Collector Development' &&
                loggedinuserName !== 'Mandal Parishad Development Officers' &&
                loggedinuserName !== 'Municipal Commissioner' &&
                loggedinuserName !== 'CEO, WAQF board ' &&
                loggedinuserName !== 'Panchayat Secretary DDO'"
                (click)="update()">Update
        </button>
        <button name="close" class="greenBtnCls" data-dismiss="modal"
            *ngIf="(isEdit && loggedinuserName === 'Collector' && (designation === 'COLLECTOR' || designation === 'Collector')) ||
                (isEdit && loggedinuserName === 'Joint Collector' && designation === 'Joint Collector') ||   
                (isEdit && loggedinuserName === 'Tahsildar' && designations === 'Tahsildar') ||
                (isEdit && loggedinuserName === 'Mandal surveyor' && designations === 'MS') ||
                (isEdit && loggedinuserName === 'Panchayat Secretary DDO' && (designations === 'Panchayat Secretary DDO' || designations =='PSDDO')) ||
                (isEdit && loggedinuserName === 'Joint Collector Development' && designations === 'Joint Collector Development') ||
                (isEdit && loggedinuserName === 'Mandal Parishad Development Officers' && (designations === 'Mandal Parishad Development Officers' || designations ==='PS')) ||
                (isEdit && loggedinuserName === 'Municipal Commissioner' && designations === 'Municipal Commissioner')"
                (click)="update()">Update
        </button>
        <button name="close" class="greenBtnCls" data-dismiss="modal"
            *ngIf="isEdit && loggedinuserName === 'Joint Collector' && designation !== 'Joint Collector'"
            (click)="update()">Approve
        </button>
        <button name="close" class="greenBtnCls" data-dismiss="modal"
            *ngIf="isEdit && loggedinuserName === 'Collector' && designation === 'Joint Collector'"
            (click)="update()">Approve
        </button>
        <!-- <button name="close" class="greenBtnCls" data-dismiss="modal"
            *ngIf="isEdit && loggedinuserName==='CEO, WAQF board' && designation ==='SPT'"
            (click)="update()">Approve</button> -->
        <button name="close" class="greenBtnCls" data-dismiss="modal"
            *ngIf="isEdit && loggedinuserName === 'Collector' && designation === 'Municipal Commissioner'"
            (click)="update()">Approve
        </button>
        <button name="close" class="greenBtnCls" data-dismiss="modal"
            *ngIf="isEdit && loggedinuserName === 'Collector' && designation === 'Joint Collector Development'"
            (click)="update()">Approve
        </button>
        <button name="close" class="greenBtnCls" data-dismiss="modal"
            *ngIf="isEdit && loggedinuserName === 'Joint Collector Development' && designation === 'Mandal Parishad Development Officers'"
            (click)="update()">Approve
        </button>
        <button name="close" class="greenBtnCls" data-dismiss="modal"
            *ngIf="isEdit && loggedinuserName === 'Tahsildar' && designations === 'VRO'"
            (click)="update()">Approve
        </button>
        <button name="close" class="greenBtnCls" data-dismiss="modal"
            *ngIf="isEdit && loggedinuserName === 'Mandal surveyor' && designations === 'VS'"
            (click)="update()">Approve
        </button>
        <button name="close" class="greenBtnCls" data-dismiss="modal"
            *ngIf="isEdit && loggedinuserName === 'Tahsildar' && (designations === 'RI' || designations === 'MS' || designations === 'Deputy Tahsildar')"
            (click)="update()">Approve
        </button>
        <button name="close" class="greenBtnCls" data-dismiss="modal"
            *ngIf="isEdit && loggedinuserName === 'Mandal Parishad Development Officers' && (designations === 'WEA' || designations === 'PSDDO' || designations === 'Panchayat Secretary DDO')"
            (click)="update()">Approve
        </button>
        <button name="close" class="greenBtnCls" data-dismiss="modal"
            *ngIf="isEdit && loggedinuserName === 'Mandal Parishad Development Officers' && designations === 'DA'"
            (click)="update()">Approve
        </button>
        <button name="close" class="greenBtnCls" data-dismiss="modal"
            *ngIf="isEdit && loggedinuserName === 'Municipal Commissioner' && (designations === 'WEA' || designations === 'DA'|| designations == 'PS')"
            (click)="update()">Approve
        </button>
       <!-- <button name="close" class="greenBtnCls" data-dismiss="modal"
            *ngIf="isEdit && ((loggedinuserName ==='ExciseDC' && designations ==='ExciseDC') ||  (loggedinuserName ==='HandloomDC' && designations ==='HandloomDC') || (loggedinuserName ==='SCWELDC' && designations ==='SCWELDC') || (loggedinuserName ==='DMO' && designations ==='DMO'))"
            (click)="update()">Approve</button> -->
    </div>
</ng-template>