<div class="modal-header" >
    <h6 class="modal-title" *ngIf = "popup === 'termsConditions'">Terms & Conditions</h6>
    <h6 class="modal-title" *ngIf = "popup === 'privacyPolicy'">Privacy Policy</h6>
    <h6 class="modal-title" *ngIf = "popup === 'refundCancel'">Refund & Cancellation Policy</h6>
    <h6 class="modal-title" *ngIf = "popup === 'challanRemittance'">Challan Remittance Alert</h6>
    <h6 class="modal-title" *ngIf = "popup === 'informationWizard'">Information Wizard For Citizen - Services/Entitlements</h6>
    <h6 class="modal-title" *ngIf = "popup === 'HardwareStatusforRegistrationofHouseSitePattas'">Checklist of Hardware Status for Registration of House Site Pattas</h6>
   
    <button type="button" *ngIf = "popup !== 'challanRemittance' && popup !== 'HardwareStatusforRegistrationofHouseSitePattas'" class="close" aria-label="Close"  (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
   
  </div>
  <div class="modal-body">
    <ng-container *ngIf = "popup === 'termsConditions'">
        <p>This website is designed, developed, and maintained by Grama Volunteers Ward Volunteers and Village Secretariats Ward Secretariats (GVWV & VSWS) department, Government of Andhra Pradesh.</p>
        <p>Though all efforts have been made to ensure the accuracy and currency of the content on this website, the same should not be construed as a statement of law or used for any legal purposes. In case of any ambiguity or doubts, users are advised to verify/check with the Department(s) and/or other source(s), and to obtain appropriate professional advice.</p>
        <p>Under no circumstances will this Department be liable for any expense, loss, or damage, including, without limitation, indirect or consequential loss or damage, or any expense, loss or damage whatsoever arising from use, or loss of use, of data, arising out of or in connection with the use of this website.</p>
        <p>These terms and conditions shall be governed by and construed in accordance with the Indian Laws. Any dispute arising under these terms and conditions shall be subject to the jurisdiction of the courts of India.</p>
        <p>The information posted on this website could include hypertext links or pointers to information created and maintained by non-government/private organizations. GVWV & VSWS department is providing these links and pointers solely for your information and convenience. When you select a link to an external website, you are leaving the GVWV & VSWS website and are subject to the privacy and security policies of the owners/sponsors of the external website.</p>
        <p>GVWV & VSWS department does not always guarantee the availability of such linked pages.</p>
    </ng-container>
    <ng-container *ngIf = "popup === 'privacyPolicy'">
        <p>Though all efforts have been made to ensure the accuracy of the content on this website, the same should not be construed as a statement of law or used for any legal purposes. Grama Volunteers Ward Volunteers and Village Secretariats Ward Secretariats (GVWV & VSWS) department accepts no responsibility in relation to the accuracy, completeness, usefulness or otherwise, of the contents. Users are advised to verify/check any information, and to obtain any appropriate professional advice before acting on the information provided on this website.</p>
        <p>GVWV & VSWS department website does not automatically capture any specific personal information from any user (like name, phone no. or e-mail address) that allows this department to identify any user individually when users visit the site. Users can generally visit the site without revealing Personal Information unless users choose to provide such information.</p>
        <h6 class="subHeading">Site Visit data</h6>
        <p>This website records user's visit and logs the following information for statistical purposes -users server's address; the name of the top-level domain from which users access the Internet (for example, .gov, .com, .in, etc.); the type of browser users use; the date and time users access the site; the pages users have accessed and the documents downloaded and the previous Internet address from which users linked directly to the site.</p>
        <p>We will not identify users or their browsing activities, except when a law enforcement agency may exercise a warrant to inspect the service provider's logs.</p>
        <h6 class="subHeading">Cookies</h6>
        <p>A cookie is a piece of software code that an internet web site sends to user's browser when users access information at that site. This website does not use cookies.</p>
        <h6 class="subHeading">Email Management</h6>
        <p>User's email address will only be recorded if users choose to send a message. It will only be used for the purpose for which users have provided it and will not be added to a mailing list. Website user's email address will not be used for any other purpose, and will not be disclosed, without user's consent.</p>
        <h6 class="subHeading">Collection of Personal Information</h6>
        <p>If users are asked for any other Personal Information users will be informed how it will be used if users choose to give it. If at any time users believe the principles referred to in this privacy statement have not been followed, or have any other comments on these principles, please notify the webmaster through the contact us page.</p>
        <p><strong class="boldText">Note:</strong> The use of the term "Personal Information" in this privacy statement refers to any information from which user's identity is apparent or can be reasonably ascertained.</p>
    </ng-container>
    <ng-container *ngIf = "popup === 'refundCancel'">
        <p>Refunds will be processed by Grama Volunteers Ward Volunteers and Village Secretariats Ward Secretariats (GVWV & VSWS) department and will be refunded to customer account source as per regulations issued by the banks.</p>
        <p>Refunds will be processed based on the timelines provided by the respective banks.</p>
    </ng-container>
    <ng-container *ngIf = "popup === 'challanRemittance'">
      <!-- <p>1. All the DA/WEDS are informed to remit the existing pending challans i.e., Both, challan not generated and challans generated & not deposited amount as on DATE <span class="subHeading">IMMEDIATELY</span>.</p> -->
      <p>1. The <span class="subHeading">  {{userInfo.jobTitle }} Mr./Mrs. {{userName}} </span> is informed to remit the pending challans that amount to <span class="subHeading">Rs. {{pendingAmount}}/- </span>i.e. Both challan not generated and challans generated & not deposited amount as on DATE <span class="subHeading">IMMEDIATELY</span>.</p>
      <p>2. From now onwards sum of services amount collected from each and every transaction should be generated in challan and must remitted on T(Transaction Date)+1 day basis excluding public holidays.</p>
      <p>If any deviation in the above process severe disciplinary action shall be taken on the individual.</p>
      <div class="mt-2">
        <input type="checkbox" id="consent" [(ngModel)]="isAccepted">
        <label class="labelCls" for="consent">I have read and agreed the Terms & Conditions of challan & remittance process.</label>
        <div *ngIf="submitted && !isAccepted" class="selectError">Please select the Terms & Conditions</div>
      </div>
      <div class="my-2 float-right">
        <div class="subHeading">Additional Commissioner (F&A),</div>
        <div>GVWV & VSWS DEPARTMENT.</div>
      </div>
  </ng-container>
  <ng-container *ngIf = "popup === 'HardwareStatusforRegistrationofHouseSitePattas'">
    <form [formGroup]="HardwareStatus">
       <div class="row mt-20">
         <div class="col-lg-4">
            <h6 class="mb-3 mt-20"><b>Latest version of Google Chrome / Microsoft Edge updated: </b><span class="mandatoryCls">*</span></h6>
         </div>
         <div class="col-lg-8">
               <input type="radio" name="googleChrome" formControlName="googleChrome" value="Yes" id="yes">
               <label class="ml-1" for="yes">Yes</label>
           
               <input type="radio" class="ml-3" name="googleChrome" formControlName="googleChrome" value="No" id="no">
               <label class="ml-1" for="no">No</label>
 
               <div *ngIf="isHardwareStatusSubmitted && y.googleChrome.errors" class="selectError">
                 <div *ngIf="y.googleChrome.errors.required">Please select Latest version of Google Chrome / Microsoft Edge updated</div>
             </div>
         </div>
         <div class="col-lg-4">
           <h6 class="mb-3 mt-20"><b>FPS Drivers installed: </b><span class="mandatoryCls">*</span></h6>
        </div>
        <div class="col-lg-8">
              <input type="radio" name="FPSDrivers" formControlName="FPSDrivers" value="Yes" id="yes">
              <label class="ml-1" for="yes">Yes</label>
          
              <input type="radio" class="ml-3" name="FPSDrivers" formControlName="FPSDrivers" value="No" id="no">
              <label class="ml-1" for="no">No</label>
              
              <div *ngIf="isHardwareStatusSubmitted && y.FPSDrivers.errors" class="selectError">
               <div *ngIf="y.FPSDrivers.errors.required">Please select FPS Drivers installed</div>
           </div>
        </div>
        <div class="col-lg-4">
         <h6 class="mb-3 mt-20"><b>Panchayat Secretary/Ward Admin Secratary details mapped: </b><span class="mandatoryCls">*</span></h6>
      </div>
      <div class="col-lg-8">
            <input type="radio" name="pswasmapped" formControlName="pswasmapped" value="Yes" id="yes">
            <label class="ml-1" for="yes">Yes</label>
        
            <input type="radio" class="ml-3" name="pswasmapped" formControlName="pswasmapped" value="No" id="no">
            <label class="ml-1" for="no">No</label>
 
            <div *ngIf="isHardwareStatusSubmitted && y.pswasmapped.errors" class="selectError">
             <div *ngIf="y.pswasmapped.errors.required">Please select Panchayat Secretary/Ward Admin Secratary details mapped</div>
         </div>
      </div>
      <div class="col-lg-4">
       <h6 class="mb-3 mt-20"><b>VRO/Ward Revenue Secretary datails mapped: </b><span class="mandatoryCls">*</span></h6>
    </div>
    <div class="col-lg-8">
          <input type="radio" name="vrowardmapped" formControlName="vrowardmapped" value="Yes" id="yes">
          <label class="ml-1" for="yes">Yes</label>
      
          <input type="radio" class="ml-3" name="vrowardmapped" formControlName="vrowardmapped" value="No" id="no">
          <label class="ml-1" for="no">No</label>
 
          <div *ngIf="isHardwareStatusSubmitted && y.vrowardmapped.errors" class="selectError">
           <div *ngIf="y.vrowardmapped.errors.required">Please select VRO/Ward Revenue Secretary datails mapped</div>
       </div>
    </div>
     </div>
    </form>
   </ng-container>
  <ng-container *ngIf = "popup === 'informationWizard'">
<form [formGroup]="information">
  <div class="row mb-3">
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Name: <span class="mandatoryCls">*</span></label>
        <input type="text" name="name"  class="inputCls" maxlength="50" formControlName="name"
        [ngClass]="{ 'is-invalid':isSubmitted && f.name.errors }" required>   
        <div *ngIf="isSubmitted && f.name.errors" class="invalid-feedback">
          <div *ngIf="f.name.errors.required">Please enter Name</div>
          <div *ngIf="f.name.errors?.pattern">Please enter valid Name</div>
      </div>          
      </div>
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Age: <span class="mandatoryCls">*</span></label>
        <input type="text" name="age"  class="inputCls" maxlength="3" formControlName="age" numbersOnly
        [ngClass]="{ 'is-invalid':isSubmitted && f.age.errors }" required>  
        <div *ngIf="isSubmitted && f.age.errors" class="invalid-feedback">
          <div *ngIf="f.age.errors.required">Please enter Age</div>
         
      </div>            
      </div>
    
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Date of Birth:<span class="mandatoryCls">*</span></label>
        <div class="row">            
        <div class="input-group" style="width:77% !important">
          <input class="calendarCls form-control ml-3" placeholder="dd/mm/yyyy" appBlockCopyPaste
                 name="d"  ngbDatepicker #d="ngbDatepicker" [minDate]="minDate" required
                 [maxDate]="maxDate" formControlName="dob"    [ngClass]="{ 'is-invalid':isSubmitted && f.dob.errors }">
                </div>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
          </div>
        </div>
        <div *ngIf="isSubmitted && f.dob.errors" class="selectError">
          <div *ngIf="f.dob.errors?.required || f.dob.invalid">Please select valid Date of Birth</div>
      </div>
        </div>
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <!-- <label class="labelCls">Caste: </label>
          <input type="text" name="caste"  class="inputCls" maxlength="20">              -->
          <label class="labelCls">Caste: <span class="mandatoryCls">*</span></label>
          <select class="selectCls" formControlName="caste" required 
          [ngClass]="{ 'is-invalid':isSubmitted && (f.caste.errors || f.caste.value === 'Select')}"> 
            <option [ngValue]="null" disabled>Select</option>
            <option *ngFor="let caste of casteCategory" [ngValue]="caste">{{caste.name}}</option>
          </select>
        <div *ngIf="isSubmitted && (f.caste.errors || f.caste.value === 'Select')" class="selectError">
          <div *ngIf="f.caste.errors?.required || f.caste.value === 'Select'">Please select Caste</div>
      </div>
        </div>
        </div>
     
        <div class="row mb-3">
      
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Address: <span class="mandatoryCls">*</span></label>
        <input type="text" name="address"  class="inputCls" maxlength="50" formControlName="address"
         required      [ngClass]="{ 'is-invalid':isSubmitted && f.address.errors }">   
         <div *ngIf="isSubmitted && f.address.errors" class="invalid-feedback">
          <div *ngIf="f.address.errors.required">Please enter Address</div>
         
      </div>             
      </div>
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">District: <span class="mandatoryCls">*</span></label>
        <!-- <input type="text" name="district"  class="inputCls" maxlength="20" formControlName="district"
        required      [ngClass]="{ 'is-invalid':isSubmitted && f.district.errors }">   -->
      
        <select class="selectCls" required formControlName="district" 
        [ngClass]="{ 'is-invalid':isSubmitted && (f.district.errors || f.district.value === 'Select') }">
        <option  [ngValue]= "null" disabled>Select</option>
          <option *ngFor="let district of districts" [ngValue]="district">{{district.name}}</option>
        </select>
        <div *ngIf="isSubmitted && (f.district.errors || f.district.value === 'Select')" class="selectError">
          <div *ngIf="f.district.errors?.required || f.district.value === 'Select'">Please select District</div>
      </div>
      </div>              
   
 
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">Pincode: <span class="mandatoryCls">*</span></label>
      <input type="text" name="pincode"  class="inputCls" maxlength="6" formControlName="pincode" numberOnly
      required      [ngClass]="{ 'is-invalid':isSubmitted && f.pincode.errors }"> 
      <div *ngIf="isSubmitted && f.pincode.errors" class="invalid-feedback">
        <div *ngIf="f.pincode.errors.required">Please enter Pincode</div>
       
    </div>              
    </div>
  </div>
      </form>
 
   

   
      <div class="row mt-20">

        <div class="col-lg-4">
      <h6 class="mb-3 mt-20"><b>Do you belong to Andhra Pradesh: </b></h6>
   </div>
    <div class="col-lg-8">
          <input type="radio" name="citizen" value="Yes" id="yes" (change)="onChangeChecklist('yes','Citizen')">
          <label class="ml-1" for="yes">Yes</label>
      
          <input type="radio" class="ml-3" name="citizen" value="No" id="no" (change)="onChangeChecklist('no','Citizen')">
          <label class="ml-1" for="no">No</label>
    </div>

        </div>
        
        <div *ngIf="showCitizen">
          <div>The citizen can avail the following services/entitlements subject to final verification</div>
          <ul class="home-sub-menu">
            <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Integratedcertificate.pdf" download="Integrated certificate.pdf">Eligible for Integrated ( Caste, Domicile) Certificate</a></li>
            <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Incomecertificate.pdf" download="Income certificate.pdf">Income Certificate</a></li>
            <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Birthcertificate.pdf" download="Birth certificate.pdf">Birth Certificate</a></li>
            <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Reissuance.pdf" download="Re Issuance certificate.pdf">Re Issuance of Certificate</a></li>
                      
        </ul>
        </div>

        <div class="row">
          <div class="col-lg-4">
      <h6 class="mb-3 mt-15"><b>Do you have Agriculture Land : </b></h6>
      
</div>
<div class="col-lg-8">
        <input type="radio" name="land" value="Yes" id="yes" (change)="onChangeChecklist('yes','Land')">
        <label class="ml-1" for="yes">Yes</label>
    
        <input type="radio" class="ml-3" name="land" value="No" id="no" (change)="onChangeChecklist('no','Land')">
        <label class="ml-1" for="no">No</label>
    
     </div>
     </div>
    
      <div *ngIf="showLand">
        <div>The citizen can avail the following services/entitlements subject to final verification</div>
        <ul class="home-sub-menu">
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/ROR1B.pdf" download="ROR 1B certificate">ROR 1B</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Computerizedadangal.pdf" download="Computerized Adangal">Computerized Adangal</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Mutations.pdf" download="Mutations">Mutations</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Seeding_deseedingpattadaraadhar.pdf" download="Seeding/Deseeding Pattadhar Aadhar">Seeding / Deseeding Pattadhar Aadhar</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Replacementofpattadharpassbook.pdf" download="Replacement of Pattadhar Passbook">Replacement of Pattadhar Passbook</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Electricalmeterconnections.pdf" download="Electrical Meter Connection">Electrical Meter Connection</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Billpayments.pdf" download="Bill Payments">Bill Payments</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Lamination.pdf" download="Lamination">Lamination Service</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Watertax.pdf" download="Water tax">Water tax Payments</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Encumbrancecertificate.pdf" download="Encumbrance Certificate">Encumbrance Certificate</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Flinepetition.pdf" download="F Line Petition">F Line Petition</a></li>
                    
      </ul>
      </div>
      <div class="row">
        <div class="col-lg-4">
      <h6 class="mb-3 mt-15"><b>Do you have Urban Property : </b></h6>
</div>
<div class="col-lg-8">
        <input type="radio" name="Property" value="Yes" id="yes" (change)="onChangeChecklist('yes','Property')">
        <label class="ml-1" for="yes">Yes</label>
    
        <input type="radio" class="ml-3" name="Property" value="No" id="no" (change)="onChangeChecklist('no','Property')">
        <label class="ml-1" for="no">No</label>
    </div>
    </div>
  
      <div *ngIf="showProperty">
        <div>The citizen can avail the following services/entitlements subject to final verification</div>
        <ul class="home-sub-menu">
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Newwaterconnection.pdf" download="New Water Connection">Water - New Water Connection</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/transferoftitle.pdf" download="Transfer of Title">Property Tax - Transfer of Title</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Newassessmentrequest.pdf" download="New Assessment Request">Property Tax - New Assessment Request</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Generalrevisionpetittion.pdf" download="General Revision Petition">Property Tax - General Revision Petition</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Additionalteration.pdf" download="Addition/Alteration">Property Tax - Addition/Alteration</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Electricalmeterconnections.pdf" download="Electrical Meter connection">Electrical Meter connection </a></li>
          <li><a target="_blank"  href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Billpayments.pdf" download="Bill Payments">Bill Payments</a></li>
          <li><a target="_blank"  href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Encumbrancecertificate.pdf" download="Encumbrance Certificate">Encumbrance Certificate</a></li>                    
      </ul>
      </div>

      <div class="row">
        <div class="col-lg-4">
      <h6 class="mb-3 mt-15"><b>Are you Married : </b></h6>
</div>
<div class="col-lg-8">
      
        <input type="radio" name="Marriage" value="Yes" id="yes" (change)="onChangeChecklist('yes','Marriage')">
        <label class="ml-1" for="yes">Married</label>
    
        <input type="radio" class="ml-3" name="Marriage" value="No" id="no" (change)="onChangeChecklist('no','Marriage')">
        <label class="ml-1" for="no">Single</label>
    </div>
     </div>
     
      <div *ngIf="showMarriageStatus">
        <div>The citizen can avail the following services/entitlements subject to final verification</div>
        <ul class="home-sub-menu">
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Marriagecertificate.pdf" download="Marriage Certificate">Marriage Certificate </a></li>                          
      </ul>
      </div>

      <div class="row">
        <div class="col-lg-4">
      <h6 class="mb-3 mt-15"><b>Are you Physically Challenged: </b></h6>
     </div>
     <div class="col-lg-8">
        <input type="radio" name="Physical" value="Yes" id="yes" (change)="onChangeChecklist('yes','Physical')">
        <label class="ml-1" for="yes">Yes</label>
    
        <input type="radio" class="ml-3" name="Physical" value="No" id="no" (change)="onChangeChecklist('no','Physical')">
        <label class="ml-1" for="no">No</label>
    </div>
    </div>
     
   
      <div *ngIf="showPhysical">
        <div>The citizen can avail the following services/entitlements subject to final verification</div>
        <ul class="home-sub-menu">
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Sadaramslotbooking.pdf" download="Sadaram Slot Booking">Sadaram Slot Booking </a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Sadaramcertificatedownload.pdf" download="Sadaram Certificate Download">Sadaram Certificate Download </a></li>                          
      </ul>
      </div>

      <div class="row">
        <div class="col-lg-4">
      <h6 class="mt-15"><b>Is your age more than 18 years: </b></h6></div>
      <div class="col-lg-8">
   
        <input type="radio" name="Age" value="Yes" id="yes" (change)="onChangeChecklist('yes','Age')">
        <label class="ml-1" for="yes">Yes</label>
    
        <input type="radio" class="ml-3" name="Age" value="No" id="no" (change)="onChangeChecklist('no','Age')">
        <label class="ml-1" for="no">No</label>
    </div>
    </div>
  
      <div *ngIf="showAge">
        <div>The citizen can avail the following services/entitlements subject to final verification</div>
        <ul class="home-sub-menu">
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Drivinglicense.pdf" download="Driving License">Driving License</a></li>                         
      </ul>
      </div>



      <div class="row">
        <div class="col-lg-4">
      <h6 class="mt-15"><b>Do you own a vehicle : </b></h6></div>
      <div class="col-lg-8">
   
        <input type="radio" name="vehicle" value="Yes" id="yes" (change)="onChangeChecklist('yes','vehicle')">
        <label class="ml-1" for="yes">Yes</label>
    
        <input type="radio" class="ml-3" name="vehicle" value="No" id="no" (change)="onChangeChecklist('no','vehicle')">
        <label class="ml-1" for="no">No</label>
    </div>
    </div>
  
      <div *ngIf="showVehicle">
        <div>The citizen can avail the following services/entitlements subject to final verification</div>
        <ul class="home-sub-menu">
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Drivinglicense.pdf" download="Driving License">Driving License</a></li>                         
      </ul>
      </div>

<div class="row">
        <div class="col-lg-4">
      <h6 class="mt-15"><b>Do you want to pay bills online : </b></h6></div>
      <div class="col-lg-8">
   
        <input type="radio" name="pay" value="Yes" id="yes" (change)="onChangeChecklist('yes','payBill')">
        <label class="ml-1" for="yes">Yes</label>
    
        <input type="radio" class="ml-3" name="pay" value="No" id="no" (change)="onChangeChecklist('no','payBill')">
        <label class="ml-1" for="no">No</label>
    </div>
    </div>
  
      <div *ngIf="showPaybills">
        <div>The citizen can avail the following services/entitlements subject to final verification</div>
        <ul class="home-sub-menu">
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Billpayments.pdf" download="Bill Payments">Water Tax</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Billpayments.pdf" download="Bill Payments">Electricity bills</a></li>                      
      </ul>
      </div>
      <div class="row">
        <div class="col-lg-4">
      <h6 class="mt-15"><b>Do you want to apply for revenue services : </b></h6></div>
      <div class="col-lg-8">
   
        <input type="radio" name="revenue" value="Yes" id="yes" (change)="onChangeChecklist('yes','revenue')">
        <label class="ml-1" for="yes">Yes</label>
    
        <input type="radio" class="ml-3" name="revenue" value="No" id="no" (change)="onChangeChecklist('no','revenue')">
        <label class="ml-1" for="no">No</label>
    </div>
    </div>
  
      <div *ngIf="showRevenue">
        <div>The citizen can avail the following services/entitlements subject to final verification</div>
        <ul class="home-sub-menu">
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Revenueservices.pdf" download="Revenue services">Revenue services</a></li>                         
      </ul>
      </div>
      <div class="row mb-3">
        <div class="col-lg-4">
      <h6 class="mt-15"><b>Do you want to apply for pensions : </b></h6></div>
      <div class="col-lg-8">
   
        <input type="radio" name="pension" value="Yes" id="yes" (change)="onChangeChecklist('yes','pension')">
        <label class="ml-1" for="yes">Yes</label>
    
        <input type="radio" class="ml-3" name="pension" value="No" id="no" (change)="onChangeChecklist('no','pension')">
        <label class="ml-1" for="no">No</label>
    </div>
    </div>
  
      <div *ngIf="showPensions">
        <div>The citizen can avail the following services/entitlements subject to final verification</div>
        <ul class="home-sub-menu">
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Pensions.pdf" download="Pensions">New pension application</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Pensions.pdf" download="Pensions">Pension card printing</a></li>
          <li><a target="_blank" href="https://gswsattendance.ap.gov.in/PortalUploads/Others/Pensions.pdf" download="Pensions">Pension social audit</a></li>                         
      </ul>
      </div>
    
    
      <div> <h6 class="mt-15"><b>Undertaking: We confirm that the list of schemes, services, and entitlements applicable to the citizen is comprehensive and that no additional schemes, services, or entitlements applicable to the citizen are required.</b></h6></div>
</ng-container>
    
  </div>
  <div class="modal-footer clear-both">
    <button type="button" *ngIf = "popup !== 'challanRemittance' && popup !== 'HardwareStatusforRegistrationofHouseSitePattas'" class="login-btn" (click)="activeModal.close('Close click')">Close</button>
    <button type="button" *ngIf = "popup === 'challanRemittance'" class="login-btn" (click)="onAccept()">Accepted</button>
    <button type="button"  *ngIf = "popup === 'HardwareStatusforRegistrationofHouseSitePattas'" class="login-btn" (click)="saveHouseHold()">Save</button>
  </div>