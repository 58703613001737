import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-issuance-of-passbooks-lpm-view',
  templateUrl: './issuance-of-passbooks-lpm-view.component.html',
  styleUrls: ['./issuance-of-passbooks-lpm-view.component.css']
})
export class IssuanceOfPassbooksLpmViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  @Output() viewImg = new EventEmitter();
  landDetails: any = [];
  jointDetails: any = [];
  pattadarDetails: any = [];
  lpmNumbers: any = '';
  constructor() { }

  ngOnInit(): void {
    this.landDetails  = this.certificateInfo?.pattadarLandDetailsList;
    this.jointDetails  = this.certificateInfo?.pattadarJointDetailsList;
    this.pattadarDetails  = this.certificateInfo?.pattadarDetailsList;
    this.landDetails.forEach((item:any, index: any) => {
      this.lpmNumbers += (index + 1) + ") " + item.lpM_No + " ";
    })
  }
  viewFile(file: any) {
    this.view.emit(file);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
  viewImgFile(file: any) {
    this.viewImg.emit(file);
  }

}
