
    <!--*********************************
Our ContactInfo part start
*******************************-->
<section id="contact-info">
    <div class="container mx-auto">
        <div class="content-text">
            <div class="row">

                <div class="col-lg-5 col-md-12 equalSpace">
                    <div class="cheader-center">
                        <h3>Our Manifesto</h3>
                    </div>

                    <div class="c-text-left">
                        <p class="nipa"> 
                            <img src="../../assets/images/Footer_logo.png" class="img_cls"/>ప్రజల ఇంట ముంగిటికి సేవలను అందించుటకు గ్రామ / వార్డు పాలనకు రాష్ట్రవ్యాప్తంగా 15,004 సచివాలయములు 02/10/2019 నుండి అందుబాటులోనికి వచ్చినవి. 
                        </p>
                        <p>ఇక పనులు ఆలస్యమవుతాయని బాధ ఉండదు. పేదవాడికి సంక్షేమం అందదనే బెంగ ఉండదు..
                        </p>
                        <ul>
                            <li><a href="#"><i class="fa fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                            <li><a href="#"><i class="fa fa-behance"></i></a></li>
                            <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                           
                        </ul>
                    </div>
                </div>



                <div class="col-lg-2 col-md-12 equalSpace">
                    <div class="cheader-center">
                        <h3>Sitemap</h3>
                    </div>

                    <div class="c-text-menu1">
                        <ul class="ul_padding">
                            <li><a routerLink="/home" routerLinkActive='active'>Home </a></li>
                            <li><a routerLink="/about-us" routerLinkActive='active'>About us</a></li>
                            <!-- <li><a href="#">Navaratnalu</a></li> -->
                            <li><a href="#">Dashboard</a></li>
                            <li><a href="#">Contact Us</a></li>
                        </ul>
                    </div>
                </div>



                <div class="col-lg-2 col-md-12 equalSpace">
                    <div class="cheader-center">
                        <h3>Useful Links</h3>
                    </div>
                    <div class="c-text-menu1">
                        <ul class="ul_padding">
                            <li><a href="our_teacher.html">Sachivalayam</a></li>
                            <li><a href="#"> Team Terms & Conditions</a></li>
                            <li><a href="blog_details.html"> News & Updates</a></li>
                            <li><a href="#"> Privacy Policy</a></li>
                            <li><a href="#"> Help</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-12 equalSpace">
                    <div class="cheader-center">
                        <h3>Contact Informations</h3>
                    </div>
                    <div class="c-text-location">
                        <div class="row">
                            <div class="col-lg-3  col-md-3">
                                <ul>
                                    <li class="li_format pb-3"><a href="#"><i class="fa fa-home"></i></a></li>
                                    <li class="li_format pb-3"><a href="#"><i class="fa fa-phone"></i></a></li>
                                    <li class="li_format pb-3"><a href="#"><i class="fa fa-envelope"></i></a></li>
                                </ul>
                            </div>
                            <div class="col-lg-9  col-md-9">
                                <div class="address">
                                    <p class="address_title pt-3">Location</p>
                                    <p></p>
                                    <p class="address_title  pt-3 mt-2">1902</p>
                                    <p></p>
                                    <p class="address_title  pt-2 mt-3">gsws-support@ap.gov.in</p>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--*********************************
Our ContactInfo part end
*******************************-->






<!--*********************************
Our footer part Start
*******************************-->
<section id="footer">
    <div class="footer1">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <p>Contents provided by Department of Rural Development, Govt. of Andhra Pradesh, India.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--*********************************
Our footer part end
*******************************-->

<div class="back_to_top" (click)="scrollToTop();">
    <i class="fa fa-angle-up "></i>
</div>
<!--*********************************
back to top end
*******************************-->