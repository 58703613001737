import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.css']
})
export class CaptchaComponent implements OnInit {
  errorMsg:any;
  generatedCode:any
  captchaInput:any;
  commonConstants: any = CommonConstants;
  captchaError: Boolean = false;
  captchaId: any;
  constructor(public activeModal:NgbActiveModal,private commonService: CommonService) { 
   }
  ngOnInit() {
    this.generateCaptcha();
  }
  reset(){

  }
   generateCaptcha() {
    this.commonService.postRequest(this.commonConstants.generateCaptcha, "",true).subscribe({
      next: (responseData:any)=>{
        if(typeof(responseData?.result) !== 'string'){
        let captchaData = responseData.result
        this.captchaId = captchaData.id
        this.generatedCode = captchaData.captcha
        }
      }
    })
    }
  
  validateCaptcha() {
    let posData = {
      captcha : this.captchaInput,
      Id: this.captchaId
    }
    this.commonService.postRequest(this.commonConstants.validateCaptcha,posData, true).subscribe({
      next: (responseData:any)=>{
        if (responseData.result === 'Success') {
          this.captchaError = false;
          this.errorMsg = 'captcha matched';
          this.activeModal.close('success');
        } else {
          this.captchaError = true;
          this.errorMsg = 'Please enter valid captcha';
        }
        
      }
    })
    
  }
}