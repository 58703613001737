import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { CommonService} from '../../shared/common.service';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants } from '../../constants/common-constants.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from '../../shared/common.service';
import { fileExtensionValidator, fileSizeValidator } from 'src/shared/validators/file-validator';
import { PdfViewerPopupComponent } from '../../shared/pdf-viewer-popup/pdf-viewer-popup.component';
@Component({
  selector: 'app-utilization-certificate',
  templateUrl: './utilization-certificate.component.html',
  styleUrls: ['./utilization-certificate.component.css'],
  providers: [NgbNavConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }]
})
export class UtilizationCertificateComponent implements OnInit {
  public submitted: Boolean = false;
  isUrban:Boolean=false;
  showFields:Boolean=false;
  showForm:Boolean=false;
  isHistory:Boolean=false;
  isMc:Boolean=false;
  isVishakapatnamMC:Boolean = false;
  utilizationForm : FormGroup;
  commonConstants : any = CommonConstants;  
  secCode: any;
  maxDate: any;
  minDate: any;
  userInformation:any;
  role:any;
  loggedInSecretariat: any
  designation:any;
  public uploadedDocument: any[] = [];
  public programsConducted:any[]=[];
  public programs:any[]=[];
  public programDetais:any[]=[];
  public history:any[]=[];
  @ViewChild('billLabel1')
  billLabel1!:ElementRef;
  @ViewChild('billLabel2')
  billLabel2!:ElementRef;
  @ViewChild('billLabel3')
  billLabel3!:ElementRef;
  @ViewChild('billLabel4')
  billLabel4!:ElementRef;
  @ViewChild('billLabel5')
  billLabel5!:ElementRef;
  @ViewChild('billLabel6')
  billLabel6!:ElementRef;
  @ViewChild('billLabel7')
  billLabel7!:ElementRef;
  @ViewChild('billLabel8')
  billLabel8!:ElementRef;
  @ViewChild('billLabel9')
  billLabel9!:ElementRef;
  @ViewChild('billLabel10')
  billLabel10!:ElementRef;
  @ViewChild('checkReceiptDocLabel')
  checkReceiptDocLabel!:ElementRef;
  currentDate = new Date();
  @ViewChild('utilizationDocLabel')
  utilizationDocLabel!:ElementRef;
  fileList:any[] = [];
  checkreceipt = {};
  utilizationCertificate = {};
  constructor(private config: NgbNavConfig, 
    private formBuilder: FormBuilder, 
    public commonService: CommonService, 
    private modalService: NgbModal ,
    private extendedFormBuilder: FormBuilder) {
    let userInfo = localStorage.getItem('userInfo');
    if (userInfo && userInfo !== null) {
     let userData = JSON.parse(userInfo);
      this.userInformation = userData;
      let userId = this.userInformation.userPrincipalName?.split('-');    
      let mcUserId = this.userInformation.userPrincipalName?.split('@');   
      //this.loggedinuserId = this.userInformation.userPrincipalName;
      this.role = userData.jobTitle;
      this.isMc = this.role === 'Municipal Commissioner' ? true : false;
      let vishakapatnamMc = mcUserId[0]?.charAt(0)?.toUpperCase() === 'U' ? mcUserId[0]?.split('U')[1] :  mcUserId[0]?.charAt(0)?.toUpperCase() === 'D' ? mcUserId[0]?.split('D')[1]: mcUserId[0];
      if( this.isMc ==  true && vishakapatnamMc !== '1086-MC'){
        this.isVishakapatnamMC = vishakapatnamMc.includes('1086-MC') ? true : false;
      }
      this.isUrban = this.role === 'Ward Admin Secretary' || this.role === 'Ward Education Secretary' ? true : false;
     
      if(userId?.length > 0 && this.isVishakapatnamMC  === false){
        this.loggedInSecretariat =  userId[0]?.charAt(0)?.toUpperCase() === 'U' ? userId[0]?.split('U')[1] :  userId[0]?.charAt(0)?.toUpperCase() === 'D' ? userId[0]?.split('D')[1]: userId[0];
        let designation1 = this.userInformation.userPrincipalName.split('-')[1];
        this.designation = designation1.split('@')[0];
      }      
      else if(mcUserId?.length >0 && this.isVishakapatnamMC == true){       
        vishakapatnamMc = vishakapatnamMc.replace("MC", "");
        this.loggedInSecretariat =vishakapatnamMc;
        this.designation ='MC'
      }   
       
        console.log(this.designation + 'designation')
         }
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.programsConducted=[{"value" : "Jagananna Suraksha"},
                            {"value" : "Jagananna Aarogya Suraksha"},
                            {"value" : "Aadudam Andhra"}
                                   ] 
    this.utilizationForm = this.formBuilder.group({  
      program:[null,Validators.required],
      accountNumber:[null,Validators.required],
      amount:['',Validators.required],
      utilizedAmount:['',Validators.required],
      unSpentBalance:['',Validators.required],
      dateOfRemittance :[''],
      checkNo:[''],
      checkReceiptDoc:[''],
      bill1:['',Validators.required],
      bill2:[''],
      bill3:[''],
      bill4:[''],
      bill5:[''],
      bill6:[''],
      bill7:[''],
      bill8:[''],
      bill9:[''],
      bill10:[''],
      utilizationDoc:['',[Validators.required]],
      consent: ['',[Validators.required]],      
      consent2: ['',[Validators.required]],
    })

  }
  

  ngOnInit(): void {
    this.getProgram();
    this.getHistory();
}
 

  // addFormGroup(): FormGroup {
  //   return this.formBuilder.group({
  //     originalBill:[null,[Validators.required]],    
   
  //   });
  // }
  
  get f(){  
    return this.utilizationForm.controls;
  }
  // get tableDatas() {
  //   return this.utilizationForm.get('tableData') as FormArray;
  // }
  
  // add() {
  //   if(this.tableDatas.length < 10){
  //     this.tableDatas.push(this.addFormGroup());
  //   }else{
  //     return;
  //   }
    
  // }
//   delete(index:number) {
//     this.tableDatas.removeAt(index);
//  }
  
  onChangeProgram(){
    if(this.f.program.value && this.f.program.valid){
      this.showForm =true;
     this.getAmount();
    }

  }
  getProgram(){
    this.secCode = localStorage.getItem('locationCode');
    
    this.commonService.getRequest(this.commonConstants.getProgramList + this.designation+'&SecretariatCode='+this.loggedInSecretariat)
      .subscribe({
        next: (responseData: any) => {
          if(responseData.result) {
            this.programs = responseData.result;                      
          } else {
            this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  getHistory(){
    this.commonService.getRequest(this.commonConstants.getUtilizationUploadDetails +  this.loggedInSecretariat)
      .subscribe({
        next: (responseData: any) => {
          console.log(responseData + 'get History')
          if(typeof(responseData.result) !== 'string') {
            if(responseData.result.length >0){
              this.isHistory =true;             
            }                  
              this.history=responseData.result;
              
          } else {
            this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  getAmount(){
    this.commonService.getRequest(this.commonConstants.getUtilizationAmount + this.loggedInSecretariat+'&ProgramName='+this.f.program.value )
      .subscribe({
        next: (responseData: any) => {
          console.log(responseData + 'responseData from getAmount')
          if(typeof(responseData.result) !== 'string') {
            this.f.amount.patchValue(responseData.result.releasedAmount);
            this.f.accountNumber.patchValue(this.commonService.RSADecrypt(responseData.result.accountNumber));
          } else {
            this.f.amount.patchValue('');
            this.f.accountNumber.patchValue('');
            this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  calculateUnSpent(){
    let amount :any=0;
    if(Number(this.f.amount.value) < Number(this.f.utilizedAmount.value)){
      this.commonService.commonErrorPopup('Alert', 'Total Amount Utilized is greater than Amount Released.', 'md');
          this.f.dateOfRemittance.patchValue(null);
          this.f.checkNo.patchValue('');
          this.f.checkReceiptDoc.patchValue(null);
          this.f.utilizedAmount.patchValue('');
          this.f.unSpentBalance.patchValue('');
          this.checkreceipt = {};
           this.checkReceiptDocLabel.nativeElement.innerText = 'No File Choosen';
      return;
    }else{
        amount = Number(this.f.amount.value) - Number(this.f.utilizedAmount.value);
        this.f.unSpentBalance.patchValue(amount);
        if(this.f.unSpentBalance.value > 0){
          this.showFields = true;
          this.f.dateOfRemittance.setValidators(Validators.required);
          this.f.checkNo.setValidators(Validators.required);
          this.f.checkReceiptDoc.setValidators(Validators.required);
        }else{
          this.showFields=false;
          this.f.dateOfRemittance.clearValidators();
          this.f.checkNo.clearValidators();
          this.f.checkReceiptDoc.clearValidators();
          this.f.dateOfRemittance.patchValue(null);
          this.f.checkNo.patchValue('');
          this.f.checkReceiptDoc.patchValue(null);
          this.checkreceipt = {};
           this.checkReceiptDocLabel.nativeElement.innerText = 'No File Choosen';
        }
        this.f.dateOfRemittance.updateValueAndValidity();
        this.f.checkNo.updateValueAndValidity();
        this.f.checkReceiptDoc.updateValueAndValidity();
      }
     
      }
  // onKeyupUnSpentBalance(){
  //   if(this.f.unSpentBalance.value > 0){
  //     this.showFields = true;
  //     this.f.dateOfRemittance.setValidators(Validators.required);
  //     this.f.checkNo.setValidators(Validators.required);
  //     this.f.checkReceiptDoc.setValidators(Validators.required);
  //   }else{
  //     this.showFields=false;
  //     this.f.dateOfRemittance.clearValidators();
  //     this.f.checkNo.clearValidators();
  //     this.f.checkReceiptDoc.clearValidators();
  //   }
  //   this.f.dateOfRemittance.updateValueAndValidity();
  //   this.f.checkNo.updateValueAndValidity();
  //   this.f.checkReceiptDoc.updateValueAndValidity();
  // }
 

  onFileChange(fileEvent: any, controlName: any, labelName:any) {
    const file: File | null = fileEvent.target.files[0];
    if(labelName === 'billLabel1'){
      this.billLabel1.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
      this.f.bill1.setValidators([
        Validators.required,
        fileExtensionValidator('pdf'),
        fileSizeValidator(fileEvent),
      ]);
      this.f.bill1.updateValueAndValidity(); 
      if (this.f.bill1.valid){
        this.fileUpload(file, this.f.ChallanDocument?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
      }
    }
      else if(labelName === 'billLabel2') {
      this.billLabel2.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
      this.f.bill2.setValidators([
        fileExtensionValidator('pdf'),
        fileSizeValidator(fileEvent),
      ]);
      this.f.bill2?.updateValueAndValidity();   
      
    if (this.f.bill2.valid){
      this.fileUpload(file, this.f.Form1?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
    }

    } else if (labelName === 'billLabel3') {
      this.billLabel3.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
      this.f.bill3.setValidators([
        fileExtensionValidator('pdf'),
        fileSizeValidator(fileEvent),
      ]);
      this.f.bill3?.updateValueAndValidity();
      if (this.f.bill3.valid){
        this.fileUpload(file, this.f.Authorization?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
      }
    }
     else if (labelName === 'billLabel4') {
      this.billLabel4.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
      this.f.bill4?.setValidators([
        fileExtensionValidator('pdf'),
        fileSizeValidator(fileEvent),
      ]);
      this.f.bill4?.updateValueAndValidity();
      if (this.f.bill4.valid){
        this.fileUpload(file, this.f.Aadhar?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
      }
     }
     else if(labelName === 'billLabel5') {
     this.billLabel5.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
     this.f.bill5.setValidators([
       fileExtensionValidator('pdf'),
       fileSizeValidator(fileEvent),
     ]);
     this.f.bill5?.updateValueAndValidity();   
     
   if (this.f.bill5.valid){
     this.fileUpload(file, this.f.Form1?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
   }

   } else if (labelName === 'billLabel6') {
     this.billLabel6.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
     this.f.bill6.setValidators([
       fileExtensionValidator('pdf'),
       fileSizeValidator(fileEvent),
     ]);
     this.f.bill6?.updateValueAndValidity();
     if (this.f.bill6.valid){
       this.fileUpload(file, this.f.Authorization?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
     }
   }
    else if (labelName === 'billLabel7') {
     this.billLabel7.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
     this.f.bill7?.setValidators([
       fileExtensionValidator('pdf'),
       fileSizeValidator(fileEvent),
     ]);
     this.f.bill7?.updateValueAndValidity();
     if (this.f.bill7.valid){
       this.fileUpload(file, this.f.Aadhar?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
     }
    }    
    else if(labelName === 'billLabel8') {
    this.billLabel8.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
    this.f.bill8.setValidators([
      fileExtensionValidator('pdf'),
      fileSizeValidator(fileEvent),
    ]);
    this.f.bill8?.updateValueAndValidity();   
    
  if (this.f.bill8.valid){
    this.fileUpload(file, this.f.Form1?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
  }

  } else if (labelName === 'billLabel9') {
    this.billLabel9.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
    this.f.bill9.setValidators([
      fileExtensionValidator('pdf'),
      fileSizeValidator(fileEvent),
    ]);
    this.f.bill9?.updateValueAndValidity();
    if (this.f.bill9.valid){
      this.fileUpload(file, this.f.Authorization?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
    }
  }
   else if (labelName === 'billLabel10') {
    this.billLabel10.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
    this.f.bill10?.setValidators([
      fileExtensionValidator('pdf'),
      fileSizeValidator(fileEvent),
    ]);
    this.f.bill10?.updateValueAndValidity();
    if (this.f.bill10.valid){
      this.fileUpload(file, this.f.Aadhar?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
    }
   }  else if (labelName === 'checkReceiptDocLabel') {
    this.checkReceiptDocLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
    this.f.checkReceiptDoc.setValidators([
      fileExtensionValidator('pdf'),
      fileSizeValidator(fileEvent),
    ]);
    this.f.checkReceiptDoc?.updateValueAndValidity();
    if (this.f.checkReceiptDoc.valid){
      this.fileUpload(file, this.f.Authorization?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
    }
  }
   else if (labelName === 'utilizationDocLabel') {
    this.utilizationDocLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
    this.f.utilizationDoc?.setValidators([
      fileExtensionValidator('pdf,doc,docx'), fileSizeValidator(fileEvent),
    ]);
    this.f.utilizationDoc?.updateValueAndValidity();
    if (this.f.utilizationDoc.valid){
      this.fileUpload(file, this.f.Aadhar?.value, fileEvent.target.files[0].name, controlName, controlName, labelName);
    }
   }  
      
     
}
fileUpload(file: any, filePath: string, fileName: string, documentType: string, name:string, label:string) {
  const formData = new FormData();
  formData.append('File', file, fileName);
  formData.append('ProgramName','');
  formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
  formData.append('DocumentType',documentType);
  formData.append('SecretariatCode',this.loggedInSecretariat);
  console.log(formData, 'formData');
  this.commonService.postUploadedFileRequest(this.commonConstants.fileUploadForUtilization, formData).subscribe({
      next: (responseData: any) => {
        if(typeof(responseData.result) !== 'string'){
          if (label === 'utilizationDocLabel') {
            this.utilizationCertificate = {
              "blobRef": responseData.result.blobRef,
              "id": responseData.result.id,
              "fileName": responseData.result.fileName
          }
            responseData.result;
          } else if (label === 'checkReceiptDocLabel') {
            this.checkreceipt ={
              "blobRef": responseData.result.blobRef,
              "id": responseData.result.id,
              "fileName": responseData.result.fileName
          };
          } else {
            this.fileList.push({
              "blobRef": responseData.result.blobRef,
              "id": responseData.result.id,
              "fileName": responseData.result.fileName
          });
          }
          // let index = this.fileList.map((item) => item.DocumentType).indexOf(documentType);
          // if (index > -1) {
          //   this.fileList.splice(index, 1);
          // }
          // this.fileList.push({
          //   FileName: fileName,
          //   DocumentType: documentType,
          //   BlobRef: responseData['result'].blobRef,
          // });
          // console.log(this.fileList)
        } else{
          if(name && label) {
            this.clearFileUpload(name, label);
          }
          this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
        }
        console.log(this.fileList, 'FileList');
      },
      error: (error) => {
        if(name && label) {
          this.clearFileUpload(name, label);
        }
        console.log('client side', error);
      },
    });
}


clearFileUpload(name: string, label: string) {    
  this.f[name]?.reset();
  let index = this.fileList.map((item) => item.DocumentType).indexOf(name);
  if (index > -1) {
    this.fileList.splice(index, 1);
  }
  if(label ==='billLabel1'){
    this.billLabel1.nativeElement.innerText = 'No File Choosen';
    this.f.bill1.setValidators([Validators.required]);
  }
   else if(label === 'billLabel2') {
    this.billLabel2.nativeElement.innerText = 'No File Choosen';
    this.f.bill2.clearValidators();    
  } else if (label === 'billLabel3') {
    this.billLabel3.nativeElement.innerText = 'No File Choosen';
    this.f.bill3.clearValidators(); 
  }
  else if(label === 'billLabel4') {
    this.billLabel4.nativeElement.innerText = 'No File Choosen';
    this.f.bill4.clearValidators(); 
  } else if (label === 'billLabel5') {
    this.billLabel5.nativeElement.innerText = 'No File Choosen';
    this.f.bill5.clearValidators(); 
  }
  else if(label === 'billLabel6') {
    this.billLabel6.nativeElement.innerText = 'No File Choosen';
    this.f.bill6.clearValidators(); 
  } else if (label === 'billLabel7') {
    this.billLabel7.nativeElement.innerText = 'No File Choosen';
    this.f.bill7.clearValidators(); 
  }
  else if(label === 'billLabel8') {
    this.billLabel8.nativeElement.innerText = 'No File Choosen';
    this.f.bill8.clearValidators(); 
  } else if (label === 'billLabel9') {
    this.billLabel9.nativeElement.innerText = 'No File Choosen';
    this.f.bill9.clearValidators(); 
  }  
  else if (label === 'billLabel10') {
    this.billLabel10.nativeElement.innerText = 'No File Choosen';
    this.f.bill10.clearValidators(); 
  }
  else if(label === 'checkReceiptDocLabel') {
    if(this.checkReceiptDocLabel) {
      this.checkReceiptDocLabel.nativeElement.innerText = 'No File Choosen';
    }
    this.f.checkReceiptDoc.setValidators([Validators.required]);    
  } else if (label === 'utilizationDocLabel') {
    this.f.utilizationDoc.setValidators([Validators.required, fileExtensionValidator('pdf,doc,docx')]);
    this.utilizationDocLabel.nativeElement.innerText = 'No File Choosen';
  }  
  this.f[name]?.updateValueAndValidity();  
}
updateFileUpload(id: any) {
  let fileData = {
    FileList: this.fileList,
    RequisitionId: id,
    
  }  
  this.commonService.postRequest(this.commonConstants.updateFileUpload,fileData).subscribe({
    next: (resData:any) => { 
      console.log(resData,'File Upload  Respose data');
      if(resData.result?.updateResult == 'Success') {
      }  
    },
    error: (error) => {
      console.log('client side', error);
    }
  });
}
numberMasking(accountNumber:any) {
  if(accountNumber.length <=4) { return accountNumber;}
  var accountNumbers = accountNumber.split(',');
  var maskedacnos:any[] = [];
  accountNumbers.forEach((acno:any) => {
    if(acno.length <=4) {
      maskedacnos.push(acno); 
    } else {
      var mask = "";
      if(acno){
        for(let j=1; j<=acno.length-4; j++){
          mask += "x";
        }
        maskedacnos.push( mask + acno.slice(acno.length-4 , acno.length));
      }
    }
  })  
  return maskedacnos.join(', ');
}
  submit(){
    if (this.utilizationForm.invalid) {
      this.submitted = true;
      return;
    }else if(Number(this.f.amount.value) < Number(this.f.utilizedAmount.value)){
      this.commonService.commonErrorPopup('Alert', 'Total Amount Utilized is greater than Amount Released.', 'md');
      return;
    }else{
      this.submitted = false;
      let postData = {
        programName:this.f.program.value,
        secretaryCode:this.loggedInSecretariat,
        accountNumber:this.commonService.RSAEncrypt(this.f.accountNumber?.value),
        amountReleased:this.f.amount?.value,
        totalAmountUtilized:this.f.utilizedAmount?.value,
        unSpendBalance:this.f.unSpentBalance?.value.toString(),
        dateRemittanceBack: this.f.dateOfRemittance?.value ?this.commonService.formatDate(this.f.dateOfRemittance?.value): null,
        chequeNo:this.f.checkNo.value,
        chequeReceiptFile: this.checkreceipt,
        uploadedBills: this.fileList,
        utilizationCertificateFile: this.utilizationCertificate
      }  
      this.commonService.postRequest(this.commonConstants.saveUtilizationUploadData,postData).subscribe({
        next: (responseData:any) => { 
          if(responseData?.result?.isSuccess) {
            this.commonService.commonErrorPopup('Alert', 'Utilization certificate details is saved successfully.', 'md');
            this.getHistory();
            this.getProgram();
            this.showForm= false;
            this.resetForm();            
          } else if(responseData?.result?.isSuccess == false && responseData?.result?.error) {
            this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md')
          } else {
            this.commonService.commonErrorPopup('Alert', 'An error occured while saving the utilization certificate details. Please try again.', 'md')
          }  
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }
}
resetForm(){
  this.utilizationForm.reset();
  this.fileList=[];
  this.checkreceipt={};
  this.utilizationCertificate={};
  this.clearFileUpload('bill1', 'billLabel1');
  this.clearFileUpload('bill2', 'billLabel2');
  this.clearFileUpload('bill3', 'billLabel3');
  this.clearFileUpload('bill4', 'billLabel4');
  this.clearFileUpload('bill5', 'billLabel5');
  this.clearFileUpload('bill6', 'billLabel6');
  this.clearFileUpload('bill7', 'billLabel7');
  this.clearFileUpload('bill8', 'billLabel8');
  this.clearFileUpload('bill9', 'billLabel9');
  this.clearFileUpload('bill10', 'billLabel10');
  this.clearFileUpload('checkReceiptDoc', 'checkReceiptDocLabel');
  this.clearFileUpload('utilizationDoc', 'utilizationDocLabel');
}
downloadFile(file:any) {
  this.commonService.getRequest(this.commonConstants.fileDownload + file.id).subscribe({
    next: (responseData: any) => { 
        this.commonService.downloadPdfFile(responseData.result.fileContents, file.filename)
    },
    error: (error) => {
      console.log('client side', error);
    }
});
}
viewFile(blobRef:any,fileName?:any) {
  this.commonService.getRequest(this.commonConstants.viewFile + blobRef).subscribe({
    next: (responseData: any) => { 
      let fileInfo =  responseData.result.fileContents;             
      const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
      modalRef.componentInstance.sourceFile = fileInfo;
      modalRef.componentInstance.showPrintDownload = true;
      
    },
    error: (error) => {
      console.log('client side', error);
    }
});
}
}
