<div class="reportContainer">
<div [formGroup]="form">
        <div class="row mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
            <select class="selectCls"  formControlName="district" (change)="onDistrictChange()"
            [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
                <option value="All">All</option>
                <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
            </select>  
            </div>
           
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Mandal <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
              [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
            <option value="All">All</option>
            <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
            </select>
         
            </div>

            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Rural/Urban : <span class="mandatoryCls">*</span></label> 
                <select class="selectCls" required formControlName="RUFlag"
                [ngClass]="{ 'is-invalid':submitted && (f.RUFlag.errors || f.RUFlag.value === 'Select') }">
                <option value = "All">All</option>
                <option value = "R">Rural</option>
                <option value = "U">Urban</option>
                </select>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Secretariat: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="village" (change)="onVillageChange()" >
                <option value="All">All</option>
                <option *ngFor="let village of villages" [ngValue]="village">{{village.secretariatName}}</option>
            </select>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">From Date: <span class="mandatoryCls">*</span></label>
                <div class="input-group">
                  <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                         name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" (dateSelect)="onFromDateSelect()"
                         [maxDate]="maxDate" required formControlName="fromDate"
                         [ngClass]="{ 'is-invalid':submitted && f.fromDate.errors }">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                  </div>
                </div>
                <div *ngIf="submitted && f.fromDate.errors" class="selectError">
                  <div *ngIf="f.fromDate.errors?.required || f.fromDate.invalid">Please enter valid From Date</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">To Date: <span class="mandatoryCls">*</span></label>
                <div class="input-group">
                  <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                         name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="toMinDate" (dateSelect)="onToDateSelect()"
                         [maxDate]="maxDate" required formControlName="toDate"
                         [ngClass]="{ 'is-invalid':submitted && f.toDate.errors }">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
                  </div>
                </div>
                <div *ngIf="submitted && f.toDate.errors" class="selectError">
                  <div *ngIf="f.toDate.errors?.required || f.toDate.invalid">Please enter valid To Date</div>
              </div>
              </div>

        </div>

        <div class="tabClass">
            <ul>
                <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li> 
            </ul>
            <div class="btnHolder">
                <button  class="greenBtnCls float-right my-3" (click)="getReissuanceReport()">Get Reports</button>
                <button  class="greenBtnCls float-right mr-3 my-3" (click)="downloadExcel()" *ngIf="items && items?.length > 0 && showReports">Download Excel</button>
                <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
            </div>
        </div>
    </div>


    <div class="table-responsive tableFixHead" *ngIf="showReports">
        <table class="tableCls">
            <thead>
                <tr>
                    <th [attr.colspan]="noOfCol">Reissuance Integrated Report from {{f.fromDate.value}} to  {{f.toDate.value}}</th>
                </tr>
                <tr>
                    <th rowspan="3">S.No</th>
                    <th rowspan="3" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District Name <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="3" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal Name <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="3" [mdbTableSort]="items" [sortBy]="'ruraL_URBAN_FLAG'" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Rural/Urban <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="3" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'" *ngIf="format === 'Secretariat Wise'">Secretariat Name <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="1" [mdbTableSort]="items" [sortBy]="'applicatioN_FILED'">Applications Filed</th>		
                    <th rowspan="1" [mdbTableSort]="items" [sortBy]="'regulaR_CASTE'">Regular Caste Certificates</th>
                    <th rowspan="1" [mdbTableSort]="items" [sortBy]="'suomotO_CASTE'">Suomotto Caste Issued</th>
                </tr>
            </thead>
            <tbody *ngIf="items && items?.length > 0">
                <ng-container>
                    <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                        <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                        <td>{{ item.districT_NAME}}</td>
                        <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{ item.mandaL_NAME }}</td>
                        <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.ruraL_URBAN_FLAG}}</td>
                        <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
                        <td>{{item.applicatioN_FILED}}</td>
                        <td>{{item.regulaR_CASTE}}</td>
                        <td>{{item.suomotO_CASTE}}</td> 
                    </tr>
                    <tr *ngIf="totalItems && totalItems?.length > 0">
                        <td></td>
                        <td>{{ totalItems[0].districT_NAME}}</td>
                        <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{ totalItems[0].mandaL_NAME }}</td>
                        <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].ruraL_URBAN_FLAG}}</td>
                        <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
                        <td>{{totalItems[0].applicatioN_FILED}}</td>
                        <td>{{totalItems[0].regulaR_CASTE}}</td>
                        <td>{{totalItems[0].suomotO_CASTE}}</td>
                    </tr>
                </ng-container>
            </tbody> 
            <ng-container>
                <tbody>
                    <tr *ngIf="items?.length == 0">
                        <td [attr.colspan]="noOfCol" class="text-center" class="text-center">No records found.</td>
                    </tr>
                </tbody>
            </ng-container>
        </table>
    </div>
</div>
    <div class="table-responsive tableFixHead" #TABLE *ngIf="showReports" class="hideTable">
        <table class="tableCls">
            <thead>
                <tr>
                    <th [attr.colspan]="noOfCol">Reissuance Integrated Report from {{f.fromDate.value}} to  {{f.toDate.value}} </th>
                </tr>
                <tr>
                    <th>S.No</th>
                    <th [mdbTableSort]="items" [sortBy]="'districT_NAME'">District Name <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th [mdbTableSort]="items" [sortBy]="'mandaL_NAME'" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal Name <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th [mdbTableSort]="items" [sortBy]="'ruraL_URBAN_FLAG'" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Rural/Urban <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'" *ngIf="format === 'Secretariat Wise'">Secretariat Name <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th [mdbTableSort]="items" [sortBy]="'applicatioN_FILED'">Applications Filed</th>		
                    <th [mdbTableSort]="items" [sortBy]="'regulaR_CASTE'">Regular Caste Certificates</th>
                    <th [mdbTableSort]="items" [sortBy]="'suomotO_CASTE'">Suomotto Caste Issued</th>
                </tr>
        </thead>
            <tbody *ngIf="items && items?.length > 0">
                <ng-container>
                    <tr *ngFor="let item of items; let i = index;">
                        <td>{{(i + 1)}}</td>
                        <td>{{ item.districT_NAME}}</td>
                        <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{ item.mandaL_NAME }}</td>
                        <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.ruraL_URBAN_FLAG}}</td>
                        <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
                        <td>{{item.applicatioN_FILED}}</td>
                        <td>{{item.regulaR_CASTE}}</td>
                        <td>{{item.suomotO_CASTE}}</td> 
                    </tr>
                    <tr *ngIf="totalItems && totalItems?.length > 0">
                        <td></td>
                        <td>{{ totalItems[0].districT_NAME}}</td>
                        <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{ totalItems[0].mandaL_NAME }}</td>
                        <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].ruraL_URBAN_FLAG}}</td>
                        <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
                        <td>{{totalItems[0].applicatioN_FILED}}</td>
                        <td>{{totalItems[0].regulaR_CASTE}}</td>
                        <td>{{totalItems[0].suomotO_CASTE}}</td>
                    </tr>
                </ng-container>
            </tbody> 
            <ng-container>
                <tbody>
                    <tr *ngIf="items?.length == 0">
                        <td [attr.colspan]="noOfCol" class="text-center" class="text-center">No records found.</td>
                    </tr>
                </tbody>
            </ng-container>
        </table>
    </div>
    
    <div class="mt-4 btnHide" *ngIf="showReports">
        <div class="float-left" *ngIf="items?.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items?.length > 0" [collectionSize]="items?.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
