<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
            GSWS / Application Submission / Revenue Department <span class="activePage">/ Challan Payment</span>
        </div>
    </div>
    <div class="mainPageContainer mr-3">
        <h6 class="mb-3 text-center"><b>Challan Payment Issue Tracker</b></h6>
        <form [formGroup]="paymentReconciliation">
            <hr class="hrCls mt-4">
            <div class="subHeadingCls mb-3">Search Criteria</div>
            <div class="row mb-3">
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <label class="labelCls">From Date :
                        <span class="mandatoryCls">*</span></label>
                    <div class="input-group">
                        <input class="calendarCls form-control" placeholder="dd/mm/yyyy" name="d" ngbDatepicker
                            [minDate]="FromminDate" [maxDate]="FrommaxDate" #d="ngbDatepicker" required
                            formControlName="periodFrom"
                            [ngClass]="{ 'is-invalid': submitted && f.periodFrom.errors }" />
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                type="button"></button>
                        </div>
                    </div>
                    <div *ngIf="submitted && f.periodFrom.errors" class="selectError">
                        <div *ngIf="f.periodFrom.errors?.required || f.periodFrom.invalid">
                            Please enter valid period From
                        </div>
                    </div>
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <label class="labelCls">To Date :
                        <span class="mandatoryCls">*</span></label>
                    <div class="input-group">
                        <input class="calendarCls form-control" placeholder="dd/mm/yyyy" name="e" ngbDatepicker
                            [minDate]="TominDate" [maxDate]="TomaxDate" #e="ngbDatepicker" required
                            formControlName="periodTo" [ngClass]="{ 'is-invalid': submitted && f.periodTo.errors }" />
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="e.toggle()"
                                type="button"></button>
                        </div>
                    </div>
                    <div *ngIf="submitted && f.periodTo.errors" class="selectError">
                        <div *ngIf="(f.periodTo.errors?.required || f.periodTo.invalid) && !f.periodTo.errors?.invalidDate">
                            Please enter valid period To
                        </div>
                        <div *ngIf="f.periodTo.errors?.invalidDate">
                            Period To Date should be greater than Period From Date.
                        </div>
                    </div>
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <label class="labelCls">Mode Of Payment:<span class="mandatoryCls">*</span></label>
                    <select class="selectCls" formControlName = "payment_mode" 
                    [ngClass]="{ 'is-invalid':submitted && f.payment_mode.errors }" required appBlockCopyPaste>
                        <option  [ngValue]= "'All'">All</option>
                        <option *ngFor="let paymentMode of paymentModes" [ngValue]="paymentMode.value">{{paymentMode.title}}
                    </select> 
                    <div *ngIf="submitted && f.payment_mode.errors" class="selectError">
                        <div *ngIf="f.payment_mode.errors">Please select Mode Of Payment.</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <label class="labelCls">Audit Status:<span class="mandatoryCls">*</span></label>
                    <select class="selectCls" formControlName = "status" 
                    [ngClass]="{ 'is-invalid':submitted && f.status.errors }" required appBlockCopyPaste>
                        <option  [ngValue]= "'All'">All</option>
                        <option  [ngValue]= "'Open'">Open</option>
                        <option  [ngValue]= "'Approved'">Approved</option>
                        <option  [ngValue]= "'Rejected'">Rejected</option>
                    </select> 
                    <div *ngIf="submitted && f.status.errors" class="selectError">
                        <div *ngIf="f.status.errors">Please select Audit Status.</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3 ">
                <div class="btnHolder text-center col-12">
                    <button class="btn btn-primary mx-auto d-block" (click)="submit()">Search</button>
                </div>
            </div>
        </form>
        <div *ngIf="showDetails">
            <hr class="hrCls mt-4">
            <div class="subHeadingCls mb-4 ol col-lg-12 col-md-12">
                <!-- <button class="btn btnB btn-primary pull-right btnHide tabMargin" (click)="printData('printData')" *ngIf="items && items.length > 0">Print </button>                 -->
            </div>
            <div class="row" id="printData">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">     
                    <div class="table-responsive tableFixHead" *ngIf="items && items.length > 0">         
                        <table class="tableCls">
                            <thead>
                                <tr>
                                    <th>Serial No</th>
                                    <th>Challan Number</th>
                                    <th>No. Of Transactions</th>
                                    <th>Challan Date</th>
                                    <th>Challan Amount</th>
                                    <th>Payment Date</th>
                                    <th>Transferred Amount</th>
                                    <th>Transferred Bank</th>
                                    <th>Reference / Transaction ID</th>
                                    <th>Challan Status</th>
                                    <th>Refund Status</th>
                                    <th>DA/WEDS Remarks</th>
                                    <th>Auditor Remarks</th>
                                    <th>Audit Status</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="items && items.length > 0">                         
                                <tr *ngFor="let item of items; let i = index;">
                                    <td> {{ item.slNo }} </td>
                                    <td> {{ item.challanNo }} </td>
                                    <td> {{ item.noOfServices }} </td>
                                    <td> {{ item?.challanDate | date: 'dd/MM/yyyy'}}</td>
                                    <td> {{ item.challanAmount }} </td>
                                    <td> {{ item?.dateOfPaymentToGSWS | date: 'dd/MM/yyyy'}}</td>
                                    <td> {{ item.amountTransferredToGSWS }} </td>
                                    <td> {{ item.amountTransferredToBank }} </td>
                                    <td> {{ item.transactionId }} </td>
                                    <td> {{ item.challanStatus }} </td>
                                    <td> {{ item.refundStatus }} </td>
                                    <td class="pre">{{ item.dA_WEDS_Remarks }} </td>
                                    <td class="pre">{{ item.auditorRemarks }} </td>
                                    <td> {{ item.reconciliationStatus ? item.reconciliationStatus : 'Open' }} </td>
                                </tr>
                            </tbody>
                        </table>                        
                    </div>
                    <div class="text-center selectError clsSize"
                        *ngIf="items && items.length == 0">
                        No records available...
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>