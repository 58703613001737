<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
            GSWS /Application Submission / Health And Family Welfare Department<span class="activePage">/ New Aarogyasri Card</span>
        </div>
    </div>
    <div class="mainPageContainer mr-3">
        <h6 class="mb-3" *ngIf = "isNewEnrollment">New Enrollment For Aarogyasri Health Card</h6>
        <h6 class="mb-3" *ngIf = "!isNewEnrollment">Existing Application For Aarogyasri Health Card</h6>
        <form [formGroup]="aarogyaSri">
            <div class="row mb-3">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12"> 
                    <label class="labelCls">Application Type:<span class="mandatoryCls">*</span></label>
                    <div class="col-sm-12">
                        <input type="radio" formControlName="appType" id="newEnrollApp" required value = "newEnrollApp" (click)="newEnrollSelected()" name="appType"><label for="newEnrollApp" class="mx-2">New Enrollment For Aarogyasri Health Card</label>
                       <!-- <input type="radio" formControlName="appType" id="existingApp" required value = "existingApp" (click)="existingSelected()" name="appType"><label for="existingApp" class="mx-2">Existing Application For Aarogyasri Health Card</label> -->
                    </div>
                </div>
            </div>
            <ng-container *ngIf = "isNewEnrollment">
                <nav ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event);clearFileUpload('familyPhoto', 'familyPhotoLabel');">
                    <ng-container [ngbNavItem] = "1">
                        <a ngbNavLink>Basic Details</a>
                        <ng-template ngbNavContent>
                            <app-common-form formControlName="commonForm" [department]="'HF'" [service]="'newAarogya-sri'"  [isSubmitted]="commonFormSubmitted" (reset)="commonFormSubmitted = false"></app-common-form>
                            <div class="btnHolder">
                            <button  class="greenBtnCls float-right" (click)="goToNextTab(); clearFileUpload('familyPhoto', 'familyPhotoLabel');">Continue</button>
                            </div>
                        </ng-template>
                    </ng-container>
                    <ng-container [ngbNavItem] = "2">
                        <a ngbNavLink>Application Form</a>
                        <ng-template ngbNavContent>
                            <div class="row mb-3">
                                    <div class="col-lg-2">
                                        <label class="labelCls">Volunteer Name:</label>
                                    </div>
                                    <div class="col-lg-4" style="margin-left: -7%;">
                                        <label class="labelCls">{{ this.volunteerName }}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-2">
                                        <label class="labelCls">Application Date:</label>
                                                                           </div>
                                                                           <div>
                                        <div class="input-group col-lg-12" style="margin-left: -36%;">
                                         <input class="calendarCls form-control" placeholder="dd/mm/yyyy" formControlName="appDate" [attr.disabled]= "true">
                                          </div>
                               </div>
                           
                                    <!-- <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <label class="labelCls">Please enter aadhaar card number: <span class="mandatoryCls">*</span></label>
                                        <input type="text" name="aadharNo" formControlName="aadharNo" required class="inputCls"   numbersOnly
                                        [ngClass]="{ 'is-invalid':submitted && f.aadharNo.errors }"  maxlength="12" minlength="12">
                                        <div *ngIf="submitted && f.aadharNo.errors" class="invalid-feedback">
                                            <div *ngIf="f.aadharNo.errors?.required || f.aadharNo.errors?.whiteSpaceValidate">Aadhaar Number is required</div>
                                            <div *ngIf="f.aadharNo.errors?.aadharValidate || f.aadharNo.errors?.minlength || f.aadharNo.errors?.whiteSpaceValidate">Please enter valid Aadhaar Number</div>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="row mb-3" >
                                    <div class="col">
                                        <div class="tab tableScroll">
                                            <hr class="hrCls mt-4">
                                            <h6 class="mb-3">Family Details </h6>
                                            <table class="tableCls">
                                                <thead>
                                                    <tr>
                                                    <th>Serial No</th>
                                                    <th>Member Name</th>
                                                    <th>Telugu Name</th>
                                                    <th>Gender<span class="mandatoryCls">*</span></th>
                                                    <th>Relation with the Family Head<span class="mandatoryCls">*</span></th>
                                                    <th>Date of Birth<span class="mandatoryCls">*</span></th>
                                                    <th>Age</th>
                                                    <th>Mobile Number<span class="mandatoryCls">*</span></th>
                                                    <th>Aadhaar Number<span class="mandatoryCls">*</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody formArrayName="familyDetailsTableData">
                                                    <tr [formGroupName]="i" *ngFor="let member of familyDetailsTableDatas.controls; let i = index">
                                                    <td class="serial">{{i+1}}</td>
                                                    <td>
                                                        {{this.familyDetailsTableDatas.controls[i].get('tbName')?.value}}
                                                    </td>
                                                    <td>
                                                        <input type="text" class="inputCls value" maxlength="50" [attr.disabled] = "(this.familyDetailsTableDatas.controls[i].get('IS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH' ) ? '' : null" 
                                                         name="tbTeluguName" id="tbTeluguName" required [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbTeluguName')?.errors }" formControlName = "tbTeluguName" (blur) = "checkText($event , i)" (keypress)="matcher($event)">
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbTeluguName')?.errors" class="invalid-feedback">
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbTeluguName')?.errors?.required">Please enter Telugu Name</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <select class="selectCls" name="tbGender" id="tbGender" required formControlName = "tbGender" 
                                                        [attr.disabled]= "true" [ngClass]="{ 'is-invalid':submitted && (familyDetailsTableDatas.controls[i].get('tbGender')?.errors || this.familyDetailsTableDatas.controls[i].get('tbGender')?.value === 'Select') }">
                                                            <option [ngValue]="null" disabled>Select</option>
                                                            <option *ngFor="let gender of genders" [ngValue]="gender.name">{{gender.name}}
                                                        </select>
                                                        <div *ngIf="submitted && (this.familyDetailsTableDatas.controls[i].get('tbGender')?.errors || this.familyDetailsTableDatas.controls[i].get('tbGender')?.value === 'Select')" class="selectError">
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbGender')?.errors?.required || this.familyDetailsTableDatas.controls[i].get('tbGender')?.value === 'Select'">Please select Gender</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <select class="selectCls" [attr.disabled] = "((this.familyDetailsTableDatas.controls[i].get('tbSelf')?.value === true) || (this.familyDetailsTableDatas.controls[i].get('IS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH' )) ? '' : null"  name="tbRelation" id="tbRelation" required formControlName = "tbRelation" (change) = "relationValidator(i)" [ngClass]="{ 'is-invalid':submitted && (familyDetailsTableDatas.controls[i].get('tbRelation')?.errors || this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'Select') }">
                                                            <option [ngValue]="null" disabled>Select</option>
                                                            <ng-container *ngIf="this.familyDetailsTableDatas.controls[i].get('tbGender')?.value ==='MALE' ">
                                                                
                                                                <option *ngFor="let relation of maleRelations"
                                                                [ngValue]="relation.name">{{relation.name}}</option>
                                                            </ng-container>
                                                            <ng-container *ngIf="this.familyDetailsTableDatas.controls[i].get('tbGender')?.value ==='FEMALE' ">
                                                                <option *ngFor="let relation of femaleRelations"
                                                                [ngValue]="relation.name">{{relation.name}}</option>
                                                            </ng-container>
                                                            <ng-container *ngIf="this.familyDetailsTableDatas.controls[i].get('tbGender')?.value ==='TRANSGENDER' ">
                                                                <option *ngFor="let relation of relations"
                                                                [ngValue]="relation.name">{{relation.name}}</option>
                                                            </ng-container>
                                                            <!-- <option *ngFor="let relation of relations" [ngValue]="relation.name">{{relation.name}} -->
                                                        </select>
                                                        <div *ngIf="submitted && (this.familyDetailsTableDatas.controls[i].get('tbRelation')?.errors || this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'Select')" class="selectError">
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbRelation')?.errors?.required || this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'Select'">Please select Relation</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-group">
                                                            <input class="calendarCls form-control" placeholder="dd/mm/yyyy" 
                                                                name="d"  ngbDatepicker #d="ngbDatepicker" [minDate]="minDate" required
                                                                [maxDate]="maxDate"  formControlName="tbDob" maxlength="20"
                                                                [attr.readonly]="true"
                                                                appBlockCopyPaste (change) = "dateChange($event , i)" (dateSelect) = "dateChangeToggle($event , i)"
                                                                [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbDob')?.errors }" >
                                                            <div class="input-group-append">
                                                            <button disabled class="btn btn-outline-secondary calendar" (click)="d.toggle()"  type="button"></button>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbDob')?.errors" class="invalid-feedback">
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbDob')?.errors?.required">Please select Date of Birth</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="inputCls"  appBlockCopyPaste  name="tbAge" id="tbAge" formControlName = "tbAge" maxlength="3" numbersOnly readonly>
                                                    </td>
                                                    <!--<td>
                                                        <input type="text" class="inputCls value" name="tbMaskAadharNo" id="tbMaskAadharNo" appBlockCopyPaste required [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbMaskAadharNo')?.errors }" formControlName = "tbMaskAadharNo" maxlength="12" minlength="12" numbersOnly>
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbMaskAadharNo')?.errors" class="invalid-feedback">
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbMaskAadharNo')?.errors?.required  || this.familyDetailsTableDatas.controls[i].get('tbMaskAadharNo')?.errors?.whiteSpaceValidate">Please enter Aadhaar Number</div>
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbMaskAadharNo')?.errors?.aadharValidate  || this.familyDetailsTableDatas.controls[i].get('tbMaskAadharNo')?.errors?.minlength">Please enter valid Aadhaar Number</div>
                                                        </div>
                                                    </td> -->
                                                    <td>
                                                        <input type="text" class="inputCls value" name="tbMobileNo" id="tbMobileNo" 
                                                        [attr.readonly] = "(this.familyDetailsTableDatas.controls[i].get('IS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH' ) ? '' : null"  appBlockCopyPaste required [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbMobileNo')?.errors }" formControlName = "tbMobileNo" maxlength="10" minlength="10" numbersOnly>
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbMobileNo')?.errors" class="invalid-feedback">
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbMobileNo')?.errors?.required  || this.familyDetailsTableDatas.controls[i].get('tbMobileNo')?.errors?.whiteSpaceValidate">Please enter Mobile Number</div>
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbMobileNo')?.errors?.minlength  || this.familyDetailsTableDatas.controls[i].get('tbMobileNo')?.errors?.pattern">Please Enter Valid 10 digit Mobile Number</div>
                                                        </div>
                                                    </td> 
                                                    <td>
                                                        {{this.familyDetailsTableDatas.controls[i].get('tbMaskAadharNo')?.value}}
                                                    </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div class="mandatoryCls" *ngIf = "duplicatesRecords">Duplicate Aadhaar Entries!</div>
                                        </div>
                                    </div>
                                </div>
                               <!-- <hr class="hrCls mt-4"> 
                                <div class="subHeadingCls mb-3">Address</div>
                                <div class="row mb-3">
                                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <label class="labelCls">District:<span class="mandatoryCls">*</span></label>
                                        <select class="selectCls" formControlName = "district" (change)="onDistrictChange($event.target,'district')" [ngClass]="{ 'is-invalid':submitted && (f.district?.errors || f.district.value === 'Select')}">
                                            <option  [ngValue]= "null" disabled>Select</option>
                                            <option *ngFor="let district of districts" [ngValue]="district">{{district.name}}</option>
                                        </select> 
                                        <div *ngIf="submitted && (f.district?.errors || f.district.value === 'Select')" class="selectError">
                                            <div *ngIf="f.district?.errors?.required || f.district.value === 'Select'">Please select district</div>
                                        </div> 
                                    </div>
                                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <label class="labelCls strong">Mandal/Circle:<span class="mandatoryCls">*</span></label>
                                        <select class="selectCls" formControlName = "mandal" (change)="onMandalChange($event.target,'mandal')"  [ngClass]="{ 'is-invalid':submitted && (f.mandal?.errors || f.mandal.value === 'Select')}">
                                            <option [ngValue]= "null" disabled>Select</option>
                                            <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.name}}</option>
                                        </select>
                                        <div *ngIf="submitted && (f.mandal?.errors || f.mandal.value === 'Select')" class="selectError">
                                            <div *ngIf="f.mandal?.errors?.required || f.mandal.value === 'Select'">Please select mandal</div>
                                        </div>              
                                    </div>
                                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <label class="labelCls">Village/Ward:<span class="mandatoryCls">*</span></label>
                                        <select class="selectCls" formControlName = "villageWard" [ngClass]="{ 'is-invalid':submitted && (f.villageWard?.errors || f.villageWard.value === 'Select')}">
                                            <option [ngValue]= "null" disabled>Select</option>
                                            <option *ngFor="let village of villages" [ngValue]="village">{{village.name}}</option>
                                        </select> 
                                        <div *ngIf="submitted && (f.villageWard?.errors || f.villageWard.value === 'Select')" class="selectError">
                                            <div *ngIf="f.villageWard?.errors?.required || f.villageWard.value === 'Select'">Please select village</div>
                                        </div> 
                                    </div> 
                                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <label class="labelCls">Door No: <span class="mandatoryCls">*</span></label>
                                        <input type="text" name="doorNo" class="inputCls" maxlength="15" appBlockCopyPaste formControlName="doorNo"
                                        [ngClass]="{ 'is-invalid':submitted && f.doorNo.errors }">
                                        <div *ngIf="submitted && f.doorNo.errors" class="invalid-feedback">
                                        <div *ngIf="f.doorNo.errors?.required || f.doorNo.errors?.whiteSpaceValidate">Please enter Door No</div>
                                        <div *ngIf="f.doorNo.errors?.pattern">Please enter valid  Door No</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <label class="labelCls">Locality/Land Mark: <span class="mandatoryCls">*</span></label>
                                        <input type="text" name="locality" class="inputCls" maxlength="50" formControlName="locality" appBlockCopyPaste
                                        [ngClass]="{ 'is-invalid':submitted && f.locality.errors }">
                                        <div *ngIf="submitted && f.locality.errors" class="invalid-feedback">
                                            <div *ngIf="f.locality.errors?.required  || f.locality.errors?.whiteSpaceValidate">Please enter locality</div>
                                            <div *ngIf="f.locality.errors?.pattern">Please enter valid Street Name</div>
                                        </div>
                                    </div>
                                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <label class="labelCls">Pin Code: <span class="mandatoryCls">*</span></label>
                                        <input type="text" name="pinCode" formControlName="pinCode" class="inputCls" appBlockCopyPaste  
                                        [ngClass]="{ 'is-invalid':submitted && f.pinCode.errors }" numbersOnly maxlength="6">
                                        <div *ngIf="submitted && f.pinCode.errors" class="invalid-feedback">
                                            <div *ngIf="f.pinCode.errors?.required">Please enter Pin Code</div>
                                            <div *ngIf="f.pinCode.errors?.pattern">Please enter valid Pin Code</div>
                                        </div>
                                    </div> 
                                </div> -->
                                <hr class="hrCls mt-4">
                                <div class="row mb-3">
                                    <div class="col">
                                        <div class="tab tableScroll">
                                            <h6 class="mb-3">Employment Details : (Step 1)</h6>
                                            <table class="tableCls">
                                                <thead>
                                                    <tr> 
                                                        <th>Sr No</th>
                                                        <th>Member Name</th>
                                                        <th>Is Government Employee/Pensioner</th>
                                                        <th>Employee Category</th>
                                                    </tr>
                                                </thead>
                                                <tbody formArrayName="familyDetailsTableData">
                                                    <tr [formGroupName]="i" *ngFor="let Data of familyDetailsTableDatas.controls; let i = index">
                                                    <td class="serial">{{i +1}}</td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbName')?.value}}</td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbGovernmentPensioner')?.value}}</td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbGovernmentCategory')?.value ? this.familyDetailsTableDatas.controls[i].get('tbGovernmentCategory')?.value : '-'}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                                <hr class="hrCls mt-4">
                                <div class="row mb-3">
                                    <div class="col">
                                        <div class="tab tableScroll">
                                            <h6 class="mb-3">Family Income Details : (Step 2)</h6>
                                            <table class="tableCls">
                                                <thead>
                                                    <tr> 
                                                        <th>Sr No</th>
                                                        <th>Member Name</th>
                                                        <th>Is Paying Income Tax</th>
                                                        <th>Annual Income(In Rs.)</th>
                                                    </tr>
                                                </thead>
                                                <tbody formArrayName="familyDetailsTableData">
                                                    <tr [formGroupName]="i" *ngFor="let Data of familyDetailsTableDatas.controls; let i = index">
                                                    <td class="serial">{{i +1}}</td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbName')?.value}}</td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbPayingIncome')?.value}}</td>
                                                    <td>
                                                        <input type="text"  name="tbAnnualIncome" [attr.disabled] = "(this.familyDetailsTableDatas.controls[i].get('IS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH' ) ? '' : null"                                                    
                                                         appBlockCopyPaste formControlName="tbAnnualIncome" (change) = "totalIncomeCalculator($event)" class="inputCls value" numbersOnly maxlength="8"
                                                        [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbAnnualIncome')?.errors }">
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbAnnualIncome')?.errors" class="invalid-feedback">
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbAnnualIncome')?.errors?.required">Please enter annual income</div>
                                                        </div>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                            </table>    
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex justify-content-center">
                                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12  ">
                                        <label class="labelCls">Total Family Income: </label>
                                        <input type="text" name="totalIncome" appBlockCopyPaste class="inputCls " numbersOnly formControlName = "totalIncome" readonly>
                                    </div>
                                </div>
                                <hr class="hrCls mt-4">
                                <div class="row mb-3">
                                    <div class="col">
                                        <div class="tab tableScroll">
                                            <h6 class="mb-3">Details of the land holding by the family : (Step 3)</h6>
                                            <table class="tableCls">
                                                <thead>
                                                    <tr> 
                                                        <th>Sr No</th>
                                                        <th>Member Name</th>
                                                        <th>Land Status</th>
                                                        <th>Wet Land(in acres)</th>
                                                        <th>Dry Land(in acres)</th>
                                                    </tr>
                                                </thead>
                                                <tbody formArrayName="familyDetailsTableData">
                                                    <tr [formGroupName]="i" *ngFor="let Data of familyDetailsTableDatas.controls; let i = index">
                                                    <td class="serial">{{i +1}}</td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbName')?.value}}</td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbLandStatus')?.value}}</td>
                                                    <td>
                                                        <input type="text" [attr.disabled] = "((this.familyDetailsTableDatas.controls[i].get('tbWetLandStatus')?.value=== 'YES') || (this.familyDetailsTableDatas.controls[i].get('IS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH' )) ? '' : null"
                                                        name="tbWetLand" appBlockCopyPaste formControlName="tbWetLand" (change) = "totalWetLandCalculator($event)" class="inputCls value"  (keypress)="cammamatch($event)"  maxlength="6" 
                                                     
                                                        [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.errors }">
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.errors" class="invalid-feedback">
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.errors?.required">Please enter wetland</div>
                                                            <div *ngIf="!this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.errors?.required && this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.errors?.pattern">Please enter valid wetland</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" [attr.disabled] = "((this.familyDetailsTableDatas.controls[i].get('tbDryLandStatus')?.value === 'YES') || (this.familyDetailsTableDatas.controls[i].get('IS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH' ) )? '' : null"
                                                          name="tbDryLand" appBlockCopyPaste formControlName="tbDryLand" (change) = "totalDryLandCalculator($event)"  (keypress)="cammamatch($event)" class="inputCls value" maxlength="6" 
                                                        [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.errors }">
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.errors" class="invalid-feedback">
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.errors?.required">Please enter dryland</div>
                                                            <div *ngIf="!this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.errors?.required && this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.errors?.pattern">Please enter valid dryland</div>
                                                        </div>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                            </table>    
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex justify-content-center">
                                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <label class="labelCls">Family Total Wetland: </label>
                                        <input type="text" name="totalWetLand" appBlockCopyPaste class="inputCls"  formControlName = "totalWetLand" maxlength="6" readonly>
                                    </div>
                                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <label class="labelCls">Family Total Dryland: </label>
                                        <input type="text" name="totalDryLand" appBlockCopyPaste class="inputCls"  formControlName = "totalDryLand" maxlength="6" readonly>
                                    </div>
                                </div>
                                <hr class="hrCls mt-4">
                                <div class="row mb-3">
                                    <div class="col">
                                        <div class="tab tableScroll">
                                            <h6 class="mb-3">Urban Property Details : (Step 4)</h6>
                                            <table class="tableCls">
                                                <thead>
                                                    <tr> 
                                                        <th>Sr No</th>
                                                        <th>Member Name</th>
                                                        <th>Is Paying Property Tax</th>
                                                        <th>Total no. of Sq.ft for which tax is paid</th>
                                                    </tr>
                                                </thead>
                                                <tbody formArrayName="familyDetailsTableData">
                                                    <tr [formGroupName]="i" *ngFor="let Data of familyDetailsTableDatas.controls; let i = index">
                                                    <td class="serial">{{i +1}}</td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbName')?.value}}</td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbPayingPropertyTax')?.value}}</td>
                                                    <td>
                                                        <input type="text" [attr.disabled] = "(this.familyDetailsTableDatas.controls[i].get('tbPayingPropertyTax')?.value === 'YES' || this.familyDetailsTableDatas.controls[i].get('IS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH')? '' : null" 
                                                        name="tbPropertyTax" appBlockCopyPaste formControlName="tbPropertyTax" (change) = "totalPropertyCalculator($event)"  (keypress)="cammamatch($event)" class="inputCls value" maxlength="6"
                                                        [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.errors }">
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.errors" class="invalid-feedback">
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.errors?.required">Please enter property tax</div>
                                                            <div *ngIf="!this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.errors?.required && this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.errors?.pattern">Please enter valid property tax</div>
                                                        </div>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                            </table>    
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex justify-content-center">
                                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12  ">
                                        <label class="labelCls">Family Total Property Tax: </label>
                                        <input type="text" name="totalPropertyTax" appBlockCopyPaste class="inputCls " numbersOnly maxlength="6" formControlName = "totalPropertyTax" readonly>
                                    </div>
                                </div>
                                <hr class="hrCls mt-4">
                                <div class="row mb-3">
                                    <div class="col">
                                        <div class="tab tableScroll">
                                            <h6 class="mb-3">No. of cars owned by the family: (Step 5)</h6>
                                            <table class="tableCls">
                                                <thead>
                                                    <tr> 
                                                        <th>Sr No</th>
                                                        <th>Member Name</th>
                                                        <th>Has Four Wheeler</th>
                                                        <th>Four Wheeler</th>
                                                        <th>Four Wheeler Numbers</th>
                                                    </tr>
                                                </thead>
                                                <tbody formArrayName="familyDetailsTableData">
                                                    <tr [formGroupName]="i" *ngFor="let Data of familyDetailsTableDatas.controls; let i = index">
                                                    <td class="serial">{{i +1}}</td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbName')?.value}}</td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbFourWheeler')?.value}}</td>                                                  
                                                    <td>
                                                        <select class="selectCls" [attr.disabled] = "(this.familyDetailsTableDatas.controls[i].get('tbFourWheeler')?.value === 'YES' || this.familyDetailsTableDatas.controls[i].get('IS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH') ? '' : null"
                                                        name="tbFourWheelerNo" id="tbFourWheelerNo"  formControlName = "tbFourWheelerNo" (change) = "totalFourWheeler(this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value,i)"
                                                        [ngClass]="{ 'is-invalid':submitted && (familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.errors || this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value === 'Select') }">
                                                            <option [ngValue]="null" disabled>Select</option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                        </select>
                                                        <!-- <div *ngIf="submitted && (this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.errors || this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value === 'Select')" class="selectError">
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.errors?.required || this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value === 'Select'">Please select number of four wheeler</div>
                                                        </div> -->
                                                    </td>
                                                    <td>
                                                        <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbFourWheeler')?.value === 'YES' || this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value === 0 || this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value === null || this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.value  === 'NO'">
                                                            {{this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.value}}
                                                        </div>
                                                    <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbFourWheeler')?.value === 'NO' && this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value > 0 &&  this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.value  !== 'NO'">
                                                        <input type="text" maxlength="15"
                                                        name="tbFourWheelerNumbers" appBlockCopyPaste formControlName="tbFourWheelerNumbers" class="inputCls value" 
                                                        [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.errors }">
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.errors" class="invalid-feedback">
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.errors?.required">Please enter FourWheeler Numbers</div>
                                                            <div *ngIf="!this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.errors?.required && this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.errors?.pattern">Please enter valid FourWheeler Numbers</div>
                                                        </div>
                                                    </div>
                                                    </td>   
                                                </tr>
                                                </tbody>
                                            </table>    
                                        </div>
                                    </div>
                                </div>
                                <hr class="hrCls mt-4">
                                <div class="row mb-3">
                                <div class="col col-lg-2 col-md-6 col-sm-12 col-xs-12 subHeadingCls">Document Upload:</div>
                                <div class="col col-lg-10 col-md-6 col-sm-12 col-xs-12 regDocLink"><a download href="assets/GUIDELINES.pdf">Guidelines for scanning the Family Photo</a></div>
                                  </div>
                                <div class="row mb-3">
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label class="labelCls" for="familyPhoto">Family Photo(Supported docs are .jpg, .jpeg & .png, Size in 200 KB) :<span class="mandatoryCls">*</span></label>
                                        <label class="note">Note: 'Please upload image with resolution (640 x 480) or lower'</label>
                                        <div class="input-group mb-3">
                                            <div class="custom-file">
                                            <input type="file" class="custom-file-input" formControlName="familyPhoto" id="familyPhoto" accept=".jpg, .jpeg , .png" required
                                            [ngClass]="{ 'is-invalid':submitted && f.familyPhoto.errors }" (change)="onFamilyPhotoSelected($event)">
                                            <label class="custom-file-label" #familyPhotoLabel for="familyPhoto"><i class="fas fa-search"></i>No File choosen</label>
                                            </div>
                                            <div class="clearCls" (click)="clearFileUpload('familyPhoto', 'familyPhotoLabel')" *ngIf="f.familyPhoto.value|| isPhoto=== true"></div>
                                         
                                        </div>
                                        <div *ngIf="submitted && f.familyPhoto.errors" class="selectError">
                                            <div *ngIf="f.familyPhoto.errors.required">Please upload Family Photo</div>
                                            <div *ngIf="f.familyPhoto?.errors?.inValidExt && !f.familyPhoto.errors.required ">Please upload the file in PNG/JPG/JPEG Format</div>
                                            <div *ngIf="f.familyPhoto.errors?.inValidSize && !f.familyPhoto.errors.required && !f.familyPhoto.errors.inValidExt">File size exceeding 200 KB</div> 
                                        </div>
                                    </div>
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">

                                        <div *ngIf="showImg && imgSrc !== '' " class="mt-3">
                                            <img [src]="imgSrc" class="mt-3 img-fluid" width="200" height="140">
                                        </div>

                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label class="labelCls" for="aadharCard">Aadhaar Card :</label>
                                        <div class="input-group mb-3">
                                            <div class="custom-file">
                                            <input type="file" class="custom-file-input" formControlName="aadharCard" id="aadharCard" accept=".pdf" 
                                            [ngClass]="{ 'is-invalid':submitted && f.aadharCard.errors }" (change)="onAadharCardSelected($event)">
                                            <label class="custom-file-label" #aadharCardLabel for="aadharCard"><i class="fas fa-search"></i>No File choosen</label>
                                            </div>
                                            <div class="clearCls" (click)="clearFileUpload('aadharCard', 'aadharCardLabel')" *ngIf="f.aadharCard.value"></div>
                                        </div>
                                        <div *ngIf="submitted && f.aadharCard.errors" class="selectError">
                                            <div *ngIf="f.aadharCard?.errors?.inValidExt && !f.aadharCard.errors.required ">Please upload the file in PDF Format</div>
                                            <div *ngIf="f.aadharCard.errors?.inValidSize && !f.aadharCard.errors.required && !f.aadharCard.errors.inValidExt">File size exceeding 1MB</div> 
                                        </div>
                                    </div>
                                </div>
                                <hr class="hrCls mt-4">
                                <div class="subHeadingCls mb-3">Declaration</div>
                                <div class="row mb-3">
                                <div class="ml-4 col-sm-9">
                                    <input class="form-check-input " formControlName = "familyForm" (change)="changeConsent()" type="checkbox" value="" id="familyForm"  [ngClass]="{ 'is-invalid':submitted && f.familyForm.errors }">
                                    <label class="labelCls" for="familyForm">The above information is true to the best of my knowledge. I agree to share my Aadhaar details of self and family with Government of Andhra Pradesh. I am aware that declaration of wrong information will entail any disciplinary action against me.:<span class="mandatoryCls">*</span> </label>
                                    <div *ngIf="submitted && f.familyForm.errors" class="invalid-feedback">
                                    <div *ngIf="f.familyForm.errors.required">Please Check Note</div>
                                    </div>
                                </div>
                                </div>
                                <div class="btnHolder">
                                    <button class="greenBtnCls float-right" (click)="submit()">Show Payment</button>
                                </div>
                        </ng-template>
                    </ng-container>
                </nav>
                <div [ngbNavOutlet]="nav"></div>
            </ng-container>
            <div *ngIf = "f.appType.value == 'existingApp'">
                <div class="dashboardGrid">
                    <table class="dashboardGrid">
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Request ID</th>
                                <th>Service Name</th>
                                <th>Service Category</th>
                                <th>Department Name</th>
                                <th>Citizen Name</th>
                                <th>Applied Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody  *ngIf="items.length > 0">
                            <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;" (click)= "getRequisitionDetails(list[i],item.requisitionId)">
                                <td>{{((page -1) * pageSize) + (i + 1)}}</td>
                                <td>{{item.requisitionId}}</td>
                                <td>{{item.serviceName}}</td>
                                <td>{{item.DepartmentCode}}</td>
                                <td>{{item.DepartmentCode}}</td>
                                <td>{{item.ApplicantName}}</td>
                                <td>{{item.RequestedDate | date: 'dd/MM/yyyy'}}</td>
                                <td>Pending</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <hr>
                <app-aarogyasri-view *ngIf="((certificateName === 'AarogyaSriNewCard') ||  (certificateName === 'UpdateAarogyaSriCard'))&& basicInfo.length !==0" [id] = "id" [basicInfo]="basicInfo" [taskId] = "taskId" [certificateInfo]="certificateInfo" [originalFileList]="fileList" [isNewEnrollment] = "false" ></app-aarogyasri-view>
            </div>
        </form>
    </div>
</div>
