<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
            </div>
        </div>
    </div>
</div>

<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">

    <div class="subHeadingCls mb-3">Service Specific Details</div>

    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo?.serviceDistrictName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo?.servicemandalName}}</div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                <tr> 
                    <th>Village</th>
                    <th>Survey No</th>
                    <th>Land Type</th>
                    <th>Extent of Land</th>
                    <th>Land Units</th>
                    <th>Nature of Inam*</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of certificateInfo?.serviceDetails">
                        <td>{{item.villageName}}</td>
                        <td class="value">{{item.surveyNo}}</td>
                        <td>{{item.landType}}</td>
                        <td class="value">{{item.extendofLand}}</td>
                        <td class="value">{{item.landUnits}}</td>
                        <td class="value">{{item.natureofInam}}</td>
                    </tr>
                </tbody>
                </table>
            </div>
    </div>

</div>

<!-- <div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Informant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Informant Name:</label>
                <div class="valueCls">{{certificateInfo?.informantName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Relationship with the Applicant:</label>
                <div class="valueCls">{{certificateInfo?.informantRelation}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Email:</label>
                <div class="valueCls">{{certificateInfo?.informantEmail}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mobile:</label>
                <div class="valueCls">{{certificateInfo?.informantMobileNo}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Delivery Type:</label>
                <div class="valueCls"></div>
            </div>

        </div>
    </div>
</div> -->

<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0"> 

        <div class="row">
            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                <ng-container *ngIf="fileList && fileList.length !== 0">
                    <div class="subHeadingCls mb-3">Document List</div>
                    <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                        <div *ngIf="file.documentType === 'Application Form'">
                            <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                            <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                    (click)="downloadFile(file)" class="download ml-2"></span></div>
                        </div>
                        <div *ngIf="file.documentType === 'Pahanis Copies'">
                            <span class="numberCls">{{i+1}}</span><label class="labelCls">1954-1955 kasara pahani, 73-74 latest pahani copies:</label>
                            <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                    (click)="downloadFile(file)" class="download ml-2"></span></div>
                        </div>
                        <div *ngIf="file.documentType === 'Family Tree'">
                            <span class="numberCls">{{i+1}}</span><label class="labelCls">Family Tree / Relationship with Inamdhar :</label>
                            <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                    (click)="downloadFile(file)" class="download ml-2"></span></div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <!-- ------------ -->

