<div class="reportContainer">
    <div  [formGroup]="form">
        <div class="row mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
            <select class="selectCls"  formControlName="district" (change)="onDistrictChange()"
            [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
                <option value="All">All</option>
                <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
            </select>  
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Rural/Urban : <span class="mandatoryCls">*</span></label> 
                <select class="selectCls" required formControlName="RUFlag" (change)="onRuralUrbanChange()"
                [ngClass]="{ 'is-invalid':submitted && (f.RUFlag.errors || f.RUFlag.value === 'Select') }">
                <option value = "All">All</option>
                <option value = "R">Rural</option>
                <option value = "U">Urban</option>
                </select>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Mandal <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
              [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
            <option value="All">All</option>
            <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
            </select>
            </div>   
        </div>
        <div class="row mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">From Date: <span class="mandatoryCls">*</span></label>
                <div class="input-group">
                    <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                            name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" (dateSelect)="onFromDateSelect($event)"
                            [maxDate]="maxDate" required formControlName="fromDate"  
                            [ngClass]="{ 'is-invalid':submitted && f.fromDate.errors }" >
                    <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                    </div>
                </div>
                <div *ngIf="submitted && f.fromDate.errors" class="selectError">
                    <div *ngIf="f.fromDate.errors?.required || f.fromDate.invalid">Please enter valid From Date</div>
                </div>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">To Date: <span class="mandatoryCls">*</span></label>
                <div class="input-group">
                    <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                            name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="toMinDate" (dateSelect)="onToDateSelect($event)"
                            [maxDate]="maxDate" required formControlName="toDate"
                            [ngClass]="{ 'is-invalid':submitted && f.toDate.errors }" >
                    <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
                    </div>
                </div>
                <div *ngIf="submitted && f.toDate.errors" class="selectError">
                    <div *ngIf="f.toDate.errors?.required || f.toDate.invalid">Please enter valid To Date</div>
                </div>
            </div>
        </div>
    </div>

    <div class="tabClass">
        <ul>
            <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li> 
        </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getVolunteerReportData()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
        </div>
      </div>

    <div class="table-responsive tableFixHead" *ngIf="showReports">
        <table class="tableCls" >
          <thead> 
            <tr>
              <th [attr.colspan]="noOfCol">Volunteer Appreciation Program Details Report</th>
            </tr>
            <tr>
              <th>S.No</th>
              <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'dname'">District<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'toT_MANDALS'">No of Mandal<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mname'">Mandal/ULB<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rurban'">Rural or Urban<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'conducT_MANDALS'">Conducted Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'uploaD_FLAG'">Uploaded (Y/N)<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'workinG_VOL'">No of Volunteers working<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'attendeD_VOL'">No of Volunteers attended<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'attendeD_SEC'">Number of Secretariat Staff attended<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'Mandal Wise' && role==='Reports Admin'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'photO_1'">Uploaded photo 1<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'Mandal Wise' && role==='Reports Admin'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'photO_2'">Uploaded photo 2<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'Mandal Wise' && role==='Reports Admin'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'photO_3'">Uploaded photo 3<mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>            
          </thead>
          <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
              <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
              <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{item.dname}}</td>
              <td *ngIf="format === 'District Wise'">{{item.toT_MANDALS}}</td>
              <td *ngIf="format === 'Mandal Wise'">{{item.mname}}</td>                
              <td *ngIf="format === 'Mandal Wise'">{{item.rurban}}</td>                
              <td *ngIf="format === 'District Wise'">{{item.conducT_MANDALS}}</td> 
              <td *ngIf="format === 'Mandal Wise'">{{item.uploaD_FLAG}}</td> 
              <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{item.workinG_VOL}}</td> 
              <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{item.attendeD_VOL}}</td> 
              <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{item.attendeD_SEC}}</td> 
              <td *ngIf="format === 'Mandal Wise' && item.photO_1 && role==='Reports Admin'"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.photO_1)">View Photo</a></td>
              <td *ngIf="format === 'Mandal Wise' && !item.photO_1 && role==='Reports Admin'"  class="text-right">-</td>
              <td *ngIf="format === 'Mandal Wise' && item.photO_2 && role==='Reports Admin'"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.photO_2)">View Photo</a></td>
              <td *ngIf="format === 'Mandal Wise' && !item.photO_2 && role==='Reports Admin'"  class="text-right">-</td>
              <td *ngIf="format === 'Mandal Wise' && item.photO_3 && role==='Reports Admin'"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.photO_3)">View Photo</a></td>
              <td *ngIf="format === 'Mandal Wise' && !item.photO_3 && role==='Reports Admin'"  class="text-right">-</td>
            </tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
              <td></td>
              <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{totalItems[0].dname}}</td>
              <td *ngIf="format === 'District Wise'">{{totalItems[0].toT_MANDALS}}</td>
              <td *ngIf="format === 'Mandal Wise'">{{totalItems[0].mname}}</td>                
              <td *ngIf="format === 'Mandal Wise'">{{totalItems[0].rurban}}</td>                
              <td *ngIf="format === 'District Wise'">{{totalItems[0].conducT_MANDALS}}</td> 
              <td *ngIf="format === 'Mandal Wise'">{{totalItems[0].uploaD_FLAG}}</td> 
              <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{totalItems[0].workinG_VOL}}</td> 
              <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{totalItems[0].attendeD_VOL}}</td> 
              <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{totalItems[0].attendeD_SEC}}</td> 
              <td  *ngIf="format === 'Mandal Wise' && role==='Reports Admin'"></td>
              <td  *ngIf="format === 'Mandal Wise' && role==='Reports Admin'"></td>
              <td  *ngIf="format === 'Mandal Wise' && role==='Reports Admin'"></td>
          </tr>
          </tbody>
          <tbody *ngIf="items && items.length === 0">
              <tr>
                  <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
              </tr>
          </tbody>
        </table>
        <div class="mt-4 btnHide">
            <div class="float-left" *ngIf="items.length > 0">
                <label>Requests per page: </label>
                <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                    <option  value="10">10</option>
                    <option  value="20">20</option>
                    <option  value="30">30</option>
                    <option  value="40">40</option>
                    <option  value="50">50</option>
                </select> 
            </div>
            <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
            </ngb-pagination>
        </div>
      </div>
      </div>

      <div class="table-responsive tableFixHead" #TABLE class="hideTable">
        <table class="tableCls">
         <thead> 
           <tr>
             <th [attr.colspan]="noOfCol">Volunteer Appreciation Program Details Report</th>
           </tr>
           <tr>
            <th>S.No</th>
            <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'dname'">District<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'toT_MANDALS'">No of Mandal<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mname'">Mandal/ULB<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rurban'">Rural or Urban<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'conducT_MANDALS'">Conducted Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'uploaD_FLAG'">Uploaded (Y/N)<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'workinG_VOL'">No of Volunteers working<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'attendeD_VOL'">No of Volunteers attended<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'attendeD_SEC'">Number of Secretariat Staff attended<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'Mandal Wise' && role==='Reports Admin'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'photO_1'">Uploaded photo 1<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'Mandal Wise' && role==='Reports Admin'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'photO_2'">Uploaded photo 2<mdb-icon fas icon="sort"></mdb-icon></th>
              <th *ngIf="format === 'Mandal Wise' && role==='Reports Admin'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'photO_3'">Uploaded photo 3<mdb-icon fas icon="sort"></mdb-icon></th>
           </tr>             
         </thead>
         <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items;let i = index;">
             <td>{{i + 1}}</td>
             <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{item.dname}}</td>
              <td *ngIf="format === 'District Wise'">{{item.toT_MANDALS}}</td>
              <td *ngIf="format === 'Mandal Wise'">{{item.mname}}</td>                
              <td *ngIf="format === 'Mandal Wise'">{{item.rurban}}</td>                
              <td *ngIf="format === 'District Wise'">{{item.conducT_MANDALS}}</td> 
              <td *ngIf="format === 'Mandal Wise'">{{item.uploaD_FLAG}}</td> 
              <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{item.workinG_VOL}}</td> 
              <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{item.attendeD_VOL}}</td> 
              <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{item.attendeD_SEC}}</td> 
              <td *ngIf="format === 'Mandal Wise' && item.photO_1 && role==='Reports Admin'"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.photO_1)">View Photo</a></td>
              <td *ngIf="format === 'Mandal Wise' && !item.photO_1 && role==='Reports Admin'"  class="text-right">-</td>
              <td *ngIf="format === 'Mandal Wise' && item.photO_2 && role==='Reports Admin'"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.photO_2)">View Photo</a></td>
              <td *ngIf="format === 'Mandal Wise' && !item.photO_2 && role==='Reports Admin'"  class="text-right">-</td>
              <td *ngIf="format === 'Mandal Wise' && item.photO_3 && role==='Reports Admin'"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.photO_3)">View Photo</a></td>
              <td *ngIf="format === 'Mandal Wise' && !item.photO_3 && role==='Reports Admin'"  class="text-right">-</td>
           </tr>
           <tr *ngIf="totalItems && totalItems.length > 0">
             <td></td>
             <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{totalItems[0].dname}}</td>
              <td *ngIf="format === 'District Wise'">{{totalItems[0].toT_MANDALS}}</td>
              <td *ngIf="format === 'Mandal Wise'">{{totalItems[0].mname}}</td>                
              <td *ngIf="format === 'Mandal Wise'">{{totalItems[0].rurban}}</td>                
              <td *ngIf="format === 'District Wise'">{{totalItems[0].conducT_MANDALS}}</td> 
              <td *ngIf="format === 'Mandal Wise'">{{totalItems[0].uploaD_FLAG}}</td> 
              <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{totalItems[0].workinG_VOL}}</td> 
              <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{totalItems[0].attendeD_VOL}}</td> 
              <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'">{{totalItems[0].attendeD_SEC}}</td> 
              <td  *ngIf="format === 'Mandal Wise' && role==='Reports Admin'"></td>
              <td  *ngIf="format === 'Mandal Wise' && role==='Reports Admin'"></td>
              <td  *ngIf="format === 'Mandal Wise' && role==='Reports Admin'"></td>
         </tr>
         </tbody>
         <tbody *ngIf="items && items.length === 0">
             <tr>
                 <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
             </tr>
         </tbody>
        </table>
       </div>
       
