<div class="reportContainer">
    <div [formGroup]="form">
    <div class="row mb-3">
        
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="district" (change)="onDistrictChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
         <option value="All">All</option>
        <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
      </select>
    </div>

    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Revenue Division : <span class="mandatoryCls">*</span></label> 
        <select class="selectCls"  formControlName="revenue" (change)="getMandals()">
        <option value = "All">All</option>
        <option *ngFor="let division of divisions" [ngValue]="division">{{division.revenueDivisionName}}</option>
        </select>
    </div>
    
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
      <option value="All">All</option>
      <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
    </select>
   </div>
   
   <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">Village/Ward/Secretariat: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="village" (change)="onVillageChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.village.errors || f.village.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let village of villages" [ngValue]="village">{{village.secretariatName}}</option>
      </select>
    </div>
    </div>

    <div class="row mb-3">
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Department: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="department" (change)="onDeptChange()"
          [ngClass]="{ 'is-invalid':submitted && (f.department.errors || f.department.value === 'Select') }">
          <option value="All">All</option>
          <option *ngFor="let dept of departments" [ngValue]="dept">{{dept}}</option>
        </select>
        </div>


    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Service: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="service" (change) = "onServiceChange()"
          [ngClass]="{ 'is-invalid':submitted && (f.service.errors || f.service.value === 'Select') }">
          <option value="All">All</option>
          <option *ngFor="let service of services" [ngValue]="service">{{service}}</option>
        </select>
       </div>
       
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">From Date: <span class="mandatoryCls">*</span></label>
        <div class="input-group">
          <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                 name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" (dateSelect)="onFromDateSelect($event)"
                 [maxDate]="maxDate" required formControlName="fromDate"
                 [ngClass]="{ 'is-invalid':submitted && f.fromDate.errors }">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
          </div>
        </div>
        <div *ngIf="submitted && f.fromDate.errors" class="selectError">
          <div *ngIf="f.fromDate.errors?.required || f.fromDate.invalid">Please enter valid From Date</div>
      </div>
      </div>

      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">To Date: <span class="mandatoryCls">*</span></label>
        <div class="input-group">
          <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                 name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="toMinDate" (dateSelect)="onToDateSelect($event)"
                 [maxDate]="maxDate" required formControlName="toDate"
                 [ngClass]="{ 'is-invalid':submitted && f.toDate.errors }">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
          </div>
        </div>
        <div *ngIf="submitted && f.toDate.errors" class="selectError">
          <div *ngIf="f.toDate.errors?.required || f.toDate.invalid">Please enter valid To Date</div>
      </div>
      </div>
      </div>

      <div class="row mb-3">
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Source Portal: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="source" (change) = "onServiceChange()">
          <option value="All">All</option>
          <option value = "MEESEVA">MEESEVA</option>
          <option value = "OLD">OLD</option>
          <option value = "APSeva">APSeva</option>
          <option value = "B2C">B2C</option>
          <option value = "MAUD">MAUD</option>
        </select>
       </div>
       </div>
      
  <div class="tabClass">
        <ul class="nav nav-pills flex-column flex-sm-row"  class="navbar navbar-expand-lg navbar-light "> 
              <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li>
              </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getCollectionPaymentReportData()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
        </div>
      </div>
</div>
<div class="table-responsive tableFixHead" *ngIf="showReports">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th [attr.colspan]="noOfCol">Collection & Payment Report Dashboard from  {{f.fromDate.value}} to  {{f.toDate.value}}</th>
            </tr>
            <tr>
               <th rowspan="2">S.No</th>
               <th rowspan="2" *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise' || format === 'Revenue Division Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Revenue Division Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'revenuE_DIVISION_NAME'">Revenue Division Name<mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal Name<mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'">Secretariat Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'">Secretariat Code <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Service Wise' || format === 'Department Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'departmenT_NAME'">Department Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'servicename'">Service Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Month Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'tranS_PAID_MNTH'">Year/Month </th>
               <th colspan="6" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items">Transaction happened in the period</th>
               <th colspan="7" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items">Payments happened in the period </th>
              </tr>
            <tr>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'totaL_REQUESTS'">Total No of Requests <mdb-icon fas icon="sort"></mdb-icon></th>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'zerO_AMOUNT_REQUESTS'">Zero Amount Requests <mdb-icon fas icon="sort"></mdb-icon></th>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'requestS_WITH_AMOUNT'">Transactions With Amount requests <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'totaL_USER_CHARGE'">User Charges <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'totaL_STAT_CHARGE'">Statutory Charges <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'totaL_AMOUNT'">Total Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'totaL_PAID_REQUESTS'">Total No of Transactions <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'totaL_PAID_USER_CHARGE'">User Charges <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'totaL_PAID_STAT_CHARGE'">Statutory Charges <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'totaL_PAID_AMOUNT'">Total Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'upI_PAID_AMOUNT'">UPI Single Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'billdesK_PAID_AMOUNT'">Billdesk challan Amount <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'icicI_PAID_AMOUNT'">ICICI Challan Amount <mdb-icon fas icon="sort"></mdb-icon></th>
              </tr>
           </thead>

        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise' || format === 'Revenue Division Wise'">{{item.districT_NAME}}</td>
                <td *ngIf="format === 'Revenue Division Wise'">{{item.revenuE_DIVISION_NAME}}</td> 
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
               <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
               <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_CODE}}</td>
               <td *ngIf="format === 'Service Wise' || format === 'Department Wise'">{{item.departmenT_NAME}}</td>
               <td *ngIf="format === 'Service Wise'">{{item.servicename}}</td>
               <td *ngIf="format === 'Month Wise'">{{item.tranS_PAID_MNTH}}</td>
               <td class="text-right">{{item.totaL_REQUESTS}}</td>
                <td class="text-right">{{item.zerO_AMOUNT_REQUESTS}}</td>
                <td class="text-right">{{item.requestS_WITH_AMOUNT}}</td>
                <td class="text-right">{{item.totaL_USER_CHARGE}}</td>
                <td class="text-right">{{item.totaL_STAT_CHARGE}}</td>
                <td class="text-right">{{item.totaL_AMOUNT}}</td>
                <td class="text-right">{{item.totaL_PAID_REQUESTS}}</td>
                <td class="text-right">{{item.totaL_PAID_USER_CHARGE}}</td>
                <td class="text-right">{{item.totaL_PAID_STAT_CHARGE}}</td>
                <td class="text-right">{{item.totaL_PAID_AMOUNT}}</td>
                <td class="text-right">{{item.upI_PAID_AMOUNT}}</td>
                <td class="text-right">{{item.billdesK_PAID_AMOUNT}}</td>
                <td class="text-right">{{item.icicI_PAID_AMOUNT}}</td>
            </tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise'|| format === 'Revenue Division Wise'">{{totalItems[0].districT_NAME}}</td>
                <td *ngIf="format === 'Revenue Division Wise'">{{totalItems[0].revenuE_DIVISION_NAME}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
               <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
               <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_CODE}}</td>
               <td *ngIf="format === 'Service Wise'|| format === 'Department Wise'">{{totalItems[0].departmenT_NAME}}</td>
               <td *ngIf="format === 'Service Wise'">{{totalItems[0].servicename}}</td>
              <td *ngIf="format === 'Month Wise'">{{totalItems[0].tranS_PAID_MNTH}}</td>
              <td class="text-right">{{totalItems[0].totaL_REQUESTS}}</td>
                <td class="text-right">{{totalItems[0].zerO_AMOUNT_REQUESTS}}</td>
                <td class="text-right">{{totalItems[0].requestS_WITH_AMOUNT}}</td>
                <td class="text-right">{{totalItems[0].totaL_USER_CHARGE}}</td>
            <td class="text-right">{{totalItems[0].totaL_STAT_CHARGE}}</td>
            <td class="text-right">{{totalItems[0].totaL_AMOUNT}}</td>
            <td class="text-right">{{totalItems[0].totaL_PAID_REQUESTS}}</td>
            <td class="text-right">{{totalItems[0].totaL_PAID_USER_CHARGE}}</td>
            <td class="text-right">{{totalItems[0].totaL_PAID_STAT_CHARGE}}</td>
            <td class="text-right">{{totalItems[0].totaL_PAID_AMOUNT}}</td>
            <td class="text-right">{{totalItems[0].upI_PAID_AMOUNT}}</td>
            <td class="text-right">{{totalItems[0].billdesK_PAID_AMOUNT}}</td>
            <td class="text-right">{{totalItems[0].icicI_PAID_AMOUNT}}</td>
                </tr>
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    <div class="mt-4 btnHide">
        <div class="float-left" *ngIf="items.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
</div>
</div>
<div class="table-responsive tableFixHead" #TABLE class="hideTable">
   <table class="tableCls" >
       <thead>
           <tr>
           
                <th [attr.colspan]="noOfCol">Collection & Payment Report Dashboard from  {{f.fromDate.value}} to  {{f.toDate.value}}</th>
            </tr>
           <tr>
               <th rowspan="2" width="100px">S.No</th>
               <th width="100px" rowspan="2" *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise' || format === 'Revenue Division Wise'">District Name</th>
               <th rowspan="2"width="100px"  *ngIf="format === 'Revenue Division Wise'">Revenue Division Name</th>
               <th rowspan="2" width="100px" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal Name</th>
               <th width="100px" rowspan="2" *ngIf="format === 'Secretariat Wise'">Secretariat Name</th>
               <th width="100px" rowspan="2" *ngIf="format === 'Secretariat Wise'">Secretariat Code</th>
               <th width="100px" rowspan="2" *ngIf="format === 'Service Wise' || format === 'Department Wise'">Department Name</th>
               <th rowspan="2" width="100px" *ngIf="format === 'Service Wise'">Service Name</th>
               <th rowspan="2" width="100px" *ngIf="format === 'Month Wise'">Year/Month</th>
               <th width="100px" colspan="6">Transaction happened in the period</th>
               <th width="100px" colspan="7">Payments happened in the period</th>
              </tr>
               <tr>
                <th width="100px">Total No of Requests</th>
                <th width="100px">Zero Amount Requests</th>
                <th width="100px">Transactions With Amount requests</th>
                <th width="100px">User Charges</th>
                <th width="100px">Statutory Charges</th>
                <th width="100px" >Total Amount</th>
                <th width="100px">Total No of Transactions</th>
                <th width="100px">User Charges</th>
                <th width="100px">Statutory Charges</th>
                <th width="100px">Total Amount</th>
                <th width="100px">UPI Single Amount</th>
                <th width="100px">Billdesk challan Amount</th>
                <th width="100px">ICICI Challan Amount</th>
                </tr>
       </thead>

       <tbody *ngIf="items && items.length > 0">
           <tr *ngFor="let item of items;let i = index;">
               <td width="100px">{{i + 1}}</td>
               <td width="100px" *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise' || format === 'Revenue Division Wise'">{{item.districT_NAME}}</td>
               <td width="100px" *ngIf="format === 'Revenue Division Wise'">{{item.revenuE_DIVISION_NAME}}</td>
               <td width="100px" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
               <td width="100px" *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
                <td width="100px" *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_CODE}}</td>
               <td width="100px" *ngIf="format === 'Service Wise' || format === 'Department Wise'">{{item.departmenT_NAME}}</td>
               <td width="100px" *ngIf="format === 'Service Wise'">{{item.servicename}}</td>
               <td width="100px" *ngIf="format === 'Month Wise'">{{item.tranS_PAID_MNTH}}</td>
               <td width="100px">{{item.totaL_REQUESTS}}</td>
               <td width="100px">{{item.zerO_AMOUNT_REQUESTS}}</td>
               <td width="100px">{{item.requestS_WITH_AMOUNT}}</td>
               <td width="100px">{{item.totaL_USER_CHARGE}}</td>
                <td width="100px">{{item.totaL_STAT_CHARGE}}</td>
                <td width="100px">{{item.totaL_AMOUNT}}</td>
                <td width="100px">{{item.totaL_PAID_REQUESTS}}</td>
                <td width="100px">{{item.totaL_PAID_USER_CHARGE}}</td>
                <td width="100px">{{item.totaL_PAID_STAT_CHARGE}}</td>
                <td width="100px">{{item.totaL_PAID_AMOUNT}}</td>
                <td width="100px">{{item.upI_PAID_AMOUNT}}</td>
                <td width="100px">{{item.billdesK_PAID_AMOUNT}}</td>
                <td width="100px">{{item.icicI_PAID_AMOUNT}}</td>
                </tr>
           <tr *ngIf="totalItems && totalItems.length > 0">
            <td></td>
            <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise' || format === 'Revenue Division Wise'">{{totalItems[0].districT_NAME}}</td>
            <td *ngIf="format === 'Revenue Division Wise'">{{totalItems[0].revenuE_DIVISION_NAME}}</td>
            <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
            <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
           <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_CODE}}</td>
           <td *ngIf="format === 'Service Wise' || format === 'Department Wise'">{{totalItems[0].departmenT_NAME}}</td>
           <td *ngIf="format === 'Service Wise'">{{totalItems[0].servicename}}</td>
           <td *ngIf="format === 'Month Wise'">{{totalItems[0].tranS_PAID_MNTH}}</td>
           <td >{{totalItems[0].totaL_REQUESTS}}</td>
           <td >{{totalItems[0].zerO_AMOUNT_REQUESTS}}</td>
           <td >{{totalItems[0].requestS_WITH_AMOUNT}}</td>
           <td >{{totalItems[0].totaL_USER_CHARGE}}</td>
           <td >{{totalItems[0].totaL_STAT_CHARGE}}</td>
           <td >{{totalItems[0].totaL_AMOUNT}}</td>
           <td >{{totalItems[0].totaL_PAID_REQUESTS}}</td>
           <td >{{totalItems[0].totaL_PAID_USER_CHARGE}}</td>
           <td >{{totalItems[0].totaL_PAID_STAT_CHARGE}}</td>
           <td >{{totalItems[0].totaL_PAID_AMOUNT}}</td>
           <td >{{totalItems[0].upI_PAID_AMOUNT}}</td>
           <td >{{totalItems[0].billdesK_PAID_AMOUNT}}</td>
           <td >{{totalItems[0].icicI_PAID_AMOUNT}}</td>
        </tr>
       </tbody>
       <tbody *ngIf="items && items.length === 0">
           <tr>
              <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
       </tbody>
   </table>
  </div>
  