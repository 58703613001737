<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Selected Criteria Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ?
                    (basicInfo?.applicantInfo?.rationCardNo):'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks ):'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Purpose Of Certificate:</label>
                <div class="valueCls">{{certificateInfo?.purposeOfCertificate }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">No Of Copies:</label>
                <div class="valueCls">{{certificateInfo?.noOfCopies ? (certificateInfo?.noOfCopies) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Registration Unit Id:</label>
                <div class="valueCls">{{certificateInfo?.registrationUnitId}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Registration No :</label>
                <div class="valueCls">{{certificateInfo?.registrationNumber}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Registration Year : :</label>
                <div class="valueCls">{{certificateInfo?.registrationYear}}</div>
            </div>

        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Whether You Want to Update Deceased Name:</label>
                <div class="valueCls">{{certificateInfo?.isDeceasedNameUpdate}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Deceased SurName:</label>
                <div class="valueCls">{{certificateInfo?.actualDeceasedSurName
                    ?certificateInfo?.actualDeceasedSurName:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Correct Deceased SurName:</label>
                <div class="valueCls">{{certificateInfo?.correctDeceasedSurName }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Deceased Name:</label>
                <div class="valueCls">{{certificateInfo?.actualDeceasedName?certificateInfo?.actualDeceasedName:'-' }}
                </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Correct Deceased Name:</label>
                <div class="valueCls">{{certificateInfo?.correctDeceasedName }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Whether You Want to Update Date Of Death:</label>
                <div class="valueCls">{{certificateInfo?.isDateOfDeathUpdate}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Date Of Death:</label>
                <div class="valueCls">{{certificateInfo?.actualDOD?certificateInfo?.actualDOD:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Correct Date Of Death :</label>
                <div class="valueCls">{{certificateInfo?.correctDOD }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Whether You Want to Update Gender:</label>
                <div class="valueCls">{{certificateInfo?.isGenderUpdate}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Gender:</label>
                <div class="valueCls">{{certificateInfo?.actualGender?certificateInfo?.actualGender:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Correct Gender : </label>
                <div class="valueCls">{{certificateInfo?.correctGender}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Whether You Want to Update Relation:</label>
                <div class="valueCls">{{certificateInfo?.isRelationUpdate}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Relation:</label>
                <div class="valueCls">{{certificateInfo?.actualRelation?certificateInfo?.actualRelation:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Correct Relation : </label>
                <div class="valueCls">{{certificateInfo?.correctRelation}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Whether You Want to Update Deceased Father Name :</label>
                <div class="valueCls">{{certificateInfo?.isDeceasedFatherNameUpdate}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Deceased Father/Husband Name:</label>
                <div class="valueCls">
                    {{certificateInfo?.actualDeceasedFatherSurName?certificateInfo?.actualDeceasedFatherSurName:'-'}}
                </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Correct Deceased Father/Husband Name:</label>
                <div class="valueCls">{{certificateInfo?.correctDeceasedFatherName }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Deceased Father/Husband SurName:</label>
                <div class="valueCls">
                    {{certificateInfo?.actualDeceasedFatherSurName?certificateInfo?.actualDeceasedFatherSurName:'-' }}
                </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Correct Deceased Father/Husband SurName :</label>
                <div class="valueCls">{{certificateInfo?.correctDeceasedFatherSurName }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Whether You Want to Update Deceased Mother Name:</label>
                <div class="valueCls">{{certificateInfo?.isDeceasedMotherNameUpdate}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Deceased Mother Name:</label>
                <div class="valueCls">
                    {{certificateInfo?.actualDeceasedMotherName?certificateInfo?.actualDeceasedMotherName:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Correct Deceased Mother Name :</label>
                <div class="valueCls">{{certificateInfo?.correctDeceasedMotherName }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Deceased Mother SurName:</label>
                <div class="valueCls">
                    {{certificateInfo?.actualDeceasedMotherSurName?certificateInfo?.actualDeceasedMotherSurName:'-' }}
                </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Correct Deceased Mother SurName :</label>
                <div class="valueCls">{{certificateInfo?.correctDeceasedMotherSurName }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Whether You Want to Update Death Place:</label>
                <div class="valueCls">{{certificateInfo?.isDeathPlaceUpdate}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Death Place :
                </label>
                <div class="valueCls">{{certificateInfo?.actualDeathPlace?certificateInfo?.actualDeathPlace:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Correct Death Place :</label>
                <div class="valueCls">{{certificateInfo?.correctDeathPlace }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls" *ngIf="certificateInfo?.correctDeathPlace ==='hospital'">Changed Hospital Name
                    :</label>
                <label class="labelCls" *ngIf="certificateInfo?.correctDeathPlace ==='residence'">Location Name
                    :</label>
                <label class="labelCls" *ngIf="certificateInfo?.correctDeathPlace ==='others'">Location Name :</label>
                <div class="valueCls">
                    {{certificateInfo?.locationName}}</div>
            </div>
        </div>
        <div class=" col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Actual Line 1 of Residential Address:
                </label>
                <div class="valueCls">{{certificateInfo?.actualAddress1?certificateInfo?.actualAddress1:'-'}}
                </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Line 2 of Residential Address:
                </label>
                <div class="valueCls">{{certificateInfo?.actualAddress2?certificateInfo?.actualAddress2:'-'}}
                </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Line 3 of Residential Address :
                </label>
                <div class="valueCls">{{certificateInfo?.actualAddress3?certificateInfo?.actualAddress3:'-'}}
                </div>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls" *ngIf="certificateInfo?.correctDeathPlace ==='hospital'">Changed Line 1 Of
                    Hospital Address : </label>
                <label class="labelCls" *ngIf="certificateInfo?.correctDeathPlace ==='residence'">Changed Line 1 Address
                    Of Death Place :</label>
                <label class="labelCls" *ngIf="certificateInfo?.correctDeathPlace ==='others'">Changed Line 1 Of Other
                    Address : </label>
                <div class="valueCls">{{certificateInfo?.changedAddress1 }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls" *ngIf="certificateInfo?.correctDeathPlace ==='hospital'">Changed Line 2 Of
                    Hospital Address : </label>
                <label class="labelCls" *ngIf="certificateInfo?.correctDeathPlace ==='residence'">Changed Line 2 Address
                    Of Death Place : </label>
                <label class="labelCls" *ngIf="certificateInfo?.correctDeathPlace ==='others'">Changed Line 2 Of Other
                    Address : </label>
                <div class="valueCls">{{certificateInfo?.changedAddress2 }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls" *ngIf="certificateInfo?.correctDeathPlace ==='hospital'">Changed Line 3 Of
                    Hospital Address : </label>
                <label class="labelCls" *ngIf="certificateInfo?.correctDeathPlace ==='residence'">Changed Line 3 Address
                    Of Death Place : : </label>
                <label class="labelCls" *ngIf="certificateInfo?.correctDeathPlace ==='others'">Changed Line 3 Of Other
                    Address : </label>
                <div class="valueCls">{{certificateInfo?.changedAddress3 }}</div>
            </div>

        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Pincode : </label>
                <div class="valueCls">{{certificateInfo?.pincode }}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Whether You Want to Update Address At The Time Of Death:</label>
                <div class="valueCls">{{certificateInfo?.isDeathAddressUpdate}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Line 1 of Address At The Time Of Death:</label>
                <div class="valueCls">
                    {{certificateInfo?.actualDeathAddress1?certificateInfo?.actualDeathAddress1:'-'}}
                </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Line 2 of Address At The Time Of Death :</label>
                <div class="valueCls">
                    {{certificateInfo?.actualDeathAddress2?certificateInfo?.actualDeathAddress2:'-' }}
                </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Line 3 of Address At The Time Of Death :</label>
                <div class="valueCls">
                    {{certificateInfo?.actualDeathAddress3?certificateInfo?.actualDeathAddress3:'-' }}
                </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Correct Line 1 of Address At The Time Of Death : </label>
                <div class="valueCls">{{certificateInfo?.correctDeathAddress1 }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Correct Line 2 of Address At The Time Of Death : </label>
                <div class="valueCls">{{certificateInfo?.correctDeathAddress2 }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Correct Line 3 of Address At The Time Of Death : </label>
                <div class="valueCls">{{certificateInfo?.correctDeathAddress3 }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Whether You Want to Update Permanent Address :</label>
                <div class="valueCls">{{certificateInfo?.isPermanentAddressUpdate}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Line 1 of Permanent Address :</label>
                <div class="valueCls">
                    {{certificateInfo?.actualPermanentAddress1?certificateInfo?.actualPermanentAddress1:'-'}}
                </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Line 2 of Permanent Address:</label>
                <div class="valueCls">
                    {{certificateInfo?.actualPermanentAddress2?certificateInfo?.actualPermanentAddress2:'-' }}
                </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Line 3 of Permanent Address :</label>
                <div class="valueCls">
                    {{certificateInfo?.actualPermanentAddress3?certificateInfo?.actualPermanentAddress3:'-' }}
                </div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Correct Line 1 of Permanent Address :: </label>
                <div class="valueCls">{{certificateInfo?.correctPermanentAddress1 }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Correct Line 2 of Permanent Address: </label>
                <div class="valueCls">{{certificateInfo?.correctPermanentAddress2 }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Correct Line 3 of Permanent Address : </label>
                <div class="valueCls">{{certificateInfo?.correctPermanentAddress3 }}</div>
            </div>
        </div>
    </div>
</div>

<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                    <div *ngIf="file.documentType === 'Application Form'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>

                </div>
            </ng-container>
        </div>
    </div>
</div>