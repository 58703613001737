import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { faLock, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Router, NavigationEnd, ActivatedRoute ,Params} from '@angular/router';
import { CommonService } from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
@Component({
  selector: 'app-external-payment-response',
  templateUrl: './external-payment-response.component.html',
  styleUrls: ['./external-payment-response.component.css'],
})
export class ExternalPaymentResponseComponent implements OnInit {
  
  @Input() queryParmData: any;
  commonConstants: any = CommonConstants;
  
  paymentResponseData: any;
  isPaymentSuccess: boolean = true;
  isAuth: boolean = false;
  transactionStatus: any = "";
  transactionID: any = "";
  transactionReferenceID: any = "";
  transactionDate: any = "";
  rdirectURL: any = "https://vswsonline.ap.gov.in/";
  responseStrParam: any = "";

  constructor(private currentRoute: ActivatedRoute,private router: Router, private commonService: CommonService) {}

  ngOnInit(): void {

    //console.log(">>>>>>>>>> In Rpayment>>>>>>>>>>>>>")
    this.currentRoute.queryParams.subscribe((params) => {
      //console.log('params payemnt=========>>>>>>>', params);
      if (params !== {}) {
        this.getResponseData(params);
      }
    });
  }

  getResponseData(data: any) {
   
    this.isAuth = false;
   
    if (Object.keys(data).length === 0 && data.constructor === Object) {
      //console.log('Object is empty');
    } else {
      //console.log('Object is not empty',new Date().toLocaleString());
     
      if(data && data.mode === "Cash"){
        
        this.transactionStatus = "Success";
        this.transactionID = data.id.replace("msg=","");
        this.transactionDate = new Date().toLocaleString(); 
        this.generateResponseQueryString("CASH","0300");

      }else if(data && data.mode === "unauthorized"){
          this.isAuth = true;
      }else{
        
        this.paymentResponseData = data.msg.split('|');
        this.transactionStatus = this.paymentResponseData[14];
        this.transactionID = this.paymentResponseData[1];
        this.transactionReferenceID = this.paymentResponseData[2];
        this.transactionDate = this.paymentResponseData[13]; 
        
        this.savePaymentResponse(data.msg);
        
        //console.log('from response=====', this.paymentResponseData);
        
        this.isPaymentSuccess = true;
        
        if(this.transactionStatus !=="0300"){
          this.isPaymentSuccess = false;
        }
      }
    }
  }

  savePaymentResponse(data:any){

    let payentInfo:any = localStorage.getItem('piplinnString');
    payentInfo = payentInfo.split('|');
    
    let postData = {
      requestId: payentInfo[0],
      requestType: payentInfo[1],
      department: payentInfo[2],
      transactionId: 'T' + payentInfo[0],
      transactionAmount: payentInfo[7],
      transactionMode: 'UPI',
      transactionStatus: this.transactionStatus,
      piplineString: data,
      userId: payentInfo[8],
      customerName: payentInfo[9],
      UserCharges:payentInfo[3],
      ServiceCharges:payentInfo[4],
      StatutaryCharges :payentInfo[5],
      DeliveryCharges :payentInfo[6],
      agentId: payentInfo[11],
      agentName :payentInfo[10],
      requestSubType: payentInfo[12],
    };

    this.commonService
      .postRequest(this.commonConstants.saveBillDeskResponse_External, postData)
      .subscribe({
        next: (responseData: any) => {
          //console.log('saveBillDeskResponse_External===', responseData);
        
          this.generateResponseQueryString("UPI",this.transactionStatus);
        },
      });
  }
  //saveBillDeskResponse_External

  generateResponseQueryString(mode:any,txtStatus:any){
    
    let payentInfo:any = localStorage.getItem('piplinnString');
    payentInfo = payentInfo.split('|');
    this.rdirectURL = payentInfo[14];

    let transactionStatus = "FAILED";
    let transactionStatusCode = txtStatus;
    
    if(txtStatus == "0300"){
      transactionStatus = "SUCCESS";
      transactionStatusCode = "0300";
    }
    
    let txnRefID = payentInfo[0];
    if(mode ==="UPI"){
      txnRefID = this.transactionReferenceID;
    }
    
    //sample response str format
    //MEESEVA_APPNO|APTS_TXNID|STATUS_DESC|STATUS_ID|STAFF_CODE|SERVICE_CODE|AMOUNT|PAYMENTMODE
    this.responseStrParam = payentInfo[0]+'|'+txnRefID+'|'+transactionStatus+'|'+ transactionStatusCode+'|'+payentInfo[8]+'|'+payentInfo[1]+'|'+payentInfo[7]+'|'+mode ;
    
    //console.log("Response String",this.responseStrParam)

    let postdata = {
      rawValue: this.responseStrParam,
    };
    
    
    this.commonService
      .postRequest(
        this.commonConstants.externalAESEncryptMesevaRequest,
        postdata
      )
      .subscribe({
        next: (responseData: any) => {
          //console.log('externalAESEncryptMesevaRequest===', responseData);

          if(responseData.result.isSuccess === true){ 
            this.responseStrParam = encodeURIComponent(responseData.result.encryptedValue);
            localStorage.removeItem("piplinnString");
            setTimeout(() => {
              window.location.href = this.rdirectURL+"?msg="+this.responseStrParam;
            }, 15000);
          }
        },
      });
     
  }

  goBack(){
    console.log('RedirectURL===', this.rdirectURL+"?msg="+this.responseStrParam);
    window.location.href = this.rdirectURL+"?msg="+this.responseStrParam;
   // window.location.href = "https://uat.vswsonline.ap.gov.in/";
   // window.location.href = "https://gswswebdev.z29.web.core.windows.net";
  }
}
