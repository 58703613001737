<div class="row my-3 justify-content-md-center paymentSuccess" *ngIf="!isPaymentSuccess  && !isAuth">
    <div class="col col-lg-10 col-md-8 col-sm-12 col-xs-12">
        <div class="paymentContainer">
            <br><br>
            <h5 class="text-center mb-3">PAYMENT ERROR</h5> <br><br>

            <img src="../../assets/payment-error.gif" alt="" class="d-block mx-auto" style="max-width: 200px;">
            <p class="resMsg">{{ transactionID }} </p>
            <div class="amountFont"><label class="labelCls">Transaction Status:</label> {{ transactionStatus }} </div>
            <div class="amountFont"><label class="labelCls">Transaction ID:</label> {{ transactionID }}</div>
            <div class="amountFont"><label class="labelCls">Transaction Date and Time:</label>{{transactionDate }}</div>
            
            <br><br>
            <div class="text-center ">
                <!-- <button name="payment" class="btn btn-success" (click)="goBack()">Go Back To Site</button> -->
            </div> 
           </div>
    </div>
</div>


<div class="row my-3 justify-content-md-center paymentSuccess" *ngIf="isPaymentSuccess && !isAuth">
    <div class="col col-lg-10 col-md-8 col-sm-12 col-xs-12">
        <div class="paymentContainer">
            <br><br>
            <h5 class="text-center mb-3">PAYMENT SUCCESS</h5> <br><br>

            <img src="../../assets/payment-success.png" alt="" class="d-block mx-auto" style="max-width: 200px;">
            <p class="resMsg">{{ transactionID }} </p>
            <div class="amountFont"><label class="labelCls">Transaction Status:</label> {{ transactionStatus }} </div>
            <div class="amountFont"><label class="labelCls">Transaction ID:</label> {{ transactionID }}</div>
            <div class="amountFont"><label class="labelCls">Transaction Date and Time:</label>{{transactionDate  }}
            </div>
            
            <br><br>
            <div class="text-center ">
                <!-- <button name="payment" class="btn btn-success" (click)="goBack()">Go Back To Site</button> -->
            </div> 

           </div>
    </div>
</div>


<div class="row my-3 justify-content-md-center paymentSuccess" *ngIf="isAuth">
    <div class="col col-lg-10 col-md-8 col-sm-12 col-xs-12">
        <div class="paymentContainer">
            <br><br>
            <h5 class="text-center mb-3">Unauthorized Access</h5><br><br>

             <img src="../../assets/unauthorized.png" alt="" class="d-block mx-auto" style="max-width: 400px;">
            

             <br><br>
            <div class="text-center ">
                <!-- <button name="payment" class="btn btn-success" (click)="goBack()">Go Back To Site</button> -->
            </div> 
        </div>
    </div>


</div>