<div class="certificateInfo ">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">FamilyIncome:</label>
                <div class="valueCls">{{certificateInfo?.familyIncome}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Occupation:</label>
                <div class="valueCls">{{certificateInfo?.occupation}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Rice Card Number:</label>
                <div class="valueCls">{{certificateInfo?.riceCardNumber}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Habitation:</label>
                <div class="valueCls">{{certificateInfo?.habitation}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Cluster:</label>
                <div class="valueCls">{{clusterName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">FP Shop:</label>
                <div class="valueCls">{{certificateInfo?.fpShopNo}}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo ">
    <div class="subHeadingCls mb-3">Changed Rice Card Address</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Door No:</label>
                <div class="valueCls">{{presentAddr ? presentAddr[0]?.doorNo: ''}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{commonFormAddedFields?.presentDistrict}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{commonFormAddedFields?.presentMandal}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{commonFormAddedFields?.presentVillage}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">PinCode:</label>
                <div class="valueCls">{{presentAddr ? presentAddr[0]?.pinCode: ''}}</div>
            </div>
        </div>
    </div>
</div>

