<div *ngIf="certificateInfo">

    <div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
        <div class="subHeadingCls mb-3">Applicantion Form</div>
        <div class="row">
            
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Is Your Establishment An Industry:</label>
                    <div class="valueCls">{{certificateInfo?.isEstablishmentAnIndustry ? 'YES' : 'NO' }}</div>
                </div>
            </div>
            <!--<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">AP Shops and ESTTS, Act 1988:</label>
                    <div class="valueCls">{{certificateInfo?.registrationRequired.isAct1988 ? 'YES' : 'NO' }}</div>
                </div>
            </div> -->
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Registered with AP innovation Society:</label>
                    <div class="valueCls">{{certificateInfo?.registeredWithAPInnovationSociety ? 'YES' : 'NO' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">relationship:</label>
                    <div class="valueCls">{{certificateInfo?.relationship ? (certificateInfo?.relationship) : '-' }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
        <div class="subHeadingCls mb-3">Service Details</div>
        <div class="row">
            <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Application Number:</label>
                    <div class="valueCls">{{certificateInfo?.serviceDetails.applicationNumber ? (certificateInfo?.serviceDetails.applicationNumber) : '-' }}</div>
                </div>
            </div> -->
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Area Of Service:</label>
                    <div class="valueCls">{{certificateInfo?.serviceDetails.areaOfService ? (certificateInfo?.serviceDetails.areaOfService) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Nature Of Business:</label>
                    <div class="valueCls">{{certificateInfo?.serviceDetails.natureOfBusiness ? (certificateInfo?.serviceDetails.natureOfBusiness) : '-' }}</div>
                </div>
            </div>
            
        </div>
    </div>
    <div class="certificateInfo">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">AP Shops and ESTTS, Act 1988:</label>
                    <div class="valueCls">{{certificateInfo?.registrationRequired.isAct1988 ? 'YES' : 'NO' }}</div>
                </div>
            </div>
        </div>
        <div class="subHeadingCls mb-3">Worker Details</div>       
        <br>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <table class="tableCls mt-2">
                    <thead>
                    <tr> 
                        <th>Category</th>
                        <th>Male</th>
                        <th>Female</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Contract Workers</td>
                            <td class="value">{{certificateInfo.workerDetails.contractWorkers.male ? (certificateInfo.workerDetails.contractWorkers.male) : '0' }}</td>
                            <td class="value">{{certificateInfo.workerDetails.contractWorkers.female ? (certificateInfo.workerDetails.contractWorkers.female) : '0' }}</td>
                        </tr>
                        <tr>
                            <td>Daily Wage Workers</td>
                            <td class="value">{{certificateInfo.workerDetails.dailyWageWorkers.male ? (certificateInfo.workerDetails.dailyWageWorkers.male) : '0' }}</td>
                            <td class="value">{{certificateInfo.workerDetails.dailyWageWorkers.female ? (certificateInfo.workerDetails.dailyWageWorkers.female) : '0' }}</td>
                        </tr>
                        <tr>
                            <td>Direct Workers</td>
                            <td class="value">{{certificateInfo.workerDetails.directWorkers.male ? (certificateInfo.workerDetails.directWorkers.male) : '0' }}</td>
                            <td class="value">{{certificateInfo.workerDetails.directWorkers.female ? (certificateInfo.workerDetails.directWorkers.female) : '0' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Total Workers:</label>
                    <div class="valueCls">{{certificateInfo?.workerDetails.noOfWorker ? (certificateInfo?.workerDetails.noOfWorker) : '0' }}</div>
                </div>
            </div>
        </div>
        </div>
    <div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
        <div class="subHeadingCls mb-3">Establishment Details</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Name:</label>
                    <div class="valueCls">{{certificateInfo?.establishmentDetails.name ? (certificateInfo?.establishmentDetails.name) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Category:</label>
                    <div class="valueCls">{{certificateInfo?.establishmentDetails.category ? (certificateInfo?.establishmentDetails.category) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Classification:</label>
                    <div class="valueCls">{{certificateInfo?.establishmentDetails.classification ? (certificateInfo?.establishmentDetails.classification) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Ownership Type:</label>
                    <div class="valueCls">{{certificateInfo?.establishmentDetails.ownerShipType ? (certificateInfo?.establishmentDetails.ownerShipType) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Date Of Commencement:</label>
                    <div class="valueCls">{{certificateInfo?.establishmentDetails.dateOfCommencement ? (certificateInfo?.establishmentDetails.dateOfCommencement | date:'dd MMM yyyy') : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Date Of Completion:</label>
                    <div class="valueCls">{{certificateInfo?.establishmentDetails.dateOfCompletion ? (certificateInfo?.establishmentDetails.dateOfCompletion | date:'dd MMM yyyy') : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">District Name:</label>
                    <div class="valueCls">{{certificateInfo?.establishmentDetails.address.districtName ? (certificateInfo?.establishmentDetails.address.districtName) : '-' }}</div>
                </div>
            </div>
        
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Mandal Name:</label>
                    <div class="valueCls">{{certificateInfo?.establishmentDetails.address.mandalMunicipalityName ? (certificateInfo?.establishmentDetails.address.mandalMunicipalityName) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Village Name:</label>
                    <div class="valueCls">{{certificateInfo?.establishmentDetails.address.villageWardName ? (certificateInfo?.establishmentDetails.address.villageWardName) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Door No:</label>
                    <div class="valueCls">{{certificateInfo?.establishmentDetails.address.doorNo ? (certificateInfo?.establishmentDetails.address.doorNo) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Locality:</label>
                    <div class="valueCls">{{certificateInfo?.establishmentDetails.address.locality ? (certificateInfo?.establishmentDetails.address.locality) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Pin Code:</label>
                    <div class="valueCls">{{certificateInfo?.establishmentDetails.address.pinCode ? (certificateInfo?.establishmentDetails.address.pinCode) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Email:</label>
                    <div class="valueCls">{{certificateInfo?.establishmentDetails.email ? (certificateInfo?.establishmentDetails.email) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Mobile No:</label>
                    <div class="valueCls">{{certificateInfo?.establishmentDetails.mobileNo ? (certificateInfo?.establishmentDetails.mobileNo) : '-' }}</div>
                </div>
            </div>
        </div>
    </div>
        <div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
            <div class="subHeadingCls mb-3">Employer Details</div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Name:</label>
                        <div class="valueCls">{{certificateInfo?.employerDetails.name ? (certificateInfo?.employerDetails.name) : '-' }}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Aadhar Number:</label>
                        <div class="valueCls">{{certificateInfo?.employerDetails.aadharNumber ? (certificateInfo?.employerDetails.aadharNumber) : '-' }}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Mobile Number:</label>
                        <div class="valueCls">{{certificateInfo?.employerDetails.mobileNo ? (certificateInfo?.employerDetails.mobileNo) : '-' }}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Designation:</label>
                        <div class="valueCls">{{certificateInfo?.employerDetails.designation ? (certificateInfo?.employerDetails.designation) : '-' }}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Father/Husband Name:</label>
                        <div class="valueCls">{{certificateInfo?.employerDetails.fatherHusbandName ? (certificateInfo?.employerDetails.fatherHusbandName) : '-' }}</div>
                    </div>
                </div>
            <!--</div>
            <div class="row"> -->
                
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">State:</label>
                        <div class="valueCls">{{certificateInfo?.employerDetails.state ? (certificateInfo?.employerDetails.state) : '-' }}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">District Name:</label>
                        <div class="valueCls">{{certificateInfo?.employerDetails.address.districtName ? (certificateInfo?.employerDetails.address.districtName) : '-' }}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Mandal Name:</label>
                        <div class="valueCls">{{certificateInfo?.employerDetails.address.mandalMunicipalityName ? (certificateInfo?.employerDetails.address.mandalMunicipalityName) : '-' }}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Village Name:</label>
                        <div class="valueCls">{{certificateInfo?.employerDetails.address.villageWardName ? (certificateInfo?.employerDetails.address.villageWardName) : '-' }}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Pin Code:</label>
                        <div class="valueCls">{{certificateInfo?.employerDetails.address.pinCode ? (certificateInfo?.employerDetails.address.pinCode) : '-' }}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Other State Address:</label>
                        <div class="valueCls">{{certificateInfo?.employerDetails.otherStateAddress ? (certificateInfo?.employerDetails.otherStateAddress) : '-' }}</div>
                    </div>
                </div>
            </div>
        </div>

        
        
        
        <div class="certificateInfo">
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
                    <ng-container *ngIf="fileList && fileList.length !== 0">
                    <div class="subHeadingCls mb-3">List of Documents</div>
                    <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                        
                        <div *ngIf="file.documentType === 'empPhoto'">
                            <span class="numberCls">{{i+1}}</span><label class="labelCls">Employer Photo with Signature:</label>
                            <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                        </div>
                        <div *ngIf="file.documentType === 'panNo'">
                            <span class="numberCls">{{i+1}}</span><label class="labelCls">PAN Card:</label>
                            <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                        </div>
                        <div *ngIf="file.documentType === 'aadharNo'">
                            <span class="numberCls">{{i+1}}</span><label class="labelCls">Aadhar Card:</label>
                            <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                        </div>
                        <div *ngIf="file.documentType === 'vatNo'">
                            <span class="numberCls">{{i+1}}</span><label class="labelCls">VAT:</label>
                            <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                        </div>
                        <div *ngIf="file.documentType === 'gstNo'">
                            <span class="numberCls">{{i+1}}</span><label class="labelCls">GST:</label>
                            <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                        </div>
                        <div *ngIf="file.documentType === 'uploadEstablishment'">
                            <span class="numberCls">{{i+1}}</span><label class="labelCls">Establishment:</label>
                            <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                        </div>
                        <div *ngIf="file.documentType === 'Authorization'">
                            <span class="numberCls">{{i+1}}</span><label class="labelCls">Authorization:</label>
                            <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                        </div>
                    </div>
                    </ng-container>
                </div>
            </div>
        </div>
</div>