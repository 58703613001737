<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Revenue Village/Ward:</label>
                <div class="valueCls">{{certificateInfo?.villageName}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">SubCaste: </label>
                <div class="valueCls">{{certificateInfo?.subCaste}}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Income Certificate Details</div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Gross Annual Income includes Salary, Agriculture, Business, Profession etc., for the financial year prior to the year of application (Applicant/His/Her Family):</label>
                <div class="valueCls">{{certificateInfo.grossAnnualIncome ? (certificateInfo.grossAnnualIncome) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Assets Information(Applicant/His/Her/Family)</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">5 Acres of agriculture land and above: </label>
                <div class="valueCls">{{certificateInfo.isAboveFiveAcresLand ? 'Yes': 'No'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Residential flat of 1000 sq.ft. and above:</label>
                <div class="valueCls">{{certificateInfo.isAboveThousandSquareFeetFlat ? 'Yes': 'No'}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Residential plot of 100 sq.yards and above in notified municipalities:</label>
            <div class="valueCls">{{certificateInfo.isAboveHundredSquareYardsPlot? 'Yes' : 'No'}}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Residential plot of 200 sq.yards and above in areas other than notified municipalities:</label>
            <div class="valueCls">{{certificateInfo.isAboveTwoHundredSquareYardsPlot? 'Yes' : 'No'}}</div>
        </div>
        </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Ration Card Certificate'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Ration card/EPIC card/AADHAR card:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'IT returns Docs'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Copy of IT Returns/Pay slips (Any Other documents):</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Affidavit'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Affidavit:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Photo'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Photo:</label>
                    <div class="valueCls"><a (click)="viewImageFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                  
            </div>
            </ng-container>
        </div>
    </div>
</div>

