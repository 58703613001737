import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';
import { NgbModal, NgbModalConfig,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-late-reg-certificate-view',
  templateUrl: './late-reg-certificate-view.component.html',
  styleUrls: ['./late-reg-certificate-view.component.css']
})
export class LateRegCertificateViewComponent implements OnInit {
  commonConstants : any = CommonConstants;
  @Input() certificateInfo: any;
  @Input() reqId: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();

  barcodeData:any="";
  screenMandal: any;
  screenDistrict: any;
  screenVillage: any;
  closeResult:any;

  elementType = 'svg';
  value = 'someValue12340987';
  format = 'CODE128';
  lineColor = '#000000';
  width = 2;
  height = 60;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;

  constructor(private commonService: CommonService, private modalService: NgbModal, modalConfig: NgbModalConfig) {

  }

  ngOnInit(): void {
  //   this.getByEntitycode(this.certificateInfo.incidenceAddress?.district, 'District');
  // this.getByEntitycode(this.certificateInfo.incidenceAddress?.mandalMunicipality, 'Mandal');
  // this.getByEntitycode(this.certificateInfo.incidenceAddress?.villageWard , 'Secretariat');

  console.log(this.reqId)
  console.log(this.certificateInfo,'certificate info')
  }

  // getByEntitycode(entityCode: any, entityType:any, addrType?: any): any {
  //   this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({  
  //   next: (responseData: any) => { 
  //       let name =  responseData.result[0]?.name;
  //       if (entityType === 'District' ) {
  //         this.screenDistrict = name;
  //       }else if (entityType === 'Mandal') {
  //         this.screenMandal = name; 
  //       } else if (entityType === 'Secretariat') {
  //         this.screenVillage = name;
  //       }
  //     },
  //     error: (error) => {
  //       console.log('client side', error);
  //     }
  // });
  // }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }


  showCheckListData(content:any){
    this.modalService
    .open(content, {
      //size: 'lg',
      size: 'xl',
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'app-modal-window',
    })
    .result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  public printChkList() {
    let Pagelink = 'about:blank';
    var pwa = window.open(Pagelink, '_new');
    pwa?.document.open();
    pwa?.document.write(this.preparePrintFormat());
    pwa?.document.close();
  }

  preparePrintFormat() {
    const printContent: HTMLElement | null =
      document.getElementById('_printingCheckList');
    let htmlData: any = '';
    let printdata = printContent?.innerHTML;
    htmlData +=
      '<html><head><title>CheckList</title> <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">';
    htmlData +=
      "<style type='text/css' media='print'> @media print{@page{size: auto; margin: 0;}.tblTitle{ background-color:black !important;color:white !important;text-align:center !important;padding:15px 0px !important;text-decoration:underline !important;font-size:20px !important;font-weight:bold !important;margin:0;}.ldbrTbl p{border-bottom:1px solid #000 !important;margin:0 !important;padding:5px 0 !important;width:100% !important;text-overflow:ellipsis !important;white-space:nowrap !important;overflow:hidden !important;}.table-bordered td,.table-bordered th{border:1px solid black !important;font-size:12px !important;padding: 5px !important;  text-align: left;!important;}.sr_no{width:5% !important;}.tbl_title{width:20% !important;}.tbl_desc{width:45% !important;}.tbl_descEnd{width:30% !important;}.headBlod{font-weight:bold !important;font-size:13px !important;}.ldbr_container{margin:0px 25px !important;}.reqIdCls{font-size: 18px !important;font-weight: bold !important;}</style> <script>function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'><br><br>";

    htmlData += printdata;
    htmlData += '</body></html>';

    return htmlData;
  }

}
