<div class="reportContainer mx-3">
 <div class="header">
     <h6>Reschedule/Add New Schedule for Welfare Schemes</h6>
 </div>
 <div class="btnHolder">
    <button  class="greenBtnCls float-right mr-3 my-3"  (click)="goToHome()">Home</button>
</div>
 <div [formGroup]="form">
    <div class="row mb-3">
        <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12" [ngClass]="{ 'is-invalid':submitted && f.scheduleType?.errors }">
            <input type="radio"  value="reshedule" name="scheduleType" formControlName="scheduleType"  (change)="onScheduleChange()">
            <span class="mx-2 radioCls">Reschedule</span>
            <input type="radio"  value="addNew" name="scheduleType" formControlName="scheduleType" (change)="onScheduleChange()">
            <span class="mx-2 radioCls">Add New Schedule</span>
            <div *ngIf="submitted && f.scheduleType.errors" class="selectError">
                <div *ngIf="f.scheduleType.errors.required">Please select Schedule Type</div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="f.scheduleType.value === 'reshedule'">
    <div class="row mb-3">
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="district" (change)="onDistrictChange('schedule')"
        [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
        <option [ngValue]= "null" disabled>Select</option>
          <option *ngFor="let district of districts" [ngValue]="district">{{district.districT_NAME}}</option>
        </select>
        <div *ngIf="submitted && (f.district.errors || f.district.value === 'Select')" class="selectError">
            <div *ngIf="f.district.errors?.required || f.district.value === 'Select'">Please select District</div>
        </div>
      </div>
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Mandal/Municipality:  <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="mandal" (change)="onMandalChange('schedule')"
        [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
        <option [ngValue]= "null" disabled>Select</option>
        <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandaL_NAME}}</option>
      </select>
      <div *ngIf="submitted && (f.mandal.errors || f.mandal.value === 'Select')" class="selectError">
        <div *ngIf="f.mandal.errors?.required || f.mandal.value === 'Select'">Please select Mandal/Municipality</div>
    </div>
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Village/Ward/Secretariat:  <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="secretariat"
        [ngClass]="{ 'is-invalid':submitted && (f.secretariat.errors || f.secretariat.value === 'Select') }">
        <option [ngValue]= "null" disabled>Select</option>
        <option *ngFor="let secretariat of secretariats" [ngValue]="secretariat">{{secretariat.secretariaT_NAME}}</option>
      </select>
      <div *ngIf="submitted && (f.secretariat.errors || f.secretariat.value === 'Select')" class="selectError">
        <div *ngIf="f.secretariat.errors?.required || f.secretariat.value === 'Select'">Please select Village/Ward/Secretariat</div>
      </div>
    </div>
    </div>
    <div class="mb-3 mt-4">
        <div class="btnHolder text-center col-xs-12">
            <button class="greenBtnCls mx-auto d-block" (click)="getSchedules()">Get Details</button>
        </div>
    </div>

    <div class="table-responsive tableFixHead" *ngIf="showReports">
        <table class="tableCls" >
            <thead> 
                <tr>
                    <th colspan="8">Schedule for Welfare Schemes</th>
                </tr>
                <tr>
                <th>S.No</th>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'ruraL_URBAN_FLAG'">RURAL/URBAN<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'">Secretariat<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'">Secretariat Code<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'schedulE_DATE'">Scheduled Date <mdb-icon fas icon="sort"></mdb-icon></th>
                <th>Action</th>
                </tr>
            </thead>

            <tbody *ngIf="items && items.length > 0">
                <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                    <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                    <td>{{item.districT_NAME}}</td>
                    <td>{{item.mandaL_NAME}}</td>
                    <td>{{item.ruraL_URBAN_FLAG === 'R'? 'Rural': 'Urban'}}</td>
                    <td>{{item.secretariaT_NAME}}</td>
                    <td class="text-right">{{item.secretariaT_CODE}}</td>
                    <td class="text-right">{{item.schedulE_DATE}}</td>
                    <td class="text-right">
                    <button class="greenBtnCls" (click)="openSchedule(item, 'reshedule', scheduleModal)">Reshedule</button>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="items && items.length === 0">
                <tr>
                    <td colspan="8" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </table>
        <div class="mt-4 btnHide">
            <div class="float-left" *ngIf="items.length > 0">
                <label>Requests per page: </label>
                <select class="selectCls pageSizeCls" [(ngModel)]="pageSize" [ngModelOptions]="{standalone: true}">
                    <option  value="10">10</option>
                    <option  value="20">20</option>
                    <option  value="30">30</option>
                    <option  value="40">40</option>
                    <option  value="50">50</option>
                </select> 
            </div>
            <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
            </ngb-pagination>
        </div>
    </div>
    </ng-container>
    <ng-container *ngIf="f.scheduleType.value === 'addNew'">
        <div class="row mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
                <select class="selectCls" formControlName="pendingDistrict" (change)="onDistrictChange()"
                [ngClass]="{ 'is-invalid':submitted && (f.pendingDistrict.errors || f.pendingDistrict.value === 'Select') }">
                <option [ngValue]= "null" disabled>Select</option>
                  <option *ngFor="let district of pendingDistricts" [ngValue]="district">{{district.districT_NAME}}</option>
                </select>
                <div *ngIf="submitted && (f.pendingDistrict.errors || f.pendingDistrict.value === 'Select')" class="selectError">
                    <div *ngIf="f.pendingDistrict.errors?.required || f.pendingDistrict.value === 'Select'">Please select District</div>
                </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Mandal/Municipality:  <span class="mandatoryCls">*</span></label>
                <select class="selectCls" formControlName="pendingMandal" (change)="onMandalChange()"
                [ngClass]="{ 'is-invalid':submitted && (f.pendingMandal.errors || f.pendingMandal.value === 'Select') }">
                <option [ngValue]= "null" disabled>Select</option>
                <option *ngFor="let mandal of pendingMandals" [ngValue]="mandal">{{mandal.mandaL_NAME}}</option>
              </select>
              <div *ngIf="submitted && (f.pendingMandal.errors || f.pendingMandal.value === 'Select')" class="selectError">
                <div *ngIf="f.pendingMandal.errors?.required || f.pendingMandal.value === 'Select'">Please select Mandal/Municipality</div>
            </div>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Village/Ward/Secretariat:  <span class="mandatoryCls">*</span></label>
                <select class="selectCls" formControlName="pendingSecretariat"
                [ngClass]="{ 'is-invalid':submitted && (f.pendingSecretariat.errors || f.pendingSecretariat.value === 'Select') }">
                <option [ngValue]= "null" disabled>Select</option>
                <option *ngFor="let secretariat of pendingSecretariats" [ngValue]="secretariat">{{secretariat.secretariaT_NAME}}</option>
              </select>
              <div *ngIf="submitted && (f.pendingSecretariat.errors || f.pendingSecretariat.value === 'Select')" class="selectError">
                <div *ngIf="f.pendingSecretariat.errors?.required || f.pendingSecretariat.value === 'Select'">Please select Village/Ward/Secretariat</div>
            </div>
            </div>
            </div>
            <div class="mb-3 mt-4">
                <div class="btnHolder text-center col-xs-12">
                    <button class="greenBtnCls mx-auto d-block" (click)="openSchedule('', 'addShedule', scheduleModal)">Add New Schedule</button>
                </div>
            </div>
    </ng-container>
    </div>
</div>


<ng-template #scheduleModal let-modal class="modalCls">
    <div class="modal-header">
        <h6 class="modal-title" id="modal-basic-title" *ngIf="f.scheduleType.value === 'addNew'">Add New Schedule</h6>
        <h6 class="modal-title" id="modal-basic-title" *ngIf="f.scheduleType.value === 'reshedule'">Reshedule Program</h6>
    </div>
    <div class="modal-body">
        <div [formGroup]="scheduleForm">
        <div class="row">
          <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12  mb-3">
              <label class="labelCls" *ngIf="f.scheduleType.value === 'reshedule'">Reschedule Date: <span class="mandatoryCls">*</span></label>
              <label class="labelCls" *ngIf="f.scheduleType.value === 'addNew'">Schedule Date: <span class="mandatoryCls">*</span></label>

              <div class="input-group">
                <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                        name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="minDate" 
                        [maxDate]="maxDate" required formControlName="resheduleDate"  required
                        [ngClass]="{ 'is-invalid':scheduleSubmitted && f1.resheduleDate?.errors }" >
                <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
                </div>
            </div>
            <div *ngIf="scheduleSubmitted && f1.resheduleDate?.errors" class="selectError">
              <div *ngIf="f1.resheduleDate?.errors?.required || f1.resheduleDate?.invalid">Please select  Schedule Date</div>
          </div>
          </div>        
        </div>
        <div class="row" *ngIf="f.scheduleType.value === 'reshedule'">
          <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12  mb-3">
              <label class="labelCls">Reason for Reshedule: <span class="mandatoryCls">*</span></label>
              <input  type="text" class="inputCls value" name="reason" formControlName="reason" 
                    [ngClass]="{ 'is-invalid':scheduleSubmitted && f1.reason.errors }" maxlength="150" >
              <div *ngIf="scheduleSubmitted && f1.reason.errors" class="selectError">
                <div *ngIf="f1.reason.errors?.required || f1.reason.errors?.required || f1.reason.errors.whiteSpaceValidate">Please enter Reason for Reshedule</div>
              </div>
          </div>
        </div>
        </div>
      </div>
      <div class="modal-footer">
        <button  name="close" class="greenBtnCls" (click)="modal.dismiss();">Cancel</button>
        <button type="submit" *ngIf="f.scheduleType.value === 'reshedule'" class="greenBtnCls" name="submit" (click)="saveReschedule()">Submit</button>
        <button type="submit" *ngIf="f.scheduleType.value === 'addNew'" class="greenBtnCls" name="submit" (click)="addNewSchedule()">Submit</button>

      </div>
</ng-template>
  

