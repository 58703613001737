import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { AadharValidator, WhitespaceValidator } from '../common.service';
import { CommonConstants } from '../../constants/common-constants.component';
@Component({
  selector: 'app-successor-aadhar-popup',
  templateUrl: './successor-aadhar-popup.component.html',
  styleUrls: ['./successor-aadhar-popup.component.css']
})
export class SuccessorAadharPopupComponent implements OnInit {
  popupSubmitted: Boolean = false;
  popupForm : FormGroup;
  @Input() name: string = '';
  @Input() isLPM: Boolean = false;
  commonConstants: any = CommonConstants;
  constructor(private formBuilder: FormBuilder, public activeModal: NgbActiveModal, config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.popupForm = this.formBuilder.group({
      successorAadharNo: [null, [Validators.required,  WhitespaceValidator.whiteSpaceValidate]],
      fatherHusbandName: [null]
    });
  }
  get f1() { return this.popupForm.controls; }
  ngOnInit(): void {
  }
  getSuccessorLand(): any {
    if(this.isLPM) {
      this.f1.fatherHusbandName.setValidators([Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]);
    } else {
      this.f1.fatherHusbandName.clearValidators();
    }
    this.f1.fatherHusbandName.updateValueAndValidity();
    this.popupSubmitted = true;
    //console.log('basic form', this.basicDetailsForm.controls['commonForm'].value);
    if(!this.popupForm.valid) {
      return false;
    } else {
      let data: any;
      if(this.isLPM) {
        data = {};
        data.aadharNo = this.popupForm.controls['successorAadharNo'].value;
        data.fatherHusbandName = this.popupForm.controls['fatherHusbandName'].value;
      } else {
        data = this.popupForm.controls['successorAadharNo'].value
      }
      this.activeModal.close(data);
    }
  }
}
