<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
            GSWS /Application Submission /Check Challan Status 
        </div>
    </div>
<div class="mainPageContainer mt-15 mr-3">
        <form [formGroup]="challanmapping">
            <div class="row  mb-3"> 
            <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <label class="subHeadingCls">Source of Payment</label>
                <select class="selectCls" formControlName="status" required
                 [ngClass]="{'is-invalid':submitted && f.status.errors}"
                 (change)="onSelectChange()">
                            <option [ngValue]="null" disabled>Select</option>
                            <option value="OLD">Old Portal</option>
                            <option value="NEW">AP Seva</option>
                            <option value="MESEVA">Meeseva</option>
                            <option value="B2C">Bill Payments</option>
                        </select>
                 <div *ngIf="submitted" class="selectError">
                    <div *ngIf="f.status.errors?.required">Please select Source</div>
                 </div>
                </div>
            <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
               <label class="subHeadingCls">Transaction Id:</label>
               <div class="input-group">
                <input type="text" class="inputCls value"  name="TransactionId" id="TransactionId" formControlName="TransactionId" required>
                <div *ngIf="submitted && f.TransactionId.errors" class="selectError">
                    <div *ngIf="f.TransactionId.errors?.required">Please Enter transaction id</div>
                </div>  
            </div>
            </div>
            <div *ngIf= "f.status.value == 'OLD'" class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <label class="subHeadingCls">App No:</label>
                    <div class="input-group">
                    <input type="text" class="inputCls value" name="appNo" id="appNo" formControlName="appNo">
                    <div *ngIf="submitted && f.appNo.errors" class="selectError">
                        <div *ngIf="f.appNo.errors?.required">Please Enter App No</div>
                    </div>
                </div> 
                </div>
            </div>        
</form> 
<div class="row mb-3 mt-4">
    <div class="btnHolder text-center col-12">
        <button class="btn btn-primary mx-auto d-block" (click)="submit()">Get Details</button>
    </div>
</div>

<div class="mr-3" *ngIf="showTable">
    <table class="tableCls">
        <thead *ngIf="reqData!=='Record Not Found'">
            <tr>
            <th>Transaction Id</th>
            <th>Transaction Date</th>
            <th>Challan Id</th>
            <th>Challan Date</th>
            <th>Challan Status</th>
            </tr>
        </thead>
            <tbody *ngIf="reqData!=='Record Not Found'">
                    <tr>
                    <td>
                    <ng-container
                        *ngIf="f.status.value !== 'NEW' || f.status.value !== 'MEESEVA'" class="mb-0"> {{
                        reqData?.transactionId }}   {{reqData?.dept_App_No? '(' + reqData?.dept_App_No + ')' : ''}}  </ng-container> </td>
                    <td>{{reqData.transactionDate | date: 'dd/MM/yyyy h:mm:ss a'}}</td> 
                    <td>{{reqData.challanId ? reqData.challanId:'-'}}</td>  
                    <td>{{reqData.challanDate?(reqData.challanDate| date: 'dd/MM/yyyy h:mm:ss a'):'-'}}</td> 
                    <td>{{reqData.challanStatus ? reqData.challanStatus:'-'}}</td> 
                     </tr>
        </tbody>
        <tbody *ngIf="reqData=='Record Not Found'">
             <tr>
            <td [attr.colspan]="5" class="text-center">No records found.</td></tr>
        </tbody>
    </table>
</div>
</div>
 </div>
