// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  referenceData: 'https://gsws-refdata-demo.azurewebsites.net/',
  citizenInfo : 'https://gsws-ctzeninfo-demo.azurewebsites.net/',
  revenue: 'https://gsws-revenue-demo.azurewebsites.net/',
  fileUpload: 'https://gsws-fileuploads-demo.azurewebsites.net/',
  worklist: 'https://gsws-workflow-demo.azurewebsites.net/',
  pdfGenerator : 'https://gsws-pdfgen-demo.azurewebsites.net/',
  weblandAPI : 'https://gsws-inbound-apim-test.azure-api.net/',
  maudServices: 'https://gswsmaudapiwebw.azurewebsites.net/',
  civilSupplies : 'https://gswscivilsuppliesapiwebw.azurewebsites.net/',
  civilSuppliesMaster : 'https://gswscivilsupplyreferenceapiwebw.azurewebsites.net/',
  energyServices: 'https://gswsenergyapiwebw.azurewebsites.net/',
  citizenInfoFromGSWS: 'https://gramawardsachivalayam.ap.gov.in/GSWSAPI/',
  civilSuppliesExternalApi :'https://gswscivilsuppliesexternalapiwebw.azurewebsites.net/',
  RSAPublicKey: '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCI8mgGhPGVwqUjroV+c6VB/adVkrfAyTfuGzxijz7SyildL31Qsvz4UhzU3kdQQ7xgGEswHj0v9PVjiraM7J+D1VZUu54FqQMmJMs0fQF2egZkl5e9TOUnJhTMKu4OWxrete+zFyFFHVV05vyv4Je80arUjqOhP2Fkvc2XB7/BKwIDAQAB-----END PUBLIC KEY-----',
  RSAPrivateKey: '-----BEGIN RSA PRIVATE KEY-----MIICXQIBAAKBgQCI8mgGhPGVwqUjroV+c6VB/adVkrfAyTfuGzxijz7SyildL31Qsvz4UhzU3kdQQ7xgGEswHj0v9PVjiraM7J+D1VZUu54FqQMmJMs0fQF2egZkl5e9TOUnJhTMKu4OWxrete+zFyFFHVV05vyv4Je80arUjqOhP2Fkvc2XB7/BKwIDAQABAoGBAIMCGt/5uD8DaQWchBp3OWXK6ZSkeeMU28uOBJNo47m1KiL6NlDIGdbYfrppNiGj2wa21t7eXryNA19JqN9h0ipZ4QpS6fDFQDS4XkgPiV2QSQNBwth+2+EOPo+ndW8oUxD/kkrdkGXffcO3nzHQoeDKUUaZD9oQqXlYKLCdFgZRAkEA3UV7fOrUlYdkkAkP4OtOO8urTDNXNpSIC8bdzJOQscNGmGsgsva8iPCt504X/BtWt20xOkdAKpkwWc8+7oTIGQJBAJ5w1DK9MFYU3Lp0fF4tsmCK8gYvQQyTJ4xomnhA1EKgeI2dSkTsjlZnMI/MBQSzNOs4xWcoiaJPSocHG5TbS+MCQQC0ePCAcVRUZWq7I/kEfUeA89xSOWm/xaO0302WhyE0K86PkIjKDv61PoDIsORVJsMytme5XdXTKMDL74Inrrs5AkB+XoFw4qJd1g/jCytytkHSbJ/yvNfGHsxpWbjydsqVRYZBdRWaeY5hbbY2ysOp5wErJ0GTMKrt8Q4BOnMOPAZ/AkAg7tQpUHXJl7XsXDz5/B6MILJjh8NWQKk4B5/w97cDjbZ/P5tvv3NpftD3WtxxhuhbhxSW+EZ87uAfKW3OFEH8-----END RSA PRIVATE KEY-----'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
