import { Component,Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-printingof-titledeed-view',
  templateUrl: './printingof-titledeed-view.component.html',
  styleUrls: ['./printingof-titledeed-view.component.css']
})
export class PrintingofTitledeedViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
}
