import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { CommonService } from '../../shared/common.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-reconsulation-payment-status',
  templateUrl: './reconsulation-payment-status.component.html',
  styleUrls: ['./reconsulation-payment-status.component.css'],
})
export class ReconsulationPaymentStatusComponent implements OnInit {
  errorCls: boolean = false;
  challnRrrorCls: boolean = false;
  commonConstants: any = CommonConstants;
  apiResponse: any;
  gswsoldApiResponse: any;
  closeResult: any;
  challanData: any = [];

  //@ViewChild('reconsallationStatus') reconsallationStatus!: ElementRef;
  constructor(
    private commonService: CommonService,
    private modalService: NgbModal
  ) {
    this.getReconsilationHistory();
    this.getGswsToVswsServiceHistory();
  }

  ngOnInit(): void {}

  getReconsilationHistory() {
    this.apiResponse = [];
    this.commonService
      .getRequest(this.commonConstants.getReconsilationHistory)
      .subscribe({
        next: (responseData: any) => {
          console.log(responseData, 'responseData');
          if (responseData?.result === 'Data Not Found') {
            alert('Billdesk Reconcillation data not available !!');
          } else {
            this.apiResponse = responseData?.result;
          }
        },
      });
  }

  submit(data: any) {
    this.errorCls = false;
    console.log(data);
    if (data === '') {
      this.errorCls = true;
    } else {
      //billDesk_Reconsilation
      this.commonService
        .getRequest(
          this.commonConstants.billDesk_Reconsilation +
            '?ChallanStatus=' +
            data +
            '&FromDate=null&ToDate=null'
        )
        .subscribe({
          next: (responseData: any) => {
            console.log(responseData, 'billDesk_Reconsilation responseData');
            this.getReconsilationHistory();
          },
        });
    }
  }

  refundAPI() {
    //billDesk_Refund
    this.commonService
      .getRequest(this.commonConstants.billDesk_Refund)
      .subscribe({
        next: (responseData: any) => {
          console.log(responseData, 'billDesk_Refund responseData');
          this.getReconsilationHistory();
        },
      });
  }

  getGswsToVswsServiceHistory() {
    this.gswsoldApiResponse = [];
    this.commonService
      .getRequest(this.commonConstants.getGswsToVswsServiceHistory, 'MAUD')
      .subscribe({
        next: (responseData: any) => {
          console.log(responseData, 'responseData');
          if (responseData?.result === 'Data Not Found') {
            alert('data not available !!');
          } else {
            this.gswsoldApiResponse = responseData?.result;
          }
        },
      });
  }

  saveGswsToVswsServiceData() {
    //saveGswsToVswsServiceData

    this.commonService
      .postRequestForWebLand(this.commonConstants.saveGswsToVswsServiceData, {})
      .subscribe({
        next: (responseData: any) => {
          console.log(responseData, 'saveGswsToVswsServiceData responseData');
          this.getGswsToVswsServiceHistory();
        },
      });
  }

  searchChallan(data: any, challanId: any) {
    this.challnRrrorCls = false;

    if (challanId != '') {
      this.commonService
        .getRequest(
          this.commonConstants.getReconsilationHistoryByChallanId + challanId )
        .subscribe({
          next: (responseData: any) => {
            console.log(responseData, 'responseData');
            if (responseData?.result?.isSuccess === true) {
              this.challanData = responseData?.result;
              this.showPopup(data);
            } else {
              alert(responseData?.result?.error);
            }
          },
        });
    }else{
      this.challnRrrorCls = true;
    }
  }

  showPopup(content: any) {
    // console.log(this.servicesData, "servicesData")

    this.modalService
      .open(content, {
        size: 'lg',
        ariaLabelledBy: 'modal-basic-title',
        windowClass: 'app-modal-window',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
