import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LabourRegistrationRoutingModule } from './labour-registration-routing.module';
import{ LabourRegistrationComponent} from './labour-registration/labour-registration.component';


@NgModule({
  declarations: [LabourRegistrationComponent],
  imports: [
    CommonModule,
    LabourRegistrationRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    NgbModule
  ]
})
export class LabourRegistrationModule { }
