import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-gsws-dynamic-survey-report',
  templateUrl: './gsws-dynamic-survey-report.component.html',
  styleUrls: ['./gsws-dynamic-survey-report.component.css']
})
export class GswsDynamicSurveyReportComponent implements OnInit {
  headerCol = [
    {colId:'district_name', colName: 'District'},
    {colId:'mandal_name', colName: 'Mandal'},
    {colId:'rural_urban', colName: 'Rural/Urban'},
    {colId:'secretariat_name', colName: 'Secretariat'},
    {colId:'designation', colName: 'Designation'},
    {colId:'role_type', colName: 'Position Type'}
    ]
  commonConstants : any = CommonConstants;
  submitted: Boolean = false;
  form: FormGroup;
  districts: any[] = [];
  mandals: any[] = [];
  villages: any[]= [];
  formatTypes: any[]= [];
  format: any;
  items: any[] = [];
  page = 1;
  pageSize = 10;
  showReports: Boolean = false;
  role:any;
  totalItems: any[] = [];
  noOfCol: any = 11;
  popupPageSize = 10;
  popupPage = 1;
  popupItems: any[] = [];
  @ViewChild('TABLE') table!: ElementRef;
  @ViewChild('SECRETARIATTABLE') secTable!: ElementRef;
  
  constructor(private formBuilder: FormBuilder, private commonService: CommonService, config: NgbModalConfig, private modalService: NgbModal,  private router: Router) { 
    config.backdrop = 'static';
    config.keyboard = false;
    this.form = this.formBuilder.group({
      village: [null],
      mandal: [null],
      district: [null],
      RUFlag: [null]
    });
    this.getDistricts();
  }

  ngOnInit(): void {
    this.format = 'District Wise';
     this.form.patchValue({
       district:'All',
       mandal:'All',
       village: 'All',
       RUFlag: 'All'
     });
     this.formatTypes = [
         {name: 'District Wise', isActive: true},
         {name:'Mandal Wise', isActive: false},
         {name:'Secretariat Wise', isActive: false},
         {name:'Designation Wise', isActive: false}
       ]
     this.getReportData();
     let userInfo = localStorage.getItem('userInfo');
       if (userInfo && userInfo !== null) {
         let userData = JSON.parse(userInfo);
         this.role = userData.jobTitle;
       }
   }
   get f() { return this.form.controls; }
   getDistricts() {
    this.commonService.getRequest(this.commonConstants.getCSPDistricts).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.districts = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'districtName');
          console.log(this.districts);
        } else {
          this.districts = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onRuralUrbanChange() {
    if(this.f['district'].value !== 'All') {
      this.onDistrictChange();
    }
  }
  onDistrictChange() {
    this.showReports = false;
    this.mandals = [];
    this.villages = [];
    this.f.mandal.patchValue('All');
    this.f.village.patchValue('All');
    this.getMandals();
  }

  getMandals(){
    this.mandals = [];
    this.villages = [];
    this.f.mandal.patchValue('All');
    this.f.village.patchValue('All');
    let RUFlag: any = this.f['RUFlag'].value === 'All' ? '' : this.f['RUFlag'].value;
    this.commonService.getRequest(this.commonConstants.getCSPMandalsRuralUrban + '?DistrictCode=' + this.f['district'].value.districtCode + '&RuralOrUrbanFlag=' + RUFlag ).subscribe({
      next: (responseData: any) => { 
        console.log(responseData)
        if(responseData.result?.isSuccess) {
          this.mandals = this.commonService.sortItems(responseData.result?.getMandalDetails, 'mandalName');
        } else {
          this.mandals = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onMandalChange() {
    this.showReports = false;
    this.villages = [];
    this.f.village.patchValue('All');
    this.commonService.getRequest(this.commonConstants.getCSPSecretariat + '?MandalCode=' + this.f.mandal.value.mandalCode).subscribe({
      next: (responseData: any) => { 
        if(responseData.result?.isSuccess) {
          this.villages = this.commonService.sortItems(responseData.result?.getSecretariatDetails, 'secretariatName');
        } else {
          this.villages = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onVillageChange() {
    this.showReports = false;
  }
  onChangeFormat(item: any) {
    this.showReports = false;
    this.formatTypes.map((item:any) => {
      item.isActive = false;
      return item;
    });
    item.isActive = true;
    this.format = item.name;
    this.getFormat();
  }
  getFormat(): any {
    if (this.format === 'District Wise' || this.format === 'Secretariat Wise') {
      this.noOfCol = 11;
      return this.noOfCol;
    } else if (this.format === 'Mandal Wise') {
      this.noOfCol = 13;
      return this.noOfCol;
    } else if (this.format === 'Designation Wise') {
      this.noOfCol = 9;
      return this.noOfCol;
    } 
  }
   getReportData():any {
    this.submitted = true;
    if (!this.form.valid){
      return false;
    } else { 
        this.page = 1;
        let district = this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode;
        let mandal = this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode;
        let village = this.f.village.value === 'All' ? 'All' : this.f.village.value?.secretariatCode;
        let RUFlag = this.f.RUFlag.value === 'All' ? 'All' : this.f.RUFlag.value;
       
        let postData: any = 'Flag='+ this.format +'&DistrictId=' + district + '&RuralUrban=' + RUFlag +'&MandalId=' + mandal +'&SecretariatCode=' + village ;
        this.commonService.getRequest(this.commonConstants.gswsDynamicSurveyReport + postData).subscribe({
          next: (responseData: any) => { 
            if(typeof(responseData?.result) !== 'string') {
                this.showReports = true;
                this.items = responseData?.result;
                  this.items = this.items.filter(item => item.district_name !== 'Total')
                  this.totalItems = responseData?.result.filter((item: { district_name: string; }) => item.district_name === 'Total')
            } else {
              this.items = [];
              this.totalItems = [];
              //this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
      });
      }
   }
   downloadExcel() {
    let fileName: any = 'GSWS Dynamic Survey Portal';
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100}
  ];
  
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'GSWS Dynamic Survey Portal');
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
  getSecretariatReport(item: any, modal: any) {
    
    this.popupItems = [];
    this.modalService.open(modal, { size: 'xl', centered: true, backdrop: 'static'});
        this.popupPage = 1;
        let district = item.did === null ? (this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode) : item.did;
        let mandal = item.mid === null ? (this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode) : item.mid;
        let village = item.sec_code === null ? (this.f.village.value === 'All' ? 'All' : this.f.village.value?.secretariatCode) : item.sec_code;
        let RUFlag = this.f.RUFlag.value === 'All' ? 'All' : this.f.RUFlag.value;
       
        let postData: any = 'Flag=Secretariat Link&DistrictId=' + district + '&RuralUrban=' + RUFlag +'&MandalId=' + mandal +'&SecretariatCode=' + village ;

        this.commonService.getRequest(this.commonConstants.gswsDynamicSurveyReport + postData).subscribe({
          next: (responseData: any) => { 
            if(typeof(responseData?.result) !== 'string') {
              this.popupItems = responseData?.result;
            } else {
              this.popupItems = [];
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
      });
  }
  downloadExcelForSecretariat() {
    let fileName: any = 'GSWS Dynamic Survey Portal';
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.secTable.nativeElement, {raw: true});
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100}
  ];
  
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'GSWS Dynamic Survey Portal');
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
  goToHome() {
    this.router.navigate(['/home']);
  }
}
