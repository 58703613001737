import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CommonConstants } from './../../constants/common-constants.component';
import { CommonService } from '../../shared/common.service';

import { FormGroup, FormBuilder } from '@angular/forms';
import { PdfViewerPopupComponent } from 'src/shared/pdf-viewer-popup/pdf-viewer-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-application-preview',
  templateUrl: './application-preview.component.html',
  styleUrls: ['./application-preview.component.css'],
})
export class ApplicationPreviewComponent implements OnInit {
  commonConstants: any = CommonConstants;
  certificateName: string = '';
  requisitionID: string = '';
  type: string = '';
  department: string = '';
  certificateInfo: any = [];
  fileList: any = [];
  basicInfo: any = [];
  presentAddr: any = [];
  permanentAddr: any = [];
  loggedInUserId:any;
  userInfo:any = [];
  certificateTypeName: string = '';
  form: FormGroup;
  commonFormAddedFields : any = {
    aadharNum : ''
  };
  revoke: Boolean=false;
  userName: string = '';
  @ViewChild('__landassignmenttosoldier') __landassignmenttosoldier: ElementRef | undefined;
  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal
  ) {
    this.form = this.formBuilder.group({
      vroChecklist: [],
    });
    let userData = localStorage.getItem('userInfo');
    if (userData && userData !== null) {
      this.userInfo = JSON.parse(userData);
      this.loggedInUserId = this.userInfo?.userPrincipalName;
      this.getUserName();

    }
    //get url params
    this.currentRoute.queryParams.subscribe((params) => {
      //this.requisitionID = 'RV-AgricultureIncomeCertificate-1023'; 
      this.requisitionID = params?.id;
      this.department = params?.department;
      this.revoke = (params?.from==='revokeModule')?true:false;
      this.type = params?.type;

      if(this.department === 'MAUD'){
        this.getRequisitionDataMaud(this.requisitionID,this.department);
      }else{
        this.getCertificateDetails(this.requisitionID);
      }
    });
  }

  get f() {
    return this.form.controls;
  }

  ngOnInit(): void {}

  getUserName() {
    this.commonService.getRequest(this.commonConstants.getUserIdDetails).subscribe({
      next: (responseData: any) => {  
        if(this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva') {
          this.userName = responseData.result[0]?.userName;
        }
      },
      error: (error) => {  
        console.log('client side', error);
      }
    });
  }
 

  getCertificateDetails(requisitionData: any) {
    this.commonService
      .getRequest(this.commonConstants.getCertificateDetails + this.department+ '&IncludeFileUploadInfo=true&IncludePaymentDetails=false&RequisitionId='+ requisitionData)
      .subscribe({
        next: (responseData: any) => {
          if (responseData && responseData.length !== 0 &&  typeof(responseData.result) != 'string') {
            // console.log('responseData.result',responseData.result)
            this.basicInfo = responseData.result?.requisitionHeader;
            this.certificateName =
              responseData.result?.requisitionHeader?.requisitionType;
              console.log(this.certificateName, "98");
            //  console.log('certificate type name ',responseData.result?.requisitionHeader?.requisitionType)
            this.certificateInfo = responseData.result?.requisitionData;
           // console.log('certificateInfo',responseData.result?.requisitionData  );
            this.fileList = responseData.result?.fileList;
             console.log('this.fileList',this.fileList, responseData.result?.fileList)
            this.presentAddr = this.basicInfo?.applicantInfo?.addresses.filter(
              (item: { addressType: string }) => item.addressType === 'present'
            );
            this.permanentAddr =
              this.basicInfo?.applicantInfo?.addresses.filter(
                (item: { addressType: string }) =>
                  item.addressType === 'permanent'
              );
            this.certificateTypeName = '';
            this.commonConstants.certificateList.forEach((e: any) => {
              if (this.certificateName == e.certName) {
                // console.log('e cert name ',e.certID)
                this.certificateTypeName = e.certID;
              }
            });
            console.log('this.certificateName ', this.certificateTypeName);
            // this.getTaskDetails();
            console.log(this.basicInfo?.applicantInfo?.aadhaarNumber);
            this.decryptAadharNum(this.basicInfo?.applicantInfo?.aadhaarNumber)

            this.presentAddr = this.basicInfo?.applicantInfo?.addresses.filter((item: { addressType: string; }) => (item.addressType === 'present') || (item.addressType === 'Present'));
            this.permanentAddr = this.basicInfo?.applicantInfo?.addresses.filter((item: { addressType: string; }) => (item.addressType === 'permanent') || (item.addressType === 'Permanent'));
            
            if(this.presentAddr?.length > 0) {
              this.getByEntitycode(this.presentAddr[0]?.district, 'District', 'present');
              this.getByEntitycode(this.presentAddr[0]?.mandalMunicipality, 'Mandal', 'present');
              this.getByEntitycode(this.presentAddr[0]?.villageWard, 'Secretariat', 'present');
            }
            if(this.permanentAddr?.length > 0) {
              this.getByEntitycode(this.permanentAddr[0]?.district, 'District', 'permanent');
              this.getByEntitycode(this.permanentAddr[0]?.mandalMunicipality, 'Mandal', 'permanent');
              this.getByEntitycode(this.permanentAddr[0]?.villageWard, 'Secretariat', 'permanent');
            }   
          } else {
            if(this.type == 'RegistrationofTokens'){
              this.getJSServiceData(requisitionData);
            } else {
              this.basicInfo = [];
              this.certificateInfo = [];
              this.fileList = [];
            }
          }
         console.log(responseData);
        },
        error: (error) => {
          this.basicInfo = [];
          this.certificateInfo = [];
          this.fileList = [];
          console.log('client side', error);
        },
      });
  }
  getJSServiceData(requisitionData: any) {
    this.commonService
      .getRequest(this.commonConstants.GetJsTokenMasterDataById + requisitionData)
      .subscribe({
        next: (responseData: any) => {
          if (responseData && responseData.length !== 0 &&  typeof(responseData.result) != 'string') {
            // console.log('responseData.result',responseData.result)
            this.basicInfo = responseData.result?.requisitionHeader;
            this.certificateName =
              responseData.result?.requisitionHeader?.requisitionType;
              console.log(this.certificateName);
            //  console.log('certificate type name ',responseData.result?.requisitionHeader?.requisitionType)
            this.certificateInfo = responseData.result?.requisitionData;
           // console.log('certificateInfo',responseData.result?.requisitionData  );
            this.fileList = responseData.result?.fileList;
             console.log('this.fileList',this.fileList, responseData.result?.fileList)
            this.presentAddr = this.basicInfo?.applicantInfo?.addresses.filter(
              (item: { addressType: string }) => item.addressType === 'present'
            );
            this.permanentAddr =
              this.basicInfo?.applicantInfo?.addresses.filter(
                (item: { addressType: string }) =>
                  item.addressType === 'permanent'
              );
            this.certificateTypeName = '';
            this.commonConstants.certificateList.forEach((e: any) => {
              if (this.certificateName == e.certName) {
                // console.log('e cert name ',e.certID)
                this.certificateTypeName = e.certID;
              }
            });
            console.log('this.certificateName ', this.certificateTypeName);
            // this.getTaskDetails();
            console.log(this.basicInfo?.applicantInfo?.aadhaarNumber);
            this.decryptAadharNum(this.basicInfo?.applicantInfo?.aadhaarNumber)

            this.presentAddr = this.basicInfo?.applicantInfo?.addresses.filter((item: { addressType: string; }) => (item.addressType === 'present') || (item.addressType === 'Present'));
            this.permanentAddr = this.basicInfo?.applicantInfo?.addresses.filter((item: { addressType: string; }) => (item.addressType === 'permanent') || (item.addressType === 'Permanent'));
            
            if(this.presentAddr?.length > 0) {
              this.getByEntitycode(this.presentAddr[0]?.district, 'District', 'present');
              this.getByEntitycode(this.presentAddr[0]?.mandalMunicipality, 'Mandal', 'present');
              this.getByEntitycode(this.presentAddr[0]?.villageWard, 'Secretariat', 'present');
            }
            if(this.permanentAddr?.length > 0) {
              this.getByEntitycode(this.permanentAddr[0]?.district, 'District', 'permanent');
              this.getByEntitycode(this.permanentAddr[0]?.mandalMunicipality, 'Mandal', 'permanent');
              this.getByEntitycode(this.permanentAddr[0]?.villageWard, 'Secretariat', 'permanent');
            }   
          } else {
            this.basicInfo = [];
            this.certificateInfo = [];
            this.fileList = [];
          }
         console.log(responseData);
        },
        error: (error) => {
          this.basicInfo = [];
          this.certificateInfo = [];
          this.fileList = [];
          console.log('client side', error);
        },
      });
  }
  getRequisitionDataMaud(reqId:any,reqType: any) {

    this.commonService.getRequest(this.commonConstants.getMaudRequisitionById +
      'RequisitionId=' + reqId +"&RequisitionType="+ reqType,  
      "MAUD"
      ).subscribe({
        next: (responseData: any) => {
          if (responseData && responseData.length !== 0) {
           // console.log('responseData.result',responseData.result)
            this.basicInfo = responseData.result?.requisition?.requisitionHeader;
           
          //  console.log('this.basicInfo',this.basicInfo)
           
            this.certificateName =
              responseData.result?.requisition?.requisitionHeader?.requisitionType;
            //  console.log('certificate type name ',responseData.result?.requisition?.requisitionHeader?.requisitionType)
            this.certificateInfo = responseData.result?.requisition?.requisitionData;
           // console.log('certificateInfo',responseData.result?.requisition?.requisitionData  );
            this.fileList = responseData.result?.requisition?.fileList;
             console.log('this.fileList',this.fileList, responseData.result?.requisition?.fileList)
            this.presentAddr = this.basicInfo?.applicantInfo?.addresses.filter(
              (item: { addressType: string }) => item.addressType === 'present'
            );
            this.permanentAddr =
              this.basicInfo?.applicantInfo?.addresses.filter(
                (item: { addressType: string }) =>
                  item.addressType === 'permanent'
              );
            this.certificateTypeName = '';
            this.commonConstants.certificateList.forEach((e: any) => {
              if (this.certificateName == e.certName) {
                // console.log('e cert name ',e.certID)
                this.certificateTypeName = e.certID;
              }
            });
           // console.log('this.certificateName ', this.certificateTypeName);
            // this.getTaskDetails();
          } else {
            this.basicInfo = [];
            this.certificateInfo = [];
            this.fileList = [];
          }
          //console.log(responseData);
        },
        error: (error) => {
          this.basicInfo = [];
          this.certificateInfo = [];
          this.fileList = [];
          console.log('client side', error);
        },
      });
  }



  viewFile(blobRef: any) {
    this.commonService
      .getRequest(this.commonConstants.viewFile + blobRef)
      .subscribe({
        next: (responseData: any) => {
          let fileInfo = responseData.result.fileContents;
           const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
            modalRef.componentInstance.sourceFile = fileInfo; 
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  viewImgFile(blobRef: any) {
    this.commonService
      .getRequest(this.commonConstants.viewFile + blobRef)
      .subscribe({
        next: (responseData: any) => {
          let fileInfo = responseData.result.fileContents;
           const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
           modalRef.componentInstance.isImage = true;
          modalRef.componentInstance.sourceFile = 'data:image/png;base64,' +fileInfo;
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  downloadFile(file: any) {
    this.commonService
      .getRequest(this.commonConstants.fileDownload + file.blobRef)
      .subscribe({
        next: (responseData: any) => {
          this.commonService.downloadPdfFile(
            responseData.result.fileContents,
            file.fileName
          );
        },
        error: (error) => {
          console.log('client side', error);
        },
    });
  }
  // new code 
  decryptAadharNum(aadhar : string){
    this.commonService
      .getRequest(this.commonConstants.cryptoDecryptValue + encodeURIComponent(aadhar))
      .subscribe({
        next: (responseData: any) => {
          this.commonFormAddedFields.aadharNum  = responseData.result.rawValue;
          console.log(this.commonFormAddedFields.aadharNum,"aadharnumber");
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
    }
    getByEntitycode(entityCode: any, entityType:any, addrType?: any): any {
      this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({  
      next: (responseData: any) => { 
          let name =  responseData.result[0]?.name;
          if (entityType === 'District' && addrType === "present") {
            this.commonFormAddedFields.presentDistrict = name;
          }else if (entityType === 'Mandal' && addrType === "present") {
            this.commonFormAddedFields.presentMandal = name; 
          } else if (entityType === 'Secretariat' && addrType === "present") {
            this.commonFormAddedFields.presentVillage = name;
          } else if (entityType === 'District' && addrType === "permanent") {
            this.commonFormAddedFields.permanentDistrict = name;
          }
           else  if (entityType === 'Mandal' && addrType === "permanent") {
            this.commonFormAddedFields.permanentMandal = name;
          } 
          else if (entityType === 'Secretariat' && addrType === "permanent") {
            this.commonFormAddedFields.permanentVillage = name;
          } 
          
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    }
    // new code

    public printReceipt() {
      let Pagelink = 'about:blank';
      var pwa = window.open(Pagelink, '_new');
      pwa?.document.open();
      pwa?.document.write(this.preparePrintFormat());
      pwa?.document.close();
    }
   
    preparePrintFormat() {
      const printContent: HTMLElement | null =
        document.getElementById('__printingFrame');
      let htmlData: any = '';
      let printdata = printContent?.innerHTML;
      htmlData +=
      htmlData +=
      `<html><head><title>Receipt</title>
       <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">
       <style type='text/css' media='print'>@media print {body {-webkit-print-color-adjust: exact;-moz-print-color-adjust: exact;-ms-print-color-adjust: exact;print-color-adjust: exact;}} .certificateInfo {margin:25px} .fieldHolder .labelCls {  color: #535353;  font-weight: bold;  display: inline-block;  width: 50%;  vertical-align: top;}  .fieldHolder .valueCls {  color: #535353;  display: inline-block;  width: 44%;  overflow-wrap: break-word;} @media print{@page{size: auto; margin: 0;} .imgCls {margin: 40px, background-color: red}} </style> <script>function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'>
       `  
     htmlData +=
    `<div style="background: #001659 0% 0% no-repeat;box-shadow: 0px 2px 2px #00000070; height: 80px;color: #fff;">
    <div style="height: 43px;left: 25px; top: 21px;">
    <img src="../../assets/home-images/APSevaPortal.png" alt="logo" class="img-fluid"  style="height:37px;">
    <img src="../../assets/zswologo.png" alt="logo" class="img-fluid" style="height:37px; position:absolute; right:0; margin-top: 15px; margin-right: 470px">
    <label class="labelCls" style="color: #fff; margin-left: 300px; margin-top: 15px; font-size: 20px; font-weight: bold">Department of Sainik Welfare, Andhra Pradesh</label>
    </div>
    </div>
    <br>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <h6 style="padding: 0;font-weight: 500;">
      Application Number: ${this.requisitionID}</h6>
      </div>`
      htmlData += printdata;  
      htmlData += 
      `<div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <h6 style="padding: 0;font-weight: 500;">
      Zilla Sainik Welfare Officer Name: ${this.userName ? this.userName : '-'}</h6>
      </div>
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <h6 style="padding: 0;font-weight: 500;">
      District: ${this.certificateInfo?.districtName ? this.certificateInfo?.districtName:'-'}</h6>
      </div>
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <h6 style="float:right;margin-right:15px;font-weight: 500;">
      Application Filed on: ${this.certificateInfo?.dateofApplicationreceivedtoZSWO ? this.certificateInfo?.dateofApplicationreceivedtoZSWO:'-'}</h6>
      </div>`
      htmlData += '</body></html>';
      return htmlData;
    }

    // printReceipt_with_page_reload() {
   
    //   const printContent: HTMLElement | null =
    //     document.getElementById('__landassignmenttosoldier');
  
    //   if (printContent != null) {
    //     window.document.write(
    //       '<html><head><title>PaymentReceipt</title><link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"></head><body><br>'
    //     );
    //     window.document.write(printContent.innerHTML);
    //     window.document.write('</body></html>');
    //     window.document.close();
    //     setTimeout(() => {
    //       window.focus();
    //       window.print();
    //       window.location.reload();
    //     }, 1000);
    //   }
    // }

}
