<form [formGroup]="authenticationForm">
<div class="row mb-3">
    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <label class="labelCls">Authentication Type:<span class="mandatoryCls">*</span></label>
        <div class="col-sm-9" required 
            [ngClass]="{ 'is-invalid':submitted && f.authenticationType.errors }">
            <input type="radio" name="authenticationType" value="OTP"  (change)="changeAuth()"
                formControlName="authenticationType"><span class="mx-2 radioCls">OTP(One Time
                Password)</span>
            <input type="radio" name="authenticationType" value="biometric"  (change)="changeAuth()"
                formControlName="authenticationType"><span class="mx-2 radioCls">Biometric</span>
        </div>
        <div *ngIf="submitted && f.authenticationType.errors"
            class="invalid-feedback col col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div *ngIf="f.authenticationType.errors.required">Please select either OTP / Biometric
            </div>
        </div>
    </div>
</div>
<div *ngIf="f.authenticationType.value == 'OTP'">
<div class="row mb-3" >
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Aadhaar Number: <span class="mandatoryCls">*</span></label>
        <input type="text" appBlockCopyPaste name="aadhaarOtp" formControlName="aadhaarOtp" (keyup)="aadharChange()"  class="inputCls width75" required numbersOnly
        [ngClass]="{ 'is-invalid':submitted && f.aadhaarOtp.errors }" maxlength="12" minlength="12"
        [readonly] ="isPattadarOtpValidated || isAadharFieldDisable">
        <button class="btn btn-primary float-right" [disabled]="isDisable" (click)="sendOTP()">Send OTP</button>
        <div *ngIf="submitted && f.aadhaarOtp.errors" class="invalid-feedback">
          <div *ngIf="f.aadhaarOtp.errors.required || f.aadhaarOtp.errors.whiteSpaceValidate">Aadhaar Number is required</div>
          <div *ngIf="f.aadhaarOtp.errors.aadharValidate || f.aadhaarOtp.errors.minlength || f.aadhaarOtp.errors.maxlength">Please enter 12 digit Valid Aadhaar Number</div>
        </div>
    </div>
    <div *ngIf="!isDisable" class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">OTP (One Time Password): <span class="mandatoryCls">*</span></label>
        <input type="password" name="otp"  appBlockCopyPaste class="inputCls" formControlName="otp" required [ngClass]="{ 'is-invalid':submitted && f.otp.errors }" numbersOnly minlength="6" maxlength="6"
        [readonly] ="isPattadarOtpValidated">
        <div *ngIf="submitted && f.otp.errors"
        class="invalid-feedback">
        <div *ngIf="f.otp.errors.required">Please enter 6 digit Valid OTP
        </div>
        </div>
    </div>
</div>

<div class="btnHolder">
    <button class="greenBtnCls float-right" (click)="validateOTP()" >OTP Authenticate</button>
</div>
</div>

 <div *ngIf="f.authenticationType.value == 'biometric'"> 
    <div class="row mb-3">
          <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Aadhaar Number: <span class="mandatoryCls">*</span></label>
        <input type="text" appBlockCopyPaste name="adhaarNumberBio" formControlName="adhaarNumberBio" class="inputCls " numbersOnly
        [ngClass]="{ 'is-invalid':biometricFlag && f.adhaarNumberBio.errors }" [readonly]="isAadharFieldDisable" maxlength="12" minlength="12">
        <div *ngIf="biometricFlag && f.adhaarNumberBio.errors" class="invalid-feedback">
          <div *ngIf="f.adhaarNumberBio.errors.required || f.adhaarNumberBio.errors.whiteSpaceValidate">Aadhaar Number is required</div>
          <div *ngIf="f.adhaarNumberBio.errors.aadharValidate || f.adhaarNumberBio.errors.minlength || f.adhaarNumberBio.errors.maxlength">Please enter valid Aadhaar Number</div>
      </div>
      </div>
      <div class="btnHolder mt-4">
        <button *ngIf="!showBiometricPopup" class="greenBtnCls float-right" (click)="biometricBtn()">Biometric Authenticate</button>
      </div>
    </div>
    <div class="btnHolder">
        <button *ngIf="showBiometricPopup" class="greenBtnCls float-right" (click)="checkBiometricStatus()">Check Biometric Status</button>
    </div>

</div>
</form>