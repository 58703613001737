import { Component, OnInit ,ViewChild, ElementRef } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import { Router } from '@angular/router';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateAdapter, NgbDateParserFormatter,NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import {
  CustomAdapter,
  CustomDateParserFormatter,
} from '../../shared/common.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as $ from 'jquery';
@Component({
  selector: 'app-revenue-share-report',
  templateUrl: './revenue-share-report.component.html',
  styleUrls: ['./revenue-share-report.component.css'],
  providers: [
    NgbNavConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe,
  ]
})
export class RevenueShareReportComponent implements OnInit {
  commonConstants : any = CommonConstants;
  headerCol = [{colId:'districT_NAME', colName: 'District'},
  {colId:'mandaL_NAME', colName: 'Mandal'},
  {colId:'secretariaT_NAME', colName: 'Secretariat'},
  {colId:'requisitionid', colName: 'Requisition Id'},
  {colId:'transactionamount', colName: 'Amount'},
  {colId:'transactiondate', colName: 'Requisition Date'},
  {colId:'paymenT_STATUS', colName: 'Payment Status'},
  {colId:'challanid', colName: 'Challan Id'},
  {colId:'challaN_TYPE', colName: 'Challan Type'},
  {colId:'challaN_DATE', colName: 'Challan Date'},
  ]
  form: FormGroup;
  services: any[] = [];
  formatTypes: any[]= [];
  items: any[] = [];
  totalItems: any[] = [];
  beyondSLAItems: any[] = [];
  page = 1;
  popupPage = 1;
  pageSize = 10;
  format: any;
  showReports:Boolean=false;
  submitted:Boolean=false;
  noOfCol: any = 26;
  role: any;
  defaultFromDate: any;
  currentDate = new Date();
  popupPageSize = 10;   
  districts: any[] = [];
  mandals: any[] = [];
  villages: any[]= [];
  designations: any[] = [];
  revenueDivisions:any=[];
  revenueDistricts:any=[];
  revenueMandals:any=[];
  Dates:any=[];
  date:any
  designation:any;
  years:any=[];
  @ViewChild('TABLE') table!: ElementRef;
  @ViewChild('TABLEDESIGNATION') tableDesignation!: ElementRef;
  
  @ViewChild('TABLEDATA') TableData!: ElementRef;
  
  
  constructor(private formBuilder: FormBuilder, private commonService: CommonService,private router: Router,private modalService: NgbModal,public datepipe: DatePipe) {
 
    this.form = this.formBuilder.group({
      village: [null],
      mandal: [null],
      district: [null],
      RUFlag: [null],
      month:[null],
      year:[null],
      revenuedistrict:[null],
      revenuemandal:[null],
      revenuedivision:[null],
      designation:[null]

    });
    this.format = 'Abstract';
    this.formatTypes = [{name: 'Abstract', isActive: true},
                        {name: 'District Wise', isActive: false},
                        {name: 'Division Wise', isActive: false},
                        {name:'Mandal Wise', isActive: false},
                        {name:'Secretariat Wise', isActive: false},
                        {name:'Designation Wise', isActive: false}
  ]
    let userInfo = localStorage.getItem('userInfo');
    if (userInfo && userInfo !== null) {
      let userData = JSON.parse(userInfo);
      this.role = userData.jobTitle;
    }
    this.getDistricts();
    this.getRevenueDistricts();
    this.getDesignations();
   // this.GetDate();
   let currentYear = (new Date()).getFullYear(); 
//Loop and add the Year values to DropDownList.
for (var i = 2022; i <= currentYear; i++) {
   this.years.push({year:i})
}
    
  }

  ngOnInit(): void {   
    this.defaultFromDate = this.datepipe.transform(this.currentDate, 'yyyyMM');
   
    this.format = 'Abstract';
     this.form.patchValue({
       district:'All',
       mandal:'All',
       village: 'All',
       Month:this.defaultFromDate,
       revenuedistrict: 'All',
       revenuemandal: 'All',
       revenuedivision: 'All',
       designation: 'All'
     });
     
  }

  get f() { return this.form.controls; }
  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getCSPDistricts).subscribe({
      next: (responseData: any) => {
        console.log(responseData,'getDistricts')
        if(responseData.result?.isSuccess) {
          this.districts = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'districtName');
        
        } else {
          this.districts = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  
  goToHome() {
    this.router.navigate(['/home']);
  }

  onDistrictChange() {
    this.showReports = false;
    this.mandals = [];
    this.villages = [];
    this.f.mandal.patchValue('All');
    this.f.village.patchValue('All');   
    this.commonService.getRequest(this.commonConstants.getCSPMandalsRuralUrban + '?DistrictCode=' + this.f['district'].value.districtCode ).subscribe({
      next: (responseData: any) => {
        console.log(responseData,'mandals')
        if(responseData.result?.isSuccess) {
          this.mandals = this.commonService.sortItems(responseData.result?.getMandalDetails, 'mandalName');
        } else {
          this.mandals = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  if(this.f['district'].value !== 'All'){  
  this.commonService.getRequest(this.commonConstants.GetRevenueDistrictByDistrict + '?DistrictCode=' + this.f['district'].value.districtCode).subscribe({
    next: (responseData: any) => {
      if(responseData.result?.isSuccess) {
        this.revenueDistricts = this.commonService.sortItems(responseData.result?.revenueDistrictDetails, 'revenueDistrictName');
       this.f.revenuedistrict.value.revenueDistrictCode =this.revenueDistricts[0].revenueDistrictCode;
        // this.mandals = this.commonService.sortItems(responseData.result?.getMandalDetails, 'mandalName');
      } else {
        this.mandals = [];
        this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
      }
    },
    error: (error) => {
      console.log('client side', error);
    }
});
}else{
  this.getRevenueDistricts();
}

  }

  onMandalChange() {
    this.showReports = false;
    this.villages = [];
   this.f.village.patchValue('All');
    this.commonService.getRequest(this.commonConstants.getCSPSecretariat + '?MandalCode=' + this.f.mandal.value.mandalCode).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.villages = this.commonService.sortItems(responseData.result?.getSecretariatDetails, 'secretariatName');
        } else {
          this.villages = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onVillageChange() {
    this.showReports = false;
  }
  getRevenueDistricts() {
    this.revenueDistricts = [];
    this.commonService.getRequest(this.commonConstants.getRevenueDistricts).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.revenueDistricts = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'revenueDistrictName');
         
        } else {
          this.revenueDistricts = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onRevenueDistrictChange(e:any){
    this.showReports = false;
    this.revenueDivisions = [];
    this.commonService.getRequest(this.commonConstants.getRevenueDivision  + this.f.revenuedistrict.value.revenueDistrictCode ).subscribe({
      next: (responseData: any) => {
        console.log(responseData,'getRevenueDivision')
        if(responseData.result?.isSuccess) {
          this.revenueDivisions = this.commonService.sortItems(responseData.result?.revenueDivisionDetails, 'revenueDivisionName');
        } else {
          this.revenueDivisions = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
 
if(e.target.value == 'All'){
  this.getDistricts();
}else{
  this.commonService.getRequest(this.commonConstants.GetDistrictByRevenueDistrict  + '?RevenueDistrictCode=' + this.f.revenuedistrict.value.revenueDistrictCode ).subscribe({
    next: (responseData: any) => {
      console.log(responseData,'getRevenueDivision')
      if(responseData.result?.isSuccess) {
       this.districts=responseData.result?.districtDetails;
       // this.revenueDivisions = this.commonService.sortItems(responseData.result?.revenueDivisionDetails, 'revenueDivisionName');
      } else {
        this.revenueDivisions = [];
        this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
      }
    },
    error: (error) => {
      console.log('client side', error);
    }
});
}

  }
  onRevenueDivisionChange(){
    this.showReports = false;
    this.revenueMandals = [];
    this.f.revenuemandal.patchValue('All');
    this.commonService.getRequest(this.commonConstants.getRevenueMandal  + this.f.revenuedivision.value.revenueDivision).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.revenueMandals = this.commonService.sortItems(responseData.result?.revenueMandalDetails, 'revenueMandalName');
        } else {
          this.revenueMandals = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
 
  }
  getDesignations(){
    this.designations=[];
    // this.designations=[{
    //   designationName:'Digital Assistant',
    //   code:'DA'
    // },
    // // {
    // //   designationName:'Joint Collector',
    // //   code:'JC'
    // // },
    // {
    //   designationName:'MPDO',
    //   code:'MPDO'

    // },{
    //   designationName:'District Collector',
    //   code:'DC'

    // },{
    //   designationName:'DLDO',
    //   code:'DLDO'

    // },{
    //   designationName:'Muncipal Commissioner',
    //   code:'MC'

    // },{
    //   designationName:'Mandal Revenue Officer',
    //   code:'MRO'

    // },{
    //   designationName:'PS',
    //   code:'PS'

    // }]
    this.commonService.getRequest(this.commonConstants.GetDesignationsList).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.designations = this.commonService.sortItems(responseData.result?.getDesignationsList, 'designationName');
        } else {
          this.designations = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
    

  }
  onChangeFormat(item: any) {
    this.showReports = false;
    this.formatTypes.map((item:any) => {
      item.isActive = false;
      return item;
    });
    item.isActive = true;
    this.format = item.name;
    this.getFormat();
  }
  
  getFormat(): any {
      if (this.format === 'District Wise' ) {
      this.noOfCol = 19;
      return this.noOfCol;
    } else if (this.format === 'Mandal Wise') {
      this.noOfCol = 23;
      return this.noOfCol;
    } else if(this.format === 'Abstract'){
      this.noOfCol = 26;
      return this.noOfCol;
    }
      else {
      this.noOfCol = 23;
      return this.noOfCol;
    }
  
  }
  // onRuralUrbanChange() {
  //   if(this.f['district'].value !== 'All') {
  //     this.onDistrictChange(e);
  //   }
  // }

  getRevenueShareReportData(from?: any): any {
    this.submitted = true;
    this.items=[];
    if (!this.form.valid){
      return false;
    } else { 
        this.page = 1;
        let district = this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode;
        let mandal = this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode;
        let village = this.f.village.value === 'All' ? 'All' : this.f.village.value?.secretariatCode;
        let revenuedistrict =this.f.revenuedistrict.value === 'All' ? 'All' : this.f.revenuedistrict.value?.revenueDistrictCode;
        let revenueMandal =this.f.revenuemandal.value === 'All' ? 'All' : this.f.revenuemandal.value?.revenueMandal;
        let revenueDivision =this.f.revenuedivision.value === 'All' ? 'All' : this.f.revenuedivision.value?.revenueDivision;
     
        let designtaion=this.f.designation.value ==='All' ?   'All' : this.f.designation.value?.designationCode;
     
        let monthandYear=(this.f.year.value && this.f.month.value ) ? (this.f.year.value.year+ ""+this.f.month.value.month) : this.defaultFromDate
       let Month=monthandYear;
    this.designation =designtaion;
     this.format = this.format === 'Designation Wise' ? 'Designation' : this.format;     
     let postData: any = '?Format='+ this.format +'&DistrictID=' + district + '&MandalId=' + mandal +'&SecCode=' + village +'&RevenueDistrict='+ revenuedistrict+'&RevenueMandal='+ revenueMandal+'&RevenueDivision='+ revenueDivision +'&Designation='+ designtaion+ '&Month='+Month;      
        this.commonService.getRequest(this.commonConstants.getRevenueShareReportData + postData).subscribe({
          next: (responseData: any) => { 
            if(typeof(responseData?.result) !== 'string') {
                this.showReports = true;
                this.format = this.format === 'Designation' ? 'Designation Wise' : this.format;
                if(this.format === 'Abstract' || this.format === 'Designation Wise'){
                  this.items = responseData?.result ?  responseData?.result.monthlyRevenueShareDataAbstract :[];
                }else{
                  this.items = responseData?.result ?  responseData?.result.monthlyRevenueShareDataValueWise :[];
                }
                  //this.totalItems = responseData?.result.filter((item: { totalFlag: string; }) => item.totalFlag === 'Y')
                  console.log(this.items,'items')
                  console.log( this.totalItems ,'total items')  
            } else {
              this.items = [];
              this.totalItems = [];
              //this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
      });
      }
    }
    
   
    onYearChange(){
      this.showReports = false;
      this.Dates = [];
      this.commonService.getRequest(this.commonConstants.GetMonthByYear  + this.f.year.value?.year).subscribe({
        next: (responseData: any) => {
          if(responseData.result) {
           this.Dates = responseData.result;
          } else {
            this.Dates = [];
            this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
   
    }

  downloadExcel() {
    let fileName: any = 'Revenue Share Report' //+ this.f.fromDate.value +' to '+ this.f.toDate.value;
    if(this.format=== 'Abstract'){
      const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
    
    
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100}
  ];
  
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Revenue Share Report');
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
    }else if(this.format=== 'Designation Wise' || this.format=== 'Designation'){
      const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.tableDesignation.nativeElement, {raw: true});
    
    
      let wscols = [
        {wpx:50},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100}
    ];
    
    ws['!cols'] = wscols;
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Revenue Share Report');
      
      /* save to file */
      XLSX.writeFile(wb, fileName + '.xlsx');
    }else if(this.format =='District Wise' || this.format =='Division Wise' || this.format =='Mandal Wise' ||this.format =='Secretariat Wise'){
      const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.TableData.nativeElement, {raw: true});
    
    
      let wscols = [
        {wpx:50},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100}
    ];
    
    ws['!cols'] = wscols;
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Revenue Share Report');
      
      /* save to file */
      XLSX.writeFile(wb, fileName + '.xlsx');
    
    }



  }
  
  saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
