import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/shared/common.service';
import { CommonConstants } from 'src/constants/common-constants.component';

@Component({
  selector: 'app-newricecard',
  templateUrl: './newricecard.component.html',
  styleUrls: ['./newricecard.component.css']
})
export class NewricecardviewComponent implements OnInit {
  commonConstants : any = CommonConstants;
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  extendedServiceEkyc : any;

  constructor(public commonService: CommonService) {
    this.commonService.commonAction.subscribe((data: any)=> {
      if(data) {
        this.onDataChange(data);
      }
    });
   }

  ngOnInit(): void {
    this.getEkycStatus(this.basicInfo)
  }

   onDataChange(data: any) {
    this.getEkycStatus(data);
  }

  getEkycStatus(data : any)
  {
    this.commonService
    .getRequest(this.commonConstants.cryptoDecryptValue + encodeURIComponent(data?.applicantInfo?.aadhaarNumber))
    .subscribe({
      next: (responseData: any) => {
        // console.log(responseData, 'decrypt value respose data');
       let aadharNumber  = this.commonService.RSADecrypt(responseData.result.rawValue);
       let obj =
       {
         uid_no: this.commonService.RSAEncrypt(aadharNumber),
       }
       this.commonService.postRequest(this.commonConstants.getEKYCStatus,obj).subscribe(
         {next: (responseData: any) => 
         {
           console.log(responseData.result.ekyc_status, "ekyc checking");
           this.extendedServiceEkyc = responseData.result.ekyc_status;
         }
       })
      },
      error: (error) => {
        console.log('client side', error);
      },
    });

    
  }


  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }


}
