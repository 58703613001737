import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants } from '../../constants/common-constants.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { CommonMsgModalComponent } from '../../shared/common-msg-modal/common-msg-modal.component';

import { DatePipe } from '@angular/common';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import {
  CustomAdapter,
  CustomDateParserFormatter,
} from '../../shared/common.service';

@Component({
  selector: 'app-payment-reconciliation-report',
  templateUrl: './payment-reconciliation-report.component.html',
  styleUrls: ['./payment-reconciliation-report.component.css'],
  providers: [
    NgbNavConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe,
  ],
})

export class PaymentReconciliationReportComponent implements OnInit {

  public paymentModes = [
    {title: 'ICICI Challan', value:'ICICI Challan'},
    {title: 'BILLDESK', value:'BILLDESK'}
  ];
  public AuditorRemark:string = "";
  public SLNo:string = "";
  public Action:string = "";
  modalRef: any; 
  public submitted: Boolean = false;
  public showDetails: Boolean = false;
  commonConstants: any = CommonConstants;
  TomaxDate: any;
  TominDate: any;
  FrommaxDate: any;
  FromminDate: any;
  currentDate = new Date();
  
  paymentReconciliation: FormGroup;
  items: any[] = [];
  
  constructor(
    private config: NgbNavConfig,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private modalService: NgbModal,
    private datePipe: DatePipe
  ) {
    this.TomaxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
    this.TominDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
   
    this.FrommaxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
    this.FromminDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };

    let currentDateFormat = this.currentDate.getDate()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getFullYear();
    this.paymentReconciliation = this.formBuilder.group({
      periodFrom: [currentDateFormat, [Validators.required]],
      periodTo: [currentDateFormat, [Validators.required]],
      payment_mode: ["All", [Validators.required]],
      status:  ["Open", [Validators.required]],
    }, { validator: this.checkDates.bind(this) });
    

    config.destroyOnHide = false;
    config.roles = false;

  }

  ngOnInit(): void {
    this.submit();
  }

  checkDates(group: FormGroup) {
    if((group.controls.periodFrom.errors || group.controls.periodTo.errors) && !group.controls.periodTo.errors?.invalidDate){
      return;
    }
    const periodFrom = group.controls.periodFrom.value;
    const periodTo = group.controls.periodTo.value;
    if(periodFrom && periodTo) {
      if(this.commonService.formatDate(periodFrom) == "Invalid date") {
        group.controls.periodFrom.setErrors({ invalid: true});
      } else if(this.commonService.formatDate(periodTo) == "Invalid date") {
        group.controls.periodTo.setErrors({ invalid: true});
      } else if(this.commonService.formatDate(periodFrom) > this.commonService.formatDate(periodTo)){
        group.controls.periodTo.setErrors({ invalidDate: true });
      } else {
        group.controls.periodTo.setErrors(null);
      }
    }
  }

  get f() {
    return this.paymentReconciliation.controls;
  }

  submit(): any {  
    this.showDetails = true; 
    this.submitted = true;    
    if (!this.paymentReconciliation.valid) {
      return false;
    } else {
      let searchData = {
        "fromDate": this.commonService.formatDate(this.f['periodFrom'].value),
        "toDate": this.commonService.formatDate(this.f['periodTo'].value),
        "modeOfPayment": this.f['payment_mode'].value,
        "auditorStatus": this.f['status'].value,
      };
     
      this.getDataAPI(searchData);
    }
  } 

  getDataAPI(postData:any){
    this.items = [];
    this.commonService
    .postRequest(this.commonConstants.GetUserWisePaymentReconciliationReport, postData)
    .subscribe({
      next: (responseData: any) => {
        if(responseData.result.isSuccess) {
          this.items = responseData.result.paymentReconciliationList;
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
}
