    <div class="certificateInfo">
        <div class="subHeadingCls mb-3">Applicant Details</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">UHID:</label>
                    <div class="valueCls">{{certificateInfo?.uhid ? (certificateInfo?.uhid) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Case Number:</label>
                    <div class="valueCls">{{certificateInfo?.caseId ? (certificateInfo?.caseId) : '-' }}</div>
                </div>
             </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Status:</label>
                    <div class="valueCls">{{certificateInfo?.accStatus ? (certificateInfo?.accStatus) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Patient Name:</label>
                    <div class="valueCls">{{certificateInfo?.patientName ? (certificateInfo?.patientName) : '-' }}</div>
                </div>                
             </div>
             <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Card Number:</label>
                    <div class="valueCls">{{certificateInfo?.cardNumber ? (certificateInfo?.cardNumber) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Hospital Name:</label>
                    <div class="valueCls">{{certificateInfo?.hospitalName ? (certificateInfo?.hospitalName) : '-' }}</div>
                </div>                
             </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">A/C Holder Type:</label>
                    <div class="valueCls">{{certificateInfo?.acHolderType ? (certificateInfo?.acHolderType) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">A/C Holder Name:</label>
                    <div class="valueCls">{{certificateInfo?.acHolderName ? (certificateInfo?.acHolderName) : '-' }}</div>
                </div>                           
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Father/Husband Name:</label>
                    <div class="valueCls">{{certificateInfo?.fatherHusbandName ? (certificateInfo?.fatherHusbandName) : '-' }}</div>
                </div>  
                <div class="fieldHolder">
                    <label class="labelCls">Account No:</label>
                    <div class="valueCls">{{certificateInfo?.accountNo ? (certificateInfo?.accountNo) : '-' }}</div>
                </div>                           
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Bank Name:</label>
                    <div class="valueCls">{{certificateInfo?.bankName ? (certificateInfo?.bankName) : '-' }}</div>
                </div>  
                <div class="fieldHolder">
                    <label class="labelCls">Bank Branch:</label>
                    <div class="valueCls">{{certificateInfo?.bankBranch ? (certificateInfo?.bankBranch) : '-' }}</div>
                </div>           
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">IFSC Code:</label>
                    <div class="valueCls">{{certificateInfo?.ifscCode ? (certificateInfo?.ifscCode) : '-' }}</div>
                </div>                          
            </div> 
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Remarks:</label>
                    <div class="valueCls">{{certificateInfo?.remarks ? (certificateInfo?.remarks) : '-' }}</div>
                </div>                           
            </div>        
        </div>
    </div>
    <div class="certificateInfo">
        <div class="row">
            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
                <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                    <div *ngIf="file.documentType === 'Passbook'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Passbook/Cheque book:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
                </ng-container>
            </div>
        </div>
    </div>  
        
   

    
    
    