<div class="mainPageContainer mr-3 mt-5"> 
    <div class="btnHolder mb-3">
        <button class="btn btn-primary float-right my-3"  (click)="goToHome()">Home</button>
        <button class="btn btn-primary float-right my-3 mr-3"  (click)="goToTeamComposition()">Go To Team Composition</button>
    </div>
    <h6 class="mb-3">Team Schedule</h6>
    <h6 class="mb-3 subHeadingCls">Note: Please click on submit for saving the changes (edit/delete/add).</h6>
    <form [formGroup]="teamForm">
       <div class="row mb-3">
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Team: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" required formControlName = "team" [ngClass]="{ 'is-invalid':submitted && (f.team.errors || f.team.value === 'Select')}">
                <option  [ngValue]= "null" disabled>Select</option>
                <option *ngFor="let team of teams" [ngValue]="team">{{team}}</option>
            </select>
            <div *ngIf="submitted && (f.team.errors || f.team.value === 'Select')" class="selectError">
                <div *ngIf="f.team.errors?.required || f.team.value === 'Select'">Please select team</div>
            </div>
        </div>
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Secretariat: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" *ngIf="!isUrban" required formControlName = "secretariat" [ngClass]="{ 'is-invalid':submitted && (f.secretariat.errors || f.secretariat.value === 'Select')}">
                <option  [ngValue]= "null" disabled>Select</option>
                <option *ngFor="let secretariat of secretariatList" [ngValue]="secretariat">{{secretariat.secretariaT_NAME}}</option>
            </select>
            <ng-multiselect-dropdown *ngIf="isUrban" [ngClass]="{ 'is-invalid': submitted && (f.secretariat.errors || f.secretariat.value === 'Select') }"
            [placeholder]="'Select'" (onSelect) ="onChangeSecretariat()"
              [settings]="dropdownSettings"
              [data]="secretariatList"
              formControlName="secretariat"
              (onSelectAll)="onChangeSecretariat()"
              (onDeSelect)="onChangeSecretariat()"
              (onDeSelectAll)="onChangeSecretariat()">
          </ng-multiselect-dropdown>
            <div *ngIf="submitted && (f.secretariat.errors || f.secretariat.value === 'Select')" class="selectError">
                <div *ngIf="f.secretariat.errors?.required || f.secretariat.value === 'Select'">Please select Secretariat</div>
            </div>
        </div>
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Schedule Date: <span class="mandatoryCls">*</span></label>
            <div class="input-group">
                <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                       name="d"  ngbDatepicker #d="ngbDatepicker" [minDate]="minDate" (dateSelect)="onScheduleDateSelect($event)"
                       [maxDate]="maxDate" required formControlName="sheduleDate" [markDisabled]="isDisabled"
                       [ngClass]="{ 'is-invalid':submitted && f.sheduleDate.errors }">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                </div>
              </div>
              <div *ngIf="submitted && f.sheduleDate.errors" class="selectError">
                <div *ngIf="f.sheduleDate.errors?.required || f.sheduleDate.invalid">Please enter valid Schedule Date</div>
            </div>
          </div>
          <div class="btnHolder mt-4">
            <button class="greenBtnCls"  (click)="addToSchedule()">Add To Schedule</button>
          </div>
        </div>
        <div id="teamScheduleE">
            <div class="subHeadingCls mb-3"><b>Team Schedule List</b></div>
            <div class="row mb-3 " >
                <div class="col">
                    <div class="tab table-responsive">
                        <table class="tableCls">
                            <thead>
                                <tr>
                                    <!-- <th>Select</th> -->
                                    <th>Serial No</th>
                                    <th>Team Name</th>
                                    <th class="width15">Secretariat/Cluster</th>
                                    <th>Scheduled Date</th>
                                    <th class="width15">Venue</th>
                                    <th>Status</th>
                                    <th class="width12">Reschedule Date</th>
                                    <th class="width15">Reschedule Remark</th>
                                    <th class="width15">Remarks</th>
                                    <!-- <th>Action</th> -->
                                </tr>
                            </thead>
                            <tbody *ngIf="teamList && teamList?.length > 0">
                                <tr *ngFor="let item of teamList;let i = index;">
                                    <!-- <td><input type="radio" [attr.disabled]="item.isConducted ? '' : null" value = {{item.isSelected}} name="schedule" (change)="selectSchedule(item)"></td> -->
                                    <td>{{i + 1}}</td>
                                    <td>{{item.teamName}}</td>
                                    <td class="width15">{{item.secretariatDisplay}}</td>
                                    <td>{{item.scheduledDate}}</td>
                                    <td class="width15">
                                        <div class="input-group">
                                            <textarea class="textareaMultiCls" rows="2"  [attr.disabled]="(item.isConducted || item.isVenueFreezed) ? '' : null" maxlength="500"
                                            [(ngModel)]="item.venue" [ngModelOptions]="{standalone: true}" 
                                            [ngClass]="{ 'is-invalid':finalSubmitted && (item.meetingHappenedOrReschedule == 'Started' || item.meetingHappenedOrReschedule == 'Reschedule' || item.meetingHappenedOrReschedule == 'Conducted' || item.rescheduleRemarks?.trim() )
                                            && (!item.venue?.trim())}"></textarea>
                                        </div>
                                    </td>
                                    <td>
                                        <select class="selectCls" (change)="meetingHappenedOrRescheduleChanged(item, mymodal, i, campStartmodal)" [attr.disabled]="(item.isConducted || item.isNewAdded || item.isLocalAction) ? '' : null" [(ngModel)]="item.meetingHappenedOrReschedule" [ngModelOptions]="{standalone: true}">
                                            <option  [ngValue]= "null" disabled>Select</option>
                                            <option [ngValue]="'Started'">Started</option>
                                            <option [ngValue]="'Conducted'">Conducted</option>
                                            <option [ngValue]="'Reschedule'" [attr.disabled]="(currentDate >= lastRescheduledDate) ? '' : null">Reschedule</option>
                                        </select>
                                    </td>
                                    <td class="width12">
                                        <div class="input-group " *ngIf="item.meetingHappenedOrReschedule == 'Reschedule'">
                                            <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                                                   name="d"  ngbDatepicker #d="ngbDatepicker" [minDate]="minDateReschedule"
                                                   [(ngModel)]="item.rescheduleDate" required [ngModelOptions]="{standalone: true}"
                                                   [maxDate]="maxDateReschedule"   [markDisabled]="isDisabled" readonly
                                                   [ngClass]="{ 'is-invalid':finalSubmitted && item.meetingHappenedOrReschedule == 'Reschedule' 
                                                   && !item.rescheduleDate}">
                                            <div class="input-group-append">
                                              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                            </div>
                                          </div>
                                    </td>
                                    <td class="width15">
                                        <div class="input-group" *ngIf="item.meetingHappenedOrReschedule == 'Reschedule'">
                                            <input class="inputCls" [(ngModel)]="item.newrescheduleRemarks" required [ngModelOptions]="{standalone: true}"
                                            [ngClass]="{ 'is-invalid':finalSubmitted && item.meetingHappenedOrReschedule == 'Reschedule' && !item.newrescheduleRemarks?.trim()}"
                                            type="text" maxlength="100">
                                        </div>
                                    </td>
                                    <td class="width15">{{item.rescheduleRemarks}}</td>

                                    
                                    <!-- <td><button  class="btn btn-primary mr-3 add-new" (click)="deleteSchedule(item, i)">Delete Schedule</button></td> -->
                                </tr>
                            </tbody>
                            <tbody *ngIf="teamList && teamList?.length === 0">
                                <tr><td colspan="9" class="text-center">No records found.</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="btnHolder">
            <button class="greenBtnCls float-right" (click) = "submit()">Submit</button>
            <button class="greenBtnCls float-right mr-3"  *ngIf="teamList && teamList?.length > 0" (click) = "printSchedule()">Print Schedule</button>
            <!-- <button class="greenBtnCls float-right mr-3" *ngIf="teamList && teamList?.length > 0"  (click) = "deleteSchedule()">Delete Schedule</button> -->
        </div>
    </form>
</div>
<div class="hideTable" id="teamSchedule">
    <div class="subHeadingCls mb-3"><b>Team Schedule List</b></div>
    <div class="row mb-3 " >
        <div class="col">
            <div class="tab table-responsive">
                <table class="tableCls">
                    <thead>
                        <tr>
                            <th>Serial No</th>
                            <th>Team Name</th>
                            <th>Secretariat/Cluster</th>
                            <th>Scheduled Date</th>
                            <th>Venue</th>
                            <th>Status</th>
                            <th>Remarks</th>
                            <!-- <th>Action</th> -->
                        </tr>
                    </thead>
                    <tbody *ngIf="teamListPrint && teamListPrint?.length > 0">
                        <tr *ngFor="let item of teamListPrint;let i = index;">
                            <td>{{i + 1}}</td>
                            <td>{{item.teamName}}</td>
                            <td>{{item.secretariatDisplay}}</td>
                            <td>{{item.scheduledDate}}</td>
                            <td> {{ item.venue }}</td>
                            <td> {{ item.meetingHappenedOrReschedule }}</td>                            
                            <td>{{item.rescheduleRemarks}}</td>

                            
                            <!-- <td><button  class="btn btn-primary mr-3 add-new" (click)="deleteSchedule(item, i)">Delete Schedule</button></td> -->
                        </tr>
                    </tbody>
                    <tbody *ngIf="teamListPrint && teamListPrint?.length === 0">
                        <tr><td colspan="7" class="text-center">No records found.</td></tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="hideTable" id="teamMemberDetails">
    <div class="subHeadingCls mb-3"><b>Team  List</b></div>
    <div class="row mb-3" *ngIf="buildTeamDetails.length > 0">
        <div class="col" *ngFor="let team of buildTeamDetails">
            <h6 class="mb-3"><b>{{team.teamName}}</b></h6>
            <div class="tab table-responsive">
                <table class="tableCls">
                    <thead>
                        <tr>
                            <th>Serial No</th>
                            <th>Aadhaar No</th>
                            <th>Name</th>
                            <th>Designation</th>
                            <th>Mobile No</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="team.teamMemberData && team?.teamMemberData.length > 0">
                        <tr *ngFor="let item of team.teamMemberData;let i = index;">
                            <td>{{i + 1}}</td>
                            <td>{{this.getAadharNo(item.memberEncryptedAadhar)}}</td>
                            <td>{{item.memberName}}</td>
                            <td>{{item.memberDesignation}}</td>
                            <td>{{item.memberMobile}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="team.teamMemberData && team.teamMemberData?.length === 0">
                        <tr><td colspan="8" class="text-center">No records found.</td></tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mb-3" *ngIf="buildTeamDetails.length === 0">
        <div class="col">Team List is not available.</div>
    </div>
</div>



<ng-template #mymodal let-modal class="modalCls">

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Camp Completion Details</h4>
        <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button> -->
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="closePopup()">
            <span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="scheduleConductedForm">
            <div class="row mb-3">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label class="labelCls">No. of citizens participated in the Camp: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="noOfCitizensParticipated" formControlName="noOfCitizensParticipated" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isSubmitted && f2.noOfCitizensParticipated.errors }" numbersOnly maxlength="10">
                    <div *ngIf="isSubmitted && f2.noOfCitizensParticipated.errors" class="invalid-feedback">
                        <div *ngIf="f2.noOfCitizensParticipated.errors.required">Please enter No. of citizens participated in the Camp</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label class="labelCls">No. of Tokens generated on the Camp day: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="noOfTokenGenerated" formControlName="noOfTokenGenerated" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isSubmitted && f2.noOfTokenGenerated.errors }" numbersOnly maxlength="10">
                    <div *ngIf="isSubmitted && f2.noOfTokenGenerated.errors" class="invalid-feedback">
                        <div *ngIf="f2.noOfTokenGenerated.errors.required">Please enter No. of Tokens generated on the Camp day</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label class="labelCls">No. of services requests raised on the Camp Day: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="noOfServicesRegistered" formControlName="noOfServicesRegistered" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isSubmitted && f2.noOfServicesRegistered.errors }" numbersOnly maxlength="10">
                    <div *ngIf="isSubmitted && f2.noOfServicesRegistered.errors" class="invalid-feedback">
                        <div *ngIf="f2.noOfServicesRegistered.errors.required">Please enter No. of services requests raised on the Camp Day</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label class="labelCls">No. of grievances raised on the Camp Day: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="noOfGrievancesRaised" formControlName="noOfGrievancesRaised" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isSubmitted && f2.noOfGrievancesRaised.errors }" numbersOnly maxlength="10">
                    <div *ngIf="isSubmitted && f2.noOfGrievancesRaised.errors" class="invalid-feedback">
                        <div *ngIf="f2.noOfGrievancesRaised.errors.required">Please enter No. of grievances raised on the Camp Day</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label class="labelCls">No. of certificates issued on the Camp Day: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="noOfCertificatesIssued" formControlName="noOfCertificatesIssued" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isSubmitted && f2.noOfCertificatesIssued.errors }" numbersOnly maxlength="10">
                    <div *ngIf="isSubmitted && f2.noOfCertificatesIssued.errors" class="invalid-feedback">
                        <div *ngIf="f2.noOfCertificatesIssued.errors.required">Please enter No. of certificates issued on the Camp Day</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label class="labelCls">No. of total CCRC cards issued during 7 days camp period: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="noOfCCRCCardIssued" formControlName="noOfCCRCCardIssued" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isSubmitted && f2.noOfCCRCCardIssued.errors }" numbersOnly maxlength="10">
                    <div *ngIf="isSubmitted && f2.noOfCCRCCardIssued.errors" class="invalid-feedback">
                        <div *ngIf="f2.noOfCCRCCardIssued.errors.required">Please enter No. of total CCRC cards issued during 7 days camp period</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label class="labelCls">No. of total Mobile number updation in Aadhaar card services during 7 days camp period: <span class="mandatoryCls">*</span></label>
                    <input type="text" name="noOfMobileUpdated" formControlName="noOfMobileUpdated" class="inputCls" appBlockCopyPaste required
                    [ngClass]="{ 'is-invalid':isSubmitted && f2.noOfMobileUpdated.errors }" numbersOnly maxlength="10">
                    <div *ngIf="isSubmitted && f2.noOfMobileUpdated.errors" class="invalid-feedback">
                        <div *ngIf="f2.noOfMobileUpdated.errors.required">Please enter No. of total Mobile number updation in Aadhaar card services during 7 days camp period</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="checkBoxCont">
                    <label class="labelCls" for="applicationForm">Camp photo<span class="mandatoryCls">*</span></label>
                    <div class="mb-3 labelCls"><b>Note: </b>All Upload Documents are in jpg/jpeg/png Format Only. Maximum Upload Size Per File is 1MB.</div>

                    </div>
                    <div class="input-group mb-3">
                        <div class="custom-file">
                        <input type="file" class="custom-file-input" formControlName="campPhoto" id="campPhoto" accept=".jpg, .jpeg, .png" required
                        [ngClass]="{ 'is-invalid':isSubmitted && f2.campPhoto.errors }" (change)="oncampPhotoFileSelected($event)">
                        <label class="custom-file-label" for="campPhoto"><i class="fas fa-search"></i> {{campPhotoLabelValue}}</label>
                        </div>
                        <div class="clearCls" (click)="clearFileUpload('campPhoto', 'campPhotoLabel')" *ngIf="f2.campPhoto.value"></div>
                    </div>
                    <div *ngIf="isSubmitted && f2.campPhoto.errors" class="selectError">
                        <div *ngIf="f2.campPhoto.errors.required">Please upload camp photo</div>
                        <div *ngIf="f2.campPhoto.errors?.inValidExt && !f2.campPhoto.errors.required ">Please upload the file in jpg/jpeg/png Format</div>
                        <div *ngIf="f2.campPhoto.errors?.inValidSize && !f2.campPhoto.errors.required && !f2.campPhoto.errors.inValidExt">File size exceeding 1MB</div> 
                    </div>
                </div>
            </div>
            
            <div class="btnHolder mt-4">
                <button class="greenBtnCls"  (click)="markAsConducted()">Mark as Conducted</button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #campStartmodal let-modal class="modalCls">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Camp Start Details</h4>
        <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button> -->
        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="closeStartPopup()">
            <span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="scheduleStartedForm">            
            <div class="row mb-3">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="checkBoxCont">
                        <label class="labelCls" for="applicationForm">Camp photo<span class="mandatoryCls">*</span></label>
                        <div class="mb-3 labelCls"><b>Note: </b>All Upload Documents are in jpg/jpeg/png Format Only. Maximum Upload Size Per File is 1MB.</div>
                    </div>
                    <div class="input-group mb-3">
                        <div class="custom-file">
                        <input type="file" class="custom-file-input" formControlName="campStartedPhoto" id="campStartedPhoto" accept=".jpg, .jpeg, .png" required
                        [ngClass]="{ 'is-invalid':isStartSubmitted && f3.campStartedPhoto.errors }" (change)="oncampStartedPhotoFileSelected($event)">
                        <label class="custom-file-label" for="campStartedPhoto"><i class="fas fa-search"></i> {{campStartedPhotoLabelValue}}</label>
                        </div>
                        <div class="clearCls" (click)="clearStartFileUpload('campStartedPhoto', 'campStartedPhotoLabel')" *ngIf="f3.campStartedPhoto.value"></div>
                    </div>
                    <div *ngIf="isStartSubmitted && f3.campStartedPhoto.errors" class="selectError">
                        <div *ngIf="f3.campStartedPhoto.errors.required">Please upload camp photo</div>
                        <div *ngIf="f3.campStartedPhoto.errors?.inValidExt && !f3.campStartedPhoto.errors.required ">Please upload the file in jpg/jpeg/png Format</div>
                        <div *ngIf="f3.campStartedPhoto.errors?.inValidSize && !f3.campStartedPhoto.errors.required && !f3.campStartedPhoto.errors.inValidExt">File size exceeding 1MB</div> 
                    </div>
                </div>
            </div>            
            <div class="btnHolder mt-4">
                <button class="greenBtnCls"  (click)="markAsStarted()">Mark as Started</button>
            </div>
        </form>
    </div>
</ng-template>


