import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-mutation-transaction-resurvey-view',
  templateUrl: './mutation-transaction-resurvey-view.component.html',
  styleUrls: ['./mutation-transaction-resurvey-view.component.css']
})
export class MutationTransactionResurveyViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  @Output() viewImg = new EventEmitter();
  rorSellerItems: any = [];
  mutationDetails: any = [];
  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
    this.rorSellerItems  = JSON.parse(this.certificateInfo?.sellerRORDetails);
    this.mutationDetails = JSON.parse(this.certificateInfo?.mutationByFMCDetails);
  }
  viewFile(file: any) {
    this.view.emit(file);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
  viewImgFile(file: any) {
    this.viewImg.emit(file);
  }
  getExecutantType(): any {
    let mutationCatg: any = this.certificateInfo.mutationCategory;
    let successionCatg: any = this.certificateInfo.successionSubCatg;
    if(mutationCatg === 'Sale' || mutationCatg === 'Exchange' || mutationCatg === 'Partition Deed Outside Family' || (mutationCatg === 'Succession' && successionCatg ==='Partition within Family')) {
        return 'Executant';
    } else if(mutationCatg === 'Gift') {
        return 'Donor';
    } else if(mutationCatg === 'Release') {
      return 'Releaser';
    } else if(mutationCatg === 'Succession' && successionCatg ==='Registered Will') {
      return 'Testator';
    } else if(mutationCatg === 'Succession' && successionCatg ==='FMC (Single Legal Heir)') {
      return 'Deceased Pattadar';
    }
  }
  getClaimantType(): any {
    let mutationCatg: any = this.certificateInfo.mutationCategory;
    let successionCatg: any = this.certificateInfo.successionSubCatg;
    if(mutationCatg === 'Sale' || mutationCatg === 'Exchange' || mutationCatg === 'Partition Deed Outside Family' || (mutationCatg === 'Succession' && successionCatg ==='Partition within Family')) {
        return 'Claimant';
    } else if(mutationCatg === 'Gift') {
        return 'Donee';
    } else if(mutationCatg === 'Release') {
      return 'Releasee';
    } else if(mutationCatg === 'Succession' && successionCatg ==='Registered Will') {
      return 'Testatee';
    } else if(mutationCatg === 'Succession' && successionCatg ==='FMC (Single Legal Heir)') {
      return 'Proposed Pattadar';
    }
  }
}
