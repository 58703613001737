import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-radio-msg-modal-component',
  templateUrl: './radio-msg-modal-component.component.html',
  styleUrls: ['./radio-msg-modal-component.component.css']
})
export class RadioMsgModalComponentComponent implements OnInit {

  @Input() reqId: string = '';
  @Input() title: string = '';
  @Input() message: string = '';
  constructor(public activeModal: NgbActiveModal, config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
  }

}
