import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { CommonService} from '../../../../shared/common.service';
import { CommonConstants } from '../../../../constants/common-constants.component';

@Component({
  selector: 'app-view-memberdeletion',
  templateUrl: './view-memberdeletion.component.html',
  styleUrls: ['./view-memberdeletion.component.css']
})
export class ViewMemberdeletionComponent implements OnInit {
  members: any[]=[];
  gridDetail:any[]=[];

  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  commonConstants: any = CommonConstants;
  

  constructor(   public commonService: CommonService,) {
    // this.members = [{
    //   aadharNumber:6788789909878,
    //   deletionReason:"Death",
    //   deathCertificateNo:789789,
    //   deathCertificate:"Upload.jpg",
    // }]
    this.commonService.commonAction.subscribe((data: any)=> {
      if(data) {
        this.onDataChange(data);
      }
    });
   }

  ngOnInit(): void {
    /* this.gridDetail = [{
      riceCardNumber:2820839514,
      name:"Charu Sai kranth	",
      relation:	"SON",
      ekycStatus:"N" 
    },
    {
      riceCardNumber:2820839514,
      name:"Charu Sai kranth",
      relation:	"SON",
      ekycStatus:"Y" 
    },
  ]  
   */

      console.log(this.fileList,"this.certificateInfo============")

     this.members = this.certificateInfo?.riceCardMembersDataList;
     //this.gridDetail = this.certificateInfo?.riceCardMembersDataList;

     this.getDetails(this.certificateInfo?.riceCardNumber)
  }
  

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

  onDataChange(data: any) {
    this.getDetails(data?.riceCardNumber);
  }

  getDetails(riceCardNumber:any) {

       let obj = {
        rice_card_no: riceCardNumber,
      };
      this.commonService
        .postRequest(this.commonConstants.getRiceCardMemberDetails, obj)
        .subscribe({
          next: (responseData: any) => {
            console.log(responseData.result, 'riceCardDetails');
            this.gridDetail = responseData.result.rice_card_mem_details;

            console.log(this.gridDetail.length)
            if(this.gridDetail.length > 0){
              // this.showDetails = true;
            }else{
             
            }
          },
          error: (error) => {
            console.log(error);
          },
        });
    }
   
}
