import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-rectification-resurvey-view',
  templateUrl: './rectification-resurvey-view.component.html',
  styleUrls: ['./rectification-resurvey-view.component.css']
})
export class RectificationResurveyViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  items: any[] = [];
  commonConstants: any = CommonConstants;

  constructor() { }

  ngOnInit(): void {
    
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    console.log(file);
    this.download.emit(file);
  }

}
