import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-titledeed-cum-passbook-view',
  templateUrl: './titledeed-cum-passbook-view.component.html',
  styleUrls: ['./titledeed-cum-passbook-view.component.css']
})
export class TitledeedCumPassbookViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  @Output() viewImg = new EventEmitter();
  members: any[]=[];

  constructor() { }

  ngOnInit(): void {
    // console.log(this.certificateInfo);
    this.members = this.certificateInfo?.applicationDetails;
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
  viewImgFile(file: any) {
    this.viewImg.emit(file);
  }

}
