import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-no-property-certificate-view',
  templateUrl: './no-property-certificate-view.component.html',
  styleUrls: ['./no-property-certificate-view.component.css']
})
export class NoPropertyCertificateViewComponent implements OnInit {

  commonConstants : any = CommonConstants;
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  screenMandal: any;
  screenDistrict: any;
  screenVillage: any;
  deceasedMandal: any;
  deceasedDistrict: any;
  deceasedVillage: any;

  constructor(private commonService: CommonService) {
  }

  ngOnInit(): void {
  //   this.getByEntitycode(this.certificateInfo.deathPlaceDetails?.district , 'District','deceasedDistrict');
  // this.getByEntitycode(this.certificateInfo.deathPlaceDetails?.mandalMunicipality, 'Mandal','deceasedMandal');
  // this.getByEntitycode(this.certificateInfo.deathPlaceDetails?.villageWard, 'Secretariat','deceasedVillage');
  // this.getByEntitycode(this.certificateInfo.incidenceAddress?.district , 'District','inicidenceDistrict');
  // this.getByEntitycode(this.certificateInfo.incidenceAddress?.mandalMunicipality, 'Mandal','inicidenceMandal');
  // this.getByEntitycode(this.certificateInfo.incidenceAddress?.villageWard , 'Secretariat','inicidenceVillage');
  }

  getByEntitycode(entityCode: any, entityType:any, addrType?: any): any {
    this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({  
    next: (responseData: any) => { 
        let name =  responseData.result[0]?.name;
        if (entityType === 'District' && addrType === 'inicidenceDistrict') {
          console.log(name,'inicidenceDistrict')
          this.screenDistrict = name;
        }else if (entityType === 'Mandal' && addrType === 'inicidenceMandal') {
          this.screenMandal = name; 
          console.log(name,'inicidenceMandal')
        } else if (entityType === 'Secretariat' && addrType === 'inicidenceVillage') {
          this.screenVillage = name;
          console.log(name,'inicidenceVillage')
        }else if (entityType === 'District' && addrType === 'deceasedDistrict') {
          this.deceasedDistrict = name;
          console.log(name,'deceasedDistrict')
        }else if (entityType === 'Mandal' && addrType === 'deceasedMandal') {
          this.deceasedMandal = name; 
          console.log(name,'deceasedMandal')
        } else if (entityType === 'Secretariat' && addrType === 'deceasedVillage') {
          this.deceasedVillage = name;
          console.log(name,'deceasedVillage')
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

}
