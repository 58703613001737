<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
      <div class="breadcrumbCls">
        GSWS<span class="activePage">/ Volunteer Appreciation Program Details </span>
      </div>
    </div>
    <div class="mainPageContainer mr-3">
      <div class="btnHolder mb-3 col-xs-12 col-lg-12 col-md-12 col-sm-12">
        <button class="greenBtnCls pull-right mb-3 mr-3" (click)="goToHome()">Home</button>
    </div>
    <ng-container *ngIf="!isDataExist">
      <form [formGroup]="form">
        <div class="row mb-3">
            <div class="col-lg-4">
              <label class="labelCls"> Program Conducted Date:  <span class="mandatoryCls">*</span></label>
            </div>
            <div class="col-lg-4">
                <div class="input-group">
                <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                        name="d2"  ngbDatepicker #d="ngbDatepicker" [minDate]="minDate" 
                        [maxDate]="maxDate" required formControlName="programConductedDate"  required
                        [ngClass]="{ 'is-invalid':submitted && f.programConductedDate?.errors }" >
                <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                </div>
                </div>
                <div *ngIf="submitted && f.programConductedDate?.errors" class="selectError">
                    <div *ngIf="f.programConductedDate?.errors?.required || f.programConductedDate?.invalid">Please select valid Program Conducted Date</div>
                    <div *ngIf="f.programConductedDate?.errors?.minDate || f.programConductedDate?.maxDate">Please select Program Conducted Date between 16/02/2024 to 22/02/2024</div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-4">
              <label class="labelCls">Total number of Volunteers working in Mandal / ULB: <span class="mandatoryCls"> *</span></label>
            </div>
            <div class="col-lg-4">
              <input type="text" name="totalVolunteers" numbersOnly maxlength ="10" min="1"
              appBlockCopyPaste class="inputCls" formControlName = "totalVolunteers"  required [ngClass]="{ 'is-invalid':submitted && f.totalVolunteers?.errors }">
              <div *ngIf="submitted && f.totalVolunteers.errors" class="invalid-feedback">
                  <div *ngIf="f.totalVolunteers.errors?.required">Please enter Total number of Volunteers working in Mandal / ULB</div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-4">
              <label class="labelCls">Number of Volunteers attended the program: <span class="mandatoryCls"> *</span></label>
            </div>
            <div class="col-lg-4">
              <input type="text" name="attendedVolunteers" numbersOnly maxlength ="10"
              appBlockCopyPaste class="inputCls" formControlName = "attendedVolunteers"  required [ngClass]="{ 'is-invalid':submitted && f.attendedVolunteers?.errors }">
              <div *ngIf="submitted && f.attendedVolunteers.errors" class="invalid-feedback">
                  <div *ngIf="f.attendedVolunteers.errors?.required">Please enter Number of Volunteers attended the program</div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-4">
              <label class="labelCls">Number of Secretariat Staff attended the program: <span class="mandatoryCls"> *</span></label>
            </div>
            <div class="col-lg-4">
              <input type="text" name="attendedSecretariatStaff" numbersOnly maxlength ="10"
              appBlockCopyPaste class="inputCls" formControlName = "attendedSecretariatStaff"  required [ngClass]="{ 'is-invalid':submitted && f.attendedSecretariatStaff?.errors }">
              <div *ngIf="submitted && f.attendedSecretariatStaff.errors" class="invalid-feedback">
                  <div *ngIf="f.attendedSecretariatStaff.errors?.required">Please enter Number of Secretariat Staff attended the program</div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-4">
            <label>Local MLA /In-Charge attended: <span class="mandatoryCls"> *</span></label>
          </div>
            <div class="col-lg-6" [ngClass]="{ 'is-invalid':submitted && f.inchargeMLAAttended?.errors }">
            <input type="radio"  value="Yes" name="inchargeMLAAttended" formControlName="inchargeMLAAttended">
            <span class="mx-2 radioCls">Yes</span>
            <input type="radio"  value="No" name="inchargeMLAAttended" formControlName="inchargeMLAAttended">
            <span class="mx-2 radioCls">No</span>
            <div *ngIf="submitted && f.inchargeMLAAttended.errors" class="selectError">
                <div *ngIf="f.inchargeMLAAttended.errors?.required">Please select if Local MLA /In-Charge attended</div>
            </div>
        </div>
        </div>
        <div class="row mb-3">
            <div class="col-lg-4">
                <label class="labelCls">Name of the VIP / Chief Guest participated: <span class="mandatoryCls">*</span></label>
            </div>
            <div class="col-lg-4 fieldHolder">
                <input type="text" name="nameOfVIPGuest"   maxlength ="50" 
                  appBlockCopyPaste class="inputCls" formControlName = "nameOfVIPGuest" required [ngClass]="{ 'is-invalid':submitted && f.nameOfVIPGuest.errors}">
                  <div *ngIf="submitted && f.nameOfVIPGuest.errors" class="invalid-feedback">
                    <div *ngIf="f.nameOfVIPGuest.errors?.required || f.nameOfVIPGuest.errors?.whiteSpaceValidate">Please enter Name of the VIP / Chief Guest participated</div>
                  </div>
            </div> 
        </div>
        <div class="row mb-3">
            <div class="col-lg-4">
                <label class="labelCls">Designation of the VIP / Chief Guest participated: <span class="mandatoryCls">*</span></label>
            </div>
            <div class="col-lg-4 fieldHolder">
                <input type="text" name="designationOfVIPGuest" maxlength ="50" 
                  appBlockCopyPaste class="inputCls" formControlName = "designationOfVIPGuest" required [ngClass]="{ 'is-invalid':submitted && f.designationOfVIPGuest.errors}">
                  <div *ngIf="submitted && f.designationOfVIPGuest.errors" class="invalid-feedback">
                    <div *ngIf="f.designationOfVIPGuest.errors?.required || f.designationOfVIPGuest.errors?.whiteSpaceValidate">Please enter Designation of the VIP / Chief Guest participated</div>
                  </div>
            </div> 
        </div>
        <div>
            <h6 class="mb-3"><strong>Note: All Upload Documents are in jpg/jpeg/png Format Only. Maximum Upload Size Per File is 1MB. </strong></h6>
       
            <div class="row mb-3 mt-3">
                <div class="col-lg-4">
                <div class="checkBoxCont">
                    <label class="labelCls">Program Photo 1:<span class="mandatoryCls">*</span></label>
                </div>
            </div>
            <div class="col-lg-4 fieldHolder"> 
                <div class="input-group">
                    <div class="custom-file">
                    <input type="file" class="custom-file-input" formControlName="programPhoto1" id="programPhoto1" accept=".jpg, .jpeg, .png" required
                    [ngClass]="{ 'is-invalid':submitted && (f.programPhoto1.errors )}"(change)="onPhotoSelected($event, 'programPhoto1', 'programPhoto1Label')">
                    <label class="custom-file-label" #programPhoto1Label for="programPhoto1"><i class="fas fa-search"></i>No File choosen </label>
                    </div>
                    <div class="clearCls" (click)="clearFileUpload('programPhoto1', 'programPhoto1Label')" *ngIf="f.programPhoto1.value"></div>
                </div>
                <div *ngIf="f.programPhoto1.errors && submitted" class="selectError">
                    <div *ngIf="f.programPhoto1.errors?.required">Please upload Program photo</div>
                    <div *ngIf="f.programPhoto1.errors?.inValidExt && !f.programPhoto1.errors.required ">Please upload the file in jpg/jpeg/png Format</div>
                    <div *ngIf="f.programPhoto1.errors?.inValidSize && !f.programPhoto1.errors.required && !f.programPhoto1.errors.inValidExt">File size exceeding 1MB</div> 
                </div>
            </div>
  
            </div> 
            <div class="row mb-3 mt-3">
                <div class="col-lg-4">
                <div class="checkBoxCont">
                    <label class="labelCls">Program Photo 2:<span class="mandatoryCls">*</span></label>
                </div>
            </div>
            <div class="col-lg-4 fieldHolder"> 
                <div class="input-group">
                    <div class="custom-file">
                    <input type="file" class="custom-file-input" formControlName="programPhoto2" id="programPhoto2" accept=".jpg, .jpeg, .png" required
                    [ngClass]="{ 'is-invalid':submitted && (f.programPhoto2.errors )}"(change)="onPhotoSelected($event, 'programPhoto2', 'programPhoto2Label')">
                    <label class="custom-file-label" #programPhoto2Label for="programPhoto2"><i class="fas fa-search"></i>No File choosen </label>
                    </div>
                    <div class="clearCls" (click)="clearFileUpload('programPhoto2', 'programPhoto2Label')" *ngIf="f.programPhoto2.value"></div>
                </div>
                <div *ngIf="f.programPhoto2.errors && submitted" class="selectError">
                    <div *ngIf="f.programPhoto2.errors?.required">Please upload Program photo</div>
                    <div *ngIf="f.programPhoto2.errors?.inValidExt && !f.programPhoto2.errors.required ">Please upload the file in jpg/jpeg/png Format</div>
                    <div *ngIf="f.programPhoto2.errors?.inValidSize && !f.programPhoto2.errors.required && !f.programPhoto2.errors.inValidExt">File size exceeding 1MB</div> 
                </div>
            </div>
  
            </div>
            <div class="row mb-3 mt-3">
                <div class="col-lg-4">
                <div class="checkBoxCont">
                    <label class="labelCls">Program Photo 3:<span class="mandatoryCls">*</span></label>
                </div>
            </div>
            <div class="col-lg-4 fieldHolder"> 
                <div class="input-group">
                    <div class="custom-file">
                    <input type="file" class="custom-file-input" formControlName="programPhoto3" id="programPhoto3" accept=".jpg, .jpeg, .png" required
                    [ngClass]="{ 'is-invalid':submitted && (f.programPhoto3.errors )}"(change)="onPhotoSelected($event, 'programPhoto3', 'programPhoto3Label')">
                    <label class="custom-file-label" #programPhoto3Label for="programPhoto3"><i class="fas fa-search"></i>No File choosen </label>
                    </div>
                    <div class="clearCls" (click)="clearFileUpload('programPhoto3', 'programPhoto3Label')" *ngIf="f.programPhoto3.value"></div>
                </div>
                <div *ngIf="f.programPhoto3.errors && submitted" class="selectError">
                    <div *ngIf="f.programPhoto3.errors?.required">Please upload Program photo</div>
                    <div *ngIf="f.programPhoto3.errors?.inValidExt && !f.programPhoto3.errors.required ">Please upload the file in jpg/jpeg/png Format</div>
                    <div *ngIf="f.programPhoto3.errors?.inValidSize && !f.programPhoto3.errors.required && !f.programPhoto3.errors.inValidExt">File size exceeding 1MB</div> 
                </div>
            </div>
  
            </div>
        </div>
        <div class="btnHolder">
            <button class="greenBtnCls float-right mr-3 my-3" (click) = "submit()">Submit</button>
        </div>
      </form>
    </ng-container>
      <ng-container *ngIf="isDataExist">
      <div class="subHeadingCls mb-3"><b>Volunteer Appreciation Program Details</b></div>
      <div class="row mb-3 " >
          <div class="col">
              <div class="tab table-responsive">
                  <table class="tableCls">
                      <thead>
                          <tr>
                              <th>Serial No</th>
                              <th>Program Conducted Date</th>
                              <th>Total number of Volunteers working in Mandal / ULB</th>
                              <th>Number of Volunteers attended the program</th>
                              <th>Number of Secretariat Staff attended the program</th>
                              <th>Local MLA /In-Charge attended</th>
                              <th>Name of the VIP / Chief Guest participated</th>
                              <th>Designation of the VIP / Chief Guest participated </th>
                              <th>Photo 1</th>
                              <th>Photo 2</th>
                              <th>Photo 3</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let item of programDetails;let i = index;">
                              <td>{{i + 1}}</td>
                              <td>{{item.programDate | date: 'dd/MM/yyyy'}}</td>
                              <td>{{item.volunteerWorkingNo}}</td>
                              <td>{{item.volunteerAttendedNo}}</td>
                              <td>{{item.secStaffAttendedNo}}</td>
                              <td>{{item.isMLAInchargeAttnd}}</td>
                              <td>{{item.vipName}}</td>
                              <td>{{item.vipDesignation}}</td>
                              <td><a class = "linkCls" (click)= "viewImgFile(item.photo1)">View Photo</a></td>
                              <td><a class = "linkCls" (click)= "viewImgFile(item.photo2)">View Photo</a></td>
                              <td><a class = "linkCls" (click)= "viewImgFile(item.photo3)">View Photo</a></td>
                          </tr>
                      </tbody>
                      <!-- <tbody *ngIf="programDetails && programDetails?.length === 0">
                          <tr><td colspan="11" class="text-center">No records found.</td></tr>
                      </tbody> -->
                  </table>
              </div>
          </div>
      </div>
      </ng-container>
    </div>
  </div>
