<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
            GSWS / Application Submission / Revenue Department / <span class="activePage">Challan Payment</span>
        </div>
    </div>
    <div class="mainPageContainer mr-3">
        <h6 class="mb-3">Challan Payment Issue Tracker</h6>
        <form [formGroup]="paymentReconciliationForm">
            <div>
                <hr class="hrCls mt-4">
                <ng-container> 
                    <div class="row mb-3">
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="labelCls">Challan Number:<span class="mandatoryCls">*</span></label>
                            <input type="text" name="challan_number" class="inputCls width75" formControlName="challan_number" required
                            [ngClass]="{ 'is-invalid':submitted && f.challan_number.errors }" maxlength="25">
                            <button class="btn btn-primary float-right" (mousedown)="getChallanInfo()">Pre-Fill</button>
                            <div *ngIf="submitted && f.challan_number.errors" class="selectError">
                                <div *ngIf="f.challan_number.errors?.required || f.challan_number.errors?.whiteSpaceValidate"> Please enter Challan Number.</div>
                                <div *ngIf="f.challan_number.errors?.pattern">Please enter valid Challan Number.</div>
                            </div>        
                        </div>                    
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="labelCls">Mode Of Payment:<span class="mandatoryCls">*</span></label>
                            <select class="selectCls" formControlName = "payment_mode" 
                            [ngClass]="{ 'is-invalid':submitted && f.payment_mode.errors }" required appBlockCopyPaste>
                                <option  [ngValue]= "null" disabled>Select</option>
                                <option *ngFor="let paymentMode of paymentModes" [ngValue]="paymentMode.value">{{paymentMode.title}}
                            </select> 
                            <div *ngIf="submitted && f.payment_mode.errors" class="selectError">
                                <div *ngIf="f.payment_mode.errors">Please select Mode Of Payment.</div>
                            </div> 
                        </div>                
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="labelCls">No Of Transactions:<span class="mandatoryCls">*</span></label>
                            <input type="text" name="no_of_transactions" class="inputCls" formControlName="no_of_transactions" numbersOnly
                            [ngClass]="{ 'is-invalid':submitted && f.no_of_transactions.errors }" required maxlength="5">
                            <div *ngIf="submitted && f.no_of_transactions.errors" class="selectError">
                                <div *ngIf="f.no_of_transactions.errors?.required">Please enter No Of Transactions.</div>
                            </div>        
                        </div>
                   
                                            
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="labelCls">Challan Date:<span class="mandatoryCls">*</span></label>
                            <div class="input-group">
                                <input class="calendarCls form-control" placeholder="dd/mm/yyyy" name="d" ngbDatepicker
                                    [minDate]="ChallanMinDate" [maxDate]="ChallanMaxDate" #d="ngbDatepicker" required
                                    formControlName="challan_date"
                                    [ngClass]="{ 'is-invalid': submitted && f.challan_date.errors }" />
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                        type="button"></button>
                                </div>  
                            </div>
                            <div *ngIf="submitted && (f.challan_date.errors || f.challan_date.invalid)" class="selectError">
                                Please enter valid Challan Date.
                            </div> 
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="labelCls">Challan Amount:<span class="mandatoryCls">*</span></label>
                            <input type="text" name="challan_amount" class="inputCls" formControlName="challan_amount"
                            [ngClass]="{ 'is-invalid':submitted && f.challan_amount.errors }" required maxlength="15">
                            <div *ngIf="submitted && f.challan_amount.errors" class="selectError">
                                <div *ngIf="f.challan_amount.errors?.required">Please enter Challan Amount.</div>
                                <div *ngIf="f.challan_amount.errors?.pattern">Please enter valid Challan Amount.</div>
                            </div>        
                        </div>                    
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="labelCls">Date of Payment GSWS<span class="mandatoryCls">*</span></label>
                            <div class="input-group">
                                <input class="calendarCls form-control" placeholder="dd/mm/yyyy" name="e" ngbDatepicker
                                    [minDate]="ChallanMinDate" [maxDate]="ChallanMaxDate" #e="ngbDatepicker" required
                                    formControlName="payment_gsws_date"
                                    [ngClass]="{ 'is-invalid': submitted && f.payment_gsws_date.errors }" />
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="e.toggle()"
                                        type="button"></button>
                                </div> 
                            </div>                                       
                            <div *ngIf="submitted && (f.payment_gsws_date.errors || f.payment_gsws_date.invalid)" class="selectError">
                                <div *ngIf="(f.payment_gsws_date.errors?.required || f.payment_gsws_date.invalid) && !f.payment_gsws_date.errors?.invalidGSWSDate">
                                    Please enter valid Date of Payment GSWS.
                                </div>                                
                                <div *ngIf="f.payment_gsws_date.errors?.invalidGSWSDate">
                                    Date of Payment GSWS should be greater than Challan Date.
                                </div>
                                
                            </div> 
                        </div>
                               
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="labelCls">Amount Transferred to GSWS:<span class="mandatoryCls">*</span></label>
                            <input type="text" name="transferred_gsws_amount" class="inputCls" formControlName="transferred_gsws_amount"
                            [ngClass]="{ 'is-invalid':submitted && f.transferred_gsws_amount.errors }" required maxlength="15">
                            <div *ngIf="submitted && f.transferred_gsws_amount.errors" class="selectError">
                                <div *ngIf="f.transferred_gsws_amount.errors?.required">Please enter Amount Transferred to GSWS.</div>
                                <div *ngIf="f.transferred_gsws_amount.errors?.pattern">Please enter valid Amount Transferred to GSWS.</div>
                            </div>        
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="labelCls">Amount Transferred to Bank:<span class="mandatoryCls">*</span></label>
                            <select class="selectCls" formControlName = "transferred_bank_amount" 
                            [ngClass]="{ 'is-invalid':submitted && f.transferred_bank_amount.errors }" required appBlockCopyPaste>
                                <option  [ngValue]= "null" disabled>Select</option>
                                <option *ngFor="let bankTransfer of bankTransfers" [ngValue]="bankTransfer.value">{{bankTransfer.title}}
                            </select> 
                            <div *ngIf="submitted && f.transferred_bank_amount.errors" class="selectError">
                                <div *ngIf="f.transferred_bank_amount.errors">Please select Amount Transferred to Bank.</div>
                            </div>         
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="labelCls">Bank Transaction / Reference id:<span class="mandatoryCls">*</span></label>
                            <input type="text" name="bank_transaction_id" class="inputCls" formControlName="bank_transaction_id"
                            [ngClass]="{ 'is-invalid':submitted && f.bank_transaction_id.errors }" required maxlength="25">
                            <div *ngIf="submitted && f.bank_transaction_id.errors " class="selectError">
                                <div *ngIf="f.bank_transaction_id.errors?.required || f.bank_transaction_id.errors?.whiteSpaceValidate">Please enter Bank Transaction/Reference id</div>
                                <div *ngIf="f.bank_transaction_id.errors?.pattern">Please enter valid Bank Transaction/Reference id.</div>
                            </div>        
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="labelCls"> Challan System Status:<span class="mandatoryCls">*</span></label>
                            <input type="text" name="challan_system_status" class="inputCls" formControlName="challan_system_status">
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="labelCls"> Challan Status:<span class="mandatoryCls">*</span></label>
                            <select class="selectCls" formControlName = "challan_status" 
                            [ngClass]="{ 'is-invalid':submitted && f.challan_status.errors }" required appBlockCopyPaste>
                                <option  [ngValue]= "null" disabled>Select</option>
                                <option *ngFor="let status of challanStatus" [ngValue]="status.value">{{status.title}}
                            </select> 
                            <div *ngIf="submitted && f.challan_status.errors" class="selectError">
                                <div *ngIf="f.challan_status.errors">Please select  Challan status.</div>
                            </div>         
                        </div>                   
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="labelCls"> Amount refunded:<span class="mandatoryCls">*</span></label>
                            <select class="selectCls" formControlName = "ammount_refunded" 
                            [ngClass]="{ 'is-invalid':submitted && f.ammount_refunded.errors }" required appBlockCopyPaste>
                                <option  [ngValue]= "null" disabled>Select</option>
                                <option *ngFor="let amountRefund of amountRefunded" [ngValue]="amountRefund.value">{{amountRefund.title}}
                            </select> 
                            <div *ngIf="submitted && f.ammount_refunded.errors" class="selectError">
                                <div *ngIf="f.ammount_refunded.errors">Please select Amount refunded.</div>
                            </div>         
                        </div>                        
                    </div>
                    <div class="row mt-3">
                        <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label class="labelCls">Remarks:<span class="mandatoryCls">*</span></label>
                            <textarea type="text" rows="5" name="remarks" class="textareaMultiCls" formControlName="remarks"
                            [ngClass]="{ 'is-invalid':submitted && f.remarks.errors }" required maxlength="500"></textarea>
                            <div *ngIf="submitted && f.remarks.errors" class="selectError">
                                <div *ngIf="f.remarks.errors?.required || f.remarks.errors?.whiteSpaceValidate">Please enter Bank Remarks</div>
                            </div>        
                        </div>
                    </div>
                    <hr class="hrCls mt-4">
                    <div class="btnHolder">
                        <button class="greenBtnCls float-right ml-3" (click)="submitAccountDetails()">Submit</button>
                        <button class="greenBtnCls float-right ml-3" (click)="resetForm()">Reset</button>
                    </div>
                </ng-container>
            </div>
        </form>
    </div>
</div>