import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonConstants } from './../../constants/common-constants.component';
import { CommonService } from '../../shared/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-transaction-status',
  templateUrl: './transaction-status.component.html',
  styleUrls: ['./transaction-status.component.css'],
})
export class TransactionStatusComponent implements OnInit {
  currentDate = new Date();
  commonConstants: any = CommonConstants;
  aadharNum="";
  pipeLineString: any;
  dateTime: any;
  requisitionInfo: any;
  requisitionID: any;
  transactionID: any;
  serviceName: any;
  applicantName: any;
  applicantFatherName: any;
  landConversionData: any;
  totalAmount: any;
  SLA: any;
  mandal: any;
  district: any;
  transactionStatus :any = "Pending";
  paymentMode: any ="UPI";
  userInfo: any = [];
  serviceList: any = [];
  departmentCode :any = "RV";
  departmentName :any = "Revenue";
  showPrintCertificate :Boolean = false;
  reIssuanceData: any;
  secretariatName: any;
  amountInwords: any;
  is_requestsent:Boolean=false; 

  categoryAServices: any = [
    {
    "serviceName": "Title Deed Cum PPB only",
    "serviceCode": "TitleDeedCumPassbook"
    },
    {
    "serviceName": "Patta Sub Division for Auto Mutation",
    "serviceCode": "PattaSubdivisionAutoMutation"
    },
    {
    "serviceName": "ROR 1B",
    "serviceCode": "ROR1BCertificate"
    },
    {
    "serviceName": "Computerized Adangal",
    "serviceCode": "ComputerizedAdangalCertificate"
    },
    {
    "serviceName": "Pattadar mobile number seeding in Land Records ",
    "serviceCode": "PattadarMobileNoSeed"
    },
    {
    "serviceName": "Reissuance of integrated/OBC/EBC Certificate",
    "serviceCode": "ReIssuanceCertificate"
    }
   ];
  
   certificateTypeName: string ="";
  additonSurvey: any=[];
  rectification: any=[];
  landConversion: any=[];

  constructor(
    private currentRoute: ActivatedRoute,
    private commonService: CommonService,
    private modalService: NgbModal,
    // public datepipe: DatePipe
  ) {
    
    this.getServiceListData();
    let userData = localStorage.getItem('userInfo');
    if(userData && userData !== null) {
      this.userInfo = JSON.parse(userData);
      let officeLocation = this.userInfo.officeLocation?.split('-');
      if (officeLocation?.length > 0) {
        this.secretariatName = officeLocation[1];
      }
    }

    let reIssuanceDetails = localStorage.getItem('reIssuanceData');
    if(reIssuanceDetails && reIssuanceDetails !== null) {
      this.reIssuanceData = JSON.parse(reIssuanceDetails);
    }

    //get url params
    this.currentRoute.queryParams.subscribe((params) => {
      this.requisitionID = params?.id;
      this.transactionID = "T"+params?.id;
      this.departmentCode = params?.department;// params?.id.split('-')[0];
      if(this.departmentCode === "CS"){
        this.departmentName = "Civil Supplies";
      }
      if(this.departmentCode === "RD"){
        this.departmentName = "Pensions";
      }
      if(this.departmentCode === "MAUD"){
        this.departmentName = "MAUD";
      }
      console.log(params?.department)
    });
   
    //let postData = "?RequisitionId="+this.requisitionID+"&DepartmentCode="+this.departmentCode+"&TransactionId="+this.transactionID;
    //let postData = "?RequisitionId=RV-BirthDeathCertificate-24&DepartmentCode=RV&TransactionId=RV-BirthDeathCertificate-24";
    let postData = {
      RequisitionId: this.requisitionID,
      DepartmentCode: this.departmentCode,
      TransactionId: this.transactionID
    } 
    this.commonService
        .postRequest(this.commonConstants.receiptPageData, postData,true)
        .subscribe({
          next: (responseData: any) => {
            
          this.requisitionInfo = responseData?.result;
          this.dateTime = this.requisitionInfo.transactionDate;
          this.serviceName = this.requisitionInfo.serviceCode;
           this.applicantName =  this.titleCaseWord(this.requisitionInfo.applicantName);
          this.applicantFatherName = this.titleCaseWord(this.requisitionInfo.applicantFatherHusbandName) ;
          this.district = this.requisitionInfo.district ;
          this.mandal = this.requisitionInfo.mandal ;
          this.totalAmount = this.requisitionInfo.transactionAmount ;
          this.paymentMode = this.requisitionInfo.transactionMode ;
          
          this.amountInwords = this.intToEnglishWordConverter(this.totalAmount);
          this.getByEntitycode(this.district, 'District');
          this.getByEntitycode(this.mandal, 'Mandal');

          this.certificateTypeName = ""
          this.commonConstants.certificateList.forEach((e: any) => {
            if(this.serviceName == e.certName)
            {
               this.certificateTypeName =e.certID
            }
          });
         
          //get service name
          for(let i=0; i < this.serviceList.length;i++){
            if(this.serviceList[i].serviceCode === this.serviceName){
              this.serviceName = this.serviceList[i].serviceName;
            }
          }
     
          //get SLA for those which having workflows
           this.getSLAData(this.requisitionInfo.serviceCode);
           this.getStatusOfService(this.requisitionInfo.serviceCode);
          
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
   }
    
  ngOnInit(): void {}

  getServiceListData(){
    this.serviceList = [];
    this.commonService
    .getRequest(this.commonConstants.getServiceList )
    .subscribe({
      next: (responseData: any) => {
         this.serviceList = responseData.result;
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  getStatusOfService(reqID:any) {
    this.commonService.getRequest(this.commonConstants.getWorkItemHistory + reqID + '&WorkItemReference=' + this.requisitionID.trim()).subscribe({
      next: (responseData:any) => { 

        let historyItems = responseData?.result?.items;
        if(historyItems?.length > 0 && historyItems[historyItems?.length - 1].actionTaken === 3) {
          this.transactionStatus = "Approved";
        } else {

          if(historyItems?.length > 0){
            this.transactionStatus = "Pending with "+responseData.result.items[(responseData.result.items.length - 1)].workflowStepCode;
          }
          
          if(historyItems?.length === 0){
            this.transactionStatus = "Pending with "+responseData.result.items[0].workflowStepCode;
          }
       
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
   }

  getByEntitycode(entityCode: any, entityType:any, addrType?: any): any {
    this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({  
    next: (responseData: any) => { 
        let name =  responseData.result[0]?.name;
        name = this.titleCaseWord(name) ;
        if (entityType === 'District' ) {``
          this.district = name;
        }else if (entityType === 'Mandal') {
          this.mandal = name; 
        } 
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
   
  getSLAData(ServiceCode:any){

    this.commonService
    .getRequest(this.commonConstants.getSLAData + "?ServiceCode="+ServiceCode)
    .subscribe({
      next: (responseData: any) => {
        if(responseData?.result != ""){
          if(responseData?.result === "No applicable workflow found"){
            if(ServiceCode === 'UpdateAarogyaSriCard') {
              this.SLA = "24 Hours" ;
            } else {
              this.SLA = "15 Minutes" ;
            }
          }else{
            this.SLA = responseData.result+" Days";
          }
        }
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  titleCaseWord(word: string) {
    if (!word) return word;
     word = word.toLowerCase();
    var splitStr = word.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
  }

  public intToEnglishWordConverter(number: number): any {
    var NS = [
      { value: 10000000, str: 'Crore' },
      { value: 100000, str: 'Lakh' },
      { value: 1000, str: 'Thousand' },
      { value: 100, str: 'Hundred' },
      { value: 90, str: 'Ninety' },
      { value: 80, str: 'Eighty' },
      { value: 70, str: 'Seventy' },
      { value: 60, str: 'Sixty' },
      { value: 50, str: 'Fifty' },
      { value: 40, str: 'Forty' },
      { value: 30, str: 'Thirty' },
      { value: 20, str: 'Twenty' },
      { value: 19, str: 'Nineteen' },
      { value: 18, str: 'Eighteen' },
      { value: 17, str: 'Seventeen' },
      { value: 16, str: 'Sixteen' },
      { value: 15, str: 'Fifteen' },
      { value: 14, str: 'Fourteen' },
      { value: 13, str: 'Thirteen' },
      { value: 12, str: 'Twelve' },
      { value: 11, str: 'Eleven' },
      { value: 10, str: 'Ten' },
      { value: 9, str: 'Nine' },
      { value: 8, str: 'Eight' },
      { value: 7, str: 'Seven' },
      { value: 6, str: 'Six' },
      { value: 5, str: 'Five' },
      { value: 4, str: 'Four' },
      { value: 3, str: 'Three' },
      { value: 2, str: 'Two' },
      { value: 1, str: 'One' },
    ];

    var result = '';
    for (var n of NS) {
      if (number >= n.value) {
        if (number <= 99) {
          result += n.str;
          number -= n.value;
          if (number > 0) result += ' ';
        } else {
          var t = Math.floor(number / n.value);
          // console.log(t);
          var d = number % n.value;
          if (d > 0) {
            return (
              this.intToEnglishWordConverter(t) +
              ' ' +
              n.str +
              ' ' +
              this.intToEnglishWordConverter(d)
            );
          } else {
            return this.intToEnglishWordConverter(t) + ' ' + n.str;
          }
        }
      }
    }
    return result;
  }

}
