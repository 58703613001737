import { Pipe, PipeTransform, SecurityContext } from "@angular/core";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Pipe({
    name: 'highlightSearch',
  })
  export class HighlightSearchPipe implements PipeTransform {
    transform(value: any, args: any): any {
      if (!args) {
        return value;
      }
  
      const regex = new RegExp(args, 'gi');
      const match = value.match(regex);
  
      if (!match) {
        return value;
      }
  
      return value.replace(regex, `<span class='highlight'>${match[0]}</span>`);
    }
  }

  @Pipe({
    name: 'searchFilter'
  })
  export class SearchPipe implements PipeTransform {
  
    transform(value: any, args?: any): any {
      if (!args) {
        return value;
      }
      return value.filter((val: any) => {
        let rVal = (val.name.toLowerCase().includes(args.trim().toLowerCase()));
        return rVal;
      })
  
    }
}


@Pipe({
  name: 'byPassSecurity'
})
export class ByPassSecurityPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform (value: string): SafeHtml {
      return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
