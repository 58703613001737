<div class="container-fluid mainCls">
  <div class="breadcrumbContainer mr-3">
    <div class="breadcrumbCls">
      GSWS / Application Submission
      <span class="activePage">/ VBSY daily IEC material data entry</span>
    </div>
  </div>
  <div class="mainPageContainer mr-3">
    <h6 class="mb-3">VBSY daily IEC material data entry</h6>
    
  <div class="btnHolder">
    <button  class="greenBtnCls float-right my-3" (click)="goToDayNodalReport()">VBSY Day Nodal Officer IEC Distribution Report</button>
    <button  class="greenBtnCls float-right my-3 mr-3" (click)="goToDistrictBalanceStationaryReport()">VBSY district level stationary balance report</button>
</div>
    <form [formGroup]="vbsyInventoryForm">
      <div class="mb-3">
        <div class="col col-xs-12">
            <label class="labelCls">Date: {{ currentDate }}</label>
        </div>
        <div class="col col-xs-12">
            <div class="tab table-responsive">
                <table class="tableCls">
                    <thead>
                        <tr>
                            <th>Counts</th>
                            <th>Calendars</th>
                            <th>Booklets</th>
                            <th>Brochures</th>
                            <th>No. of TShirts</th>
                            <th>No. of Caps</th>
                            <th>No. of Badges</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Opening stock</td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="openingStockCalendars" formControlName="openingStockCalendars"
                                        class="inputCls" [ngClass]="{'is-invalid':(submitted && f.openingStockCalendars.errors)}" numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.openingStockCalendars.errors?.required)" class="selectError">
                                    <div>Opening stock calendars data is required</div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="openingStockBooklets" formControlName="openingStockBooklets"
                                       class="inputCls" [ngClass]="{'is-invalid':(submitted && f.openingStockBooklets.errors)}" numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.openingStockBooklets.errors?.required)" class="selectError">
                                    <div>Opening stock booklets data is required</div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="openingStockBrochures" formControlName="openingStockBrochures"
                                        class="inputCls" [ngClass]="{'is-invalid':(submitted && f.openingStockBrochures.errors)}" numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.openingStockBrochures.errors?.required)" class="selectError">
                                    <div>Opening stock brochures data is required</div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="openingStockTShirts" formControlName="openingStockTShirts"
                                        class="inputCls" [ngClass]="{'is-invalid':(submitted && f.openingStockTShirts.errors)}" numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.openingStockTShirts.errors?.required)" class="selectError">
                                    <div>Opening stock tshirts data is required</div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="openingStockCaps" formControlName="openingStockCaps"
                                        class="inputCls" [ngClass]="{'is-invalid':(submitted && f.openingStockCaps.errors)}" numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.openingStockCaps.errors?.required)" class="selectError">
                                    <div>Opening stock caps data is required</div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="openingStockBadges" formControlName="openingStockBadges"
                                        class="inputCls" [ngClass]="{'is-invalid':(submitted && f.openingStockBadges.errors)}" numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.openingStockBadges.errors?.required)" class="selectError">
                                    <div>Opening stock badges data is required</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Received today</td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="receivedTodayCalendars" formControlName="receivedTodayCalendars"
                                        class="inputCls" [ngClass]="{'is-invalid':(submitted && f.receivedTodayCalendars.errors)}" numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.receivedTodayCalendars.errors?.required)" class="selectError">
                                    <div>Received today calendars data is required</div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="receivedTodayBooklets" formControlName="receivedTodayBooklets"
                                        class="inputCls" [ngClass]="{'is-invalid':(submitted && f.receivedTodayBooklets.errors)}" numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.receivedTodayBooklets.errors?.required)" class="selectError">
                                    <div>Received today booklets data is required</div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="receivedTodayBrochures" formControlName="receivedTodayBrochures"
                                        class="inputCls" [ngClass]="{'is-invalid':(submitted && f.receivedTodayBrochures.errors)}" numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.receivedTodayBrochures.errors?.required)" class="selectError">
                                    <div>Received today brochures data is required</div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="receivedTodayTShirts" formControlName="receivedTodayTShirts"
                                        class="inputCls" [ngClass]="{'is-invalid':(submitted && f.receivedTodayTShirts.errors)}" numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.receivedTodayTShirts.errors?.required)" class="selectError">
                                    <div>Received today tshirts data is required</div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="receivedTodayCaps" formControlName="receivedTodayCaps"
                                        class="inputCls" [ngClass]="{'is-invalid':(submitted && f.receivedTodayCaps.errors)}" numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.receivedTodayCaps.errors?.required)" class="selectError">
                                    <div>Received today caps data is required</div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="receivedTodayBadges" formControlName="receivedTodayBadges"
                                        class="inputCls" [ngClass]="{'is-invalid':(submitted && f.receivedTodayBadges.errors)}" numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.receivedTodayBadges.errors?.required)" class="selectError">
                                    <div>Received today badges data is required</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Distributed today</td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="distributedTodayCalendars" formControlName="distributedTodayCalendars"
                                        class="inputCls" [ngClass]="{'is-invalid':(submitted && f.distributedTodayCalendars.errors)}" numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.distributedTodayCalendars.errors?.required)" class="selectError">
                                    <div>Distributed today calendars data is required</div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="distributedTodayBooklets" formControlName="distributedTodayBooklets"
                                        class="inputCls" [ngClass]="{'is-invalid':(submitted && f.distributedTodayBooklets.errors)}" numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.distributedTodayBooklets.errors?.required)" class="selectError">
                                    <div>Distributed today booklets data is required</div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="distributedTodayBrochures" formControlName="distributedTodayBrochures"
                                        class="inputCls" [ngClass]="{'is-invalid':(submitted && f.distributedTodayBrochures.errors)}"  numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.distributedTodayBrochures.errors?.required)" class="selectError">
                                    <div>Distributed today brochures data is required</div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="distributedTodayTShirts" formControlName="distributedTodayTShirts"
                                        class="inputCls" [ngClass]="{'is-invalid':(submitted && f.distributedTodayTShirts.errors)}"  numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.distributedTodayTShirts.errors?.required)" class="selectError">
                                    <div>Distributed today tshirts data is required</div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="distributedTodayCaps" formControlName="distributedTodayCaps"
                                        class="inputCls" [ngClass]="{'is-invalid':(submitted && f.distributedTodayCaps.errors)}"  numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.distributedTodayCaps.errors?.required)" class="selectError">
                                    <div>Distributed today caps data is required</div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">
                                    <input type="text" name="distributedTodayBadges" formControlName="distributedTodayBadges"
                                        class="inputCls" [ngClass]="{'is-invalid':(submitted && f.distributedTodayBadges.errors)}"  numbersOnly maxlength="6"/>
                                </div>
                                <div *ngIf="(submitted && f.distributedTodayBadges.errors?.required)" class="selectError">
                                    <div>Distributed today badges data is required</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Closing stock</td>
                            <td>
                                <div class="inputCls" [ngClass]="{'is-invalid':(submitted && (+f.openingStockCalendars.value + +f.receivedTodayCalendars.value - +f.distributedTodayCalendars.value) < 0)}">
                                    {{ +f.openingStockCalendars.value + +f.receivedTodayCalendars.value - +f.distributedTodayCalendars.value }}
                                </div>
                                <div *ngIf="(submitted && +f.openingStockCalendars.value + +f.receivedTodayCalendars.value - +f.distributedTodayCalendars.value < 0)" class="selectError">
                                    <div>Closing stock calendars value should not be negative</div>
                                </div>
                            </td>
                            <td>
                                <div class="inputCls" [ngClass]="{'is-invalid':(submitted && (+f.openingStockBooklets.value + +f.receivedTodayBooklets.value - +f.distributedTodayBooklets.value) < 0)}">
                                    {{ +f.openingStockBooklets.value + +f.receivedTodayBooklets.value - +f.distributedTodayBooklets.value }}
                                </div>
                                <div *ngIf="(submitted && +f.openingStockBooklets.value + +f.receivedTodayBooklets.value - +f.distributedTodayBooklets.value < 0)" class="selectError">
                                    <div>Closing stock booklets value should not be negative</div>
                                </div>
                            </td>
                            <td>
                                <div class="inputCls" [ngClass]="{'is-invalid':(submitted && (+f.openingStockBrochures.value + +f.receivedTodayBrochures.value - +f.distributedTodayBrochures.value) < 0)}">
                                    {{ +f.openingStockBrochures.value + +f.receivedTodayBrochures.value - +f.distributedTodayBrochures.value }}
                                </div>
                                <div *ngIf="(submitted && +f.openingStockBrochures.value + +f.receivedTodayBrochures.value - +f.distributedTodayBrochures.value < 0)" class="selectError">
                                    <div>Closing stock brochures value should not be negative</div>
                                </div>
                            </td>
                            <td>
                                <div class="inputCls" [ngClass]="{'is-invalid':(submitted && (+f.openingStockTShirts.value + +f.receivedTodayTShirts.value - +f.distributedTodayTShirts.value) < 0)}">
                                    {{ +f.openingStockTShirts.value + +f.receivedTodayTShirts.value - +f.distributedTodayTShirts.value }}
                                </div>
                                <div *ngIf="(submitted && +f.openingStockTShirts.value + +f.receivedTodayTShirts.value - +f.distributedTodayTShirts.value < 0)" class="selectError">
                                    <div>Closing stock tshirts value should not be negative</div>
                                </div>
                            </td>
                            <td>
                                <div class="inputCls" [ngClass]="{'is-invalid':(submitted && (+f.openingStockCaps.value + +f.receivedTodayCaps.value - +f.distributedTodayCaps.value) < 0)}">
                                    {{ +f.openingStockCaps.value + +f.receivedTodayCaps.value - +f.distributedTodayCaps.value }}
                                </div>
                                <div *ngIf="(submitted && +f.openingStockCaps.value + +f.receivedTodayCaps.value - +f.distributedTodayCaps.value < 0)" class="selectError">
                                    <div>Closing stock caps value should not be negative</div>
                                </div>
                            </td>
                            <td>
                                <div class="inputCls" [ngClass]="{'is-invalid':(submitted && (+f.openingStockBadges.value + +f.receivedTodayBadges.value - +f.distributedTodayBadges.value) < 0)}">
                                    {{ +f.openingStockBadges.value + +f.receivedTodayBadges.value - +f.distributedTodayBadges.value }}
                                </div>
                                <div *ngIf="(submitted && +f.openingStockBadges.value + +f.receivedTodayBadges.value - +f.distributedTodayBadges.value < 0)" class="selectError">
                                    <div>Closing stock badges value should not be negative</div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </div>
      <div class="btnHolder">
        <button class="greenBtnCls float-right" (click)="submit()">Submit</button>
    </div>
    </form>
  </div>
</div>
