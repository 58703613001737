<div class="certificateInfo" *ngIf="certificateInfo">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Select Head Of Family: </label>
                <div class="valueCls">{{certificateInfo.headOfFamily}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Service Type: </label>
                <div class="valueCls">{{certificateInfo.serviceType}}</div>
            </div>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col">
            <div class="tab table-responsive">
                <table class="tableCls">
                    <tr>
                        <th>Name of the Citizen</th>
                        <th>eKYC Status</th>
                        <!-- <th>Aadhar Number</th> -->
                        <th>Gender</th>
                        <th>Status</th>
                        <th>Marital Status</th>
                        <th>Date Of Birth</th>
                        <th>Caste</th>
                        <!-- <th>Mobile Number</th> -->
                        <th>Relationship</th>
                        <th>Split Type</th>
                        <th>Desired Household</th>
                    </tr>
                    <tr *ngFor="let item of certificateInfo?.existingHouseHoldList">
                        <td>{{item.citizenName}}</td>
                        <td>{{item.eKyc}}</td>
                        <td>{{item.gender}}</td>
                        <td>{{item.status}}</td>
                        <td>{{item.maritalStatus}}</td>
                        <td>{{item.dateOfBirth | date:'dd/MM/YYYY'}}</td>
                         <td>{{item.caste}}</td>
                        <td>{{item.relationship}}</td>
                        <td>{{item.splitType}}</td>
                        <td>{{item.desiredHouseHold}}</td>
                    </tr>
            </table>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="subHeadingCls mb-3">Household 1 Member Details</div>
            <div class="tab table-responsive">
                <table class="tableCls">
                    <tr>
                        <th>Name of the Citizen</th>
                        <th>Relationship</th>
                    </tr>
                    <tr *ngFor="let item of certificateInfo?.newHouseHoldList1">
                        <td>{{item.name}}</td>
                        <td>{{item.relation}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="subHeadingCls mb-3">Household 2 Member Details</div>
            <div class="tab table-responsive">
                <table class="tableCls">
                    <tr>
                        <th>Name of the Citizen</th>
                        <th>Relationship</th>
                    </tr>
                    <tr *ngFor="let item of certificateInfo?.newHouseHoldList2">
                        <td>{{item.name}}</td>
                        <td>{{item.relation}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="row mb-3" *ngIf="certificateInfo?.isMigration && certificateInfo.serviceType === 'Divorce Split'">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Migrate to Existing Household: </label>
                <div class="valueCls">{{certificateInfo.isMigration ? 'Yes' : 'No'}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"></div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            
            <div class="subHeadingCls mb-3">Destination Household  Member Details</div>
            <div class="tab table-responsive">
                <table class="tableCls"> 
                    <tr>
                        <th>Name of the Citizen</th>
                        <!-- <th>eKYC Status</th> -->
                        <th>Gender</th>
                        <th>Status</th>
                        <!-- <th>Date Of Birth</th> -->
                    </tr>
                    <tr *ngFor="let item of certificateInfo?.migrationHouseholdList">
                        <td>{{item.name}}</td>
                        <!-- <td>{{item.ekycstatus}}</td> -->
                        <td>{{item.gender}}</td>
                        <td>{{item.status}}</td>
                        <!-- <td>{{item.DOB_DT | date: 'dd/MM/yyyy'}}</td> -->
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo mt-2 mb-3">
    <div class="row">
      <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <ng-container *ngIf="fileList && fileList?.length !== 0">
          <div class="subHeadingCls mb-3">List of Documents</div>
          <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
            <ng-container *ngIf="certificateInfo.serviceType === 'Marriage Split' || certificateInfo.serviceType === 'Single Old Age Person'">
            <div *ngIf="file.documentType?.indexOf('1') > -1">
              <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">{{certificateInfo.docTypeHH1}} of household 1</label>
              <div class="valueCls">
                <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                  class="download ml-2"></span>
              </div>
            </div>
            <div *ngIf="file.documentType?.indexOf('2') > -1">
                <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">{{certificateInfo.docTypeHH2}} of household 2</label>
                <div class="valueCls">
                  <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                    class="download ml-2"></span>
                </div>
              </div>
          </ng-container>
          <ng-container *ngIf="certificateInfo.serviceType === 'Divorce Split'">
            <div>
              <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Divorce Decree</label>
              <div class="valueCls">
                <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                  class="download ml-2"></span>
              </div>
            </div>
          </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>