<div class="reportContainer">
    <div class="row mb-3"> </div>
 <div class="header">
     <h3> Schedule table for welfare schemes </h3>
 </div>
 <div class="modal-body">
 <div [formGroup]="form">
    <div class="row mb-3">
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="district" (change)="onDistrictChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
           <option value="All">All</option>
          <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
        </select>
      </div>
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Mandal/Municipality:  <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
      </select>
    </div>
    </div>
</div>
</div>
<div class="mb-3 mt-4">
    <div class="btnHolder text-center col-xs-12">
      <button
        class="btn btn-primary mx-auto d-block"
        (click)="GetJASWelfareScheduleDetails()"
      >
       Get Details
      </button>
    </div>
  </div>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3"  (click)="goToHome()">Home</button>
            <button  type="submit" class="greenBtnCls float-right mr-3 my-3" name="submit" (click)="printScheduleDetails()">Print</button>
        </div>
<div class="table-responsive tableFixHead" *ngIf="showReports">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th [attr.colspan]="noOfCol">Schedule table for welfare schemes</th>
            </tr>
            <tr>
               <th rowspan="3">S.No</th>
               <th rowspan="3" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districtName'">District<mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandalName'">Mandal<mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'ruralOrUrban'">RURAL/URBAN<mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariatName'">Secretariat<mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariatCode'">Secretariat Code<mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'scheduleDate'">Scheduled Date <mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>
        </thead>

        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                <td>{{item.districtName}}</td>
                <td>{{item.mandalName}}</td>
                <td>{{item.ruralOrUrban}}</td>
               <td>{{item.secretariatName}}</td>
               <td class="text-right">{{item.secretariatCode}}</td>
               <td class="text-right">{{item.scheduleDate | date: 'dd/MM/yyyy'}}</td>
              <tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td>{{totalItems[0].districtName}}</td>
                <td>{{totalItems[0].mandalName}}</td>
                <td>{{totalItems[0].ruralOrUrban}}</td>
                <td>{{totalItems[0].secretariatName}}</td>
                <td class="text-right">{{totalItems[0].secretariatCode}}</td>
                <td class="text-right">{{totalItems[0].scheduleDate | date: 'dd/MM/yyyy' }}</td>
                </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    <div class="mt-4 btnHide">
        <div class="float-left" *ngIf="items.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
</div>
</div>
 <div class="table-responsive tableFixHead"  id="printContainer" #TABLE class="hideTable">
   <table class="tableCls" >
       <thead>
           <tr>
                <th [attr.colspan]="noOfCol"> Schedule table for welfare schemes</th>
            </tr>
           <tr>
               <th width="100px">S.No</th>
               <th width="100px" >District</th>
               <th width="100px">Mandal</th>
               <th width="100px">RURAL/URBAN</th>
               <th width="100px" >Secretariat</th>
               <th width="100px" >Secretariat Code</th>
               <th width="100px" >Scheduled Date</th>
            </tr>
        </thead>

     
        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items;let i = index;">
                <td>{{i + 1}}</td>
                <td>{{item.districtName}}</td>
                <td>{{item.mandalName}}</td>
                <td>{{item.ruralOrUrban}}</td>
               <td>{{item.secretariatName}}</td>
               <td class="text-right">{{item.secretariatCode}}</td>
               <td class="text-right">{{item.scheduleDate | date: 'dd/MM/yyyy' }}</td>
              <tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td>{{totalItems[0].districtName}}</td>
                <td>{{totalItems[0].mandalName}}</td>
                <td>{{totalItems[0].ruralOrUrban}}</td>
                <td>{{totalItems[0].secretariatName}}</td>
                <td class="text-right">{{totalItems[0].secretariatCode}}</td>
                <td class="text-right">{{totalItems[0].scheduleDate | date: 'dd/MM/yyyy' }}</td>
                </tbody>
       <tbody *ngIf="items && items.length === 0">
           <tr>
              <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
       </tbody>
   </table>
  </div> 
  

