export const MenuConstants = [
  {
    module: 'Revenue Department', services: [
      { name: 'Computerized Adangal', path: '/revenue/computerised-adangal' },
      { name: 'Integrated Certificate', path: '/revenue/integrated-certificate' },
      { name: 'Income Certificate', path: '/revenue/income-certificate' },
      { name: 'Family member Certificate', path: '/revenue/familymembership-certificate' },
      { name: 'Issuance Of Income & Asset Certificate For Economically Weaker Sections (EWS)', path: '/revenue/issuanceincomeasset-certificate' },
      { name: 'Issuance Of Small And Marginal  Farmer Certificate', path: '/revenue/issuesmallmarginal-farmers-certificate' },
      { name: 'OBC Certificate', path: '/revenue/obc' },
      { name: 'Late Registration Of Death/Birth', path: '/revenue/lateRegistration' },
      { name: 'Agriculture Income Certificate', path: '/revenue/agricultureincome-certificate' },
      { name: 'Possession Certificate', path: '/revenue/possession-certificate' },
      { name: 'No Earning Certificate', path: '/revenue/noearning-certificate' },
      { name: 'Manual Adangal', path: '/revenue/old-adangal-application' },
     // { name: 'Addition Of Survey No In  Adangal And  1 B', path: '/revenue/addition-surveyno-adangal' },
      //{ name: 'F.M.B Copy', path: '/revenue/fmbcopy-applicationform' },
      //{ name: 'F-line Application (New)', path: '/revenue/f-line' },
      { name: 'Mutation and Title Deed Cum PPB', path: '/revenue/mutation-titledeed-ppb-ror' },
      //{ name: 'E-Passbook Application', path: '/revenue/e-passbook' },
      { name: 'Title Deed Cum Passbook', path: '/revenue/title-deed' },
      //{ name: 'Patta Sub Division For Auto Mutation Process Form ', path: '/revenue/patta-subdivision' },
      // { name: 'One Time Conversion (Fresh Application)', path: '/revenue/land-coversion-new' },
      { name: 'ROR - 1B', path: '/revenue/ror-1b' },
      //{ name: 'Encumbrance Certificate', path: '/revenue/encumbranceCertificate' },
      { name: 'Printing of Title Deed cum passbook', path: '/revenue/printing-titlecum-passbook' },
      //{ name: 'Rectifications  Of Entries In Record Of Rights', path: '/revenue/rectificationOfEntries' },
     // { name: 'Pattadar Mobile No Seeding In Land Records', path: '/revenue/pattadar-mobile-seeding' },
      { name: 'Change of Name Application', path: '/revenue/changeofnameapplication' },
      { name: 'Extract of House site / D-Form Patta Application', path: '/revenue/extract-of-housesite-or-dformpatta-application' },
      { name: 'Extract of NOC under Explosive / Petroleum Act', path: '/revenue/extractofnoc-explosiveact-or-petroleumact' },
      { name: 'Permission of Digging of Bore Well for Agricultural Purpose', path: '/revenue/permission-of-digging-of-bore-well-for-agricultural-purpose' },
      { name: 'No Property Application Service', path: '/revenue/nopropertycertificate' },
      { name: 'Renewal Of Cinema License', path: '/revenue/renewalofcinemalicence' },
      { name: 'Issue of Occupancy Rights Certificates for Inam Lands', path: '/revenue/issue-of-occupancy-rights-certificates-for-inam-lands' },
      { name: 'Issue of NOC For Storing of Petroleum Products', path: '/revenue/issueofnoc-forstoringof-petroleumproducts' },
      {name: 'Cracker license Application',path:'/revenue/crackerLicenceApplication'},
      {name: 'Duplicate of OBC / EBC / Integrated Certificate', path:'/revenue/re-issuance-of-certificates'},
      {name: 'AP Dotted Lands Application', path:'/revenue/ap-dotted-lands'},
      // {name: 'House Site Application', path:'/revenue/housing-site-application'},
      {name: 'Re-Issuance of Certificate', path:'/revenue/re-issuance-of-certificates-new'},
      {name: 'Mutation by Corrections & Automatic Pattadar Passbook Ordering', path:'/revenue/mutation-corrections-resurvey'},
      {name: 'Pattadar Aadhar Seeding to LP Number', path:'/revenue/pattadar-aadhar-seeding'},
      {name: 'Mutation by Transactions - LP Number(s)', path:'/revenue/mutation-transactions-resurvey'},
      //{name: 'Modfications In 22A List', path:'/revenue/modfications-in-22A-list'}
      {name: 'Land conversion (Agriculture to Non agriculture)', path:'/revenue/land-conversion-agri'},
      { name: 'Water Tax Application', path: '/revenue/water-tax' },
      // { name: 'Grievance in Resurvey Process' ,path: '/revenue/rectification-of-entries-resurveyed-records'},
      { name: 'Secretariat to Revenue Village Mapping' ,path: '/revenue/secretariatrvvillagemapping'},
      // { name: 'ROFR - Mutation for Transactions-Succession', path: '/revenue/ROFR-Mutation-For-Transactions-Succession'},
      // { name: 'Regularization Of Sadabainamas', path: '/revenue/regularization-of-sadabainamas' },
     // { name: 'Water Tax Application', path: '/revenue/water-tax' },
      //{name: 'Issuance of BHU HAKKU PATRAM', path:'/revenue/issuance-of-passbook-lpm'}
 
    ],
    showSubmenu: false,
    showMenu: false,
    heading: 'Revenue',
    subHeading: 'Revenue',
    imgCls: 'revenue'
  },
  {
    module: 'Civil Supplies Department', services: [
       { name: 'New Rice Card', path: '/civil-supplies/new-rice-card' },
      { name: 'Member Addition in Rice Card', path: '/civil-supplies/memberaddition-in-ricecard' },
      { name: 'Member Deletion in Rice Card', path: '/civil-supplies/memberdeletion-in-ricecard' },
      { name: 'Splitting Of Rice Card', path: '/civil-supplies/splitting-of-ricecard' },
      { name: 'Surrender Of Rice Card', path: '/civil-supplies/surrender-of-ricecard' },
      { name: 'Correction Of Wrong Aadhar Seeding', path: '/civil-supplies/correction-of-wrong-aadhar-seeding' },
      { name: 'Change of Address in Rice Card', path: '/civil-supplies/change-address-rice-card' },
      { name: 'FP Shop Renewal Application', path: '/civil-supplies/fp-shop' }
    ],
    showSubmenu: false,
    showMenu: false,
    heading: 'Consumer Affairs, Food and Civil Supplies',
    subHeading: 'Civil Supplies',
    imgCls: 'civil'
  },
  {
    module: 'Health and Family Welfare Department', services: [
    //  { name: 'New Aarogyasri Card', path: '/health/aarogya-sri' },
    //   {name :'Generate AAROGYASRI Card',path: '/health/health-card'},
      {name :'Update Dr NTR Vaidya Seva Card',path: '/health/update-health-card'},
      {name:'Check Dr NTR Vaidya Seva Card Status',path:'/health/health-card-status'},
    //  {name:'Health Cards Dispatch Status Updation',path:'/health/dispatch-status'},
      {name:'Vaidya Mithra - Patient Account Details',path:'/health/patient-bankdetails'}

    ],
   showSubmenu: false,
   showMenu: false,
   heading: 'Health and Family Welfare Department',
   subHeading: 'Aarogyasri',
   imgCls: 'health'
 },
  
  // {
  //   module: 'Pension Department', services: [
  //     { name: 'New Pension Application Form', path: '/pension-ui/pension-form' },
  //     // { name: 'Pension Card Printing Details', path: '/pension-ui/pension-card-printing' },
  //     // { name: 'Pension Social Audit', path: '/pension-ui/pension-social-audit' },
  //     // { name: 'Urban Property and Electricity Tagging for Pension Application', path: '/pension-ui/pension-urban-electricity-tagging' }
  //   ],
  //   showSubmenu: false,
  //   showMenu: false,
  //   heading: 'Panchayat Raj and Rural Development',
  //   subHeading: 'Rural Development',
  //   imgCls: 'panchayat'
  // },
  {
    module: 'GSWS Services', services: [
      { name: 'Lamination Service', path: '/lamination' },
      { name: 'Reprint of Certificates', path: '/print-service' },
      //{ name: 'Splitting of Household Members', path: '/revenue/household-split' },
      { name: 'Address Certificate for Aadhar Enrollment/Update', path: '/address-certificate' },
      // { name: 'Jagananna Suraksha Token Registration', path: '/registration-token' }  
      { name: 'Aarogya Suraksha 2.0 Token Registration', path: '/aarogya-suraksha-token-registaration' }      
    ],
    showSubmenu: false,
    showMenu: false,
    heading: 'GSWS Services',
    subHeading: 'GSWS Services',
    imgCls: 'gsws'
  },
  {
    module: 'Human Resources', services: [
      { name: 'Job Seeker Registration', path: '/human-resources/job-seeker-registration' }
    ],
    showSubmenu: false,
    showMenu: false,
    heading: 'Human Resources',
    subHeading: 'Human Resources',
    imgCls: 'hrms'
  },
  // {
  //   module: 'Labour Service', services: [
  //     { name: 'Application For Integrated Registration Of Establishment Under Labour Laws', path: '/labour-registration/registration' }
  //   ],
  //   showSubmenu: false,
  //   showMenu: false,
  //   heading: 'Labour',
  //   subHeading: 'Labour',
  //   imgCls: 'labour'
  // },
  
  {
    module: 'PR & MAUD', services: [
      { name: 'Marriage Certificate', path: '/registration/marriage-certificate' }
    ],
    showSubmenu: false,
    showMenu: false,
    heading: 'PR & MAUD',
    subHeading: 'PR & MAUD',
    imgCls: 'police'
  },
  
  // {
  //   module: 'MAUD Department', services: [
  //     { name: 'Birth/Death Certificate', path: '/maud/birth-death-cert' },
  //     { name: 'Child Name Inclusion', path: '/maud/childname-inclusion' },
  //     { name: 'Corrections In Birth Certificate', path: '/maud/corrections-in-birth' },
  //     { name: 'Corrections In Death Certificate', path: '/maud/corrections-in-death' },
  //     { name: 'Non Availability Birth Application', path: '/maud/non-availability-birth-application' },
  //     { name: 'Non Availability Death Application', path: '/maud/non-availability-death-application' },
      
  //   ],
  //   showSubmenu: false,
  //   showMenu: false,
  //   heading: 'Municipal Administration and Urban Development (CDMA)',
  //   subHeading: 'MAUD (CDMA)',
  //   imgCls: 'police'
  // }                                                
]
export const MenuConstantsCitizen = [
  {
    module: 'Revenue Department', services: [
      { name: 'Computerized Adangal', path: '/revenue/computerised-adangal' },
      { name: 'Integrated Certificate', path: '/revenue/integrated-certificate' },
      { name: 'Income Certificate', path: '/revenue/income-certificate' },
      { name: 'Family member Certificate', path: '/revenue/familymembership-certificate' },
      { name: 'Issuance Of Income & Asset Certificate For Economically Weaker Sections (EWS)', path: '/revenue/issuanceincomeasset-certificate' },
      { name: 'Issuance Of Small And Marginal  Farmer Certificate', path: '/revenue/issuesmallmarginal-farmers-certificate' },
      { name: 'OBC Certificate', path: '/revenue/obc' },
      { name: 'Late Registration Of Death/Birth', path: '/revenue/lateRegistration' },
      { name: 'Agriculture Income Certificate', path: '/revenue/agricultureincome-certificate' },
      { name: 'Possession Certificate', path: '/revenue/possession-certificate' },
      { name: 'No Earning Certificate', path: '/revenue/noearning-certificate' },
      { name: 'Manual Adangal', path: '/revenue/old-adangal-application' },
     // { name: 'Addition Of Survey No In  Adangal And  1 B', path: '/revenue/addition-surveyno-adangal' },
      //{ name: 'F.M.B Copy', path: '/revenue/fmbcopy-applicationform' },
      //{ name: 'F-line Application (New)', path: '/revenue/f-line' },
      //{ name: 'E-Passbook Application', path: '/revenue/e-passbook' },
      { name: 'Title Deed Cum Passbook', path: '/revenue/title-deed' },
      // { name: 'One Time Conversion (Fresh Application)', path: '/revenue/land-coversion-new' },
      { name: 'ROR - 1B', path: '/revenue/ror-1b' },
      //{ name: 'Encumbrance Certificate', path: '/revenue/encumbranceCertificate' },
      { name: 'Printing of Title Deed cum passbook', path: '/revenue/printing-titlecum-passbook' },
      //{ name: 'Rectifications  Of Entries In Record Of Rights', path: '/revenue/rectificationOfEntries' },
     // { name: 'Pattadar Mobile No Seeding In Land Records', path: '/revenue/pattadar-mobile-seeding' },
      { name: 'Change of Name Application', path: '/revenue/changeofnameapplication' },
      { name: 'Permission of Digging of Bore Well for Agricultural Purpose', path: '/revenue/permission-of-digging-of-bore-well-for-agricultural-purpose' },
      { name: 'No Property Application Service', path: '/revenue/nopropertycertificate' },
      { name: 'Renewal Of Cinema License', path: '/revenue/renewalofcinemalicence' },
      { name: 'Issue of Occupancy Rights Certificates for Inam Lands', path: '/revenue/issue-of-occupancy-rights-certificates-for-inam-lands' },
      { name: 'Issue of NOC For Storing of Petroleum Products', path: '/revenue/issueofnoc-forstoringof-petroleumproducts' },
      {name: 'Cracker license Application',path:'/revenue/crackerLicenceApplication'},
      {name: 'Duplicate of OBC / EBC / Integrated Certificate', path:'/revenue/re-issuance-of-certificates'},
      {name: 'Re-Issuance of Certificate', path:'/revenue/re-issuance-of-certificates-new'},
      // { name: 'Water Tax Application', path: '/revenue/water-tax' },
      //{name: 'Modfications In 22A List', path:'/revenue/modfications-in-22A-list'}
    ],
    showSubmenu: false,
    showMenu: false,
    heading: 'Revenue',
    subHeading: 'Revenue',
    imgCls: 'revenue'
  },
  {
    // module: 'Civil Supplies Department', services: [
    //   { name: 'New Rice Card', path: '/civil-supplies/new-rice-card' }
    // ],
    showSubmenu: false,
    showMenu: false,
    heading: 'Consumer Affairs, Food and Civil Supplies',
    subHeading: 'Civil Supplies',
    imgCls: 'civil'
  },
  {
     module: 'Health and Family Welfare Department', services: [
   //  { name: 'New Aarogyasri Card', path: '/health/aarogya-sri' },
    //   {name :'Generate AAROGYASRI Card',path: '/health/health-card'},
    // {name :'Update Dr NTR Vaidya Seva Card',path: '/health/update-health-card'},
    // {name:'Check Dr NTR Vaidya Seva Card Status',path:'/health/health-card-status'},
    // {name:'Vaidya Mithra - Patient Account Details',path:'/health/patient-bankdetails'}
      ],
    showSubmenu: false,
    showMenu: false,
    heading: 'Health and Family Welfare Department',
    subHeading: 'Aarogyasri',
    // imgCls: 'pension'
  },
  {
    module: 'PR & MAUD', services: [
      { name: 'Marriage Certificate', path: '/registration/marriage-certificate' }
    ],
    showSubmenu: false,
    showMenu: false,
    heading: 'PR & MAUD',
    subHeading: 'PR & MAUD',
   // imgCls: 'pension'
  },
  
  {
    module: 'Human Resources', services: [
      { name: 'Job Seeker Registration', path: '/human-resources/job-seeker-registration' }
    ],
    showSubmenu: false,
    showMenu: false,
    heading: 'Human Resources',
    subHeading: 'Human Resources',
   // imgCls: 'pension'
  },
  // {
  //   module: 'Civil Supplies Department', services: [
  //     { name: 'New Rice Card', path: '/civil-supplies/new-rice-card' },
  //     { name: 'Member Addition in Rice Card', path: '/civil-supplies/memberaddition-in-ricecard' },
  //     { name: 'Member Deletion in Rice Card', path: '/civil-supplies/memberdeletion-in-ricecard' },
  //     { name: 'Splitting Of Rice Card', path: '/civil-supplies/splitting-of-ricecard' },
  //     { name: 'Surrender Of Rice Card', path: '/civil-supplies/surrender-of-ricecard' },
  //     { name: 'Correction Of Wrong Aadhar Seeding', path: '/civil-supplies/correction-of-wrong-aadhar-seeding' },
  //     { name: 'Change of Address in Rice Card', path: '/civil-supplies/change-address-rice-card' },
  //   ],
  //   showSubmenu: false,
  //   showMenu: false,
  //   heading: 'Consumer Affairs, Food and Civil Supplies',
  //   subHeading: 'Civil Supplies',
  //   imgCls: 'civil'
  // },
  // {
  //   module: 'Pension Department', services: [
  //     { name: 'New Pension Application Form', path: '/pension-ui/pension-form' },
  //     { name: 'Pension Card Printing Details', path: '/pension-ui/pension-card-printing' },
  //     { name: 'Pension Social Audit', path: '/pension-ui/pension-social-audit' }
  //   ],
  //   showSubmenu: false,
  //   showMenu: false,
  //   heading: 'Panchayat Raj and Rural Development',
  //   subHeading: 'Rural Development',
  //   imgCls: 'pension'
  // },
  // {
  //   module: 'Registration and Stamp Department', services: [
  //     { name: 'Marriage Certificate', path: '/registration/marriage-certificate' }
  //   ],
  //   showSubmenu: false,
  //   showMenu: false,
  //   heading: 'Registration and Stamp Department',
  //   subHeading: 'Registration and Stamp',
  //  // imgCls: 'pension'
  // },
  // {
  //   module: 'GSWS Services', services: [
  //     { name: 'Lamination Service', path: '/lamination' },
  //     { name: 'Print Service', path: '/print-service' },
  //   ],
  //   showSubmenu: false,
  //   showMenu: false,
  //   heading: 'GSWS Services',
  //   subHeading: 'GSWS Services',
  //   // imgCls: 'pension'
  // },
  // {
  //   module: 'Health and Family Department', services: [
  //     { name: 'New Aarogyasri Health Card', path: '/health/aarogya-sri' },
  //   ],
  //   showSubmenu: false,
  //   showMenu: false,
  //   heading: 'Health and Family Department',
  //   subHeading: 'Aarogyasri',
  //   // imgCls: 'pension'
  // },
  // {
  //   module: 'MAUD Department', services: [
  //     { name: 'Birth/Death Certificate', path: '/maud/birth-death-cert' },
  //     { name: 'Child Name Inclusion', path: '/maud/childname-inclusion' },
  //     { name: 'Corrections In Birth Certificate', path: '/maud/corrections-in-birth' },
  //     { name: 'Corrections In Death Certificate', path: '/maud/corrections-in-death' },
  //     { name: 'Non Availability Birth Application', path: '/maud/non-availability-birth-application' },
  //     { name: 'Non Availability Death Application', path: '/maud/non-availability-death-application' },
      
  //   ],
  //   showSubmenu: false,
  //   showMenu: false,
  //   heading: 'Municipal Administration and Urban Development (CDMA)',
  //   subHeading: 'MAUD (CDMA)',
  //   imgCls: 'police'
  // }                                                
]

export const MenuConstantsMeeseva = [
  {
    module: 'PR & MAUD', services: [
      { name: 'Marriage Certificate', path: '/registration/marriage-certificate' }
    ],
    showSubmenu: false,
    showMenu: false,
    heading: 'PR & MAUD',
    subHeading: 'PR & MAUD',
    imgCls: 'police'
  },
  {
    module: 'Revenue Department', services: [
      { name: 'Mutation and Title Deed Cum PPB', path: '/revenue/mutation-titledeed-ppb-ror' },
      {name: 'Land conversion (Agriculture to Non agriculture)', path:'/revenue/land-conversion-agri'},
      { name: 'Integrated Certificate', path: '/revenue/integrated-certificate' },
      { name: 'Income Certificate', path: '/revenue/income-certificate' },
      {name: 'Re-Issuance of Certificate', path:'/revenue/re-issuance-of-certificates-new'},
      { name: 'Printing of Title Deed cum passbook', path: '/revenue/printing-titlecum-passbook' },
      { name: 'Title Deed Cum Passbook', path: '/revenue/title-deed' },
      { name: 'Family member Certificate', path: '/revenue/familymembership-certificate' },
      { name: 'OBC Certificate', path: '/revenue/obc' },

      { name: 'ROR - 1B', path: '/revenue/ror-1b' },
      { name: 'Computerized Adangal', path: '/revenue/computerised-adangal' },
      { name: 'Manual Adangal', path: '/revenue/old-adangal-application' },
      { name: 'Issuance Of Income & Asset Certificate For Economically Weaker Sections (EWS)', path: '/revenue/issuanceincomeasset-certificate' },
      { name: 'Issuance Of Small And Marginal  Farmer Certificate', path: '/revenue/issuesmallmarginal-farmers-certificate' },
      { name: 'Late Registration Of Death/Birth', path: '/revenue/lateRegistration' },
      { name: 'Agriculture Income Certificate', path: '/revenue/agricultureincome-certificate' },
      { name: 'Possession Certificate', path: '/revenue/possession-certificate' },
      { name: 'No Earning Certificate', path: '/revenue/noearning-certificate' },
      { name: 'Change of Name Application', path: '/revenue/changeofnameapplication' },
      { name: 'Extract of House site / D-Form Patta Application', path: '/revenue/extract-of-housesite-or-dformpatta-application' },
      { name: 'Permission of Digging of Bore Well for Agricultural Purpose', path: '/revenue/permission-of-digging-of-bore-well-for-agricultural-purpose' },
      { name: 'No Property Application Service', path: '/revenue/nopropertycertificate' },
      { name: 'Cracker license Application',path:'/revenue/crackerLicenceApplication'},
      {name: 'Mutation by Corrections & Automatic Pattadar Passbook Ordering', path:'/revenue/mutation-corrections-resurvey'},
      {name: 'Pattadar Aadhar Seeding to LP Number', path:'/revenue/pattadar-aadhar-seeding'},
      {name: 'Mutation by Transactions - LP Number(s)', path:'/revenue/mutation-transactions-resurvey'},
      { name: 'Water Tax Application', path: '/revenue/water-tax' },
    ],
    showSubmenu: false,
    showMenu: false,
    heading: 'Revenue',
    subHeading: 'Revenue',
    imgCls: 'revenue'
  }                                              
]
