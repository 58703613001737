import { CommonConstants } from 'src/constants/common-constants.component';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService, CustomAdapter, CustomDateParserFormatter } from 'src/shared/common.service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { NgbNavConfig, NgbModal, NgbNavChangeEvent, NgbActiveModal, NgbModalConfig,  NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-reissuance-report',
  templateUrl: './reissuance-report.component.html',
  styleUrls: ['./reissuance-report.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
  ]
})

export class ReissuanceReportComponent implements OnInit {

  form!: FormGroup;
  submitted: Boolean = false;
  showReports: Boolean = false;
  mandals: any[] = [];
  villages: any[]= [];
  commonConstants : any = CommonConstants;
  districts: any[] = [];
  format: any;
  defaultFromDate: any;
  currentDate = new Date();
  formatTypes: any[]= [];
  noOfCol: any = 8;
  items: any[] = [];
  page = 1;
  pageSize = 10;
  totalItems: any[] = [];
  popupPageSize = 10;
  popupPage = 1;
  role: any;
  maxDate: any;
  minDate: any;
  toMinDate: any;
  @ViewChild('TABLE') table!: ElementRef;

  constructor(private formBuilder: FormBuilder,private datePipe: DatePipe, public activeModal: NgbActiveModal, public config: NgbModalConfig, private modalService: NgbModal , private commonService: CommonService, private router: Router) { 
    this.format = 'District Wise';
    this.form = this.formBuilder.group({
      village: [null],
      mandal: [null],
      district: [null],
      RUFlag: [null],
      fromDate: ['', [Validators.required]],
      toDate: ['', [Validators.required]],
    });
    config.backdrop = 'static';
  }

  ngOnInit(): void {
    this.defaultFromDate = this.currentDate.getDate()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getFullYear();
    this.format = 'District Wise';
     this.form.patchValue({
       district:'All',
       mandal:'All',
       village: 'All',
       RUFlag: 'All',
       fromDate: this.defaultFromDate,
       toDate: this.defaultFromDate,
     });
     this.formatTypes = [
      {name: 'District Wise', isActive: true},
      {name:'Mandal Wise', isActive: false},
      {name:'Secretariat Wise', isActive: false}
    ]
    this.getDistricts();
    let userInfo = localStorage.getItem('userInfo');
    if (userInfo && userInfo !== null) {
      let userData = JSON.parse(userInfo);
      this.role = userData.jobTitle;
    }
  }

  get f() { return this.form.controls; }

  onChangeFormat(item: any) {
    this.showReports = false;
    this.formatTypes.map((item:any) => {
      item.isActive = false;
      return item;
    });
    item.isActive = true;
    this.format = item.name;
    this.getFormat();
  }

  onSourceChange(){
    this.showReports = false;
  }

  getFormat(): any {
    if (this.format === 'District Wise' ) {
      this.noOfCol = 8;
      return this.noOfCol;
    } else if (this.format === 'Mandal Wise') {
      this.noOfCol = 10;
      return this.noOfCol;
    }  else if (this.format === 'Secretariat Wise') {
      this.noOfCol = 11;
      return this.noOfCol;
    }else {
      this.noOfCol = 8;
      return this.noOfCol;
    }
  }

  getReissuanceReport(): any {
    this.submitted = true;
    if (!this.form.valid){
      return false;
    }
    else {
        this.page = 1;
        let district = this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode;
        let mandal = this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode;
        let village = this.f.village.value === 'All' ? 'All' : this.f.village.value?.secretariatCode;
        let RUFlag = this.f.RUFlag.value === 'All' ? 'All' : this.f.RUFlag.value;
        let fromDate = this.datePipe.transform(this.commonService.formatDate(this.f.fromDate.value), 'dd/MM/yyyy')
        let toDate = this.datePipe.transform(this.commonService.formatDate(this.f.toDate.value), 'dd/MM/yyyy')
        let postData: any = '?Flag='+ this.format +'&DistrictId=' + district + '&MandalId=' + mandal +'&Secretariat=' + village + '&Rurban=' + RUFlag + '&Fromdate=' + fromDate + '&Todate=' + toDate;
        this.commonService.getRequest(this.commonConstants.getReissuanceReport + postData).subscribe({
          next: (responseData: any) => { 
            if(typeof(responseData?.result) !== 'string') {
                this.showReports = true;
                  this.items = responseData?.result;
                  this.items = this.items.filter((item: any) => item.totalflag !== 'Y')
                  this.totalItems = responseData?.result.filter((item: { totalflag: string; }) => item.totalflag === 'Y')
            } else {
              this.showReports = true;
              this.items = [];
              this.totalItems = [];
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
      }
        )
    }
  }

  downloadExcel() {
    let fileName: any = 'Reissuance Integrated Report' + ' ' + this.f.fromDate.value +' to '+ this.f.toDate.value; 
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100}
  ];
  
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'ReissuanceReport');
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  onFromDateSelect() {
    this.showReports = false;
  }
  onToDateSelect() {
    this.showReports = false;
  }

  onDistrictChange() {
    this.showReports = false;
    this.mandals = [];
    this.villages = [];
    this.f.mandal.patchValue('All');
    this.f.village.patchValue('All');
    let RUFlag: any = this.f['RUFlag'].value === 'All' ? '' : this.f['RUFlag'].value;
    this.commonService.getRequest(this.commonConstants.getCSPMandalsRuralUrban + '?DistrictCode=' + this.f['district'].value.districtCode + '&RuralOrUrbanFlag=' + '').subscribe({
      next: (responseData: any) => { 
        if(responseData.result?.isSuccess) {
          this.mandals = this.commonService.sortItems(responseData.result?.getMandalDetails, 'mandalName');
        } else {
          this.mandals = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getCSPDistricts).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.districts = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'districtName');
        } else {
          this.districts = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  
  onMandalChange() {
    this.showReports = false;
    this.villages = [];
    this.f.village.patchValue('All');
    this.commonService.getRequest(this.commonConstants.getCSPSecretariat + '?MandalCode=' + this.f.mandal.value.mandalCode).subscribe({
      next: (responseData: any) => { 
        if(responseData.result?.isSuccess) {
          this.villages = this.commonService.sortItems(responseData.result?.getSecretariatDetails, 'secretariatName');
        } else {
          this.villages = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onVillageChange() {
    this.showReports = false;
  }
}
