import { Component,Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-extractofnoc-explosiveorpetroleum-cerificate',
  templateUrl: './extractofnoc-explosiveorpetroleum-cerificate.component.html',
  styleUrls: ['./extractofnoc-explosiveorpetroleum-cerificate.component.css']
})
export class ExtractofnocExplosiveorpetroleumCerificateComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
}
