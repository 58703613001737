<div class="certificateInfo" *ngIf="certificateInfo">
    <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div class="subHeadingCls mb-3">Applicant Details</div>
            <div class="fieldHolder">
                <label class="labelCls">Aadhar No: </label>
                <div class="valueCls">{{ applicantAadharNumber }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Applicant Name: </label>
                <div class="valueCls">{{ certificateInfo.applicantName }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Relation With Sadabainama Holder: </label>
                <div class="valueCls">{{ certificateInfo.applicantRelation }}</div>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div class="subHeadingCls mb-3">Land Located Details</div>
            <div class="fieldHolder">
                <label class="labelCls">District: </label>
                <div class="valueCls">{{ certificateInfo.landLocatedDetails.districtName }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal: </label>
                <div class="valueCls">{{ certificateInfo.landLocatedDetails.mandalName }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Village: </label>
                <div class="valueCls">{{ certificateInfo.landLocatedDetails.villageName }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Secretariat: </label>
                <div class="valueCls">{{ certificateInfo.landLocatedDetails.secretariatName }}</div>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div class="subHeadingCls mb-3">Sadabainama Holder Katha Details</div>
            <div class="fieldHolder">
                <label class="labelCls">Katha availability: </label>
                <div class="valueCls">{{ certificateInfo.landLocatedDetails.isKhathaAvailable == true ? 'Yes' : 'No' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Khatha Number: </label>
                <div class="valueCls">{{ certificateInfo.landLocatedDetails.khathaNumber }}</div>
            </div>
        </div>
    </div>
    <hr class="hrCls mt-4">
    <div class="row">
        <div class="col">
            <div class="subHeadingCls mb-3">Seller Land Details:</div>
            <div class="tab table-responsive">
                <table class="tableCls">
                    <thead>
                        <tr>
                            <th>Sl No.</th>
                            <th>LP No</th>
                            <th>Khata No</th>
                            <th>Old Sy No</th>
                            <th>Extent (Acrs-Cents)</th>
                            <th>Land Classification (Sub Classification 1,2)</th>
                            <th>Land Nature (Sub Nature 1,2,3)</th>
                            <th>Pattadar Name (Telugu)</th>
                            <th>Pattadar Father/Husband Name (Telugu)</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let data of certificateInfo.sellerLandDetailsList; let i=index">
                        <tr>
                            <td>{{ i + 1 }}</td>
                            <td>{{ data.lpNumber }}</td>
                            <td>{{ data.sellerKhathaNumber }}</td>
                            <td>{{ data.oldSurveyNumber }}</td>
                            <td>{{ data.extent }}</td>
                            <td>{{ data.landClassificationName }}({{data.landClassificationSubCategory1}}, {{data.landClassificationSubCategory2}})</td>
                            <td>{{ data.landNature }}({{data.landNatureSubCategory1}}, {{data.landNatureSubCategory2}}, {{data.landNatureSubCategory3}})</td>
                            <td>{{ data.pattadarTeluguName }}</td>
                            <td>{{ data.pattadarFatherOrHusbandName }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <hr class="hrCls mt-4">
    <div class="subHeadingCls mb-3">Proposed Land Details to be transferred to Sadabainama Holder</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Seller Name English: </label>
                <div class="valueCls">{{ certificateInfo.sellerNameEnglish }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Seller Name Telugu: </label>
                <div class="valueCls">{{ certificateInfo.sellerNameTelugu }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Seller Relation English: </label>
                <div class="valueCls">{{ certificateInfo.sellerRelationNameEnglish }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Seller Relation Telugu: </label>
                <div class="valueCls">{{ certificateInfo.sellerRelationNameTelugu }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Sadabainama Holder Name English: </label>
                <div class="valueCls">{{ certificateInfo.sadabainamaHolderNameEnglish }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Sadabainama Holder Name Telugu: </label>
                <div class="valueCls">{{ certificateInfo.sadabainamaHolderNameTelugu }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Sadabainama Holder Relation English: </label>
                <div class="valueCls">{{ certificateInfo.sadabainamaHolderRelationNameEnglish.substring(4) }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Sadabainama Holder Relation Telugu: </label>
                <div class="valueCls">{{ certificateInfo.sadabainamaHolderRelationNameTelugu.substring(4) }}</div>
            </div>
        </div>
        <div class="col">
            <div class="tab table-responsive">
                <table class="tableCls mt-2">
                    <thead>
                        <tr>
                            <th>Sl No.</th>
                            <th>LP No</th>
                            <th>Old Sy No</th>
                            <th>LP Extent (Acrs-Cents)</th>
                            <th>Date of Unregistered Document</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let data of certificateInfo.sadabainamaHolderDetailsList; let i=index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ data.lpNumber }}</td>
                        <td>{{ data.oldSurveyNumber }}</td>
                        <td>{{ data.lpExtent }}</td>
                        <td>{{ data.unregisteredDocumentDate | date: 'dd/MM/yyyy' }}</td>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <hr class="hrCls mt-4">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <ng-container *ngIf="fileList && fileList?.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Unregistered Documents'">
                    <span class="numberCls">{{ i }}</span><label class="labelCls">Unregistered Documents:</label>
                    <div class="valueCls">
                    <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                        class="download ml-2"></span>
                    </div>
                </div>
                <div *ngIf="file.documentType === 'Supporting Documents'">
                    <span class="numberCls">{{ i }}</span><label class="labelCls">Supporting Documents:</label>
                    <div class="valueCls">
                    <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                        class="download ml-2"></span>
                    </div>
                </div>
                <div *ngIf="file.documentType === 'Signature'">
                    <span class="numberCls">{{ i }}</span><label class="labelCls">Signature:</label>
                    <div class="valueCls">
                        <a (click)="viewImgFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                        class="download ml-2"></span>
                    </div>
                </div>
            </div>
        </ng-container>
        </div>
    </div>
</div>