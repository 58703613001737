<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <ng-container *ngIf="certificateInfo.serviceType == 'houseSitePatta'">
        <div class="subHeadingCls mb-3">House Site Patta</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">District:</label>
                    <div class="valueCls">{{certificateInfo.houseSiteOrDFormPattaAddress?.districtName}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Mandal:</label>
                    <div class="valueCls">{{certificateInfo.houseSiteOrDFormPattaAddress?.mandalMunicipalityName }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Village:</label>
                    <div class="valueCls">{{certificateInfo.houseSiteOrDFormPattaAddress?.villageWardName }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf = "certificateInfo.selectType == 'houseSiteSurveyNo'">
                <div class="fieldHolder">
                    <label class="labelCls">Plot No:</label>
                    <div class="valueCls">{{certificateInfo.plotNo ? (certificateInfo.plotNo) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Survey No:</label>
                    <div class="valueCls">{{certificateInfo.surveyNo ? (certificateInfo.surveyNo) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf = "certificateInfo.selectType == 'houseSiteTSNo'">
                <div class="fieldHolder">
                    <label class="labelCls">Plot No:</label>
                    <div class="valueCls">{{certificateInfo.plotNo ? (certificateInfo.plotNo) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">T.S No:</label>
                    <div class="valueCls">{{certificateInfo.tsNumber ? (certificateInfo.tsNumber) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Block:</label>
                    <div class="valueCls">{{certificateInfo.block ? (certificateInfo.block) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Ward:</label>
                    <div class="valueCls">{{certificateInfo.ward ? (certificateInfo.ward) : '-' }}</div>
                </div>
            </div>  
        </div>
    </ng-container>
    <ng-container *ngIf="certificateInfo.serviceType == 'dFormPatta'">
        <div class="subHeadingCls mb-3">D-Form Patta</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf = "certificateInfo.selectType == 'dFormSurveyNo'">
                <div class="fieldHolder">
                    <label class="labelCls">Survey No:</label>
                    <div class="valueCls">{{certificateInfo.surveyNo ? (certificateInfo.surveyNo) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf = "certificateInfo.selectType == 'dFormTSNo'">
                <div class="fieldHolder">
                    <label class="labelCls">T.S No:</label>
                    <div class="valueCls">{{certificateInfo.tsNumber ? (certificateInfo.tsNumber) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Block:</label>
                    <div class="valueCls">{{certificateInfo.block ? (certificateInfo.block) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Ward:</label>
                    <div class="valueCls">{{certificateInfo.ward ? (certificateInfo.ward) : '-' }}</div>
                </div>
            </div> 
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Name of Assignee:</label>
                    <div class="valueCls">{{certificateInfo.assigneeName ? (certificateInfo.assigneeName) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Father/Husband Name:</label>
                    <div class="valueCls">{{certificateInfo.assigneeFatherOrHusbandName ? (certificateInfo.assigneeFatherOrHusbandName) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Proceeding No:</label>
                    <div class="valueCls">{{certificateInfo.proceedingNumber ? (certificateInfo.proceedingNumber) : '-' }}</div>
                </div>  
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Extent(In Acres):</label>
                    <div class="valueCls">{{certificateInfo.extentInAcres ? (certificateInfo.extentInAcres) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Date:</label>
                    <div class="valueCls">{{certificateInfo.dFormPattaDate ? (certificateInfo.dFormPattaDate  | date: 'dd/MM/yyyy') : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">District:</label>
                    <div class="valueCls">{{certificateInfo.houseSiteOrDFormPattaAddress.districtName ? (certificateInfo.houseSiteOrDFormPattaAddress.districtName) : '-' }}</div>
                </div>  
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Mandal:</label>
                    <div class="valueCls">{{certificateInfo.houseSiteOrDFormPattaAddress.mandalMunicipalityName ? (certificateInfo.houseSiteOrDFormPattaAddress.mandalMunicipalityName) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Village:</label>
                    <div class="valueCls">{{certificateInfo.houseSiteOrDFormPattaAddress.villageWardName ? (certificateInfo.houseSiteOrDFormPattaAddress.villageWardName) : '-' }}</div>
                </div>  
            </div>
        </div>
    </ng-container>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList ; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>