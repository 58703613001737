<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Purpose:</label>
                <div class="valueCls">{{certificateInfo.purpose ? (certificateInfo.purpose) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Revenue Ward/Village:</label>
                <div class="valueCls">{{certificateInfo.villageName ? (certificateInfo.villageName) : '-' }}</div>
            </div>
        </div>

    </div>
</div>

<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Income Certificate</div>
    <!-- <form #userForm="ngForm" (ngSubmit)="onFormSubmit(userForm)"> -->
        <div class="row  mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls"><b>Income on Lands:</b></label>
                <input class="inputCls" id="landsIncome"  name="landsIncome" #landIncome="ngModel"
                    type="text"
                    required (keypress)="numberOnly($event)" [minlength]="incomeUpdate.landsIncome !== 0 ? 4 : 0"  maxlength="8"
                    [(ngModel)]="incomeUpdate.landsIncome"  (change)="totalValue()"/>
                <!-- <div *ngIf="landIncome.errors && userForm.submitted && !isValidFormSubmitted" class="selectError" [ngClass] = "'error'"> 
                    <div *ngIf="landIncome.errors.minlength">
                        <span class="mandatoryCls">Please enter atleast 4 digits</span>
                    </div>
                </div> -->
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls"><b>Income on Business:</b></label>
                <input class="inputCls" id="businessIncome" 
                    name="businessIncome"
                    type="text"
                    required required (keypress)="numberOnly($event)" [minlength]="incomeUpdate.businessIncome !== 0 ? 4 : 0"  maxlength="8"
                    [(ngModel)]="incomeUpdate.businessIncome"  (change)="totalValue()"/>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls"><b>Income on Buildings:</b></label>
                <input class="inputCls" id="buildingsIncome" 
                    name="buildingsIncome"
                    type="text"
                    required (keypress)="numberOnly($event)" [minlength]="incomeUpdate.buildingsIncome !== 0 ? 4 : 0"  maxlength="8"
                    [(ngModel)]="incomeUpdate.buildingsIncome"  (change)="totalValue()"/>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls"><b>Income on Labour:</b></label>
                <input class="inputCls" id="labourIncome" 
                    name="labourIncome"
                    type="text"
                    required (keypress)="numberOnly($event)" [minlength]="incomeUpdate.labourIncome !== 0 ? 4 : 0"  maxlength="8"
                    [(ngModel)]="incomeUpdate.labourIncome"  (change)="totalValue()"/>
            </div>
        
        <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Income on Buildings:</label>
            <div class="valueCls">{{certificateInfo.incomeSources?.incomeOnBuildings ? (certificateInfo.incomeSources?.incomeOnBuildings) : '-' }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Income on Labour:</label>
            <div class="valueCls">{{certificateInfo.incomeSources?.incomeOnLabour ? (certificateInfo.incomeSources?.incomeOnLabour) : '-' }}</div>
        </div>
        </div> -->
        </div>
        <div class="row">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls"><b>Salary of both Wife and Husband (Employed):</b></label>
                <input class="inputCls" id="familyIncome" 
                    name="familyIncome"
                    type="text"
                    required (keypress)="numberOnly($event)" [minlength]="incomeUpdate.familyIncome !== 0 ? 4 : 0"  maxlength="8"
                    [(ngModel)]="incomeUpdate.familyIncome"  (change)="totalValue()" />
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls"><b>Income on Other Sources:</b></label>
                <input class="inputCls" id="otherIncome" 
                    name="otherIncome"
                    type="text"
                    required (keypress)="numberOnly($event)" [minlength]="incomeUpdate.otherIncome !== 0 ? 4 : 0"  maxlength="8"
                    [(ngModel)]="incomeUpdate.otherIncome"  (change)="totalValue()"/>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls"><b>Total Income:</b></label>
                <input class="inputCls" disabled id="totalIncome" 
                    name="totalIncome" 
                    type="text"
                    required 
                    [(ngModel)]="incomeUpdate.totalIncome"  />
            </div>          
            <!-- <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls"><b></b></label>
                <button  class="greenBtnCls float-right" (click)="onFormSubmit(userForm)">Update</button>
            </div> -->
            
            <!-- <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Salary of both Wife and Husband (Employed):</label>
                    <div class="valueCls">{{certificateInfo.incomeSources?.incomeOfFamily ? (certificateInfo.incomeSources?.incomeOfFamily) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Income on Other Sources:</label>
                    <div class="valueCls">{{certificateInfo.incomeSources?.otherIncome ? (certificateInfo.incomeSources?.otherIncome) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Total Income:</label>
                <div class="valueCls">{{certificateInfo.totalIncome ? (certificateInfo.totalIncome) : '-' }}</div>
            </div>
            </div> -->
        </div>
    <!-- </form> -->
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Ration Card Certificate' || file.documentType?.trim() === 'IC_AC_PDF'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Ration card /EPIC Card/AADHAR Card:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'IT Return Copy' || file.documentType?.trim() === 'Income_PDF'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Copy of IT Returns/ Pay Slips (Any other documents):</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>



<!-- <app-common-dashboard  (view)="viewFile($event)" [incomeUpdate]="incomeUpdate"> </app-common-dashboard> -->

