
<div class="reportContainer">
    <div  [formGroup]="form">

        <div class="row mb-3">
            
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
            <select class="selectCls"  formControlName="district">
                <option value="All">All</option>
                <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
            </select>
            
            </div>

            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Rural/Urban : <span class="mandatoryCls">*</span></label> 
                <select class="selectCls" required formControlName="RUFlag" (change)="onRuralUrbanChange()"
                [ngClass]="{ 'is-invalid':submitted && (f.RUFlag.errors || f.RUFlag.value === 'Select') }">
                <option value = "All">All</option>
                <option value = "R">Rural</option>
                <option value = "U">Urban</option>
                </select>
            </div>
        
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="mandal" (change)="onMandalChange()">
            <option value="All">All</option>
            <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
            </select>            
            </div>

            
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Secretariat: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" formControlName="secreteriate">
                        <option value="All">All</option>
                        <option *ngFor="let sec of secreteriates" [ngValue]="sec">{{sec.secretariatName}}</option>
                    </select>
        </div>
    </div>

    
    <div class="tabClass">
        <ul>
            <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li> 
        </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getReportData()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" (click)="goToHome()">Home</button>
        </div>
      </div>

      <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="table-responsive tableFixHead" *ngIf="showReports">
        <table class="tableCls" >
            <thead> 
                <tr>
                    <th [attr.colspan]="noOfCol">SECRETARIAT-REVENUE VILLAGE MAPPING REPORT</th>
                </tr>
                <tr>
                    <th rowspan="2" width="100px">S.No</th>
                    <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'" >District Name<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'toT_MANDAL'"   *ngIf="format === 'District Wise'">No.Of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal Name<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rurban'" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Rural/Urban<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'" *ngIf="format === 'Secretariat Wise'">SEC CODE <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'" *ngIf="format === 'Secretariat Wise'">SEC NAME <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="2" style="width:14%" aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'toT_MAPPED_VILLAGE'" *ngIf="format === 'Secretariat Wise'">No Of Rev Village Mapped To Secreteriat<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th colspan="2" width="100px" *ngIf="format !== 'Secretariat Wise'">TOTAL</th>
                    <th colspan="2" width="100px" *ngIf="format !== 'Secretariat Wise'">MAPPED</th>
                    <th colspan="2" width="100px" *ngIf="format !== 'Secretariat Wise'">PENDING</th> 
                </tr> 
                <tr>
                    <th width="100px" aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'toT_SEC'" *ngIf="format !== 'Secretariat Wise'">No of Secretariat<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th width="100px" aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'toT_VIL'" *ngIf="format !== 'Secretariat Wise'">No of Revenue Village<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th width="100px" aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'toT_MAPPED_SEC'" *ngIf="format !== 'Secretariat Wise'">No of Secretariat<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th width="100px" aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'toT_MAPPED_VILLAGE'" *ngIf="format !== 'Secretariat Wise'">No of Revenue Village<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th width="100px" aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'penD_SEC'" *ngIf="format !== 'Secretariat Wise'">No of Secretariat<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th width="100px" aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'penD_VILLAGE'" *ngIf="format !== 'Secretariat Wise'">No of Revenue Village<mdb-icon fas icon="sort"></mdb-icon></th>
                </tr>          
                               
            </thead>
    
            <tbody *ngIf="items && items.length > 0">
                <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;"> 
                <td width="100px">{{ ((page -1) * pageSize) + (i + 1)}}</td>
                <td>{{item.districT_NAME}}</td>        
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.rurban}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_CODE}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
                <td *ngIf="format === 'Secretariat Wise'" class="linkCls"><a (click)="getSubReportData(item.secretariaT_CODE,secWise)">{{item.toT_MAPPED_VILLAGE}}</a></td>
                <td *ngIf="format === 'District Wise'">{{item.toT_MANDAL}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{item.toT_SEC}}</td>  
                <td *ngIf="format !== 'Secretariat Wise'">{{item.toT_VIL}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{item.toT_MAPPED_SEC}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{item.toT_MAPPED_VILLAGE}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{item.penD_SEC}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{item.penD_VILLAGE}}</td>           
            </tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td>{{totalItems[0].districT_NAME}}</td>        
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].rurban}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_CODE}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].toT_MAPPED_VILLAGE}}</td>
                <td *ngIf="format == 'District Wise'">{{totalItems[0].toT_MANDAL}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].toT_SEC}}</td>  
                <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].toT_VIL}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].toT_MAPPED_SEC}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].toT_MAPPED_VILLAGE}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].penD_SEC}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].penD_VILLAGE}}</td>  
               </tr></tbody>
            <tbody *ngIf="items && items.length === 0">
                <tr>
                    <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="mt-4 btnHide" *ngIf="showReports">
            <div class="float-left" *ngIf="items.length > 0">
                <label>Requests per page: </label>
                <select class="selectCls pageSizeCls" [(ngModel)]="pageSize" [ngModelOptions]="{standalone: true}">
                    <option  value="10">10</option>
                    <option  value="20">20</option>
                    <option  value="30">30</option>
                    <option  value="40">40</option>
                    <option  value="50">50</option>
                </select> 
            </div>
            <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
            </ngb-pagination>
        </div>
    
    
    </div>
    </div>

    <!-- Download report -->
    
    <div class="table-responsive tableFixHead" #TABLE class="hideTable">
        <table class="tableCls" >
            <thead>
             <tr>
                 <th [attr.colspan]="noOfCol">SECRETARIAT-REVENUE VILLAGE MAPPING REPORT</th>
             </tr>
            
             <tr>
                <th rowspan="2" width="100px">S.No</th>
                <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'" >District Name<mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'toT_MANDALS'"   *ngIf="format === 'District Wise'">No.Of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal Name<mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rurbaN_FLAG'" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Rural/Urban<mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'" *ngIf="format === 'Secretariat Wise'">SEC CODE <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'" *ngIf="format === 'Secretariat Wise'">SEC NAME <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" style="width:14%"  aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'toT_MAPPED_VILLAGE'" *ngIf="format === 'Secretariat Wise'">No Of Rev Village Mapped To Secreteriat<mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="2" width="100px" *ngIf="format !== 'Secretariat Wise'">TOTAL</th>
                <th colspan="2" width="100px" *ngIf="format !== 'Secretariat Wise'">MAPPED</th>
                <th colspan="2" width="100px" *ngIf="format !== 'Secretariat Wise'">PENDING</th> 
            </tr> 
            <tr>
                <th width="100px" aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'toT_SEC'" *ngIf="format !== 'Secretariat Wise'">No of Secretariat<mdb-icon fas icon="sort"></mdb-icon></th>
                <th width="100px" aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'toT_VIL'" *ngIf="format !== 'Secretariat Wise'">No of Revenue Village<mdb-icon fas icon="sort"></mdb-icon></th>
                <th width="100px" aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'toT_MAPPED_SEC'" *ngIf="format !== 'Secretariat Wise'">No of Secretariat<mdb-icon fas icon="sort"></mdb-icon></th>
                <th width="100px" aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'toT_MAPPED_VILLAGE'" *ngIf="format !== 'Secretariat Wise'">No of Revenue Village<mdb-icon fas icon="sort"></mdb-icon></th>
                <th width="100px" aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'penD_SEC'" *ngIf="format !== 'Secretariat Wise'">No of Secretariat<mdb-icon fas icon="sort"></mdb-icon></th>
                <th width="100px" aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'penD_VILLAGE'" *ngIf="format !== 'Secretariat Wise'">No of Revenue Village<mdb-icon fas icon="sort"></mdb-icon></th>
           </tr>      
                
            </thead>
     
            <tbody *ngIf="items && items.length > 0">
                <tr *ngFor="let item of items;let i=index">
                <td width="100px">{{i + 1}}</td>                    
                <td>{{item.districT_NAME}}</td>        
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.rurban}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_CODE}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{item.toT_MAPPED_VILLAGE}}</td>
                <td *ngIf="format == 'District Wise'">{{item.toT_MANDAL}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{item.toT_SEC}}</td>  
                <td *ngIf="format !== 'Secretariat Wise'">{{item.toT_VIL}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{item.toT_MAPPED_SEC}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{item.toT_MAPPED_VILLAGE}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{item.penD_SEC}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{item.penD_VILLAGE}}</td> 
                </tr>
                <tr *ngIf="totalItems && totalItems.length > 0">
                    <td></td>
                    <td>{{totalItems[0].districT_NAME}}</td>        
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].rurban}}</td>
                    <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_CODE}}</td>
                    <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
                    <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].toT_MAPPED_VILLAGE}}</td>
                    <td *ngIf="format == 'District Wise'">{{totalItems[0].toT_MANDAL}}</td>
                    <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].toT_SEC}}</td>  
                    <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].toT_VIL}}</td>
                    <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].toT_MAPPED_SEC}}</td>
                    <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].toT_MAPPED_VILLAGE}}</td>
                    <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].penD_SEC}}</td>
                    <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].penD_VILLAGE}}</td>  
                   </tr>
            </tbody>
            <tbody *ngIf="items && items.length === 0">
                <tr>
                    <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </table>
     </div>
<!-- Download sub Report -->

<div class="table-responsive tableFixHead" #SUBTABLE class="hideTable">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th>S.No</th>
                  <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rV_DISTRICTNAME'" >RV DISTRICT NAME<mdb-icon fas icon="sort"></mdb-icon></th>                    
                  <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rV_DIVISIONNAME'">RV DIVISION NAME<mdb-icon fas icon="sort"></mdb-icon></th>
                  <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rV_MANDALNAME'">RV MANDAL NAME<mdb-icon fas icon="sort"></mdb-icon></th>
                  <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rV_VILLAGEID'">RV VILLAGEID<mdb-icon fas icon="sort"></mdb-icon></th>
                  <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rV_VILLAGENAME'">RV VILLAGE NAME<mdb-icon fas icon="sort"></mdb-icon></th>
                  <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mrO_ACTION'">TEHSILDAR/MRO ACTION<mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>
        </thead>

        <tbody *ngIf="villageWiseItems && villageWiseItems.length > 0">
            <tr *ngFor="let vilg of villageWiseItems | slice: (popupPage-1) * popupPageSize : popupPage * popupPageSize;let i = index;">
                <td>{{ ((popupPage -1) * popupPageSize) + (i + 1)}}</td>
                <td>{{vilg.rV_DISTRICTNAME}}</td>
                <td>{{vilg.rV_DIVISIONNAME}}</td>
                <td>{{vilg.rV_MANDALNAME}}</td>
                <td>{{vilg.rV_VILLAGEID}}</td>
                <td>{{vilg.rV_VILLAGENAME}}</td>
                <td>{{vilg.mrO_ACTION}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="villageWiseItems && villageWiseItems.length === 0">
           
            <tr>
                <td colspan="7" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
 </div>

     <!-- sub report -->
     <ng-template #secWise let-modal>
        <div class="modal-header">
         <h5 class="modal-title" id="editProfileLabel">Village Wise</h5>
         <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
         </button>
        </div>
        
        <div class="modal-body">
            <div class="table-responsive tableFixHead">
                <table class="tableCls" >
                    <thead> 
                        <tr>
                            <th>S.No</th>
                              <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rV_DISTRICTNAME'" >RV DISTRICT NAME<mdb-icon fas icon="sort"></mdb-icon></th>                    
                              <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rV_DIVISIONNAME'">RV DIVISION NAME<mdb-icon fas icon="sort"></mdb-icon></th>
                              <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rV_MANDALNAME'">RV MANDAL NAME<mdb-icon fas icon="sort"></mdb-icon></th>
                              <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rV_VILLAGEID'">RV VILLAGEID<mdb-icon fas icon="sort"></mdb-icon></th>
                              <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rV_VILLAGENAME'">RV VILLAGE NAME<mdb-icon fas icon="sort"></mdb-icon></th>
                              <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mrO_ACTION'">TEHSILDAR/MRO ACTION<mdb-icon fas icon="sort"></mdb-icon></th>
                        </tr>
                    </thead>
            
                    <tbody *ngIf="villageWiseItems && villageWiseItems.length > 0">
                        <tr *ngFor="let vilg of villageWiseItems | slice: (popupPage-1) * popupPageSize : popupPage * popupPageSize;let i = index;">
                            <td>{{ ((popupPage -1) * popupPageSize) + (i + 1)}}</td>
                            <td>{{vilg.rV_DISTRICTNAME}}</td>
                            <td>{{vilg.rV_DIVISIONNAME}}</td>
                            <td>{{vilg.rV_MANDALNAME}}</td>
                            <td>{{vilg.rV_VILLAGEID}}</td>
                            <td>{{vilg.rV_VILLAGENAME}}</td>
                            <td>{{vilg.mrO_ACTION}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="villageWiseItems && villageWiseItems.length === 0">
                       
                        <tr>
                            <td colspan="7" class="text-center">No records found.</td>
                        </tr>
                    </tbody>
                </table>
                <div class="mt-4 btnHide">
                    <div class="float-left" *ngIf="villageWiseItems.length > 0">
                        <label>Requests per page: </label>
                        <select class="selectCls pageSizeCls" [(ngModel)]="popupPageSize" [ngModelOptions]="{standalone: true}">
                            <option  value="10">10</option>
                            <option  value="20">20</option>
                            <option  value="30">30</option>
                            <option  value="40">40</option>
                            <option  value="50">50</option>
                        </select> 
                    </div>
                    <ngb-pagination class="d-flex justify-content-end" *ngIf="villageWiseItems.length > 0" [collectionSize]="villageWiseItems.length" [(page)]="popupPage" [pageSize]="popupPageSize"
                        [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                    </ngb-pagination>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button  name="close" class="greenBtnCls" (click)="modal.dismiss()">Cancel</button>
          <button  name="close" class="greenBtnCls" *ngIf="villageWiseItems && villageWiseItems.length > 0" (click)="downloadExcelForSubReport()">Download Excel</button>
        </div>
       </ng-template>

     </div>
</div>