import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../shared/auth.guard';
import { MuslimMarriageCertificateComponent } from './muslim-marriage-certificate/muslim-marriage-certificate.component';

const routes: Routes = [
 { path: 'muslim-marriage-certificate', component: MuslimMarriageCertificateComponent ,data:{isCitizen: true}, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MinorityWelfareRoutingModule { }
