import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
  } from '@angular/common/http';
  import { Observable, throwError } from 'rxjs';
  import { retry, catchError, map } from 'rxjs/operators';
import { CommonService } from './common.service';
import { Injectable } from '@angular/core';
  
@Injectable()
  export class HttpErrorInterceptor implements HttpInterceptor {

    encryptedResponseProperties: string[] = [];

    constructor(private commonService: CommonService) { 
      this.encryptedResponseProperties = [
        "rawValue",
        "aadhaarNumber",
        "aadharNo",
        "uid",
        "selectedAadharKhata",
        "ApplicantAadhar",
        "SellerAadhar",
        "ApplicantAadharNo",
        "applicantAadharNo",
        "AadharNumber",
        "PattadarAadhar",
        "IdentityNumber",
        "aadhar_No",
        "aadharId",
        "aadhaarNo",
        "aadharNumber",
        "accountNo",
        "accountNumber",
        "adhar_number",
        "applicantInfo",
        "CITIZEN_ID",
        "citizen_UID",
        "deceasedAadharNo",
        "encryptedValue",
        "familyAadharNo",
        "identityNumber",
        "requested_Aadhar",
        "Aadharno",
        "ApplicantAadhar",
        "SellerAadhar",
        "IdentityNumber",
        "CertificateDetails",
        "uid_no",
        "AADHAR",
        "aadhar",
        "AadhaarNo",
        "ApplicantAadharNo",
        "CitizenId",
        "DeceasedAadharNo",
        "AadhaarNumber",
        "Id",
        "PattadarAadhar",
        "Pensioner_Uid",
        "UID",
        "uid",
        "AadharNumber",
        "UID_NUM",
        "fpShopDealerAadhar"
      ];
    }

    decrypteResponseProperties(obj: any) {
      for (let key in obj) {
        if (typeof obj[key] === "object") {
          this.decrypteResponseProperties(obj[key]);      
        } else {
          if(this.encryptedResponseProperties.indexOf(key) >= 0) {
            obj[key] = this.commonService.RSADecrypt(obj[key]);    
          }                 
        }
      }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request)
        .pipe(
          //retry(1),          
          map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                this.decrypteResponseProperties(event.body);   
                return event.clone({
                    body: event.body
                });
            }
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            let errorMessage = '';
            if (error.error instanceof ErrorEvent) {
              // client-side error
              errorMessage = `Error: ${error.error.message}`;
            } else {
              // server-side error
              errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
            window.alert(errorMessage);
            return throwError(errorMessage);
          })
        )
    }
  }