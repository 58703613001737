import { AbstractControl } from '@angular/forms';

export function AgeValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control.value > 150) {
    return { 'age': true };
  }
  if(control.value == 0)
  {
    return {'zero' : true}
  }
  return null;
}