<div class="reportContainer">
    <div  [formGroup]="form">
        <div class="row mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
                <select class="selectCls" formControlName="district" (change)="onDistrictChange()">
                    <option [ngValue]="null" disabled>Select</option>
                    <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
                </select>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Designation : <span class="mandatoryCls">*</span></label>
                <select class="selectCls" formControlName="designation" (change)="onDesignationChange()" >
                    <option value="All">All</option>
                    <option value="Collector">Collector</option>
                    <option value="Revenue Divisonal Officer">Revenue Divisonal Officer</option>
                    <option value="Revenue Inspector">Revenue Inspector</option>
                    <option value="Tahsildar">Tahsildar</option>
                    <option value="Village Revenue Officer">Village Revenue Officer</option>
                    <option value="Ward Revenue Secretary">Ward Revenue Secretary</option>
                    <!-- <option *ngFor="let designation of designations" [ngValue]="district">{{designation.designationName}}</option>  -->
                </select>
            </div>
        </div>
    </div>
    <div class="tabClass">
        <div class="btnHolder">
            <button  class="greenBtnCls float-right mr-3 my-3" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" (click)="goToHome()">Home</button>
        </div>
    </div>
    <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="table-responsive tableFixHead" *ngIf="showReports">
                <table class="tableCls" >
                    <thead>
                        <tr>
                            <th [attr.colspan]="noOfCol">SUOMOTO DESIGNATION WISE PENDING REPORT</th>
                        </tr>
                        <tr>
                            <th>S.No</th>
                            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'designation'">Designation<mdb-icon fas icon="sort"></mdb-icon></th>
                            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'userid'">User ID<mdb-icon fas icon="sort"></mdb-icon></th>
                            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'">Secretariat<mdb-icon fas icon="sort"></mdb-icon></th>
                            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District<mdb-icon fas icon="sort"></mdb-icon></th>
                            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal / ULB<mdb-icon fas icon="sort"></mdb-icon></th>
                            <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'apP_COUNT'">No. of Applications Pending<mdb-icon fas icon="sort"></mdb-icon></th>
                        </tr>
                    </thead>
                    <tbody *ngIf="items && items.length > 0">
                        <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                            <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                            <td>{{ item.designation }}</td>
                            <td>{{ item.userid }}</td>
                            <td>{{ item.secretariaT_NAME }}</td>
                            <td>{{ item.districT_NAME }}</td>
                            <td>{{ item.mandaL_NAME }}</td>
                            <td class="text-right">{{ item.apP_COUNT }}</td>
                        </tr>
                        <tr *ngIf="totalItems && totalItems.length > 0">
                            <td class="text-center" [attr.colspan]="6">{{ totalItems[0].districT_NAME }}</td>
                            <td class="text-right">{{ totalItems[0].apP_COUNT }}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="items && items.length === 0">
                        <tr>
                            <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="mt-4 btnHide" *ngIf="showReports">
                <div class="float-left" *ngIf="items.length > 0">
                    <label>Requests per page: </label>
                    <select class="selectCls pageSizeCls" [(ngModel)]="pageSize" [ngModelOptions]="{standalone: true}">
                        <option  value="10">10</option>
                        <option  value="20">20</option>
                        <option  value="30">30</option>
                        <option  value="40">40</option>
                        <option  value="50">50</option>
                    </select> 
                </div>
                <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
                    [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>

    <!-- Download report -->
    
    <div class="table-responsive tableFixHead" #TABLE class="hideTable">
        <table class="tableCls" >
            <thead>
                <tr>
                    <th [attr.colspan]="noOfCol">SUOMOTO DESIGNATION WISE PENDING REPORT</th>
                </tr>
                <tr>
                    <th>S.NO</th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'designation'">Designation<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'userid'">User ID<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'">Secretariat<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal/ULB<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'apP_COUNT'">No. of Applications Pending<mdb-icon fas icon="sort"></mdb-icon></th>
                </tr>
            </thead>
            <tbody *ngIf="items && items.length > 0">
                <tr *ngFor="let item of items; let i = index;">
                    <td>{{ i + 1}}</td>
                    <td>{{ item.designation }}</td>
                    <td>{{ item.userid }}</td>
                    <td>{{ item.secretariaT_NAME }}</td>
                    <td>{{ item.districT_NAME }}</td>
                    <td>{{ item.mandaL_NAME }}</td>
                    <td class="text-right">{{ item.apP_COUNT }}</td>
                </tr>
                <tr *ngIf="totalItems && totalItems.length > 0">
                    <td class="text-center" [attr.colspan]="6">{{ totalItems[0].districT_NAME }}</td>
                    <td class="text-right">{{ totalItems[0].apP_COUNT }}</td>
                </tr>
            </tbody>
            <tbody *ngIf="items && items.length === 0">
                <tr>
                    <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>