import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HealthViewRoutingModule } from './health-view-routing.module';
import { AarogyasriViewComponent } from './aarogyasri-view/aarogyasri-view.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { SharedModule } from 'src/shared/shared.module';
import { PatientBankDetailsViewComponent } from './patient-bankdetails-view/patient-bankdetails-view.component';
@NgModule({
  declarations: [
    AarogyasriViewComponent,
    PatientBankDetailsViewComponent
  ],
  imports: [
    CommonModule,
    HealthViewRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgxQRCodeModule,
    SharedModule
  ],
  exports : [
    AarogyasriViewComponent,
    PatientBankDetailsViewComponent
  ]
})
export class HealthViewModule { }
