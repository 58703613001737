<!-- <div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
            GSWS / Application Submission / Revenue Department <span class="activePage">/ Re-Issuance of
                Income/Integrated/Family Member/Late Registration of Birth and Death Certificate</span>
        </div>
    </div>
    <div class="mainPageContainer mr-3">
        <h6 class="mb-3">Re-Issuance of Income/Integrated/Family Member/Late Registration of Birth and Death Certificate
            Application Form</h6>

        <form [formGroup]="aarogya_sri_Form">
            <div class="row mb-3">

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Enter UHD :<span class="mandatoryCls">*</span></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">

                    <input type="text" name="aarogya_sri_number" placeholder="aarogya_sri_number" required
                        class="inputCls" formControlName="aarogya sri number"
                        [ngClass]="{ 'is-invalid':submitted && f.aarogya_sri_number.errors }">

                    <div *ngIf="submitted && f.aarogya_sri_number.errors" class="invalid-feedback ">
                        <div *ngIf="f.aarogya_sri_number.errors.required">Please enter aarogya_sri_number</div>
                    </div>
                </div>
            </div>

            <div class="btnHolder">
                <button class="greenBtnCls float-right" (click)="submit()">Get Details</button>
            </div>
        </form>
    </div>
</div> -->



<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
            GSWS / Application Submission / Health And Family Welfare Department<span class="activePage">/ Update Dr NTR Vaidya Seva Card
                </span>
        </div>
    </div>
    <div class="mainPageContainer mr-3">
        <h6 class="mb-3">Update Dr NTR Vaidya Seva Card</h6>
        <form [formGroup]="aarogyaSri">
            <nav ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)">
                <ng-container [ngbNavItem] = "1">
                    <a ngbNavLink>Basic Details</a>
                    <ng-template ngbNavContent>
                        <app-common-form formControlName="commonForm" [department]="'HF'" [isSubmitted]="commonFormSubmitted" (reset)="commonFormSubmitted = false"></app-common-form>
                        <div class="btnHolder">
                        <button  class="greenBtnCls float-right" (click)="goToNextTab()">Continue</button>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-container [ngbNavItem]="2">
                    <a ngbNavLink>Application Form</a>
                    <ng-template ngbNavContent>
                        <div class="row mb-3">
                           
                         

                                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <label class="labelCls">Enter UHID :<span class="mandatoryCls">*</span></label>
                                </div>
                                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                
                                    <input type="text" name="aarogya_sri_number" placeholder="UHID number" required  numbersOnly
                                        class="inputCls" formControlName="aarogya_sri_number" maxlength="15" minlength="11"
                                        [ngClass]="{ 'is-invalid':getDetailsFlag && f.aarogya_sri_number.errors }"
                                        [attr.disabled] ="showDetails ? '' : null">
                
                                    <div *ngIf="getDetailsFlag && f.aarogya_sri_number.errors" class="invalid-feedback ">
                                        <div *ngIf="f.aarogya_sri_number.errors.required">Please enter UHID number</div>
                                        <div *ngIf="f.aarogya_sri_number.errors?.minlength">Please enter valid UHID number</div>
                                      
                                
                                    </div>
                                </div>
                            <!--</div> -->
                
                            <div class="btnHolder">
                                <button class="greenBtnCls float-right" (click)="getAarogysriData()">Get Details</button>
                            </div>

                        </div>    
                       
                        <div *ngIf="showDetails">
                            <div class="row mb-3">
                                <div class="col">
                                    <div class="tab table-responsive">
                                        <hr class="hrCls mt-4">
                                        <h6 class="mb-3">Family Details </h6>
                                        <table class="tableCls" style="width :1300px">
                                            <thead>
                                                <tr>
                                                    <th>Serial No</th>
                                                    <th>Member Name</th>
                                                    <th>Telugu Name</th>
                                                    <th>Gender<span class="mandatoryCls">*</span></th>
                                                    <th>Relation with the Family Head<span class="mandatoryCls">*</span>
                                                    </th>
                                                    <th>Date of Birth<span class="mandatoryCls">*</span></th>
                                                    <th>Age</th>
                                                    <th>Mobile Number<span class="mandatoryCls">*</span></th>
                                                    <th>Aadhaar Number<span class="mandatoryCls">*</span></th>

                                                    <!-- commented member deletion as part of election code of conduct -->
                                                    <!-- <th>Reason for Deactivation </th> -->
                                                </tr>
                                            </thead>
                                            <tbody formArrayName="familyDetailsTableData">
                                                <tr [formGroupName]="i"
                                                    *ngFor="let member of familyDetailsTableDatas.controls; let i = index">
                                                    <td class="serial">{{i+1}}</td>
                                                    <td>
                                                        {{this.familyDetailsTableDatas.controls[i].get('tbName')?.value}}
                                                    </td>
                                                    <td>
                                                        <input type="text" class="inputCls value" name="tbTeluguName" maxlength="50" minlength="2"
                                                        [attr.disabled] = "(this.familyDetailsTableDatas.controls[i].get('iS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH' ) ? '' : null" 
                                                        id="tbTeluguName" required [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbTeluguName')?.errors }" 
                                                        formControlName = "tbTeluguName" (keypress)="matcher($event)" (blur) = "checkText($event , i)">
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbTeluguName')?.errors" class="invalid-feedback">
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbTeluguName')?.errors?.required">Please enter Telugu Name</div>
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbTeluguName')?.errors?.minlength ">Please enter Valid Telugu Name</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <select class="selectCls" name="tbGender" id="tbGender" required
                                                            formControlName="tbGender"     [attr.disabled]= "true"
                                                            [ngClass]="{ 'is-invalid':submitted && (familyDetailsTableDatas.controls[i].get('tbGender')?.errors || this.familyDetailsTableDatas.controls[i].get('tbGender')?.value === 'Select') }">
                                                            <option [ngValue]="null" disabled>Select</option>
                                                            <option *ngFor="let gender of genders"
                                                                [ngValue]="gender.name">{{gender.name}}
                                                        </select>
                                                        <div *ngIf="submitted && (this.familyDetailsTableDatas.controls[i].get('tbGender')?.errors || this.familyDetailsTableDatas.controls[i].get('tbGender')?.value === 'Select')"
                                                            class="selectError">
                                                            <div
                                                                *ngIf="this.familyDetailsTableDatas.controls[i].get('tbGender')?.errors?.required || this.familyDetailsTableDatas.controls[i].get('tbGender')?.value === 'Select'">
                                                                Please select Gender</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <select class="selectCls" 
                                                            [attr.disabled] = "(this.familyDetailsTableDatas.controls[i].get('tbSelf')?.value === true || this.familyDetailsTableDatas.controls[i].get('iS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH' ) ? '' : null"
                                                            name="tbRelation" id="tbRelation"
                                                            required formControlName="tbRelation"
                                                            (change) = "relationValidator(i)"
                                                            [ngClass]="{ 'is-invalid':submitted && (familyDetailsTableDatas.controls[i].get('tbRelation')?.errors || this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'Select') }">
                                                            <option [ngValue]="null" disabled>Select</option>
                                                            <ng-container *ngIf="this.familyDetailsTableDatas.controls[i].get('tbGender')?.value ==='MALE' ">
                                                                
                                                                <option *ngFor="let relation of maleRelations"
                                                                [ngValue]="relation.name">{{relation.name}}</option>
                                                            </ng-container>
                                                            <ng-container *ngIf="this.familyDetailsTableDatas.controls[i].get('tbGender')?.value ==='FEMALE' ">
                                                                <option *ngFor="let relation of femaleRelations"
                                                                [ngValue]="relation.name">{{relation.name}}</option>
                                                            </ng-container>
                                                            <ng-container *ngIf="this.familyDetailsTableDatas.controls[i].get('tbGender')?.value ==='TRANSGENDER' ">
                                                                <option *ngFor="let relation of relations"
                                                                [ngValue]="relation.name">{{relation.name}}</option>
                                                            </ng-container>
                                                            <!-- <option *ngFor="let relation of this.familyDetailsTableDatas.controls[i].get('tbRelationarray')?.value"
                                                                [ngValue]="relation.name">{{relation.name}}</option> -->
                                                        </select>
                                                        <div *ngIf="submitted && (this.familyDetailsTableDatas.controls[i].get('tbRelation')?.errors || this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'Select')"
                                                            class="selectError">
                                                            <div
                                                                *ngIf="this.familyDetailsTableDatas.controls[i].get('tbRelation')?.errors?.required || this.familyDetailsTableDatas.controls[i].get('tbRelation')?.value === 'Select'">
                                                                Please select Relation</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="input-group" style="position:initial !important">
                                                            <input class="calendarCls form-control"  placeholder="dd/mm/yyyy" maxlength="20"
                                                                name="d"  ngbDatepicker #d="ngbDatepicker" [minDate]="minDate" required
                                                                [attr.readonly]="true" 
                                                                [maxDate]="maxDate"  formControlName="tbDob" appBlockCopyPaste (change) = "dateChange($event , i)" (dateSelect) = "dateChangeToggle($event , i)"
                                                                [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbDob')?.errors }" >
                                                            <div class="input-group-append">
                                                            <!-- <button class="btn btn-outline-secondary calendar"  *ngIf = "this.familyDetailsTableDatas.controls[i].get('tbDob')?.value" (click)="d.toggle()"  type="button"></button> -->
                                                            </div>
                                                        </div>
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbDob')?.errors" class="selectError">
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbDob')?.errors?.required || this.familyDetailsTableDatas.controls[i].get('tbDob')?.invalid">Please select Valid Date of Birth</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="inputCls"  appBlockCopyPaste  name="tbAge" id="tbAge" formControlName = "tbAge" maxlength="3" numbersOnly readonly>
                                                    </td>
                                                    <!--<td>
                                                        <div class="input-group">
                                                            <input class="calendarCls form-control"
                                                                placeholder="dd/mm/yyyy" name="d" ngbDatepicker
                                                                #d="ngbDatepicker" [minDate]="minDate" 
                                                                [maxDate]="maxDate" required formControlName="tbDob"
                                                                (mousedown)="ageCalculator()"
                                                                appBlockCopyPaste
                                                                [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbDob')?.errors }">
                                                            <div class="input-group-append">
                                                                <button class="btn btn-outline-secondary calendar"
                                                                (click)="d.toggle()"  type="button"></button>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbDob')?.errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="this.familyDetailsTableDatas.controls[i].get('tbDob')?.errors?.required">
                                                                Please select Date of Birth</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="inputCls" appBlockCopyPaste
                                                            name="tbAge" id="tbAge" formControlName="tbAge"
                                                            maxlength="3" numbersOnly readonly>
                                                    </td> -->
                                                    <!--<td>
                                                        <input type="text" class="inputCls value" name="tbMaskAadharNo"
                                                            id="tbMaskAadharNo" appBlockCopyPaste required
                                                            [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbMaskAadharNo')?.errors }"
                                                            formControlName="tbMaskAadharNo" maxlength="12" minlength="12"
                                                            numbersOnly>
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbMaskAadharNo')?.errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="this.familyDetailsTableDatas.controls[i].get('tbMaskAadharNo')?.errors?.required  || this.familyDetailsTableDatas.controls[i].get('tbMaskAadharNo')?.errors?.whiteSpaceValidate">
                                                                Please enter Aadhaar Number</div>
                                                            <div
                                                                *ngIf="this.familyDetailsTableDatas.controls[i].get('tbMaskAadharNo')?.errors?.aadharValidate  || this.familyDetailsTableDatas.controls[i].get('tbMaskAadharNo')?.errors?.minlength">
                                                                Please enter valid Aadhaar Number</div>
                                                        </div>
                                                    </td> -->
                                                    <td>
                                                        <input type="text" class="inputCls value" name="tbMobile"
                                                            id="tbMobile" appBlockCopyPaste required
                                                            [attr.readonly] = "(this.familyDetailsTableDatas.controls[i].get('iS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH' ) ? '' : null"
                                                            [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbMobile')?.errors }"
                                                            formControlName="tbMobile" maxlength="10" minlength="10"
                                                            numbersOnly>
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbMobile')?.errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="this.familyDetailsTableDatas.controls[i].get('tbMobile')?.errors?.required  || this.familyDetailsTableDatas.controls[i].get('tbMobile')?.errors?.whiteSpaceValidate">
                                                                Please enter Mobile Number</div>
                                                            <div
                                                                *ngIf="this.familyDetailsTableDatas.controls[i].get('tbMobile')?.errors?.minlength || this.familyDetailsTableDatas.controls[i].get('tbMobile')?.errors?.pattern">
                                                                Please Enter valid 10 digit Mobile Number</div>
                                                        </div>
                                                    </td> 
                                                    <td>
                                                        {{this.familyDetailsTableDatas.controls[i].get('tbMaskAadharNo')?.value}}
                                                    </td>

                                    <!-- commented member deletion as part of election code of conduct -->
<!-- 
                                                    <td *ngIf="this.familyDetailsTableDatas.controls[i].get('tbisActive')?.value !== true">
                                                        <select class="selectCls" name="tbReason" id="tbReason" 
                                                            formControlName="tbReason"
                                                            [attr.disabled] = "isDeathPerson == true ? '' : null" 
                                                           >
                                                            <option [ngValue]="null" disabled>Select</option>
                                                            <option *ngFor="let reason of reasons"
                                                                [ngValue]="reason.reason">{{reason.reason}}
                                                        </select>
                                                        
                                                    </td> -->
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="mandatoryCls" *ngIf="duplicatesRecords">Duplicate Aadhaar Entries!
                                        </div>
                                    </div>
                                </div>
                            </div>

<!-- commented member addition as part of Election code of conduct -->
                              <!-- ========= Member Addition  ================ -->
                           
                              <!-- <div class="row mb-3">
                                <div class="col">
                                    <div class="tab table-responsive">
                              <form [formGroup]="Member">
                                <hr class="hrCls mt-4">
                                <h6 class="mb-3">Add Member Details</h6>
                                <table class="tableCls" style="width :1800px">
                                    <thead>
                                        <tr>
                                            <th>SNo</th>
                                            <th>Aadhar No<span class="mandatoryCls">*</span> </th>
                                            <th>DOB<span class="mandatoryCls">*</span> </th>
                                            <th> Age<span class="mandatoryCls">*</span> </th>
                                            <th>Relation with the Family Head<span class="mandatoryCls">*</span> </th>
                                            <th> Member Name<span class="mandatoryCls">*</span> </th>
                                            <th> Telugu<span class="mandatoryCls">*</span> </th>
                                            <th> Mobile No<span class="mandatoryCls">*</span> </th>
                                            <th> Gender<span class="mandatoryCls">*</span> </th>
                                            <th> Annual<br/> Income <br/>(In Rs.)<span class="mandatoryCls">*</span> </th>
                                            <th> Govt. Employee/ <br/>Pensioner <br/> YES/NO<span class="mandatoryCls">*</span> </th>
                                            <th>Employee Category</th>
                                            <th> Income Details <br/> (If Paying Tax)<span class="mandatoryCls">*</span> </th>
                                            <th> Four Wheelers <br/> YES/NO<span class="mandatoryCls">*</span> </th>
                                            <th> No of Four Wheelers<span class="mandatoryCls">*</span> </th>
                                            <th> Four Wheelers Numbers<span class="mandatoryCls">*</span> </th>
                                            <th>Wet Land Details<span class="mandatoryCls">*</span> </th>
                                            <th> Dry Land Details<span class="mandatoryCls">*</span> </th>
                                            <th> Total Land<span class="mandatoryCls">*</span> </th>
                                            <th> Property <br/>Details <br/>present <br/>in Muncipal <br/>Area(in Sq.ft)<span class="mandatoryCls">*</span> </th>
                                            <th> <span class="mandatoryCls"></span> </th>
                                        </tr>
    
                                        </thead>
    
                                        <tbody formArrayName="AddingMembersData">
                                            <tr [formGroupName]="i"
                                            *ngFor="let member of AddingMembers.controls; let i = index;">
                                            <td class="serial">{{i+1}}</td>    
    
                                            <td>                                            
    
                                                <input type="text" class="inputCls value" name="tbAadharNo"
                                                id="tbAadharNo" appBlockCopyPaste  required
                                                [ngClass]="{ 'is-invalid':familySubmitted && this.AddingMembers.controls[i].get('tbAadharNo')?.errors }"
                                                formControlName="tbAadharNo" maxlength="12" minlength="12"
                                                numbersOnly 
                                                (keyup)="onKeyup($event,i)"  >
                                            <div *ngIf="familySubmitted && this.AddingMembers.controls[i].get('tbAadharNo')?.errors"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="this.AddingMembers.controls[i].get('tbAadharNo')?.errors?.required  || this.AddingMembers.controls[i].get('tbAadharNo')?.errors?.whiteSpaceValidate">
                                                    Please enter Aadhaar Number</div>
                                                <div
                                                    *ngIf="this.AddingMembers.controls[i].get('tbAadharNo')?.errors?.aadharValidate  || this.AddingMembers.controls[i].get('tbAadharNo')?.errors?.minlength">
                                                    Please enter valid Aadhaar Number</div>
                                            </div>
    
    
                                            </td>
    
    
    
                                            <td>
                                               
                                                <div class="input-group" style="position:initial !important">
                                                    <input class="calendarCls form-control"
                                                        placeholder="dd/mm/yyyy" name="d" ngbDatepicker
                                                        #d="ngbDatepicker" [minDate]="minDate" 
                                                        [maxDate]="maxDate" 
                                                        maxlength="20"
                                                         formControlName="tbDob"
                                                        required
                                                        [attr.readonly]="true" 
                                                        appBlockCopyPaste
                                                        (change) = "FamilydateChange($event , i)" 
                                                        (dateSelect) = "FamilydateChangeToggle($event , i)"
                                                        [ngClass]="{ 'is-invalid':familySubmitted && this.AddingMembers.controls[i].get('tbDob')?.errors }">
                                                  
                                                </div>
                                                <div *ngIf="familySubmitted && this.AddingMembers.controls[i].get('tbDob')?.errors"
                                                    class="selectError">
                                                    <div
                                                        *ngIf="this.AddingMembers.controls[i].get('tbDob')?.errors?.required || this.AddingMembers.controls[i].get('tbDob')?.invalid">
                                                        Please select Valid Date of Birth</div>
                                                </div>
    
                                            </td>
                                            <td>
                                                
    
                                                <input type="text" class="inputCls" appBlockCopyPaste
                                                name="tbAge" id="tbAge" formControlName="tbAge"
                                                maxlength="3" numbersOnly readonly>
                                                <div *ngIf="familySubmitted && this.AddingMembers.controls[i].get('tbAge')?.errors" class="invalid-feedback">
                                                    <div *ngIf="this.AddingMembers.controls[i].get('tbAge')?.errors?.required">Age is required</div>
                                                </div>
                                            </td>
                                            <td>
                                             

                                                <select class="selectCls" name="tbRelation" id="tbRelation"
                                                 formControlName="tbRelation" required
                                                [ngClass]="{ 'is-invalid':familySubmitted && (AddingMembers.controls[i].get('tbRelation')?.errors || this.AddingMembers.controls[i].get('tbRelation')?.value === 'Select') }">
                                                <option [ngValue]="null" disabled>Select</option>
                                               
                                                    <ng-container *ngIf="this.AddingMembers.controls[i].get('tbGender')?.value ==='MALE' ">
                                                                
                                                        <option *ngFor="let relation of memberMaleRelations"
                                                        [ngValue]="relation.name">{{relation.name}}</option>
                                                    </ng-container>
                                                    <ng-container *ngIf="this.AddingMembers.controls[i].get('tbGender')?.value ==='FEMALE' ">
                                                        <option *ngFor="let relation of memberFemaleRelations"
                                                        [ngValue]="relation.name">{{relation.name}}</option>
                                                    </ng-container>
                                                    <ng-container *ngIf="this.AddingMembers.controls[i].get('tbGender')?.value ==='TRANSGENDER' ">
                                                        <option *ngFor="let relation of memberRelations"
                                                        [ngValue]="relation.name">{{relation.name}}</option>
                                                    </ng-container>
                                            </select>
                                            <div *ngIf="familySubmitted && (this.AddingMembers.controls[i].get('tbRelation')?.errors || this.AddingMembers.controls[i].get('tbRelation')?.value === 'Select')"
                                                class="selectError">
                                                <div
                                                    *ngIf="this.AddingMembers.controls[i].get('tbRelation')?.errors?.required || this.AddingMembers.controls[i].get('tbRelation')?.value === 'Select'">
                                                    Please select Relation</div>
                                            </div>
                                            </td>
                                            <td>
                                         
                                         
                                                <input type="text"  readonly name="tbName" appBlockCopyPaste formControlName="tbName" class="inputCls"   maxlength="50" required
                                                [ngClass]="{ 'is-invalid':familySubmitted && this.AddingMembers.controls[i].get('tbName')?.errors }">
                                                <div *ngIf="familySubmitted && this.AddingMembers.controls[i].get('tbName')?.errors" class="invalid-feedback">
                                                    <div *ngIf="this.AddingMembers.controls[i].get('tbName')?.errors?.required || this.AddingMembers.controls[i].get('tbName')?.errors?.whiteSpaceValidate">Name is required in English</div>
                                                    <div *ngIf="this.AddingMembers.controls[i].get('tbName')?.errors?.pattern">Please enter valid Name in English</div>
                                                </div>
                                            </td>
                                            <td>
                                                <input type="text" class="inputCls value" maxlength="50" minlength="2"
                                                 name="tbTeluguName" id="tbTeluguName" required [ngClass]="{ 'is-invalid':familySubmitted && this.AddingMembers.controls[i].get('tbTeluguName')?.errors }" 
                                                 (keypress)="matcher($event)" formControlName = "tbTeluguName">
                                                <div *ngIf="familySubmitted && this.AddingMembers.controls[i].get('tbTeluguName')?.errors" class="invalid-feedback">
                                                    <div *ngIf="this.AddingMembers.controls[i].get('tbTeluguName')?.errors?.required">Please enter Telugu Name</div>
                                                    <div *ngIf="this.AddingMembers.controls[i].get('tbTeluguName')?.errors?.minlength">Please enter Valid Telugu Name</div>
                                                </div>
                                            </td>
                                            <td>
                                               

                                                <input type="text" class="inputCls value" appBlockCopyPaste name="tbMobile"  id="tbMobile"  required [ngClass]="{ 'is-invalid':familySubmitted && this.AddingMembers.controls[i].get('tbMobile')?.errors }"  numbersOnly  maxlength="10" minlength="10" formControlName = "tbMobile">
                                                <div *ngIf="familySubmitted && this.AddingMembers.controls[i].get('tbMobile')?.errors" class="invalid-feedback">
                                                <div *ngIf="this.AddingMembers.controls[i].get('tbMobile')?.errors?.required  || this.AddingMembers.controls[i].get('tbMobile')?.errors?.whiteSpaceValidate">Please enter Mobile Number</div>
                                                <div
                                                                *ngIf="this.AddingMembers.controls[i].get('tbMobile')?.errors?.minlength || this.AddingMembers.controls[i].get('tbMobile')?.errors?.pattern">
                                                                Please Enter valid 10 digit Mobile Number</div>  
                                            </div>

                                            </td>
                                            <td>
                                               

                                                <select class="selectCls" name="tbGender" id="tbGender" 
                                                formControlName="tbGender"  required   [attr.disabled]= "true"
                                                
                                                [ngClass]="{ 'is-invalid':familySubmitted && (AddingMembers.controls[i].get('tbGender')?.errors || this.AddingMembers.controls[i].get('tbGender')?.value === 'Select') }">
                                                <option [ngValue]="null" disabled>Select</option>
                                                <option *ngFor="let gender of genders"
                                                    [ngValue]="gender.name">{{gender.name}}
                                            </select>
                                            <div *ngIf="familySubmitted && (this.AddingMembers.controls[i].get('tbGender')?.errors || this.AddingMembers.controls[i].get('tbGender')?.value === 'Select')"
                                                class="selectError">
                                                <div
                                                    *ngIf="this.AddingMembers.controls[i].get('tbGender')?.errors?.required || this.AddingMembers.controls[i].get('tbGender')?.value === 'Select'">
                                                    Please select Gender</div>
                                            </div>

                                            </td>
                                            <td>
                                               
                                                <input type="text" class="inputCls value" (keyup)="onchangeofAnualIncome($event)"
                                                appBlockCopyPaste name="tbAnnualIncome" id="tbAnnualIncome" [ngClass]="{ 'is-invalid':familySubmitted && this.AddingMembers.controls[i].get('tbAnnualIncome')?.errors }"  numbersOnly  maxlength="8"  formControlName = "tbAnnualIncome"  >
                                                <div *ngIf="familySubmitted && (this.AddingMembers.controls[i].get('tbAnnualIncome')?.errors)"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="this.AddingMembers.controls[i].get('tbAnnualIncome')?.errors?.required">
                                                    Please enter Annual Income</div>
                                            </div>
                                            </td>
                                            <td>
                                              
                                                <input type="text" class="inputCls value" readonly appBlockCopyPaste name="tbGovernmentPensioner" id="tbGovernmentPensioner" [ngClass]="{ 'is-invalid':familySubmitted && this.AddingMembers.controls[i].get('tbGovernmentPensioner')?.errors }"   formControlName = "tbGovernmentPensioner" >
                                                <div *ngIf="familySubmitted && (this.AddingMembers.controls[i].get('tbGovernmentPensioner')?.errors)"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="this.AddingMembers.controls[i].get('tbGovernmentPensioner')?.errors?.required ">
                                                    Please Enter Governament Pensioner / Not</div>
                                            </div>
                                            </td>
                                            <td>
                                               
                                                <input type="text" class="inputCls value" readonly appBlockCopyPaste name="tbGovernmentCategory" id="tbGovernmentCategory" [ngClass]="{ 'is-invalid':familySubmitted && this.AddingMembers.controls[i].get('tbGovernmentCategory')?.errors }"   formControlName = "tbGovernmentCategory">
                                               </td>
                                           <td>
                                              
                                                <input type="text" class="inputCls value" maxlength="8" readonly  appBlockCopyPaste name="tbPayingIncome" id="tbPayingIncome"   formControlName = "tbPayingIncome"  [ngClass]="{ 'is-invalid':familySubmitted && this.AddingMembers.controls[i].get('tbPayingIncome')?.errors }">
                                                <div *ngIf="familySubmitted && (this.AddingMembers.controls[i].get('tbPayingIncome')?.errors)"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="this.AddingMembers.controls[i].get('tbPayingIncome')?.errors?.required">
                                                    Please Enter Income</div>
                                            </div>
                                            </td>
                                            <td>
                                                <input type="text" class="inputCls value" readonly   appBlockCopyPaste name="tbFourWheeler" id="tbFourWheeler"   formControlName = "tbFourWheeler"  [ngClass]="{ 'is-invalid':familySubmitted && this.AddingMembers.controls[i].get('tbFourWheeler')?.errors }">
                                                <div *ngIf="familySubmitted && (this.AddingMembers.controls[i].get('tbFourWheeler')?.errors)"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="this.AddingMembers.controls[i].get('tbFourWheeler')?.errors?.required">
                                                    Please Enter Four Wheeler Deatils</div>
                                            </div>
                                            </td>


                                            <td>
                                               
                                                <input type="text" class="inputCls value" maxlength="10" readonly   appBlockCopyPaste name="tbFourWheelerNo" id="tbFourWheelerNo"   formControlName = "tbFourWheelerNo"  [ngClass]="{ 'is-invalid':familySubmitted && this.AddingMembers.controls[i].get('tbFourWheelerNo')?.errors }">
                                                <div *ngIf="familySubmitted && (this.AddingMembers.controls[i].get('tbFourWheelerNo')?.errors)"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="this.AddingMembers.controls[i].get('tbFourWheelerNo')?.errors?.required">
                                                    Please Enter Four Wheeler Deatils</div>
                                            </div>
                                            </td>
                                            <td>
                                               <input type="text" class="inputCls value" readonly  appBlockCopyPaste name="tbFourWheelerNumbers" id="tbFourWheelerNumbers"   formControlName = "tbFourWheelerNumbers"  [ngClass]="{ 'is-invalid':familySubmitted && this.AddingMembers.controls[i].get('tbFourWheelerNumbers')?.errors }">
                                                <div *ngIf="familySubmitted && (this.AddingMembers.controls[i].get('tbFourWheelerNumbers')?.errors)"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="this.AddingMembers.controls[i].get('tbFourWheelerNumbers')?.errors?.required">
                                                    Please Enter Four Wheeler Numbers</div>
                                            </div>
                                            </td>

                                            
                                           
                                            <td>
                                              
                                                <input type="text" class="inputCls value" maxlength="6"
                                                 appBlockCopyPaste name="tbWetLand" id="tbWetLand"  (keyup)="TotalLandValue($event,'wet',i)" formControlName = "tbWetLand"  (keypress)="cammamatch($event)" [ngClass]="{ 'is-invalid':familySubmitted && this.AddingMembers.controls[i].get('tbWetLand')?.errors }">
                                                <div *ngIf="familySubmitted && (this.AddingMembers.controls[i].get('tbWetLand')?.errors)"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="this.AddingMembers.controls[i].get('tbWetLand')?.errors?.required">
                                                    Please enter Wet Land details</div>
                                                    <div *ngIf="!this.AddingMembers.controls[i].get('tbWetLand')?.errors?.required && this.AddingMembers.controls[i].get('tbWetLand')?.errors?.pattern">Please enter valid wetland</div>
                                            </div>
                                            </td>
                                            <td>
                                               
                                                <input type="text" class="inputCls value" maxlength="6"  appBlockCopyPaste name="tbDryLand" id="tbDryLand"  (keypress)="cammamatch($event)" (keyup)="TotalLandValue($event,'dry',i)" formControlName = "tbDryLand"  [ngClass]="{ 'is-invalid':familySubmitted && this.AddingMembers.controls[i].get('tbDryLand')?.errors }">
                                                <div *ngIf="familySubmitted && (this.AddingMembers.controls[i].get('tbDryLand')?.errors)"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="this.AddingMembers.controls[i].get('tbDryLand')?.errors?.required">
                                                    Please enter Dry Land details</div>
                                                    <div *ngIf="!this.AddingMembers.controls[i].get('tbDryLand')?.errors?.required && this.AddingMembers.controls[i].get('tbDryLand')?.errors?.pattern">Please enter valid dryland</div>
                                            </div>
                                            </td>
                                            <td>
                                               
                                                <input type="text" class="inputCls value" appBlockCopyPaste name="tbLand" id="tbLand"  readonly  formControlName = "tbLand"  [ngClass]="{ 'is-invalid':familySubmitted && this.AddingMembers.controls[i].get('tbLand')?.errors }">
                                                <div *ngIf="familySubmitted && (this.AddingMembers.controls[i].get('tbLand')?.errors)"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="this.AddingMembers.controls[i].get('tbLand')?.errors?.required">
                                                    Please enter Land details</div>
                                            </div>
                                            </td>
                                            <td>
                                              
                                                <input type="text" maxlength="6"  class="inputCls value" appBlockCopyPaste name="tbPropertyTax" id="tbPropertyTax"  (keypress)="cammamatch($event)"  formControlName = "tbPropertyTax"  [ngClass]="{ 'is-invalid':familySubmitted && this.AddingMembers.controls[i].get('tbPropertyTax')?.errors }">
                                                <div *ngIf="familySubmitted && (this.AddingMembers.controls[i].get('tbPropertyTax')?.errors)"
                                                class="invalid-feedback">
                                                <div
                                                    *ngIf="this.AddingMembers.controls[i].get('tbPropertyTax')?.errors?.required">
                                                    Please enter Property</div>
                                                    <div *ngIf="!this.AddingMembers.controls[i].get('tbPropertyTax')?.errors?.required && this.AddingMembers.controls[i].get('tbPropertyTax')?.errors?.pattern">Please enter valid property tax</div>
                                            </div>
                                            </td>
                                            <td> <div>
                                                <button class="btn btn-primary mr-3 add-new" (click)="deleteMember(i,$event)">Delete</button>
                                            </div></td>                                                                                  
    
    
    </tr>
                                         
                                        </tbody>
                                        </table>
                                        <div class="mandatoryCls" *ngIf="duplicatesRecordsinMember">Duplicate Aadhaar Entries!</div>
                                            <div class="text-center mb-3">
                        <button name="addnewrow" class="btn btn-primary mr-3 add-new" (click)="addNewMember()"><i class="fa fa-plus"></i>Add New Member</button>
                      
                        </div>
    </form>
    </div>
    </div>
    </div> -->
                                <!-- =============== Member Addition End ============== -->

                                <!-- ========== Member Addition below 5 years ============ -->

<!-- commented member addition as part of Election code of conduct -->
                                <!-- <div class="row mb-3">
                                    <div class="col">
                                        <div class="tab table-responsive">
                                  <form [formGroup]="Child">
                                    <hr class="hrCls mt-4">
                                    <h6 class="mb-3">Add Family Members Below Five Years Of Age</h6>
                                    <table class="tableCls" style="width :1800px">
                                        <thead>
                                            <tr>
                                                <th>SNo</th>
                                                <th>Aadhar No<span class="mandatoryCls">*</span> </th>
                                                <th>DOB<span class="mandatoryCls">*</span> </th>
                                                <th> Age<span class="mandatoryCls">*</span> </th>
                                                <th> Relation with the Family Head<span class="mandatoryCls">*</span> </th>
                                                <th> Member <br/> Name<span class="mandatoryCls">*</span> </th>
                                                <th> Telugu Name<span class="mandatoryCls">*</span> </th>
                                                <th> Gender<span class="mandatoryCls">*</span> </th>                                               
                                                <th> <span class="mandatoryCls"></span> </th>
                                            </tr>        
                                            </thead>
        
                                            <tbody formArrayName="AddingMembersDataBelowFive">
                                                <tr [formGroupName]="i"
                                                *ngFor="let child of AddingChild.controls; let i = index;">
                                                <td class="serial">{{i+1}}</td>           
                                                <td>                                            
        
                                                    <input type="text" class="inputCls value" name="tbAadhar"
                                                    id="tbAadhar" appBlockCopyPaste  required
                                                    [ngClass]="{ 'is-invalid':childSubmitted && this.AddingChild.controls[i].get('tbAadhar')?.errors }"
                                                    formControlName="tbAadhar" maxlength="12" minlength="12"
                                                    numbersOnly  
                                                    (keyup)="onKeyupChild($event,i)" >
                                                <div *ngIf="childSubmitted && this.AddingChild.controls[i].get('tbAadhar')?.errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="this.AddingChild.controls[i].get('tbAadhar')?.errors?.required  || this.AddingChild.controls[i].get('tbAadhar')?.errors?.whiteSpaceValidate">
                                                        Please enter Aadhaar Number</div>
                                                    <div
                                                        *ngIf="this.AddingChild.controls[i].get('tbAadhar')?.errors?.aadharValidate  || this.AddingChild.controls[i].get('tbAadhar')?.errors?.minlength">
                                                        Please enter valid Aadhaar Number</div>
                                                </div>
        
        
                                                </td>
        
        
        
                                                <td>
                                                   
                                                    <div class="input-group" style="position:initial !important">
                                                        <input class="calendarCls form-control"
                                                            placeholder="dd/mm/yyyy" name="d" ngbDatepicker
                                                            #d="ngbDatepicker" [minDate]="minDate" 
                                                            [maxDate]="maxDate" 
                                                             formControlName="tbDob"
                                                             (change) = "ChilddateChange($event , i)" 
                                                        (dateSelect) = "ChilddateChangeToggle($event , i)"
                                                            required                                                            
                                                            [attr.readonly]="true" 
                                                            appBlockCopyPaste
                                                            [ngClass]="{ 'is-invalid':childSubmitted && this.AddingChild.controls[i].get('tbDob')?.errors }">
                                                    
                                                    </div>
                                                    <div *ngIf="childSubmitted && this.AddingChild.controls[i].get('tbDob')?.errors"
                                                        class="invalid-feedback">
                                                        <div
                                                            *ngIf="this.AddingChild.controls[i].get('tbDob')?.errors?.required">
                                                            Please select Date of Birth</div>
                                                    </div>
        
                                                </td>
                                                <td>
                                                   
        
                                                    <input type="text" class="inputCls" appBlockCopyPaste
                                                    name="tbAge" id="tbAge" formControlName="tbAge"
                                                    maxlength="3" numbersOnly readonly >
                                                    <div *ngIf="childSubmitted && this.AddingChild.controls[i].get('tbAge')?.errors"
                                                    class="invalid-feedback">
                                                    <div
                                                        *ngIf="this.AddingChild.controls[i].get('tbAge')?.errors?.required">
                                                        Age is required</div>
                                                </div>
                                                </td>
                                                <td>
                                                   
    
                                                    <select class="selectCls" name="tbRelation" id="tbRelation"
                                                     formControlName="tbRelation" required 
                                                    [ngClass]="{ 'is-invalid':childSubmitted && (AddingChild.controls[i].get('tbRelation')?.errors || this.AddingChild.controls[i].get('tbRelation')?.value === 'Select') }">
                                                    <option [ngValue]="null" disabled>Select</option>
                                                    <ng-container *ngIf="this.AddingChild.controls[i].get('tbGender')?.value ==='MALE' ">
                                                                
                                                        <option *ngFor="let relation of memberMaleRelations"
                                                        [ngValue]="relation.name">{{relation.name}}</option>
                                                    </ng-container>
                                                    <ng-container *ngIf="this.AddingChild.controls[i].get('tbGender')?.value ==='FEMALE' ">
                                                        <option *ngFor="let relation of memberFemaleRelations"
                                                        [ngValue]="relation.name">{{relation.name}}</option>
                                                    </ng-container>
                                                    <ng-container *ngIf="this.AddingChild.controls[i].get('tbGender')?.value ==='TRANSGENDER' ">
                                                        <option *ngFor="let relation of memberRelations"
                                                        [ngValue]="relation.name">{{relation.name}}</option>
                                                    </ng-container>
                                                </select>
                                                <div *ngIf="childSubmitted && (this.AddingChild.controls[i].get('tbRelation')?.errors || this.AddingChild.controls[i].get('tbRelation')?.value === 'Select')"
                                                    class="selectError">
                                                    <div
                                                        *ngIf="this.AddingChild.controls[i].get('tbRelation')?.errors?.required || this.AddingChild.controls[i].get('tbRelation')?.value === 'Select'">
                                                        Please select Relation</div>
                                                </div>
                                                </td>
                                                <td>
                                                   
                                             
                                                    <input type="text"  name="tbName" appBlockCopyPaste formControlName="tbName" class="inputCls"   maxlength="50" required readonly
                                                    [ngClass]="{ 'is-invalid':childSubmitted && this.AddingChild.controls[i].get('tbName')?.errors }">
                                                    <div *ngIf="childSubmitted && this.AddingChild.controls[i].get('tbName')?.errors" class="invalid-feedback">
                                                        <div *ngIf="this.AddingChild.controls[i].get('tbName')?.errors?.required || this.AddingChild.controls[i].get('tbName')?.errors?.whiteSpaceValidate">Name is required in English</div>
                                                        <div *ngIf="this.AddingChild.controls[i].get('tbName')?.errors?.pattern">Please enter valid Name in English</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="text" class="inputCls value" maxlength="50" minlength="2"
                                                     name="tbTeluguName" id="tbTeluguName" (keypress)="matcher($event)" required [ngClass]="{ 'is-invalid':childSubmitted && this.AddingChild.controls[i].get('tbTeluguName')?.errors }" formControlName = "tbTeluguName">
                                                    <div *ngIf="childSubmitted && this.AddingChild.controls[i].get('tbTeluguName')?.errors" class="invalid-feedback">
                                                        <div *ngIf="this.AddingChild.controls[i].get('tbTeluguName')?.errors?.required">Please enter Telugu Name</div>
                                                        <div *ngIf="this.AddingChild.controls[i].get('tbTeluguName')?.errors?.minlength">Please enter valid Telugu Name</div>
                                                    </div>
                                                </td>
                                            
                                                <td>
                                                 
    
                                                    <select class="selectCls" name="tbGender" id="tbGender" 
                                                    formControlName="tbGender"  required [attr.disabled]= "true"
                                                    
                                                    [ngClass]="{ 'is-invalid':childSubmitted && (AddingChild.controls[i].get('tbGender')?.errors || this.AddingChild.controls[i].get('tbGender')?.value === 'Select') }">
                                                    <option [ngValue]="null" disabled>Select</option>
                                                    <option *ngFor="let gender of genders"
                                                        [ngValue]="gender.name">{{gender.name}}
                                                </select>
                                                <div *ngIf="childSubmitted && (this.AddingChild.controls[i].get('tbGender')?.errors || this.AddingChild.controls[i].get('tbGender')?.value === 'Select')"
                                                    class="selectError">
                                                    <div
                                                        *ngIf="this.AddingChild.controls[i].get('tbGender')?.errors?.required || this.AddingChild.controls[i].get('tbGender')?.value === 'Select'">
                                                        Please select Gender</div>
                                                </div>
    
                                                </td>
                                              
                                               
                                                <td> <div>
                                                    <button class="btn btn-primary mr-3 add-new" (click)="deleteChild(i)">Delete</button>
                                                </div></td>                                                                                  
        
        
        </tr>
                                             
                                            </tbody>
                                            </table>
                                            <div class="mandatoryCls" *ngIf="duplicateRecordsinChild">Duplicate Aadhaar Entries!</div>
                                                <div class="text-center mb-3">
                            <button name="addnewrow" class="btn btn-primary mr-3 add-new" (click)="addChild()"><i class="fa fa-plus"></i>Add New Member</button>
                          
                            </div>
        </form>
        </div>
        </div>
        </div> -->

                                <!--  ========== Member Addition below 5 years End ============== -->
    
                            <!-- <hr class="hrCls mt-4"> -->
                            <!--  <hr class="hrCls mt-4">
                            <div class="subHeadingCls mb-3">Address</div>
                            <div class="row mb-3">
                                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <label class="labelCls">District:<span class="mandatoryCls">*</span></label>
                                    <select class="selectCls" formControlName="district" required
                                        (change)="onDistrictChange($event.target,'district')"
                                        [ngClass]="{ 'is-invalid':submitted && (f.district?.errors || f.district.value === 'Select')}">
                                        <option [ngValue]="null" disabled>Select</option>
                                        <option *ngFor="let district of districts" [ngValue]="district">
                                            {{district.name}}</option>
                                    </select>
                                    <div *ngIf="submitted && (f.district?.errors || f.district.value === 'Select')"
                                        class="selectError">
                                        <div *ngIf="f.district?.errors?.required || f.district.value === 'Select'">
                                            Please select district</div>
                                    </div>
                                </div>
                                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <label class="labelCls strong">Mandal/Circle:<span
                                            class="mandatoryCls">*</span></label>
                                    <select class="selectCls" formControlName="mandal" required
                                        (change)="onMandalChange($event.target,'mandal')"
                                        [ngClass]="{ 'is-invalid':submitted && (f.mandal?.errors || f.mandal.value === 'Select')}">
                                        <option [ngValue]="null" disabled>Select</option>
                                        <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && (f.mandal?.errors || f.mandal.value === 'Select')"
                                        class="selectError">
                                        <div *ngIf="f.mandal?.errors?.required || f.mandal.value === 'Select'">Please
                                            select mandal</div>
                                    </div>
                                </div>
                                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <label class="labelCls">Village/Ward:<span class="mandatoryCls">*</span></label>
                                    <select class="selectCls" formControlName="villageWard" required
                                        [ngClass]="{ 'is-invalid':submitted && (f.villageWard?.errors || f.villageWard.value === 'Select')}">
                                        <option [ngValue]="null" disabled>Select</option>
                                        <option *ngFor="let village of villages" [ngValue]="village">{{village.name}}
                                        </option>
                                    </select>
                                    <div *ngIf="submitted && (f.villageWard?.errors || f.villageWard.value === 'Select')"
                                        class="selectError">
                                        <div
                                            *ngIf="f.villageWard?.errors?.required || f.villageWard.value === 'Select'">
                                            Please select village</div>
                                    </div>
                                </div>
                                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <label class="labelCls">Door No: <span class="mandatoryCls">*</span></label>
                                    <input type="text" name="doorNo" class="inputCls" required maxlength="15"
                                        appBlockCopyPaste formControlName="doorNo"
                                        [ngClass]="{ 'is-invalid':submitted && f.doorNo.errors }">
                                    <div *ngIf="submitted && f.doorNo.errors" class="invalid-feedback">
                                        <div *ngIf="f.doorNo.errors?.required || f.doorNo.errors?.whiteSpaceValidate">
                                            Please enter Door No</div>
                                        <div *ngIf="f.doorNo.errors?.pattern">Please enter valid Door No</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <label class="labelCls">Locality/Land Mark: <span
                                            class="mandatoryCls">*</span></label>
                                    <input type="text" name="locality" class="inputCls" required maxlength="50"
                                        formControlName="locality" appBlockCopyPaste
                                        [ngClass]="{ 'is-invalid':submitted && f.locality.errors }">
                                    <div *ngIf="submitted && f.locality.errors" class="invalid-feedback">
                                        <div
                                            *ngIf="f.locality.errors?.required  || f.locality.errors?.whiteSpaceValidate">
                                            Please enter locality</div>
                                        <div *ngIf="f.locality.errors?.pattern">Please enter valid Street Name</div>
                                    </div>
                                </div>
                                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <label class="labelCls">Pin Code: <span class="mandatoryCls">*</span></label>
                                    <input type="text" name="pinCode" formControlName="pinCode" class="inputCls"
                                        appBlockCopyPaste required
                                        [ngClass]="{ 'is-invalid':submitted && f.pinCode.errors }" numbersOnly
                                        maxlength="6">
                                    <div *ngIf="submitted && f.pinCode.errors" class="invalid-feedback">
                                        <div *ngIf="f.pinCode.errors?.required">Please enter Pin Code</div>
                                        <div *ngIf="f.pinCode.errors?.pattern">Please enter valid Pin Code</div>
                                    </div>
                                </div>
                            </div> -->
                            <hr class="hrCls mt-4">
                            <div class="row mb-3">
                                <div class="col">
                                    <div class="tab">
                                        <h6 class="mb-3">Employment Details : (Step 1)</h6>
                                        <table class="tableCls">
                                            <thead>
                                                <tr>
                                                    <th>Sr No</th>
                                                    <th>Member Name</th>
                                                    <th>Is Government/Pensioner</th>
                                                    <th>Employee Category</th>
                                                </tr>
                                            </thead>
                                            <tbody formArrayName="familyDetailsTableData">
                                                <tr [formGroupName]="i"
                                                    *ngFor="let Data of familyDetailsTableDatas.controls; let i = index">
                                                    <td class="serial">{{i +1}}</td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbName')?.value}}
                                                    </td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbGovernmentPensioner')?.value}}
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbGovernmentCategory')?.value ? this.familyDetailsTableDatas.controls[i].get('tbGovernmentCategory')?.value : '-'}}</td>
                                                   
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                            <hr class="hrCls mt-4">
                            <div class="row mb-3">
                                <div class="col">
                                    <div class="tab">
                                        <h6 class="mb-3">Family Income Details : (Step 2)</h6>
                                        <table class="tableCls">
                                            <thead>
                                                <tr>
                                                    <th>Sr No</th>
                                                    <th>Member Name</th>
                                                    <th>Is Paying Income Tax</th>
                                                    <th>Annual Income(In Rs.)</th>
                                                </tr>
                                            </thead>
                                            <tbody formArrayName="familyDetailsTableData">
                                                <tr [formGroupName]="i"
                                                    *ngFor="let Data of familyDetailsTableDatas.controls; let i = index">
                                                    <td class="serial">{{i +1}}</td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbName')?.value}}
                                                    </td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbPayingIncome')?.value}}
                                                    </td>
                                                    <td>
                                                        <input type="text" name="tbAnnualIncome" appBlockCopyPaste
                                                            formControlName="tbAnnualIncome"
                                                            [attr.disabled] = "(this.familyDetailsTableDatas.controls[i].get('iS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH' ) ? '' : null"       
                                                            (change)="totalIncomeCalculator($event)"
                                                            class="inputCls value" numbersOnly maxlength="8"
                                                            [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbAnnualIncome')?.errors }">
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbAnnualIncome')?.errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="this.familyDetailsTableDatas.controls[i].get('tbAnnualIncome')?.errors?.required">
                                                                Please enter annual income</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12  ">
                                    <label class="labelCls">Total Family Income: </label>
                                    <input type="text" name="totalIncome" appBlockCopyPaste class="inputCls "
                                        numbersOnly formControlName="totalIncome" readonly>
                                </div>
                            </div>
                            <hr class="hrCls mt-4">
                            <div class="row mb-3">
                                <div class="col">
                                    <div class="tab">
                                        <h6 class="mb-3">Details of the land holding by the family : (Step 3)</h6>
                                        <table class="tableCls">
                                            <thead>
                                                <tr>
                                                    <th>Sr No</th>
                                                    <th>Member Name</th>
                                                    <th>Land Status</th>
                                                    <th>Wet Land(in acres)</th>
                                                    <th>Dry Land(in acres)</th>
                                                </tr>
                                            </thead>
                                            <tbody formArrayName="familyDetailsTableData">
                                                <tr [formGroupName]="i"
                                                    *ngFor="let Data of familyDetailsTableDatas.controls; let i = index">
                                                    <td class="serial">{{i +1}}</td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbName')?.value}}
                                                    </td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbLandStatus')?.value}}
                                                    </td>
                                                    <td>
                                                        <input type="text" name="tbWetLand" appBlockCopyPaste
                                                            formControlName="tbWetLand"
                                                            (change)="totalWetLandCalculator($event)" (keypress)="cammamatch($event)"
                                                            class="inputCls value" maxlength="6"
                                                            [attr.disabled] = "(this.familyDetailsTableDatas.controls[i].get('tbWetLandStatus')?.value === 'YES' || (this.familyDetailsTableDatas.controls[i].get('iS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH' ))  ? '' : null"
                                                            [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.errors }">
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.errors?.required">
                                                                Please enter wetland</div>
                                                            <div
                                                                *ngIf="!this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.errors?.required && this.familyDetailsTableDatas.controls[i].get('tbWetLand')?.errors?.pattern">
                                                                Please enter valid wetland</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input type="text" name="tbDryLand" appBlockCopyPaste
                                                            formControlName="tbDryLand"
                                                            (change)="totalDryLandCalculator($event)" (keypress)="cammamatch($event)"
                                                            [attr.disabled] = "(this.familyDetailsTableDatas.controls[i].get('tbDryLandStatus')?.value === 'YES' || (this.familyDetailsTableDatas.controls[i].get('iS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH' ))? '' : null"
                                                            class="inputCls value" maxlength="6"
                                                            [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.errors }">
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.errors?.required">
                                                                Please enter dryland</div>
                                                            <div
                                                                *ngIf="!this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.errors?.required && this.familyDetailsTableDatas.controls[i].get('tbDryLand')?.errors?.pattern">
                                                                Please enter valid dryland</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12  ">
                                    <label class="labelCls">Family Total Wetland: </label>
                                    <input type="text" name="totalWetLand" appBlockCopyPaste class="inputCls "
                                        numbersOnly formControlName="totalWetLand" maxlength="6" readonly>
                                </div>
                                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12  ">
                                    <label class="labelCls">Family Total Dryland: </label>
                                    <input type="text" name="totalDryLand" appBlockCopyPaste class="inputCls "
                                        numbersOnly formControlName="totalDryLand" maxlength="6" readonly>
                                </div>
                            </div>
                            <hr class="hrCls mt-4">
                            <div class="row mb-3">
                                <div class="col">
                                    <div class="tab">
                                        <h6 class="mb-3">Urban Property Details : (Step 4)</h6>
                                        <table class="tableCls">
                                            <thead>
                                                <tr>
                                                    <th>Sr No</th>
                                                    <th>Member Name</th>
                                                    <th>Is Paying Property Tax</th>
                                                    <th>Total no. of Sq.ft for which tax is paid</th>
                                                </tr>
                                            </thead>
                                            <tbody formArrayName="familyDetailsTableData">
                                                <tr [formGroupName]="i"
                                                    *ngFor="let Data of familyDetailsTableDatas.controls; let i = index">
                                                    <td class="serial">{{i +1}}</td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbName')?.value}}
                                                    </td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbPayingPropertyTax')?.value}}
                                                    </td>
                                                    <td>
                                                        <input type="text" name="tbPropertyTax" appBlockCopyPaste
                                                            formControlName="tbPropertyTax"
                                                            (change)="totalPropertyCalculator($event)" (keypress)="cammamatch($event)"
                                                            class="inputCls value"  maxlength="6"
                                                            [attr.disabled] = "(this.familyDetailsTableDatas.controls[i].get('tbPayingPropertyTax')?.value === 'YES' || (this.familyDetailsTableDatas.controls[i].get('iS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH' ))? '' : null"
                                                            [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.errors }">
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.errors"
                                                            class="invalid-feedback">
                                                            <div
                                                                *ngIf="this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.errors?.required">
                                                                Please enter property tax</div>
                                                                <div *ngIf="!this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.errors?.required && this.familyDetailsTableDatas.controls[i].get('tbPropertyTax')?.errors?.pattern">Please enter valid property tax</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-center">
                                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12  ">
                                    <label class="labelCls">Family Total Property Tax: </label>
                                    <input type="text" name="totalPropertyTax" appBlockCopyPaste class="inputCls "
                                        numbersOnly formControlName="totalPropertyTax" maxlength="6" readonly>
                                </div>
                            </div>
                            <hr class="hrCls mt-4">
                            <div class="row mb-3">
                                <div class="col">
                                    <div class="tab">
                                        <h6 class="mb-3">No. of cars owned by the family: (Step 5)</h6>
                                        <table class="tableCls">
                                            <thead>
                                                <tr>
                                                    <th>Sr No</th>
                                                    <th>Member Name</th>
                                                    <th>Is Four Wheeler</th>
                                                    <th>Four Wheeler</th>
                                                    <th>Four Wheeler Numbers</th>
                                                </tr>
                                            </thead>
                                            <tbody formArrayName="familyDetailsTableData">
                                                <tr [formGroupName]="i"
                                                    *ngFor="let Data of familyDetailsTableDatas.controls; let i = index">
                                                    <td class="serial">{{i +1}}</td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbName')?.value}}
                                                    </td>
                                                    <td>{{this.familyDetailsTableDatas.controls[i].get('tbFourWheeler')?.value}}
                                                    </td>
                                                    <td>
                                                        <select class="selectCls" name="tbFourWheelerNo"
                                                            id="tbFourWheelerNo" formControlName="tbFourWheelerNo"
                                                            (change)="totalFourWheeler(this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value,i)" [attr.disabled] = "(this.familyDetailsTableDatas.controls[i].get('tbFourWheeler')?.value === 'YES' || (this.familyDetailsTableDatas.controls[i].get('iS_LIVING_WITHFAMILY')?.value?.toUpperCase()?.trim() === 'DEATH' )) ? '' : null"
                                                            [ngClass]="{ 'is-invalid':submitted && (familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.errors || this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value === 'Select') }">
                                                            <option [ngValue]="null" disabled>Select</option>
                                                            <option value="0">0</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                        </select>
                                                        <!-- <div *ngIf="submitted && (this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.errors || this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value === 'Select')" class="selectError">
                                                    <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.errors?.required || this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value === 'Select'">Please select number of four wheeler</div>
                                                </div> -->
                                                    </td>
                                                    <!-- <td>{{this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.value}}</td>
                                                     -->
                                                     <td>
                                                        <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbFourWheeler')?.value === 'YES' || this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value === 0 || this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value === null  || this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.value  === 'NO'">
                                                            {{this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.value}}
                                                        </div>
                                                    <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbFourWheeler')?.value === 'NO' && this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNo')?.value > 0  &&  this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.value  !== 'NO'">
                                                        <input type="text"  maxlength="15"
                                                        name="tbFourWheelerNumbers" appBlockCopyPaste formControlName="tbFourWheelerNumbers" class="inputCls value" 
                                                        [ngClass]="{ 'is-invalid':submitted && this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.errors }">
                                                        <div *ngIf="submitted && this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.errors" class="invalid-feedback">
                                                            <div *ngIf="this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.errors?.required">Please enter FourWheeler Numbers</div>
                                                            <div *ngIf="!this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.errors?.required && this.familyDetailsTableDatas.controls[i].get('tbFourWheelerNumbers')?.errors?.pattern">Please enter valid FourWheeler Numbers</div>
                                                        </div>
                                                    </div>
                                                    </td>   
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <hr class="hrCls mt-4">
                            <div class="row mb-3">
                                <div class="col col-lg-2 col-md-6 col-sm-12 col-xs-12 subHeadingCls">Document Upload:</div>
                                <div class="col col-lg-10 col-md-6 col-sm-12 col-xs-12 regDocLink"><a download href="assets/GUIDELINES.pdf">Guidelines for scanning the Family Photo</a></div>
                                </div>
                                    <div class="row mb-3">
                                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                   
                                    <label class="labelCls" for="familyPhoto">Family Photo(Supported docs are .jpg, .jpeg & .png, Size in 200KB) :<span class="mandatoryCls">*</span></label>
                                    <label class="note">Note: 'Please upload image with resolution (640 x 480) or lower..'</label>
                                    <div class="input-group mb-3">
                                       
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" formControlName="familyPhoto" required
                                                id="familyPhoto" accept=".jpg, .jpeg , .png" 
                                                [ngClass]="{ 'is-invalid':submitted && f.familyPhoto.errors }" 
                                                (change)="onFamilyPhotoSelected($event)" >
                                            <label class="custom-file-label" #familyPhotoLabel for="familyPhoto"><i
                                                    class="fas fa-search"></i>No File choosen</label>
                                        </div>
                                        <div class="clearCls"
                                            (click)="clearFileUpload('familyPhoto', 'familyPhotoLabel')"
                                            *ngIf="(f.familyPhoto.value || isPhoto === true)"></div>
                                            <!-- <canvas #canvas style="display: none;"></canvas> -->
                                    </div>
                                    <div *ngIf="submitted && f.familyPhoto.errors" class="selectError">
                                        <div *ngIf="f.familyPhoto.errors.required">Please upload Family Photo</div>
                                        <div
                                            *ngIf="f.familyPhoto?.errors?.inValidExt && !f.familyPhoto.errors.required ">
                                            Please upload the file in PNG/JPG/JPEG Format</div>
                                        <div
                                            *ngIf="f.familyPhoto.errors?.inValidSize && !f.familyPhoto.errors.required && !f.familyPhoto.errors.inValidExt">
                                            File size exceeding 200KB</div>
                                          
                                    </div>
                                </div>
                                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    

                                        <div *ngIf="showImg && imgSrc !== '' ">
                                            <img [src]="imgSrc" class="mt-3 img-fluid" width="200" height="140">
                                        </div>

                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <label class="labelCls" for="aadharCard">Aadhaar Card :</label>
                                    <div class="input-group mb-3">
                                        <div class="custom-file">
                                            <input type="file" class="custom-file-input" formControlName="aadharCard"
                                                id="aadharCard" accept=".pdf"
                                                [ngClass]="{ 'is-invalid':submitted && f.aadharCard.errors }"
                                                (change)="onAadharCardSelected($event)">
                                            <label class="custom-file-label" #aadharCardLabel for="aadharCard"><i
                                                    class="fas fa-search"></i>No File choosen</label>
                                        </div>
                                        <div class="clearCls" (click)="clearFileUpload('aadharCard', 'aadharCardLabel')"
                                            *ngIf="f.aadharCard.value"></div>
                                    </div>
                                    <div *ngIf="submitted && f.aadharCard.errors" class="selectError">
                                        <div *ngIf="f.aadharCard?.errors?.inValidExt && !f.aadharCard.errors.required ">
                                            Please upload the file in PDF Format</div>
                                        <div
                                            *ngIf="f.aadharCard.errors?.inValidSize && !f.aadharCard.errors.required && !f.aadharCard.errors.inValidExt">
                                            File size exceeding 1MB</div>
                                    </div>
                                </div>
                            </div>
                            <hr class="hrCls mt-4">
                            <div class="subHeadingCls mb-3">Declaration</div>
                            <div class="row mb-3">
                                <div class="ml-4 col-sm-9">
                                    <input class="form-check-input " formControlName="familyForm" (change)="changeConsent()" required
                                        type="checkbox" value="" id="familyForm"
                                        [ngClass]="{ 'is-invalid':submitted && f.familyForm.errors }">
                                    <label class="labelCls" for="familyForm">The above information is true to the best
                                        of my knowledge. I agree to share my Aadhaar details of self and family with
                                        Government of Andhra Pradesh. I am aware that declaration of wrong information
                                        will entail any disciplinary action against me.:<span
                                            class="mandatoryCls">*</span> </label>
                                    <div *ngIf="submitted && f.familyForm.errors" class="invalid-feedback">
                                        <div *ngIf="f.familyForm.errors.required">Please Check Note</div>
                                    </div>
                                </div>
                            </div>
                            <div class="btnHolder">
                                <button class="greenBtnCls float-right" (click)="submit()">Show Payment</button>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </nav>
            <div [ngbNavOutlet]="nav"></div>
        </form>
    </div>
</div>