<div class="mainPageContainer mr-3">
    <button class=" mt-3 float-right greenBtnCls homebtn mr-3" (click)="goToHome()">Home</button>
           <form [formGroup]="ViewTransactions">
              <hr class="hrCls mt-4">
                <div class="row mb-3">
                  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                   <label class="labelCls">Transaction Id / Application No: </label>
                      <input type="text" name="transactionID" class="inputCls" formControlName="transactionID" maxlength="50" (keyup)="clearGrid()">
                         <div *ngIf="submitted && f.transactionID.errors" class="selectError">
                            <div *ngIf="f.transactionID.errors?.required || f.transactionID.errors.whiteSpaceValidate">Please
                                Enter transaction id</div>
                            </div>
                </div>
                </div>
               <hr class="hrCls mt-4">
            <div class="row mb-3 ">
                <div class="btnHolder text-center col-12">
                    <button class="btn btn-primary mx-auto d-block" (click)="submit()">Application Details</button>
                </div>
            </div>
        <div *ngIf="showHideTable">
            <div class="row" id="printData">
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <table class="tableCls">
                            <thead>
                                <tr>
                                    <th>Transaction ID</th>
                                    <th>Service Name</th>
                                    <th class="widthTD">Citizen Name</th>
                                    <th>Transaction Date</th>
                                    <th>Payment<br> Mode</th>
                                    <th>Amount</th>
                                    <th *ngIf="reqData?.serviceName != 'Integrated Certificate-SuoMoto'" class="btnHide">View<br>Certificate</th>
                                </tr>
                            </thead>

                            <tbody *ngIf="showTable">
                            <tr>
                                    <td>{{ reqData?.applicationNo }}</td>
                                    <td>{{ reqData?.serviceName }}</td>
                                    <td>{{ reqData?.citizenName }}</td>
                                    <td>{{ reqData?.transactionDate | date: 'dd/MM/yyyy h:mm:ss a'}}</td>
                                    <td>{{ reqData?.paymentMode }}</td>
                                    <td>{{ reqData?.amount }}</td>
                                    <td *ngIf="reqData?.serviceName != 'Integrated Certificate-SuoMoto'"><a class ="textDcor" [(ngModel)]="transactionID" (click)="getDashBoardForCertificatePreview()">Preview</a></td>
                            </tr>
                            </tbody> 

                            <tbody *ngIf= "!showTable">
                                <tr><td [attr.colspan]="noOfCols" class="text-center">No records found.</td></tr>
                             </tbody>
                            </table>
                </div>
            </div>
            
            <div class="row mb-3 btnMargin">
                <div *ngIf="showTable" class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Reason for Revoke:</label>
                 <input type="text" name="reason" class="inputCls" formControlName="reason" maxlength="100" required>
                      <div *ngIf="submitted && f.reason.errors" class="selectError">
                          <div *ngIf="f.reason.errors?.required || f.reason.errors.whiteSpaceValidate">Please
                              Enter reason for revoke</div>
                      </div>
                 </div>
            </div>
            
            <div class="row mb-3 btnMargin ">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div class="btnHolder text-center col-12">
                    <button *ngIf="showTable" class="btn btn-primary mx-auto d-block" (click)="revoke()">Revoke</button>
                </div>
            </div>
            </div>
</div>
 </form>
 </div>

