import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MuslimMarriageCertificateComponent } from './muslim-marriage-certificate/muslim-marriage-certificate.component';
import { MinorityWelfareRoutingModule } from './minority-welfare-routing.module';
import { SharedModule } from 'src/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MuslimMarriageViewComponent } from './muslim-marriage-view/muslim-marriage-view.component';


@NgModule({
  declarations: [
    MuslimMarriageCertificateComponent,
    MuslimMarriageViewComponent
  ],
  imports: [
    CommonModule,
    MinorityWelfareRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    NgbModule
  ],
  exports: [
    CommonModule,
    MuslimMarriageViewComponent
 ]
})
export class MinorityWelfareModule { }
