import { Component, Input, OnInit } from '@angular/core';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { NgbModal, NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-pdf-viewer-popup',
  templateUrl: './pdf-viewer-popup.component.html',
  styleUrls: ['./pdf-viewer-popup.component.css'],
})
export class PdfViewerPopupComponent implements OnInit {
  @Input()
  sourceFile!: any;
  fileName: string = 'downloaded_document';
  @Input() showPrintDownload!: boolean;
  @Input() isImage: Boolean = false;
  department:any;
  constructor(public activeModal: NgbActiveModal, config: NgbModalConfig , private route: ActivatedRoute) {
    pdfDefaultOptions.assetsFolder = 'bleeding-edge';
    config.backdrop = 'static';
    config.keyboard = false;
    this.department = this.route.snapshot.queryParams.department;
  }  
  ngOnInit(): void {}

  printCertificate() {
    
    let okbtnelement: HTMLElement = document.getElementById(
      'okbtn'
    ) as HTMLElement;
   
    let printelement: HTMLElement = document.getElementById(
      'print'
    ) as HTMLElement;

    printelement.click();
   
    setTimeout(() => {
      okbtnelement.click();
      //window.close()
    }, 4000);
    
  }
}
