import { Component, OnInit, forwardRef, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../../shared/common.service';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  NG_VALIDATORS
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommonConstants } from '../../constants/common-constants.component';
import { WhitespaceValidator, AadharValidator } from '../../shared/common.service';
import { NgbDateAdapter, NgbDateParserFormatter,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from '../../shared/common.service';
import { CommonMsgModalComponent } from '../../shared/common-msg-modal/common-msg-modal.component';
import { DatePipe } from '@angular/common';
import { runInThisContext } from 'vm';
import { BasicPopupChangesComponent } from 'src/app/basic-popup-changes/basic-popup-changes.component';
@Component({
  selector: 'app-common-form',
  templateUrl: './common-form.component.html',
  styleUrls: ['./common-form.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommonFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CommonFormComponent),
      multi: true
    },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
  ]
})
export class CommonFormComponent implements  ControlValueAccessor, OnDestroy {
  commonConstants : any = CommonConstants;
  form: FormGroup;
  subscriptions: Subscription[] = [];
  submitted: Boolean = false;
  currentDate = new Date();
  maxDate: any;
  minDate: any;
  districts: any[] = [];
  mandals: any[] = [];
  relations: any[] = [];
  casteCategory: any[] = [];
  subcaste: any[] = [];
  qualifications: any[] = []
  permMandals: any [] = [];
  religions: any[] = [];
  villages: any[] = [];
  permVillages: any[] = [];
  permPostalVillages : any[] = [];
  permPostalOffices : any[] = [];
  prePostalVillages : any[] = [];
  prePostalOffices : any[] = [];  
  sixStepResponseData:any[]=[];
  familyMembers:any[]=[];
  memberNames : any[] = [];
  isNotAlive:any[]=[];
  isAvailableFamily:any[]=[];
  postDataToCheck:any={};
  encrtyptedAadhar : any = '';
  details: any;
  ekyc: any;
  age:any
  isAadharPrefilled: boolean = false;
  statusofCard:any;
  existingApplictionId:any;
  existingUHID:any;
  // isWhatsappEnabled:any="Yes"
  @Input() department: any;
  @Input() service:  any;
  @Input() isSubmitted: Boolean = false;
  @Input() postalRequired: Boolean = false;
  @Output() casteChange: any = new EventEmitter();
  @Output() checkValidation: any = new EventEmitter();
  @Output() reset: any = new EventEmitter();
  isAuthenticated: boolean= false;
  loggedInUser: any;
  secretariatList: any;
  exemptedAadhaarData: any;
  ngOnInit() {
    if(this.postalRequired)
    {
      this.form.controls['permPostalOffice'].setValidators([Validators.required]);
      this.form.controls['permPostalVillage'].setValidators([Validators.required]);
      this.form.controls['prePostalOffice'].setValidators([Validators.required]);
      this.form.controls['prePostalVillage'].setValidators([Validators.required]);
      
      this.form.controls['permPostalOffice'].updateValueAndValidity();
      this.form.controls['permPostalVillage'].updateValueAndValidity();
      this.form.controls['prePostalOffice'].updateValueAndValidity();
      this.form.controls['prePostalVillage'].updateValueAndValidity();

      console.log(this.form.controls);
      
    }
    this.secretariatList = [10790458, 10790459, 10790460, 10790461, 10790462, 10790463, 10790464, 10790465, 10790466, 10790467, 10790468, 10790469, 10790470, 10790471, 10790472, 10790473, 10790474, 10790701, 10790694, 10790695, 10790696, 10790697, 10790698, 10790699, 10790700, 10790702, 10790703, 10790704, 10790705, 10790706, 10790707, 10790708, 10790709, 10790710, 10790711, 10790712, 10790713, 10790714, 10790715, 10790716, 10790717, 10790718, 10790719, 10790720, 10790721, 10790722, 10790723, 10790724, 21023001, 21023002, 21023003, 21023004, 21023005, 21023006, 21023007, 21023008, 21023009, 21023010, 21023011, 21023012, 21023013, 21023014, 21023015, 21023016, 21023017, 21023018, 21023019, 21023020, 21143001, 21143002, 21143003, 21143004, 21143005, 21143006, 21143007, 21143008, 21143009, 21143010, 21143011, 21143012, 21143013, 21143014, 21143015]
    let userData = localStorage.getItem('userInfo');
    if(userData && userData !== null) {
      let userInfo = JSON.parse(userData);
      this.loggedInUser = userInfo.userPrincipalName;
    }
  }
  get f() { return this.form.controls; }

  get value(): any {
    return this.form.value;
  }

  set value(value: any) {
    this.form.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get emailControl() {
    return this.form.controls.email;
  } 
  constructor(private formBuilder: FormBuilder, private modalService: NgbModal, private commonService: CommonService, private datePipe: DatePipe) {
    // this.getDistricts();
    // this.getCaste();
    // this.getQualifications();
    // this.getReligions();
    if (!this.commonService.getMasterData('districts'))
      this.getDistricts();
    else 
      this.districts = this.commonService.getMasterData('districts')
    if (!this.commonService.getMasterData('caste'))
      this.getCaste();
    else
      this.casteCategory = this.commonService.getMasterData('caste');
    if (!this.commonService.getMasterData('qualifications'))
      this.getQualifications();
    else
      this.qualifications = this.commonService.getMasterData('qualifications')
    if (!this.commonService.getMasterData('religions'))
      this.getReligions();
    else
      this.religions = this.commonService.getMasterData('religions')
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
   
    // create the inner form
    this.form = this.formBuilder.group({
      aadharNo: ['', [Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
      firstName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      middleName: [''],
      lastName: [''],
      fatherHusName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern),  WhitespaceValidator.whiteSpaceValidate]],
      gender: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      caste: ['Select', [Validators.required]],
      religion: ['Select', [Validators.required]],
      qualification: ['', [Validators.required]],
      maritalStatus: ['', [Validators.required]],
      mobileNo: ['', [Validators.required, Validators.pattern(this.commonConstants.mobilePattern)]],
      isWhatsAppAvailable:['',[Validators.required]],
      email: ['', [Validators.pattern(this.commonConstants.emailPattern)]],
      deliveryType: [''],
      preDoorNo: ['', [Validators.required, Validators.pattern(this.commonConstants.doorNoStreetNamePattern)]],
      preStreet: [''],
      preVillage: ['Select', [Validators.required]],
      preMandal: ['Select', [Validators.required]],
      preDistrict: ['Select', [Validators.required]],
      prePinCode: ['', [Validators.required, Validators.pattern(this.commonConstants.pincodePattern)]],
      prePostalOffice : [''],
      prePostalVillage : [''],
      permDoorNo: ['', [Validators.required, Validators.pattern(this.commonConstants.doorNoStreetNamePattern)]],
      permStreet: [''],
      permVillage: ['Select', [Validators.required]],
      permMandal: ['Select', [Validators.required]],
      permDistrict: ['', [Validators.required]],
      permPinCode: ['', [Validators.required, Validators.pattern(this.commonConstants.pincodePattern)]],
      permPostalOffice: [''],
      permPostalVillage: [''],
      sameAddress: [''],
      encryptedAadharNo:[],
      householdid:[],
      clusterid:[],
      isDataFromAadhar:[]
    });
 
    this.subscriptions.push(
      // any time the inner form changes update the parent of any change
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
        this.submitted = true;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    if (value) {
      this.value = value;
    }

    if (value === null) {
      this.form.reset();
    }
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  // communicate the inner form validation to the parent form
  validate(_: FormControl) {
    return this.form.valid ? null : { commonForm: { valid: false } };
  }

  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getDistricts).subscribe({
      next: (responseData: any) => { 
        this.districts = this.commonService.sortData(responseData.result);
        this.commonService.setMasterData('districts', this.districts);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onDistrictChange(from: string, name: string, event?: any) {
    this.commonService.getRequest(this.commonConstants.getMandals + this.f[name].value.id).subscribe({
      next: (responseData: any) => { 
        if (from === 'present') {
          this.mandals = this.commonService.sortData(responseData.result);
          this.villages = [];
          this.f.preMandal.patchValue(null);
          this.f.preVillage.patchValue(null);
          this.validateAddressSame();
        } else if (from === 'permanent') {
          this.permMandals = this.commonService.sortData(responseData.result);
          this.permVillages = [];
          this.f.permMandal.patchValue(null);
          this.f.permVillage.patchValue(null);
          if (this.f.sameAddress.value) {
            this.mandals = this.permMandals;
            this.f.preDistrict.patchValue(this.f.permDistrict.value);
            this.f.preMandal.patchValue(null);
            this.f.preVillage.patchValue(null);
            // console.log('event.value', event.value);
            // console.log('this.f.preDistrict.patchValue', this.f.preDistrict.value);
            // console.log('this.f.preDistrict.patchValue', this.f.permDistrict.value);
          }
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onMandalChange(from: string, name: string, event?: any) {
    this.commonService.getRequest(this.commonConstants.getVillages + this.f[name].value.id).subscribe({
      next: (responseData: any) => { 
        if (from === 'present') {
          this.villages = this.commonService.sortData(responseData.result);
          this.f.preVillage.patchValue(null);
          this.validateAddressSame();
        } else if (from === 'permanent') {
          this.permVillages = this.commonService.sortData(responseData.result);
          this.f.permVillage.patchValue(null);
          if (this.f.sameAddress.value) {
            this.villages = this.permVillages;
            this.f.preMandal.patchValue(this.f.permMandal.value);
            this.f.preVillage.patchValue(null);
          }
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onVillageChange(event: any) {
    if (this.f.sameAddress.value) {
      this.f.preVillage.patchValue(this.f.permVillage.value);
    }
  }
  getRelations() {
    this.commonService.getRequest(this.commonConstants.getRelations).subscribe({
      next: (responseData: any) => { 
        this.relations = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onPostalOfficeChange(from: string, name: string, event?: any)
  {
    this.commonService.getRequest(this.commonConstants.getPostalVillage + this.f[name].value.id).subscribe({
      next: (responseData: any) => { 
        if (from === 'present') {
          this.prePostalVillages = this.commonService.sortData(responseData.result);
          this.f.prePostalVillage.patchValue(null);
          this.validateAddressSame();
        } else if (from === 'permanent') {
          this.permPostalVillages = this.commonService.sortData(responseData.result);
          this.f.permPostalVillage.patchValue(null);
          if (this.f.sameAddress.value) {
            this.prePostalVillages =  this.permPostalVillages;
            this.f.prePostalOffice.patchValue(this.f.permPostalOffice.value);
            // console.log(this.f.prePostalOffice.value , "prePostalOffice")
            this.f.prePostalVillage.patchValue(null);
          }
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onPostalVillageChange(event: any)
  {
    if (this.f.sameAddress.value) {
      this.f.prePostalVillage.patchValue(this.f.permPostalVillage.value);
    }
  }

  getCaste() {
    this.commonService.getRequest(this.commonConstants.getCaste).subscribe({
      next: (responseData: any) => { 
        this.casteCategory = this.commonService.sortData(responseData.result);
        this.commonService.setMasterData('caste', this.casteCategory);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onCasteChange(name: string, event?: any, ) {
    this.commonService.getRequest(this.commonConstants.getSubCaste + this.f[name].value.id).subscribe({
      next: (responseData: any) => { 
        this.subcaste = this.commonService.sortData(responseData.result);
        this.casteChange.emit(this.subcaste);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  
getCitizenInfo() {
  if (this.f.aadharNo.valid) {
  //   this.commonService.getRequest(this.commonConstants.getCitizenInfo + this.f.aadharNo.value).subscribe({
  //     next: (responseData: any) => { 
  //       if(responseData && responseData.length !==0)
  //         this.loadCitizenData(responseData);
  //       else
  //         alert('Citizen details are not available to populate.');
  //     },
  //     error: (error) => {
  //       console.log('client side', error);
  //     }
  // });
    let postData: any = {};
    postData.aadhaarNumber = this.commonService.RSAEncrypt(this.f.aadharNo.value, true);
   if(this.service === 'new-ricecard'){
    this.getCitizenInfoforNewRiceCard()
   }  else{ 
     if(this.service === 'IntegratedCertificate' || this.service === 'issuanceIncomeAssetCertificate') {
      this.checkValidation.emit('Yes');
     }   
  this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe({
    next: (responseData: any) => { 
      if(responseData?.result &&responseData?.result.length !==0)
       { 
        responseData.result.forEach((item: any) => {
          item.aadhaarNumber = item.aadhaarNumber;
        });
        if (this.service === 'Pensions' && (this.loggedInUser == 'UAPSHMIT@apgsws.onmicrosoft.com' || this.loggedInUser == 'APSHMIT@apgsws.onmicrosoft.com' || this.loggedInUser == 'DAPSHMIT@apgsws.onmicrosoft.com')) {
          let index = this.secretariatList.findIndex((item: any) => {return item == responseData?.result[0].seccode});
          if (index === -1) {
            this.commonService.commonErrorPopup("Alert", 'Applicant does not belong to Mangalagiri Constituency', 'md');
            this.resetForm();
            return;
          }

        }
        if(this.department === 'HF'){
        let dob =responseData.result[0].birthDate;
        const convertAge = new Date(this.commonService.formatDate(dob));
        const timeDiff = Math.abs(Date.now() - convertAge.getTime());
        this.age  = Math.floor(timeDiff / (1000 * 3600 * 24) / 365); 
        
        this.sixStepResponseData=[];
        // for Aarogya-sri validation calling sixstepvalidation API
var sendPostData={
  CITIZEN_ID: this.commonService.RSAEncrypt(this.f.aadharNo.value, true),
}
this.commonService.postRequestForWebLand(this.commonConstants.newSixStepDetails, sendPostData , 'citizenInfo').subscribe({
  next: (sixstepData:any) => { 
    this.sixStepResponseData = sixstepData.result;
    this.familyMembers=[];
if(this.sixStepResponseData.length>0){
  let isValid: any;
  let teluguName:any;
  let isTeluguName:any;
  //let numberinNames:any
  let data:any=[];
  let aadharDatainName:any=[];
  
    for (let i = 0; i < this.sixStepResponseData.length; i++){   
     
  
      teluguName =[];
      isTeluguName=[];    
      teluguName =   this.sixStepResponseData[i].CITIZEN_NAME.match(this.commonConstants.namePattern) // it will check for english alphabets    
      data.push(teluguName);
     // console.log(data, data.length , 'teluguname')
      isTeluguName = data.filter((item:any) => (item === null));
     // console.log(isTeluguName , 'teluguname')
    }
    
    this.isNotAlive = this.sixStepResponseData.filter((item:any) => (item.IS_LIVING_WITHFAMILY?.toUpperCase()?.trim() == "DEATH"));
    this.isAvailableFamily=this.sixStepResponseData.filter((item:any) => (item.IS_LIVING_WITHFAMILY?.toUpperCase()?.trim() !== "DEATH"));
    let isAlive = this.isNotAlive.filter(item => (item.CITIZEN_ID == this.f.aadharNo.value))
   for(let i=0; i < this.isAvailableFamily.length ;i++){
    this.familyMembers.push({'citizen_UID':(this.commonService.RSAEncrypt(this.isAvailableFamily[i].CITIZEN_ID))}); 
   }
   console.log(JSON.stringify(this.familyMembers) + 'familyMembers')
    let id=this.f.aadharNo.value
    if(isAlive.length === 0  && this.service==="newAarogya-sri"){
      this.postDataToCheck = {
        aadharNumbers:this.familyMembers
      }
      
    this.commonService.postRequest(this.commonConstants.checkCardStatus ,this.postDataToCheck ).subscribe({
      next: (checkData:any) => {
        if(checkData.result){
          this.statusofCard=checkData.result.isExisting;
          this.existingApplictionId=checkData.result.existingApplictionId;
          this.existingUHID=checkData.result.existingUHID;
          if(this.statusofCard === true  && (this.existingUHID != null || this.existingApplictionId != null) && this.service==="newAarogya-sri"){
            if(this.existingUHID !== null){ 
              this.commonService.commonErrorPopup("Alert", 'One of the member of this House hold has Health Card with UHID  ' + this.existingUHID + ' Kindly update the details in existing card using Update Dr NTR Vaidya Seva Card Service.', "md")
           
          }else{
            this.commonService.commonErrorPopup("Alert", 'Pending at DC with Application id ' + this.existingApplictionId, "md")
          
          }
        } else {
          
       //   isValid=this.sixStepResponseData.filter(item => (item.CITIZEN_NAME === "")|| (item.CITIZEN_NAME === null)||(item.CITIZEN_NAME === undefined) ||(item.CITIZEN_NAME === 'NA') || (item.RESIDENT_ID==="") );
          if(this.age < 18){
            this.commonService.commonErrorPopup("Alert", "'The Head of the family must and should above 18 years", "md")
         
           return;
         }else{
          this.loadCitizenData(responseData.result);
          this.isAadharPrefilled = true; 
         }
      //  else if(isValid.length === 0 && isTeluguName.length === 0){
      //  this.loadCitizenData(responseData.result);
      //  this.isAadharPrefilled = true;     
        // }
        //    else{
        //     this.commonService.commonErrorPopup("Alert", "One of the family members does not have proper data", "md")
        //   // alert('One of the family members does not have proper data');
        //  }
        }
        }       
       }})
       // this.sixStepResponseData = sixstepData.result;
      }else if(isAlive.length === 0 && this.service !=="newAarogya-sri"){
        if(this.age < 18){
          this.commonService.commonErrorPopup("Alert", "'The Head of the family must and should above 18 years", "md")
       
         return;
       }else{
        this.loadCitizenData(responseData.result);
        this.isAadharPrefilled = true; 
       }
      }else{
       
          this.commonService.commonErrorPopup("Alert", 'This Applicant status is death in house hold details.Please apply with other member aadhar in the family', "md")
       
      }
  
   
}  
  },
  error: (error) => {
    console.log('client side', error);
  }
})

        }
        if(this.department !== 'HF'){
          if(this.service === 'HousingSite' || this.service === 'RegistrationofTokens') {
            this.checkValidation.emit('Yes');
          }
            this.loadCitizenData(responseData.result);
        this.isAadharPrefilled = true;
        }
        
        if(this.department === 'CS' && this.service != 'FP Shop Renewal') {
          this.checkAadharCitizenStatus()
        }
        //this.getEncryptedAadharValues(this.f.aadharNo.value);
       }
      else
        {
          //alert('Citizen details are not available to populate.');
          if(this.department === 'HF'){

            alert('Your details are not present in the Household survey. Please contact respective Secretariat/volunteer for Household Survey');
    
              }else{
                if(this.service === 'HousingSite') {
                  alert('Your details are not present in the Household survey. Please contact respective Secretariat/volunteer for Household Survey.');
                  this.checkValidation.emit('No');
                } else if(this.service === 'RegistrationofTokens'){
                  let postDataLive: any = {};
                  postDataLive.UIDNUM = this.commonService.RSAEncrypt(this.f.aadharNo.value, true);  
                  this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfoLive, postDataLive, 'citizenInfo').subscribe({
                    next: (responseData: any) => { 
                      if(responseData?.result && responseData?.result.length !==0) { 
                        responseData.result.forEach((item: any) => {
                          item.aadhaarNumber = this.commonService.RSADecrypt(item.aadhaarNumber);
                        });
                        this.loadCitizenDataLive(responseData.result);
                          this.checkValidation.emit('Yes');                
                      } else {
                        alert('Your details are not present in the Household survey. Please contact respective Secretariat/volunteer for Household Survey.');
                        this.checkValidation.emit('No');
                      }
                    },
                    error: (error) => {
                      console.log('client side', error);
                    }
                  });
                } else {
                  if(this.department === 'RD' || (this.department === 'CS' && this.service != 'FP Shop Renewal')){
                    alert('Aadhaar is not enrolled in Household data. Please update Aadhaar in Household data in GSWS Volunteer APP');
                    // this.isAuthenticated = false;  
                  }else{
                  // this.f.isDataFromAadhar.patchValue('false');
                  //  alert('Citizen details are not available to populate');
                  if(this.service != 'FP Shop Renewal' && this.service != 'ROFRMutationForTransactionsSuccession'){
                   alert('Aadhaar is not enrolled in Household data. Please update Aadhaar in Household data in GSWS Volunteer APP');
                  //  alert('Aadhaar Not enrolled in HH data');
                    const modalRef = this.modalService.open(BasicPopupChangesComponent, { size: 'xl' });
                    modalRef.componentInstance.title = 'Alert';
                    modalRef.componentInstance.aadharFromCF = this.f.aadharNo.value;
                    modalRef.result.then((data: any) => {
                    if (data) {
                      this.isAuthenticated = true;
                      this.form.patchValue({
                        isDataFromAadhar:'true',
                        firstName: data.firstName,
                        fatherHusName:data.fatherHusName,
                        gender:data.gender,
                        dob:data.dob
                      });
                      this.checkValidation.emit('search');
                    }
                  }).catch((res) => {});
                }
              }
                }
      }
      }
    },
    error: (error) => {
      console.log('client side', error);
    }
});

  } }
  else {
       if(this.department === 'HF'){
      alert('Your details are not present in the Household survey. Please contact respective Secretariat/volunteer for Household Survey');
        }else{
        alert('Please enter valid Aadhar Number');
}
  }
  
}
  getCitizenInfoforNewRiceCard(){
    this.details='';
    this.ekyc='';    
    let postData: any = {};
    postData.aadhaarNumber = this.commonService.RSAEncrypt(this.f.aadharNo.value, true);  
    // let reqdata : any = {
    //   uid : this.f.aadharNo.value
    // };
    // this.commonService.postRequest(this.commonConstants.CheckIfExemptedAadhaar,reqdata, true).subscribe({
    //   next: (responseData: any) => {
    //   this.exemptedAadhaarData = responseData.result
    // if(!this.exemptedAadhaarData.isExempted){
    //   this.commonService.commonErrorPopup("Alert", this.exemptedAadhaarData.error, 'md');
    //   return
    // }
 let obj =
    {
      uid_no:  this.commonService.RSAEncrypt(this.f.aadharNo.value)
    }
    this.commonService.postRequest(this.commonConstants.getEKYCStatus,obj).subscribe({
    next: (responseData: any) => {
      this.details = responseData['result'].rice_card_no;
      this.ekyc = responseData['result'].rc_status;           
 if(this.ekyc !== null)
 {
   this.aadharExists(this.details);
   return
 }else{
  this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe({
    next: (responseData: any) => { 
    //  console.log(responseData.result,'prefill data')
      if(responseData?.result &&responseData?.result.length !==0)
       { 
        responseData.result.forEach((item: any) => {
          item.aadhaarNumber =item.aadhaarNumber;
        });
        this.loadCitizenData(responseData.result);
        this.isAadharPrefilled = true;
        if(this.department === 'CS' && this.service != 'FP Shop Renewal') {
          this.checkAadharCitizenStatus()
        }
        //this.getEncryptedAadharValues(this.f.aadharNo.value);
       }
      else
        {           
              // alert('Citizen details are not available to populate');   
              alert('Aadhaar is not enrolled in Household data. Please update Aadhaar in Household data in GSWS Volunteer APP');   
      
      }
    },
    error: (error) => {
      console.log('client side', error);
    }
});
 }

    }
 })
}
// })
// }
  
  aadharExistsResponse: any;
  checkAadharCitizenStatus(){
    var foundValue:any;
    let postData = {
      AadharNumber: this.f.aadharNo.value
    }
    this.commonService.postRequest(this.commonConstants.getApplicantDetailsByAadharNo, postData, true ).subscribe({
      next: (responseData: any) => { 
        this.aadharExistsResponse = responseData.result
        console.log(this.aadharExistsResponse , "aadharExistsResponse")
        // console.log(this.aadharExistsResponse[0]?.slA_Status , "status of aadharExistsResponse")
        if(!this.aadharExistsResponse.includes("No applicant found For the aadhar number")){
          foundValue = this.aadharExistsResponse.filter((obj: { slA_Status: string; })=>obj.slA_Status!=null);
          console.log(foundValue,foundValue[0]?.departmentId,'foundvalue')
          if(foundValue[0]?.slA_Status?.toLowerCase()?.includes('pending with') &&  foundValue[0]?.departmentId == 'Civil Supplies'){
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = 'Please try with New Aadhar. Entered Aaadhar No - ' + this.f.aadharNo.value + ' Already Exists with the Request ID - '+ foundValue[0]?.requisitionId +' & Current status as ' + foundValue[0]?.slA_Status + '.';
            modalRef.result.then((result: any) => {
              if (result) {
                console.log(result);
                this.resetForm();
              }
            });
          }
        }
      }
    })
  }

  // this.incomeCertificate.controls.landsIncome.value
  aadharencrypt(event: any){
    if(this.f.aadharNo.valid){
      this.getEncryptedAadharValues(this.f.aadharNo.value);
    }else {
      this.encrtyptedAadhar = '';
    }
  }
  getEncryptedAadharValues(aadharValue:any){
    this.encrtyptedAadhar = '';
    this.commonService
      .getRequest(this.commonConstants.cryptoEncryptValue + encodeURIComponent(this.commonService.RSAEncrypt(aadharValue)))
      .subscribe({
        next: (responseData: any) => {
          // console.log(responseData.result.encryptedValue, 'encrypt value respose data');
          this.encrtyptedAadhar =  responseData.result.encryptedValue;
         // console.log(this.encrtyptedAadhar,'encryptaadhar')

         console.log(this.encrtyptedAadhar,'encrypt aaaadhhhhar')
          if(this.encrtyptedAadhar?.length !==0 && this.encrtyptedAadhar !== null){
            this.form.patchValue({
              encryptedAadharNo: this.encrtyptedAadhar,
            });
            // console.log(this.form,'encrypt aaaadhhhhar')
             console.log('encrypt aaaadhhhhar  AA')
            }else{
              console.log('encrypt aaaadhhhhar  BB')
              this.commonService.commonErrorPopup("Alert", "Aadhar Encryption Failed !!", "md")
              this.resetForm();
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  // getEncryptedAadharValues(aadharValue:any){
  //   this.encrtyptedAadhar = '';
  //   this.commonService
  //     .getRequest(this.commonConstants.cryptoEncryptValue + encodeURIComponent(this.commonService.RSAEncrypt(aadharValue)))
  //     .subscribe({
  //       next: (responseData: any) => {
  //         // console.log(responseData.result.encryptedValue, 'encrypt value respose data');
  //         this.encrtyptedAadhar =  responseData.result.encryptedValue;
  //        // console.log(this.encrtyptedAadhar,'encryptaadhar')

  //        console.log(this.encrtyptedAadhar,'encrypt aaaadhhhhar')
  //         if(this.encrtyptedAadhar?.length !==0 && this.encrtyptedAadhar !== null){
  //           this.form.patchValue({
  //             encryptedAadharNo: this.encrtyptedAadhar,
  //           });
  //           // console.log(this.form,'encrypt aaaadhhhhar')
  //            console.log('encrypt aaaadhhhhar  AA')
  //           }else{
  //             console.log('encrypt aaaadhhhhar  BB')
  //             this.commonService.commonErrorPopup("Alert", "Aadhar Encryption Failed !!", "md")
  //             this.resetForm();
  //         }
  //       },
  //       error: (error) => {
  //         console.log('client side', error);
  //       },
  //     });
  // }

  getQualifications() {
    this.commonService.getRequest(this.commonConstants.getQualifications).subscribe({
      next: (responseData: any) => { 
        this.qualifications =  this.commonService.sortData(responseData.result);
        this.commonService.setMasterData('qualifications', this.qualifications);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  loadCitizenDataLive(result:any) {
    this.f.sameAddress.patchValue(false);
    let data:any = result[0];
    this.loadDistrict(data.LGD_DIST_CODE, data.LGD_MANDAL_CODE, data.SECRETARIAT_CODE);
    // if (data.caste !== null && data.caste !== '') {
    //   this.loadCaste(data.caste);
    // }
    // if(data.pinCode !== null && data.pinCode !== '')
    // {
    //   this.onPincodeChange('permanent', data.pinCode)
    // }
   
    this.form.patchValue({
      aadharNo: data.UID_NUM,
      firstName: this.department === 'RD' ? this.f.firstName.value :data.CITIZEN_NAME,
      middleName: '',
      lastName: '',
      fatherHusName: '',
      gender: this.department === 'RD'? this.f.gender.value : this.loadGender(data.GENDER?.toUpperCase()),
      dob: this.department === 'RD' ? this.f.dob.value : data.DOB_DT,
      religion: null,
      qualification: null,
      maritalStatus: null,
      mobileNo: data.MOBILE_NUMBER,
      isWhatsAppAvailable:null,
      email: null,
      deliveryType: '',
      permDoorNo: data.DOOR_NO,
      permStreet: '',
      permPinCode: null,
      permPostalOffice : null,
      permPostalVillage : null,
      preDoorNo: '',
      preStreet: '',
      preVillage: null,
      preMandal: null,
      preDistrict: null,
      prePinCode: '',
      prePostalOffice : null,
      prePostalVillage : null,
      householdid:data.TEMP_ID,
      clusterid: data.CLUSTER_ID,
  });
}

  loadCitizenData(result:any) {
    this.f.sameAddress.patchValue(false);
    this.f.isDataFromAadhar.patchValue('false');
    let data:any = result[0];
    this.loadDistrict(data.districtcode, data.mandalcode, data.seccode);
    if (data.caste !== null && data.caste !== '') {
      this.loadCaste(data.caste);
    }
    if(data.pinCode !== null && data.pinCode !== '')
    {
      this.onPincodeChange('permanent', data.pinCode)
    }
   
    this.form.patchValue({
      aadharNo: data.aadhaarNumber,
      firstName: this.department === 'RD' ? this.f.firstName.value :data.firstName,
      middleName: '',
      lastName: '',
      fatherHusName: data.fatherHusbandName,
      // fatherHusName: this.responseData.co,
      gender: this.department === 'RD'? this.f.gender.value : this.loadGender(data.gender?.toUpperCase()),
      dob: this.department === 'RD' ? this.f.dob.value : data.birthDate,
      religion: data.religion !== '' ? this.loadReligion(data.religion): null,
      qualification: data.qualification !== '' ? this.loadQualifications(data.qualification): null,
      maritalStatus: data.maritalStatus !== '' ? data.maritalStatus : null,
      mobileNo: data.mobileNumber,
      isWhatsAppAvailable:data.isWhatsAppAvailable,
      email: data.email,
      deliveryType: '',
      permDoorNo: data.doorNo,
      permStreet: '',
      permPinCode: data.pinCode,
      permPostalOffice : null,
      permPostalVillage : null,
      preDoorNo: '',
      preStreet: '',
      preVillage: null,
      preMandal: null,
      preDistrict: null,
      prePinCode: '',
      prePostalOffice : null,
      prePostalVillage : null,
      householdid:data.id,
      clusterid: data.clusterid,
  });
    
  // if (data.birthDate !== '' && data.birthDate !== null) {
  //   this.f.dob.disable();
  // }
}
checkAddress() {
  if (this.f.sameAddress.value) {
    this.mandals = this.permMandals;
    this.villages = this.permVillages;
    this.prePostalOffices = this.permPostalOffices;
    this.prePostalVillages = this.permPostalVillages
    this.form.patchValue({
      preDoorNo: this.f.permDoorNo.value,
      preStreet: this.f.permStreet.value,
      preVillage: this.f.permVillage.value,
      preMandal: this.f.permMandal.value,
      preDistrict: this.f.permDistrict.value,
      prePinCode: this.f.permPinCode.value,
      prePostalOffice: this.f.permPostalOffice.value,
      prePostalVillage: this.f.permPostalVillage.value,
  });
  } else {
    this.mandals = [];
    this.villages = [];
    this.prePostalOffices = [];
    this.prePostalVillages = [];
    this.form.patchValue({
      preDoorNo: '',
      preStreet: '',
      preVillage: null,
      preMandal: null,
      preDistrict: null,
      prePinCode: '',
      prePostalOffice : null,
      prePostalVillage : null,
  });
  }
}
getReligions() {
  this.commonService.getRequest(this.commonConstants.getReligions).subscribe({
    next: (responseData: any) => { 
      this.religions =  this.commonService.sortData(responseData.result);
      this.commonService.setMasterData('religions', this.religions);
    },
    error: (error) => {
      console.log('client side', error);
    }
});
}
onDoorNoChange(value:any) {
  if (value && this.f.sameAddress.value) {
    this.f.preDoorNo.patchValue(this.f.permDoorNo.value);
  }
}
onStreetChange(value:any) {
  if (value && this.f.sameAddress.value) {
    this.f.preStreet.patchValue(this.f.permStreet.value);
  }
}
onPincodeChange(from: string, value : any) {

  this.commonService.getRequest(this.commonConstants.getPostalOffice + value).subscribe({
    next: (responseData: any) => { 
      if (from === 'present') {
        this.prePostalOffices = this.commonService.sortData(responseData.result);
        this.prePostalVillages = [];
        this.f.prePostalOffice.patchValue(null);
        this.f.prePostalVillage.patchValue(null);
        this.validateAddressSame();
      } else if (from === 'permanent') {
        this.permPostalOffices = this.commonService.sortData(responseData.result);
        this.permPostalVillages = [];
        this.f.permPostalOffice.patchValue(null);
        this.f.permPostalVillage.patchValue(null);
        if (this.f.sameAddress.value) {
          this.prePostalOffices = this.permPostalOffices;
          this.f.prePinCode.patchValue(this.f.permPinCode.value);
          this.f.prePostalOffice.patchValue(null);
          this.f.prePostalVillage.patchValue(null);
        }
      }
    },
    error: (error) => {
      console.log('client side', error);
    }
});
  if (value && this.f.sameAddress.value) {
    this.f.prePinCode.patchValue(this.f.permPinCode.value);
  }
}
validateAddressSame() {
  if(this.f.permDoorNo.value !== this.f.preDoorNo.value || this.f.permStreet.value !== this.f.preStreet.value ||
     this.f.permVillage.value !== this.f.preVillage.value || this.f.permMandal.value !== this.f.preMandal.value ||
     this.f.permDistrict.value !== this.f.preDistrict.value || this.f.permPinCode.value !== this.f.prePinCode.value ||
     this.f.permPostalOffice.value !== this.f.prePostalOffice.value || this.f.permPostalVillage.value !== this.f.prePostalVillage.value) {
      if (this.f.sameAddress.value)
        this.f.sameAddress.patchValue(null);
     }
}
loadDistrict(code: string, mandal: string, village: string) {
  let districtVal = this.districts.filter(item => item.entityCode === code);
  console.log('districtVal', districtVal);
  if (districtVal && districtVal.length === 0) {
    this.commonService.getRequest(this.commonConstants.getParentCodeByChildCode +'Mandal&EntityCode='+ mandal +'&LanguageCode=EN-IN').subscribe({
      next: (responseData:any) => { 
        districtVal = this.districts.filter(item => item.entityCode === responseData.result[0]?.entityCode);
       //console.log('districtCode', districtVal)
       this.f.permDistrict.patchValue(districtVal[0]);
        this.loadMandal(mandal, village);
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  } else {
   this.f.permDistrict.patchValue(districtVal[0]);
    this.loadMandal(mandal, village);
  }
 // console.log('districtVal',districtVal);
}
loadMandal(code: string, village: string) {
  this.commonService.getRequest(this.commonConstants.getMandals + this.f.permDistrict.value.id).subscribe({
    next: (responseData: any) => { 
        this.permMandals = this.commonService.sortData(responseData.result);
        let mandalVal = this.permMandals.filter(item => item.entityCode === code);
         this.f.permMandal.patchValue(mandalVal[0]);
         this.loadVillage(village);
    },
    error: (error) => {
      console.log('client side', error);
    }
  });
}
loadVillage(code: string) {
  this.commonService.getRequest(this.commonConstants.getVillages + this.f.permMandal.value.id).subscribe({
    next: (responseData: any) => { 
        this.permVillages = this.commonService.sortData(responseData.result);
        let villageVal = this.permVillages.filter(item => item.entityCode === code);
        this.f.permVillage.patchValue(villageVal[0]);
    },
    error: (error) => {
      console.log('client side', error);
    }
});
}
loadGender(value: string) {
  // if (value !== '' && value !== null) {
  //   this.f.gender.disable();
  // }
  if (value === 'MALE')
    return 'Male';
  else if (value === 'FEMALE')
    return 'Female';
  else if (value === 'TRANSGENDER')
    return 'Transgender';
  else 
    return null;
}
loadCaste(name: string) {
  let casteVal = this.casteCategory.filter(item => item.name === name);
  //console.log('casteVal', casteVal);
  if (casteVal && casteVal.length > 0) {
    this.f.caste.patchValue(casteVal[0]);
    this.onCasteChange('caste');
  }
}
loadReligion(name: string) {
  let religionVal = this.religions.filter(item => item.name === name);
  return religionVal[0];
}
loadQualifications(name: string) {
  let qualVal = this.qualifications.filter(item => item.name === name);
  return qualVal[0];
}

resetForm(){
  this.form.reset();
  this.isAadharPrefilled = false;
  this.reset.emit();
 // this.form.controls.permDistrict.patchValue(null);
  // this.f.dob.enable();
  // this.f.gender.enable();
}



  aadharExists(details : any) {
    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    // modalRef.componentInstance.reqId = id;
    modalRef.componentInstance.title = 'Alert ' + this.alertDateTimeZone;
    modalRef.componentInstance.message = "Member already has a Rice Card with Rice Card Number : " + details;
    modalRef.result.then((result: any) => {
      if (result) {
        console.log(result);
      }
    });
  }
  alertDateTimeZone : any;
  formatAMPM() {
   var date = new Date(); 
     var hours : any = date.getHours();
     var minutes : any= date.getMinutes();
     var ampm = hours >= 12 ? 'pm' : 'am';
     hours = hours % 12;
     hours = hours ? hours : 12; // the hour '0' should be '12'
     minutes = minutes < 10 ? '0'+minutes : minutes;
     var strTime =  date.getDate() + "/"  + (date.getMonth()+1)  + "/"  + date.getFullYear() + ' '+ hours + ':' + minutes + ' ' + ampm;
     this.alertDateTimeZone =  date.getDate() + "/" + (date.getMonth()+1)  + "/" + date.getFullYear() + ' '+ hours + ':' + minutes + ' ' + ampm;
     return strTime; 
  }
}
