import { Component, OnInit ,ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import { NgbDateAdapter, NgbDateParserFormatter,NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from '../../shared/common.service';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-passbook-service-report',
  templateUrl: './passbook-service-report.component.html',
  styleUrls: ['./passbook-service-report.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
  ]
})
export class PassbookServiceReportComponent implements OnInit {
  headerCol = [
    {colId:'districtname', colName: 'District'},
    {colId:'divisionname', colName: 'Division'},
    {colId:'mandalname', colName: 'Mandal'},
    {colId:'villagename', colName: 'Village'},
    {colId:'applicatioN_NO', colName: 'Application Number'},
    {colId:'approveD_DATE', colName: 'Application Approved Date'},
    {colId:'passbooK_SENT_DATE', colName: 'Passbook sent to Printers Date'},
    {colId:'printeD_DATE', colName: 'Passbook Printed by Printers Date'},
    {colId:'dispatcheD_DATE', colName: 'Passbook Dispatched by Printers Date'},
    {colId:'consignmenT_NO', colName: 'Consignment Number/ Courier Ref Number'},
    ]
  commonConstants : any = CommonConstants;
  form: FormGroup;
  districts: any[] = [];
  mandals: any[] = [];
  villages: any[]= [];
  formatTypes: any[]= [];
  services: any[] = [];
  format: any;
  maxDate: any;
  minDate: any;
  toMinDate: any;
  currentDate = new Date();
  submitted: Boolean = false;
  defaultFromDate: any;
  items: any[] = [];
  page = 1;
  pageSize = 10;
  showReports: Boolean = false;
  noOfCol: any = 7;
  totalItems: any[] = [];
  postData: any = {};
  role: any;
  title: any;
  @ViewChild('TABLE') table!: ElementRef;
  @ViewChild('MODALTABLE') beyondSLATable!: ElementRef;
  subTable: any;
  popupPageSize = 10;
  popupPage = 1;
  modalItems: any[] = [];
 
  constructor(private formBuilder: FormBuilder, private commonService: CommonService, config: NgbModalConfig, private modalService: NgbModal, private datePipe: DatePipe, private router: Router) { 
    config.backdrop = 'static';
    config.keyboard = false;
    this.form = this.formBuilder.group({
    service: [null],
    mandal: [null],
    district: [null],
    village: [null],
    fromDate: ['', [Validators.required]],
    toDate: ['', [Validators.required]],
  });
  this.maxDate = {
    year: this.currentDate.getFullYear(),
    month: this.currentDate.getMonth() + 1,
    day: this.currentDate.getDate()
  };
  this.minDate = {
    year: this.currentDate.getFullYear() - 150,
    month: this.currentDate.getMonth() + 1,
    day: this.currentDate.getDate()
  };
  this.toMinDate = {
    year: this.currentDate.getFullYear() - 150,
    month: this.currentDate.getMonth() + 1,
    day: this.currentDate.getDate()
  }
  this.getDistricts();
  this.onDeptChange();
  }

  ngOnInit(): void {
    this.defaultFromDate = this.currentDate.getDate()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getFullYear();
    this.format = 'District Wise';
     this.form.patchValue({
       district:'All',
       mandal:'All',
       village:'All',
       service:'All',
       fromDate: this.defaultFromDate,
       toDate: this.defaultFromDate,
     });
     this.formatTypes = [
         {name: 'District Wise', isActive: true},
         {name:'Mandal Wise', isActive: false},
         {name:'Village Wise', isActive: false},
         {name:'Service Wise', isActive: false}
       ]
       this.getReports();
     let userInfo = localStorage.getItem('userInfo');
       if (userInfo && userInfo !== null) {
         let userData = JSON.parse(userInfo);
         this.role = userData.jobTitle;
       }
  }

  get f() { return this.form.controls; }
   onTypeOfDateChange() {
    this.showReports = false;
  }
  getDistricts() {
    this.commonService.getRequest(this.commonConstants.GetRevenueDistrictMSP).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          let districts: any[] = [];
           responseData.result?.getDistrictDetails.forEach((district: any)=>{
            districts.push(district);
          })
          this.districts = this.commonService.sortItems(districts, 'rV_DISTRICTNAME');
        } else {
          this.districts = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onDistrictChange() {
    this.showReports = false;
    this.mandals = [];
    this.villages = [];
    this.f.mandal.patchValue('All');
    this.f.village.patchValue('All');
    this.f.service.patchValue('All');
    this.getMandals();
  }
  getMandals(){
    this.showReports = false;
    this.mandals = [];
    this.villages = [];
    this.f.mandal.patchValue('All');
    this.f.village.patchValue('All');
    this.f.service.patchValue('All');
    this.commonService.getRequest(this.commonConstants.GetRevenueMandalByDistrictMSP + '?DistrictCode=' + this.f['district'].value.rV_DISTRICT_ID
    ).subscribe({
      next: (responseData: any) => { 
        let mandals: any[] = [];
        responseData.result?.datalist.forEach((mandal: any)=>{
          mandals.push(mandal);
       })
        if(responseData.result?.isSuccess) {
          this.mandals = this.commonService.sortItems(mandals, 'revenuE_MANDAL_NAME');
        } else {
          this.mandals = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onMandalChange() {
    this.showReports = false;
    this.villages = [];
    this.f.village.patchValue('All');
    this.f.service.patchValue('All');
    this.commonService.getRequest(this.commonConstants.GetRevenueVillageByMandalMSP +'?DistrictCode=' + this.f['district'].value.rV_DISTRICT_ID + '&MandalCode=' + this.f['mandal'].value.revenuE_MANDAL_CODE).subscribe({
      next: (responseData: any) => { 
        let villages: any[] = [];
        responseData.result?.datalist.forEach((village: any)=>{
          villages.push(village);
       })
        if(responseData.result?.isSuccess) {
          this.villages = this.commonService.sortItems(villages, 'revenuE_VILLAGE_NAME');
        } else {
          this.villages = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onVillageChange() {
    this.showReports = false;
  }
  onDeptChange() {
    this.showReports = false;
    this.services = [];
    this.f.service.patchValue('All');
    this.commonService.getRequest(this.commonConstants.GetServiceTypes).subscribe({
      next: (responseData: any) => {
         let services: any[] = [];
         responseData.result?.getServiceTypes?.forEach((service: any)=>{
          services.push(service);
       })
        if(responseData.result?.isSuccess) {
          this.services = this.commonService.sortItems(services, 'serviceType');
        }
        else {
          this.services = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onServiceChange() {
    this.showReports = false;
  }
  onFromDateSelect(event: any) {
    this.showReports = false;
    this.toMinDate = event;
  }
  onToDateSelect(event: any) {
    this.showReports = false;
  }
  onChangeFormat(item: any) {
    this.showReports = false;
    this.formatTypes.map((item:any) => {
      item.isActive = false;
      return item;
    });
    item.isActive = true;
    this.format = item.name;
    this.getFormat();
  }
  downloadExcel() {
    let fileName: any = 'Passbooks Related Service Report from'+ this.f.fromDate.value +' to '+ this.f.toDate.value;
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},     
  ];
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Passbooks Service Report'); 
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
  downloadExcelForModal() {
    let fileName: any = this.title + ' ' + this.f.fromDate.value +' to '+ this.f.toDate.value +')';
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.beyondSLATable.nativeElement, {raw: true});
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100}
  ];
  
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    let sheetName = this.title === 'Application wise' ? this.title : (this.title === 'Print Wise'? this.title :(this.title === 'Dispatch Wise' ?  this.title : (this.title === 'MSP Sent' )));
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
 
  saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
  getFormat(): any {
     if (this.format === 'District Wise') {
      this.noOfCol = 7;
      return this.noOfCol;
    } else if (this.format === 'Mandal Wise') {
      this.noOfCol = 9;
      return this.noOfCol;
    } else if (this.format === 'Village Wise') {
      this.noOfCol = 10;
      return this.noOfCol;
    }
    else if (this.format === 'Service Wise') {
      this.noOfCol = 7;
      return this.noOfCol;
    }
  }
 
  getReports(from?: any): any {
    this.submitted = true;
    if (!this.form.valid){
      return false;
    } else { 
        this.page = 1;
        let district = this.f.district.value === 'All' ? 'All' : this.f.district.value?.rV_DISTRICT_ID;
        let mandal = this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.revenuE_MANDAL_CODE;
        let village = this.f.village.value === 'All' ? 'All' : this.f.village.value?.revenuE_VILLAGE_CODE;
        let services = this.f.service.value === 'All' ? 'All' : this.f.service.value?.serviceType ;
        let fromDate = this.datePipe.transform(this.commonService.formatDate(this.f.fromDate.value), 'dd/MM/yyyy')
        let toDate = this.datePipe.transform(this.commonService.formatDate(this.f.toDate.value), 'dd/MM/yyyy')
        let postData: any = '?FLAG='+ this.format +'&Districtid=' + district + '&Mandalid=' + mandal + '&Villageid=' + village  + '&Servicetype=' + services  +'&Fromdate=' + fromDate + '&ToDate=' + toDate;
        this.commonService.getRequest(this.commonConstants.getPassbookReportCount + postData).subscribe({
          next: (responseData: any) => { 
            if(typeof(responseData?.result) !== 'string') {
                this.showReports = true;
                this.items = responseData?.result;
                  this.items = this.items.filter(item => item.totalflag !== 'Y')
                  this.totalItems = responseData?.result.filter((item: { totalflag: string; }) => item.totalflag === 'Y')
            } else {
              this.items = [];
              this.totalItems = [];
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
      });
      }
    }
    getModal(item: any, modal: any, from?: any) {
      let format: any;
      if (from === 'app') {
        this.title = 'Application Wise';
        format = 'App Wise';
      } else if (from === 'print') {
        this.title = 'Print Wise';
        format = 'Print Wise';
      } else if(from === 'dispatch') {
        this.title = 'Dispatched Wise';
        format = 'Dispatched Wise';
      } else {
        this.title = 'MSP Sent Wise';
        format = 'MSP Sent';
      }
      this.modalItems = [];
      this.modalService.open(modal, { size: 'xl', centered: true, backdrop: 'static'});
          this.popupPage = 1;
          let district = item.districtid === null ? (this.f.district.value === 'All' ? 'All' : this.f.district.value?.rV_DISTRICT_ID) : item.districtid;
          let mandal = item.mandalid === null ? (this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.revenuE_MANDAL_CODE) : item.mandalid;
          let village = item.villageid === null ? (this.f.village.value === 'All' ? 'All' : this.f.village.value?.revenuE_VILLAGE_CODE) : item.villageid;
          let services = this.f.service.value === 'All' ? 'All' : this.f.service.value?.servicE_TYPE ;
          let fromDate = this.datePipe.transform(this.commonService.formatDate(this.f.fromDate.value), 'dd/MM/yyyy')
          let toDate = this.datePipe.transform(this.commonService.formatDate(this.f.toDate.value), 'dd/MM/yyyy')
          let postData: any = '?FLAG='+ format +'&Districtid=' + district + '&Mandalid=' + mandal + '&Villageid=' + village  + '&Servicetype=' + services  +'&Fromdate=' + fromDate + '&ToDate=' + toDate;
          this.commonService.getRequest(this.commonConstants.getPassbookReportCount + postData).subscribe({
            next: (responseData: any) => { 
              if(typeof(responseData?.result) !== 'string') {
                this.modalItems = responseData?.result;
              } else {
                this.modalItems = [];
              }
            },
            error: (error) => {
              console.log('client side', error);
            }
        });
    }
  goToHome() {
    this.router.navigate(['/home']);
  }

  
}
