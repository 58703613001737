import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-epassbook-view',
  templateUrl: './epassbook-view.component.html',
  styleUrls: ['./epassbook-view.component.css']
})
export class EPassbookViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  members: any[]=[];


  constructor() { }

  ngOnInit(): void {
    this.members = this.certificateInfo?.ppbDetails;
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

}
