import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-land-conversion-agri-view',
  templateUrl: './land-conversion-agri-view.component.html',
  styleUrls: ['./land-conversion-agri-view.component.css']
})
export class LandConversionAgriViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  totalConversionAmount: number = 0;
  totalChallanAmount: number = 0;
  constructor() { }

  ngOnInit(): void {
    if(this.certificateInfo?.isResurveyVillage || this.certificateInfo?.adangalDetailsList?.length > 0) {
      this.certificateInfo.isResurveyVillage = true;
      this.totalConversionAmount = this.certificateInfo.adangalDetailsList
      .filter((x:any) => x.isSelected == true )
      .reduce((sum: any, item: any) => { return sum + parseFloat(item.conversionAmount); },0);
    } else {
      this.totalConversionAmount = this.certificateInfo?.adangalDetailsListSyNo
        .reduce((sum: any, item: any) => { return sum + parseFloat(item.conversionAmount); },0);
    }
    

    this.totalChallanAmount = this.certificateInfo.challans.reduce((sum:any, item:any) => { return sum + parseFloat(item.challanAmount); },0);

  }
  viewFile(file: any) {
    this.view.emit(file);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
}
