<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Document Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Document District:</label>
                <div class="valueCls">{{certificateInfo?.certificateInfo }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">SRO:</label>
                <div class="valueCls">{{certificateInfo?.sro }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Period From (DD/MM/YYYY):</label>
                <div class="valueCls">{{certificateInfo?.periodFrom  | date: 'dd/MM/yyyy'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Period To (DD/MM/YYYY):</label>
                <div class="valueCls">{{certificateInfo?.periodTo  | date: 'dd/MM/yyyy'}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Document Number:</label>
                <div class="valueCls">{{certificateInfo?.documentNumber ? (certificateInfo?.documentNumber) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Document Year:</label>
                <div class="valueCls">{{certificateInfo?.documentYear ? (certificateInfo?.documentYear) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Buildings/Structures</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Flat Number:</label>
                <div class="valueCls">{{certificateInfo?.buildingsInfo?.doorNo ? (certificateInfo?.buildingsInfo?.doorNo) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Apartment:</label>
                <div class="valueCls">{{certificateInfo?.buildingsInfo?.streetName ? (certificateInfo?.buildingsInfo?.streetName) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{certificateInfo?.buildingsInfo?.villageWardName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Alias:</label>
                <div class="valueCls">{{ certificateInfo?.alias }}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Old HouseNo:</label>
                <div class="valueCls">{{certificateInfo?.oldHouseNo ? (certificateInfo?.oldHouseNo) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ward – Block:</label>
                <div class="valueCls">{{certificateInfo?.wardBlock ? (certificateInfo?.wardBlock) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Open Sites/Agricultural Lands</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Plot No(s):</label>
                <div class="valueCls">{{certificateInfo?.plotNo ? (certificateInfo?.plotNo) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">In Survey No(s):</label>
                <div class="valueCls">{{certificateInfo?.surveyNos ? (certificateInfo?.surveyNos) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{certificateInfo?.agriculturalLandsVillageName }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Alias:</label>
                <div class="valueCls">{{certificateInfo?.agriculturalLandsAlias }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Bounded By</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">East:</label>
                <div class="valueCls">{{certificateInfo?.boundedByEast ? (certificateInfo?.boundedByEast) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">West:</label>
                <div class="valueCls">{{certificateInfo?.boundedByWest ? (certificateInfo?.boundedByWest) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">North:</label>
                <div class="valueCls">{{certificateInfo?.boundedByNorth ? (certificateInfo?.boundedByNorth) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">South:</label>
                <div class="valueCls">{{certificateInfo?.boundedBySouth ? (certificateInfo?.boundedBySouth) : '-' }}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Extent:</label>
                <div class="valueCls">{{certificateInfo?.extent ? (certificateInfo?.extent) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Built Up:</label>
                <div class="valueCls">{{certificateInfo?.builtUp ? (certificateInfo?.builtUp) : '-' }}</div>
            </div>
        </div>
    </div> 
    <div class="btnHolder">
        <button class="greenBtnCls float-right" (click)="searchDocuments()">Search Documents</button>
    </div>
    <div class="row" *ngIf="showDocList">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                <tr> 
                    <th>S.No</th>
                    <th>Select All</th>
                    <th>Application No</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
                </table>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        </div>
    </div> 
</div>



