import { environment } from './../environments/environment';

export class CommonConstants {
  public static encryptDecryptPassword: string = 'gsws@TCS';
  public static cryptoEncryptValue : string = 'revenue/Common/RSAEncrypt?RawValue=';
  public static cryptoDecryptValue : string = 'revenue/Common/RSADecrypt?EncryptedValue=';
  public static AESEncryptNoAuth:string='revenue/Common/AESEncryptNoAuth?RawValue=';
  public static passportPattern: string = '^[a-zA-Z]{1}[0-9]{7,9}$';
  public static voterIdPattern: string = '^[a-zA-Z]{3}[0-9]{7,9}$';
  public static aadharIdPattern: string = '^[0-9]{12}$';
  public static aadharenrollmentIdPattern: string = '^[0-9]{14}$';
  public static namePattern: string = '^[a-zA-Z. ]*$';
  public static namePatternNew: string ='^[\\u0C00-\\u0C7F\\sA-Za-z0-9\\/\\\\]+$';
  public static validTeluguPattern: string ='^[\\u0C00-\\u0C7F\\sA-Za-z0-9.,\\/\\\\]+$';
  public static mobilePattern: string = '^[6-9]{1}[0-9]{9}$';
  public static fourwheelerNumber:string='^[a-zA-Z0-9 ]+$';
  public static addressPattern: string = '^[a-zA-Z0-9 .,/=&-]*$';
  public static doorNoStreetNamePattern: string = '^[a-zA-Z0-9][a-zA-Z0-9 .,/=_-]*$';
  public static namePatternSpChar: string = '^[a-zA-Z./,:() -]*$';
  public static ifscCodePattern: string = '^[A-Za-z]{4}[a-zA-Z0-9]{7}$';
  public static noSpecialCharacterPattern: string = '^[A-Za-z0-9 .]+$';
  public static panNumber: string = '^([A-Z]){5}([0-9]){4}([A-Z]){1}$';
  public static doorNoPattern: string = '^(?=.*[0-9])([a-zA-Z0-9 .,/=-]+)$';//atleast one number
  public static streetNamePattern: string = '^(?=.*[a-zA-Z])([a-zA-Z0-9 .,/=-]+)$';//atleast one alphabet
  public static emailPattern: string = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$';
  public static pincodePattern: string = '^[5]{1}[0-9]{5}$';
  public static alphanumericPattern: string = '^[a-zA-Z0-9 ]+$';
  public static alphanumWithOutSpace : string = '^[a-zA-Z0-9]+$';
  public static alphabetPattern: string = '^[a-zA-Z][a-zA-Z0-9 ]*$';
  public static rationCardPattern: any = new RegExp('^[WYPRTJA]AP[0-9]{12}$', 'i');
  public static surveyNoPattern: any = new RegExp('^[0-9][a-z0-9/-]*[0-9a-z]*$', 'i');
  public static nocPattern: any = new RegExp('^(?=[0-9a-zA-Z,]*[.-][0-9a-zA-Z,]*$)[0-9a-zA-Z,.-]+$', 'i');
  public static decimalPattern: string = '^[0-9]+(\.[0-9]{1,5})?$';
  public static decimalPatternforland: string = '^[0-9]+(\.[0-9]{1,6})?$';
  public static alphanumericwithoutspace: string = '^[a-zA-Z0-9]+$';
  public static zeroNotAllowedPattern: string = '^[1-9][0-9]*$';
  public static khataPattern: string = '^(0*[1-9]+0*)+$';
  public static onlyCapitalAlphabets: string = '^[A-Z0-9]*$'
  public static zeroNotAllowedPatternAndAlphabetsallowed: string = '^[0-9a-zA-Z][0-9a-zA-Z]*$';
  public static latLongPattern :string='^-?[0-9]{2}(?:\.[0-9]{1,10})?$';
  public static teluguCharacters: string = '^[\u0C00-\u0C7F. ]+$';
  public static requestMsg: string = 'Request has been submitted successfully with Requisition ID:';
  public static VRORIRemarks: any = ['Recommended', 'Not Recommended'];
  public static tahsildarRemarks: any = ['Recommended', 'Not Recommended'];
  public static remarksReasonsOptionsApi: any = 'workflow/api/ServiceDocuments/GetServiceRejections?ServiceCode=';
  /*API end points*/
  public static graphAPI: string = 'https://graph.microsoft.com/v1.0/me?$select=displayName,givenName,jobTitle,mail,mobilePhone,officeLocation,preferredLanguage,surname,userPrincipalName,id,businessPhones,streetAddress';
  public static getDistricts: string = 'ref-data/api/ReferenceData?EntityType=District&LanguageCode=EN-IN';
  public static getCitizenInfo_old: string = 'citizen-info/CitizenDetails?aadharNumber=';
  public static getCitizenInfo: string = 'gramawardsachivalaya/HouseholdDetailsbyUID';
  public static getCitizenInfoLive: string = 'gramawardsachivalaya/APSevaDataSharing';
  public static teluguTranslator : string = 'translate/GET_TRANSLATION'
  public static newSixStepDetails: string = 'gramawardsachivalaya/NewSixStepValidation'; //key: 52711373A28D2ABD2EB87C645CED55D762574AB6F3A443D28F7881EEEB43B6E15D524E9F8D

  public static getMandals: string = 'ref-data/api/ReferenceData?EntityType=Mandal&ParentType=District&LanguageCode=EN-IN&ParentId=';
  public static getRelations: string = 'ref-data/api/ReferenceData?EntityType=Relation&LanguageCode=EN-IN';
  public static getCaste: string = 'ref-data/api/ReferenceData?EntityType=CastCategory&LanguageCode=EN-IN';
  public static getSubCaste: string = 'ref-data/api/ReferenceData?EntityType=Subcast&ParentType=CastCategory&LanguageCode=EN-IN&ParentId=';
  public static getQualifications: string = 'ref-data/api/ReferenceData?EntityType=Qualification&LanguageCode=EN-IN';
  public static getOccupations: string = 'ref-data/api/ReferenceData?EntityType=Designation&LanguageCode=EN-IN';
  public static getRevenueOccupations: string = 'ref-data/api/ReferenceData?EntityType=REVENUEOccupation&LanguageCode=EN-IN';
  public static getNoIncomeOccupations: string = 'ref-data/api/ReferenceData?EntityType=RVNoIncomeOccupation&LanguageCode=EN-IN';
  public static getDivisions: string = 'ref-data/api/ReferenceData?EntityType=RVDivision&ParentType=RVDistrict&LanguageCode=TE&ParentId=';
  public static getByEntitycode: string = 'ref-data/api/ReferenceData/GetByEntityCode?LanguageCode=';
  public static getById: string = 'ref-data/api/ReferenceData/GetById?Id=';
  public static getReligions: string = 'ref-data/api/ReferenceData?EntityType=Religion&LanguageCode=EN-IN';
  public static getVillages: string = 'ref-data/api/ReferenceData?EntityType=Secretariat&ParentType=Mandal&LanguageCode=EN-IN&ParentId=';
  public static getFasliYear: string = 'ref-data/api/ReferenceData?EntityType=REVENUEFasali&LanguageCode=EN-IN';
  public static getOldFasliYear: string = 'ref-data/api/ReferenceData?EntityType=REVENUEFasali2009&LanguageCode=EN-IN';
  public static getReasonForDeath: string = 'ref-data/api/ReferenceData?EntityType=DeathReasons&LanguageCode=EN-IN';
  public static getFamilyMemberOccupation: string = 'ref-data/api/ReferenceData?EntityType=FamilyMemberOccupation&LanguageCode=EN-IN';
  public static getOBCCaste: string = 'ref-data/api/ReferenceData?EntityType=OBC&LanguageCode=EN-IN';
  public static getPensionSubCaste: string = 'ref-data/api/ReferenceData?EntityType=PensionSubCaste&ParentType=CastCategory&LanguageCode=EN-IN&ParentId=';
  public static getPostalOffice : string = 'ref-data/api/ReferenceData/GetByEntityCode?EntityType=PinCode&LanguageCode=EN-IN&EntityCode=';
  public static getPostalVillage : string = 'ref-data/api/ReferenceData?EntityType=PostalVillage&ParentType=PinCode&LanguageCode=EN-IN&ParentId='
  public static getCSDistrictCode : string = 'ref-data/api/ReferenceData?EntityType=CIVILDistrict&ParentType=District&LanguageCode=EN-IN&ParentId='
  public static getRVToRevenueMandal : string = 'ref-data/api/ReferenceData?EntityType=RVTOREVENUE&ParentType=RVMandal&LanguageCode=TE&ParentId='
  public static getDetailsByRevenueMandal : string = 'ref-data/api/ReferenceData/GetDetailsByRevenueMandal?RevenueMandalCode='
  public static getByEntityType : string = 'ref-data/api/ReferenceData/GetByEntityType?EntityType=';
  public static getDistrictSecretariatDetailsbyMandal : string = 'ref-data/api/ReferenceData/GetDistrictSecretariatDetailsbyMandal?MandalCode=';
  public static checkRuralUrban : string = 'workflow/api/WorkList/CheckSecretariateIsRuralOrUrban?SecretariatCode=';
  public static getCertificateDetails: string = 'revenue/revenue/Requisition/GetById?DepartmentCode=';  //RV&IncludeFileUploadInfo=true&IncludePaymentDetails=false&RequisitionId=';
  public static getJSVenueScheduleDetailsByAadharSecCode: string = 'revenue/revenue/JaganannaSuraksha/GetJSVenueScheduleDetailsByAadharSecCode?SecCode=';
  public static getJSTokenDetailsInViewTransactions: string = 'revenue/revenue/JaganannaSuraksha/GetJSTokenDetailsInViewTransactions?RequisitionId=';
  public static fileUpload: string = 'file-upload/api/FileStorage/Upload';
  public static updateFileUpload: string = 'revenue/revenue/Requisition/UpdateFileUploadInfo';
  public static updateCosmosSumotoData: string = 'revenue/revenue/SuoMotoIC/UpdateSuomotoIntegratedCetificateInfo';
  public static GetCitizenDailyTransactionsCount: string = 'revenue/revenue/Requisition/GetCitizenDailyTransactionsCount';
  public static viewFile: string = 'file-upload/api/FileStorage/GetFile?BlobRef=';
  public static fileDownload: string = 'file-upload/api/FileStorage/Download?BlobRef=';
  public static getWorklist: string = 'workflow/api/WorkList?pageIndex=1&pageSize=10';
  public static GetJSBulkIncomeIntegrateData: string = 'workflow/api/DashBoard/GetJSBulkIncomeIntegrateData?userId=';
  public static GetJsTokenMasterDataById: string = 'revenue/revenue/JaganannaSuraksha/GetJsTokenMasterDataById?RequisitionId=';
  public static approveReject: string = 'workflow/api/WorkList';
  public static getTaskDetails: string = 'workflow/api/WorkList/taskdetails?taskId=';
  public static getWorkItemHistory: string = 'workflow/api/WorkList/GetWorkItemHistory?WorkItemType=';
  public static getApprovedWorkItem: string = 'workflow/api/WorkList/GetApprovedWorkItem?';
  public static saveRequisitionDetails: string = 'workflow/api/DashBoard/SaveRequisitionDetails';
  public static updateTransactionStatusInRequisitionDetails: string = 'workflow/api/DashBoard/UpdateTransactionStatusInRequisitionDetails';
  public static saveSadaremPrintCertificateDetails: string = 'revenue/revenue/CategoryACertificate/SaveSadaremPrintCertificateDetails'
  
  public static getUserWiseDashboardDetails: string = 'reports/api/DashBoard/GetUserWiseRequisitionDetails?UserId=';
  public static getAadharChallanDetailsReport: string = 'reports/api/DashBoard/GetAadharChallanTransactionReportData';
  public static getUserWiseRequisitionDetailsPaged: string = 'reports/api/DashBoard/GetUserWiseRequisitionDetailsPaged';
  public static GetIdhiManchiPrabutvamSurveyData: string = 'reports/api/IdhiManchiPrabutvamSurveyDetails/GetIdhiManchiPrabutvamSurveyData';
  public static getUCReportData: string = 'reports/api/UtilizationCertificate/GetUtilizationCertificateReport';
  public static getVolunteerReportData: string = 'reports/api/VolunteerAppreciationProgram/GetVolunteerAppreciationProgramReport';
  
  public static getRequestRaisedByUser: string = 'reports/api/DashBoard/GetRequestRaisedByUser?';
  public static getRequestRaisedByUserPaged: string = 'reports/api/DashBoard/GetRequestRaisedByUserPaged';
  
  public static getRequestRaisedForUser: string = 'reports/api/DashBoard/GetRequestRaisedForUser?';
  public static getRequestRaisedForUserPaged: string = 'reports/api/DashBoard/GetRequestRaisedForUserPaged';
  public static getOpenRequests: string = 'reports/api/DashBoard/GetVRODashBoardApplicationDetails';
  public static getVRODashBoardDetails: string = 'reports/api/DashBoard/GetVRODashBoardDetailsCount?';
  public static getDADashBoardDetailsCount: string = 'reports/api/DashBoard/GetDADashBoardDetailsCount';
  public static getVRODashBoardDetailsList: string = 'reports/api/DashBoard/GetVRODashBoardDetailsList?';
  public static getDADashBoardDetailsList: string = 'reports/api/DashBoard/GetDADashBoardDetailsList?';
  public static getDashBoardApplicationDetails: string = 'reports/api/DashBoard/GetDashBoardApplicationDetails';
  // public static GetApplicationsDetailsByUID:string='reports/api/DashBoard/GetApplicationsDetailsByUID?UID='
  public static GetApplicationsDetailsByUIDPost:string='reports/api/DashBoard/GetApplicationsDetailsByUIDPost'
  public static getMutationForCorrection: string='reports/api/MutationForCorrections/GetMutationForCorrections';
  public static getMutationForTransaction: string='reports/api/MutationForTransactions/GetMutationForTransactions';
  public static getFailedTransactionDetails: string='reports/api/DailyTransactionDetails/GetFailedTransactionDetails';
  public static getNextUserRole: string = 'workflow/api/Worklist/GetUserRole?TaskId=';
  public static validateSumotoSpecialCaste: string = 'workflow/api/WorkList/GetSuoMotoICSpecialCaste';
  public static getPrintedCertificate: string = 'workflow/api/UserDetails/GetPrintedCertificate';
  public static savePrintedCertificate: string = 'workflow/api/UserDetails/SavePrintedCertificate';
  public static getSecretariatRankingDetails: string = 'reports/api/DashBoard/GetSecretariatRankingDetails';
  public static getReportsData: string = 'reports/api/DashBoard/GetDashBoardSummary?';
  public static getTopTenServices: string = 'reports/api/DashBoard/GetTopTenServices';
  public static getCSPDistricts: string = 'reports/api/CSPServiceDashboard/GetDistrict';
  public static getCSPMandals: string = 'reports/api/CSPServiceDashboard/GetMandal';
  public static getCSPMandalsRuralUrban: string = 'reports/api/CSPServiceDashboard/GetMandalByDistrictRuralUrban';
  public static getCSPDeparments: string = 'reports/api/CSPServiceDashboard/GetDepartment';
  public static getCSPSecretariat: string = 'reports/api/CSPServiceDashboard/GetSecretariatByMandal';
  public static getCSPService: string = 'reports/api/CSPServiceDashboard/GetServiceByDepartment';
  public static getCSPServiceDashboardData: string = 'reports/api/CSPServiceDashboard/GetCSPServiceDashboardData';
  public static getCSPRevenueMandal: string = 'reports/api/CSPServiceDashboard/GetRevenueMandalByDistrict';
  public static getPassbookReportData: string = 'reports/api/MutationForCorrections/GetMspReportByData';
  public static GetServiceTypes: string = 'reports/api/MutationForCorrections/GetServiceTypes';
  public static GetPensionSixstepParameterUpdateReport: string = 'reports/api/PensionsReport/GetPensionSixstepParameterUpdateReport';
  public static getPassbookReportCount: string = 'reports/api/MutationForCorrections/GetMspReportBySummary';
  public static getJASDistricts: string = 'reports/api/JASReport/GetDistrict';
  public static getJASMandalsRuralUrban: string = 'reports/api/JASReport/GetMandalByDistrictRuralUrban';
  public static getJASVHCS: string = 'reports/api/JASReport/GetVHCByMandal';
  public static getJASVHCSecretariat: string = 'reports/api/JASReport/GetVHCSecretariatByMandal';
  public static getPHCMandal: string = 'reports/api/JASReport/GetPHCByMandal';
  public static getVHCByPHC: string = 'reports/api/JASReport/GetVHCByPHC';
  public static GetRevenueDistrictMSP: string = 'reports/api/MutationForCorrections/GetRevenueDistrictMSP';
  public static GetRevenueMandalByDistrictMSP: string = 'reports/api/MutationForCorrections/GetRevenueMandalByDistrictMSP';
  public static GetRevenueVillageByMandalMSP: string = 'reports/api/MutationForCorrections/GetRevenueVillageByMandalMSP';
  public static GetPendingChallanAmountByDA : string = 'reports/api/DashBoard/GetPendingChallanAmountByDA';
  public static GetPPWelfareSchemeReport: string = 'reports/api/JASReport/GetPPWelfareSchemeReport';
  public static getViksitBharatReport : string = 'reports/api/VikasithBharath/GetVBSY_DIST_BALANCE_REPORT';
  public static getSurakshaCampDetails: string = 'HealthandFamily/HealthAndFamily/JaganannaAarogyaSuraksha/GetJASTeamDetailsByVHCScheduledDate';
  public static tokenRegAarogyaSuraksha: string = 'HealthandFamily/HealthAndFamily/JaganannaAarogyaSuraksha/SaveTokenDetails';
  public static getPaymentReportData: string = 'reports/api/DashBoard/GetPaymentDashboard';
  public static getOTSPaymentReportData: string = 'reports/api/DashBoard/GetOtsPaymentData';
  public static getPanCardReportData: string = 'reports/api/PanCardReport/GetPanCardReport';
  public static GetViksitBharatDistrict : string = 'reports/api/VikasithBharath/GetDistrict';
  public static GetViksitBharatMandalByDistrictCode : string = 'reports/api/VikasithBharath/GetMandalByDistrict';
  public static GetViksitBharatGpByMandal: string = 'reports/api/VikasithBharath/GetGPByMandal';
  public static getDaynodalIECReport : string ='reports/api/VikasithBharath/GetDaynodalIECReport';
  public static GetJaganannaSurakshaCampStatusReport : string = 'reports/api/JaganannaSurakshaReport/GetJSCampStatusReport';
  public static getMaudRequisitionById: string = 'gswsmaudapiwebw/maud/Requisition/GetMaudRequisitionById?'; 
  public static getSNoForBiometric: string = 'gswsmaudapiwebw/api/GswsToVswsService/BiometricByAadhar';
  public static GetRevenueDivisionByDistrict: string = 'reports/api/CSPServiceDashboard/GetRevenueDivisionByDistrict?DistrictCode=';
  public static getServiceList: string = 'workflow/api/ServiceConfig';
  public static getReissuanceReport: string = 'reports/api/SuoMotoICReport/GetReissuanceIntegratedReport';
  public static getDocumentList: string = 'workflow/api/ServiceDocuments/GetServiceDocuments?ServiceCode=';
  public static getReasonsByServiceCode: string = 'ref-data/api/ServiceData/GetReasonsByServiceCode?Service_Code=';
  public static getSubReasonsByReasonId: string = 'ref-data/api/ServiceData/GetSubReasonsByReasonId?Reason_Id=';
  public static getVillagesForSecretariatMapping: string= 'reports/api/Mapping/GetVillageSecretariatMapping'
  public static getSecPanUpdatedReport: string= 'reports/api/PanCardReport/GetSecretariatPanReport'
  public static getSecRevVilgMappingReport: string= 'reports/api/Mapping/RV_VILLAGE_SEC_MAPPING_REPORT'  
  public static getHouseSiteDistricts: string = 'ref-data/api/ReferenceData?EntityType=HousesiteDistrict&LanguageCode=EN-IN';
  public static getHouseSiteMandals: string = 'ref-data/api/ReferenceData?EntityType=HousesiteMandal&ParentType=HousesiteDistrict&LanguageCode=EN-IN&ParentId=';
  public static getHouseSiteVillages: string = 'ref-data/api/ReferenceData?EntityType=HousesiteVillage-New&ParentType=HousesiteMandal&LanguageCode=EN-IN&ParentId=';
  public static getHouseSitePanchayat: string = 'ref-data/api/ReferenceData?EntityType=HousesitePanchayat&ParentType=HousesiteVillage-New&LanguageCode=EN-IN&ParentId=';

  public static laminationOptions : string = 'ref-data/api/ReferenceData?EntityType=Lamination&LanguageCode=EN-IN';
  
  public static registrationService : string = 'redirectionservice/RedirectionService/New';
  public static CitizenSurveyData: string = 'GSWSCITIZEN/CitizenDetails/CitizenSurveyData';
  public static InsertCitizenSurveyData: string = 'GSWSCITIZEN/CitizenDetails/InsertCitizenSurveyData';
  public static MeesevaCitizenSurveyData: string = 'GSWSCITIZEN/CitizenDetails/MeesevaCitizenSurveyData';
  public static InsertMeesevaCitizenSurveyData: string = 'GSWSCITIZEN/CitizenDetails/InsertMeesevaCitizenSurveyData';
//NCS APIS

public static JobSeekerRegistration: string = 'gswscivilsuppliesapiwebw/CivilSupplies/EmploymentExchange/JobSeekerRegistration';
public static EXNCSAuthenticateUser: string = 'EXNCS/AuthenticateUser';
public static EXNCSRegisterJobseekers: string = 'EXNCS/RegisterJobseekers';
public static EKYCValidationNew = 'comms-vm/VolunteerMobileApp/api/gsws/EkycValidationNew';

  // good government apis
  public static GetHouseHoldSurveyDetails: string = 'GSWSCITIZEN/IdiMannciPrabhutvamSurveyController/IdiMannciPrabhutvamSurvey/GetHouseHoldSurveyDetails?SecretariatCode=';
  public static SaveHouseHoldSurveyDetails: string = 'GSWSCITIZEN/IdiMannciPrabhutvamSurveyController/IdiMannciPrabhutvamSurvey/SaveHouseHoldSurveyDetails';
  public static getHouseSiteDesignation: string = 'ref-data/api/ReferenceData?EntityType=100_Designation&LanguageCode=EN-IN';
  public static UpdateHouseHoldSurveyDetails: string = 'GSWSCITIZEN/IdiMannciPrabhutvamSurveyController/IdiMannciPrabhutvamSurvey/UpdateHouseHoldSurveyDetails';
  public static DeleteHouseHoldSurveyDetails: string = 'GSWSCITIZEN/IdiMannciPrabhutvamSurveyController/IdiMannciPrabhutvamSurvey/DeleteHouseHoldSurveyDetails';  
 
  //Revenue screen APIS
  public static computerizedAdangal: string = 'revenue/revenue/CatBStraightThrough/ComputerizedAdangalCertificate';
  public static integratedCertificate: string = 'revenue/revenue/CategoryBCertificate/IntegratedCertificate';
  public static incomeCertificate: string = 'revenue/revenue/CategoryBCertificate/IncomeCertificate';
  public static familyMemberCertificate: string = 'revenue/revenue/CategoryBCertificate/FamilyMemberCertificate';
  public static issuanceIncomeAssetCertificate: string = 'revenue/revenue/CategoryBCertificate/IncomeAssetCertificateForEWS';
  public static issuanceOfSmallMarginalFarmerCertificate: string = 'revenue/revenue/CatBStraightThrough/SnMFarmerCertificate';
  public static obcCertificate: string = 'revenue/revenue/CategoryBCertificate/OBCCertificate';
  public static lateRegistration: string = 'revenue/revenue/CategoryBCertificate/BirthDeathCertificate';
  public static agricultureIncomeCertificate: string = 'revenue/revenue/CatBStraightThrough/AgricultureIncomeCertificate';
  public static possessionCertificate: string = 'revenue/revenue/CategoryBCertificate/PossessionCertificate';
  public static noEarningCertificate: string = 'revenue/revenue/CategoryBCertificate/NoEarningMemberCertificate';
  public static manualAdangal: string = 'revenue/revenue/CatBStraightThrough/ManualAdangalCertificate';
  public static additonOfSurveyNoinAdangal: string = 'revenue/revenue/CatBStraightThrough/InsertSurveyNumInAdangal';
  public static fmbCopyCertifcate: string = 'revenue/revenue/CategoryBCertificate/FMBCopy';
  public static fLineNewApplication: string = 'revenue/revenue/CatBStraightThrough/FLineApplication';
  public static mutationTitle: string = 'revenue/revenue/CatBStraightThrough/MutationNTitleCumPPB';
  public static ePassbookReplacementPPB: string = 'revenue/revenue/CatBStraightThrough/ReplacementPPB';
  public static printingTitleDeedCertificate: string = 'revenue/revenue/CategoryACertificate/PrintTitleDeedCumPPB';
  public static pattaSubDivisionforAutoMutationProcess: string = 'revenue/revenue/CategoryACertificate/PattaSubDivisionForAutoMutation';
  public static landCoversionNewApi: string = 'revenue/revenue/CatBStraightThrough/LandConversionNewCertificate';
  public static ror1bCertificate: string = 'revenue/revenue/CatBStraightThrough/ROR1BCertificate';
  public static waterTaxPaymentCertificate: string = 'revenue/revenue/CategoryACertificate/WaterTaxPaymentService';
  public static encumbranceCert: string = 'revenue/revenue/CatBStraightThrough/EncumbranceCertificate';
  public static titleDeedCertificate: string = 'revenue/revenue/CatBStraightThrough/TitleDeedCumPPB';
  public static RoRFormRectification: string = 'revenue/revenue/CatBStraightThrough/RoRFormRectification';
  public static pattadarMobileNoSeed: string = 'revenue/revenue/CategoryACertificate/PattadarMobInLandRecords';
  public static changeOfNameApplication: string = 'revenue//revenue/CategoryBCertificate/ChangeOfNameCertificate';
  public static extractOfHouseSiteOrDform: string = 'revenue/revenue/CategoryBCertificate/HouseSiteDFormPatta';
  public static extractOfNOCunderPetroleumAct: string = 'revenue/revenue/CatBStraightThrough/ExtractOfNOCUnderExplosiveOrPetroleum';
  public static permissionOfDigginOfBoreWell: string = 'revenue/revenue/CatBStraightThrough/PFDAgriculturalwellOrDrinkingwaterRequisition';
  public static noPropertyCertificate: string = 'revenue/revenue/CategoryBCertificate/NoPropertyCertificate';
  public static renewalOfCinemaLicence: string = 'revenue/revenue/CategoryBCertificate/RenewalCinemaLicence';
  public static issueOfOccupancyRights: string = 'revenue/revenue/CategoryBCertificate/OccupancyRightsCertificateForInamLands';
  public static issueOfNocForStoringProducts: string = 'revenue/revenue/CatBStraightThrough/IssueOfNOCToStoringPetroleumProductsRequisition';
  public static crackerLicenceApplication: string = 'revenue/revenue/CategoryBCertificate/ExplosiveMaterialLicence';
  public static reIssuanceCertificate: string = 'revenue/revenue/CategoryACertificate/ReIssueIntegratedCertificate';
  public static reIssuanceNewCertificate: string = 'revenue/revenue/CategoryACertificate/ReIssuanceOfNewCertificates';
  public static getIncomeCertificateGSWS: string = 'revenue/revenue/Requisition/GetOldIncomeCertificateData';
  public static getIntegratedCertificateGSWS: string ='revenue/revenue/Requisition/GetOldIntegratedCertificateData';
  public static getOldMeesevaIntegratedCertificateData: string = 'revenue/revenue/Requisition/GetOldMeesevaIntegratedCertificateData';
  public static ValidateCasteCertificate: string = 'revenue/Common/ValidateCasteCertificate';
  public static RejectSuoMotoApplication : string = 'revenue/revenue/SuoMotoIC/RejectSuoMotoApplication';
  public static getLRBDCertificateGSWS: string ='revenue/revenue/Requisition/GetOldBirthDeathCertificateData';
  public static getFMBCertificateGSWS: string ='revenue/revenue/Requisition/GetOldFamilyMemberCertificateData';
  public static pattadarAadharSeedForLPM: string ='revenue/revenue/CatBStraightThrough/AadhaarSeedingforLPNumbers';
  public static getTiffFile: string ='revenue/revenue/Requisition/ConvertByteToTif';
  public static modiifcationsIn22AList : string = 'revenue/revenue/CategoryBCertificate/ModificationIn22AList';
  public static dottedLands : string = 'revenue/revenue/CategoryBCertificate/DottedLands';
  public static getTokenFromMeeseva: string = 'meesevawebservice';
  public static getReissuanceDetails: string = 'meesevawebservice';
  public static updateBirthDeathReq: string = 'revenue/revenue/Requisition/UpdateBirthDeathCetificateInfo';
  public static updateIntegratedReq: string = 'revenue/revenue/Requisition/UpdateIntegratedCetificateInfo';
  public static updateIncomeReq: string = 'revenue/revenue/Requisition/UpdateIncomeCetificateInfo';
  public static updateFamilyMemberReq: string = 'revenue/revenue/Requisition/UpdateFamilyMemberCertificateInfo';
  public static panchayat: string = '[a-zA-Z]+[]*$';
  public static housingSiteApplication: string = 'revenue/revenue/CategoryBCertificate/HousingSiteApplicationForm';
  public static getAccountDetails: string = 'revenue/Common/GetBankAccountDetails?UserId=';
  public static saveAccountDetails: string = 'revenue/Common/SaveBankAccountDetails';
  public static verifySignatureExist = 'revenue/Common/VerifySignatureExist?UserId=';
  public static updateManualAdangalReq = 'revenue/revenue/Requisition/UpdateManualAdangalCetificateInfo';
  public static householdSplitApplication: string = 'revenue/revenue/CategoryBCertificate/HouseHoldSplitApplication';
  public static householdSplitAppStatus: string = 'workflow/api/WorkFlowStatusUpdate/GetPendingApplicationbyHouseHoldId?HouseHoldId='
  public static updateHouseSiteCetificateInfo: string = 'revenue/revenue/Requisition/UpdateHouseSiteCetificateInfo';
  public static mutationDuplicateCheck: string = 'revenue/revenue/Requisition/GetRequisitionStatusBasedOnSurveyNo';
  public static mutationcorrectionResurvey: string = 'revenue/revenue/CategoryBCertificate/ReSurveyOfMutationForCorrection';
  public static mutationTransactionsWithLPMNumbers = 'revenue/revenue/CategoryBCertificate/MutationTransactionsWithLPMNumbers';
  public static saveTeamDetails: string = 'revenue/revenue/JaganannaSuraksha/SaveTeamDetails';
  public static saveTeamScheduleDetails: string = 'revenue/revenue/JaganannaSuraksha/SaveTeamScheduleDetails';
  public static getJSTeamDetails: string = 'revenue/revenue/JaganannaSuraksha/GetJSTeamDetails?UserId=';
  public static getTeamScheduleDetails: string = 'revenue/revenue/JaganannaSuraksha/GetTeamScheduleDetails?UserId=';
  public static getJaganAnnaArogyaSurakshaTeamDetails: string = 'HealthandFamily/HealthAndFamily/JaganannaAarogyaSuraksha/GetJASTeamDetails?UserId=';
  public static saveJaganAnnaArogyaSurakshaTeamDetails: string = 'HealthandFamily/HealthAndFamily/JaganannaAarogyaSuraksha/SaveTeamMemberDetails';
  public static saveWelfareDetails: string = 'HealthandFamily/HealthAndFamily/JaganannaAarogyaSuraksha/SaveWelfareSchemeDisplayDetails';
  public static getWelfareSchemeDispalyDetails: string = 'HealthandFamily/HealthAndFamily/JaganannaAarogyaSuraksha/GetWelfareSchemeDispalyDtls?MandalId=';
  public static getWelfareSchmeRescheduleDetails: string = 'HealthandFamily/HealthAndFamily/JaganannaAarogyaSuraksha/GetWelfareSchmeRescheduleDtls?';
  public static getPENSIONReportData: string = 'reports/api/PensionsReport/PENSION_REVERTED_REPORT';
  public static validateRevenueSadassulu: string = 'revenue/revenue/RevenueSadassulu/ValidateRevenueSadassulu';
  public static updateRevenueSadassulu: string = 'revenue/revenue/RevenueSadassulu/UpdateRevenueSadassulu';
  
  //public static updateJaganAnnaArogyaSurakshaTeamDetails: string = 'HealthandFamily/HealthAndFamily/JaganannaAarogyaSuraksha/UpdateDeleteTeamMemberDetails';
  public static getTeamCountForAarogyaSuraksha: string = 'HealthandFamily/HealthAndFamily/JaganannaAarogyaSuraksha/GetJASTeamListByMandalId?MandalId=';
  public static getTeamCountForAarogyaSurakshaByUserId: string = 'HealthandFamily/HealthAndFamily/JaganannaAarogyaSuraksha/GetJASTeamListByUserId?UserId=';
  public static getDMHOTeamScheduleList: string = 'HealthandFamily/HealthAndFamily/JaganannaAarogyaSuraksha/GetJASDMHOTeamScheduleDetails?';
  public static mutationResurveyMaster: string = 'uatwebland/W2L0/Master';
  public static mutationResurveyToken: string = 'uatwebland/api/Token';
  public static zswolpmnumberfetch: string = 'uatwebland/CGS/GovLands';
  public static zswotoken: string = 'uatwebland/api/Token/zswo';
  public static postMutationResurvey: string = 'uatwebland/W2L0/Ipost';
  public static postMutationResurveyTest: string = 'uatwebland/W2L0/Ipost';
  public static postPattadarAadharSeed: string = 'uatwebland/gsws/profile';
  public static postMutationTransactionsWithLPMNo: string = 'uatwebland/W2L0/MNPOST';
  public static issuanceOfBHP: string = 'revenue/revenue/CategoryACertificate/BhuHakkuPatram';
  public static tokenGenerationBHP: string = 'BHP/api/Token';
  public static masterBHP: string = 'BHP/W2L0/Master'; 
  public static RORDetailsBHP: string = 'BHP/W2L0/BHPROR';
  public static postDetailsBHP: string = 'BHP/W2L0/BHP';
  public static housingSiteValidation : string = 'revenue/revenue/Requisition/CheckHouseSiteRequestStatusByAaadharNo';
  public static integratedValidation : string = 'revenue/revenue/Requisition/CheckExistingIntegratedCertificateByAaadharNo';
  public static incomeValidation : string = 'revenue/revenue/CategoryBCertificate/CheckIncomeRequestStatusByAaadharNo';
  public static issuanceValidation : string = 'revenue/revenue/Requisition/CheckExistingEWSCertificateByAaadharNo';
  public static CFMSChallanStatus : string = 'EXCFMS/RESTAdapter/CFMS/ChallanStatus';
  public static VSWS_GetToken : string = 'exmeeseva/VSWSWEBAPI/api/VSWS/VSWS_GetToken';
  public static VSWS_GetLCChallanNo : string = 'exmeeseva/VSWSWEBAPI/api/VSWS/VSWS_GetLCChallanNo';
  public static LandConversionAgriToNonAgri : string = 'revenue/revenue/CatBStraightThrough/LandConversionAgriToNonAgri';
  public static LPMMarketValue : string = 'LPMGSWS/restAdapter/LPM-MARKETVALUE';
  public static SyNoMarketValueToken : string = 'PDI/v1/users/os/token';
  public static SyNoMarketValue : string = 'PDI/v1/ob/mvCalbyClassification';
  public static getRORDataForLandConversionSyNo : string = 'conversion/LC_Deemed_KhataDetails';
  public static GPADetails : string = 'LPMGSWS/restAdapter/GPA_DETAILS';
  public static LandConversionPost : string = 'uatwebland/W2L0/LCV1';
  public static landConversionSurveyNoDetailsPost : string = 'conversion/LandConversion227ApplicationFiling';
  public static GetRequisitionStatusBasedOnChallanNo : string = 'revenue/revenue/Requisition/GetRequisitionStatusBasedOnChallanNo?ChallanNumber=';
  public static getJSDepartment: string = 'ref-data/api/ReferenceData?EntityType=JS_DEPARTMENT&LanguageCode=EN-IN';
  public static getJSServices: string = 'ref-data/api/ReferenceData?EntityType=JS_SERVICE&ParentType=JS_DEPARTMENT&LanguageCode=EN-IN&ParentId=';
  public static RegistrationofTokens: string = 'revenue/revenue/CategoryACertificate/RegistrationofTokens';
  public static CheckIfJSServiceIsAvailableToUser: string = 'revenue/revenue/JaganannaSuraksha/CheckIfJSServiceIsAvailableToUser?SecretariatCode=';
  public static DeleteJSToken: string = 'revenue/revenue/JaganannaSuraksha/DeleteJSToken';

  public static getNCSReligion: string = 'ref-data/api/ReferenceData?EntityType=NCS_Religion&ParentType=Religion&LanguageCode=EN-IN&ParentId=';
  public static getNCSDistrict: string = 'ref-data/api/ReferenceData?EntityType=NCS_DISTRICT&ParentType=DISTRICT&LanguageCode=EN-IN&ParentId=';
  public static getNCSMandal: string = 'ref-data/api/ReferenceData?EntityType=NCS_LGD_MANDAL&ParentType=MANDAL&LanguageCode=EN-IN&ParentId=';
  public static getPurpose: string = 'ref-data/api/ReferenceData?EntityType=IncomePurpose&LanguageCode=EN-IN';
  public static getOldMeesevaIncomeCertificateData: string = 'revenue/revenue/Requisition/GetOldMeesevaIncomeCertificateData';
    //Lamination 
  public static lamination: string = 'revenue/revenue/CategoryACertificate/LaminationService';
  public static printCertificate: string = 'revenue/revenue/CategoryACertificate/PrintCertificate'
  public static addressCertificateForAadharEnrollment: string = 'revenue/revenue/CategoryBCertificate/AddressCertificateForAadharEnrollment'

  // search screen caste API's

  public static getTokenForSearchScreen = 'exmeeseva/VSWSWEBAPI/api/VSWS/VSWS_GetCasteToken';
  public static getSearchCasteDetails = 'exmeeseva/VSWSWEBAPI/api/VSWS/VSWS_GetSearchCasteDetails';
  public static getVSWSCasteCertificate = 'exmeeseva/VSWSWEBAPI/api/VSWS/VSWS_GetCasteCertificate';
  public static UpdateMeesevaIntegratedData = 'revenue/revenue/Requisition/UpdateMeesevaIntegratedData';

  // search screen districts,mandals, villages

public static getRevenueDistrictSearch: string = 'ref-data/api/ReferenceData/GetByEntityType?LanguageCode=EN-IN&EntityType=REVENUEDISTRICT';
public static getRevenueMandalSearch: string = 'ref-data/api/ReferenceData/GetAllSubChildsByParentId?EntityType=RevenueDistrict&ChildEntityType=RevenueDivision&SubChildEntityType=RevenueMandal&Level=2&LanguageCode=EN-IN&ParentId=';
public static getRevenueVillagesSearch: string = 'ref-data/api/ReferenceData?EntityType=REVENUEVillage&ParentType=REVENUEMandal&LanguageCode=EN-IN&ParentId=';

  public static landAssignmentForSoldiers: string = 'revenue/revenue/CategoryBCertificate/ServicePersonAndSoldierDetails';
  // Civil Supplies screen APIS
  public static newRiceCard: string = 'gswscivilsuppliesapiwebw/CivilSupplies/RiceCard/NewRiceCard';
  public static marriageSplitRiceCard: string = 'gswscivilsuppliesapiwebw/CivilSupplies/RiceCard/MarriageSplitRiceCard';
  public static getDataById: string = 'gswscivilsuppliesapiwebw/Requisition/GetById?RequisitionId=CS-SplittingRiceCard-1&RequisitionType=SplittingRiceCard&IncludeFileUploadInfo=false&IncludePaymentDetails=false';
  public static splittingRiceCard: string = 'gswscivilsuppliesapiwebw/CivilSupplies/RiceCard/SplittingRiceCard';
  public static surrenderRiceCard: string = 'gswscivilsuppliesapiwebw/CivilSupplies/RiceCard/SurrenderOfRiceCard';
  public static modifyRiceCard: string = 'gswscivilsuppliesapiwebw/CivilSupplies/RiceCard/ModifyRiceCard';
  public static cancelRiceCard : string = 'gswscivilsuppliesapiwebw/CivilSupplies/RiceCard/CancelRiceCard';
  public static correctionOfWrongAadhar : string = 'gswscivilsuppliesapiwebw/CivilSupplies/RiceCard/CorrectionOfWrongAadharSeeding'
  public static getcivilsuppliesbyId: string = 'gswscivilsuppliesapiwebw/civilsupplies/Requisition/GetById';
  public static getApplicantDetailsByAadharNo: string = '/gswscivilsuppliesapiwebw/civilsupplies/Requisition/GetApplicantDetailsByAadharNo';
  public static changeOfAddressInRiceCard : string = 'gswscivilsuppliesapiwebw/CivilSupplies/RiceCard/ChangeOfAddressInRiceCard';
  public static fpShop: string ='gswscivilsuppliesapiwebw/CivilSupplies/RiceCard/FPShopRenewalApplication';
  public static generateCertificateForCivilSupplies : string ='gswscivilsuppliesapiwebw/civilsupplies/Requisition/GenerateCertificateData';
  public static generateFPShopValidity: string ='gswscivilsuppliesapiwebw/civilsupplies/Requisition/GenerateFPShopValidity';
  public static byPassEKYC: string = 'gswscivilsuppliesexternal/ricecard/ekycservice/getmemberlist'
  // revenue village mapping.
  public static villagemappingTahsildhar: string = 'reports/api/Mapping/RVVillageSecretariatMappingByTehsildar';
  //marriage certificate
  public static marriageCertificate: string = 'revenue/revenue/Registration/MarriageRegistration';
  public static existingMarriageCertificate: string = 'revenue/revenue/Requisition/CheckExistingMarriageCertificateByAaadharNo';
  // Muslim Marriage certificate
  public static muslimMarriageCertificate: string = 'revenue/revenue/Registration/MuslimMarriageRegistration';
  public static getQaziDistrictList: string = 'revenue/revenue/Registration/GetQaziDistrictList';
  public static getQaziMandalListByDistrict = 'revenue/revenue/Registration/GetQaziMandalListByDistrict?DistrictCode=';
  public static getAllQaziMasterDetails: string = 'revenue/revenue/Registration/GetAllQaziMasterDetails';
  public static updateMMCApprovedData = 'revenue/revenue/Registration/UpdateMuslimMarriageCertApprovedData';
  //MAUD Screen APIS
  // public static maudFileUploadInfo : string = 'gswsmaudapiwebw.azurewebsites.net/maud/Requisition/UpdateFileUploadInfo'
  public static maudBirthCertificate: string = 'gswsmaudapiwebw/maud/CategoryACertificate/BirthCertificate';
  public static maudDeathCertificate: string = 'gswsmaudapiwebw/maud/CategoryACertificate/DeathCertificate';
  public static maudChildNameInclusion: string = 'gswsmaudapiwebw/maud/CategoryBCertificate/ChildNameInclusion';
  public static maudCorrectionsInBirth: string = 'gswsmaudapiwebw/maud/CategoryBCertificate/CorrectionInBirth';
  public static maudCorrectionsInDeath: string = 'gswsmaudapiwebw/maud/CategoryBCertificate/CorrectionInDeath';
  public static maudNonAvailabilityOfBirth : string = 'gswsmaudapiwebw/maud/CategoryBCertificate/NonAvailabilityBirth';
  public static maudNonAvailabilityOfDeath : string = 'gswsmaudapiwebw/maud/CategoryBCertificate/NonAvailabilityDeath';
  public static maudFileUpload : string = 'gswsmaudapiwebw/maud/Requisition/UpdateFileUploadInfo';
  public static maudPostforApprovedCertificate: string = 'gswsmaudapiwebw/maud/Requisition/GenerateCertificateData';
 //PAN Deatils collection APIS
  public static getPanDetails: string = 'GSWSCITIZEN/CitizenInfo/PANDetails/GetPANDetails';
  public static savePanDetails: string = 'GSWSCITIZEN/CitizenInfo/PANDetails/SavePANDetails';
  public static updatePanDetails: string = 'GSWSCITIZEN/CitizenInfo/PANDetails/UpdatePANDetails';
 
  // UTILIZATION-CERTIFICATE APIs

  public static getProgramList: string ='GSWSCITIZEN/UtilizationCertificate/UtilizationCertificateUpload/GetProgramList?DesignationCode=';
  public static getUtilizationUploadDetails : string ='GSWSCITIZEN/UtilizationCertificate/UtilizationCertificateUpload/GetUtilizationCertficateUploadDetails?SecretariatCode=';
  public static getUtilizationAmount : string ='GSWSCITIZEN/UtilizationCertificate/UtilizationCertificateUpload/GetUtilizationCertficateProgramAmount?SecretariatCode=';
  public static saveUtilizationUploadData: string ='GSWSCITIZEN/UtilizationCertificate/UtilizationCertificateUpload/SaveUtilizationCertficateUploadDetails';
  public static fileUploadForUtilization: string ='GSWSCITIZEN/UtilizationCertificate/UtilizationCertificateUpload/UploadUtilizationCertficateDocuments';
  //Audit log API
  public static SaveLoginLogoutLogAPI: string = 'GSWSCITIZEN/CitizenDetails/InsertUserAuditDetails';
  public static SaveLoginTime: string = 'GSWSCITIZEN/CitizenInfo/UserDetails/SaveUserLoginDetails';
  public static GetUserLogDetails: string = 'GSWSCITIZEN/CitizenInfo/UserDetails/GetUserLogDetails';
  //MAUD Screen Master data APIS
  public static BirthCatB1a2 : string = 'cdma/BirthCatB1a2';
  public static BirthCatB3 : string = 'cdma/BirthCatB3';
  public static BirthCorrections : string = 'cdma/BirthCorrections';
  public static BirthCorrectionsFieldList : string = 'cdma/BirthCorrectionsFieldList';
  public static BirthNameInclusion : string = 'cdma/BirthNameInclusion';
  public static Check_Correction_ReqStatus : string = 'cdma/Check_Correction_ReqStatus';
  public static CheckNameInclusion : string = 'cdma/CheckNameInclusion';
  public static DeathCatB1a2 : string = 'cdma/DeathCatB1a2';
  public static DeathCatB3 : string = 'cdma/DeathCatB3';
  public static DeathCorrections : string = 'cdma/DeathCorrections';
  public static DeathCorrectionsFieldList : string = 'cdma/DeathCorrectionsFieldList';
  public static getBirthDetails : string = 'cdma/getBirthDetails';
  public static getBirthNameInclusionRecords : string = 'cdma/getBirthNameInclusionRecords';
  public static getDeathRecord : string = 'cdma/getDeathRecord';
  public static getGMCList : string = 'cdma/getGMCList';
  public static getGPRegUnits : string = 'cdma/getGPRegUnits';
  public static getHospitalList : string = 'cdma/getHospitalList';
  public static getMCList : string = 'cdma/getMCList';
  public static getMPTYList : string = 'cdma/getMPTYList';
  public static getRegUnitDetails : string = 'cdma/getRegUnitDetails';
  public static getUCRBirthCertDetails : string = 'cdma/getUCRBirthCertDetails';
  public static getUCRBirthRecordsService : string = 'cdma/getUCRBirthRecordsService';
  public static getUCRDeathCertDetails : string = 'cdma/getUCRDeathCertDetails';
  public static getUCRDeathRecords : string = 'cdma/getUCRDeathRecords';
  public static MeesevaTransRollBack : string = 'cdma/MeesevaTransRollBack';
  public static NonAvailabilityBirth : string = 'cdma/NonAvailabilityBirth';
  public static NonAvailabilityDeath : string = 'cdma/NonAvailabilityDeath';
  public static SearchBirthRecord : string = 'cdma/SearchBirthRecord';
  public static SearchDeathRecord : string = 'cdma/SearchDeathRecord';
  public static postNonAvailabilityOfBirth : string = 'cdma/NonAvailabilityBirth';
  public static postBirthNameInclusion: string = 'cdma/BirthNameInclusion';
  public static postNonAvailabilityOfDeath : string =  'cdma/NonAvailabilityDeath';
  public static getMAUDDistrict: string = 'ref-data/api/ReferenceData?EntityType=MAUDLocation&ParentType=District&LanguageCode=EN-IN&ParentId=';
  public static getMAUDLocations: string = 'ref-data/api/ReferenceData?EntityType=MAUDLocationType&LanguageCode=EN-IN';
  public static getByParentId: string = 'ref-data/api/ReferenceData?EntityType='
  public static getDistCodeBySecCode: string = 'ref-data/api/ReferenceData/GetDistrictDetailsBySecretariatCode?SECRETARIAT_CODE='
  public static getSecDesignationDetails : string = 'workflow/api/UserDetails/GetSecretariatWiseDesignations?SecretariatCode=';
  public static saveSecDesignationDetails : string = 'workflow/api/UserDetails/SaveSecretariatWiseRoleDetails';
  public static gswsDynamicSurveyReport : string = 'reports/api/DashBoard/GetEmployeePositionCaptureReport?';
  public static getDistrictWiseCasteData : string = 'ref-data/api/ReferenceData/GetDistrictWiseCasteData?DistrictCode=';
  //payment gateway APIS
  //public static getAmount: string = 'revenue/revenue/Payment/GetAmount';
  //public static getAmount: string = 'paymentservice/paymentservice/Payment/GetAmount';
  public static getAmount: string = 'paymentservice/paymentservice/Payment/GetAmount';
  public static getCheckSum: string = 'paymentservice/paymentservice/Payment/GetCheckSum';
  public static getTransactions: string = 'paymentservice/paymentservice/Payment/GetAllTransactionDetails';
  public static pagginated_GetAllTransactionDetails: string = 'paymentservice/paymentservice/Payment/Pagginated_GetAllTransactionDetails';
  public static pagginated_GetAllTransactionDetailsJS: string = 'paymentservice/paymentservice/Payment/Pagginated_GetAllTransactionDetailsJS';
  public static saveBillDeskResponse: string = 'paymentservice/paymentservice/Payment/SaveBillDeskResponse';
  public static receiptPageData: string = 'paymentservice/paymentservice/Payment/GetPaymentDetails';
  public static getServiceWiseAmount: string = 'paymentservice/paymentservice/Payment/GetServiceWiseAmount?UserId=';
  public static generateChallan: string = 'paymentservice/paymentservice/Payment/GenerateChallan';
  public static aadharChallanGenerate: string = 'paymentservice/PaymentService/CSCAadhaarServices/SaveCSCAadhaarTransactionDetails';
  public static getCategory: string = 'paymentservice/PaymentService/CSCAadhaarServices/GetServiceIdList';
  public static getAmountByserviceId: string = 'paymentservice/PaymentService/CSCAadhaarServices/GetAmountByServiceId';
  public static getUserWiseChallanList: string = 'paymentservice/paymentservice/Payment/GetUserWiseChallanList?UserId=';
  public static getTransactionByRequisitionType: string = 'paymentservice/paymentservice/Payment/GetTransactionByRequisitionType?';
  public static GetCommonTransactionsByChallanId: string = 'paymentservice/paymentservice/Payment/GetCommonTransactionsByChallanId?';
  public static getTransactionsByChallanId: string = 'paymentservice/paymentservice/Payment/GetTransactionsByChallanId?';
  public static getFailedChallanTransactions: string = 'paymentservice/paymentservice/Payment/GetFailedChallanTransactions?';
  public static getTransactionsForChallanGeneration: string = 'paymentservice/paymentservice/Payment/Paginated_GetTransactionsForChallanGeneration?Userid=';
  public static generateChallan_New: string = 'paymentservice/paymentservice/Payment/GenerateChallan_NewImplementation';
  public static getReconsilationHistory: string = 'paymentservice/paymentservice/Payment/GetReconsilationHistory';
  public static billDesk_Reconsilation: string = 'paymentservice/paymentservice/Payment/BillDesk_Reconsilation';
  public static billDesk_Refund: string = 'paymentservice/paymentservice/Payment/BillDesk_Refund';
  public static getReconsilationHistoryByChallanId: string = 'paymentservice/paymentservice/Payment/GetReconsilationHistoryByChallanId?ChallanId=';
  public static GetChallanDetailsByChallanId: string = 'PaymentService/PaymentService/PaymentReconciliation/GetChallanDetailsByChallanId?ChallanId=';
  public static getPaytmCheckSum: string = 'paymentservice/paymentservice/Payment/GetPaytmCheckSum';
  public static GetChallanIdByTransactionId: string = 'paymentservice/paymentservice/Payment/GetChallanIdByTransactionId';
  public static GetPostmanDetails: string = 'paymentservice/IndiaPostBankService/GetIPPB_PostManDetails';
  public static SaveHardwareStatusOfHouseSite: string = 'GSWSCITIZEN/CitizenInfo/UserDetails/SaveHardwareStatusOfHouseSite';
  public static generateCaptcha: string ='GSWSCITIZEN/Captcha/GenerateCaptcha';
  public static validateCaptcha: string ='GSWSCITIZEN/Captcha/ValidateCaptcha';

  public static getCheckSum_External: string = 'paymentservice/paymentservice/ExternalPaymentService/GetCheckSum_External';
  public static saveBillDeskResponse_External: string = 'paymentservice/paymentservice/ExternalPaymentService/SaveBillDeskResponse_External';
  public static verifyPaymentToken: string = 'paymentservice/paymentservice/ExternalPaymentService/VerifyPaymentToken';
  public static saveExternalPaymentAllRequest: string = 'paymentservice/paymentservice/ExternalPaymentService/SaveExternalPaymentAllRequest';
  public static generatePaymentToken: string = 'paymentservice/paymentservice/ExternalPaymentService/GeneratePaymentToken';
  public static getTestAmount: string = 'paymentservice/paymentservice/ExternalPaymentService/GetTestAmount';
  public static verifyExternalPaymentChecksum: string = 'paymentservice/paymentservice/ExternalPaymentService/VerifyExternalPaymentChecksum';
  public static externalAESDecryptMesevaRequest: string = 'paymentservice/paymentservice/ExternalPaymentService/AESDecryptMesevaRequest';
  public static externalAESEncryptMesevaRequest: string = 'paymentservice/paymentservice/ExternalPaymentService/AESEncryptMesevaRequest';
  public static getMeesevaTransactions: string = 'paymentservice/paymentservice/ExternalPaymentService/GetMesevaTransactionDetails'
  public static Pagginated_GetUserWiseChallanList: string = 'paymentservice/paymentservice/Payment/Pagginated_GetUserWiseChallanList?UserId=';
  public static GetOTSTransactionForChallanGeneration: string = 'paymentservice/paymentservice/OTSServices/Paginated_GetOTSTransactionForChallanGeneration?Userid=';
  public static generateOTSChallan: string = 'paymentservice/paymentservice/OTSServices/GenerateOTSChallan';
  public static getUserWiseOTSChallanList: string = 'paymentservice/paymentservice/OTSServices/GetUserWiseOTSChallanList?UserId=';
  public static Pagginated_GetUserWiseOTSChallanList: string = 'paymentservice/paymentservice/OTSServices/Pagginated_GetUserWiseOTSChallanList?UserId=';
  public static getOTSTransactionsByChallanId: string = 'paymentservice/paymentservice/OTSServices/GetOTSTransactionsByChallanId?';
  public static getFailedChallanOTSTransactions: string = 'paymentservice/paymentservice/OTSServices/GetFailedChallanOTSTransactions?';
  public static getRequisitionDetailsByTransactionId: string = 'paymentservice/paymentservice/Payment/GetRequisitionDetailsByTransactionId?TransactionId=';
  public static SavePaymentReconciliationCollectionDetails: string = 'paymentservice/PaymentService/PaymentReconciliation/SavePaymentReconciliationCollectionDetails';
  public static GetPaymentReconciliationListForApprovals: string = 'paymentservice/PaymentService/PaymentReconciliation/GetPaymentReconciliationListForApproval';
  public static GetUserWisePaymentReconciliationReport: string = 'paymentservice/PaymentService/PaymentReconciliation/GetUserWisePaymentReconciliationReport';
  public static UpdatePaymentReconciliationCollectionStatus: string = 'paymentservice/PaymentService/PaymentReconciliation/UpdatePaymentReconciliationCollectionStatus';
  public static getSLAData: string = 'workflow/api/WorkList/GetSLADays';
  public static getGswsToVswsServiceHistory: string = 'gswsmaudapiwebw/api/GswsToVswsService/GetGswsToVswsServiceHistory';
  public static saveGswsToVswsServiceData: string = 'gswsmaudapiwebw/api/GswsToVswsService/SaveGswsToVswsServiceData';
  public static getSadaramCertificateData: string = 'exsadram/SADAREMCERT/rest/Print/getCertificateandIDCardForPrint'; 
  public static CheckSadaremPaymentDetails: string = 'HealthandFamily/HealthAndFamily/SadaremService/CheckSadaremPaymentDetails'; 
  
  public static getOtherServicesTransactionDetails: string = 'gswsmaudapiwebw/api/GswsToVswsService/GetOtherServicesTransactionDetails';
  public static getMuadServiceRequest: string = 'gswsmaudapiwebw/api/MaudTransaction/GetMuadServiceRequest';
  public static getEnergyServiceRequest: string = 'gswsenergyapiwebw/api/EnergyTransaction/GetEnergyServiceRequest';
  public static getOldPortalServiceWiseAmount: string = 'gswsmaudapiwebw/api/GswsToVswsService/GetOldPortalServiceWiseAmount?UserId=';
  public static updateChallanIdInGswsToVsws: string = 'gswsmaudapiwebw/api/GswsToVswsService/UpdateChallanIdInGswsToVsws';
  public static getoldPortalTransactionByRequisitionType: string = 'gswsmaudapiwebw/api/GswsToVswsService/GetoldPortalTransactionByRequisitionType?';
  public static getoldPortalTransactionsByChallanId: string = 'gswsmaudapiwebw/api/GswsToVswsService/GetoldPortalTransactionsByChallanId?';
  public static getMesevaTransactionByChallanId: string = 'paymentservice/paymentservice/ExternalPaymentService/GetMesevaTransactionByChallanId?';
//e-Seva Services
public static eSevaTransaction: string = 'gswsenergyapiwebw/api/ESeva/New';

  //EMUDHRA relted urls
  public static postforApprovedCertificate: string = 'revenue/revenue/Requisition/GenerateCertificateData';
  public static getforApprovedCertificate: string = 'pdfgen/api/PdfGenerate/GenerateCertificate?CertificateId=';
  public static postforApprovedCertificateForPreview: string = 'revenue/revenue/Requisition/GenerateCertificateDataNoAuth';
  public static getforApprovedCertificateForPreview: string = 'pdfgen/api/PdfGenerate/GenerateCertificateNoAuth?CertificateId=';
  public static encryptEmudraDoc: string = 'pdfgen/api/PdfSign/Encrypt';
  public static decryptEmudraDoc: string = 'pdfgen/api/PdfSign/Decrypt';
  public static getListForEmudra: string = 'pdfgen/api/PdfGenerate/GetCertificate';
  public static getSignedCertificateData: string = 'pdfgen/api/PdfGenerate/DownloadCertificate?CertificateId=';
  public static getSignedCertificateDataForPreview: string = 'pdfgen/api/PdfGenerate/DownloadCertificateNoAuth?CertificateId=';
  public static getCertificateDataForWorklist: string =  'workflow/api/WorkList/GetWorklistByReqTypeAndUser';
  public static checkSignedCertData: string = 'pdfgen/api/PdfGenerate/GetCertificateData?CertificateId=';
  public static getUserSignature : string = 'pdfgen/api/PdfGenerate/GetUserSignature?UserId=';
  public static getOfficersDetails: string = 'pdfgen/api/PdfGenerate/CheckifProfileUpdated?UserId=';
  public static aarogyaSurakshaConceptNoteDownloadInTE: string = 'pdfgen/api/WhatsappReport/DownloadReportForWhatsapp?FileName=JaganannaAarogyaSuraksha_ConceptNote_Telugu(Updated).pdf';
  public static aarogyaSurakshaConceptNoteDownloadInEN: string = 'pdfgen/api/WhatsappReport/DownloadReportForWhatsapp?FileName=JaganannaAarogyaSurakshaConceptNote_English_Updated.pdf';
  public static suomotoCasteReport: string = 'reports/api/SuoMotoICReport/GetSuoMotoCasteVerificationReport'
  //emudra
  public static certificateList: { certName: String, certID: string,  subServiceType ?: string}[] = [
    { certName: "IntegratedCertificate", certID: "CC" },  //done
    { certName: "IntegratedCertificate-RDO", certID: "CC" }, 
    { certName: "IntegratedCertificateForStudents", certID: "CC" },
    { certName: "IntegratedCertificateForStudents-RDO", certID: "CC" }, 
    { certName: "IncomeCertificate", certID: "IC" }, //done
    { certName: "IncomeCertificateForStudents", certID: "IC" },    
    { certName: "OBCMemberCertificate", certID: "OC", subServiceType: 'state' },
    { certName: "OBCMemberCertificate", certID: "COBC", subServiceType: 'central'}, //done
    { certName: "FamilyMemberCertificate", certID: "FM" },  //done
    { certName: "PossessionCertificate", certID: "PS" },
    { certName: "NoEarningMemberCertificate", certID: "NE" },
    { certName: "IncomeAssetCertificateForEWS", certID: "EWS" },  //done
    { certName: "PrintingOfTitleDeedCumPassbook", certID: "PPB" },
    { certName: "RenewalCinemaLicence", certID: "RCL" },
    { certName: "SnMFarmerCertificate", certID: "SNM" },
    { certName: "AgricultureIncomeCertificate", certID: "AGI" },
    { certName: "ROR1BCertificate", certID: "ROR", subServiceType:'surveyNo' },
    { certName: "ROR1BCertificate", certID: "RORLP", subServiceType:'lpmNo' },
    // { certName: "ROR1BCertificate", certID: "RORLP" },
    { certName: "LandConversionNewCertificate", certID: "LC" },
    { certName: "ComputerizedAdangalCertificate", certID: "CAD" ,subServiceType: 'surveyNo' },
    { certName: "ComputerizedAdangalCertificate", certID: "LCA", subServiceType: 'lpmNo' },
    { certName: "NoPropertyCertificate", certID: "NP" },
    { certName: "OccupancyRightsCertificateForInamLands", certID: "ORC" },
    { certName: "ChangeOfNameCertificate", certID: "CN" },
    { certName: "FLineApplication", certID: "FLA", subServiceType : 'approve' }, //approve
    { certName: "FLineApplication", certID: "FLR", subServiceType : 'reject' }, //reject
    { certName: "EncumbranceCertificate", certID: "EC" },
    { certName: "PFDAgriculturalwellOrDrinkingwater", certID: "PDBC" },
    { certName: "IssueOfNOCToStoringPetroleumProducts", certID: "NOCSP" },
    // { certName: "ExtractOfNOCUnderExplosiveOrPetroleum", certID: "NOCEM" },
    { certName: "TitleDeedCumPassbook", certID: "TPB" },
    { certName: "BirthDeathCertificate", certID: "BC", subServiceType : 'birth' },
    { certName: "BirthDeathCertificate", certID: "DC" , subServiceType : 'death'},
    { certName: "22AModification", certID: "M22AA", subServiceType : 'approve' },
    { certName: "22AModification", certID: "M22AR" , subServiceType : 'reject'},
    { certName: "MaudBirthDeathCertificate", certID: "MBC", subServiceType : 'birthCertificate' },
    { certName: "MaudBirthDeathCertificate", certID: "MDC" , subServiceType : 'deathCertificate'},
    { certName: "Pensions@1", certID: "PNSC" },
    { certName: "Pensions@2", certID: "PNSC" },
    { certName: "Pensions-NEW@2", certID: "PNSC" },
    { certName: "Pensions-NEW@V2", certID: "PNSC" },
    { certName: "Pensions@3", certID: "PNSC" },
    { certName: "Pensions@4", certID: "PNSC" },
    { certName: "Pensions@5", certID: "PNSC" },
    { certName: "Pensions@6", certID: "PNSC" },
    { certName: "Pensions@7", certID: "PNSC" },
    { certName: "Pensions@8", certID: "PNSC" },
    { certName: "Pensions@9", certID: "PNSC" },
    { certName: "Pensions-NEW@9", certID: "PNSC" },
    { certName: "Pensions-NEW@V9", certID: "PNSC" },
    { certName: "Pensions@10", certID: "PNSC" },
    { certName: "Pensions-NEW@10", certID: "PNSC" },
    { certName: "Pensions-NEW@V10", certID: "PNSC" },
    // { certName: "Pensions@10", certID: "RevokeCertificate" },
    // { certName: "RevokeCertificate", certID: "PNSC" }, 
    { certName: "ReIssuanceCertificateNew", certID: "RIC", subServiceType : 'Income'},
    { certName: "ReIssuanceCertificateNew", certID: "RCC", subServiceType : 'Integrated'},
    { certName: "ReIssuanceCertificateNew", certID: "RFM", subServiceType : 'FMB'},
    { certName: "ReIssuanceCertificateNew", certID: "RBC", subServiceType : 'LRB'},
    { certName: "ReIssuanceCertificateNew", certID: "RDC", subServiceType : 'LRD'},
    { certName: "MarriageCertificate", certID: "MRGC"},
    { certName: "MarriageCertificate-Rural", certID: "MRGC"},
    { certName: "ExplosiveMaterialLicence", certID: "CRL"},
    { certName: "AarogyaSriNewCard", certID: "DHC"},
    { certName: "UpdateAarogyaSriCard", certID: "DHC"},
    {certName:'UpdateAarogyaSriCardForImage',certID: "DHC"},
    { certName: "LegacyAarogyaSriCard", certID: "LHC"},
    { certName: 'ManualAdangalCertificate', certID: 'NEMA'},
    {certName:'LabourRegistration', certID:'LD'},
    {certName:'LabourRegistrationRenew', certID:'LD'},
    // {certName:'HouseholdSplit', certID:'HHSC'},
    // {certName:'HouseholdSplit@1', certID:'HHSC'},
    // {certName:'HouseholdSplit@2', certID:'HHSC'},
    {certName:'MuslimMarriageCertificate', certID:'MMC'},
    {certName:'AddressCertificateForAadharEnrollment', certID:'CAU'},
    {certName:'AddressCertificateForAadharEnrollment@1', certID:'CAU'},
    {certName:'AddressCertificateForAadharEnrollment@2', certID:'CAU'},
    {certName:'AddressCertificateForAadharEnrollment@3', certID:'CAU'},
    {certName:'FPSHOPRENEW-VSKP',certID:'FRC'},
    {certName:'FPSHOPRENEW-OTH',certID:'FRC'},
    {certName:'FPSHOPRENEW',certID:'FRC'}


  ];


  //Civil supplies Six Step Validation 
  public static getSixstepvalidationDetails: string = 'gramawardsachivalaya/SixStepValidation';
  public static saveSixStepValidationData: string = 'gswscivilsuppliesapiwebw/civilsupplies/Requisition/SaveSixStepValidationData';
  public static getSixStepValidationData: string = 'gswscivilsuppliesapiwebw/civilsupplies/Requisition/GetSixStepValidationData';
  public static postNICdata: string = 'gswscivilsuppliesexternal/civilsuppliesexternal/GetePds_Data_Port_Applications_NewCards';
  public static postNICdataVRO: string = 'gswscivilsuppliesexternal/civilsuppliesexternal/GetePds_Data_Port_Applications_NewCardsFinal';
  public static postNICReject:string ='gswscivilsuppliesexternal/civilsuppliesexternal/GetePds_Data_Port_Applications_NewCardsRejection';
  public static nicEkycStatus: string = 'workflow/api/WorkFlowStatusUpdate/GetDeptWorkFlowStatusDetails';
  public static aadharChecking: string = 'gswscivilsuppliesexternal/civilsuppliesexternal/GetePds_Data_Port_Applications_RiceCards_ValidationsCheck';
  public static workFlowStatus: string = 'workflow/api/WorkFlowStatusUpdate/GetDeptWorkFlowStatusDetailsByRequisitionId';

  //'https://epds3.ap.gov.in/Epds_Dataport_API_ServiceInfo/getePDS_DataPort_ServiceInfo?wsdl';
  
  
  //'';

  public static civilSuppliesFileUpload: string = 'gswscivilsuppliesapiwebw/civilsupplies/Requisition/UpdateFileUploadInfo';
  //Civil supplies external api
  public static getRiceCardMemberDetails: string = 'gswscivilsuppliesexternal/civilsuppliesexternal/GetRiceCardMember';
  public static getRiceCardEKYCByNo: string = 'gswscivilsuppliesexternal/civilsuppliesexternal/GetRiceCardEkycStatus';
  public static getRiceCardEKYCById: string = 'gswscivilsuppliesexternal/civilsuppliesexternal/GetRiceCardEkycStatusById';
  public static getExistingRationCardMemberDetails: string = 'gswscivilsuppliesexternal/civilsuppliesexternal/GetRationCardMember';
  public static getEKYCStatus: string = 'gswscivilsuppliesexternal/civilsuppliesexternal/GetEkycStatus';
  public static getClusterFpShopResult:string ='gswscivilsuppliesexternal/civilsuppliesexternal/GetePds_Data_Port_Approval_Cluster_updation';
  public static requestCSActionStatus: string = 'gswscivilsuppliesexternal/civilsuppliesexternal/GetePds_Data_Port_Approval_status_NewCards';
  public static maintainCSlog: string = 'gswscivilsuppliesapiwebw/civilsupplies/Requisition/SaveCivilSupplyLogCommand';
  public static externalChangeOfAddress : string = 'gswscivilsuppliesexternal/civilsuppliesexternal/GetePds_Data_Port_Applications_Address';
  public static checkStatus : string = 'gswscivilsuppliesexternal/ricecard/ekycservice/GetCivilSuppliesApplicationDetailsByRequisitionId';
  public static CheckIfExemptedAadhaar: string = 'gswscivilsuppliesapiwebw/civilsupplies/Requisition/CheckIfExemptedAadhaar';


  
  public static getOccupation: string = 'ref-data/api/ReferenceData?EntityType=Occupation&LanguageCode=EN-IN';
  public static getGender: string = 'ref-data/api/ReferenceData?EntityType=Gender&LanguageCode=EN-IN';
  public static getCivilSuppliesGender: string = 'ref-data/api/ReferenceData?EntityType=CivilSuppliesGender&LanguageCode=EN-IN';
  public static getRelationshipInEn: string = 'ref-data/api/ReferenceData?EntityType=Relationship&LanguageCode=EN-IN';
  // public static getRelationshipInTe: string = 'gswscivilsupplyreferenceapiwebw/api/ReferenceData?EntityType=Relationship&LanguageCode=TE';
  public static getSingleMemberSplitInEn: string = 'ref-data/api/ReferenceData?EntityType=SingleMemberSplit&LanguageCode=EN-IN';
  // public static getSingleMemberSplitInTe: string = 'gswscivilsupplyreferenceapiwebw/api/ReferenceData?EntityType=SingleMemberSplit&LanguageCode=TE';
  public static getSingleMemberType: string = 'ref-data/api/ReferenceData?EntityType=SingleMemberCard&LanguageCode=en-in';
  //master data
  public static getPanchayatDDO: string = 'ref-data/api/ReferenceData?EntityType=PanchayatDDO&ParentId='
  public static getDistrictsInTe: string = 'ref-data/api/ReferenceData?EntityType=District&LanguageCode=TE';

  public static getSecretariatPanchayatCode: string = 'ref-data/api/ReferenceData?EntityType=Panchayat&ParentId='
  public static getMandalRevenueMandalInEN: string = 'ref-data/api/ReferenceData?EntityType=MandalRevenueMandal&ParentId=' //837e3708-51be-43a0-be9f-0a964aaa3dce&ParentType=Mandal&LanguageCode=EN-IN
  public static getRevenueVillagesInEN: string = 'ref-data/api/ReferenceData/GetChildCodeByParentCode?EntityType=RevenueMandal&EntityCode=' //716&ChildEntityType=RevenueVillage&LanguageCode=EN-IN'
   // public static getRevenueVillagesInEN: string = 'ref-data/api/ReferenceData?EntityType=RVVillage&ParentType=Mandal&LanguageCode=EN-IN&ParentId=';
  public static getDistMandalcode: string = 'workflow/api/WorkList/GetDistMandalcode?SecretariatCode=';
  public static getVillagesInTe: string = 'ref-data/api/ReferenceData?EntityType=Village&ParentType=Mandal&LanguageCode=TE&ParentId=';
  public static getMandalsInTe: string = 'ref-data/api/ReferenceData?EntityType=Mandal&ParentType=District&LanguageCode=TE&ParentId=';
  public static getLandClass: string = 'ref-data/api/ReferenceData?EntityType=RVLandClass&ParentType=RVDistrict&LanguageCode=TE&ParentId=';
  public static getLandNature: string = 'ref-data/api/ReferenceData?EntityType=RVLandNature&ParentType=RVDistrict&LanguageCode=TE&ParentId=';
  public static getLandEnjoy: string = 'ref-data/api/ReferenceData?EntityType=RVLANDENJOY&ParentType=RVDistrict&LanguageCode=TE&ParentId=';
  public static getWaterSource: string = 'ref-data/api/ReferenceData?EntityType=RVWATERSOURCE&ParentType=RVDistrict&LanguageCode=TE&ParentId=';
  public static getRevenueDistrictTe: string = 'ref-data/api/ReferenceData?EntityType=RVDistrict&ParentType=District&LanguageCode=TE&ParentId=';
  // public static getRevenueMandalsInTe: string = 'ref-data/api/ReferenceData?EntityType=RVMandal&ParentType=RVDistrict&LanguageCode=TE&ParentId=';
  public static getRevenueMandalsInTe: string = 'ref-data/api/ReferenceData/GetAllSubChildsByParentId?EntityType=RVDistrict&ChildEntityType=RVDivision&SubChildEntityType=RVMandal&Level=2&ParentId=';
  public static getRevenueVillagesInTe: string = 'ref-data/api/ReferenceData?EntityType=RVVillage&ParentType=RVMandal&LanguageCode=TE&ParentId=';
 public static getCluster: string = 'ref-data/api/ReferenceData?EntityType=Cluster&ParentType=Secretariat&LanguageCode=EN-IN&ParentId=';
  public static getFpshop: string = 'gswscivilsuppliesexternal/civilsuppliesexternal/GetShopDatabyCluster';
  public static getChildCodeByParentCode: string = 'ref-data/api/ReferenceData/GetChildCodeByParentCode?EntityType=Mandal&ChildEntityType=MandalRevenueMandal&LanguageCode=EN-IN&EntityCode=';
  public static getParentCodeByChildCode: string = 'ref-data/api/ReferenceData/GetParentCodeByChildCode?EntityType=';
  public static getParentCodeByChildId: string = 'ref-data/api/ReferenceData/GetParentCodeByChildCode?EntityType=';
  public static getChildCodeByParentCodeCommon : string = 'ref-data/api/ReferenceData/GetChildCodeByParentCode?EntityType=';
  public static getSROinEC : string = 'ref-data/api/ReferenceData?EntityType=RevenueSRO&ParentId=';
  public static getAllRevenueVillages: string = 'ref-data/api/ReferenceData/GetAllSubChildsByParentId?EntityType=REVENUEDiStrict&ChildEntityType=REVENUEDivision&SubChildEntityType=REVENUEMandal&SubSubChildEntityType=REVENUEVillage&Level=3&ParentId=';
  public static getSubRegistrar : string = 'ref-data/api/ReferenceData/GetChildCodeByParentCode?EntityType=RevenueDistrict&ChildEntityType=RevenueSRO&LanguageCode=EN-IN&EntityCode=';
  public static getMutationParties : string = 'ref-data/api/ReferenceData?EntityType=MutationParty&LanguageCode=EN-IN';
  //MAUD
  public static getMAUDServicesName: string = 'ref-data/api/ReferenceData?EntityType=MAUDURLLABEL&ParentType=URBANMandal&LanguageCode=EN-IN&ParentId=';
  public static getMAUDServiceUrl: string = 'ref-data/api/ReferenceData?EntityType=MAUDURL&ParentType=URBANMandal&LanguageCode=EN-IN&ParentId=';
  public static getUrbanMandals: string = 'ref-data/api/ReferenceData/GetByEntityType?EntityType=URBANMandal';
  public static initiateTransaction: string = 'gswsmaudapiwebw/maud/Requisition/New';
  //Transport Services
  public static initiateTransportReq: string = 'transport/transport/Requisition/New';
  //Energy
  public static getEnergyServicesName: string = 'ref-data/api/ReferenceData?EntityType=ENERGYLABEL&ParentType=CPDCLDISTRICT&LanguageCode=EN-IN&ParentId=';
  public static getEnergyServicesUrl: string = 'ref-data/api/ReferenceData?EntityType=ENERGYURL&ParentType=CPDCLDISTRICT&LanguageCode=EN-IN&ParentId=';
  public static getCPDCLDistricts: string = 'ref-data/api/ReferenceData?EntityType=CPDCLDISTRICT&LanguageCode=EN-IN';
  public static getEPDCLServicesName: string = 'ref-data/api/ReferenceData?EntityType=EPDCLLABEL&ParentType=EPDCLDISTRICT&LanguageCode=EN-IN&ParentId=';
  public static getEPDCLServicesUrl: string = 'ref-data/api/ReferenceData?EntityType=EPDCLURL&ParentType=EPDCLDISTRICT&LanguageCode=EN-IN&ParentId=';
  public static getEPDCLDistricts: string = 'ref-data/api/ReferenceData?EntityType=EPDCLDISTRICT&LanguageCode=EN-IN';
  public static getSPDCLServicesName: string = 'ref-data/api/ReferenceData?EntityType=SPDCLLABEL&ParentType=SPDCLDISTRICT&LanguageCode=EN-IN&ParentId=';
  public static getSPDCLServicesUrl: string = 'ref-data/api/ReferenceData?EntityType=SPDCLURL&ParentType=SPDCLDISTRICT&LanguageCode=EN-IN&ParentId=';
  public static getSPDCLDistricts: string = 'ref-data/api/ReferenceData?EntityType=SPDCLDISTRICT&LanguageCode=EN-IN';
  public static initiateTransactionEnergy: string = 'gswsenergyapiwebw/energy/Requisition/New';
  

  //Health 
  public static getRelationsAarogyasri: string = 'ref-data/api/ReferenceData?EntityType=AarogyasriRelation&LanguageCode=EN-IN';
  public static aarogyasriNewHealthCard: string = 'HealthandFamily/HealthAndFamily/AarogyasriHealthCard/NewHealthCard';
  public static aarogyasriUpdateHealthCard: string = 'HealthandFamily/HealthAndFamily/AarogyasriHealthCard/UpdateHealthCard';
  public static HealthCardFileUpload: string = 'HealthandFamily/HealthAndFamily/Requisition/UpdateFileUploadInfo';
  public static getReasons:string='workflow/api/ServiceDocuments/GetServiceMigarationReasons?ServiceCode=AarogyaSriNewCard';
  public static getVehicleDetails:string='gramawardsachivalaya/FamilyParameterdetails';
  public static getHealthCardStatus:string='HealthandFamily/HealthAndFamily/Requisition/GetHealthCardStatusById';
 public static generateAarogyaSriUHID : string ='HealthandFamily/HealthAndFamily/AarogyasriHealthCard/GenerateAarogyasriUHID';
 public static saveUHID:string='HealthandFamily/HealthAndFamily/AarogyasriHealthCard/UpdateUhidinHealthCardRequisitionInfo'; 
 public static getDataBasedOnUHID ='HealthandFamily/HealthAndFamily/Requisition/GetRequisitionByUHID';
public static checkCardStatus='HealthandFamily/HealthAndFamily/Requisition/CheckHealthCardStatusByAadharNo';
public static saveHealthCardData='HealthandFamily/HealthAndFamily/Requisition/UpdateRequisitionDataForArogyasri'; 
public static GetReferenceIdFromAadharOrUHID='HealthandFamily/HealthAndFamily/Requisition/GetReferenceIdFromAadharOrUHID'; 
public static GetCurentApplicationStatusByID='HealthandFamily/HealthAndFamily/Requisition/GetCurentApplicationStatusByID'; 
public static UpdateRequisitionDataForArogyasri='HealthandFamily/HealthAndFamily/Requisition/UpdateRequisitionDataForArogyasri';
public static aarogyasriLegacyHealthCard: string = 'HealthandFamily/HealthAndFamily/AarogyasriHealthCard/LegacyHealthCard';
public static CheckPaymentStatusForCatACertificateById: string = 'paymentservice/PaymentService/Requisition/CheckPaymentStatusForCatACertificateById?RequisitionId=';
public static GetJASTeamScheduleDetails: string = 'HealthandFamily/HealthAndFamily/JaganannaAarogyaSuraksha/GetJASTeamScheduleDetails?';
public static GetDetails: string = 'HealthandFamily/HealthAndFamily/JaganannaAarogyaSuraksha/GetWelfareSchmSchdDtls';
public static GetJASHolidaysList: string = 'HealthandFamily/HealthAndFamily/JaganannaAarogyaSuraksha/GetJASHolidaysList?DateFlag=';
public static GetJASVHCScheduleSplitDetails: string = 'HealthandFamily/HealthAndFamily/JaganannaAarogyaSuraksha/GetJASVHCScheduleSplitDetails?';
public static GetVHCSecretariatByVHCCode: string = "reports/api/JASReport/GetVHCSecretariatByVHCCode?VHCCode=";

public static caseSearch='ExternalArogMithraPAD/nwhReferral/nwhAsaraCases'; 
public static getFamilyDetails='ExternalArogMithraPAD/nwhReferral/nwhAsaraFamilyDetails'; 
public static getBankDetails='ExternalArogMithraPAD/miscellaneous/getBankDetails'; 
public static submitAccountDetails ='HealthandFamily/HealthAndFamily/MithraPatient/New_MithraPatientAccountDetails';
public static submitExternalAccountDetails ='HealthandFamily/HealthAndFamily/MithraPatient/PostMithraPatientAccountDetails'; 
 
public static getApplicationsListBySecId ='HealthandFamily/HealthAndFamily/Requisition/GetApplicationsListBySecId?SecratariatCode=';
public static updateCardReceivedStatusData='HealthandFamily/HealthAndFamily/AarogyasriHealthCard/UpdateCardReceivedStatusData';
public static GetJASWelfareScheduleDetails: string = 'HealthandFamily/HealthAndFamily/JaganannaAarogyaSuraksha/GetWelfareDetailsByDistrict';
//Registration
  public static getRegistartionDetails: string = 'regns/GSWS/restAdapter/GSWSReg';//'regns/GSWSReg';
  public static getRegistrationDetailsWithLPNumbers = 'regns/LPMGSWSRestAPI/restAdapter/LPM-GSWS';

  //Labour-service

  public static IntegratedRegistrationOfEstablishment: string = 'revenue/revenue/LabourDepartment/IntegratedRegistrationOfEstablishment';
  public static GenerateRegistrationNumberForEstablishment: string = 'revenue/revenue/Requisition/GenerateRegistrationNumberForEstablishment';
  public static RenewalIntegratedRegistrationOfEstablishment: string = 'revenue/revenue/LabourDepartment/RenewalIntegratedRegistrationOfEstablishment';
  public static GetRenewalValidationCheck: string = 'revenue/revenue/LabourDepartment/GetRenewalValidationCheck?ApplicationNumber=';


  //Webland API
  public static getRoRDetails: string = 'wspahani/GetRORDetails';
  public static getWaterTaxDetails: string = 'watertaxservice/PaymentRequestsKhata'; 
  public static getWaterTaxPaymentDetails: string = 'watertaxservice/PostingOfAmount';
  public static getKhataNoInFline: string = 'uatwspahani/GetKhathaNoInformation';
  public static getRORDetailsForPPB: string = 'uatwspahani/GetRORDetailsForPPB';
  public static getOldSurveyNumberDetails: string = 'uatwspahani/GetOldSurveyNumberDetails';
  public static getPreviousPahaniDetails: string = 'uatwspahani/GetPreviousPahaniDetails';
  public static getSurveyNumberDetails: string = 'wspahani/GetSurveyNumberDetails';
  public static getPattadarsForSurveyNumber: string = 'uatwspahani/GetPattadarsForSurveyNumber';
  public static getCurrentPahaniDetailsForSRO: string = 'uatwspahani/GetCurrentPahaniDetailsForSRO';
  public static getCurrentPahaniDetails: string = 'wspahani/GetCurrentPahaniDetails';
  public static getPattadarDetailsByKhataNo: string = 'passbookservicesqr/getPattadarDetails_ByKhataNo';
  public static getPattadarDetails_ByKhataNoSignPhoto: string = 'passbookservicesqr/getPattadarDetails_ByKhataNoSignPhoto';
  public static paymentRequests: string = 'automutationmeeseva/PaymentRequests';
  public static surveyNumberlandConversion: string = 'conversion/ConversionInformation';
  // public static getPhananiAppeal:string = environment.weblandAPI +'uatwspahani/GetPahaniAppeal HTTP/1.1';
  // public static landConvertedSurveyNoDetails: string = 'conversion/LandConvertedSurveyNoDetails';
  // public static surveyNumberlandConversion: string = 'conversion/LandConvertedSurveyNoDetails';
  public static makePPBRequestToWebLand: string = 'ppbrequestservice/MakePPBRequestToWebLand';
  public static applyMutationAndPPB: string = 'mutation/ApplyMutationAndPPB';
  public static  postMutationTransactions: string = 'mutation/MutationForTranscation';
  public static sadabainamaToken: string = 'exsada/Token';
  public static sadabainamaMasterData: string = 'exsada/Master';
  // public static zswotoken: string = 'uatwebland/api/Token/zswo';
  // public static zswolpmnumberfetch: string = 'uatwebland/CGS/GovLands';
  public static sadabainamaFormSubmission: string = 'exsada/SBV1';
  public static getsadaramdetailsfromapi: string = 'exsadram/SADAREMCERT/rest/Print/getSadaremIdDetails';
  public static sadabainamaForAgriLand: string = 'revenue/revenue/CatBStraightThrough/SadaBainamaForAgriLand';
  public static rectificationEntries: string = 'revenue/revenue/CategoryBCertificate/RectificationOfEntriesInResurveyRecord';
  public static ROFRToken: string = 'exrofr/api/Token';
  public static ROFRMasters: string = 'exrofr/ROFR/ROFRMasters';
  public static ROFRMN: string = 'exrofr/ROFR/ROFRMN';
  public static ROFRMutationForTransaction: string = 'revenue/revenue/CatBStraightThrough/ROFRMutationForTransaction';


  public static applyOldPahani: string = 'mutation/applyOldPahani';
  public static updateMobileinPattadar: string = 'uatwspahani/UpdateMObileinPattadar';
  public static automutationPosting: string = 'automutationmeeseva/AutomutationPosting';
  public static getPhananiAppeal: string = 'GetPahaniAppeal';
  public static getPahaniAppeal: string = 'uatwspahani/GetPahaniAppeal';
  public static postWorkFlowStatusUpdate: string = 'workflow/api/WorkFlowStatusUpdate/GSWSWorkFlowStatusUpdate';
  
  public static postWorkFlowGetHmacHash: string = 'workflow/api/WorkFlowStatusUpdate/GetHmacHash';
  // Pension APIS
  public static validateAadhar: string = 'sspensions/NewApplicationAadharValidation/validateAadhar';
  public static PanchayatorHabitationMaster: string = 'sspensions/generic/MastersData';
  public static getIncomeDetailsOrCasteDetails: string = 'pensionsmeeseva/SSP_DISBURSEMENT_DATA/GET_MEESEVA_SERVICE_DETAILS';
  public static pensionSubmit: string = 'pensions/Pension/Pension/NewPensionApplication';
  public static pensionFileUpload: string = 'pensions/Pensions/Requisition/UpdateFileUploadInfo';
  public static getPensionCardAndQRcodedetails: string = 'sspensions/pensionercarddetails/getpensionercardandqrcodedetails';
  public static getSocialAuditList: string = 'sspensions/getPensionersStatusDetails/getA4Data';
  public static epdcl: string = 'electric/ThirdpartyAPI/v2/searchByCustomerDetails';
  public static spdcl: string = 'electric/ApspdclWS/ws/SearchCustomerDetails/getdata';
  public static cpdcl: string = 'electric/AadhaarSeedingDeseeding/rest/listener/getconsumption';
  public static urbanproperties: string = 'pensions/Pension/Pension/CheckValidAssessmentNo';
  public static getPensionApplicationsForEBUrbanTag: string = 'pensions/Pension/PensionMissingData/GetMissingApplications?UserId=';
  public static getPensionsEBUrbanDetails: string = 'pensions/Pension/PensionMissingData/GetMissingApplicationData?ApplicationNo=';
  public static savePensionEBUrbanDetails: string = 'pensions/Pension/PensionMissingData/UpdateMissingApplicationData';
  public static approvePensionUrbanDetails:string ='pensions/Pension/PensionMissingData/ApproveMissingApplicationData';
  public static energyMeter :string ='exem/EnergyMeter';
  public static energyMeter12MonthsUnits :string ='exem/EnergyMeter12MonthsUnits';
  public static urbanValidation : string ='exmaud/property/propertyOwnerDetails';
  public static getdecryptedAadharForUrbanProperty : string ='javaapi/rest/encrydecryp/getdectxt/TgaL8iCPcZzEtPRz2RTSFclL6LTwbwLl';
//  WorkFlowStatusUpdate';
  // NEW PENSION APIS
  public static VALIDATE_BENEFICIARY_IN_PENSIONS: string = 'pensionsvalidation/VALIDATE_BENEFICIARY_IN_PENSIONS';
  public static NEW_APPLICATIONS_FINAL_SANCTIONS: string = 'pensionsvalidation/NEW_APPLICATIONS_FINAL_SANCTIONS';
  public static CN_GetDetailsByAppNo: string = 'meesevawebservice';
  public static VSWSPensionMandal: string = 'ref-data/api/ReferenceData';
  public static PensionMandalByEntityCode: string = 'ref-data/api/ReferenceData/GetByEntityCode';
  public static PensionPanchayatOrHabitation: string = 'ref-data/api/ReferenceData';
  public static UpdatePensionCetificateInfo: string = 'pensions/Pensions/Requisition/UpdatePensionCetificateInfo'
  public static GetRequisitionByPensionId: string = 'pensions/Pensions/Requisition/GetRequisitionByPensionId'
  public static getPensionerDetails: string = 'pensions/Pension/Pension/GetPensionerDetails';
  // SIGNATUREUPLOADSCREENS
  public static GetSignatureList: string = 'pdfgen/api/PdfGenerate/GetSignatureList'
  public static AddSignature: string = 'pdfgen/api/PdfGenerate/AddSignature'
  public static UpdateSignature: string = 'pdfgen/api/PdfGenerate/UpdateSignature'
  public static getByEntityCodes: string = 'ref-data/api/ReferenceData/GetByEntityCode'
  public static divisionMandalByEntityType: string = 'ref-data/api/ReferenceData'

  // Payement Report Service
  public static getPayementReport: string = 'reports/api/DashBoard/GetAllPaymentReport';
  //Revoke API
  public static GetTransactionDetailsForRevoke: string = 'revenue/revenue/Revoke/GetTransactionDetailsForRevoke';
  public static GetWorklistForRevoke: string = 'workflow/api/WorkList/GetWorklistByReqTypeAndUser';
  public static SaveRevokeCertificateData: string = 'revenue/revenue/Revoke/SaveRevokeCertificateData';
  //Redirect other services API
  public static redirectToExisting: string = 'gswscivilsuppliesapiwebw/ExternalRedirection/RedirectNew';
  public static commonRedirectionToExisting: string = 'redirectionservice/ExternalRedirection/CommonRedirection';
  // SMS API
  //public static sendSMS: string = 'http://20.197.60.196:5100/SmsService/Send';
  public static sendSMS: string= 'comms/SmsService/Send';
  public static sendWhatsAppMsg: string= 'comms/WhatsappService/Send';
  //aadhar OTP 
  public static generateAadharOTP: string= 'comms-vm/Aadhaar/api/Home/AadhaarOTP'; // 'comms/AadharService/GenerateOTP';
  public static verifyAadharOTP: string= 'comms/AadharService/VerifyOTP';
  public static getUserIdDetails: string = 'workflow/api/UserDetails/GetUserDetails';
  //public static pensionAadharOTP: string = 'comms-vm/VolunteerMobileApp/api/gsws/AadhaarOTPEKyc';
  public static pensionAadharOTP: string = 'comms/api/gsws/AadhaarOTPEKyc';

  public static updateProfile: string = 'https://graph.microsoft.com/v1.0/me';
//Revenue-share reports APIs

public static getRevenueShareReportData : string = 'reports/api/MonthlyRevenueShare/GetMonthlyRevenueShareData';
public static getRevenueDistricts: string = 'reports/api/MonthlyRevenueShare/GetRevenueDistrict';
public static getRevenueDivision: string = 'reports/api/MonthlyRevenueShare/GetRevenueDivisionByDistrict?RevenueDistrictCode=';
public static getRevenueMandal: string = 'reports/api/MonthlyRevenueShare/GetRevenueMandalByRevenueDivision?RevenueDivisionCode=';
public static GetRevenueDistrictByDistrict: string = 'reports/api/MonthlyRevenueShare/GetRevenueDistrictByDistrict';
public static GetDistrictByRevenueDistrict: string = 'reports/api/MonthlyRevenueShare/GetDistrictByRevenueDistrict';
public static GetDesignationsList: string = 'reports/api/MonthlyRevenueShare/GetDesignationsList';
public static GetMonthByYear: string = 'reports/api/MonthlyRevenueShare/GetMonthsByYear?Year=';
public static getCollectionPaymentReportData: string = 'reports/api/DashBoard/GetAllPaymentCollectionReport';
public static GetJaganannaSurakshaReportData: string = 'reports/api/DashBoard/GetAllJSTokenReport';
public static GetArogyaSurakshaReportData: string = 'reports/api/JASReport/GetJASStatusReport';

public static getJaganannaSurakshaTokenReport: string = 'reports/api/JASReport/GetJASTokenStatusReport';
public static GetJASCampStatusReport: string = 'reports/api/JASReport/GetJASCampStatusReport';
public static GetTeamScheduleReportData: string = 'reports/api/DashBoard/GetJSTeamScheduleDetails';
public static getAllJSDepartments: string = 'reports/api/DashBoard/GetAllJSDepartments';
public static getAllJSServicesByDepartment: string = 'reports/api/DashBoard/GetAllJSServicesByDepartment?DepartmentCode=';
public static getAllJSTokenDashboardData: string = 'reports/api/DashBoard/GetAllJSTokenDashboardData?';
public static getVBSYScreenDetails: string = 'GSWSCITIZEN/CitizenInfo/VikashitBharat/GetVBSYScreenDetails?';
public static saveVolunteerAppreciationProgramDetails: string = 'GSWSCITIZEN/VolunteerAppreciationProgramController/VolunteerAppreciationProgram/SaveVolunteerAppreciationProgramDetails';
public static getVolunteerAppreciationProgramDetails: string = 'GSWSCITIZEN/VolunteerAppreciationProgramController/VolunteerAppreciationProgram/GetVolunteerAppreciationProgramDetails?UserId=';
public static getSuoMotoICReport: string = 'reports/api/SuoMotoICReport/GetSuoMotoICReport?DistrictID='

  public static categoryAServices: any = [
    {
    "serviceName": "Printing Of Title Deed Cum Passbook",
    "serviceCode": "PrintingOfTitleDeedCumPassbook"
    },
    {
    "serviceName": "Patta Sub Division for Auto Mutation",
    "serviceCode": "PattaSubdivisionAutoMutation"
    },
    {
    "serviceName": "ROR 1B",
    "serviceCode": "ROR1BCertificate"
    },
    {
    "serviceName": "Computerized Adangal",
    "serviceCode": "ComputerizedAdangalCertificate"
    },
    {
    "serviceName": "Pattadar mobile number seeding in Land Records ",
    "serviceCode": "PattadarMobileNoSeed"
    },
    {
    "serviceName": "Reissuance of integrated/OBC/EBC Certificate",
    "serviceCode": "ReIssuanceCertificate"
    },
    {
      "serviceName": "Reissuance of Certificates",
      "serviceCode": "ReIssuanceCertificateNew"
      },
    {
    "serviceName": "Birth / Death Certificate",
    "serviceCode": "MaudBirthDeathCertificate"
    },
    {
      "serviceName": "Lamination Service",
      "serviceCode": "Lamination"
    },
    {
      "serviceName": "Update Dr NTR Vaidya Seva Card",
      "serviceCode": "UpdateAarogyaSriCard"
    },
    {
      "serviceName": "Application For Integrated Registration Of Establishment Under Labour Laws",
      "serviceCode": "LabourRegistration"
    },
    {
      "serviceName": "Application For Integrated Registration Of Establishment Under Labour Laws",
      "serviceCode": "LabourRegistrationRenew"
    },
    {
      "serviceName": "Vaidya Mithra - Patient Account Details",
      "serviceCode": "PatientBankDetails"
    },
    {
      "serviceName": "Assignment of Lands to Ex-Serviceperson Serving Soldiers",
      "serviceCode": "AssignmentofLandstoEx-ServicepersonServingSoldiers"
    },
    {
      "serviceName": "Jagananna Suraksha Token Registration",
      "serviceCode": "RegistrationofTokens"
    },
    {
      "serviceName": "Issuance of Passbook LP Number(s)",
      "serviceCode": "IssuanceOfBhuHakkuPatram"
    },
    {
      "serviceName": "Water Tax Service",
      "serviceCode": "WaterTaxService"
    },
    {
      "serviceName": "Job Seeker Registration",
      "serviceCode": "JobSeekerRegistration"
    },
    {
      "serviceName": "Reprint of Certificates",
      "serviceCode": "PrintCertificate"
    },
  ];

  public static casteBasedWorkflow : any = [
    {
      "casteName" : "Chakalas",
      "casteCategory" : "BCE",
      "rdoFlag" : "Y"
    },
    {
      "casteName" : "Dulia",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    {
      "casteName" : "Nayaks (in the Agency tracks)",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    {
      "casteName" : "Putiya",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    {
      "casteName" : "Thoti",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    {
      "casteName" : "Valmiki (in the Agency tracks)",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    // {
    //   "casteName" : "Adi Andhra",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Adi Dravida",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Anamuk",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Arundhatiya",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Arwa Mala",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Arya Mala",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Bavuri",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Beda(Budaga) Jangam",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Bindla",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    {
      "casteName" : "Bentho Oriya",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    {
      "casteName" : "Bartika",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    // {
    //   "casteName" : "Byagara",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Chachati",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Chalavadi",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Chambhar",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Chandala",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    {
      "casteName" : "Chamar,Mochi,Muchi",
      "casteCategory" : "SC",
      "rdoFlag" : "Y"
    },
    // {
    //   "casteName" : "Dakkal,Dokkalwar",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Dandasi",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Dom,Dombara,Paidi,Pano",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Dhor",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Ellamalawar,Yellammalawandlu",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Ghasi,Haddi Relli,Chanchandi",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    {
      "casteName" : "Goudu (in the Agency tracks)",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    // {
    //   "casteName" : "Godagali",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Godari",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Gosangi",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    {
      "casteName" : "Holva",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    {
      "casteName" : "Hill Reddies",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    // {
    //   "casteName" : "Holeya Dasari",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Holeya",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Jaggali",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Jambuvulu",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    {
      "casteName" : "Kammara",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    {
      "casteName" : "Kondareddis",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    {
      "casteName" : "Konda Kapus",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    {
      "casteName" : "Konda Dhoras",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    // {
    //   "casteName" : "Kolupulavandlu",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    {
      "casteName" : "Kotia",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    // {
    //   "casteName" : "Madiga",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Mang Garodi",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Mahar",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Malahannai",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Mala Jangam",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Mala",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Mala Dasari",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Mala Dasu",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Mala Masli",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Mang",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Manne",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Mala Sanyasi",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Masthi",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Matangi",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Madiga Dasu,Mashleen",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Mehtar",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Mitha Ayyalvar",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Madasi Kuruva,Madari Kuruva",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    {
      "casteName" : "Manna Dhora",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    // {
    //   "casteName" : "Mala Sale,Netkani",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    {
      "casteName" : "Mutyalammavandlu",
      "casteCategory" : "BCA",
      "rdoFlag" : "Y"
    },
    // {
    //   "casteName" : "Mundala",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Panchama,Pariah",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Pambada,Pambanda",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Parnidi",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Paiko",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Paki,Moti,Thoti",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    {
      "casteName" : "Reddi Dhoras",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    // {
    //   "casteName" : "Relli",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Samagara",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Samban",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Sapru",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    {
      "casteName" : "Sidhopaiko",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    // {
    //   "casteName" : "Sindhollu,Chindollu",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    {
      "casteName" : "Sanrona",
      "casteCategory" : "ST",
      "rdoFlag" : "Y"
    },
    // {
    //   "casteName" : "Valluvan",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // },
    // {
    //   "casteName" : "Yatala",
    //   "casteCategory" : "SC",
    //   "rdoFlag" : "Y"
    // }
  ];

  public static pensionTypeForDeptPostingData : any = [
    {
      "PENSION_TYPE_NAME" : "O.A.P",
      "RD_SERVICE_CODE" : "1",
      "GSWS_SERVICE_CODE" : "1"
    },
    {
      "PENSION_TYPE_NAME" : "Weavers",
      "RD_SERVICE_CODE" : "2",
      "GSWS_SERVICE_CODE" : "2"
    },
    {
      "PENSION_TYPE_NAME" : "Disabled",
      "RD_SERVICE_CODE" : "3",
      "GSWS_SERVICE_CODE" : "3"
    },
    {
      "PENSION_TYPE_NAME" : "Widow",
      "RD_SERVICE_CODE" : "4",
      "GSWS_SERVICE_CODE" : "4"
    },
    {
      "PENSION_TYPE_NAME" : "Toddy Tappers",
      "RD_SERVICE_CODE" : "5",
      "GSWS_SERVICE_CODE" : "5"
    },
    {
      "PENSION_TYPE_NAME" : "Transgender",
      "RD_SERVICE_CODE" : "9",
      "GSWS_SERVICE_CODE" : "6"
    },
    {
      "PENSION_TYPE_NAME" : "Fishermen",
      "RD_SERVICE_CODE" : "10",
      "GSWS_SERVICE_CODE" : "7"
    },
    {
      "PENSION_TYPE_NAME" : "Single Women",
      "RD_SERVICE_CODE" : "11",
      "GSWS_SERVICE_CODE" : "8"
    },
    {
      "PENSION_TYPE_NAME" : "Traditional Cobblers",
      "RD_SERVICE_CODE" : "13",
      "GSWS_SERVICE_CODE" : "9"
    },
    {
      "PENSION_TYPE_NAME" : "Dappu Artists",
      "RD_SERVICE_CODE" : "14",
      "GSWS_SERVICE_CODE" : "10"
    }
  ];

  public static freeServiceChargeRVServices = [
    'ComputerizedAdangalCertificate',
    'IntegratedCertificate',
    'IncomeCertificate',
    'FamilyMemberCertificate',
    'IncomeAssetCertificateForEWS',
    'SnMFarmerCertificate',
    'OBCMemberCertificate',
    'BirthDeathCertificate',
    'AgricultureIncomeCertificate',
    'PossessionCertificate',
    'NoEarningMemberCertificate',
    'ManualAdangalCertificate',
    'MutationTitleDeedPPB',
    'TitleDeedCumPassbook',
    'ROR1BCertificate',
    'PrintingOfTitleDeedCumPassbook',
    'ChangeOfNameCertificate',
    'HouseSiteDFormPatta',
    'NoPropertyCertificate',
    'OccupancyRightsCertificateForInamLands',
    'ClaimsUnderDottedLands',
    'ResurveyofMutationforCorrection',
    'PattadarAadharSeedingbyLPnumber',
    'mutationTransactionsWithLPMNumbers',
    'WaterTaxService',
    'ReIssuanceCertificate'
  ];

  public static statutaryChargesNotZeroRV = [
    'BirthDeathCertificate',
    'MutationTitleDeedPPB',
    'TitleDeedCumPassbook',
    'ResurveyofMutationforCorrection',
    'mutationTransactionsWithLPMNumbers'
  ];
}

enum enumContentType {
  TextPKCS7 = 1,
  TextPKCS1 = 2,
  XML = 3,
  Sha256HashPKCS7 = 4,
  Sha256HashPKCS1 = 5,
  CSRSha256HashPKCS1 = 6,
  TBSCertificate = 7,
  TextPKCS7ATTACHED = 8
}
