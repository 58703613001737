import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';
import { CommonService } from 'src/shared/common.service';
import { fileExtensionValidator, fileSizeValidator } from 'src/shared/validators/file-validator';

@Component({
  selector: 'app-mutationtitledeed-view',
  templateUrl: './mutationtitledeed-view.component.html',
  styleUrls: ['./mutationtitledeed-view.component.css']
})
export class MutationtitledeedViewComponent implements OnInit {
  @Input() reqId: any;
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  @Output() viewImg = new EventEmitter();
  commonConstants: any = CommonConstants;
  regDetails :any
  sellerRORDetailsTable :any
  rorDetailsTable :any
  editRorDetailsTable :any =[]
  updatedSurveyDetails :any =[]
  ExecutantInfoTable :any =[]
  regSurveyNo :any;
  isSignatureRequired: Boolean = false;
  isPhotoRequired: Boolean = false;
  fileForm: FormGroup;
  @ViewChild('passportSizeLabel')
  passportSizeLabel!: ElementRef;
  @ViewChild('signatureLabel')
  signatureLabel!: ElementRef;
  submitted: Boolean = false;
  updatedFileList: any = [];
  ngOnInit(): void {
    this.fileList.forEach((item: any) => {
      this.updatedFileList.push({
        FileName: item.fileName,
        DocumentType: item.documentType,
        BlobRef: item.blobRef
      })
    })
    
    if(this.fileList.filter((file:any) => file.documentType === 'Signature').length === 0) {
      this.isSignatureRequired = true;
      this.f.signature.setValidators([Validators.required]);
      this.f.signature.updateValueAndValidity();
    }
    if(this.fileList.filter((file:any) => file.documentType === 'Passport size photo').length === 0) {
      this.isPhotoRequired = true;
      this.f.passportSizePhoto.setValidators([Validators.required]);
      this.f.passportSizePhoto.updateValueAndValidity();
    }
    this.regDetails = (this.certificateInfo?.registrationOutput !== '' && this.certificateInfo?.registrationOutput?.length > 0) ? JSON.parse(this.certificateInfo?.registrationOutput) : [];
    console.log("regDetails",this.regDetails)

    this.sellerRORDetailsTable = (this.certificateInfo?.sellerRORDetails?.length > 0)? JSON.parse(this.certificateInfo?.sellerRORDetails): [];
    console.log("sellerRORDetailsTable",this.sellerRORDetailsTable)
    this.rorDetailsTable = JSON.parse(this.certificateInfo?.rorDetails)
    console.log("rorDetailsTable",this.rorDetailsTable)
    
    this.regSurveyNo = JSON.stringify(this.certificateInfo?.registrationInput?.regSurveyNo)

    if(this.certificateInfo?.serviceType==="Mutation for Corrections"){
      this.editRorDetailsTable = [];
      this.updatedSurveyDetails = JSON.parse(this.certificateInfo?.updatedSurveyDetails);

      for(let item of this.rorDetailsTable){
        if(item.pSurvey_no == this.certificateInfo?.editSurveyNo){
          this.editRorDetailsTable = item;
        }
      }
    }
    console.log("editRorDetailsTable",this.editRorDetailsTable)
    console.log("updatedSurveyDetails",this.updatedSurveyDetails)
    
    if(this.certificateInfo?.serviceType==="Mutation for Transactions"){
      this.ExecutantInfoTable = [];

      for(let cnt = 0; cnt < this.certificateInfo?.mutationCategoryInfo.length; cnt++) {
        if(this.certificateInfo?.mutationCategoryInfo[0].successorName.indexOf("$") >= 0) {
          let tempName = this.certificateInfo?.mutationCategoryInfo[0].successorName.split("$");
          let tempFatherName = this.certificateInfo?.mutationCategoryInfo[0].fatherName.split("$");
          //let tempAddress = this.certificateInfo?.mutationCategoryInfo[0].sellerAddress.split("$");
         
          
         // console.log("tempAddress==",tempAddress)
    
          for(let i=0; i<tempName.length ; i++)
          { 
            let temp: any = {};
    
            temp.id = i;
            temp.successorName = tempName[i];
            temp.fatherName = tempFatherName[i];
            temp.sellerAddress = this.certificateInfo?.mutationCategoryInfo[0].sellerAddress;
            temp.surveyNo = this.certificateInfo?.mutationCategoryInfo[0].surveyNo;
            temp.totalExtent = this.certificateInfo?.mutationCategoryInfo[0].totalExtent;
            temp.khataNo = this.certificateInfo?.mutationCategoryInfo[0].khataNo;
            temp.extentArea = this.certificateInfo?.mutationCategoryInfo[0].extentArea;
            temp.documentNo = this.certificateInfo?.mutationCategoryInfo[0].documentNo;
            temp.village = this.certificateInfo?.mutationCategoryInfo[0].village;
            temp.documentDate = this.certificateInfo?.mutationCategoryInfo[0].documentDate;
    
            this.ExecutantInfoTable.push(temp);
          }

        } else {
          let temp: any = {};
    
            temp.id = cnt;
            temp.successorName = this.certificateInfo?.mutationCategoryInfo[cnt].successorName;
            temp.fatherName = this.certificateInfo?.mutationCategoryInfo[cnt].fatherName;
            temp.sellerAddress = this.certificateInfo?.mutationCategoryInfo[cnt].sellerAddress;
            temp.surveyNo = this.certificateInfo?.mutationCategoryInfo[cnt].surveyNo;
            temp.totalExtent = this.certificateInfo?.mutationCategoryInfo[cnt].totalExtent;
            temp.khataNo = this.certificateInfo?.mutationCategoryInfo[cnt].khataNo;
            temp.extentArea = this.certificateInfo?.mutationCategoryInfo[cnt].extentArea;
            temp.documentNo = this.certificateInfo?.mutationCategoryInfo[cnt].documentNo;
            temp.village = this.certificateInfo?.mutationCategoryInfo[cnt].village;
            temp.documentDate = this.certificateInfo?.mutationCategoryInfo[cnt].documentDate;
    
            this.ExecutantInfoTable.push(temp);
        }
      }

      console.log("this.ExecutantInfoTable========>", this.ExecutantInfoTable)
    }
 
    

  }
  constructor(private commonService: CommonService, private formBuilder: FormBuilder, private router: Router,  private modalService: NgbModal) {
    this.fileForm = this.formBuilder.group({
      passportSizePhoto: [''],
      signature: ['']
    })
  }
  get f() {
    return this.fileForm.controls;
  }
  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  viewImgFile(file: any) {
    this.viewImg.emit(file);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
  onpassportSizeFileSelected(fileEvent: any) {
    const passportSizeFile: File | null = fileEvent.target.files[0];
    //convert image to byte array and byte array to base64
    const file = fileEvent.target.files[0];
 //convert image to byte array and byte array to base64
    const fileExtensionType = fileEvent.target.files[0]?.name.split('.').pop();
    this.passportSizeLabel.nativeElement.innerText =
      fileEvent.target.files.length !== 0
        ? fileEvent.target.files[0]?.name
        : 'No File Choosen';
        var validExt = "";
        // console.log('fileExtensionType',fileExtensionType)
        if(fileExtensionType.toLowerCase() === 'jpg'){
          validExt = "jpg"
        }else {
          validExt = "jpeg"
        }
    this.f.passportSizePhoto.setValidators([Validators.required, fileExtensionValidator(validExt),
       fileSizeValidator(fileEvent)
    ]);
    this.f.passportSizePhoto.updateValueAndValidity();
    if (this.f.passportSizePhoto.valid){
      this.fileUpload(
        passportSizeFile,
        this.f['passportSizePhoto'].value,
        fileEvent.target.files[0].name,
        'Passport size photo',
        'passportSizePhoto', 
        'passportSizeLabel'
      );
    }
      
  }
  onsignatureFileSelected(fileEvent: any) {
    const signatureFile: File | null = fileEvent.target.files[0];
    const fileExtensionType = fileEvent.target.files[0]?.name.split('.').pop();
    var validExt = "";
    const file = fileEvent.target.files[0];
    // console.log('fileExtensionType',fileExtensionType)
    if(fileExtensionType.toLowerCase() === 'jpg'){
      validExt = "jpg"
    }else if(fileExtensionType.toLowerCase() === 'jpeg') {
      validExt = "jpeg"
    } else {
      validExt = "others"
    }
    this.signatureLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
    this.f.signature.setValidators([Validators.required, fileExtensionValidator(validExt.toLowerCase()), fileSizeValidator(fileEvent)]);
    this.f.signature.updateValueAndValidity();
    if (this.f.signature.valid)
     { 
      this.fileUpload(signatureFile, this.f['signature'].value, fileEvent.target.files[0].name, 'Signature', 'signature', 'signatureLabel');
    }
  }
  clearFileUpload(name: string, label: string) {
    this.f[name].reset();
    if (label === 'passportSizeLabel') {
      this.passportSizeLabel.nativeElement.innerText = 'No File Choosen';
    }
    else if (label === 'signatureLabel') {
      this.signatureLabel.nativeElement.innerText = 'No File Choosen';
    } 
  }
  fileUpload(file: any, filePath: string, fileName: string, documentType: string, name: string = '', label: string = '') {
    const formData = new FormData();
    formData.append('File', file, fileName);
    formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
    this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload, formData).subscribe({
      next: (responseData: any) => {
        if(typeof(responseData.result) !== 'string'){
          let index = this.updatedFileList.map((item:any) => item.DocumentType).indexOf(documentType);
          if (index > -1) {
            this.updatedFileList.splice(index, 1);
          }
          this.updatedFileList.push({
            FileName: fileName,
            DocumentType: documentType,
            BlobRef: responseData['result'].blobRef
          })
        }else{
          this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
          if(name && label) {
            this.clearFileUpload(name, label);
          }
        }
      },
      error: (error) => {
        if(name && label) {
          this.clearFileUpload(name, label);
        }
      }
    });
  }
 
  saveFileDetails(): any {
    this.submitted = true;
    if(this.isPhotoRequired && this.updatedFileList.filter((file:any) => file.DocumentType === 'Passport size photo').length === 0) {
      this.commonService.commonErrorPopup('Alert', 'Passport Size photo is not uploaded properly. Please try again.', 'md');
      return false;
    } else if (this.isSignatureRequired && this.updatedFileList.filter((file:any) => file.DocumentType === 'Signature').length === 0) {
      this.commonService.commonErrorPopup('Alert', 'Signature is not uploaded properly. Please try again.', 'md');
      return false;
    }
    let fileData = {
      FileList: this.updatedFileList,
      RequisitionId: this.reqId,
    };
    this.commonService
      .postRequest(this.commonConstants.updateFileUpload, fileData)
      .subscribe({
        next: (resData: any) => {
          if(resData.result?.updateResult == 'Success') {
            const modalRef = this.modalService.open(CommonMsgModalComponent, {size: 'md' });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = 'File(s) uploaded successfully.';
            modalRef.result.then((result: any) => {
              window.location.reload();
            });
          } else {
            this.commonService.commonErrorPopup('Alert', 'An error occured while updating file list in the requisition.', 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
}
