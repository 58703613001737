<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Selected Criteria Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No :</label>
                <div class="valueCls">{{basicInfo?.rationCardNo}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo?.remarks}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Registration Unit Id:</label>
                <div class="valueCls">{{certificateInfo?.registrationUnitId}}</div>
            </div>
           
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">No Of Copies:</label>
                <div class="valueCls">{{certificateInfo?.noOfCopies ? (certificateInfo?.noOfCopies) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Purpose Of Certificate:</label>
                <div class="valueCls">{{certificateInfo?.purposeOfCertificate }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Death Criteria Details</div>
    <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Deceased Name:</label>
                <div class="valueCls">{{certificateInfo?.deceasedName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Deceased SurName:</label>
                <div class="valueCls">{{certificateInfo?.deceasedSurName }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Relation With Parent:</label>
                <div class="valueCls">{{certificateInfo?.relationWithParent }}</div>
            </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Father Name:</label>
                <div class="valueCls">{{certificateInfo?.fatherName ? (certificateInfo?.fatherName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Father SurName:</label>
                <div class="valueCls">{{certificateInfo?.fatherSurName ? (certificateInfo?.fatherSurName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mother Name:</label>
                <div class="valueCls">{{certificateInfo?.motherName ? (certificateInfo?.motherName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mother SurName:</label>
                <div class="valueCls">{{certificateInfo?.motherSurName ? (certificateInfo?.motherSurName) : '-' }}</div>
            </div>
       
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Date Of Death:</label>
                <div class="valueCls">{{certificateInfo?.dateOfDeath ? (certificateInfo?.dateOfDeath) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Place Of Death:</label>
                <div class="valueCls">{{certificateInfo?.placeOfDeath ? (certificateInfo?.placeOfDeath) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfDeath ==='Hospital'">Hospital Adress :</label> 
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfDeath ==='Residence'">Residence Address :</label>
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfDeath ==='Others'">Others Address :</label>                   <div class="valueCls">{{certificateInfo?.locationName ? (certificateInfo?.locationName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfDeath ==='Hospital'">Hospital Adress :</label> 
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfDeath ==='Residence'">Residence Address :</label>
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfDeath ==='Others'">Others Address :</label>   
                <div class="valueCls">{{certificateInfo?.address1 ? (certificateInfo?.address1) : '-' }}</div>
            </div>
       
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfDeath ==='Hospital'">Hospital Adress :</label> 
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfDeath ==='Residence'">Residence Address :</label>
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfDeath ==='Others'">Others Address :</label>    
                               <div class="valueCls">{{certificateInfo?.address2 ? (certificateInfo?.address2) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfDeath ==='Hospital'">Hospital Adress :</label> 
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfDeath ==='Residence'">Residence Address :</label>
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfDeath ==='Others'">Others Address :</label>                   <div class="valueCls">{{certificateInfo?.address3 ? (certificateInfo?.address3) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo?.district ? (certificateInfo?.district) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Location:</label>
                <div class="valueCls">{{certificateInfo?.location ? (certificateInfo?.location) : '-' }}</div>
            </div>
       
        </div>
    </div>
</div>

<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
      
            </div>
            </ng-container>
        </div>
    </div>
</div>

