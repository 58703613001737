import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService, CustomAdapter, CustomDateParserFormatter, WhitespaceValidator } from 'src/shared/common.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonConstants } from 'src/constants/common-constants.component';

@Component({
  selector: 'app-good-government',
  templateUrl: './good-government.component.html',
  styleUrls: ['./good-government.component.css'],
  providers: [DatePipe,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class GoodGovernmentComponent implements OnInit {
  goodGovernmentForm: FormGroup;
  isSubmitted: boolean = false;
  role: any;
  maxDate: any;
  minDate: any;
  secretariatCode: any;
  secretariatName: any;
  houseHoldSurveyData: any;
  designation: any = [];
  commonConstants: any = CommonConstants;
  showInput: boolean = false;
  confirmModal: any;
  isEditable:boolean = true;
  currentIndex=0;
  editState:boolean[]=[];
  currentHouseHoldNumber:number[]=[];
  houseHoldId:string="";

  constructor(private formBuilder: FormBuilder, private commonService: CommonService, private modalService:NgbModal) {
    this.goodGovernmentForm = this.formBuilder.group({
      date: ['', [Validators.required]],
      householdsVisited: ['', [Validators.required]],
      // chiefGuestName: ['', [Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      // chiefGuestDesignation: ['', [Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      // chiefGuestDesignationName: ['', [Validators.required]],
      guestDetails: this.formBuilder.array([]),
    });
    this.maxDate = {
      year: 2024,
      month: 9,
      day: 26
    };
    this.minDate = {
      year: 2024,
      month: 9,
      day: 20
    };
    this.secretariatCode = localStorage.getItem('locationCode');
    let userInfo = localStorage.getItem('userInfo');
    if (userInfo && userInfo !== null) {
      let userData = JSON.parse(userInfo);
      this.role = userData.jobTitle;
      this.secretariatName = userData.givenName;
    }
  }

  ngOnInit(): void {
    this.getHHSurveyDetails();
    this.getDesignation()
    this.addGuestDetails(0)
    this.editState =  this.houseHoldSurveyData.map(()=>false)
    this.houseHoldSurveyData.forEach((data:any,index:any)=>{
      this.currentHouseHoldNumber[index]=data;
    })
  }
  get guestDetails() {
    return this.goodGovernmentForm.get('guestDetails') as FormArray;
  }
  get f() {
    return this.goodGovernmentForm.controls;
  }
  pushGuestDetails(){
    return this.formBuilder.group({
      chiefGuestName: ['', [Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      chiefGuestDesignation: ['', [Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      chiefGuestDesignationName: ['',],
    })
  }
  addGuestDetails(index:number){
    this.guestDetails.push(this.pushGuestDetails())
  }

  getHHSurveyDetails() {
    this.commonService.getRequest(this.commonConstants.GetHouseHoldSurveyDetails + this.secretariatCode).subscribe({
      next: (responseData: any) => {
        if (responseData && responseData.result && responseData.result.isSuccess == true) {
          this.houseHoldSurveyData = responseData.result.houseHoldSurveyData;
        } else if (responseData && responseData.result && responseData.result.isSuccess == false) {
          this.commonService.commonErrorPopup('Alert', responseData.result.error , 'md');
        } else {
          this.commonService.commonErrorPopup('Alert', 'Error occured while fetching data' , 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    })
  }

  submit(): any {
    this.isSubmitted = true;
    if (!this.goodGovernmentForm.valid) {
      return false;
    } else {
      let dateFormat = this.commonService.formatDate(this.f.date.value);
      // if(!this.showInput){
      //   this.goodGovernmentForm.patchValue({chiefGuestDesignation:this.f.chiefGuestDesignationName.value})
      // }
      let guestNames:any = []
      let emptyInput= true
      this.guestDetails.value.forEach((element:any, index:any) => {
       let guestData:any={}
       if(element.chiefGuestName ==''&& element.chiefGuestDesignationName == "" && index != (this.guestDetails.value.length- 1)){
        this.commonService.commonErrorPopup('Alert', 'Please enter chief guest details.', 'md');
        emptyInput =false
      return ;
       }
        if(element.chiefGuestDesignationName === 'others'){
          guestData.chiefGuestName = element.chiefGuestName
          guestData.chiefGuestDesignation = element.chiefGuestDesignation
        }else{
          guestData.chiefGuestName = element.chiefGuestName
          guestData.chiefGuestDesignation = element.chiefGuestDesignationName
        }
        if(guestData.chiefGuestName){
          guestNames.push(guestData)
        }
       // guestNames.push(guestData)

      });
      if(emptyInput){
      let payload: any = {
        surveyDate: dateFormat,
        noOfHouseholds: this.f.householdsVisited.value.toString(),
        secretariatCode: this.secretariatCode,
        secretariatName: this.secretariatName,
        //chiefGuestName: this.f.chiefGuestName.value,
       // designation: this.f.chiefGuestDesignation.value,
        chiefGuestNames: guestNames
      }
      this.commonService.postRequest(this.commonConstants.SaveHouseHoldSurveyDetails, payload).subscribe({
        next: (responseData: any) => {
          if (responseData && responseData.result && responseData.result.isSuccess == true) {
            this.isSubmitted = false;
            this.goodGovernmentForm.reset();
            this.guestDetails.controls.splice(0,this.guestDetails.length);
            this.guestDetails.value.splice(0,this.guestDetails.length);
            this.addGuestDetails(0)
            this.commonService.commonErrorPopup('Alert', 'Data saved successfully' ,'md');
            this.getHHSurveyDetails();
          } else if (responseData && responseData.result && responseData.result.isSuccess == false) {
            this.commonService.commonErrorPopup('Alert', responseData.result.errorDescription ,'md');
          } else {
            this.commonService.commonErrorPopup('Alert', 'Error occured while saving the data' ,'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }
    }
  }
  onDesignationChange(index:any, value:any){
   
    if(value.indexOf('others') >= 0){
      this.showInput = true 
      this.guestDetails.controls[index].get('chiefGuestDesignation')?.setValidators([Validators.required])
      this.guestDetails.controls[index].get('chiefGuestDesignation')?.updateValueAndValidity()
      
      //this.guestDetails.value[index].chiefGuestDesignation.setValidators([Validators.required]);
      //this.guestDetails.value[index].chiefGuestDesignation.updateValueAndValidity();
    }else{
      this.guestDetails.controls[index].get('chiefGuestDesignation')?.clearValidators();
      this.guestDetails.controls[index].get('chiefGuestDesignation')?.reset();
    }
  }
  getDesignation() {
    this.commonService.getRequest(this.commonConstants.getHouseSiteDesignation).subscribe({
      next: (responseData: any) => {
        this.designation = this.commonService.sortData(responseData.result);
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  delete(index:any){
    this.guestDetails.controls.splice(index,1)
    this.guestDetails.value.splice(index,1)
  }
  openDeleteHouseHold(confirmmodal:any,houseHoldId:string){
    this.confirmModal = this.modalService.open(confirmmodal, { size: 'lg', centered: true, backdrop: 'static'});
    this.houseHoldId=houseHoldId
  }
 
  deleteHouseHold(){
    this.confirmModal.close();
 
    if(!this.houseHoldId){
      this.commonService.commonErrorPopup('Alert', 'This is not valid id.', 'md');
      return;
    }
    const deletePostData={"id":this.houseHoldId}
    this.commonService.postRequest(this.commonConstants.DeleteHouseHoldSurveyDetails, deletePostData).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.commonService.commonErrorPopup('Alert', 'The data is deleted successfully.', 'md');
          this.getHHSurveyDetails();
        } else if(responseData.result?.isSuccess == false && responseData.result?.error) {
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md')
        } else {
          this.commonService.commonErrorPopup('Alert', 'An error occured while delete the schedule. Please try again.', 'md')
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
 
  editHouseHold(index:number,currentHouseHoldNumber:number){
    this.editState[index]=true;
    this.isEditable = !this.editHouseHold
    this.currentHouseHoldNumber[index]=currentHouseHoldNumber
  }
 
  isNumber(value:any){
    return !isNaN(value) && !isNaN(parseFloat(value))
  }
 
  updateHouseHold(index:any,noOfHouseHold:number,id:any){
  if(!this.isNumber(noOfHouseHold)){
      this.commonService.commonErrorPopup('Alert', 'Please enter No. Of Households Visited', 'md');
      return;
    }
    this.editState[index]=false;
    const updatedData={"id":id,"noOfHouseholds":noOfHouseHold}
    this.commonService.postRequest(this.commonConstants.UpdateHouseHoldSurveyDetails, updatedData).subscribe({
      next: (responseData: any) => {
        if (responseData && responseData.result && responseData.result.isSuccess == true) {
          this.isSubmitted = false;
          this.goodGovernmentForm.reset();
          this.commonService.commonErrorPopup('Alert', 'Data updated successfully' ,'md');
          this.getHHSurveyDetails();
          this.currentHouseHoldNumber=[];
        } else if (responseData && responseData.result && responseData.result.isSuccess == false) {
          this.commonService.commonErrorPopup('Alert', responseData.result.errorDescription ,'md');
        } else {
          this.commonService.commonErrorPopup('Alert', 'Error occured while saving the data' ,'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
 
  cancelHouseHold(index:any){
    this.editState[index]=false;
  }
 

}
