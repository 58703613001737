
<div class="container-fluid mainCls">
   
<div class="mainPageContainer mt-15 mr-3">
<div>
    <div class="row">
        <h6 class="mb-3 col-10"><b>PAN Details collection for Secretariat: </b></h6>
    <div class="btnHolder mb-3 col-2" *ngIf="this.role == 'Panchayat Secretary' ||  this.role == 'Ward Admin Secretary'">
        <button class="greenBtnCls homebtn mr-3 my-3"  (click)="goToHome()">Home</button>
    </div>
    </div>
    
    <hr class="hrCls mt-4">
    <form [formGroup]="panCard">

            <div class="row  mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="subHeadingCls">Secretariat PAN number :<span class="mandatoryCls">*</span></label></div>
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <input type="password" class="inputCls value" maxlength="10" (keyup)="onKeyUp($event)"
                        name="pan" id="pan"  appBlockCopyPaste formControlName="panNumber" placeholder=" ex .ARXYP5543Z" [ngClass]="{ 'is-invalid':submitted && f.panNumber.errors }">
                        <div *ngIf="submitted && f.panNumber.errors" class="invalid-feedback">
                            <div *ngIf="f.panNumber.errors.required">Please enter Secretariat PAN number</div>
                            <div *ngIf="f.panNumber.errors?.minlength">PAN number length should be 10 digits</div>  
                            <div *ngIf="f.panNumber.errors.pattern">PAN number should be in valid format ex.ARXYP5543Z</div>                        
                    
                        </div>
                    </div> 
                </div>  

                <div class="row  mb-3"> 
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="subHeadingCls">Re-enter Secretariat PAN number :<span class="mandatoryCls">*</span></label></div>
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <input type="text" class="inputCls value" maxlength="10" placeholder="ex .ARXYP5543Z"  
                        name="panNum" id="panNum" (keyup)="onKeyUpPAN($event)" appBlockCopyPaste formControlName="enterPanNumber" [ngClass]="{ 'is-invalid':submitted && f.enterPanNumber.errors }">
                        <div *ngIf="submitted && f.enterPanNumber.errors" class="invalid-feedback">
                            <div *ngIf="f.enterPanNumber.errors.required">Please re-enter Secretariat PAN number</div>
                            <div *ngIf="f.enterPanNumber.errors?.minlength">PAN number length should be 10 digits</div> 
                            <div *ngIf="f.enterPanNumber.errors.pattern">PAN number should be in valid format ex.ARXYP5543Z</div>                        
                    
                        </div>
                    </div> 
                   
                    </div>
          
                    <div class="row  mb-3"> 
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="subHeadingCls">Name as per PAN number :<span class="mandatoryCls">*</span></label></div>
                            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <input type="text" class="inputCls value" maxlength="150" 
                            name="name" id="name"  formControlName="name" [ngClass]="{ 'is-invalid':submitted && f.name.errors }">
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Please enter name as per PAN number</div>
                                
                            </div>
                        </div> 
                       
                        </div>
             
                        <div class="row  mb-3"> 
                            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <label class="subHeadingCls">Date of Incorporation/Formation :<span class="mandatoryCls">*</span></label></div>
                                <div class="input-group col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <input class="calendarCls form-control" placeholder="dd/mm/yyyy"
                                        name="a" ngbDatepicker #a="ngbDatepicker"
                                        [minDate]="minDate" [maxDate]="maxDate" 
                                        formControlName="date" required [ngClass]="{'is-invalid':submitted &&
                                        f.date?.errors }">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary calendar buton"
                                            (click)="a.toggle()" type="button"></button>
                                    </div>
                                    <div *ngIf="submitted && f.date.errors"
                                        class="invalid-feedback">
                                        <div
                                            *ngIf="f.date.errors?.required || f.date?.invalid">
                                            Please enter valid Date
                                        </div>
                                    </div>
                                </div>                           
                            </div>

                            <div class="row mb-3">
                                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                    <label class="subHeadingCls">Is the e-Services & Pension bank account linked to PAN number? <span class="mandatoryCls">*</span></label></div>
                                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <input type="radio" value="Yes" id="yes" formControlName="linkedPan" />
                                        <label class="mx-2" for="yes">Yes</label>
                                        <input type="radio" value="No" id="no" [checked]="true" formControlName="linkedPan"/>
                                        <label class="mx-2" for="no" >No</label>
                                        <div *ngIf="submitted && f.linkedPan.errors"   class="selectError">
                                        <div
                                            *ngIf="f.linkedPan.errors?.required || f.linkedPan?.invalid">
                                            Please select Yes or No
                                        </div>
                                    </div>  
                                    </div>
                                      
                                    </div> 
                                    <div class="row mb-3">

                                        <div class="col-3">
                                            <label class="subHeadingCls">PAN Card(It should be pdf format and size should be less than or equal to 1 MB) :<span class="mandatoryCls">*</span></label></div>
                                           
                                            <div class="row" style="margin-left:1%">
                                                <div class ="col-9">
                                                    <input type="file" class="custom-file-input" name="pancard" formControlName = "pancard" required [ngClass]="{ 'is-invalid':submitted && f.pancard.errors }" accept=".pdf" (change) = "onPanSelected($event)">
                                                    <label class="custom-file-label" #pancardLabel for="pancard"><i class="fas fa-search"></i>No File choosen</label>
                                                </div>
                                                <div class="clearCls col-1 ml-2" (click)="clearFileUpload('pancard', 'pancardLabel')" *ngIf="f.pancard.value"></div>   
                                              
                                                <div *ngIf="submitted && f.pancard.errors" class="selectError">
                                                    <div  *ngIf="f.pancard.errors?.required">
                                                        Please upload PAN Card 
                                                    </div>
                                                    <div *ngIf="f.pancard?.errors?.inValidExt && !f.pancard.errors.required ">Please upload the file in pdf Format</div>
                                                    <div *ngIf="f.pancard.errors?.inValidSize && !f.pancard.errors.required && !f.pancard.errors.inValidExt">File size exceeding 1 MB</div> 
                                       
                                                </div>
                                           
                                            </div>
                                               <div class="col-2 regDocLink"  *ngIf="isAvailable"><a  (click)="downloadFile()">Download Previous Document</a></div>
                                           
                                            </div> 
                            

                </form>
                </div>
             
                <div class="row mb-3 mt-4 ml-20">
                
                    <div class="btnHolder text-center col-12" *ngIf="!isAvailable">                        
                        <button class="btn btn-primary mx-auto d-block"  (click)="savePanDetails()">Save</button>
                    </div>
                    <div class="btnHolder text-center col-12" *ngIf="isAvailable">                        
                        <button class="btn btn-primary mx-auto d-block"  (click)="updatePanDetails()">Update</button>
                    </div>
                </div>
      
 
   </div>


</div>