<div *ngIf="certificateInfo">

    <div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
        <div class="subHeadingCls mb-3">Applicant Details</div>
        <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Purpose of certificate :</label>
                <div class="valueCls">{{certificateInfo?.purposeOfCertificate ? (certificateInfo?.purposeOfCertificate) : '-' }}</div>
            </div>
  
    </div>
       
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Nearby Landmark to your address :</label>
                    <div class="valueCls">{{certificateInfo?.landMark ? (certificateInfo?.landMark) : '-' }}</div>
                </div>
      
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Area/Locality/Sector :</label>
                <div class="valueCls">{{certificateInfo?.area ? (certificateInfo?.area) : '-' }}</div>
            </div>
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
    <div class="fieldHolder">
        <label class="labelCls">Street/Road/Lane :</label>
        <div class="valueCls">{{certificateInfo?.street ? (certificateInfo?.street) : '-' }}</div>
    </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Village/Town/City :</label>
            <div class="valueCls">{{certificateInfo?.village ? (certificateInfo?.village) : '-' }}</div>
        </div>
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Panchayat Name/Ward Number :</label>
            <div class="valueCls">{{certificateInfo?.panchayatWardName ? (certificateInfo?.panchayatWardName) : '-' }}</div>
        </div>
    </div>
    </div>
        </div>
    
        <div class="certificateInfo">
            <div class="subHeadingCls mb-3">Workflow Details</div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">District :</label>
                        <div class="valueCls">{{certificateInfo?.workflowDistrictName}}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Mandal/Municipality :</label>
                        <div class="valueCls">{{certificateInfo?.workflowMandalName}}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Village/Ward/Secretariat :</label>
                        <div class="valueCls">{{certificateInfo?.workflowSecretariatName}}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Designation :</label>
                        <div class="valueCls">{{certificateInfo?.workflowDesignation}}</div>
                    </div>
                </div> 
            </div>
        </div>  

      <div class="certificateInfo">
        <div class="row">
            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
                <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                  
                   
                    <div *ngIf="file.documentType === 'aadhar'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Aadhar Card:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'otherDocument'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Other Documents (Rice Card/Driving License/Passport/Electricity Bill/Gas Bill):</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    
                  
                </div>
                </ng-container>
            </div>
        </div>
    </div>


</div>