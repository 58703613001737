import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[numbersOnly]'
})
export class NumberOnlyInputDirective {

  constructor(private elRef: ElementRef) { }

  @HostListener('keypress', ['$event']) onKeyPress(event:any) {
    let key = event.key;
    let numberPattern = /^[0-9]*$/;
    if (!numberPattern.test(key)) {
     event.preventDefault();
    }
  }

}