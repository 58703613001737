<div class="certificateInfo" *ngIf="certificateInfo">
    <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div class="subHeadingCls mb-3">Applicant Details</div>
            <div class="fieldHolder">
                <label class="labelCls">Aadhar No: </label>
                <div class="valueCls">{{ applicantAadharNumber }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Applicant Name: </label>
                <div class="valueCls">{{ certificateInfo.rofrApplicantDetails.applicantNameEnglish }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Relation With Deceased Pattadar: </label>
                <div class="valueCls">{{ certificateInfo.relationWithDeceasedPattadar }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">FMC No: </label>
                <div class="valueCls">{{ certificateInfo.rofrApplicantDetails.fmcNo }}</div>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div class="subHeadingCls mb-3">Request for Mutation for Land Located in</div>
            <div class="fieldHolder">
                <label class="labelCls">District: </label>
                <div class="valueCls">{{ certificateInfo.rofrLandLocatedDetails.districtName }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal: </label>
                <div class="valueCls">{{ certificateInfo.rofrLandLocatedDetails.mandalName }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Village: </label>
                <div class="valueCls">{{ certificateInfo.rofrLandLocatedDetails.villageName }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Secretariat: </label>
                <div class="valueCls">{{ certificateInfo.rofrLandLocatedDetails.secretariatName }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Division: </label>
                <div class="valueCls">{{ certificateInfo.rofrLandLocatedDetails.divisionName }}</div>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div class="subHeadingCls mb-3">Deceased pattadar details</div>
            <div class="fieldHolder">
                <label class="labelCls">Deceased Pattadar Aadhar: </label>
                <div class="valueCls">{{ deceasedAadharNumber }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Plot Ids for mutation: </label>
                <div class="valueCls">{{ plotIds }}</div>
            </div>
        </div>
    </div>
    <hr class="hrCls mt-4">
    <div class="row">
        <div class="col">
            <div class="subHeadingCls mb-3">ROFR Details:</div>
            <div class="tab table-responsive">
                <table class="tableCls">
                    <thead>
                        <tr>
                            <th>Serial No</th>
                            <th>Plot Id</th>
                            <th>Beneficiary Id</th>
                            <th>Compartment No</th>
                            <th>Pattadar Name</th>
                            <th>Pattadar Father / Husband Name</th>
                            <th>Plot Extent (Acres-Cents)</th>
                            <th>Occupant Extent (Acres-Cents)</th>
                            <th>Forest Division</th>
                            <th>Forest Beat</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let data of certificateInfo.rofrLandDetails; let i=index">
                        <tr>
                            <td>{{ i + 1 }}</td>
                            <td>{{ data.plotId }}</td>
                            <td>{{ data.beneficiaryId }}</td>
                            <td>{{ data.compartmentNo }}</td>
                            <td>{{ data.pattadarName }}</td>
                            <td>{{ data.pattadarRelationName }}</td>
                            <td>{{ data.extent }}</td>
                            <td>{{ data.occupantExtent }}</td>
                            <td>{{ data.forestDivisionName_Eng }}</td>
                            <td>{{ data.forestBeatName_Eng }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <hr class="hrCls mt-4">
    <div class="row">
        <div class="col">
            <div class="subHeadingCls mb-3">Details of Deceased Pattadar and Proposed Pattadar for Mutation</div>
            <div class="tab table-responsive">
                <table class="tableCls">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Aadhar Number</th>
                            <th>Proposed Pattadar Name English (Family Member Name)</th>
                            <th>Proposed Pattadar Name Telugu (Family Member Name)</th>
                            <th>Gender</th>
                            <th>Date Of Birth</th>
                            <th>Mobile Number</th>
                            <th>Caste</th>
                            <th>Sub Caste</th>
                            <th>Proposed Pattadar Father/Husband Name English (Family Member Name)</th>
                            <th>Proposed Pattadar Father/Husband Name Telugu (Family Member Name)</th>
                            <th>Beneficiary Id</th>
                            <th>Postal Address</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let data of certificateInfo.proposedPattadarDataList; let i=index">
                        <tr>
                            <td>{{ i + 1 }}</td>
                            <td>{{ data.aadharNo }}</td>
                            <td>{{ data.name }}</td>
                            <td>{{ data.nameInTelugu }}</td>
                            <td>{{ data.gender }}</td>
                            <td>{{ data.dob | date: 'dd/MM/yyyy' }}</td>
                            <td>{{ data.mobileNo }}</td>
                            <td>{{ data.caste }}</td>
                            <td>{{ data.subCaste }}</td>
                            <td>{{ data.fatherHusbandName }}</td>
                            <td>{{ data.fatherHusbandNameInTelugu }}</td>
                            <td>{{ data.beneficiaryId }}</td>
                            <td>{{ data.postalAddress }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <hr class="hrCls mt-4">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <ng-container *ngIf="fileList && fileList?.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                    <div *ngIf="file.documentType === 'Deceased Pattadar Old Passbook'">
                        <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Old Passbook of Deceased Pattadar:</label>
                        <div class="valueCls">
                        <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                            class="download ml-2"></span>
                        </div>
                    </div>
                    <div *ngIf="file.documentType === 'Death Certificate'">
                        <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Death Certificate:</label>
                        <div class="valueCls">
                        <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                            class="download ml-2"></span>
                        </div>
                    </div>
                    <div *ngIf="file.documentType === 'Family Member Certificate'">
                        <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Family Member Certificate:</label>
                        <div class="valueCls">
                        <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                            class="download ml-2"></span>
                        </div>
                    </div>
                    <div *ngIf="file?.documentType === 'Affidavit'">
                        <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Affidavit:</label>
                        <div class="valueCls">
                        <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                            class="download ml-2"></span>
                        </div>
                    </div>
                    <div *ngIf="file.documentType === 'Applicant Photo'">
                        <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Applicant Photo:</label>
                        <div class="valueCls">
                          <a (click)="viewImgFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                            class="download ml-2"></span>
                        </div>
                    </div>
                    <div *ngIf="file.documentType === 'Applicant Signature'">
                        <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Applicant Signature:</label>
                        <div class="valueCls">
                          <a (click)="viewImgFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                            class="download ml-2"></span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>