<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.remarks ? (basicInfo?.applicantInfo?.remarks) : '-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo?.districtName ? (certificateInfo?.districtName) : '-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo?.mandalMunicipalityName ? (certificateInfo?.mandalMunicipalityName) : '-'}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{certificateInfo?.villageWardName ? (certificateInfo?.villageWardName) : '-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Year:</label>
                <div class="valueCls">{{certificateInfo?.year ? (certificateInfo?.year) : '-'}}</div>
            </div>
           
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Added Survey Number Details</div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                <tr> 
                    <th>Survey No/Sub Division</th>
                    <th>Khata Number</th>
                    <th>Extent of Area</th>
                    <th>Mode of Acquisition</th>
                    <th>North </th>
                    <th>south</th>
                    <th>East</th>
                    <th>West</th>
                    
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of certificateInfo?.surveyDetails">
                        <td class="value">{{item?.surveyNo  ? (item?.surveyNo) : '-' }}</td>
                        <td class="value">{{item.khataNo ? (item.khataNo) : '-' }}</td>
                        <td class="value">{{item.extentofArea ? (item.extentofArea) : '-' }}</td>
                        <td class="value">{{item.modeofAcquisition ? (item.modeofAcquisition) : '-' }}</td>
                        <td class="value">{{item.north ? (item.north) : '-' }}</td>
                        <td class="value">{{item.south ? (item.south) : '-' }}</td>
                        <td class="value">{{item.east ? (item.east) : '-' }}</td>
                        <td class="value">{{item.west ? (item.west) : '-' }}</td>
                     
                        

                    </tr>
                </tbody>
                </table>
            </div>
    </div>
</div>
    <!-- <div class="certificateInfo" >
    <div class="row" *ngFor="let item of certificateInfo?.surveyDetails">
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Survey No:</label>
                <div class="valueCls">{{item?.surveyNo }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Khata Number:</label>
                <div class="valueCls">{{item?.khataNo ? (item?.khataNo) : '-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Extent of Area:</label>
                <div class="valueCls">{{item?.extentofArea ? (item?.extentofArea) : '-'}}</div>
            </div>
           
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Mode of Acquisition:</label>
                <div class="valueCls">{{item?.modeofAcquisition ? (item?.modeofAcquisition) : '-'}}</div>
            </div>
          
            <div class="fieldHolder">
                <label class="labelCls">North:</label>
                <div class="valueCls">{{item?.north ? (item?.north) : '-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">South:</label>
                <div class="valueCls">{{item?.south ? (item?.south) : '-'}}</div>
            </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">

            <div class="fieldHolder">
                <label class="labelCls">East:</label>
                <div class="valueCls">{{item?.east ? (item?.east) : '-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">West:</label>
                <div class="valueCls">{{item?.west ? (item?.west) : '-'}}</div>
            </div>
           
        </div>
    </div>
    </div> -->
<div class="certificateInfo">
        <div class="row">
            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
                <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of fileList ; let i = index">
                    <div *ngIf="file.documentType === 'Application Form'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form (FORM III):</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Registered Documents'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Registered Documents with Document No and Date ( all
                            link Documents shall be provided):</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'RH Maintainance Documents'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Entries in RH maintained by the Registration department:
                            copy:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Encumbrance Documents'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Encumbrance Certificate copy:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Adangal Documents'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Adangal Copy:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Account Form'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">10(1) Account:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'ROR Form'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">ROR Record maintained by Revenue Department Copy:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Competent Authority'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Order or decree of any court / competent authority Copy:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Id Proof'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Aadhaar card/ Voter id card/ Ration card or any other
                            address or id proof:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
                </ng-container>
            </div>
        </div>
</div>
