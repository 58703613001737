import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import { CommonMsgModalComponent } from '../../shared/common-msg-modal/common-msg-modal.component';
import { NgbDateAdapter, NgbDateParserFormatter,NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter } from '../../shared/common.service';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jagananna-suraksha-dashboard',
  templateUrl: './jagananna-suraksha-dashboard.component.html',
  styleUrls: ['./jagananna-suraksha-dashboard.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
  ]
})
export class JaganannaSurakshaDashboardComponent implements OnInit {
    popupCols: any;
    subReport: any;
    commonConstants : any = CommonConstants;
    form: FormGroup;
    htmlStr: any;
    districts: any[] = [];
    mandals: any[] = [];
    revenueMandals:any[] = [];
    villages: any[]= [];
    departments: any[] = [];
    services: any[] = [];
    formatTypes: any[]= [];
    format: any;
    maxDate: any;
    minDate: any;
    toMinDate: any;
    currentDate = new Date();
    submitted: Boolean = false;
    defaultFromDate: any;
    items: any[] = [];
    page = 1;
    pageSize = 10;
    showReports: Boolean = false;
    totalReport: Boolean = false;
    noOfCol: any = 19;
    popupPageSize = 10;
    popupPage = 1;
    popupItems: any[] = [];
    totalItems: any[] = [];
    postData: any = {};
    role: any;
    title: any;
    @ViewChild('TABLE') table!: ElementRef;
    @ViewChild('SUBTABLE') subTable!: ElementRef;
    constructor(private formBuilder: FormBuilder, private commonService: CommonService, config: NgbModalConfig, private modalService: NgbModal, private datePipe: DatePipe, private router: Router) { 
      config.backdrop = 'static';
      config.keyboard = false;
      this.form = this.formBuilder.group({
        village: [null],
        mandal: [null],
        revenueMandal: [null],
        district: [null],
        RUFlag: [null],
        department: [null],
        service: [null],
        fromDate: ['', [Validators.required]],
        toDate: ['', [Validators.required]],
        typeOfDate: ['Scheduled Date', [Validators.required] ]
      });
      this.maxDate = {
        year: this.currentDate.getFullYear(),
        month: 8,//this.currentDate.getMonth() + 1,
        day: 15 //this.currentDate.getDate()
      };
      this.minDate = {
        year: this.currentDate.getFullYear() - 150,
        month: this.currentDate.getMonth() + 1,
        day: this.currentDate.getDate()
      };
      this.toMinDate = {
        year: this.currentDate.getFullYear() - 150,
        month: this.currentDate.getMonth() + 1,
        day: this.currentDate.getDate()
      }
      this.getDistricts();
      this.getDepartment();
    }
  
    ngOnInit(): void {
    //   if (!this.commonService.getMasterData('districts'))
    //   this.getDistricts();
    // else 
    //   this.districts = this.commonService.getMasterData('districts')
     this.defaultFromDate = this.currentDate.getDate()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getFullYear();
     this.format = 'District Wise';
      this.form.patchValue({
        district:'All',
        mandal:'All',
        revenueMandal:'All',
        village: 'All',
        RUFlag: 'All',
        department: 'All',
        service: 'All',
        fromDate: this.defaultFromDate,
        toDate: this.defaultFromDate,
      });
      this.formatTypes = [
          {name: 'District Wise', isActive: true},
          {name:'Department Wise', isActive: false},
          {name:'Service Wise', isActive: false},
          {name:'RevenueMandal Wise', isActive: false},
          {name:'Mandal Wise', isActive: false},
          {name:'Secretariat Wise', isActive: false},
          {name:'Cluster Wise', isActive: false},
        ]
      this.getDashboardData();
      let userInfo = localStorage.getItem('userInfo');
        if (userInfo && userInfo !== null) {
          let userData = JSON.parse(userInfo);
          this.role = userData.jobTitle;
        }
    }
    get f() { return this.form.controls; }
    onTypeOfDateChange() {
      this.showReports = false;
      // if(this.f.typeOfDate.value === 'Scheduled Date') {
      //   this.f.toDate.patchValue(this.f.fromDate.value);
      // }
    }
    getDistricts() {
      this.commonService.getRequest(this.commonConstants.getCSPDistricts).subscribe({
        next: (responseData: any) => {
          if(responseData.result?.isSuccess) {
            this.districts = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'districtName');
            console.log(this.districts);
          } else {
            this.districts = [];
            this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    }
  
    onRuralUrbanChange() {
      if(this.f['district'].value !== 'All') {
        this.onDistrictChange();
      }
    }
    onDistrictChange() {
      this.showReports = false;
      this.revenueMandals = [];
      this.mandals = [];
      this.villages = [];
      this.f.revenueMandal.patchValue('All');
      this.f.mandal.patchValue('All');
      this.f.village.patchValue('All');
      this.getMandals();
      this.getRevenueMandals();
    }
  
    getMandals(){
      this.showReports = false;
      this.mandals = [];
      this.villages = [];
      this.f.mandal.patchValue('All');
      this.f.village.patchValue('All');
      let RUFlag: any = this.f['RUFlag'].value === 'All' ? '' : this.f['RUFlag'].value;
      let revenueMandalCode: any = this.f['revenueMandal'].value === 'All' ? '' : this.f['revenueMandal'].value.revenuE_MANDAL_CODE;
      this.commonService.getRequest(this.commonConstants.getCSPMandalsRuralUrban + '?DistrictCode=' + this.f['district'].value.districtCode + '&RuralOrUrbanFlag=' + RUFlag + '&RevenueMandalCode=' + revenueMandalCode).subscribe({
        next: (responseData: any) => { 
          console.log(responseData)
          if(responseData.result?.isSuccess) {
            this.mandals = this.commonService.sortItems(responseData.result?.getMandalDetails, 'mandalName');
          } else {
            this.mandals = [];
            this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    }
  
    //RevenueMandal
    getRevenueMandals() {
      this.showReports = false;
      this.revenueMandals=[];
      this.mandals = [];
      this.villages = [];
      this.f.mandal.patchValue('All');
      this.f.village.patchValue('All');
      this.commonService.getRequest(this.commonConstants.getCSPRevenueMandal + '?DistrictCode=' + this.f.district.value.districtCode).subscribe({
        next: (responseData: any) => {
          if(responseData.result?.isSuccess) {
            this.revenueMandals = this.commonService.sortItems(responseData.result?.datalist, 'revenuE_MANDAL_NAME');
          } else {
            this.revenueMandals = [];
            this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
          }
        }
    });
    }
  
    onMandalChange() {
      this.showReports = false;
      this.villages = [];
      this.f.village.patchValue('All');
      this.commonService.getRequest(this.commonConstants.getCSPSecretariat + '?MandalCode=' + this.f.mandal.value.mandalCode).subscribe({
        next: (responseData: any) => { 
          if(responseData.result?.isSuccess) {
            this.villages = this.commonService.sortItems(responseData.result?.getSecretariatDetails, 'secretariatName');
          } else {
            this.villages = [];
            this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    }
  
  
    onVillageChange() {
      this.showReports = false;
    }
    getDepartment() {
      this.commonService.getRequest(this.commonConstants.getAllJSDepartments).subscribe({
        next: (responseData: any) => {
          if(responseData.result?.isSuccess) {
            this.departments = this.commonService.sortItems(responseData.result?.departmentDataList, 'departmentName');
          } else {
            this.departments = [];
            this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    }
    onDeptChange() {
      this.showReports = false;
      this.services = [];
      this.f.service.patchValue('All');
      
      this.commonService.getRequest(this.commonConstants.getAllJSServicesByDepartment + this.f.department.value?.departmentCode).subscribe({
        next: (responseData: any) => {
          if(responseData.result?.isSuccess) {
            this.services = this.commonService.sortItems(responseData.result?.jsServiceDataList,'serviceName'); //serviceCode
          } else {
            this.services = [];
            this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    }
  
    
  
    onServiceChange() {
      this.showReports = false;
    }
    getReportData(): any {}
    onFromDateSelect(event: any) {
      this.showReports = false;
      this.toMinDate = event;
      // console.log('from' , event)
      // if(this.f.typeOfDate.value === 'Scheduled Date') {
      //   console.log('from' , this.f.fromDate.value)
      //   this.f.toDate.patchValue(this.f.fromDate.value);
      // }
    }
    onToDateSelect(event: any) {
      this.showReports = false;
      // if(this.f.typeOfDate.value === 'Scheduled Date') {
      //   this.f.fromDate.patchValue(this.f.toDate.value);
      //   this.toMinDate = event;
      // }
    }
    onChangeFormat(item: any) {
      this.showReports = false;
      this.formatTypes.map((item:any) => {
        item.isActive = false;
        return item;
      });
      item.isActive = true;
      this.format = item.name;
      this.getFormat();
    }
    downloadExcel() {
      let fileName: any = 'Suraksha Dashboard from '+ this.f.fromDate.value +' to '+ this.f.toDate.value;
      const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
      let wscols = [
        {wpx:50},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100}
    ];
    
    ws['!cols'] = wscols;
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Jagananna Suraksha Dashboard');
      
      /* save to file */
      XLSX.writeFile(wb, fileName + '.xlsx');
    }
    downloadExcelForSubReport() {
      let fileName: any = this.title + ' ' + this.f.fromDate.value +' to '+ this.f.toDate.value +')';
      const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.subTable.nativeElement, {raw: true});
      let wscols = [
        {wpx:50},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100}
    ];
    
    ws['!cols'] = wscols;
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      let sheetName = this.title;
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      
      /* save to file */
      XLSX.writeFile(wb, fileName + '.xlsx');
    }
    saveAsExcelFile(buffer: any, fileName: string) {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    }
    getFormat(): any {
       if (this.format === 'Service Wise') {
        this.noOfCol = 14;
        return this.noOfCol;
      } else if (this.format === 'Department Wise') {
        this.noOfCol = 13;
        return this.noOfCol;
      } else if (this.format === 'Mandal Wise' || this.format === 'District Wise') {
        this.noOfCol = 19;
        return this.noOfCol;
      } else if (this.format === 'Secretariat Wise') {
        this.noOfCol = 20;
        return this.noOfCol;
      } else if (this.format === 'Cluster Wise'){
        this.noOfCol = 18;
        return this.noOfCol;
      } else if (this.format === 'RevenueMandal Wise'){
        this.noOfCol = 15;
        return this.noOfCol;
      }
    }
    getDashboardData(from?: any): any {
      this.submitted = true;
      if (!this.form.valid){
        return false;
      } else { 
        // let diffDate = this.commonService.diffInDates(this.commonService.formatDate(this.f.fromDate.value), this.commonService.formatDate(this.f.toDate.value));
        // if (diffDate && !this.totalReport) {
        //   const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
        //   modalRef.componentInstance.title = 'Alert';
        //   modalRef.componentInstance.message = 'Please select the date range with less than or equal to 31 days.';
        // } else 
        if ((this.format === 'Cluster Wise') && this.f.district.value === 'All'){
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = 'Please select one district to get the Secretariat Wise / Cluster Wise Report.';
        } else {
          this.page = 1;
          let fromDate = this.datePipe.transform(this.commonService.formatDate(this.f.fromDate.value), 'dd/MM/yyyy')
          let toDate = this.datePipe.transform(this.commonService.formatDate(this.f.toDate.value), 'dd/MM/yyyy')
          let district = this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode;
          let mandal = this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode;
          let village = this.f.village.value === 'All' ? 'All' : this.f.village.value?.secretariatCode;
          let RUFlag = this.f.RUFlag.value === 'All' ? 'All' : this.f.RUFlag.value;
          let dept = this.f.department.value === 'All' ? 'All' : this.f.department.value?.departmentName;
          let service = this.f.service.value === 'All' ? 'All' : this.f.service.value?.serviceName;
          service = encodeURIComponent(service);
          let revenueMandal = this.f.revenueMandal.value === 'All' ? 'All' : this.f.revenueMandal.value?.revenuE_MANDAL_CODE;
        
          dept = encodeURIComponent(dept);
          let postData: any = 'Flag='+ this.format +'&DistrictId=' + district + '&RuralUrban=' + RUFlag +'&MandalId=' + mandal +'&SecretariatCode=' + village + '&DepartmentName=' + dept +'&ServiceName=' + service +'&FromDate=' + fromDate + '&ToDate=' + toDate + '&ClusterId=All&DateFlag=' + this.f.typeOfDate.value  + '&RVMID=' + revenueMandal;
          console.log(this.postData)
          this.showReports = true;
          this.commonService.getRequest(this.commonConstants.getAllJSTokenDashboardData + postData).subscribe({
            next: (responseData: any) => { 
              if(typeof(responseData?.result) !== 'string') {
                  this.showReports = true;
                  this.items = responseData?.result;
                    this.items = this.items.filter(item => item.totaL_FLAG !== 'Y')
                    this.totalItems = responseData?.result.filter((item: { totaL_FLAG: string; }) => item.totaL_FLAG === 'Y')
              } else {
                this.items = [];
                this.totalItems = [];
                //this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
              }
            },
            error: (error) => {
              console.log('client side', error);
            }
        });
        }
        }
    }
    // getBeyondSLA(item: any, modal: any, from?: any) {
    //   let format: any;
    //   if (from === '24') {
    //     this.title = 'Requests Lapsing Within 24 Hours';
    //     format = 'Transaction24';
    //   } else if (from === '48') {
    //     this.title = 'Requests Lapsing Within 48 Hours';
    //     format = 'Transaction48';
    //   } else if(from === 'within') {
    //     this.title = 'Within SLA Requests';
    //     format = 'Transaction Within';
    //   } else {
    //     this.title = 'Open Beyond SLA Requests';
    //     format = 'Transaction Wise';
    //   }
    //   this.popupItems = [];
    //   this.modalService.open(modal, { size: 'xl', centered: true, backdrop: 'static'});
    //       this.popupPage = 1;
    //       let fromDate = this.datePipe.transform(this.commonService.formatDate(this.f.fromDate.value), 'dd/MM/yyyy')
    //       let toDate = this.datePipe.transform(this.commonService.formatDate(this.f.toDate.value), 'dd/MM/yyyy')
    //       let district = item.did === null ? (this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode) : item.did;
    //       let mandal = item.mid === null ? (this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode) : item.mid;
    //       let village = item.seC_CODE === null ? (this.f.village.value === 'All' ? 'All' : this.f.village.value?.secretariatCode) : item.seC_CODE;
    //       let RUFlag = this.f.RUFlag.value === 'All' ? 'All' : this.f.RUFlag.value;
    //       let dept = item.departmenT_NAME === null ? (this.f.department.value === 'All' ? 'All' : this.f.department.value?.departmentName) : item.departmenT_NAME;
    //       let service = item.servicename === null ? (this.f.service.value === 'All' ? 'All' : this.f.service.value?.serviceName) : item.servicename;
    //       let clusterId = item.clusteR_CODE === null ?  'All' : item.clusteR_CODE;
    //       //let revenueMandal = item.revenuE_MANDAL_CODE === null ? (this.f.revenueMandal.value === 'All' ? 'All' : this.f.revenueMandal.value?.revenuE_MANDAL_CODE) : item.revenuE_MANDAL_CODE;
    //       service = encodeURIComponent(service);
    //       dept = encodeURIComponent(dept);
    //       let postData: any = 'Flag='+ format +'&DistrictId=' + district + '&RuralUrban=' + RUFlag +'&MandalId=' + mandal +'&SecretariatCode=' + village + '&DepartmentName=' + dept +'&ServiceCode=' + service +'&FromDate=' + fromDate + '&ToDate=' + toDate + '&ClusterId='+ clusterId;
    //       this.commonService.getRequest(this.commonConstants.getAllJSTokenDashboardData + postData).subscribe({
    //         next: (responseData: any) => { 
    //           if(typeof(responseData?.result) !== 'string') {
    //             this.popupItems = responseData?.result;
    //           } else {
    //             this.popupItems = [];
    //           }
    //         },
    //         error: (error) => {
    //           console.log('client side', error);
    //         }
    //     });
    // }
    getSubReport(item: any, modal: any, from?: any) {
      let format: any;
      if (from === 'tokens') {
        this.title = 'No. of Tokens Registered';
        format = 'TOKEN WISE';
        this.popupCols = 17;
        this.subReport = 'tokens';
      } else if (from === 'services') {
        this.title = 'No. of Service Requests raised';
        format = 'APP WISE';
        this.popupCols = 17;
        this.subReport = 'services';
      } else if(from === 'beyond' || from === 'within') {
        this.title = from === 'beyond' ? 'Open Beyond SLA Requests' : 'Open Within SLA Requests';
        format = from === 'beyond' ? 'OPEN BEYOND': 'OPEN WITHIN';
        this.popupCols = 17;
        this.subReport = 'open';
      } else if(from === 'isHHWise') {
        this.title = 'Household wise Requests';
        format = 'HOUSEHOLD WISE';
        this.popupCols = 6;
        this.subReport = 'isHHWise';
      } else if(from === '24') {
        this.title = 'Lapsing Within 24 Hours';
        format = 'Transaction24';
        this.popupCols = 17;
        this.subReport = 'lapsing24';
      } else if(from === '48') {
        this.title = 'Lapsing Within 48 Hours';
        format = 'Transaction48';
        this.popupCols = 17;
        this.subReport = 'lapsing48';
      } 
      this.modalService.open(modal, { size: 'xl', centered: true, backdrop: 'static'});
      this.popupPage = 1;
          let fromDate = this.datePipe.transform(this.commonService.formatDate(this.f.fromDate.value), 'dd/MM/yyyy')
          let toDate = this.datePipe.transform(this.commonService.formatDate(this.f.toDate.value), 'dd/MM/yyyy')
          let district = item.did === null ? (this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode) : item.did;
          let mandal = item.mid === null ? (this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode) : item.mid;
          let village = item.seC_CODE === null ? (this.f.village.value === 'All' ? 'All' : this.f.village.value?.secretariatCode) : item.seC_CODE;
          let RUFlag = this.f.RUFlag.value === 'All' ? 'All' : this.f.RUFlag.value;
          let dept = item.departmenT_NAME === null ? (this.f.department.value === 'All' ? 'All' : this.f.department.value?.departmentName) : item.departmenT_NAME;
          let service = item.servicename === null ? (this.f.service.value === 'All' ? 'All' : this.f.service.value?.serviceName) : item.servicename;
          let clusterId = item.clusteR_CODE === null ?  'All' : item.clusteR_CODE;
          let revenueMandal = item.rvmandaL_ID === null ? (this.f.revenueMandal.value === 'All' ? 'All' : this.f.revenueMandal.value?.revenuE_MANDAL_CODE) : item.rvmandaL_ID;
          service = encodeURIComponent(service);
          dept = encodeURIComponent(dept);
          let postData: any = 'Flag='+ format +'&DistrictId=' + district + '&RuralUrban=' + RUFlag +'&MandalId=' + mandal +'&SecretariatCode=' + village + '&DepartmentName=' + dept +'&ServiceName=' + service +'&FromDate=' + fromDate + '&ToDate=' + toDate + '&ClusterId='+ clusterId + '&DateFlag=' + this.f.typeOfDate.value  + '&RVMID=' + revenueMandal;
          this.commonService.getRequest(this.commonConstants.getAllJSTokenDashboardData + postData).subscribe({
            next: (responseData: any) => { 
              if(typeof(responseData?.result) !== 'string') {
                this.popupItems = responseData?.result;
              } else {
                this.popupItems = [];
              }
            },
            error: (error) => {
              console.log('client side', error);
            }
        });
    }
    goToHome() {
      this.router.navigate(['/home']);
    }
  
}
