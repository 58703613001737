import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PensionViewRoutingModule } from './pension-view-routing.module';
import { PensionformViewComponent } from './pensionform-view/pensionform-view.component';


@NgModule({
  declarations: [
    PensionformViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PensionViewRoutingModule
  ],
  exports: [
    CommonModule, PensionformViewComponent
  ]
})
export class PensionViewModule { }
