
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerPopupComponent } from '../../shared/pdf-viewer-popup/pdf-viewer-popup.component';
import { CommonConstants } from '../../constants/common-constants.component';
import { CommonService } from '../../shared/common.service';
@Component({
  selector: 'app-address-certificate-for-aadhar-enrollment-view',
  templateUrl: './address-certificate-for-aadhar-enrollment-view.component.html',
  styleUrls: ['./address-certificate-for-aadhar-enrollment-view.component.css']
})
export class AddressCertificateForAadharEnrollmentViewComponent implements OnInit {
  commonConstants : any = CommonConstants;
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  constructor(private commonService: CommonService, private modalService: NgbModal,config: NgbModalConfig) { }

  ngOnInit(): void {
    if(this.certificateInfo.purposeOfCertificate)
    this.certificateInfo.purposeOfCertificate  = this.certificateInfo.purposeOfCertificate === 'NewEnrolment'? 'New Enrollment' :'Update Request'
  }
  viewFile(blobRef:any, fileName:any) {
    console.log('file Extension', this.checkFileExtension(fileName));
    this.commonService.getRequest(this.commonConstants.viewFile + blobRef).subscribe({
      next: (responseData: any) => { 
        let fileInfo =  responseData.result.fileContents;
        const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
        modalRef.componentInstance.sourceFile = fileInfo;
        modalRef.componentInstance.showPrintDownload = false;
        if (this.checkFileExtension(fileName) !== 'pdf') {
          modalRef.componentInstance.isImage = true;
          modalRef.componentInstance.sourceFile = 'data:image/png;base64,' +fileInfo;
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  checkFileExtension(name: any) {
    let fileNameArr = name.split('.');
    return fileNameArr[fileNameArr?.length - 1]?.toLowerCase();
  }
    downloadFile(file: any) {
      this.download.emit(file);
    } 
}
