<div class="certificateInfo ">
    <div class="subHeadingCls mb-3">Applicant Details
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">FamilyIncome:</label>
                <div class="valueCls">{{certificateInfo?.familyIncome}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Occupation:</label>
                <div class="valueCls">{{certificateInfo?.occupation}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Rice Card Number:</label>
                <div class="valueCls">{{certificateInfo?.riceCardNumber}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Habitation:</label>
                <div class="valueCls">{{certificateInfo?.habitation}}</div>
            </div>

        </div>

    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"
            *ngIf="certificateInfo?.splitSingleMemberType ==='normalSplit'">
            <div class="fieldHolder">
                <label class="labelCls">Split Type:</label>
                <div class="valueCls">Normal Split</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"
            *ngIf="certificateInfo?.splitSingleMemberType ==='widowSplit'">
            <div class="fieldHolder">
                <label class="labelCls">Split Type:</label>
                <div class="valueCls">Widow / Widower Split</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"
            *ngIf="certificateInfo?.splitSingleMemberType ==='divorceeSplit'">
            <div class="fieldHolder">
                <label class="labelCls">Split Type:</label>
                <div class="valueCls">Divorcee Split</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12"
            *ngIf="certificateInfo?.splitSingleMemberType ==='singlemembersplit'">
            <div class="fieldHolder">
                <label class="labelCls">Split Type:</label>
                <div class="valueCls">Single Member Split</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="certificateInfo?.singleMemberType">
            <div class="fieldHolder">
                <label class="labelCls">Split Single Member Type:</label>
                <div class="valueCls">{{certificateInfo?.singleMemberType ? certificateInfo?.singleMemberType : '-'}}
                </div>
            </div>
        </div>
    </div>
</div>

<div class="certificateInfo ">

    <div class="subHeadingCls mb-3">Rice Card Family Details</div>

    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                    <tr>
                        <th>Serial Number</th>
                        <th>Aadhar No</th>
                        <th>Rice Card Number</th>
                        <th>Name</th>
                        <th>Relation</th>
                        <th>Age</th>
                        <th>DOB</th>
                        <th>EKYC Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of gridDetail;let i=index">
                        <td class="serial">{{i+1}}</td>
                        <td class="value">{{item.adhar_number}}</td>
                        <td class="value">{{item.rice_Card_id ? (item.rice_Card_id) : '-' }}</td>
                        <td>{{item?.name}}</td>
                        <td>{{item?.relation}}</td>
                        <td class="value">{{item?.member_age}}</td>
                        <td>{{item?.member_dob | date: 'dd/MM/yyyy'}}</td>
                        <td>{{item.ekyc_status_done ? (item.ekyc_status_done) : '-' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>
<div class="certificateInfo ">

    <div class="subHeadingCls mb-3">Splitted Rice Card Family Details</div>

    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                    <tr>
                        <th>Serial Number</th>
                        <th>Aadhar No</th>
                        <th>Rice Card Number</th>
                        <th>Name</th>
                        <th>Relation</th>
                        <th>Age</th>
                        <th>DOB</th>
                        <th>EKYC Status</th>
                        <th>
                            Upload Certificate</th>
                        <th>
                            Upload
                            Photo</th>

                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of certificateInfo?.riceCardMembersDataList;let i=index">
                        <td class="serial">{{i +1}}</td>
                        <td class="value">{{item.aadhaarNumber ? this.commonService.RSADecrypt(item.aadhaarNumber) : '-' }}</td>
                        <td class="value">{{item.existingRiceCardNo ? (item.existingRiceCardNo) : '-' }}</td>
                        <td>{{item?.englishName}}</td>
                        <td>{{item?.relationShip}}</td>
                        <td class="value">{{item?.age}}</td>
                        <td>{{item?.dateOfBirth | date: 'dd/MM/yyyy'}}</td>
                        <td>{{item.exycStatus ? (item.exycStatus) : '-' }}</td>
                        <td>
                            <ng-container *ngIf="fileList">
                                <div class="fieldHolder" *ngFor="let file of fileList ; let i = index">
                                    <div *ngIf="file.documentType === item.aadhaarNumber+'_UploadCertificate'">
                                        <div class="valueCls"><a
                                                (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                                    </div>
                                </div>
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="fileList">
                                <div class="fieldHolder" *ngFor="let file of fileList ; let i = index">
                                    <div *ngIf="file.documentType === item.aadhaarNumber+'_UploadPhoto'">
                                        <div class="valueCls"><a
                                                (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                                    </div>
                                </div>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>
<div>

</div>