
<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
            GSWS /Application Submission / Health And Family Welfare Department<span class="activePage">/ Generate AAROGYASRI Card</span>
        </div>
    </div>
<div *ngIf="!showCard"class="mainPageContainer mt-15 mr-3">
<div>
    <h6 class="mb-3"><b>Generate AAROGYASRI Card: </b></h6>
    <hr class="hrCls mt-4">
    <form [formGroup]="generateCard">

            <div class="row  mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="subHeadingCls">UHID:</label></div>
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <input type="text" class="inputCls value" maxlength="13" numbersOnly
                        name="uhid" id="uhid"  formControlName="uhid" [ngClass]="{ 'is-invalid':submitted && f.uhid.errors }">
                        <div *ngIf="submitted && f.uhid.errors" class="invalid-feedback">
                            <div *ngIf="f.uhid.errors?.minlength">Please enter valid UHID number</div>
                          
                    
                        </div>
                    </div>
                   
                    
                </div>
                <!-- <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12"> OR</div>
                <div class="row  mb-3"> 
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  
                        <label class="subHeadingCls">Application Number:</label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <input type="text" class="inputCls value" maxlength="15"
                        name="referenceNo" id="referenceNo" formControlName="referenceNo" [ngClass]="{ 'is-invalid':submitted && f.referenceNo.errors }">
                        <div *ngIf="submitted && f.referenceNo.errors" class="invalid-feedback">
                            <div *ngIf="f.referenceNo.errors?.minlength || f.referenceNo.errors?.pattern">Please enter valid Application number</div>
                           
                   </div>
                    </div>
                   
                    </div> -->
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12"> OR</div>
               <div class="row">
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  
                            <label class="subHeadingCls">Registered Aadhaar No:</label></div>
                            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <input type="text" class="inputCls value" maxlength="12" numbersOnly
                            name="aadharNo" id="aadharNo" formControlName="aadharNo" [ngClass]="{ 'is-invalid':submitted && f.aadharNo.errors }">
                            <div *ngIf="submitted && f.aadharNo.errors" class="invalid-feedback">
                                <div *ngIf="f.aadharNo.errors.whiteSpaceValidate || f.aadharNo.errors?.aadharValidate || f.aadharNo.errors?.minlength">Please enter valid  Aadhaar Number</div>

                       </div>
                        </div>

                        <div class="btnHolder" *ngIf="role == 'Citizen'">
                        <button class="greenBtnCls" (click)="sendOTP()">Send OTP</button>
                    </div>
                </div>
               <br>
                <div *ngIf="isAadharOTP" class="row">
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="subHeadingCls">OTP (One Time Password): <span class="mandatoryCls">*</span></label></div>
                            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <input type="password" name="otp"  appBlockCopyPaste class="inputCls" formControlName="otp" required [ngClass]="{ 'is-invalid':submitted && f.otp.errors }" numbersOnly minlength="6" maxlength="6"
                            [readonly] ="isPattadarOtpValidated">
                            <div *ngIf="submitted && f.otp.errors" class="invalid-feedback">
                                <div *ngIf="f.otp.errors.required">Please enter 6 digit Valid OTP </div>
                            </div>
                        </div>
                  
                   
                    <div class="btnHolder">
                        <button class="greenBtnCls float-right"  [disabled]="isPattadarOtpValidated" (click)="validateOTP()" >OTP Authenticate</button>
                    </div>
                </div>
                </form>
                </div>
             
                <div class="row mb-3 mt-4 ml-20">
                    <!-- <div class="btnHolder text-center col-4">
                        <button class="btn btn-primary mx-auto d-block"  (click)="downloadDigitalCard('print')">Print Card(PVC Card-Printer)</button>                        
                    </div> -->
                    <div class="btnHolder text-center col-12">                        
                        <button class="btn btn-primary mx-auto d-block"  (click)="downloadDigitalCard('digitalCard')">Download Digital card </button>
                    </div>
                   
                </div>
   </div>

   


  <!-- StationaryPopup Popup -->
  <ng-template #showStationaryPopup let-modal class="modalCls btnHide">

    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Stationary Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body"  id="transPoup">
        <br>    
        <form [formGroup]="stationaryForm">
        <div class="row">
            <div class="col col-lg-4 col-md-4 col-sm-4 col-xs-3">
                <label class="labelCls lableHead"><b>Enter stationary serial number :</b></label>
            </div>
            <div class="col col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <label class="labelCls">
                    <input type="text" name="stationary_number" placeholder="stationary number" class="inputCls" formControlName="stationary_number" maxlength="12">
                    
                    <div *ngIf="submittedStationary && fc.stationary_number.errors" class="selectError">
                        <div *ngIf="fc.stationary_number.errors?.required">Please Enter stationary number</div>
                        <div *ngIf="fc.stationary_number.errors?.pattern">Please enter valid stationary number</div>
                    </div>
                </label>
                
            </div>

            <div class="col col-lg-4 col-md-4 col-sm-4 col-xs-4">
                <label class="labelCls lableHead">
                    <button type="button" class="btn btn-primary" (click)="submitStaionaryData()">submit</button>
                </label>
            </div>
            
        </div>
        </form>
    </div>

    <div class="modal-footer">
        <button type="button" id="closePopup" class="btn btn-danger" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>
<!-- StationaryPopup Popup end -->

</div>