import { NgStyle } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { element } from 'protractor';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';
import { AadharValidator, CommonService, WhitespaceValidator } from 'src/shared/common.service';
import { fileExtensionValidator, fileSizeValidator, fileSizeValidatorForAarogyaSri } from 'src/shared/validators/file-validator';
import { read } from 'xlsx';
@Component({
  selector: 'app-signature-upload',
  templateUrl: './signature-upload.component.html',
  styleUrls: ['./signature-upload.component.css']
})
export class SignatureUploadComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;
  showtable: boolean = false;
  addForm: FormGroup;
  formsubmitted: boolean = false;
  ref: any;
  fileList: { FileName: string, DocumentType: string, BlobRef: string }[] = [];
  @ViewChild('fascimileFormLabel')
  fascimileFormLabel!: ElementRef;
  commonConstants: any = CommonConstants;
  array: any = [];
  isEdit: boolean = false;
  userInformation: any;
  loggedinDetails: any;
  loggedinuserName: any;
  divisionName: any;
  userInfo: any;
  gswsDistrict: any;
  revenueDistrict: any;
  revenueDivision: any;
  revenueMandals: any;
  sourceFile: any;
  loggedinUserId: any;
  maxDate: any;
  minDate: any;
  currentDate = new Date();
  curdate: any;
  imagefile: any;
  base64data: string | ArrayBuffer | null | any = '';
  blobRef: any;
  districtname: any;
  revenuedistrict: any;
  revenuedistrictname: any;
  revenuedivision: any;
  revenuedivisionname: any;
  revenuemandal: any;
  revenuemandalname: any;
  districtid: any;
  z: any;
  digitalsignature: any;
  encryptedAadharNumber: any;
  encryptedupdateAadharNumber: any;
  revenuedivisionss: any;
  revenuemandalcode: any;
  revenuedivisioncode: any;
  revenuedistrictcode: any;
  aadharnumber: any;
  editdata: any;
  revenuedistrictnamee: any;
  postobj: any = {}
  revenueMandalId: any;
  RVMandalId: any;
  RVMandalName: any;
  designation: any;
  ulbdistrictid: any;
  ulbdistrictname: any;
  ulbmandalid: any;
  ulbmandalName: any;
  reff: any;
  aadharFinalNumber: any;
  showaddsignature: boolean = true;
  showchangedimage: boolean = false;
  tempArr: any = []
  flag: any;
  editencryptedupdateAadharNumber: any;
  designations: any;
  updateencryptedupdateAadharNumber: any;
  showtehsignature: boolean = false;
  panchayatname: any;
  isEditImage: boolean = false;
  panchayatcode: any;
  BlobRef: any;
  passbookblobRef: any;
  passbookimagefile: any;
  passbooksourceFile: any;
  passbookshowchangedimage: boolean = false;
  passbookfileList: { FileName: string, DocumentType: string, BlobRef: string }[] = [];
  @ViewChild('addModal') addModal: any;
  constructor(private formBuilder: FormBuilder, private modalService: NgbModal, private commonService: CommonService, private router: Router, private currentRoute: ActivatedRoute) {
    this.form = this.formBuilder.group({
      designation: [null, [Validators.required]],
      division: [null],
      mandal: [null],
      signstatus: [null]
    });
    this.addForm = this.formBuilder.group({
      Name: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      aadharNumber: ['', [Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
      mobileNumber: ['', [Validators.required, Validators.pattern(this.commonConstants.mobilePattern)]],
      emailId: [''],
      digitalSignature: ['', [Validators.required, WhitespaceValidator.whiteSpaceValidate]],
      role: [null, [Validators.required]],
      status: [null, [Validators.required]],
      designation: [null, [Validators.required]],
      fascimileForm: [''],
      acName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      acNo: ['', [Validators.required, WhitespaceValidator.whiteSpaceValidate, Validators.pattern(this.commonConstants.noSpecialCharacterPattern)]],
      bankName: ['', [Validators.required, WhitespaceValidator.whiteSpaceValidate, Validators.pattern(this.commonConstants.noSpecialCharacterPattern)]],
      ifscCode: ['', [Validators.required, WhitespaceValidator.whiteSpaceValidate, Validators.pattern(this.commonConstants.ifscCodePattern)]],
      passbook: [''],
      // revenueMandal: ['', Validators.required]
    });
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
  }

  ngOnInit(): void {
    this.userInformation = localStorage.getItem('userInfo');
    this.loggedinDetails = JSON.parse(this.userInformation);
    this.loggedinuserName = this.loggedinDetails.jobTitle;
    this.designation = this.loggedinuserName
    this.loggedinUserId = this.loggedinuserName.split("-")[0]?.replace('U', '');
    this.getList();
    this.getUserId();
  }
  ngAfterViewInit() {
    this.currentRoute.queryParams.subscribe((params: any) => {
      if (params.exist === 'false' && this.array?.length === 0) {
        this.openModal(this.addModal);
        //this.modalService.open(this.addModal, {size: 'lg'});
      }
    });
  }
  get f() {
    return this.form.controls;
  }
  get g() {
    return this.addForm.controls;
  }
  designationChange(event: any) {
    this.form.controls['division'].patchValue(null)
    this.form.controls['mandal'].patchValue(null)
    if (event.target.value === 'Tahsildar' || event.target.value === 'Deputy Tahsildar'
      || event.target.value === 'MPDO' || event.target.value === 'Municipal Commissioner' || event.target.value === 'Sub Collector') {
      this.form.controls['mandal'].setValidators(Validators.required);
      this.form.controls['mandal'].updateValueAndValidity();
      this.form.controls['division'].setValidators(Validators.required);
      this.form.controls['division'].updateValueAndValidity();
    } else {
      this.form.controls['mandal'].clearValidators()
      this.form.controls['mandal'].updateValueAndValidity();
      this.form.controls['division'].clearValidators();
      this.form.controls['division'].updateValueAndValidity();
    }
    // this.showtable = false;
  }
  onMandalChange(event: any) {
    // this.showtable = false;
  }
  openModal(targetModal: any) {
    this.resetAddEditForm();
    this.setValidationforBankDetails();
    // if(this.loggedinuserName == 'Mandal Parishad Development Officers' 
    // || this.loggedinuserName == 'Municipal Commissioner' 
    // || this.loggedinuserName == 'Tahsildar') {
    //   this.addForm.controls['passbook'].setValidators([
    //     Validators.required
    //   ]);
    // }
    // this.submitted = true;
    this.isEdit = false;
    // if (this.form.valid) {
    this.ref = this.modalService.open(targetModal,
      {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      }
    );
    // }
    // this.addForm.reset();
    //this.sourceFile='';
  }
  openModall(addModal: any) {
    this.resetAddEditForm();
    this.setValidationforBankDetails();
    this.isEdit = true;
    this.reff = this.modalService.open(addModal,
      {
        size: 'lg',
        centered: true,
        backdrop: 'static'
      }
    );
  }
  resetAddEditForm() {
    this.editdata = null;
    this.designations = '';
    this.addForm.reset();
    this.sourceFile = null;
    this.passbooksourceFile = null;
    this.showchangedimage = false;
    this.passbookshowchangedimage = false;
  }
  setValidationforBankDetails() {
    if ((this.loggedinuserName == 'Mandal Parishad Development Officers' && this.designations == 'Mandal Parishad Development Officers')
      || (this.loggedinuserName == 'Municipal Commissioner' && this.designations == 'Municipal Commissioner')
      || (this.loggedinuserName == 'Tahsildar' && this.designations == 'Tahsildar')
      || (this.loggedinuserName == 'Joint Collector' && (
        this.designations == 'Mandal Parishad Development Officers'
        || this.designations == 'Municipal Commissioner'
        || this.designations == 'Tahsildar'))
    ) {
      this.addForm.controls['acName'].setValidators([
        Validators.required,
        Validators.pattern(this.commonConstants.namePattern),
        WhitespaceValidator.whiteSpaceValidate
      ]);
      this.addForm.controls['acNo'].setValidators([
        Validators.required,
        Validators.pattern(this.commonConstants.noSpecialCharacterPattern),
        WhitespaceValidator.whiteSpaceValidate
      ]);
      this.addForm.controls['bankName'].setValidators([
        Validators.required,
        Validators.pattern(this.commonConstants.noSpecialCharacterPattern),
        WhitespaceValidator.whiteSpaceValidate
      ]);
      this.addForm.controls['ifscCode'].setValidators([
        Validators.required,
        Validators.pattern(this.commonConstants.ifscCodePattern),
        WhitespaceValidator.whiteSpaceValidate
      ]);
      if (this.passbooksourceFile) {
        this.addForm.controls['passbook'].clearValidators();
      } else {
        this.addForm.controls['passbook'].setValidators([
          Validators.required
        ])
      }
    } else {
      this.addForm.controls['acName'].clearValidators();
      this.addForm.controls['acNo'].clearValidators();
      this.addForm.controls['bankName'].clearValidators();
      this.addForm.controls['ifscCode'].clearValidators();
      this.addForm.controls['passbook'].clearValidators();
    }
    this.addForm.controls['acName'].updateValueAndValidity();
    this.addForm.controls['acNo'].updateValueAndValidity();
    this.addForm.controls['bankName'].updateValueAndValidity();
    this.addForm.controls['ifscCode'].updateValueAndValidity();
    this.addForm.controls['passbook'].updateValueAndValidity();
  }
  fascimileFormDocument(fileEvent: any) {
    const File: File | null = fileEvent.target.files[0];
    this.imagefile = fileEvent.target.files[0];
    this.blobRef = fileEvent.target.files[0]?.name;
    // this.fascimileFormLabel.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0]?.name : 'No File Choosen';
    this.addForm.controls['fascimileForm'].setValidators([
      Validators.required, fileExtensionValidator('jpg'), fileSizeValidatorForAarogyaSri(fileEvent)
    ])
    this.addForm.controls['fascimileForm'].updateValueAndValidity();
    if (this.g.fascimileForm.valid) {
      this.fileUpload(File, this.g['fascimileForm'].value, fileEvent.target.files[0].name, 'FascimileForm', 'fascimileForm');
    }
    let reader = new FileReader();
    reader.readAsDataURL(this.imagefile);
    this.sourceFile = reader.onloadend = function () {
      return reader.result
    };

    setTimeout(() => {
      this.imageto64(reader.result)
    }, 100);

  }
  fileUpload(file: any, filePath: string, fileName: string, documentType: string, name: string) {
    const formData = new FormData();
    formData.append('File', file, fileName);
    formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
    this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload, formData).subscribe({
      next: (responseData: any) => {
        if (typeof (responseData.result) !== 'string') {
          let index = this.fileList.map((item: any) => item.DocumentType).indexOf(documentType);
          if (index > -1) {
            this.fileList.splice(index, 1);
          }
          this.fileList.push({
            FileName: fileName,
            DocumentType: documentType,
            BlobRef: responseData['result'].blobRef
          });
          this.BlobRef = this.fileList[0].BlobRef;
        } else {
          if (name) {
            this.clearDocs(name);
          }
          this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
        }
      },
      error: (error) => {
        if (name) {
          this.clearDocs(name);
        }
        console.log('client side', error);
      }
    });
  }
  clearDocs(name: string) {
    this.showchangedimage = true;
    this.sourceFile = null;
    this.g[name].reset();
    // this.showchangedimage = false;
    this.addForm.controls['fascimileForm'].setValidators
    this.addForm.controls['fascimileForm'].updateValueAndValidity();
    // if (label === 'fascimileFormLabel') {
    //   this.fascimileFormLabel.nativeElement.innerText = 'No File Choosen';
    // }
  }
  passbookDocument(fileEvent: any) {
    const File: File | null = fileEvent.target.files[0];
    this.passbookimagefile = fileEvent.target.files[0];
    this.passbookblobRef = fileEvent.target.files[0]?.name;
    this.addForm.controls['passbook'].setValidators([
      Validators.required, fileExtensionValidator('jpg'), fileSizeValidator(fileEvent)
    ])
    this.addForm.controls['passbook'].updateValueAndValidity();
    if (this.g.passbook.valid) {
      this.passbookfileUpload(File, this.g['passbook'].value, fileEvent.target.files[0].name, 'passbook', 'passbook');
    }
    let reader = new FileReader();
    reader.readAsDataURL(this.passbookimagefile);
    this.passbooksourceFile = reader.onloadend = function () {
      return reader.result
    };
    setTimeout(() => {
      this.passbookimageto64(reader.result)
    }, 100);

  }
  passbookfileUpload(file: any, filePath: string, fileName: string, documentType: string, name: string) {
    const formData = new FormData();
    formData.append('File', file, fileName);
    formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
    this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload, formData).subscribe({
      next: (responseData: any) => {
        if (typeof (responseData.result) !== 'string') {
          let index = this.passbookfileList.map((item: any) => item.DocumentType).indexOf(documentType);
          if (index > -1) {
            this.passbookfileList.splice(index, 1);
          }
          this.passbookfileList.push({
            FileName: fileName,
            DocumentType: documentType,
            BlobRef: responseData['result'].blobRef
          })
          this.passbookblobRef = this.passbookfileList[0].BlobRef;
        } else {
          if (name) {
            this.clearDocs(name);
          }
          this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
        }
      },
      error: (error) => {
        if (name) {
          this.clearDocs(name);
        }
        console.log('client side', error);
      }
    });
  }
  clearPassbookDocs(name: string) {
    this.passbookshowchangedimage = true;
    this.passbooksourceFile = null;
    this.g[name].reset();
    // this.passbookshowchangedimage = false;
    this.addForm.controls['passbook'].setValidators
    this.addForm.controls['passbook'].updateValueAndValidity();
    // if (label === 'passbookLabel') {
    //   this.passbookLabel.nativeElement.innerText = 'No File Choosen';
    // }
  }

  passbookimageto64(changesourceFile: any) {
    this.passbooksourceFile = changesourceFile;
    this.passbookshowchangedimage = true;
  }

  submit(): any {
    this.formsubmitted = true;
    if (this.loggedinuserName === 'Village Revenue Officer' ||
      this.loggedinuserName === 'Panchayat Secretary' ||
      this.loggedinuserName === 'Assistant Secretary' ||
      this.loggedinuserName === 'Digital Assistant' ||
      this.loggedinuserName === 'Ward Education Secretary' ||
      this.loggedinuserName === 'Revenue Inspector' ||
      this.loggedinuserName === 'Ward Revenue Secretary' ||
      this.loggedinuserName === 'Welfare & Educational Assistant' ||
      this.loggedinuserName === 'Ward Welfare & Development Secretary' ||
      this.loggedinuserName === 'ExciseDC' ||
      this.loggedinuserName === 'HandloomDC' ||
      this.loggedinuserName === 'HandloomDO' ||
      this.loggedinuserName === 'SCWELDC' ||
      this.loggedinuserName === 'DMO' ||
      this.loggedinuserName === 'FisheryDC' ||
      this.designations === 'ExciseDC' ||
      this.designations === 'HandloomDC' ||
      this.designations === 'HandloomDO' ||
      this.designations === 'SCWELDC' ||
      this.designations === 'DMO' ||
      this.designations === 'FisheryDC' ||
      this.loggedinuserName === 'Ward Admin Secretary' ||
      this.loggedinuserName === 'Superintendent' ||
      this.loggedinuserName === 'Village surveyor' ||
      this.loggedinuserName === 'Mandal surveyor' ||
      this.loggedinuserName === 'Assistant Social Welfare Officer' ||
      this.loggedinuserName === 'Fisheries Development Officer' ||
      this.loggedinuserName === 'Assistant Civil Supplies Officer') {
      this.addForm.controls['fascimileForm'].clearValidators();
      this.addForm.controls['fascimileForm'].updateValueAndValidity();
      this.addForm.controls['passbook'].clearValidators();
      this.addForm.controls['passbook'].updateValueAndValidity();
      this.addForm.controls['digitalSignature'].clearValidators();
      this.addForm.controls['digitalSignature'].updateValueAndValidity();
    }

    if (this.loggedinuserName !== 'Revenue Divisonal Officer' && this.loggedinuserName !== 'Sub Collector') {
      this.addForm.controls['designation'].clearValidators();
      this.addForm.controls['designation'].updateValueAndValidity();
    }

    if (this.loggedinuserName == 'Zilla Sainik Welfare Officer') {
      this.addForm.controls['digitalSignature'].clearValidators();
      this.addForm.controls['digitalSignature'].updateValueAndValidity();
    }

    if (!this.addForm.valid) {
      return false;
    } else {
      if (this.imagefile && !this.passbookimagefile) {
        this.getBase64(this.imagefile, null);
        this.ref?.close();
      } else if (!this.imagefile && this.passbookimagefile) {
        this.getBase64(null, this.passbookimagefile);
        this.ref?.close();
      } else if (this.imagefile && this.passbookimagefile) {
        this.getBase64(this.imagefile, this.passbookimagefile);
        this.ref?.close();
      } else {
        this.editVRORIIProfile()
      }
      // this.addForm.reset();
    }
  }
  update(): any {
    if (this.loggedinuserName === 'Village Revenue Officer' ||
      this.loggedinuserName === 'Assistant Social Welfare Officer' ||
      this.loggedinuserName === 'Fisheries Development Officer' ||
      this.loggedinuserName === 'Panchayat Secretary' ||
      this.loggedinuserName === 'Assistant Secretary' ||
      this.loggedinuserName === 'Digital Assistant' ||
      this.loggedinuserName === 'Ward Education Secretary' ||
      this.loggedinuserName === 'Revenue Inspector' ||
      this.loggedinuserName === 'Ward Revenue Secretary' ||
      this.designations === 'VRO' ||
      this.designations === 'FDO' ||
      this.designations === 'ASWO' ||
      this.designations === 'VS' ||
      this.designations === 'RI' ||
      this.designations === 'MS' ||
      this.designations === 'ASO' ||
      this.loggedinuserName === 'Welfare & Educational Assistant' ||
      this.loggedinuserName === 'Ward Welfare & Development Secretary' ||
      this.designations === 'WEA' ||
      this.designations === 'DA' ||
      this.designations === 'ExciseDC' ||
      this.designations === 'HandloomDC' ||
      this.designations === 'HandloomDO' ||
      this.designations === 'SCWELDC' ||
      this.designations === 'DMO' ||
      this.designations === 'FisheryDC' ||
      this.loggedinuserName === 'ExciseDC' ||
      this.loggedinuserName === 'HandloomDC' ||
      this.loggedinuserName === 'HandloomDO' ||
      this.loggedinuserName === 'SCWELDC' ||
      this.loggedinuserName === 'DMO' ||
      this.designations === 'FisheryDC' ||
      this.designations === 'PS' ||
      this.loggedinuserName === 'Ward Admin Secretary' ||
      this.designations === 'WS' ||
      this.designations === 'SPT' ||
      this.designations === 'AS' ||
      this.loggedinuserName === 'Superintendent' ||
      this.loggedinuserName === 'Village surveyor' ||
      this.loggedinuserName === 'Mandal surveyor' ||
      this.loggedinuserName === 'Assistant Civil Supplies Officer') {
      this.addForm.controls['fascimileForm'].clearValidators();
      this.addForm.controls['fascimileForm'].updateValueAndValidity();
      this.addForm.controls['passbook'].clearValidators();
      this.addForm.controls['passbook'].updateValueAndValidity();
      this.addForm.controls['digitalSignature'].clearValidators();
      this.addForm.controls['digitalSignature'].updateValueAndValidity();
    } else {
      // this.addForm.controls['fascimileForm'].clearValidators();
      // this.addForm.controls['fascimileForm'].updateValueAndValidity();
    }
    if (this.loggedinuserName !== 'Revenue Divisonal Officer' && this.loggedinuserName !== 'Sub Collector') {
      this.addForm.controls['designation'].clearValidators();
      this.addForm.controls['designation'].updateValueAndValidity();
    }

    if (this.loggedinuserName == 'Zilla Sainik Welfare Officer') {
      this.addForm.controls['digitalSignature'].clearValidators();
      this.addForm.controls['digitalSignature'].updateValueAndValidity();
    }

    this.formsubmitted = true;
    if (!this.addForm.valid) {
      return false;
    } else {
      this.isEditImage = false;
      if (this.imagefile && !this.passbookimagefile) {
        this.getBase644(this.imagefile, null);
        this.ref?.close();
      } else if (!this.imagefile && this.passbookimagefile) {
        this.getBase644(null, this.passbookimagefile);
        this.ref?.close();
      } else if (this.imagefile && this.passbookimagefile) {
        this.getBase644(this.imagefile, this.passbookimagefile);
        this.ref?.close();
        
      } else if (this.loggedinuserName !== 'Village Revenue Officer'&&  this.loggedinuserName !=='Assistant Social Welfare Officer' && this.loggedinuserName !=='Fisheries Development Officer' && this.loggedinuserName !== 'Ward Admin Secretary' && this.loggedinuserName !== 'Superintendent' && this.loggedinuserName !== 'Panchayat Secretary' && this.loggedinuserName !== 'Assistant Secretary' && this.loggedinuserName !== 'Digital Assistant' && this.loggedinuserName !== 'Ward Education Secretary' && this.loggedinuserName !== 'Revenue Inspector' && this.loggedinuserName !== 'Ward Revenue Secretary' && this.loggedinuserName !== 'Village surveyor' && this.loggedinuserName !== 'Mandal surveyor' && this.loggedinuserName !== 'Assistant Civil Supplies Officer'
        && this.designations !== 'VRO' && this.designations !== 'FDO' && this.designations !== 'ASWO' && this.designations !== 'VS' && this.designations !== 'RI' && this.designations !== 'MS' && this.designations !== 'ASO' && this.designations !== 'WS' && this.designations !== 'AS' && this.designations !== 'PS' && this.designations !== 'SPT'
        && this.loggedinuserName !== 'Welfare & Educational Assistant' && this.loggedinuserName !== 'Ward Welfare & Development Secretary'
        && this.designations !== 'WEA' && this.designations !== 'DA') {
        this.isEditImage = true;
        this.updateSignaturee(this.editdata.signatureImage, this.editdata.passbookStatement)
      } else {
        this.udpateVRORIIProfile()
      }
      // this.addForm.reset();
    }
  }
  edit(data: any): any {
    this.resetAddEditForm();
    this.editdata = data;
    this.designations = this.editdata.designation ? this.editdata.designation : '';
    this.isEdit = true;
    this.showchangedimage = true;
    this.passbookshowchangedimage = true;
    // this.decryptAadharNum(data.aadharNo)
    // if(this.aadharFinalNumber){
    this.array.forEach((element: any) => {
      if (this.editdata.userID === element.userID && this.editdata.userName === element.userName) {
        this.addForm.patchValue({
          Name: element.userName,
          aadharNumber: element.AadharNumber,
          mobileNumber: element.mobileNo,
          //emailId: element.emailID,
          digitalSignature: element?.digitalSignature,
          role: element.role,
          status: element.status,
          designation: element.designation,
          // fascimileForm: 'data:image/png;base64,' + element.signatureImage
        });

        if (this.loggedinuserName === 'Ward Education Secretary' || this.loggedinuserName == 'Digital Assistant'
          || this.loggedinuserName === 'Mandal Parishad Development Officers' || this.loggedinuserName === 'Municipal Commissioner') {
          this.addForm.patchValue({
            emailId: element.emailID,
          });
        }

        if (element.designation === 'Revenue Divisonal Officer' || element.designation === 'Sub Collector') {
          this.addForm.patchValue({
            designation: element.designation,
          });
        }

        if (element.designation === 'Mandal Parishad Development Officers'
          || element.designation === 'Municipal Commissioner'
          || element.designation === 'Tahsildar') {
          this.addForm.patchValue({
            acName: element.accountHolderName,
            acNo: element.accountNumber,
            bankName: element.bankBranch,
            ifscCode: element.ifscCode
          });
        }

        this.divisionName = element.mandalName;
        this.districtname = this.editdata.districtName
        this.revenuedistrict = this.editdata.revenueDistrict
        this.revenuedistrictname = this.editdata.revenueDistrict_Name
        this.revenuedivision = this.editdata.revenueDivision
        this.revenuedivisionname = this.editdata.revenueDivision_Name
        this.revenuemandal = this.editdata.revenueMandal
        this.revenuemandalname = this.editdata.revenueMandal_Name
        this.digitalsignature = element.digitalSignature
        if(this.designation !== 'Revenue Divisonal Officer' && this.designation !== 'Sub Collector' ){
          this.designation = element.designation ? element.designation : ''
        }
       
      }
    });
    // }
    this.addForm.controls['fascimileForm'].clearValidators();
    this.addForm.controls['fascimileForm'].updateValueAndValidity();
    this.addForm.controls['passbook'].clearValidators();
    this.addForm.controls['passbook'].updateValueAndValidity();
    this.sourceFile = 'data:image/JPG;base64,' + this.editdata.signatureImage
    this.passbooksourceFile = this.editdata.passbookStatement ? 'data:image/JPG;base64,' + this.editdata.passbookStatement : null;
    this.setValidationforBankDetails();
  }
  getList() {
    this.flag = this.currentRoute.snapshot.queryParams.flag;
    this.commonService.getRequest(this.commonConstants.GetSignatureList + '?FLAG=' + this.flag).subscribe({
      next: (responseData: any) => {
        if (responseData.result.length !== 0) {
          this.array = responseData.result;
          for (let i = 0; i < this.array.length; i++) {
            this.commonService
              .getRequest(this.commonConstants.cryptoDecryptValue + encodeURIComponent(this.array[i].aadharNo))
              .subscribe({
                next: (responseData: any) => {
                  this.aadharnumber = this.commonService.RSADecrypt(responseData.result.rawValue);
                  if (this.aadharnumber != null || this.aadharnumber != undefined) {
                    this.array[i].AadharNumber = this.commonService.RSADecrypt(responseData.result.rawValue);
                    this.array[i].maskAadharrrr = this.maskAadhar(this.aadharnumber);
                  }
                },
                error: (error) => {
                  console.log('client side', error);
                },
              });
            // this.array[i].maskAadharrrr = this.aadharNumberDecryption(this.array[i].aadharNo)
          }
          this.showaddsignature = true;
          this.array.forEach((element: any) => {
            if (element.designation == 'Joint Collector' || (element.designation == 'Zilla Sainik Welfare Officer' && this.loggedinuserName == 'Zilla Sainik Welfare Officer')
              || (element.designation == 'Tahsildar' && this.loggedinuserName == 'JointCollector')
              || (element.designation == 'Tahsildar' && this.loggedinuserName == 'Tahsildar')
              || (element.designation == 'MS' && this.loggedinuserName == 'Mandal surveyor')
              || (element.designation == 'CEO, WAQF board' && this.loggedinuserName == 'CEO, WAQF board')
              || (element.designation == 'Joint Collector Development' && this.loggedinuserName == 'Joint Collector Development')
              || (element.designation == 'Mandal Parishad Development Officers' && this.loggedinuserName == 'Mandal Parishad Development Officers')
              || (element.designation == 'Municipal Commissioner' && this.loggedinuserName == 'Municipal Commissioner')) {
              this.showaddsignature = false
            }
            // else if(element.designation ==='Tahsildar' && this.loggedinuserName ==='JointCollector'){
            //   alert("TEH")
            //   this.showaddsignature =false;
            // }
            // else if(element.designation ==='Tahsildar' && this.loggedinuserName ==='Tahsildar'){
            //   alert("TEH")
            //   this.showaddsignature =false;
            // }
            //   else {
            //     alert("TEHoutside")
            //   this.showaddsignature = true;
            // }
            if ((element.designation == 'Revenue Divisonal Officer' && this.loggedinuserName === 'Joint Collector') || (element.designation == 'Sub Collector' && this.loggedinuserName === 'Joint Collector') ||
              (this.loggedinuserName === 'Revenue Divisonal Officer') || (this.loggedinuserName === 'Sub Collector')) {
              this.getRevenueDivisionByEntityCode(element.revenueMandal);
            }
            if ((element.designation == 'RI' && this.loggedinuserName === 'Tahsildar') ||
              this.loggedinuserName === 'Revenue Inspector') {
              this.getRevenueMandalByEntityCode(element.revenueMandal);
            }
          })
          // this.array.forEach((element: any) => {
          //   // this.sourceFile = 'data:image/JPG;base64,' + element.signatureImage
          //   if (element.aadharNo !== null && element.aadharNo.includes("==")) {
          //     this.decryptAadharNum(element.aadharNo)
          //   } else {
          //     // this.aadharnumber =element.aadharNo
          //   }
          // });
          // if (this.loggedinuserName === 'Joint Collector' || this.loggedinuserName === 'Collector' || this.loggedinuserName === 'Mandal Parishad Development Officers') {
          this.showtable = true;
          // } else {
          //   this.showtable = false;
          // }
          this.tempArr = this.array
        } else if (this.loggedinDetails.jobTitle === 'Panchayat Secretary DDO') {
          this.onChangeSecretariat();
        } else {
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  aadharNumberDecryption(aadharNo: any): any {
    let maskaadhar = ""
    this.commonService
      .getRequest(this.commonConstants.cryptoDecryptValue + encodeURIComponent(aadharNo))
      .subscribe({
        next: (responseData: any) => {
          this.aadharnumber = this.commonService.RSADecrypt(responseData.result.rawValue);
          if (this.aadharnumber != null || this.aadharnumber != undefined) {
            maskaadhar = this.maskAadhar(this.aadharnumber);
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
    return maskaadhar
  }

  encryptaadhar(aadhar: any, imgresult: any, passbookimgresult: any): any {
    this.encryptedAadharNumber = this.commonService.RSAEncrypt(aadhar);
    if (this.encryptedAadharNumber) {
      this.apicall(imgresult, passbookimgresult)
    }
  }
  encryptaadharupdate(aadhar: any, imgresult: any, passbookimgresult: any): any {
    this.encryptedupdateAadharNumber = this.commonService.RSAEncrypt(aadhar)
    if (this.encryptedupdateAadharNumber) {
      this.updateapicall(imgresult, passbookimgresult)
    }
  }

  encryptaadharupdatee(aadhar: any, imgresult: any, passbookimgresult: any): any {
    this.encryptedupdateAadharNumber = this.commonService.RSAEncrypt(aadhar);
    if (this.encryptedupdateAadharNumber) {
      this.updateapicalll(imgresult, passbookimgresult)
    }
  }

  addSignature(imgresult: any, passbookimgresult: any) {
    this.curdate = this.currentDate.getDate() + '-' + (this.currentDate.getMonth() + 1) + '-' + this.currentDate.getFullYear()
    let createdDateFormat = this.commonService.formatDate(this.curdate);
    this.encryptaadhar(this.g['aadharNumber'].value, imgresult, passbookimgresult);
  }

  finalpostapicall() {
    this.commonService.postRequest(this.commonConstants.AddSignature, this.postobj, true).subscribe({
      next: (responseData: any) => {
        if (responseData.result === "success") {
          this.getList();
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = 'Profile Added Successfully';
        } else {
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = responseData.result;
        }
        this.resetAddEditForm();
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
    this.sourceFile = '';
    this.passbooksourceFile = '';
  }
  apicall(imgresult: any, passbookimgresult: any) {
    if (this.loggedinDetails.jobTitle === 'Tahsildar' || this.loggedinDetails.jobTitle === 'Deputy Tahsildar') {
      this.postobj = {
        userId: this.loggedinDetails.userPrincipalName,
        name: this.g['Name'].value,
        signatureImage: imgresult ? imgresult.replace('data:image/jpeg;base64,', '') : null,
        accountHolderName: this.g['acName'].value,
        accountNumber: this.g['acNo'].value,
        bankBranch: this.g['bankName'].value,
        ifscCode: this.g['ifscCode'].value,
        passbookStatement: passbookimgresult ? passbookimgresult.replace('data:image/jpeg;base64,', '') : null,
        emailId: this.loggedinDetails.userPrincipalName,
        phoneNo: this.g['mobileNumber'].value,
        districtId: this.revenuedistrictcode,
        districtDescription: this.revenuedistrictnamee,
        mandalId: this.RVMandalId ? this.RVMandalId : '',
        mandalDescription: this.RVMandalName ? this.RVMandalName : '',
        certSerialNo: this.g['digitalSignature'].value,
        designation: this.loggedinDetails.jobTitle ? this.loggedinDetails.jobTitle : '',
        revenueDistrict: this.revenuedistrictcode,
        revenueDivision: '',
        revenueMandal: this.userInfo?.locationScope?.locationCode ? this.userInfo?.locationScope?.locationCode : '',
        /* blobReference: this.blobRef.toString(),
        isUploaded: true, */
        blobReference: null,
        isUploaded: false,
        actualDesignation: this.loggedinDetails.jobTitle ? this.loggedinDetails.jobTitle : '',
        role: this.g['role'].value,
        status: this.g['status'].value,
        aadharNo: this.encryptedAadharNumber,
        createdBy: this.loggedinDetails.userPrincipalName
      }
      this.finalpostapicall();
    }
    else if (this.loggedinDetails.jobTitle === 'Revenue Divisonal Officer' || this.loggedinDetails.jobTitle === 'Sub Collector') {
      this.postobj = {
        userId: this.loggedinDetails.userPrincipalName,
        name: this.g['Name'].value,
        signatureImage: imgresult ? imgresult.replace('data:image/jpeg;base64,', '') : null,
        accountHolderName: this.g['acName'].value,
        accountNumber: this.g['acNo'].value,
        bankBranch: this.g['bankName'].value,
        ifscCode: this.g['ifscCode'].value,
        passbookStatement: passbookimgresult ? passbookimgresult.replace('data:image/jpeg;base64,', '') : null,
        emailId: this.loggedinDetails.userPrincipalName,
        phoneNo: this.g['mobileNumber'].value,
        districtId: this.revenuedistrictcode,
        districtDescription: this.revenuedistrictnamee,
        mandalId: '',
        mandalDescription: '',
        certSerialNo: this.g['digitalSignature'].value,

        /* designation: this.loggedinDetails.jobTitle, */
        designation: this.g['designation'].value ? this.g['designation'].value : '',

        revenueDistrict: this.revenuedistrictcode,
        revenueDivision: this.userInfo.locationScope.locationCode,
        revenueMandal: this.userInfo.locationScope.locationCode,
        /* blobReference: this.blobRef.toString(),
         isUploaded: true, */
        blobReference: null,
        isUploaded: false,
        actualDesignation: this.loggedinDetails.jobTitle ? this.loggedinDetails.jobTitle : '',
        role: this.g['role'].value,
        status: this.g['status'].value,
        aadharNo: this.encryptedAadharNumber,
        createdBy: this.loggedinDetails.userPrincipalName
      }

      // this.showtable = true;
      this.finalpostapicall();
    }
    else if (this.loggedinDetails.jobTitle === 'Joint Collector' || this.loggedinDetails.jobTitle === 'Joint Collector Development' || this.loggedinDetails.jobTitle === 'Collector' || this.loggedinDetails.jobTitle === 'ExciseDC' || this.loggedinDetails.jobTitle === 'HandloomDC' || this.loggedinDetails.jobTitle === 'HandloomDO' ||
      this.loggedinDetails.jobTitle === 'SCWELDC' || this.loggedinDetails.jobTitle === 'DMO'
      || this.loggedinDetails.jobTitle === 'FisheryDC' || this.loggedinDetails.jobTitle === 'Ward Admin Secretary' || this.loggedinDetails.jobTitle === 'Superintendent' || this.loggedinDetails.jobTitle === 'District Civil Supplies Officer' || this.loggedinDetails.jobTitle === 'Zilla Sainik Welfare Officer') {
      this.postobj = {
        userId: this.loggedinDetails.userPrincipalName,
        name: this.g['Name'].value,
        signatureImage: imgresult ? imgresult.replace('data:image/jpeg;base64,', '') : null,
        accountHolderName: this.g['acName'].value,
        accountNumber: this.g['acNo'].value,
        bankBranch: this.g['bankName'].value,
        ifscCode: this.g['ifscCode'].value,
        passbookStatement: passbookimgresult ? passbookimgresult.replace('data:image/jpeg;base64,', '') : null,
        emailId: this.loggedinDetails.userPrincipalName,
        phoneNo: this.g['mobileNumber'].value,
        districtId: this.districtid,
        districtDescription: this.districtname,
        mandalId: '',
        mandalDescription: '',
        certSerialNo: this.g['digitalSignature'].value,
        designation: this.loggedinDetails.jobTitle ? this.loggedinDetails.jobTitle : '',
        revenueDistrict: '',
        revenueDivision: '',
        revenueMandal: '',
        /* blobReference: this.blobRef.toString(),
        isUploaded: true, */
        blobReference: null,
        isUploaded: false,
        actualDesignation: this.loggedinDetails.jobTitle ? this.loggedinDetails.jobTitle : '',
        role: this.g['role'].value,
        status: this.g['status'].value,
        aadharNo: this.encryptedAadharNumber,
        createdBy: this.loggedinDetails.userPrincipalName
      }
      // this.showtable = true;
      this.finalpostapicall()
    }
    else if (this.loggedinDetails.jobTitle === 'Municipal Commissioner' || this.loggedinDetails.jobTitle === 'Mandal Parishad Development Officers' || this.loggedinDetails.jobTitle === 'CEO, WAQF board') {
      this.postobj = {
        userId: this.loggedinDetails.userPrincipalName,
        name: this.g['Name'].value,
        signatureImage: imgresult ? imgresult.replace('data:image/jpeg;base64,', '') : null,
        accountHolderName: this.g['acName'].value,
        accountNumber: this.g['acNo'].value,
        bankBranch: this.g['bankName'].value,
        ifscCode: this.g['ifscCode'].value,
        passbookStatement: passbookimgresult ? passbookimgresult.replace('data:image/jpeg;base64,', '') : null,
        emailId: this.loggedinDetails.userPrincipalName,
        phoneNo: this.g['mobileNumber'].value,
        districtId: this.ulbdistrictid,
        districtDescription: this.ulbdistrictname,
        mandalId: this.ulbmandalid,
        mandalDescription: this.ulbmandalName,
        certSerialNo: this.g['digitalSignature'].value,
        designation: this.loggedinDetails.jobTitle ? this.loggedinDetails.jobTitle : '',
        revenueDistrict: '',
        revenueDivision: '',
        revenueMandal: this.loggedinDetails.jobTitle === 'Mandal Parishad Development Officers' ? this.ulbmandalid : '',
        /* blobReference: this.blobRef.toString(),
        isUploaded: true, */
        blobReference: null,
        isUploaded: false,
        actualDesignation: this.loggedinDetails.jobTitle ? this.loggedinDetails.jobTitle : '',
        role: this.g['role'].value,
        status: this.g['status'].value,
        aadharNo: this.encryptedAadharNumber,
        createdBy: this.loggedinDetails.userPrincipalName
      }
      this.finalpostapicall()
    } else if (this.loggedinDetails.jobTitle === 'Panchayat Secretary DDO') {
      this.postobj = {
        signatureImage: imgresult ? imgresult.replace('data:image/jpeg;base64,', '') : null,
        
        userId: this.loggedinDetails.userPrincipalName,
        name: this.g['Name'].value,
        saccountHolderName: this.g['acName'].value,
        accountNumber: this.g['acNo'].value,
        bankBranch: this.g['bankName'].value,
        ifscCode: this.g['ifscCode'].value,
        passbookStatement: passbookimgresult ? passbookimgresult.replace('data:image/jpeg;base64,', '') : null,
        emailId: this.loggedinDetails.userPrincipalName,
        phoneNo: this.g['mobileNumber'].value,
        districtId: this.districtid ? this.districtid : '',
        districtDescription: this.districtname ? this.districtname : '',
        mandalId: this.ulbmandalid ? this.ulbmandalid : '',
        mandalDescription: this.ulbmandalName ? this.ulbmandalName : '',
        certSerialNo: this.g['digitalSignature'].value,
        designation: 'Panchayat Secretary DDO',
        revenueDistrict: '',
        revenueDivision: '',
        revenueMandal: '',
        panchayatName: this.panchayatname ? this.panchayatname : '',
        panchayatCode: this.userInfo?.locationScope?.locationCode ? this.userInfo?.locationScope?.locationCode : '',
        /* blobReference: this.blobRef.toString(),
        isUploaded: true, */
        blobReference: null,
        isUploaded: false,
        actualDesignation: 'Panchayat Secretary DDO',
        role: this.g['role'].value,
        status: this.g['status'].value,
        aadharNo: this.encryptedAadharNumber,
        createdBy: this.loggedinDetails.userPrincipalName
      }
      this.finalpostapicall();
    }
  }
  updateSignature(imgresult: any, passbookimgresult: any): any {
    this.curdate = this.currentDate.getDate() + '-' + (this.currentDate.getMonth() + 1) + '-' + this.currentDate.getFullYear()
    let updatedDateFormat = this.commonService.formatDate(this.curdate);
    this.encryptaadharupdate(this.g['aadharNumber'].value, imgresult, passbookimgresult)
  }
  updateSignaturee(imgresult: any, passbookimgresult: any): any {
    this.curdate = this.currentDate.getDate() + '-' + (this.currentDate.getMonth() + 1) + '-' + this.currentDate.getFullYear()
    let updatedDateFormat = this.commonService.formatDate(this.curdate);
    this.encryptaadharupdatee(this.g['aadharNumber'].value, imgresult, passbookimgresult)
  }
  updateapicall(imgresult: any, passbookimgresult: any) {
    let blobRefdata = null;
    let isUpdateStatus = false;
    if (this.isEditImage == true) {
      blobRefdata = this.editdata.blobReference;
      isUpdateStatus = this.editdata.isUploaded;
    }
    let obj: any;
    if (this.loggedinDetails.jobTitle === 'Panchayat Secretary DDO') {
      obj = {
        userId: this.editdata.userID,
        name: this.addForm.value.Name,
        signatureImage: imgresult ? imgresult.replace('data:image/jpeg;base64,', '') : (this.editdata.signatureImage ? this.editdata.signatureImage : null),
        accountHolderName: this.g['acName'].value,
        accountNumber: this.g['acNo'].value,
        bankBranch: this.g['bankName'].value,
        ifscCode: this.g['ifscCode'].value,
        passbookStatement: passbookimgresult ? passbookimgresult.replace('data:image/jpeg;base64,', '') : (this.editdata.passbookStatement ? this.editdata.passbookStatement : null),
        //emailId: this.editdata.emailID,
        emailId: this.g['emailId'].value ? this.g['emailId'].value : '',
        phoneNo: this.g['mobileNumber'].value,
        certSerialNo: this.g['digitalSignature'].value,
        designation: 'Panchayat Secretary DDO',
        panchayatName: this.panchayatname ? this.panchayatname : '',
        panchayatCode: this.userInfo?.locationScope?.locationCode ? this.userInfo?.locationScope?.locationCode : '',
        blobReference: null,
        isUploaded: false,
        role: this.g['role'].value,
        status: this.g['status'].value,
        aadharNo: this.encryptedupdateAadharNumber,
        updatedBy: this.loggedinDetails.userPrincipalName
      }
    } else if (this.loggedinDetails.jobTitle === 'Mandal Parishad Development Officers' && (this.editdata.designation === 'PSDDO' || this.editdata.designation === 'Panchayat Secretary DDO')) {
      obj = {
        userId: this.editdata.userID,
        name: this.addForm.value.Name,
        signatureImage: imgresult ? imgresult.replace('data:image/jpeg;base64,', '') : (this.editdata.signatureImage ? this.editdata.signatureImage : null),
        accountHolderName: this.g['acName'].value,
        accountNumber: this.g['acNo'].value,
        bankBranch: this.g['bankName'].value,
        ifscCode: this.g['ifscCode'].value,
        passbookStatement: passbookimgresult ? passbookimgresult.replace('data:image/jpeg;base64,', '') : (this.editdata.passbookStatement ? this.editdata.passbookStatement : null),
        //emailId: this.editdata.emailID,
        emailId: this.g['emailId'].value ? this.g['emailId'].value : '',
        phoneNo: this.g['mobileNumber'].value,
        certSerialNo: this.g['digitalSignature'].value,
        designation: 'Panchayat Secretary DDO',
        panchayatName: this.editdata.panchayatName ? this.editdata.panchayatName : '',
        panchayatCode: this.editdata.panchayat ? this.editdata.panchayat : '',
        blobReference: null,
        isUploaded: false,
        role: this.g['role'].value,
        status: this.g['status'].value,
        aadharNo: this.encryptedupdateAadharNumber,
        updatedBy: this.loggedinDetails.userPrincipalName
      }

    }
    else {
      obj = {
        userId: this.editdata.userID,
        name: this.addForm.value.Name,
        signatureImage: imgresult ? imgresult.replace('data:image/jpeg;base64,', '') : (this.editdata.signatureImage ? this.editdata.signatureImage : null),
        accountHolderName: this.g['acName'].value,
        accountNumber: this.g['acNo'].value,
        bankBranch: this.g['bankName'].value,
        ifscCode: this.g['ifscCode'].value,
        passbookStatement: passbookimgresult ? passbookimgresult.replace('data:image/jpeg;base64,', '') : (this.editdata.passbookStatement ? this.editdata.passbookStatement : null),
        //emailId: this.editdata.emailID,
        emailId: this.g['emailId'].value ? this.g['emailId'].value : '',
        phoneNo: this.g['mobileNumber'].value,
        certSerialNo: this.g['digitalSignature'].value,
        designation: this.editdata.designation ? this.editdata.designation : '',

        blobReference: null,
        isUploaded: false,
        role: this.g['role'].value,
        status: this.g['status'].value,
        aadharNo: this.encryptedupdateAadharNumber,
        updatedBy: this.loggedinDetails.userPrincipalName
      }
    }
    //  if(this.loggedinDetails.jobTitle === 'Revenue Divisonal Officer'){
    // obj.blobReference=this.BlobRef? this.BlobRef:null
    //  }
    this.commonService.postRequest(this.commonConstants.UpdateSignature, obj, true).subscribe({
      next: (responseData: any) => {
        if (responseData.result === "success") {

          this.isEditImage = false;

          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          if (this.loggedinDetails.jobTitle === 'Joint Collector' && this.editdata.designation !== 'Joint Collector') {
            modalRef.componentInstance.message = 'Profile Updated  and Approved Successfully';
          }
          else if (this.loggedinDetails.jobTitle === 'Collector') {
            modalRef.componentInstance.message = 'Profile Updated  and Approved Successfully';
          } else {
            modalRef.componentInstance.message = 'Profile Updated Successfully';
          }
        } else {
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = responseData.result;
        }
        this.resetAddEditForm();
        this.reff?.close();
        this.getList();
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  updateapicalll(imgresult: any, passbookimgresult: any) {
    let blobRefdata = null;
    let isUpdateStatus = false;
    if (this.isEditImage == true) {
      blobRefdata = this.editdata.blobReference;
      isUpdateStatus = this.editdata.isUploaded;
    }
    let obj: any;
    if (this.loggedinDetails.jobTitle === 'Panchayat Secretary DDO') {
      obj = {
        userId: this.editdata.userID,
        name: this.addForm.value.Name,
        signatureImage: imgresult ? imgresult.replace('data:image/jpeg;base64,', '') : null,
        accountHolderName: this.g['acName'].value,
        accountNumber: this.g['acNo'].value,
        bankBranch: this.g['bankName'].value,
        ifscCode: this.g['ifscCode'].value,
        passbookStatement: passbookimgresult ? passbookimgresult.replace('data:image/jpeg;base64,', '') : null,
        // emailId: this.editdata.emailID,
        emailId: this.g['emailId'].value ? this.g['emailId'].value : '',
        phoneNo: this.g['mobileNumber'].value,
        certSerialNo: this.g['digitalSignature'].value,
        designation: 'Panchayat Secretary DDO',
        panchayatName: this.panchayatname ? this.panchayatname : '',
        panchayatCode: this.userInfo?.locationScope?.locationCode ? this.userInfo?.locationScope?.locationCode : '',
        blobReference: null,
        isUploaded: false,
        role: this.g['role'].value,
        status: this.g['status'].value,
        aadharNo: this.encryptedupdateAadharNumber,
        updatedBy: this.loggedinDetails.userPrincipalName
      }
    } else if (this.loggedinDetails.jobTitle === 'Mandal Parishad Development Officers' && (this.editdata.designation === 'PSDDO' || this.editdata.designation === 'Panchayat Secretary DDO')) {
      obj = {
        userId: this.editdata.userID,
        name: this.addForm.value.Name,
        signatureImage: imgresult ? imgresult.replace('data:image/jpeg;base64,', '') : null,
        accountHolderName: this.g['acName'].value,
        accountNumber: this.g['acNo'].value,
        bankBranch: this.g['bankName'].value,
        ifscCode: this.g['ifscCode'].value,
        passbookStatement: passbookimgresult ? passbookimgresult.replace('data:image/jpeg;base64,', '') : null,
        //emailId: this.editdata.emailID,
        emailId: this.g['emailId'].value ? this.g['emailId'].value : '',
        phoneNo: this.g['mobileNumber'].value,
        certSerialNo: this.g['digitalSignature'].value,
        designation: 'Panchayat Secretary DDO',
        panchayatName: this.editdata.panchayatName ? this.editdata.panchayatName : '',
        panchayatCode: this.editdata.panchayat ? this.editdata.panchayat : '',
        blobReference: null,
        isUploaded: false,
        role: this.g['role'].value,
        status: this.g['status'].value,
        aadharNo: this.encryptedupdateAadharNumber,
        updatedBy: this.loggedinDetails.userPrincipalName
      }
    } else {
      obj = {
        userId: this.editdata.userID,
        name: this.addForm.value.Name,
        signatureImage: imgresult ? imgresult.replace('data:image/jpeg;base64,', '') : null,
        accountHolderName: this.g['acName'].value,
        accountNumber: this.g['acNo'].value,
        bankBranch: this.g['bankName'].value,
        ifscCode: this.g['ifscCode'].value,
        passbookStatement: passbookimgresult ? passbookimgresult.replace('data:image/jpeg;base64,', '') : null,
        //emailId: this.editdata.emailID,
        emailId: this.g['emailId'].value ? this.g['emailId'].value : '',
        phoneNo: this.g['mobileNumber'].value,
        certSerialNo: this.g['digitalSignature'].value,
        designation: this.editdata.designation ? this.editdata.designation : '',

        blobReference: null,
        isUploaded: false,
        role: this.g['role'].value,
        status: this.g['status'].value,
        aadharNo: this.encryptedupdateAadharNumber,
        updatedBy: this.loggedinDetails.userPrincipalName
      }
    }

    if (this.loggedinuserName === 'Revenue Divisonal Officer' || this.loggedinuserName === 'Sub Collector') {
      obj.designation = this.g['designation'].value ? this.g['designation'].value : '';
    }

    this.commonService.postRequest(this.commonConstants.UpdateSignature, obj, true).subscribe({
      next: (responseData: any) => {
        if (responseData.result === "success") {

          this.isEditImage = false;

          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          if (this.loggedinDetails.jobTitle === 'Joint Collector' && this.editdata.designation !== 'Joint Collector') {
            modalRef.componentInstance.message = 'Profile Updated  and Approved Successfully';
          }
          else if (this.loggedinDetails.jobTitle === 'Collector') {
            modalRef.componentInstance.message = 'Profile Updated  and Approved Successfully';
          } else if (this.loggedinDetails.jobTitle === 'Tahsildar' && this.editdata.designation === 'Deputy Tahsildar') {
            modalRef.componentInstance.message = 'Profile Updated  and Approved Successfully';
          } else {
            modalRef.componentInstance.message = 'Profile Updated Successfully';
          }
        } else {
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = responseData.result;
        }
        this.resetAddEditForm();
        this.reff?.close();
        this.getList();
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  getUserId() {
    this.commonService.getRequest(this.commonConstants.getUserIdDetails).subscribe({
      next: (responseData: any) => {
        if (responseData && responseData.result) {
          responseData.result.forEach((item: any) => {
            item.aadhaarNo = this.commonService.RSADecrypt(item.aadhaarNo);
          });
        }
        this.userInfo = responseData.result[0];
        // filters
        // this.getDistrictByEntityCode();
        // filters
        if (this.loggedinDetails.jobTitle === 'Tahsildar' || this.loggedinDetails.jobTitle === 'Deputy Tahsildar') {
          this.getRevenueDivisionss();
          this.getRevenueMandalEntityCode();
        } else if (this.loggedinDetails.jobTitle === 'Revenue Divisonal Officer') {
          this.getRevenueDistrictss(this.userInfo.locationScope.locationCode)
        }
        else if (this.loggedinDetails.jobTitle === 'Joint Collector' || this.loggedinDetails.jobTitle === 'Joint Collector Development' || this.loggedinDetails.jobTitle === 'Zilla Sainik Welfare Officer' ||
          this.loggedinDetails.jobTitle === 'Collector' || this.loggedinDetails.jobTitle === 'ExciseDC' || this.loggedinDetails.jobTitle === 'HandloomDC' ||
          this.loggedinDetails.jobTitle === 'HandloomDO' ||
          this.loggedinDetails.jobTitle === 'SCWELDC' || this.loggedinDetails.jobTitle === 'DMO'
          || this.loggedinDetails.jobTitle === 'FisheryDC' || this.loggedinDetails.jobTitle === 'District Civil Supplies Officer') {
          // this.getRevenueDivisionss();
          this.getDistrictByJCLocationCode();
        }
        else if (this.loggedinDetails.jobTitle === 'Municipal Commissioner' || this.loggedinDetails.jobTitle === 'Mandal Parishad Development Officers'
        ) {
          this.getMandalEntityCode();
          this.onChangeSecretariat();
        }
        else if (this.loggedinDetails.jobTitle === 'Panchayat Secretary DDO') {
          this.getDistrictByJCLocationCode();
          this.getMandalEntityCode();
          this.onChangeSecretariat();
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  // filters
  getDistrictByEntityCode() {
    this.commonService.getRequest(this.commonConstants.getByEntityCodes + '?EntityCode=' + this.userInfo.locationScope.locationCode + '&LanguageCode=EN-IN&EntityType=District').subscribe({
      next: (responseData: any) => {
        this.gswsDistrict = responseData.result[0];
        this.districtid = responseData.result[0].entityCode
        this.districtname = responseData.result[0].name
        if (this.gswsDistrict) {
          this.getRevenueDistricts();
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  getRevenueDistricts() {
    this.commonService.getRequest(this.commonConstants.divisionMandalByEntityType + '?EntityType=REVENUEDistrict&ParentId=' + this.gswsDistrict.id + '&ParentType=District&LanguageCode=EN-IN').subscribe({
      next: (responseData: any) => {
        this.revenueDistrict = responseData.result[0];
        this.revenuedistrict = responseData.result[0].entityCode
        this.revenuedistrictname = responseData.result[0].name
        if (this.revenueDistrict) {
          this.getDivisions();
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  getDivisions() {
    this.commonService.getRequest(this.commonConstants.divisionMandalByEntityType + '?EntityType=REVENUEDivision&ParentId=' + this.revenueDistrict.id + '&ParentType=REVENUEDistrict&LanguageCode=EN-IN').subscribe({
      next: (responseData: any) => {
        this.revenueDivision = responseData.result;
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  onDivisionChange() {
    this.commonService.getRequest(this.commonConstants.divisionMandalByEntityType + '?EntityType=REVENUEMandal&ParentId=' + this.f['division'].value.id + '&ParentType=REVENUEDivision&LanguageCode=EN-IN').subscribe({
      next: (responseData: any) => {
        this.revenueMandals = responseData.result;
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  filter() {
    this.submitted = true;
    //alert('filter')
  }
  // filters
  convertDataURIToBinary(base64: any): any {
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let index = 0; index < rawLength; index++) {
      array[index] = raw.charCodeAt(index);

    }
    return array;
  }
  imageto64(changesourceFile: any) {
    this.sourceFile = changesourceFile;
    this.showchangedimage = true;
  }
  getBase64(image: any, passbookimage: any): any {
    let xdat;
    let file = image;
    let reader = new FileReader();
    let passbookfile = passbookimage;
    let passbookreader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        return reader.result
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }

    if (passbookfile) {
      passbookreader.readAsDataURL(passbookfile);
      passbookreader.onloadend = function () {
        return passbookreader.result
      };
      passbookreader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }

    setTimeout(() => {
      if (image && !passbookimage) {
        this.addSignature(reader.result, null);
      } else if (!image && passbookimage) {
        this.addSignature(null, passbookreader.result);
      } else if (image && passbookimage) {
        this.addSignature(reader.result, passbookreader.result);
      }
    }, 100);

  }
  getBase644(image: any, passbookimage: any): any {
    let xdat;
    let file = image;
    let reader = new FileReader();
    let passbookfile = passbookimage;
    let passbookreader = new FileReader();

    if (image) {
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        return reader.result
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
    if (passbookimage) {
      passbookreader.readAsDataURL(passbookfile);
      passbookreader.onloadend = function () {
        return passbookreader.result
      };
      passbookreader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }


    setTimeout(() => {
      if (image && !passbookimage) {
        this.updateSignature(reader.result, null);
      } else if (!image && passbookimage) {
        this.updateSignature(null, passbookreader.result);
      } else if (image && passbookimage) {
        this.updateSignature(reader.result, passbookreader.result);
      }
    }, 100);
  }
  // deputy teh and teh roles
  getRevenueDivisionss() {
    this.commonService.getRequest(this.commonConstants.getParentCodeByChildCode + 'REVENUEMandal' + '&EntityCode=' + this.userInfo.locationScope.locationCode + '&LanguageCode=EN-IN').subscribe({
      next: (responseData: any) => {
        this.revenuedivisioncode = responseData.result[0].entityCode
        if (this.revenuedivisioncode) {
          this.getRevenueDistrictss(this.revenuedivisioncode)
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  onChangeSecretariat() {
    this.commonService.getRequest(this.commonConstants.getByEntityCodes + '?EntityCode=' + this.userInfo.locationScope.locationCode + '&LanguageCode=EN-IN&EntityType=PanchayatDDO').subscribe({
      next: (responseData: any) => {
        this.panchayatname = responseData.result[0].name;
        this.panchayatcode = responseData.result[0].entityCode;
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  decryptAadharNum(aadhar: string) {
    this.commonService
      .getRequest(this.commonConstants.cryptoDecryptValue + encodeURIComponent(aadhar))
      .subscribe({
        next: (responseData: any) => {
          this.aadharnumber = this.commonService.RSADecrypt(responseData.result.rawValue);
          if (this.aadharnumber != null || this.aadharnumber != undefined) {
            let maskaadhar = this.maskAadhar(this.aadharnumber);
            return maskaadhar
          }
          // if (this.aadharnumber)
          //   this.array.forEach((element: any) => {
          //     if (this.editdata.userName === element.userName) {
          //       this.addForm.patchValue({
          //         Name: element.userName,
          //         aadharNumber: this.aadharnumber,
          //         mobileNumber: element.mobileNo,
          //         digitalSignature: element.digitalSignature,
          //         role: element.role,
          //         status: element.status
          //       });
          //       this.divisionName = element.mandalName;
          //       this.districtname = this.editdata.districtName
          //       this.revenuedistrict = this.editdata.revenueDistrict
          //       this.revenuedistrictname = this.editdata.revenueDistrict_Name
          //       this.revenuedivision = this.editdata.revenueDivision
          //       this.revenuedivisionname = this.editdata.revenueDivision_Name
          //       this.revenuemandal = this.editdata.revenueMandal
          //       this.revenuemandalname = this.editdata.revenueMandal_Name
          //       this.digitalsignature = element.digitalSignature
          //     }
          //   });
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  getRevenueDistrictss(entitycode: any) {
    this.commonService.getRequest(this.commonConstants.getParentCodeByChildCode + 'REVENUEDivision' + '&EntityCode=' + entitycode + '&LanguageCode=EN-IN').subscribe({
      next: (responseData: any) => {
        if (responseData.result) {
          this.revenuedistrictcode = responseData.result[0].entityCode
          this.revenuedistrictnamee = responseData.result[0].name
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  getRevenueMandalEntityCode() {
    this.commonService.getRequest(this.commonConstants.getByEntityCodes + '?EntityCode=' + this.userInfo.locationScope.locationCode + '&LanguageCode=EN-IN&EntityType=REVENUEMANDAL').subscribe({
      next: (responseData: any) => {
        this.revenueMandalId = responseData.result[0].id
        if (this.revenueMandalId) {
          this.getRvMandals()
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  getRvMandals() {
    this.commonService.getRequest(this.commonConstants.divisionMandalByEntityType + '?EntityType=REVENUETORV&ParentId=' + this.revenueMandalId + '&ParentType=REVENUEMANDAL&LanguageCode=EN-IN').subscribe({
      next: (responseData: any) => {
        this.RVMandalId = responseData.result[0].entityCode;
        this.RVMandalName = responseData.result[0].name;
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  // depteh,teh roles 
  // jc
  getDistrictByJCLocationCode() {
    this.commonService.getRequest(this.commonConstants.getByEntityCodes + '?EntityCode=' + this.userInfo.locationScope.locationCode + '&LanguageCode=EN-IN&EntityType=District').subscribe({
      next: (responseData: any) => {
        this.districtid = responseData.result[0].entityCode ? responseData.result[0].entityCode : '';
        this.districtname = responseData.result[0].name ? responseData.result[0].name : '';
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  // jc
  // mc ,mpdo
  getMandalEntityCode() {
    this.commonService.getRequest(this.commonConstants.getByEntityCodes + '?EntityCode=' + this.userInfo.locationScope.locationCode + '&LanguageCode=EN-IN&EntityType=Mandal').subscribe({
      next: (responseData: any) => {
        this.ulbmandalid = responseData.result[0].entityCode
        this.ulbmandalName = responseData.result[0].name
        this.getDistrictBasedonULBMandal(responseData.result[0].entityCode)
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  getDistrictBasedonULBMandal(entitycode: any) {
    this.commonService.getRequest(this.commonConstants.getParentCodeByChildCode + 'Mandal' + '&EntityCode=' + entitycode + '&LanguageCode=EN-IN').subscribe({
      next: (responseData: any) => {
        this.ulbdistrictid = responseData.result[0].entityCode
        this.ulbdistrictname = responseData.result[0].name
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  // mc ,mpdo
  getSecretariatEntityCode() {
    this.commonService.getRequest(this.commonConstants.getByEntityCodes + '?EntityCode=' + this.userInfo.locationScope.locationCode + '&LanguageCode=EN-IN&EntityType=Secretariat').subscribe({
      next: (responseData: any) => {
        this.getMandalBySecEntityCode(responseData.result[0].entityCode)
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  getMandalBySecEntityCode(entitycode: any) {
    this.commonService.getRequest(this.commonConstants.getByEntityCodes + '?EntityCode=' + entitycode + '&LanguageCode=EN-IN&EntityType=Mandal').subscribe({
      next: (responseData: any) => {
        this.ulbmandalid = responseData.result[0].entityCode
        this.ulbmandalName = responseData.result[0].name
        this.getDistrictBasedonULBMandal(responseData.result[0].entityCode)
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  getStatus(event: any) {

    let statusName = event.target.value;

    this.tempArr = [];
    if (statusName === "Pending") {
      this.tempArr = this.array.filter((item: any) => item.isApproved == false);
      return this.tempArr
    } else if (statusName === "Approved") {
      this.tempArr = this.array.filter((item: any) => item.isApproved == true);
      return this.tempArr;
    } else if (statusName === "All") {
      this.tempArr = this.array;
      //this.getList();
    }
  }
  maskAadhar(cardNumber: any) {
    var splifubc = cardNumber.split('')
    let aadharFinalNum: any;
    var strArray = Array.from(cardNumber)
    for (let index = 0; index < strArray.length; index++) {
      if (index > 7) {
        aadharFinalNum = aadharFinalNum + strArray[index]
      } else if (index == 0) {
        aadharFinalNum = 'X'
      } else {
        aadharFinalNum = aadharFinalNum + 'X'
      }

    }
    this.aadharFinalNumber = aadharFinalNum;
    return this.aadharFinalNumber

  }
  editVRORIIProfile() {
    this.editencryptaadharupdatee(this.g['aadharNumber'].value)
  }
  udpateVRORIIProfile() {
    this.updateencryptaadharupdatee(this.g['aadharNumber'].value)
  }

  updateVRORIProfile() {
    if (this.editdata.designation === 'RI') {
      this.editdata.designation = 'Revenue Inspector'
    } else if (this.editdata.designation === 'MS') {
      this.editdata.designation = 'Mandal surveyor'
    } else if (this.editdata.designation === 'ASO') {
      this.editdata.designation = 'Assistant Civil Supplies Officer'
    } else if (this.editdata.designation === 'VRO') {
      this.editdata.designation = 'Village Revenue Officer'
    } else if (this.editdata.designation === 'VS') {
      this.editdata.designation = 'Village surveyor'
    } else if (this.editdata.designation === 'DA') {
      this.editdata.designation = 'Digital Assistant'
    } else if (this.editdata.designation === 'FDO') {
      this.editdata.designation = 'Fisheries Development Officer'
    } else if (this.editdata.designation === 'ASWO') {
      this.editdata.designation = 'Assistant Social Welfare Officer'
    }
    

    else if (this.editdata.designation === 'WEA') {
      if (this.loggedinuserName === 'Welfare & Educational Assistant' || this.loggedinuserName === 'Mandal Parishad Development Officers') {
        this.editdata.designation = 'Welfare & Educational Assistant'
      } else if (this.loggedinuserName === 'Ward Welfare & Development Secretary' || this.loggedinuserName === 'Municipal Commissioner') {
        this.editdata.designation = 'Ward Welfare & Development Secretary'
      }
    }
    let obj = {
      userId: this.editdata.userID,
      name: this.g['Name'].value,
      signatureImage: '',
      //emailId: this.editdata.emailID,
      emailId: this.g['emailId'].value ? this.g['emailId'].value : '',
      phoneNo: this.g['mobileNumber'].value,
      certSerialNo: '',
      designation: this.editdata.designation ? this.editdata.designation : '',
      blobReference: null,
      isUploaded: false,
      role: this.g['role'].value,
      status: this.g['status'].value,
      aadharNo: this.updateencryptedupdateAadharNumber,
      updatedBy: this.loggedinDetails.userPrincipalName
    }

    this.commonService.postRequest(this.commonConstants.UpdateSignature, obj, true).subscribe({
      next: (responseData: any) => {
        if (responseData.result === "success") {
          if (this.loggedinuserName === 'Village Revenue Officer' ||
            this.loggedinuserName === 'Assistant Social Welfare Officer' ||
            this.loggedinuserName === 'Fisheries Development Officer' ||
            this.loggedinuserName === 'Panchayat Secretary' ||
            this.loggedinuserName === 'Assistant Secretary' ||
            this.loggedinuserName === 'Digital Assistant' ||
            this.loggedinuserName === 'Ward Education Secretary' ||
            this.loggedinuserName === 'Revenue Inspector' ||
            this.loggedinuserName === 'Welfare & Educational Assistant' ||
            this.loggedinuserName === 'Ward Welfare & Development Secretary' ||
            this.loggedinuserName === 'Ward Admin Secretary' ||
            this.loggedinuserName === 'Superintendent' ||
            this.loggedinuserName === 'Village surveyor' ||
            this.loggedinuserName === 'Assistant Civil Supplies Officer' ||
            this.loggedinuserName === 'Ward Revenue Secretary') {
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = 'Profile Updated Successfully';
          } else {
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = 'Profile Updated  and Approved Successfully';
          }
        } else {
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = responseData.result;
        }
        this.reff?.close();
        this.getList();
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  submitVRORIProfile() {
    if (this.loggedinDetails.jobTitle === 'Ward Revenue Secretary') {
      this.loggedinDetails.jobTitle = 'Village Revenue Officer'
    }
    let obj = {
      userId: this.loggedinDetails.userPrincipalName,
      name: this.g['Name'].value,
      signatureImage: '',
      emailId: this.loggedinDetails.userPrincipalName,
      phoneNo: this.g['mobileNumber'].value,
      certSerialNo: '',
      designation: this.loggedinDetails.jobTitle ? this.loggedinDetails.jobTitle : '',
      blobReference: null,
      isUploaded: false,
      role: this.g['role'].value,
      status: this.g['status'].value,
      aadharNo: this.editencryptedupdateAadharNumber,
      updatedBy: this.loggedinDetails.userPrincipalName
    }
    this.commonService.postRequest(this.commonConstants.AddSignature, obj, true).subscribe({
      next: (responseData: any) => {
        if (responseData.result === "success") {
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = 'Profile Submitted Successfully';
        } else {
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.title = 'Alert';
          modalRef.componentInstance.message = responseData.result;
        }
        this.resetAddEditForm();
        this.ref?.close();
        this.getList();
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  editencryptaadharupdatee(aadhar: any): any {
    this.editencryptedupdateAadharNumber = this.commonService.RSAEncrypt(aadhar);
    if (this.editencryptedupdateAadharNumber) {
      this.submitVRORIProfile()
    }
  }
  updateencryptaadharupdatee(aadhar: any): any {
    this.updateencryptedupdateAadharNumber = this.commonService.RSAEncrypt(aadhar);
    if (this.updateencryptedupdateAadharNumber) {
      this.updateVRORIProfile()
    }
  }
  goToHome() {
    this.router.navigate(['/home']);
  }

  getRevenueDivisionByEntityCode(entitycode: any) {
    this.commonService.getRequest(this.commonConstants.getByEntityCodes + '?EntityCode=' + entitycode + '&LanguageCode=EN-IN&EntityType=REVENUEDivision').subscribe({
      next: (responseData: any) => {
        for (let index = 0; index < this.array.length; index++) {
          const element = this.array[index];
          if ((element.designation === 'Revenue Divisonal Officer' || element.designation === 'Sub Collector') && (this.array[index].revenueMandal === responseData.result[0].entityCode)) {
            this.array[index].revdiv = responseData.result[0].name;
          }
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  getRevenueMandalByEntityCode(entitycode: any) {
    this.commonService.getRequest(this.commonConstants.getByEntityCodes + '?EntityCode=' + entitycode + '&LanguageCode=EN-IN&EntityType=REVENUEMandal').subscribe({
      next: (responseData: any) => {
        for (let index = 0; index < this.array.length; index++) {
          const element = this.array[index];
          if (element.designation === 'RI' && (this.array[index].revenueMandal === responseData.result[0].entityCode)) {
            this.array[index].mandalName = responseData.result[0].name;
          }
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
}

