import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AarogyasriViewComponent } from './aarogyasri-view/aarogyasri-view.component';

const routes: Routes = [
  { path: 'app-aarogyasri-view', component : AarogyasriViewComponent }
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HealthViewRoutingModule { }
