import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-mutation-corrections-resurvey-view',
  templateUrl: './mutation-corrections-resurvey-view.component.html',
  styleUrls: ['./mutation-corrections-resurvey-view.component.css']
})
export class MutationCorrectionsResurveyViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  @Output() viewImg = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  viewFile(file: any) {
    this.view.emit(file);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
  viewImgFile(file: any) {
    this.viewImg.emit(file);
  }
}
