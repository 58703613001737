import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { PdfViewerPopupComponent } from 'src/shared/pdf-viewer-popup/pdf-viewer-popup.component';

@Component({
  selector: 'app-uc-report',
  templateUrl: './uc-report.component.html',
  styleUrls: ['./uc-report.component.css']
})
export class UCReportComponent implements OnInit {
  commonConstants : any = CommonConstants;
  form: FormGroup;
  htmlStr: any;
  districts: any[] = [];
  mandals: any[] = [];
  villages: any[]= [];
  formatTypes: any[]= [];
  format: any;
  currentDate = new Date();
  submitted: Boolean = false;
  defaultFromDate: any;
  items: any[] = [];
  page = 1;
  pageSize = 10;
  showReports: Boolean = false;
  totalReport: Boolean = false;
  noOfCol: any = 46;
  popupPageSize = 10;
  popupPage = 1;
  beyondSLAItems: any[] = [];
  totalItems: any[] = [];
  role: any;
  title: any;
  @ViewChild('TABLE') table!: ElementRef;

  constructor(private formBuilder: FormBuilder, private commonService: CommonService, config: NgbModalConfig, private modalService: NgbModal, private router: Router) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.form = this.formBuilder.group({
      village: [null],
      mandal: [null],
      district: [null],
      RUFlag: [null],
    });

    this.getDistricts();
  }
   

  ngOnInit(): void {
    this.defaultFromDate = this.currentDate.getDate()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getFullYear();
    this.format = 'District Wise';
     this.form.patchValue({
       district:'All',
       mandal:'All',
       village: 'All',
       RUFlag: 'All',
     });
     this.formatTypes = [
        {name: 'District Wise', isActive: true},
        {name:'Mandal Wise', isActive: false},
        {name:'Secretariat Wise', isActive: false}
      ]
      this.getUCReportData();
     let userInfo = localStorage.getItem('userInfo');
      if (userInfo && userInfo !== null) {
        let userData = JSON.parse(userInfo);
        this.role = userData.jobTitle;
      }
  }

  get f() { return this.form.controls; }

  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getCSPDistricts).subscribe({
      next: (responseData: any) => {
        console.log(responseData,'getDistricts')
        if(responseData.result?.isSuccess) {
          this.districts = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'districtName');
          console.log(this.districts,'districts')
        } else {
          this.districts = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onChangeFormat(item: any) {
    this.showReports = false;
    this.formatTypes.map((item:any) => {
      item.isActive = false;
      return item;
    });
    item.isActive = true;
    this.format = item.name;
    this.getFormat();
  }
  
  onRuralUrbanChange() {
    if(this.f['district'].value !== 'All') {
      this.onDistrictChange();
    }
  }

  onDistrictChange() {
    this.showReports = false;
    this.mandals = [];
    this.villages = [];
    this.f.mandal.patchValue('All');
    this.f.village.patchValue('All');
    let RUFlag: any = this.f['RUFlag'].value === 'All' ? '' : this.f['RUFlag'].value;
    this.commonService.getRequest(this.commonConstants.getCSPMandalsRuralUrban + '?DistrictCode=' + this.f['district'].value.districtCode + '&RuralOrUrbanFlag=' + '').subscribe({
      next: (responseData: any) => { 
        console.log(responseData,'mandals')
        if(responseData.result?.isSuccess) {
          this.mandals = this.commonService.sortItems(responseData.result?.getMandalDetails, 'mandalName');
        } else {
          this.mandals = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onMandalChange() {
    this.showReports = false;
    this.villages = [];
    this.f.village.patchValue('All');
    this.commonService.getRequest(this.commonConstants.getCSPSecretariat + '?MandalCode=' + this.f.mandal.value.mandalCode).subscribe({
      next: (responseData: any) => { 
        console.log(responseData,'villages')
        if(responseData.result?.isSuccess) {
          this.villages = this.commonService.sortItems(responseData.result?.getSecretariatDetails, 'secretariatName');
        } else {
          this.villages = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onVillageChange() {
    this.showReports = false;
  }

  goToHome() {
    this.router.navigate(['/home']);
  }

  downloadExcel() {
    let fileName: any = 'Utilization Certificate Report' 
    const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
    let wscols = [
      {wpx:50},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100},
      {wpx:100}
  ];
  
  ws['!cols'] = wscols;
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'UCReport');
    
    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
 
  saveAsExcelFile(buffer: any, fileName: string) {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  viewImgFile(blobRef: any) {
    this.commonService
      .getRequest(this.commonConstants.viewFile + blobRef)
      .subscribe({
        next: (responseData: any) => {
          let fileInfo = responseData.result.fileContents;
           const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
           modalRef.componentInstance.sourceFile = fileInfo;
           modalRef.componentInstance.showPrintDownload = false;
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
 

  getFormat(): any {
    if (this.format === 'District Wise' ) {
      this.noOfCol = 46;
      return this.noOfCol;
    } else if (this.format === 'Mandal Wise') {
      this.noOfCol = 39;
      return this.noOfCol;
    }  else{
      this.noOfCol = 33;
      return this.noOfCol;
    }
  }

  getUCReportData(from?: any): any {
    this.submitted = true;
    if (!this.form.valid){
      return false;
    } else { 
        this.page = 1;
        let district = this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode;
        let mandal = this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode;
        let village = this.f.village.value === 'All' ? 'All' : this.f.village.value?.secretariatCode;
        let RUFlag = this.f.RUFlag.value === 'All' ? 'All' : this.f.RUFlag.value;
        let postData: any = '?Flag='+ this.format +'&DID=' + district  + '&MID=' + mandal +'&SEC=' + village + '&RURBAN=' + RUFlag ;
        this.commonService.getRequest(this.commonConstants. getUCReportData + postData).subscribe({
          next: (responseData: any) => { 
            console.log(typeof(responseData?.result),'result man')
            if(typeof(responseData?.result) !== 'string') {
                this.showReports = true;
                this.items = responseData?.result;
                  this.items = this.items.filter(item => item.totalflag !== 'Y')
                  this.totalItems = responseData?.result.filter((item: { totalflag: string; }) => item.totalflag === 'Y')
                  console.log(this.items,'items')
                  console.log( this.totalItems ,'total items')
            } else {
              this.items = [];
              this.totalItems = [];
            }
          },
          error: (error) => {
            console.log('client side', error);
          }
      });
      }
  }

}

