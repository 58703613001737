<div class="pensions-report-container">
    <div [formGroup]="form">
        <div class="row mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
            <select class="selectCls"  formControlName="district" (change)="onDistrictChange()"
            [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
                <option value="All">All</option>
                <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
            </select>  
            </div>
           
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Mandal <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
              [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
            <option value="All">All</option>
            <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
            </select>
         
            </div>

            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Rural/Urban : <span class="mandatoryCls">*</span></label> 
                <select class="selectCls" required formControlName="RUFlag"
                [ngClass]="{ 'is-invalid':submitted && (f.RUFlag.errors || f.RUFlag.value === 'Select') }">
                <option value = "All">All</option>
                <option value = "R">Rural</option>
                <option value = "U">Urban</option>
                </select>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Secretariat: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="village" (change)="onVillageChange()" >
                <option value="All">All</option>
                <option *ngFor="let village of villages" [ngValue]="village">{{village.secretariatName}}</option>
            </select>
            </div>
        </div>
    <div class="tabClass">
        <ul>
            <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li> 
        </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getpensionreportData()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" (click)="downloadExcel()" *ngIf="items && items?.length > 0 && showReports">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
        </div>
    </div>
</div>
    <div class="table-responsive tableFixHead" *ngIf="showReports">
        <table class="tableCls">
            <thead>
                <tr>
                    <th [attr.colspan]="noOfCol">PENSIONS REPORT</th>
                </tr>
                <tr>
                    <th rowspan="3">SL NO</th>
                    <th rowspan="3" [mdbTableSort]="items" [sortBy]="'districT_NAME'">DISTRICT NAME <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="3" *ngIf="format === 'MANDAL WISE' || format === 'SECRETARIAT WISE'" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">MANDAL NAME <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="3" *ngIf="format === 'MANDAL WISE' || format === 'SECRETARIAT WISE'" [mdbTableSort]="items" [sortBy]="'rurbaN_FLAG'">RURAL/URBAN <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="3" *ngIf="format === 'SECRETARIAT WISE'" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'">SECRETARIAT CODE <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="3" *ngIf="format === 'SECRETARIAT WISE'" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'">SECRETARIAT NAME <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="1" colspan="11">FISHERMEN PENSION</th>
                    <th rowspan="1" colspan="11">DAPPU ARTISTS PENSION</th>
                    <th rowspan="1" colspan="11">TRADITIONAL COBBLERS PENSION </th>
                    <th rowspan="1" colspan="11">WEAVERS PENSION</th>				
                </tr>
                <tr>
                    <th rowspan="2">TOTAL APPLICATION</th>
                    <th rowspan="2" class="sub-report-heading">APPROVED</th>
                    <th rowspan="2" class="sub-report-reject-heading">REJECTED</th>
                    <th rowspan="2" class="sub-report-pending-heading">TOTAL PENDING</th>
                    <th colspan="7">PENDING AT DESIGNATION</th>

                    <th rowspan="2">TOTAL APPLICATION</th>
                    <th rowspan="2" class="sub-report-heading">APPROVED</th>
                    <th rowspan="2" class="sub-report-reject-heading">REJECTED</th>
                    <th rowspan="2" class="sub-report-pending-heading">TOTAL PENDING</th>
                    <th colspan="7">PENDING AT DESIGNATION</th>

                    <th rowspan="2">TOTAL APPLICATION</th>
                    <th rowspan="2" class="sub-report-heading">APPROVED</th>
                    <th rowspan="2" class="sub-report-reject-heading">REJECTED</th>
                    <th rowspan="2" class="sub-report-pending-heading">TOTAL PENDING</th>
                    <th colspan="7">PENDING AT DESIGNATION</th>

                    <th rowspan="2">TOTAL APPLICATION</th>
                    <th rowspan="2" class="sub-report-heading">APPROVED</th>
                    <th rowspan="2" class="sub-report-reject-heading">REJECTED</th>
                    <th rowspan="2" class="sub-report-pending-heading">TOTAL PENDING</th>
                    <th colspan="7">PENDING AT DESIGNATION</th>
                </tr>
                <tr>
                    <th colspan="2">WEA/WWDS_PENDING</th>
                    <th colspan="2">FDO_PENDING</th>
                    <th colspan="2">FISHERYDC_PENDING</th>
                    <th colspan="1">MPDO/MC_PENDING</th>
                 
                    <th colspan="2">WEA/WWDS_PENDING</th>
                    <th colspan="2">ASWO_PENDING</th>
                    <th colspan="2">SCWELDC_PENDING</th>
                    <th colspan="1">MPDO/MC_PENDING</th>

                    <th colspan="2">WEA/WWDS_PENDING</th>
                    <th colspan="2">ASWO_PENDING</th>
                    <th colspan="2">SCWELDC_PENDING</th>
                    <th colspan="1">MPDO/MC_PENDING</th>

                    <th colspan="2">WEA/WWDS_PENDING</th>
                    <th colspan="2">DO_PENDING</th>
                    <th colspan="2">DC_PENDING</th>
                    <th colspan="1">MPDO/MC_PENDING</th>
                </tr>
            </thead>
            <tbody *ngIf="items && items?.length > 0">
                <ng-container>
                    <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                        <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                        <td>{{item.districT_NAME}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'SECRETARIAT WISE'">{{item.mandaL_NAME}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'SECRETARIAT WISE'">{{item.rurbaN_FLAG}}</td>
                        <td *ngIf= "format == 'SECRETARIAT WISE'">{{item.secretariaT_CODE}}</td>
                        <td *ngIf= "format == 'SECRETARIAT WISE'">{{item.secretariaT_NAME}}</td>
                        <td>{{item.toT_FISH}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_FISH_APP}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_FISH_REJ}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_FISH_PEND}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report" (click)="getpensionreportDataStatus('Approved', modalpopupstatus,'APPROVED','Fishermen Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">
                            <a>{{item.toT_FISH_APP}}</a>  
                        </td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report-reject" (click)="getpensionreportDataStatus('Rejected',modalpopupstatus,'REJECTED','Fishermen Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">
                            <a>{{item.toT_FISH_REJ}}</a> 
                        </td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report-pending" (click)="getpensionreportDataStatus('Pending',modalpopupstatus,'PENDING','Fishermen Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">
                            <a>{{item.toT_FISH_PEND}}</a>
                        </td>
                        <td colspan="2">{{item.fisH_PEND_WEA}}</td>
                        <td colspan="2">{{item.fisH_PEND_FDO}}</td>
                        <td colspan="2">{{item.fisH_PEND_FDC}}</td>
                        <td colspan="1">{{item.fisH_PEND_MPDO}}</td>
                        <td>{{item.toT_DAPPU}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_DAPPU_APP}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_DAPPU_REJ}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_DAPPU_PEND}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report" (click)="getpensionreportDataStatus('Approved',modalpopupstatus, 'APPROVED','Dappu Artists Pension',$event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">{{item.toT_DAPPU_APP}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report-reject" (click)="getpensionreportDataStatus('Rejected', modalpopupstatus, 'REJECTED','Dappu Artists Pension',$event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">{{item.toT_DAPPU_REJ}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report-pending" (click)="getpensionreportDataStatus('Pending',modalpopupstatus, 'PENDING','Dappu Artists Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">{{item.toT_DAPPU_PEND}}</td>
                        <td colspan="2">{{item.dappU_PEND_WEA}}</td>
                        <td colspan="2">{{item.dappU_PEND_ASWO}}</td>
                        <td colspan="2">{{item.dappU_PEND_SCWDC}}</td>
                        <td colspan="1">{{item.dappU_PEND_MPDO}}</td>
                        <td>{{item.toT_Cobblers}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_Cobblers_APP}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_Cobblers_REJ}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_Cobblers_PEND}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report" (click)="getpensionreportDataStatus('Approved', modalpopupstatus, 'APPROVED','Traditional Cobblers Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">{{item.toT_Cobblers_APP}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report-reject" (click)="getpensionreportDataStatus('Rejected', modalpopupstatus, 'REJECTED','Traditional Cobblers Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">{{item.toT_Cobblers_REJ}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report-pending" (click)="getpensionreportDataStatus('Pending', modalpopupstatus, 'PENDING','Traditional Cobblers Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">{{item.toT_Cobblers_PEND}}</td>
                        <td colspan="2">{{item.cobblers_PEND_WEA}}</td>
                        <td colspan="2">{{item.cobblers_PEND_ASWO}}</td>
                        <td colspan="2">{{item.cobblers_PEND_SCWDC}}</td>
                        <td colspan="1">{{item.cobblers_PEND_MPDO}}</td>
                        <td>{{item.toT_Weaver}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_Weaver_APP}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_Weaver_REJ}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_Weaver_PEND}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report" (click)="getpensionreportDataStatus('Approved', modalpopupstatus, 'APPROVED','Weavers Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">{{item.toT_Weaver_APP}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report-reject" (click)="getpensionreportDataStatus('Rejected', modalpopupstatus, 'REJECTED','Weavers Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">{{item.toT_Weaver_REJ}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report-pending" (click)="getpensionreportDataStatus('Pending',modalpopupstatus, 'PENDING','Weavers Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG )">{{item.toT_Weaver_PEND}}</td>
                        <td colspan="2">{{item.weaver_PEND_WEA}}</td>
                        <td colspan="2">{{item.weaver_PEND_HDO}}</td>
                        <td colspan="2">{{item.weaver_PEND_HDC}}</td>
                        <td colspan="1">{{item.weaver_PEND_MPDO}}</td>
                    </tr>
                    <tr *ngIf="totalItems && totalItems?.length > 0">
                        <td></td>
                        <td>{{totalItems[0].districT_NAME}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'SECRETARIAT WISE'">{{totalItems[0].mandaL_NAME}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'SECRETARIAT WISE'">{{totalItems[0].rurbaN_FLAG}}</td>
                        <td *ngIf= "format == 'SECRETARIAT WISE'">{{totalItems[0].secretariaT_CODE}}</td>
                        <td *ngIf= "format == 'SECRETARIAT WISE'">{{totalItems[0].secretariaT_NAME}}</td>
                        <td>{{totalItems[0].toT_FISH}}</td>
                        <td>{{totalItems[0].toT_FISH_APP}}</td>
                        <td>{{totalItems[0].toT_FISH_REJ}}</td>
                        <td>{{totalItems[0].toT_FISH_PEND}}</td>
                        <td colspan="2">{{totalItems[0].fisH_PEND_WEA}}</td>
                        <td colspan="2">{{totalItems[0].fisH_PEND_FDO}}</td>
                        <td colspan="2">{{totalItems[0].fisH_PEND_FDC}}</td>
                        <td colspan="1">{{totalItems[0].fisH_PEND_MPDO}}</td>
                        <td>{{totalItems[0].toT_DAPPU}}</td>
                        <td>{{totalItems[0].toT_DAPPU_APP}}</td>
                        <td>{{totalItems[0].toT_DAPPU_REJ}}</td>
                        <td>{{totalItems[0].toT_DAPPU_PEND}}</td>
                        <td colspan="2">{{totalItems[0].dappU_PEND_WEA}}</td>
                        <td colspan="2">{{totalItems[0].dappU_PEND_ASWO}}</td>
                        <td colspan="2">{{totalItems[0].dappU_PEND_SCWDC}}</td>
                        <td colspan="1">{{totalItems[0].dappU_PEND_MPDO}}</td>
                        <td>{{totalItems[0].toT_Cobblers}}</td>
                        <td>{{totalItems[0].toT_Cobblers_APP}}</td>
                        <td>{{totalItems[0].toT_Cobblers_REJ}}</td>
                        <td>{{totalItems[0].toT_Cobblers_PEND}}</td>
                        <td colspan="2">{{totalItems[0].cobblers_PEND_WEA}}</td>
                        <td colspan="2">{{totalItems[0].cobblers_PEND_ASWO}}</td>
                        <td colspan="2">{{totalItems[0].cobblers_PEND_SCWDC}}</td>
                        <td colspan="1">{{totalItems[0].cobblers_PEND_MPDO}}</td>
                        <td>{{totalItems[0].toT_Weaver}}</td>
                        <td>{{totalItems[0].toT_Weaver_APP}}</td>
                        <td>{{totalItems[0].toT_Weaver_REJ}}</td>
                        <td>{{totalItems[0].toT_Weaver_PEND}}</td>
                        <td colspan="2">{{totalItems[0].weaver_PEND_WEA}}</td>
                        <td colspan="2">{{totalItems[0].weaver_PEND_HDO}}</td>
                        <td colspan="2">{{totalItems[0].weaver_PEND_HDC}}</td>
                        <td colspan="1">{{totalItems[0].weaver_PEND_MPDO}}</td>
                    </tr>
                </ng-container>
            </tbody> 
            <ng-container>
                <tbody *ngIf="items?.length == 0">
                    <tr>
                        <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                    </tr>
                </tbody>
            </ng-container>
        </table>
    </div>

    <div class="table-responsive tableFixHead" #TABLE class="hideTable">
        <table class="tableCls">
            <thead>
                <tr>
                    <th [attr.colspan]="noOfCol">PENSIONS REPORT</th>
                </tr>
                <tr>
                    <th rowspan="3">SL NO</th>
                    <th rowspan="3" [mdbTableSort]="items" [sortBy]="'districT_NAME'">DISTRICT NAME <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="3" *ngIf="format === 'MANDAL WISE' || format === 'SECRETARIAT WISE'" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">MANDAL NAME <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="3" *ngIf="format === 'MANDAL WISE' || format === 'SECRETARIAT WISE'" [mdbTableSort]="items" [sortBy]="'rurbaN_FLAG'">RURAL/URBAN <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="3" *ngIf="format === 'SECRETARIAT WISE'" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'">SECRETARIAT CODE <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="3" *ngIf="format === 'SECRETARIAT WISE'" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'">SECRETARIAT NAME <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th rowspan="1" colspan="11">FISHERMEN PENSION</th>
                    <th rowspan="1" colspan="11">DAPPU ARTISTS PENSION</th>
                    <th rowspan="1" colspan="11">TRADITIONAL COBBLERS PENSION </th>
                    <th rowspan="1" colspan="11">WEAVERS PENSION</th>				
                </tr>
                <tr>
                    <th rowspan="2">TOTAL APPLICATION</th>
                    <th rowspan="2" class="sub-report-heading">APPROVED</th>
                    <th rowspan="2" class="sub-report-reject-heading">REJECTED</th>
                    <th rowspan="2" class="sub-report-pending-heading">TOTAL PENDING</th>
                    <th colspan="7">PENDING AT DESIGNATION</th>

                    <th rowspan="2">TOTAL APPLICATION</th>
                    <th rowspan="2" class="sub-report-heading">APPROVED</th>
                    <th rowspan="2" class="sub-report-reject-heading">REJECTED</th>
                    <th rowspan="2" class="sub-report-pending-heading">TOTAL PENDING</th>
                    <th colspan="7">PENDING AT DESIGNATION</th>

                    <th rowspan="2">TOTAL APPLICATION</th>
                    <th rowspan="2" class="sub-report-heading">APPROVED</th>
                    <th rowspan="2" class="sub-report-reject-heading">REJECTED</th>
                    <th rowspan="2" class="sub-report-pending-heading">TOTAL PENDING</th>
                    <th colspan="7">PENDING AT DESIGNATION</th>

                    <th rowspan="2">TOTAL APPLICATION</th>
                    <th rowspan="2" class="sub-report-heading">APPROVED</th>
                    <th rowspan="2" class="sub-report-reject-heading">REJECTED</th>
                    <th rowspan="2" class="sub-report-pending-heading">TOTAL PENDING</th>
                    <th colspan="7">PENDING AT DESIGNATION</th>
                </tr>
                <tr>
                    <th colspan="2">WEA/WWDS_PENDING</th>
                    <th colspan="2">FDO_PENDING</th>
                    <th colspan="2">FISHERYDC_PENDING</th>
                    <th colspan="1">MPDO/MC_PENDING</th>
                 
                    <th colspan="2">WEA/WWDS_PENDING</th>
                    <th colspan="2">ASWO_PENDING</th>
                    <th colspan="2">SCWELDC_PENDING</th>
                    <th colspan="1">MPDO/MC_PENDING</th>

                    <th colspan="2">WEA/WWDS_PENDING</th>
                    <th colspan="2">ASWO_PENDING</th>
                    <th colspan="2">SCWELDC_PENDING</th>
                    <th colspan="1">MPDO/MC_PENDING</th>

                    <th colspan="2">WEA/WWDS_PENDING</th>
                    <th colspan="2">DO_PENDING</th>
                    <th colspan="2">DC_PENDING</th>
                    <th colspan="1">MPDO/MC_PENDING</th>
                </tr>
            </thead>
            <tbody *ngIf="items && items?.length > 0">
                <ng-container>
                    <tr *ngFor="let item of items; let i = index;">
                        <td>{{i + 1}}</td>
                        <td>{{item.districT_NAME}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'SECRETARIAT WISE'">{{item.mandaL_NAME}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'SECRETARIAT WISE'">{{item.rurbaN_FLAG}}</td>
                        <td *ngIf= "format == 'SECRETARIAT WISE'">{{item.secretariaT_CODE}}</td>
                        <td *ngIf= "format == 'SECRETARIAT WISE'">{{item.secretariaT_NAME}}</td>
                        <td>{{item.toT_FISH}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_FISH_APP}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_FISH_REJ}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_FISH_PEND}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report" (click)="getpensionreportDataStatus('Approved', modalpopupstatus,'APPROVED','Fishermen Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">
                            <a>{{item.toT_FISH_APP}}</a>
                        </td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report-reject" (click)="getpensionreportDataStatus('Rejected',modalpopupstatus,'REJECTED','Fishermen Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">
                            <a>{{item.toT_FISH_REJ}}</a> 
                        </td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report-pending" (click)="getpensionreportDataStatus('Pending',modalpopupstatus,'PENDING','Fishermen Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">
                            <a>{{item.toT_FISH_PEND}}</a>
                        </td>
                        <td colspan="2">{{item.fisH_PEND_WEA}}</td>
                        <td colspan="2">{{item.fisH_PEND_FDO}}</td>
                        <td colspan="2">{{item.fisH_PEND_FDC}}</td>
                        <td colspan="1">{{item.fisH_PEND_MPDO}}</td>
                        <td>{{item.toT_DAPPU}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_DAPPU_APP}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_DAPPU_REJ}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_DAPPU_PEND}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report" (click)="getpensionreportDataStatus('Approved',modalpopupstatus, 'APPROVED','Dappu Artists Pension',$event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">{{item.toT_DAPPU_APP}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report-reject" (click)="getpensionreportDataStatus('Rejected', modalpopupstatus, 'REJECTED','Dappu Artists Pension',$event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">{{item.toT_DAPPU_REJ}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report-pending" (click)="getpensionreportDataStatus('Pending',modalpopupstatus, 'PENDING','Dappu Artists Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">{{item.toT_DAPPU_PEND}}</td>
                        <td colspan="2">{{item.dappU_PEND_WEA}}</td>
                        <td colspan="2">{{item.dappU_PEND_ASWO}}</td>
                        <td colspan="2">{{item.dappU_PEND_SCWDC}}</td>
                        <td colspan="1">{{item.dappU_PEND_MPDO}}</td>
                        <td>{{item.toT_Cobblers}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_Cobblers_APP}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_Cobblers_REJ}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_Cobblers_PEND}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report" (click)="getpensionreportDataStatus('Approved', modalpopupstatus, 'APPROVED','Traditional Cobblers Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">{{item.toT_Cobblers_APP}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report-reject" (click)="getpensionreportDataStatus('Rejected', modalpopupstatus, 'REJECTED','Traditional Cobblers Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">{{item.toT_Cobblers_REJ}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report-pending" (click)="getpensionreportDataStatus('Pending', modalpopupstatus, 'PENDING','Traditional Cobblers Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">{{item.toT_Cobblers_PEND}}</td>
                        <td colspan="2">{{item.cobblers_PEND_WEA}}</td>
                        <td colspan="2">{{item.cobblers_PEND_ASWO}}</td>
                        <td colspan="2">{{item.cobblers_PEND_SCWDC}}</td>
                        <td colspan="1">{{item.cobblers_PEND_MPDO}}</td>
                        <td>{{item.toT_Weaver}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_Weaver_APP}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_Weaver_REJ}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'DISTRICT WISE'">{{item.toT_Weaver_PEND}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report" (click)="getpensionreportDataStatus('Approved', modalpopupstatus, 'APPROVED','Weavers Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">{{item.toT_Weaver_APP}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report-reject" (click)="getpensionreportDataStatus('Rejected', modalpopupstatus, 'REJECTED','Weavers Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG)">{{item.toT_Weaver_REJ}}</td>
                        <td *ngIf="format == 'SECRETARIAT WISE'" class="sub-report-pending" (click)="getpensionreportDataStatus('Pending',modalpopupstatus, 'PENDING','Weavers Pension', $event, item.districT_CODE, item.mandaL_CODE, item.secretariaT_CODE, item.rurbaN_FLAG )">{{item.toT_Weaver_PEND}}</td>
                        <td colspan="2">{{item.weaver_PEND_WEA}}</td>
                        <td colspan="2">{{item.weaver_PEND_HDO}}</td>
                        <td colspan="2">{{item.weaver_PEND_HDC}}</td>
                        <td colspan="1">{{item.weaver_PEND_MPDO}}</td>
                    </tr>
                    <tr *ngIf="totalItems && totalItems?.length > 0">
                        <td></td>
                        <td>{{totalItems[0].districT_NAME}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'SECRETARIAT WISE'">{{totalItems[0].mandaL_NAME}}</td>
                        <td *ngIf="format == 'MANDAL WISE' || format == 'SECRETARIAT WISE'">{{totalItems[0].rurbaN_FLAG}}</td>
                        <td *ngIf= "format == 'SECRETARIAT WISE'">{{totalItems[0].secretariaT_CODE}}</td>
                        <td *ngIf= "format == 'SECRETARIAT WISE'">{{totalItems[0].secretariaT_NAME}}</td>
                        <td>{{totalItems[0].toT_FISH}}</td>
                        <td>{{totalItems[0].toT_FISH_APP}}</td>
                        <td>{{totalItems[0].toT_FISH_REJ}}</td>
                        <td>{{totalItems[0].toT_FISH_PEND}}</td>
                        <td colspan="2">{{totalItems[0].fisH_PEND_WEA}}</td>
                        <td colspan="2">{{totalItems[0].fisH_PEND_FDO}}</td>
                        <td colspan="2">{{totalItems[0].fisH_PEND_FDC}}</td>
                        <td colspan="1">{{totalItems[0].fisH_PEND_MPDO}}</td>
                        <td>{{totalItems[0].toT_DAPPU}}</td>
                        <td>{{totalItems[0].toT_DAPPU_APP}}</td>
                        <td>{{totalItems[0].toT_DAPPU_REJ}}</td>
                        <td>{{totalItems[0].toT_DAPPU_PEND}}</td>
                        <td colspan="2">{{totalItems[0].dappU_PEND_WEA}}</td>
                        <td colspan="2">{{totalItems[0].dappU_PEND_ASWO}}</td>
                        <td colspan="2">{{totalItems[0].dappU_PEND_SCWDC}}</td>
                        <td colspan="1">{{totalItems[0].dappU_PEND_MPDO}}</td>
                        <td>{{totalItems[0].toT_Cobblers}}</td>
                        <td>{{totalItems[0].toT_Cobblers_APP}}</td>
                        <td>{{totalItems[0].toT_Cobblers_REJ}}</td>
                        <td>{{totalItems[0].toT_Cobblers_PEND}}</td>
                        <td colspan="2">{{totalItems[0].cobblers_PEND_WEA}}</td>
                        <td colspan="2">{{totalItems[0].cobblers_PEND_ASWO}}</td>
                        <td colspan="2">{{totalItems[0].cobblers_PEND_SCWDC}}</td>
                        <td colspan="1">{{totalItems[0].cobblers_PEND_MPDO}}</td>
                        <td>{{totalItems[0].toT_Weaver}}</td>
                        <td>{{totalItems[0].toT_Weaver_APP}}</td>
                        <td>{{totalItems[0].toT_Weaver_REJ}}</td>
                        <td>{{totalItems[0].toT_Weaver_PEND}}</td>
                        <td colspan="2">{{totalItems[0].weaver_PEND_WEA}}</td>
                        <td colspan="2">{{totalItems[0].weaver_PEND_HDO}}</td>
                        <td colspan="2">{{totalItems[0].weaver_PEND_HDC}}</td>
                        <td colspan="1">{{totalItems[0].weaver_PEND_MPDO}}</td>
                    </tr>
                </ng-container>
            </tbody> 
            <ng-container>
                <tbody *ngIf="items?.length == 0">
                    <tr>
                        <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                    </tr>
                </tbody>
            </ng-container>
        </table>
    </div>

    <div class="mt-4 btnHide" *ngIf="showReports">
        <div class="float-left" *ngIf="items?.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items?.length > 0" [collectionSize]="items?.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
</div>
<ng-template let-modal #modalpopupstatus>
  <div class="modal-header" >
    <h6 class="modal-title">{{statusformat}} Records</h6>
    <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
     </button>
  </div>
  <div class="modal-body">
    <ng-container class="table-responsive tableFixHead">
        <table class="tableCls">
            <thead>
                <th>SL.NO</th>
                <th>DISTRICT NAME</th>
                <th>MANDAL NAME</th>
                <th>RURAL / URBAN</th>
                <th>SECRETARIAT CODE</th>
                <th>SECRETARIAT NAME</th>
                <th>APPLICATION NUMBER</th>
                <th>APPLICATION TYPE</th>
                <th>APPLICATION CREATED</th>
                <th>APPLICATION STATUS</th>
                 <th *ngIf="formattypestatus == 'APPROVED'">APPROVED DATE</th> 
                 <th *ngIf="formattypestatus == 'PENDING'">PENDING WITH DESIGNATION </th> 
                 <th *ngIf="formattypestatus == 'REJECTED'">REJECTED DATE</th> 
            </thead>
        <tbody *ngIf="resultitemsforstatus && resultitemsforstatus?.length > 0">
            <tr *ngFor="let item of resultitemsforstatus | slice: (popupPage-1) * popupPageSize : popupPage * popupPageSize; let i = index;">
            <td>{{((popupPage -1) * popupPageSize) + (i + 1)}}</td>
            <td>{{item.districT_NAME}}</td>
            <td>{{item.mandaL_NAME}}</td>
            <td>{{item.rurbaN_FLAG}}</td>
            <td>{{item.secretariaT_CODE}}</td>
            <td>{{item.secretariaT_NAME}}</td>
            <td>{{item.applicatioN_NO}}</td>
            <td>{{item.servicename}}</td>
            <td>{{item.createD_DATE}}</td>
            <td>{{item.apP_STATUS}}</td>
            <td *ngIf="formattypestatus == 'APPROVED' || formattypestatus == 'REJECTED'">{{item.latesT_ACTION_DATE}}</td>
            <td *ngIf="formattypestatus == 'PENDING'">{{item.pendinG_AT_DESIGNATION}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="resultitemsforstatus?.length == 0">
            <tr>
                <td colspan="20" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    </ng-container>
    <div class="mt-4 btnHide" *ngIf="showReports">
        <div class="float-left" *ngIf="resultitemsforstatus.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="popupPageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="resultitemsforstatus.length > 0" [collectionSize]="resultitemsforstatus.length" [(page)]="popupPage" [pageSize]="popupPageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
  </div>
  <div>
    <div class="modal-footer">
        <button  name="close" class="greenBtnCls" (click)="downloadExcelForstatus()" *ngIf="resultitemsforstatus && resultitemsforstatus?.length > 0 && showReports">Download Excel</button>
      <button type="button" class="greenBtnCls" (click)="modal.dismiss()">Ok</button>
    </div>
  </div>
</ng-template>

<div class="table-responsive tableFixHead" #BEYONDTABLEFORSTATUS class="hideTable">
        <table class="tableCls">
            <thead>
                <tr>
                <th>SL.NO</th>
                <th>DISTRICT NAME</th>
                <th>MANDAL NAME</th>
                <th>RURAL / URBAN</th>
                <th>SECRETARIAT CODE</th>
                <th>SECRETARIAT NAME</th>
                <th>APPLICATION NUMBER</th>
                <th>APPLICATION TYPE</th>
                <th>APPLICATION CREATED</th>
                <th>APPLICATION STATUS</th>
                 <th *ngIf="formattypestatus == 'APPROVED'">APPROVED DATE</th> 
                 <th *ngIf="formattypestatus == 'PENDING'">PENDING WITH DESIGNATION </th> 
                 <th *ngIf="formattypestatus == 'REJECTED'">REJECTED DATE</th> 
                </tr>
            </thead>
        <tbody *ngIf="resultitemsforstatus && resultitemsforstatus?.length > 0">
            <tr *ngFor="let item of resultitemsforstatus; let i = index;">
            <td>{{i+1}}</td>
            <td>{{item.districT_NAME}}</td>
            <td>{{item.mandaL_NAME}}</td>
            <td>{{item.rurbaN_FLAG}}</td>
            <td>{{item.secretariaT_CODE}}</td>
            <td>{{item.secretariaT_NAME}}</td>
            <td>{{item.applicatioN_NO}}</td>
            <td>{{item.servicename}}</td>
            <td>{{item.createD_DATE}}</td>
            <td>{{item.apP_STATUS}}</td>
            <td *ngIf="formattypestatus == 'APPROVED' || formattypestatus == 'REJECTED'">{{item.latesT_ACTION_DATE}}</td>
            <td *ngIf="formattypestatus == 'PENDING'">{{item.pendinG_AT_DESIGNATION}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="resultitemsforstatus?.length == 0">
            <tr>
                <td colspan="20" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
</div>