<div class="certificateInfo">
    <div class="subHeadingCls mb-3">Application Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Department:</label>
                <div class="valueCls">{{getDepartment(certificateInfo?.department)}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Service Name:</label>
                <div class="valueCls">{{serviceName}}</div>
            </div>
         </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Application Number:</label>
                <div class="valueCls">{{certificateInfo?.applicationNo }}</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.department != 'HF'">
                <label class="labelCls">Number Of Copies:</label>
                <div class="valueCls">{{certificateInfo?.noOfCopies}}</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.department == 'HF'">
                <label class="labelCls">UHID:</label>
                <div class="valueCls">{{certificateInfo?.uhid}}</div>
            </div>
        </div>
     
    </div>
</div>