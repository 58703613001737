import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/shared/common.service';
import { CommonConstants } from 'src/constants/common-constants.component';
@Component({
  selector: 'app-childname-inclusion-view',
  templateUrl: './childname-inclusion-view.component.html',
  styleUrls: ['./childname-inclusion-view.component.css']
})
export class ChildnameInclusionViewComponent implements OnInit {

  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  commonConstants : any = CommonConstants;
  
  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }


}
