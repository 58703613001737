import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { post } from 'jquery';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';
import { CommonService, CustomAdapter, CustomDateParserFormatter, WhitespaceValidator } from 'src/shared/common.service';
import { PdfViewerPopupComponent } from 'src/shared/pdf-viewer-popup/pdf-viewer-popup.component';
import { fileExtensionValidator, fileSizeValidator } from 'src/shared/validators/file-validator';

@Component({
  selector: 'app-volunteer-appreciation-program-details',
  templateUrl: './volunteer-appreciation-program-details.component.html',
  styleUrls: ['./volunteer-appreciation-program-details.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]
})
export class VolunteerAppreciationProgramDetailsComponent implements OnInit {
  isDataExist: Boolean = false;
  fileList: { FileName: string, DocumentType: string, BlobRef: string }[] = [];
  form: FormGroup;
  commonConstants: any = CommonConstants;
  maxDate: any;
  minDate: any;
  currentDate: any= new Date();
  submitted: Boolean = false;
  @ViewChild('programPhoto1Label')
  programPhoto1Label!: ElementRef;
  @ViewChild('programPhoto2Label')
  programPhoto2Label!: ElementRef;
  @ViewChild('programPhoto3Label')
  programPhoto3Label!: ElementRef;
  programDetails: any = [];
  userData: any;
  locationDetails: any;
  blobRefPhoto1: any ='';
  blobRefPhoto2: any = '';
  blobRefPhoto3: any = '';
  constructor(private formBuilder: FormBuilder, private commonService: CommonService, private router: Router, private modalService: NgbModal) {
    this.form = this.formBuilder.group({
      programConductedDate: [null,[Validators.required]],
      totalVolunteers:[null,[Validators.required, Validators.min(1)]],
      attendedVolunteers:[null,[Validators.required, Validators.min(1)]],
      attendedSecretariatStaff: [null,[Validators.required, Validators.min(1)]],
      inchargeMLAAttended:[null,[Validators.required]],
      nameOfVIPGuest:[null,[Validators.required,Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      designationOfVIPGuest:[null,[Validators.required,Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      programPhoto1: [null, [Validators.required]],
      programPhoto2: [null, [Validators.required]],
      programPhoto3: [null, [Validators.required]]
    });
    this.maxDate = {
      year: 2024,
      month: 2,
      day: 29
    };
    this.minDate = {
      year: 2024,
      month: 2,
      day: 16
    };
    let userInfo = localStorage.getItem('userInfo');
    if (userInfo && userInfo !== null) {
      this.userData = JSON.parse(userInfo);
    }
    this.getDetails();
    this.getVolunteerProgramDetails();
   }

  ngOnInit(): void {
  }
  get f() {
    return this.form.controls;
  }
  onPhotoSelected(fileEvent: any, name: any, label:any) {
    const file: File | null = fileEvent.target.files[0];
    const fileExtensionType = fileEvent.target.files[0]?.name.split('.').pop();
    var validExt = "";
   
    if(fileExtensionType.toLowerCase() === 'jpg'){
      validExt = "jpg"
    } else if(fileExtensionType.toLowerCase() === 'jpeg') {
      validExt = "jpeg"
    } else if(fileExtensionType.toLowerCase() === 'png'){
      validExt = "png"
    } else {
      validExt = "others"
    }
    console.log(validExt,'validExt')
    if(label === 'programPhoto1Label')
      this.programPhoto1Label.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
    else if(label === 'programPhoto2Label')
      this.programPhoto2Label.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
    else if(label === 'programPhoto3Label')
      this.programPhoto3Label.nativeElement.innerText = fileEvent.target.files.length !== 0 ? fileEvent.target.files[0] ?.name : 'No File Choosen';
    this.f[name].setValidators([Validators.required, fileExtensionValidator(validExt.toLowerCase()), fileSizeValidator(fileEvent)]);
    this.f[name].updateValueAndValidity();
    if (this.f[name].valid){ 
      this.fileUpload(file, this.f[name].value, fileEvent.target.files[0].name, name, name, label);
    }
  }
  fileUpload(file: any, filePath: string, fileName: string, documentType: string, name: string = '', label: string = '') {
    const formData = new FormData();
    formData.append('File', file, fileName);
    formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
    console.log(formData, 'formData');
    this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload, formData).subscribe({
      next: (responseData: any) => {
        if(typeof(responseData.result) !== 'string'){
          let index = this.fileList.findIndex((item) => item.DocumentType === documentType);
          if (index > -1) {
            this.fileList.splice(index, 1);
          }
          this.fileList.push({
            FileName: fileName,
            DocumentType: documentType,
            BlobRef: responseData['result'].blobRef
          })
          if(documentType === 'programPhoto1') {
            this.blobRefPhoto1 = responseData['result'].blobRef;
          } else if(documentType === 'programPhoto2') {
            this.blobRefPhoto2 = responseData['result'].blobRef;
          } else if(documentType === 'programPhoto3') {
            this.blobRefPhoto3 = responseData['result'].blobRef;
          }
          console.log(documentType , 'documentType')
        
        }else{
          this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
          if(name && label) {
            this.clearFileUpload(name, label);
          }
        }
      },
      error: (error) => {
        if(name && label) {
          this.clearFileUpload(name, label);
        }
        console.log('client side', error);
      }
    });
  }
  clearFileUpload(name: string, label: string) {
    this.f[name].reset();
    if (label === 'programPhoto1Label') {
      this.programPhoto1Label.nativeElement.innerText = 'No File Choosen';
    } else if (label === 'programPhoto2Label') {
      this.programPhoto2Label.nativeElement.innerText = 'No File Choosen';
    } else if (label === 'programPhoto3Label') {
      this.programPhoto3Label.nativeElement.innerText = 'No File Choosen';
    } 
  }
  goToHome() {
    this.router.navigate(['/home']);
  }
  getDetails() {
    let locationCode: any = localStorage.getItem('locationCode');
    this.commonService.getRequest(this.commonConstants.getDistrictSecretariatDetailsbyMandal + locationCode).subscribe({
      next: (responseData: any) => {
        this.locationDetails = responseData.result;
       },
      error: (error) => {
        this.locationDetails = [];
        console.log('client side', error);
      },
    });
  }
  submit(): any {
    this.submitted = true;
    if(!this.form.valid)
      return false;
    else if(this.blobRefPhoto1 === '' || this.blobRefPhoto2 === '' || this.blobRefPhoto3  === '') {
      this.commonService.commonErrorPopup('Alert', 'Photo uploads are not saved properly. Please try again.', 'md');
      return false;
    } else if(Number(this.f.attendedVolunteers.value) > Number(this.f.totalVolunteers.value)) {
      this.commonService.commonErrorPopup('Alert', 'No. of attended volunteers shouldn\'t be greater than no. of total volunteers.', 'md');
      return false;
    } else {
      let postData: any = {};
      postData.userId = this.userData.userPrincipalName;
      postData.districtCode = this.locationDetails?.neW_LGD_DIST_CODE?.toString();
      postData.mandalCode = this.locationDetails?.lgD_MANDAL_CODE?.toString();
      postData.programDate = this.commonService.formatDate(this.f.programConductedDate.value);
      postData.volunteerWorkingNo = Number(this.f.totalVolunteers.value);
      postData.volunteerAttendedNo = Number(this.f.attendedVolunteers.value);
      postData.secStaffAttendedNo = Number(this.f.attendedSecretariatStaff.value);
      postData.isMLAInchargeAttnd = this.f.inchargeMLAAttended.value;
      postData.vipName = this.f.nameOfVIPGuest.value;
      postData.vipDesignation = this.f.designationOfVIPGuest.value;
      postData.photo1 = this.blobRefPhoto1;
      postData.photo2 = this.blobRefPhoto2;
      postData.photo3 = this.blobRefPhoto3;
   
      this.commonService.postRequest(this.commonConstants.saveVolunteerAppreciationProgramDetails, postData).subscribe({
        next: (responseData: any) => {
          if(responseData.result?.isSuccess) {
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message =  'Program details are saved successfully.';
            modalRef.result.then((result: any) => {
              if (result) {
                this.form.reset();
                this.submitted = false;
                this.getVolunteerProgramDetails();
                this.blobRefPhoto1 = '';
                this.blobRefPhoto2 = '';
                this.blobRefPhoto3 = '';
                this.fileList = [];
              }
            });
          } else {
            this.commonService.commonErrorPopup('Alert', 'An error occured while saving the data. Please try again.', 'md')
          }
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
    }
  }
  viewImgFile(blobRef: any) {
    this.commonService
      .getRequest(this.commonConstants.viewFile + blobRef)
      .subscribe({
        next: (responseData: any) => {
          let fileInfo = responseData.result.fileContents;
           const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
           modalRef.componentInstance.isImage = true;
          modalRef.componentInstance.sourceFile = 'data:image/jpeg;base64,' +fileInfo;
        },
        error: (error) => {
          console.log('client side', error);
        },
      });
  }
  getVolunteerProgramDetails() {
    this.programDetails = [];
  this.commonService.getRequest(this.commonConstants.getVolunteerAppreciationProgramDetails + this.userData.userPrincipalName).subscribe({
    next: (responseData: any) => {
      if(responseData?.result?.isSuccess) {
        this.isDataExist = true;
        this.programDetails.push(responseData?.result?.volunteerAppreciationProgramData);
        console.log(this.programDetails);
      } else {
        this.isDataExist = false;
        this.programDetails = [];
        this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md')
      }
    },
    error: (error) => {
      this.isDataExist = false;
      this.programDetails = [];
      console.log('client side', error);
    },
  });
  }
}
