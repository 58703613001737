<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
      <div class="breadcrumbCls">
        GSWS / Application Submission <span class="activePage">/ Sadarem Print Certificate</span>
      </div>
    </div>
    <div class="mainPageContainer mr-3">
      <h6>Sadarem Print Certificate</h6>
      <form [formGroup]="sadaremForm">
        <nav ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)">
          <ng-container [ngbNavItem] = "1">
            <a ngbNavLink>Basic Details</a>
            <ng-template ngbNavContent>
              <app-common-form formControlName="commonForm" [isSubmitted]="commonFormSubmitted" [postalRequired] = 'true' (reset)="commonFormSubmitted = false"></app-common-form>
              <div class="btnHolder">
                <button  class="greenBtnCls float-right" (click)="goToNextTab()">Continue</button>
              </div>
            </ng-template>
          </ng-container>
          <ng-container [ngbNavItem] = "2">
            <a ngbNavLink>Application Form</a>
                <ng-template ngbNavContent>
                    <div class="row mb-3">
                        <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                            <label class="labelCls mt-2">Sadarem Id: <span class="mandatoryCls">*</span></label>
                        </div>
                        <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                            <input type="text" name="sadaremID" formControlName="sadaremID" class="inputCls w-100" appBlockCopyPaste required numbersOnly
                            [ngClass]="{ 'is-invalid':submitted && f.sadaremID.errors }">
                            <div *ngIf="submitted && f.sadaremID.errors" class="invalid-feedback">
                              <div *ngIf="f.sadaremID.errors.required">Sadarem Id is required</div>
                              <div *ngIf="f.sadaremID.errors.minlength">Minimum number of digits for sadarem id is 17.</div>
                              <div *ngIf="f.sadaremID.errors.maxlength">Maximum number of digits for sadarem id is 17.</div>
                          </div>
                        </div>
                        <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12" *ngIf="showgetdetails">
                            <div class="btnHolder">
                                <button class="greenBtnCls float-right" (click)="getSadaremDetails()">Get Details</button>
                            </div>
                        </div>
                        <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12" *ngIf="reset">
                            <div class="btnHolder">
                                <button class="greenBtnCls float-right" (click)="resetsadaremid()">Reset</button>
                            </div>
                        </div>
                </div>
                <ng-container *ngIf="showData">
                    <div class="row mb-3">
                        <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                            <label class="labelCls">Sadarem Code:</label>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <span>{{sadaremdetailsfromapi.Sadaremcode}}</span>
                        </div>
                   
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                    <label class="labelCls">Status:</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <span>{{sadaremdetailsfromapi.Status}}</span>
                </div>
            </div>
        <div class="row mb-3">
            <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <label class="labelCls">Aadhar Number:</label>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <span>{{sadaremdetailsfromapi.AadharNumber}}</span>
            </div>
            <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <label class="labelCls">Date Of Birth:</label>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <span>{{sadaremdetailsfromapi.DateOfBirth}}</span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <label class="labelCls">Person Name:</label>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <span>{{sadaremdetailsfromapi.PERSONNAME}}</span>
            </div>
       
            <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <label class="labelCls">Date Of Issue:</label>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <span>{{sadaremdetailsfromapi.DateOfIssue}}</span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <label class="labelCls">Percentage:</label>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <span>{{sadaremdetailsfromapi.percentage}}</span>
            </div>
        
            <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <label class="labelCls">Address:</label>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <span>{{sadaremdetailsfromapi.Address}}</span>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <label class="labelCls">Gender:</label>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <span>{{sadaremdetailsfromapi.gender}}</span>
            </div>
            <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <label class="labelCls">Type Of Disability:</label>
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <span>{{sadaremdetailsfromapi.TYPEOFDISABILITY}}</span>
            </div>
        </div>

        <hr class="hrCls mt-4">
        <div class="subHeadingCls mb-3">Declaration</div>
        <div class="row mb-3">
            <div class="ml-4 col-sm-9">
                <input class="form-check-input " formControlName="familyForm" required
                    type="checkbox" value="" id="familyForm"
                    [ngClass]="{ 'is-invalid':submitted && f.familyForm.errors }">
                <label class="labelCls" for="familyForm">The above information is true to the best
                    of my knowledge. I agree to share my Aadhaar details of self and family with
                    Government of Andhra Pradesh. I am aware that declaration of wrong information
                    will entail any disciplinary action against me.:<span
                        class="mandatoryCls">*</span> </label>
                <div *ngIf="submitted && f.familyForm.errors" class="invalid-feedback">
                    <div *ngIf="f.familyForm.errors.required">Please Check Note</div>
                </div>
            </div>
        </div>

        
        <div class="btnHolder text-right">
            <button class="greenBtnCls" (click)="submit()">Show Payment</button>
        </div>
                </ng-container>
            </ng-template>
          </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav"></div>
      </form>
    </div>
  </div>
  