import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonFormComponent } from '../shared/common-form/common-form.component';
import { NumberOnlyInputDirective } from './number-only-input.directive';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerPopupComponent } from './pdf-viewer-popup/pdf-viewer-popup.component';
import { CommonMsgModalComponent } from './common-msg-modal/common-msg-modal.component';
import { CommonFormViewComponent } from './common-form-view/common-form-view.component';
import { BlockCopyPasteDirective } from './block-copy-paste.directive';
import { BasicDetailsPopupComponent } from './basic-details-popup/basic-details-popup.component';
import { HighlightSearchPipe, SearchPipe,ByPassSecurityPipe } from '../shared/pipes/highlight-search.pipe';
import { AccountDetailsPopupComponent } from './account-details-popup/account-details-popup.component';
import { SuccessorAadharPopupComponent } from './successor-aadhar-popup/successor-aadhar-popup.component';

import { StringifyJsonPipe } from '../shared/pipes/stringify-json.pipe';
import { PoliciesPopupComponent } from './policies-popup/policies-popup.component';
import { AadharAuthenticationComponent } from './aadhar-authentication/aadhar-authentication.component';
import { CaptchaComponent } from './captcha-viewer-popup/captcha/captcha.component';
import { LabourRegViewModule } from 'src/app/labour-reg-view/labour-reg-view.module';
import { LabourRegistrationViewComponent } from 'src/app/labour-reg-view/labour-registration-view/labour-registration-view.component';
import { CommonMsgModalForDeleterecordComponent } from './common-msg-modal-for-deleterecord/common-msg-modal-for-deleterecord.component';
import { RadioMsgModalComponentComponent } from './radio-msg-modal-component/radio-msg-modal-component.component';
import { CommonUploadFileComponentComponent } from './common-upload-file-component/common-upload-file-component.component';

@NgModule({
  declarations: [
    CommonFormComponent,
    NumberOnlyInputDirective,
    BlockCopyPasteDirective,
    PdfViewerPopupComponent,
    CommonMsgModalComponent,
    CommonFormViewComponent,
    BasicDetailsPopupComponent,
    HighlightSearchPipe,
    StringifyJsonPipe,
    SearchPipe,
    ByPassSecurityPipe,
    AccountDetailsPopupComponent,
    SuccessorAadharPopupComponent,
    PoliciesPopupComponent,
    AadharAuthenticationComponent,
    CaptchaComponent,
    CommonMsgModalForDeleterecordComponent,
    RadioMsgModalComponentComponent,
    CommonUploadFileComponentComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgxExtendedPdfViewerModule,
    LabourRegViewModule
  ],
  exports: [
    CommonModule,
    NumberOnlyInputDirective,
    BlockCopyPasteDirective,
    ReactiveFormsModule,
    CommonFormComponent,
    PdfViewerPopupComponent,
    NgxExtendedPdfViewerModule,
    CommonMsgModalComponent,
    CommonFormViewComponent,
    HighlightSearchPipe,
    StringifyJsonPipe,
    SearchPipe,
    ByPassSecurityPipe,
    AadharAuthenticationComponent,
    CaptchaComponent,
    LabourRegistrationViewComponent
 ]
})
export class SharedModule { }
