<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ?
                    (basicInfo?.applicantInfo?.rationCardNo) : '-'}}</div>
            </div>
        </div>
        
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <ng-container *ngIf="certificateInfo.birthDeathDetails?.placeOfBirth !== ''">
        <div class="subHeadingCls mb-3">Service Details</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Service Type:</label>
                    <div class="valueCls">{{certificateInfo?.certificateType ?
                        certificateInfo?.certificateType : '-'}}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Panchayat:</label>
                    <div class="valueCls">{{certificateInfo?.panchayatVillageName ?  certificateInfo?.panchayatVillageName : '-' 
                       }}</div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <ng-container *ngIf="certificateInfo.birthDeathDetails?.placeOfBirth !== ''">
        <div class="subHeadingCls mb-3">Birth Details</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Child Name:</label>
                    <div class="valueCls">{{certificateInfo.birthDeathDetails?.childName}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Child Date Of Birth:</label>
                    <div class="valueCls">{{certificateInfo.incidenceDate | date: 'dd/MM/yyyy'}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Place Of Birth:</label>
                    <div class="valueCls">{{certificateInfo.birthDeathDetails?.placeOfBirth}}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">No. Of Delivery:</label>
                    <div class="valueCls">{{certificateInfo.birthDeathDetails?.noOfDelivery}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Age of Mother:</label>
                    <div class="valueCls">{{certificateInfo.birthDeathDetails?.ageOfMother}}</div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="certificateInfo.birthDeathDetails?.placeOfDeath !== ''">
        <div class="subHeadingCls mb-3">Death Details</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Deceased Person's Name:</label>
                    <div class="valueCls">{{certificateInfo.birthDeathDetails?.deceasedName}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Date Of Death:</label>
                    <div class="valueCls">{{certificateInfo.incidenceDate | date: 'dd/MM/yyyy'}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Place Of Death:</label>
                    <div class="valueCls">{{certificateInfo.birthDeathDetails?.placeOfDeath}}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Cause Of Death:</label>
                    <div class="valueCls">{{certificateInfo.birthDeathDetails?.causeOfDeath}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Deceased Person Age:</label>
                    <div class="valueCls">{{certificateInfo.birthDeathDetails?.deceasedAge}}</div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Service Specific Details( Please specify the details of Birth/Death happened)</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Door No:</label>
                <div class="valueCls">{{certificateInfo.incidenceAddress?.doorNo}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Address:</label>
                <div class="valueCls">{{certificateInfo.incidenceAddress?.addressType}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo.incidenceAddress?.districtName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Village/Ward:</label>
                <div class="valueCls">{{certificateInfo.incidenceAddress?.villageWardName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Pin Code:</label>
                <div class="valueCls">{{certificateInfo.incidenceAddress?.pinCode}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Gender:</label>
                <div class="valueCls">{{certificateInfo.birthDeathDetails?.gender}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Mother Name:</label>
                <div class="valueCls">{{certificateInfo.motherName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Father's Name/Husband's Name:</label>
                <div class="valueCls">{{certificateInfo.personName?.fatherHusbandName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal/Circle:</label>
                <div class="valueCls">{{certificateInfo.incidenceAddress?.mandalMunicipalityName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Panchayat:</label> 
                <div class="valueCls">{{certificateInfo?.incidentPanchayatVillageName ?  certificateInfo?.incidentPanchayatVillageName : '-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Purpose:</label>
                <div class="valueCls">{{certificateInfo.certificatePurpose}}</div>
            </div>
        </div>
    </div>
    
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of fileList">
                    <div *ngIf="file.documentType === 'Physical Document'">
                        <span class="numberCls">1</span><label class="labelCls">Physical Document:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                    <div *ngIf="file.documentType === 'Non availabilty certificate'">
                        <span class="numberCls">2</span><label class="labelCls">Non availability certificate issued by
                            the GP or Municipal Commissioner:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Ration Card Certificate'">
                        <span class="numberCls">3</span><label class="labelCls">Ration card copy:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Self Affidavit'">
                        <span class="numberCls">4</span><label class="labelCls">Self Affidavit:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'SSC marks memo'">
                        <span class="numberCls">5</span><label class="labelCls">SSC marks memo(Date of Birth Proof
                            Document):</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>



<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <ng-container>
                <div class="subHeadingCls mb-3">Checklist</div>
                <div class="fieldHolder">
                    <div>
                        <label class="labelCls">Checklist:</label>
                        <div class="valueCls" (click)="showCheckListData(showCheckList)"><a>View Checklist</a></div>
                    </div>

                </div>
            </ng-container>
        </div>
    </div>
</div>





<ng-template #showCheckList let-modal class="modalCls">

    <div class="modal-header clsPrintD">
        <h4 class="modal-title" id="modal-basic-title">CheckList </h4>
        <button class="btn-primary btn btnPrint" (click)="printChkList()">Print</button> <button type="button" class="close"
            aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>

    <div class="modal-body">


        <div class="container" id="_printingCheckList">
            <div class="row">
                <div class="col-lg-12">

                    <div class="ldbr_container">
                        <div class="row">
                            <div class="col-lg-12 text-right">
                              <!--   <img src="./barcode.png" class="img-fluid" alt=""> -->
                              <ngx-barcode  [bc-value]="reqId" [bc-line-color]="lineColor"
                              [bc-width]="width" [bc-height]="height" [bc-display-value]="displayValue" [bc-font-options]="fontOptions" [bc-font]="font"
                              [bc-text-align]="textAlign" [bc-text-position]="textPosition" [bc-text-margin]="textMargin" [bc-font-size]="fontSize" [bc-background]="background"
                              [bc-margin]="margin" [bc-margin-top]="marginTop" [bc-margin-bottom]="marginBottom" [bc-margin-left]="marginLeft" [bc-margin-right]="marginRight"></ngx-barcode>

                               <!--  <p class="reqIdCls"> {{ reqId }} </p>  -->
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">

                                <p>Procedure:(following to be enclosed)</p>
                                <h4>Birth/Death Upload Documents</h4>
                                <div>
                                    1) Physical Document <br>
                                    2) Non availability certificate issued by the GP or Municipal Commissioner<br>
                                    3) Ration card copy<br>
                                    4) SSC marks memop<br>
                                    5) Self Affidavit<br>
                                </div>

                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-12">
                                <p class="tblTitle">CHECK SLIP FOR LATE REGISTRATIONS OF BIRTH/DEATH
                                </p>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-lg-12">
                                <table class="table table-bordered table-responsive ldbrTbl text-center">

                                    <tr>
                                        <td class="sr_no">1</td>
                                        <td class="tbl_title headBlod">RDO/SEM
                                            (2 DAYS)</td>
                                        <td class="tbl_desc">Whenever the application received for the Late Registration
                                            through
                                            online,it should be forwarded to tahsildar concerned for verification</td>
                                        <td class="tbl_descEnd">
                                        </td>

                                    </tr>



                                    <tr>
                                        <td>2</td>
                                        <td class="headBlod">Tahsildar
                                            (3 DAYS)</td>
                                        <td> Download the application and send it for field enquiry.
                                        </td>
                                        <td></td>

                                    </tr>

                                    <tr  *ngIf="certificateInfo.birthDeathDetails?.placeOfBirth !== ''">
                                        <td rowspan="16" style="vertical-align: middle;">3</td>
                                        <th rowspan="16" style="vertical-align: middle;"> VRO/MRI/SURVEYOR </th>
                                        <td> Child Name</td>
                                        <td> {{certificateInfo.birthDeathDetails?.childName}} </td>
                                    </tr>

                                    <tr *ngIf="certificateInfo.birthDeathDetails?.placeOfDeath !== ''">
                                        <td rowspan="16" style="vertical-align: middle;">3</td>
                                        <th rowspan="16" style="vertical-align: middle;"> VRO/MRI/SURVEYOR </th>
                                        <td> Deceased Person's Name </td>
                                        <td>{{certificateInfo.birthDeathDetails?.deceasedName}} </td>
                                    </tr>

                                    <tr>
                                        <td>Father Name</td>
                                        <td> {{certificateInfo.personName?.fatherHusbandName}}</td>

                                    </tr>
                                    <tr>
                                        <td>Mother Name</td>
                                        <td> {{certificateInfo?.motherName}}</td>

                                    </tr>
                                    <tr>

                                        <td> DOB/DOD</td>
                                        <td> {{certificateInfo.incidenceDate | date: 'dd/MM/yyyy'}} </td>
                                    </tr>
                                    <tr>
                                        <td>Address</td>
                                        <td>
                                            {{certificateInfo.incidenceAddress?.doorNo}}
                                            {{certificateInfo.incidenceAddress?.addressType}}
                                            {{certificateInfo.incidenceAddress?.districtName}}
                                            {{certificateInfo.incidenceAddress?.villageWardName}}
                                            {{certificateInfo.incidenceAddress?.mandalMunicipalityName}}
                                           - {{certificateInfo.incidenceAddress?.pinCode}}

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Conduct of enquiry at field level</td>
                                        <td></td>

                                    </tr>
                                    <tr>
                                        <td>Whether verified the LateRegistration and record a statement from
                                            applicant/mediators</td>
                                        <td></td>

                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>

                                    <tr>
                                        <td>Recommendation of the VRO</td>
                                        <td></td>
                                    </tr>

                                    <tr>
                                        <td>Signature of the VRO
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Verification of report of the VRO</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Whether verified the records produced by the applicant</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Verification of revenue records</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Specific Recommendation of the MRI
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Signature of the MRI </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Whether prepared the combined sketch </td>
                                        <td></td>
                                    </tr>
                                   

                                    <!--  -->

                                    <tr>
                                        <td rowspan="4" style="vertical-align: middle;">4</td>
                                        <th rowspan="4" style="vertical-align: middle;">Tahsildar</th>

                                    </tr>
                                    <tr>
                                        <td>Verify reports of the RI/VRO/Surveyor and preparation of letter to
                                            Revenue Divisional Officer for approval
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Recommendation of the Tahsildar
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Signature of the Tahsildar</td>
                                        <td></td>
                                    </tr>


                                    <!--  -->

                                    <tr>
                                        <td rowspan="5" style="vertical-align: middle;">5</td>
                                        <th rowspan="5" style="vertical-align: middle;">RDO/SEM</th>

                                    </tr>
                                    <tr>
                                        <td>Verify the report of the Tahsildar by the Asst. concerned/A.O and put up
                                            to R.D.O.</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Whether the RDO/SEM has conducted the field inspection and Date.

                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Issue notices to the applicant to pay the required challan amount or
                                            shortfall amount,if the applicant already paid the amount.
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Issuance of proceedings and Signature of RDO/SEM
                                        </td>
                                        <td></td>
                                    </tr>


                                </table>

                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>


    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="printChkList()">Print</button>
        <button type="button" class="btn btn-danger" (click)="modal.close('Save click')">Close</button>
    </div>
</ng-template>