<div class="certificateInfo" *ngIf="certificateInfo">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Aadhar No: </label>
                <div class="valueCls">{{certificateInfo.applicantAadharNo}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Applicant Name: </label>
                <div class="valueCls">{{certificateInfo.applicantName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Relation With Pattadar:</label>
            <div class="valueCls">{{certificateInfo.relationWithOwner}}</div>
        </div>
        </div>
    </div>
    <div class="subHeadingCls mb-3">Land Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo.landDetails?.districtName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo.landDetails?.mandalMunicipalityName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Village:</label>
            <div class="valueCls">{{certificateInfo.landDetails?.villageWardName}}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Land Parcel Numbers:</label>
            <div class="valueCls">{{certificateInfo.landParcelNo}}</div>
        </div>
        </div>
    </div>
    <ng-container *ngIf="certificateInfo.isOtherState">
    <div class="subHeadingCls mb-3">Postal Address</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Door No & Street Name: :</label>
                <div class="valueCls">{{certificateInfo.postalLocation }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">State:</label>
                <div class="valueCls">{{certificateInfo.postalState}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">District:</label>
            <div class="valueCls">{{certificateInfo.postalDistrict }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Mandal:</label>
            <div class="valueCls">{{certificateInfo.postalMandal}}</div>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{certificateInfo.postalVillage }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Pin Code:</label>
                <div class="valueCls">{{certificateInfo.postalPincode }}</div>
            </div>
        </div>
    </div>
   </ng-container>
    <div class="subHeadingCls mb-3">Adangal Details</div>
    <div class="row mb-3"> <!---->
        <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <label class="labelCls">Do you need correction in Land Parcel Total Extent? : {{(certificateInfo?.isExtentCorrection) ? 'Yes' : 'No'}} </label>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="tab table-responsive">
                <table class="tableCls">
                    <tr>
                        <th colspan="4" class="text-left"><h6>LPM Number: {{certificateInfo?.lpNumberDetails?.landParcelNumber}}</h6></th>
                    </tr>
                    <tr>
                        <th>Column Numbers</th>
                        <th>Adangal Columns</th>
                        <th>Present Entry</th>
                        <th>Request Entry</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>Land Parcel Number</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.landParcelNumber}}</td>
                        <td>{{certificateInfo?.lpNumberDetails?.landParcelNumber}}</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Land Parcel Total Extent (Acrs-Cents)</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.landParcelTotalExtent}}</td>
                        <td>{{certificateInfo?.lpNumberDetails?.landParcelTotalExtent}}</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Unique Land Parcel Number</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.uniqueLandParcelNumber}}</td>
                        <td>{{certificateInfo?.lpNumberDetails?.uniqueLandParcelNumber}}</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Old Sy.No / Subdivision</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.oldSurveyOrSubDivisionNo}}</td>
                        <td>{{certificateInfo?.lpNumberDetails?.oldSurveyNoOrSubDivision}}</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Old Sy.No / Subdivision Total Extent (Acrs-Cents)</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.oldSurveyOrSubDivisionTotalExtent}}</td>
                        <td>{{certificateInfo?.lpNumberDetails?.oldSUrveyNoOrSubDivisionTotalExtent}}</td>
                    </tr>
                    <tr>
                        <td rowspan="4">6</td>
                        <td>Land Nature (Broad)</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.landNature}}</td>
                        <td>{{certificateInfo?.landNatureDetails?.broad}}</td>
                    </tr>
                    <tr>
                        <td>Land Nature (Sub Nature 1)</td>
                        <td>{{(certificateInfo?.adangalDetailsList[0]?.landSubNature1) ? (certificateInfo?.adangalDetailsList[0]?.landSubNature1) : '-'}}</td>
                        <td>{{(certificateInfo?.landNatureDetails?.subNature1) ? (certificateInfo?.landNatureDetails?.subNature1) : '-'}}</td>
                    </tr>
                    <tr>
                        <td>Land Nature (Sub Nature 2)</td>
                        <td>{{(certificateInfo?.adangalDetailsList[0]?.landSubNature2)? (certificateInfo?.adangalDetailsList[0]?.landSubNature2) : '-'}}</td>
                        <td>{{(certificateInfo?.landNatureDetails?.subNature2) ? (certificateInfo?.landNatureDetails?.subNature2) : '-'}}</td>
                    </tr>
                    <tr>
                        <td>Land Nature (Sub Nature 3)</td>
                        <td>{{(certificateInfo?.adangalDetailsList[0]?.landSubNature3)? (certificateInfo?.adangalDetailsList[0]?.landSubNature3) : '-'}}</td>
                        <td>{{(certificateInfo?.landNatureDetails?.subNature3) ? (certificateInfo?.landNatureDetails?.subNature3) : '-' }}</td>
                    </tr>
                    <tr>
                        <td rowspan="3">7</td>
                        <td>Land Classification (Broad)</td>
                        <td>{{(certificateInfo?.adangalDetailsList[0]?.broad)? (certificateInfo?.adangalDetailsList[0]?.broad) : '-'}}</td>
                        <td>{{(certificateInfo?.landClassificationDetails?.broad) ? (certificateInfo?.landClassificationDetails?.broad) : '-' }}</td>
                    </tr>
                    <tr>
                        <td>Land Classification (Sub Classification 1)</td>
                        <td>{{(certificateInfo?.adangalDetailsList[0]?.landSubClassification1)? (certificateInfo?.adangalDetailsList[0]?.landSubClassification1) : '-'}}</td>
                        <td>{{(certificateInfo?.landClassificationDetails?.subClassification1) ? (certificateInfo?.landClassificationDetails?.subClassification1) : '-' }}</td>
                    </tr>
                    <tr>
                        <td>Land Classification (Sub Classification 2)</td>
                        <td>{{(certificateInfo?.adangalDetailsList[0]?.landSubClassification2)? (certificateInfo?.adangalDetailsList[0]?.landSubClassification2) : '-'}}</td>
                        <td>{{(certificateInfo?.landClassificationDetails?.subClassification2) ? (certificateInfo?.landClassificationDetails?.subClassification2) : '-' }}</td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>Nature of Land Use</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.natureOfLandUse}}</td>
                        <td>{{certificateInfo?.pattadarDetails?.natureOfLandUse}}</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>Source of Irrigation</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.sourceOfIrrigation}}</td>
                        <td>{{certificateInfo?.pattadarDetails?.sourceOfIrrigation}}</td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Khatha Number</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.khathaNumber}}</td>
                        <td>{{certificateInfo?.pattadarDetails?.khathaDetails}}</td>
                    </tr>
                    <tr>
                        <td rowspan="4">11</td>
                        <td>Pattadar Name</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.pattadarName}}</td>
                        <td>{{certificateInfo?.pattadarDetails?.pattadarName}}</td>
                    </tr>
                    <tr>
                        <td>Pattadar Name (In English)</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.pattadarNameInEng}}</td>
                        <td>{{certificateInfo?.pattadarDetails?.pattadarNameInEng}}</td>
                    </tr>
                    <tr>
                        <td>Pattadar Father/Husband Name</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.pattadarRelationName}}</td>
                        <td>{{certificateInfo?.pattadarDetails?.pattadarFatherOrHusbandName}}</td>
                    </tr>
                    <tr>
                        <td>Pattadar Father/Husband Name (In English)</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.PattadarRelationNameInEng}}</td>
                        <td>{{certificateInfo?.pattadarDetails?.pattadarFatherOrHusbandNameInEng}}</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>Pattadar Enjoyment Extent (Acrs-Cents)</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.pattadarEnjoymentExtent}}</td>
                        <td>{{certificateInfo?.pattadarDetails?.enjoymentExtent}}</td>
                    </tr>

                    <tr>
                        <td>13</td>
                        <td>Nature of Enjoyment / Mode of Acquisition</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.enjoymentNatureOrModeOfAcquisition}}</td>
                        <td>{{certificateInfo?.landNatureDetails?.enjoymentNatureOrModeOfAcquisition}}</td>
                    </tr>
                    <tr>
                        <td rowspan="4">14</td>
                        <td>Occupant Name</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.occupantName}}</td>
                        <td>{{certificateInfo?.occupantDetails?.occupantName}}</td>
                    </tr>
                    <tr>
                        <td>Occupant Name (In English)</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.occupantNameInEng}}</td>
                        <td>{{certificateInfo?.occupantDetails?.occupantNameInEng}}</td>
                    </tr>
                    <tr>
                        <td>Occupant Father/Husband Name</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.occupantRelationName}}</td>
                        <td>{{certificateInfo?.occupantDetails?.occupantFatherOrHusbandName}}</td>
                    </tr>
                    <tr>
                        <td>Occupant Father/Husband Name (In English)</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.occupantRelationNameInEng}}</td>
                        <td>{{certificateInfo?.occupantDetails?.occupantFatherOrHusbandNameInEng}}</td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>Occupant Extent (Acrs-Cents)</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.occupantEnjoymentExtent}}</td>
                        <td>{{certificateInfo?.occupantDetails?.occupantExtent}}</td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>Pattadar Mobile Number</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.mobileNumber}}</td>
                        <td>{{basicInfo?.applicantInfo?.mobileNumber}}</td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>Pattadar Gender</td>
                        <td>{{certificateInfo?.adangalDetailsList[0]?.gender}}</td>
                        <td>{{basicInfo?.applicantInfo?.gender}}</td>
                    </tr>
                
            </table>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo mt-2 mb-3">
    <div class="row">
      <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <ng-container *ngIf="fileList && fileList?.length !== 0">
          <div class="subHeadingCls mb-3">List of Documents</div>
          <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
            <div *ngIf="file.documentType === 'Tax Receipt'">
              <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Tax Receipts if any:</label>
              <div class="valueCls">
                <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                  class="download ml-2"></span>
              </div>
            </div>
            <div *ngIf="file.documentType === 'Signature'">
              <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Signature:</label>
              <div class="valueCls">
                <a (click)="viewImgFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                  class="download ml-2"></span>
              </div>
            </div>
            <div *ngIf="file.documentType === 'aadharPhoto'">
              <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Photo:</label>
              <div class="valueCls">
                <a (click)="viewImgFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                  class="download ml-2"></span>
              </div>
            </div>
          
          </div>
        </ng-container>
      </div>
    </div>
  </div>