
  import { Component, OnInit ,ViewChild, ElementRef } from '@angular/core';
  import { CommonConstants } from '../../constants/common-constants.component';
  import { FormBuilder, FormGroup, Validators } from '@angular/forms';
  import { CommonService, CustomAdapter, CustomDateParserFormatter } from '../../shared/common.service';
  import { Router } from '@angular/router';
  import { NgbDateAdapter, NgbDateParserFormatter,NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
  import * as FileSaver from 'file-saver';
  import * as XLSX from 'xlsx';
  import { DatePipe } from '@angular/common';
  import { PdfViewerPopupComponent } from 'src/shared/pdf-viewer-popup/pdf-viewer-popup.component';

@Component({
  selector: 'app-jagananna-suraksha-token-generation-report',
  templateUrl: './jagananna-suraksha-token-generation-report.component.html',
  styleUrls: ['./jagananna-suraksha-token-generation-report.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
  ]
})

export class JaganannaSurakshaTokenGenerationReportComponent implements OnInit {
    commonConstants : any = CommonConstants;
    headerCol = [{colId:'districT_NAME', colName: 'District'},
    {colId:'mandaL_NAME', colName: 'Mandal'},
    {colId:'secretariaT_NAME', colName: 'Secretariat'},
    {colId:'requisitionid', colName: 'Requisition Id'},
    {colId:'transactionamount', colName: 'Amount'},
    {colId:'transactiondate', colName: 'Requisition Date'},
    {colId:'paymenT_STATUS', colName: 'Payment Status'},
    {colId:'challanid', colName: 'Challan Id'},
    {colId:'challaN_TYPE', colName: 'Challan Type'},
    {colId:'challaN_DATE', colName: 'Challan Date'},
    ]
    form: FormGroup;
    districts: any[] = [];
    mandals: any[] = [];
    phcs: any[] = [];
    vhcs: any[] = [];
    villages: any[]= [];
    departments: any[] = [];
    services: any[] = [];
    formatTypes: any[]= [];
    items: any[] = [];
    totalItems: any[] = [];
    beyondSLAItems: any[] = [];
    page = 1;
    popupPage = 1;
    pageSize = 10;
    format: any;
    showReports:Boolean=false;
    submitted:Boolean=false;
    noOfCol: any = 9;
    role: any;
    defaultFromDate: any;
    currentDate = new Date();
    popupPageSize = 10;
    maxDate: any;
    minDate: any;
    toMinDate: any;
    
    @ViewChild('TABLE') table!: ElementRef;
    @ViewChild('BEYONDSLATABLE') beyondSLATable!: ElementRef;
    
    constructor(private formBuilder: FormBuilder, private datePipe: DatePipe, private commonService: CommonService,  config: NgbModalConfig,private router: Router,private modalService: NgbModal) { 
      config.backdrop = 'static';
      config.keyboard = false;
      this.form = this.formBuilder.group({
        village: [null],
        mandal: [null],
        district: [null],
        RUFlag: [null],
        phc: [null],
        vhc:[null],
        fromDate: ['', [Validators.required]],
        toDate: ['', [Validators.required]]
      });
      this.getDistricts();

      this.maxDate = {
        year: 2024,
        month: 6,
        day: 30
      };
      this.minDate = {
        year: 2024,
        month: 1,
        day: 1  
      };
  
      // this.maxDate = {
      //   year: 2023,
      //   month: 11,
      //   day: 30    
      // };
  
      // this.minDate = {
      //   year: 2023,
      //   month: 9,
      //   day: 30  
      // };
      this.toMinDate = {
        year: 2023,
        month: 9,
        day: 30
      }
    }
  
    ngOnInit(): void {
      let startDate = new Date(2023,8,30);
      if(this.currentDate < startDate) {
        this.defaultFromDate = startDate.getDate()+"-"+(startDate.getMonth()+1)+"-"+startDate.getFullYear();
      } else {
        this.defaultFromDate = this.currentDate.getDate()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getFullYear();
      }
      this.format = 'District Wise';
       this.form.patchValue({
         district:'All',
         mandal:'All',
        village: 'All',
         RUFlag: 'All',
         phc:'All',
         vhc:'All',
         fromDate: this.defaultFromDate,
         toDate: this.defaultFromDate,
       });
       this.formatTypes = [{name: 'District Wise', isActive: true},
       {name:'Mandal Wise', isActive: false},
      //  {name:'PHC Wise', isActive: false},
       {name:'VHC Wise', isActive: false}
  ]
  this.getJaganannaSurakshaTokenReport();
  let userInfo = localStorage.getItem('userInfo');
    if (userInfo && userInfo !== null) {
      let userData = JSON.parse(userInfo);
      this.role = userData.jobTitle;
    }
      }
  
  
    get f() { return this.form.controls; }
    
    viewImgFile(blobRef: any) {
      this.commonService
        .getRequest(this.commonConstants.viewFile + blobRef)
        .subscribe({
          next: (responseData: any) => {
            let fileInfo = responseData.result.fileContents;
             const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
             modalRef.componentInstance.isImage = true;
            modalRef.componentInstance.sourceFile = 'data:image/png;base64,' +fileInfo;
          },
          error: (error) => {
            console.log('client side', error);
          },
        });
    }
    onFromDateSelect(event: any) {
      this.showReports = false;
      this.toMinDate = event;
      // console.log('from' , event)
      // if(this.f.typeOfDate.value === 'Scheduled Date') {
      //   console.log('from' , this.f.fromDate.value)
      //   this.f.toDate.patchValue(this.f.fromDate.value);
      // }
    }
    onToDateSelect(event: any) {
      this.showReports = false;
      // if(this.f.typeOfDate.value === 'Scheduled Date') {
      //   this.f.fromDate.patchValue(this.f.toDate.value);
      //   this.toMinDate = event;
      // }
    }
   
    getDistricts() {
      this.commonService.getRequest(this.commonConstants.getJASDistricts).subscribe({
        next: (responseData: any) => {
          console.log(responseData,'getDistricts')
          if(responseData.result?.isSuccess) {
            this.districts = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'districtName');
            console.log(this.districts,'districts')
          } else {
            this.districts = [];
            this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    }
    onRuralUrbanChange() {
      if(this.f['district'].value !== 'All') {
        this.onDistrictChange();
      }
    }
    onDistrictChange() {
      this.showReports = false;
      this.mandals = [];
      this.phcs = [];
      this.f.mandal.patchValue('All');
      this.f.phc.patchValue('All');
      let RUFlag: any = this.f['RUFlag'].value === 'All' ? 'All' : this.f['RUFlag'].value;
      this.commonService.getRequest(this.commonConstants.getJASMandalsRuralUrban + '?DistrictCode=' + this.f['district'].value.districtCode + '&RuralOrUrbanFlag=' + RUFlag ).subscribe({
        next: (responseData: any) => {
          console.log(responseData,'mandals')
          if(responseData.result?.isSuccess) {
            this.mandals = this.commonService.sortItems(responseData.result?.getMandalDetails, 'mandalName');
          } else {
            this.mandals = [];
            this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    }
    
  onMandalChange() {
      this.showReports = false;
      this.phcs = [];
      this.vhcs = [];
     this.f.phc.patchValue('All');
     this.f.vhc.patchValue('All');
      this.commonService.getRequest(this.commonConstants.getPHCMandal + '?MandalCode=' + this.f.mandal.value.mandalCode).subscribe({
        next: (responseData: any) => {
          console.log(responseData,'phcs')
          if(responseData.result?.isSuccess) {
            this.phcs = this.commonService.sortItems(responseData.result?.getPHCDetails, 'phcName');
          } else {
            this.phcs = [];
            this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    }
    onPHCChange() {
      this.showReports = false;
      this.vhcs = [];
     this.f.vhc.patchValue('All');
      this.commonService.getRequest(this.commonConstants.getVHCByPHC + '?phcCode=' + this.f.phc.value.phcCode).subscribe({
        next: (responseData: any) => {
          console.log(responseData,'vhcs')
          if(responseData.result?.isSuccess) {
            this.vhcs = this.commonService.sortItems(responseData.result?.getVHCDetails, 'vhcName');
          } else {
            this.vhcs = [];
            this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
    });
    }
    onVHCChange() {
      this.showReports = false;
    }
  
    onChangeFormat(item: any) {
      this.showReports = false;
      this.formatTypes.map((item:any) => {
        item.isActive = false;
        return item;
      });
      item.isActive = true;
      this.format = item.name;
      this.getFormat();
    }
    
    getFormat(): any {
      if (this.format === 'District Wise' ) {
        this.noOfCol = 9;
        return this.noOfCol;
      } else if (this.format === 'Mandal Wise') {
        this.noOfCol = 11;
        return this.noOfCol;
      } else if (this.format === 'VHC Wise') {
        this.noOfCol = 16;
        return this.noOfCol;
      } else {
        this.noOfCol = 9;
        return this.noOfCol;
      }
    }
    
  
    downloadExcel() {
      let fileName: any = 'Aarogya Suraksha 2.0 Camp Status Dashboard' + this.f.fromDate.value +' to '+ this.f.toDate.value;
      const ws: XLSX.WorkSheet=XLSX.utils.table_to_sheet(this.table.nativeElement, {raw: true});
      let wscols = [
        {wpx:50},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100},
        {wpx:100}
    ];
    
    ws['!cols'] = wscols;
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Aarogya Suraksha 2.0 Dashbaord');
      
      /* save to file */
      XLSX.writeFile(wb, fileName + '.xlsx');
    }
    saveAsExcelFile(buffer: any, fileName: string) {
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    }
  
  
  
    getJaganannaSurakshaTokenReport(from?: any): any {
      this.submitted = true;
      if (!this.form.valid){
        return false;
      } else { 
          this.page = 1;
         
          let district = this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode;
          let mandal = this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode;
          let RUFlag = this.f.RUFlag.value === 'All' ? 'All' : this.f.RUFlag.value;
          let phc = this.f.phc.value === 'All' ? 'All' : this.f.phc.value?.phcCode;
          let vhc= this.f.vhc.value === 'All' ? 'All' : this.f.vhc.value?.vhcCode;
          let fromDate = this.datePipe.transform(this.commonService.formatDate(this.f.fromDate.value), 'dd/MM/yyyy')
          let toDate = this.datePipe.transform(this.commonService.formatDate(this.f.toDate.value), 'dd/MM/yyyy')
          let postData: any = '?Flag='+ this.format +'&DID=' + district + '&MID=' + mandal  + '&RuralUrban=' + RUFlag  + '&PHC=' + phc  + '&VHC=' + vhc +'&FromDate=' + fromDate + '&ToDate=' + toDate;
          this.commonService.getRequest(this.commonConstants.getJaganannaSurakshaTokenReport + postData).subscribe({
            next: (responseData: any) => { 
              console.log(typeof(responseData?.result),'result man')
              if(typeof(responseData?.result) !== 'string') {
                  this.showReports = true;
                  this.items = responseData?.result;
                    this.items = this.items.filter(item => item.totaL_FLAG !== 'Y')
                    this.totalItems = responseData?.result.filter((item: { totaL_FLAG: string; }) => item.totaL_FLAG === 'Y')
                    console.log(this.items,'items')
                    console.log( this.totalItems ,'total items')
              } else {
                this.items = [];
                this.totalItems = [];
              }
            },
            error: (error) => {
              console.log('client side', error);
            }
        });
        }
      }
      getPendingTransaction(item: any, modal: any) {
        let format: any;
          format = 'Pending';
        this.beyondSLAItems = [];
        this.modalService.open(modal, { size: 'xl', centered: true, backdrop: 'static'});
            this.popupPage = 1;
            
            let district = item.lgD_DIST_CODE === null ? (this.f.district.value === 'All' ? 'All' : this.f.district.value?.districtCode) : item.lgD_DIST_CODE;
            let mandal = item.lgD_MANDAL_CODE === null ? (this.f.mandal.value === 'All' ? 'All' : this.f.mandal.value?.mandalCode) : item.lgD_MANDAL_CODE;
            let phc = this.f.phc.value === 'All' ? 'All' : this.f.phc.value;
            let vhc= this.f.vhc.value === 'All' ? 'All' : this.f.vhc.value;
            let RUFlag = this.f.RUFlag.value === 'All' ? 'All' : this.f.RUFlag.value;
            let postData: any = '?Flag='+ this.format +'&DID=' + district + '&MID=' + mandal  + '&RuralUrban=' + RUFlag  + '&PHC=' + phc  + '&VHC=' + vhc ;
            
            this.commonService.getRequest(this.commonConstants.GetArogyaSurakshaReportData + postData).subscribe({
              next: (responseData: any) => { 
                console.log('Transaction Wise',responseData)
                if(typeof(responseData?.result) !== 'string') {
                  this.beyondSLAItems = responseData?.result;
                } else {
                  this.beyondSLAItems = [];
                }
              },
              error: (error) => {
                console.log('client side', error);
              }
          });
      }
     
      goToHome() {
        this.router.navigate(['/home']);
      }
    }
  