import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-print-six-step-proforma',
  templateUrl: './print-six-step-proforma.component.html',
  styleUrls: ['./print-six-step-proforma.component.css'],
})
export class PrintSixStepProformaComponent implements OnInit {
 
  @Input() sixstepPrintData: any;
  myDate = new Date();

  constructor() {}

  ngOnInit(): void {}

  public printData() {
   /*  console.log('sixstepPrintData');
    console.log(this.sixstepPrintData); */

    let Pagelink = "about:blank";
    var pwa = window.open(Pagelink, "_new");
    pwa?.document.open();
    pwa?.document.write(this.preparePrintFormat());
    pwa?.document.close();
  }


  preparePrintFormat() {
    
    const printContent: HTMLElement | null = document.getElementById('__printingFrame');
    let htmlData :any = "";
    let printdata  = printContent?.innerHTML;

    htmlData +='<html><head><title>SizStepProForma</title> <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">';
    htmlData += "<style type='text/css' media='print'> @media print{@page{size: auto; margin: 0;}table{table-layout: fixed; width: 100%;}td, th{font-size: 12px; border: 1px solid rgb(97, 91, 91) !important; text-align: center; padding: 12px 10px !important; vertical-align: middle !important;}.dataAlign{border: 1px solid rgb(97, 91, 91);}.spacw30{width: 30%;}.spacw10{width: 10%;}}</style> <style>table{table-layout: fixed; width: 100%;}td, th{font-size: 12px; border: 1px solid rgb(97, 91, 91) !important; text-align: center; padding: 12px 10px !important; vertical-align: middle !important;}.dataAlign{border: 1px solid rgb(97, 91, 91);}.spacw30{width: 30%;}.spacw10{width: 10%;}</style> <script>function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'>";

    htmlData += printdata;
    htmlData += '</body></html>';

    return htmlData;
  }
}
