import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-lamination-view',
  templateUrl: './lamination-view.component.html',
  styleUrls: ['./lamination-view.component.css']
})
export class LaminationViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

}
