import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';
@Component({
  selector: 'app-issue-of-occupancy-rights-certificates-for-inam-lands-view',
  templateUrl: './issue-of-occupancy-rights-certificates-for-inam-lands-view.component.html',
  styleUrls: ['./issue-of-occupancy-rights-certificates-for-inam-lands-view.component.css']
})
export class IssueOfOccupancyRightsCertificatesForInamLandsViewComponent implements OnInit {

  commonConstants : any = CommonConstants;
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  // screenMandal: any;
  // screenDistrict: any;
  // screenVillage: any;

  constructor(private commonService: CommonService) {
  }

  ngOnInit(): void {
    // this.getByEntitycode(this.certificateInfo?.serviceDistrict, 'District');
    // this.getByEntitycode(this.certificateInfo?.servicemandal, 'Mandal');

    // this.getdynamiceVillageByEntitycode(this.certificateInfo?.serviceDetails , 'Secretariat');
  }

  // getByEntitycode(entityCode: any, entityType:any, addrType?: any): any {
  //   this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({  
  //   next: (responseData: any) => { 
  //       let name =  responseData.result[0]?.name;
  //       if (entityType === 'District' ) {
  //         this.screenDistrict = name;
  //       }else if (entityType === 'Mandal') {
  //         this.screenMandal = name; 
  //       } else if (entityType === 'Secretariat') {
  //         this.screenVillage = name;
  //       }
  //     },
  //     error: (error) => {
  //       console.log('client side', error);
  //     }
  // });
  // }

  // serviceDetailsGridData = [
  //   {
  //     village : "",
  //     surveyNo : "",
  //     landType : "",
  //     extendofLand : "",
  //     landUnits : "",
  //     natureofInam : ""
  //   }
  // ];
  // getdynamiceVillageByEntitycode(gridData: any, entityType:any): any {
  //   this.serviceDetailsGridData = []
  //   gridData.forEach((element: any) => {
  //     this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + element.village + '&EntityType=' + entityType).subscribe({  
  //       next: (responseData: any) => { 
  //           let name =  responseData.result[0]?.name;
  //           if (entityType === 'Secretariat') {
  //             this.screenVillage = name;
  //             this.serviceDetailsGridData.push({
  //              village : this.screenVillage,
  //              surveyNo : element.surveyNo,
  //              landType : element.landType,
  //              extendofLand : element.extendofLand,
  //              landUnits : element.landUnits,
  //              natureofInam : element.natureofInam
  //             })
  //           }
  //         },
  //         error: (error) => {
  //           console.log('client side', error);
  //         }
  //     });

  //   });

    
  // }


  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

}
