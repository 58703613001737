<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">FamilyIncome:</label>
                <div class="valueCls">{{certificateInfo?.familyIncome ? (certificateInfo?.familyIncome) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Occupation:</label>
                <div class="valueCls">{{certificateInfo?.occupation ? (certificateInfo?.occupation) : '-' }}</div>
            </div>
            <div class="fieldHolder" *ngIf = "certificateInfo?.singleMemberType">
                <label class="labelCls">Single Member Type:</label>
                <div class="valueCls">{{certificateInfo?.singleMemberType}}</div>
            </div>
         </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Habitation:</label>
                <div class="valueCls">{{certificateInfo?.habitation ? (certificateInfo?.habitation) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Member Type:</label>
                <div class="valueCls">{{certificateInfo?.memberType ? (certificateInfo?.memberType) : '-' }}</div>
            </div>
           
        </div>
     
    </div>
    <hr>
    <div class="subHeadingCls mb-3">Rice Card Family Member Details</div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                <tr> 
                    <th>Aadhar Card Number</th>
                    <th>English Name</th>
                    <th>Telugu Name</th>
                    <th>Gender</th>
                    <th>Mobile Number</th>
                    <th>DOB</th>
                    <th>Relation</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of certificateInfo?.riceCardMembersDataList">
                        <td class="value">{{item.aadhaarNumber ? this.commonService.RSADecrypt(item.aadhaarNumber) : '-' }}</td>
                        <td>{{item.englishName ? (item.englishName) : '-' }}</td>
                        <td>{{item.teluguName ? (item.teluguName) : '-' }}</td>
                        <td>{{item.gender ? (item.gender) : '-' }}</td>
                        <td class="value">{{item.mobileNumber ? (item.mobileNumber) : '-' }}</td>
                        <td>{{item.dateOfBirth | date: 'dd/MM/yyyy' }}</td>
                        <td>{{item.relationShip ? (item.relationShip) : '-' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Death or Divorce Certificate'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Marriage Certificate:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>   



