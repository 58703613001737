<div class="certificateInfo">
    <div class="subHeadingCls mb-3">Basic Details</div>
    <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Aadhaar Number:</label>
                <div class="valueCls" *ngIf="department !== 'HF'">{{commonFormAddedFields?.aadharNum ? commonFormAddedFields?.aadharNum : basicInfo?.applicantInfo?.aadhaarNumber}}</div>
                <div class="valueCls" *ngIf="department === 'HF'">{{this.numberMasking(commonFormAddedFields?.aadharNum ? commonFormAddedFields?.aadharNum : basicInfo?.applicantInfo?.aadhaarNumber)}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Citizen Name:</label>
                <div class="valueCls">{{basicInfo?.personNames[0]?.firstName}}</div>
            </div>
            <!-- <div class="fieldHolder">
                <label class="labelCls">Middle Name:</label>
                <div class="valueCls">{{basicInfo?.personNames[0]?.middleName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Last Name:</label>
                <div class="valueCls">{{basicInfo?.personNames[0]?.lastName}}</div>
            </div> -->
            <div class="fieldHolder">
                <label class="labelCls">Father / Husband Name:</label>
                <div class="valueCls">{{basicInfo?.personNames[0]?.fatherHusbandName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Gender:</label>
                <div class="valueCls">{{basicInfo?.gender}}</div>
            </div>
            <div class="fieldHolder" *ngIf="urlpath == 'suomoto'">
                <label class="labelCls">Village / Ward / Secretariat:</label>
                <div class="valueCls">{{commonService.sumotodata?.villageWard}}</div>
            </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div class="fieldHolder" *ngIf="urlpath !== 'suomoto'">
                <label class="labelCls">Date of Birth:</label>
                <div class="valueCls">{{basicInfo?.birthDate | date: 'dd/MM/yyyy'}}</div>
            </div>
            <div class="fieldHolder" *ngIf="urlpath == 'suomoto'">
                <label class="labelCls">Date of Birth:</label>
                <div class="valueCls"></div>
            </div>
            <div class="fieldHolder" *ngIf="urlpath != 'suomoto'">
                <label class="labelCls">Caste:</label>
                <div class="valueCls">{{basicInfo?.caste}}</div>
            </div>
            <div class="fieldHolder" *ngIf="urlpath == 'suomoto'">
                <label class="labelCls">Caste:</label>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Religion:</label>
                <div class="valueCls">{{basicInfo?.religion}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Qualification:</label>
                <div class="valueCls">{{basicInfo?.qualification}}</div>
            </div>
            <div class="fieldHolder" *ngIf="urlpath == 'suomoto'">
                <label class="labelCls">Cluster Id: </label>
                <div class="valueCls">{{commonService.sumotodata?.clusterid}}</div>
            </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Marital Status:</label>
                <div class="valueCls">{{basicInfo?.maritalStatus}}</div>
            </div>
            <div class="fieldHolder" *ngIf= "urlpath != 'suomoto'">
                <label class="labelCls">Mobile No:</label>
                <div class="valueCls">{{basicInfo?.mobileNumber}}</div>
            </div>
            <div class="fieldHolder" *ngIf="urlpath == 'suomoto'">
                <label class="labelCls">Mobile No:</label>
                <div class="valueCls">{{commonService.sumotodata?.mobileNumber}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Is the Mobile Number same as Whatsapp Number?</label>
                <div class="valueCls">{{basicInfo?.isWhatsAppAvailable}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Email:</label>
                <div class="valueCls">{{(basicInfo?.emailAddress) ? (basicInfo?.emailAddress) : '-'}}</div>
            </div>
            <!-- <div class="fieldHolder">
                <label class="labelCls">Delivery Type:</label>
                <div class="valueCls">{{basicInfo?.deliveryType}}</div>
            </div> -->
        </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="subHeadingCls mb-3">Present Address (Mailing Address)</div>
            <div class="fieldHolder"*ngIf= "urlpath != 'suomoto'">
                <label class="labelCls">Door No & Street Name:</label>
                <div class="valueCls">{{presentAddr ? presentAddr[0]?.doorNo: ''}}</div>
            </div>
            <div class="fieldHolder" *ngIf= "urlpath == 'suomoto'">
                <label class="labelCls">Door No & Street Name:</label>
                <div class="valueCls">{{commonService.sumotodata?.doorNo}} - {{commonService.sumotodata?.streetName}}</div>
            </div>
            <!-- <div class="fieldHolder">
                <label class="labelCls">Street Name:</label>
                <div class="valueCls">{{presentAddr[0]?.streetName}}</div>
            </div> -->
            <div class="fieldHolder">
                <label class="labelCls">Village / Ward / Secretariat:</label> 
                <div class="valueCls">{{commonFormAddedFields?.presentVillage}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal / Municipality:</label>
                <div class="valueCls">{{commonFormAddedFields?.presentMandal}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{commonFormAddedFields?.presentDistrict}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Pin Code:</label>
                <div class="valueCls">{{presentAddr ? presentAddr[0]?.pinCode: ''}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Postal Office:</label>
                <div class="valueCls">{{presentAddr[0]?.postalOfficeName ? presentAddr[0]?.postalOfficeName: '-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Postal Village:</label>
                <div class="valueCls">{{presentAddr[0]?.postalVillageName ? presentAddr[0]?.postalVillageName: '-'}}</div>
            </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="subHeadingCls mb-3">Permanent Address</div>
        <div class="fieldHolder" *ngIf="urlpath != 'suomoto'">
            <label class="labelCls">Door No & Street Name:</label>
            <div class="valueCls">{{permanentAddr ? permanentAddr[0]?.doorNo: ''}}</div>
        </div>
        <div class="fieldHolder" *ngIf= "urlpath == 'suomoto'">
            <label class="labelCls">Door No & Street Name:</label>
            <div class="valueCls">{{suomotodata?.doorNo}} - {{suomotodata?.streetName}}</div>
        </div>
        <!-- <div class="fieldHolder">
            <label class="labelCls">Street Name:</label>
            <div class="valueCls">{{permanentAddr[0]?.streetName}}</div>
        </div> -->
        <div class="fieldHolder">
            <label class="labelCls">Village / Ward / Secretariat:</label>
            <div class="valueCls">{{commonFormAddedFields?.permanentVillage}}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Mandal / Municipality:</label>
            <div class="valueCls">{{commonFormAddedFields?.permanentMandal}}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">District:</label>
            <div class="valueCls">{{commonFormAddedFields?.permanentDistrict}}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Pin Code:</label>
            <div class="valueCls">{{permanentAddr ? permanentAddr[0]?.pinCode: ''}}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Postal Office:</label>
            <div class="valueCls">{{permanentAddr[0]?.postalOfficeName ? permanentAddr[0]?.postalOfficeName: '-'}}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Postal Village:</label>
            <div class="valueCls">{{permanentAddr[0]?.postalVillageName ? permanentAddr[0]?.postalVillageName: '-'}}</div>
        </div>
        </div>
    </div>
</div>
