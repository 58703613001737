import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  private _env!: Environment;
  private baseUrl: string = '';
  private inboundUrl: string = '';
  private subscriptionKeys: any;
  private landingUrls: any;

  get env(): Environment {
    return this._env;
  }

  get apiUrl(): string {
    return this.baseUrl;
  }
  get InboundApiUrl(): string {
    return this.inboundUrl;
  }
  get SubscriptionKeys(): any {
    return this.subscriptionKeys;
  }
  get externalUrls() : any {
    return this.landingUrls;
  }
  constructor() {}

  init(): Promise<void> {
    return new Promise(resolve => {
      this.setEnvVariables();
      resolve();
    });
  }

  private setEnvVariables(): void {
    const hostname = window && window.location && window.location.hostname;
    // this.baseUrl = 'https://apiuat.vswsonline.ap.gov.in/';
    // this.inboundUrl = 'https://apiuat.vswsonline.ap.gov.in/';
    // if (/^.*localhost.*/.test(hostname)) {
    //   this.baseUrl = 'https://gsws-ict-apim-dev.azure-api.net/';
    //   this.inboundUrl = 'https://gsws-inbound-apim-test.azure-api.net/';
    //   this.subscriptionKeys = {
    //     internalKey :'7819e1cf869d4bcfb4ee8d71ab0b0f7b',
    //     externalKey : 'b248c8be2e9d4a5487ada90f99eecbef'
    //   }
    // } 
     if (/^.*localhost.*/.test(hostname) || /^gswswebdev.z29.web.core.windows.net/.test(hostname) || /^apgv.vswsonline.ap.gov.in/.test(hostname) 
      || /^stgvswsdevappci01.z29.web.core.windows.net/.test(hostname) || /^dev.vswsonline.ap.gov.in/.test(hostname)) {
      //this.env = Environment.Dev;
      this.baseUrl = 'https://apidev.vswsonline.ap.gov.in/'; //'https://gsws-ict-apim-dev.azure-api.net/';
      this.inboundUrl = 'https://apidev.vswsonline.ap.gov.in/'; //'https://gsws-inbound-apim-test.azure-api.net/';
      this.subscriptionKeys = {
        internalKey :'0356f181388f46a090fe8782574ec6ce', //'7819e1cf869d4bcfb4ee8d71ab0b0f7b',
        externalKey : '6fe79bebadf7453a9b6cf5d4d61bb747' //'b248c8be2e9d4a5487ada90f99eecbef'
      }
      this.landingUrls = {
        RTS: 'http://uat.gramawardsachivalayam.ap.gov.in/GSWSRTS/UI/RTS_SSO.aspx',
        deptUrl: 'https://uat.meeseva.gov.in/APSDCDeptPortal/UserInterface/GSWSIntegration.aspx',
        gsws: 'http://uat.gramawardsachivalayam.ap.gov.in/GSWS/#!/SingleSignOn',
        CPDCL: 'http://103.231.215.242:8080/APCPDCL_SERVICES_GSWS/servlet/LT_NewConn_URLGSWS',
        EPDCL: 'http://59.144.184.77:8085/EPDCL_GSWS/integrateGswsAndEpdclWeb',
        SPDCL: 'http://122.252.251.175:8080/APSPDCL_SERVICES_GSWS/servlet/LT_NewConn_URLGSWS',
        pensionFinalSanction: 'https://uat-abdg.aptonline.in:8090/APOL_APSSP_API_SERVICES/api/APOL_GSWS_SERVICES/NEW_APPLICATIONS_FINAL_SANCTIONS',
        clientIp: 'https://uat.vswsonline.ap.gov.in',
        pensionValidateBeneficiary:'https://uat-abdg.aptonline.in:8090/APOL_APSSP_API_SERVICES/api/APOL_GSWS_SERVICES/VALIDATE_BENEFICIARY_IN_PENSIONS',
        eSeva: 'http://103.129.75.95/esevaUAT/GSWSloginhome.html',
        meesevaCitizen: "https://uatmeeseva.ap.gov.in/CitizenPortal/userinterface/citizen/APSevaRedirection.aspx",
        transportServiceLandingPage: 'https://app-vsws-dev-transportredirect-ci-01.azurewebsites.net/TransportRedirect.aspx?',
        commonServiceLandingPage : 'https://app-vsws-dev-transportredirect-ci-01.azurewebsites.net/CommonRedirection.aspx?',
        // marriageServiceLandingPage : 'https://app-vsws-dev-transportredirect-ci-01.azurewebsites.net/RegistrationRedirection.aspx?',
        // openSchoolServiceLandingPage: 'https://app-vsws-dev-transportredirect-ci-01.azurewebsites.net/SchoolDepartmentRedirection.aspx?'
        biometricRedirectionPage: 'https://apgv.vswsonline.ap.gov.in/dev/AadhaarBiometric/Authentication/Aadhaar?uid=',
        saderamRedirection :  "https://uat-apsadarem.aptonline.in:8443/SADAREMAPSEVA/slotBookingWithUIDForAPSEVA.do"
      }
    } else if (/^uat.vswsonline.ap.gov.in/.test(hostname)) {
      this.baseUrl = 'https://apiuat.vswsonline.ap.gov.in/';
      this.inboundUrl = 'https://apiuat.vswsonline.ap.gov.in/';
      this.subscriptionKeys = {
        internalKey :'0356f181388f46a090fe8782574ec6ce',
        externalKey : '6fe79bebadf7453a9b6cf5d4d61bb747'
      }
      this.landingUrls = {
        RTS: 'https://gramawardsachivalayam.ap.gov.in/GSWSRTS/UI/RTS_SSO.aspx',
        deptUrl: 'https://uat.meeseva.gov.in/APSDCDeptPortal/UserInterface/GSWSIntegration.aspx',
        gsws: 'http://uat.gramawardsachivalayam.ap.gov.in/GSWS/#!/SingleSignOn',
        CPDCL: 'http://103.231.215.242:8080/APCPDCL_SERVICES_GSWS/servlet/LT_NewConn_URLGSWS',
        EPDCL: 'http://59.144.184.77:8085/EPDCL_GSWS/integrateGswsAndEpdclWeb',
        SPDCL: 'http://122.252.251.175:8080/APSPDCL_SERVICES_GSWS/servlet/LT_NewConn_URLGSWS',
        pensionFinalSanction: 'https://uat-abdg.aptonline.in:8090/APOL_APSSP_API_SERVICES/api/APOL_GSWS_SERVICES/NEW_APPLICATIONS_FINAL_SANCTIONS',
        clientIp: 'https://uat.vswsonline.ap.gov.in',
        pensionValidateBeneficiary:'https://uat-abdg.aptonline.in:8090/APOL_APSSP_API_SERVICES/api/APOL_GSWS_SERVICES/VALIDATE_BENEFICIARY_IN_PENSIONS',
        eSeva: 'http://103.129.75.95/esevaUAT/GSWSloginhome.html',
        meesevaCitizen: "https://uatmeeseva.ap.gov.in/CitizenPortal/userinterface/citizen/APSevaRedirection.aspx",
        saderamRedirection :  "https://uat-apsadarem.aptonline.in:8443/SADAREMAPSEVA/slotBookingWithUIDForAPSEVA.do",
        transportServiceLandingPage: 'https://app-gsws-transportredirect-ci-uat.azurewebsites.net/TransportRedirect.aspx?',
        commonServiceLandingPage : 'https://app-gsws-transportredirect-ci-uat.azurewebsites.net/CommonRedirection.aspx?',
        // openSchoolServiceLandingPage: 'https://app-gsws-transportredirect-ci-uat.azurewebsites.net/SchoolDepartmentRedirection.aspx?'
        biometricRedirectionPage: 'https://apgv.vswsonline.ap.gov.in/dev/AadhaarBiometric/Authentication/Aadhaar?uid=',  
      }
    }  else if (/^vswsonline.ap.gov.in/.test(hostname)) {
      this.baseUrl = 'https://api.vswsonline.ap.gov.in/';
      this.inboundUrl = 'https://api.vswsonline.ap.gov.in/';
      this.subscriptionKeys = {
        internalKey :'36491518b58b42cfbffa854fde92f134', // need to change the keys for prod
        externalKey : '2402114c7f064b42aec9685dac6603f0' // need to change the keys for prod
      }
      this.landingUrls = {
        RTS: 'https://gramawardsachivalayam.ap.gov.in/GSWSRTS/UI/RTS_SSO.aspx',
        deptUrl: 'https://apdept.meeseva.gov.in/APSDCDeptPortal/UserInterface/GSWSIntegration.aspx',
        gsws: 'http://gramawardsachivalayam.ap.gov.in/GSWS/#!/SingleSignOn',
        CPDCL: 'http://103.231.215.245:8080/APCPDCL_SERVICES_GSWS/servlet/LT_NewConn_URLGSWS',
        EPDCL: 'https://gswsnew.apeasternpower.com/integrateGswsAndEpdclWeb',
        SPDCL: 'http://122.252.251.175:8080/APSPDCL_SERVICES_GSWS/servlet/LT_NewConn_URLGSWS',
        pensionFinalSanction: 'https://abdg.aptonline.in/APOL_APSSP_API_SERVICES/api/APOL_GSWS_SERVICES/NEW_APPLICATIONS_FINAL_SANCTIONS',
        clientIp: 'https://vswsonline.ap.gov.in',
        pensionValidateBeneficiary:'https://abdg.aptonline.in/APOL_APSSP_API_SERVICES/api/APOL_GSWS_SERVICES/VALIDATE_BENEFICIARY_IN_PENSIONS',
        eSeva: 'http://eseva.ap.gov.in/eseva/GSWSloginhome.html',
        meesevaCitizen: "https://onlineap.meeseva.gov.in/CitizenPortal/userinterface/citizen/APSevaRedirection.aspx",
        transportServiceLandingPage: 'https://app-vsws-transportredirect-ci-prod.azurewebsites.net/TransportRedirect.aspx?',
        biometricRedirectionPage: 'https://apgv.vswsonline.ap.gov.in/AadhaarBiometric/Authentication/Aadhaar?uid=',
        // marriageServiceLandingPage : 'https://app-vsws-transportredirect-ci-prod.azurewebsites.net/RegistrationRedirection.aspx?',
        // openSchoolServiceLandingPage: 'https://app-vsws-transportredirect-ci-prod.azurewebsites.net/SchoolDepartmentRedirection.aspx?',
        commonServiceLandingPage : 'https://app-vsws-transportredirect-ci-prod.azurewebsites.net/CommonRedirection.aspx?',
        saderamRedirection :  "https://sadarem.ap.gov.in/SADAREMAPSEVA/slotBookingWithUIDForAPSEVA.do"
      }
    }
    else {
      console.warn(`Cannot find environment for host name ${hostname}`);
    }
  }
}
 export enum Environment {
  Prod = 'prod',
  Staging = 'staging',
  Test = 'test',
  Dev = 'dev',
  Local = 'local',
}
