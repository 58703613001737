<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Relationship with Deceased:</label>
                <div class="valueCls">{{certificateInfo.relationshipWithDeceased? (certificateInfo.relationshipWithDeceased) : '-' }}</div>
            </div>
        </div>
        
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo">
    <div class="subHeadingCls mb-3">Deceased Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Deceased First Name:</label>
                <div class="valueCls">{{certificateInfo.deceasedInfo?.firstName ? (certificateInfo.deceasedInfo?.firstName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Deceased Middle Name:</label>
                <div class="valueCls">{{certificateInfo.deceasedInfo?.middleName ? (certificateInfo.deceasedInfo?.middleName) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Deceased Last Name:</label>
            <div class="valueCls">{{certificateInfo.deceasedInfo?.lastName ? (certificateInfo.deceasedInfo?.lastName) : '-' }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Father/Husband Name:</label>
            <div class="valueCls">{{certificateInfo.deceasedInfo?.fatherHusbandName ? (certificateInfo.deceasedInfo?.fatherHusbandName) : '-' }}</div>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Date of Death:</label>
                <div class="valueCls">{{certificateInfo.deceasedDateofDeath | date: 'dd/MM/yyyy'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Reason for Certificate:</label>
                <div class="valueCls">{{certificateInfo.reasonofCertificate ? (certificateInfo.reasonofCertificate) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Occupation:</label>
            <div class="valueCls">{{certificateInfo.deceasedOccupation ? (certificateInfo.deceasedOccupation) : '-' }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Door No:</label>
            <div class="valueCls">{{certificateInfo.deceasedDeathAddress?.doorNo ? (certificateInfo.deceasedDeathAddress?.doorNo) : '-' }}</div>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Street Name :</label>
                <div class="valueCls">{{certificateInfo.deceasedDeathAddress?.streetName ? (certificateInfo.deceasedDeathAddress?.streetName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Death Happened in District:</label>
                <div class="valueCls">{{certificateInfo.deceasedDeathAddress?.districtName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Death Happened in Mandal:</label>
            <div class="valueCls">{{certificateInfo.deceasedDeathAddress?.mandalMunicipalityName }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Death Happened in Village:</label>
            <div class="valueCls">{{certificateInfo.deceasedDeathAddress?.villageWardName }}</div>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Pin Code:</label>
                <div class="valueCls">{{certificateInfo.deceasedDeathAddress?.pinCode ? (certificateInfo.deceasedDeathAddress?.pinCode) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Reason for Death:</label>
                <div class="valueCls">{{certificateInfo.reasonofDeath ? (certificateInfo.reasonofDeath) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Death Happened Place:</label>
            <div class="valueCls">{{certificateInfo.placeofDeath ? (certificateInfo.placeofDeath) : '-' }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Native Village:</label>
            <div class="valueCls">{{this.certificateInfo.nativeVillageName }}</div>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                <tr> 
                    <th>Name of the Family Member</th>
                    <th>Age</th>
                    <th>Gender</th>
                    <th>Occupation</th>
                    <th>Relationship with Deceseaed</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of certificateInfo?.familyDetails">
                        <td>{{item.familyMemberInfo?.personNames[0]?.firstName ? (item.familyMemberInfo?.personNames[0]?.firstName) : '-' }}</td>
                        <td>{{item.age ? (item.age) : '-' }}</td>
                        <td>{{item.familyMemberInfo?.gender ? (item.familyMemberInfo?.gender) : '-' }}</td>
                        <td>{{item.occupation ? (item.occupation) : '-' }}</td>
                        <td>{{item.relationwithDeceased ? (item.relationwithDeceased) : '-' }}</td>
                    </tr>
                </tbody>
                </table>
            </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Ration Card Certificate'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Ration card /EPIC Card/AADHAR Card:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Death Certificate'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Death Certificate:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Family Member Certificate'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Copy of Family Member Certificate:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>

