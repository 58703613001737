<div class="reportContainer">
    <div [formGroup]="form">
    <div class="row mb-3">
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Select Filter Type : <span class="mandatoryCls">*</span></label> 
        <select class="selectCls" required formControlName="format" (change)="onChangeFormat()" 
        [ngClass]="{ 'is-invalid':submitted && (f.format.errors || f.format.value === 'Select') }">
        <option  [ngValue]= "null" disabled>Select</option>
        <option value = "State Wise">State Wise</option>
        <option value = "District Wise">District Wise</option>
        <option value ="Mandal Wise">Mandal Wise</option>
        </select>
        </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="district" (change)="onDistrictChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
         <option value="All">All</option>
        <option *ngFor="let district of districts" [ngValue]="district">{{district.name}}</option>
      </select>
      <!-- <div *ngIf="submitted && (f.permDistrict.errors || f.permDistrict.value === 'Select')" class="selectError">
        <div *ngIf="f.permDistrict.errors?.required || f.permDistrict.value === 'Select'">Please select District</div>
    </div> -->
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
      <option value="All">All</option>
      <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.name}}</option>
    </select>
    <!-- <div *ngIf="submitted && (f.permMandal.errors || f.permMandal.value === 'Select')" class="selectError">
      <div *ngIf="f.permMandal.errors?.required || f.permMandal.value === 'Select'">Please select Mandal/Municipality</div>
    </div> -->
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">Village/Ward/Secretariat: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="village" (change)="onVillageChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.village.errors || f.village.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let village of villages" [ngValue]="village">{{village.name}}</option>
      </select>
      <!-- <div *ngIf="submitted && (f.permVillage.errors || f.permVillage.value === 'Select')" class="selectError">
        <div *ngIf="f.permVillage.errors?.required || f.permVillage.value === 'Select'">Please select Village/Ward/Secretariat</div>
    </div> -->
    </div>
</div>
<div class="row mb-3">
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">From Date: <span class="mandatoryCls">*</span></label>
        <div class="input-group">
          <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                 name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" (dateSelect)="onFromDateSelect($event)"
                 [maxDate]="maxDate" required formControlName="fromDate"
                 [ngClass]="{ 'is-invalid':submitted && f.fromDate.errors }">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
          </div>
        </div>
        <div *ngIf="submitted && f.fromDate.errors" class="selectError">
          <div *ngIf="f.fromDate.errors?.required || f.fromDate.invalid">Please enter valid From Date</div>
      </div>
      </div>
      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">To Date: <span class="mandatoryCls">*</span></label>
        <div class="input-group">
          <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                 name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="toMinDate" (dateSelect)="onToDateSelect($event)"
                 [maxDate]="maxDate" required formControlName="toDate"
                 [ngClass]="{ 'is-invalid':submitted && f.toDate.errors }">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
          </div>
        </div>
        <div *ngIf="submitted && f.toDate.errors" class="selectError">
          <div *ngIf="f.toDate.errors?.required || f.toDate.invalid">Please enter valid To Date</div>
      </div>
      </div>
    </div>
    <div class="btnHolder mb-3">
        <button  class="greenBtnCls float-right" (click)="getReportData()">Get Reports</button>
        <button *ngIf="items && items.length > 0 && showReports" class="greenBtnCls float-right mr-3" (click)="downloadExcel()">Download Excel</button>
        <button  class="greenBtnCls float-right mr-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
      </div>
</div>
<div class="table-responsive tableFixHead" *ngIf="showReports">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th [attr.colspan]="f.format.value === 'State Wise' ? 11 : 12"><span>{{f.format.value}} SLA Dashboard </span><span *ngIf="f.district.value !== 'All'"> 
                    for {{f.district.value?.name}} </span> <span *ngIf="f.mandal.value !== 'All'"> ,
                     {{f.mandal.value?.name}} </span> <span *ngIf="f.village.value !== 'All'"> ,
                     {{f.village.value?.name}} </span><span> from {{f.fromDate.value}} to {{f.toDate.value}}</span></th>
            </tr>
            <tr>
                <th>S.No</th>
                <th>District</th>
                <th *ngIf="f.format.value === 'District Wise' || f.format.value === 'Mandal Wise'">Mandal</th>
                <th *ngIf="f.format.value === 'District Wise' || f.format.value === 'Mandal Wise'">Urban/Rural</th>
                <th *ngIf="f.format.value === 'Mandal Wise'">Secretariat</th>
                <th>Department</th>
                <th>Service</th>
                <th>Total No Of Requests</th>
                <th>Open Beyond SLA</th>
                <th>Open Within SLA</th>
                <th>Closed Within SLA</th>
                <th>Closed Beyond SLA</th>
            </tr>
        </thead>

        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                <td>{{item.district_name}}</td>
                <td *ngIf="f.format.value === 'District Wise' || f.format.value === 'Mandal Wise'">{{item.mandal_name}}</td>
                <td *ngIf="f.format.value === 'District Wise' || f.format.value === 'Mandal Wise'">{{item.rurban?.toUpperCase() === 'U' ? 'Urban': 'Rural'}}</td>
                <td *ngIf="f.format.value === 'Mandal Wise'">{{item.sec_name}}</td>
                <td>{{item.department}}</td>
                <td>{{item.servicename}}</td>
                <td>{{item.tot_req}}</td>
                <td>{{item.opn_bynd_sla}}</td>
                <td>{{item.opn_within_sla}}</td>
                <td>{{item.clsd_within_sla}}</td>
                <td>{{item.clsd_bynd_sla}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="f.format.value === 'State Wise' ? 11 : 12" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    <div class="mt-4 btnHide">
        <div class="float-left" *ngIf="items.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
</div>
</div>
<div class="table-responsive tableFixHead" #TABLE class="hideTable">
    <div>SLA Dashboard from {{f.fromDate.value}} to {{f.toDate.value}}</div> 
   <table class="tableCls" >
       <thead>
           <tr>
           <th [attr.colspan]="f.format.value === 'State Wise' ? 11 : 12"><span>{{f.format.value}} SLA Dashboard </span><span *ngIf="f.district.value !== 'All'"> 
            <span>&nbsp; for &nbsp;</span><span> {{f.district.value?.name}} </span></span> <span *ngIf="f.mandal.value !== 'All'"> ,
                     {{f.mandal.value?.name}} </span> <span *ngIf="f.village.value !== 'All'"> ,
                     {{f.village.value?.name}} </span><span> &nbsp;from&nbsp; </span><span> {{f.fromDate.value}} to {{f.toDate.value}}</span></th>
           </tr>
           <tr>
               <th width="100px">S.No</th>
               <th width="100px">District</th>
               <th width="100px" *ngIf="f.format.value === 'District Wise' || f.format.value === 'Mandal Wise'">Mandal</th>
               <th *ngIf="f.format.value === 'District Wise' || f.format.value === 'Mandal Wise'">Urban/Rural</th>
               <th width="100px" *ngIf="f.format.value === 'Mandal Wise'">Secretariat</th>
               <th width="100px">Department</th>
               <th width="100px">Service</th>
               <th width="100px">Total No Of Requests</th>
               <th width="100px">Open Beyond SLA</th>
               <th width="100px">Open Within SLA</th>
               <th width="100px">Closed Within SLA</th>
               <th width="100px">Closed Beyond SLA</th>
           </tr>
       </thead>

       <tbody *ngIf="items && items.length > 0">
           <tr *ngFor="let item of items;let i = index;">
               <td width="100px">{{i + 1}}</td>
               <td width="100px">{{item.district_name}}</td>
               <td width="100px" *ngIf="f.format.value === 'District Wise' || f.format.value === 'Mandal Wise'">{{item.mandal_name}}</td>
               <td *ngIf="f.format.value === 'District Wise' || f.format.value === 'Mandal Wise'">{{item.rurban?.toUpperCase() === 'U' ? 'Urban': 'Rural'}}</td>
               <td width="100px" *ngIf="f.format.value === 'Mandal Wise'">{{item.sec_name}}</td>
               <td width="100px">{{item.department}}</td>
               <td width="100px">{{item.servicename}}</td>
               <td width="100px">{{item.tot_req}}</td>
               <td width="100px">{{item.opn_bynd_sla}}</td>
               <td width="100px">{{item.opn_within_sla}}</td>
               <td width="100px">{{item.clsd_within_sla}}</td>
               <td width="100px">{{item.clsd_bynd_sla}}</td>
           </tr>
       </tbody>
       <tbody *ngIf="items && items.length === 0">
           <tr>
               <td [attr.colspan]="f.format.value === 'State Wise' ? 11 : 12" class="text-center">No records found.</td>
           </tr>
       </tbody>
   </table>
</div>