<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Selected Criteria Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No :</label>
                <div class="valueCls">{{basicInfo?.rationCardNo}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo?.remarks}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Registration Unit Id:</label>
                <div class="valueCls">{{certificateInfo?.registrationUnitId}}</div>
            </div>
           
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">No Of Copies:</label>
                <div class="valueCls">{{certificateInfo?.noOfCopies ? (certificateInfo?.noOfCopies) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Purpose Of Certificate:</label>
                <div class="valueCls">{{certificateInfo?.purposeOfCertificate }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Birth Criteria Details</div>
    <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Child Name:</label>
                <div class="valueCls">{{certificateInfo?.childName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Child SurName:</label>
                <div class="valueCls">{{certificateInfo?.childSurName }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Relation With Child:</label>
                <div class="valueCls">{{certificateInfo?.relationWithChild }}</div>
            </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Father Name:</label>
                <div class="valueCls">{{certificateInfo?.fatherName ? (certificateInfo?.fatherName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Father SurName:</label>
                <div class="valueCls">{{certificateInfo?.fatherSurName ? (certificateInfo?.fatherSurName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mother Name:</label>
                <div class="valueCls">{{certificateInfo?.motherName ? (certificateInfo?.motherName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mother SurName:</label>
                <div class="valueCls">{{certificateInfo?.motherSurName ? (certificateInfo?.motherSurName) : '-' }}</div>
            </div>
       
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Date Of Birth:</label>
                <div class="valueCls">{{certificateInfo?.dateOfBirth ? (certificateInfo?.dateOfBirth) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Place Of Birth:</label>
                <div class="valueCls">{{certificateInfo?.placeOfBirth ? (certificateInfo?.placeOfBirth) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfBirth ==='hospital'">Hospital :</label> 
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfBirth ==='residence'">Residence :</label>
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfBirth ==='others'">Others :</label>
                <div class="valueCls">{{certificateInfo?.locationName ? (certificateInfo?.locationName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfBirth ==='hospital'">Hospital Adress :</label> 
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfBirth ==='residence'">Residence Address :</label>
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfBirth ==='others'">Others Address :</label>   
                             <div class="valueCls">{{certificateInfo?.address1 ? (certificateInfo?.address1) : '-' }}</div>
            </div>
       
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div class="fieldHolder">

                <label class="labelCls"  *ngIf="certificateInfo?.placeOfBirth ==='hospital'">Hospital Adress2 :</label> 
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfBirth ==='residence'">Residence  Adress2:</label>
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfBirth ==='others'">Others  Adress2  :</label>                 
                  <div class="valueCls">{{certificateInfo?.address2 ? (certificateInfo?.address2) : '-' }}</div>
            </div>
            <div class="fieldHolder">

             <label class="labelCls"  *ngIf="certificateInfo?.placeOfBirth ==='hospital'">Hospital Adress3 :</label> 
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfBirth ==='residence'">Residence  Adress3:</label>
                <label class="labelCls"  *ngIf="certificateInfo?.placeOfBirth ==='others'">Others  Adress3  :</label>                   <div class="valueCls">{{certificateInfo?.address3 ? (certificateInfo?.address3) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo?.district ? (certificateInfo?.district) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Location:</label>
                <div class="valueCls">{{certificateInfo?.location ? (certificateInfo?.location) : '-' }}</div>
            </div>
       
        </div>
    </div>
</div>

<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
      
            </div>
            </ng-container>
        </div>
    </div>
</div>

