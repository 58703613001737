import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AadharValidator, CommonService, WhitespaceValidator } from 'src/shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-aarogya-suraksha-team-form',
  templateUrl: './aarogya-suraksha-team-form.component.html',
  styleUrls: ['./aarogya-suraksha-team-form.component.css']
})
export class AarogyaSurakshaTeamFormComponent implements OnInit {
  teamCount: any = 2;
  commonConstants : any = CommonConstants;
  teamForm: FormGroup;
  submitted: Boolean = false;
  locationDetails: any = [];
  isUrban: Boolean = false;
  secretariatList: any = [];
  userData: any;
  constructor(private formBuilder: FormBuilder, private router: Router, private commonService: CommonService) { 
    this.teamForm = this.formBuilder.group({
    teamData: this.formBuilder.array([]),
    });
    let userInfo = localStorage.getItem('userInfo');
    if (userInfo && userInfo !== null) {
      this.userData = JSON.parse(userInfo);;
    }
    this.getDetails();
    this.getTeamDetails();
  }

  ngOnInit(): void {
  }
  get f() {
    return this.teamForm.controls;
  }

  initTeam(data?:any, i?:any): FormGroup {
    return this.formBuilder.group({ 
      teamMemberData: this.formBuilder.array([])
    });
  }
  initTeamMember(data?:any): FormGroup {
    if(data) {
      return this.formBuilder.group({
        isSelected: [false],
        aadharNo:  [this.commonService.RSADecrypt(data.memberEncryptedAadhar), [Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
        name:[data.memberName, [Validators.required , Validators.pattern(this.commonConstants.namePattern)]] ,
        designation:[data.memberDesignation, [Validators.required ]], 
        mobileNo:[data.memberMobile, [Validators.required]]
      })
    } else {
      return this.formBuilder.group({ 
        isSelected: [false],
        aadharNo:  ['', [Validators.required, AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate]],
        name:['', [Validators.required , Validators.pattern(this.commonConstants.namePattern)]] ,
        designation:['', [Validators.required ]], 
        mobileNo:[null, [Validators.required]]
      })
    }
   
  }
  get teamData() {
    return this.teamForm.get('teamData') as FormArray;
  }
  getTeamMembers(team: any) {
    return team.controls.teamMemberData.controls;
  }
  addTeam(): any {
    if(this.teamData.length === this.teamCount && this.teamCount !== -1) {
      this.commonService.commonErrorPopup('Alert', this.teamCount + ' team(s) is/are allowed for this mandal.', 'md');
      return false;
    } 
    
    console.log(this.teamData.value);
      this.teamData.push(
        this.initTeam()
      );
      const control = this.teamData.at(this.teamData.length - 1).get("teamMemberData") as FormArray;
      control.push(
        this.initTeamMember()
      );
  }
  addTeamMember(j:any, data?:any): any{
    const control = this.teamData.at(j).get("teamMemberData") as FormArray;
    //const control = this.teamData.controls[j].get('teamMemberData') as FormArray;
     if(data) {
       data.forEach((item:any) => {
        control.push(
          this.initTeamMember(item)
        );
      })
    } else {
      if(control.length === 3) {
        this.commonService.commonErrorPopup('Alert', 'Maximum 3 team members are allowed in a team.', 'md');
        return false;
      } 
      control.push(
          this.initTeamMember()
        );
    }
  
  }
  selectTeamMember(team:any, j: any) {
      console.log(team, 'team');
      const control = team.controls.teamMemberData;
      control.controls.forEach((item: any) => {
        item.get('isSelected').patchValue(false);
      });
     control.at(j).get('isSelected').patchValue(true);
  }
  deleteTeamMember(j:any): any {
   // const control = team.controls.teamMemberData;
    const control = this.teamData.at(j).get("teamMemberData") as FormArray;
    console.log('control.controls', control.controls);
    let selectedIndex = control.controls.findIndex((item: any) => item.get('isSelected').value);
    console.log('selectedIndex', selectedIndex);
    if(selectedIndex > -1) {
      if(control.length === 1) {
        this.commonService.commonErrorPopup('Alert', 'You can\'t delete as only one team member present.', 'md');
        return false;
      }
      control.removeAt(selectedIndex);
    } else {
      this.commonService.commonErrorPopup('Alert', 'Please select a team member to delete it.', 'md');
    }
   
  }

  deleteTeam(i:any): any {
    const control = <FormArray>this.teamForm.get('teamData');
    // if(control.length === 1) {
    //   this.commonService.commonErrorPopup('Alert', 'You can\'t delete the team as only one team present.', 'md');
    //   return false;
    // }
    control.removeAt(i);
  }
  goToHome() {
    this.router.navigate(['/home']);
  }
  goToTeamSchedule() {
    this.router.navigate(['/aarogya-team-schedule']);
  }
  getDetails() {
    let locationCode: any = localStorage.getItem('locationCode');
    this.commonService.getRequest(this.commonConstants.getDistrictSecretariatDetailsbyMandal + locationCode).subscribe({
      next: (responseData: any) => {
        this.locationDetails = responseData.result;
        this.isUrban = responseData.result?.isRuralUrbanFlag === 'R' ? false : true ;
        this.secretariatList = responseData.result?.secretariatList;
        this.getTeamCount();
       },
      error: (error) => {
        this.locationDetails = [];
        console.log('client side', error);
      },
    });
  }
  getTeamCount() {
    this.commonService.getRequest(this.commonConstants.getTeamCountForAarogyaSurakshaByUserId +this.userData.userPrincipalName).subscribe({
      next: (responseData: any) => {
        if(responseData?.result?.isSuccess) {
          this.teamCount = responseData.result?.teamList[0]?.allowedTeams;
        }
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  submit(): any {
    this.submitted = true;
    if(!this.teamForm.valid)
      return false;
    let formData = this.teamForm.value?.teamData;
    let aadharNumbers:any = [];
    formData.forEach((item:any) => {
       let teamAadharNumbers = item.teamMemberData.map((el:any) => el.aadharNo);
       aadharNumbers = [...aadharNumbers, ...teamAadharNumbers];
    });
    if(aadharNumbers.some((el:any, index:any) => aadharNumbers.indexOf(el) !== index)) {
      this.commonService.commonErrorPopup('Alert', 'Same Aadhar Number entered for more than one team member.', 'md');
      return false;
    } else if(this.teamData.length < this.teamCount && this.teamCount !== -1) {
      this.commonService.commonErrorPopup('Alert', 'Please add ' + this.teamCount + ' teams with 3 team members in each team.', 'md');
      return false;
    } else if(aadharNumbers.length < (this.teamData.length *3)) {
      this.commonService.commonErrorPopup('Alert', 'Please add 3 team members details in each team.', 'md');
      return false;
    } else {
        let teamData: any = this.teamForm.value.teamData;
        let teamList: any = [];
        teamData.forEach((item:any, index: any) =>{
          let i = index + 1;
          let memberList: any = [];
          let temp: any = {};
          item.teamMemberData.forEach((el:any) => {
            let tempMember: any = {};
            tempMember.aadharNumber = this.commonService.RSAEncrypt(el.aadharNo);
            tempMember.designation = el.designation;
            tempMember.mobileNumber = el.mobileNo;
            tempMember.teamMemberName = el.name;
            memberList.push(tempMember);
          })
          temp.teamName = 'Team ' + i;
          temp.teamMembersList = memberList;
          teamList.push(temp);
        })
        
        let postData: any = {};
        postData.districtCode = this.locationDetails?.neW_LGD_DIST_CODE?.toString();
        postData.districtName = this.locationDetails?.neW_DISTRICT_NAME;
        postData.mandalCode = this.locationDetails?.lgD_MANDAL_CODE?.toString();
        postData.mandalName = this.locationDetails?.mandaL_NAME?.toString();
        postData.ruralUrban = this.isUrban ? 'U' : 'R';
        postData.createdBy = this.userData.userPrincipalName;
        postData.teamsList = teamList;
        postData.remarks = '';
        this.commonService.postRequest(this.commonConstants.saveJaganAnnaArogyaSurakshaTeamDetails, postData).subscribe({
          next: (responseData: any) => {
            if(responseData.result?.isSuccess) {
              this.commonService.commonErrorPopup('Alert', 'The team details are saved successfully.', 'md');
            } else {
              this.commonService.commonErrorPopup('Alert', responseData.result?.errorDescription, 'md')
            }
          },
          error: (error) => {
            console.log('client side', error);
          },
        });
    }
   
    }
   getTeamDetails() {
    this.commonService.getRequest(this.commonConstants.getJaganAnnaArogyaSurakshaTeamDetails + this.userData.userPrincipalName).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          let teamData: any = responseData.result?.jasTeamDetailsData;
          let teamCount: any = responseData.result?.teamCount;
          
          // let teamOneData: any = teamData.filter((item: any) => item.teamName?.trim() === 'Team 1')
          // let teamTwoData: any = teamData.filter((item: any) => item.teamName?.trim() === 'Team 2')
          // let teamThreeData: any = teamData.filter((item: any) => item.teamName?.trim() === 'Team 3')
          // let teamFourData: any = teamData.filter((item: any) => item.teamName?.trim() === 'Team 4')
          // let teamFiveData: any = teamData.filter((item: any) => item.teamName?.trim() === 'Team 5')
          let buildTeamDetails: any = [];

          for(let i = 0 ; i < teamCount ; i ++) {
            let temp: any = {};
            temp.teamName = 'Team ' + (i + 1);
            temp.teamMemberData = teamData.filter((item: any) => item.teamName?.trim() === 'Team '  + (i + 1));
            buildTeamDetails.push(temp);
          }
          // if(teamOneData.length > 0) {
          //   let temp: any = {};
          //   temp.teamName = 'Team 1';
          //   temp.teamMemberData = teamOneData;
          //   buildTeamDetails.push(temp);
          // }
          // if(teamTwoData.length > 0) {
          //   let temp: any = {};
          //   temp.teamName = 'Team 2';
          //   temp.teamMemberData = teamTwoData;
          //   buildTeamDetails.push(temp);
          // }
          // if(teamThreeData.length > 0) {
          //   let temp: any = {};
          //   temp.teamName = 'Team 3';
          //   temp.teamMemberData = teamThreeData;
          //   buildTeamDetails.push(temp);
          // }
          // if(teamFourData.length > 0) {
          //   let temp: any = {};
          //   temp.teamName = 'Team 4';
          //   temp.teamMemberData = teamFourData;
          //   buildTeamDetails.push(temp);
          // }
          // if(teamFiveData.length > 0) {
          //   let temp: any = {};
          //   temp.teamName = 'Team 5';
          //   temp.teamMemberData = teamFiveData;
          //   buildTeamDetails.push(temp);
          // }
          console.log('buildTeamDetails', buildTeamDetails);
          if(buildTeamDetails.length > 0) {
            this.formTeamDetails(buildTeamDetails);
          }
        } else {
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
   } 
   formTeamDetails(data: any) {
    data.forEach((team: any, index: any) => {
      this.teamData.push(this.initTeam(team, index));
      const controls = this.teamData.at(index).get('teamMemberData') as FormArray;
      team.teamMemberData.forEach((item:any) => {
        controls.push(this.initTeamMember(item));
      })
    })
   }
}
