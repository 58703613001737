import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-old-adangal-view',
  templateUrl: './old-adangal-view.component.html',
  styleUrls: ['./old-adangal-view.component.css']
})
export class OldAdangalViewComponent implements OnInit {

  constructor(private commonService: CommonService) {
  }
  commonConstants : any = CommonConstants;
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  // screenMandal: any;
  // screenDistrict: any;
  // screenVillage: any;

  ngOnInit(): void {
    // this.getByEntitycode(this.certificateInfo.selectionCriteriaDetails?.district, 'RVDistrict');
    // this.getByEntitycode(this.certificateInfo.selectionCriteriaDetails?.mandalMunicipality, 'RVMandal');
    // this.getByEntitycode(this.certificateInfo.selectionCriteriaDetails?.villageWard, 'RVVillage');
  }

  // getByEntitycode(entityCode: any, entityType:any): any {
  //   console.log(entityCode,'entityCode')
  //   console.log(entityType,'entityType')
  //   this.commonService.getRequest(this.commonConstants.getByEntitycode + 'TE&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({
  //     next: (responseData: any) => { 
  //       let name =  responseData.result[0]?.name;
  //       if (entityType === 'RVDistrict') {
  //         this.screenDistrict = name;
  //       }else if (entityType === 'RVMandal') {
  //         this.screenMandal = name; 
  //       } else if (entityType === 'RVVillage') {
  //         this.screenVillage = name;
  //       } 
  //     },
  //     error: (error) => {
  //       console.log('client side', error);
  //     }
  // });
  // }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

  

}
