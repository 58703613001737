<div class="reportContainer">
    <div [formGroup]="form">
    <div class="row mb-3">
        
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="district" (change)="onDistrictChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
         <option value="All">All</option>
        <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
      </select>
    </div>

    
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
      <option value="All">All</option>
      <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
    </select>
   </div>
  
   
   <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">Village/Ward/Secretariat: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="village" (change)="onVillageChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.village.errors || f.village.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let village of villages" [ngValue]="village">{{village.secretariatName}}</option>
      </select>
    </div>
</div>

  <div class="tabClass">
        <ul class="nav nav-pills flex-column flex-sm-row"  class="navbar navbar-expand-lg navbar-light "> 
              <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li>
              </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)=" GetJaganannaSurakshaReportData()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
        </div>
      </div>
</div>
<div class="table-responsive tableFixHead" *ngIf="showReports">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th [attr.colspan]="noOfCol">Revenue Sadassulu Service Dashboard</th>
            </tr>
            <tr>
               <th rowspan="3">S.No</th>
               <th rowspan="3" *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'dname'">District Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mname'">Mandal Name<mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'seC_NAME'">Secretariat Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <!-- <th  rowspan="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_TOKENS'">No. of Tokens registered <mdb-icon fas icon="sort"></mdb-icon></th> -->
               <th  rowspan="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'toT_REQ'">No. of service requests raised <mdb-icon fas icon="sort"></mdb-icon></th>
               <th colspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items">Open</th>
               <th colspan="4" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items">Closed</th>
              </tr>
            <tr>
                <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opN_BYND_SLA_REQ'">Beyond SLA <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opN_WTH_SLA_REQ'">Within SLA <mdb-icon fas icon="sort"></mdb-icon></th>
                <th colspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" >Beyond SLA<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  colspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" >Within SLA <mdb-icon fas icon="sort"></mdb-icon></th>
               </tr>
               <tr>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsD_BYND_SLA_APRVD_REQ'">Approved <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsD_BYND_SLA_REJ_REQ'">Rejected <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsD_WTH_SLA_APRVD_REQ'">Approved <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clsD_WTH_SLA_REJ_REQ'">Rejected <mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>
           </thead>

        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise'">{{item.dname}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mname}}</td>
               <td *ngIf="format === 'Secretariat Wise'">{{item.seC_NAME}}</td>
               <!-- <td class="text-right">{{item.nO_OF_TOKENS}}</td> -->
                <td class="text-right">{{item.toT_REQ}}</td>
                <td class="text-right">{{item.opN_BYND_SLA_REQ}}</td>
                <td class="text-right">{{item.opN_WTH_SLA_REQ}}</td>
                <!-- <td class="text-right">{{item.}}</td>
                <td class="text-right">{{item.}}</td> -->
                <td class="text-right">{{item.clsD_BYND_SLA_APRVD_REQ}}</td>
                <td class="text-right">{{item.clsD_BYND_SLA_REJ_REQ}}</td>
                <td class="text-right">{{item.clsD_WTH_SLA_APRVD_REQ}}</td>
                <td class="text-right">{{item.clsD_WTH_SLA_REJ_REQ}}</td>
              <tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise'">{{totalItems[0].dname}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mname}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].seC_NAME}}</td>
                <td class="text-right">{{totalItems[0].nO_OF_TOKENS}}</td>
                <td class="text-right">{{totalItems[0].toT_REQ}}</td>
                <td class="text-right">{{totalItems[0].opN_BYND_SLA_REQ}}</td>
                <td class="text-right">{{totalItems[0].opN_WTH_SLA_REQ}}</td>
            <!-- <td class="text-right">{{totalItems[0].}}</td>
            <td class="text-right">{{totalItems[0].}}</td> -->
            <td class="text-right">{{totalItems[0].clsD_BYND_SLA_APRVD_REQ}}</td>
            <td class="text-right">{{totalItems[0].clsD_BYND_SLA_REJ_REQ}}</td>
            <td class="text-right">{{totalItems[0].clsD_WTH_SLA_APRVD_REQ}}</td>
            <td class="text-right">{{totalItems[0].clsD_WTH_SLA_REJ_REQ}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    <div class="mt-4 btnHide">
        <div class="float-left" *ngIf="items.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
</div>
</div>
<div class="table-responsive tableFixHead" #TABLE class="hideTable">
   <table class="tableCls" >
       <thead>
           <tr>
           
                <th [attr.colspan]="noOfCol">Revenue Sadassulu Service Dashboard</th>
            </tr>
           <tr>
               <th rowspan="3" width="100px">S.No</th>
               <th width="100px" rowspan="3" *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise'">District Name</th>
               <th rowspan="3" width="100px" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal Name</th>
               <th width="100px" rowspan="3" *ngIf="format === 'Secretariat Wise'">Secretariat Name</th>
               <!-- <th width="100px" rowspan="3">No. of Tokens registered</th> -->
               <th width="100px" rowspan="3">No. of service requests raised</th>
               <th width="100px" colspan="2">Open</th>
               <th width="100px" colspan="4">Closed</th>
              </tr>
               <tr>
                <th rowspan="2" width="100px">Beyond SLA</th>
                <th rowspan="2" width="100px">Within SLA</th>
                <th colspan="2" width="100px">Beyond SLA</th>
                <th colspan="2" width="100px">Within SLA</th>
                </tr>
                <tr>
                  <th width="100px">Approved</th>
                  <th width="100px">Rejected</th>
                  <th width="100px">Approved</th>
                  <th width="100px">Rejected</th>
                  </tr>
       </thead>

       <tbody *ngIf="items && items.length > 0">
           <tr *ngFor="let item of items;let i = index;">
               <td width="100px">{{i + 1}}</td>
               <td width="100px" *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise'">{{item.dname}}</td>
               <td width="100px" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mname}}</td>
               <td width="100px" *ngIf="format === 'Secretariat Wise'">{{item.seC_NAME}}</td>
                <!-- <td width="100px">{{item.nO_OF_TOKENS}}</td> -->
               <td width="100px">{{item.toT_REQ}}</td>
               <td width="100px">{{item.opN_BYND_SLA_REQ}}</td>
               <td width="100px">{{item.opN_WTH_SLA_REQ}}</td>
                <!-- <td width="100px">{{item.}}</td>
                <td width="100px">{{item.}}</td> -->
                <td width="100px">{{item.clsD_BYND_SLA_APRVD_REQ}}</td>
                <td width="100px">{{item.clsD_BYND_SLA_REJ_REQ}}</td>
                <td width="100px">{{item.clsD_WTH_SLA_APRVD_REQ}}</td>
                <td width="100px">{{item.clsD_WTH_SLA_REJ_REQ}}</td>
                </tr>
           <tr *ngIf="totalItems && totalItems.length > 0">
            <td></td>
            <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise'">{{totalItems[0].dname}}</td>
            <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mname}}</td>
            <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].seC_NAME}}</td>
           <td >{{totalItems[0].nO_OF_TOKENS}}</td>
           <td >{{totalItems[0].toT_REQ}}</td>
           <td >{{totalItems[0].opN_BYND_SLA_REQ}}</td>
           <td >{{totalItems[0].opN_WTH_SLA_REQ}}</td>
           <!-- <td >{{totalItems[0].}}</td>
           <td >{{totalItems[0].}}</td> -->
           <td >{{totalItems[0].clsD_BYND_SLA_APRVD_REQ}}</td>
           <td >{{totalItems[0].clsD_BYND_SLA_REJ_REQ}}</td>
           <td >{{totalItems[0].clsD_WTH_SLA_APRVD_REQ}}</td>
           <td >{{totalItems[0].clsD_WTH_SLA_REJ_REQ}}</td>
           </tr>
       </tbody>
       <tbody *ngIf="items && items.length === 0">
           <tr>
              <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
       </tbody>
   </table>
  </div>
  