import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import { NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants } from '../../constants/common-constants.component';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { CommonMsgModalComponent } from '../../shared/common-msg-modal/common-msg-modal.component';

import { DatePipe } from '@angular/common';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import {
  CustomAdapter,
  CustomDateParserFormatter,
} from '../../shared/common.service';

@Component({
  selector: 'app-payment-reconciliation-approval',
  templateUrl: './payment-reconciliation-approval.component.html',
  styleUrls: ['./payment-reconciliation-approval.component.css'],
  providers: [
    NgbNavConfig,
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe,
  ],
})

export class PaymentReconciliationApprovalComponent implements OnInit {

  districts: any[] = [];
  mandals: any[] = [];
  villages: any[] = [];
  public paymentModes = [
    {title: 'ICICI Challan', value:'ICICI Challan'},
    {title: 'BILLDESK', value:'BILLDESK'}
  ];
  public AuditorRemark:string = "";
  public SLNo:string = "";
  public Action:string = "";
  modalRef: any; 
  public submitted: Boolean = false;
  public showDetails: Boolean = false;
  commonConstants: any = CommonConstants;
  TomaxDate: any;
  TominDate: any;
  FrommaxDate: any;
  FromminDate: any;
  currentDate = new Date();
  
  paymentReconciliation: FormGroup;
  items: any[] = [];
  
  constructor(
    private config: NgbNavConfig,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private modalService: NgbModal,
    private datePipe: DatePipe
  ) {

    this.getDistricts();

    this.TomaxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
    this.TominDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
   
    this.FrommaxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };
    this.FromminDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate(),
    };

    let currentDateFormat = this.currentDate.getDate()+"-"+(this.currentDate.getMonth()+1)+"-"+this.currentDate.getFullYear();
    this.paymentReconciliation = this.formBuilder.group({
      periodFrom: [currentDateFormat, [Validators.required]],
      periodTo: [currentDateFormat, [Validators.required]],
      payment_mode: ["All", [Validators.required]],
      District: ["All", [Validators.required]],
      Mandal:  ["All", [Validators.required]],
      Village:  ["All", [Validators.required]],
      status:  ["Open", [Validators.required]],
    }, { validator: this.checkDates.bind(this) });
    

    config.destroyOnHide = false;
    config.roles = false;

  }

  ngOnInit(): void {
    this.submit();
  }

  checkDates(group: FormGroup) {
    if((group.controls.periodFrom.errors || group.controls.periodTo.errors) && !group.controls.periodTo.errors?.invalidDate){
      return;
    }
    const periodFrom = group.controls.periodFrom.value;
    const periodTo = group.controls.periodTo.value;
    if(periodFrom && periodTo) {
      if(this.commonService.formatDate(periodFrom) == "Invalid date") {
        group.controls.periodFrom.setErrors({ invalid: true});
      } else if(this.commonService.formatDate(periodTo) == "Invalid date") {
        group.controls.periodTo.setErrors({ invalid: true});
      } else if(this.commonService.formatDate(periodFrom) > this.commonService.formatDate(periodTo)){
        group.controls.periodTo.setErrors({ invalidDate: true });
      } else {
        group.controls.periodTo.setErrors(null);
      }
    }
  }

  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getDistricts).subscribe({
      next: (responseData: any) => { 
        this.districts = this.commonService.sortData(responseData.result);
        this.commonService.setMasterData('districts', this.districts);
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  onDistrictChange() {
    this.mandals = [];
    this.villages = [];
    this.commonService.getRequest(this.commonConstants.getMandals + this.f['District'].value.id).subscribe({
      next: (responseData: any) => { 
          this.mandals = this.commonService.sortData(responseData.result);
          this.f.Mandal.patchValue("All");  
          this.f.Village.patchValue("All");
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  onMandalChange() {
    this.villages = [];
    this.commonService.getRequest(this.commonConstants.getVillages + this.f['Mandal'].value.id).subscribe({
      next: (responseData: any) => { 
          this.villages = this.commonService.sortData(responseData.result);
          this.f.Village.patchValue("All");
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  get f() {
    return this.paymentReconciliation.controls;
  }

  submit(): any {  
    this.showDetails = true; 
    this.submitted = true;    
    if (!this.paymentReconciliation.valid) {
      return false;
    } else {
      let searchData = {
        "fromDate": this.commonService.formatDate(this.f['periodFrom'].value),
        "toDate": this.commonService.formatDate(this.f['periodTo'].value),
        "modeOfPayment": this.f['payment_mode'].value,
        "district": this.f['District'].value == "All" ? "All" : this.f['District'].value?.entityCode,
        "mandal": this.f['Mandal'].value == "All" ? "All" :  this.f['Mandal'].value?.entityCode,
        "secretariat": this.f['Village'].value == "All" ? "All" :  this.f['Village'].value?.entityCode,
        "auditorStatus": this.f['status'].value,
      };
     
      this.getDataAPI(searchData);
    }
  } 

  getDataAPI(postData:any){
    this.items = [];
    this.commonService
    .postRequest(this.commonConstants.GetPaymentReconciliationListForApprovals, postData)
    .subscribe({
      next: (responseData: any) => {
        if(responseData.result.isSuccess) {
          this.items = responseData.result.paymentReconciliationListForApprovals;
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
  
  approveReject(action: any, slNo: any,targetModal: any): any {
    this.SLNo = slNo;
    this.Action = action;   
    this.modalRef = this.modalService.open(targetModal, { size: 'md', centered: true });   
    this.modalRef.result.then((result: any) => {
      this.AuditorRemark = "";
      this.SLNo = "";
      this.Action = ""; 
      if(result == "ok")  {   
        this.submit();
      }
    });     
  }

  closeRemarkPopup() {
    this.modalRef.close('close');
  }

  submitComment() {
    if(this.AuditorRemark.trim().length === 0) {
      this.commonService.commonErrorPopup('Alert', "Please enter remarks.", 'md');
      return;
    }
    const postData ={ "status": this.Action, "slNo": this.SLNo, "approverRemarks": this.AuditorRemark };
    this.commonService
    .postRequest(this.commonConstants.UpdatePaymentReconciliationCollectionStatus, postData)
    .subscribe({
      next: (responseData: any) => {
        if(responseData.result.isSuccess) {
          if(this.Action == "Approved") {
            this.commonService.commonErrorPopup('Success', "Payment issue request is approved successfully.", 'md');
          } else if(this.Action == "Rejected")  {
            this.commonService.commonErrorPopup('Success', "Payment issue request is rejected successfully.", 'md');
          }
          this.modalRef.close('ok'); 
        } else {
          this.commonService.commonErrorPopup('Alert', responseData.result.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });    
  }
}
