 <div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo):'-'}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.remarks ? (basicInfo?.applicantInfo?.remarks) : '-'}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Service Type:</label>
                <div class="valueCls">{{certificateInfo?.serviceType === 'crackerLicenceForDiwali' ? 'Cracker License For Diwali' : 'Grant of License for storage and Explosive Material'}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Status:</label>
                <div class="valueCls">{{certificateInfo?.status}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Applicant Qualification:</label>
                <div class="valueCls">{{certificateInfo.applicantQualification ? (certificateInfo.applicantQualification) : '-'}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Applicant Experience: 
                    :</label>
                <div class="valueCls">{{certificateInfo.applicantExperience ? (certificateInfo.applicantExperience) : '-'}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="certificateInfo?.status === 'Company/Firm'">
            <div class="fieldHolder">
                <label class="labelCls">Properator Name: 
                    :</label>
                <div class="valueCls">{{certificateInfo.properatorName ? (certificateInfo.properatorName) :'-'}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="certificateInfo?.status === 'Company/Firm'">
            <div class="fieldHolder">
                <label class="labelCls">Company Address: 
                    :</label>
                <div class="valueCls">{{certificateInfo.companyAddress ? (certificateInfo.companyAddress) :'-'}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Technical Person Employed : 
                     
                    </label>
                <div class="valueCls">{{certificateInfo.isTechnicalPersonEmployed ? 'Yes' :'No' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="certificateInfo.isTechnicalPersonEmployed">
            <div class="fieldHolder">
                <label class="labelCls">Technical Person Qualification : 
                     
                    </label>
                <div class="valueCls">{{certificateInfo.technicalPersonQualification ? (certificateInfo.technicalPersonQualification) : '-'}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="certificateInfo.isTechnicalPersonEmployed">
            <div class="fieldHolder">
                <label class="labelCls">Technical Person Experience : 
                     
                    </label>
                <div class="valueCls">{{certificateInfo.technicalPersonExperience ? ( certificateInfo.technicalPersonExperience) : '-'}}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Service Specific Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Door No / Street Name :</label>
                <div class="valueCls">{{certificateInfo?.explosiveMaterialAddress?.doorNo ? (certificateInfo?.explosiveMaterialAddress?.doorNo): '-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Locality:</label>
                <div class="valueCls">{{certificateInfo?.explosiveMaterialAddress?.streetName ? (certificateInfo?.explosiveMaterialAddress?.streetName): '-'}}</div>
            </div>

            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo?.explosiveMaterialAddress?.districtName ? (certificateInfo?.explosiveMaterialAddress?.districtName): '-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo?.explosiveMaterialAddress?.mandalMunicipalityName ? (certificateInfo?.explosiveMaterialAddress?.mandalMunicipalityName): '-'}}</div>
            </div>
           
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{certificateInfo?.explosiveMaterialAddress?.villageWardName ? (certificateInfo?.explosiveMaterialAddress?.villageWardName): '-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">StreetName:</label>
                <div class="valueCls">{{certificateInfo?.explosiveMaterialAddress?.streetName ? (certificateInfo?.explosiveMaterialAddress?.streetName): '-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Police Station:</label>
                <div class="valueCls">{{certificateInfo?.policeStation ? (certificateInfo?.policeStation): '-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Railway Station:</label>
                <div class="valueCls">{{certificateInfo.railwayStation ? (certificateInfo.railwayStation): '-'}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
     
        <div class="fieldHolder">
            <label class="labelCls">Purpose of Licence:</label>
            <div class="valueCls">{{certificateInfo?.licencePurpose ? (certificateInfo?.licencePurpose):'-'}}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Are the Premises attached to a licensed Manufacturer explosive?:</label>
            <div class="valueCls">{{certificateInfo.isPremisesAttached ? 'Yes': 'No'}}</div>
        </div>
        <div class="fieldHolder" *ngIf="certificateInfo.isPremisesAttached">
            <label class="labelCls">Premises Attached Remarks:</label>
            <div class="valueCls">{{certificateInfo.premisesAttachedRemarks ? (certificateInfo.premisesAttachedRemarks):'-'}}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Have the premises previously been licenced:</label>
            <div class="valueCls">{{certificateInfo.isPremisesLicenced ? 'Yes': 'No'}}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Licenced Premises Remarks:</label>
            <div class="valueCls">{{certificateInfo.licencedPremisesRemarks ? (certificateInfo.licencedPremisesRemarks) : '-'}}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Details of amendment Proposed /additional Information if any ? 
                :</label>
            <div class="valueCls">{{certificateInfo.amendmentProposedRemarks}}</div>
        </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="certificateInfo.isPremisesLicenced">
            <div class="fieldHolder">
                <label class="labelCls">Has the applicant been convicted of any offence or Ordered to execute any bound under chapter VIII of the code or criminal Procedure 1973 during last 10 years ? </label>
                <div class="valueCls">{{certificateInfo.isApplicantConvictedAnyOffence ? 'Yes' :'No'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Has Applicant Convicted if any? - Remarks </label>
                <div class="valueCls">{{certificateInfo.applicantConvictedAnyOffenceRemarks ? certificateInfo.applicantConvictedAnyOffenceRemarks :'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">A)Particulars of other license if any under explosive act, 1884 by applicant during the last 10 years
                    B)Was any license cancelled/not renewal ?:</label>
                <div class="valueCls">{{certificateInfo.isLicenceCancelled ? 'Yes' : 'No'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Licence Remarks </label>
                <div class="valueCls">{{certificateInfo.licenceCancelledRemarks ? certificateInfo.licenceCancelledRemarks :'-'}}</div>
            </div>
        </div>
    </div>
  
    
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                <tr> 
                    <th>Explosive Name</th>
                    <th>Class</th>
                    <th>Division</th>
                    <th>One Time Quantity</th>
                    <th>One Month Quantity</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of certificateInfo?.explosiveProposedList">
                        <td>{{item?.explosiveName ? (item?.explosiveName) : '-'}}</td>
                        <td class="value">{{item?.class ? (item?.class) : '-'}}</td>
                        <td class="value">{{item?.division ? (item?.division) :'-'}}</td>
                        <td>{{item?.oneMonthQuantity ? (item?.oneMonthQuantity) :'-'}}</td>
                        <td>{{item?.oneTimeQuantity ? (item?.oneTimeQuantity) :'-'}}</td>

                    </tr>
                </tbody>
                </table>
            </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Application File'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Identity Proof File'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Identity Proof:                    </label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Address Proof File'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Address Proof 
                        :</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Verifiication Report File'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Verification Report from Police 
                        :</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'No Objection Fire File'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">No Objection from Fire Service Department 
                        :</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'No Objection Local File'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">No Objection from local body:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'PreviousFile Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Particulars of Previous license if any *
                        :</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Recent Passport size Photograph'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Photo:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div> 



