<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Informant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Informant Name:</label>
                <div class="valueCls">{{certificateInfo?.informantName?.firstName ? (certificateInfo?.informantName?.firstName) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Relation with Farmer:</label>
                <div class="valueCls">{{certificateInfo?.relationwithFarmer ? (certificateInfo?.relationwithFarmer) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Land Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{ certificateInfo.details?.districtName }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Mandal:</label>
            <div class="valueCls">{{certificateInfo.details?.mandalMunicipalityName }}</div>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Village/Ward/Secretariat:</label>
                <div class="valueCls">{{ certificateInfo.details?.villageWardName }}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                <tr> 
                    <th>Village</th>
                    <th>1B Khata No.</th>
                    <th>Survey No.</th>
                    <th>Land Type (Dry/Wet)</th>
                    <th>Extent Of Land</th>
                    <th>Land Units</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of certificateInfo?.landInfo">
                        <td>{{item.villageName ? (item.villageName) : '-' }}</td>
                        <td class="value">{{item.khataNo ? (item.khataNo) : '-' }}</td>
                        <td class="value">{{item.surveyNo ? (item.surveyNo) : '-' }}</td>
                        <td>{{item.landType ? (item.landType) : '-' }}</td>
                        <td class="value">{{item.extentofLand ? (item.extentofLand) : '-' }}</td>
                        <td class="value">{{item.landUnit ? (item.landUnit) : '-' }}</td>
                    </tr>
                </tbody>
                </table>
            </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Scan Copy'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Scanned Copy of Pattadhar Passbook/Title Deed/1B Extract/Register sale deeds of the Lands:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Self Declare Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Self declaration by the Farmer for the lands owned by him/her:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>

