import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobSeekerRegComponent } from './job-seeker-reg/job-seeker-reg.component';
import { HumanResourcesRoutingModule } from './human-resources-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { JobSeekerRegViewComponent } from './job-seeker-reg-view/job-seeker-reg-view.component';

@NgModule({
  declarations: [
    JobSeekerRegComponent,
    JobSeekerRegViewComponent
  ],
  imports: [
    CommonModule,
    HumanResourcesRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    NgbModule
  ],
  exports: [
    CommonModule,
    JobSeekerRegViewComponent
 ]
})
export class HumanResourcesModule { }
