import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';
import { PdfViewerPopupComponent } from 'src/shared/pdf-viewer-popup/pdf-viewer-popup.component';


@Component({
  selector: 'app-land-assignment-view-new',
  templateUrl: './land-assignment-view-new.component.html',
  styleUrls: ['./land-assignment-view-new.component.css']
})
export class LandAssignmentViewNewComponent implements OnInit {
  commonConstants : any = CommonConstants;
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  requisitionID: any;
  departmentCode :any = "RV";
  departmentName :any = "Revenue";
  aadharnumberfromsoldier: any;
  redirectedFrom: any;
  presentDistrict: any;
  presentMandal: any;
  presentVillage: any;
  permanentDistrict: any;
  permanentMandal: any;
  permanentVillage: any;
  @Input() commonFormAddedFields : any;

  constructor(public commonService: CommonService,  private router: Router,
    private currentRoute: ActivatedRoute, private modalService: NgbModal,config: NgbModalConfig) { }

  ngOnInit(): void {
    this.currentRoute.queryParams.subscribe((params) => {
      //this.requisitionID = 'RV-AgricultureIncomeCertificate-1023'; 
      this.requisitionID = params?.requisitionid;
      this.departmentCode = params?.department;
      this.redirectedFrom = params?.from;
    }); 
    if(this.certificateInfo?.categoryofServicepersonOrSoldie == 'Ex-Serviceperson'){
      this.certificateInfo.categoryofServicepersonOrSoldie = 'Ex-Serviceperson';
    }
    else if(this.certificateInfo?.categoryofServicepersonOrSoldie == 'ServingSoldier'){
      this.certificateInfo.categoryofServicepersonOrSoldie = 'Serving Soldier';
    }
    else if(this.certificateInfo?.categoryofServicepersonOrSoldie == 'DeceasedSoldier'){
      this.certificateInfo.categoryofServicepersonOrSoldie = 'Deceased Soldier';
    }

    if(this.certificateInfo?.relation == 'self'){
      this.certificateInfo.relation = 'Self';
    } else if(this.certificateInfo?.relation == 'War Widows'){
      this.certificateInfo.relation = 'War Widows/ Next of Kin (NoK) of soldier killed in action (Battle Casualties)';
    } else if(this.certificateInfo?.relation == 'Widows'){
      this.certificateInfo.relation = 'Widows/ Next of Kin (NoK) of soldier died while in service (Physical Casualties)';
    } 
  }

 



  viewFile(blobRef:any, fileName:any) {
    console.log('file Extension', this.checkFileExtension(fileName));
    this.commonService.getRequest(this.commonConstants.viewFile + blobRef).subscribe({
      next: (responseData: any) => { 
        let fileInfo =  responseData.result.fileContents;
        const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
        modalRef.componentInstance.sourceFile = fileInfo;
        modalRef.componentInstance.showPrintDownload = false;
        if (this.checkFileExtension(fileName) !== 'pdf') {
          modalRef.componentInstance.isImage = true;
          modalRef.componentInstance.sourceFile = 'data:image/png;base64,' +fileInfo;
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  
  checkFileExtension(name: any) {
    let fileNameArr = name.split('.');
    return fileNameArr[fileNameArr?.length - 1]?.toLowerCase();
  }
   
  downloadFile(file: any) {
    this.download.emit(file);
  }

}

