import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-patta-subdivision-auto-mutation-view',
  templateUrl: './patta-subdivision-auto-mutation-view.component.html',
  styleUrls: ['./patta-subdivision-auto-mutation-view.component.css']
})
export class PattaSubdivisionAutoMutationViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  members: any[]=[];

  constructor() { }

  ngOnInit(): void {
    this.members = this.certificateInfo?.documentDetailsList;
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

}
