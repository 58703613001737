import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';
@Component({
  selector: 'app-encumbrance-certificate-view',
  templateUrl: './encumbrance-certificate-view.component.html',
  styleUrls: ['./encumbrance-certificate-view.component.css']
})
export class EncumbranceCertificateViewComponent implements OnInit {

  commonConstants : any = CommonConstants;

  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  screenMandal: any;
  screenDistrict: any;
  screenBuildingVillage: any;
  screenBuildingAlias: any;
  screenLandVillage: any;
  screenLandAlias: any;
  showDocList: Boolean = false;
  constructor(private commonService: CommonService) {
  }

  ngOnInit(): void {
  
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

  searchDocuments() {
    this.showDocList = true;
  }
  

}
