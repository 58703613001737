
<div class="reportContainer">
    <div  [formGroup]="form">

        <div class="row mb-3">
            
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Status : <span class="mandatoryCls">*</span></label>
            <select class="selectCls"  formControlName="status" (change)="onStatusChange()">
                <option value="All">All</option>
                <option value="Pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="Reject">Reject</option>
            </select>
            
            </div>

            

            
                
    </div>

    
    <div class="tabClass">
       
        <div class="btnHolder">
            <!-- <button  class="greenBtnCls float-right my-3" (click)="getReportData()">Get Reports</button> -->
            <button  class="greenBtnCls float-right mr-3 my-3" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" (click)="goToHome()">Home</button>
        </div>
      </div>

      <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="table-responsive tableFixHead" *ngIf="showReports">
        <table class="tableCls" >
            <thead> 
              
                <tr>
                    <th [attr.colspan]="noOfCol">SUOMOTO CASTE VERIFICATION REPORT</th>
                </tr>
                <tr>
                    <th width="100px">S.No</th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'" >District <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'" >Mandal <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'village'" >Village <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'" >Secretariat <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'revenuE_DIVISION'" >Revenue Division <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'revenuE_MANDAL'" >Revenue Mandal Name <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'requisitioN_ID'" >Application Number <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'citizeN_NAME'" >Applicant Name <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'caste'" >Caste <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'suB_CASTE'" >Sub Caste <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'apP_DATE'" >Action Taken date<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'actioN_TAKEN_DESG'" >Approved/Rejected by Designation <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'apP_STATUS'" >Approved/Rejected Status <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rejectioN_REMARKS'" >Remarks<mdb-icon fas icon="sort"></mdb-icon></th>


                    </tr>           
               
                
            </thead>
    
            <tbody *ngIf="items && items.length > 0">
                <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;"> 
                <td width="100px">{{ ((page -1) * pageSize) + (i + 1)}}</td>
                <td>{{item.districT_NAME}}</td>                
                <td >{{item.mandaL_NAME}}</td>
                <td >{{item.village}}</td>
                <td >{{item.secretariaT_NAME}}</td>
                <td >{{item.revenuE_DIVISION}}</td>
                <td >{{item.revenuE_MANDAL}}</td>  
                <td >{{item.requisitioN_ID}}</td> 
                <td >{{item.citizeN_NAME}}</td>
                <td >{{item.caste}}</td>
                <td >{{item.suB_CASTE}}</td> 
                <td >{{item.apP_DATE | date: 'dd/MM/yyyy'}}</td>   
                <td >{{item.actioN_TAKEN_DESG}}</td>   
                <td >{{item.apP_STATUS}}</td>   
                <td >{{item.rejectioN_REMARKS}}</td>           
            </tr>
           </tbody>
            <tbody *ngIf="items && items.length === 0">
                <tr>
                    <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="mt-4 btnHide" *ngIf="showReports">
            <div class="float-left" *ngIf="items.length > 0">
                <label>Requests per page: </label>
                <select class="selectCls pageSizeCls" [(ngModel)]="pageSize" [ngModelOptions]="{standalone: true}">
                    <option  value="10">10</option>
                    <option  value="20">20</option>
                    <option  value="30">30</option>
                    <option  value="40">40</option>
                    <option  value="50">50</option>
                </select> 
            </div>
            <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
            </ngb-pagination>
        </div>
    
    
    </div>
    </div>

    <!-- Download report -->
    
    <div class="table-responsive tableFixHead" #TABLE class="hideTable">
        <table class="tableCls" >
            <thead>
             <tr>
                 <th [attr.colspan]="noOfCol">SUOMOTO CASTE VERIFICATION REPORT</th>
             </tr>
            
             <tr>
                <th width="100px">S.No</th>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'" >District <mdb-icon fas icon="sort"></mdb-icon></th>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'" >Mandal <mdb-icon fas icon="sort"></mdb-icon></th>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'village'" >Village <mdb-icon fas icon="sort"></mdb-icon></th>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'" >Secretariat <mdb-icon fas icon="sort"></mdb-icon></th>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'revenuE_DIVISION'" >Revenue Division <mdb-icon fas icon="sort"></mdb-icon></th>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'revenuE_MANDAL'" >Revenue Mandal Name <mdb-icon fas icon="sort"></mdb-icon></th>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'requisitioN_ID'" >Application Number <mdb-icon fas icon="sort"></mdb-icon></th>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'citizeN_NAME'" >Applicant Name <mdb-icon fas icon="sort"></mdb-icon></th>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'caste'" >Caste <mdb-icon fas icon="sort"></mdb-icon></th>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'suB_CASTE'" >Sub Caste <mdb-icon fas icon="sort"></mdb-icon></th>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'apP_DATE'" >Action Taken date<mdb-icon fas icon="sort"></mdb-icon></th>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'actioN_TAKEN_DESG'" >Approved/Rejected by Designation <mdb-icon fas icon="sort"></mdb-icon></th>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'apP_STATUS'" >Approved/Rejected Status <mdb-icon fas icon="sort"></mdb-icon></th>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rejectioN_REMARKS'" >Remarks<mdb-icon fas icon="sort"></mdb-icon></th>


                </tr>  
                
            </thead>
     
            <tbody *ngIf="items && items.length > 0">
                
                <tr *ngFor="let item of items;let i = index;"> 
                    <td width="100px">{{ ((page -1) * pageSize) + (i + 1)}}</td>
                    <td>{{item.districT_NAME}}</td>                
                    <td >{{item.mandaL_NAME}}</td>
                    <td >{{item.village}}</td>
                    <td >{{item.secretariaT_NAME}}</td>
                    <td >{{item.revenuE_DIVISION}}</td>
                    <td >{{item.revenuE_MANDAL}}</td>  
                    <td >{{item.requisitioN_ID}}</td> 
                    <td >{{item.citizeN_NAME}}</td>
                    <td >{{item.caste}}</td>
                    <td >{{item.suB_CASTE}}</td> 
                    <td >{{item.apP_DATE | date: 'dd/MM/yyyy'}}</td>   
                    <td >{{item.actioN_TAKEN_DESG}}</td>   
                    <td >{{item.apP_STATUS}}</td>   
                    <td >{{item.rejectioN_REMARKS}}</td>           
                </tr>
                
            </tbody>
            <tbody *ngIf="items && items?.length === 0">
                <tr>
                    <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </table>
     </div>

     </div>
</div>
