<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Selected Criteria Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{this.certificateInfo.selectionCriteriaDetails?.districtName }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{this.certificateInfo.selectionCriteriaDetails?.villageWardName }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{this.certificateInfo.selectionCriteriaDetails?.mandalMunicipalityName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Secretariat:</label>
                <div class="valueCls">{{certificateInfo?.secretariatName ? (certificateInfo?.secretariatName) : '-' }}</div>
            </div>
           
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Survey No/ Sub Division:</label>
                <div class="valueCls">{{certificateInfo?.surveyNo ? (certificateInfo?.surveyNo) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Khata No:</label>
                <div class="valueCls">{{certificateInfo?.khataNo ? (certificateInfo?.khataNo) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Fasli Year:</label>
                <div class="valueCls">{{certificateInfo?.fasliYear ? (certificateInfo?.fasliYear) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Pattadhar Name:</label>
                <div class="valueCls">{{certificateInfo?.pattadharName?.firstName ? (certificateInfo?.pattadharName?.firstName) : '-' }}</div>
            </div>
        </div>
    </div>
</div>


