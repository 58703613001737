import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgForm } from '@angular/forms';
import {
  WhitespaceValidator,
  CustomAdapter,
  CustomDateParserFormatter,
} from 'src/shared/common.service';
import {
  fileExtensionValidator,
  fileSizeValidator,
  riceOrRationCardValidator,
} from 'src/shared/validators/file-validator';
import { CommonService } from '../../../shared/common.service';
// import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { CommonConstants } from 'src/constants/common-constants.component';
import {
  NgbNavConfig,
  NgbNavChangeEvent,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { ModalComponent } from 'src/shared/modal/modal.component'
import { CommonMsgModalComponent } from '../../../shared/common-msg-modal/common-msg-modal.component';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-income-certificate-view',
  templateUrl: './income-certificate-view.component.html',
  styleUrls: ['./income-certificate-view.component.css'],
})



export class IncomeCertificateViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();

  incomeUpdate : IncomeUpdate= {
    landsIncome:  0,
    businessIncome:  0,
    buildingsIncome:  0,
    labourIncome:  0,
    familyIncome:  0,
    otherIncome:  0,
    totalIncome:  0
    // landsIncome: this.certificateInfo.incomeSources?.incomeOnLands ? (this.certificateInfo.incomeSources?.incomeOnBusiness) : 0,
    //   businessIncome: this.certificateInfo.incomeSources?.incomeOnBusiness ? (this.certificateInfo.incomeSources?.incomeOnBusiness) : 0,
    //   buildingsIncome:  this.certificateInfo.incomeSources?.incomeOnBuildings ? (this.certificateInfo.incomeSources?.incomeOnBusiness) : 0,
    //   labourIncome:  this.certificateInfo.incomeSources?.incomeOnLabour ? (this.certificateInfo.incomeSources?.incomeOnBusiness) : 0,
    //   familyIncome: this.certificateInfo.incomeSources?.incomeOfFamily ? (this.certificateInfo.incomeSources?.incomeOnBusiness) : 0,
    //   otherIncome:  this.certificateInfo.incomeSources?.otherIncome ? (this.certificateInfo.incomeSources?.incomeOnBusiness) : 0,
    //   totalIncome:  this.certificateInfo.incomeSources?.totalIncome ? (this.certificateInfo.incomeSources?.incomeOnBusiness) : 0
  };

  
  
 numberOnly(event: { which: any; keyCode: any; }): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;

}

  ngOnInit(): void {

    this.incomeUpdate = {
      landsIncome: this.certificateInfo.incomeSources?.incomeOnLands ? (this.certificateInfo.incomeSources?.incomeOnLands) : 0,
      businessIncome: this.certificateInfo.incomeSources?.incomeOnBusiness ? (this.certificateInfo.incomeSources?.incomeOnBusiness) : 0,
      buildingsIncome:  this.certificateInfo.incomeSources?.incomeOnBuildings ? (this.certificateInfo.incomeSources?.incomeOnBuildings) : 0,
      labourIncome:  this.certificateInfo.incomeSources?.incomeOnLabour ? (this.certificateInfo.incomeSources?.incomeOnLabour) : 0,
      familyIncome: this.certificateInfo.incomeSources?.incomeOfFamily ? (this.certificateInfo.incomeSources?.incomeOfFamily) : 0,
      otherIncome:  this.certificateInfo.incomeSources?.otherIncome ? (this.certificateInfo.incomeSources?.otherIncome) : 0,
      totalIncome:  this.certificateInfo.incomeSources?.totalIncome ? (this.certificateInfo.incomeSources?.totalIncome) : 0
    };
    // console.log(this.incomeUpdate,'aaddaa',this.certificateInfo.incomeSources?.incomeOnLands)
    this.setvalueForIncome()
  }

  ngOnChanges(){
    this.incomeUpdate = {
      landsIncome: this.certificateInfo.incomeSources?.incomeOnLands ? (this.certificateInfo.incomeSources?.incomeOnLands) : 0,
      businessIncome: this.certificateInfo.incomeSources?.incomeOnBusiness ? (this.certificateInfo.incomeSources?.incomeOnBusiness) : 0,
      buildingsIncome:  this.certificateInfo.incomeSources?.incomeOnBuildings ? (this.certificateInfo.incomeSources?.incomeOnBuildings) : 0,
      labourIncome:  this.certificateInfo.incomeSources?.incomeOnLabour ? (this.certificateInfo.incomeSources?.incomeOnLabour) : 0,
      familyIncome: this.certificateInfo.incomeSources?.incomeOfFamily ? (this.certificateInfo.incomeSources?.incomeOfFamily) : 0,
      otherIncome:  this.certificateInfo.incomeSources?.otherIncome ? (this.certificateInfo.incomeSources?.otherIncome) : 0,
      totalIncome:  this.certificateInfo.incomeSources?.totalIncome ? (this.certificateInfo.incomeSources?.totalIncome) : 0
    };
    // console.log(this.incomeUpdate,'aaddaa',this.certificateInfo.incomeSources?.incomeOnLands)
    this.setvalueForIncome()
  }

  constructor(
   
  ) {
    
    // this.setvalueForIncome()
  }

 

  setvalueForIncome(){
    // console.log(this.certificateInfo)
   
  this.totalValue()
    
  }


  totalValue() {
    let finalValue =
      Number(this.incomeUpdate.landsIncome) +
      Number(this.incomeUpdate.businessIncome) +
      Number(this.incomeUpdate.buildingsIncome) +
      Number(this.incomeUpdate.labourIncome) +
      Number(this.incomeUpdate.familyIncome) +
      Number(this.incomeUpdate.otherIncome);
    this.incomeUpdate.totalIncome=finalValue;
    this.incomeUpdate = {
      landsIncome: this.incomeUpdate?.landsIncome ? (this.incomeUpdate?.landsIncome) : 0,
      businessIncome: this.incomeUpdate?.businessIncome ? (this.incomeUpdate?.businessIncome) : 0,
      buildingsIncome:  this.incomeUpdate?.buildingsIncome ? (this.incomeUpdate?.buildingsIncome) : 0,
      labourIncome: this.incomeUpdate?.labourIncome ? (this.incomeUpdate?.labourIncome) : 0,
      familyIncome: this.incomeUpdate?.familyIncome ? (this.incomeUpdate?.familyIncome) : 0,
      otherIncome:  this.incomeUpdate?.otherIncome ? (this.incomeUpdate?.otherIncome) : 0,
      totalIncome:  this.incomeUpdate?.totalIncome ? (this.incomeUpdate?.totalIncome) : 0,
    };
    // console.log(this.incomeUpdate,this.incomeUpdate.totalIncome,'this.incomeUpdate.totalIncome')
    localStorage.removeItem('incomeUpdate')
    localStorage.setItem('incomeUpdate', JSON.stringify(this.incomeUpdate) )
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
}

export interface IncomeUpdate {
  landsIncome: number,
  businessIncome: number,
  buildingsIncome: number,
  labourIncome: number,
  familyIncome: number,
  otherIncome: number,
  totalIncome: number,
}
