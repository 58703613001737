<div class="container-fluid">
    <div class="paymentGateway">
        <div class="">
            <br>
            <div class="row my-3">
               

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12"></div>

                <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3 clsbox">
                    
                    <h5 class="text-center mb-3"><b>PAYMENT GATEWAY</b> </h5>

                    <div class="paymentContainer">
                        <div class="orderSummary">
                            <h6 class="float-left">Payment Details</h6>
                        </div>
                        <div>
                            <label class="labelCls "><strong>Reference No : </strong>  </label>
                        </div>
                        <br>
                        <div>
                            <label class="labelCls">Amount To Pay</label>
                            <span><strong>{{ amountData[7] }}</strong></span>
                        </div>
                        <br>
                        <div>
                            <label class="labelCls">Surcharge Amount</label>
                            <span><strong>{{ amountData[5] }}</strong></span>
                        </div>
                        <br>
                        <div>
                            <label class="labelCls">Convenience Fee <span id="neft"><strong>NEFT</strong></span></label>
                            <span><strong>0</strong></span>
                        </div>
                        <br>
                        <div class="totalamount">
                            <label class="labelCls " id="sizeFont">Total Amount</label>
                            <span><strong>{{amountData[7] }}</strong></span>
                        </div>
                        <br>
                        <div class="text-center ">
                            <label class="labelCls1">
                                <fa-icon class="float-center" [icon]="faLock" size="2x"></fa-icon>&nbsp;&nbsp;&nbsp;
                                Secure Checkout by&nbsp;&nbsp;&nbsp; <img class="oneone"
                                    src="../../assets/images/billDesk.png">
                            </label>
                        </div>
                        <br>
                        <div class="row mb-3">
                            <div class="col col-lg-7 col-md-7 col-sm-12 col-xs-12">
                                <label class="labelCls">Select Payment Method</label>
                            </div>

                            <div class="col col-lg-5 col-md-5 col-sm-12 col-xs-12">
                                <strong> <input type="radio" name="mode" value="UPI" (click)="selectPaymentMode('UPI')"  [checked]="!isCashModeEnable"><span class="mx-1 radioCls">Online</span>
                                <ng-container *ngIf="isCashModeEnable">
                                    <input type="radio" name="mode" value="CASH" (click)="selectPaymentMode('CASH')" [checked]=true>
                                    <span class="mx-1 radioCls">Cash</span>
                                </ng-container>
                                </strong>
                            </div>
                        </div>

                        <div class="text-center ">
                            <button name="payment" class="btn btn-success col-lg-6" (click)="confirmPay()">Continue
                                Payment</button>
                        </div>
                        <br>
                        <div class="text-center ">
                            <a class="cancel" href="">Cancel Payment</a>
                        </div>
                      
                        <br>
                    </div>

                </div>
               
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12"></div>
            
            </div>
        </div>

    </div>
</div>

<div [className]="isActive ? 'imgLoader' : 'imgLoaderHide'">
    <img src="../../assets/loader.gif">
</div>