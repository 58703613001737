<!-- <div class="certificateInfo" *ngIf="certificateInfo">

  <div class="subHeadingCls mb-3">Applicant Details</div>
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="fieldHolder">
        <label class="labelCls">Ration Card No:</label>
        <div class="valueCls">
          {{
            basicInfo?.applicantInfo?.rationCardNo
              ? basicInfo?.applicantInfo?.rationCardNo
              : "-"
          }}
        </div>
      </div>
      <div class="fieldHolder">
        <label class="labelCls">Remarks:</label>
        <div class="valueCls">
          {{ basicInfo.remarks ? basicInfo.remarks : "-" }}
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="fieldHolder">
        <label class="labelCls">Applicant Type:</label>
        <div class="valueCls">
          {{
            certificateInfo.applicantType ? certificateInfo.applicantType : "-"
          }}
        </div>
      </div>
      <div class="fieldHolder">
        <label class="labelCls">Buyer KhataNo:</label>
        <div class="valueCls">
          {{
            certificateInfo.buyerKhataNo ? certificateInfo.buyerKhataNo : "-"
          }}
        </div>
      </div>
      <div class="fieldHolder">
        <label class="labelCls">Buyer Name:</label>
        <div class="valueCls">
          {{ certificateInfo.buyerName ? certificateInfo.buyerName : "-" }}
        </div>
      </div>
      <div class="fieldHolder">
        <label class="labelCls">Buyer Ration Card No:</label>
        <div class="valueCls">
          {{
            certificateInfo.buyerRationCardNo
              ? certificateInfo.buyerRationCardNo
              : "-"
          }}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo">
  <div class="subHeadingCls mb-3">Document Details</div>

  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="fieldHolder">
        <label class="labelCls">Nri Identity Number:</label>
        <div class="valueCls">
          {{ certificateInfo.nriIdNo ? certificateInfo.nriIdNo : "-" }}
        </div>
      </div>
      <div class="fieldHolder">
        <label class="labelCls">District Name:</label>
        <div class="valueCls">
          {{
            certificateInfo.documentAddressdetails?.districtName
              ? certificateInfo.documentAddressdetails?.districtName
              : "-"
          }}
        </div>
      </div>
      <div class="fieldHolder">
        <label class="labelCls">Mandal Name:</label>
        <div class="valueCls">
          {{
            certificateInfo.documentAddressdetails?.mandalMunicipalityName
              ? certificateInfo.documentAddressdetails?.mandalMunicipalityName
              : "-"
          }}
        </div>
      </div>
      <div class="fieldHolder">
        <label class="labelCls">Village Name</label>
        <div class="valueCls">
          {{
            certificateInfo.documentAddressdetails?.villageWardName
              ? certificateInfo.documentAddressdetails?.villageWardName
              : "-"
          }}
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="fieldHolder">
        <label class="labelCls">Division:</label>
        <div class="valueCls">
          {{ certificateInfo.division ? certificateInfo.division : "-" }}
        </div>
      </div>
      <div class="fieldHolder">
        <label class="labelCls">Mutation Category:</label>
        <div class="valueCls">
          {{
            certificateInfo.mutationCategory
              ? certificateInfo.mutationCategory
              : "-"
          }}
        </div>
      </div>
      <div class="fieldHolder">
        <label class="labelCls">Mutation SubCategory:</label>
        <div class="valueCls">
          {{
            certificateInfo.mutationSubCategory
              ? certificateInfo.mutationSubCategory
              : "-"
          }}
        </div>
      </div>
    </div>
  </div>
</div>
  <div class="certificateInfo" *ngIf="certificateInfo">
    <div class="row mb-3">
        <div class="col">
            <div class="tab">
                <table class="tableCls">
                    <thead>
                        <tr>
                <th>
                  <span *ngIf="certificateInfo?.mutationSubCategory == '03'"
                    >Donar Name</span
                  >
                  <span *ngIf="certificateInfo?.mutationSubCategory == '02'"
                    >Testator Name
                  </span>
                  <span
                    *ngIf="
                      certificateInfo?.mutationSubCategory == '01' ||
                      certificateInfo?.mutationSubCategory == ''
                    "
                    >Seller Name</span
                  >
                </th>
                <th>
                  <span *ngIf="certificateInfo?.mutationSubCategory == '03'"
                    >Donar Address</span
                  >
                  <span *ngIf="certificateInfo?.mutationSubCategory == '02'"
                    >Testator Address
                  </span>
                  <span
                    *ngIf="
                      certificateInfo?.mutationSubCategory == '01' ||
                      certificateInfo?.mutationSubCategory == ''
                    "
                    >Seller Address
                  </span>
                </th>
                <th>Survey Number/Sub Division</th>
                <th>Khata Number</th>
                <th>ExtentArea</th>
                <th>Document No</th>
                <th>Successor Name</th>
                <th>DeceasedName</th>
                <th>Relation with Deceased</th>
                <th>Name</th>
                <th>Father Name</th>
                <th>Total Extent</th>
                <th>Sub Registrar</th>
                <th>Doc Date(dd/mm/yyyy)</th>
                <th>Village</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of certificateInfo?.mutationCategoryInfo">
                <td>{{ item?.sellerAddress ? item?.sellerAddress : "-" }}</td>
                <td class="value">
                  {{ item?.surveyNo ? item?.surveyNo : "-" }}
                </td>
                <td class="value">{{ item?.khataNo ? item?.khataNo : "-" }}</td>
                <td>{{ item?.extentArea ? item?.extentArea : "-" }}</td>
                <td>{{ item?.documentNo ? item?.documentNo : "-" }}</td>

                <td class="value">
                  {{ item?.successorName ? item?.successorName : "-" }}
                </td>
                <td class="value">
                  {{ item?.deceasedName ? item?.deceasedName : "-" }}
                </td>
                <td>
                  {{
                    item?.relationwithDeceased
                      ? item?.relationwithDeceased
                      : "-"
                  }}
                </td>
                <td>{{ item?.name ? item?.name : "-" }}</td>
                <td class="value">
                  {{ item?.fatherName ? item?.fatherName : "-" }}
                </td>
                <td class="value">
                  {{ item?.totalExtent ? item?.totalExtent : "-" }}
                </td>
                <td>{{ item?.subRegistrar ? item?.subRegistrar : "-" }}</td>
                <td>
                  {{
                    item?.authenticationType ? item?.authenticationType : "-"
                  }}
                </td>
                <td>{{ item?.documentDate ? item?.documentDate : "-" }}</td>

                <td>{{ item?.village ? item?.village : "-" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

<div class="certificateInfo">
  <div class="row">
    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <ng-container *ngIf="fileList && fileList.length !== 0">
        <div class="subHeadingCls mb-3">List of Documents</div>
        <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
          <div *ngIf="file.documentType === 'Application Form'">
            <span class="numberCls">{{ i + 1 }}</span
            ><label class="labelCls">Application Form:</label>
            <div class="valueCls">
              <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a
              ><span (click)="downloadFile(file)" class="download ml-2"></span>
            </div>
          </div>
          <div *ngIf="file.documentType === 'Registered Document Copies'">
            <span class="numberCls">{{ i + 1 }}</span
            ><label class="labelCls">Income Certificate/ Ration card:</label>
            <div class="valueCls">
              <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a
              ><span (click)="downloadFile(file)" class="download ml-2"></span>
            </div>
          </div>
          <div *ngIf="file.documentType === 'Old Pattadar Passbook'">
            <span class="numberCls">{{ i + 1 }}</span
            ><label class="labelCls">Income Certificate/ Ration card:</label>
            <div class="valueCls">
              <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a
              ><span (click)="downloadFile(file)" class="download ml-2"></span>
            </div>
          </div>
          <div *ngIf="file.documentType === 'Tax Receipt'">
            <span class="numberCls">{{ i + 1 }}</span
            ><label class="labelCls">Income Certificate/ Ration card:</label>
            <div class="valueCls">
              <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a
              ><span (click)="downloadFile(file)" class="download ml-2"></span>
            </div>
          </div>
          <div *ngIf="file.documentType === 'Signature'">
            <span class="numberCls">{{ i + 1 }}</span
            ><label class="labelCls">Signature:</label>
            <div class="valueCls">
              <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a
              ><span (click)="downloadFile(file)" class="download ml-2"></span>
            </div>
          </div>
          <div *ngIf="file.documentType === 'Passport size photo'">
            <span class="numberCls">{{ i + 1 }}</span
            ><label class="labelCls">Passport size photo:</label>
            <div class="valueCls">
              <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a
              ><span (click)="downloadFile(file)" class="download ml-2"></span>
            </div>
          </div>
          <div *ngIf="file.documentType === 'Identity of the NRI'">
            <span class="numberCls">{{ i + 1 }}</span
            ><label class="labelCls">Identity of the NRI:</label>
            <div class="valueCls">
              <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a
              ><span (click)="downloadFile(file)" class="download ml-2"></span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
 -->

<div class="certificateInfo" *ngIf="certificateInfo">
  <!--  <div class="subHeadingCls mb-3">Applicant Details</div> -->
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="fieldHolder">
          <label class="labelCls">Are you availing this service for the purpose of Grievance?:</label>
          <div class="valueCls">{{certificateInfo?.grievanceQestion ? (certificateInfo?.grievanceQestion) : '-' }}</div>
      </div>
      <div class="fieldHolder">
          <label class="labelCls">Grievance Number:</label>
          <div class="valueCls">{{certificateInfo?.grievanceNumber ? (certificateInfo?.grievanceNumber) : '-' }}</div>
      </div>           
        
                    
  </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="fieldHolder">
        <label class="labelCls">Application For:</label>
        <div class="valueCls">
          {{
          certificateInfo?.serviceType
          }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="certificateInfo" *ngIf="certificateInfo">
  <div class="subHeadingCls mb-3">Land Details</div>

  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="fieldHolder">
        <label class="labelCls">District:</label>
        <div class="valueCls">{{certificateInfo?.documentAddressdetails?.districtName}}</div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="fieldHolder">
        <label class="labelCls">Mandal:</label>
        <div class="valueCls">{{certificateInfo?.documentAddressdetails?.mandalMunicipalityName }}</div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="fieldHolder">
        <label class="labelCls">Village: </label>
        <div class="valueCls">{{certificateInfo?.documentAddressdetails?.villageWardName}}</div>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="fieldHolder">
        <label class="labelCls">Division: </label>
        <div class="valueCls">{{certificateInfo?.division.split(",")[1] }}</div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
      <div class="fieldHolder">
        <label class="labelCls">Selected Aadhar/Khata:</label>
        <div class="valueCls">{{certificateInfo?.selectedAadharKhata }}</div>
      </div>
    </div>
  </div>

</div>

<div class="certificateInfo" *ngIf="certificateInfo">
  <div class="subHeadingCls mb-3">ROR/Adangal Details</div>

  <div class="row mb-3">
    <div class="col">
      <div class="tab">
        <table class="tableCls">
          <thead>
            <tr>
              <th>Serial No</th>
              <th>Survey No</th>
              <th>Total Extent</th>
              <th>Un Cultivated Extent</th>
              <th>Cultivated Extent</th>
              <th>Land Nature</th>
              <th>Tax</th>
              <th>Land Classification</th>
              <th>Water Source</th>
              <th>Water Source Extent</th>
              <th>Khata Number</th>
              <th>Pattadar Name</th>
              <th>Pattadar Father /Husband Name</th>
              <th>Occupant Name</th>
              <th>Occupant Father /Husband Name</th>
              <th>Occupant Extent</th>
              <th>Enjoyment Nature</th>
              <th>Acquisition Rights</th>
            </tr>
          </thead>
          <tbody *ngIf="rorDetailsTable && rorDetailsTable?.length > 0">
            <tr *ngFor="let item of rorDetailsTable;">
              <td>{{item.pSerialNo}}</td>
              <td>{{item.pSurvey_no}}</td>
              <td>{{item.pTotal_Extent}}</td>
              <td>{{item.pUncultivated_Land}}</td>
              <td>{{item.pCultivatable_Land}}</td>
              <td>{{item.pLand_Nature}}</td>
              <td>{{item.pTax}}</td>
              <td>{{item.pLand_Classification?.replaceAll(' ','')}}</td>
              <td>{{item.pWater_Resource}}</td>
              <td>{{item.pAyakat_Extent}}</td>
              <td>{{item.pKhata_Number}}</td>
              <td>{{item.pPattadar_Name}}</td>
              <td>{{item.pPattadar_Father_Name}}</td>
              <td>{{item.pOccupant_Name}}</td>
              <td>{{item.pOccupant_Father_Name}}</td>
              <td>{{item.pOccupant_Extent}}</td>
              <td>{{item.pEnjoyment_Nature}}</td>
              <td>{{item.pAcquiredReason}}</td>
            </tr>
          </tbody>
          <tbody *ngIf="rorDetailsTable && rorDetailsTable?.length === 0">
            <tr>
              <td colspan="18" class="text-center">No records found.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div *ngIf="certificateInfo?.serviceType=='Mutation for Transactions'">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Mutation Category:</label>
          <div class="valueCls">{{certificateInfo?.mutationCategory}}</div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="certificateInfo?.successionSubCatg !=''">
        <div class="fieldHolder">
          <label class="labelCls">Succession Category:</label>
          <div class="valueCls">{{(certificateInfo?.successionSubCatg) === 'FMC (Single Legal Heir) ? Mutation by FMC : (certificateInfo?.successionSubCatg)'}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Mutation for Transactions -->
<div *ngIf="certificateInfo?.serviceType=='Mutation for Transactions'">

  <div class="certificateInfo" *ngIf="certificateInfo">
    <div class="subHeadingCls mb-3">Executant Land Details</div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">District:</label>
          <div class="valueCls">{{certificateInfo?.documentAddressdetails?.districtName}}</div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Mandal:</label>
          <div class="valueCls">{{certificateInfo?.documentAddressdetails?.mandalMunicipalityName }}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Village: </label>
          <div class="valueCls">{{certificateInfo?.documentAddressdetails?.villageWardName}}</div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Division: </label>
          <div class="valueCls">{{certificateInfo?.division.split(",")[1] }}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Selected Aadhar/Khata:</label>
          <div class="valueCls">{{certificateInfo?.selectedAadharKhata}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="certificateInfo" *ngIf="certificateInfo">
    <div class="subHeadingCls mb-3"> ROR/Adangal Details </div>

    <div class="row mb-3">
      <div class="col">
        <div class="tab">
          <table class="tableCls">
            <thead>
              <tr>
                <th>Serial No</th>
                <th>Survey No</th>
                <th>Total Extent</th>
                <th>Un Cultivated Extent</th>
                <th>Cultivated Extent</th>
                <th>Land Nature</th>
                <th>Tax</th>
                <th>Land Classification</th>
                <th>Water Source</th>
                <th>Water Source Extent</th>
                <th>Khata Number</th>
                <th>Pattadar Name</th>
                <th>Pattadar Father /Husband Name</th>
                <th>Occupant Name</th>
                <th>Occupant Father /Husband Name</th>
                <th>Occupant Extent</th>
                <th>Enjoyment Nature</th>
                <th>Date</th>
                <th>Acquisition Rights</th>
              </tr>
            </thead>
            <tbody *ngIf="sellerRORDetailsTable && sellerRORDetailsTable?.length > 0">
              <tr *ngFor="let item of sellerRORDetailsTable;">
                <td>{{item.pSerialNo}}</td>
                <td>{{item.pSurvey_no}}</td>
                <td>{{item.pTotal_Extent}}</td>
                <td>{{item.pUncultivated_Land}}</td>
                <td>{{item.pCultivatable_Land}}</td>
                <td>{{item.pLand_Nature}}</td>
                <td>{{item.pTax}}</td>
                <td>{{item.pLand_Classification?.replaceAll(' ','')}}</td>
                <td>{{item.pWater_Resource}}</td>
                <td>{{item.pAyakat_Extent}}</td>
                <td>{{item.pKhata_Number}}</td>
                <td>{{item.pPattadar_Name}}</td>
                <td>{{item.pPattadar_Father_Name}}</td>
                <td>{{item.pOccupant_Name}}</td>
                <td>{{item.pOccupant_Father_Name}}</td>
                <td>{{item.pOccupant_Extent}}</td>
                <td>{{item.pEnjoyment_Nature}}</td>
                <td>{{item.pMutated_Date}}</td>
                <td>{{item.pAcquiredReason}}</td>
              </tr>
            </tbody>
            <tbody *ngIf="sellerRORDetailsTable && sellerRORDetailsTable?.length === 0">
              <tr>
                <td colspan="18" class="text-center">No records found.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Registration District:</label>
          <div class="valueCls"> - </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Document Year:</label>
          <div class="valueCls"> {{ certificateInfo?.registrationInput?.regDocYear}}</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Document Number:</label>
          <div class="valueCls"> {{ certificateInfo?.registrationInput?.regDocNo}} </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">SRO:</label>
          <div class="valueCls"> {{ certificateInfo?.registrationInput?.regSRO.split("-")[1] }} </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Transaction Type:</label>
         <!--  <div class="valueCls"> {{ certificateInfo?.regTransactionType }} </div> -->
          <div class="valueCls">- </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Book No:</label>
          <div class="valueCls">{{ certificateInfo?.registrationInput?.regBook}} </div>
        </div>
      </div>
    </div>
  </div>

  <div class="certificateInfo" *ngIf="certificateInfo">

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Survey No:</label>
          <div class="valueCls"> {{regSurveyNo}} </div>
        </div>
      </div>
    </div>


    <div class="subHeadingCls mb-3">Registration Details </div>

    <div class="row mb-3">
      <div class="col">
        <div class="tab">
          <table class="tableCls">
            <thead>
              <tr>
                <th>Category</th>
                <th>Name</th>
                <th>Father/Husband Name</th>
                <th>Address</th>
                <th>Sy.No</th>
                <th>Extent</th>
                <th>Unit</th>
                <th>Date of Registration</th>
                <!-- <th>Year</th> -->
                <th>Document No</th>
                <th>SRO</th>
              </tr>
            </thead>
            <tbody *ngIf="regDetails && regDetails?.length > 0">
              <tr *ngFor="let item of regDetails; let i = index;">
                <td>{{item.PARTYDESC}}</td>
                <td>{{item.PARTYNAME}}</td>
                <td>{{item.RELATIONNAME}}</td>
                <td>{{item.ADDRESS}}</td>
                <td *ngIf="i === 0" [attr.rowspan]="regDetails.length">{{item.updatedSurveyNo}}</td>
                <td *ngIf="i === 0" [attr.rowspan]="regDetails.length">{{item.updateSurveyExt}}</td>
                <td *ngIf="i === 0" [attr.rowspan]="regDetails.length">{{ item.updateSurveyUnit === 'A' ? 'Acres' :
                  (item.UNIT === 'Y' ? 'Sq. Yards' : (item.UNIT === 'F' ? 'Sq. Feets' : (item.UNIT === 'M' ? 'Sq.Meters'
                  : ''))) }}</td>
                <td>{{item.R_DATE | date: 'dd/MM/yyyy'}}</td>
                <!-- <td>{{item.RYEAR}}</td> -->
                <td *ngIf="i === 0" [attr.rowspan]="regDetails.length">{{item.RDOCT_NO}}</td>
                <!-- <td>{{item.CODE ==='TR' ? 'Testator' : item.CODE ==='DR' ? 'Donor' : 'Others'}}</td> -->
                <!-- <td *ngIf="i === 0"  [attr.rowspan]="regDetails.length">{{f.regSRO.value?.name}}</td> -->
                <td *ngIf="i === 0" [attr.rowspan]="regDetails.length">{{
                  certificateInfo?.registrationInput?.regSRO.split("-")[1]}} </td>
              </tr>
            </tbody>
            <tbody *ngIf="regDetails && regDetails?.length === 0">
              <tr>
                <td colspan="11" class="text-center">No records found.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row mt-2 mb-3">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="text-center my-3 regDocLink" *ngIf="regDetails && regDetails?.length > 0"><a target="_blank"
            [attr.href]="regDetails[0]?.DOCLINK">View/Download Registration Document</a></div>
      </div>
    </div>

    <div class="row mt-2 mb-3">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Claimant Name:</label>
          <div class="valueCls"> {{ certificateInfo?.buyerName }} </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Claimant Khata No:</label>
          <div class="valueCls"> {{ certificateInfo?.buyerKhataNo }} </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Rice Card No:</label>
        <!--   <div class="valueCls"> {{ certificateInfo?.buyerRationCardNo }} </div> -->
          <div class="valueCls">{{certificateInfo?.buyerRationCardNo !== '' ? certificateInfo?.buyerRationCardNo : '-' }} </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Remarks:</label>
         <!--  <div class="valueCls">{{ basicInfo?.remarks}} </div> -->
         <!-- <div class="valueCls">{{certificateInfo?.remarks === '' ? '-' : certificateInfo?.remarks }} </div> -->
          <div class="valueCls"> -  </div>
        </div>
      </div>
    </div>


    <div class="row mt-2 mb-3">
      <div class="col">
        <div class="tab">
          <table class="tableCls">
            <thead>
              <tr>
                <th>Executant Name</th>
                <th>Executant Father/Husband Name</th>
                <th>Executant Address</th>
                <th>Executant Survey No/Sub Division</th>
              <!--   <th>Extent</th> -->
                <th>Khata No.</th>
                <th>Extent Of Area(ACS/Gts)</th>
                <th>Date of Registration </th>
                <th>Document No.</th>
                <th>Village</th>

              </tr>
            </thead>
            <tbody *ngIf="ExecutantInfoTable && ExecutantInfoTable?.length > 0">
              <tr *ngFor="let item of ExecutantInfoTable; let i = index;">
                <td> {{ item?.successorName }} </td>
                <td> {{ item?.fatherName }} </td>
                <td> {{ item?.sellerAddress }} </td>
                <td> {{ item?.surveyNo }} </td>
              <!--   <td> {{ item?.totalExtent }} </td> -->
                <td> {{ item?.khataNo }} </td>
                <td> {{ item?.extentArea }} </td>
                <td> {{ item?.documentDate | date: 'dd/MM/yyyy' }} </td>
                <td> {{ item?.documentNo }} </td>
                <td> {{ item?.village }} </td>

              </tr>
            </tbody>
            <tbody *ngIf="ExecutantInfoTable && ExecutantInfoTable?.length === 0">
              <tr>
                <td colspan="11" class="text-center">No records found.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>


    <div class="row mt-2 mb-3">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Passbook Type:</label>
          <div class="valueCls"> {{ certificateInfo?.passbookType }} </div>
        </div>
      </div>
    </div>

  </div>
</div>

<!-- Mutation for Corrections -->
<div *ngIf="certificateInfo?.serviceType =='Mutation for Corrections'">

  <div class="certificateInfo" *ngIf="certificateInfo">
    <div class="subHeadingCls mb-3">Edit Adangal Details </div>

    <div class="row mb-3">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Select Survey No to edit:</label>
          <div class="valueCls"> {{certificateInfo?.editSurveyNo}} </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col">
        <div class="tab table-responsive">
          <table class="tableCls">
            <thead>
              <tr>
                <th>S.No</th>
                <th colspan="3">Webland Data</th>
                <th>Applicant Request</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Sy.No</td>
                <td>సర్వే నెం</td>
                <td>{{editRorDetailsTable?.pSurvey_no}}</td>
                <td>
                  {{updatedSurveyDetails?.rorGridSurveyNo}}
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Total Extent</td>
                <td>పూర్తి విస్తీర్ణం</td>
                <td>{{editRorDetailsTable?.pTotal_Extent}}</td>
                <td>
                  {{updatedSurveyDetails?.rorTotalExtent}}
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Un Cultivated Extent</td>
                <td>సాగుకు పనికి రాని విస్తీర్ణం</td>
                <td>{{editRorDetailsTable?.pUncultivated_Land}}</td>
                <td>
                  {{updatedSurveyDetails?.rorUncultivable}}
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>Cultivated Extent</td>
                <td>సాగుకు పనికి వచ్చు విస్తీర్ణం</td>
                <td>{{editRorDetailsTable?.pCultivatable_Land}}</td>
                <td>
                  {{updatedSurveyDetails?.rorCultivable}}
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>Land Nature</td>
                <td>భూమి స్వభావం</td>
                <td>{{editRorDetailsTable?.pLand_Nature}}</td>
                <td>
                  {{updatedSurveyDetails?.rorLandNature}}
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>Tax</td>
                <td>శిస్తు (రూ.)</td>
                <td>{{editRorDetailsTable?.pTax}}</td>
                <td>{{editRorDetailsTable?.pTax}}</td>
              </tr>
              <tr>
                <td>7</td>
                <td>Land Classification</td>
                <td>భూమి వివరణ</td>
                <td>{{editRorDetailsTable?.pLand_Classification?.replaceAll(' ','') }}</td>
                <td>
                  {{updatedSurveyDetails?.rorLandClassification?.replaceAll(' ','') }}
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>Water Source</td>
                <td>జలాధారము</td>
                <td>{{editRorDetailsTable?.pWater_Resource}}</td>
                <td>
                  {{updatedSurveyDetails?.rorWaterSource}}
                </td>
              </tr>
              <tr>
                <td>9</td>
                <td>Water Source Extent</td>
                <td>ఆయకట్టు విస్తీర్ణం</td>
                <td>{{editRorDetailsTable?.pAyakat_Extent}}</td>
                <td>
                  {{updatedSurveyDetails?.rorWaterExtent}}
                </td>
              </tr>
              <tr>
                <td>10</td>
                <td>Khatha Number</td>
                <td>ఖాతా నెంబరు</td>
                <td>{{editRorDetailsTable?.pKhata_Number}}</td>
                <td>{{editRorDetailsTable?.pKhata_Number}}</td>

              </tr>
              <tr>
                <td>11</td>
                <td>Pattadar Name</td>
                <td>పట్టాదారు పేరు</td>
                <td>{{editRorDetailsTable?.pPattadar_Name?.replaceAll(' ','')}}</td>
                <td>
                  {{updatedSurveyDetails?.rorPattadarName?.replaceAll(' ','')}}
                </td>
              </tr>
              <tr>
                <td>12</td>
                <td>Pattadar Father /Husband Name</td>
                <td>పట్టాదారు తండ్రి/భర్త పేరు)</td>
                <td>{{editRorDetailsTable?.pPattadar_Father_Name?.replaceAll(' ','')}}</td>
                <td>
                  {{updatedSurveyDetails?.rorPattadarFatherName?.replaceAll(' ','')}}
                </td>
              </tr>
              <tr>
                <td>13</td>
                <td>Occupant Name</td>
                <td>అనుభవ దారు పేరు</td>
                <td>{{editRorDetailsTable?.pOccupant_Name?.replaceAll(' ','')}}</td>
                <td>
                  {{updatedSurveyDetails?.rorOccupantName?.replaceAll(' ','')}}
                </td>
              </tr>
              <tr>
                <td>14</td>
                <td>Occupant Father/Husband Name</td>
                <td>అనుభవదారు (తండ్రి/భర్త పేరు) పేరు</td>
                <td>{{editRorDetailsTable?.pOccupant_Father_Name?.replaceAll(' ','')}}</td>
                <td>
                  {{updatedSurveyDetails?.rorOccupantFatherName?.replaceAll(' ','')}}
                </td>
              </tr>
              <tr>
                <td>15</td>
                <td>Occupant Extent</td>
                <td>అనుభవ విస్తీర్ణం</td>
                <td>{{editRorDetailsTable?.pOccupant_Extent}}</td>
                <td>
                  {{updatedSurveyDetails?.rorOccupantExtent}}
                </td>
              </tr>
              <tr>
                <td>16</td>
                <td>Enjoyment Nature</td>
                <td>అనుభవ స్వభావం</td>
                <td>{{editRorDetailsTable?.pEnjoyment_Nature}}</td>
                <td>
                  {{updatedSurveyDetails?.rorEnjoymentNature}}
                </td>
              </tr>
              <tr>
                <td>17</td>
                <td>Acquisition Rights</td>
                <td>పట్టదారుకు ఏ విధంగా సంక్రమించింది</td>
                <td>{{editRorDetailsTable?.pAcquiredReason}}</td>
                <td>
                  {{updatedSurveyDetails?.rorAcquisition}}
                </td>
              </tr>
              <!-- <tr>
                        <td colspan="5">
                             <button class="greenBtnCls float-right" (click)="updateROR()">Apply</button>
                         </td>
                    </tr> -->
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row mt-2 mb-3">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
          <label class="labelCls">Passbook Type:</label>
          <div class="valueCls"> {{ certificateInfo?.passbookType }} </div>
        </div>
      </div>
    </div>

  </div>
</div>


<div class="certificateInfo mt-2 mb-3">
  <div class="row">
    <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
      <ng-container *ngIf="fileList && fileList.length !== 0">
        <div class="subHeadingCls mb-3">List of Documents</div>
        <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
          <div *ngIf="file.documentType === 'Old Pattadar Passbook'">
            <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Old Pattadar Passbook/Title
              deed/(Seller PPB/TD):</label>
            <div class="valueCls">
              <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                class="download ml-2"></span>
            </div>
          </div>
          <div *ngIf="file.documentType === 'Tax Receipt'">
            <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Tax Receipts if any:</label>
            <div class="valueCls">
              <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                class="download ml-2"></span>
            </div>
          </div>


          <div *ngIf="file.documentType === 'Signature'">
            <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Signature:</label>
            <div class="valueCls">
              <a (click)="viewImgFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                class="download ml-2"></span>
            </div>
          </div>
          <div *ngIf="file.documentType === 'Passport size photo'">
            <span class="numberCls">{{ i + 1 }}</span><label class="labelCls"> Recent Passport Size photo:</label>
            <div class="valueCls">
              <a (click)="viewImgFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                class="download ml-2"></span>
            </div>
          </div>
          <div *ngIf="file.documentType === 'Identity of the NRI'">
            <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Identity of the NRI:</label>
            <div class="valueCls">
              <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                class="download ml-2"></span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="certificateInfo mt-2 mb-3" *ngIf="isSignatureRequired || isPhotoRequired">
  <form [formGroup]="fileForm">
    <div class="row">
      <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12"  *ngIf="isPhotoRequired">
        <div class="checkBoxCont">
            <!-- <input class="checkboxCls" type="checkbox" value="" id="passportSizePhoto"> -->
            <label class="labelCls" for="passportSizePhoto">Recent Passport Size photo
                (Upload Only jpg/jpeg format)<span class="mandatoryCls">*</span></label>
        </div>
        <div class="input-group mb-3">
            <div class="custom-file">
                <input type="file" class="custom-file-input"
                    formControlName="passportSizePhoto" id="passportSizePhoto" accept=".jpg,.jpeg"
                    [ngClass]="{ 'is-invalid':submitted && f.passportSizePhoto.errors }"
                    (change)="onpassportSizeFileSelected($event)">
                <label class="custom-file-label" #passportSizeLabel
                    for="passportSizePhoto"><i class="fas fa-search"></i>No File
                    choosen</label>
            </div>
            <div class="clearCls"
                (click)="clearFileUpload('passportSizePhoto', 'passportSizeLabel')"
                *ngIf="f.passportSizePhoto.value"></div>
        </div>
        <div *ngIf="submitted && f.passportSizePhoto.errors" class="selectError">
            <div *ngIf="f.passportSizePhoto.errors.required">Please upload Recent Passport
                Size
                photo
                (Upload Only jpg/jpeg format)</div>
            <div
                *ngIf="f.passportSizePhoto.errors?.inValidExt && !f.passportSizePhoto.errors.required ">

                Please upload the file in JPG/JPEG Format</div>
            <div
                *ngIf="f.passportSizePhoto.errors?.inValidSize && !f.passportSizePhoto.errors.required && !f.passportSizePhoto.errors.inValidExt">
                File size exceeding 1MB</div>
        </div>
    </div>
    <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngIf="isSignatureRequired">
        <div class="checkBoxCont">
            <!-- <input class="checkboxCls" type="checkbox" value="" id="signatureFormat"> -->
            <label class="labelCls" for="signatureFormat">Signature
                (Upload Only jpg/jpeg format)<span class="mandatoryCls">*</span></label>
        </div>
        <div class="input-group mb-3">
            <div class="custom-file">
                <input type="file" class="custom-file-input" formControlName="signature"
                    id="signature" accept=".jpg,.jpeg"
                    [ngClass]="{ 'is-invalid':submitted && f.signature.errors }"
                    (change)="onsignatureFileSelected($event)">
                <label class="custom-file-label" #signatureLabel for="signature"><i
                        class="fas fa-search"></i>No File choosen</label>
            </div>
            <div class="clearCls" (click)="clearFileUpload('signature', 'signatureLabel')"
                *ngIf="f.signature.value"></div>
        </div>
        <div *ngIf="submitted && f.signature.errors" class="selectError">
            <div *ngIf="f.signature.errors.required">Please upload Signature (Upload Only jpg/jpeg format)</div>
            <div *ngIf="f.signature.errors?.inValidExt && !f.signature.errors.required ">

                Please
                Upload the file in JPG/JPEG Format</div>
            <div
                *ngIf="f.signature.errors?.inValidSize && !f.signature.errors.required && !f.signature.errors.inValidExt">
                File size exceeding 1MB</div>
        </div>
    </div>
    </div>
    <div class="btnholder my-3 text-center">
      <button class="greenBtnCls " (click)="saveFileDetails()">Save</button>
    </div>
</form>
</div>