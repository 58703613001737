import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-payment-response-paytm',
  templateUrl: './payment-response-paytm.component.html',
  styleUrls: ['./payment-response-paytm.component.css']
})
export class PaymentResponsePaytmComponent implements OnInit {

  commonConstants : any = CommonConstants;
  transactionID: any;
  orderId: any;
  transactionStatus: any;
  paymentError: Boolean = false;
  currentDate = new Date();

  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute,
    private commonService: CommonService
  ) {

    this.currentRoute.queryParams.subscribe((params) => {
      console.log(params, '>>>URL message data');
      if(params.Result === "TXN_SUCCESS" && params.TransactionId != 'null' && params.TransactionId != ''){
           this.getById(params.TransactionId)
      }else if(params.Result === "TXN_SUCCESS" && params?.ChallanId != 'null'  && params?.ChallanId != 'null') {
        const queryParams: Params = { id: params?.ChallanId, amount: params?.ChallanAmount ,type:"gsws", mode: 'paytm'};
        this.router.navigate(['/view-challan'], {
          relativeTo: this.currentRoute,
          queryParams: queryParams,
          queryParamsHandling: '',
        });
        //alert('challan generation success')
      }else if(params.Result === "TXN_FAILURE" && params?.ChallanId != 'null'  && params?.ChallanId != 'null') { 
        this.orderId =  params.ChallanId;
        this.transactionID = params.ChallanId;
        this.transactionStatus = 'Fail';
        this.paymentError = true;
      }else{

        this.orderId =  params.TransactionId;
        this.transactionID = params.TransactionId;
        this.transactionStatus = 'Fail';
        this.paymentError = true;
      }
      
    });

   }

  ngOnInit(): void {
  }


  getById(txnId: any) {
    console.log(txnId)
    let departmentID = "RV";

    this.commonService.getRequest(
      this.commonConstants.getRequisitionDetailsByTransactionId + txnId
    ).subscribe({
      next: (responseData: any) => { 
          console.log("responseData",responseData)

          let queryParams: Params = {};

          queryParams = { id: responseData.result.requisitionId,
                          transactionID: txnId,
                          department: responseData.result.department,
                          type: responseData.result.requisitionType,
                          };
      
          this.router.navigate(['/payment-receipt'], {
            relativeTo: this.currentRoute,
            queryParams: queryParams,
            queryParamsHandling: '',
          });

      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }




}

//id=NHC220916000921&department=HF&type=AarogyaSriNewCard&transactionID=TNHC22091600092
