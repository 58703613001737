<div *ngIf="certificateInfo">

    <div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
        <div class="subHeadingCls mb-3">Applicant Details</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Marriage Date:</label>
                    <div class="valueCls">{{certificateInfo?.marriageDate ? (certificateInfo?.marriageDate | date:'dd MMM yyyy') : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Marriage Address:</label>
                    <div class="valueCls">{{certificateInfo?.marriageAddress ? (certificateInfo?.marriageAddress) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Marriage Booklet Number:</label>
                    <div class="valueCls">{{certificateInfo?.marriageBookletNumber ? (certificateInfo?.marriageBookletNumber) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Maher Amount:</label>
                    <div class="valueCls">{{certificateInfo?.maherAmount ? (certificateInfo?.maherAmount) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Qazi District Name:</label>
                    <div class="valueCls">{{certificateInfo?.districtName ? (certificateInfo?.districtName) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Qazi Mandal Name:</label>
                    <div class="valueCls">{{certificateInfo?.mandalMunicipalityName ? (certificateInfo?.mandalMunicipalityName) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Qazzat Name:</label>
                    <div class="valueCls">{{certificateInfo?.quazzat ? (certificateInfo?.quazzat) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Qazi Address:</label>
                    <div class="valueCls">{{certificateInfo?.quazzatAddress ? (certificateInfo?.quazzatAddress) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Number Of Certificates:</label>
                    <div class="valueCls">{{certificateInfo?.noOfCertificates ? (certificateInfo?.noOfCertificates) : '-' }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
        <div class="subHeadingCls mb-3">Bride Details</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Aadhaar Number</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails?.aadhaarNumber ? (certificateInfo?.brideDetails?.aadhaarNumber) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Name:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails?.name ? (certificateInfo?.brideDetails?.name) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Date Of Birth:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails?.dateOfBirth ? (certificateInfo?.brideDetails?.dateOfBirth | date:'dd MMM yyyy') : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Age:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails?.age ? (certificateInfo?.brideDetails?.age) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Nationality:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails?.nationality ? (certificateInfo?.brideDetails?.nationality) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Father's Name:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails?.fathersName ? (certificateInfo?.brideDetails?.fathersName) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Mother's Name:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails?.mothersName ? (certificateInfo?.brideDetails?.mothersName) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Occupation:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails?.occupation ? (certificateInfo?.brideDetails?.occupation) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Address:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails?.address ? (certificateInfo?.brideDetails?.address) : '-' }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
        <div class="subHeadingCls mb-3">Bridegroom Details</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Aadhaar Number</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails?.aadhaarNumber ? (certificateInfo?.bridegroomDetails?.aadhaarNumber) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Name:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails?.name ? (certificateInfo?.bridegroomDetails?.name) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Date Of Birth:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails?.dateOfBirth ? (certificateInfo?.bridegroomDetails?.dateOfBirth | date:'dd MMM yyyy') : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Age:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails?.age ? (certificateInfo?.bridegroomDetails?.age) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Nationality:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails?.nationality ? (certificateInfo?.bridegroomDetails?.nationality) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Father's Name:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails?.fathersName ? (certificateInfo?.bridegroomDetails?.fathersName) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Mother's Name:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails?.mothersName ? (certificateInfo?.bridegroomDetails?.mothersName) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Occupation:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails?.occupation ? (certificateInfo?.bridegroomDetails?.occupation) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Address:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails?.address ? (certificateInfo?.bridegroomDetails?.address) : '-' }}</div>
                </div>
            </div>
        </div>
    </div>

        
    <div class="certificateInfo">
        <div class="row">
            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
                <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                    <div *ngIf="file.documentType !== 'aadharFile' && file.documentType !== 'casteCert' && file.documentType !== 'docCV'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">{{file.documentType}}:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>