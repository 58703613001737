<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Selection Criteria Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo.selectionCriteriaDetails.districtName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo.selectionCriteriaDetails.mandalMunicipalityName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Village / Ward:</label>
                <div class="valueCls">{{certificateInfo.selectionCriteriaDetails.villageWardName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Fasli Year: </label>
                <div class="valueCls">{{certificateInfo.fasliYear}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div *ngIf="certificateInfo?.applicantLPDetailsList?.length > 0" class="fieldHolder">
                <label class="labelCls">LP No:</label>
                <div class="valueCls">{{certificateInfo.applicantLPDetailsList[0].lplpmno}}</div>
            </div>
            <div *ngIf="certificateInfo?.applicantDetailsList?.length > 0" class="fieldHolder">
                <label class="labelCls">Survey No / Sub Division:</label>
                <div class="valueCls">{{certificateInfo.surveyNo}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Please select Without crop :</label>
                <div class="valueCls">{{certificateInfo.cropDetail}}</div>
            </div>
            <!-- <div class="fieldHolder">
                <label class="labelCls">Did transaction today:</label>
                <div class="valueCls">true</div>
            </div> -->
        </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="subHeadingCls mb-3">Applicant Details
    </div>
    <div class="row mb-3">
        <div class="col">
            <div class="tab">
                <table class="tableCls" *ngIf="certificateInfo?.applicantLPDetailsList?.length > 0">
                    <thead>
                        <tr>
                          <th>S NO</th>
                          <th>LP No</th>
                          <th>Old Sy.No</th>
                          <th>LP Extent</th>
                          <th>ULPIN</th>
                          <th>Land Nature(Sub Nature 1,2,3)</th>
                          <th>Land Classification(Sub Classification1,2)</th>
                          <th>Khata Number</th>
                          <th>Pattadar Name (Pattadar Father / Husband Name)</th>
                          <th>Occupant Name (Occupant Father / Husband Name)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let litem of certificateInfo?.applicantLPDetailsList; let i=index">
                            <td>{{i+1}}</td>
                            <td class="value">{{litem?.lplpmno ? (litem?.lplpmno) : '-'}}</td>
                            <td class="value">{{litem?.lpoldsurveyno? (litem?.lpoldsurveyno) : '-'}}</td>
                            <td *ngIf="!isJoint" class="value">{{litem?.lplpmextent? (litem?.lplpmextent) : '-'}}</td>
                            <td *ngIf="isJoint && i == 0" [attr.rowspan]="certificateInfo?.applicantLPDetailsList.length" class="value align-top">{{litem?.lplpmextent? (litem?.lplpmextent) : '-'}}</td>
                            <td class="value">{{litem?.lpulpin? (litem?.lpulpin) : '-'}}</td>
                            <td>{{litem?.lplandnature? (litem?.lplandnature) : '-'}} &nbsp;({{litem?.lpLandNatureSubCategory1? (litem?.lpLandNatureSubCategory1) : '-'}},&nbsp;{{litem?.lpLandNatureSubCategory2? (litem?.lpLandNatureSubCategory2) : '-'}},&nbsp;{{litem?.lpLandNatureSubCategory3? (litem?.lpLandNatureSubCategory3) : '-'}})</td>
                            <td>{{litem?.lplandclassification? (litem?.lplandclassification) : '-'}} &nbsp;({{litem?.lpLandClassificationSubCategory1? (litem?.lpLandClassificationSubCategory1) : '-'}},&nbsp;{{litem?.lpLandClassificationSubCategory2? (litem?.lpLandClassificationSubCategory2) : '-'}})</td>
                            <td class="value">{{litem?.lpkhatano? (litem?.lpkhatano) : '-'}}</td>
                            <td>{{litem?.lppattadarname? (litem?.lppattadarname) : '-'}}&nbsp;({{litem?.lpPattadarRelationName? (litem?.lpPattadarRelationName) : '-'}})</td>
                            <td>{{litem?.lpEnjoyerName? (litem?.lpEnjoyerName) : '-'}}&nbsp;({{litem?.lpEnjoyerRelationName? (litem?.lpEnjoyerRelationName) : '-'}})</td>
                        </tr>
                    </tbody>
                </table>
                <table class="tableCls" *ngIf="certificateInfo?.applicantDetailsList?.length > 0">
                    <thead>
                        <tr>
                            <th>S NO</th>
                            <th>Survey No</th>
                            <th>Total Extent</th>
                            <th>Un Cultivatable / Cultivatable Land</th>
                            <th>Land Nature / Tax</th>
                            <th>Land Classification / Water Source</th>
                            <th>Water Source Extent</th>
                            <th>Khata Number</th>
                            <th>Pattadar Name (Pattadar Father/Husband Name)</th>
                            <th>Occupant Name (Occupant Father/Husband Name)</th>
                            <th>Occupant Extent / Enjoyment Nature</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of certificateInfo?.applicantDetailsList; let i=index">
                            <td>{{item?.serialNumber ? (item?.serialNumber) : '-'}}</td>
                            <td class="value">{{item?.surveyNumber ? (item?.surveyNumber) : '-'}}</td>
                            <td *ngIf="!isJoint" class="value">{{item?.totalExtent.toString() ? (item?.totalExtent) :'-'}}</td>
                            <td *ngIf="isJoint && i == 0" [attr.rowspan]="certificateInfo?.applicantDetailsList.length" class="value align-top">{{item?.totalExtent ? (item?.totalExtent) :'-'}}</td>
                            <td *ngIf="!isJoint" class="value">{{item?.totalUncultivatable.toString() ? (item?.totalUncultivatable) :'-'}} / {{item?.cultivatableLand.toString() ? (item?.cultivatableLand) :'-'}}</td>
                            <td *ngIf="isJoint && i == 0" [attr.rowspan]="certificateInfo?.applicantDetailsList.length" class="value align-top">{{item?.totalUncultivatable.toString() ? (item?.totalUncultivatable) :'-'}} / {{item?.cultivatableLand.toString() ? (item?.cultivatableLand) :'-'}}</td>
                            <td>{{item?.landNature ? (item?.landNature) :'-'}} / {{item?.tax ? (item?.tax) :'-'}}</td>
                            <td>{{item?.landClassification ? (item?.landClassification) :'-'}} / {{item?.waterSource ? (item?.waterSource) :'-'}}</td>
                            <td *ngIf="!isJoint" class="value">{{item?.waterSourceExtent.toString() ? (item?.waterSourceExtent) :'-'}}</td>
                            <td *ngIf="isJoint && i == 0" [attr.rowspan]="certificateInfo?.applicantDetailsList.length" class="value align-top">{{item?.waterSourceExtent.toString() ? (item?.waterSourceExtent) :'-'}}</td>
                            <td class="value">{{item?.khathaNumber ? (item?.khathaNumber) :'-'}}</td>
                            <td>{{item?.pattadarName ? (item?.pattadarName) :'-'}} ({{item?.pattadarFatherName ? (item?.pattadarFatherName) :'-'}})</td>
                            <td>{{item?.occupantName ? (item?.occupantName) :'-'}} ({{item?.occupantFatherName ? (item?.occupantFatherName) :'-'}})</td>
                            <td>{{item?.occupantExtent.toString() ? (item?.occupantExtent) :'-'}} / {{item?.enjoymentNature ? (item?.enjoymentNature) :'-'}}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of fileList ;let i = index">
                    <div *ngIf="file.documentType === 'Application Form'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>