<div class="certificateInfo">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo?.housingSiteAddress?.districtName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Village / Ward:</label>
                <div class="valueCls">{{certificateInfo?.housingSiteAddress?.villageWardName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Relationship With Beneficiary:</label>
                <div class="valueCls">{{certificateInfo?.relationship}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Age(Years):</label>
                <div class="valueCls">{{certificateInfo?.age}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Occupation:</label>
                <div class="valueCls">{{certificateInfo?.occupation}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Municipality/Mandal:</label>
                <div class="valueCls">{{certificateInfo?.housingSiteAddress?.mandalMunicipalityName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Panchayat:</label>
                <div class="valueCls">{{certificateInfo?.panchayat}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Relation Name:</label>
                <div class="valueCls">{{certificateInfo?.relationName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Sub caste:</label>
                <div class="valueCls">{{certificateInfo?.subCaste}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Is Physically Challenged ? :</label>
                <div class="valueCls">{{certificateInfo?.isPhysicallyChallenged === true ? 'Yes' : 'No'}}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="subHeadingCls mb-3">Eligibility Details For Housing Site</div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Does the beneficiary have a white ration card ?:</label>
                <div class="valueCls">{{certificateInfo?.isHaveWhiteRationCard === true ? 'Yes' : 'No'}}</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.isHaveWhiteRationCard">
                <label class="labelCls">If yes, then ration card number:</label>
                <div class="valueCls">{{certificateInfo?.rationCardNumber}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Does the beneficiary own a house in the state of Andhra Pradesh ?:</label>
                <div class="valueCls">{{certificateInfo?.isHaveOwnHouse === true ? 'Yes' : 'No'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Does the beneficiary own a house site in the state of Andhra Pradesh ?: </label>
                <div class="valueCls">{{certificateInfo?.isHaveHouseSite === true ? 'Yes' : 'No'}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Does the beneficiary already have a government-sanctioned home ?:</label>
                <div class="valueCls">{{certificateInfo?.isHaveGovtHome === true ? 'Yes' : 'No'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Does the beneficiary already have a government-sanctioned home site ?:</label>
                <div class="valueCls">{{certificateInfo?.isHaveGovtHomeSite === true ? 'Yes' : 'No'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Does the beneficiary own 2.5 acres of Wetland or 5.0 acres of Dryland ?:</label>
                <div class="valueCls">{{certificateInfo?.isHaveWetOrDryLand === true ? 'Yes' : 'No'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Whether the beneficiary has an annual income of less than three (3) lakhs ?:
                </label>
                <div class="valueCls">{{certificateInfo?.isAnnualIncomeLessthan3Lakhs === true ? 'Yes' : 'No'}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Does the beneficiary own a house previously granted under the PMAY schemes
                    ?:</label>
                <div class="valueCls">{{certificateInfo?.isHaveHouseUnderPMAYScheme === true ? 'Yes' : 'No'}}</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.isHaveHouseUnderPMAYScheme ===true">
                <label class="labelCls">If yes, specify the Benefit Scheme:</label>
                <div class="valueCls">{{certificateInfo?.benefitScheme}}</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.benefitScheme ==='AHP'">
                <label class="labelCls">Have you assigned the assignment order under AHP ? </label>
                <div class="valueCls">{{certificateInfo?.isAssignmentAssignedunderAHP === true ? 'Yes' : 'No'}}</div>
            </div>
            <div class="fieldHolder" *ngIf="certificateInfo?.isAssignmentAssignedunderAHP ===true">
                <label class="labelCls">If yes, Specify the allotment details:
                </label>
                <div class="valueCls">{{certificateInfo?.allotmentDetails}}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of fileList ;let i = index">
                    <div *ngIf="file.documentType === 'Application Form'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Xerox copy of Aadhaar Card:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'RationCard Form' && certificateInfo?.isHaveWhiteRationCard">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Rice/RationCard:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Voter Card Form'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Voter Card:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>