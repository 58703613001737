<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Selected Criteria Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No :</label>
                <div class="valueCls">{{basicInfo?.rationCardNo}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo?.remarks}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Registration Unit Id:</label>
                <div class="valueCls">{{certificateInfo?.registrationUnitId}}</div>
            </div>
           
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">No Of Copies:</label>
                <div class="valueCls">{{certificateInfo?.noOfCopies ? (certificateInfo?.noOfCopies) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Purpose Of Certificate:</label>
                <div class="valueCls">{{certificateInfo?.purposeOfCertificate }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Registration No:</label>
                <div class="valueCls">{{certificateInfo?.registrationNumber }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Registration Year:</label>
                <div class="valueCls">{{certificateInfo?.registrationYear }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Child Details</div>
    <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Actual Child SurName:</label>
                <div class="valueCls">{{certificateInfo?.actualChildSurName ? (certificateInfo?.actualChildSurName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Changed Child SurName:</label>
                <div class="valueCls">{{certificateInfo?.changedChildSurName ? (certificateInfo?.changedChildSurName) : '-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Actual Child Name:</label>
                <div class="valueCls">{{certificateInfo?.actualChildName ? (certificateInfo?.actualChildName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Changed Child Name:</label>
                <div class="valueCls">{{certificateInfo?.changedChildName ? (certificateInfo?.changedChildName) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Father Name:</label>
                <div class="valueCls">{{certificateInfo?.fatherName ? (certificateInfo?.fatherName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Father SurName:</label>
                <div class="valueCls">{{certificateInfo?.fatherSurName ? (certificateInfo?.fatherSurName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mother Name:</label>
                <div class="valueCls">{{certificateInfo?.motherName ? (certificateInfo?.motherName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mother SurName:</label>
                <div class="valueCls">{{certificateInfo?.motherSurName ? (certificateInfo?.motherSurName) : '-' }}</div>
            </div>
       
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Place of birth:</label>
                <div class="valueCls">{{certificateInfo?.birthPlace ? (certificateInfo?.birthPlace) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Location Name:</label>
                <div class="valueCls">{{certificateInfo?.locationName ? (certificateInfo?.locationName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Hospital Name:</label>
                <div class="valueCls">{{certificateInfo?.hospitalName ? (certificateInfo?.hospitalName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Birth Address:</label>
                <div class="valueCls">{{certificateInfo?.birthAddress ? (certificateInfo?.birthAddress) : '-' }}</div>
            </div>
       
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Residence Address:</label>
                <div class="valueCls">{{certificateInfo?.residenceAddress ? (certificateInfo?.residenceAddress) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Permanent Address:</label>
                <div class="valueCls">{{certificateInfo?.permanentAddress ? (certificateInfo?.permanentAddress) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">State:</label>
                <div class="valueCls">{{certificateInfo?.state ? (certificateInfo?.state) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo?.district ? (certificateInfo?.district) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Pincode:</label>
                <div class="valueCls">{{certificateInfo?.pincode ? (certificateInfo?.pincode) : '-' }}</div>
            </div>
          
           
        </div>
    </div>
</div>

<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
      
            </div>
            </ng-container>
        </div>
    </div>
</div>



