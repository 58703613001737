<div class="certificateInfo" *ngIf="certificateInfo">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Age:</label>
                <div class="valueCls">{{certificateInfo.ownerAge ? (certificateInfo.ownerAge) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Income:</label>
                <div class="valueCls">{{certificateInfo.ownerIncome ? (certificateInfo.ownerIncome) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
            </div>
        <div class="fieldHolder">
            <label class="labelCls">Survey No:</label>
            <div class="valueCls">{{certificateInfo.ownerSurveyNo ? (certificateInfo.ownerSurveyNo) : '-' }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Purpose:</label>
            <div class="valueCls">{{certificateInfo.purpose ? (certificateInfo.purpose) : '-' }}</div>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Extent:</label>
                <div class="valueCls">{{certificateInfo.ownerExtent ? (certificateInfo.ownerExtent) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo ">
    <div class="subHeadingCls mb-3">House Boundaries (Name of the House/Land holder) </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">East:</label>
                <div class="valueCls">{{certificateInfo.eastSidePropertyOwnerName ? (certificateInfo.eastSidePropertyOwnerName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">West:</label>
                <div class="valueCls">{{certificateInfo.westSidePropertyOwnerName ? (certificateInfo.westSidePropertyOwnerName) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">North:</label>
            <div class="valueCls">{{certificateInfo.northSidePropertyOwnerName ? (certificateInfo.northSidePropertyOwnerName) : '-' }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">South:</label>
            <div class="valueCls">{{certificateInfo.southSidePropertyOwnerName ? (certificateInfo.southSidePropertyOwnerName) : '-' }}</div>
        </div>
        </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Ration Card Certificate'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Income Certificate/ Ration card:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>

