import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-corrections-in-death-view',
  templateUrl: './corrections-in-death-view.component.html',
  styleUrls: ['./corrections-in-death-view.component.css']
})
export class CorrectionsInDeathViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  constructor(private commonService: CommonService) { }
  commonConstants: any = CommonConstants;
  ngOnInit(): void {
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

}
