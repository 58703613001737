<div class="certificateInfo" *ngIf="certificateInfo">
    <div class="subHeadingCls mb-3">Situation of the permises where petroleum is to be stored:</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo.petroleumStoredPermises?.districtName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo?.petroleumStoredPermises?.mandalMunicipalityName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{certificateInfo.petroleumStoredPermises?.villageWardName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Pin Code:</label>
                <div class="valueCls">{{certificateInfo.petroleumStoredPermises.pinCode}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Police Station Name having jurisdiction:</label>
                <div class="valueCls">{{certificateInfo.policeStation}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">

            <div class="fieldHolder">
                <label class="labelCls">Nearest Railway Station:</label>
                <div class="valueCls">{{certificateInfo.railwayStation}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Survey Number:</label>
                <div class="valueCls">{{certificateInfo.surveyNumber}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Licence Number:</label>
                <div class="valueCls">{{certificateInfo.licenceNumber}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Type Of Licence:</label>
                <div class="valueCls">{{certificateInfo.licenceType}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Number of licence held for the premises and the Full name of the holder of the
                    licence:</label>
                <div class="valueCls">{{certificateInfo.fullNameAlongwithlicenceDetails}}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo.petroleumQuantityToBeImportedNStroredInfo">
    <div class="subHeadingCls mb-3">Quantity(in litres) of Petroleum to be imported and stored:</div>
    <div class="row mb-3">
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        </div>
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="subHeadingCls mb-3">In Bulk</label>
        </div>
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="subHeadingCls mb-3">Not In Bulk</label>
        </div>
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="subHeadingCls mb-3">Total</label>
        </div>
    
    </div>
    <div class="row mb-3" *ngFor="let x of certificateInfo.petroleumQuantityToBeImportedNStroredInfo.petroleumClassInfo; let i=index">
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="subHeadingCls mb-3">Class {{i+1}}</label>
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        {{x.inBulk ? (x.inBulk):'-'}}
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        {{x.notInBulk ? (x.notInBulk):'-'}}
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        {{x.total ? (x.total):'-'}}
    </div>
</div>
<div class="row mb-3">
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="subHeadingCls mb-3">Total of classes of Petroleum:</label>
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        {{certificateInfo.petroleumQuantityToBeImportedNStroredInfo.totalclassesOfPetroleum}}
    </div>
</div>
</div>

<div class="certificateInfo" *ngIf="certificateInfo.petroleumQuantityAlreadyStroredInfo">
    <div class="subHeadingCls mb-3">Quantity(in litres) of Petroleum already strored in premises:</div>
    <div class="row mb-3">
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        </div>
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="subHeadingCls mb-3">In Bulk</label>
        </div>
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="subHeadingCls mb-3">Not In Bulk</label>
        </div>
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="subHeadingCls mb-3">Total</label>
        </div>
    
    </div>
    <div class="row mb-3" *ngFor="let x of certificateInfo.petroleumQuantityAlreadyStroredInfo.petroleumClassInfo; let i=index">
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="subHeadingCls mb-3">Class {{i+1}}</label>
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        {{x.inBulk ? (x.inBulk):'-'}}
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        {{x.notInBulk ? (x.notInBulk):'-'}}
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        {{x.total ? (x.total):'-'}}
    </div>
</div>
<div class="row mb-3">
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="subHeadingCls mb-3">Total of classes of Petroleum:</label>
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        {{certificateInfo.petroleumQuantityAlreadyStroredInfo.totalclassesOfPetroleum}}
    </div>
</div>
</div>

<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                    <div *ngIf="file.documentType === 'Application Form'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'SitePlan Form'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Site Plan:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Copy Passbook Form'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Copy of Passbook/title deed/sale
                            deed:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Lease Agreement Form'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Lease Agreement:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Issue Of Oil LetterForm Form'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Letter of intent issued by the oil
                            company:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Extract Of PahaniFile Form'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Extract of Pahani:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>