import { Component, NgModuleRef, OnInit } from '@angular/core';
import { MenuConstants, MenuConstantsCitizen, MenuConstantsMeeseva } from '../../constants/menu.constants';
import { CommonService } from '../../shared/common.service';
import { CommonConstants } from '../../constants/common-constants.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal ,ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { BasicDetailsPopupComponent } from '../../shared/basic-details-popup/basic-details-popup.component';

import { CommonMsgModalComponent } from '../../shared/common-msg-modal/common-msg-modal.component';
import { EnvironmentService } from '../../shared/environment.service';
import { PoliciesPopupComponent } from '../../shared/policies-popup/policies-popup.component';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  showMenu: Boolean = false;
  showMAUD: Boolean = false;
  showTransport: Boolean = false;
  showSandBooking: Boolean = false;
  showhinduMarriage: Boolean = false;
  showOpenSchool: Boolean = false;
  showFisheries: Boolean = false;
  showMutation: Boolean = false;
  showSaderam: Boolean = false;
  showFire: Boolean = false
  showCaseSheet: Boolean = false;
  submitted: Boolean = false;
  showSchool:Boolean =false;
  showComplaint:Boolean=false;
  isUserExist: Boolean = false;
  mandals: any[] = [];
  transportServices: any = [];
  openSchoolServices: any = [];
  fisheriesServices: any = [];
  SVAMITVAMutationServices: any  = [];
  hinduMarriage: any = [];
  MAUDForm: FormGroup;
  maudServices: any = [];
  sandServices: any = [];
  fireServices: any = [];
  maudurls: any = [];
  showEnergy: Boolean = false;
  districts: any[] = [];
  energyForm: FormGroup;
  energyServices: any = [];
  energyurls: any = [];
  locationDetails: any;
  sacretraitRanking: any = {diD_RANK: 0, diD_TOT_RNK: 0, miD_RANK: 0, miD_TOT_RNK: 0};
  menuConstants: any = MenuConstants;
  commonConstants: any = CommonConstants;
  redirectionUrl: any;
  userData: any;
  commonUrl: any;
  items: any[] = [];
  ViewTransactions: FormGroup;
   //*Transaction status
   aadharNum="";
   pipeLineString: any;
   dateTime: any;
   requisitionInfo: any;
   requisitionID: any;
   transactionID: any;
   serviceName: any;
   applicantName: any;
   applicantFatherName: any;
   landConversionData: any;
   totalAmount: any;
   SLA: any;
   mandal: any;
   district: any;
   transactionStatusData :any = "Pending";
   paymentMode: any ="UPI";
   departmentCode :any = "RV";
   departmentName :any = "Revenue";
   showPrintCertificate :Boolean = false;
   reIssuanceData: any;
   secretariatName: any;
   amountInwords: any;
   is_requestsent:Boolean=false; 
   certificateTypeName:any; 
   serviceList: any = [];
   closeResult:any;
   userInfo: any = [];
   userIdData : string = '';
   loginUserName : string = '';
   transData: any = [];
   is_categoryA:Boolean=false; 
   searchService: string = '';
   servicesData :any =[]; 
   servicesCountData :any =[]; 
   totalNumberOfServicesRaised : number = 0;
   totalNumberOfServicesRaiseddata : any ;
   totalNumberOfServicesRaisedInSameSecretariat : number = 0;
   totalNumberOfServicesRaisedInSameSecretariatData : any ;
   totalNumberOfServicesFlownToThisSecretariat : number = 0;
   totalNumberOfServicesFlownToThisSecretariatData : any ;
   secretariatCode: any;
   dashbardVroCounts: any = [];
   dashbardpopupTitle: any = [];
   transactionDataPopup: any = {};
   cntDash: number = 0;
   page = 1;
   pageSize = 10;
   selectDashboardBox="";
   totalPageCount: number = 0;
   isProdEnv: Boolean = false;
   isLoginPresent: Boolean = false;
   isProdTestUser: Boolean = false;
   lastUpdate: any;
   userID:any;
  date: any;
  lastLoginTime: any;
  userName: any;
  constructor( private commonService: CommonService, private formBuilder: FormBuilder, private modalService: NgbModal,  private environmentService: EnvironmentService) {
    
    // this.getMandals();
    // this.getCPDCLDistricts();
   
      // energy services disabled in new portal
    // if (!this.commonService.getMasterData('energyDistricts'))
    //   this.getCPDCLDistricts();
    // else
    //   this.districts = this.commonService.getMasterData('energyDistricts');
    
    this.getServiceListData();
    this.userName = localStorage.getItem('userName');
    this.date = moment(new Date).format("YYYY-MM-DD");

    let userData = localStorage.getItem('userInfo');
    if(userData && userData !== null) {
      this.userInfo = JSON.parse(userData);
      this.userID = btoa(this.userInfo?.userPrincipalName);
      if(this.userInfo.jobTitle === 'Citizen') {
        this.menuConstants = MenuConstantsCitizen;
      } else if(this.userInfo.jobTitle?.trim() === 'Meeseva') {
        this.menuConstants = MenuConstantsMeeseva;
      } else {
        this.menuConstants = MenuConstants;
      }
      let officeLocation = this.userInfo.officeLocation?.split('-');
      if (officeLocation?.length > 0) {
        this.secretariatName = officeLocation[1];
      }
    }
    if(this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle?.trim() !== 'Meeseva') {
      if (!this.commonService.getMasterData('MAUDMandals'))
      this.getMandals();
    else 
      this.mandals = this.commonService.getMasterData('MAUDMandals')
      // if (!this.commonService.getMasterData('transportServices'))
      //   this.getTransportServices();
      // else 
      //   this.mandals = this.commonService.getMasterData('transportServices')
    if (!this.commonService.getMasterData('totalServiceReq'))
        this.getUserWiseDashboardDetailsWithPagination('default');
    else 
        this.totalNumberOfServicesRaised = this.commonService.getMasterData('totalServiceReq');

    if (!this.commonService.getMasterData('serviceRaisedSameSec'))
        this.getRequestRaisedByUserWithPagination('default');
    else 
        this.totalNumberOfServicesRaisedInSameSecretariat = this.commonService.getMasterData('serviceRaisedSameSec');

    if (!this.commonService.getMasterData('serviceFlownToSec'))
        this.getRequestFlownToThisSecretariatWithPagination('default');
    else 
        this.totalNumberOfServicesFlownToThisSecretariat = this.commonService.getMasterData('serviceFlownToSec');

   

    if (!this.commonService.getMasterData('secRankings'))
        this.getSecretariatRankingDetails();
    else 
        this.sacretraitRanking = this.commonService.getMasterData('secRankings');
    }
    if (!this.commonService.getMasterData('dashboardCount'))
      this.getDADashBoardDetailsCount();
    else 
      this.dashbardVroCounts = this.commonService.getMasterData('dashboardCount');
    if (!this.commonService.getMasterData('lastUpdate'))
        this.lastUpdate = new Date();
    else 
        this.lastUpdate = this.commonService.getMasterData('lastUpdate');
    /* this.dashbardVroCounts = {
      toT_APPS : 0,
      closeD_WITH : 0,
      closeD_BEYOND : 0,
      opeN_WITH : 0,
      opeN_BEYOND : 0,
    }; */

    //Dashbaord APIs
    
    /* this.getUserWiseDashboardDetails();
    this.getRequestRaisedByUser();
    this.getRequestFlownToThisSecretariat(); */
  
  
    //  this.getUserWiseDashboardDetailsWithPagination("default");
    // this.getRequestRaisedByUserWithPagination("default");
    // this.getRequestFlownToThisSecretariatWithPagination("default");

    // this.getDADashBoardDetailsCount();
    // this.getSecretariatRankingDetails();

    
  

    this.MAUDForm = this.formBuilder.group({
      mandal: [null, [Validators.required]],
    });
    this.energyForm = this.formBuilder.group({
      district: [null, [Validators.required]],
    });
    this.setMenuDefault();

    this.ViewTransactions = this.formBuilder.group({
        transactionId: ["", [Validators.required]],
     });


  }

 

  ngOnInit(): void {


    // this.dashbardVroCounts = {
    //   toT_APPS : 0,
    //   closeD_WITH : 0,
    //   closeD_BEYOND : 0,
    //   opeN_WITH : 0,
    //   opeN_BEYOND : 0,
    // };
 
    let userInfo = localStorage.getItem('userInfo');
    if (userInfo && userInfo !== null) {
      this.userData = JSON.parse(userInfo);
      
      //console.log('userData', this.userData);
      let userId = this.userInfo.userPrincipalName?.split('-');
      if (userId?.length > 0) {
        let loginIds: any = ['11290387','11290573','11290153','11290148','11290147','11290363','10690364',
        '11290378','11290890','11290380', '12345678'];
        this.secretariatCode = userId[0]?.charAt(0)?.toUpperCase() === 'U' ? userId[0]?.split('U')[1] : userId[0];
        this.isLoginPresent = loginIds.includes(this.secretariatCode);
        //console.log('this.secretariatCode', this.secretariatCode)
        this.isProdTestUser = this.secretariatCode === '12345678' ? true : false;
      }
    }
   
    /* this.transactionDataPopup =    {
      
      data: [
        { role : "Digital Asistant", transaction_status:"Within SLA", transaction_date:"01-01-2022", status:"Transaction Initiated" },
        { role : "Village Revenue Officer" , transaction_status:"Beyond SLA", transaction_date:"01-01-2022", status:"Application Approved" },
        { role : "Revenue Inspector" , transaction_status:"Open", transaction_date:"01-01-2022", status:"Application Recommended" },
        { role : "Revenue Inspector" , transaction_status:"Closed", transaction_date:"01-01-2022", status:"Application Recommended" },
        // { role : "Revenue Inspector" , transaction_status:"Open", transaction_date:"01-01-2022", status:"Application Recommended" },
        // { role : "Tahsildar" , transaction_status:"Closed", transaction_date:"01-01-2022", status:"Transaction Closed" },
      ],

      tableData : {
        district: "test 4",
        mandal: "test 1",
        service_name : "test 13465",
        SLA: "5 days",
        citizen_name: "test test dsgsd",
      }
    };
       
    this.cntDash = this.transactionDataPopup.data.length; */
    //console.log(this.cntDash,"cntDash")
   if(this.userData.jobTitle != 'Citizen' && this.userData.jobTitle != 'Meeseva') {
    this.GetUserDetails();
    this.SaveLoginTime();
   }
   
    this.isProdEnv = false;
    if (
      /^vswsonline.ap.gov.in/.test(
        window && window.location && window.location.hostname
      )
    ) { 
        this.isProdEnv = true;
    }

    if(this.userInfo.userPrincipalName === "12345678-DA@apgsws.onmicrosoft.com"){
      this.isProdEnv = false;
    }
      this.getTransportServices();
      this.getopenSchoolServices();
      this.getFisheriesServices();
      this.getSVAMITVAMutationServices();
      this.getSandServices();
      this.getFireServices();
  }

  showPoliciesPopup(type:any){
    if(type === 'HardwareStatusforRegistrationofHouseSitePattas'){
      let modalRef: any = this.modalService.open(PoliciesPopupComponent, { size: 'xl', centered: true, backdrop: 'static'});
      modalRef.componentInstance.popup = type;
    }    
  }

  SaveHardwareStatusOfHouseSite(){
    let HardwareStatusOfHouseSite = {
      userId: this.userInfo.userPrincipalName,
    };
    this.commonService.postRequest(this.commonConstants.SaveHardwareStatusOfHouseSite, HardwareStatusOfHouseSite).subscribe({
      next: (responseData: any) => {
        console.log(responseData, 'responseData');
          this.isUserExist = responseData.result.isUserExist;
          if(!this.isUserExist){
            this.showPoliciesPopup('HardwareStatusforRegistrationofHouseSitePattas');
          }
    },
      error: (error) => {
        console.log('client side', error);
      }
    });      
  }

  SaveLoginTime(){
    let postData = {
      userId: this.userInfo.userPrincipalName,
      logInTime: moment(this.date).format("YYYY-MM-DDThh:mm:ss[Z]")
    };
    this.commonService.postRequest(this.commonConstants.SaveLoginTime , postData).subscribe({
      next: (responseData: any) => { 
        if(responseData.result.isSuccess){
        this.lastLoginTime = moment(responseData.result.userLogInDetailsData[0].logInTime).format("YYYY-MM-DD");
        if(this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle?.trim() !== 'Meeseva' && this.lastLoginTime !== this.date) {
          let modalRef: any = this.modalService.open(PoliciesPopupComponent, { size: 'lg', centered: true, backdrop: 'static'});
          modalRef.componentInstance.popup = 'challanRemittance';
          modalRef.componentInstance.userName = localStorage.getItem('userName');
          modalRef.result.then((result:any) => {
             this.SaveHardwareStatusOfHouseSite();
          })

        }
        else{
          this.SaveHardwareStatusOfHouseSite();
        }
        console.log(this.lastLoginTime);    
        console.log(moment(this.date).format("YYYY-MM-DD") + ' req date');
      }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  get f() {
    return this.MAUDForm.controls;
  }
  get fc() {
    return this.ViewTransactions.controls;
  }

  refreshDashboard() {
    if(this.userInfo.jobTitle !== 'Citizen' && this.userInfo.jobTitle !== 'Meeseva') {
    this.getUserWiseDashboardDetailsWithPagination("default");
    this.getRequestRaisedByUserWithPagination("default");
    this.getRequestFlownToThisSecretariatWithPagination("default");
    this.getSecretariatRankingDetails();
    this.getDADashBoardDetailsCount();
    } else {
      this.getDADashBoardDetailsCount();
    }
  }
  getMandals() {
    let districtId = '5572de9b-d2a8-49c4-a672-22404743d45c';
    this.commonService.getRequest(this.commonConstants.getUrbanMandals).subscribe({
      next: (responseData: any) => { 
      this.mandals = this.commonService.sortData(responseData.result);
      this.commonService.setMasterData('MAUDMandals', this.mandals);
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  onMandalChange() {
    this.maudServices = [];
    //console.log('mandal', this.f.mandal.value)
    this.commonService.getRequest(this.commonConstants.getMAUDServicesName + this.f.mandal.value.id).subscribe({
      next: (responseData: any) => {
      this.maudServices = responseData.result;
      this.getMAUDUrl();
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getMAUDUrl() {
    this.commonService.getRequest(this.commonConstants.getMAUDServiceUrl + this.f.mandal.value.id).subscribe({
      next: (responseData: any) => {
      this.maudurls = responseData.result;
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  onClickUrl(code: any) {
    let filterItem = this.maudurls.filter((items: { entityCode: any; }) => items.entityCode === code);
    let url = filterItem.length > 0 ? filterItem[0].name : '';
    //console.log('url', url);
     const modalRef = this.modalService.open(BasicDetailsPopupComponent, { size: 'xl' });
        modalRef.componentInstance.title = 'Basic Details';
        modalRef.result.then((result) => {
          if (result) {
            //console.log("adfsfsdg");
              //console.log(result);
              let postData: any = {
                serviceRequestHeader: {
                  requisitionType: code,
                  subRequisitionType: "",
                  departmentCode: "MAUD",
                  redirectionUrl: url,
                  userInfo: {
                    id: this.secretariatCode,
                    email:  this.userData.mail !== null ? this.userData.mail : this.userData.userPrincipalName,
                    name: this.userData.displayName,
                   // mobileNumber: this.userData.mobilePhone,
                    aadhaarNumber: result.encryptedAadharNo,
                    gender: result.gender,
                    birthDate: this.commonService.formatDate(result.dob),
                    Caste: result.caste.name,
                    religion: result.religion.name,
                    qualification: result.qualification.name,
                    maritalStatus: result.maritalStatus,
                    rationCardNo: "",
                    deliveryType: result.deliveryType,
                    mobileNumber: result.mobileNo,
                    emailAddress: result.email,
                    addresses: [
                      {
                        addressType:"present",
                        doorNo: result.preDoorNo,
                        streetName:result.preStreet,
                        villageWard: result.preVillage.entityCode,
                        mandalMunicipality: result.preMandal.entityCode,
                        district: result.preDistrict.entityCode,
                        pinCode: result.prePinCode,
                      },
                      {
                        addressType:"permanent",
                        doorNo: result.permDoorNo,
                        streetName: result.permStreet,
                        villageWard: result.permVillage.entityCode,
                        mandalMunicipality: result.permMandal.entityCode,
                        district: result.permDistrict.entityCode,
                        pinCode: result.permPinCode,
                      }
                    ],
                    personNames: [
                      {
                        languageCode: "en-in",
                        firstName: result.firstName,
                        middleName:result.middleName,
                        lastName: result.lastName,
                        fatherHusbandName: result.fatherHusName,
                      }
                    ],
                  },
                  remarks: ""
                }
              };
              //let commonUrl: any = 'https://kurnool-uat.allvy.com/wardsecretary/wardSecretaryServices-redirect';
              let commonUrl: any = 'https://kurnool-uat.allvy.com/wardsecretary/wsportal';
              this.commonService.postRequestForWebLand(this.commonConstants.initiateTransaction , postData).subscribe({
                next: (responseData: any) => {
                  let transactionDetails: any = responseData.result;
                  if (typeof(transactionDetails) !== 'string') { 
                    let transactionId = transactionDetails?.postParam?.encryptedTransactionId;
                    let deptCode = transactionDetails?.postParam?.encryptedDepartment;
                    let accessToken = transactionDetails?.serviceRequestHeader?.accessToken;
                    //console.log('on redirect', responseData.result);
                    this.redirectionUrl = url+'?DepartmentId='+deptCode+'&RequestId='+transactionId+'&TokenId='+accessToken;
                    //console.log('this.redirectionUrl', this.redirectionUrl);
                    window.open(this.redirectionUrl, '_blank');
                   } else {
                      this.alertPopup('other');
                    }
                },
                error: (error) => {
                console.log('client side', error);
                }
            });
          }
        });
  }
  getTransportServices() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'Transport-Service').subscribe({
      next: (responseData: any) => { 
        if (typeof(responseData.result) !== 'string'){
         this.transportServices = this.commonService.sortData(responseData.result);
        }
        else{
          this.transportServices=[];
        }
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }


  getSandServices() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'SANDonlinebookingGSWS').subscribe({
      next: (responseData: any) => { 
        if (typeof(responseData.result) !== 'string'){
         this.sandServices = this.commonService.sortData(responseData.result);
        }
        else{
          this.sandServices=[];
        }
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }


  getFireServices() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'AP DISASTER RESPONSE AND FIRE SERVICES DEPARTMENT').subscribe({
      next: (responseData: any) => { 
        if (typeof(responseData.result) !== 'string'){
         this.fireServices = this.commonService.sortData(responseData.result);
        }
        else{
          this.fireServices=[];
        }
      },
      error: (error) => {
      console.log('client side', error);
      }
    });
  }

  getopenSchoolServices() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'APOSS Amaravathi').subscribe({
      next: (responseData: any) => { 
        if (typeof(responseData.result) !== 'string'){
         this.openSchoolServices = this.commonService.sortData(responseData.result);
        }
        else{
          this.openSchoolServices=[];
        }

      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getFisheriesServices() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'Fisheries').subscribe({
      next: (responseData: any) => { 
        if (typeof(responseData.result) !== 'string'){
         this.fisheriesServices = this.commonService.sortData(responseData.result);
        }
        else{
          this.fisheriesServices=[];
        }

      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getSVAMITVAMutationServices() {
    this.commonService.getRequest(this.commonConstants.getByEntityType + 'SVAMITVA Mutation').subscribe({
      next: (responseData: any) => { 
        if (typeof(responseData.result) !== 'string'){
         this.SVAMITVAMutationServices = this.commonService.sortData(responseData.result);
        }
        else{
          this.SVAMITVAMutationServices=[];
        }
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  onClickTransportService(service: any) {
     const modalRef = this.modalService.open(BasicDetailsPopupComponent, { size: 'xl' });
        modalRef.componentInstance.title = 'Basic Details';
        modalRef.result.then((result) => {
          if (result) {
              let postData: any = {
                serviceRequestHeader: {
                  requisitionType: service.entityCode,
                  requisitionPKey: '',
                  departmentCode: "TD",
                  serviceName: service.name,
                  userInfo: {
                    id: this.secretariatCode,
                    aadhaarNumber: result.encryptedAadharNo,

                    personNames: [
                      {
                        languageCode: "en-in",
                        firstName: result.firstName,
                        middleName:result.middleName,
                        lastName: result.lastName,
                        fatherHusbandName: result.fatherHusName,
                      }
                    ],
                    gender: result.gender,
                    birthDate: this.commonService.formatDate(result.dob),
                    caste: result.caste.name, 
                    religion: result.religion.name,
                    qualification: result.qualification.name,
                    maritalStatus: result.maritalStatus,
                    addresses: [
                      {
                        addressType:"present",
                        doorNo: result.preDoorNo,
                        streetName:result.preStreet,
                        villageWard: result.preVillage.entityCode,
                        mandalMunicipality: result.preMandal.entityCode,
                        district: result.preDistrict.entityCode,
                        pinCode: result.prePinCode,
                      },
                      {
                        addressType:"permanent",
                        doorNo: result.permDoorNo,
                        streetName: result.permStreet,
                        villageWard: result.permVillage.entityCode,
                        mandalMunicipality: result.permMandal.entityCode,
                        district: result.permDistrict.entityCode,
                        pinCode: result.permPinCode,
                      }
                    ],
                    email: this.userData.userPrincipalName,
                    rationCardNo: "",
                    deliveryType: result.deliveryType,
                    mobileNumber: result.mobileNo
                  }
                }
              };
              this.commonService.postRequest(this.commonConstants.initiateTransportReq , postData).subscribe({
                next: (responseData: any) => {
                  let transactionDetails: any = responseData.result;
                  if (typeof(transactionDetails) !== 'string') { 
                    let transportRedirectionUrl: any = this.environmentService.externalUrls.transportServiceLandingPage + 'RequisitionID='+transactionDetails.gswsTransactionId +'&ServiceID='+service.entityCode+'&UserId='+this.userData.userPrincipalName;
                    window.open(transportRedirectionUrl, '_blank');
                   } else {
                      this.alertPopup('other');
                    }
                },
                error: (error) => {
                console.log('client side', error);
                }
            });
          }
        });


  }
  onClickHinduMarriageService() {
    const modalRef = this.modalService.open(BasicDetailsPopupComponent, { size: 'xl' });
       modalRef.componentInstance.title = 'Basic Details';
       modalRef.result.then((result) => {
         if (result) {
             let postData: any = {
                 requisitionType: "HMR",
                 subRequisitionType: "HinduMarriageCertificate",
                 departmentCode: "Registration and Stamps",
                 redirectionUrl: "",
                 userInfo: {
                   id: this.secretariatCode,
                   aadhaarNumber: result.encryptedAadharNo,

                   personNames: [
                     {
                       languageCode: "en-in",
                       firstName: result.firstName,
                       middleName:result.middleName,
                       lastName: result.lastName,
                       fatherHusbandName: result.fatherHusName,
                     }
                   ],
                   gender: result.gender,
                   birthDate: this.commonService.formatDate(result.dob),
                   caste: result.caste.name, 
                   religion: result.religion.name,
                   qualification: result.qualification.name,
                   maritalStatus: result.maritalStatus,
                   addresses: [
                     {
                       addressType:"present",
                       doorNo: result.preDoorNo,
                       streetName:result.preStreet,
                       villageWard: result.preVillage.entityCode,
                       mandalMunicipality: result.preMandal.entityCode,
                       district: result.preDistrict.entityCode,
                       pinCode: result.prePinCode,
                     },
                     {
                       addressType:"permanent",
                       doorNo: result.permDoorNo,
                       streetName: result.permStreet,
                       villageWard: result.permVillage.entityCode,
                       mandalMunicipality: result.permMandal.entityCode,
                       district: result.permDistrict.entityCode,
                       pinCode: result.permPinCode,
                     }
                   ],
                   email: this.userData.userPrincipalName,
                   rationCardNo: "",
                   deliveryType: result.deliveryType,
                   mobileNumber: result.mobileNo
                 }
               
             };
             this.commonService.postRequest(this.commonConstants.registrationService , postData).subscribe({
               next: (responseData: any) => {
                 let registrationDetails: any = responseData.result;
                 if (typeof(registrationDetails) !== 'string') { 
                   let marriageRedirectionUrl: any = this.environmentService.externalUrls.commonServiceLandingPage + 'departmentId='+registrationDetails.redirectionEncyrptParam.department+'&requestId='+registrationDetails.redirectionEncyrptParam.transactionId+'&tokenId='+registrationDetails.tokenId +'&DCode='+ registrationDetails.redirectionEncyrptParam.dCode + '&SCode=' + registrationDetails.redirectionEncyrptParam.sCode;
                   console.log(marriageRedirectionUrl, 'marriageRedirectionUrl');
                   window.open(marriageRedirectionUrl, '_blank');
                  } else {
                     this.alertPopup('other');
                   }
               },
               error: (error) => {
               console.log('client side', error);
               }
           });
         }
       });


 }
 
 onClickAarogyaSurakshaService() {
          let postData: any = {
                 requisitionType: "CaseSheet",
                 subRequisitionType: "Print CaseSheet",
                 departmentCode: "Health and Family",
                 redirectionUrl: "",
                 userInfo: {
                   id: this.secretariatCode,
                   aadhaarNumber: null,
  
                   personNames: [
                     {
                       languageCode: "en-in",
                       firstName: null,
                       middleName:null,
                       lastName:null,
                       fatherHusbandName:null,
                     }
                   ],
                   gender: null,
                   birthDate:null,
                   caste:null,
                   religion:null,
                   qualification:null,
                   maritalStatus:null,
                   addresses: [
                     {
                       addressType:null,
                       doorNo:null,
                       streetName:null,
                       villageWard:null,
                       mandalMunicipality:null,
                       district:null,
                       pinCode:null,
                     },
                     {
                       addressType:null,
                       doorNo:null,
                       streetName:null,
                       villageWard:null,
                       mandalMunicipality:null,
                       district:null,
                       pinCode:null,
                     }
                   ],
                   email: this.userData.userPrincipalName,
                   rationCardNo:null,
                   deliveryType:null,
                   mobileNumber:null,
                 }
               
             };
           this.commonService.postRequest(this.commonConstants.registrationService , postData).subscribe({
             next: (responseData: any) => {
               let registrationDetails: any = responseData.result;
               if (typeof(registrationDetails) !== 'string') { 
                 let caseSheetUrl: any = this.environmentService.externalUrls.commonServiceLandingPage + 'departmentId='+registrationDetails.redirectionEncyrptParam.department+'&requestId='+registrationDetails.redirectionEncyrptParam.transactionId+'&tokenId='+registrationDetails.tokenId +'&DCode='+ registrationDetails.redirectionEncyrptParam.dCode + '&SCode=' + registrationDetails.redirectionEncyrptParam.sCode;
                 console.log(caseSheetUrl, 'caseSheetUrl');
                 window.open(caseSheetUrl, '_blank');
                } else {
                   this.alertPopup('other');
                 }
             },
             error: (error) => {
             console.log('client side', error);
             }
         });
}


onClickSadaramService() {
this.userIdData = this.userInfo.userPrincipalName;
this.loginUserName = this.userInfo.displayName;
let postData: any = {};
postData.userMobileNumber = this.userData?.mobilePhone;
postData.secretariatCode = this.secretariatCode;
postData.userId = this.userIdData;
postData.userName = this.loginUserName;
postData.department = "SadaremService";

this.commonService.postRequest(this.commonConstants.commonRedirectionToExisting , postData).subscribe({
  next: (responseData: any) => {
    let RedirectionDetails: any = responseData.result;
    let transactionId: any = responseData.result?.transactionId;
    if (typeof(RedirectionDetails) !== 'string') { 
    let saderamRedirectionUrl: any = this.environmentService.externalUrls.saderamRedirection + '?refID='+transactionId;
    window.open(saderamRedirectionUrl, '_blank');
    console.log(saderamRedirectionUrl, 'saderamRedirection');
    }
  },
  error: (error) => {
  console.log('client side', error);
  }
});
}
//Open School Department Services Code
onClickOpenSchoolService(service: any) {
  const modalRef = this.modalService.open(BasicDetailsPopupComponent, { size: 'xl' });
     modalRef.componentInstance.title = 'Basic Details';
     modalRef.result.then((result) => {
       if (result) {
           let postData: any = {
               requisitionType: service.entityCode,
               subRequisitionType:service.name,
               departmentCode: "APOSS Amaravathi",
               redirectionUrl: "",
               userInfo: {
                 id: this.secretariatCode,
                 aadhaarNumber: result.encryptedAadharNo,

                 personNames: [
                   {
                     languageCode: "en-in",
                     firstName: result.firstName,
                     middleName:result.middleName,
                     lastName: result.lastName,
                     fatherHusbandName: result.fatherHusName,
                   }
                 ],
                 gender: result.gender,
                 birthDate: this.commonService.formatDate(result.dob),
                 caste: result.caste.name, 
                 religion: result.religion.name,
                 qualification: result.qualification.name,
                 maritalStatus: result.maritalStatus,
                 addresses: [
                   {
                     addressType:"present",
                     doorNo: result.preDoorNo,
                     streetName:result.preStreet,
                     villageWard: result.preVillage.entityCode,
                     mandalMunicipality: result.preMandal.entityCode,
                     district: result.preDistrict.entityCode,
                     pinCode: result.prePinCode,
                   },
                   {
                     addressType:"permanent",
                     doorNo: result.permDoorNo,
                     streetName: result.permStreet,
                     villageWard: result.permVillage.entityCode,
                     mandalMunicipality: result.permMandal.entityCode,
                     district: result.permDistrict.entityCode,
                     pinCode: result.permPinCode,
                   }
                 ],
                 email: this.userData.userPrincipalName,
                 rationCardNo: "",
                 deliveryType: result.deliveryType,
                 mobileNumber: result.mobileNo,
               }
             
           };
           this.commonService.postRequest(this.commonConstants.registrationService , postData).subscribe({
             next: (responseData: any) => {
               let registrationDetails: any = responseData.result;
               if (typeof(registrationDetails) !== 'string') { 
                let openSchoolRedirection: any = this.environmentService.externalUrls.commonServiceLandingPage +'TokenID='+registrationDetails.tokenId + '&RequestID='+registrationDetails.redirectionEncyrptParam.transactionId + '&DepartmentID='+registrationDetails.redirectionEncyrptParam.department + '&Candidate_Mobile_No='+result.mobileNo + '&Candidate_Full_Name='+result.firstName + '&Candidate_Father_Name='+result.fatherHusName + '&Gender='+result.gender + '&Candidate_Date_Of_Birth='+this.commonService.NCSFormatDate(result.dob) + '&Service_Code=' + service.entityCode + '&Aadhar_Number='+result.encryptedAadharNo + '&DCode='+ registrationDetails.redirectionEncyrptParam.dCode + '&SCode=' + registrationDetails.redirectionEncyrptParam.sCode;;
                 console.log(openSchoolRedirection, 'openSchoolRedirection');
                 window.open(openSchoolRedirection, '_blank');
                } else {
                   this.alertPopup('other');
                 }
             },
             error: (error) => {
             console.log('client side', error);
             }
         });
       }
     });


}

//Fisheries Department Services Code
onClickFisheriesServices(service: any) {
  this.loginUserName = this.userInfo.displayName;
  const modalRef = this.modalService.open(BasicDetailsPopupComponent, { size: 'xl' });
     modalRef.componentInstance.title = 'Basic Details';
     modalRef.result.then((result) => {
       if (result) {
           let postData: any = {
               requisitionType: service.entityCode,
               subRequisitionType:service.name,
               departmentCode: "Fisheries Department",
               redirectionUrl: "",
               userInfo: {
                 id: this.secretariatCode,
                 aadhaarNumber: result.encryptedAadharNo,
                 personNames: [
                   {
                     languageCode: "en-in",
                     firstName: result.firstName,
                     middleName:result.middleName,
                     lastName: result.lastName,
                     fatherHusbandName: result.fatherHusName,
                   }
                 ],
                 gender: result.gender,
                 birthDate: this.commonService.formatDate(result.dob),
                 caste: result.caste.name, 
                 religion: result.religion.name,
                 qualification: result.qualification.name,
                 maritalStatus: result.maritalStatus,
                 addresses: [
                   {
                     addressType:"present",
                     doorNo: result.preDoorNo,
                     streetName:result.preStreet,
                     villageWard: result.preVillage.entityCode,
                     mandalMunicipality: result.preMandal.entityCode,
                     district: result.preDistrict.entityCode,
                     pinCode: result.prePinCode,
                   },
                   {
                     addressType:"permanent",
                     doorNo: result.permDoorNo,
                     streetName: result.permStreet,
                     villageWard: result.permVillage.entityCode,
                     mandalMunicipality: result.permMandal.entityCode,
                     district: result.permDistrict.entityCode,
                     pinCode: result.permPinCode,
                   }
                 ],
                 email: this.userData.userPrincipalName,
                 rationCardNo: "",
                 deliveryType: result.deliveryType,
                 mobileNumber: result.mobileNo,
               }   
           };
           this.commonService.postRequest(this.commonConstants.registrationService , postData).subscribe({
             next: (responseData: any) => {
               let registrationDetails: any = responseData.result;
               this.userIdData = this.userInfo.userPrincipalName;
               this.loginUserName = this.userInfo.displayName;
               let postData: any = {};
                postData.userMobileNumber = this.userData?.mobilePhone;
                postData.secretariatCode = this.secretariatCode;
                postData.userId = this.userIdData;
                postData.userName = this.loginUserName;
                postData.department = "Fisheries Department";
                postData.serviceCode = service.entityCode;
                postData.requisitionId = registrationDetails.requisitionId;

                this.commonService.postRequest(this.commonConstants.commonRedirectionToExisting , postData).subscribe({
                  next: (responseData: any) => {
                    let refId: any = responseData.result?.transactionId;
                    if (typeof(registrationDetails) !== 'string') { 
                        let fisheriesRedirection: any = this.environmentService.externalUrls.commonServiceLandingPage + 'refID='+refId + '&DCode='+ registrationDetails.redirectionEncyrptParam.dCode + '&SCode=' + registrationDetails.redirectionEncyrptParam.sCode;
                         console.log(fisheriesRedirection, 'fisheriesRedirection');
                         window.open(fisheriesRedirection, '_blank');
                        } else {
                           this.alertPopup('other');
                         }
                  },
                  error: (error) => {
                  console.log('client side', error);
                  }
                });
             },
             error: (error) => {
             console.log('client side', error);
             }
         });
       }
     });

    }

    // GSWS ONLINE SAND BOOKING CODE 

  onClickOnlineSandBooking(service: any) {
      this.loginUserName = this.userInfo.displayName;
      const modalRef = this.modalService.open(BasicDetailsPopupComponent, { size: 'xl' });
         modalRef.componentInstance.title = 'Basic Details';
         modalRef.result.then((result) => {
           if (result) {
               let postData: any = {
                   requisitionType: service.entityCode,
                   subRequisitionType:service.name,
                   departmentCode: "Mines and Geology Department",
                   redirectionUrl: "",
                   userInfo: {
                     id: this.secretariatCode,
                     aadhaarNumber: result.encryptedAadharNo,
                     personNames: [
                       {
                         languageCode: "en-in",
                         firstName: result.firstName,
                         middleName:result.middleName,
                         lastName: result.lastName,
                         fatherHusbandName: result.fatherHusName,
                       }
                     ],
                     gender: result.gender,
                     birthDate: this.commonService.formatDate(result.dob),
                     caste: result.caste.name, 
                     religion: result.religion.name,
                     qualification: result.qualification.name,
                     maritalStatus: result.maritalStatus,
                     addresses: [
                       {
                         addressType:"present",
                         doorNo: result.preDoorNo,
                         streetName:result.preStreet,
                         villageWard: result.preVillage.entityCode,
                         mandalMunicipality: result.preMandal.entityCode,
                         district: result.preDistrict.entityCode,
                         pinCode: result.prePinCode,
                       },
                       {
                         addressType:"permanent",
                         doorNo: result.permDoorNo,
                         streetName: result.permStreet,
                         villageWard: result.permVillage.entityCode,
                         mandalMunicipality: result.permMandal.entityCode,
                         district: result.permDistrict.entityCode,
                         pinCode: result.permPinCode,
                       }
                     ],
                     email: this.userData.userPrincipalName,
                     rationCardNo: "",
                     deliveryType: result.deliveryType,
                     mobileNumber: result.mobileNo,
                   }   
               };
               this.commonService.postRequest(this.commonConstants.registrationService , postData).subscribe({
                 next: (responseData: any) => {
                   let registrationDetails: any = responseData.result;
                   this.userIdData = this.userInfo.userPrincipalName;
                   this.loginUserName = this.userInfo.displayName;
                   let postData: any = {};
                    postData.userMobileNumber = this.userData?.mobilePhone;
                    postData.secretariatCode = this.secretariatCode;
                    postData.userId = this.userIdData;
                    postData.userName = this.loginUserName;
                    postData.department = "Mines and Geology Department";
                    postData.serviceCode = service.entityCode;
                    postData.requisitionId = registrationDetails.requisitionId;
    
                    this.commonService.postRequest(this.commonConstants.commonRedirectionToExisting , postData).subscribe({
                      next: (responseData: any) => {
                        let refId: any = responseData.result?.transactionId;
                        if (typeof(registrationDetails) !== 'string') { 
                            let sandRedirection: any = this.environmentService.externalUrls.commonServiceLandingPage + 'refID='+refId + '&DCode='+ registrationDetails.redirectionEncyrptParam.dCode + '&SCode=' + registrationDetails.redirectionEncyrptParam.sCode + '&OrderId=' + registrationDetails.redirectionEncyrptParam.transactionId + '&DepartmentID=' + registrationDetails.redirectionEncyrptParam.department;
                             console.log(sandRedirection, 'sandRedirection');
                             window.open(sandRedirection, '_blank');
                            } else {
                               this.alertPopup('other');
                             }
                      },
                      error: (error) => {
                      console.log('client side', error);
                      }
                    });
                 },
                 error: (error) => {
                 console.log('client side', error);
                 }
             });
           }
         });
    }



    // fire Service
    onClickFireService(service: any) {
      this.loginUserName = this.userInfo.displayName;
      const modalRef = this.modalService.open(BasicDetailsPopupComponent, { size: 'xl' });
         modalRef.componentInstance.title = 'Basic Details';
         modalRef.result.then((result) => {
           if (result) {
               let postData: any = {
                   requisitionType: service.entityCode,
                   subRequisitionType:service.name,
                   departmentCode: "AP DISASTER RESPONSE AND FIRE SERVICES DEPARTMENT",
                   redirectionUrl: "",
                   userInfo: {
                     id: this.secretariatCode,
                     aadhaarNumber: result.encryptedAadharNo,
                     personNames: [
                       {
                         languageCode: "en-in",
                         firstName: result.firstName,
                         middleName:result.middleName,
                         lastName: result.lastName,
                         fatherHusbandName: result.fatherHusName,
                       }
                     ],
                     gender: result.gender,
                     birthDate: this.commonService.formatDate(result.dob),
                     caste: result.caste.name, 
                     religion: result.religion.name,
                     qualification: result.qualification.name,
                     maritalStatus: result.maritalStatus,
                     addresses: [
                       {
                         addressType:"present",
                         doorNo: result.preDoorNo,
                         streetName:result.preStreet,
                         villageWard: result.preVillage.entityCode,
                         mandalMunicipality: result.preMandal.entityCode,
                         district: result.preDistrict.entityCode,
                         pinCode: result.prePinCode,
                       },
                       {
                         addressType:"permanent",
                         doorNo: result.permDoorNo,
                         streetName: result.permStreet,
                         villageWard: result.permVillage.entityCode,
                         mandalMunicipality: result.permMandal.entityCode,
                         district: result.permDistrict.entityCode,
                         pinCode: result.permPinCode,
                       }
                     ],
                     email: this.userData.userPrincipalName,
                     rationCardNo: "",
                     deliveryType: result.deliveryType,
                     mobileNumber: result.mobileNo,
                   }   
               };
               this.commonService.postRequest(this.commonConstants.registrationService , postData).subscribe({
                 next: (responseData: any) => {
                   let registrationDetails: any = responseData.result;
                   this.userIdData = this.userInfo.userPrincipalName;
                   this.loginUserName = this.userInfo.displayName;
                   let postData: any = {};
                    postData.userMobileNumber = this.userData?.mobilePhone;
                    postData.secretariatCode = this.secretariatCode;
                    postData.userId = this.userIdData;
                    postData.userName = this.loginUserName;
                    postData.department = "AP DISASTER RESPONSE AND FIRE SERVICES DEPARTMENT";
                    postData.serviceCode = service.entityCode;
                    postData.requisitionId = registrationDetails.requisitionId;
    
                    this.commonService.postRequest(this.commonConstants.commonRedirectionToExisting , postData).subscribe({
                      next: (responseData: any) => {
                        let refId: any = responseData.result?.transactionId;
                        if (typeof(registrationDetails) !== 'string') { 
                            let fireRedirection: any = this.environmentService.externalUrls.commonServiceLandingPage + 'refID='+refId + '&DCode='+ registrationDetails.redirectionEncyrptParam.dCode + '&SCode=' + registrationDetails.redirectionEncyrptParam.sCode + '&OrderId=' + registrationDetails.redirectionEncyrptParam.transactionId + '&DepartmentID=' + registrationDetails.redirectionEncyrptParam.department;
                             console.log(fireRedirection, 'fireRedirection');
                             window.open(fireRedirection, '_blank');
                            } else {
                               this.alertPopup('other');
                             }
                      },
                      error: (error) => {
                      console.log('client side', error);
                      }
                    });
                 },
                 error: (error) => {
                 console.log('client side', error);
                 }
             });
           }
         });
    }

GetUserDetails(){
  this.commonService.getRequest(this.commonConstants.getUserIdDetails).subscribe({
    next: (responseData: any) => {
      this.commonService.getRequest(this.commonConstants.GetDetails+ '?Location=' + responseData?.result[0]?.locationScope?.locationType).subscribe({
      next: (response: any) => { 
        this.locationDetails = response?.result?.welfareList[0];
        console.log(this.locationDetails,'checking the response')
      },
      error: (error) => {  
        console.log('client side', error);
      }
    });
},
error: (error) => {
  console.log('client side', error);
}
});
}
//PR Department Services Code
onClickSVAMITVAMutationServices(service: any) {
  const modalRef = this.modalService.open(BasicDetailsPopupComponent, { size: 'xl' });
     modalRef.componentInstance.title = 'Basic Details';
     modalRef.result.then((result) => {
       if (result) {
           let postData: any = {
               requisitionType: service.entityCode,
               subRequisitionType:service.name,
               departmentCode: "AP Panchayat Raj Department",
               redirectionUrl: "",
               userInfo: {
                 id: this.secretariatCode,
                 aadhaarNumber: result.encryptedAadharNo,

                 personNames: [
                   {
                     languageCode: "en-in",
                     firstName: result.firstName,
                     middleName:result.middleName,
                     lastName: result.lastName,
                     fatherHusbandName: result.fatherHusName,
                   }
                 ],
                 gender: result.gender,
                 birthDate: this.commonService.formatDate(result.dob),
                 caste: result.caste.name, 
                 religion: result.religion.name,
                 qualification: result.qualification.name,
                 maritalStatus: result.maritalStatus,
                 addresses: [
                   {
                     addressType:"present",
                     doorNo: result.preDoorNo,
                     streetName:result.preStreet,
                     villageWard: result.preVillage.entityCode,
                     mandalMunicipality: result.preMandal.entityCode,
                     district: result.preDistrict.entityCode,
                     pinCode: result.prePinCode,
                   },
                   {
                     addressType:"permanent",
                     doorNo: result.permDoorNo,
                     streetName: result.permStreet,
                     villageWard: result.permVillage.entityCode,
                     mandalMunicipality: result.permMandal.entityCode,
                     district: result.permDistrict.entityCode,
                     pinCode: result.permPinCode,
                   }
                 ],
                 email: this.userData.userPrincipalName,
                 rationCardNo: "",
                 deliveryType: result.deliveryType,
                 mobileNumber: result.mobileNo,
               }
             
           };
           this.commonService.postRequest(this.commonConstants.registrationService , postData).subscribe({
             next: (responseData: any) => {
               let registrationDetails: any = responseData.result;
               if (typeof(registrationDetails) !== 'string') { 
                let SVAMITVAMutationRedirection: any = this.environmentService.externalUrls.commonServiceLandingPage +'District_ID=' + this.locationDetails.districtId +'&Mandal_ID=' + this.locationDetails.mandalId+ '&Secretariat_ID=' +this.locationDetails.secretariatCode+ '&DCode='+ registrationDetails.redirectionEncyrptParam.dCode + '&SCode=' + registrationDetails.redirectionEncyrptParam.sCode ;
                 console.log(SVAMITVAMutationRedirection, 'SVAMITVAMutationRedirection');
                 window.open(SVAMITVAMutationRedirection, '_blank');
                } else {
                   this.alertPopup('other');
                 }
             },
             error: (error) => {
             console.log('client side', error);
             }
         });
       }
     });


}
  getCPDCLDistricts() {
    this.commonService.getRequest(this.commonConstants.getCPDCLDistricts).subscribe({
      next: (responseData: any) => { 
        this.districts = responseData.result;
        this.getEPDCLDistricts();
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  getEPDCLDistricts() {
    this.commonService.getRequest(this.commonConstants.getEPDCLDistricts).subscribe({
      next: (responseData: any) => {
        let districts = [...this.districts, ...responseData.result];
        this.districts = this.commonService.sortData(districts);
        this.getSPDCLDistricts();
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  getSPDCLDistricts() {
    this.commonService.getRequest(this.commonConstants.getSPDCLDistricts).subscribe({
      next: (responseData: any) => {
        let districts = [...this.districts, ...responseData.result]
        this.districts = this.commonService.sortData(districts);
        this.commonService.setMasterData('energyDistricts', this.districts);
      },
      error: (error) => {
        //console.log('client side', error);
      }
  });
  }
  onDistrictChange() {
    this.energyServices = [];
    let url;
    let selectedDistrict = this.energyForm.controls.district.value;
    if(selectedDistrict.entityType === 'CPDCLDISTRICT') {
      url = this.commonConstants.getEnergyServicesName;
    } else if(selectedDistrict.entityType === 'EPDCLDISTRICT') {
      url = this.commonConstants.getEPDCLServicesName;
    } else if(selectedDistrict.entityType === 'SPDCLDISTRICT') {
      url = this.commonConstants.getSPDCLServicesName;
    }
    this.commonService.getRequest(url + this.energyForm.controls.district.value.id).subscribe({
      next: (responseData: any) => {
      this.energyServices = responseData.result;
      this.getEnergyServicesUrl();
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  getEnergyServicesUrl() {
    let url;
    let selectedDistrict = this.energyForm.controls.district.value;
    if(selectedDistrict.entityType === 'CPDCLDISTRICT') {
      url = this.commonConstants.getEnergyServicesUrl;
      this.commonUrl = this.environmentService.externalUrls.CPDCL;
    } else if(selectedDistrict.entityType === 'EPDCLDISTRICT') {
      url = this.commonConstants.getEPDCLServicesUrl;
      this.commonUrl = this.environmentService.externalUrls.EPDCL;
    } else if(selectedDistrict.entityType === 'SPDCLDISTRICT') {
      url = this.commonConstants.getSPDCLServicesUrl;
      this.commonUrl = this.environmentService.externalUrls.SPDCL;
    }
    this.commonService.getRequest(url + this.energyForm.controls.district.value.id).subscribe({
      next: (responseData: any) => {
      this.energyurls = responseData.result;
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }
  onClickEnergyUrl(code: any) {
    let filterItem = this.energyurls.filter((items: { entityCode: any; }) => items.entityCode === code);
    let url = filterItem.length > 0 ? filterItem[0].name : '';
    const modalRef = this.modalService.open(BasicDetailsPopupComponent, { size: 'xl' });
    modalRef.componentInstance.title = 'Basic Details';
    modalRef.result.then((result) => {
    if (result) {
      let postData: any = {
        serviceRequestHeader: {
          requisitionType: code,
          subRequisitionType: "",
          departmentCode: "ENERGY",
          redirectionUrl: url,
          userInfo: {
            id: this.secretariatCode,
            email:  this.userData.mail !== null ? this.userData.mail : this.userData.userPrincipalName,
            name: this.userData.displayName,
           // mobileNumber: this.userData.mobilePhone, // need to check reg mobile number
            aadhaarNumber: result.encryptedAadharNo,
            gender: result.gender,
            birthDate: this.commonService.formatDate(result.dob),
            Caste: result.caste.name,
            religion: result.religion.name,
            qualification: result.qualification.name,
            maritalStatus: result.maritalStatus,
            rationCardNo: "",
            emailAddress: result.email,
            deliveryType: result.deliveryType,
            mobileNumber: result.mobileNo,
            addresses: [
              {
                addressType:"present",
                doorNo: result.preDoorNo,
                streetName:result.preStreet,
                villageWard: result.preVillage.entityCode,
                mandalMunicipality: result.preMandal.entityCode,
                district: result.preDistrict.entityCode,
                pinCode: result.prePinCode,
              },
              {
                addressType:"permanent",
                doorNo: result.permDoorNo,
                streetName: result.permStreet,
                villageWard: result.permVillage.entityCode,
                mandalMunicipality: result.permMandal.entityCode,
                district: result.permDistrict.entityCode,
                pinCode: result.permPinCode,
              }
            ],
            personNames: [
              {
                languageCode: "en-in",
                firstName: result.firstName,
                middleName:result.middleName,
                lastName: result.lastName,
                fatherHusbandName: result.fatherHusName,
              }
            ]
          },
         // remarks: "",
          accessToken: ""
        }
      };
      
      //let commonUrl: any = 'http://103.231.215.242:8080/APCPDCL_SERVICES_GSWS/servlet/LT_NewConn_URLGSWS';
      //let commonUrl: any = 'http://59.144.184.77:8085/EPDCL_GSWS/integrateGswsAndEpdclWeb';
      this.commonService.postRequestForWebLand(this.commonConstants.initiateTransactionEnergy , postData).subscribe({
        next: (responseData: any) => {
            let transactionDetails: any = responseData.result;
            if (typeof(transactionDetails) !== 'string') {
              let transactionId = transactionDetails?.postParam?.encryptedTransactionId;
              let deptCode = transactionDetails?.postParam?.encryptedDepartment;
              let accessToken = transactionDetails?.serviceRequestHeader?.accessToken;
              //console.log('on redirect', responseData.result);
              this.redirectionUrl = this.commonUrl+'?DepartmentId='+deptCode+'&RequestId='+transactionId+'&TokenId='+accessToken;
              //console.log('this.redirectionUrl', this.redirectionUrl);
              window.open(this.redirectionUrl, '_blank');
            } else {
              this.alertPopup('other');
            }
            
        },
        error: (error) => {
        console.log('client side', error);
        }
    });
  }
  });
  }

  getUserWiseDashboardDetails(){
    this.totalNumberOfServicesRaised = 0;
    this.totalNumberOfServicesRaiseddata = [];
    this.commonService
    .getRequest(this.commonConstants.getUserWiseDashboardDetails + this.userInfo.userPrincipalName )
    .subscribe({
      next: (responseData: any) => {
          if(responseData?.result){     
            //this.totalNumberOfServicesRaiseddata =   responseData?.result;  
            for (let obj of responseData?.result) {
            
              this.totalNumberOfServicesRaised++;
              obj.serviceFullName = this.getServiceName(obj.requisitionType);
               this.totalNumberOfServicesRaiseddata.push(obj);
            }
          }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });

  }
  
  getRequestRaisedByUser(){
    
    this.totalNumberOfServicesRaisedInSameSecretariat = 0;
    this.totalNumberOfServicesRaisedInSameSecretariatData = [];
   /*  let locationId = this.userInfo.userPrincipalName.split('-')[0];
    locationId = locationId.replace('U','');
    //console.log(locationId,"locationId") */

    this.commonService
    //.getRequest( this.commonConstants.getRequestRaisedByUser + this.userInfo.userPrincipalName+'&LocationId=21028013' )
    .getRequest( this.commonConstants.getRequestRaisedByUser + 'UserId='+this.userInfo.userPrincipalName )
    .subscribe({
      next: (responseData: any) => {
          if(responseData?.result){     
          // this.totalNumberOfServicesRaisedInSameSecretariatData = responseData?.result; 
          //console.log(responseData?.result,"user id") 
              for (let obj of responseData?.result) {
                this.totalNumberOfServicesRaisedInSameSecretariat++;
                obj.serviceFullName = this.getServiceName(obj.requisitionType);
                this.totalNumberOfServicesRaisedInSameSecretariatData.push(obj);
           
              }
          }
      },
      error: (error) => {
        //console.log('client side', error);
      },
    });
  }

  getRequestFlownToThisSecretariat(){
    
    this.totalNumberOfServicesFlownToThisSecretariat = 0;
    this.totalNumberOfServicesFlownToThisSecretariatData = [];
  
    this.commonService
    .getRequest( this.commonConstants.getRequestRaisedForUser + 'UserId='+this.userInfo.userPrincipalName )
    .subscribe({
      next: (responseData: any) => {
          if(responseData?.result){     
           //this.totalNumberOfServicesFlownToThisSecretariatData = responseData?.result;  
              for (let obj of responseData?.result) {
                this.totalNumberOfServicesFlownToThisSecretariat++;
                obj.serviceFullName = this.getServiceName(obj.requisitionType);
                this.totalNumberOfServicesFlownToThisSecretariatData.push(obj);
              }
          }
      },
      error: (error) => {
        //console.log('client side', error);
      },
    });
  }


  getUserWiseDashboardDetailsWithPagination(data:any){
    
    let isCount = false;
    if(data == "default"){
      this.lastUpdate = new Date();
      this.commonService.setMasterData('lastUpdate', this.lastUpdate);
      isCount = true;
    }
    this.totalNumberOfServicesRaised = 0;
    this.totalNumberOfServicesRaiseddata = [];
    this.items = [];

    let  postData = {
                        "search": {
                            "draw": this.page,
                            "length": 10,
                            "columns": [],
                            "searchCollection": [
                                {
                                    "name": "userId",
                                    "search": {
                                        "value":this.userInfo.userPrincipalName,
                                        "operator": "equal",
                                        "regex": ""
                                    }
                                }
                            ],
                            "orderCollection": [
                                {
                                    "name": "transactionDate",
                                    "order": {
                                        "dir": true
                                    }
                                }
                            ],
                            "isCountOnly":isCount,
                        }
                    };

    this.commonService
    .postRequest( this.commonConstants.getUserWiseRequisitionDetailsPaged , postData)
    .subscribe({
      next: (responseData: any) => {
          if(responseData?.result){     
            
            this.totalPageCount = responseData?.result?.filteredResultsCount;
            this.totalNumberOfServicesRaised =  responseData?.result?.filteredResultsCount;
            this.commonService.setMasterData('totalServiceReq', this.totalNumberOfServicesRaised);
            let pageNumbers = 1;
            pageNumbers = (this.page - 1) * 10;
          
            if(responseData?.result?.pagedList !== null){
              console.log("================IN list")
            responseData?.result?.pagedList.forEach((element: any) => {
              pageNumbers ++ ;
                let temp: any = {};
                temp.id  = pageNumbers ; 
                temp.requisitionId  = element?.requisitionId ; 
                temp.serviceFullName  = this.getServiceName(element.requisitionType) ; 
                temp.transactionId  = element?.transactionId ; 
                temp.transactionDate  = element?.transactionDate ; 
                temp.transactionAmount  = element?.transactionAmount ; 
                this.items.push(temp);
              });
            }
          
          }
      },
      error: (error) => {
        //console.log('client side', error);
      },
    });
  }

  getRequestRaisedByUserWithPagination(data:any){
    
    let isCount = false;
    if(data == "default"){
      isCount = true;
    }
    this.totalNumberOfServicesRaisedInSameSecretariat = 0;
    this.totalNumberOfServicesRaisedInSameSecretariatData = [];
    this.items = [];

    let  postData = {
                        "search": {
                            "draw": this.page,
                            "length": 10,
                            "columns": [],
                            "searchCollection": [
                                {
                                    "name": "userId",
                                    "search": {
                                        "value": this.userInfo.userPrincipalName,
                                        "operator": "equal",
                                        "regex": ""
                                    }
                                }
                            ],
                            "orderCollection": [
                                {
                                    "name": "transactionDate",
                                    "order": {
                                        "dir": true
                                    }
                                }
                            ],
                            "isCountOnly":isCount,
                        }
                    };

    this.commonService
    .postRequest( this.commonConstants.getRequestRaisedByUserPaged , postData)
    .subscribe({
      next: (responseData: any) => {
          if(responseData?.result){     
            
            this.totalPageCount = responseData?.result?.filteredResultsCount;
            this.totalNumberOfServicesRaisedInSameSecretariat =  responseData?.result?.filteredResultsCount;
            this.commonService.setMasterData('serviceRaisedSameSec', this.totalNumberOfServicesRaisedInSameSecretariat);
            let pageNumbers = 1;
            pageNumbers = (this.page - 1) * 10;
          
            if(responseData?.result?.pagedList !== null){
            responseData?.result?.pagedList.forEach((element: any) => {
              pageNumbers ++ ;
                let temp: any = {};
                temp.id  = pageNumbers ; 
                temp.requisitionId  = element?.requisitionId ; 
                temp.serviceFullName  = this.getServiceName(element.requisitionType) ; 
                temp.transactionId  = element?.transactionId ; 
                temp.transactionDate  = element?.transactionDate ; 
                temp.transactionAmount  = element?.transactionAmount ; 
                this.items.push(temp);
              });
            }
          
          }
      },
      error: (error) => {
        //console.log('client side', error);
      },
    });
  }
 
  getRequestFlownToThisSecretariatWithPagination(data:any){
    
    let isCount = false;
    if(data == "default"){
      isCount = true;
    }
    
    this.totalNumberOfServicesFlownToThisSecretariat = 0;
    this.totalNumberOfServicesFlownToThisSecretariatData = [];
    this.items = [];

    let  postData = {
                        "search": {
                            "draw": this.page,
                            "length": 10,
                            "columns": [],
                            "searchCollection": [
                                {
                                    "name": "userId",
                                    "search": {
                                        "value": this.userInfo.userPrincipalName,
                                        "operator": "equal",
                                        "regex": ""
                                    }
                                }
                            ],
                            "orderCollection": [
                                {
                                    "name": "transactionDate",
                                    "order": {
                                        "dir": true
                                    }
                                }
                            ],
                            "isCountOnly":isCount,
                        }
                    };

    this.commonService
    .postRequest( this.commonConstants.getRequestRaisedForUserPaged , postData )
    .subscribe({
      next: (responseData: any) => {
          if(responseData?.result){     
           
            this.totalPageCount = responseData?.result?.filteredResultsCount;
            this.totalNumberOfServicesFlownToThisSecretariat =  responseData?.result?.filteredResultsCount;
            this.commonService.setMasterData('serviceFlownToSec', this.totalNumberOfServicesFlownToThisSecretariat);
            
            let pageNumbers = 1;
              pageNumbers = (this.page - 1) * 10;
            
              if(responseData?.result?.pagedList !== null){
                responseData?.result?.pagedList.forEach((element: any) => {
                    pageNumbers ++ ;
                    let temp: any = {};
                    temp.id  = pageNumbers ; 
                    temp.requisitionId  = element?.requisitionId ; 
                    temp.serviceFullName  = this.getServiceName(element.requisitionType) ; 
                    temp.transactionId  = element?.transactionId ; 
                    temp.transactionDate  = element?.transactionDate ; 
                    temp.transactionAmount  = element?.transactionAmount ; 
                    this.items.push(temp);
                  });
              }
          }
      },
      error: (error) => {
        //console.log('client side', error);
      },
    });
  }
  

  showDashBoardPopup(content:any,data:any){
   
    console.log(data,"====data")
    this.selectDashboardBox = data;
    this.servicesData = [];
    this.items = [];
    this.page = 1;

    this.pageChanged(this.page);
   
   /*  if( data == 'totalServiceRaised'){
      this.servicesData = this.totalNumberOfServicesRaiseddata;
    }
    if( data == 'totalServiceFromSame'){
      this.servicesData = this.totalNumberOfServicesRaisedInSameSecretariatData;
    }
    if( data == 'totalServiceFromOther'){
      this.servicesData = this.totalNumberOfServicesFlownToThisSecretariatData;
    } */

    /* let pageNumbers = 1;
    pageNumbers = (this.page - 1) * 10; */
    
    /* this.servicesData.forEach((element: any) => {
     // pageNumbers ++ ;
      let temp: any = {};
     // temp.id  = pageNumbers ; 
      temp.requisitionId  = element?.requisitionId ; 
      temp.serviceFullName  = element?.serviceFullName ; 
      temp.transactionId  = element?.transactionId ; 
      temp.transactionDate  = element?.transactionDate ; 
      temp.transactionAmount  = element?.transactionAmount ; 
      this.items.push(temp);
    }); */

    //console.log( this.servicesData," this.servicesData")

    this.modalService
    .open(content, {
      size: 'xl',
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'app-modal-window',
    })
    .result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  
  pageChanged(event:any){
    console.log("pageChanged", this.page)

    if( this.selectDashboardBox == 'totalServiceRaised'){
      this.getUserWiseDashboardDetailsWithPagination("page");
    }
    if( this.selectDashboardBox == 'totalServiceFromSame'){
      this.getRequestRaisedByUserWithPagination("page");
    }
    if( this.selectDashboardBox == 'totalServiceFromOther'){
      this.getRequestFlownToThisSecretariatWithPagination("page");
    }

  }

  getServiceName(reqType:any){
    let serviceFullName =  this.serviceList.find((item: any) => item.serviceCode ===  reqType)?.serviceName;
    return (serviceFullName !== "" && serviceFullName !== undefined) ? serviceFullName : reqType;
  }

  /* trasaction status start*/

  submit(content:any):any{
  
    this.submitted = true;
    if (!this.ViewTransactions.valid) {
      return false;
    } else {
      //console.log(this.fc['transactionId'].value)
      this.getTransactionData(content,this.fc['transactionId'].value);
    }

  }

  getServiceListData(){
    this.serviceList = [];

    this.commonService
    .getRequest(this.commonConstants.getServiceList )
    .subscribe({
      next: (responseData: any) => {
        //console.log(responseData, 'get service list data');
        this.serviceList = responseData.result;
      },
      error: (error) => {
        //console.log('client side', error);
      },
    });
  }


  getTransactionData(content:any,TID:any){
  //let  postData = '?TransactionID=' + TID+'&Userid='+this.userInfo.userPrincipalName;
  let  postData = '?TransactionID=' + TID +'&TransactionStatus=0300' ; //+ '&TransactionStatus=';
  
     this.commonService
      .getRequest(this.commonConstants.getTransactions + postData)
      .subscribe({
        next: (responseData: any) => {
         
          this.transData = responseData.result[0];
          
          if(this.transData){
            //console.log( this.transData)
            this.transactionStatusPopup(content,this.transData);
          }else{
            this.alertPopup();
          }
        },
        error: (error) => {
          //console.log('client side', error);
        },
      });
  }

  transactionStatusPopup(content:any, data:any){
    
    //let postData = "?RequisitionId="+data.requisitionId+"&DepartmentCode="+data.departmentID+"&TransactionId="+data.transactionID;
    this.transactionID = data.transactionID;
    this.is_categoryA = false;
    this.departmentName = "";
    if(data.departmentID === "CS"){
      this.departmentName = "Civil Supplies";
    }
    if(data.departmentID === "RD"){
      this.departmentName = "Pensions";
    }
    if(data.departmentID === "MAUD"){
      this.departmentName = "MAUD";
    }
    if(data.departmentID === "RV"){
      this.departmentName = "Revenue ";
    }

     let postData = {
      RequisitionId: data.requisitionId,
      DepartmentCode: data.departmentID,
      TransactionId: data.transactionID
    }
    this.commonService
        .postRequest(this.commonConstants.receiptPageData, postData, true)
        .subscribe({
          next: (responseData: any) => {
            
          this.requisitionInfo = responseData?.result;
          this.dateTime = this.requisitionInfo.transactionDate;
          this.serviceName = this.requisitionInfo.serviceCode;
           this.applicantName =  this.titleCaseWord(this.requisitionInfo.applicantName);
          this.applicantFatherName = this.titleCaseWord(this.requisitionInfo.applicantFatherHusbandName) ;
          this.district = this.requisitionInfo.district ;
          this.mandal = this.requisitionInfo.mandal ;
          this.totalAmount = this.requisitionInfo.transactionAmount ;
          this.paymentMode = this.requisitionInfo.transactionMode ;
          
          this.amountInwords = this.intToEnglishWordConverter(this.totalAmount);
          this.getByEntitycode(this.district, 'District');
          this.getByEntitycode(this.mandal, 'Mandal');

          this.certificateTypeName = ""
          this.commonConstants.certificateList.forEach((e: any) => {
            if(this.serviceName == e.certName)
            {
               this.certificateTypeName =e.certID
            }
          });
         
          //get service name
          for(let i=0; i < this.serviceList.length;i++){
            if(this.serviceList[i].serviceCode === this.serviceName){
              this.serviceName = this.serviceList[i].serviceName;
            }
          }
          this.SLA = "";
          this.transactionStatusData = "Pending";
          //check catA service or not
          for(let i=0; i < this.commonConstants.categoryAServices.length;i++){
            if(this.commonConstants.categoryAServices[i].serviceCode == this.requisitionInfo.serviceCode){
             this.is_categoryA = true;
             this.transactionStatusData = "Approved";
             if(this.requisitionInfo.serviceCode === 'UpdateAarogyaSriCard') {
              this.SLA = "24 Hours" ;
            } else {
              this.SLA = "15 Minutes" ;
            }
          }
         }
     
         if(this.is_categoryA == false){
          //get SLA for those which having workflows
           this.getSLAData(this.requisitionInfo.serviceCode);
          // this.getStatusOfService( data.requisitionId, data.requisitionType);
          this.getApplicationStatusForCatB(data.requisitionId);
         }
           this.transactionPopup(content);
          
        },
        error: (error) => {
          //console.log('client side', error);
        },
      });
  }

  alertPopup(from?:any) {
    const modalRef = this.modalService.open(CommonMsgModalComponent, {
      size: 'md',
    });
    // modalRef.componentInstance.reqId = id;
    modalRef.componentInstance.title = 'Alert';
    if(from === 'other') {
      modalRef.componentInstance.message =
      'An error occured while processing your request. Please try again..!!';
    } else {
      modalRef.componentInstance.message =
      'Record not found...Please enter correct Transaction Id...!!!';
    }
    
    modalRef.result.then((result: any) => {
      if (result) {
        //console.log(result);
      }
    });
  }

  transactionPopup(content: any) {
    this.modalService.open(content, {
              size: 'lg',
              ariaLabelledBy: 'modal-basic-title',
              windowClass: 'app-modal-window',
            })
            .result.then(
              (result) => {
                this.closeResult = `Closed with: ${result}`;
              },
              (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
              }
            );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getStatusOfService(reqID:any,reqType:any) {
  
    //check catB service or not
      this.commonService.getRequest(this.commonConstants.getWorkItemHistory + reqType + '&WorkItemReference=' + reqID).subscribe({
        next: (responseData:any) => { 
  
          let historyItems = responseData?.result?.items;

          console.log(historyItems[historyItems?.length - 1].actionTaken,"status")
          console.log(historyItems?.length)
          if(historyItems?.length > 0 && historyItems[historyItems?.length - 1].actionTaken === 3) {
         
            this.transactionStatusData = "Approved";
        
          } else if(historyItems?.length > 0 && historyItems[historyItems?.length - 1].actionTaken === 4) {
         
            this.transactionStatusData = 'Rejected';
        
          }else{

            if(historyItems?.length > 0){
              this.transactionStatusData = "Pending with "+responseData.result.items[(responseData.result.items.length - 1)].workflowStepCode;
            }
            
            if(historyItems?.length === 0){
              this.transactionStatusData = "Pending with "+responseData.result.items[0].workflowStepCode;
            }
        
          }
        },
        error: (error) => {
          //console.log('client side', error);
        }
      });
  
   }

  getByEntitycode(entityCode: any, entityType:any, addrType?: any): any {
    this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({  
    next: (responseData: any) => { 
        let name =  responseData.result[0]?.name;
        name = this.titleCaseWord(name) ;
        if (entityType === 'District' ) {``
          this.district = name;
        }else if (entityType === 'Mandal') {
          this.mandal = name; 
        } 
      },
      error: (error) => {
        //console.log('client side', error);
      }
  });
  }
   
  getSLAData(ServiceCode:any){

    this.commonService
    .getRequest(this.commonConstants.getSLAData + "?ServiceCode="+ServiceCode)
    .subscribe({
      next: (responseData: any) => {
        if(responseData?.result != ""){
          if(responseData?.result === "No applicable workflow found"){
            if(ServiceCode === 'UpdateAarogyaSriCard') {
              this.SLA = "24 Hours" ;
            } else {
              this.SLA = "15 Minutes" ;
            }
          }else{
            this.SLA = responseData.result;
          }
        }
       },
      error: (error) => {
        //console.log('client side', error);
      },
    });
  }

  titleCaseWord(word: string) {
    if (!word) return word;
     word = word.toLowerCase();
    var splitStr = word.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
    }
    return splitStr.join(' '); 
  }

   intToEnglishWordConverter(number: number): any {
    var NS = [
      { value: 10000000, str: 'Crore' },
      { value: 100000, str: 'Lakh' },
      { value: 1000, str: 'Thousand' },
      { value: 100, str: 'Hundred' },
      { value: 90, str: 'Ninety' },
      { value: 80, str: 'Eighty' },
      { value: 70, str: 'Seventy' },
      { value: 60, str: 'Sixty' },
      { value: 50, str: 'Fifty' },
      { value: 40, str: 'Forty' },
      { value: 30, str: 'Thirty' },
      { value: 20, str: 'Twenty' },
      { value: 19, str: 'Nineteen' },
      { value: 18, str: 'Eighteen' },
      { value: 17, str: 'Seventeen' },
      { value: 16, str: 'Sixteen' },
      { value: 15, str: 'Fifteen' },
      { value: 14, str: 'Fourteen' },
      { value: 13, str: 'Thirteen' },
      { value: 12, str: 'Twelve' },
      { value: 11, str: 'Eleven' },
      { value: 10, str: 'Ten' },
      { value: 9, str: 'Nine' },
      { value: 8, str: 'Eight' },
      { value: 7, str: 'Seven' },
      { value: 6, str: 'Six' },
      { value: 5, str: 'Five' },
      { value: 4, str: 'Four' },
      { value: 3, str: 'Three' },
      { value: 2, str: 'Two' },
      { value: 1, str: 'One' },
    ];

    var result = '';
    for (var n of NS) {
      if (number >= n.value) {
        if (number <= 99) {
          result += n.str;
          number -= n.value;
          if (number > 0) result += ' ';
        } else {
          var t = Math.floor(number / n.value);
          // //console.log(t);
          var d = number % n.value;
          if (d > 0) {
            return (
              this.intToEnglishWordConverter(t) +
              ' ' +
              n.str +
              ' ' +
              this.intToEnglishWordConverter(d)
            );
          } else {
            return this.intToEnglishWordConverter(t) + ' ' + n.str;
          }
        }
      }
    }
    return result;
  }


  
  public printReceipt() {
    let Pagelink = 'about:blank';
    var pwa = window.open(Pagelink, '_new');
    pwa?.document.open();
    pwa?.document.write(this.preparePrintFormat());
    pwa?.document.close();
  }

  preparePrintFormat() {
    const printContent: HTMLElement | null =
      document.getElementById('printTransStatus');
    let htmlData: any = '';
    let printdata = printContent?.innerHTML;
    htmlData +=
      '<html><head><title>Receipt</title> <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">';
    htmlData +=
      "<style type='text/css' media='print'> @media print{@page{size: auto; margin: 0;} </style> <script>function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'>";

    htmlData += printdata;
    htmlData += '</body></html>';

    return htmlData;
  }

  /* trasaction status  end*/

  redirectToOldGSWS() {
    this.userIdData = this.userInfo.userPrincipalName;
    this.loginUserName = this.userInfo.displayName;
    let postData: any = {};
    postData.userMobileNumber = this.userData?.mobilePhone;
    postData.secretariatCode = this.secretariatCode;
    postData.userId = this.userIdData;
    postData.userName = this.loginUserName;
    //let url = 'http://uat.gramawardsachivalayam.ap.gov.in/GSWS/#!/SingleSignOn';
    let url = this.environmentService.externalUrls.gsws;
    this.commonService.postRequest(this.commonConstants.redirectToExisting , postData).subscribe({
      next: (responseData: any) => {
          let transactionId: any = responseData.result?.transactionId;
          //console.log('on redirect', responseData.result);
          window.open(url + '?ReqID='+ transactionId, '_blank');
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }

  
  redirectToOldGSWSNew() {

    this.userIdData = this.userInfo.userPrincipalName;
    this.loginUserName = this.userInfo.displayName;
    let postData: any = {};
    postData.userMobileNumber = this.userData?.mobilePhone;
    postData.secretariatCode = this.userInfo.jobTitle === 'Citizen' ? null : this.secretariatCode;
    postData.userId = this.userIdData;
    postData.userName = this.loginUserName;
    postData.department = "Meeseva";
    //let url = 'http://uat.gramawardsachivalayam.ap.gov.in/GSWS/#!/SingleSignOn';
    // let url = "http://uat.meeseva.gov.in/GSVWIMeeseva/UserInterface/DC/APSevaRedirection.aspx";
   
    //let url = "http://uatmeeseva.ap.gov.in/GSVWIMeeseva/UserInterface/DC/APSevaRedirection.aspx" //this.environmentService.externalUrls.gsws;
    let url: any;
    if(this.userInfo.jobTitle === 'Citizen') {
      url = this.environmentService.externalUrls.meesevaCitizen;
    } else {
     url = "https://gsws-meeseva1.ap.gov.in/GSVWIMeeseva/UserInterface/DC/APSevaRedirection.aspx" ;
    }
    this.commonService.postRequest(this.commonConstants.commonRedirectionToExisting , postData).subscribe({
      next: (responseData: any) => {
          let transactionId: any = responseData.result?.transactionId;
          //console.log('on redirect', responseData.result);
          window.open(url + '?ReqID='+ transactionId, '_blank');
      },
      error: (error) => {
      console.log('client side', error);
      }
  });
  }

  searchServiceName() {
    if(this.searchService.trim() !== null && this.searchService.trim() !== '') {
      this.menuConstants = this.menuConstants.map((item: any) => {
          let filteredItems = item.services.filter((val: any) => val.name.toLowerCase().includes(this.searchService.trim().toLowerCase()));
          if (filteredItems?.length > 0){
            //console.log('if')
            item.showMenu = true;
            item.showParent = true;
          } else {
            //console.log('else')
            item.showMenu = false;
            item.showParent = false;
          }
          return item;
      })
    } else {
      this.setMenuDefault();
    }
  }
  setMenuDefault() {
    this.menuConstants.map((item: any) => {
      item.showParent = true;
      item.showMenu = false;
      return item;
    });
  }


  getDADashBoardDetailsCount(){
    // this.dashbardVroCounts = {
    //   toT_APPS : 0,
    //   closeD_WITH : 0,
    //   closeD_BEYOND : 0,
    //   opeN_WITH : 0,
    //   opeN_BEYOND : 0,
    // };
    //this.dashbardVroCounts = [];
    this.commonService
    .getRequest( this.commonConstants.getDADashBoardDetailsCount + '?UserId='+ this.userInfo.userPrincipalName)
    .subscribe({
      next: (responseData: any) => {

        if(responseData?.result[0]){
          this.dashbardVroCounts = responseData.result[0];
          this.commonService.setMasterData('dashboardCount', this.dashbardVroCounts)
          // console.log(responseData.result)
        }

      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

    
  getVRODashBoardDetailsList(showPopup:any,appstatus:any,slaStatus:any,appCount:any){
   
    if(appCount == '' || appCount == 0){
      console.log(appCount,"appCount")
    }else{
      
        this.transactionID="";
        this.servicesCountData = [];
        this.items = [];
        this.page = 1;
        this.dashbardpopupTitle = "";
        if( slaStatus == 'Within-SubSLA'){
          slaStatus = 'Within SLA';
          this.dashbardpopupTitle = appstatus+" Within SLA ";
        } else if( slaStatus == 'Beyond-SubSLA'){
          slaStatus = 'Beyond SLA';
          this.dashbardpopupTitle = appstatus+" Beyond SLA ";
        }

        let inputStr = "UserId="+ this.userInfo.userPrincipalName+"&AppStatus="+appstatus+"&SlaStatus="+slaStatus;
        this.commonService
        .getRequest( this.commonConstants.getDADashBoardDetailsList + inputStr )
        .subscribe({
          next: (responseData: any) => {
            if(responseData?.result){
            // this.servicesData = responseData?.result;
            // console.log('userid, appstatus,slastatus', responseData?.result);
              for (let obj of responseData?.result) {
                obj.serviceFullName = this.getServiceName(obj.requisitioN_TYPE);
                this.servicesCountData.push(obj);
              }

              this.servicesCountData.forEach((element: any) => {
                let temp: any = {};
                temp.requisitionId  = element?.requisitioN_ID ; 
                temp.serviceFullName  = element?.serviceFullName ; 
                temp.transactionDate  = element?.transactioN_DATE ; 
                temp.transactionAmount  = element?.transactioN_AMOUNT ; 
                temp.transactionId  = element?.transactioN_ID ; 
                this.items.push(temp);
              });


            }
            this.showDashBoardCountPopup(showPopup);
          },
          error: (error) => {
            console.log('client side', error);
          },
        });

    }

  }

  getSecretariatRankingDetails(){
    let appId  ="10690658";
    this.commonService
    .getRequest( this.commonConstants.getSecretariatRankingDetails + '?UserId='+ this.userInfo.userPrincipalName)
    .subscribe({
      next: (responseData: any) => {
         // console.log(responseData,"getSecretariatRankingDetails")
          if( typeof responseData.result ==  "object"){
          this.sacretraitRanking = responseData.result;
          this.commonService.setMasterData('secRankings', this.sacretraitRanking);
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
 
  showDashBoardCountPopup(content:any){
    // console.log(this.servicesData, "servicesData")
   
    this.modalService
    .open(content, {
      size: 'xl',
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'app-modal-window',
    })
    .result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }



  getApplicationStatusForCatB(applicationNo:any){

    this.transactionDataPopup = [];
    let postData ={
      ApplicationId : applicationNo
    }
    this.commonService
    .postRequest( this.commonConstants.getDashBoardApplicationDetails, postData, true )
    .subscribe({
      next: (responseData: any) => {
      
        console.log(responseData,"getDashBoardApplicationDetails")
  
        this.transactionDataPopup =  responseData.result;
        let result =  Array.isArray(this.transactionDataPopup);
        if(result && this.transactionDataPopup.length != 0 )
        { 
          this.cntDash = this.transactionDataPopup.length;
          this.transactionDataPopup.sort((a:any, b:any) => {
            var keyA = new Date(a.step_Order);
            var keyB = new Date(b.step_Order);
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });

          this.transactionStatusData = this.transactionDataPopup[0].slA_Status
          
        }else{
         // this.alertPopupForNoRecords();
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  alertPopupForNoRecords() {
    const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
    modalRef.componentInstance.title = 'Alert';
    modalRef.componentInstance.message = 'The entered application not found...Please enter valid application number.';
    modalRef.result.then((result: any) => {});
  }

 
  onClickESeva() {
    // this.commonService.getRequest(this.commonConstants.getParentCodeByChildCode + 'Secretariat&EntityCode='+ this.secretariatCode +'&LanguageCode=EN-IN').subscribe({
    //   next: (responseData:any) => { 
    //    let mandalCode: any = responseData.result[0]?.entityCode;
    //    this.commonService.getRequest(this.commonConstants.getParentCodeByChildCode + 'Mandal&EntityCode='+ mandalCode +'&LanguageCode=EN-IN').subscribe({
    //     next: (responseData:any) => { 
    //      let gswsDistrictId: any = responseData.result[0]?.id;
    //      this.commonService.getRequest(this.commonConstants.getByParentId + 'eSevaDistrict&ParentId='+ gswsDistrictId +'&ParentType=District&LanguageCode=EN-IN').subscribe({
    //       next: (responseData:any) => { 
    //        let districtCode: any = responseData.result[0]?.entityCode;
    //        this.redirectToESeva(districtCode);
    //       },
    //       error: (error) => {
    //         console.log('client side', error);
    //       }
    //     });
    //     },
    //     error: (error) => {
    //       console.log('client side', error);
    //     }
    //   });
    //   },
    //   error: (error) => {
    //     console.log('client side', error);
    //   }
    // }); 
    this.commonService.getRequest(this.commonConstants.getDistCodeBySecCode + this.secretariatCode).subscribe({
      next: (responseData:any) => { 
       let oldDistrictCode: any = responseData.result?.olD_LGD_DIST_CODE;
       this.commonService.getRequest(this.commonConstants.getChildCodeByParentCodeCommon + 'District&EntityCode='+ oldDistrictCode +'&ChildEntityType=eSevaDistrict&LanguageCode=EN-IN').subscribe({
        next: (responseData:any) => { 
          let districtCode: any = responseData.result[0]?.entityCode;
          if (districtCode !== null && districtCode !== undefined && districtCode !== '') {
           this.redirectToESeva(districtCode);
          } else {
            this.commonService.commonErrorPopup('Alert', 'An error occured while processing your request. Please try again..!!', 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  redirectToESeva(districtCode: any) {
    let mail = this.userInfo.userPrincipalName?.split('@');
    let staffCode = mail[0]?.split('-'); 
    let postData: any = {};
    postData.staffCode = this.secretariatCode + staffCode[1]; // 12345678DA
    postData.districtCode = districtCode;
    postData.counter = '1';
    postData.centreCode  = this.secretariatCode;
    postData.shiftCode  = '1';
    postData.agencyType  = 'A';
    postData.agencyName  = 'APSEVA';
    postData.userName  = 'eseva';
    postData.password  = 'eseva';
    postData.franCode  = this.userInfo.userPrincipalName;
    postData.departmentCode = 'ESEVA';
    postData.requisitionType = 'eSevaServices';
   // console.log('postData', postData);
    this.commonService.postRequestForWebLand(this.commonConstants.eSevaTransaction , postData).subscribe({
      next: (responseData: any) => {
        let transactionDetails: any = responseData.result;
        if (typeof(transactionDetails) !== 'string') { 
          let transactionId = transactionDetails?.requestID; //requestID //enCryptRequestID
          let tokenId = transactionDetails?.tokenID;
          let redirectionUrl = this.environmentService.externalUrls.eSeva +'?RequisitionId='+ transactionId +'&TokenId='+ tokenId;
          //console.log('this.redirectionUrl', this.redirectionUrl);
          window.open(redirectionUrl, '_blank');
         } else {
            this.alertPopup('other');
          }
      },
      error: (error: any) => {
      console.log('client side', error);
      }
  });
  }
  openCCC(value:any){
    let url='';
    if(value === 'complaint'){
       url ='https://apspdcl.in/ConsumerDashboard/CccLodgeComplaint.jsp?source='+this.userID;
    }else{
      url='https://apspdcl.in/ConsumerDashboard/CCCcomplaintstatus.jsp?source='+this.userID;
    }
    
if(url){
  window.open(url, '_blank');
}   
  }
}
