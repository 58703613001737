<!-- <div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Selected Criteria Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Service Type :</label>
                <div class="valueCls">{{certificateInfo?.serviceType ? (certificateInfo?.serviceType) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Issued Caste Certificate InPast:</label>
                <div class="valueCls">{{certificateInfo?.issuedCastCertificateInPast ? (certificateInfo?.issuedCastCertificateInPast) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Caste Claimed:</label>
                <div class="valueCls">{{certificateInfo?.castClaimed ? (certificateInfo?.castClaimed) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Purpose of Caste Certificate::</label>
                <div class="valueCls">{{certificateInfo?.purposeOfCertificate ? (certificateInfo?.purposeOfCertificate) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Caste Category :</label>
                <div class="valueCls">{{certificateInfo?.castCategory ? (certificateInfo?.castCategory) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Family Income:</label>
                <div class="valueCls">{{certificateInfo?.familyIncome ? (certificateInfo?.familyIncome) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Education Certificate Contains Caste:</label>
                <div class="valueCls">{{certificateInfo?.doesEducationCerficateContainCast ? (certificateInfo?.doesEducationCerficateContainCast) : '-' }}</div>
            </div>
        </div>
        
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div> -->

<!-- new -->
<div *ngIf="certificateInfo?.serviceType === 'IncomeCertificate' || certificateInfo?.serviceType === 'IntegratedCertificate'">

        <div class="certificateInfo">
            <div class="subHeadingCls mb-3">Applicant Details</div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Workflow Address Type:</label>
                        <div class="valueCls">{{ certificateInfo?.reIssueIntegratedcertificateData?.workflowAddressType ? (certificateInfo?.reIssueIntegratedcertificateData?.workflowAddressType == 'permanent' ? 'Permanent' : 'Present') : '-'}}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Revenue Village/Ward:</label>
                        <div class="valueCls">{{ certificateInfo?.reIssueIntegratedcertificateData?.revenueVillageName ? certificateInfo?.reIssueIntegratedcertificateData?.revenueVillageName : '-'}}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Relation With Applicant:</label>
                        <div class="valueCls">{{ certificateInfo?.relation }}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Service Type:</label>
                        <div class="valueCls">{{ certificateInfo?.serviceType }}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Application Number:</label>
                        <div class="valueCls">{{ certificateInfo?.applicationNo }}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Reason for applying reissuance certificate:</label>
                        <div class="valueCls">{{ certificateInfo?.applyReason }}</div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder" *ngIf="certificateInfo?.serviceType === 'IntegratedCertificate'">
                        <label class="labelCls">Reason for Integrated Certificate:</label>
                        <div class="valueCls">{{certificateInfo?.reIssueIntegratedcertificateData?.applySubReason}}</div>
                    </div>
                  </div>
            </div>

        </div>

        <div class="certificateInfo">
            <div class="subHeadingCls mb-3">Certificate Details</div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Aadhaar Number:</label>
                        <div class="valueCls">{{ maskedAadhar}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Citizen Name:</label>
                        <div class="valueCls">{{certificateInfo?.applicantName}}</div>
                    </div>
                
                    <div class="fieldHolder">
                        <label class="labelCls">Father / Husband Name:</label>
                        <div class="valueCls">{{certificateInfo?.fatherHusbandName}}</div>
                    </div>
                    <div class="fieldHolder" *ngIf="certificateInfo?.serviceType === 'IncomeCertificate'">
                        <label class="labelCls">Total Income:</label>
                        <div class="valueCls">{{certificateInfo?.reIssueIncomecertificateData?.totalIncome | number : '1.2-2'}}</div>
                    </div>
                    <div class="fieldHolder" *ngIf="certificateInfo?.serviceType === 'IntegratedCertificate'">
                        <label class="labelCls">Caste:</label>
                        <div class="valueCls">{{certificateInfo?.reIssueIntegratedcertificateData?.castCategory}}</div>
                    </div>
                    <div class="fieldHolder" *ngIf="certificateInfo?.serviceType === 'IntegratedCertificate'">
                        <label class="labelCls">Sub Caste:</label>
                        <div class="valueCls">{{certificateInfo?.reIssueIntegratedcertificateData?.castClaimed}}</div>
                    </div>
                
                
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Address:</label>
                        <div class="valueCls">{{certificateInfo?.doorNo}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">DistrictName:</label>
                        <div class="valueCls">{{certificateInfo?.districtName}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">MandalName:</label>
                        <div class="valueCls">{{certificateInfo?.mandalName}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">VillageName:</label>
                        <div class="valueCls">{{certificateInfo?.villageName}}</div>
                    </div>
                
                
                </div>
            </div>
        </div>

        <div class="certificateInfo">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Issued Officer Name: </label>
                        <div class="valueCls">{{certificateInfo?.verifiedByName}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Issued Officer District:</label>
                        <div class="valueCls">{{certificateInfo?.verifiedByDistrict}}</div>
                    </div>
                    
                    <div class="fieldHolder">
                        <label class="labelCls">Applied Date:</label>
                        <div class="valueCls">{{certificateInfo?.appiledDate  | date: 'dd/MM/yyyy'}}</div>
                    </div>
                
                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Issued Officer Designation:</label>
                        <div class="valueCls">{{certificateInfo?.verifiedByDesignation}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Issued Officer Mandal:</label>
                        <div class="valueCls">{{certificateInfo?.verifiedByMandal}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Issued Date:</label>
                        <div class="valueCls">{{certificateInfo?.headerDate | date: 'dd/MM/yyyy'}}</div>
                    <!--   <div class="valueCls" *ngIf="!isAppFromMeeSeva">{{certificateData.issuedDate}}</div> -->
                    </div>
                </div>

            
            </div>
        </div>

        <div class="certificateInfo">
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
                    <ng-container *ngIf="fileList && fileList.length !== 0">
                    <div class="subHeadingCls mb-3">List of Documents</div>
                    <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                        <div *ngIf="file.documentType === 'Application Form'">
                            <span class="numberCls">{{i+1}}</span><label class="labelCls">ID proof of the actual applicant or his/her parents:</label>
                            <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                        </div>
                    </div>
                    </ng-container>
                </div>
            </div>
        </div>
</div>

