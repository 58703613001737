<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Selection Criteria Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo.districtName?certificateInfo.districtName:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo.mandalMunicipalityName
                    ?certificateInfo.mandalMunicipalityName:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Village / Ward:</label>
                <div class="valueCls">{{certificateInfo.villageWardName?certificateInfo.villageWardName:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Khata Number:</label>
                <div class="valueCls">{{certificateInfo.khataNo}}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details :</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Pattadar Name:</label>
                <div class="valueCls">{{certificateInfo.pattadarName?certificateInfo.pattadarName:'-'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Biometric Authentication:</label>
                <div class="valueCls">{{certificateInfo.biometricAuthentication}}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Authenticate Mobile Number :</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Mobile Number:</label>
                <div class="valueCls">{{certificateInfo.mobileNo}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">OTP (One Time Password):</label>
                <div class="valueCls">{{certificateInfo.otp}}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of fileList ;let i = index">
                    <div *ngIf="file.documentType === 'Application Form'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span
                                (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>