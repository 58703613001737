import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateAdapter, NgbDateParserFormatter,NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomAdapter, CustomDateParserFormatter, CommonService, WhitespaceValidator } from '../../shared/common.service';
import { CommonConstants } from 'src/constants/common-constants.component';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-aarogya-suraksha-token-generation',
  templateUrl: './aarogya-suraksha-token-generation.component.html',
  styleUrls: ['./aarogya-suraksha-token-generation.component.css'],
  providers: [
  { provide: NgbDateAdapter, useClass: CustomAdapter },
  { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  DatePipe
  ]
})
export class AarogyaSurakshaTokenGenerationComponent implements OnInit {
  submitted: Boolean = false;
  tokenRegForm: FormGroup;
  commonConstants : any = CommonConstants;
  currentDate = new Date();
  maxDate: any;
  minDate: any;
  userData: any;
  role: any;
  hhid: any = '';
  tokenDetails: any;
  showPrint: Boolean = false;
  constructor(private formBuilder: FormBuilder, private commonService: CommonService, private modalService: NgbModal, private datePipe: DatePipe, private router: Router) {
    this.tokenRegForm = this.formBuilder.group({
      aadharNo: ['', [Validators.required, WhitespaceValidator.whiteSpaceValidate]],
      citizenName: ['', [Validators.required, Validators.pattern(this.commonConstants.namePattern), WhitespaceValidator.whiteSpaceValidate]],
      gender: [null, [Validators.required]],
      dob: ['', [Validators.required]]
    });
    let userInfo = localStorage.getItem('userInfo');
    if (userInfo && userInfo !== null) {
      this.userData = JSON.parse(userInfo);
      this.role = this.userData.jobTitle;
    }
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
  }

  ngOnInit(): void {
  }
  get f() {
    return this.tokenRegForm.controls;
  }
  getCitizenInfo() {
    if (this.f.aadharNo.valid) {
      let postData: any = {};
      postData.aadhaarNumber = this.commonService.RSAEncrypt(this.f.aadharNo.value, true);
      this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe({
        next: (responseData: any) => { 
          if(responseData?.result && responseData?.result.length !== 0) { 
            let data: any = responseData?.result[0];
            this.hhid = responseData?.result[0].id;
            this.tokenRegForm.patchValue({
              citizenName: data.firstName,
              gender: this.loadGender(data.gender?.toUpperCase()),
              dob: data.birthDate,
            })
          } else {   
            this.hhid = '';                 
            alert('Aadhaar is not enrolled in Household data. Please update Aadhaar in Household data in GSWS Volunteer APP');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      }); 
    }  else {         
      alert('Please enter valid Aadhar Number');
    }
  }
  resetDetails() {
    this.submitted = false;
    this.hhid = '';
    this.tokenDetails = [];
    this.showPrint = false;
    this.tokenRegForm.reset();
    this.tokenRegForm.enable();
  }
  generateToken(): any {
    this.submitted = true;
    if(!this.tokenRegForm.valid)
      return false;
    console.log(this.f.dob.value, 'dob')
    let year=  this.currentDate.getFullYear()
    let month= this.currentDate.getMonth() + 1
    let day=  this.currentDate.getDate()
    let dateFormat = day +"-"+  month +"-"+ year 
    let createdDate = moment(dateFormat,"DD-MM-YYYY").format("YYYY-MM-DDThh:mm:ss")
    let postData: any = {};
    postData.uid = this.commonService.RSAEncrypt(this.f.aadharNo.value);
    postData.name = this.f.citizenName.value;
    postData.gender = this.f.gender.value === 'Female' ? 'F' : (this.f.gender.value === 'Male' ? 'M' : 'T');
    postData.dob = this.commonService.formatDate(this.f.dob.value)?.split('T')[0];
    postData.hhid = this.hhid;
    postData.userId = this.userData.userPrincipalName;
    this.commonService.postRequest(this.commonConstants.tokenRegAarogyaSuraksha, postData).subscribe({
      next: (resData:any) => { 
        if(resData?.result?.isSuccess) {
          this.tokenDetails = resData?.result?.tokenDetailsData;
          if(this.tokenDetails?.remarks === 'Already token generated for this Aadhaar' || this.tokenDetails?.remarks === 'Token generated Successfully') {
            this.commonService.commonErrorPopup('Alert', this.tokenDetails?.remarks + ':' + this.tokenDetails?.tokeN_NO, 'md');
            this.showPrint = true;
            this.tokenDetails.createD_DATE =  createdDate;
            this.tokenRegForm.disable();
          } else {
            this.commonService.commonErrorPopup('Alert', this.tokenDetails?.remarks , 'md');
            this.showPrint = false;
          }
          
        } else {
          this.tokenDetails = [];
          this.showPrint = false;
        }
      },
      error: (error) => {
        this.tokenDetails = [];
        this.showPrint = false;
        console.log('client side', error);
      }
    });
  }
  openPrintPopup(printModal:any) {
    this.modalService.open(printModal, { size: 'lg', centered: true, backdrop: 'static'});
  }
  goToHome() {
    this.router.navigate(['/home']);
  }
  printTokenDetails() {
    let Pagelink = 'about:blank';
    var pwa = window.open(Pagelink, '_new');
    pwa?.document.open();
    pwa?.document.write(this.preparePrintFormat());
    pwa?.document.close();
  }
  preparePrintFormat() {
    const printContent: HTMLElement | null =
      document.getElementById('printContainer');
    let htmlData: any = '';
    let printdata = printContent?.innerHTML;
    htmlData +=
      '<html><head><title>Token Registration Details</title> <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">';
    htmlData +=
      `<style type='text/css' media='print'> @media print{@page{size: auto; margin: 0;} 
      .tableCls td, .tableCls th {padding: 5px;border: 1px solid #ccc;} table {width: 100%;} body{margin: 25px;}
        </style> <script>function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'>`;

    htmlData += printdata;
    htmlData += '</body></html>';

    return htmlData;
  }
  loadGender(value: string) {
    if (value === 'MALE')
      return 'Male';
    else if (value === 'FEMALE')
      return 'Female';
    else if (value === 'TRANSGENDER')
      return 'Transgender';
    else 
      return null;
  }
}
