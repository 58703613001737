<div class="reportContainer">
    <div [formGroup]="form">
        <div class="row mb-3">
        
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
              <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
              <select class="selectCls" formControlName="district" (change)="onDistrictChange()"
              [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
                 <option value="All">All</option>
                <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
              </select>
            </div>

            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Rural/Urban : <span class="mandatoryCls">*</span></label> 
                <select class="selectCls" required formControlName="RUFlag" (change)="onRuralUrbanChange()"
                [ngClass]="{ 'is-invalid':submitted && (f.RUFlag.errors || f.RUFlag.value === 'Select') }">
                <option value = "All">All</option>
                <option value = "R">Rural</option>
                <option value = "U">Urban</option>
                </select>
            </div>
        
        
        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Mandal/Municipality:  <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
            [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
            <option value="All">All</option>
            <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
          </select>
        </div>

        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls"> PHC <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="phc" (change)= "onPHCChange()"
              [ngClass]="{ 'is-invalid':submitted && (f.phc.errors || f.phc.value === 'Select') }">
              <option value="All">All</option>
              <option *ngFor="let phc of phcs" [ngValue]="phc">{{phc.phcName}}</option>
            </select>
          </div>
          </div>
      
      <div class="row mb-3">
          <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
              <label class="labelCls"> VHC <span class="mandatoryCls">*</span></label>
              <select class="selectCls" formControlName="vhc" (change)="onVHCChange()"
                [ngClass]="{ 'is-invalid':submitted && (f.vhc.errors || f.vhc.value === 'Select') }">
                <option value="All">All</option>
                <option *ngFor="let vhc of vhcs" [ngValue]="vhc">{{vhc.vhcName}}</option>
              </select>
            </div>

            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">From Date: <span class="mandatoryCls">*</span></label>
                <div class="input-group">
                  <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                         name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" (dateSelect)="onFromDateSelect($event)"
                         [maxDate]="maxDate" required formControlName="fromDate"
                         [ngClass]="{ 'is-invalid':submitted && f.fromDate.errors }">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
                  </div>
                </div>
                <div *ngIf="submitted && f.fromDate.errors" class="selectError">
                  <div *ngIf="f.fromDate.errors?.required || f.fromDate.invalid">Please enter valid From Date</div>
              </div>
              </div>
              <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">To Date: <span class="mandatoryCls">*</span></label>
                <div class="input-group">
                  <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                         name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="toMinDate" (dateSelect)="onToDateSelect($event)"
                         [maxDate]="maxDate" required formControlName="toDate"
                         [ngClass]="{ 'is-invalid':submitted && f.toDate.errors }">
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
                  </div>
                </div>
                <div *ngIf="submitted && f.toDate.errors" class="selectError">
                  <div *ngIf="f.toDate.errors?.required || f.toDate.invalid">Please enter valid To Date</div>
              </div>
              </div>
        </div>
  <div class="tabClass">
        <ul class="nav nav-pills flex-column flex-sm-row"  class="navbar navbar-expand-lg navbar-light "> 
              <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li>
              </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getJaganannaSurakshaTokenReport()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
        </div>
      </div>
</div>
<div class="table-responsive tableFixHead" *ngIf="showReports">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th [attr.colspan]="noOfCol">Suraksha Token Generation Report</th>
            </tr>
            <tr>
               <th>S.No</th>
               <th *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_CODE'">District Id <mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format === 'Mandal Wise'  || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_CODE'">Mandal Id<mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format === 'Mandal Wise'  || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal Name<mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls= "tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'scheD_SHUD_STARTED_VHC'">No. of VHC'S/UPHC'S should be started as per schedule <mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'camP_STARTED_VHC'">No. of VHC'S camp started <mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'camP_COMPLETED_VHC'">No. of VHC'S completed camp <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'phC_CODE'">PHC Code<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'phC_NAME'">PHC Name<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'vhC_CODE'">VHC Code<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'vhC_NAME'">VHC Name<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'seC_CODES'">Secretariat Codes<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'camP_STARTED_VHC'">Camp Status<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'camP_DATE'">Camp Date<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'ruraL_URBAN_FLAG'">Rural/Urban<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'tokeN_GENERATED'">No. of token generated(Total) <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'malE_TOKEN_GENERATED'">No. of token generated(Male) <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'femalE_TOKEN_GENERATED'">No. of token generated(Female) <mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>
           </thead>

        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
               <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
               <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'VHC Wise'">{{item.districT_CODE}}</td>
               <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'VHC Wise'">{{item.districT_NAME}}</td>
               <td *ngIf="format === 'Mandal Wise'  || format === 'VHC Wise'">{{item.mandaL_CODE}}</td>
               <td *ngIf="format === 'Mandal Wise'  || format === 'VHC Wise'">{{item.mandaL_NAME}}</td>
               <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.scheD_SHUD_STARTED_VHC}}</td>
               <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.camP_STARTED_VHC}}</td>
               <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.camP_COMPLETED_VHC}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.phC_CODE}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.phC_NAME}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.vhC_CODE}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.vhC_NAME}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.seC_CODES}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.camP_STARTED_VHC}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.camP_DATE}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.ruraL_URBAN_FLAG}}</td>
               <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'">{{item.tokeN_GENERATED}}</td>
               <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'">{{item.malE_TOKEN_GENERATED}}</td>
               <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'">{{item.femalE_TOKEN_GENERATED}}</td>
              <tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'VHC Wise'">{{totalItems[0].districT_CODE}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'VHC Wise'">{{totalItems[0].districT_NAME}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'VHC Wise'">{{totalItems[0].mandaL_CODE}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'VHC Wise'">{{totalItems[0].mandaL_NAME}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].scheD_SHUD_STARTED_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].camP_STARTED_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].camP_COMPLETED_VHC}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].phC_CODE}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].phC_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].vhC_CODE}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].vhC_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].seC_CODES}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].camP_STARTED_VHC}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].camP_DATE}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].ruraL_URBAN_FLAG}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'">{{totalItems[0].tokeN_GENERATED}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'">{{totalItems[0].malE_TOKEN_GENERATED}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'">{{totalItems[0].femalE_TOKEN_GENERATED}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    <div class="mt-4 btnHide">
        <div class="float-left" *ngIf="items.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
</div>
</div>
<div class="table-responsive tableFixHead" #TABLE class="hideTable">
   <table class="tableCls" >
       <thead>
           <tr>
           
                <th [attr.colspan]="noOfCol">Suraksha Camp Status Report</th>
            </tr>
            <tr>
                <th>S.No</th>
               <th *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_CODE'">District Id <mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format === 'Mandal Wise'  || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_CODE'">Mandal Id<mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format === 'Mandal Wise'  || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal Name<mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls= "tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'scheD_SHUD_STARTED_VHC'">No. of VHC'S/UPHC'S should be started as per schedule <mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'camP_STARTED_VHC'">No. of VHC'S camp started <mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'camP_COMPLETED_VHC'">No. of VHC'S completed camp <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'phC_CODE'">PHC Code<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'phC_NAME'">PHC Name<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'vhC_CODE'">VHC Code<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'vhC_NAME'">VHC Name<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'seC_CODES'">Secretariat Codes<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'camP_STARTED_VHC'">Camp Status<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'camP_DATE'">Camp Date<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'ruraL_URBAN_FLAG'">Rural/Urban<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'tokeN_GENERATED'">No. of token generated(Total) <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'malE_TOKEN_GENERATED'">No. of token generated(Male) <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'femalE_TOKEN_GENERATED'">No. of token generated(Female) <mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>
       </thead>

       <tbody *ngIf="items && items.length > 0">
        <tr *ngFor="let item of items;let i = index;">
            <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
               <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'VHC Wise'">{{item.districT_CODE}}</td>
               <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'VHC Wise'">{{item.districT_NAME}}</td>
               <td *ngIf="format === 'Mandal Wise'  || format === 'VHC Wise'">{{item.mandaL_CODE}}</td>
               <td *ngIf="format === 'Mandal Wise'  || format === 'VHC Wise'">{{item.mandaL_NAME}}</td>
               <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.scheD_SHUD_STARTED_VHC}}</td>
               <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.camP_STARTED_VHC}}</td>
               <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.camP_COMPLETED_VHC}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.phC_CODE}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.phC_NAME}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.vhC_CODE}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.vhC_NAME}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.seC_CODES}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.camP_STARTED_VHC}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.camP_DATE}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.ruraL_URBAN_FLAG}}</td>
               <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'">{{item.tokeN_GENERATED}}</td>
               <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'">{{item.malE_TOKEN_GENERATED}}</td>
               <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'">{{item.femalE_TOKEN_GENERATED}}</td>
          <tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'VHC Wise'">{{totalItems[0].districT_CODE}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'VHC Wise'">{{totalItems[0].districT_NAME}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'VHC Wise'">{{totalItems[0].mandaL_CODE}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'VHC Wise'">{{totalItems[0].mandaL_NAME}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].scheD_SHUD_STARTED_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].camP_STARTED_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].camP_COMPLETED_VHC}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].phC_CODE}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].phC_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].vhC_CODE}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].vhC_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].seC_CODES}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].camP_STARTED_VHC}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].camP_DATE}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].ruraL_URBAN_FLAG}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'">{{totalItems[0].tokeN_GENERATED}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'">{{totalItems[0].malE_TOKEN_GENERATED}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'">{{totalItems[0].femalE_TOKEN_GENERATED}}</td>
            </tr>
       </tbody>
       <tbody *ngIf="items && items.length === 0">
           <tr>
              <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
       </tbody>
   </table>
  </div>
  
