import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JobSeekerRegComponent } from './job-seeker-reg/job-seeker-reg.component';
import { AuthGuard } from '../../shared/auth.guard';
const routes: Routes = [
  {path: 'job-seeker-registration', component: JobSeekerRegComponent,data:{isCitizen: true}, canActivate: [AuthGuard]},
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HumanResourcesRoutingModule { }
