<div class="reportContainer">
  <div [formGroup]="form">
  <div class="row mb-3">
      
  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
    <select class="selectCls" formControlName="district" (change)="onDistrictChange()"
    [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
       <option value="All">All</option>
      <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
    </select>
  </div>

  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">Rural/Urban : <span class="mandatoryCls">*</span></label> 
      <select class="selectCls" required formControlName="RUFlag" (change)="onRuralUrbanChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.RUFlag.errors || f.RUFlag.value === 'Select') }">
      <option value = "All">All</option>
      <option value = "R">Rural</option>
      <option value = "U">Urban</option>
      </select>
  </div>


<div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
  <label class="labelCls">Mandal/Municipality:  <span class="mandatoryCls">*</span></label>
  <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
  [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
  <option value="All">All</option>
  <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
</select>
</div>

 
 <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <label class="labelCls"> PHC <span class="mandatoryCls">*</span></label>
    <select class="selectCls" formControlName="phc" (change)= "onPHCChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.phc.errors || f.phc.value === 'Select') }">
      <option value="All">All</option>
      <option *ngFor="let phc of phcs" [ngValue]="phc">{{phc.phcName}}</option>
    </select>
  </div>
  </div>

<div class="row mb-3">
  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls"> VHC <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="vhc" (change)="onVHCChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.vhc.errors || f.vhc.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let vhc of vhcs" [ngValue]="vhc">{{vhc.vhcName}}</option>
      </select>
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">From Date: <span class="mandatoryCls">*</span></label>
      <div class="input-group">
        <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
               name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" (dateSelect)="onFromDateSelect($event)"
               [maxDate]="maxDate" required formControlName="fromDate"
               [ngClass]="{ 'is-invalid':submitted && f.fromDate.errors }">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
        </div>
      </div>
      <div *ngIf="submitted && f.fromDate.errors" class="selectError">
        <div *ngIf="f.fromDate.errors?.required || f.fromDate.invalid">Please enter valid From Date</div>
    </div>
    </div>
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">To Date: <span class="mandatoryCls">*</span></label>
      <div class="input-group">
        <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
               name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="toMinDate" (dateSelect)="onToDateSelect($event)"
               [maxDate]="maxDate" required formControlName="toDate"
               [ngClass]="{ 'is-invalid':submitted && f.toDate.errors }">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
        </div>
      </div>
      <div *ngIf="submitted && f.toDate.errors" class="selectError">
        <div *ngIf="f.toDate.errors?.required || f.toDate.invalid">Please enter valid To Date</div>
    </div>
    </div>
</div>

<div class="tabClass">
      <ul class="nav nav-pills flex-column flex-sm-row"  class="navbar navbar-expand-lg navbar-light "> 
            <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li>
            </ul>
      <div class="btnHolder">
          <button  class="greenBtnCls float-right my-3" (click)=" GetArogyaSurakshaReportData()">Get Reports</button>
          <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
          <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
      </div>
    </div>
</div>
<div class="table-responsive tableFixHead" *ngIf="showReports">
  <table class="tableCls" >
    <thead> 
      <tr>
        <th [attr.colspan]="noOfCol">Aarogya Suraksha 2.0 Camp Status Dashboard</th>
      </tr>
      <tr>
        <th>S.No</th>
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_CODE'">District Id<mdb-icon fas icon="sort"></mdb-icon></th>
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District Name<mdb-icon fas icon="sort"></mdb-icon></th>
        <th *ngIf="format === 'Mandal Wise' || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_CODE'">Mandal Id <mdb-icon fas icon="sort"></mdb-icon></th>
        <th *ngIf="format === 'Mandal Wise' || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal Name <mdb-icon fas icon="sort"></mdb-icon></th>
        <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'phC_CODE'">PHC Code<mdb-icon fas icon="sort"></mdb-icon></th>
        <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'phC_NAME'">PHC Name<mdb-icon fas icon="sort"></mdb-icon></th>
        <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'vhC_CODE'">VHC Code<mdb-icon fas icon="sort"></mdb-icon></th>
        <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'vhC_NAME'">VHC Name<mdb-icon fas icon="sort"></mdb-icon></th>

        <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'ruraL_URBAN_FLAG'">Urban/Rural<mdb-icon fas icon="sort"></mdb-icon></th>
        
        <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'seC_CODES'">Secretariat Codes<mdb-icon fas icon="sort"></mdb-icon></th>

        <th *ngIf="format === 'District Wise'|| format === 'Mandal Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'scheD_SHUD_STARTED_VHC'" >No. of VHCs/UPHCs should be started as per schedule<mdb-icon fas icon="sort"></mdb-icon></th>
        <th *ngIf="format === 'District Wise'|| format === 'Mandal Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'camP_STARTED_VHC'" >No. of VHCs camp started<mdb-icon fas icon="sort"></mdb-icon></th>
        <th *ngIf="format === 'District Wise'|| format === 'Mandal Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'camP_COMPLETED_VHC'" >No. of VHCs completed camp<mdb-icon fas icon="sort"></mdb-icon></th>


        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfPatientAtStart'" >No. of patients at start of Camp<mdb-icon fas icon="sort"></mdb-icon></th>	
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfPatientAttended'" >No. of patients participated in Camp<mdb-icon fas icon="sort"></mdb-icon></th>
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfRooms'" >Total number of rooms<mdb-icon fas icon="sort"></mdb-icon></th>
        
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfFemaleDoc'">
          <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of Camps Female doctor present</span>
          <span *ngIf="format === 'VHC Wise'">Female Doctor Present (Yes/NO)</span>
        <mdb-icon fas icon="sort"></mdb-icon></th>
      
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfWaitingRooms'">
          <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of Camps Waiting rooms present</span>
          <span *ngIf="format === 'VHC Wise'">Waiting rooms present (Yes/No)</span>
        <mdb-icon fas icon="sort"></mdb-icon></th>

        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfPrivateRooms'">
          <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of Camps Private Room Availability for Female Patient Checkups</span>
          <span *ngIf="format === 'VHC Wise'">Private Room Availability for Female Patient Checkups (Yes/No)</span>
        <mdb-icon fas icon="sort"></mdb-icon></th>

        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfHelpDesk'">
          <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps Help desk and Registration desk available</span>
          <span *ngIf="format === 'VHC Wise'">Help desk and Registration desk available (Yes/No)</span>
        <mdb-icon fas icon="sort"></mdb-icon></th>

        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfMedicalKtsRecvd'">
          <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps Medication kits received</span>
          <span *ngIf="format === 'VHC Wise'">Medication kits received (Yes/No)</span>
        <mdb-icon fas icon="sort"></mdb-icon></th>

        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfJASFolderAvlbl'">
          <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps JAS Folders available</span>
          <span *ngIf="format === 'VHC Wise'">JAS Folders available (Yes/No)</span>
        <mdb-icon fas icon="sort"></mdb-icon></th>

        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfCaseSheet'">
          <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps JAS Case sheets available</span>
          <span *ngIf="format === 'VHC Wise'">JAS Case sheets available (Yes/No)</span>
        <mdb-icon fas icon="sort"></mdb-icon></th>

        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfJASBags'">
          <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps JAS Bags available</span>
          <span *ngIf="format === 'VHC Wise'">JAS Bags available (Yes/No)</span>
        <mdb-icon fas icon="sort"></mdb-icon></th>

        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfJASBanners'">
          <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps JAS Banner and Standee available</span>
          <span *ngIf="format === 'VHC Wise'">JAS Banner and Standee available (Yes/No)</span>
        <mdb-icon fas icon="sort"></mdb-icon></th>

        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfEYETestAvlbl'">
          <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps Eye test facilities available</span>
          <span *ngIf="format === 'VHC Wise'">Eye test facilities available (Yes/No)</span>
        <mdb-icon fas icon="sort"></mdb-icon></th>

        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfBasicFclty'">
          <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps Basic Facilities available</span>
          <span *ngIf="format === 'VHC Wise'">Basic Facilities available (Yes/No)</span>
        <mdb-icon fas icon="sort"></mdb-icon></th>

        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfMaskSanitAvlbl'">
          <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps Mask and Sanitizer available</span>
          <span *ngIf="format === 'VHC Wise'">Mask and Sanitizer available (Yes/No)</span>
        <mdb-icon fas icon="sort"></mdb-icon></th>

        
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfCampSanitDone'">
          <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps Post camp sanitization of venue completed</span>
          <span *ngIf="format === 'VHC Wise'">Post camp sanitization of venue completed (Yes/No)</span>
        <mdb-icon fas icon="sort"></mdb-icon></th>

        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfSecialistAttended'">No of specialists attended<mdb-icon fas icon="sort"></mdb-icon></th>
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfDoctorstAttended'">Total number of doctors attended<mdb-icon fas icon="sort"></mdb-icon></th>

        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfTotOps'">No. of total OP's<mdb-icon fas icon="sort"></mdb-icon></th>
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfTotWomenOps'">No. of Women OP's<mdb-icon fas icon="sort"></mdb-icon></th>
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOftMenOps'">No. of Men OP's<mdb-icon fas icon="sort"></mdb-icon></th>
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfSclChildern'">No. of School children<mdb-icon fas icon="sort"></mdb-icon></th>
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfSam_Mam_Awc'">No. of Malnourished (SAM/MAM/AWC)<mdb-icon fas icon="sort"></mdb-icon></th>
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfPregLactMother'">No. of Pregnant/Lactating Mothers<mdb-icon fas icon="sort"></mdb-icon></th>
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfEYEScreening'">No. of Eye Screening Done<mdb-icon fas icon="sort"></mdb-icon></th>
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfSpecsDistributed'">No. of Spectacles distributed<mdb-icon fas icon="sort"></mdb-icon></th>
        
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noNcdCdSession'" >
          <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of awareness session on NCD/CD conducted</span>
          <span *ngIf="format === 'VHC Wise'">Awareness session on NCD/CD conducted(Yes/No)</span>
        <mdb-icon fas icon="sort"></mdb-icon></th>

        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfAwrnsCamp'" >
          <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of awareness conducted during health camp</span>
          <span *ngIf="format === 'VHC Wise'">Awareness conducted during health camp (Yes/No)</span>
        <mdb-icon fas icon="sort"></mdb-icon></th>

        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfANMSession'" >
          <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of session on Health & Nutrition by ANM</span>
          <span *ngIf="format === 'VHC Wise'">Session on Health & Nutrition by ANM (Yes/No)</span>
        <mdb-icon fas icon="sort"></mdb-icon></th>

        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfStallAWC'" >
          <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of Nutrition stall by AWCS</span>
          <span *ngIf="format === 'VHC Wise'">Nutrition stall by AWCS (Yes/No)</span>
        <mdb-icon fas icon="sort"></mdb-icon></th>

        <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'camP_STARTED_VHC'">Camp Status<mdb-icon fas icon="sort"></mdb-icon></th>
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfCtznRcvdMedication'">No. of citizens who received medication<mdb-icon fas icon="sort"></mdb-icon></th>
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfDoctorsPresent'">No. of doctors present<mdb-icon fas icon="sort"></mdb-icon></th>
        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfSecialistPresent'">No. of specialists present<mdb-icon fas icon="sort"></mdb-icon></th>

        <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'camP_START_PHOTO'">Camp start photo<mdb-icon fas icon="sort"></mdb-icon></th>
        <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'camP_END_PHOTO'">Camp end photo<mdb-icon fas icon="sort"></mdb-icon></th>
        <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'doC_ATTEND_SHEET_PHOTO'">Doctor attendance sheet photo<mdb-icon fas icon="sort"></mdb-icon></th>

      </tr>            
    </thead>
    <tbody *ngIf="items && items.length > 0">
      <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
        <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
        <td>{{item.districT_CODE}}</td>
        <td>{{item.districT_NAME}}</td>
        <td *ngIf="format === 'Mandal Wise'|| format === 'VHC Wise'">{{item.mandaL_CODE}}</td>                
        <td *ngIf="format === 'Mandal Wise'|| format === 'VHC Wise'">{{item.mandaL_NAME}}</td>                
        <td *ngIf="format === 'VHC Wise'">{{item.phC_CODE}}</td> 
        <td *ngIf="format === 'VHC Wise'">{{item.phC_NAME}}</td> 
        <td *ngIf="format === 'VHC Wise'">{{item.vhC_CODE}}</td> 
        <td *ngIf="format === 'VHC Wise'">{{item.vhC_NAME}}</td> 

        <td *ngIf="format === 'VHC Wise'">{{item.ruraL_URBAN_FLAG}}</td> 

        <td *ngIf="format === 'VHC Wise'">{{item.seC_CODES}}</td> 

        <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ item.scheD_SHUD_STARTED_VHC }}</td>
        <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ item.camP_STARTED_VHC }}</td>
        <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ item.camP_COMPLETED_VHC }}</td>

        <td >{{ item.noOfPatientAtStart }}</td>
        <td >{{ item.noOfPatientAttended }}</td>
        <td >{{ item.noOfRooms }}</td>
        <td >{{ item.noOfFemaleDoc }}</td>
        <td >{{ item.noOfWaitingRooms }}</td>
        <td >{{ item.noOfPrivateRooms }}</td>
        <td >{{ item.noOfHelpDesk }}</td>
        <td >{{ item.noOfMedicalKtsRecvd }}</td>
        <td >{{ item.noOfJASFolderAvlbl }}</td>
        <td >{{ item.noOfCaseSheet }}</td>
        <td >{{ item.noOfJASBags }}</td>
        <td >{{ item.noOfJASBanners }}</td>
        <td >{{ item.noOfEYETestAvlbl }}</td>
        <td >{{ item.noOfBasicFclty }}</td>
        <td >{{ item.noOfMaskSanitAvlbl }}</td>
        <td >{{ item.noOfCampSanitDone }}</td>

        <td >{{ item.noOfSecialistAttended }}</td>
        <td >{{ item.noOfDoctorstAttended }}</td>
        
        <td >{{ item.noOfTotOps }}</td>
        <td >{{ item.noOfTotWomenOps }}</td>
        <td >{{ item.noOftMenOps }}</td>
        <td >{{ item.noOfSclChildern }}</td>
        <td >{{ item.noOfSam_Mam_Awc }}</td>
        <td >{{ item.noOfPregLactMother }}</td>
        <td >{{ item.noOfEYEScreening }}</td>
        <td >{{ item.noOfSpecsDistributed }}</td>
        <td >{{ item.noNcdCdSession }}</td>
        <td >{{ item.noOfAwrnsCamp }}</td>
        <td >{{ item.noOfANMSession }}</td>
        <td >{{ item.noOfStallAWC }}</td>

        <td *ngIf="format === 'VHC Wise'">{{ item.camP_STARTED_VHC }}</td>
        <td >{{ item.noOfCtznRcvdMedication }}</td>
        <td >{{ item.noOfDoctorsPresent }}</td>
        <td >{{ item.noOfSecialistPresent }}</td>

        <td *ngIf="format === 'VHC Wise' && item.camP_START_PHOTO"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.camP_START_PHOTO)">Show Image</a></td>
        <td *ngIf="format === 'VHC Wise' && !item.camP_START_PHOTO"  class="text-right">-</td>
        <td *ngIf="format === 'VHC Wise' && item.camP_END_PHOTO"  class="text-right"><a class="linkCls" (click)= "viewImgFile(item.camP_END_PHOTO)">Show Image</a></td>
        <td *ngIf="format === 'VHC Wise' && !item.camP_END_PHOTO"  class="text-right">-</td>

        <td *ngIf="format === 'VHC Wise' && item.doC_ATTEND_SHEET_PHOTO"  class="text-right"><a class="linkCls" (click)= "viewImgFile(item.doC_ATTEND_SHEET_PHOTO)">Show Image</a></td>
        <td *ngIf="format === 'VHC Wise' && !item.doC_ATTEND_SHEET_PHOTO"  class="text-right">-</td>

      </tr>
      <tr *ngIf="totalItems && totalItems.length > 0">
        <td></td>
        <td>{{totalItems[0].districT_CODE}}</td>
        <td>{{totalItems[0].districT_NAME}}</td>
        <td *ngIf="format === 'Mandal Wise'|| format === 'VHC Wise'">{{totalItems[0].mandaL_CODE}}</td>                
        <td *ngIf="format === 'Mandal Wise'|| format === 'VHC Wise'">{{totalItems[0].mandaL_NAME}}</td>                
        <td *ngIf="format === 'VHC Wise'">{{totalItems[0].phC_CODE}}</td> 
        <td *ngIf="format === 'VHC Wise'">{{totalItems[0].phC_NAME}}</td> 
        <td *ngIf="format === 'VHC Wise'">{{totalItems[0].vhC_CODE}}</td> 
        <td *ngIf="format === 'VHC Wise'">{{totalItems[0].vhC_NAME}}</td> 
    
        <td *ngIf="format === 'VHC Wise'">{{totalItems[0].ruraL_URBAN_FLAG}}</td> 
    
        <td *ngIf="format === 'VHC Wise'">{{totalItems[0].seC_CODES}}</td> 
    
        <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].scheD_SHUD_STARTED_VHC }}</td>
        <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].camP_STARTED_VHC }}</td>
        <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].camP_COMPLETED_VHC }}</td>
    
        <td >{{ totalItems[0].noOfPatientAtStart }}</td>
        <td >{{ totalItems[0].noOfPatientAttended }}</td>
        <td >{{ totalItems[0].noOfRooms }}</td>
        <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfFemaleDoc }}</span></td>
        <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfWaitingRooms }}</span></td>
        <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfPrivateRooms }}</span></td>
        <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfHelpDesk }}</span></td>
        <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfMedicalKtsRecvd }}</span></td>
        <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfJASFolderAvlbl }}</span></td>
        <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfCaseSheet }}</span></td>
        <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfJASBags }}</span></td>
        <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfJASBanners }}</span></td>
        <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfEYETestAvlbl }}</span></td>
        <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfBasicFclty }}</span></td>
        <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfMaskSanitAvlbl }}</span></td>
        <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfCampSanitDone }}</span></td>
    
        <td >{{ totalItems[0].noOfSecialistAttended }}</td>
        <td >{{ totalItems[0].noOfDoctorstAttended }}</td>
        
        <td >{{ totalItems[0].noOfTotOps }}</td>
        <td >{{ totalItems[0].noOfTotWomenOps }}</td>
        <td >{{ totalItems[0].noOftMenOps }}</td>
        <td >{{ totalItems[0].noOfSclChildern }}</td>
        <td >{{ totalItems[0].noOfSam_Mam_Awc }}</td>
        <td >{{ totalItems[0].noOfPregLactMother }}</td>
        <td >{{ totalItems[0].noOfEYEScreening }}</td>
        <td >{{ totalItems[0].noOfSpecsDistributed }}</td>

        <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noNcdCdSession }}</span></td>
        <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfAwrnsCamp }}</span></td>
        <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfANMSession }}</span></td>
        <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfStallAWC }}</span></td>
    
        <td *ngIf="format === 'VHC Wise'"></td>
        <td >{{ totalItems[0].noOfCtznRcvdMedication }}</td>
        <td >{{ totalItems[0].noOfDoctorsPresent }}</td>
        <td >{{ totalItems[0].noOfSecialistPresent }}</td>
        <td *ngIf="format === 'VHC Wise'"></td>
        <td *ngIf="format === 'VHC Wise'"></td>
        <td *ngIf="format === 'VHC Wise'"></td>
  
    </tr>
    </tbody>
    <tbody *ngIf="items && items.length === 0">
        <tr>
            <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
        </tr>
    </tbody>
  </table>
  <div class="mt-4 btnHide">
      <div class="float-left" *ngIf="items.length > 0">
          <label>Requests per page: </label>
          <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
              <option  value="10">10</option>
              <option  value="20">20</option>
              <option  value="30">30</option>
              <option  value="40">40</option>
              <option  value="50">50</option>
          </select> 
      </div>
      <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
          [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
      </ngb-pagination>
  </div>
</div>
</div>
<div class="table-responsive tableFixHead" #TABLE class="hideTable">
 <table class="tableCls">
  <thead> 
    <tr>
      <th [attr.colspan]="noOfCol">Aarogya Suraksha 2.0 Camp Status Dashboard</th>
    </tr>
    <tr>
      <th>S.No</th>
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_CODE'">District Id<mdb-icon fas icon="sort"></mdb-icon></th>
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District Name<mdb-icon fas icon="sort"></mdb-icon></th>
      <th *ngIf="format === 'Mandal Wise' || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_CODE'">Mandal Id <mdb-icon fas icon="sort"></mdb-icon></th>
      <th *ngIf="format === 'Mandal Wise' || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal Name <mdb-icon fas icon="sort"></mdb-icon></th>
      <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'phC_CODE'">PHC Code<mdb-icon fas icon="sort"></mdb-icon></th>
      <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'phC_NAME'">PHC Name<mdb-icon fas icon="sort"></mdb-icon></th>
      <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'vhC_CODE'">VHC Code<mdb-icon fas icon="sort"></mdb-icon></th>
      <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'vhC_NAME'">VHC Name<mdb-icon fas icon="sort"></mdb-icon></th>

      <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'ruraL_URBAN_FLAG'">Urban/Rural<mdb-icon fas icon="sort"></mdb-icon></th>
      
      <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'seC_CODES'">Secretariat Codes<mdb-icon fas icon="sort"></mdb-icon></th>

      <th *ngIf="format === 'District Wise'|| format === 'Mandal Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'scheD_SHUD_STARTED_VHC'" >No. of VHCs/UPHCs should be started as per schedule<mdb-icon fas icon="sort"></mdb-icon></th>
      <th *ngIf="format === 'District Wise'|| format === 'Mandal Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'camP_STARTED_VHC'" >No. of VHCs camp started<mdb-icon fas icon="sort"></mdb-icon></th>
      <th *ngIf="format === 'District Wise'|| format === 'Mandal Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'camP_COMPLETED_VHC'" >No. of VHCs completed camp<mdb-icon fas icon="sort"></mdb-icon></th>


      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfPatientAtStart'" >No. of patients at start of Camp<mdb-icon fas icon="sort"></mdb-icon></th>	
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfPatientAttended'" >No. of patients participated in Camp<mdb-icon fas icon="sort"></mdb-icon></th>
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfRooms'" >Total number of rooms<mdb-icon fas icon="sort"></mdb-icon></th>
      
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfFemaleDoc'">
        <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of Camps Female doctor present</span>
        <span *ngIf="format === 'VHC Wise'">Female Doctor Present (Yes/NO)</span>
      <mdb-icon fas icon="sort"></mdb-icon></th>
    
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfWaitingRooms'">
        <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of Camps Waiting rooms present</span>
        <span *ngIf="format === 'VHC Wise'">Waiting rooms present (Yes/No)</span>
      <mdb-icon fas icon="sort"></mdb-icon></th>

      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfPrivateRooms'">
        <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of Camps Private Room Availability for Female Patient Checkups</span>
        <span *ngIf="format === 'VHC Wise'">Private Room Availability for Female Patient Checkups (Yes/No)</span>
      <mdb-icon fas icon="sort"></mdb-icon></th>

      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfHelpDesk'">
        <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps Help desk and Registration desk available</span>
        <span *ngIf="format === 'VHC Wise'">Help desk and Registration desk available (Yes/No)</span>
      <mdb-icon fas icon="sort"></mdb-icon></th>

      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfMedicalKtsRecvd'">
        <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps Medication kits received</span>
        <span *ngIf="format === 'VHC Wise'">Medication kits received (Yes/No)</span>
      <mdb-icon fas icon="sort"></mdb-icon></th>

      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfJASFolderAvlbl'">
        <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps JAS Folders available</span>
        <span *ngIf="format === 'VHC Wise'">JAS Folders available (Yes/No)</span>
      <mdb-icon fas icon="sort"></mdb-icon></th>

      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfCaseSheet'">
        <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps JAS Case sheets available</span>
        <span *ngIf="format === 'VHC Wise'">JAS Case sheets available (Yes/No)</span>
      <mdb-icon fas icon="sort"></mdb-icon></th>

      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfJASBags'">
        <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps JAS Bags available</span>
        <span *ngIf="format === 'VHC Wise'">JAS Bags available (Yes/No)</span>
      <mdb-icon fas icon="sort"></mdb-icon></th>

      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfJASBanners'">
        <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps JAS Banner and Standee available</span>
        <span *ngIf="format === 'VHC Wise'">JAS Banner and Standee available (Yes/No)</span>
      <mdb-icon fas icon="sort"></mdb-icon></th>

      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfEYETestAvlbl'">
        <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps Eye test facilities available</span>
        <span *ngIf="format === 'VHC Wise'">Eye test facilities available (Yes/No)</span>
      <mdb-icon fas icon="sort"></mdb-icon></th>

      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfBasicFclty'">
        <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps Basic Facilities available</span>
        <span *ngIf="format === 'VHC Wise'">Basic Facilities available (Yes/No)</span>
      <mdb-icon fas icon="sort"></mdb-icon></th>

      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfMaskSanitAvlbl'">
        <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps Mask and Sanitizer available</span>
        <span *ngIf="format === 'VHC Wise'">Mask and Sanitizer available (Yes/No)</span>
      <mdb-icon fas icon="sort"></mdb-icon></th>

      
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfCampSanitDone'">
        <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of camps Post camp sanitization of venue completed</span>
        <span *ngIf="format === 'VHC Wise'">Post camp sanitization of venue completed (Yes/No)</span>
      <mdb-icon fas icon="sort"></mdb-icon></th>

      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfSecialistAttended'">No of specialists attended<mdb-icon fas icon="sort"></mdb-icon></th>
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfDoctorstAttended'">Total number of doctors attended<mdb-icon fas icon="sort"></mdb-icon></th>

      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfTotOps'">No. of total OP's<mdb-icon fas icon="sort"></mdb-icon></th>
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfTotWomenOps'">No. of Women OP's<mdb-icon fas icon="sort"></mdb-icon></th>
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOftMenOps'">No. of Men OP's<mdb-icon fas icon="sort"></mdb-icon></th>
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfSclChildern'">No. of School children<mdb-icon fas icon="sort"></mdb-icon></th>
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfSam_Mam_Awc'">No. of Malnourished (SAM/MAM/AWC)<mdb-icon fas icon="sort"></mdb-icon></th>
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfPregLactMother'">No. of Pregnant/Lactating Mothers<mdb-icon fas icon="sort"></mdb-icon></th>
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfEYEScreening'">No. of Eye Screening Done<mdb-icon fas icon="sort"></mdb-icon></th>
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfSpecsDistributed'">No. of Spectacles distributed<mdb-icon fas icon="sort"></mdb-icon></th>
      
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noNcdCdSession'" >
        <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of awareness session on NCD/CD conducted</span>
        <span *ngIf="format === 'VHC Wise'">Awareness session on NCD/CD conducted(Yes/No)</span>
      <mdb-icon fas icon="sort"></mdb-icon></th>

      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfAwrnsCamp'" >
        <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of awareness conducted during health camp</span>
        <span *ngIf="format === 'VHC Wise'">Awareness conducted during health camp (Yes/No)</span>
      <mdb-icon fas icon="sort"></mdb-icon></th>

      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfANMSession'" >
        <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of session on Health & Nutrition by ANM</span>
        <span *ngIf="format === 'VHC Wise'">Session on Health & Nutrition by ANM (Yes/No)</span>
      <mdb-icon fas icon="sort"></mdb-icon></th>

      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfStallAWC'" >
        <span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">No. of Nutrition stall by AWCS</span>
        <span *ngIf="format === 'VHC Wise'">Nutrition stall by AWCS (Yes/No)</span>
      <mdb-icon fas icon="sort"></mdb-icon></th>

      <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'camP_STARTED_VHC'">Camp Status<mdb-icon fas icon="sort"></mdb-icon></th>
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfCtznRcvdMedication'">No. of citizens who received medication<mdb-icon fas icon="sort"></mdb-icon></th>
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfDoctorsPresent'">No. of doctors present<mdb-icon fas icon="sort"></mdb-icon></th>
      <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfSecialistPresent'">No. of specialists present<mdb-icon fas icon="sort"></mdb-icon></th>

      <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'camP_START_PHOTO'">Camp start photo<mdb-icon fas icon="sort"></mdb-icon></th>
      <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'camP_END_PHOTO'">Camp end photo<mdb-icon fas icon="sort"></mdb-icon></th>
      <th *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'doC_ATTEND_SHEET_PHOTO'">Doctor attendance sheet photo<mdb-icon fas icon="sort"></mdb-icon></th>

    </tr>             
  </thead>
  <tbody *ngIf="items && items.length > 0">
     <tr *ngFor="let item of items;let i = index;">
      <td>{{i + 1}}</td>
      <td>{{item.districT_CODE}}</td>
      <td>{{item.districT_NAME}}</td>
      <td *ngIf="format === 'Mandal Wise'|| format === 'VHC Wise'">{{item.mandaL_CODE}}</td>                
      <td *ngIf="format === 'Mandal Wise'|| format === 'VHC Wise'">{{item.mandaL_NAME}}</td>                
      <td *ngIf="format === 'VHC Wise'">{{item.phC_CODE}}</td> 
      <td *ngIf="format === 'VHC Wise'">{{item.phC_NAME}}</td> 
      <td *ngIf="format === 'VHC Wise'">{{item.vhC_CODE}}</td> 
      <td *ngIf="format === 'VHC Wise'">{{item.vhC_NAME}}</td> 

      <td *ngIf="format === 'VHC Wise'">{{item.ruraL_URBAN_FLAG}}</td> 

      <td *ngIf="format === 'VHC Wise'">{{item.seC_CODES}}</td> 

      <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ item.scheD_SHUD_STARTED_VHC }}</td>
      <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ item.camP_STARTED_VHC }}</td>
      <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ item.camP_COMPLETED_VHC }}</td>

      <td >{{ item.noOfPatientAtStart }}</td>
      <td >{{ item.noOfPatientAttended }}</td>
      <td >{{ item.noOfRooms }}</td>
      <td >{{ item.noOfFemaleDoc }}</td>
      <td >{{ item.noOfWaitingRooms }}</td>
      <td >{{ item.noOfPrivateRooms }}</td>
      <td >{{ item.noOfHelpDesk }}</td>
      <td >{{ item.noOfMedicalKtsRecvd }}</td>
      <td >{{ item.noOfJASFolderAvlbl }}</td>
      <td >{{ item.noOfCaseSheet }}</td>
      <td >{{ item.noOfJASBags }}</td>
      <td >{{ item.noOfJASBanners }}</td>
      <td >{{ item.noOfEYETestAvlbl }}</td>
      <td >{{ item.noOfBasicFclty }}</td>
      <td >{{ item.noOfMaskSanitAvlbl }}</td>
      <td >{{ item.noOfCampSanitDone }}</td>

      <td >{{ item.noOfSecialistAttended }}</td>
      <td >{{ item.noOfDoctorstAttended }}</td>
      
      <td >{{ item.noOfTotOps }}</td>
      <td >{{ item.noOfTotWomenOps }}</td>
      <td >{{ item.noOftMenOps }}</td>
      <td >{{ item.noOfSclChildern }}</td>
      <td >{{ item.noOfSam_Mam_Awc }}</td>
      <td >{{ item.noOfPregLactMother }}</td>
      <td >{{ item.noOfEYEScreening }}</td>
      <td >{{ item.noOfSpecsDistributed }}</td>
      <td >{{ item.noNcdCdSession }}</td>
      <td >{{ item.noOfAwrnsCamp }}</td>
      <td >{{ item.noOfANMSession }}</td>
      <td >{{ item.noOfStallAWC }}</td>

      <td *ngIf="format === 'VHC Wise'">{{ item.camP_STARTED_VHC }}</td>
      <td >{{ item.noOfCtznRcvdMedication }}</td>
      <td >{{ item.noOfDoctorsPresent }}</td>
      <td >{{ item.noOfSecialistPresent }}</td>

      <td *ngIf="format === 'VHC Wise' && item.camP_START_PHOTO"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.camP_START_PHOTO)">Show Image</a></td>
      <td *ngIf="format === 'VHC Wise' && !item.camP_START_PHOTO"  class="text-right">-</td>
      <td *ngIf="format === 'VHC Wise' && item.camP_END_PHOTO"  class="text-right"><a class="linkCls" (click)= "viewImgFile(item.camP_END_PHOTO)">Show Image</a></td>
      <td *ngIf="format === 'VHC Wise' && !item.camP_END_PHOTO"  class="text-right">-</td>

      <td *ngIf="format === 'VHC Wise' && item.doC_ATTEND_SHEET_PHOTO"  class="text-right"><a class="linkCls" (click)= "viewImgFile(item.doC_ATTEND_SHEET_PHOTO)">Show Image</a></td>
      <td *ngIf="format === 'VHC Wise' && !item.doC_ATTEND_SHEET_PHOTO"  class="text-right">-</td>

    </tr>
    <tr *ngIf="totalItems && totalItems.length > 0">
      <td></td>
      <td>{{totalItems[0].districT_CODE}}</td>
      <td>{{totalItems[0].districT_NAME}}</td>
      <td *ngIf="format === 'Mandal Wise'|| format === 'VHC Wise'">{{totalItems[0].mandaL_CODE}}</td>                
      <td *ngIf="format === 'Mandal Wise'|| format === 'VHC Wise'">{{totalItems[0].mandaL_NAME}}</td>                
      <td *ngIf="format === 'VHC Wise'">{{totalItems[0].phC_CODE}}</td> 
      <td *ngIf="format === 'VHC Wise'">{{totalItems[0].phC_NAME}}</td> 
      <td *ngIf="format === 'VHC Wise'">{{totalItems[0].vhC_CODE}}</td> 
      <td *ngIf="format === 'VHC Wise'">{{totalItems[0].vhC_NAME}}</td> 
  
      <td *ngIf="format === 'VHC Wise'">{{totalItems[0].ruraL_URBAN_FLAG}}</td> 
  
      <td *ngIf="format === 'VHC Wise'">{{totalItems[0].seC_CODES}}</td> 
  
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].scheD_SHUD_STARTED_VHC }}</span></td>
      <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].camP_STARTED_VHC }}</td>
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].camP_COMPLETED_VHC }}</span></td>
  
      <td >{{ totalItems[0].noOfPatientAtStart }}</td>
      <td >{{ totalItems[0].noOfPatientAttended }}</td>
      <td >{{ totalItems[0].noOfRooms }}</td>
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfFemaleDoc }}</span></td>
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfWaitingRooms }}</span></td>
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfPrivateRooms }}</span></td>
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfHelpDesk }}</span></td>
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfMedicalKtsRecvd }}</span></td>
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfJASFolderAvlbl }}</span></td>
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfCaseSheet }}</span></td>
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfJASBags }}</span></td>
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfJASBanners }}</span></td>
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfEYETestAvlbl }}</span></td>
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfBasicFclty }}</span></td>
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfMaskSanitAvlbl }}</span></td>
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfCampSanitDone }}</span></td>
  
      <td >{{ totalItems[0].noOfSecialistAttended }}</td>
      <td >{{ totalItems[0].noOfDoctorstAttended }}</td>
      
      <td >{{ totalItems[0].noOfTotOps }}</td>
      <td >{{ totalItems[0].noOfTotWomenOps }}</td>
      <td >{{ totalItems[0].noOftMenOps }}</td>
      <td >{{ totalItems[0].noOfSclChildern }}</td>
      <td >{{ totalItems[0].noOfSam_Mam_Awc }}</td>
      <td >{{ totalItems[0].noOfPregLactMother }}</td>
      <td >{{ totalItems[0].noOfEYEScreening }}</td>
      <td >{{ totalItems[0].noOfSpecsDistributed }}</td>

      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noNcdCdSession }}</span></td>
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfAwrnsCamp }}</span></td>
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfANMSession }}</span></td>
      <td><span *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{ totalItems[0].noOfStallAWC }}</span></td>
  
      <td *ngIf="format === 'VHC Wise'"></td>
      <td >{{ totalItems[0].noOfCtznRcvdMedication }}</td>
      <td >{{ totalItems[0].noOfDoctorsPresent }}</td>
      <td >{{ totalItems[0].noOfSecialistPresent }}</td>
  
  </tr>
  </tbody>
  <tbody *ngIf="items && items.length === 0">
      <tr>
          <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
      </tr>
  </tbody>
 </table>
</div>
