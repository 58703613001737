import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from 'src/shared/common.service';
import { CommonConstants } from 'src/constants/common-constants.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CommonMsgModalComponent } from 'src/shared/common-msg-modal/common-msg-modal.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-token-registration-view',
  templateUrl: './token-registration-view.component.html',
  styleUrls: ['./token-registration-view.component.css']
})
export class TokenRegistrationViewComponent implements OnInit {
  commonConstants : any = CommonConstants;
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Input() reqId: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  selectedToken: any = "";
  confirmModal: any;
  services:any;
  loggedinDetails: any;
  userInformation: any;


  constructor(private router: Router, private commonService: CommonService, private modalService: NgbModal,config: NgbModalConfig) { }

  ngOnInit(): void {
    this.services = this.certificateInfo?.services.filter((item:any) => !item.isDeleted);
    this.userInformation = localStorage.getItem('userInfo');
    this.loggedinDetails = JSON.parse(this.userInformation);
    this.getServiceList();
  }

  openConfirmModal(confirmModal: any, token: any) { // Add log out function here
    this.selectedToken = token;
    this.confirmModal = this.modalService.open(confirmModal, { size: 'md', centered: true, backdrop: 'static'});
  }
  deleteToken() {
    if(this.selectedToken) {  
      this.confirmModal.close();   
      let postData = {
        tokenNo: this.selectedToken,
        RequestedBy: this.loggedinDetails.userPrincipalName
      };
      this.commonService.postRequest(this.commonConstants.DeleteJSToken, postData)
      .subscribe({ next: (responseData: any) => {   
          if(responseData?.result?.isSuccess){
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.title = 'Alert';
            modalRef.componentInstance.message = "Token deleted successfully. ";
            modalRef.result.then((result: any) => {
              if (result) {
                window.location.reload();
              }
            });
          } else if(responseData?.result?.errorDescription) {
            this.commonService.commonErrorPopup('Alert', responseData?.result?.errorDescription, 'md');
          } else {
            this.commonService.commonErrorPopup('Alert', "Error occured while delete the token.", 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      });
    }
    this.selectedToken = "";      
  }
  getServiceList() {
    this.commonService.getRequest(this.commonConstants.getJSTokenDetailsInViewTransactions + this.reqId)
    .subscribe({ next: (responseData: any) => {   
        if(responseData?.result?.isSuccess){
          this.services = responseData?.result?.services.filter((item:any) => !item.isDeleted);
        } else {
          this.commonService.commonErrorPopup('Alert', responseData?.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
}
