import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-common-msg-modal-for-deleterecord',
  templateUrl: './common-msg-modal-for-deleterecord.component.html',
  styleUrls: ['./common-msg-modal-for-deleterecord.component.css']
})
export class CommonMsgModalForDeleterecordComponent implements OnInit {

  @Input() reqId: string = '';
  @Input() title: string = '';
  @Input() message: string = '';
  constructor(public activeModal: NgbActiveModal, config: NgbModalConfig) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  ngOnInit(): void {
  }
}
