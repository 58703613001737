<div *ngIf="certificateInfo">

    <div class="certificateInfo">
        <div class="subHeadingCls mb-3">Marriage Details</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Marriage Date:</label>
                <div class="valueCls">{{certificateInfo?.marriageDate | date:'dd MMM yyyy'}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Marriage Venue:</label>
                <div class="valueCls">{{certificateInfo?.marriageVenue}}</div>
            </div>
            </div>
            </div>

        </div>
        <div class="certificateInfo">
            <div class="subHeadingCls mb-3">Place of Marriage</div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <!-- <div class="fieldHolder">
                        <label class="labelCls">Door No & Street Name:</label>
                        <div class="valueCls">{{certificateInfo?.marriageAddress.doorNo}} {{ certificateInfo?.marriageAddress.streetName}}</div>
                    </div> -->

                    <div class="fieldHolder" *ngIf="certificateInfo?.panchayatOrMunicipalityName">
                        <label class="labelCls">Panchayat:</label>
                        <div class="valueCls">{{certificateInfo?.panchayatOrMunicipalityName}}</div>
                    </div>

                    <div class="fieldHolder">
                        <label class="labelCls">Village/Ward/Secretariat:</label>
                        <div class="valueCls">{{certificateInfo?.marriageAddress?.villageWardName}}</div>
                    </div>
                
                    <div class="fieldHolder">
                        <label class="labelCls">Mandal/Municipality:</label>
                        <div class="valueCls">{{certificateInfo?.marriageAddress?.mandalMunicipalityName}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">District:</label>
                        <div class="valueCls">{{certificateInfo?.marriageAddress?.districtName}}</div>
                    </div>
                    <!-- <div class="fieldHolder">
                        <label class="labelCls">PinCode:</label>
                        <div class="valueCls">{{certificateInfo?.marriageAddress.pinCode}}</div>
                    </div> -->
                    
            </div>
        </div>
        </div>
    
    <div class="certificateInfo">
        <div class="subHeadingCls mb-3">Bride Details</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Aadhaar Number:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails.aadhaarNumber}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Citizen Name:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails.name}}</div>
                </div>
            
                <div class="fieldHolder">
                    <label class="labelCls">Father Name:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails.fathersName}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Date of Birth:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails.dateOfBirth | date:'dd MMM yyyy'}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Age:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails.age}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Gender:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails.gender}}</div>
                </div>
            
                <div class="fieldHolder">
                    <label class="labelCls">Mobile Number:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails.mobileNumber}}</div>
                </div>
              
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Address:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails.address}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Religion:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails.religion}}</div>
                </div>
            
                <div class="fieldHolder">
                    <label class="labelCls">Caste:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails.caste}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Qualification:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails.qualification}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Occupation:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails.occupation}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Handicapped:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails.isHandicapped == false ? 'No' : 'Yes'}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Status at the time of marriage:</label>
                    <div class="valueCls">{{certificateInfo?.brideDetails.status}}</div>
                </div>
            
            
            </div>
        </div>
    </div>
    
    <div class="certificateInfo">
        <div class="subHeadingCls mb-3">Bridegroom Details</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Aadhaar Number:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails.aadhaarNumber}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Citizen Name:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails.name}}</div>
                </div>
            
                <div class="fieldHolder">
                    <label class="labelCls">Father Name:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails.fathersName}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Date of Birth:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails.dateOfBirth | date:'dd MMM yyyy'}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Age:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails.age}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Gender:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails.gender}}</div>
                </div>
            
                <div class="fieldHolder">
                    <label class="labelCls">Mobile Number:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails.mobileNumber}}</div>
                </div>
              
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Address:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails.address}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Religion:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails.religion}}</div>
                </div>
            
                <div class="fieldHolder">
                    <label class="labelCls">Caste:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails.caste}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Qualification:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails.qualification}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Occupation:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails.occupation}}</div>
                </div>
                
                <div class="fieldHolder">
                    <label class="labelCls">Handicapped:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails.isHandicapped == false ? 'No' : 'Yes'}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Status at the time of marriage:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomDetails.status}}</div>
                </div>
            
            </div>
        </div>
    </div>






    <div class="certificateInfo">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="subHeadingCls mb-3">Witness 1 Details for Bride</div>
                <div class="fieldHolder">
                    <label class="labelCls">AadhaarNumber:</label>
                    <div class="valueCls">{{certificateInfo?.brideWitnessOneDetails.aadhaarNumber}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Name:</label>
                    <div class="valueCls">{{certificateInfo?.brideWitnessOneDetails.name}}</div>
                </div>
            
                <div class="fieldHolder">
                    <label class="labelCls">Father/HusbandName:</label>
                    <div class="valueCls">{{certificateInfo?.brideWitnessOneDetails.fatherOrHusbandName}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Address:</label>
                    <div class="valueCls">{{certificateInfo?.brideWitnessOneDetails.address}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Age:</label>
                    <div class="valueCls">{{certificateInfo?.brideWitnessOneDetails.age}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Relation:</label>
                    <div class="valueCls">{{certificateInfo?.brideWitnessOneDetails.relation}}</div>
                </div>
                
            </div>
    
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="subHeadingCls mb-3">Witness 2 Details for Bride</div>
            <div class="fieldHolder">
                <label class="labelCls">AadhaarNumber:</label>
                <div class="valueCls">{{certificateInfo?.brideWitnessTwoDetails.aadhaarNumber}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Name:</label>
                <div class="valueCls">{{certificateInfo?.brideWitnessTwoDetails.name}}</div>
            </div>
        
            <div class="fieldHolder">
                <label class="labelCls">Father/HusbandName:</label>
                <div class="valueCls">{{certificateInfo?.brideWitnessTwoDetails.fatherOrHusbandName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Address:</label>
                <div class="valueCls">{{certificateInfo?.brideWitnessTwoDetails.address}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Age:</label>
                <div class="valueCls">{{certificateInfo?.brideWitnessTwoDetails.age}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Relation:</label>
                <div class="valueCls">{{certificateInfo?.brideWitnessTwoDetails.relation}}</div>
            </div>
                    </div>
    </div>
    </div>
    <div class="certificateInfo">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="subHeadingCls mb-3">Witness 1 Details for Bridegroom</div>
                <div class="fieldHolder">
                    <label class="labelCls">AadhaarNumber:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomWitnessOneDetails.aadhaarNumber}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Name:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomWitnessOneDetails.name}}</div>
                </div>
            
                <div class="fieldHolder">
                    <label class="labelCls">Father/HusbandName:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomWitnessOneDetails.fatherOrHusbandName}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Address:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomWitnessOneDetails.address}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Age:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomWitnessOneDetails.age}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Relation:</label>
                    <div class="valueCls">{{certificateInfo?.bridegroomWitnessOneDetails.relation}}</div>
                </div>
                
            </div>
    
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="subHeadingCls mb-3">Witness 2 Details for Bridegroom</div>
            <div class="fieldHolder">
                <label class="labelCls">AadhaarNumber:</label>
                <div class="valueCls">{{certificateInfo?.bridegroomWitnessTwoDetails.aadhaarNumber}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Name:</label>
                <div class="valueCls">{{certificateInfo?.bridegroomWitnessTwoDetails.name}}</div>
            </div>
        
            <div class="fieldHolder">
                <label class="labelCls">Father/HusbandName:</label>
                <div class="valueCls">{{certificateInfo?.bridegroomWitnessTwoDetails.fatherOrHusbandName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Address:</label>
                <div class="valueCls">{{certificateInfo?.bridegroomWitnessTwoDetails.address}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Age:</label>
                <div class="valueCls">{{certificateInfo?.bridegroomWitnessTwoDetails.age}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Relation:</label>
                <div class="valueCls">{{certificateInfo?.bridegroomWitnessTwoDetails.relation}}</div>
            </div>
                    </div>
    </div>
    </div>



    <div class="certificateInfo">
        <div class="row">
            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
                <ng-container *ngIf="fileList && fileList.length !== 0">
                <div class="subHeadingCls mb-3">List of Documents</div>
                <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                    <div *ngIf="file.documentType === 'Marriage Photo'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Marriage Photo:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Marriage Invitation Card'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Marriage Invitation Card:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Proof Of Age For Bride'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Proof Of Age For Bride:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Proof Of Age For Bridegroom'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Proof Of Age For Bridegroom:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <!-- <div *ngIf="file.documentType === 'Caste Certificate of Bride'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Caste Certificate of Bride:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Caste Certificate of Bridegroom'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Caste Certificate of Bridegroom:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div> -->
                    <div *ngIf="file.documentType === 'Proof Of Residence'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Proof Of Residence:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'bWOnePhoto'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Bride Witness One Photo:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'bWTwoPhoto'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Bride Witness Two Photo:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'gWOnePhoto'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Bridegroom Witness One Photo:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'gWTwoPhoto'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Bridegroom Witness One Photo:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                    <div *ngIf="file.documentType === 'Divorce Certificate'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Divorce Certificate:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef,file.fileName)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
