<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
            GSWS / Application Submission / Digital Services <span class="activePage">/ Challan Payment</span>
        </div>
    </div>
    <div class="mainPageContainer mr-3">
        <form [formGroup]="aadharGeneration">
            <div class="row mb-3">
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                    <label class="labelCls"><b>Select Category :</b><span class="mandatoryCls">*</span></label>
                </div>
                <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <select class="selectCls" formControlName="aadharCategory" (change)="onCategoryChange()" required
                        [ngClass]="{ 'is-invalid':submitted && (f.aadharCategory.errors || f.aadharCategory.value === 'Select') }">
                        <option [ngValue]="null" disabled>Select</option>
                        <option value="Aadhaar Services">Aadhar Services</option>
                    </select>
                    <div *ngIf="submitted && (f.aadharCategory.errors || f.aadharCategory.value === 'Select')"
                        class="selectError">
                        <div *ngIf="f.aadharCategory.errors || f.aadharCategory.value === 'Select'">Please
                            select category</div>
                    </div>
                </div>
            </div>
            <div>

                <div class="row mb-3">
                    <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                        <label class="labelCls"><b>Service Raised Date :</b><span class="mandatoryCls">*</span></label>
                    </div>
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div class="input-group">
                            <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste name="d1"
                                ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate"
                                (dateSelect)="onFromDateSelect($event)" [maxDate]="maxDate" required
                                formControlName="serviceRaisedDate"
                                [ngClass]="{ 'is-invalid':submitted && f.serviceRaisedDate.errors }">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()"
                                    type="button"></button>
                            </div>
                        </div>
                        <div *ngIf="submitted && (f.serviceRaisedDate.errors || f.serviceRaisedDate.value === 'Select')"
                            class="selectError">
                            <div *ngIf="f.serviceRaisedDate.errors || f.serviceRaisedDate.value === 'Select'">Please
                                select service raised date</div>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                        <label class="labelCls"><b>Type of Service :</b><span class="mandatoryCls">*</span></label>
                    </div>
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <select class="selectCls" formControlName="typeOfService" (change)="onChangeAmount()" required
                            [ngClass]="{ 'is-invalid':submitted && (f.typeOfService.errors || f.typeOfService.value === 'Select') }">
                            <option [ngValue]="null" disabled>Select</option>
                            <option *ngFor="let category of categories" [ngValue]="category">
                                {{category.serviceType}}</option>
                        </select>
                        <div *ngIf="submitted && (f.typeOfService.errors || f.typeOfService.value === 'Select')"
                            class="selectError">
                            <div *ngIf="f.typeOfService.errors || f.typeOfService.value === 'Select'">Please
                                select type of service</div>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                        <label class="labelCls"><b>No of Services Provided :</b><span class="mandatoryCls">*</span></label>
                    </div>
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <input type="text" name="noOfService" formControlName="noOfService" numbersOnly 
                            (change)="onChangeNoOfService($event)" class="inputCls" appBlockCopyPaste required
                            [ngClass]="{ 'is-invalid':submitted && f.noOfService.errors }">
                        <div *ngIf="submitted && f.noOfService.errors" class="invalid-feedback">
                            <div *ngIf="f.noOfService.errors.required || f.noOfService.errors.whiteSpaceValidate">Please
                                enter number of services provided </div> 
                            <div *ngIf="f.noOfService.errors.pattern">Zeros does not allow infront of digits</div>
                            <div *ngIf="f.noOfService.errors.min">Minimum number of services should be provided is 1</div>
                            <div *ngIf="f.noOfService.errors.max">Maximum number of services should be provided is 100</div>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12">
                        <label class="labelCls"><b>Amount :</b><span class="mandatoryCls">*</span></label>
                    </div>
                    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <input type="number" name="amount" formControlName="amount"
                            class="inputCls" appBlockCopyPaste required 
                            [attr.disabled]="disable ? '' : null"
                            [ngClass]="{ 'is-invalid':submitted && f.amount.errors }">
                        <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                            <div *ngIf="f.amount.errors.required || f.amount.errors.whiteSpaceValidate">Please enter
                                amount</div>
                            <div *ngIf="f.amount.errors?.pattern">Please enter valid amount</div>
                        </div>
                    </div>
                </div>
                <div class="btnHolder">
                    <button class="greenBtnCls float-left my-3" (click)="addAadharChallanGenerationData()">Add Service</button>
                </div>
            </div>
        </form>
    </div>
</div>