<div class="reportContainer">
    <div [formGroup]="form">
    <div class="row mb-3">
        
    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
      <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="district" (change)="onDistrictChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
         <option value="All">All</option>
        <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
      </select>
     
    </div>

    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="labelCls">Revenue Mandal: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="revenueMandal" (change)="getMandals()"
        [ngClass]="{ 'is-invalid':submitted && (f.revenueMandal.errors || f.revenueMandal.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let revenue of revenueMandals" [ngValue]="revenue">{{revenue.revenuE_MANDAL_NAME}}</option>
      </select>
    </div>

    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
        <label class="labelCls">Rural/Urban : <span class="mandatoryCls">*</span></label> 
        <select class="selectCls" required formControlName="RUFlag" (change)="onRuralUrbanChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.RUFlag.errors || f.RUFlag.value === 'Select') }">
        <option value = "All">All</option>
        <option value = "R">Rural</option>
        <option value = "U">Urban</option>
        </select>
    </div>

    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
      <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
      <option value="All">All</option>
      <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
    </select>
    </div>

    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
      <label class="labelCls">Village/Ward/Secretariat: <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="village" (change)="onVillageChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.village.errors || f.village.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let village of villages" [ngValue]="village">{{village.secretariatName}}</option>
      </select>
    </div>

    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="labelCls">Department: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="department" (change)="onDeptChange()"
          [ngClass]="{ 'is-invalid':submitted && (f.department.errors || f.department.value === 'Select') }">
          <option value="All">All</option>
          <option *ngFor="let dept of departments" [ngValue]="dept">{{dept.departmentName}}</option>
        </select>
        
      </div>
      <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="labelCls">Service: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="service" (change) = "onServiceChange()"
          [ngClass]="{ 'is-invalid':submitted && (f.service.errors || f.service.value === 'Select') }">
          <option value="All">All</option>
          <option *ngFor="let service of services" [ngValue]="service">{{service.serviceName}}</option>
        </select>
       
      </div>
      <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="labelCls">Type of Date: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="typeOfDate" (change) = "onTypeOfDateChange()"
          [ngClass]="{ 'is-invalid':submitted && (f.typeOfDate.errors || f.typeOfDate.value === 'Select') }">
          <option [ngValue]="null" disabled>Select</option>
          <option value="Scheduled Date">Scheduled Date</option>
          <option value="Token Date">Token Date</option>
        </select>
       
      </div>
</div>
<div class="row mb-3">    
    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="labelCls">From Date: <span class="mandatoryCls">*</span></label>
        <div class="input-group">
          <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                 name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" (dateSelect)="onFromDateSelect($event)"
                 [maxDate]="maxDate" required formControlName="fromDate"
                 [ngClass]="{ 'is-invalid':submitted && f.fromDate.errors }">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
          </div>
        </div>
        <div *ngIf="submitted && f.fromDate.errors" class="selectError">
          <div *ngIf="f.fromDate.errors?.required || f.fromDate.invalid">Please enter valid From Date</div>
      </div>
      </div>
      <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="labelCls">To Date: <span class="mandatoryCls">*</span></label>
        <div class="input-group">
          <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                 name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="toMinDate" (dateSelect)="onToDateSelect($event)"
                 [maxDate]="maxDate" required formControlName="toDate"
                 [ngClass]="{ 'is-invalid':submitted && f.toDate.errors }">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
          </div>
        </div>
        <div *ngIf="submitted && f.toDate.errors" class="selectError">
          <div *ngIf="f.toDate.errors?.required || f.toDate.invalid">Please enter valid To Date</div>
      </div>
      </div>
    </div>
    
      <div class="tabClass">
        <ul>
              <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li>
        </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getDashboardData()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
        </div>
      </div>
</div>
<div class="table-responsive tableFixHead" *ngIf="showReports">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th [attr.colspan]="noOfCol">Suraksha Dashboard from  {{f.fromDate.value}} to  {{f.toDate.value}}</th>
            </tr>
            <tr>
               <th rowspan="2">S.No</th>
               <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'Cluster Wise' || format === 'RevenueMandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'dname'">District <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'RevenueMandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'diV_NAME'">Revenue Division <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'RevenueMandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rvmandaL_NAME'">Revenue Mandal <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise' || format === 'Cluster Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mname'">Mandal <mdb-icon fas icon="sort"></mdb-icon></th>
               <!-- <th rowspan="2" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise' || format === 'Cluster Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'ruraL_URBAN'">Rural/Urban <mdb-icon fas icon="sort"></mdb-icon></th> -->
               <th rowspan="2" *ngIf="format === 'Secretariat Wise' || format === 'Cluster Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'seC_NAME'">Secretariat <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Secretariat Wise' || format === 'Cluster Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'seC_CODE'">Secretariat Code <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Cluster Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clusteR_NAME'">Cluster <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Department Wise' || format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'departmenT_NAME'">Department <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'Service Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'servicename'">Service Name <mdb-icon fas icon="sort"></mdb-icon></th>

               <th rowspan="2" *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_CNT'">No. of Mandals <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'seC_CNT'">No. of Secretariats <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clusteR_CNT'">No. of Clusters <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'clusteR_CNT'">No. of Clusters Survey Started <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'Cluster Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'householD_CNT'">No. of Households <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'householD_CNT'">No. of Households Survey Completed <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'voL_REQS'">No. of Services requested in volunteer app<mdb-icon fas icon="sort"></mdb-icon></th>
               
               <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'tokens'">No. of tokens Registered <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'srV_APPS'">No. of Service Requests raised <mdb-icon fas icon="sort"></mdb-icon></th>
               <th colspan="2">Open Requests </th>
               <th colspan="2">Closed Beyond SLA Requests </th>
               <th colspan="2">Closed Within SLA Requests </th>
               <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'lapsinG_24_HRS'">Lapsing Within 24 Hours<mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'lapsinG_48_HRS'">Lapsing Within 48 Hours  <mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>
            <tr>
                <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opeN_BEYOND_APPS'">Beyond SLA <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'opeN_WITHIN_APPS'">Within SLA <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'closeD_BEYOND_APRVD_APPS'">Approved <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'closeD_BEYOND_REJ_APPS'">Rejected <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'closeD_WITHIN_APRVD_APPS'">Approved <mdb-icon fas icon="sort"></mdb-icon></th>
               <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'closeD_WITHIN_REJ_APPS'">Rejected <mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>
           
        </thead>

        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'Cluster Wise' || format === 'RevenueMandal Wise'">{{item.dname}}</td>
                <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{item.diV_NAME}}</td>
                <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{item.rvmandaL_NAME}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise' || format === 'Cluster Wise'">{{item.mname}}</td>
               <td *ngIf="format === 'Secretariat Wise' || format === 'Cluster Wise'">{{item.seC_NAME}}</td>
               <td *ngIf="format === 'Secretariat Wise' || format === 'Cluster Wise'">{{item.seC_CODE}}</td>
               <td *ngIf="format === 'Cluster Wise'" ><a (click) ="getSubReport(item, subReportModal, 'isHHWise')" class="linkCls">{{item.clusteR_NAME}}</a></td>
               <td *ngIf="format === 'Department Wise' || format === 'Service Wise'">{{item.departmenT_NAME}}</td>
               <td *ngIf="format === 'Service Wise'">{{item.servicename}}</td>
                <td *ngIf="format === 'District Wise'" class="text-right">{{item.mandaL_CNT}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'" class="text-right">{{item.seC_CNT}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" class="text-right">{{item.clusteR_CNT}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" class="text-right">{{item.clusteR_SURVEYED_CNT}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'Cluster Wise'" class="text-right">{{item.householD_CNT}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" class="text-right">{{item.householD_SURVEYED_CNT}}</td>
                <td class="text-right">{{item.voL_REQS}}</td>
                <td class="text-right"><a (click) ="getSubReport(item, subReportModal, 'tokens')" class="linkCls">{{item.tokens}}</a></td>
                <td class="text-right"><a (click) ="getSubReport(item, subReportModal, 'services')" class="linkCls">{{item.srV_APPS}}</a></td>
                <td class="text-right"><a (click) ="getSubReport(item, subReportModal, 'beyond')" class="linkCls">{{item.opeN_BEYOND_APPS}}</a></td>
                <td class="text-right"><a (click) ="getSubReport(item, subReportModal, 'within')" class="linkCls">{{item.opeN_WITHIN_APPS}}</a></td>
                <td class="text-right">{{item.closeD_BEYOND_APRVD_APPS}}</td>
                <td class="text-right">{{item.closeD_BEYOND_REJ_APPS}}</td>
                <td class="text-right">{{item.closeD_WITHIN_APRVD_APPS}}</td>
                <td class="text-right">{{item.closeD_WITHIN_REJ_APPS}}</td>
                <td class="text-right"><a (click) ="getSubReport(item, subReportModal, '24')" class="linkCls">{{item.lapsinG_24_HRS}}</a></td>
                <td class="text-right"><a (click) ="getSubReport(item, subReportModal, '48')" class="linkCls">{{item.lapsinG_48_HRS}}</a></td>
            </tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'Cluster Wise' || format === 'RevenueMandal Wise'">{{totalItems[0].dname}}</td>
                <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{totalItems[0].diV_NAME}}</td>
               <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{totalItems[0].rvmandaL_NAME}}</td>
               <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise' || format === 'Cluster Wise'">{{totalItems[0].mname}}</td>
               <td *ngIf="format === 'Secretariat Wise' || format === 'Cluster Wise'">{{totalItems[0].seC_NAME}}</td>
               <td *ngIf="format === 'Secretariat Wise' || format === 'Cluster Wise'">{{totalItems[0].seC_CODE}}</td>
               <td *ngIf="format === 'Cluster Wise'" ><a (click) ="getSubReport('item', subReportModal, 'isHHWise')" class="linkCls">{{totalItems[0].clusteR_NAME}}</a></td>
               <td *ngIf="format === 'Department Wise' || format === 'Service Wise'">{{totalItems[0].departmenT_NAME}}</td>
               <td *ngIf="format === 'Service Wise'">{{totalItems[0].servicename}}</td>
                <td *ngIf="format === 'District Wise'" class="text-right">{{totalItems[0].mandaL_CNT}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'" class="text-right">{{totalItems[0].seC_CNT}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" class="text-right">{{totalItems[0].clusteR_CNT}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" class="text-right">{{totalItems[0].clusteR_SURVEYED_CNT}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'Cluster Wise'" class="text-right">{{totalItems[0].householD_CNT}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" class="text-right">{{totalItems[0].householD_SURVEYED_CNT}}</td>
                <td class="text-right">{{totalItems[0].voL_REQS}}</td>
                <td class="text-right">{{totalItems[0].tokens}}</td>
                <td class="text-right">{{totalItems[0].srV_APPS}}</td>
                <td class="text-right">{{totalItems[0].opeN_BEYOND_APPS}}</td>
                <td class="text-right">{{totalItems[0].opeN_WITHIN_APPS}}</td>
                <td class="text-right">{{totalItems[0].closeD_BEYOND_APRVD_APPS}}</td>
                <td class="text-right">{{totalItems[0].closeD_BEYOND_REJ_APPS}}</td>
                <td class="text-right">{{totalItems[0].closeD_WITHIN_APRVD_APPS}}</td>
                <td class="text-right">{{totalItems[0].closeD_WITHIN_REJ_APPS}}</td>
                <td class="text-right">{{totalItems[0].lapsinG_24_HRS}}</td>
                <td class="text-right">{{totalItems[0].lapsinG_48_HRS}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    <div class="mt-4 btnHide">
        <div class="float-left" *ngIf="items.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
</div>


</div>
<div class="table-responsive tableFixHead" #TABLE class="hideTable">
   <table class="tableCls" >
    <thead> 
        <tr>
            <th [attr.colspan]="noOfCol">Suraksha Dashboard from  {{f.fromDate.value}} to  {{f.toDate.value}}</th>
        </tr>
        <tr>
           <th rowspan="2">S.No</th>
           <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'Cluster Wise' || format === 'RevenueMandal Wise'">District</th>
           <th rowspan="2" width="100px" *ngIf="format === 'RevenueMandal Wise'">Revenue Division</th>
            <th rowspan="2" width="100px" *ngIf="format === 'RevenueMandal Wise'">Revenue Mandal</th>
           <th rowspan="2" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise' || format === 'Cluster Wise'">Mandal </th>
           <!-- <th rowspan="2" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise' || format === 'Cluster Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'ruraL_URBAN'">Rural/Urban <mdb-icon fas icon="sort"></mdb-icon></th> -->
           <th rowspan="2" *ngIf="format === 'Secretariat Wise' || format === 'Cluster Wise'">Secretariat</th>
           <th rowspan="2" *ngIf="format === 'Secretariat Wise' || format === 'Cluster Wise'">Secretariat Code</th>
           <th rowspan="2" *ngIf="format === 'Cluster Wise'">Cluster</th>
           <th rowspan="2" *ngIf="format === 'Department Wise' || format === 'Service Wise'">Department</th>
           <th rowspan="2" *ngIf="format === 'Service Wise'">Service Name</th>

           <th rowspan="2" *ngIf="format === 'District Wise'">No. of Mandals</th>
           <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise'">No. of Secretariats</th>
           <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">No. of Clusters</th>
           <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">No. of Clusters Survey Started</th>
           <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'Cluster Wise'">No. of Households</th>
           <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">No. of Households Survey Completed</th>
           <th rowspan="2">No. of Services requested in volunteer app</th>
           
           <th rowspan="2">No. of tokens Registered </th>
           <th rowspan="2">No. of Service Requests raised</th>
           <th colspan="2">Open Requests </th>
           <th colspan="2">Closed Beyond SLA Requests</th>
           <th colspan="2">Closed Within SLA Requests</th>
           <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'lapsinG_24_HRS'">Lapsing Within 24 Hours<mdb-icon fas icon="sort"></mdb-icon></th>
        <th rowspan="2" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'lapsinG_48_HRS'">Lapsing Within 48 Hours  <mdb-icon fas icon="sort"></mdb-icon></th>
        </tr>
        <tr>
            <th >Beyond SLA</th>
           <th >Within SLA </th>
           <th>Approved</th>
           <th >Rejected</th>
           <th>Approved</th>
           <th >Rejected</th>
        </tr>
        
    </thead>

    <tbody *ngIf="items && items.length > 0">
        <tr *ngFor="let item of items;let i = index;">
            <td>{{ i + 1}}</td>
            <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'Cluster Wise' || format === 'RevenueMandal Wise'">{{item.dname}}</td>
            <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{item.diV_NAME}}</td>
            <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{item.rvmandaL_NAME}}</td>
            <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise' || format === 'Cluster Wise'">{{item.mname}}</td>
           <td *ngIf="format === 'Secretariat Wise' || format === 'Cluster Wise'">{{item.seC_NAME}}</td>
           <td *ngIf="format === 'Secretariat Wise' || format === 'Cluster Wise'">{{item.seC_CODE}}</td>
           <td *ngIf="format === 'Cluster Wise'" ><a (click) ="getSubReport(item, subReportModal, 'isHHWise')" class="linkCls">{{item.clusteR_NAME}}</a></td>
           <td *ngIf="format === 'Department Wise' || format === 'Service Wise'">{{item.departmenT_NAME}}</td>
           <td *ngIf="format === 'Service Wise'">{{item.servicename}}</td>
            <td *ngIf="format === 'District Wise'" class="text-right">{{item.mandaL_CNT}}</td>
            <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'" class="text-right">{{item.seC_CNT}}</td>
            <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" class="text-right">{{item.clusteR_CNT}}</td>
            <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" class="text-right">{{item.clusteR_SURVEYED_CNT}}</td>
            <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'Cluster Wise'" class="text-right">{{item.householD_CNT}}</td>
            <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" class="text-right">{{item.householD_SURVEYED_CNT}}</td>
            <td class="text-right">{{item.voL_REQS}}</td>
            <td class="text-right"><a (click) ="getSubReport(item, subReportModal, 'tokens')" class="linkCls">{{item.tokens}}</a></td>
            <td class="text-right"><a (click) ="getSubReport(item, subReportModal, 'services')" class="linkCls">{{item.srV_APPS}}</a></td>
            <td class="text-right"><a (click) ="getSubReport(item, subReportModal, 'beyond')" class="linkCls">{{item.opeN_BEYOND_APPS}}</a></td>
            <td class="text-right"><a (click) ="getSubReport(item, subReportModal, 'within')" class="linkCls">{{item.opeN_WITHIN_APPS}}</a></td>
            <td class="text-right">{{item.closeD_BEYOND_APRVD_APPS}}</td>
            <td class="text-right">{{item.closeD_BEYOND_REJ_APPS}}</td>
            <td class="text-right">{{item.closeD_WITHIN_APRVD_APPS}}</td>
            <td class="text-right">{{item.closeD_WITHIN_REJ_APPS}}</td>
            <td class="text-right">{{item.lapsinG_24_HRS}}</td>
            <td class="text-right">{{item.lapsinG_48_HRS}}</td>
        </tr>
        <tr *ngIf="totalItems && totalItems.length > 0">
            <td></td>
            <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'Cluster Wise' || format === 'RevenueMandal Wise'">{{totalItems[0].dname}}</td>
            <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{totalItems[0].diV_NAME}}</td>
            <td width="100px" *ngIf="format === 'RevenueMandal Wise'">{{totalItems[0].rvmandaL_NAME}}</td>
            <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise' || format === 'Cluster Wise'">{{totalItems[0].mname}}</td>
           <td *ngIf="format === 'Secretariat Wise' || format === 'Cluster Wise'">{{totalItems[0].seC_NAME}}</td>
           <td *ngIf="format === 'Secretariat Wise' || format === 'Cluster Wise'">{{totalItems[0].seC_CODE}}</td>
           <td *ngIf="format === 'Cluster Wise'" ><a (click) ="getSubReport('item', subReportModal, 'isHHWise')" class="linkCls">{{totalItems[0].clusteR_NAME}}</a></td>
           <td *ngIf="format === 'Department Wise' || format === 'Service Wise'">{{totalItems[0].departmenT_NAME}}</td>
           <td *ngIf="format === 'Service Wise'">{{totalItems[0].servicename}}</td>
            <td *ngIf="format === 'District Wise'" class="text-right">{{totalItems[0].mandaL_CNT}}</td>
            <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'" class="text-right">{{totalItems[0].seC_CNT}}</td>
            <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" class="text-right">{{totalItems[0].clusteR_CNT}}</td>
            <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" class="text-right">{{totalItems[0].clusteR_SURVEYED_CNT}}</td>
            <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise' || format === 'Cluster Wise'" class="text-right">{{totalItems[0].householD_CNT}}</td>
            <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" class="text-right">{{totalItems[0].householD_SURVEYED_CNT}}</td>
            <td class="text-right">{{totalItems[0].voL_REQS}}</td>
            <td class="text-right">{{totalItems[0].tokens}}</td>
            <td class="text-right">{{totalItems[0].srV_APPS}}</td>
            <td class="text-right">{{totalItems[0].opeN_BEYOND_APPS}}</td>
            <td class="text-right">{{totalItems[0].opeN_WITHIN_APPS}}</td>
            <td class="text-right">{{totalItems[0].closeD_BEYOND_APRVD_APPS}}</td>
            <td class="text-right">{{totalItems[0].closeD_BEYOND_REJ_APPS}}</td>
            <td class="text-right">{{totalItems[0].closeD_WITHIN_APRVD_APPS}}</td>
            <td class="text-right">{{totalItems[0].closeD_WITHIN_REJ_APPS}}</td>
            <td class="text-right">{{totalItems[0].lapsinG_24_HRS}}</td>
            <td class="text-right">{{totalItems[0].lapsinG_48_HRS}}</td>
        </tr>
    </tbody>
    <tbody *ngIf="items && items.length === 0">
        <tr>
            <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
        </tr>
    </tbody>
   </table>
</div>
<div class="table-responsive tableFixHead" #SUBTABLE class="hideTable">
    <table class="tableCls" >
        <thead *ngIf = "subReport !== 'isHHWise'"> 
            <tr>
                <th>S.No</th>
                <th>District</th>
                <th>Mandal</th>
                <th>Secretariat</th>
                <th>Secretariat Code</th>
                <th>Department</th>
                <th>Service Name</th>
                <th>Is service requested in volunteer app</th>
                <th>Token Number</th>
                <th>Token Raised Date</th>
                <th>Application No</th>
                <th>Application Raised Date</th>
                <th>SLA Status</th>
                <th>SLA Days</th>
                <th>Application Status</th>
                <th>Lapsing Date</th>
                <th>Pending at Designation</th>
            </tr>
        </thead>
        <thead *ngIf = "subReport === 'isHHWise'"> 
            <tr >
                <th>S.No</th>
                <th >HHID</th>
                <th >Service Name </th>
                <th >No. of services requested in volunteer app </th>
                <th >Token Generated</th>
                <th >Service Request raised</th>
            </tr>
        </thead>
        <tbody *ngIf = "subReport !== 'isHHWise' && popupItems && popupItems.length > 0">
            <tr *ngFor="let item of popupItems;let i = index;">
                <td>{{i + 1}}</td>
                <td>{{item.dname}}</td>
                <td>{{item.mname}}</td>
                <td>{{item.seC_NAME}}</td>
                <td>{{item.seC_CODE}}</td>
                <td>{{item.departmenT_NAME}}</td>
                <td>{{item.servicename}}</td>
                <td>{{item.voL_REQ_FLAG === 'N' ? 'No' : 'Yes'}}</td>
                <td>{{item.tokeN_NO}}</td>
                <td>{{item.tokeN_RAISED_DATE}}</td>
                <td>{{item.apP_NO}}</td>
                <td>{{item.apP_RAISED_DATE}}</td>
                <td>{{item.apP_STATUS}}</td>
                <td>{{item.slA_DAYS}}</td>
                <td>{{item.approvE_STATUS}}</td>
                <td>{{item.lapsinG_DATE}}</td>
                <td>{{item.pendinG_AT_DESIGNATION}}</td>
            </tr>
        </tbody>
        <tbody *ngIf = "subReport === 'isHHWise' && popupItems && popupItems.length > 0">
            <tr *ngFor="let item of popupItems ;let i = index;">
                <td>{{i + 1}}</td>
                <td>{{item.householD_CODE}}</td>
                <td>{{item.servicename}}</td>
                <td>{{item.voL_REQS}}</td>
                <td>{{item.tokens}}</td>
                <td>{{item.srV_APPS}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="popupItems && popupItems.length === 0">
            <tr>
                <td [attr.colspan]="popupCols" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #subReportModal let-modal>
    <div class="modal-header">
     <h5 class="modal-title">{{title}}</h5>
     <button type="button" class="close" #closebutton (click)="modal.dismiss()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
     </button>
    </div>
    
    <div class="modal-body">
        <div class="table-responsive tableFixHead">
            <table class="tableCls" >
                <thead *ngIf = "subReport !== 'isHHWise'"> 
                    <tr>
                        <th>S.No</th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'dname'">District <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'mname'">Mandal <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'seC_NAME'">Secretariat <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'seC_CODE'">Secretariat Code <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'departmenT_NAME'">Department <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'servicename'">Service Name <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'voL_REQ_FLAG'">Is service requested in volunteer app <mdb-icon fas icon="sort"></mdb-icon> </th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'tokeN_NO'">Token Number  <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'tokeN_RAISED_DATE'">Token Raised Date  <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'apP_NO'">Application No <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'apP_RAISED_DATE'">Application Raised Date <mdb-icon fas icon="sort"></mdb-icon></th>
                        
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'apP_STATUS'">SLA Status<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'slA_DAYS'">SLA Days <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'approvE_STATUS'">Application Status <mdb-icon fas icon="sort"></mdb-icon></th>
                        <!-- <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'" *ngIf="subReport === 'tokens'">Token Expiry Date <mdb-icon fas icon="sort"></mdb-icon></th> -->
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'lapsinG_DATE'">Lapsing Date <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'pendinG_AT_DESIGNATION'">Pending at Designation <mdb-icon fas icon="sort"></mdb-icon></th>
                    </tr>
                </thead>
                <thead *ngIf = "subReport === 'isHHWise'"> 
                    <tr >
                        <th>S.No</th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'householD_CODE'">HHID <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'servicename'">Service Name <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'voL_REQS'">No. of services requested in volunteer app <mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'tokens'">Token Generated<mdb-icon fas icon="sort"></mdb-icon></th>
                        <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="popupItems" [sortBy]="'srV_APPS'">Service Request raised<mdb-icon fas icon="sort"></mdb-icon></th>
                    </tr>
                </thead>
                <tbody *ngIf = "subReport !== 'isHHWise' && popupItems && popupItems.length > 0">
                    <tr *ngFor="let item of popupItems | slice: (popupPage-1) * popupPageSize : popupPage * popupPageSize;let i = index;">
                        <td>{{ ((popupPage -1) * popupPageSize) + (i + 1)}}</td>
                        <td>{{item.dname}}</td>
                        <td>{{item.mname}}</td>
                        <td>{{item.seC_NAME}}</td>
                        <td>{{item.seC_CODE}}</td>
                        <td>{{item.departmenT_NAME}}</td>
                        <td>{{item.servicename}}</td>
                        <td>{{item.voL_REQ_FLAG === 'N' ? 'No' : 'Yes'}}</td>
                        <td>{{item.tokeN_NO}}</td>
                        <td>{{item.tokeN_RAISED_DATE}}</td>
                        <td>{{item.apP_NO}}</td>
                        <td>{{item.apP_RAISED_DATE}}</td>
                        <td>{{item.apP_STATUS}}</td>
                        <td>{{item.slA_DAYS}}</td>
                        <td>{{item.approvE_STATUS}}</td>
                        <td>{{item.lapsinG_DATE}}</td>
                        <td>{{item.pendinG_AT_DESIGNATION}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf = "subReport === 'isHHWise' && popupItems && popupItems.length > 0">
                    <tr *ngFor="let item of popupItems | slice: (popupPage-1) * popupPageSize : popupPage * popupPageSize;let i = index;">
                        <td>{{ ((popupPage -1) * popupPageSize) + (i + 1)}}</td>
                        <td>{{item.householD_CODE}}</td>
                        <td>{{item.servicename}}</td>
                        <td>{{item.voL_REQS}}</td>
                        <td>{{item.tokens}}</td>
                        <td>{{item.srV_APPS}}</td>
                    </tr>
                </tbody>
                <tbody *ngIf="popupItems && popupItems.length === 0">
                    <tr>
                        <td [attr.colspan]="popupCols" class="text-center">No records found.</td>
                    </tr>
                </tbody>
            </table>
            <div class="mt-4 btnHide">
                <div class="float-left" *ngIf="popupItems.length > 0">
                    <label>Requests per page: </label>
                    <select class="selectCls pageSizeCls" [(ngModel)]="popupPageSize">
                        <option  value="10">10</option>
                        <option  value="20">20</option>
                        <option  value="30">30</option>
                        <option  value="40">40</option>
                        <option  value="50">50</option>
                    </select> 
                </div>
                <ngb-pagination class="d-flex justify-content-end" *ngIf="popupItems.length > 0"    [collectionSize]="popupItems.length" [(page)]="popupPage" [pageSize]="popupPageSize"
                    [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                </ngb-pagination>
            </div>
        </div>
    </div>
    <div class="modal-footer">
      <button  name="close" class="greenBtnCls" (click)="modal.dismiss()">Cancel</button>
      <button  name="close" class="greenBtnCls" *ngIf="popupItems && popupItems.length > 0" (click)="downloadExcelForSubReport()">Download Excel</button>
    </div>
   </ng-template>