import { Injectable } from '@angular/core';
import { CommonMsgModalComponent } from './common-msg-modal/common-msg-modal.component';
import { CommonService } from './common.service';
import { EmudhraDigitalSignService } from './emudhra-digital-sign.service';
import { LoaderService } from './loader.service';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal, NgbModalConfig,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Injectable({
  providedIn: 'root'
})
export class DigitalSignService {

  constructor(
    private modalService: NgbModal,
    private eMudhraDigiSign: EmudhraDigitalSignService,
    private spinner: LoaderService,
    private utils: CommonService,
    // private toast: TosterService
  ) {}

  signCertificate(obj: { imageData: any; serviceId: any; location: any; req: any; }): void {
    // Create the event
    const event = new CustomEvent('digitalSignInput', {
      detail: {
        imageData: obj.imageData,
        serviceId: obj.serviceId,
        location: obj.location,
        req: obj.req,
      },
    });

    // Dispatch/Trigger/Fire the event
    document.dispatchEvent(event);
  }


  
  tokenList(): any {
    return new Promise((resolve, reject) => {
      const tokenReq = {
        AppID: this.utils.getAppId(),
        tokenStatus: this.utils.tokenStatus,
        tokenType: this.utils.tokenType,
      };
      let tokenReqStr = JSON.stringify(tokenReq);
      var sendingReq :any = {
        request: {
          type: "ListToken",
          data: tokenReqStr,
          tempFilePath: ""
        }
      }
      // console.log((JSON.stringify(sendingReq)),'stringify')
      this.eMudhraDigiSign
        .tokenListEncrypt(sendingReq)
        .then((resp: any) => {
        //  console.log(resp,'token encrypt res',resp.result.result)
         // console.log('token parse resp',JSON.parse(resp.result.content))
         if(resp.result.isSuccess == true){
          if(resp.result.result.ErrorCode == null && resp.result.result.ErrorMsg == null){
            var encryptResponse = JSON.parse(resp.result.result)
            const sendRequestEmudhra = {
              encryptedRequest: encryptResponse.encryptedRequest,
              encryptionKeyId:encryptResponse.encryptionKeyId,
            };
            if (resp.status === 5) {
              // const encData = JSON.parse(resp.result.content);
              const encData = JSON.stringify(sendRequestEmudhra)
              this.eMudhraDigiSign
                .ListToken(encData)
                .then((res: any) => {
                // console.log(res,'token emudra resp',res)
                if(res.errorCode == null && res.errorMsg == null){
                  if (res.status === 1 && res.responseData !== null) {
                    var decryptsendingReq :any = {
                      request: {
                        type: "ListToken",
                        data: res.responseData,
                        tempFilePath: ""
                      }
                    }
                    //console.log(res,'token decrypt req',decryptsendingReq)
                    this.eMudhraDigiSign
                      .tokenListDecrypt(decryptsendingReq)
                      .then((respy: any) => {
                        // console.log(respy,'decrypt respy',respy.result.content)
                        if(respy.result.isSuccess == true){
                        if(respy.result.result.ErrorCode == null && respy.result.result.ErrorMsg == null){
                      
                      // console.log(respy,'response status resp',respy.result.content)
                        if (respy.status === 5) {
                          const tokenDetails = JSON.parse(respy.result.result);
                          if (tokenDetails.tokens.length > 0) {
                            const objResp = {
                              success: true,
                              result: tokenDetails.tokens,
                            };
                            resolve(objResp);
                          } else {
                            //alert("Empty Token List")
                            // this.toast.info(respy.message);
                            const objResp = {
                              success: false,
                            };
                            resolve(objResp);
                          }
                        } else {
                          //alert("Error in Token List Decryption")
                          // this.toast.info(respy.message);
                          const objResp = {
                            success: false,
                          };
                          resolve(objResp);
                        }
                        }else if(respy.result.result.ErrorCode != null && respy.result.result.ErrorMsg != null){
                          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                          modalRef.componentInstance.message = 'Error in Digital Signing - ' + respy.result.result.ErrorCode  + ' -  ' + respy.result.result.ErrorMsg;
                        }
                      }else{
                        const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                        modalRef.componentInstance.message = 'Error in Digital Signing - ' + respy.result.error;
                      }
                      })
                      .catch((error: any) => {
                        reject(error);
                      });
                  } else {
                  //  alert("Error in e-Mudhra response")
                    // this.toast.info(res.errorMessage);
                    const objResp = {
                      success: false,
                    };
                    resolve(objResp);
                  }
                }else if(res.errorCode != null && res.errorMsg != null){
                  const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                  modalRef.componentInstance.message = 'Error in Digital Signing - ' + res.errorCode + ' -  ' + res.errorMsg;
                }
                })
                .catch((error: any) => {
                  reject(error);
                });
            } else {
            // alert("Error in Token List Encryption")
              // this.toast.info(resp.message);
              const objResp = {
                success: false,
              };
              resolve(objResp);
            }
          }else if(resp.result.result.ErrorCode != null && resp.result.result.ErrorMsg != null){
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.message = 'Error in Digital Signing - ' + resp.result.result.ErrorCode + ' -  ' + resp.result.result.ErrorMsg;
          }
         }else{
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.message = 'Error in Digital Signing - ' + resp.result.error;
         }
         
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }



  certificateList(certReq: any): any {
    return new Promise((resolve, reject) => {
      certReq.appID = this.utils.getAppId();
      let certReqStr = JSON.stringify(certReq);
      var sendingReq :any = {
        request: {
          type: "ListCertificate",
          data: certReqStr,
          tempFilePath: ""
        }
      }
      //console.log(JSON.stringify(sendingReq),'certificateList input')
      this.eMudhraDigiSign
        .certificateListEncrypt(sendingReq)
        .then((resp: any) => {
        //  console.log(resp,'cert encrypt status resp',resp.result.result)
         // console.log('cert parse resp',JSON.parse(resp.result.content))
         if(resp.result.isSuccess == true){
         if(resp.result.result.ErrorCode == null && resp.result.result.ErrorMsg == null){
            var encryptResponse = JSON.parse(resp.result.result)
            const sendRequestEmudhra = {
              encryptedRequest: encryptResponse.encryptedRequest,
              encryptionKeyId:encryptResponse.encryptionKeyId,
            };
            if (resp.status === 5) {
              // const encData = JSON.parse(resp.result);
              const encData = JSON.stringify(sendRequestEmudhra)
              //console.log(encData,'cert emudra encData',)
              this.eMudhraDigiSign
                .ListCertificate(encData)
                .then((res: any) => {
                  // console.log(res,'cert emudra resp')
                  if(res.errorCode == null && res.errorMsg == null){
                
                  if (res.status === 1 && res.responseData !== null) {
                    var decryptsendingReq :any = {
                      request: {
                        type: "ListCertificate",
                        data: res.responseData,
                        tempFilePath: ""
                      }
                    }
                  // console.log(res,'cert decrypt req',decryptsendingReq)
                    this.eMudhraDigiSign
                      .certificateListDecrypt(decryptsendingReq)
                      .then((respy: any) => {
                        //  console.log(respy,'decrypt respy',respy.result.result)
                        if(respy.result.isSuccess == true){
                        if(respy.result.result.ErrorCode == null && respy.result.result.ErrorMsg == null){
                       
                      // console.log(respy,'response status resp',respy.result.content)
                        if (respy.status === 5) {
                          const certDetails = JSON.parse(respy.result.result);
                          if (certDetails.Certificates.length > 0) {
                            const objResp = {
                              success: true,
                              result: certDetails.Certificates,
                            };
                            resolve(objResp);
                          } else {
                          // alert("No Certificates Found")
                          //  this.toast.info(respy.message);
                            const objResp = {
                              success: false,
                            };
                            resolve(objResp);
                          }
                        } else {
                        // alert("Error in Certificate List Decryption")
                          // this.toast.info(respy.message);
                          const objResp = {
                            success: false,
                          };
                          resolve(objResp);
                        }
                      }else if(respy.result.result.ErrorCode != null && respy.result.result.ErrorMsg != null){
                        const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                        modalRef.componentInstance.message = 'Error in Digital Signing - ' + respy.result.result.ErrorCode + ' -  ' + respy.result.result.ErrorMsg;
                      }
                    }else{
                      const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                      modalRef.componentInstance.message = 'Error in Digital Signing - ' + respy.result.error;
                     }
                      })
                      .catch((error: any) => {
                        reject(error);
                      });
                  } else {
                  // alert("Error in e-Mudra Connection")
                    // this.toast.info(res.errorMessage);
                    const objResp = {
                      success: false,
                    };
                    resolve(objResp);
                  }
                }else if(res.errorCode != null && res.errorMsg != null){
                  const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                  modalRef.componentInstance.message = 'Error in Digital Signing - ' + res.errorCode + ' -  ' + res.errorMsg;
                }
                })
                .catch((error: any) => {
                  reject(error);
                });
            } else {
            //  alert("Error in Certificate List Encryption")
              // this.toast.info(resp.message);
              const objResp = {
                success: false,
              };
              resolve(objResp);
            }
          }else if(resp.result.result.ErrorCode != null && resp.result.result.ErrorMsg != null){
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.message = 'Error in Digital Signing - ' + resp.result.result.ErrorCode + ' -  ' + resp.result.result.ErrorMsg;
          }
        }else{
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.message = 'Error in Digital Signing - ' + resp.result.error;
         }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  checkEmudhraCredentials(certReq: any, signModel : any, requisitionID: string, departmentName : string): any{
    return new Promise((resolve, reject) => {
      var tempPath = "";
      certReq.appID = this.utils.getAppId();
      let certReqStr = JSON.stringify(certReq);
     // console.log(certReqStr,'certReqStr')
      var sendingReq :any = {
        request: {
          type: "PKCSBulkSign",
          data: certReqStr,
          tempFilePath: "",
          CertificateId: requisitionID,
          File  : signModel
        }
      }
     // console.log(JSON.stringify(sendingReq),'sendingReq')
      this.eMudhraDigiSign
        .individualSignEncrypt(sendingReq)
        .then((resp: any) => {
         // console.log(resp,'sign encrypt status response',resp.result.content)
          //console.log('sign parse response',JSON.parse(resp.result.content))
          var encryptResponse:any;
          if(resp.result.isSuccess == true){
          if(resp.errorCode == null && resp.errorMsg == null){   
          if (resp.result.content !== undefined && resp.status === 5) {
            encryptResponse = JSON.parse(resp.result.content)
           // console.log('sign parse response',JSON.parse(resp.result.content))
                
          } else {
            const objResp = {
              success: false,
            };
            resolve(objResp);
          }
          }else if(resp.errorCode != null && resp.errorMsg != null){
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.message = 'Error in Digital Signing - ' + resp.errorCode + ' -  ' + resp.errorMsg;
          }
        }else{
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.message = 'Error in Digital Signing - ' + resp.result.error;
         }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  signDoc(certReq: any, signModel : any, requisitionID: string, departmentName : string): any {
    return new Promise((resolve, reject) => {
      var tempPath = "";
      certReq.appID = this.utils.getAppId();
      let certReqStr = JSON.stringify(certReq);
     // console.log(certReqStr,'certReqStr')
      var sendingReq :any = {
        request: {
          type: "PKCSBulkSign",
          data: certReqStr,
          tempFilePath: "",
          CertificateId: requisitionID,
          File  : signModel
        }
      }
     // console.log(JSON.stringify(sendingReq),'sendingReq')
      this.eMudhraDigiSign
        .individualSignEncrypt(sendingReq)
        .then((resp: any) => {
        // console.log(resp,'sign encrypt status response',resp.result.result)
          //console.log('sign parse response',JSON.parse(resp.result.content))
          var encryptResponse:any;
          if(resp.result.isSuccess == true){
          if(resp.result.result.ErrorCode == null && resp.result.result.ErrorMsg == null){
              
          if (resp.result.result !== undefined && resp.status === 5) {
            encryptResponse = JSON.parse(resp.result.result)
            tempPath = encryptResponse.tempFilePath;
            const sendRequestEmudhra = {
              encryptedRequest: encryptResponse.encryptedRequest,
              encryptionKeyId:encryptResponse.encryptionKeyId,
              tempFilePath: encryptResponse.tempFilePath
            };
            // const encData = JSON.parse(resp.result);
            const encData = JSON.stringify(sendRequestEmudhra)
            //console.log(encData,'sign emudra encData request')
            this.eMudhraDigiSign
              .PKCSBulkSign(encData)
              .then((res: any) => {
              //  console.log(res,'sign emudra response')
               if(res.errorCode == null && res.errorMsg == null){
                  if (res.status === 1 && res.responseData !== null) {
                    var decryptsendingReq :any = {
                      request: {
                        type: "PKCSBulkSign",
                        data: res.responseData,
                        tempFilePath: encryptResponse.tempFilePath,
                        CertificateId: requisitionID,
                        DepartmentCode : departmentName
                      }
                    }
                  //  console.log(res,'sign decrypt request',decryptsendingReq)
                    this.eMudhraDigiSign
                      .individualSignDecrypt(decryptsendingReq)
                      .then((respy: any) => {
                      // console.log(res,'sign decrypt response',respy.result.result)
                      // console.log(respy,'sign response status resp',respy.result.content)
                      if(respy.result.isSuccess == true){
                      if(respy.result.result.ErrorCode == null && respy.result.result.ErrorMsg == null){
                          if (respy.status === 5) {
                            var signDetails:any;
                            if(respy.result.result !== undefined)
                              signDetails = JSON.parse(respy.result.result);
                            if ( signDetails.bulkSignItems!=null && signDetails.bulkSignItems.length > 0) {
                              const objResp = {
                                success: true,
                                result: signDetails.bulkSignItems,
                              };
                            resolve(objResp);
                            } else {
                              const objResp = {
                                success: false,
                              };
                              resolve(objResp);
                            }
                          } else {
                            const objResp = {
                              success: false,
                            };
                            resolve(objResp);
                          }
                        }else if(respy.result.result.ErrorCode != null && respy.result.result.ErrorMsg != null){
                          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                          modalRef.componentInstance.message = 'Error in Digital Signing - ' + respy.result.result.ErrorCode + ' -  ' + respy.result.result.ErrorMsg;
                        }
                      }else{
                        const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                        modalRef.componentInstance.message = 'Error in Digital Signing - ' + respy.result.error;
                       }
                      })
                      .catch((error: any) => {
                        reject(error);
                      });
                  } else {
                    const objResp = {
                      success: false,
                    };
                    resolve(objResp);
                  }
                }else if(res.errorCode != null && res.errorMsg != null){
                  const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
                  modalRef.componentInstance.message = 'Error in Digital Signing - ' + res.errorCode + ' -  ' + resp.errorMsg;
                }
              })
              .catch((error: any) => {
                reject(error);
              });
          } else {
            const objResp = {
              success: false,
            };
            resolve(objResp);
          }
          }else if(resp.result.result.ErrorCode != null && resp.result.result.ErrorMsg != null){
            const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
            modalRef.componentInstance.message = 'Error in Digital Signing - ' + resp.result.result.ErrorCode + ' -  ' + resp.result.result.ErrorMsg;
          }
        }else{
          const modalRef = this.modalService.open(CommonMsgModalComponent, { size: 'md' });
          modalRef.componentInstance.message = 'Error in Digital Signing - ' + resp.result.error;
         }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  }

  
}
