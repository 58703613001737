import { Component,Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pattadar-mobileno-seeding-view',
  templateUrl: './pattadar-mobileno-seeding-view.component.html',
  styleUrls: ['./pattadar-mobileno-seeding-view.component.css']
})
export class PattadarMobilenoSeedingViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
}
