<div class="certificateInfo" *ngIf="basicInfo ">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo ">
    <div class="subHeadingCls mb-3">Caste Certificate</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Issued Caste Certificate In Past:</label>
                <div class="valueCls">{{certificateInfo.issuedCastCertificateInPast ? (certificateInfo.issuedCastCertificateInPast) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Caste Claimed:</label>
                <div class="valueCls">{{certificateInfo.castClaimed ? (certificateInfo.castClaimed) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <!-- <div class="fieldHolder">
                <label class="labelCls">Caste Category:</label>
                <div class="valueCls">{{certificateInfo.castCategory ? (certificateInfo.castCategory) : '-' }}</div>
            </div> -->
            <div class="fieldHolder">
                <label class="labelCls">Purpose of Caste Certificate:</label>
                <div class="valueCls">{{certificateInfo.purposeOfCertificate ? (certificateInfo.purposeOfCertificate) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Education Certificate Contains Caste:</label>
                <div class="valueCls">{{certificateInfo.doesEducationCerficateContainCast? 'Yes' : 'No'}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Family Income:</label>
                <div class="valueCls">{{certificateInfo.familyIncome ? (certificateInfo.familyIncome) : '-' }}</div>
            </div>
        </div>
        </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Property Particulars Certificate'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Applicant Father/Mother property particulars:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Ration Card Certificate'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Ration card /EPIC Card/AADHAR Card:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Employment particulars Certificate'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Applicant Father/Mother Employment particulars/Income Tax returns (for professionals):</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>


