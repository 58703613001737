import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService} from '../../../../shared/common.service';
@Component({
  selector: 'app-fp-shop-view',
  templateUrl: './fp-shop-view.component.html',
  styleUrls: ['./fp-shop-view.component.css']
})
export class FpShopViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  items: any[] = [];
  commonConstants: any = CommonConstants;
    
  constructor(private commonService: CommonService, ) { }

  ngOnInit(): void {
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

}
