import { Component, OnInit } from '@angular/core';
import { PdfViewerPopupComponent } from '../../shared/pdf-viewer-popup/pdf-viewer-popup.component';
import { CommonConstants } from '../../constants/common-constants.component';
import { CommonService } from '../../shared/common.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WhitespaceValidator } from '../../shared/common.service';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent implements OnInit {
  commonConstants : any = CommonConstants;
  fileList: any = [];
  reqId: any;
  deptCode: any;
  loginDisplay: Boolean = false;
  form: FormGroup;
  submitted: Boolean = false;
  showDocuments: Boolean = false;
  constructor(private commonService: CommonService, private modalService: NgbModal,config: NgbModalConfig,
    private currentRoute: ActivatedRoute,private authService: MsalService, private formBuilder: FormBuilder
    ) { 
      config.backdrop = 'static';
      config.keyboard = false;
      // this.currentRoute.queryParams.subscribe((params) => {
      //   this.reqId = params?.applicationNo;
      //   this.deptCode = params?.deptCode;
      // });
      this.form = this.formBuilder.group({
        applicationNo: ['', [Validators.required, WhitespaceValidator.whiteSpaceValidate,  Validators.pattern(this.commonConstants.alphanumericwithoutspace)]],
      });
     // this.getFileDetails();
    }

  ngOnInit(): void {
    this.setLoginDisplay();
  }
  get f() { return this.form.controls; }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    // if(this.loginDisplay) {
    //   alert('Please log out from AP MeeSeva Portal to check uploaded documents.')
    // }
  }
  viewFile(blobRef:any, fileName:any) {
    console.log('file Extension', this.checkFileExtension(fileName));
    this.commonService.getRequest(this.commonConstants.viewFile + blobRef).subscribe({
      next: (responseData: any) => { 
        let fileInfo =  responseData.result.fileContents;
        const modalRef = this.modalService.open(PdfViewerPopupComponent, { size: 'lg' });
        modalRef.componentInstance.sourceFile = fileInfo;
        modalRef.componentInstance.showPrintDownload = false;
        if (this.checkFileExtension(fileName) !== 'pdf') {
          modalRef.componentInstance.isImage = true;
          modalRef.componentInstance.sourceFile = 'data:image/png;base64,' +fileInfo;
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  getFileDetails(reqId: any, deptCode: any) {
     this.showDocuments = true;   
    this.commonService.getRequest(this.commonConstants.getCertificateDetails + deptCode + '&IncludeFileUploadInfo=true&IncludePaymentDetails=false&RequisitionId='+ reqId).subscribe({
      next: (responseData: any) => { 
        if(typeof(responseData?.result) !== 'string') {
          this.reqId = responseData.result?.id;
          this.fileList = responseData.result?.fileList;
        } else {
          this.reqId = this.f.applicationNo?.value;
          this.fileList = [];
        }
      },
      error: (error) => {
        this.fileList = [];
        this.reqId = this.f.applicationNo?.value;
        console.log('client side', error);
      }
  });
  }
  checkFileExtension(name: any) {
    let fileNameArr = name.split('.');
    return fileNameArr[fileNameArr?.length - 1]?.toLowerCase();
  }
  getDocuments(): any {
    this.submitted = true;
    if (!this.form.valid)
      return false;
    else {
      this.getFileDetails(this.f.applicationNo.value, 'RV');
    }
  }
  onAppNoChange() {
    this.showDocuments = false;
  }
}
