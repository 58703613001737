<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3 doNotPrint">
        <div class="breadcrumbCls" *ngIf="this.departmentCode !== 'RD'">
            GSWS / Application Submission / {{departmentName}} Department <span class="activePage">/ Transaction Status</span>
        </div>
        <div class="breadcrumbCls" *ngIf="this.departmentCode === 'RD'">
            GSWS / Application Submission / Pension Department <span class="activePage">/ Transaction Status</span>
        </div>
    </div>
  
    <div class="mainPageContainer mr-3">
        <div id="__printingFrame">
            <div class="row mb-3">
                <!-- <img src="../../assets/images/Footer_logo.png" class="d-block mx-auto" width="6%"> -->
                <img src="../../assets/images/logo_official.png" class="d-block mx-auto" width="11%">
            </div>
        
            <div class="subHeadingCls text-center"><b>Transaction Status</b></div>
            <div class="row">
            
                <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right" style="line-height: 10px;">
                    <label class="labelCls note_txt"><b>Date :</b> {{ dateTime | date: 'dd/MM/yyyy'}} {{ dateTime | date: 'h:mm:ss a'}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
               
                </div>
            </div>

            <hr class="hrCls">
           
            <div class="row mb-3">
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>Secretariat Name :</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls">{{secretariatName}}</label>
                </div>

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>User ID :</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls">{{userInfo.userPrincipalName}}</label>
                </div>

                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
            </div>

            <div class="row mb-3">
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                
                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>Service Name :</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode !=='RD'">
                    <label class="labelCls" style="word-break: break-word;">{{ serviceName }}</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@1' || this.requisitionInfo.serviceCode ==='Pensions@V1')">
                    <label class="labelCls" style="word-break: break-word;">Pensions O.A.P</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@2' || this.requisitionInfo.serviceCode ==='Pensions@V2' || this.requisitionInfo.serviceCode ==='Pensions-NEW@2' || this.requisitionInfo.serviceCode ==='Pensions-NEW@V2')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Weavers</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@3' || this.requisitionInfo.serviceCode ==='Pensions@V3')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Disabled</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@4' || this.requisitionInfo.serviceCode ==='Pensions@V4')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Widow</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@5' || this.requisitionInfo.serviceCode ==='Pensions@V5')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Toddy Tappers</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@6'  || this.requisitionInfo.serviceCode ==='Pensions@V6')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Transgender</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@7' || this.requisitionInfo.serviceCode ==='Pensions@V7')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Fishermen</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@8' || this.requisitionInfo.serviceCode ==='Pensions@V8')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Single Women</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@9' || this.requisitionInfo.serviceCode ==='Pensions@V9' || this.requisitionInfo.serviceCode ==='Pensions-NEW@9' || this.requisitionInfo.serviceCode ==='Pensions-NEW@V9')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Traditional Cobblers</label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode ==='RD' && (this.requisitionInfo.serviceCode ==='Pensions@10'  || this.requisitionInfo.serviceCode ==='Pensions@V10'|| this.requisitionInfo.serviceCode ==='Pensions-NEW@10'  || this.requisitionInfo.serviceCode ==='Pensions-NEW@V10')">
                    <label class="labelCls" style="word-break: break-word;">Pensions Dappu Artists</label>
                </div>


                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>Transaction ID :</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls">{{ transactionID}}</label>
                </div>
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
            </div>



            <div class="row mb-3">
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                
                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b> Applicant Name : </b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls">{{ applicantName }} </label>
                </div>

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b> Applicant Father Name : </b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls">{{ applicantFatherName }}</label>
                </div>

                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
            </div>


            <div class="row mb-3">
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                
                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b> District :</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls">{{district }}</label>
                </div>

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b> Mandal:</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls">{{mandal }}</label>
                </div>

                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
            </div>

            
            <div class="row mb-3" *ngIf="this.departmentCode !== 'RD'">
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
                
                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b> Amount Paid : </b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls">{{ totalAmount }} /-</label>
                </div>

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>Amount In Words :</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls"> {{ amountInwords }} Ruppes Only</label>
                </div>

                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>
            </div>
            

            <div class="row mb-3">
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>

               <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2" *ngIf="this.departmentCode !== 'RD'">
                    <label class="labelCls lableHead"><b>Payment Mode :</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3" *ngIf="this.departmentCode !== 'RD'">
                    <label class="labelCls">{{paymentMode}}</label>
                </div>
                
                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>SLA :</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls">{{ SLA }}</label>
                </div>

          
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1">
                </div>
            </div>

            <div class="row mb-3">
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1"></div>

                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>Department Name:</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls"> {{departmentName }} Department </label>
                </div>
                
                <div class="col col-lg-2 col-md-2 col-sm-2 col-xs-2">
                    <label class="labelCls lableHead"><b>Transaction Status :</b></label>
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-3 col-xs-3">
                    <label class="labelCls"> {{ transactionStatus }} </label>
                </div>

             
                <div class="col col-lg-1 col-md-1 col-sm-1 col-xs-1">
                </div>
            </div>



            <br> <br><div class="row mb-3">
                <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
                <div class="col col-lg-8 col-md-8 col-sm-12 col-xs-12 text-center btnAlign">
                    <button class="btn btn-primary" routerLink="/view-transactions" routerLinkActive='active'>View Tranasactions</button>
                 </div>
               <div class="col col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
            </div>
   </div>   
            
    
    </div>
</div>