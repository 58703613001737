import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ror1-bview',
  templateUrl: './ror1-bview.component.html',
  styleUrls: ['./ror1-bview.component.css']
})
export class ROR1BViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  members: any[]=[];

  constructor() { }

  ngOnInit(): void {
    this.members = this.certificateInfo?.applicantDetailsInfoList;
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

}
