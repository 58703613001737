<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">FamilyIncome:</label>
                    <div class="valueCls">{{certificateInfo?.familyIncome}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Occupation:</label>
                    <div class="valueCls">{{certificateInfo?.occupation}}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Habitation:</label>
                    <div class="valueCls">{{certificateInfo?.habitation}}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Rice Card Number:</label>
                    <div class="valueCls">{{certificateInfo?.riceCardNumber}}</div>
                </div>
            </div>
    </div>
</div>
<hr>
<div class="certificateInfo ">
        <div class="subHeadingCls mb-3">Rice Card Family Member Details</div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <table class="tableCls mt-2">
                    <thead>
                        <tr>
                            <th>Serial Number</th>
                            <th>Rice Card Number</th>
                            <th>Name</th>
                            <th>Relation</th>
                            <th>Age</th>
                            <th>DOB</th>
                            <th>EKYC Status</th>
                            <th>Aadhar Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of gridDetail;let i=index">
                            <td class="serial">{{i+1 }}</td>
                            <td class="value">{{item.rice_Card_id}}</td>
                            <td>{{item?.name }}</td>
                            <td>{{item?.relation}}</td>
                            <td class="value">{{item?.member_age}}</td>
                            <td>{{item?.member_dob  | date: 'dd/MM/yyyy'}}</td>
                            <td>{{item.ekyc_status_done ? (item.ekyc_status_done) : '-' }}</td>
                            <td class="value">{{item?.adhar_number}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
</div>
<div class="certificateInfo ">
    <div class="subHeadingCls mb-3">Update Member Details</div>
    <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <table class="tableCls mt-2">
                    <thead>
                        <tr>
                            <th>Aadhar Card Number</th>
                            <th>English Name</th>
                            <th>Telugu Name</th>
                            <th>Mobile Number</th>
                            <th>DOB</th>
                            <th>Gender</th>
                            <th>Relation</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of members">
                                <td class="value">{{item.aadhaarNumber ? this.commonService.RSADecrypt(item.aadhaarNumber) : '-' }}</td>
                                <td>{{item.englishName ? (item.englishName) : '-' }}</td>
                                <td>{{item.teluguName ? (item.teluguName) : '-' }}</td>
                                <td class="value">{{item.mobileNumber ? (item.mobileNumber) : '-' }}</td>
                                <td>{{item.dateOfBirth | date: 'dd/MM/yyyy'}}</td>
                                <td>{{item.gender ? (item.gender) : '-' }}</td>
                                <td>{{item.relationShip ? (item.relationShip) : '-' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

    </div>

</div>
