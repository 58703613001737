import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';

@Component({
  selector: 'app-view-correction-of-wrong-aadhar-seeding',
  templateUrl: './view-correction-of-wrong-aadhar-seeding.component.html',
  styleUrls: ['./view-correction-of-wrong-aadhar-seeding.component.css']
})
export class ViewCorrectionOfWrongAadharSeedingComponent implements OnInit {
  members: any[]=[];
  gridDetail:any[]=[];

  commonConstants : any = CommonConstants;
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  
  constructor(public commonService: CommonService) {
    this.commonService.commonAction.subscribe((data: any)=> {
      if(data) {
        this.onDataChange(data);
      }
    });
   }

  ngOnInit(): void {
    this.members = this.certificateInfo?.riceCardMembersDataList;
    // console.log(this.members);
    this.getDetails(this.certificateInfo)
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }

  getDetails(certData:any) {

    let obj = {
     rice_card_no: certData.riceCardNumber,
   };
   this.commonService
     .postRequest(this.commonConstants.getRiceCardMemberDetails, obj)
     .subscribe({
       next: (responseData: any) => {
         console.log(responseData.result, 'riceCardDetails');
         this.gridDetail = responseData.result.rice_card_mem_details;

         console.log(this.gridDetail.length)
         if(this.gridDetail.length > 0){
           // this.showDetails = true;
           this.gridDetail = this.gridDetail.filter((item: any) => Number(item.adhar_number) !== Number(certData.riceCardFamilyInfoDataList[0].aAdharNumber));
         }else{
          
         }
       },
       error: (error) => {
         console.log(error);
       },
     });
 }

 onDataChange(data: any) {
  this.getDetails(data?.riceCardNumber);
}

}
