<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
      <div class="breadcrumbCls">
        GSWS / Application Submission / GSWS Department <span class="activePage">/ Reprint of Certificates</span>
      </div>
    </div>
    <div class="mainPageContainer mr-3">
      <h6>Reprint of Certificates Application Form</h6>
      <form [formGroup]="printForm">
        <nav ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)">
          <ng-container [ngbNavItem] = "1">
            <a ngbNavLink>Basic Details</a>
            <ng-template ngbNavContent>
              <app-common-form formControlName="commonForm" [isSubmitted]="commonFormSubmitted" (casteChange)="getSubCaste($event)" (reset)="commonFormSubmitted = false"></app-common-form>
              <div class="btnHolder">
                <button  class="greenBtnCls float-right" (click)="goToNextTab()">Continue</button>
              </div>
            </ng-template>
          </ng-container>
          <ng-container [ngbNavItem] = "2">
            <a ngbNavLink>Application Form</a>
            <ng-template ngbNavContent>
                <ng-container>
                    <div class="subHeadingCls mb-3">Authentication</div>
                    <div class="row mb-3">
                        
                        <div class="col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label class="labelCls">Authentication Type:<span class="mandatoryCls">*</span></label>
                            <div class="col-sm-9" required 
                                [ngClass]="{ 'is-invalid':otpSubmitted && f.authenticationType.errors }">
                                <input type="radio" name="authenticationType" value="rbOTP"  (change)="otpChange($event)"
                                    formControlName="authenticationType"><span class="mx-2 radioCls">OTP(One Time
                                    Password)</span>
                                <input type="radio" name="authenticationType" value="rbBiometric"  (change)="otpChange($event)"
                                    formControlName="authenticationType"><span class="mx-2 radioCls">Biometric</span>
                            </div>
                            <div *ngIf="otpSubmitted && f.authenticationType.errors"
                                class="invalid-feedback col col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div *ngIf="f.authenticationType.errors.required">Please select either OTP / Biometric
                                </div>
                            </div>
                        </div>
    
                       
                    </div>
                    <div *ngIf="f.authenticationType.value == 'rbOTP'">
                    <div class="row mb-3" >
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="labelCls">Aadhaar Number: <span class="mandatoryCls">*</span></label>
                            <input type="text" appBlockCopyPaste name="aadhaarNumberOTP" formControlName="aadhaarNumberOTP"  class="inputCls width63" required numbersOnly
                            [ngClass]="{ 'is-invalid':otpSubmitted && f.aadhaarNumberOTP.errors }" maxlength="12" minlength="12"
                            readonly>
                            <button class="btn btn-primary float-right" (click)="btnSendOTPandTimer()">Send OTP</button>
                            <div *ngIf="otpSubmitted && f.aadhaarNumberOTP.errors" class="invalid-feedback">
                              <div *ngIf="f.aadhaarNumberOTP.errors.required || f.aadhaarNumberOTP.errors.whiteSpaceValidate">Aadhaar Number is required</div>
                              <div *ngIf="f.aadhaarNumberOTP.errors.aadharValidate || f.aadhaarNumberOTP.errors.minlength || f.aadhaarNumberOTP.errors.maxlength">Please enter 12 digit Valid Aadhaar Number</div>
                            </div>
                        </div>
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <label class="labelCls">OTP (One Time Password): <span class="mandatoryCls">*</span></label>
                            <input type="password" name="otp"  appBlockCopyPaste class="inputCls" formControlName="otp" required [ngClass]="{ 'is-invalid':otpSubmitted && f.otp.errors }" numbersOnly minlength="6" maxlength="6"
                            [readonly] ="isOtpValidated">
                            <div *ngIf="otpSubmitted && f.otp.errors"
                            class="invalid-feedback">
                            <div *ngIf="f.otp.errors.required">Please enter 6 digit Valid OTP
                            </div>
                            </div>
                        </div>
                    </div>
                   
                    <div class="btnHolder"  *ngIf="f.authenticationType.value == 'rbOTP'">
                        <button class="greenBtnCls float-right" (click)="otpBtn()" >OTP Authenticate</button>
                        <!-- <button class="greenBtnCls float-right mr-3" (click)="resetPattadarOTPValidation()" >Reset</button> -->
                    </div>
                    <!-- <h5 class="text-success"  *ngIf="f.authenticationType.value == 'rbOTP'">{{this.message}}</h5> -->
                    <!-- <div class="mandatoryCls mb-3">Authentication disabled for testing purpose in dev environment</div> -->
                </div>
                <ng-container *ngIf="f.authenticationType.value == 'rbBiometric'">
                    <div class="row mb-3">
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Aadhaar Number: <span class="mandatoryCls">*</span></label>
                    <input type="text" appBlockCopyPaste name="aadhaarNumberBio" formControlName="aadhaarNumberBio" class="inputCls "  numbersOnly readonly
                    [ngClass]="{ 'is-invalid':f.aadhaarNumberBio.errors }" maxlength="12" minlength="12">
                    <div *ngIf="f.aadhaarNumberBio.errors" class="invalid-feedback">
                        <div *ngIf="f.aadhaarNumberBio.errors.required || f.aadhaarNumberBio.errors.whiteSpaceValidate">Aadhaar Number is required</div>
                        <div *ngIf="f.aadhaarNumberBio.errors.aadharValidate || f.aadhaarNumberBio.errors.minlength || f.aadhaarNumberBio.errors.maxlength">Please enter valid Aadhaar Number</div>
                    </div>
                    </div>
                    <div class="btnHolder mt-4">
                    <button *ngIf="!showBiometricPopup" class="greenBtnCls float-right" (click)="biometricBtn()">Biometric Authenticate</button>
                    </div>
                </div>
                <div class="btnHolder">
                    <button *ngIf="showBiometricPopup" class="greenBtnCls float-right" (click)="checkBiometricStatus()">Check Biometric Status</button>
                </div>
                </ng-container>
                </ng-container>
                <ng-container *ngIf="showCertificateInfo"> <!---->
                <div class="row my-3">
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                      <label class="labelCls">Department: <span class="mandatoryCls">*</span> </label>
                      <select class="selectCls" formControlName="department" required (change) = "onDepartmentChange()" [ngClass]="{ 'is-invalid':submitted && (f.department.errors|| f.department.value === 'Select')}"> 
                          <option [ngValue]="null" disabled>Select</option>
                          <option value="RV">Revenue</option>

                          <!-- commenting Health and Family Welfare Department  as of now as per ECC -->
                          <!-- <option value="HF">Health and Family Welfare Department</option> -->
                          <!-- <option value="Function Hall">Function Hall</option>
                          <option value="Worship Place">Worship Place</option>
                          <option value="Others">Others</option> -->
                        </select>
                      <div *ngIf="submitted && f.department.errors" class="selectError">
                        <div *ngIf="f.department.errors?.required">Please select Department</div>
                    </div>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                      <label class="labelCls">Service Name: <span class="mandatoryCls">*</span> </label>
                      <select class="selectCls" formControlName="serviceName" required [ngClass]="{ 'is-invalid':submitted && (f.serviceName.errors|| f.serviceName.value === 'Select')}"> 
                        <option [ngValue]="null" disabled>Select</option>
                        <option *ngFor="let item of services" [ngValue]="item.entityCode"> {{item.name}}</option>               
                         
                        </select>
                      <div *ngIf="submitted && f.serviceName.errors" class="selectError">
                        <div *ngIf="f.serviceName.errors?.required">Please select Service Name</div>
                    </div>
                    </div>

                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf="f.department.value ==='HF'">
                      <label class="labelCls">UHID: <span class="mandatoryCls">*</span> </label>
                      <input type="text" appBlockCopyPaste name="uhid"  formControlName="uhid" class="inputCls" required numbersOnly
                      [ngClass]="{ 'is-invalid':submitted && f.uhid.errors }" maxlength="11" minlength="11"  (keyup)="onKeyup($event)">
                      <div *ngIf="submitted && f.uhid.errors" class="invalid-feedback ">
                          <div *ngIf="f.uhid.errors.required">Please enter UHID</div>
                          <div *ngIf="f.uhid.errors.minlength">Please enter valid UHID</div>
                      </div>
                    </div> 
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="labelCls">Application Number: <span class="mandatoryCls">*</span> </label>
                        <input type="text" appBlockCopyPaste name="applicationNo"  formControlName="applicationNo" class="inputCls" required
                        [ngClass]="{ 'is-invalid':submitted && f.applicationNo.errors }" maxlength="20">
                        <div *ngIf="submitted && f.applicationNo.errors" class="invalid-feedback ">
                            <div *ngIf="f.applicationNo.errors.required || f.applicationNo.errors.whiteSpaceValidate">Please enter Application Number</div>
                            <div *ngIf="f.applicationNo.errors.pattern">Please enter valid Application Number</div>
                        </div>
                      </div>
                      <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12" *ngIf="f.department.value !=='HF'">
                        <label class="labelCls">Number Of Copies: <span class="mandatoryCls">*</span> </label>
                        <select class="selectCls" formControlName="noOfCopies" required [ngClass]="{ 'is-invalid':submitted && (f.noOfCopies.errors|| f.noOfCopies.value === 'Select')}"> 
                            <option [ngValue]="null" disabled>Select</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                        <div *ngIf="submitted && f.noOfCopies.errors" class="selectError">
                          <div *ngIf="f.noOfCopies.errors?.required">Please select Number Of Copies</div>
                      </div>
                      </div>
                  </div>
              <div class="btnHolder">
                <button  class="greenBtnCls float-right" (click)="submit()">Show Payment</button>
              </div>
            </ng-container>
            </ng-template>
          </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav"></div>
      </form>
    </div>
  </div>
  
  