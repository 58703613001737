import { Component, OnInit } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants.component';
import { Router } from '@angular/router';
import { CommonService, CustomAdapter, CustomDateParserFormatter, WhitespaceValidator } from 'src/shared/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbDatepickerConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { fileExtensionValidator, fileSizeValidator } from 'src/shared/validators/file-validator';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
@Component({
  selector: 'app-aarogya-team-schedule',
  templateUrl: './aarogya-team-schedule.component.html',
  styleUrls: ['./aarogya-team-schedule.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]
})
export class AarogyaTeamScheduleComponent implements OnInit {
  submitted: Boolean = false;
  commonConstants : any = CommonConstants;
  teamList: any = [];
  teams: any = [];
  VHCList: any = [];
  teamForm: FormGroup;
  currentDate = new Date();
  maxDate: any;
  minDate: any;
  selectedvhccode: any;
  selectedscheduleDate: any;
  selectedsecretariatCode: any;
  confirmModal: any;
  confirmModal1: any;
  confirmModal2: any;
  isScheduleConfirmed: boolean = false;
  isDisabled: any;
  holidayDates: string[] = [];
  allowedDates: string[] = [];
  selectedSchedule:any = null;
  isEditSubmitted: boolean = false;
  editModal: any;
  campStartDetailsForm: FormGroup;
  campEndDetailsForm: FormGroup;
  isCampDetailsSubmitted: Boolean = false;
  campStartPhotoLabelValue = "";
  campStartPhoto: any;
  docAttendanceSheet: any;
  campEndPhoto: any;
  campDetailsPopup: any;
  docAttendanceSheetLabelValue ="";
  campEndPhotoLabelValue =  "";
  campDetails: any;
  isCampStarted: Boolean = false;
  isCampEnded: Boolean = false;
  isCampClosed: Boolean = false;
  campStartImg: any;
  campEndImg: any;
  docSheetImg: any
  splitCampForm: FormGroup;
  campSplitModal: any;
  isSplitSubmitted: Boolean = false;
  dropdownSettings:IDropdownSettings={};
  secretariatList: any = [];
  minCampSplitDate: any;
  isUrban: any = false;
  SplitVHCList: any = [];
  isCommonSplitFlag: boolean = false;
  secretariatListAddSchedule: any = [];


  constructor(private modalService: NgbModal,
    private formBuilder: FormBuilder, private router: Router, private commonService: CommonService,  private calendar: NgbCalendar, private config: NgbDatepickerConfig) {
    this.teamForm = this.formBuilder.group({
      team: [null, [Validators.required ]], 
      VHC: [null, [Validators.required]], 
      secretariat: [null, [Validators.required]], 
      VenueType: [null, [Validators.required]], 
      Venue: [null, [Validators.required, WhitespaceValidator.whiteSpaceValidate]], 
      sheduleDate: [null, [Validators.required]],
      PHC: [null],
      PHCCode: [null]
      //secretariat: [null]
    });

    this.campStartDetailsForm = this.formBuilder.group({
      campStartStatus: [null, [Validators.required]],
      campStartPhoto: [null, [Validators.required]],
      docAttendanceSheet: [null, [Validators.required]],
      isFemaleDocPresent: [null, [Validators.required]],
      totalRooms: [null, [Validators.required]],
      waitingRoomAvail: [null, [Validators.required]],
      privateRoomAvail: [null, [Validators.required]],
      helpdeskRegAvail: [null, [Validators.required]],
      medicationKits: [null, [Validators.required]],
      JASFoldersAvail: [null, [Validators.required]],
      JASCaseSheetAvail: [null, [Validators.required]],
      JASBagsAvail: [null, [Validators.required]],
      JASBannerAvail: [null, [Validators.required]],
      eyeTestAvail: [null, [Validators.required]],
      basicFacilitiesAvail: [null, [Validators.required]],
      maskSanitizerAvail: [null, [Validators.required]],
      patientsCount: [null, [Validators.required]],
      doctorsAttended: [null, [Validators.required]],
      specialistAttendCount: [null, [Validators.required]],
    });
    this.campEndDetailsForm = this.formBuilder.group({
      totalPatientsCount: [null, [Validators.required]],
      campEndPhoto:  [null, [Validators.required]],
      sanitizationOfVenue: [null, [Validators.required]],
      totalOPs: [null, [Validators.required]],
      totalWomenOPs:  [null, [Validators.required]],
      totalMenOPs: [null, [Validators.required]],
      noOfSchoolChilds: [null, [Validators.required]],
      noOfAnganwadiChilds:  [null, [Validators.required]],
      noOfPregnants: [null, [Validators.required]],
      noOfEyeScreenings: [null, [Validators.required]],
      noOfSpectacles:  [null, [Validators.required]],
      awarenessSession: [null, [Validators.required]],
      awarenessAarogyasri: [null, [Validators.required]],
      sessionByANM:  [null, [Validators.required]],
      nutritionStall: [null, [Validators.required]],
      noOfCitizensCampEnd: [null, [Validators.required]],
      noOfDoctorsCampEnd:  [null, [Validators.required]],
      noOfSpecialistCampEnd: [null, [Validators.required]]
      
    });

    this.splitCampForm = this.formBuilder.group({
      team: [null, [Validators.required ]], 
      VHC: [null, [Validators.required]], 
      VenueType: [null, [Validators.required]], 
      Venue: [null, [Validators.required, WhitespaceValidator.whiteSpaceValidate]], 
      sheduleDate: [null, [Validators.required]],
      secretariat: [null, [Validators.required]]
    });
    this.getDetails();
    //this.disableDates();
    this.getTeams();
    //this.getSplitTeams();
    this.getScheduleDetails();
    
    this.maxDate = {
      year: 2024,
      month: 6,
      day: 30    
    };

    this.minDate = {
      year: 2024,
      month: 1,
      day: 1  
    };
    this.minCampSplitDate = {
      year: 2024,
      month: 6,
      day: 30,
    };  
  }

  ngOnInit(): void {
    this.dropdownSettings = {
      idField: 'secretariatCode',
      textField: 'secretariatName',
      enableCheckAll: true,
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All"
    };    
  }

  get f() {
    return this.teamForm.controls;
  }
  get f1() {
    return this.campStartDetailsForm.controls;
  }
  get f2() {
    return this.campEndDetailsForm.controls;
  }
  get f3() {
    return this.splitCampForm.controls;
  }  

  goToHome() {
    this.router.navigate(['/home']);
  }

  goToTeamComposition() {
    this.router.navigate(['/aarogya-suraksha-team-composition']);
  }

  // disableDates() {
  //   let data = "FUTURE_DATE";
  //   this.commonService.getRequest(this.commonConstants.GetJASHolidaysList + data).subscribe({
  //     next: (responseData: any) => {
  //       if(responseData?.result?.isSuccess){
  //         this.holidayDates = responseData?.result?.holidayList;
  //         this.isDisabled = (date: NgbDateStruct) => {
  //           let dt = moment(new Date(date.year,date.month-1, date.day),"DD-MM-YYYY").format("YYYY-MM-DD");
  //           return this.holidayDates.indexOf(dt) >= 0? true : false;
  //         };
  //       }
  //      },
  //     error: (error) => {
  //       console.log('client side', error);
  //     },
  //   });
  // }

  getTeams() {
    let data = "Flag=GET TEAM";
    this.commonService.getRequest(this.commonConstants.GetJASTeamScheduleDetails + data).subscribe({
      next: (responseData: any) => {
        if(responseData?.result?.isSuccess){
          this.teams = responseData.result.jasTeamScheduleDetailsData;
        } else {
          this.teams = [];
        }
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  getVHC() {
    let data = "Flag=GET VHC";
    this.commonService.getRequest(this.commonConstants.GetJASTeamScheduleDetails + data).subscribe({
      next: (responseData: any) => {
        if(responseData?.result?.isSuccess){
          this.VHCList = responseData.result.jasTeamScheduleDetailsData;
        } else {
          this.VHCList = [];
        }
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  getScheduleDetails() {
    this.getVHC();
    this.teamList = [];
    let data = "Flag=GET SCHEDULES";
    this.commonService.getRequest(this.commonConstants.GetJASTeamScheduleDetails + data).subscribe({
      next: (responseData: any) => {
        if(responseData?.result?.isSuccess){
          this.teamList = responseData.result.jasTeamScheduleDetailsData;
          if(this.teamList.length){
            this.isScheduleConfirmed = (this.teamList[0].conforM_FLAG == 'Y' ? true : false);
            //this.isCommonSplitFlag = (this.teamList[0].commoN_SPLIT_FLAG == 'Y' ? true : false);
          } else {
            this.isScheduleConfirmed = false;
          }
        } else {
          this.teamList = [];
        }
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  resetForm(){
    this.teamForm.reset();
    this.submitted = false;
    this.secretariatListAddSchedule = [];
  }

  addToSchedule():any {    
    this.submitted = true;
    if(!this.teamForm.valid) {
      return false;  
    }
    let postData = "Flag=SET&";
    postData += "Team=" + this.f.team.value + "&";
    postData += "VHCCode=" + this.f.VHC.value?.vhC_CODE + "&";
    postData += "SecretariatCode=" + this.f.secretariat.value?.secretariaT_CODE + "&";    
    postData += "VenueType=" + this.f.VenueType.value + "&";
    postData += "Venue=" + this.f.Venue.value + "&";
    postData += "PHCCode=" + this.f.PHCCode.value + "&";
    postData += "ScheduleDate=" + moment(this.f.sheduleDate.value,"DD-MM-YYYY").format("DD/MM/YYYY");

    this.commonService.getRequest(this.commonConstants.GetJASTeamScheduleDetails + postData).subscribe({
      next: (responseData: any) => {
        if(responseData?.result?.isSuccess) {
          this.commonService.commonErrorPopup('Alert', 'The team schedule is saved successfully.', 'md');
          this.getScheduleDetails();
          this.resetForm();
        } else if(responseData?.result?.isSuccess == false && responseData?.result?.error) {
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md')
        } else {
          this.commonService.commonErrorPopup('Alert', 'An error occured while saving the schedule. Please try again.', 'md')
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  } 

  edit(item: any, editModal: any) {
    this.isEditSubmitted = false;
    this.selectedSchedule = {...item};
    this.editModal = this.modalService.open(editModal, { size: 'md', centered: true, backdrop: 'static'});
  }

  update():any {    
    this.isEditSubmitted = true;
    if(!this.selectedSchedule.venuE_TYPE || !this.selectedSchedule.venuE_ADDRESS.trim()) {
      return false;  
    }
    let postData = "Flag=SET VENUE&";
    postData += "VHCCode=" + this.selectedSchedule.vhC_CODE + "&";
    postData += "SecretariatCode=" + this.selectedSchedule.secretariaT_CODE + "&";
    postData += "VenueType=" + this.selectedSchedule.venuE_TYPE + "&";
    postData += "Venue=" + this.selectedSchedule.venuE_ADDRESS;

    this.commonService.getRequest(this.commonConstants.GetJASTeamScheduleDetails + postData).subscribe({
      next: (responseData: any) => {
        this.editModal.close();
        if(responseData?.result?.isSuccess) {
          this.commonService.commonErrorPopup('Alert', 'The team schedule is updated successfully.', 'md');
          this.getScheduleDetails();
          this.selectedSchedule = null;
          this.isEditSubmitted = false;
        } else if(responseData?.result?.isSuccess == false && responseData?.result?.error) {
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md')
        } else {
          this.commonService.commonErrorPopup('Alert', 'An error occured while updating the schedule. Please try again.', 'md')
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
  } 

  
  openConfirmModal(confirmmodal: any, vhccode:any, scheduleDate: any, secretariatCode: any) { // Add log out function here
    this.selectedvhccode = vhccode;
    this.selectedscheduleDate = scheduleDate;
    this.selectedsecretariatCode = secretariatCode;    
    this.confirmModal = this.modalService.open(confirmmodal, { size: 'md', centered: true, backdrop: 'static'});
  }

  deleteSchedule() {   
    if(!this.selectedvhccode || !this.selectedscheduleDate) { return; }
    this.confirmModal.close();   
    let postData = "Flag=DELETE&";
    postData += "VHCCode=" + this.selectedvhccode + "&";
    postData += "SecretariatCode=" + this.selectedsecretariatCode + "&";
    postData += "ScheduleDate=" + this.selectedscheduleDate;

    this.commonService.getRequest(this.commonConstants.GetJASTeamScheduleDetails + postData).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.commonService.commonErrorPopup('Alert', 'The team schedule is deleted successfully.', 'md');
          this.getScheduleDetails();
        } else if(responseData.result?.isSuccess == false && responseData.result?.error) {
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md')
        } else {
          this.commonService.commonErrorPopup('Alert', 'An error occured while delete the schedule. Please try again.', 'md')
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });
    this.selectedvhccode = "";
    this.selectedscheduleDate = "";
  }

  onChangeVHC() {
    this.f.PHCCode.patchValue( this.f.VHC.value?.phC_CODE);
    this.f.PHC.patchValue( this.f.VHC.value?.phC_NAME);
    this.f.sheduleDate.reset();
    this.f.secretariat.reset();
    //this.f.Doctor.patchValue( this.f.VHC.value?.mO_NAME);
    this.enableDisableDates();
    this.getIntialSecretariatList();

  }

  getIntialSecretariatList() {
    this.secretariatListAddSchedule = [];
    let data = "Flag=GET SECRETARIAT&";
    data += "VHCCode=" + this.f.VHC.value?.vhC_CODE;
    this.commonService.getRequest(this.commonConstants.GetJASTeamScheduleDetails + data).subscribe({      next: (responseData: any) => {
        if(responseData?.result?.isSuccess){
          this.secretariatListAddSchedule = responseData.result?.jasTeamScheduleDetailsData;
        }
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  enableDisableDates() {
    this.allowedDates = [];
    let postData = "Flag=GET DATES&";
    postData += "VHCCode=" + this.f.VHC.value?.vhC_CODE;
    this.commonService.getRequest(this.commonConstants.GetJASTeamScheduleDetails + postData).subscribe({
      next: (responseData: any) => {
        if(responseData?.result?.isSuccess){
          responseData?.result?.jasTeamScheduleDetailsData.forEach((item:any) => {
            this.allowedDates.push(item.alloweD_DATE);
          });           
          this.isDisabled = (date: NgbDateStruct) => {
            let dt = moment(new Date(date.year,date.month-1, date.day),"DD-MM-YYYY").format("YYYY-MM-DD");
            return this.allowedDates.indexOf(dt + "T00:00:00") >= 0? false : true;
          };
        }
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }
 
  printSchedule() {
    let Pagelink = 'about:blank';
    var pwa = window.open(Pagelink, '_new');
    pwa?.document.open();
    pwa?.document.write(this.preparePrintFormat());
    pwa?.document.close();
  }

  preparePrintFormat() {
    const printContent: HTMLElement | null =
      document.getElementById('AarogyaTeamSchedule');
    // const teamMemberDetails: HTMLElement | null =
    // document.getElementById('teamMemberDetails');
    let htmlData: any = '';
    let printdata = printContent?.innerHTML;
    // let printTeamData = teamMemberDetails?.innerHTML;
    htmlData +=
      '<html><head><title>Team Schedule Details</title> <link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">';
    htmlData +=
      `<style type='text/css' media='print'> @media print{@page{size: auto; margin: 0;} 
      .tableCls td, .tableCls th {padding: 5px;border: 1px solid #ccc;} table {width: 100%;} body{margin: 15px;}
        </style> <script>function step1(){setTimeout('step2()', 2);}function step2(){window.print(); window.close()}</script></head><body onload='step1()'>`;

    htmlData += printdata;
    // htmlData += printTeamData;
    htmlData += '</body></html>';

    return htmlData;
  }

  openConfirmScheduleModal1(confirmmodal: any) {   
    this.confirmModal1 = this.modalService.open(confirmmodal, { size: 'md', centered: true, backdrop: 'static'});
  }

  openConfirmScheduleModal2(confirmmodal: any) {  
    this.confirmModal1.close();    
    this.confirmModal2 = this.modalService.open(confirmmodal, { size: 'md', centered: true, backdrop: 'static'});
  }

  confirmSchedule() {
    this.confirmModal2.close(); 
    let data = "Flag=SET CONFORMATION";
    this.commonService.getRequest(this.commonConstants.GetJASTeamScheduleDetails + data).subscribe({
      next: (responseData: any) => {
        if(responseData.result?.isSuccess) {
          this.commonService.commonErrorPopup('Alert', 'The team schedules are confirmed.', 'md');
          this.isScheduleConfirmed = true;
        } else if(responseData.result?.isSuccess == false && responseData.result?.error) {
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md')
        } else {
          this.commonService.commonErrorPopup('Alert', 'An error occured while confirm the schedules. Please try again.', 'md')
        }
      },
      error: (error) => {
        console.log('client side', error);
      },
    });   
  }
  openCampDetails(item: any, campDetailsModal:any) {
    this.campStartDetailsForm.enable();
    this.campEndDetailsForm.enable();
    this.campStartDetailsForm.reset();
    this.campEndDetailsForm.reset();
    this.isCampDetailsSubmitted = false;
    this.campStartPhotoLabelValue = '';
    this.campEndPhotoLabelValue = '';
    this.docAttendanceSheetLabelValue = '';
    this.campDetailsPopup = this.modalService.open(campDetailsModal, { size: 'xl', centered: true, backdrop: 'static'});
    this.isCampStarted = item.camP_FLAG === 'S' ? true : false;
    this.isCampEnded = item.camP_FLAG === 'E' ? true : false;
    this.isCampClosed = item.camP_FLAG === 'V' ? true : false;
    this.selectedSchedule = {...item};
    if(this.isCampClosed) {
      this.getCampDetails(this.selectedSchedule)
    }
  }
  onCampStartPhotoSelected(fileEvent: any) {
    const campStartFile: File | null = fileEvent.target.files[0];
    this.campStartPhotoLabelValue = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
    this.f1.campStartPhoto.setValidators([Validators.required, fileExtensionValidator('jpg,jpeg,png'), fileSizeValidator(fileEvent)])
    this.f1.campStartPhoto.updateValueAndValidity();
    if (this.f1.campStartPhoto.valid){
      this.fileUpload(campStartFile,this.f1.campStartPhoto.value,fileEvent.target.files[0].name,'campStartPhoto', 'campStartPhoto', 'campStartPhotoLabel');
    }
  }
  onDocAttendanceSheetSelected(fileEvent: any) {
    const docSheetFile: File | null = fileEvent.target.files[0];
    this.docAttendanceSheetLabelValue = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
    this.f1.docAttendanceSheet.setValidators([Validators.required, fileExtensionValidator('jpg,jpeg,png'), fileSizeValidator(fileEvent)])
    this.f1.docAttendanceSheet.updateValueAndValidity();
    if (this.f1.docAttendanceSheet.valid){
      this.fileUpload(docSheetFile,this.f1.docAttendanceSheet.value,fileEvent.target.files[0].name,'docAttendanceSheet', 'docAttendanceSheet', 'docAttendanceSheetLabel');
    }
  }
  onCampEndPhotoSelected(fileEvent: any) {
    const campEndFile: File | null = fileEvent.target.files[0];
    this.campEndPhotoLabelValue = fileEvent.target.files.length !==0 ? fileEvent.target.files[0]?.name: 'No File Choosen';
    this.f2.campEndPhoto.setValidators([Validators.required, fileExtensionValidator('jpg,jpeg,png'), fileSizeValidator(fileEvent)])
    this.f2.campEndPhoto.updateValueAndValidity();
    if (this.f2.campEndPhoto.valid){
      this.fileUpload(campEndFile,this.f2.campEndPhoto.value,fileEvent.target.files[0].name,'campEndPhoto', 'campEndPhoto', 'campEndPhotoLabel');
    }
  }
  clearCampStartPhotoUpload(name:string, label:string) {
    if (label === 'campStartPhotoLabel') {
      this.f1[name].reset();
      this.campStartPhotoLabelValue = 'No File Choosen';
    } else if (label === 'docAttendanceSheetLabel') {
      this.f1[name].reset();
      this.docAttendanceSheetLabelValue = 'No File Choosen';
    } else if (label === 'campEndPhotoLabel') {
      this.f2[name].reset();
      this.campEndPhotoLabelValue = 'No File Choosen';
    }
  }

  fileUpload(file: any, filePath: string, fileName: string, documentType: string, name:string, label:string)  {
    const formData = new FormData();
      formData.append('File', file, fileName);
      formData.append('FileBlobMeta', "{key1: 'value1', key2: 'value2'}");
      console.log( formData,'formData');
    this.commonService.postUploadedFileRequest(this.commonConstants.fileUpload,formData).subscribe({
      next: (responseData:any) => {
        if(typeof(responseData.result) !== 'string'){
          if(label === 'campStartPhotoLabel')  {
            this.campStartPhoto  = {
              FileName: fileName,
              DocumentType: documentType,
              BlobRef : responseData['result'].blobRef
            };
          } else if(label === 'docAttendanceSheetLabel')  {
            this.docAttendanceSheet  = {
              FileName: fileName,
              DocumentType: documentType,
              BlobRef : responseData['result'].blobRef
            };
          } else if(label === 'campEndPhotoLabel')  {
            this.campEndPhoto  = {
              FileName: fileName,
              DocumentType: documentType,
              BlobRef : responseData['result'].blobRef
            };
          }
         
        }else{
          if(name && label) {
            this.clearCampStartPhotoUpload(name, label);
          }
            this.commonService.commonErrorPopup('Alert', responseData.result, 'md');
        }
      },
      error: (error) => {
        if(name && label) {
          this.clearCampStartPhotoUpload(name, label);
        }
        console.log('client side', error);
      }
    });
  }
  downloadFile(blobRef:any, fileName:any) {
    this.commonService.getRequest(this.commonConstants.viewFile + blobRef).subscribe({
      next: (responseData: any) => { 
        let fileInfo =  responseData.result.fileContents;
        if (fileName === 'campStart') {
          this.campStartImg = 'data:image/png;base64,' +fileInfo;
        } else if (fileName === 'campEnd') {
          this.campEndImg = 'data:image/png;base64,' +fileInfo;
        } else {
          this.docSheetImg = 'data:image/png;base64,' +fileInfo;
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  updateCampStartDetails():any {    
    this.isCampDetailsSubmitted = true;
    if(!this.campStartDetailsForm.valid){
      return false;
    }
    let postData = "Flag=SET START&";
    postData += "VHCCode=" + this.selectedSchedule.vhC_CODE + "&";
    postData += "SecretariatCode=" + this.selectedSchedule.secretariaT_CODE + "&";
    postData += "StartFlag=" + this.f1.campStartStatus.value + "&";
    postData += "CampStartPhoto=" + this.campStartPhoto?.BlobRef + "&";
    postData += "DocAttendSheetPhoto=" + this.docAttendanceSheet?.BlobRef + "&";
    postData += "FemaleDocFlag=" + this.f1.isFemaleDocPresent.value + "&";
    postData += "NoOfRooms=" + this.f1.totalRooms.value + "&";
    postData += "WaitRoomFlag=" + this.f1.waitingRoomAvail.value + "&";
    postData += "PrivateRoomFlag=" + this.f1.privateRoomAvail.value + "&";
    postData += "HelpRegDskFlag=" + this.f1.helpdeskRegAvail.value + "&";
    postData += "MedKitsFlag=" + this.f1.medicationKits.value + "&";
    postData += "JasFolderFlag=" + this.f1.JASFoldersAvail.value + "&";
    postData += "JasCaseSheetFlag=" + this.f1.JASCaseSheetAvail.value + "&";
    postData += "JasBagFlag=" + this.f1.JASBagsAvail.value + "&";
    postData += "JasBannerFlag=" + this.f1.JASBannerAvail.value + "&";
    postData += "EyeTestFlag=" + this.f1.eyeTestAvail.value + "&";
    postData += "FacilityFlag=" + this.f1.basicFacilitiesAvail.value + "&";
    postData += "MaskSanitizerFlag=" + this.f1.maskSanitizerAvail.value + "&";
    postData += "PatientStartCount=" + this.f1.patientsCount.value + "&"
    postData += "SpecialistsAttendedCnt=" + this.f1.specialistAttendCount.value  + "&"
    postData += "DoctorsAttendedCnt=" + this.f1.doctorsAttended.value  

    this.commonService.getRequest(this.commonConstants.GetJASTeamScheduleDetails + postData).subscribe({
      next: (responseData: any) => {
        this.campDetailsPopup.close();
        if(responseData?.result?.isSuccess) {
          this.commonService.commonErrorPopup('Alert', 'The team schedule is started successfully.', 'md');
          this.getScheduleDetails();          
        } else if(responseData?.result?.isSuccess == false && responseData?.result?.error) {
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md')
        } else {
          this.commonService.commonErrorPopup('Alert', 'An error occured while updating the schedule. Please try again.', 'md')
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  updateCampEndDetails():any {    
    this.isCampDetailsSubmitted = true;
    if(!this.campEndDetailsForm.valid){
      return false;
    }
    let postData = "Flag=SET END&";
    postData += "VHCCode=" + this.selectedSchedule.vhC_CODE + "&";
    postData += "SecretariatCode=" + this.selectedSchedule.secretariaT_CODE + "&";
    postData += "PatientAttendCount=" + this.f2.totalPatientsCount.value + "&";
    postData += "CampEndPhoto=" + this.campEndPhoto?.BlobRef + "&";
    postData += "CampSanitizationFlag=" + this.f2.sanitizationOfVenue.value + "&";
    postData += "TotalOPCnt=" + this.f2.totalOPs.value + "&";
    postData += "TotalWomenOPCnt=" + this.f2.totalWomenOPs.value + "&";
    postData += "TotalMenOPCnt=" + this.f2.totalMenOPs.value + "&";
    postData += "SclChildCnt=" + this.f2.noOfSchoolChilds.value + "&";
    postData += "SamMamAwcCnt=" + this.f2.noOfAnganwadiChilds.value + "&";
    postData += "PregLactMotherCnt=" + this.f2.noOfPregnants.value + "&";
    postData += "EyeScrngCnt=" + this.f2.noOfEyeScreenings.value + "&";
    postData += "SpecsDistributed=" + this.f2.noOfSpectacles.value + "&";
    postData += "NcdCdSessionFlag=" + this.f2.awarenessSession.value + "&";
    postData += "AwarenessCampFlag=" + this.f2.awarenessAarogyasri.value + "&";
    postData += "SessionByAnmFlag=" + this.f2.sessionByANM.value + "&";
    postData += "StallByAwcFlag=" + this.f2.nutritionStall.value + "&";
    postData += "CitizRcvdMedCnt=" + this.f2.noOfCitizensCampEnd.value + "&";
    postData += "DocPresentCnt=" + this.f2.noOfDoctorsCampEnd.value + "&";
    postData += "SpecialistPrsntCnt=" + this.f2.noOfSpecialistCampEnd.value ;
 
    this.commonService.getRequest(this.commonConstants.GetJASTeamScheduleDetails + postData).subscribe({
      next: (responseData: any) => {
        this.campDetailsPopup.close();
        if(responseData?.result?.isSuccess) {
          this.commonService.commonErrorPopup('Alert', 'The team schedule is ended successfully.', 'md');
          this.getScheduleDetails();          
        } else if(responseData?.result?.isSuccess == false && responseData?.result?.error) {
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md')
        } else {
          this.commonService.commonErrorPopup('Alert', 'An error occured while updating the schedule. Please try again.', 'md')
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  getCampDetails(data:any) {
    this.campDetails = [];
    let postData = "Flag=GET CAMP DETAILS&";
    postData += "VHCCode=" + this.selectedSchedule.vhC_CODE;
    this.commonService.getRequest(this.commonConstants.GetJASTeamScheduleDetails + postData).subscribe({
      next: (responseData: any) => {
        if(responseData?.result?.isSuccess){
          let campViewResponse: any = responseData.result.jasTeamScheduleDetailsData;
          this.campDetails = campViewResponse.filter((item: any) => item.vhC_CODE == this.selectedSchedule.vhC_CODE);
          this.bindCampDetails(this.campDetails[0]);
          this.downloadFile(this.campDetails[0]?.camP_START_PHOTO, 'campStart');
          this.downloadFile(this.campDetails[0]?.camP_END_PHOTO, 'campEnd');  
          this.downloadFile(this.campDetails[0]?.doC_ATTEND_SHEET_PHOTO, 'docSheet');    
        } else {
          this.campDetails = [];
        }
       },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }
  bindCampDetails(data:any) {
    this.campStartDetailsForm.patchValue({
      campStartStatus: data?.starT_FLAG,
      campStartPhoto: '',
      docAttendanceSheet: '',
      isFemaleDocPresent: data?.femalE_DOC_FLAG,
      totalRooms: data?.nO_OF_ROOMS,
      waitingRoomAvail: data?.waiT_ROOM_FLAG,
      privateRoomAvail: data?.prvT_ROOM_FLAG,
      helpdeskRegAvail: data?.helP_REG_DSK_FLAG,
      medicationKits: data?.meD_KITS_FLAG,
      JASFoldersAvail: data?.jaS_FOLDER_FLAG,
      JASCaseSheetAvail: data?.jaS_CASE_SHEET_FLAG,
      JASBagsAvail: data?.jaS_BAG_FLAG,
      JASBannerAvail: data?.jaS_BANNER_FLAG,
      eyeTestAvail: data?.eyE_TEST_FLAG,
      basicFacilitiesAvail: data?.facilitY_FLAG,
      maskSanitizerAvail: data?.masK_SANITIZER_FLAG,
      patientsCount: data?.patienT_STRAT_COUNT,
      doctorsAttended: data?.toT_DOCTOR_ATTENDED ,
      specialistAttendCount: data?.toT_SPECIALIST_ATTENDED
    });
    this.campEndDetailsForm.patchValue({
      totalPatientsCount: data?.patienT_ATTEND_COUNT,
      campEndPhoto: '',
      sanitizationOfVenue: data?.camP_SANITIZATION_FLAG,
      totalOPs: data?.toT_OPS_COUNT,
      totalWomenOPs: data?.toT_WOMEN_OPS,
      totalMenOPs: data?.toT_MEN_OPS,
      noOfSchoolChilds: data?.scL_CHILDREN_CNT,
      noOfAnganwadiChilds: data?.saM_MAM_AWC_CNT,
      noOfPregnants: data?.preG_LACT_MOTHER_CNT,
      noOfEyeScreenings: data?.eyE_SCREENING_CNT,
      noOfSpectacles: data?.specS_DISTRIBUTED_CNT,
      awarenessSession: data?.ncD_CD_SESSION_FLAG,
      awarenessAarogyasri: data?.awrnS_CAMP_FLAG,
      sessionByANM: data?.anM_SESSION_FLAG,
      nutritionStall: data?.stalL_BY_AWC_FLAG,
      noOfCitizensCampEnd: data?.toT_CITIZEN_RCVD_MEDICATION,
      noOfDoctorsCampEnd: data?.toT_DOCTORS_PRESENT,
      noOfSpecialistCampEnd: data?.toT_SPECIALISTS_PRESENT
    });
    this.campEndDetailsForm.disable();
    this.campStartDetailsForm.disable();
  }
  closeCampDetailsPopup() {
    this.campDetailsPopup.close();
    this.campStartDetailsForm.reset();
    this.campEndDetailsForm.reset();
    this.isCampDetailsSubmitted = false;
    this.campStartPhotoLabelValue = '';
    this.campEndPhotoLabelValue = '';
    this.docAttendanceSheetLabelValue = '';
  }

  openCampSplitDetails(campSplitModal: any) {
    this.splitCampForm.reset();
    this.secretariatList = [];
    this.isSplitSubmitted = false;
    this.getSplitVHC();
    this.campSplitModal = this.modalService.open(campSplitModal, { size: 'xl', centered: true, backdrop: 'static'});
  }

  splitCamp() {
    this.isSplitSubmitted = true; 
    if(!this.splitCampForm.valid) {
      return;
    }    

    let postData = "Flag=SPLIT&";
    postData += "Team1=" + this.f3.team.value + "&";
    postData += "VHCCode=" + this.f3.VHC.value?.vhC_CODE + "&";
    postData += "VenueType1=" + this.f3.VenueType.value + "&";
    postData += "VenueAddr1=" + this.f3.Venue.value + "&";
    postData += "SecCodeList1=" + this.f3.secretariat.value + "&";
    postData += "ScheduleDate1=" + moment(this.f3.sheduleDate.value,"DD-MM-YYYY").format("DD/MM/YYYY");

    this.commonService.getRequest(this.commonConstants.GetJASVHCScheduleSplitDetails + postData).subscribe({
      next: (responseData: any) => {
        if(responseData?.result?.isSuccess) {
          this.commonService.commonErrorPopup('Alert', 'The team schedule is splitted successfully.', 'md');
          //this.campSplitModal.close();
          this.splitCampForm.reset();
          this.secretariatList = [];
          this.isSplitSubmitted = false;
          this.getSplitVHC();
          this.getScheduleDetails();          
        } else if(responseData?.result?.isSuccess == false && responseData?.result?.error) {
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md')
        } else {
          this.commonService.commonErrorPopup('Alert', 'An error occured while splitting the schedule. Please try again.', 'md')
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  getDetails() {
    let locationCode: any = localStorage.getItem('locationCode');
    this.commonService.getRequest(this.commonConstants.getDistrictSecretariatDetailsbyMandal + locationCode).subscribe({
      next: (responseData: any) => {
        this.isUrban = responseData.result?.isRuralUrbanFlag === 'R' ? false : true;       
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  
  onChangeSplitVHC() {
    this.getsecretariatList(this.f3.VHC.value?.vhC_CODE);
  }

  getSplitVHC() {
    this.SplitVHCList = [];
    let data = "Flag=GET PENDING VHCS";
    this.commonService.getRequest(this.commonConstants.GetJASVHCScheduleSplitDetails + data).subscribe({
      next: (responseData: any) => {
        if(responseData?.result?.isSuccess){
          this.SplitVHCList = responseData.result.remarks;
        }
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  getsecretariatList(vhccode: any) {
    this.secretariatList = [];
    let data = "Flag=GET PENDING SECRETARIATES&";
    data += "VHCCode=" + vhccode + "&";
    this.commonService.getRequest(this.commonConstants.GetJASVHCScheduleSplitDetails + data).subscribe({      next: (responseData: any) => {
        if(responseData?.result?.isSuccess){
          this.secretariatList = responseData.result?.remarks;
        }
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

  getSplitTeams() {
    let data = "Flag=GET TEAM";
    this.commonService.getRequest(this.commonConstants.GetJASVHCScheduleSplitDetails + data).subscribe({
      next: (responseData: any) => {
        if(responseData?.result?.isSuccess){
          this.teams = responseData.result.remarks;
        } else {
          this.teams = [];
        }
       },
      error: (error) => {
        console.log('client side', error);
      },
    });
  }

}