<div class="reportContainer">
    <div [formGroup]="form">
    <div class="row mb-3">
        
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="district" (change)="onDistrictChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
         <option value="All">All</option>
        <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
      </select>
    </div>

    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Rural/Urban : <span class="mandatoryCls">*</span></label> 
        <select class="selectCls" required formControlName="RUFlag" (change)="onRuralUrbanChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.RUFlag.errors || f.RUFlag.value === 'Select') }">
        <option value = "All">All</option>
        <option value = "R">Rural</option>
        <option value = "U">Urban</option>
        </select>
    </div>


<div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
    <label class="labelCls">Mandal/Municipality:  <span class="mandatoryCls">*</span></label>
    <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
    [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
    <option value="All">All</option>
    <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
  </select>
</div>
  
   
   <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <label class="labelCls"> PHC <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="phc" (change)= "onPHCChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.phc.errors || f.phc.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let phc of phcs" [ngValue]="phc">{{phc.phcName}}</option>
      </select>
    </div>
    </div>

<div class="row mb-3">
    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls"> VHC <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="vhc" (change)="onVHCChange()"
          [ngClass]="{ 'is-invalid':submitted && (f.vhc.errors || f.vhc.value === 'Select') }">
          <option value="All">All</option>
          <option *ngFor="let vhc of vhcs" [ngValue]="vhc">{{vhc.vhcName}}</option>
        </select>
      </div>
</div>

  <div class="tabClass">
        <ul class="nav nav-pills flex-column flex-sm-row"  class="navbar navbar-expand-lg navbar-light "> 
              <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li>
              </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)=" GetArogyaSurakshaReportData()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
        </div>
      </div>
</div>
<div class="table-responsive tableFixHead" *ngIf="showReports">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th [attr.colspan]="noOfCol">Aarogya Suraksha 2.0 Scheduling Status Reports</th>
            </tr>
            <tr>
               <th rowspan="3">S.No</th>
               <th rowspan="3" *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'did'">District Code<mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3"  *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_MANDALS'">No Of Mandals/ULBs <mdb-icon fas icon="sort"></mdb-icon></th>
               <th rowspan="3" *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mid'">Mandal Code<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3"  *ngIf="format === 'Mandal Wise' || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal/ULB <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3"  *ngIf="format === 'Mandal Wise' || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rurban'">Rural/Urban <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3"  *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_PHCS'">No. of PHCs <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3"   *ngIf="format === 'District Wise'|| format === 'Mandal Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_VHCS'">No. of VHCs/Urban Secretariates <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3"  *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_TEAMS'">No. of Teams Formed<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3"   *ngIf="format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_TEAMS'">No. of Teams <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3" *ngIf="format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'shD_COMP'">Scheduling Completed(Yes/No) <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3" *ngIf="format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_CONFORM'">Confirmation Done(Yes/No) <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3"  *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'dmhO_CONFORM_FLAG'">DMHO Confirmation Given(Yes/No)<mdb-icon fas icon="sort"></mdb-icon></th>
               <th colspan="6" *ngIf="format === 'District Wise'|| format === 'Mandal Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'nO_OF_VHCS'">No. of VHCs/Urban Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
               <th colspan="4" *ngIf="format === 'District Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_MANDALS'">No of Mandals/ULBs<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'phC_CODE'">PHC Code<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'phC_NAME'">PHC<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'vhC_CODE'">VHC Code<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'vhC_NAME'">VHC /UHC<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'seC_CODES'">Secretariat Codes<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'scheduleD_DATE'" >Scheduled Date<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_LEVEL_CONFORM'">Mandal Level Confirmation Done(Yes/No)<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'distircT_LEVEL_CONFORM'">District Level Confirmation Done(Yes/No)<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'teaM_NAME'">Assigned Team<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'venuE_TYPE'">Venue Type<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'venuE_ADDRESS'" >Venue Address<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'doctoR_1_NAME'" >Doctor1 Name<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'doctoR_2_NAME'" >Doctor2 Name<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'doctoR_3_NAME'" >Doctor3 Name<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'ophT_ASSIST_NAME'" >Ophthalmologist Name<mdb-icon fas icon="sort"></mdb-icon></th>
               <tr>
                <th rowspan="2"  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pendinG_VHC'">Scheduling Pending</th>
                <th colspan="5"  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'comP_VHC'">Scheduling Completed</th>
                <th rowspan="2" *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pendinG_MANDALS'">Scheduling Pending</th>
                <th colspan="3"  *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'shD_COMP'">Scheduling Completed</th>
               </tr>
               <tr>
                <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'comP_VHC'" >Total<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'doctoR_VHC'">Doctors Tagged<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_DOCTOR_VHC'" >Doctors Not Tagged yet<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'ophT_ASSIST_VHC'">Ophthalmologist Tagged<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OPHT_ASSIST_VHC'" >Ophthalmologist Not Tagged yet<mdb-icon fas icon="sort"></mdb-icon></th>
                <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'totaL_SCHD_COMP_MANDALS'">Total <mdb-icon fas icon="sort"></mdb-icon></th>
                <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandalS_CONFORMED'">Confirmation Given <mdb-icon fas icon="sort"></mdb-icon></th>
                <th *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'conforM_PENDING_MANDALS'">Confirmation Pending <mdb-icon fas icon="sort"></mdb-icon></th>
               </tr>
              </thead>

        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'VHC Wise'">{{ ((page -1) * pageSize) + (i + 1)}}</td>
                <td *ngIf="format === 'VHC Wise'">{{item.did}}</td>
                <td  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'VHC Wise'" >{{item.districT_NAME}}</td>
                <td  *ngIf="format === 'District Wise'" >{{item.nO_OF_MANDALS}}</td>
                <td *ngIf="format === 'VHC Wise'">{{item.mid}}</td>
                <td  *ngIf="format === 'Mandal Wise'|| format === 'VHC Wise'">{{item.mandaL_NAME}}</td>
                <td  *ngIf="format === 'Mandal Wise'|| format === 'VHC Wise'">{{item.rurban}}</td>
                <td  *ngIf=" format === 'District Wise' || format === 'Mandal Wise'">{{item.nO_OF_PHCS}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.nO_OF_VHCS}}</td>
                <td *ngIf="format === 'District Wise'">{{item.nO_OF_TEAMS}}</td>
                <td *ngIf="format === 'District Wise'">{{item.dmhO_CONFORM_FLAG}}</td>
                <td *ngIf="format === 'Mandal Wise'">{{item.nO_OF_TEAMS}}</td>
                <td *ngIf="format === 'Mandal Wise'">{{item.shD_COMP}}</td>
               <td *ngIf="format === 'Mandal Wise'">{{item.mandaL_CONFORM}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.phC_CODE}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.phC_NAME}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.vhC_CODE}}</td>
                <td *ngIf="format === 'VHC Wise'">{{item.vhC_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{item.seC_CODES}}</td>
                <td *ngIf="format === 'VHC Wise'">{{item.scheduleD_DATE}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.mandaL_LEVEL_CONFORM}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.distircT_LEVEL_CONFORM}}</td>
                <td *ngIf="format === 'VHC Wise'">{{item.teaM_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{item.venuE_TYPE}}</td>
                <td *ngIf="format === 'VHC Wise'">{{item.venuE_ADDRESS}}</td>
                <td *ngIf="format ==='VHC Wise'">{{item.doctoR_1_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{item.doctoR_2_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{item.doctoR_3_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{item.ophT_ASSIST_NAME}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.pendinG_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.comP_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.doctoR_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.nO_DOCTOR_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.ophT_ASSIST_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.nO_OPHT_ASSIST_VHC}}</td>
                <td *ngIf="format === 'District Wise'">{{item.pendinG_MANDALS}}</td>
                <td *ngIf="format === 'District Wise'">{{item.totaL_SCHD_COMP_MANDALS}}</td>
                <td *ngIf="format === 'District Wise'">{{item.mandalS_CONFORMED}}</td>
                <td *ngIf="format === 'District Wise'">{{item.conforM_PENDING_MANDALS}}</td>
                </tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].did}}</td>
                <td  *ngIf="format === 'District Wise' || format === 'Mandal Wise'|| format === 'VHC Wise'" >{{totalItems[0].districT_NAME}}</td>
                <td  *ngIf="format === 'District Wise'" >{{totalItems[0].nO_OF_MANDALS}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].mid}}</td>
                <td  *ngIf="format === 'Mandal Wise'|| format === 'VHC Wise'">{{totalItems[0].mandaL_NAME}}</td>
                <td  *ngIf="format === 'Mandal Wise'|| format === 'VHC Wise'">{{totalItems[0].rurban}}</td>
                <td  *ngIf="format === 'Mandal Wise' || format === 'District Wise'">{{totalItems[0].nO_OF_PHCS}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].nO_OF_VHCS}}</td>
                <td *ngIf="format === 'District Wise'">{{totalItems[0].nO_OF_TEAMS}}</td>
                <td *ngIf="format === 'District Wise'">{{totalItems[0].dmhO_CONFORM_FLAG}}</td>
                <td *ngIf="format === 'Mandal Wise'">{{totalItems[0].nO_OF_TEAMS}}</td>
                <td *ngIf="format === 'Mandal Wise'">{{totalItems[0].shD_COMP}}</td>
                <td *ngIf="format === 'Mandal Wise'">{{totalItems[0].mandaL_CONFORM}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].phC_CODE}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].phC_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].vhC_CODE}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].vhC_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].seC_CODES}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].scheduleD_DATE}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].mandaL_LEVEL_CONFORM}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].distircT_LEVEL_CONFORM}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].teaM_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].venuE_TYPE}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].venuE_ADDRESS}}</td>
                <td *ngIf="format ==='VHC Wise'">{{totalItems[0].doctoR_1_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].doctoR_2_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].doctoR_3_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].ophT_ASSIST_NAME}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].pendinG_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].comP_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].doctoR_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].nO_DOCTOR_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].ophT_ASSIST_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].nO_OPHT_ASSIST_VHC}}</td>
                <td *ngIf="format === 'District Wise'">{{totalItems[0].pendinG_MANDALS}}</td>
                <td *ngIf="format === 'District Wise'">{{totalItems[0].totaL_SCHD_COMP_MANDALS}}</td>
                <td *ngIf="format === 'District Wise'">{{totalItems[0].mandalS_CONFORMED}}</td>
                <td *ngIf="format === 'District Wise'">{{totalItems[0].conforM_PENDING_MANDALS}}</td>
            </tr>
            </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    <div class="mt-4 btnHide">
        <div class="float-left" *ngIf="items.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
</div>
</div>
<div class="table-responsive tableFixHead" #TABLE class="hideTable">
   <table class="tableCls" >
    <thead> 
        <tr>
            <th [attr.colspan]="noOfCol">Aarogya Suraksha 2.0 Scheduling Status Reports</th>
        </tr>
        <tr>
            <th rowspan="3">S.No</th>
            <th rowspan="3" *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'did'">District Code<mdb-icon fas icon="sort"></mdb-icon></th>
            <th rowspan="3" *ngIf="format === 'District Wise'|| format === 'Mandal Wise' || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District <mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3"  *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_MANDALS'">No Of Mandals/ULBs <mdb-icon fas icon="sort"></mdb-icon></th>
            <th rowspan="3" *ngIf="format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mid'">Mandal Code<mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3"  *ngIf="format === 'Mandal Wise' || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal/ULB <mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3"  *ngIf="format === 'Mandal Wise' || format === 'VHC Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rurban'">Rural/Urban <mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3"  *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_PHCS'">No. of PHCs <mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3"   *ngIf="format === 'District Wise'|| format === 'Mandal Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_VHCS'">No. of VHCs/Urban Secretariates <mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3"  *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_TEAMS'">No. of Teams Formed<mdb-icon fas icon="sort"></mdb-icon></th>
           <th  rowspan="3"   *ngIf="format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_TEAMS'">No. of Teams <mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3" *ngIf="format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'shD_COMP'">Scheduling Completed(Yes/No) <mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3" *ngIf="format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_CONFORM'">Confirmation Done(Yes/No) <mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3"  *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'dmhO_CONFORM_FLAG'">DMHO Confirmation Given(Yes/No)<mdb-icon fas icon="sort"></mdb-icon></th>
           <th colspan="6" *ngIf="format === 'District Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'nO_OF_VHCS'">No. of VHCs/Urban Secretariates<mdb-icon fas icon="sort"></mdb-icon></th>
            <th colspan="4" *ngIf="format === 'District Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OF_MANDALS'">No of Mandals/ULBs<mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'phC_CODE'">PHC Code<mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'phC_NAME'">PHC<mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'vhC_CODE'">VHC Code<mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'vhC_NAME'">VHC /UHC<mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'seC_CODES'">Secretariat Codes<mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'scheduleD_DATE'" >Scheduled Date<mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_LEVEL_CONFORM'">Mandal Level Confirmation Done(Yes/No)<mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'distircT_LEVEL_CONFORM'">District Level Confirmation Done(Yes/No)<mdb-icon fas icon="sort"></mdb-icon></th>
           <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'teaM_NAME'">Assigned Team<mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'venuE_TYPE'">Venue Type<mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'venuE_ADDRESS'" >Venue Address<mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'doctoR_1_NAME'" >Doctor1 Name<mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'doctoR_2_NAME'" >Doctor2 Name<mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'doctoR_3_NAME'" >Doctor3 Name<mdb-icon fas icon="sort"></mdb-icon></th>
            <th  rowspan="3" *ngIf="format === 'VHC Wise'"aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'ophT_ASSIST_NAME'" >Ophthalmologist Name<mdb-icon fas icon="sort"></mdb-icon></th>
            <tr>
             <th rowspan="2"  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pendinG_VHC'">Scheduling Pending</th>
             <th colspan="5"  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'comP_VHC'">Scheduling Completed</th>
             <th rowspan="2" *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pendinG_MANDALS'">Scheduling Pending</th>
             <th colspan="3"  *ngIf="format === 'District Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'shD_COMP'">Scheduling Completed</th>
            </tr>
            <tr>
                <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'comP_VHC'" >Total<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'doctoR_VHC'">Doctors Tagged<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_DOCTOR_VHC'" >Doctors Not Tagged yet<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'ophT_ASSIST_VHC'">Ophthalmologist Tagged<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'nO_OPHT_ASSIST_VHC'" >Ophthalmologist Not Tagged yet<mdb-icon fas icon="sort"></mdb-icon></th>
                <th   *ngIf="format === 'District Wise'" width="100px">Total</th>
                <th   *ngIf="format === 'District Wise'" width="100px">Confirmation Given</th>
                <th   *ngIf="format === 'District Wise'" width="100px">Confirmation Pending </th>
            </tr>
        </thead>

       <tbody *ngIf="items && items.length > 0">
           <tr *ngFor="let item of items;let i = index;">
               <td width="100px">{{i + 1}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.did}}</td>
               <td width="100px" *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'VHC Wise'" >{{item.districT_NAME}}</td>
               <td width="100px" *ngIf="format === 'District Wise'" >{{item.nO_OF_MANDALS}}</td>
               <td  width="100px" *ngIf="format === 'VHC Wise'">{{item.mid}}</td>
               <td width="100px" *ngIf="format === 'Mandal Wise'|| format === 'VHC Wise'">{{item.mandaL_NAME}}</td>
               <td width="100px" *ngIf="format === 'Mandal Wise'|| format === 'VHC Wise'">{{item.rurban}}</td>
               <td width="100px" *ngIf=" format === 'District Wise' || format === 'Mandal Wise'">{{item.nO_OF_PHCS}}</td>
               <td width="100px" *ngIf="format === 'Mandal Wise'||format === 'District Wise'">{{item.nO_OF_VHCS}}</td>
               <td width="100px" *ngIf="format === 'District Wise'">{{item.nO_OF_TEAMS}}</td>
               <td width="100px" *ngIf="format === 'District Wise'">{{item.dmhO_CONFORM_FLAG}}</td>
               <td width="100px" *ngIf="format === 'Mandal Wise'">{{item.nO_OF_TEAMS}}</td>
               <td width="100px" *ngIf="format === 'Mandal Wise'">{{item.shD_COMP}}</td>
               <td width="100px" *ngIf="format === 'Mandal Wise'">{{item.mandaL_CONFORM}}</td>
               <td *ngIf="format === 'VHC Wise'">{{item.phC_CODE}}</td>
              <td width="100px" *ngIf="format === 'VHC Wise'">{{item.phC_NAME}}</td>
              <td *ngIf="format === 'VHC Wise'">{{item.vhC_CODE}}</td>
               <td width="100px" *ngIf="format === 'VHC Wise'">{{item.vhC_NAME}}</td>
               <td width="100px" *ngIf="format === 'VHC Wise'">{{item.seC_CODES}}</td>
               <td width="100px" *ngIf="format === 'VHC Wise'">{{item.scheduleD_DATE}}</td>
               <td width="100px" *ngIf="format === 'VHC Wise'">{{item.mandaL_LEVEL_CONFORM}}</td>
               <td  width="100px" *ngIf="format === 'VHC Wise'">{{item.distircT_LEVEL_CONFORM}}</td>
               <td width="100px" *ngIf="format === 'VHC Wise'">{{item.teaM_NAME}}</td>
               <td width="100px" *ngIf="format === 'VHC Wise'">{{item.venuE_TYPE}}</td>
               <td width="100px" *ngIf="format === 'VHC Wise'">{{item.venuE_ADDRESS}}</td>
               <td width="100px" *ngIf="format ==='VHC Wise'">{{item.doctoR_1_NAME}}</td>
               <td width="100px" *ngIf="format === 'VHC Wise'">{{item.doctoR_2_NAME}}</td>
               <td width="100px" *ngIf="format === 'VHC Wise'">{{item.doctoR_3_NAME}}</td>
               <td width="100px" *ngIf="format === 'VHC Wise'">{{item.ophT_ASSIST_NAME}}</td>
               <td width="100px" *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.pendinG_VHC}}</td>
               <td width="100px" *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.comP_VHC}}</td>
               <td width="100px" *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.doctoR_VHC}}</td>
               <td width="100px" *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.nO_DOCTOR_VHC}}</td>
               <td width="100px" *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.ophT_ASSIST_VHC}}</td>
               <td width="100px" *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{item.nO_OPHT_ASSIST_VHC}}</td>
               <td width="100px" *ngIf="format === 'District Wise'">{{item.pendinG_MANDALS}}</td>
               <td width="100px" *ngIf="format === 'District Wise'">{{item.totaL_SCHD_COMP_MANDALS}}</td>
               <td width="100px" *ngIf="format === 'District Wise'">{{item.mandalS_CONFORMED}}</td>
               <td width="100px" *ngIf="format === 'District Wise'">{{item.conforM_PENDING_MANDALS}}</td>
                </tr>
           <tr *ngIf="totalItems && totalItems.length > 0">
            <td></td>
            <td *ngIf="format === 'VHC Wise'">{{totalItems[0].did}}</td>
            <td  *ngIf="format === 'District Wise' || format === 'Mandal Wise'|| format === 'VHC Wise'" >{{totalItems[0].districT_NAME}}</td>
                <td  *ngIf="format === 'District Wise'" >{{totalItems[0].nO_OF_MANDALS}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].mid}}</td>
                <td  *ngIf="format === 'Mandal Wise'|| format === 'VHC Wise'">{{totalItems[0].mandaL_NAME}}</td>
                <td  *ngIf="format === 'Mandal Wise'|| format === 'VHC Wise'">{{totalItems[0].rurban}}</td>
                <td  *ngIf="format === 'Mandal Wise' || format === 'District Wise'">{{totalItems[0].nO_OF_PHCS}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].nO_OF_VHCS}}</td>
                <td *ngIf="format === 'District Wise'">{{totalItems[0].nO_OF_TEAMS}}</td>
                <td *ngIf="format === 'District Wise'">{{totalItems[0].dmhO_CONFORM_FLAG}}</td>
                <td *ngIf="format === 'Mandal Wise'">{{totalItems[0].nO_OF_TEAMS}}</td>
                <td *ngIf="format === 'Mandal Wise'">{{totalItems[0].shD_COMP}}</td>
                <td *ngIf="format === 'Mandal Wise'">{{totalItems[0].mandaL_CONFORM}}</td>
               <td *ngIf="format === 'VHC Wise'">{{totalItems[0].phC_CODE}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].phC_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].vhC_CODE}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].vhC_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].seC_CODES}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].scheduleD_DATE}}</td>
               <td *ngIf="format === 'VHC Wise'">{{totalItems[0].mandaL_LEVEL_CONFORM}}</td>
               <td *ngIf="format === 'VHC Wise'">{{totalItems[0].distircT_LEVEL_CONFORM}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].teaM_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].venuE_TYPE}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].venuE_ADDRESS}}</td>
                <td *ngIf="format ==='VHC Wise'">{{totalItems[0].doctoR_1_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].doctoR_2_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].doctoR_3_NAME}}</td>
                <td *ngIf="format === 'VHC Wise'">{{totalItems[0].ophT_ASSIST_NAME}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].pendinG_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].comP_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].doctoR_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].nO_DOCTOR_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].ophT_ASSIST_VHC}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'">{{totalItems[0].nO_OPHT_ASSIST_VHC}}</td>
                <td *ngIf="format === 'District Wise'">{{totalItems[0].pendinG_MANDALS}}</td>
                <td *ngIf="format === 'District Wise'">{{totalItems[0].totaL_SCHD_COMP_MANDALS}}</td>
                <td *ngIf="format === 'District Wise'">{{totalItems[0].mandalS_CONFORMED}}</td>
                <td *ngIf="format === 'District Wise'">{{totalItems[0].conforM_PENDING_MANDALS}}</td>
           </tr>
       </tbody>
       <tbody *ngIf="items && items.length === 0">
           <tr>
              <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
       </tbody>
   </table>
  </div>
  
