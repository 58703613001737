<div class="certificateInfo" *ngIf="certificateInfo">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Aadhar No: </label>
                <div class="valueCls">{{certificateInfo.applicantAadharNo}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Applicant Name: </label>
                <div class="valueCls">{{certificateInfo.applicantName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Relation With Pattadar:</label>
            <div class="valueCls">{{certificateInfo.relationWithOwner}}</div>
        </div>
        </div>
    </div>
    <div class="subHeadingCls mb-3">Land Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo.landDetails?.districtName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo.landDetails?.mandalMunicipalityName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Village:</label>
            <div class="valueCls">{{certificateInfo.landDetails?.villageWardName}}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Khata Number:</label>
            <div class="valueCls">{{certificateInfo.khataNo}}</div>
        </div>
        </div>
    </div>
    <div class="subHeadingCls mb-3">Adangal Details</div>
    <div class="row mb-3">
        <div class="col">
            <div class="tab table-responsive">
                <table class="tableCls" *ngIf ="certificateInfo.isResurveyVillage">                   
                    <tr>
                        <th>Sl No.</th>
                        <th>LPNumber</th>
                        <th>LP Total extent</th>
                        <th>Old survey no / Sub-division</th>
                        <th>Land Nature(Broad) / Subnature1,2,3</th>
                        <th>Land classification(Broad) / Sub-classification1,2</th>
                        <th>Nature of Land Use</th>
                        <th>Pattadar Name</th>
                        <th>Pattadar Father/Husband Name</th>
                        <!-- <th>Purpose of Land Conversion</th> -->
                        <th>Market value </th>
                        <th>5% conversion amount to be paid</th>
                    </tr>
                    <tr *ngFor="let item of certificateInfo.adangalDetailsList; ; let i=index">
                        <td>{{ i + 1 }}</td>
                        <td>{{item?.lpmno}}</td>
                        <td>{{item?.lpM_Extent}}</td>
                        <td>{{item?.surveyNo}}</td>
                        <td>{{item?.landNature + ', ' + (item?.landNatureSubCategory1 === null ? '-' : item?.landNatureSubCategory1) + ',' + (item?.landNatureSubCategory2 === null ? '-' : item?.landNatureSubCategory2) + ',' + (item?.landNatureSubCategory3 === null ? '-' : item?.landNatureSubCategory3)}}</td>
                        <td>{{ (item?.landCalssification === null ? '-' : item?.landCalssification) + ',' + (item?.landClassificationSubCategory1 === null ? '-': item?.landClassificationSubCategory1) + ',' + (item?.landClassificationSubCategory2 === null ? '-': item?.landClassificationSubCategory2)}}</td>
                        <td>{{item?.natureOfLandUse }}</td>
                        <td>{{item?.pattadarName}}</td>
                        <td>{{item?.pattadarRelationName}}</td>
                        <td><span class="float-right">{{item.marketvalue  | currency:'INR'}}</span></td>
                        <td><span class="float-right">{{item.conversionAmount  | currency:'INR'}}</span></td>
                    </tr>
                    <tr>
                        <td colspan="10"> <span class="float-right">Total Conversion Amount:</span></td>
                        <td> <span class="float-right">{{ totalConversionAmount  | currency:'INR' }}</span> </td>
                    </tr>
            </table>
            <table class="tableCls" *ngIf="!certificateInfo.isResurveyVillage">
                <tr>
                    <th>Sl No.</th>
                    <th>Khata Number</th>
                    <th>Survey No</th>
                    <th>Survey No Total Extent</th>
                    <th>Occupant Extent</th>
                    <th>Occupant Extent to be Converted</th>
                    <th>Land Nature</th>
                    <th>Market value </th>
                    <th>5% Conversion Amount to be Paid</th>
                </tr>
                <tr *ngFor="let item of certificateInfo.adangalDetailsListSyNo; let i=index">
                    <td>{{ i + 1 }}</td>
                    <td>{{item?.khataNo}}</td>
                    <td>{{item?.surveyNo}}</td>
                    <td>{{item?.totalExtent}}</td>
                    <td>{{item?.occupantExtent}}</td>
                    <td>{{item?.extentCovered}}</td>
                    <td>{{item?.landNature}}</td>
                    <td><span class="float-right">{{item.marketvalue | currency:'INR'}}</span></td>
                    <td><span class="float-right">{{item.conversionAmount | currency:'INR'}}</span></td>
                </tr>
                <tr>
                    <td colspan="8"> <span class="float-right">Total Conversion Amount:</span></td>
                    <td> <span class="float-right">{{totalConversionAmount  | currency:'INR'}}</span> </td>
                </tr>
        </table>
            </div>
        </div>
    </div>
    <div class="subHeadingCls mb-3">CFMS Challan Details</div>
    <div class="row">
        <div class="col">
            <div class="tab table-responsive">
                <table class="tableCls">                   
                    <tr>
                        <th>Sl No.</th>
                        <th>Challan Number</th>
                        <th>Challan Amount</th>
                    </tr>
                    <tr *ngFor="let item of certificateInfo.challans; ; let i=index">
                        <td>{{ i + 1 }}</td>
                        <td>{{item?.challanNumber}}</td>
                        <td><span class="float-right">{{item?.challanAmount  | currency:'INR'}}</span></td>                        
                    </tr>
                    <tr>
                        <td colspan="2"></td>
                        <td><span class="float-right">{{ totalChallanAmount  | currency:'INR' }}</span></td>
                    </tr>
            </table>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo mt-2 mb-3">
    <div class="row">
      <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <ng-container *ngIf="fileList && fileList?.length !== 0">
          <div class="subHeadingCls mb-3">List of Documents</div>
          <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
            <div *ngIf="file.documentType === 'Market Value Certificate'">
              <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Market Value Certificate:</label>
              <div class="valueCls">
                <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                  class="download ml-2"></span>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>