<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Selected Criteria Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo.selectionCriteriaDetails?.districtName}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{certificateInfo.selectionCriteriaDetails?.villageWardName }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo?.selectionCriteriaDetails.mandalMunicipalityName ? (certificateInfo.selectionCriteriaDetails?.mandalMunicipalityName) : '-' }}</div>
            </div>
        <div class="fieldHolder">
            <label class="labelCls">Survey No/ Sub Division:</label>
            <div class="valueCls">{{certificateInfo?.surveyNo ? (certificateInfo?.surveyNo) : '-' }}</div>
        </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Adangal Survey No. Details </div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                <tr> 
                    <th>Survey No/Sub Division</th>
                    <th>Un Cultivatable Land(In acres)</th>
                    <th>Cultivatable Land(In Acres)</th>
                    <th>Land Nature</th>
                    <th>Tax </th>
                    <th>Land Classification</th>
                    <th>Water Source </th>
                    <th>Water Source Extent	</th>
                    <th>Khata Number</th>
                    <th>Enjoyment Nature</th>
                    <th>Pattadar Name</th>
                    <th>Pattadar Father Name </th>
                    <th>Occupant Name</th>
                    <th>Occupant Father Name</th>
                    <th>Occupant Extent</th>
                    <th>Total Extent</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of certificateInfo?.adangalSurveyInfo">
                        <td class="value">{{certificateInfo?.surveyNo ? (certificateInfo?.surveyNo) : '-' }}</td>
                        <td class="value">{{item.unCultivatableLand ? (item.unCultivatableLand) : '-' }}</td>
                        <td class="value">{{item.cultivatableLand ? (item.cultivatableLand) : '-' }}</td>
                        <td>{{item.landNature ? (item.landNature) : '-' }}</td>
                        <td class="value">{{item.tax ? (item.tax) : '-' }}</td>
                        <td>{{item.landClassification ? (item.landClassification) : '-' }}</td>
                        <td>{{item.waterSource ? (item.waterSource) : '-' }}</td>
                        <td class="value">{{item.extentofWaterSource ? (item.extentofWaterSource) : '-' }}</td>
                        <td class="value">{{item.khataNo ? (item.khataNo) : '-' }}</td>
                        <td>{{item.enjoymentNature ? (item.enjoymentNature) : '-' }}</td>
                        <td>{{item.pattadarDetails?.firstName ? (item.pattadarDetails?.firstName) : '-' }}</td>
                        <td>{{item.pattadarDetails?.fatherHusbandName ? (item.pattadarDetails?.fatherHusbandName) : '-' }}</td>
                        <td>{{item.occupantDetails?.firstName ? (item.occupantDetails?.firstName) : '-' }}</td>
                        <td>{{item.occupantDetails?.fatherHusbandName ? (item.occupantDetails?.fatherHusbandName) : '-' }}</td>
                        <td class="value">{{item.occupantExtent ? (item.occupantExtent) : '-' }}</td>
                        <td class="value">{{item?.totalExtent ? (item?.totalExtent) : '-' }}</td>
                        

                    </tr>
                </tbody>
                </table>
            </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Supporting Documents'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Supporting Documents:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>

