<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Replacement/Duplicate Pattadhar Pass Book Application Form</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Service Type:</label>
                <div class="valueCls">{{certificateInfo?.serviceType ? (certificateInfo?.serviceType) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo.pattadarAddress?.districtName ? (certificateInfo.pattadarAddress?.districtName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo.pattadarAddress?.mandalMunicipalityName ? (certificateInfo.pattadarAddress?.mandalMunicipalityName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{certificateInfo.pattadarAddress?.villageWardName ? (certificateInfo.pattadarAddress?.villageWardName) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Khata Number:</label>
            <div class="valueCls">{{certificateInfo?.khataNumber ? (certificateInfo?.khataNumber) : '-' }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Pattadar Name:</label>
            <div class="valueCls">{{certificateInfo.pattadarDetails?.firstName ? (certificateInfo.pattadarDetails?.firstName) : '-' }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Father Name:</label>
            <div class="valueCls">{{certificateInfo.pattadarDetails?.fatherHusbandName ? (certificateInfo.pattadarDetails?.fatherHusbandName) : '-' }}</div>
        </div>
        </div>
    </div>
    <hr>
    <div class="subHeadingCls mb-3">Details</div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <table class="tableCls mt-2">
                    <thead>
                    <tr> 
                        <th>Pattadar Name</th>
                        <th>Pattadar Father Name</th>
                        <th>Khata Number</th>
                        <th>Survey Number</th>
                        <th>Land Classification</th>
                        <th>Total Extent</th>
                        <th>Tax</th>
                        <th>Acquired Reason</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of members">
                            <td>{{item.pattarName ? (item.pattarName) : '-' }}</td>
                            <td>{{item.pattarFatherName ? (item.pattarFatherName ) : '-' }}</td>
                            <td class="value">{{item.khataNo ? (item.khataNo) : '-' }}</td>
                            <td class="value">{{item.surveyNo ? (item.surveyNo) : '-' }}</td>
                            <td>{{item.landClassification ? (item.landClassification) : '-' }}</td>
                            <td class="value">{{item.totalExtent ? (item.totalExtent) : '-' }}</td>
                            <td class="value">{{item.tax ? (item.tax) : '-' }}</td>
                            <td>{{item.acquiredReason ? (item.acquiredReason) : '-' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <hr>
        <div class="subHeadingCls mb-3">Pattadhar Details</div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Caste:</label>
                    <div class="valueCls">{{certificateInfo?.caste ? (certificateInfo?.caste) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Caste Name:</label>
                    <div class="valueCls">{{certificateInfo?.caste ? (certificateInfo?.caste) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Division Name:</label>
                    <div class="valueCls">{{certificateInfo?.divisionName ? (certificateInfo?.divisionName) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Old PPB Number:</label>
                    <div class="valueCls">{{certificateInfo?.oLdPPBNumber ? (certificateInfo?.oLdPPBNumber) : '-' }}</div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="fieldHolder">
                    <label class="labelCls">Registration Number:</label>
                    <div class="valueCls">{{certificateInfo?.registrationNumber ? (certificateInfo?.registrationNumber) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Registration Date:</label>
                    <div class="valueCls">{{certificateInfo?.registrationDate ? (certificateInfo?.registrationDate) : '-' }}</div>
                </div>
                <div class="fieldHolder">
                    <label class="labelCls">Reasons for replacement/duplicate of pattadar passbook:</label>
                    <div class="valueCls">{{certificateInfo?.reasonOfReplacement ? (certificateInfo?.reasonOfReplacement) : '-' }}</div>
                </div>
            </div>
        </div>

</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Register Documents'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Register Documents:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Passport size photo'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Passport size photo:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'scan copy of FIR'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">scan copy of FIR:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'NOC Certificate'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">NOC Certificate:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Signature'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Signature of applicant:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>



