<div class="reportContainer">
    <div [formGroup]="form">
    <div class="row mb-3">
        
    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
      <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
      <select class="selectCls" formControlName="district" (change)="onDistrictChange()"
      [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
         <option value="All">All</option>
        <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
      </select>
    </div>

    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
        <label class="labelCls">Rural/Urban : <span class="mandatoryCls">*</span></label> 
        <select class="selectCls" required formControlName="RUFlag" (change)="onRuralUrbanChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.RUFlag.errors || f.RUFlag.value === 'Select') }">
        <option value = "All">All</option>
        <option value = "R">Rural</option>
        <option value = "U">Urban</option>
        </select>
    </div>

    <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-3">
        <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
        [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
        <option value="All">All</option>
        <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
      </select>
      </div>

      <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <label class="labelCls">Village/Ward/Secretariat: <span class="mandatoryCls">*</span></label>
        <select class="selectCls" formControlName="village" (change)="onVillageChange()"
          [ngClass]="{ 'is-invalid':submitted && (f.village.errors || f.village.value === 'Select') }">
          <option value="All">All</option>
          <option *ngFor="let village of villages" [ngValue]="village">{{village.secretariatName}}</option>
        </select>
      </div>

        <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="labelCls">From Date: <span class="mandatoryCls">*</span></label>
            <div class="input-group">
              <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                     name="d1"  ngbDatepicker #d1="ngbDatepicker" [minDate]="minDate" (dateSelect)="onFromDateSelect($event)"
                     [maxDate]="maxDate" required formControlName="fromDate"
                     [ngClass]="{ 'is-invalid':submitted && f.fromDate.errors }">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"></button>
              </div>
            </div>
            <div *ngIf="submitted && f.fromDate.errors" class="selectError">
              <div *ngIf="f.fromDate.errors?.required || f.fromDate.invalid">Please enter valid From Date</div>
          </div>
          </div>
          <div class="col col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <label class="labelCls">To Date: <span class="mandatoryCls">*</span></label>
            <div class="input-group">
              <input class="calendarCls form-control" placeholder="dd/mm/yyyy" appBlockCopyPaste
                     name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]="toMinDate" (dateSelect)="onToDateSelect($event)"
                     [maxDate]="maxDate" required formControlName="toDate"
                     [ngClass]="{ 'is-invalid':submitted && f.toDate.errors }">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
              </div>
            </div>
            <div *ngIf="submitted && f.toDate.errors" class="selectError">
              <div *ngIf="f.toDate.errors?.required || f.toDate.invalid">Please enter valid To Date</div>
          </div>
          </div>
        </div> 
        <div class="tabClass">
            <ul>
                  <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li>
            </ul>
            <div class="btnHolder">
                <button  class="greenBtnCls float-right my-3" (click)="GetPPWelfareSchemeReport()">Get Reports</button>
                <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
                <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
            </div>
          </div>
    </div>
    <div class="table-responsive tableFixHead" *ngIf="showReports">
        <table class="tableCls" >
            <thead> 
                <tr>
                    <th [attr.colspan]="noOfCol">Unveiling Of Welfare Schemes Display Boards-Program Details Report from  {{f.fromDate.value}} to  {{f.toDate.value}}</th>
                </tr>
                <tr>
                   <th rowspan="2">S.No</th>
                   <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_CODE'">District Code<mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District Name<mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_CODE'">Mandal Code<mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal Name<mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rurban'">Rural/Urban<mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'">Secretariat Code<mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'">Secretariat Name <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'scheD_SHUD_STARTED_SEC'">No of Secretariat Scheduled<mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'prgrM_STARTED_SEC'">No of Secretariat Camp Started <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'scheD_SHUD_STARTED_SEC'">Is Camp started(yes/no)<mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfMembersAttended'">No of Members Attended <mdb-icon fas icon="sort"></mdb-icon></th>
                <th rowspan="2" *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'chiefGuestName'">Chief Guest Name<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" >Camp Photo<mdb-icon fas icon="sort"></mdb-icon></th>
                <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'reason'">Reason Entered<mdb-icon fas icon="sort"></mdb-icon></th>
                <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'tentativeScheduleDate'">Tentative ScheduleDate<mdb-icon fas icon="sort"></mdb-icon></th> 
                </tr>
            </thead>
    
            <tbody *ngIf="items && items.length > 0">
                <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                    <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{item.districT_CODE}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{item.districT_NAME}}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_CODE}}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.rurban}}</td>
                    <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_CODE}}</td>
                    <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'" class="text-right">{{item.scheD_SHUD_STARTED_SEC}}</td>
                    <td *ngIf="format === 'District Wise' ||format === 'Mandal Wise'" class="text-right">{{item.prgrM_STARTED_SEC}}</td>
                    <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{item.scheD_SHUD_STARTED_SEC}}</td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" class="text-right">{{item.noOfMembersAttended}}</td>
                    <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{item.chiefGuestName}}</td>
                    <td *ngIf="format === 'Secretariat Wise'  && item.programPhoto"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.programPhoto)">Show Image</a></td>
                    <td *ngIf="format === 'Secretariat Wise' && !item.programPhoto"  class="text-right">-</td>
                    <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{item.reason}}</td>
                    <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{item.tentativeScheduleDate}}</td> 
                </tr>
                <tr *ngIf="totalItems && totalItems.length > 0">
                    <td></td>
                    <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{totalItems[0].districT_CODE}}</td>
             <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{totalItems[0].districT_NAME}}</td>
             <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_CODE}}</td>
             <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
             <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].rurban}}</td>
             <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_CODE}}</td>
             <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
             <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'" class="text-right">{{totalItems[0].scheD_SHUD_STARTED_SEC}}</td>
             <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'" class="text-right">{{totalItems[0].prgrM_STARTED_SEC}}</td>
             <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{totalItems[0].scheD_SHUD_STARTED_SEC}}</td>
             <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" class="text-right">{{totalItems[0].noOfMembersAttended}}</td>
             <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{totalItems[0].chiefGuestName}}</td>
             <td *ngIf="format === 'Secretariat Wise'"  class="text-right"></td>
             <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{totalItems[0].reason}}</td>
             <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{totalItems[0].tentativeScheduleDate}}</td>
                </tr>
            </tbody>
            <tbody *ngIf="items && items.length === 0">
                <tr>
                    <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </table>
        <div class="mt-4 btnHide">
            <div class="float-left" *ngIf="items.length > 0">
                <label>Requests per page: </label>
                <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                    <option  value="10">10</option>
                    <option  value="20">20</option>
                    <option  value="30">30</option>
                    <option  value="40">40</option>
                    <option  value="50">50</option>
                </select> 
            </div>
            <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
            </ngb-pagination>
        </div>
    </div>
</div>   
<div class="table-responsive tableFixHead" #TABLE class="hideTable">
    <table class="tableCls" >
        <thead>
            <tr>
                <th [attr.colspan]="noOfCol">Unveiling Of Welfare Schemes Display Boards-Program Details Report from {{f.fromDate.value}} to  {{f.toDate.value}}</th>
             </tr>
            <tr>
                <th >S.No</th>
                <th  *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_CODE'">District Code<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District Name<mdb-icon fas icon="sort"></mdb-icon></th>
                <th *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_CODE'">Mandal Code<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal Name<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rurban'">Rural/Urban<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'">Secretariat Code<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'">Secretariat Name <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'scheD_SHUD_STARTED_SEC'">No of Secretariat Scheduled<mdb-icon fas icon="sort"></mdb-icon></th>
                <th *ngIf="format === 'District Wise' || format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'prgrM_STARTED_SEC'">No of Secretariat Camp Started <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'scheD_SHUD_STARTED_SEC'">Is Camp started(yes/no)<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'noOfMembersAttended'">No of Members Attended <mdb-icon fas icon="sort"></mdb-icon></th>
                <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'chiefGuestName'">Chief Guest Name<mdb-icon fas icon="sort"></mdb-icon></th>
                <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'reason'">Reason Entered<mdb-icon fas icon="sort"></mdb-icon></th>
                <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'tentativeScheduleDate'">Tentative ScheduleDate<mdb-icon fas icon="sort"></mdb-icon></th> 
        </thead>
 
        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items;let i = index;">
                <td>{{(i + 1)}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{item.districT_CODE}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{item.districT_NAME}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_CODE}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.rurban}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_CODE}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'" class="text-right">{{item.scheD_SHUD_STARTED_SEC}}</td>
                <td *ngIf="format === 'District Wise' ||format === 'Mandal Wise'" class="text-right">{{item.prgrM_STARTED_SEC}}</td>
                <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{item.scheD_SHUD_STARTED_SEC}}</td>
                <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" class="text-right">{{item.noOfMembersAttended}}</td>
                <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{item.chiefGuestName}}</td>
                <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{item.reason}}</td>
                <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{item.tentativeScheduleDate}}</td> 
                 </tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
             <td></td>
             <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{totalItems[0].districT_CODE}}</td>
             <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'">{{totalItems[0].districT_NAME}}</td>
             <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_CODE}}</td>
             <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
             <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].rurban}}</td>
             <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_CODE}}</td>
             <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
             <td *ngIf="format === 'District Wise' || format === 'Mandal Wise'" class="text-right">{{totalItems[0].scheD_SHUD_STARTED_SEC}}</td>
             <td *ngIf="format === 'District Wise'  || format === 'Mandal Wise'" class="text-right">{{totalItems[0].prgrM_STARTED_SEC}}</td>
             <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{totalItems[0].scheD_SHUD_STARTED_SEC}}</td>
             <td *ngIf="format === 'District Wise' || format === 'Mandal Wise' || format === 'Secretariat Wise'" class="text-right">{{totalItems[0].noOfMembersAttended}}</td>
             <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{totalItems[0].chiefGuestName}}</td>
             <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{totalItems[0].reason}}</td>
             <td *ngIf="format === 'Secretariat Wise'" class="text-right">{{totalItems[0].tentativeScheduleDate}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
               <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
             </tr>
        </tbody>
    </table>
   </div>
      

    

