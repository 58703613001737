import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService, CustomAdapter, CustomDateParserFormatter } from 'src/shared/common.service';
import { NgbDateAdapter, NgbDateParserFormatter, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { CommonConstants } from '../../constants/common-constants.component';
import { error } from 'console';

@Component({
  selector: 'app-aadhaar-challan-generation',
  templateUrl: './aadhaar-challan-generation.component.html',
  styleUrls: ['./aadhaar-challan-generation.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    DatePipe
  ]
})
export class AadhaarChallanGenerationComponent implements OnInit {
  categories: any[] = [];
  amount: any = [];
  noOfServiceAmount: any = [];
  typeOfService: any[] = [];
  aadharGeneration: FormGroup;
  public submitted: Boolean = false;
  commonConstants: any = CommonConstants;
  maxDate: any;
  minDate: any;
  toMinDate: any;
  currentDate = new Date();
  aadharChallanDetails: any = {};
  loggedInUserId: any;
  userInfo: any;
  postData: any = {};
  disable: Boolean = true;

  constructor(private formBuilder: FormBuilder, private commonService: CommonService, private datePipe: DatePipe, private router: Router, private modalService: NgbModal) {
    this.aadharGeneration = this.formBuilder.group({
      aadharCategory: [null, [Validators.required]],
      serviceRaisedDate: ['', [Validators.required]],
      noOfService: ['', [Validators.required,Validators.min(1), Validators.max(100), Validators.pattern(/^[1-9]/)]],
      typeOfService: [null, [Validators.required]],
      amount: ['', [Validators.required]],
    });

    let userData = localStorage.getItem('userInfo');
    if (userData && userData !== null) {
      this.userInfo = JSON.parse(userData);
      this.loggedInUserId = this.userInfo?.userPrincipalName
    }
    this.maxDate = {
      year: this.currentDate.getFullYear(),
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.minDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    };
    this.toMinDate = {
      year: this.currentDate.getFullYear() - 150,
      month: this.currentDate.getMonth() + 1,
      day: this.currentDate.getDate()
    }
    this.onCategoryChange();
   }

   get f() {
    return this.aadharGeneration.controls;
  }

  onFromDateSelect(event: any) {
    this.toMinDate = event;
  }

  ngOnInit(): void {
  }

  onCategoryChange() {
    this.commonService.getRequest(this.commonConstants.getCategory + '?ServiceName=' + this.f.aadharCategory.value).subscribe({
      next: (responseData: any) => {
        console.log(responseData, "responseData")
        if (responseData.result) {
          this.categories = responseData.result
        } else {
          this.categories = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  }

  onChangeNoOfService(event: any) {
    this.noOfServiceAmount = this.f.amount.patchValue(event.target.value * this.f['typeOfService'].value.serviceCharges);
  }

  onChangeAmount() {
    this.f.noOfService.patchValue(null);
    this.f.amount.patchValue(null);
  }

  resetForm(){
    this.aadharGeneration.reset();
    this.submitted = false;
  }
  addAadharChallanGenerationData(): any {
    this.submitted = true;
    if (!this.aadharGeneration.valid)
      return false;
    let serviceType: any;
    serviceType = this.categories.find((element) => {
      return element.serviceId == this.f['typeOfService'].value.serviceId
    })

    let postData = {
      secretariatCode: localStorage.getItem('locationCode'),
      userId: this.loggedInUserId,
      aadharCategory: this.f['aadharCategory'].value,
      serviceRaisedDate: this.commonService.formatDate(this.f['serviceRaisedDate'].value),
      typeOfService: serviceType.serviceType,
      noOfService: this.f['noOfService'].value,
    }
    this.commonService.postRequest(this.commonConstants.aadharChallanGenerate, postData).subscribe({
      next: (responseData: any) => {
        console.log(responseData, 'responseData');
        if (responseData.result?.status == 100) {
          this.commonService.commonErrorPopup('Alert', responseData?.result?.reason, 'md');
          this.aadharChallanDetails = responseData;
           this.resetForm();
        } else {
          this.commonService.commonErrorPopup('Alert', responseData?.result?.reason, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error)
      }
    });
  }
}
