import { Component,Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-reissuance-view',
  templateUrl: './reissuance-view.component.html',
  styleUrls: ['./reissuance-view.component.css']
})
export class ReissuanceViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();

  maskedAadhar:any = ""
  constructor() {

   
   }

  ngOnInit(): void {

    this.maskedAadhar = this.certificateInfo?.aadharNumber?.replace(
      this.certificateInfo?.aadharNumber.substr(0, 8),
      'XXXX-XXXX-'
    );
    
  }
  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
}
