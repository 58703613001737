<div class="certificateInfo" *ngIf="basicInfo ">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Relationship with Death Person:</label>
                <div class="valueCls">{{certificateInfo?.relationshipWithDeceased ? (certificateInfo?.relationshipWithDeceased) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo ">
    <div class="subHeadingCls mb-3">Death Person Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Death Person First Name:</label>
                <div class="valueCls">{{certificateInfo.deathPersonName?.firstName ? (certificateInfo.deathPersonName?.firstName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Death Person Middle Name:</label>
                <div class="valueCls">{{certificateInfo.deathPersonName?.middleName ? (certificateInfo.deathPersonName?.middleName) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Death Person Last Name:</label>
            <div class="valueCls">{{certificateInfo.deathPersonName?.lastName ? (certificateInfo.deathPersonName?.lastName) : '-' }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Father/Husband Name:</label>
            <div class="valueCls">{{certificateInfo.deathPersonName?.fatherHusbandName ? (certificateInfo.deathPersonName?.fatherHusbandName) : '-' }}</div>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Door No:</label>
                <div class="valueCls">{{certificateInfo.incidenceAddress?.doorNo ? (certificateInfo.incidenceAddress?.doorNo) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{this.certificateInfo.incidenceAddress?.districtName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Street Name :</label>
                <div class="valueCls">{{certificateInfo.incidenceAddress?.streetName ? (certificateInfo.incidenceAddress?.streetName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{this.certificateInfo.incidenceAddress?.mandalMunicipalityName}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{this.certificateInfo.incidenceAddress?.villageWardName }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Date of Death:</label>
                <div class="valueCls">{{certificateInfo?.incidenceDate | date: 'dd/MM/yyyy'}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Pin Code:</label>
                <div class="valueCls">{{certificateInfo.incidenceAddress?.pinCode ? (certificateInfo.incidenceAddress?.pinCode) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Place Of Death(Hospital Name/Home):</label>
                <div class="valueCls">{{certificateInfo.placeOfDeath ? (certificateInfo.placeOfDeath) : '-' }}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Occupation:</label>
                <div class="valueCls">{{certificateInfo.deceasedOccupation ? (certificateInfo.deceasedOccupation) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Cause Of Death:</label>
                <div class="valueCls">{{certificateInfo.causeofDeath ? (certificateInfo.causeofDeath) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Purpose:</label>
            <div class="valueCls">{{certificateInfo.purpose ? (certificateInfo.purpose) : '-' }}</div>
        </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo ">
    <div class="subHeadingCls mb-3">Location Of Death</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">State:</label>
                <div class="valueCls">{{certificateInfo?.deceasedState ? (certificateInfo?.deceasedState) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{this.certificateInfo.deathPlaceDetails?.mandalMunicipalityName }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{this.certificateInfo.deathPlaceDetails?.districtName }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{this.certificateInfo.deathPlaceDetails?.villageWardName}}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo ">
    <div class="subHeadingCls mb-3">Family Members of the Death Person</div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                <tr> 
                    <th>Name of the Family Member</th>
                    <th>Gender</th>
                    <th>Relationship with Deceseaed Person</th>
                    <th>Occupation</th>
                    <th>Marital Status</th>
                    <th>Educational Qualification</th>
                    <th>Employment</th>
                    <th>Income Per Month</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of certificateInfo?.familyDetails">
                        <td>{{item?.familyName ? (item?.familyName) : '-' }}</td>
                        <td class="value">{{item?.age ? (item?.age) : '-' }}</td>
                        <td>{{item?.relation ? (item?.relation) : '-' }}</td>
                        <td>{{item?.occupation ? (item?.occupation) : '-' }}</td>
                        <td>{{item?.maritalStatus ? (item?.maritalStatus) : '-' }}</td>
                        <td>{{item?.education ? (item?.education) : '-' }}</td>
                        <td>{{item?.employment ? (item?.employment) : '-' }}</td>
                        <td class="value">{{item?.incomePerMonth ? (item?.incomePerMonth) : '-' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Ration Card/Aadhaar No/Electoral Card'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Ration card /EPIC Card/AADHAR Card:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Death Certificate'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Death Certificate:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Copy of Family Member Certificate'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Copy of Family Member Certificate:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>

