import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {CommonService,AadharValidator,WhitespaceValidator} from 'src/shared/common.service';
import { CommonConstants } from 'src/constants/common-constants.component';
import { post } from 'jquery';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-jagananna-schedule-search',
  templateUrl: './jagananna-schedule-search.component.html',
  styleUrls: ['./jagananna-schedule-search.component.css']
})
export class JaganannaScheduleSearchComponent implements OnInit {
  //Jagananna: FormGroup;
  form: FormGroup;
  commonConstants : any = CommonConstants;
  districts: any[] = [];
  mandals: any[] = [];
  villages: any[]= [];
  divisions:any[]= [];
  showReports:Boolean=false;

  public submitted:Boolean=false;
  permMandals: any[] = [];
  permVillages:any[]= [];
  reqData: any;
  showTable:Boolean=false;
  constructor(public activeModal: NgbActiveModal , private commonService: CommonService,private formBuilder: FormBuilder) { 
    // this.Jagananna = this.formBuilder.group({
    //   aadharNo: ['',[AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate,Validators.minLength(12)]],
    //   seccode : ['']
    // });
    this.form = this.formBuilder.group({
      village: [null],
      mandal: [null],
      district: [null],
      aadharNo: ['',[AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate,Validators.minLength(12)]],
      // RUFlag: [null],

    });
    this.getDistricts();
  }
  ngOnInit(): void {
  }
  //get f() {
   // return this.Jagananna.controls;
 // }
  get f() { return this.form.controls; }

  getDistricts() {
    this.commonService.getRequest(this.commonConstants.getCSPDistricts).subscribe({
      next: (responseData: any) => {
        console.log(responseData,'getDistricts')
        if(responseData.result?.isSuccess) {
          this.districts = this.commonService.sortItems(responseData.result?.getDistrictDetails, 'districtName');
          console.log(this.districts,'districts')
        } else {
          this.districts = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  
  onDistrictChange() {
    this.showReports = false;
    this.mandals = [];
    this.villages = [];
    this.f.mandal.patchValue(null);
    this.f.village.patchValue(null);
   
    this.commonService.getRequest(this.commonConstants.getCSPMandalsRuralUrban + '?DistrictCode=' + this.f['district'].value.districtCode + '&RuralOrUrbanFlag=' + '').subscribe({
      next: (responseData: any) => {
        console.log(responseData,'mandals')
        if(responseData.result?.isSuccess) {
          this.mandals = this.commonService.sortItems(responseData.result?.getMandalDetails, 'mandalName');
        } else {
          this.mandals = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  onMandalChange() {
    this.showReports = false;
    this.villages = [];
   this.f.village.patchValue(null);
    this.commonService.getRequest(this.commonConstants.getCSPSecretariat + '?MandalCode=' + this.f.mandal.value.mandalCode).subscribe({
      next: (responseData: any) => {
        console.log(responseData,'villages')
        if(responseData.result?.isSuccess) {
          this.villages = this.commonService.sortItems(responseData.result?.getSecretariatDetails, 'secretariatName');
        } else {
          this.villages = [];
          this.commonService.commonErrorPopup('Alert', responseData.result?.error, 'md');
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  hideResult() {
    this.showReports = false;
  }
 
  // submit(): any{
   // let postData: any = {};
  //  var sendPostData={
  //   aadharNo: this.f.aadharNo.value, 
  // }

    //     console.log("birja",sendPostData);
    // this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, sendPostData).subscribe({
    //   next: (houseHoldResponseData: any) => {
    //     console.log("houseHoldResponseData",houseHoldResponseData);
          // if(houseHoldResponseData.result?.isSuccess) {
          //   this.commonService.getRequest(this.commonConstants.getJSVenueScheduleDetailsByAadharSecCode,seccode).subscribe({
          //     next:(responseData:any)=>{

          //     }
          //   })
                
          // } else {
          //   this.commonService.commonErrorPopup('Alert', houseHoldResponseData.result?.error, 'md');
          // }
//       },
//       error: (error) => {
//         console.log('client side', error);
//       }
//   });
//   }
// }
getCitizenInfo():any{
  this.submitted=true;
  if((this.f['village'].value !== null && this.f.aadharNo.value !== null && this.f.aadharNo.value?.trim() !== '') || 
  this.f['village'].value === null && this.f.district.value === null && this.f.mandal.value === null && (this.f.aadharNo.value === null || this.f.aadharNo.value?.trim() === '')){
    this.commonService.commonErrorPopup("Alert", "Please search by either  aadhar Number or secretariat. ", "md");
  } else if(this.f.aadharNo.value !== null && this.f.aadharNo.value?.trim() !== '') {
    if(!this.f.aadharNo.valid) 
      return false;
    let postData: any = {};
    postData.aadhaarNumber = this.commonService.RSAEncrypt(this.f.aadharNo.value, true); 
    this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe({
      next: (responseData: any) => { 
          if(responseData?.result && responseData?.result.length !==0) {
            responseData.result.forEach((item: any) => {
              item.aadhaarNumber = item.aadhaarNumber;
            });
            this.getScheduleData(responseData.result[0].seccode); 
          } else {
            this.commonService.commonErrorPopup('Alert', 'Household data is not available for the provided aadhar number. Please search by selecting secretariat.', 'md');
          }
        },
        error: (error) => {
          console.log('client side', error);
        }
      })
  } else if(this.f['village'].value !== null && this.f.district.value !== null && this.f.mandal.value !== null) {
    this.getScheduleData(this.f['village'].value?.secretariatCode);
  }
  
   } 


getScheduleData(secCode:any) {
  this.commonService.getRequest(this.commonConstants.getJSVenueScheduleDetailsByAadharSecCode + secCode).subscribe({
    next:(responseData:any)=>{ 
    this.reqData = responseData?.result; 
    console.log("aadhar req",this.reqData);
      if(this.reqData){
      this.showReports=true;
      }
      else{
      this.commonService.commonErrorPopup("Alert", "Records not found", "md")
      this.showReports=false;
      }
   },
   error: (error) => {
    console.log('client side', error);
   }
   })
}
}
