import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonConstants } from '../../constants/common-constants.component';
import { CommonService } from '../../shared/common.service';

@Component({
  selector: 'app-certificate-download',
  templateUrl: './certificate-download.component.html',
  styleUrls: ['./certificate-download.component.css']
})
export class CertificateDownloadComponent implements OnInit {
  @Input() queryParmData: any;
  @Input() selectedPage: any;
  commonConstants: any = CommonConstants;
  constructor(private currentRoute: ActivatedRoute, private router: Router, private commonService: CommonService) {}

  ngOnInit(): void {
   this.currentRoute.queryParams.subscribe((params: any) => {
    if(params !== undefined && params.hasOwnProperty('certificateId') && params.hasOwnProperty('deptCode')) {
      if(params.certificateId !== null && params.certificateId !== undefined && params.certificateId !== '' &&
      params.deptCode !== null && params.deptCode !== undefined && params.deptCode !== '')
        this.downloadCertificate(params);
      else
        this.commonService.commonErrorPopup('Alert', 'Input data is missed.', 'md');
    }
    
    });
  }
  downloadCertificate(data:any) {
    this.commonService.getRequest(this.commonConstants.getSignedCertificateDataForPreview + data.certificateId + '&DepartmentCode='+ data.deptCode ).subscribe({
      next: (responseData: any) => {
        console.log(responseData,'getSignedCertificateDataForPreview viewDatainPDF'); 
        console.log('responseData.result.fileContents',responseData)
        if(responseData.result.error == null){
          let fileInfo =  responseData.result.fileContents;
          this.commonService.downloadPdfFile(fileInfo, data.certificateId);
        } else{
          this.commonService.commonErrorPopup('Alert', 'No application found.', 'md');
        }
       
      },
      error: (error) => {
        console.log('client side', error);
      }
    });
  } 
}
