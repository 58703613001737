import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {CommonService,AadharValidator,WhitespaceValidator} from 'src/shared/common.service';
import { CommonConstants } from 'src/constants/common-constants.component';
@Component({
  selector: 'app-health-card-status',
  templateUrl: './health-card-status.component.html',
  styleUrls: ['./health-card-status.component.css']
})
export class HealthCardStatusComponent implements OnInit {
  aarogyaSri: FormGroup;
  showTable:Boolean=false;
  commonConstants : any = CommonConstants;
  members : {
    aadharId: any,
    age: any,
    memberName: string,
    enteredDate: any,
    relation: string,
    referenceId:any
    requestsFor: any,
    residentId:any,
    secretariatName: string,
    status: string,
    uhid: any,
    volunteerName: string,   
    MigrationReason:string;
  } [] = [];
  tempMember:any=[]
  secretariate:any;
  public submitted:Boolean=false;
  volunteerName:any;
  sixStepResponseData:any[]=[];
  sixStepResponseDataforRequsition:any[]=[];
  statusInGsws:any;
  constructor(private commonService: CommonService,private formBuilder: FormBuilder) {
    this.aarogyaSri = this.formBuilder.group({
      
      referenceNo: ['',[Validators.pattern(this.commonConstants.alphanumericPattern),Validators.minLength(9)]],
      uhid: ['',[Validators.minLength(11)]],
      aadharNo: ['',[AadharValidator.aadharValidate, WhitespaceValidator.whiteSpaceValidate,Validators.minLength(12)]]
      
    });
   }

  ngOnInit(): void {
  }
  get f() {
    return this.aarogyaSri.controls;
  }
  submit(){
    this.members=[];
    if(this.f['referenceNo'].value || this.f['uhid'].value || this.f['aadharNo'].value){
      if(this.aarogyaSri.valid){
      this.submitted=false;      
      let id:any;
      let type:any;
    if(this.f['referenceNo'].value){
      id = this.f['referenceNo'].value; 
      type='ReferenceId';
     }else if(this.f['aadharNo'].value){
      id = this.f['aadharNo'].value; 
      type='UID';
     }else if(this.f['uhid'].value){
      id = this.f['uhid'].value; 
      type='UHID';
     }
     let requestData = {
      Id: id,
      IdType: type
     }
     
      this.commonService.postRequest(this.commonConstants.getHealthCardStatus, requestData, true ).subscribe({
        next: (responseData: any) => { 
          let reqData = responseData?.result;
          if(reqData.length  >0 && typeof(reqData) !== 'string'){
            let entityCode=reqData[0].secretariatName;
            this.showTable= true;        
            let postData= {
              Id: reqData[0]?.referenceId,
              IdType: 'ReferenceId'
            };
              this.commonService.postRequest(this.commonConstants.GetCurentApplicationStatusByID, postData, true).subscribe({
                next: (statusresponseData: any) => { 
                  let status ='';
                  this.statusInGsws='';
                  if(statusresponseData?.result?.isSuccess === true){
                    status=  statusresponseData?.result?.status;
                    if(status == 'Legacy data record'){                   
                      this.statusInGsws = '';
                    }else if(status =='Pending'){
                      this.statusInGsws = 'Pending at DC' ;
                    }else if(status =='No records found for the given Id'){
                      this.statusInGsws = '' ; 
                    }else if(status =='Transaction Unsuccessful'){
                      this.statusInGsws = 'Payment failed' ; 
                    }else if(status =='Approved'){
                      this.statusInGsws = 'Approved' ; 
                    } else if(status == 'Rejected'){
                      this.statusInGsws = statusresponseData?.result?.status +((statusresponseData?.result?.reason !== null  && statusresponseData?.result?.reason !== '') ? ( '-'+ statusresponseData?.result?.reason) : '');
                      }
                  }else{
                    this.statusInGsws = '';
                  }
                 
                  // else{
                  //   this.statusInGsws = statusresponseData?.result;
                  // }
                  //this.statusInGsws = statusresponseData?.result; 
                }})
                this.onMandalChange(entityCode,reqData); 
          } 
          else if(reqData.length === 0){
            let postData= {
              Id: id,
              IdType: type
            };
            this.commonService.postRequest(this.commonConstants.GetCurentApplicationStatusByID, postData, true).subscribe({
              next: (statusresponseData: any) => { 
                let status ='';
                this.statusInGsws='';
                if(statusresponseData?.result?.isSuccess === true){
                  status=  statusresponseData?.result?.status;
                  if(status == 'Legacy data record'){                   
                    this.statusInGsws = '';
                  }else if(status =='Pending'){
                    this.statusInGsws = 'Pending at DC' ;
                  }else if(status =='No records found for the given Id'){
                    this.statusInGsws = '' ; 
                  }else if(status =='Transaction Unsuccessful'){
                    this.statusInGsws = 'Payment failed' ; 
                  }else if(status =='Approved'){
                    this.statusInGsws = 'Approved' ; 
                  }else if(status == 'Rejected'){
                    this.statusInGsws = statusresponseData?.result?.status +((statusresponseData?.result?.reason !== null  && statusresponseData?.result?.reason !== '') ? ( '-'+ statusresponseData?.result?.reason) : '');
                  } 
                }else{
                  this.statusInGsws = '';
                }
              
                // else{
                //   this.statusInGsws = statusresponseData?.result;
                // }
                //this.statusInGsws = statusresponseData?.result; 

                if(reqData.length === 0 && status == 'Transaction Unsuccessful' ){
                  this.commonService.commonErrorPopup("Alert", "Transaction Failed", "md") 
                }else{
                  this.commonService.commonErrorPopup("Alert", "Records not found", "md")
                }
              }})
          }
          else{
            this.commonService.commonErrorPopup("Alert", "Records not found", "md")
          }       
             
        }
      })    
    }else{
this.submitted=true;
this.showTable=false;
    }

    }else{
      this.commonService.commonErrorPopup("Alert", "Please enter Application Number or UHID or Aadhar No", "md")
     // alert('Please enter Application Number or UHID or Aadhar No');
    }

  }
  onMandalChange(entityCode:any,reqData:any) {
    this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode).subscribe({  
      next: (responseData: any) => {    
             
if(responseData.result.length >0 ){
  this.secretariate = responseData.result[0]?.name; 
  for (let i = 0; i < reqData.length; i++) {
    reqData[i].secretariatName=this.secretariate;
    reqData[i].status=this.statusInGsws;         
   
  }
}else if(responseData.result.length === 0){
  for (let i = 0; i < reqData.length; i++) {
    this.members.push({
      aadharId: this.commonService.RSADecrypt(reqData[i].aadharId),
      age : reqData[i].age,
      memberName:reqData[i].memberName,
      enteredDate:reqData[i].enteredDate,
      referenceId:reqData[i].referenceId,
      relation:reqData[i].relation ? reqData[i].relation : '',
      requestsFor:reqData[i].requestsFor,
      residentId:reqData[i].residentId,
      secretariatName:'',
      status:this.statusInGsws ? this.statusInGsws:'',
      uhid:reqData[i].uhid,
      volunteerName:reqData[i].volunteerName ? reqData[i].volunteerName :this.volunteerName,
      MigrationReason: ''
      //gswsStatus:this.statusInGsws
    })
 
  }
}
      
          let referenceId=reqData[0].referenceId.toString();
         let prefix =referenceId?.substring(0,3);
         if(prefix === 'UHC' || prefix === 'NHC'){
        
            var sendPostData={
              CITIZEN_ID: this.commonService.RSAEncrypt(this.commonService.RSADecrypt(reqData[0].aadharId), true),
            }
            this.commonService.postRequestForWebLand(this.commonConstants.newSixStepDetails, sendPostData , 'citizenInfo').subscribe({
              next: (sixstepData:any) => { 
                this.sixStepResponseDataforRequsition = sixstepData.result;
                this.members=[];
             if(this.sixStepResponseDataforRequsition.length >0){
              for (let i = 0; i < reqData.length; i++) {
                this.members.push({
                  aadharId:this.commonService.RSADecrypt(reqData[i].aadharId),
                  age : reqData[i].age,
                  memberName:reqData[i].memberName,
                  enteredDate:reqData[i].enteredDate,
                  referenceId:reqData[i].referenceId,
                  relation:reqData[i].relation ? reqData[i].relation : '',
                  requestsFor:reqData[i].requestsFor,
                  residentId:reqData[i].residentId,
                  secretariatName:reqData[i].secretariatName ? reqData[i].secretariatName :'',
                  status:this.statusInGsws ? this.statusInGsws:'',
                  uhid:reqData[i].uhid,
                  volunteerName:reqData[i].volunteerName ? reqData[i].volunteerName :this.volunteerName,
                  MigrationReason: reqData[i].aadharId ?this.getmigrationreasonfromSixstep(this.commonService.RSADecrypt(reqData[i].aadharId)) :''
                  //gswsStatus:this.statusInGsws
                })
             
              }
             }
          }
        })
       
        
         // this.members=reqData;
         

          }else{
            this.citizeninfo(0,reqData);
        
          } 
       
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }
  citizeninfo(i:any,reqData:any){
   
    let postData: any = {};
    postData.aadhaarNumber = this.commonService.RSAEncrypt(reqData[i].aadharId, true);    
    this.commonService.postRequestForWebLand(this.commonConstants.getCitizenInfo, postData, 'citizenInfo').subscribe({
      next: (responseData: any) => { 
        if(responseData?.result &&responseData?.result.length !==0){
          responseData.result.forEach((item: any) => {
            item.aadhaarNumber = item.aadhaarNumber;
          });
      this.volunteerName=responseData?.result[0].volunteername;
          this.getsixstep(reqData[i].aadharId,reqData);
        }else if(responseData?.result &&responseData?.result.length ==0){
          i+=1
          this.citizeninfo(i,reqData);
        }
      },
    });
    }
    
         
  
 
  getsixstep(id:any,data:any){
    var sendPostData={
      CITIZEN_ID: this.commonService.RSAEncrypt(id, true),
    }
    this.commonService.postRequestForWebLand(this.commonConstants.newSixStepDetails, sendPostData , 'citizenInfo').subscribe({
      next: (sixstepData:any) => { 
        this.sixStepResponseData = sixstepData.result;
    if(this.sixStepResponseData.length>0){      
    this.members=[];
        for (let i = 0; i < data.length; i++){  
     
            this.members.push({aadharId:this.commonService.RSADecrypt(data[i].aadharId),
              age : data[i].age,
              memberName:this.getnamefromsixstep(this.commonService.RSADecrypt(data[i].aadharId)) ?this.getnamefromsixstep(this.commonService.RSADecrypt(data[i].aadharId)):'',
              enteredDate:data[i].enteredDate,
              referenceId:data[i].referenceId,
              relation:data[i].relation,
              requestsFor:data[i].requestsFor,
              residentId:data[i].residentId,
              secretariatName:this.getsecreteriatefromSixstep(this.commonService.RSADecrypt(data[i].aadharId)),
              status:this.statusInGsws,
              uhid:data[i].uhid,
              volunteerName:this.volunteerName,
              MigrationReason:data[i].IS_LIVING_WITHFAMILY
            })
          
        
  }
}
},
});


}
getnamefromsixstep(id:any){
  let data = this.sixStepResponseData.filter((item:any) => (item.CITIZEN_ID === id)); 
  return data[0]?.CITIZEN_NAME ? data[0]?.CITIZEN_NAME :'' ;
}
getmigrationreasonfromSixstep(id:any){
let reason = this.sixStepResponseDataforRequsition.filter((item:any) => (item.CITIZEN_ID === id));
return reason[0]?.IS_LIVING_WITHFAMILY ? reason[0]?.IS_LIVING_WITHFAMILY :'';
}
getsecreteriatefromSixstep(id:any){
  let secreteriate = this.sixStepResponseDataforRequsition.filter((item:any) => (item.CITIZEN_ID === id));
  return secreteriate[0]?.SECRETARIAT_NAME ? secreteriate[0]?.SECRETARIAT_NAME :'';
}
}
