<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Theater Owner Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Name of the Theater:</label>
                <div class="valueCls">{{certificateInfo?.theaterName ? (certificateInfo?.theaterName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Licence Issued Date:</label>
                <div class="valueCls">{{certificateInfo?.licenceIssuedDate ? (certificateInfo?.licenceIssuedDate) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">B - Form Licence No:</label>
            <div class="valueCls">{{certificateInfo?.licenceNumber ? (certificateInfo?.licenceNumber) : '-' }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Renewal Period in Year(s):</label>
            <div class="valueCls">{{certificateInfo?.renewalPeriod ? (certificateInfo?.renewalPeriod) : '-' }}</div>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Door No:</label>
                <div class="valueCls">{{certificateInfo.theaterAddress?.doorNo ? (certificateInfo.theaterAddress?.doorNo) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo.theaterAddress?.districtName }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Street Name :</label>
                <div class="valueCls">{{certificateInfo.theaterAddress?.streetName ? (certificateInfo.theaterAddress?.streetName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo.theaterAddress?.mandalMunicipalityName}}</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{certificateInfo.theaterAddress?.villageWardName }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Pin Code:</label>
                <div class="valueCls">{{certificateInfo.theaterAddress?.pinCode ? (certificateInfo.theaterAddress?.pinCode) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList && fileList.length !== 0">
            <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList ; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Copy of B - Form Licence Certificate'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Copy of B - Form Licence Certificate:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Certificate issued by Dy.Electrical Inspector'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Certificate issued by Dy.Electrical Inspector:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Film Division Certificate'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Film Division Certificate:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Structural Soundness Certificate issued by the EE R&D'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Structural Soundness Certificate issued by the EE R&D:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Certificate issued by Divisional Fire officer'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Certificate issued by Divisional Fire officer:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
                <div *ngIf="file.documentType === 'Certificate issued by DMHO'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Certificate issued by DMHO:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>