
<div class="reportContainer">
    <div  [formGroup]="form">
        <div class="row mb-3">
            
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
            <select class="selectCls"  formControlName="district" (change)="onDistrictChange()"
            [ngClass]="{ 'is-invalid':submitted && (f.district.errors || f.district.value === 'Select') }">
                <option value="All">All</option>
                <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
            </select>
        
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="mandal" (change)="onMandalChange()"
              [ngClass]="{ 'is-invalid':submitted && (f.mandal.errors || f.mandal.value === 'Select') }">
            <option value="All">All</option>
            <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
            </select>
         
            </div>
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Village/Ward/Secretariat: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="village"  (change)="onVillageChange()"
            >
                <option value="All">All</option>
                <option *ngFor="let village of villages" [ngValue]="village">{{village.secretariatName}}</option>
            </select>
            
            </div>
            <div></div>
            <div class="col col-lg-1 col-md-2 col-sm-12 col-xs-12">

                <label class="labelCls">Year:<span class="mandatoryCls">*</span></label>
                <div class="input-group">
                    <select class="selectCls" formControlName="year" (change)="onYearChange()"
                    >
                        
                        <option *ngFor="let year of years" [ngValue]="year">{{year.year}}</option>
                    </select>
                  
                </div>
                
        </div>
        <div class="col col-lg-1 col-md-2 col-sm-12 col-xs-12">
            <label class="labelCls">Month:<span class="mandatoryCls">*</span></label>
            <div class="input-group">
                <select class="selectCls" formControlName="month"  
                >
                    
                    <option *ngFor="let month of Dates" [ngValue]="month">{{month.month}}</option>
                </select>
            </div>
            
    </div>
    </div>
<div>

            <div class="row mb-3">
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Revenue District : <span class="mandatoryCls">*</span></label>
                <select class="selectCls"  formControlName="revenuedistrict" (change)="onRevenueDistrictChange($event)"
                [ngClass]="{ 'is-invalid':submitted && (f.revenuedistrict.errors || f.revenuedistrict.value === 'Select') }">
                    <option value="All">All</option>
                    <option *ngFor="let district of revenueDistricts" [ngValue]="district">{{district.revenueDistrictName}}</option>
                </select>
            
                </div>

                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Revenue Division: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" formControlName="revenuedivision"  (change)="onRevenueDivisionChange()"
                    >
                    <option value="All">All</option>
                    <option *ngFor="let division of revenueDivisions" [ngValue]="division">
                        {{division.revenueDivisionName}}</option>
                    </select>
                    
                    </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Revenue Mandal/Municipality: <span class="mandatoryCls">*</span></label>
                <select class="selectCls" formControlName="revenuemandal" 
                  [ngClass]="{ 'is-invalid':submitted && (f.revenuemandal.errors || f.revenuemandal.value === 'Select') }">
                <option value="All">All</option>
                <option *ngFor="let mandal of revenueMandals" [ngValue]="mandal">{{mandal.revenueMandalName}}</option>
                </select>
              
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Designation: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" formControlName="designation" 
                      [ngClass]="{ 'is-invalid':submitted && (f.designation.errors || designation === 'Select') }">
                      <option value="All">All</option>
                    <option *ngFor="let designation of designations" [ngValue]="designation">{{designation.designationName}}</option>
                    </select>
                  
                    </div>
            </div>

        </div>
    

 <!-- <div class="row mb-3">  -->
    
      <div class="tabClass">
        <ul>
            <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li> 
        </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getRevenueShareReportData()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" (click)="goToHome()">Home</button>
        </div>
      </div>
 <!-- </div>  -->
 </div>
<div class="row">
    <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">

        <!-- ----------Abstract Wise--------- -->

<div class="table-responsive tableFixHead" *ngIf="showReports  && format =='Abstract'">
    <table class="tableCls" >
        <thead> 
            <tr>
                <!-- <th [attr.colspan]="noOfCol">{{date}} Month Revenue Share Report</th> -->
                <th [attr.colspan]="noOfCol">Revenue Share Report</th>
            </tr>
            <tr>
                <th  [attr.rowspan]="3">S.No</th>
                <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'lgD_DIST_CODE'">District Code<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'districT_NAME'">District Name<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'lgD_MANDAL_CODE'" >Mandal/ULB Code <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'" >Mandal/ ULB Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'">Secretariat code <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'">Secretariat Name<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'revenuE_DISTRICT_NAME'">Revenue Disitrict<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'revenuE_DIVISION_NAME'">Revenue Division<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'revenuE_MANDAL_NAME'">Revenue Mandal<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'caT_TYPE'">CAT A/B<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'ruraL_URBAN_FLAG'">U/R<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'category'">Category<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'toT_SRVS'">Total No. of Services happened<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'toT_AMT'">Total Amount<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'sucC_SRVS'">Services realized<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'sucC_AMT'">amt realized<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'depT_SHARE'">Department Share in Service Charge<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'gswS_SHARE'">GSWS/ESD<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'colL_SRVS'">Collector<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'dldO_SRVS'">DLDOs<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mrO_SRVS'">MRO<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mpdO_SRVS'">MPDO<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mC_SRVS'">MC<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pS_SRVS'">Secretariat<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'dA_AMT'">Cash Remitting/ Handling to DA/WEDPS<mdb-icon fas icon="sort"></mdb-icon></th>
              

            </tr>
     
                   
        </thead>

        <tbody *ngIf="items && items.length > 0"> 
<tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;"> 
            <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
            <td>{{item.lgD_DIST_CODE}}</td>
            <td>{{item.districT_NAME}}</td>
            <!-- <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
            <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td> -->
            <td>{{item.lgD_MANDAL_CODE}}</td>
            <td>{{item.mandaL_NAME}}</td>
            <td>{{item.secretariaT_CODE}}</td>
            <td>{{item.secretariaT_NAME}}</td>
            <td>{{item.revenuE_DISTRICT_NAME}}</td>
            <td>{{item.revenuE_DIVISION_NAME}}</td>
            <td>{{item.revenuE_MANDAL_NAME}}</td>
            <td>{{item.caT_TYPE}}</td>
            <!-- <td class="openWithin text-right"><a (click) ="getPendingTransaction(item, beyondSLAModal )" class="linkCls" ><b>{{item.pendinG_TRANS}}</b></a></td> -->
            <td>{{item.ruraL_URBAN_FLAG}}</td>
            <td>{{item.category}}</td>
            <td>{{item.toT_SRVS}}</td>
            <td>{{item.toT_AMT}}</td>
            <td>{{item.sucC_SRVS}}</td>
            <td>{{item.sucC_AMT}}</td>
            <td>{{item.depT_SHARE}}</td>
            <td>{{item.gswS_SHARE}}</td>
            <td>{{item.colL_AMT}}</td>            
            <!-- <td>{{item.colL_AMT}}</td> -->
            <td>{{item.dldO_AMT}}</td>
            <!-- <td>{{item.dldO_AMT}}</td> -->
            <td>{{item.mrO_AMT}}</td>
            <td>{{item.mpdO_AMT}}</td>
            <!-- <td>{{item.mpdO_AMT}}</td> -->
            <td>{{item.mC_AMT}}</td>
            <td>{{item.pS_AMT}}</td>
            <td>{{item.dA_AMT}}</td>
        </tr> 
        </tbody>
         <tbody *ngIf="items && items.length === 0"> 
        
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    </div>

 <!-- District Wise , Mandal Wise,  Division Wise, secretariate wise  -->
    <div class="table-responsive tableFixHead" *ngIf="showReports  && (format =='District Wise' || format =='Division Wise' || format =='Mandal Wise' ||format =='Secretariat Wise')">
        <table class="tableCls" >
            <thead> 
                <tr>
                    <th [attr.colspan]="noOfCol">Revenue Share Report</th>
                </tr>
                <tr>
                    <th  [attr.rowspan]="3">S.No</th>
                    <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'lgD_DIST_CODE'" *ngIf="format !== 'Division Wise'">District Code<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'districT_NAME'" *ngIf="format !== 'Division Wise'">District Name<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'revenuE_DISTRICT_NAME'" *ngIf="format === 'Division Wise'">Revenue District<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'revenuE_DIVISION_NAME'" *ngIf="format === 'Division Wise'">Revenue Division<mdb-icon fas icon="sort"></mdb-icon></th>
                    <!-- <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'revenuE_MANDAL_NAME'" *ngIf="format === 'Division Wise'">Revenue Mandal<mdb-icon fas icon="sort"></mdb-icon></th> -->
                    <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'lgD_MANDAL_CODE'" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal Code<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'mandaL_NAME'" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal Name<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'" *ngIf="format === 'Secretariat Wise'">Secretariat Code<mdb-icon fas icon="sort"></mdb-icon></th>
                     <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'" *ngIf="format === 'Secretariat Wise'">Secretariat name<mdb-icon fas icon="sort"></mdb-icon></th>                 
                  
                    <th [attr.rowspan]="2" [attr.colspan]="2">District Collector</th>
                    <th [attr.rowspan]="2" [attr.colspan]="2">DLDO</th>
                    <th [attr.rowspan]="2" [attr.colspan]="2">MPDO</th>
                    <th [attr.rowspan]="2" [attr.colspan]="2">MC</th>
                    <th [attr.rowspan]="2" [attr.colspan]="2">MRO</th>
                    <th [attr.rowspan]="2" [attr.colspan]="2">PS</th>
                    <th [attr.rowspan]="2" [attr.colspan]="2">DA</th>
                    <th [attr.rowspan]="3" [attr.colspan]="1">Total Revenue Share</th>
                  
                    <!-- <th [attr.colspan]="4">Bill Desk Challan Deposited</th> -->
                </tr>
                <tr>
                </tr>
                <tr>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'colL_SRVS'">Total Service<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'colL_AMT'">Total Revenue Share<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'dldO_SRVS'">Total Service<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'dldO_AMT'">Total Revenue Share<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mpdO_SRVS'">Total Service<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mpdO_AMT'">Total Revenue Share<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mC_SRVS'">Total Service<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mC_AMT'">Total Revenue Share <mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mrO_SRVS'">Total Service<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mrO_AMT'">Total Revenue Share<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pS_SRVS'">Total Service<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'pS_AMT'">Total Revenue Share<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'dA_SRVS'">Total Service<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'dA_AMT'">Total Revenue Share<mdb-icon fas icon="sort"></mdb-icon></th>
                   <!-- <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'casH_TRANS'">Total Service<mdb-icon fas icon="sort"></mdb-icon></th> -->
                   <!-- <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'totaL_SHARE'">Total Revenue Share <mdb-icon fas icon="sort"></mdb-icon></th> -->
                
                </tr>
                
            </thead>
    
            <tbody *ngIf="items && items.length > 0"> 
                <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;"> 
                            <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                            <td *ngIf="format !== 'Division Wise'">{{item.lgD_DIST_CODE}}</td>
                            <td *ngIf="format !== 'Division Wise'">{{item.districT_NAME}}</td>
                            <td *ngIf="format === 'Division Wise'">{{item.revenuE_DISTRICT_NAME}}</td>
                            <td *ngIf="format === 'Division Wise'">{{item.revenuE_DIVISION_NAME}}</td>
                            <!-- <td *ngIf="format === 'Division Wise'">{{item.revenuE_MANDAL_NAME}}</td> -->
                            <!-- <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
                            <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td> -->
                            <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.lgD_MANDAL_CODE}}</td>
                            <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
                            <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_CODE}}</td>
                            <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
                        
                            <td>{{item.colL_SRVS}}</td>            
                            <td>{{item.colL_AMT}}</td> 
                            <td>{{item.dldO_SRVS}}</td>
                            <td>{{item.dldO_AMT}}</td> 
                            
                            <td>{{item.mpdO_SRVS}}</td>
                            <td>{{item.mpdO_AMT}}</td>

                            <td>{{item.mC_SRVS}}</td>
                            <td>{{item.mC_AMT}}</td>
                             <td>{{item.mrO_SRVS}}</td> 
                            <td>{{item.mrO_AMT}}</td>
                            <td>{{item.pS_SRVS}}</td>
                            <td>{{item.pS_AMT}}</td>            
                            <td>{{item.dA_SRVS}}</td>
                            <td>{{item.dA_AMT}}</td>
                            <!-- <td></td>   -->
                            <td>{{item.totaL_SHARE}}</td>
                        </tr> 
                        </tbody>
             <tbody *ngIf="items && items.length === 0"> 
            
                <tr>
                    <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </table>
        </div>

        <!-- -------Designation Wise -------------->
    <div class="table-responsive tableFixHead" *ngIf="showReports  && format =='Designation Wise'">
        <table class="tableCls" >
            <thead> 
                <tr>
                    <th [attr.colspan]="noOfCol">Revenue Share Report</th>
                </tr>
                <tr>
                    <th  [attr.rowspan]="3">S.No</th>
                    <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'lgD_DIST_CODE'" *ngIf="designation !== 'MRO'">District Code<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'districT_NAME'" *ngIf="designation !== 'MRO'">District Name<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col"  [mdbTableSort]="items" [sortBy]="'districT_NAME'" *ngIf="designation === 'MRO'">Revenue District Name<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" *ngIf="designation === 'DLDO' || designation === 'MRO'" [mdbTableSort]="items" [sortBy]="'revenuE_DIVISION_NAME'">Revenue Division Name<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th  [attr.rowspan]="3"  aria-controls="tableSortExample" scope="col" *ngIf="designation === 'MRO'" [mdbTableSort]="items" [sortBy]="'revenuE_MANDAL_NAME'">Revenue Mandal Name<mdb-icon fas icon="sort"></mdb-icon></th>
                  
                  
                <th  [attr.rowspan]="3" *ngIf="designation === 'MC' || designation === 'MPDO'  || designation === 'PS' || designation === 'DA'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'lgD_MANDAL_CODE'" >Mandal/ULB Code <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  [attr.rowspan]="3" *ngIf="designation === 'MC' || designation === 'MPDO' || designation === 'PS' || designation === 'DA'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'" >Mandal/ ULB Name <mdb-icon fas icon="sort"></mdb-icon></th>
                
                <th  [attr.rowspan]="3" *ngIf="designation === 'PS' || designation === 'DA'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'" >Secretariat Code <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  [attr.rowspan]="3" *ngIf="designation === 'PS' || designation === 'DA'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'" >Secretariat Name <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  [attr.rowspan]="3" *ngIf="designation === 'MC' || designation === 'MPDO' || designation === 'PS' || designation === 'DA'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'ruraL_URBAN_FLAG'">U/R<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  [attr.colspan]="5">GSWS SERVICES</th>
                  
                </tr>
                <tr>
                    <th aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'toT_SRVS'">Total No. of Service Requests raised<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'toT_AMT'">Total Amount<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample" [mdbTableSort]="items" [sortBy]="'sucC_SRVS'">Total no. of Services & Payments Reconciled<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'sucC_AMT'">Total Amount Reconciled<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample" [mdbTableSort]="items" [sortBy]="'colL_AMT'" *ngIf="designation === 'DC'">Revenue Share of DC<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'dldO_AMT'" *ngIf="designation === 'DLDO'">Revenue Share of DLDO<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'mC_SRVS'" *ngIf="designation === 'MC'">Revenue Share of MC<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'mpdO_AMT'" *ngIf="designation === 'MPDO'">Revenue Share of MPDO<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'mrO_AMT'" *ngIf="designation === 'MRO'">Revenue Share of MRO<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'pS_AMT'" *ngIf="designation === 'PS'">Revenue Share of PS<mdb-icon fas icon="sort"></mdb-icon></th>
                   <th aria-controls="tableSortExample"  [mdbTableSort]="items" [sortBy]="'dA_AMT'" *ngIf="designation === 'DA'">Revenue Share of DA<mdb-icon fas icon="sort"></mdb-icon></th>
                </tr>
                
            </thead>
    
            <tbody *ngIf="items && items.length > 0"> 
                <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;"> 
                    <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                    <td *ngIf="designation !== 'MRO'">{{item.lgD_DIST_CODE}}</td>
                    <td *ngIf="designation !== 'MRO'">{{item.districT_NAME}}</td>
                    <td *ngIf="designation === 'MRO'">{{item.revenuE_DISTRICT_NAME}}</td>
                    <td *ngIf="designation === 'DLDO' || designation === 'MRO'">{{item.revenuE_DIVISION_NAME}}</td>
                    <td *ngIf="designation === 'MRO'">{{item.revenuE_MANDAL_NAME}}</td>
                    <td *ngIf="designation === 'MC' || designation === 'MPDO'  || designation === 'PS' || designation === 'DA'" >{{item.lgD_MANDAL_CODE}}</td>
                    <td *ngIf="designation === 'MC' || designation === 'MPDO'  || designation === 'PS' || designation === 'DA'" >{{item.mandaL_NAME}}</td>
                    <td *ngIf="designation === 'PS' || designation === 'DA'">{{item.secretariaT_CODE}}</td>
                    <td *ngIf="designation === 'PS' || designation === 'DA'">{{item.secretariaT_NAME}}</td>
                    <td  *ngIf="designation === 'MC' || designation === 'MPDO' || designation === 'PS' || designation === 'DA'" >{{item.ruraL_URBAN_FLAG}}</td>
                    <td>{{item.toT_SRVS}}</td>
                    <td>{{item.toT_AMT}}</td>
                    <td>{{item.sucC_SRVS}}</td>
                    <td>{{item.sucC_AMT}}</td>
                            <td *ngIf="designation === 'DC'">{{item.colL_AMT}}</td>  
                            <!-- <td>{{item.dldO_SRVS}}</td> -->
                            <td *ngIf="designation === 'DLDO'">{{item.dldO_AMT}}</td> 
                            
                            <!-- <td>{{item.mpdO_SRVS}}</td> -->
                            <td *ngIf="designation === 'MPDO'">{{item.mpdO_AMT}}</td>

                            <!-- <td>{{item.mC_SRVS}}</td> -->
                            <td *ngIf="designation === 'MC'">{{item.mC_AMT}}</td>
                            
                            <td *ngIf="designation === 'MRO'">{{item.mrO_AMT}}</td>
                            
                            <td *ngIf="designation === 'PS'">{{item.pS_AMT}}</td>            
                            
                            <td *ngIf="designation === 'DA'">{{item.dA_AMT}}</td>
                          
                        </tr> 
                        </tbody>
             <tbody *ngIf="items && items.length === 0"> 
            
                <tr>
                    <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </table>
        </div>
   

</div>
</div>



<div class="mt-4 btnHide" *ngIf="showReports">
    <div class="float-left" *ngIf="items.length > 0">
        <label>Requests per page: </label>
        <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
            <option  value="10">10</option>
            <option  value="20">20</option>
            <option  value="30">30</option>
            <option  value="40">40</option>
            <option  value="50">50</option>
        </select> 
    </div>
    <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
        [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
    </ngb-pagination>
</div> 



<!-- Code for download Excel -->

<!-- Download excel for Abstract -->
<div class="table-responsive tableFixHead" #TABLE class="hideTable">
    <table class="tableCls" >
        <thead>
         <tr>
             <th [attr.colspan]="noOfCol">Revenue Share Report</th>
         </tr>
         <tr>
            <th>S.No</th>
            <th scope="col">District Code</th>
            <th scope="col">District Name</th>
            <th scope="col">Mandal/ULB Code </th>
            <th scope="col">Mandal/ ULB Name </th>
            <th scope="col">Secretariat code </th>
            <th scope="col">Secretariat Name</th>
            <th scope="col">Revenue Disitrict</th>
            <th scope="col">Revenue Division</th>
            <th scope="col">Revenue Mandal</th>
            <th scope="col">CAT A/B</th>
            <th scope="col">U/R</th>
            <th scope="col">Category</th>
            <th scope="col">Total No. of Services happened</th>
            <th scope="col">Total Amount</th>
            <th scope="col">Services realized</th>
            <th scope="col">amt realized</th>
            <th scope="col">Department Share in Service Charge</th>
            <th scope="col">GSWS/ESD</th>
            <th scope="col">Collector</th>
            <th scope="col">DLDOs</th>
            <th scope="col">MRO</th>
            <th scope="col">MPDO</th>
            <th scope="col">MC</th>
            <th scope="col">Secretariat</th>
            <th scope="col">Cash Remitting/ Handling to DA/WEDPS</th>
        </tr>
        </thead>

        
        <tbody *ngIf="items && items.length > 0"> 
            <tr *ngFor="let item of items;let i = index;"> 
                       <td width="100px">{{i + 1}}</td>
                        <td>{{item.lgD_DIST_CODE}}</td>
                        <td>{{item.districT_NAME}}</td>
                        <td>{{item.lgD_MANDAL_CODE}}</td>
                        <td>{{item.mandaL_NAME}}</td>
                        <td>{{item.secretariaT_CODE}}</td>
                        <td>{{item.secretariaT_NAME}}</td>
                        <td>{{item.revenuE_DISTRICT_NAME}}</td>
                        <td>{{item.revenuE_DIVISION_NAME}}</td>
                        <td>{{item.revenuE_MANDAL_NAME}}</td>
                        <!-- <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
                        <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td> -->
                        
                    
                    


                        <td>{{item.caT_TYPE}}</td>
                        <!-- <td class="openWithin text-right"><a (click) ="getPendingTransaction(item, beyondSLAModal )" class="linkCls" ><b>{{item.pendinG_TRANS}}</b></a></td> -->
                        <td>{{item.ruraL_URBAN_FLAG}}</td>
                        <td>{{item.category}}</td>
                        <td>{{item.toT_SRVS}}</td>
                        <td>{{item.toT_AMT}}</td>
                        <td>{{item.sucC_SRVS}}</td>
                        <td>{{item.sucC_AMT}}</td>
                        <td>{{item.depT_SHARE}}</td>
                        <td>{{item.gswS_SHARE}}</td>
                        <td>{{item.colL_AMT}}</td>            
                        <!-- <td>{{item.colL_AMT}}</td> -->
                        <td>{{item.dldO_AMT}}</td>
                        <!-- <td>{{item.dldO_AMT}}</td> -->
                        <td>{{item.mrO_AMT}}</td>
                        <td>{{item.mpdO_AMT}}</td>
                        <!-- <td>{{item.mpdO_AMT}}</td> -->
                        <td>{{item.mC_AMT}}</td>
                        <td>{{item.pS_AMT}}</td>
                        <td>{{item.dA_AMT}}</td>

                    </tr> 
                    </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
 </div> 
 
 <!-- Download Ecxel for Designation -->
<div class="table-responsive tableFixHead" #TABLEDESIGNATION class="hideTable">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th [attr.colspan]="noOfCol">Revenue Share Report</th>
            </tr>
            <tr>
                <th>S.No</th>
                <th  scope="col" *ngIf="designation !== 'MRO'">District Code</th>
                <th  scope="col"  *ngIf="designation !== 'MRO'">District Name</th>
                <th  scope="col"  *ngIf="designation === 'MRO'">Revenue District Name</th>
                <th  scope="col" *ngIf="designation === 'DLDO' || designation === 'MRO'">Revenue Division Name</th>
                <th  scope="col" *ngIf="designation === 'MRO'">Revenue Mandal Name</th>
              
              
            <th *ngIf="designation === 'MC' || designation === 'MPDO'  || designation === 'PS' || designation === 'DA'"  scope="col" [mdbTableSort]="items" >Mandal/ULB Code</th>
            <th *ngIf="designation === 'MC' || designation === 'MPDO' || designation === 'PS' || designation === 'DA'" scope="col">Mandal/ ULB Name</th>
            
            <th *ngIf="designation === 'PS' || designation === 'DA'"  scope="col">Secretariat Code</th>
            <th *ngIf="designation === 'PS' || designation === 'DA'"  scope="col">Secretariat Name</th>
            <th *ngIf="designation === 'MC' || designation === 'MPDO' || designation === 'PS' || designation === 'DA'" scope="col">U/R</th>
            <th  [attr.colspan]="5">GSWS SERVICES</th>
              
            </tr>
            <tr>
                <th>Total No. of Service Requests raised</th>
               <th>Total Amount</th>
               <th>Total no. of Services & Payments Reconciled</th>
               <th>Total Amount Reconciled</th>
               <th *ngIf="designation === 'DC'">Revenue Share of DC</th>
               <th *ngIf="designation === 'DLDO'">Revenue Share of DLDO</th>
               <th *ngIf="designation === 'MC'">Revenue Share of MC</th>
               <th *ngIf="designation === 'MPDO'">Revenue Share of MPDO</th>
               <th *ngIf="designation === 'MRO'">Revenue Share of MRO</th>
               <th *ngIf="designation === 'PS'">Revenue Share of PS</th>
               <th *ngIf="designation === 'DA'">Revenue Share of DA</th>
            </tr>
            
        </thead>

        <tbody *ngIf="items && items.length > 0"> 
            <tr *ngFor="let item of items;let i = index;"> 
                       <td width="100px">{{i + 1}}</td>
                <td *ngIf="designation !== 'MRO'">{{item.lgD_DIST_CODE}}</td>
                <td *ngIf="designation !== 'MRO'">{{item.districT_NAME}}</td>
                <td *ngIf="designation === 'MRO'">{{item.revenuE_DISTRICT_NAME}}</td>
                <td *ngIf="designation === 'DLDO' || designation === 'MRO'">{{item.revenuE_DIVISION_NAME}}</td>
                <td *ngIf="designation === 'MRO'">{{item.revenuE_MANDAL_NAME}}</td>
                <td *ngIf="designation === 'MC' || designation === 'MPDO'  || designation === 'PS' || designation === 'DA'" >{{item.lgD_MANDAL_CODE}}</td>
                <td *ngIf="designation === 'MC' || designation === 'MPDO'  || designation === 'PS' || designation === 'DA'" >{{item.mandaL_NAME}}</td>
                <td *ngIf="designation === 'PS' || designation === 'DA'">{{item.secretariaT_CODE}}</td>
                <td *ngIf="designation === 'PS' || designation === 'DA'">{{item.secretariaT_NAME}}</td>
                <td  *ngIf="designation === 'MC' || designation === 'MPDO' || designation === 'PS' || designation === 'DA'" >{{item.ruraL_URBAN_FLAG}}</td>
                <td>{{item.toT_SRVS}}</td>
                <td>{{item.toT_AMT}}</td>
                <td>{{item.sucC_SRVS}}</td>
                <td>{{item.sucC_AMT}}</td>
                        <td *ngIf="designation === 'DC'">{{item.colL_AMT}}</td>  
                        <!-- <td>{{item.dldO_SRVS}}</td> -->
                        <td *ngIf="designation === 'DLDO'">{{item.dldO_AMT}}</td> 
                        
                        <!-- <td>{{item.mpdO_SRVS}}</td> -->
                        <td *ngIf="designation === 'MPDO'">{{item.mpdO_AMT}}</td>

                        <!-- <td>{{item.mC_SRVS}}</td> -->
                        <td *ngIf="designation === 'MC'">{{item.mC_AMT}}</td>
                        
                        <td *ngIf="designation === 'MRO'">{{item.mrO_AMT}}</td>
                        
                        <td *ngIf="designation === 'PS'">{{item.pS_AMT}}</td>            
                        
                        <td *ngIf="designation === 'DA'">{{item.dA_AMT}}</td>
                      
                    </tr> 
                    </tbody>
                    <tbody *ngIf="items && items.length === 0">
                        <tr>
                            <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                        </tr>
                    </tbody>
    </table>
</div> 
<!-- Download Excel for District, mandal, seceretariate, division wise  -->

<div class="table-responsive tableFixHead" #TABLEDATA class="hideTable">
    <table class="tableCls" >
    
            <thead> 
                <tr>
                    <th [attr.colspan]="noOfCol">Revenue Share Report</th>
                </tr>
                <tr>
                    <th  [attr.rowspan]="3">S.No</th>
                    <th  [attr.rowspan]="3"   scope="col"   *ngIf="format !== 'Division Wise'">District Code</th>
                    <th  [attr.rowspan]="3"   scope="col"   *ngIf="format !== 'Division Wise'">District Name</th>
                    <th  [attr.rowspan]="3"   scope="col"   *ngIf="format === 'Division Wise'">Revenue District</th>
                    <th  [attr.rowspan]="3"   scope="col"   *ngIf="format === 'Division Wise'">Revenue Division</th>
                    <!-- <th  [attr.rowspan]="3"   scope="col"  [mdbTableSort]="items" [sortBy]="'revenuE_MANDAL_NAME'" *ngIf="format === 'Division Wise'">Revenue Mandal</th> -->
                    <th  [attr.rowspan]="3"   scope="col"   *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal Code</th>
                    <th  [attr.rowspan]="3"   scope="col"   *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal Name</th>
                    <th  [attr.rowspan]="3"   scope="col"   *ngIf="format === 'Secretariat Wise'">Secretariat Code</th>
                     <th  [attr.rowspan]="3"   scope="col"   *ngIf="format === 'Secretariat Wise'">Secretariat name</th>                 
                  
                    <th [attr.rowspan]="2" [attr.colspan]="2">District Collector</th>
                    <th [attr.rowspan]="2" [attr.colspan]="2">DLDO</th>
                    <th [attr.rowspan]="2" [attr.colspan]="2">MPDO</th>
                    <th [attr.rowspan]="2" [attr.colspan]="2">MC</th>
                    <th [attr.rowspan]="2" [attr.colspan]="2">MRO</th>
                    <th [attr.rowspan]="2" [attr.colspan]="2">PS</th>
                    <th [attr.rowspan]="2" [attr.colspan]="2">DA</th>
                    <th [attr.rowspan]="3" scope="col">Total Revenue Share</th>
                  
                    <!-- <th [attr.colspan]="4">Bill Desk Challan Deposited</th> -->
                </tr>
                <tr>
                </tr>
                <tr>
                    <th  scope="col">Total Service</th>
                   <th  scope="col">Total Revenue Share</th>
                   <th  scope="col">Total Service</th>
                   <th  scope="col">Total Revenue Share</th>
                   <th  scope="col">Total Service</th>
                   <th  scope="col">Total Revenue Share</th>
                   <th  scope="col">Total Service</th>
                   <th  scope="col">Total Revenue Share </th>
                   <th  scope="col">Total Service</th>
                   <th  scope="col">Total Revenue Share</th>
                   <th  scope="col">Total Service</th>
                   <th  scope="col">Total Revenue Share</th>
                   <th  scope="col">Total Service</th>
                   <th  scope="col">Total Revenue Share</th>
                   <!-- <th  scope="col">Total Service</th> -->
                   <!-- <th  scope="col">Total Revenue Share</th> -->
                
                </tr>
                
            </thead>
    
            <tbody *ngIf="items && items.length > 0"> 
                <tr *ngFor="let item of items;let i = index;"> 
                           <td width="100px">{{i + 1}}</td>
                            <td *ngIf="format !== 'Division Wise'">{{item.lgD_DIST_CODE}}</td>
                            <td *ngIf="format !== 'Division Wise'">{{item.districT_NAME}}</td>
                            <td *ngIf="format === 'Division Wise'">{{item.revenuE_DISTRICT_NAME}}</td>
                            <td *ngIf="format === 'Division Wise'">{{item.revenuE_DIVISION_NAME}}</td>
                            <!-- <td *ngIf="format === 'Division Wise'">{{item.revenuE_MANDAL_NAME}}</td> -->
                            <!-- <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
                            <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td> -->
                            <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.lgD_MANDAL_CODE}}</td>
                            <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
                            <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_CODE}}</td>
                            <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
                        
                            <td>{{item.colL_SRVS}}</td>            
                            <td>{{item.colL_AMT}}</td> 
                            <td>{{item.dldO_SRVS}}</td>
                            <td>{{item.dldO_AMT}}</td> 
                            
                            <td>{{item.mpdO_SRVS}}</td>
                            <td>{{item.mpdO_AMT}}</td>

                            <td>{{item.mC_SRVS}}</td>
                            <td>{{item.mC_AMT}}</td>
                             <td>{{item.mrO_SRVS}}</td> 
                            <td>{{item.mrO_AMT}}</td>
                            <td>{{item.pS_SRVS}}</td>
                            <td>{{item.pS_AMT}}</td>            
                            <td>{{item.dA_SRVS}}</td>
                            <td>{{item.dA_AMT}}</td>
                            <!-- <td></td>   -->
                            <td>{{item.totaL_SHARE}}</td>
                        </tr> 
                        </tbody>
             <tbody *ngIf="items && items.length === 0"> 
            
                <tr>
                    <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                </tr>
            </tbody>
    </table>
</div> 
</div>