

<section id="about">
    <div class="about_part">
        <div class="container mx-auto">

            <div class="row">
                <div class="col-lg-6">
                    <img src="../../assets/images/class_03.jpg" class="img-fluid" alt="aboutSlider1">

                </div>
                <div class="col-lg-6">
                    <div class="about_text">
                        <h1>About us</h1>
                            <h5>గ్రామ - వార్డు సచివాలయము
                            సచివాలయాల ద్వారా ఇంటి ముంగిటకే పాలన...</h5>
                        <p style="line-height: 32px;"> దేశంలో ఇప్పటి వరకు ఎక్కడా లేని విధంగా ఆంధ్రప్రదేశ్ ప్రభుత్వం గ్రామ, వార్డు సచివాలయాల
                            వ్యవస్థను తెచ్చింది. అవినీతికి కానీ, వివక్షతకు కానీ తావు ఇవ్వకూడదని, పరిపాలన అన్నది
                            ప్రజలకు చేరువ కావాలన్న ప్రధాన ఉద్దేశంతో రాష్ట్ర ముఖ్యమంత్రి శ్రీ వై యస్ జగన్ మోహన్
                            రెడ్డి గారు ఈ సచివాలయ వ్యవస్థకు శ్రీకారం చుట్టారు. గ్రామ,వార్డు సచివాలయాల్లో దాదాపు 35
                            ప్రభుత్వ శాఖలకు సంబంధించి 500 సేవలు అందుబాటులో ఉంటాయి.
                         </p>
                     
                         <p style="line-height: 32px;">
                             పింఛన్ కావాలన్నా..రేషన్ కార్డు కావాలన్నా.. ఇంటి పట్టాలు కావాలన్నా.. తాగునీటి సరఫరా సమస్య ఉన్నా.. సివిల్ పనులకు సంబంధించిన పనులు ఉన్నా.. వైద్యం కానీ.. ఆరోగ్యం కానీ.. రెవిన్యూ కానీ.. భూముల సర్వేకానీ.. శిశు సంక్షేమం కానీ.. డెయిరీ కానీ, పౌల్టీరు రంగాల సేవలు కానీ.. ఇలాంటివెన్నో గ్రామ సచివాలయాల్లో అర్జీ పెట్టుకున్న 72 గంటలోనే సమస్యను పరిష్కరిస్తారు. </p>

                        <p style="line-height: 32px;">
                            అర్హత ఉన్న ఏ ఒక్కరూ తమకు ప్రభుత్వ సంక్షేమ ఫలాలు అందలేదని బాధపడే సమస్య ఇకపై ఉండదు.ప్రతి పేదవాడి ముఖంలో చిరునవ్వు చూడాలన్నదే ఈ ప్రభుత్వ లక్ష్యం. ప్రజల కోసం ఏర్పాటు చేసిన ఈ గ్రామ సచివాలయాలు ప్రజలు బాగా సద్వినియోగం చేసుకోవాలి. ఇది ప్రజల ప్రభుత్వం! ప్రజా సంక్షేమమే ఈ ప్రభుత్వ లక్ష్యం!!
                        </p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



