import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';
@Component({
  selector: 'app-pensionform-view',
  templateUrl: './pensionform-view.component.html',
  styleUrls: ['./pensionform-view.component.css']
})
export class PensionformViewComponent implements OnInit {
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  items: any[] = [];
  commonConstants: any = CommonConstants;
  constructor() { }

  ngOnInit(): void {
    console.log(this.certificateInfo, "cerificateinfo");
    console.log(this.fileList, "fileList");
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    console.log(file);
    this.download.emit(file);
  }

}
