<div class="container-fluid mainCls">
    <div class="breadcrumbContainer  mr-3">
        <div class="breadcrumbCls">
            GSWS /Application Submission / Health And Family Welfare Department<span class="activePage">/ Check Dr NTR Vaidya Seva Card Status</span>
        </div>
    </div>
<div class="mainPageContainer mt-15 mr-3">
    <h6 class="mb-3 mt-15"><b>Check Dr NTR Vaidya Seva Card Status: </b></h6>
    <hr class="hrCls mt-4">
    <form [formGroup]="aarogyaSri">

        <div class="row  mb-3"> 
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
              
                    <label class="subHeadingCls">Application Number:</label>
                    </div>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <input type="text" class="inputCls value" maxlength="15" 
                    name="referenceNo" id="referenceNo" formControlName="referenceNo"   [ngClass]="{ 'is-invalid':submitted && f.referenceNo.errors }">
                    <div *ngIf="submitted && f.referenceNo.errors" class="invalid-feedback">
                       
                        <div *ngIf="f.referenceNo.errors?.pattern || f.referenceNo.errors?.minlength">Please enter valid Application Number</div>
                        <!-- <div *ngIf="f.referenceNo.errors?.minlength">Please enter valid Application number</div> -->
                    </div>
                </div>
               
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12"> OR</div>

            <div class="row  mb-3">
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <label class="subHeadingCls">UHID:</label></div>
                        <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <input type="text" class="inputCls value" maxlength="13" numbersOnly
                        [ngClass]="{ 'is-invalid':submitted && f.uhid.errors }"
                        name="uhid" id="uhid"  formControlName="uhid">
                        <div *ngIf="submitted && f.uhid.errors" class="invalid-feedback">
                            <div *ngIf="f.uhid.errors?.minlength">Please enter valid UHID number</div>
                          
                    
                        </div>
                    </div>
                    
                </div>
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12"> OR</div>
               
               <div class="row"  mb-3>
                    <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                  
                            <label class="subHeadingCls">Aadhaar No:</label></div>
                            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <input type="text" class="inputCls value" maxlength="12" numbersOnly
                            name="aadharNo" id="aadharNo" formControlName="aadharNo" 
                            [ngClass]="{ 'is-invalid':submitted && f.aadharNo.errors }">
                            <div *ngIf="submitted && f.aadharNo.errors" class="invalid-feedback">
                                <div *ngIf="f.aadharNo.errors.whiteSpaceValidate || f.aadharNo.errors?.aadharValidate || f.aadharNo.errors?.minlength">Please enter valid  Aadhaar Number</div>

                            </div>
                        </div>
                    </div>
                </form>


                <div class="row mb-3 mt-4">
                    <div class="btnHolder text-center col-12">
                        <button class="btn btn-primary mx-auto d-block" (click)="submit()">Check Status</button>
                    </div>
                </div>

                <div class="mr-3" *ngIf="showTable">
                    <h6 class="mb-3 mt-15"><b>Status & Secratariat Details: </b></h6>
                    
                    <table class="tableCls">
                        <thead>
                            <tr>
                            <th>Reference Id</th>
                            <th>UHID</th>
                            <th>Secretariat Name</th>
                            <th>Member Name</th>
                            <th>Aadhaar Id</th>   
                            <th>Resident ID</th>  
                            <th>Relation</th>  
                            <th>Age</th>    
                            <th>Volunteer Name</th>
                            <th>Status</th>  
                            <th>Requests For</th> 
                            <th>Entered/Edit Date</th>
                            <th>Application Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let member of members; let i = index">
                                <tr *ngIf="member.MigrationReason !== 'Death'">
                                    <td>{{member.referenceId}}</td> 
                                    <td>{{member.uhid}}</td>
                                    <td>{{member.secretariatName}}</td>
                                    <td>{{(member.memberName) ? member.memberName : ''}}</td>
                                    <td>{{member.aadharId}}</td> 
                                    <td>{{(member.residentId !== "\\N") ? (member.residentId ) : ''}}</td>
                                    <td>{{(member.relation !== '' && member.relation !='\\N') ? member.relation : '' }}</td>
                                    <td>{{member.age}}</td>
                                    <td>{{member.volunteerName}}</td> 
                                    <td>Eligible</td>
                                    <td>{{member.requestsFor}}</td>
                                    <td>{{(member.enteredDate !== "\\N" &&  member.enteredDate) ? (member.enteredDate| date: 'dd/MM/yyyy'): ''}}</td>          
                                    <td>{{member.status}}</td>  </tr>
                                
                            </ng-container> 
                               
                       
                            
                           
                        </tbody>
                    </table>
                    
                       
                    </div>
                </div>
                </div>