<div class="certificateInfo">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo ) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo">
    <div class="subHeadingCls mb-3">Land Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Current Year:</label>
                <div class="valueCls">{{certificateInfo?.currentYear ? (certificateInfo?.currentYear) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Type of Service:</label>
                <div class="valueCls">{{certificateInfo.typeofService ? (certificateInfo.typeofService) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">District:</label>
            <div class="valueCls">{{screenDistrict }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Mandal:</label>
            <div class="valueCls">{{screenMandal }}</div>
        </div>
        
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
           
            <div class="fieldHolder">
                <label class="labelCls">Khata No:</label>
                <div class="valueCls">{{certificateInfo.khataNo ? (certificateInfo.khataNo) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
           
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{screenVillage }}</div>
            </div>
        </div>
    </div>

    
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                <tr> 
                    <th>Directions</th>
                    <th>Survey No</th>
                    <th>Name</th>
                    <th>Father/Husband Name</th>
                    <th>Address</th>
                    <th>Contact Number</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of certificateInfo?.boundariesInfo">
                        <td>{{item.direction ? (item.direction) : '-' }}</td>
                        <td>{{item.surveyNo ? (item.surveyNo) : '-' }}</td>
                        <td>{{item.ownerName?.firstName ? (item.ownerName?.firstName) : '-' }}</td>
                        <td>{{item.ownerName?.fatherHusbandName ? (item.ownerName?.fatherHusbandName) : '-' }}</td>
                        <td>{{item.address ? (item.address) : '-' }}</td>
                        <td>{{item.contactNo ? (item.contactNo) : '-' }}</td>
                    </tr>
                </tbody>
                </table>
            </div>
    </div>
</div>
<div class="certificateInfo">
    <div class="row">
        <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
            <ng-container *ngIf="fileList">
                <div class="subHeadingCls mb-3">List of Documents</div>
            <div class="fieldHolder" *ngFor="let file of fileList ; let i = index">
                <div *ngIf="file.documentType === 'Application Form'">
                    <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                    <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                </div>
            </div>
            </ng-container>
        </div>
    </div>
</div>

