    <div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
            <div class="subHeadingCls mb-3">Selected Criteria Details</div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Registration Unit Id:</label>
                        <div class="valueCls">{{certificateInfo?.registrationUnitId ? (certificateInfo?.registrationUnitId) : '-'}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Purpose Of Certificate:</label>
                        <div class="valueCls">{{certificateInfo?.purposeOfCertificate ? (certificateInfo?.purposeOfCertificate) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">No Of Copies:</label>
                        <div class="valueCls">{{certificateInfo?.noOfCopies ? (certificateInfo?.noOfCopies) : '-' }}</div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Registration Number:</label>
                        <div class="valueCls">{{certificateInfo?.registrationNumber ? (certificateInfo?.registrationNumber) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Registration Year:</label>
                        <div class="valueCls">{{certificateInfo?.registrationYear ? (certificateInfo?.registrationYear) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Registration Date:</label>
                        <div class="valueCls">{{certificateInfo?.registrationDate ? (certificateInfo?.registrationDate) : '-' }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
            <ng-container *ngIf="certificateInfo?.certificateType === 'birthCertificate'">
            <div class="subHeadingCls mb-3">Birth Criteria Details</div>
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Child Name:</label>
                        <div class="valueCls">{{certificateInfo?.childName ? (certificateInfo?.childName) : '-'}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Child SurName:</label>
                        <div class="valueCls">{{certificateInfo?.childSurName ? (certificateInfo?.childSurName) : '-'}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Date of Birth:</label>
                        <div class="valueCls">{{certificateInfo?.dateOfBirth ? (certificateInfo?.dateOfBirth | date: 'dd/MM/yyyy') : '-'}}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Gender:</label>
                        <div class="valueCls">{{certificateInfo?.gender ? (certificateInfo?.gender) : '-'}}</div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Father Name:</label>
                        <div class="valueCls">{{certificateInfo?.fatherName ? (certificateInfo?.fatherName) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Father SurName:</label>
                        <div class="valueCls">{{certificateInfo?.fatherSurName ? (certificateInfo?.fatherSurName) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Mother Name:</label>
                        <div class="valueCls">{{certificateInfo?.motherName ? (certificateInfo?.motherName) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Mother SurName:</label>
                        <div class="valueCls">{{certificateInfo?.motherSurName ? (certificateInfo?.motherSurName) : '-' }}</div>
                    </div>
               
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Place of Birth:</label>
                        <div class="valueCls">{{certificateInfo?.birthPlace ? (certificateInfo?.birthPlace) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Birth Address:</label>
                        <div class="valueCls">{{certificateInfo?.birthAddress ? (certificateInfo?.birthAddress) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Location Name:</label>
                        <div class="valueCls">{{certificateInfo?.locationName ? (certificateInfo?.locationName) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Residence Address:</label>
                        <div class="valueCls">{{certificateInfo?.residence ? (certificateInfo?.residence) : '-' }}</div>
                    </div>
               
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">Permanent Address:</label>
                        <div class="valueCls">{{certificateInfo?.permanentAddress ? (certificateInfo?.permanentAddress) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Address1:</label>
                        <div class="valueCls">{{certificateInfo?.address1 ? (certificateInfo?.address1) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Address2:</label>
                        <div class="valueCls">{{certificateInfo?.address2 ? (certificateInfo?.address2) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Address3:</label>
                        <div class="valueCls">{{certificateInfo?.address3 ? (certificateInfo?.address3) : '-' }}</div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <div class="fieldHolder">
                        <label class="labelCls">District:</label>
                        <div class="valueCls">{{certificateInfo?.districtName ? (certificateInfo?.districtName) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">Location Name:</label>
                        <div class="valueCls">{{certificateInfo?.locationName ? (certificateInfo?.locationName) : '-' }}</div>
                    </div>
                    <div class="fieldHolder">
                        <label class="labelCls">PinCode:</label>
                        <div class="valueCls">{{certificateInfo?.pincode ? (certificateInfo?.pincode) : '-' }}</div>
                    </div>
                   
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="certificateInfo?.certificateType === 'deathCertificate'">
                <div class="subHeadingCls mb-3">Death Criteria Details</div>
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div class="fieldHolder">
                            <label class="labelCls">Deceased Name:</label>
                            <div class="valueCls">{{certificateInfo?.deceasedName ? (certificateInfo?.deceasedName) : '-' }}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Deceased SurName:</label>
                            <div class="valueCls">{{certificateInfo?.deceasedSurName ? (certificateInfo?.deceasedSurName) : '-'}}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Date of Death:</label>
                            <div class="valueCls">{{certificateInfo?.dateOfDeath ? (certificateInfo?.dateOfDeath | date: 'dd/MM/yyyy') : '-' }}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Father Name:</label>
                            <div class="valueCls">{{certificateInfo?.fatherName ? (certificateInfo?.fatherName) : '-' }}</div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        
                        <div class="fieldHolder">
                            <label class="labelCls">Father SurName:</label>
                            <div class="valueCls">{{certificateInfo?.fatherSurName ? (certificateInfo?.fatherSurName) : '-' }}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Mother Name:</label>
                            <div class="valueCls">{{certificateInfo?.motherName ? (certificateInfo?.motherName) : '-' }}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Mother SurName:</label>
                            <div class="valueCls">{{certificateInfo?.motherSurName ? (certificateInfo?.motherSurName) : '-' }}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Place of Death:</label>
                            <div class="valueCls">{{certificateInfo?.deathPlace ? (certificateInfo?.deathPlace) : '-' }}</div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                       
                        <div class="fieldHolder">
                            <label class="labelCls">Birth Address:</label>
                            <div class="valueCls">{{certificateInfo?.birthAddress ? (certificateInfo?.birthAddress) : '-' }}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Location Name:</label>
                            <div class="valueCls">{{certificateInfo?.locationName ? (certificateInfo?.locationName) : '-' }}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Address1:</label>
                            <div class="valueCls">{{certificateInfo?.address1 ? (certificateInfo?.address1) : '-' }}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">Address2:</label>
                            <div class="valueCls">{{certificateInfo?.address2 ? (certificateInfo?.address2) : '-' }}</div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
               
                      
                       
                        <div class="fieldHolder">
                            <label class="labelCls">Address3:</label>
                            <div class="valueCls">{{certificateInfo?.address3 ? (certificateInfo?.address3) : '-' }}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">District:</label>
                            <div class="valueCls">{{certificateInfo?.districtName ? (certificateInfo?.districtName) : '-' }}</div>
                        </div>
                        <div class="fieldHolder">
                            <label class="labelCls">PinCode:</label>
                            <div class="valueCls">{{certificateInfo?.pincode ? (certificateInfo?.pincode) : '-' }}</div>
                        </div>
                    </div>
                    
                </div>
            
        </ng-container>
        </div>
        
        <div class="certificateInfo">
            <div class="row">
                <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
                    <ng-container *ngIf="fileList && fileList.length !== 0">
                    <div class="subHeadingCls mb-3">List of Documents</div>
                    <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
                        <div *ngIf="file.documentType === 'Application Form'">
                            <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                            <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                        </div>
              
                    </div>
                    </ng-container>
                </div>
            </div>
        </div>
    
   

    
    
