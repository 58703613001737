
<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
            </div>
        </div>
    </div>
</div>

<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Document Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Land Located District:</label>
                <div class="valueCls">{{certificateInfo?.docAddressDetails?.districtName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Land Located Mandal:</label>
                <div class="valueCls">{{certificateInfo?.docAddressDetails?.mandalMunicipalityName}}</div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Land Located Village:</label>
                <div class="valueCls">{{certificateInfo?.docAddressDetails?.villageWardName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Challan Amount:</label>
                <div class="valueCls">{{certificateInfo?.challanAmount }}</div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Challan No:</label>
                <div class="valueCls">{{certificateInfo?.challanNo}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Challan Date:</label>
                <div class="valueCls">{{certificateInfo?.challanDate }}</div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Challan Details (Amount paid in Treasury/Bank):</label>
                <div class="valueCls">{{certificateInfo?.challanBankDetails}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Purpose for the Conversion:</label>
                <div class="valueCls">{{certificateInfo?.purposeforConversion }}</div>
            </div>
        </div>
    </div>

</div>


<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Document Details</div>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <table class="tableCls mt-2">
                <thead>
                <tr> 
                    <th>Survey No/Sub Division</th>
                    <th>Khatha No</th>
                    <th>Extent</th>
                    <th>Land Nature</th>
                    <th>Land Classifaction</th>
                    <th>TotalExtent</th>
                    <th>Area (In Units)</th>
                    <th>Extent for Conversion (Area in Units)</th>
                    <th>Area (In Units)</th>
                    <th>Doc.No/year</th>
                    <th>Pattadhar Name</th>
                </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of certificateInfo?.documentInfo">
                        <td class="value">{{item.surveyNo ? (item.surveyNo) : '-' }}</td>
                        <td class="value">{{item.khataNo ? (item.khataNo) : '-' }}</td>
                        <td class="value">{{item.kathaExtent ? (item.kathaExtent) : '-' }}</td>
                        <td>{{item.landNature ? (item.landNature) : '-' }}</td>
                        <td>{{item.landClassifaction ? (item.landClassifaction) : '-' }}</td>
                        <!-- <td>{{item.extentforConversion ? (item.extentforConversion) : '-' }}</td> -->
                        <td class="value">{{item.totalExtent ? (item.totalExtent) : '-' }}</td>
                        <td>{{item.areaInUnits ? (item.areaInUnits) : '-' }}</td>
                        <td class="value">{{item.convertionAmount ? (item.convertionAmount) : '-' }}</td>
                        <td>{{item.unitsOfArea ? (item.unitsOfArea) : '-' }}</td>
                        <td class="value">{{item.docNoOryear ? (item.docNoOryear) : '-' }}</td>
                        <td>{{item.pattadharName ? (item.pattadharName) : '-' }}</td>
                      
                    </tr>
                </tbody>
                </table>
            </div>
    </div>


    <div class="certificateInfo">
        <div class="row">
            <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12" >
                <ng-container *ngIf="fileList">
                    <div class="subHeadingCls mb-3">Documents List</div>
               

                    <div class="fieldHolder" *ngFor="let file of fileList ; let i = index">
                        <div *ngIf="file.documentType === 'annexureCopy'">
                            <span class="numberCls">{{i+1}}</span><label class="labelCls">Annexure-A Copy:</label>
                            <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                        </div>
                   

                 
                    <div *ngIf="file.documentType === 'Application Form'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Application Form:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                


                 
                    <div *ngIf="file.documentType === 'Register Documents'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Register documents/copies of Pattadhar Pass books/Title Deeds:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
               

                 
                    <div *ngIf="file.documentType === 'subRegistrarCertificates'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Basic Value certificate from Sub-Registrar:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
               

                 
                    <div *ngIf="file.documentType === 'rationCard'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Ration Card/ Aadhaar No./EPIC card:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
              

                 
                    <div *ngIf="file.documentType === 'challanCopy'">
                        <span class="numberCls">{{i+1}}</span><label class="labelCls">Challan Copy:</label>
                        <div class="valueCls"><a (click)="viewFile(file.blobRef)">{{file.fileName}}</a><span (click)="downloadFile(file)" class="download ml-2"></span></div>
                    </div>
                </div>


                </ng-container>
            </div>
        </div>
    </div>

</div>