
<div class="reportContainer">
    <div  [formGroup]="form">

        <div class="row mb-3">
            
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">District : <span class="mandatoryCls">*</span></label>
            <select class="selectCls"  formControlName="district">
                <option value="All">All</option>
                <option *ngFor="let district of districts" [ngValue]="district">{{district.districtName}}</option>
            </select>
            
            </div>

            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <label class="labelCls">Rural/Urban : <span class="mandatoryCls">*</span></label> 
                <select class="selectCls" required formControlName="RUFlag" (change)="onRuralUrbanChange()"
                [ngClass]="{ 'is-invalid':submitted && (f.RUFlag.errors || f.RUFlag.value === 'Select') }">
                <option value = "All">All</option>
                <option value = "R">Rural</option>
                <option value = "U">Urban</option>
                </select>
            </div>
        
            <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
            <label class="labelCls">Mandal/Municipality: <span class="mandatoryCls">*</span></label>
            <select class="selectCls" formControlName="mandal" (change)="onMandalChange()">
            <option value="All">All</option>
            <option *ngFor="let mandal of mandals" [ngValue]="mandal">{{mandal.mandalName}}</option>
            </select>            
            </div>

            
                <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
                    <label class="labelCls">Secretariat: <span class="mandatoryCls">*</span></label>
                    <select class="selectCls" formControlName="secreteriate">
                        <option value="All">All</option>
                        <option *ngFor="let sec of secreteriates" [ngValue]="sec">{{sec.secretariatName}}</option>
                    </select>
        </div>
    </div>

    
    <div class="tabClass">
        <ul>
            <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li> 
        </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getReportData()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" (click)="goToHome()">Home</button>
        </div>
      </div>

      <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="table-responsive tableFixHead" *ngIf="showReports">
        <table class="tableCls" >
            <thead> 
                <tr>
                    <th [attr.colspan]="noOfCol">SECRETARIAT PAN UPDATED REPORT</th>
                </tr>
                <tr>
                    <th width="100px">S.No</th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'" >District Name<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal Name<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rurbaN_FLAG'" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Rural/Urban<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'" *ngIf="format === 'Secretariat Wise'">SEC CODE <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'" *ngIf="format === 'Secretariat Wise'">SEC NAME <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'toT_MANDALS'"   *ngIf="format === 'District Wise'">No.Of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'toT_SEC'" *ngIf="format !== 'Secretariat Wise'">No.Of Secretariat<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'paN_ENTERED_SEC'" *ngIf="format !== 'Secretariat Wise'">No.Of Secretariat Entered Pan Details<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'paN_PENDING_SEC'" *ngIf="format !== 'Secretariat Wise'" >No.Of Pending Secreteriat To Update PAN<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'paN_ENTERED_SEC'" *ngIf="format == 'Secretariat Wise'" >Updated Pan Details (Yes/No)<mdb-icon fas icon="sort"></mdb-icon></th>
                    </tr>           
               
                
            </thead>
    
            <tbody *ngIf="items && items.length > 0">
                <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;"> 
                <td width="100px">{{ ((page -1) * pageSize) + (i + 1)}}</td>
                <td>{{item.districT_NAME}}</td>                
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.rurbaN_FLAG}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_CODE}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
                <td *ngIf="format == 'District Wise'">{{item.toT_MANDALS}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{item.toT_SEC}}</td>  
                <td *ngIf="format !== 'Secretariat Wise'">{{item.paN_ENTERED_SEC}}</td> 
                <td *ngIf="format !== 'Secretariat Wise'">{{item.paN_PENDING_SEC}}</td>
                <td *ngIf="format == 'Secretariat Wise'">{{item.paN_ENTERED_SEC}}</td>            
            </tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td>{{totalItems[0].districT_NAME}}</td>                
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].rurbaN_FLAG}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_CODE}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
                <td *ngIf="format == 'District Wise'">{{totalItems[0].toT_MANDALS}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].toT_SEC}}</td>  
                <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].paN_ENTERED_SEC}}</td> 
                <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].paN_PENDING_SEC}}</td>
                <td *ngIf="format == 'Secretariat Wise'">{{totalItems[0].paN_ENTERED_SEC}}</td>  
               </tr></tbody>
            <tbody *ngIf="items && items.length === 0">
                <tr>
                    <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </table>
        </div>
        <div class="mt-4 btnHide" *ngIf="showReports">
            <div class="float-left" *ngIf="items.length > 0">
                <label>Requests per page: </label>
                <select class="selectCls pageSizeCls" [(ngModel)]="pageSize" [ngModelOptions]="{standalone: true}">
                    <option  value="10">10</option>
                    <option  value="20">20</option>
                    <option  value="30">30</option>
                    <option  value="40">40</option>
                    <option  value="50">50</option>
                </select> 
            </div>
            <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
            </ngb-pagination>
        </div>
    
    
    </div>
    </div>

    <!-- Download report -->
    
    <div class="table-responsive tableFixHead" #TABLE class="hideTable">
        <table class="tableCls" >
            <thead>
             <tr>
                 <th [attr.colspan]="noOfCol">SECRETARIAT PAN UPDATED REPORT</th>
             </tr>
            
                <tr>
                    <th width="100px">S.No</th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'" >District Name<mdb-icon fas icon="sort"></mdb-icon></th>                    
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Mandal Name<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'rurbaN_FLAG'" *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">Rural/Urban<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'" *ngIf="format === 'Secretariat Wise'">SEC CODE <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_NAME'" *ngIf="format === 'Secretariat Wise'">SEC NAME <mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'toT_MANDALS'"   *ngIf="format === 'District Wise'">No.Of Mandals<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'toT_SEC'" *ngIf="format !== 'Secretariat Wise'">No.Of Secretariat<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'paN_ENTERED_SEC'" *ngIf="format !== 'Secretariat Wise'">No.Of Secretariat Entered Pan Details<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'paN_PENDING_SEC'" *ngIf="format !== 'Secretariat Wise'" >No.Of Pending Secreteriat To Update PAN<mdb-icon fas icon="sort"></mdb-icon></th>
                    <th aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'paN_ENTERED_SEC'" *ngIf="format !== 'Mandal Wise' && format == 'Secretariat Wise'" >Updated Pan Details (Yes/No)<mdb-icon fas icon="sort"></mdb-icon></th>
                    
                </tr>
                
            </thead>
     
            <tbody *ngIf="items && items.length > 0">
                <tr *ngFor="let item of items;let i=index">
                <td width="100px">{{i + 1}}</td>                    
                <td>{{item.districT_NAME}}</td>                
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.rurbaN_FLAG}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_CODE}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_NAME}}</td>
                <td *ngIf="format == 'District Wise'">{{item.toT_MANDALS}}</td>
                <td *ngIf="format !== 'Secretariat Wise'">{{item.toT_SEC}}</td>  
                <td *ngIf="format !== 'Secretariat Wise'">{{item.paN_ENTERED_SEC}}</td> 
                <td *ngIf="format !== 'Secretariat Wise'">{{item.paN_PENDING_SEC}}</td>
                <td *ngIf="format !== 'Mandal Wise' && format == 'Secretariat Wise'">{{item.paN_ENTERED_SEC}}</td>                               
                </tr>
                <tr *ngIf="totalItems && totalItems.length > 0">
                    <td></td>
                    <td>{{totalItems[0].districT_NAME}}</td>                    
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
                    <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].rurbaN_FLAG}}</td>
                    <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_CODE}}</td>
                    <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_NAME}}</td>
                    <td *ngIf="format == 'District Wise'">{{totalItems[0].toT_MANDALS}}</td>
                    <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].toT_SEC}}</td>  
                    <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].paN_ENTERED_SEC}}</td> 
                    <td *ngIf="format !== 'Secretariat Wise'">{{totalItems[0].paN_PENDING_SEC}}</td>
                    <td *ngIf="format == 'Secretariat Wise'">{{totalItems[0].paN_ENTERED_SEC}}</td>  
                   </tr>
            </tbody>
            <tbody *ngIf="items && items.length === 0">
                <tr>
                    <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
                </tr>
            </tbody>
        </table>
     </div>

     </div>
</div>