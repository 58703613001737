<div class="reportContainer">
    <div [formGroup]="form">

<div class="row mb-3">
  <div class="col col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <label class="labelCls">Schedule Date: <span class="mandatoryCls">*</span></label>
        <div class="input-group">
          <input class="calendarCls form-control" placeholder="dd-mm-yyyy" appBlockCopyPaste
                 name="d2"  ngbDatepicker #d2="ngbDatepicker" [minDate]='minDate' (dateSelect)="onToDateSelect($event)"
                 [maxDate]="maxDate"  formControlName="toDate"
                 [ngClass]="{ 'is-invalid':submitted && f.toDate.errors }">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"></button>
          </div>
        </div>
        <div *ngIf="submitted && f.toDate.errors" class="selectError">
            <div *ngIf="f.toDate.errors?.required || f.toDate.invalid">Please enter valid Schedule Date</div>
        </div>
        </div>
</div>

  <div class="tabClass">
        <ul class="nav nav-pills flex-column flex-sm-row"  class="navbar navbar-expand-lg navbar-light "> 
              <li *ngFor="let format of formatTypes"><a [ngClass]="{'active' : format.isActive}" (click)="onChangeFormat(format)">{{format.name}}</a></li>
              </ul>
        <div class="btnHolder">
            <button  class="greenBtnCls float-right my-3" (click)="getJaganannaSurakshaCampStatusReport()">Get Reports</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="items && items.length > 0 && showReports" (click)="downloadExcel()">Download Excel</button>
            <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="role !== 'Digital Assistant' && role !== 'Ward Education Secretary'" (click)="goToHome()">Home</button>
        </div>
      </div>
</div>
<div class="table-responsive tableFixHead" *ngIf="showReports">
    <table class="tableCls" >
        <thead> 
            <tr>
                <th [attr.colspan]="noOfCol">Suraksha Camp Status Report</th>
            </tr>
            <tr>
               <th>S.No</th>
               <th *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal Name<mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'seC_NAME'">Secretariat Name <mdb-icon fas icon="sort"></mdb-icon></th>
               <th *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'">Secretariat Code <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'toT_SECS'">No. of secretariats should be started as per schedule <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'camP_STARTED_SEC'">No. of secretariats camp started <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'camP_STARTED_SEC'">Is camp started <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'camP_COMP_SEC'">No. of secretariats completed camp <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'camP_STARTED_SEC'">Is camp completed<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'noOfCitizensParticipated'">No. of citizens participated in Camp <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'noOfTokenGenerated'">No. of tokens generated on camp day <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'noOfServicesRegistered'">No. of service requests raised on camp day <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'noOfGrievancesRaised'">No. of grievances raised on camp day <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'noOfCertificatesIssued'">No. of certificates issued on camp day <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'noOfCCRCCARD_ISSUED'">No of CCRC cards issued during 7 days camp period <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'noofaadhaaR_SERVICES'">No of Mobile number updation in Aadhaar card services during 7 days camp period <mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'Secretariat Wise' && validUsers()" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" >Camp Start Photo<mdb-icon fas icon="sort"></mdb-icon></th>
               <th  *ngIf="format === 'Secretariat Wise' && validUsers()" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" >Camp End Photo<mdb-icon fas icon="sort"></mdb-icon></th>
              </tr>
           </thead>

        <tbody *ngIf="items && items.length > 0">
            <tr *ngFor="let item of items | slice: (page-1) * pageSize : page * pageSize;let i = index;">
                <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise'">{{item.districT_NAME}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
               <td *ngIf="format === 'Secretariat Wise'">{{item.seC_NAME}}</td>
               <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_CODE}}</td>
               <td class="text-right">{{item.toT_SECS ? item.toT_SECS: '-'}}</td>
                <td class="text-right">{{item.camP_STARTED_SEC}}</td>
                <td class="text-right">{{item.camP_COMP_SEC}}</td>
                <td class="text-right">{{item.noOfCitizensParticipated}}</td>
                <td class="text-right">{{item.noOfTokenGenerated}}</td>
                <td class="text-right">{{item.noOfServicesRegistered}}</td>
                <td class="text-right">{{item.noOfGrievancesRaised}}</td>
                <td class="text-right">{{item.noOfCertificatesIssued}}</td>
                <td class="text-right">{{item.noOfCCRCCARD_ISSUED}}</td>
                <td class="text-right">{{item.noofaadhaaR_SERVICES}}</td>
                <td *ngIf="format === 'Secretariat Wise' && validUsers() && item.campStartedPhotos"  class="text-right"><a class = "linkCls" (click)= "viewImgFile(item.campStartedPhotos)">Show Image</a></td>
                <td *ngIf="format === 'Secretariat Wise' && validUsers() && !item.campStartedPhotos"  class="text-right">-</td>
                <td *ngIf="format === 'Secretariat Wise' && validUsers() && item.campPhotos"  class="text-right"><a class="linkCls" (click)= "viewImgFile(item.campPhotos)">Show Image</a></td>
                <td *ngIf="format === 'Secretariat Wise' && validUsers() && !item.campPhotos"  class="text-right">-</td>
              <tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise'">{{totalItems[0].districT_NAME}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].seC_NAME}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_CODE}}</td>
                <td class="text-right">{{totalItems[0].toT_SECS ? totalItems[0].toT_SECS: '-' }}</td>
                <td class="text-right">{{totalItems[0].camP_STARTED_SEC}}</td>
                <td class="text-right">{{totalItems[0].camP_COMP_SEC}}</td>
                <td class="text-right">{{totalItems[0].noOfCitizensParticipated}}</td>
            <td class="text-right">{{totalItems[0].noOfTokenGenerated}}</td>
            <td class="text-right">{{totalItems[0].noOfServicesRegistered}}</td>
            <td class="text-right">{{totalItems[0].noOfGrievancesRaised}}</td>
            <td class="text-right">{{totalItems[0].noOfCertificatesIssued}}</td>
            <td class="text-right">{{totalItems[0].noOfCCRCCARD_ISSUED}}</td>
            <td class="text-right">{{totalItems[0].noofaadhaaR_SERVICES}}</td>
            <td *ngIf="format === 'Secretariat Wise' && validUsers()"  class="text-right"></td>
            <td *ngIf="format === 'Secretariat Wise' && validUsers()"  class="text-right"></td>
            </tr>
        </tbody>
        <tbody *ngIf="items && items.length === 0">
            <tr>
                <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
        </tbody>
    </table>
    <div class="mt-4 btnHide">
        <div class="float-left" *ngIf="items.length > 0">
            <label>Requests per page: </label>
            <select class="selectCls pageSizeCls" [(ngModel)]="pageSize">
                <option  value="10">10</option>
                <option  value="20">20</option>
                <option  value="30">30</option>
                <option  value="40">40</option>
                <option  value="50">50</option>
            </select> 
        </div>
        <ngb-pagination class="d-flex justify-content-end" *ngIf="items.length > 0" [collectionSize]="items.length" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
        </ngb-pagination>
    </div>
</div>
</div>
<div class="table-responsive tableFixHead" #TABLE class="hideTable">
   <table class="tableCls" >
       <thead>
           <tr>
           
                <th [attr.colspan]="noOfCol">Suraksha Camp Status Report</th>
            </tr>
            <tr>
                <th >S.No</th>
                <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'districT_NAME'">District Name <mdb-icon fas icon="sort"></mdb-icon></th>
                <th *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'mandaL_NAME'">Mandal Name<mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'seC_NAME'">Secretariat Name <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'secretariaT_CODE'">Secretariat Code <mdb-icon fas icon="sort"></mdb-icon></th>
                <th    aria-controls="tableSortExample" scope="col" [mdbTableSort]="items" [sortBy]="'toT_SECS'">No. of secretariats should be started as per schedule <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'camP_STARTED_SEC'">No. of secretariats camp started <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'camP_STARTED_SEC'">Is camp started <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'District Wise'|| format === 'Mandal Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'camP_COMP_SEC'">No. of secretariats completed camp <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  *ngIf="format === 'Secretariat Wise'" aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'camP_STARTED_SEC'">Is camp completed<mdb-icon fas icon="sort"></mdb-icon></th>
                <th   aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'noOfCitizensParticipated'">No. of citizens participated in Camp <mdb-icon fas icon="sort"></mdb-icon></th>
                <th    aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'noOfTokenGenerated'">No. of tokens generated on camp day <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'noOfServicesRegistered'">No. of service requests raised on camp day <mdb-icon fas icon="sort"></mdb-icon></th>
                <th   aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'noOfGrievancesRaised'">No. of grievances raised on camp day <mdb-icon fas icon="sort"></mdb-icon></th>
                <th   aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'noOfCertificatesIssued'">No. of certificates issued on camp day <mdb-icon fas icon="sort"></mdb-icon></th>
                <th   aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'noOfCCRCCARD_ISSUED'">No of CCRC cards issued during 7 days camp period <mdb-icon fas icon="sort"></mdb-icon></th>
                <th  aria-controls="tableSortExample" scope="col" [mdbTableSort]="items"  [sortBy]="'noofaadhaaR_SERVICES'">No of Mobile number updation in Aadhaar card services during 7 days camp period <mdb-icon fas icon="sort"></mdb-icon></th>
            </tr>
       </thead>

       <tbody *ngIf="items && items.length > 0">
        <tr *ngFor="let item of items;let i = index;">
            <td>{{ ((page -1) * pageSize) + (i + 1)}}</td>
            <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise'">{{item.districT_NAME}}</td>
            <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{item.mandaL_NAME}}</td>
           <td *ngIf="format === 'Secretariat Wise'">{{item.seC_NAME}}</td>
           <td *ngIf="format === 'Secretariat Wise'">{{item.secretariaT_CODE}}</td>
           <td class="text-right">{{item.toT_SECS ? item.toT_SECS: '-'}}</td>
            <td class="text-right">{{item.camP_STARTED_SEC}}</td>
            <td class="text-right">{{item.camP_COMP_SEC}}</td>
            <td class="text-right">{{item.noOfCitizensParticipated}}</td>
            <td class="text-right">{{item.noOfTokenGenerated}}</td>
            <td class="text-right">{{item.noOfServicesRegistered}}</td>
            <td class="text-right">{{item.noOfGrievancesRaised}}</td>
            <td class="text-right">{{item.noOfCertificatesIssued}}</td>
            <td class="text-right">{{item.noOfCCRCCARD_ISSUED}}</td>
            <td class="text-right">{{item.noofaadhaaR_SERVICES}}</td>
          <tr>
            <tr *ngIf="totalItems && totalItems.length > 0">
                <td></td>
                <td *ngIf="format === 'District Wise'|| format === 'Mandal Wise'|| format === 'Secretariat Wise'">{{totalItems[0].districT_NAME}}</td>
                <td *ngIf="format === 'Mandal Wise'  || format === 'Secretariat Wise'">{{totalItems[0].mandaL_NAME}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].seC_NAME}}</td>
                <td *ngIf="format === 'Secretariat Wise'">{{totalItems[0].secretariaT_CODE}}</td>
                <td class="text-right">{{totalItems[0].toT_SECS ? totalItems[0].toT_SECS: '-'}}</td>
                <td class="text-right">{{totalItems[0].camP_STARTED_SEC}}</td>
                <td class="text-right">{{totalItems[0].camP_COMP_SEC}}</td>
                <td class="text-right">{{totalItems[0].noOfCitizensParticipated}}</td>
            <td class="text-right">{{totalItems[0].noOfTokenGenerated}}</td>
            <td class="text-right">{{totalItems[0].noOfServicesRegistered}}</td>
            <td class="text-right">{{totalItems[0].noOfGrievancesRaised}}</td>
            <td class="text-right">{{totalItems[0].noOfCertificatesIssued}}</td>
            <td class="text-right">{{totalItems[0].noOfCCRCCARD_ISSUED}}</td>
            <td class="text-right">{{totalItems[0].noofaadhaaR_SERVICES}}</td>
            </tr>
       </tbody>
       <tbody *ngIf="items && items.length === 0">
           <tr>
              <td [attr.colspan]="noOfCol" class="text-center">No records found.</td>
            </tr>
       </tbody>
   </table>
  </div>
  
