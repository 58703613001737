<div class="certificateInfo" *ngIf="basicInfo && basicInfo.length !==0">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Ration Card No:</label>
                <div class="valueCls">{{basicInfo?.applicantInfo?.rationCardNo ? (basicInfo?.applicantInfo?.rationCardNo) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Remarks:</label>
                <div class="valueCls">{{basicInfo.remarks ? (basicInfo.remarks) : '-' }}</div>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo" *ngIf="certificateInfo && certificateInfo.length !==0">
    <div class="subHeadingCls mb-3">Selected Criteria Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">District:</label>
                <div class="valueCls">{{certificateInfo.khataDetails?.districtName ? (certificateInfo.khataDetails?.districtName) : '-' }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Mandal:</label>
                <div class="valueCls">{{certificateInfo.khataDetails?.mandalMunicipalityName ? (certificateInfo.khataDetails?.mandalMunicipalityName) : '-' }}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Village:</label>
            <div class="valueCls">{{certificateInfo.khataDetails?.villageWardName ? (certificateInfo.khataDetails?.villageWardName) : '-' }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Khata Number:</label>
            <div class="valueCls">{{certificateInfo?.khataNo ? (certificateInfo?.khataNo) : '-' }}</div>
        </div>
        </div>
    </div>
    <hr>
    <div class="subHeadingCls mb-3">Applicant Details</div>
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">

                <table class="tableCls mt-2" *ngIf="certificateInfo?.applicantLPDetailsList?.length > 0">
                    <thead>
                        <tr>
                            <th>Serial No</th>
                            <th>Pattadar Name</th>
                            <th>Pattadar Father/Husband Name</th>
                            <th>Khata Number</th>
                            <th>LP No</th>
                            <th>Old Survey No</th>
                            <th>LP Extent</th>
                            <th>Land Classification (Sub Classification 1,2)</th>
                            <th>Acquisition Rights</th>
                          </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let litem of certificateInfo?.applicantLPDetailsList; let i=index">                            
                            <td>{{i+1}}</td>
                            <td class="value">{{litem?.lppattadarname ? (litem?.lppattadarname) : '-' }}</td>
                            <td>{{litem?.lppattadarrelationname? (litem?.lppattadarrelationname) : '-'}}</td>
                            <td>{{litem?.lpkhatano? (litem?.lpkhatano) : '-'}}</td>
                            <td>{{litem?.lplpmno? (litem?.lplpmno) : '-'}}</td>
                            <td>{{litem?.lpoldsurveyno? (litem?.lpoldsurveyno) : '-'}}</td>
                            <td>{{litem?.lplpmextent? (litem?.lplpmextent) : '-'}}</td>
                            <td>{{litem?.lplandclassification? (litem?.lplandclassification) : '-'}} &nbsp;( {{litem?.lplandclassificationsubcategory1? (litem?.lplandclassificationsubcategory1) : '-'}} ,&nbsp;{{litem?.lplandclassificationsubcategory2? (litem?.lplandclassificationsubcategory2) : '-'}} )</td>
                            <td>{{litem?.lpAcquisitionRights? (litem?.lpAcquisitionRights) : '-'}}</td>
                        </tr>
                    </tbody>
                </table>
               

                <table class="tableCls mt-2" *ngIf="members.length > 0">
                    <thead>
                    <tr> 
                        <th>Pattadar Name</th>
                        <th>Pattadar Father Name</th>
                        <th>Khata Number</th>
                        <th>Survey Number</th>
                        <th>Land Classification</th>
                        <th>Total Extent</th>
                        <th>Tax</th>
                        <th>Acquired Reason</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of members">
                            <td>{{item.pattadarName ? (item.pattadarName) : '-' }}</td>
                            <td>{{item.pattadarFatherName ? (item.pattadarFatherName) : '-' }}</td>
                            <td class="value">{{certificateInfo?.khataNo ? (certificateInfo?.khataNo) : '-' }}</td>
                            <td class="value">{{item.surveyNumber ? (item.surveyNumber) : '-' }}</td>
                            <td>{{item.landClassification ? (item.landClassification) : '-' }}</td>
                            <td class="value">{{item.totalExtent ? (item.totalExtent) : '-' }}</td>
                            <td class="value">{{item.tax ? (item.tax) : '-' }}</td>
                            <td>{{item.acquiredReason ? (item.acquiredReason) : '-' }}</td>
                        </tr>
                    </tbody>
                </table>
                
            </div>
        </div>

</div>

