import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { CommonConstants } from 'src/constants/common-constants.component';
import { CommonService } from 'src/shared/common.service';
@Component({
  selector: 'app-no-earning-certificate-view',
  templateUrl: './no-earning-certificate-view.component.html',
  styleUrls: ['./no-earning-certificate-view.component.css']
})
export class NoEarningCertificateViewComponent implements OnInit {
  commonConstants : any = CommonConstants;
  @Input() certificateInfo: any;
  @Input() basicInfo: any;
  @Input() fileList: any;
  @Output() view = new EventEmitter();
  @Output() download = new EventEmitter();
  screenMandal: any;
  screenDistrict: any;
  screenVillage: any;
  nativeVillage : any;

  constructor(private commonService: CommonService) {
  }

  ngOnInit(): void {
  //   this.getByEntitycode(this.certificateInfo.deceasedDeathAddress?.district, 'District');
  // this.getByEntitycode(this.certificateInfo.deceasedDeathAddress?.mandalMunicipality, 'Mandal');
  // this.getByEntitycode(this.certificateInfo.deceasedDeathAddress?.villageWard, 'Secretariat');
  // this.getByEntitycode(this.certificateInfo.nativeVillage , 'Secretariat','NativeVillage');
  }

  getByEntitycode(entityCode: any, entityType:any, addrType?: any): any {
    this.commonService.getRequest(this.commonConstants.getByEntitycode + 'EN-IN&EntityCode=' + entityCode + '&EntityType=' + entityType).subscribe({  
    next: (responseData: any) => { 
        let name =  responseData.result[0]?.name;
        if (entityType === 'District' ) {
          this.screenDistrict = name;
        }else if (entityType === 'Mandal') {
          this.screenMandal = name; 
        } else if (entityType === 'Secretariat' && addrType === 'NativeVillage') {
          this.nativeVillage = name;
        }
        else if (entityType === 'Secretariat') {
          this.screenVillage = name;
        }
      },
      error: (error) => {
        console.log('client side', error);
      }
  });
  }

  viewFile(blobRef: any) {
    this.view.emit(blobRef);
  }
  downloadFile(file: any) {
    this.download.emit(file);
  }
}
