<div class="certificateInfo" *ngIf="certificateInfo">
    <div class="subHeadingCls mb-3">Applicant Details</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Aadhar No: </label>
                <div class="valueCls">{{certificateInfo.applicantAadhar}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Applicant Name: </label>
                <div class="valueCls">{{certificateInfo.applicantName}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Relation With Pattadar:</label>
            <div class="valueCls">{{certificateInfo.relationwithOwner}}</div>
        </div>
        </div>
    </div>
   
    <ng-container *ngIf="certificateInfo.isOtherState">
    <div class="subHeadingCls mb-3">Postal Address</div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Door No & Street Name: :</label>
                <div class="valueCls">{{certificateInfo.postalLocation }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">State:</label>
                <div class="valueCls">{{certificateInfo.postalState}}</div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">District:</label>
            <div class="valueCls">{{certificateInfo.postalDistrict }}</div>
        </div>
        <div class="fieldHolder">
            <label class="labelCls">Mandal:</label>
            <div class="valueCls">{{certificateInfo.postalMandal}}</div>
        </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Village:</label>
                <div class="valueCls">{{certificateInfo.postalVillage }}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Pin Code:</label>
                <div class="valueCls">{{certificateInfo.postalPincode }}</div>
            </div>
        </div>
    </div>
   </ng-container>
   <div class="subHeadingCls mb-3">Mutation Request is for the Land located in</div>
   <div class="row">
       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
           <div class="fieldHolder">
               <label class="labelCls">District:</label>
               <div class="valueCls">{{certificateInfo.landDetails?.districtName}}</div>
           </div>
           <div class="fieldHolder">
               <label class="labelCls">Mandal:</label>
               <div class="valueCls">{{certificateInfo.landDetails?.mandalMunicipalityName}}</div>
           </div>
       </div>
       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
       <div class="fieldHolder">
           <label class="labelCls">Village:</label>
           <div class="valueCls">{{certificateInfo.landDetails?.villageWardName}}</div>
       </div>
       <div class="fieldHolder">
           <label class="labelCls">Secretariat:</label>
           <div class="valueCls">{{certificateInfo.secretariatName}}</div>
       </div>
       </div>
       <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Division:</label>
            <div class="valueCls">{{certificateInfo.division}}</div>
        </div>
        </div>
   </div>
   <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Mutation Category:</label>
            <div class="valueCls">{{certificateInfo.mutationCategory}}</div>
        </div>
        <div class="fieldHolder" *ngIf="certificateInfo.mutationCategory === 'Succession'">
            <label class="labelCls">Succession Category:</label>
            <div class="valueCls">{{certificateInfo.successionSubCatg}}</div>
        </div>
    </div>
    </div>
   <div class="subHeadingCls mb-3">ROR/Adangal Details</div>
   <div class="row mb-3">
       <div class="col">
           <div class="tab table-responsive">
               <table class="tableCls">
                   <thead>
                       <tr>
                           <th>Serial No</th>
                           <th>LP Number</th>
                           <th>Khata Number</th>
                           <th>Land Nature</th>
                           <th>Pattadar Name</th>
                           <th>Pattadar Father /Husband Name</th>
                           <th>LP Extent</th>
                           <!-- <th>Enjoyment Nature</th> -->
                           <th>Acquisition Rights</th>
                       </tr>
                   </thead>
                   <tbody>
                       <tr *ngFor="let item of rorSellerItems;let i = index;">
                           <td>{{i + 1}}</td>
                           <td>{{item.LPMNO}}</td>
                           <td>{{item.KhataNo}}</td>
                           <td>{{item.LandNature}}</td>
                           <td>{{item.PattadarName}}</td>
                           <td>{{item.PattadarRelationName}}</td>
                           <td>{{item.LPM_Extent}}</td>
                           <!-- <td>{{item.EnjoyerNature}}</td> -->
                           <td>{{item.AcquisitionRights}}</td>
                       </tr>
                   </tbody>
               </table>
           </div>
       </div>
   </div>
   <div class="row" *ngIf="certificateInfo.successionSubCatg === 'FMC (Single Legal Heir)'">
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <div class="fieldHolder">
            <label class="labelCls">Family Member Application No:</label>
            <div class="valueCls">{{certificateInfo.fmbAppNo}}</div>
        </div>
       
    </div>
    </div>
    <div class="row" *ngIf="certificateInfo.successionSubCatg !== 'FMC (Single Legal Heir)'">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div class="fieldHolder">
                <label class="labelCls">Registration Document No:</label>
                <div class="valueCls">{{certificateInfo.registrationInput.regDocNo}}</div>
            </div>
            <div class="fieldHolder">
                <label class="labelCls">Registration Document Date:</label>
                <div class="valueCls">{{certificateInfo.registrationInput.regDocYear | date: 'dd/MM/yyyy'}}</div>
            </div>
        </div>
        </div>
    <div class="subHeadingCls mb-3" >{{getExecutantType()}} Details</div>
    <div class="row mb-3">
        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div class="tab">
                <table class="tableCls">
                    <thead>
                        <tr>
                            <th colspan="2">Land Details</th>
                        </tr>
                        <tr>
                            <th>LP Number</th>
                            <th>Extent</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="rorSellerItems && rorSellerItems?.length > 0">
                        <tr *ngFor="let item of rorSellerItems">
                            <td>{{item.LPMNO}}</td>
                            <td>{{item.LPM_Extent}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="rorSellerItems && rorSellerItems?.length === 0">
                        <tr>
                            <td colspan="2" class="text-center">No records found.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <label class="labelCls mb-3">{{getExecutantType()}} Name: <span>{{rorSellerItems[0]?.PattadarName}}</span></label>
            <label class="labelCls">{{getExecutantType()}} Father/Husband Name: <span>{{rorSellerItems[0]?.PattadarRelationName}}</span></label>
        </div>
       
    </div>
    <div class="row mb-3">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="tab table-responsive">
                <table class="tableCls">
                    <thead>
                        <tr>
                            <th colspan="7" *ngIf="certificateInfo.successionSubCatg !== 'FMC (Single Legal Heir)'">Proposed {{getClaimantType()}} Details</th>
                            <th colspan="7" *ngIf="certificateInfo.successionSubCatg === 'FMC (Single Legal Heir)'">Proposed Pattadar Details as per FMC</th>
                        </tr>
                        <tr>
                            <th>S.No</th>
                            <th>Enter Aadhar Number</th>
                            <th>{{getClaimantType()}} Name in English</th>
                            <th>{{getClaimantType()}} Name in Telugu</th>
                            <th>Father / Husband Name in English</th>
                            <th>Father / Husband Name in Telugu</th>
                            <th>Khata No</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let member of mutationDetails; let i=index;">
                            <td>{{i + 1}}</td>
                            <td>{{ this.commonService.RSADecrypt(member.aadharNo) }}</td>
                            <td>{{member.name}}</td>
                            <td>{{member.nameInTelugu}}</td>
                            <td>{{member.fatherHusbandName}}</td>
                            <td>{{member.fatherHusbandNameInTelugu}}</td>
                            <td>{{member.khataNo}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="certificateInfo mt-2 mb-3">
    <div class="row">
      <div class="col-lg-9 col-md-9 col-sm-12 col-xs-12">
        <ng-container *ngIf="fileList && fileList?.length !== 0">
          <div class="subHeadingCls mb-3">List of Documents</div>
          <div class="fieldHolder" *ngFor="let file of fileList; let i = index">
            <div *ngIf="file.documentType === 'Tax Receipt'">
              <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Tax Receipts if any:</label>
              <div class="valueCls">
                <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                  class="download ml-2"></span>
              </div>
            </div>
            <div *ngIf="file.documentType === 'Old Pattadar Passbook'">
              <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Old Bhu Hakku Patram:</label>
              <div class="valueCls">
                <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                  class="download ml-2"></span>
              </div>
            </div>
            <div *ngIf="file.documentType === 'Registration Document'">
                <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Registration Document:</label>
                <div class="valueCls">
                  <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                    class="download ml-2"></span>
                </div>
              </div>
              <div *ngIf="file.documentType === 'Death Certificate'">
                <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Death Certificate:</label>
                <div class="valueCls">
                  <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                    class="download ml-2"></span>
                </div>
              </div>
              <div *ngIf="file.documentType === 'Affidavit'">
                <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Affidavit:</label>
                <div class="valueCls">
                  <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                    class="download ml-2"></span>
                </div>
              </div>
              <div *ngIf="file.documentType === 'FMBCertificate'">
                <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Family Member Certificate:</label>
                <div class="valueCls">
                  <a (click)="viewFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                    class="download ml-2"></span>
                </div>
              </div>
            <div *ngIf="file.documentType === 'aadharPhoto'">
              <span class="numberCls">{{ i + 1 }}</span><label class="labelCls">Photo:</label>
              <div class="valueCls">
                <a (click)="viewImgFile(file.blobRef)">{{ file.fileName }}</a><span (click)="downloadFile(file)"
                  class="download ml-2"></span>
              </div>
            </div>
          
          </div>
        </ng-container>
      </div>
    </div>
  </div>